/* eslint-disable no-unused-vars */
import React, { memo, Component } from 'react';
import { View, StyleSheet, TouchableOpacity, Dimensions } from 'react-native';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import firebase from 'firebase/app';
import 'firebase/database';

import { perfectSize } from '../../../helpers/deviceHelper';
import { MESSAGE } from '../../../constants/images';

import NormalButton from '../../buttons/normal-button';
import HopDetailCard from '../hop-detail-card';
import Label from '../../label';
import TimelineCore from '../../../pages/timeline/timelinecore';
import I18n from '../../../i18n';
import AppStyles from '../../../constants/styles';
import Feather from 'react-native-vector-icons/Feather';
import * as FirebasePaths from '../../../constants/firebasePaths';
import dimens from '../../../constants/dimens';
import LocalImageOpacity from '../../local-image-opacity';
import ImageWithPlaceholder from '../../image-with-placeholder';
const { width: screenWidth } = Dimensions.get('window');

class HopCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      textHeight: 20,
      shadowColor: '#C70064',
    };
    this.hopShadowListener = null;
    this.userStatusLilstener = null;
  }

  componentDidMount() {
    const cosmeticsShadowPath =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      this.props.item.uid +
      '/' +
      FirebasePaths.FIREBASE_COSMETICS_ATTR +
      '/' +
      FirebasePaths.FIREBASE_HOP_SHADOW_ATTR;

    const userStatusRef =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      this.props.item.uid +
      '/' +
      FirebasePaths.FIREBASE_DETAILS_ATTR;

    this.hopShadowListener = firebase.database().ref(cosmeticsShadowPath);

    this.userStatusLilstener = firebase.database().ref(userStatusRef);
    this.hopShadowListener.on('value', (cosmeticsSnapshot) => {
      const cosmeticsVal = cosmeticsSnapshot.val();
      const userMap = new Map();
      for (let key in cosmeticsVal) {
        const val = cosmeticsVal[key];
        userMap.set(key, val.toString());
      }
      const _shadowColor = userMap.get(FirebasePaths.FIREBASE_COLOR_ATTR);

      if (
        _shadowColor !== null &&
        _shadowColor !== undefined &&
        _shadowColor !== ''
      ) {
        this.setState({ shadowColor: _shadowColor });
      } else {
        this.userStatusLilstener.on('value', (userDetails) => {
          const userDetailsVal = userDetails.val();
          let shadowColor = '#C70064';
          for (let key in userDetailsVal) {
            const val = userDetailsVal[key];
            if (key === FirebasePaths.FIREBASAE_USER_RANK_ATTR) {
              if (val >= 8300) {
                shadowColor = '#19E7DD';
              }
              if (val >= 10800) {
                shadowColor = '#0087FF';
              }
              if (val >= 11700) {
                shadowColor = '#FCA40B';
              }
              if (val >= 12600) {
                shadowColor = '#F8DD00';
              }
              if (val >= 15000) {
                shadowColor = '#CC2893';
              }
              this.setState({ shadowColor });
            }
          }
        });
      }
    });
  }

  componentWillUnmount() {
    if (this.hopShadowListener !== null) {
      this.hopShadowListener.off();
    }
    if (this.userStatusLilstener !== null) {
      this.userStatusLilstener.off();
    }
  }

  getShadowColor = () => {
    // let color = '#0E1319';
    let color = '#0E1319';
    if (this.props.item.IS_HOP_SHADOW_ACTIVATED) {
      if (this.props.item.rank >= 8300 && this.props.item.rank < 10800) {
        color = '#19E7DD';
      } else if (
        this.props.item.rank >= 10800 &&
        this.props.item.rank < 11700
      ) {
        color = '#0087FF';
      } else if (
        this.props.item.rank >= 11700 &&
        this.props.item.rank < 12600
      ) {
        color = '#FCA40B';
      } else if (
        this.props.item.rank >= 12600 &&
        this.props.item.rank < 15000
      ) {
        color = '#F8DD00';
      } else if (this.props.item.rank > 15000) {
        color = '#CC2893';
      }
    }

    if (this.props.isAdmin) {
      color = '#C70064';
    }

    return color;
  };

  thumbUp = () => {
    if (
      !TimelineCore.getDisLiked(this.props.item) &&
      !TimelineCore.getLiked(this.props.item)
    ) {
      TimelineCore.increaseHP(this.props.item);
    }

    global.featuresLogger.HOP_LIKE();
    TimelineCore.thumbUpHop(this.props.item);
  };

  thumbDown = () => {
    global.featuresLogger.HOP_DISLIKE();
    TimelineCore.thumbDownHop(this.props.item);
  };

  render() {
    const { theme, disableLike } = this.props;
    const isJoined = this.props.item.type === 'TEXT_ONLY';
    const _styles = styles(theme);

    return (
      <View
        style={[
          _styles.flexRow,
          {
            paddingBottom: perfectSize(16),
            // minWidth: screenWidth - dimens.BODY_PADDING_HORIZONTAL * 4 - 2,
          },
        ]}>
        <View
          style={[
            _styles.leftShadow,
            {
              backgroundColor:
                this.props.item.shadowColor || this.state.shadowColor,
            },
          ]}
        />
        <View
          style={[
            _styles.container,
            {
              height:
                129 +
                this.state.textHeight +
                (this.props.item.images && this.props.item.images.length > 0
                  ? 200
                  : 0),
            },
            isJoined
              ? {
                backgroundColor: theme.secondary_btn_background,
              }
              : {
                backgroundColor: theme.btn_tag,
              },
          ]}>
          <TouchableOpacity
            onPress={this.props.onPress}
            onLongPress={this.props.onLongPress && this.props.onLongPress}>
            <HopDetailCard
              style={[
                _styles.detailCard,
                {
                  height: this.state.textHeight + 40,
                },
              ]}
              item={this.props.item}
              onMenu={this.props.onMenu && this.props.onMenu}
              isAdmin={this.props.isAdmin}
              from={this.props.from}
              onLayout={(height) => {
                this.setState({
                  textHeight: height,
                });
              }}
            />
          </TouchableOpacity>
          {isJoined ? (
            // Already joined
            <View style={_styles.footer}>
              <View style={_styles.flexRow}>
                <TouchableOpacity
                  style={_styles.flexRow}
                  onPress={() => {
                    disableLike ? this.props.onPress() : this.thumbUp();
                  }}>
                  <Label
                    size="small"
                    style={[
                      _styles.voteText,
                      (TimelineCore.getLiked(this.props.item) ||
                        this.props.item.likesCount > 0) &&
                      _styles.hightlightText,
                    ]}>
                    {TimelineCore.getHopLikesCount(this.props.item)}
                  </Label>

                  <Feather
                    name="thumbs-up"
                    color={
                      TimelineCore.getLiked(this.props.item)
                        ? '#C70064'
                        : 'grey'
                    }
                    size={perfectSize(14)}
                  />
                </TouchableOpacity>
                <TouchableOpacity
                  style={[_styles.flexRow, { marginLeft: perfectSize(10) }]}
                  onPress={() => {
                    disableLike ? this.props.onPress() : this.thumbDown();
                  }}>
                  <Label
                    size="small"
                    style={[
                      _styles.voteText,
                      TimelineCore.getDisLiked(this.props.item) &&
                      _styles.hightlightText,
                    ]}>
                    {TimelineCore.getHopDislikesCount(this.props.item)}
                  </Label>

                  <Feather
                    name="thumbs-down"
                    color={
                      TimelineCore.getDisLiked(this.props.item)
                        ? '#C70064'
                        : 'grey'
                    }
                    size={perfectSize(14)}
                  />
                </TouchableOpacity>
              </View>
              <View style={_styles.flexRow}>
                <Label size="small" style={_styles.downvoteText}>
                  {this.props.item.repliesNumber != null
                    ? this.props.item.repliesNumber
                    : 0}
                </Label>
                <LocalImageOpacity
                  source={MESSAGE}
                  style={_styles.comment}
                  resizeMode="contain"
                />
              </View>
            </View>
          ) : (
            // Not joined
            <View style={_styles.footer}>
              <View style={_styles.flexRow}>
                <Label size="small" style={_styles.downvoteText}>
                  {this.props.item.adminName}
                </Label>
                <ImageWithPlaceholder
                  uri={global.rankLogger.getRankImg(this.props.item.userRank)}
                  style={global.rankLogger.getRankStyle(
                    this.props.item.userRank,
                  )}
                  resizeMode="contain"
                  from={this.props.from}
                />
              </View>
              <NormalButton
                text={I18n.t('LOBBY_PAGE.join')}
                onPress={this.props.onPress}
                style={_styles.joinButton}
              />
            </View>
          )}
        </View>
        <View
          style={[
            _styles.leftShadow,
            {
              backgroundColor:
                this.props.item.shadowColor || this.state.shadowColor,
            },
          ]}
        />
      </View>
    );
  }
}

HopCard.propTypes = {
  data: PropTypes.object,
  isJoined: PropTypes.bool,
  onJoin: PropTypes.func,
  from: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
  };
};

export default memo(connect(mapStateToProps)(HopCard));

const styles = (theme) =>
  StyleSheet.create({
    container: {
      marginHorizontal: perfectSize(0.5),
      borderRadius: perfectSize(12),
      paddingHorizontal: perfectSize(16),
      height: '100%',
      flex: 1,
    },
    detailCard: {
      marginHorizontal: 0,
      overflow: 'hidden',
    },
    footer: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: perfectSize(8),
    },
    flexRow: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    hightlightText: {
      color: theme.primary_btn_background,
    },
    voteText: {
      opacity: 0.56,
      marginRight: perfectSize(4),
    },
    thumb: {
      marginLeft: perfectSize(4),
    },
    comment: {
      width: perfectSize(12),
      height: perfectSize(12),
      marginLeft: perfectSize(4),
    },
    imgGroup: {
      width: perfectSize(17),
      height: perfectSize(14),
      marginRight: perfectSize(4),
    },
    joinButton: {},
    leftShadow: {
      width: perfectSize(1),
      height: '80%',
    },
  });
