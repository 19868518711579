import React, { Component } from 'react';
import { View } from 'react-native';
import { compose } from 'redux';
import { connect } from 'react-redux';
import I18n from '../../i18n';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';

// import components
import MyTabBar from '../../components/custom-top-tab';
import YourClans from './yourClans';
import RecentClans from './recentClans';
import VerifiedClans from './verifiedClans';

// import styles
import styles from './styles';
import AppStyles from '../../constants/styles';

// import actions
import { switchTheme } from '../../redux/modules/app/actions';
import { useIsFocused } from '@react-navigation/native';

const Tab = createMaterialTopTabNavigator();

class TeamsPage extends Component {
  componentDidMount() {
    this.focusListener$ = this.props.navigation.addListener(
      'focus',
      (payload) => {
        // Log the page view
        global.featuresLogger.LOG_TEAMS_TEAM_TEAM_USER_TEAMS_PAGE_VIEW();

        // Log Rank HP
        global.rankLogger.LOG_NEW_HP(
          global.rankLogger.VIEW_TEAMS_PAGE,
          'VIEW_TEAMS_PAGE',
        );

        // Log the AD view
        global.featuresLogger.CLANS_AD_PAGE_VIEW();
      },
    );
  }

  componentWillUnmount() {
    if (this.focusListener$) {
      this.focusListener$();
    }
  }

  render() {
    const { theme, isFocused } = this.props;
    const _styles = styles(theme);
    if (isFocused) {
      return (
        <View style={_styles.container}>
          <View style={_styles.content}>
            <Tab.Navigator
              lazy={true}
              sceneContainerStyle={AppStyles.transparent}
              tabBar={(props) => (
                <MyTabBar
                  {...props}
                  tabBarStyle={_styles.tabbar}
                  lang={this.props.lang}
                />
              )}>
              <Tab.Screen
                name="Your"
                component={YourClans}
                options={{ tabBarLabel: I18n.t('TEAMS_PAGE.switcher_teams') }}
              />
              <Tab.Screen
                name="Recent"
                component={RecentClans}
                options={{ tabBarLabel: I18n.t('TEAMS_PAGE.switcher_recent') }}
              />
              <Tab.Screen
                name="Verified"
                component={VerifiedClans}
                options={{ tabBarLabel: I18n.t('TEAMS_PAGE.switcher_verified') }}
              />
            </Tab.Navigator>
          </View>
        </View>
      );
    }
    return <View />;
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
    lang: state.appReducer.currentLang,
  };
};

function focusComponent(props) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const isFocused = useIsFocused();
  return <TeamsPage {...props} isFocused={isFocused} />;
}

export default compose(connect(mapStateToProps, { switchTheme }))(focusComponent);
