/* eslint-disable react-native/no-inline-styles */
import React, { memo, Component } from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { LinearGradient } from 'expo-linear-gradient';
import GameManager from '../../../services/gameManager';

import OverlayImage from '../../overlay-image';
import Tag from '../../buttons/tag';
import NormalButton from '../../buttons/normal-button';
import Label from '../../label';
import UserAvatar from '../../user-avatar';

import Feather from 'react-native-vector-icons/Feather';
import firebase from 'firebase/app';
import 'firebase/database';

import AppStyles from '../../../constants/styles';
import { perfectSize } from '../../../helpers/deviceHelper';
import {
  getHumantime,
  getColorTimestamp,
  getWidthTimestamp,
} from '../../../services/utils';
import * as FirebasePaths from '../../../constants/firebasePaths';
import { getFlag } from '../../../services/utils';
import * as Images from '../../../constants/images';

import I18n from '../../../i18n';
import NavigationService from '../../../navigation/navigationService';
import Lobbycore from '../../../pages/lobby/lobbycore';
import ImageWithPlaceholder from '../../image-with-placeholder';
import LocalImageOpacity from '../../local-image-opacity';

class RequestCard extends Component {
  constructor(props) {
    super(props);
    this.mount = true;
    this.state = {
      userPhoto: null,
      rank: -1,
    };
  }

  componentDidMount() {
    if (this.props.request !== undefined) {
      if (this.mount) {
        this.setState({
          playersNumber: this.props.request.playersNumber,
          currentPlayersNumber: this.props.request.currentPlayersNumber,
        });
      }

      let ref =
        FirebasePaths.FIREBASE_USERS_INFO_ATTR +
        '/' +
        this.props.request.admin +
        '/' +
        FirebasePaths.FIREBASE_DETAILS_ATTR;

      firebase.database()
        .ref(ref)
        .once('value')
        .then((snapshot) => {
          const shot = snapshot.val();
          let photoUrl = shot._picUrl_;
          let rank = 0;
          if (shot._rank_) {
            rank = shot._rank_;
          }

          if (!photoUrl || photoUrl === 'NULL' || photoUrl === 'default') {
            photoUrl =
              'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/teams%20default%20photos%20and%20background%2Fdefault_team_logov2.png?alt=media&token=b88bf926-35b9-4568-a80e-ee24110aceb6';
          }
          if (this.mount) {
            this.setState({
              userPhoto: photoUrl,
              rank: rank,
            });
          }
        });

      this.getProfileBorderColor();

      Lobbycore.loadLobby(
        this.props.request.requestId,
        this.props.request.platform,
        this.props.request.gameId,
        this.props.request.region,
      ).then((response) => {
        let request = response.val();
        if (this.mount) {
          this.setState({
            playersNumber: request.playersNumber,
            currentPlayersNumber: request.currentPlayersNumber,
          });
        }
      });
    }
  }

  componentWillUnmount() {
    this.mount = false;
  }

  getProfileBorderColor() {
    let cosmeticsPath =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      this.props.request.admin +
      '/' +
      FirebasePaths.FIREBASE_COSMETICS_ATTR +
      '/' +
      FirebasePaths.FIREBASE_PROFILE_BORDER_ATTR;
    firebase.database()
      .ref(cosmeticsPath)
      .once('value')
      .then((snapshot) => {
        let borderColor = '#880e4a';
        snapshot.forEach((item) => {
          if (item.key === FirebasePaths.FIREBASE_COLOR_ATTR) {
            borderColor = item.val();
          }
        });
        if (this.mount) {
          this.setState({ borderColor: borderColor });
        }
      });
  }

  render() {
    const { theme, request } = this.props;
    const _styles = styles(theme);

    let compLabel = '';
    if (request !== undefined) {
      compLabel = 'All Matches';
      if (request.matchType === 'Competitive') {
        compLabel = 'Comp';
      } else if (request.matchType === 'Quick Match') {
        compLabel = 'Quick';
      }
    }
    return (
      <>
        {request !== undefined && (
          <View style={[_styles.container, this.props.customStyles]}>
            <View style={_styles.header}>
              <OverlayImage
                uri={
                  this.props.gamesList[this.props.request.gameId] !== undefined
                    ? this.props.gamesList[this.props.request.gameId]
                      .gameLargePhotoUrl
                      ? this.props.gamesList[this.props.request.gameId]
                        .gameLargePhotoUrl
                      : this.props.gamesList[this.props.request.gameId]
                        .gamePhotoUrl
                    : ''
                }
                style={_styles.fullSize}
              />
              <LinearGradient
                colors={['#00000033', '#00000033']}
                style={_styles.gradient}
              />
              <View
                style={[
                  _styles.headerDivider,
                  {
                    backgroundColor: getColorTimestamp(request.timeStamp),
                    width: getWidthTimestamp(request.timeStamp) + '%',
                  },
                ]}
              />
              <View style={_styles.userWrap}>
                <View style={_styles.flexRow}>
                  <TouchableOpacity>
                    <UserAvatar
                      src={this.state.userPhoto}
                      size={perfectSize(32)}
                      borderColor={this.state.borderColor}
                      style={{ marginRight: perfectSize(8) }}
                      uid={this.props.request.admin}
                    />
                  </TouchableOpacity>
                  <View style={{ justifyContent: 'center' }}>
                    <View style={[_styles.flexRow]}>
                      <Label size="x_small">{request.adminName}</Label>
                      {this.state.rank >= 0 && (
                        <ImageWithPlaceholder
                          uri={global.rankLogger.getRankImg(this.state.rank)}
                          style={global.rankLogger.getRankStyle(
                            this.state.rank,
                          )}
                          placeholder={Images.PLACEHOLDER_AVATAR}
                          resizeMode="contain"
                        />
                      )}
                    </View>

                    {request.timeStamp && (
                      <Label size="small" style={_styles.grayLabel}>
                        {getHumantime(request.timeStamp)}
                      </Label>
                    )}
                  </View>
                </View>
              </View>
            </View>
            <View style={_styles.infoWrap}>
              <Label size="small" style={_styles.grayLabel}>
                {GameManager.getGameById(request.gameId)?.gameName}
              </Label>
              <View
                style={[
                  _styles.flexRow,
                  _styles.alignCenter,
                  { marginTop: perfectSize(4) },
                ]}>
                {request.region && (
                  <LocalImageOpacity
                    source={getFlag(request.region)}
                    style={_styles.countryImage}
                    resizeMode="contain"
                  />
                )}
                <Label
                  style={[
                    _styles.grayLabel,
                    { marginLeft: perfectSize(4), fontSize: perfectSize(10) },
                  ]}>
                  {request.region}
                </Label>
              </View>
              <View style={[_styles.flexRow, { marginTop: perfectSize(4) }]}>
                {request.platform === 'PS' && (
                  <LocalImageOpacity
                    source={Images.BTN_TAG_PLAYSTATION_SELECTED}
                    style={_styles.btn_tag}
                    resizeMode="contain"
                  />
                )}
                {request.platform === 'PC' && (
                  <LocalImageOpacity
                    source={Images.BTN_TAG_DESKTOP_SELECTED}
                    style={_styles.btn_tag}
                    resizeMode="contain"
                  />
                )}
                {request.platform === 'XBOX' && (
                  <LocalImageOpacity
                    source={Images.BTN_TAG_BALL_SELECTED}
                    style={_styles.btn_tag}
                    resizeMode="contain"
                  />
                )}
                {request.platform === 'MOBILE' && (
                  <LocalImageOpacity
                    source={Images.BTN_TAG_MOBILE_SELECTED}
                    style={_styles.btn_tag}
                    resizeMode="contain"
                  />
                )}
                {request.platform === 'NINTENDO' && (
                  <LocalImageOpacity
                    source={Images.BTN_TAG_DOUBLE_SELECTED}
                    style={_styles.btn_tag}
                    resizeMode="contain"
                  />
                )}
                <Tag
                  title={compLabel}
                  customStyles={{ marginRight: perfectSize(5) }}
                />
              </View>
              <Label size="x_small" style={{ marginVertical: perfectSize(8) }}>
                {request.description}
              </Label>
              {this.props.showJoin && (
                <>
                  <View style={_styles.seperator} />
                  <View style={_styles.footerWrap}>
                    <View style={[_styles.flexRow, { alignItems: 'center' }]}>
                      <Feather
                        name="users"
                        color="gray"
                        size={perfectSize(17)}
                      />

                      <Label
                        size="small"
                        style={[
                          _styles.grayLabel,
                          { marginLeft: perfectSize(4) },
                        ]}>
                        {`${this.state.currentPlayersNumber}/${this.state.playersNumber}`}
                      </Label>
                    </View>
                    <View style={_styles.alignCenter}>
                      <NormalButton
                        text="Join"
                        onPress={() => {
                          NavigationService.moveToScreenInStack(
                            'HomeStack',
                            'LobbyPage',
                            {
                              lastReq: request,
                            },
                          );
                        }}
                      />
                    </View>
                  </View>
                </>
              )}
              {this.props.showPost && (
                <>
                  <View style={_styles.seperator} />
                  <View style={_styles.footerWrap}>
                    <View style={[_styles.flexRow, { alignItems: 'center' }]}>
                      <Feather
                        name="users"
                        color="gray"
                        size={perfectSize(17)}
                      />

                      <Label
                        size="small"
                        style={[
                          _styles.grayLabel,
                          { marginLeft: perfectSize(4) },
                        ]}>
                        {`${request.currentPlayersNumber}/${request.playersNumber}`}
                      </Label>
                    </View>
                    <View style={_styles.alignCenter}>
                      <NormalButton
                        text={I18n.t('NEW_REQUEST_PAGE.post')}
                        onPress={() => {
                          this.props.onPost && this.props.onPost(request);
                        }}
                      />
                    </View>
                  </View>
                </>
              )}
              {!this.props.hideDropDown && (
                <TouchableOpacity
                  style={_styles.actionMenu}
                  onPress={() => {
                    this.props.onMenu && this.props.onMenu();
                  }}>
                  <View style={_styles.downContainer}>
                    <Feather
                      name="chevron-down"
                      color="gray"
                      size={perfectSize(20)}
                    />
                  </View>
                </TouchableOpacity>
              )}
            </View>
          </View>
        )}
      </>
    );
  }
}

RequestCard.propTypes = {
  title: PropTypes.string,
  country: PropTypes.string,
  onPress: PropTypes.func,
  userName: PropTypes.string,
  description: PropTypes.string,
  timeAgo: PropTypes.number,
  originalImage: PropTypes.any,
  isCreate: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
    gamesList: state.hubReducer.gamesList,
  };
};

export default memo(connect(mapStateToProps)(RequestCard));

const styles = (theme) =>
  StyleSheet.create({
    container: {
      borderRadius: perfectSize(12),
      overflow: 'hidden',
      backgroundColor: theme.secondary_btn_background,
    },
    header: {
      width: '100%',
      height: perfectSize(80),
    },
    fullSize: {
      width: '100%',
      height: '100%',
    },
    headerDivider: {
      width: '60%',
      height: 2,
      backgroundColor: theme.request_seperator,
    },
    gradient: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      height: perfectSize(79),
    },
    userWrap: {
      position: 'absolute',
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
      paddingHorizontal: perfectSize(15),
      top: perfectSize(25),
    },
    flexRow: {
      flexDirection: 'row',
    },
    grayLabel: {
      opacity: 0.56,
    },
    infoWrap: {
      width: '100%',
      paddingVertical: perfectSize(16),
      paddingHorizontal: perfectSize(12),
    },
    alignCenter: {
      alignItems: 'center',
    },
    countryImage: {
      resizeMode: 'contain',
      width: perfectSize(12),
      height: perfectSize(12),
    },
    imgUserGroup: {
      resizeMode: 'contain',
      width: perfectSize(16),
      height: perfectSize(16),
    },
    seperator: {
      width: '100%',
      height: 1,
      backgroundColor: theme.primary_text,
      opacity: 0.1,
    },
    footerWrap: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginTop: perfectSize(12),
    },
    btn_tag: {
      width: perfectSize(40),
      height: perfectSize(20),
      marginRight: perfectSize(4),
      marginTop: perfectSize(5),
    },

    actionMenu: {
      position: 'absolute',
      top: perfectSize(16),
      right: perfectSize(16),
    },
    arrowDown: {
      width: perfectSize(12.05),
      height: perfectSize(6),
      resizeMode: 'contain',
    },
    downContainer: {
      width: 40,
      height: 40,
      alignItems: 'flex-end',
    },
  });
