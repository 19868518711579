/* eslint-disable no-unused-vars */
import React, { memo, Component } from 'react';
import { View, StyleSheet } from 'react-native';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { perfectSize } from '../../helpers/deviceHelper';
import { Placeholder, Progressive } from 'rn-placeholder';
import SkeletonUserAvatar from '../skeletonWrapper/skeletonUserAvatar';
import SkeletonTextContent from '../skeletonWrapper/skeletonTextContent';


class HopReplySkeleton extends Component {
    render() {
        const { theme } = this.props;
        const _styles = styles(theme);
        return (
            <Placeholder
                Animation={Progressive}
            >
                <View style={_styles.container}>
                    <View style={_styles.body}>
                        <SkeletonUserAvatar size={32} />
                        <View style={_styles.wrapper}>
                            <SkeletonTextContent height={12} width={40} style={{ marginBottom: 5 }} />
                            <View style={_styles.designation}>
                                <SkeletonTextContent height={12} width={30} />
                            </View>
                        </View>
                    </View>
                    <SkeletonTextContent height={30} style={{ marginTop: 10 }} />
                </View>
            </Placeholder>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        theme: state.appReducer.currentTheme,
    };
};

export default memo(connect(mapStateToProps)(HopReplySkeleton));

const styles = (theme) =>
    StyleSheet.create({
        container: {
            marginTop: 10,
            flexDirection: 'column',
        },
        body: {
            paddingVertical: 9,
            flexDirection: 'row',
        },
        wrapper: {
            marginLeft: 8,
            flex: 1,
            justifyContent: 'center',
        },
        designation: {
            flexDirection: 'row',
            marginTop: 4,
            justifyContent: 'space-between'
        },
        message: {
            opacity: 0.56,
            flex: 1,
        },
        timeline: {
            width: 80,
            color: '#95979A',
            fontWeight: '300',
            textAlign: 'right',
        },
        divider: {
            height: 1,
            backgroundColor: '#FFFFFF',
            opacity: 0.05,
        },
    });
