import {StyleSheet} from 'react-native';
import Dimens from '../../constants/dimens';
import {perfectSize} from '../../helpers/deviceHelper';

const styles = (theme) =>
  StyleSheet.create({
    container: {
      flex: 1,
    },
    // Header
    header: {
      width: '100%',
      height: 270,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#181F29',
    },
    cover: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
    backArrow: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: Dimens.BAR_BUTTON_SIZE,
      height: Dimens.BAR_BUTTON_SIZE,
      justifyContent: 'center',
      alignItems: 'center',
      margin: perfectSize(8),
      zIndex: 999,
    },
    headerWrapper: {
      justifyContent: 'center',
      alignItems: 'center',
    },
    logo: {
      width: 50,
      height: 50,
      borderRadius: 25,
      borderWidth: 1,
      borderColor: 'white',
      resizeMode: 'cover',
    },
    appName: {
      marginTop: perfectSize(4),
    },
    gameName: {
      marginTop: perfectSize(16),
    },
    streamButton: {
      minWidth: perfectSize(100),
      marginTop: perfectSize(8),
      paddingVertical: perfectSize(4),
      paddingHorizontal: perfectSize(12),
      borderWidth: 1,
      borderColor: 'white',
      borderRadius: 30,
    },
    streamLabel: {
      fontSize: perfectSize(10),
      alignSelf: 'center',
    },
    // Content
    content: {
      marginTop: -Dimens.BODY_BORDER_TOP_RADIUS,
    },
    tabbar: {
      justifyContent: 'space-between',
    },
    tabContent: {
      flex: 1,
    },
  });

export default styles;
