import React, { Component } from 'react';
import { View, TouchableOpacity, FlatList } from 'react-native';
import { compose } from 'redux';
import { connect } from 'react-redux';
import I18n from '../../i18n';
import firebase from 'firebase/app';
import 'firebase/database';

import * as FirebasePaths from '../../constants/firebasePaths';
import { store } from '../../redux/store';
import NavigationService from '../../navigation/navigationService';

// import components
import Header from '../../components/header';
import Label from '../../components/label';
import RateCard from '../../components/cards/rate-card';
import PrimaryButton from '../../components/buttons/primary-button';

// import styles
import AppStyles from '../../constants/styles';
import styles from './styles';

import Lobbycore from '../lobby/lobbycore';
import RatingPlayer from '../../models/ratingPlayer';

// import actions
import { switchTheme } from '../../redux/modules/app/actions';
import { set as setHub } from '../../redux/modules/hub/actions';

import { STAR_DARK_GIF, STAR_GIF } from '../../constants/images';
import { presentToast } from '../../helpers/toastHelper';
import { clearFastImageCache } from '../../services/utils';

class GroupRatingsPage extends Component {
  constructor(props) {
    super(props);
    const params = props.navigation.state.params;
    this.state = {
      reqRef: params.chatRef,
      joinedPlayers: new Map(),
      joinedPlayersArr: [],
      adminRatingModel: new RatingPlayer(),
      ratedUsers: new Map(),
    };
  }

  componentDidMount() {
    // Log Rate Page View
    global.featuresLogger.LOG_RATE_VIEW();

    this.loadRatesTo();

    // Get the chat ref to load lobby
    let chatRef = this.props.allGroupChatRefs.get(this.state.reqRef);

    if (chatRef != null) {
      // Call the loadLobbyCore function to load the players keys
      Lobbycore.loadLobby(
        chatRef.chatRef,
        chatRef.platform,
        chatRef.gameId,
        chatRef.region,
      )
        .then((shot) => {
          let loadedReq = shot.val();

          // Fill adming data first
          let adminRatingModel = new RatingPlayer();
          adminRatingModel.uid = loadedReq.admin;
          adminRatingModel.username = loadedReq.adminName;
          this.setState({ adminRatingModel });

          // Check if this request has players ! and fill the joinedPlayers list
          if (loadedReq.players != null) {
            let playersJson = loadedReq.players;

            let joinedPlayers = new Map();
            // filling the hub
            for (var key in playersJson) {
              if (playersJson.hasOwnProperty(key)) {
                let player = new RatingPlayer();
                player.username = playersJson[key].username;
                player.uid = playersJson[key].uid;
                player.photoUrl = playersJson[key].photoUrl;
                player.gameProvider = playersJson[key].gameProvider;

                if (loadedReq.platform === 'PS') {
                  player.PSN_account = playersJson[key].PSN_account;
                } else if (loadedReq.platform === 'XBOX') {
                  player.XBOX_live_account = playersJson[key].XBOX_live_account;
                } else {
                  // We used those square brackets because we don't know which variable is the pc provider
                  player.pcGameProvider = playersJson[key][player.gameProvider];
                }
                joinedPlayers.set(key, player);
              }
            }
            this.setState({ joinedPlayers });
          }
        })
        .then(() => {
          // Load  players  info : I know this function should be declared in the core
          this.loadAdminInfo();
        });
    }
  }

  componentWillUnmount() {
    if (this.ratesToRef$) {
      this.ratesToRef$.off();
      this.ratesToRef$ = null;
    }
  }

  // This function will load all rated users : TO
  loadRatesTo() {
    let ref =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      this.props.uid +
      '/' +
      FirebasePaths.FIREBASE_USER_RATINGS +
      '/' +
      FirebasePaths.FIREBASE_USER_RATING_TO;
    this.ratesToRef$ = firebase.database().ref(ref);
    this.ratesToRef$.on('value', (rates) => {
      let ratedUsers = new Map();
      rates.forEach((rate) => {
        ratedUsers.set(rate.key, rate.val());
      });
      this.setState({ ratedUsers });
    });
  }

  // Getting Animated Gif for the right mode
  getGif() {
    if (this.props.theme.mode === 'dark') {
      return STAR_DARK_GIF;
    } else {
      return STAR_GIF;
    }
  }

  // This function will load Admin info
  loadAdminInfo() {
    let { adminRatingModel, joinedPlayers } = this.state;
    // Load admin photo
    let usersInfoPath =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      adminRatingModel.uid +
      '/' +
      FirebasePaths.FIREBASE_DETAILS_ATTR;
    firebase.database()
      .ref(usersInfoPath)
      .once('value')
      .then((shots) => {
        shots.forEach((shot) => {
          if (shot.key === FirebasePaths.FIREBASE_PICTURE_URL_ATTR) {
            adminRatingModel.photoUrl = shot.val();

            // Set default img if its NULL
            if (adminRatingModel.photoUrl === 'NULL') {
              adminRatingModel.photoUrl =
                'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/teams%20default%20photos%20and%20background%2Fdefault_team_logov2.png?alt=media&token=b88bf926-35b9-4568-a80e-ee24110aceb6';
            }
          }
        });

        // Now add this player to the group map and arr
        joinedPlayers.set(adminRatingModel.uid, adminRatingModel);

        this.setState({
          adminRatingModel,
          joinedPlayers,
          joinedPlayersArr: Array.from(joinedPlayers.values()),
        });
      });
  }

  // This function will submit users ratings
  sibmitRatings = () => {
    // Check if the user at least rated one user and filter ratings
    let foundRatings = false;
    let ratedUsers = new Map();
    this.state.joinedPlayersArr.forEach((player) => {
      if (player.rated) {
        foundRatings = true;
        ratedUsers.set(player.uid, player);
      }
    });

    // Check now
    if (foundRatings && ratedUsers.size > 0) {
      // Log Rated
      global.featuresLogger.LOG_RATE_RATED();

      // Log Rank HP
      global.rankLogger.LOG_NEW_HP(global.rankLogger.RATE_USERS, 'RATE_USERS');

      // Submit Ratings
      ratedUsers.forEach((player) => {
        // Upload rates to other users
        this.uploadRatesToUsers(player.uid, player.rating);

        // Keep the record so the user can't rate again and again
        this.uploadRatedUsers(player.uid, player.rating);
      });

      // Show a success message and pop this page
      presentToast({ message: I18n.t('GROUP_RATING_PAGE.success_rate_msg') });
      clearFastImageCache();
      NavigationService.goBack();
    } else {
      // Show a toast message
      presentToast({ message: I18n.t('GROUP_RATING_PAGE.no_rates_msg') });
    }
  };

  // This function will set the rate ref  : TO USERS : Set ref to rated users
  uploadRatesToUsers(userId, rateNumber) {
    // Back-End
    let ref =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      userId +
      '/' +
      FirebasePaths.FIREBASE_USER_RATINGS +
      '/' +
      FirebasePaths.FIREBASE_USER_RATING_FROM;
    firebase.database().ref(ref).child(this.props.uid).set(rateNumber);
  }

  // This function will set the rate ref  : RATED USERS
  uploadRatedUsers(userId, rateNumber) {
    // Back-End
    let ref =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      this.props.uid +
      '/' +
      FirebasePaths.FIREBASE_USER_RATINGS +
      '/' +
      FirebasePaths.FIREBASE_USER_RATING_TO;
    firebase.database().ref(ref).child(userId).set(rateNumber);

    // Send a notification for the user
    let notificationId = firebase.database().ref().push().key;
    // Create a notification Obj
    let notificationObj = {
      id: notificationId,
      status: 'UNREAD',
      body:
        this.props.userinformationDB.username +
        ' ' +
        ' قيمك شيك على نجماتك في البروفايل ',
      type: 'RATE',
      TRIGGER: this.props.uid,
      timeStamp: firebase.database.ServerValue.TIMESTAMP,
    };

    let friendNotificationsPath =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      userId +
      '/' +
      FirebasePaths.FIREBASE_USER_NOTIFICATIONS +
      '/' +
      notificationId;
    // For the admin
    firebase.database().ref(friendNotificationsPath).set(notificationObj);
  }

  onRated = (item, rating) => {
    let { joinedPlayers } = this.state;
    let updatedPlayerModel = joinedPlayers.get(item.uid);
    if (updatedPlayerModel != null) {
      updatedPlayerModel.rating = rating;
      updatedPlayerModel.rated = true;
    }
    // Update the map and then the array
    joinedPlayers.set(updatedPlayerModel.uid, updatedPlayerModel);
    this.setState({
      joinedPlayers,
      joinedPlayersArr: Array.from(joinedPlayers.values()),
    });
  };

  render() {
    const { joinedPlayersArr, ratedUsers } = this.state;
    let filteredArr = joinedPlayersArr.filter((item) => {
      return this.props.uid !== item.uid;
    });
    let allRated = true;
    filteredArr.forEach((item) => {
      if (!ratedUsers.has(item.uid)) {
        allRated = false;
      }
    });
    return (
      <View style={AppStyles.rootContainer}>
        {/* Header */}
        <Header
          text={I18n.t('GROUP_RATING_PAGE.title')}
          rightComponent={
            filteredArr.length > 0 && !allRated ? (
              <View style={styles.headerRight}>
                <TouchableOpacity
                  style={styles.actionBtn}
                  onPress={() => this.sibmitRatings()}>
                  <Label style={styles.pinkLabel} size="x_small" bold={true}>
                    {I18n.t('GROUP_RATING_PAGE.submit_btn')}
                  </Label>
                </TouchableOpacity>
              </View>
            ) : null
          }
        />
        {/* Content */}
        <View style={AppStyles.rootContent}>
          {filteredArr.length > 0 ? (
            <FlatList
              data={filteredArr}
              renderItem={({ item, index }) => (
                <RateCard
                  item={item}
                  starCount={ratedUsers.get(item.uid) || 0}
                  disabled={ratedUsers.has(item.uid)}
                  onRate={(rating) => this.onRated(item, rating)}
                />
              )}
              showsVerticalScrollIndicator={false}
              keyExtractor={(item, index) => index.toString()}
              ListFooterComponent={
                <View>
                  {filteredArr.length > 0 && !allRated ? (
                    <PrimaryButton
                      text={I18n.t('GROUP_RATING_PAGE.submit_btn')}
                      onPress={() => this.sibmitRatings()}
                      size="medium"
                      type="filled"
                      customStyles={styles.submitBtn}
                    />
                  ) : null}
                </View>
              }
            />
          ) : (
            <View style={styles.emptyContent}>
              <View style={AppStyles.emptyContent}>
                {/* <FastImage
                  source={this.getGif()}
                  style={AppStyles.emptyContentGif}
                  resizeMode="contain"
                /> */}
                <Label style={AppStyles.emptyContentLabel}>
                  {I18n.t('GROUP_RATING_PAGE.no_on_to_rate_msg')}
                </Label>
              </View>
            </View>
          )}
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
    uid: state.authReducer.uid,
    userinformationDB: state.authReducer.userinformationDB,
    allGroupChatRefs: state.hubReducer.allGroupChatRefs,
  };
};

export default compose(connect(mapStateToProps, { switchTheme, setHub }))(
  GroupRatingsPage,
);
