import * as FirebasePaths from '../constants/firebasePaths';
import { store } from '../redux/store';
import firebase from 'firebase/app';
import 'firebase/database';

export default class FeaturesLogger {
  constructor() { }

  // This function will log (Clicking on the find hoplay match after validating the inputs)
  LOG_FOUND_HOPLAY_MATCH() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      // Feateues logger => Hoplay Match -> FOUND MATCH -> date -> uid : username
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_HOPLAY_MATCH +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_HOPLAY_MATCH_FOUND_MATCH;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // This function will log (clicking on chat button in hoplay match after finding someone to play with )
  LOG_CHAT_HOPLAY_MATCH() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      // Feateues logger => Hoplay Match -> FOUND MATCH -> date -> uid : username
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_HOPLAY_MATCH +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_HOPLAY_MATCH_CHAT;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // Hops Send Log
  LOG_HOP_SEND() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      // Feateues logger => HOPS -> SEND HOP -> date -> uid : username
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_HOPS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_HOPS_SEND;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // Hops Reply Log
  LOG_HOP_REPLY() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      // Feateues logger => HOPS -> REPLY HOP -> date -> uid : username
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_HOPS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_HOPS_REPLY;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // Hops viewed Log
  LOG_HOP_VIEWED() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      // Feateues logger => HOPS -> REPLY HOP -> date -> uid : username
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_HOPS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_HOPS_VIEWED;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // Request View Log
  LOG_REQUEST_VIEW() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      // Feateues logger => REQUEST -> VIEW GROUP -> date -> uid : username
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_REQUEST +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_REQUEST_VIEW_GROUP;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // Request JOIN Log
  LOG_REQUEST_JOIN() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      // Feateues logger => REQUEST -> VIEW GROUP -> date -> uid : username
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_REQUEST +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_REQUEST_JOIN_GROUP;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // Rate Page View
  LOG_RATE_VIEW() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      // Feateues logger => RATE -> Page View -> date -> uid : username
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_RATE +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_RATE_VIEW_PAGE;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // Rate Page View
  LOG_RATE_RATED() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      // Feateues logger => RATE -> Rated -> date -> uid : username
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_RATE +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_RATE_RATED;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // DONT FORGET WEB !

  // New Request
  LOG_NEW_REQUEST() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      // Feateues logger => REQUEST -> NEW REQUEST -> date -> uid : username
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_REQUEST +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_REQUEST_NEW_REQUEST;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // SHARE APP
  LOG_SHARE_APP() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      // Feateues logger => SHARE_APP -> date -> uid : username
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_SHARE_APP;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  LOG_SHARE_HOP() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      // Feateues logger => SHARE_POST -> date -> uid : username
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_SHARE_HOP;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  LOG_SHARE_REQUEST() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      // Feateues logger => SHARE_REQUEST -> date -> uid : username
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_SHARE_REQUEST;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  LOG_SHARE_CLAN() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      // Feateues logger => SHARE_CLAN -> date -> uid : username
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_SHARE_CLAN;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // // USER LOGGED IN
  LOG_USER_LOGGED_IN() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      // Feateues logger => _USER_LOGGED_IN_ -> date -> uid : username
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_USER_LOGGED;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  //
  // Update Profile
  LOG_UPDATE_PROFILE() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      // Feateues logger => PROFILE -> UPDATE PROFILE-> date -> uid : username
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_PROFILE +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_PROFILE_PROFILE_UPDATED;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // View User Profile
  LOG_VIEW_USER_PROFILE() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      // Feateues logger => FRIEND -> VIEW FRIEND -> date -> uid : username
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_FRIEND +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_FRIEND_VIEW_PROFILE;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // View User ADD
  LOG_VIEW_USER_ADD() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      // Feateues logger => FRIEND -> ADD FRIEND -> date -> uid : username
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_FRIEND +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_FRIEND_ADD;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // View User DELETE
  LOG_VIEW_USER_DELETE() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      // Feateues logger => FRIEND -> FRIEND DELETE -> date -> uid : username
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_FRIEND +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_FRIEND_DELETE;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // request : Click Search btn
  LOG_SEARCH_REQUEST_BTN_CLICKED() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      // Feateues logger => = REQUEST  -> SEARCH -> date -> uid : username
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_REQUEST +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_SEARCH_REQUEST_BTN;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  //  request : VIEW RESULT
  LOG_SEARCH_REQUEST_VIEW_RESULT() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      // Feateues logger =>  REQUEST  -> VIEW RESULT -> date -> uid : username
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_REQUEST +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_SEARCH_REQUEST_VIEW_RESULT;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // request : Exit Group
  LOG_REQUEST_EXIT() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      // Feateues logger =>  REQUEST  -> EXIT Group -> date -> uid : username
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_REQUEST +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_REQUEST_EXIT_GROUP;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // Feedbacks : Support
  LOG_FEEDBACKS_SUPPORT() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      // Feateues logger =>  FEEDBACKS  ->  SUPPORT -> date -> uid : username
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_FEEDBACKS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_FEEDBACKS_SUPPORT;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  LOG_FEEDBACKS_EMAIL() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      // Feateues logger =>  FEEDBACKS  ->  EMAIL -> date -> uid : username
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_FEEDBACKS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_FEEDBACKS_EMAIL;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  LOG_FEEDBACKS_WHATSAPP() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      // Feateues logger =>  FEEDBACKS  ->  CALL -> date -> uid : username
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_FEEDBACKS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_FEEDBACKS_WHATSAPP;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // Feedbacks : Request Report
  LOG_FEEDBACKS_REQUEST_REPORT() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      // Feateues logger =>  FEEDBACKS  ->  Request Report -> date -> uid : username
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_FEEDBACKS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_FEEDBACKS_REQUEST_REPORT;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // Feedbacks : Hop Report
  LOG_FEEDBACKS_HOP_REPORT() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      // Feateues logger =>  FEEDBACKS  ->  Hop Report -> date -> uid : username
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_FEEDBACKS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_FEEDBACKS_HOP_REPORT;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // Logout
  LOG_LOGOUT() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      // Feateues logger => SHARE_APP -> date -> uid : username
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_LOGOUT;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // NOTIFICATION  :  PAGE VIEW
  LOG_NOTIFICATIONS_PAGE_VIEW() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      // Feateues logger =>  NOTIFICATION  ->  PAGE VIEW -> date -> uid : username
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_NOTIFICATION +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_NOTIFICATION_VIEW_PAGE;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // NOTIFICATION  :  ACTIVATE NOTIFICATION
  LOG_NOTIFICATIONS_ACTIVATE_NOTIFICATION() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      // Feateues logger =>  NOTIFICATION  ->  ACTIVATE NOTIFICATION  -> date -> uid : username
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_NOTIFICATION +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_NOTIFICATION_ACTIVATE_NOTIFICATION;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // NOTIFICATION  :  DEACTIVATE NOTIFICATION
  LOG_NOTIFICATIONS_DEACTIVATE_NOTIFICATION() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      // Feateues logger =>  NOTIFICATION  ->  DEACTIVATE NOTIFICATION  -> date -> uid : username
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_NOTIFICATION +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_NOTIFICATION_DEACTIVATE_NOTIFICATION;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // NOTIFICATION  :  User notifications
  LOG_NOTIFICATIONS_USER_NOTIFICATIONS_PAGE_VIEW() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_NOTIFICATION +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_NOTIFICATION_USER_NOTIFICATIONS_PAGE;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // NOTIFICATION  :  User notifications
  LOG_NOTIFICATIONS_USER_NOTIFICATIONS_CLICKS() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_NOTIFICATION +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_NOTIFICATION_USER_NOTIFICATIONS_CLICKS;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // request : DELETE SAVED REQUEST
  LOG_REQUEST_DELETE_SAVED_REQUEST() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      // Feateues logger => = REQUEST  -> DELETE SAVED REQUEST -> date -> uid : username
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_REQUEST +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_REQUEST_DELETE_SAVED_REQUEST;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // request : UPDATE SAVED REQUEST
  LOG_REQUEST_UPDATE_SAVED_REQUEST() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      // Feateues logger => = REQUEST  -> UPDATE SAVED REQUEST -> date -> uid : username
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_REQUEST +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_REQUEST_UPDATE_REQUEST;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // SLIDE CLICK
  LOG_SLIDE_CLICK() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      // Feateues logger => SLIDE CLICK -> date -> uid : username
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_SLIDE_CLICK;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // request : CHECK REQ CLICK
  LOG_REQUEST_CHECK_REQUEST_CLICK() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      // Feateues logger => = REQUEST  -> CHECK REQUEST CLICK -> date -> uid : username
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_REQUEST +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_REQUEST_CHECK_REQUEST_CLICK;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // Ranks Timeline View
  LOG_RANKS_TIMELINE_VIEW() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      // Feateues logger => RANKS TIMELINE VIEW -> date -> uid : username
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_RANKS_TIMELINE_VIEW;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // request : VIEW REQUEST HUB
  LOG_REQUEST_VIEW_REQUEST_HUB() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      // Feateues logger => = REQUEST  -> VIEW REQUEST HUB -> date -> uid : username
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_REQUEST +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_REQUEST_VIEW_REQUEST_HUB;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // Chat : private chat opened
  LOG_CHAT_PRIVATE_OPENED() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      // Feateues logger => = CHAT  -> private opened -> date -> uid : username
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_CHAT +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_CHAT_PRIVATE_OPEN;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // Chat : GROUP chat opened
  LOG_CHAT_GROUP_OPENED() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      // Feateues logger => = CHAT  -> GROUP opened -> date -> uid : username
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_CHAT +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_CHAT_GROUP_OPEN;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // friend : Click on new private chat
  LOG_NEW_PRIVATE_CHAT() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      // Feateues logger => FRIEND -> NEW PRIVATE CHAT -> date -> uid : username
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_FRIEND +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_FRIEND_CLICK_ON_NEW_CHAT;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // games : VIEW PAGE
  LOG_GAMES_VIEW_PAGE() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      // Feateues logger => GAMES -> VIEW PAGE -> date -> uid : username
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_GAMES +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_GAMES_VIEW_PAGE;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // games : ADD GAME
  LOG_GAMES_ADD_GAME() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      // Feateues logger => GAMES -> ADD GAME-> date -> uid : username
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_GAMES +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_GAMES_ADD;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // games : DELETE GAME
  LOG_GAMES_DELETE_GAME() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      // Feateues logger => GAMES -> DELETE GAME-> date -> uid : username
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_GAMES +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_GAMES_DELETE;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // teams : LOGGER   : Done
  LOG_TEAMS_TEAM_PAGE_VIEW() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_TEAMS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_TEAMS_TEAM_PAGE_VIEW;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // teams : LOGGER  :DONE
  LOG_TEAMS_TEAM_TEAM_NOTIFICATION_PAGE_VIEW() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_TEAMS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_TEAMS_TEAM_NOTIFICATION_PAGE_VIEW;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // teams : LOGGER   :  DONE
  LOG_TEAMS_TEAM_TEAM_USER_TEAMS_PAGE_VIEW() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_TEAMS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_TEAMS_USER_TEAMS_PAGE_VIEW;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // teams : LOGGER  : DONE
  LOG_TEAMS_TEAM_CREATE_TEAM_PAGE_VIEW() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_TEAMS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_TEAMS_CREATE_TEAM_PAGE_VIEW;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // teams : LOGGER  : DONE
  LOG_TEAMS_TEAM_JOIN_TEAM_PAGE_VIEW() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_TEAMS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_TEAMS_JOIN_TEAM_PAGE_VIEW;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }
  // teams : LOGGER  : Done
  LOG_TEAMS_TEAM_JOIN_REQUESTS_PAGE_VIEW() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_TEAMS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_TEAMS_TEAM_JOIN_REQUESTS_PAGE_VIEW;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // teams : direct join
  LOG_TEAMS_TEAM_DIRECT_JOIN() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_TEAMS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_TEAMS_TEAM_DIRECT_JOIN;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // teams : LOGGER  : DONE
  LOG_TEAMS_USER_ACCOUNTS_PAGE_VIEW() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_TEAMS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_TEAMS_USER_ACCOUNTS_PAGE_VIEW;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // teams : LOGGER  : DONE
  LOG_TEAMS_TEAM_CHAT_PAGE_VIEW() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_TEAMS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_TEAMS_TEAM_CHAT_PAGE_VIEW;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // teams : LOGGER  : DONE
  LOG_TEAMS_TEAMS_CREATED() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_TEAMS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_TEAMS_TEAM_CREATED;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // teams : LOGGER
  LOG_TEAMS_TEAMS_INVITES_SENT() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_TEAMS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_TEAMS_TEAMS_INVITES_SENT;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // teams : LOGGER   : done
  LOG_TEAMS_TEAM_JOIN_REQUEST_SENT() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_TEAMS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_TEAMS_TEAM_JOIN_REQUEST_SENT;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // teams : LOGGER  : DONE
  LOG_TEAMS_TEAM_ACCEPTS() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_TEAMS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_TEAMS_TEAM_ACCEPTS;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // teams : LOGGER  : DONE
  LOG_TEAMS_TEAM_REJECTS() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_TEAMS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_TEAMS_TEAM_REJECTS;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // teams : LOGGER  : DONE
  LOG_TEAMS_TEAM_USERS_ACCEPT() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_TEAMS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_TEAMS_TEAM_USERS_ACCEPT;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // teams : LOGGER  : DONE
  LOG_TEAMS_TEAM_USERS_REJECTS() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_TEAMS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_TEAMS_TEAM_USERS_REJECTS;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // teams : LOGGER  : DONE
  LOG_TEAMS_TEAM_UPLOAD_FEED() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_TEAMS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_TEAMS_TEAM_UPLOAD_FEED;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // teams : LOGGER  : DONE
  LOG_TEAMS_TEAM_EXITS() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_TEAMS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_TEAMS_TEAM_EXITS;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // teams : LOGGER  : DONE
  LOG_TEAMS_VERIFIED_TEAMS_SECTION_VIEW() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_TEAMS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_TEAMS_VERIFIED_TEAMS_SECTION_VIEW;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // teams : LOGGER  : DONE
  LOG_TEAMS_REGISTERED_TEAMS_SECTION_VIEW() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_TEAMS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_TEAMS_REGISTERED_TEAMS_SECTION_VIEW;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // rank : LOGGER  : DONE
  LOG_RANKS_LEADER_BOARD_PAGE_VIEW() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_RANK +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_RANK_LEADERBOARD_PAGE_VIEW;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // Agents : LOGGER  : Done
  LOG_AGENTS_AGENTS_PAGE_VIEW() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_AGENT +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_AGENT_PAGE_VIEW;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // teams : LOGGER  : DONE
  LOG_TEAMS_USER_ACCOUNT_NOTIFIED() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_TEAMS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_TEAMS_USER_ACCOUNT_NOTIFIED;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // news : LOGGER  : done
  LOG_NEWS_PAGE_VIEW() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_NEWS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_NEWS_PAGE_VIEW;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // news : LOGGER  : done
  LOG_NEWS_CLICKS() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_NEWS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_NEWS_NEWS_CLICKS;

      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  LOG_NEWS_SHARE() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_NEWS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_NEWS_NEWS_SHARES;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // Championships
  CHAMPIONSHIPS_PAGE_VIEW() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_CHAMPIONSHIP +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_CHAMPIONSHIPS_PAGE_VIEW;

      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }
  CHAMPIONSHIP_PAGE_VIEW() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_CHAMPIONSHIP +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_CHAMPIONSHIP_PAGE_VIEW;

      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // Championship complete registration
  CHAMPIONSHIP_COMPLETE_REGISTRATION() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_CHAMPIONSHIP +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_CHAMPIONSHIP_COMPLETE_REGISTRATION;

      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // Championship Streaming Click
  CHAMPIONSHIP_STREAMING_CLICK() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_CHAMPIONSHIP +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_CHAMPIONSHIP_STREAMING_CLICK;

      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  CHAMPIONSHIP_REGISTER_CLICKED() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_CHAMPIONSHIP +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_CHAMPIONSHIP_REGISTER_CLICKED;

      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }
  CHAMPIONSHIP_BRACKET_IMG_VIEW() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_CHAMPIONSHIP +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_CHAMPIONSHIP_BRACKET_IMG_VIEW;

      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }
  CHAMPIONSHIP_BRACKETS_PAGE_VIEW() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_CHAMPIONSHIP +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_CHAMPIONSHIP_BRACKETS_PAGE_VIEW;

      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // ADS : HOPS
  HOP_AD_CLICK() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_AD_SPOTS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_ADS_SPOTS_HOPS_CLICK;

      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  HOP_AD_PAGE_VIEW() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_AD_SPOTS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_ADS_SPOTS_HOPS_PAGE_VIEW;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // ADS : CLANS
  CLANS_AD_CLICK() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_AD_SPOTS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_ADS_SPOTS_CLANS_CLICK;

      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  CLANS_AD_PAGE_VIEW() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_AD_SPOTS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_ADS_SPOTS_CLANS_PAGE_VIEW;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // ADS : NEWS
  NEWS_AD_CLICK() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_AD_SPOTS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_ADS_SPOTS_NEWS_CLICK;

      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  NEWS_AD_PAGE_VIEW() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_AD_SPOTS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_ADS_SPOTS_NEWS_PAGE_VIEW;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // ADS : SEARCH REQUEST RESULT
  SEARCH_REQUEST_RESULT_AD_CLICK() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_AD_SPOTS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_ADS_SPOTS_SEARCH_REQUEST_RESULTS_CLICK;

      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  SEARCH_REQUEST_RESULT_AD_PAGE_VIEW() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_AD_SPOTS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_ADS_SPOTS_SEARCH_REQUEST_RESULTS_PAGE_VIEW;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // AD page view : gaming centers .. etc
  AD_PAGE_VIEW() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_AD_PAGE +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_AD_PAGE_VIEW;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }
  AD_LOCATION_CLICKED() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_AD_PAGE +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_AD_LOCATION_CLICKED;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }
  AD_SOCIAL_MEDIA_CLICKED() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_AD_PAGE +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_AD_SOCIAL_MEDIA_CLICKED;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }
  AD_WEBSITE_CLICKED() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_AD_PAGE +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_AD_WEBSITE_CLICKED;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }
  AD_APP_CLICKED() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_AD_PAGE +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_AD_APP_CLICKED;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // Main slide ad click
  MAIN_SLIDE_AD_CLICK() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_AD_SPOTS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_ADS_SPOTS_MAIN_SLIDE_AD_CLICK;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // Main slide ad click
  MAIN_SLIDE_AD_PAGE_VIEW() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_AD_SPOTS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_ADS_SPOTS_MAIN_SLIDE_AD_PAGE_VIEW;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // Hopped Request : Feature usage
  HOPPED_REQUEST_USAGE() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_HOPPED_REQUEST +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_HOPPED_REQUEST_USAGE;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // Hopped Request : Feature view
  HOPPED_REQUEST_VIEWED() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_HOPPED_REQUEST +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_HOPPED_REQUEST_VIEWED;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // Number of users sent photos through chats private/public/clans
  CHATS_PHOTOS() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      // Feateues logger => _CHATS_PHOTOS_ -> date -> uid : username
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_CHATS_PHOTOS;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // Number of users like a hop
  HOP_LIKE() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      // Feateues logger => _hops_vote_system_
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_HOP_VOTE_SYSTEM +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_HOP_VOTE_SYSTEM_HOP_LIKE;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // Number of users like a hop
  HOP_DISLIKE() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      // Feateues logger => _hops_vote_system_
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_HOP_VOTE_SYSTEM +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_HOP_VOTE_SYSTEM_HOP_DISLIKE;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // Reply LIKES
  REPLY_LIKE() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      // Feateues logger => _hops_vote_system_
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_HOP_VOTE_SYSTEM +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_HOP_VOTE_SYSTEM_REPLY_LIKE;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // Reply DISLIKES
  REPLY_DISLIKE() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      // Feateues logger => _hops_vote_system_
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_HOP_VOTE_SYSTEM +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_HOP_VOTE_SYSTEM_REPLY_DISLIKE;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // User cosmetics view shadow color
  USER_COSMETICS_SHADOW_COLOR_PAGE_VIEW() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_USER_COSMETICS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_USER_COSMETICS_SHADOW_COLOR +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_USER_COSMETICS_PROFILE_BORDER_COLOR_PAGE_VIEW;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // User cosmetics chaning shadow color
  USER_COSMETICS_SHADOW_COLOR_UPDATE_PROFILE() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_USER_COSMETICS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_USER_COSMETICS_SHADOW_COLOR +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_USER_COSMETICS_SHADOW_COLOR_UPDATE;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // User cosmetics chaning profile border color
  USER_COSMETICS_PROFILE_BORDER_COLOR_PAGE_VIEW() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_USER_COSMETICS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_USER_COSMETICS_PROFILE_BORDER_COLOR +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_USER_COSMETICS_PROFILE_BORDER_COLOR_PAGE_VIEW;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // Join request alert display
  JOIN_REQUEST_ALERT_PAGE_VIEW() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FB_REQUESTS_JOIN +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_JOIN_REQUEST_ALERT_PAGE_VIEW;

      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // Join request alert click redirect page
  JOIN_REQUEST_ALERT_URL_BTN_CLICK() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FB_REQUESTS_JOIN +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_JOIN_REQUEST_ALERT_URL_BTN_CLICK;

      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // Join request alert click chat redirect
  JOIN_REQUEST_ALERT_CHAT_REDIRECTION() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FB_REQUESTS_JOIN +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_JOIN_REQUEST_ALERT_CHAT_REDIRECTOR_BTN;

      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // championships page ad view
  CHAMPIONSHIPS_AD_PAGE_VIEW() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_AD_SPOTS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_CHAMPIONSHIPS_AD_PAGE_VIEW;

      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // championships page ad click
  CHAMPIONSHIPS_AD_CLICK() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_AD_SPOTS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_CHAMPIONSHIPS_AD_CLICK;

      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // private chat page ad view
  PRIVATE_CHAT_AD_PAGE_VIEW() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_AD_SPOTS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_PRIVATE_CHAT_AD_PAGE_VIEW;

      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // private chat page ad view
  PRIVATE_CHAT_AD_CLICK() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_AD_SPOTS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_PRIVATE_CHAT_AD_CLICK;

      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // public chat ad page view
  PUBLIC_CHAT_AD_PAGE_VIEW() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_AD_SPOTS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_PUBLIC_CHAT_AD_PAGE_VIEW;

      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // public chat ad click
  PUBLIC_CHAT_AD_CLICK() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_AD_SPOTS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_PUBLIC_CHAT_AD_CLICK;

      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // clan chat ad page view
  CLAN_CHAT_AD_PAGE_VIEW() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_AD_SPOTS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_CLAN_CHAT_AD_PAGE_VIEW;

      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // clan chat ad click
  CLAN_CHAT_AD_CLICK() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_AD_SPOTS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_CLAN_CHAT_AD_CLICK;

      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // lobby ad page view
  LOBBY_AD_PAGE_VIEW() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_AD_SPOTS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_LOBBY_AD_PAGE_VIEW;

      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // lobby ad click
  LOBBY_AD_CLICK() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_AD_SPOTS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_LOBBY_AD_CLICK;

      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // found match ad page view
  FOUND_MATCH_AD_PAGE_VIEW() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_AD_SPOTS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_FOUND_MATCH_AD_PAGE_VIEW;

      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // found match ad click
  FOUND_MATCH_AD_CLICK() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_AD_SPOTS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_FOUND_MATCH_AD_CLICK;

      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // hope reply img
  HOP_REPLY_IMG() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_HOPS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_HOP_REPLY_IMG;

      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // hope send img
  HOP_SEND_IMG() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_HOPS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_HOP_SEND_IMG;

      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // User cosmetics chaning profile border color
  USER_COSMETICS_PROFILE_BORDER_COLOR_UPDATE_PROFILE() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_USER_COSMETICS +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_USER_COSMETICS_PROFILE_BORDER_COLOR +
        '/' +
        FirebasePaths.FIREBASE_FEATURE_LOG_USER_COSMETICS_PROFILE_BORDER_COLOR_UPDATE;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // _stickers_/CHAT_STICKERS/{PRIVATE, PUBLIC, CLAN}/{POPOVER_OPEN,STICKER_CLICK}
  CHAT_STICKER_LOGGER(stickerType = 'PRIVATE', loggerType = 'open') {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    const _loggerType =
      loggerType === 'open'
        ? FirebasePaths.FIREBASE_POPOVER_OPEN
        : FirebasePaths.FIREBASE_STICKER_CLICK;
    if (uid != null && uid !== '') {
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_STICKERS_REFERENCE +
        '/' +
        FirebasePaths.FIREBASE_CHAT_STICKERS_FEATURE +
        '/' +
        stickerType + '/' + _loggerType;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // _stickers_/CHAT_STICKERS/{PRIVATE, PUBLIC, CLAN}/{POPOVER_OPEN,STICKER_CLICK}
  HOPS_STICKER_LOGGER(loggerType = 'open') {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    const _loggerType =
      loggerType === 'open'
        ? FirebasePaths.FIREBASE_POPOVER_OPEN
        : FirebasePaths.FIREBASE_STICKER_CLICK;
    if (uid != null && uid !== '') {
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_STICKERS_REFERENCE +
        '/' +
        FirebasePaths.FIREBASE_HOP_STICKERS_FEATURE +
        '/' +
        _loggerType;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // _privacy_/{PAGE_VIEW, RECENT_REQUESTS_ON, RECENT_REQUESTS_OFF ... }
  PRIVACY_LOGGER(privacyType) {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;

    if (uid != null && uid !== '') {
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_USER_PRIVACY_ATTR +
        '/' +
        privacyType;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // _notifications_settings_/{PAGE_VIEW, CLAN_CHAT_ON, CLAN_CHAT_OFF, ... }
  NOTIFICATION_SETTINGS_LOGGER(notificationType) {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;

    if (uid != null && uid !== '') {
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_NOTIFICATIONS_SETTINGS_ATTR +
        '/' +
        notificationType;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  POPUP_VIEW() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;

    if (uid != null && uid !== '') {
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_LAUNCH_POPUP +
        '/' +
        FirebasePaths.FIREBASE_POPUP_VIEW;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  POPUP_PRIMARY_BTN_CLICK() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;

    if (uid != null && uid !== '') {
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_LAUNCH_POPUP +
        '/' +
        FirebasePaths.FIREBASE_POPUP_PRIMARY_BTN_CLICK;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  POPUP_SECONDARY_BTN_CLICK() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;

    if (uid != null && uid !== '') {
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_LAUNCH_POPUP +
        '/' +
        FirebasePaths.FIREBASE_POPUP_SECONDARY_BTN_CLICK;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  POPUP_DISMISS() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;

    if (uid != null && uid !== '') {
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_LAUNCH_POPUP +
        '/' +
        FirebasePaths.FIREBASE_POPUP_DISMISS;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  /**
   * 
   * Hoots system features loggers
   */

   CREATE_HOOT_BTN_CLICK() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      // Feateues logger => hoots system
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_HOOTS +
        '/' +
        FirebasePaths.FIREBASE_CREATE_HOOT_BTN_CLICK;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  CREATE_HOOT_VIEW() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      // Feateues logger => hoots system
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_HOOTS +
        '/' +
        FirebasePaths.FIREBASE_CREATE_HOOT_VIEW;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  CREATE_HOOT_GENRE_CLICK() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      // Feateues logger => hoots system
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_HOOTS +
        '/' +
        FirebasePaths.FIREBASE_CREATE_HOOT_GENRE_CLICK;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  CREATE_HOOT_GENRE_SELECT() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      // Feateues logger => hoots system
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_HOOTS +
        '/' +
        FirebasePaths.FIREBASE_CREATE_HOOT_GENRE_SELECT;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  CREATE_HOOT_COVER_CLICK() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      // Feateues logger => hoots system
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_HOOTS +
        '/' +
        FirebasePaths.FIREBASE_CREATE_HOOT_COVER_CLICK;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  CREATE_HOOT_COVER_SUCCESS() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      // Feateues logger => hoots system
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_HOOTS +
        '/' +
        FirebasePaths.FIREBASE_CREATE_HOOT_COVER_SUCCESS;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  HOOTS_TIMELINE_VIEW() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      // Feateues logger => hoots system
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_HOOTS +
        '/' +
        FirebasePaths.FIREBASE_HOOTS_TIMELINE_VIEW;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  HOOTS_TIMELINE_LIVE_CLICK() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      // Feateues logger => hoots system
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_HOOTS +
        '/' +
        FirebasePaths.FIREBASE_HOOTS_TIMELINE_LIVE_CLICK;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  HOOTS_TIMELINE_RECENT_CLICK() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      // Feateues logger => hoots system
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_HOOTS +
        '/' +
        FirebasePaths.FIREBASE_HOOTS_TIMELINE_RECENT_CLICK;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }


  HOOTS_TIMELINE_LIVE_HOOT_JOIN() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      // Feateues logger => hoots system
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_HOOTS +
        '/' +
        FirebasePaths.FIREBASE_HOOTS_TIMELINE_LIVE_HOOT_JOIN;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  HOOTS_TIMELINE_LIVE_HOOT_JOIN_CONFIRM() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      // Feateues logger => hoots system
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_HOOTS +
        '/' +
        FirebasePaths.FIREBASE_HOOTS_TIMELINE_LIVE_HOOT_JOIN_CONFIRM;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  HOOTS_TIMELINE_LIVE_HOOT_JOIN_DISMISS() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      // Feateues logger => hoots system
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_HOOTS +
        '/' +
        FirebasePaths.FIREBASE_HOOTS_TIMELINE_LIVE_HOOT_JOIN_DISMISS;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  HOOTS_TIMELINE_HOOT_OPTIONS_OPEN() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      // Feateues logger => hoots system
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_HOOTS +
        '/' +
        FirebasePaths.FIREBASE_HOOTS_TIMELINE_HOOT_OPTIONS_OPEN;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }


  HOOTS_TIMELINE_HOOT_OPTIONS_VIEW_PROFILE() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      // Feateues logger => hoots system
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_HOOTS +
        '/' +
        FirebasePaths.FIREBASE_HOOTS_TIMELINE_HOOT_OPTIONS_VIEW_PROFILE;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  HOOTS_TIMELINE_HOOT_OPTIONS_ADD_FRIEND() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      // Feateues logger => hoots system
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_HOOTS +
        '/' +
        FirebasePaths.FIREBASE_HOOTS_TIMELINE_HOOT_OPTIONS_ADD_FRIEND;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  HOOTS_TIMELINE_HOOT_OPTIONS_BLOCK_USER() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      // Feateues logger => hoots system
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_HOOTS +
        '/' +
        FirebasePaths.FIREBASE_HOOTS_TIMELINE_HOOT_OPTIONS_BLOCK_USER;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  HOOTS_TIMELINE_HOOT_OPTIONS_REPORT() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      // Feateues logger => hoots system
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_HOOTS +
        '/' +
        FirebasePaths.FIREBASE_HOOTS_TIMELINE_HOOT_OPTIONS_REPORT;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  HOOTS_TIMELINE_HOOT_OPTIONS_REPORT_CONFIRM() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      // Feateues logger => hoots system
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_HOOTS +
        '/' +
        FirebasePaths.FIREBASE_HOOTS_TIMELINE_HOOT_OPTIONS_REPORT_CONFIRM;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  HOOTS_TIMELINE_LIVE_SEARCH_TRIGGER() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      // Feateues logger => hoots system
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_HOOTS +
        '/' +
        FirebasePaths.FIREBASE_HOOTS_TIMELINE_LIVE_SEARCH_TRIGGER;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  HOOTS_TIMELINE_RECENT_HOOT_JOIN() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      // Feateues logger => hoots system
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_HOOTS +
        '/' +
        FirebasePaths.FIREBASE_HOOTS_TIMELINE_RECENT_HOOT_JOIN;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  HOOTS_TIMELINE_RECENT_HOOT_JOIN_CONFIRM() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      // Feateues logger => hoots system
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_HOOTS +
        '/' +
        FirebasePaths.FIREBASE_HOOTS_TIMELINE_RECENT_HOOT_JOIN_CONFIRM;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  HOOTS_TIMELINE_RECENT_HOOT_JOIN_DISMISS() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      // Feateues logger => hoots system
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_HOOTS +
        '/' +
        FirebasePaths.FIREBASE_HOOTS_TIMELINE_RECENT_HOOT_JOIN_DISMISS;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  HOOTS_TIMELINE_RECENT_HOOT_CALL_AGAIN() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      // Feateues logger => hoots system
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_HOOTS +
        '/' +
        FirebasePaths.FIREBASE_HOOTS_TIMELINE_RECENT_HOOT_CALL_AGAIN;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  HOOT_CALL_VIEW() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      // Feateues logger => hoots system
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_HOOTS +
        '/' +
        FirebasePaths.FIREBASE_HOOT_CALL_VIEW;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  HOOT_CALL_DISCONNECT() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      // Feateues logger => hoots system
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_HOOTS +
        '/' +
        FirebasePaths.FIREBASE_HOOT_CALL_DISCONNECT;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  HOOT_CALL_MUTE() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      // Feateues logger => hoots system
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_HOOTS +
        '/' +
        FirebasePaths.FIREBASE_HOOT_CALL_MUTE;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  HOOT_CALL_DEAFEN() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      // Feateues logger => hoots system
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_HOOTS +
        '/' +
        FirebasePaths.FIREBASE_HOOT_CALL_DEAFEN;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  HOOT_CALL_SPEAKER() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      // Feateues logger => hoots system
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_HOOTS +
        '/' +
        FirebasePaths.FIREBASE_HOOT_CALL_SPEAKER;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  HOOT_CALL_PARTICIPANT_OPTIONS() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      // Feateues logger => hoots system
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_HOOTS +
        '/' +
        FirebasePaths.FIREBASE_HOOT_CALL_PARTICIPANT_OPTIONS;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }


  HOOT_CALL_PARTICIPANT_OPTIONS_VIEW_PROFILE() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      // Feateues logger => hoots system
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_HOOTS +
        '/' +
        FirebasePaths.FIREBASE_HOOT_CALL_PARTICIPANT_OPTIONS_VIEW_PROFILE;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  HOOT_CALL_PARTICIPANT_OPTIONS_ADD_FRIEND() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      // Feateues logger => hoots system
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_HOOTS +
        '/' +
        FirebasePaths.FIREBASE_HOOT_CALL_PARTICIPANT_OPTIONS_ADD_FRIEND;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  HOOT_CALL_PARTICIPANT_OPTIONS_BLOCK_USER() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      // Feateues logger => hoots system
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_HOOTS +
        '/' +
        FirebasePaths.FIREBASE_HOOT_CALL_PARTICIPANT_OPTIONS_BLOCK_USER;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  HOOT_CALL_PARTICIPANT_OPTIONS_REPORT_USER() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      // Feateues logger => hoots system
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_HOOTS +
        '/' +
        FirebasePaths.FIREBASE_HOOT_CALL_PARTICIPANT_OPTIONS_REPORT_USER;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  HOOT_CALL_PARTICIPANT_OPTIONS_REPORT_USER_CONFIRM() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      // Feateues logger => hoots system
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_HOOTS +
        '/' +
        FirebasePaths.FIREBASE_HOOT_CALL_PARTICIPANT_OPTIONS_REPORT_USER_CONFIRM;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  HOOT_CALL_PARTICIPANT_OPTIONS_KICK() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      // Feateues logger => hoots system
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_HOOTS +
        '/' +
        FirebasePaths.FIREBASE_HOOT_CALL_PARTICIPANT_OPTIONS_KICK;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  HOOT_CALL_PARTICIPANT_OPTIONS_BAN() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      // Feateues logger => hoots system
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_HOOTS +
        '/' +
        FirebasePaths.FIREBASE_HOOT_CALL_PARTICIPANT_OPTIONS_BAN;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  HOOT_CALL_PARTICIPANT_OPTIONS_BAN_CONFIRM() {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    if (uid != null && uid !== '') {
      // Feateues logger => hoots system
      let date = new Date();
      let timestamp = date.toDateString();

      let ref =
        FirebasePaths.FIREBASE_FEATURES_LOGGERS +
        '/' +
        FirebasePaths.FIREBASE_HOOTS +
        '/' +
        FirebasePaths.FIREBASE_HOOT_CALL_PARTICIPANT_OPTIONS_BAN_CONFIRM;
      firebase.database()
        .ref(ref + '/' + timestamp + '/')
        .child(uid)
        .set(username);

      this.CLICKS_NUMBER_LOGGER(ref, timestamp);
    }
  }

  // #######################################################################################################################################

  // This function will log also the number of clicks by providing the userid + random number as a ref log then you will calculate the number of clicks per
  // Logged refs
  CLICKS_NUMBER_LOGGER(path, timeStamp) {
    let ref = path + '_CLICKS';
    // Generate a unique id with the original user id to calculate the number of clicks
    // userId___unique id : three underscores
    let uniqueId = firebase.database().ref().push().key;

    let userClickRef = store.getState().authReducer.uid + '___' + uniqueId;
    const username = store.getState().authReducer.userinformationDB.username;

    firebase.database()
      .ref(ref + '/' + timeStamp)
      .child(userClickRef)
      .set(username);
  }
}

if (!global.featuresLogger) {
  global.featuresLogger = new FeaturesLogger();
}
