export const FIREBASE_PRIVATE_ATTR = '_private_';
export const FIREBASE_PUBLIC_ATTR = '_public_';

export const FIREBASE_RECENT_GAME_ID_ATTR = 'game_id';
export const FIREBASE_RECENT_GAME_TYPE_ATTR = 'game_type';
export const FIREBASE_RECENT_MATCH_TYPE_ATTR = 'match_type';
export const FIREBASE_RECENT_REQUEST_PLATFORM_ATTR = 'request_platform';
export const FIREBASE_RECENT_TIME_STAMP_ATTR = 'timeStamp';
export const FIREBASE_RECENT_REQUEST_ID = 'request_id';
export const FIREBASE_RECENT_REGION = 'region';

export const FIREBASE_REQUESTS_REQUEST_ID_ATTR = 'requestId';
export const FIREBASE_REQUESTS_ADMIN_ATTR = 'admin';
export const FIREBASE_REQUESTS_ADMIN_NAME_ATTR = 'adminName';
export const FIREBASE_REQUESTS_DESCRIPTION_ATTR = 'description';
export const FIREBASE_REQUESTS_GAME_ID_ATTR = 'gameId';
export const FIREBASE_REQUESTS_MATCH_TYPE_ATTR = 'matchType';
export const FIREBASE_REQUESTS_PLATFORM_ATTR = 'platform';
export const FIREBASE_REQUESTS_PLAYERS_NUMBER_ATTR = 'playersNumber';
export const FIREBASE_REQUESTS_RANK_ATTR = 'rank';
export const FIREBASE_REQUESTS_REGION_ATTR = 'region';
export const FIREBASE_REQUESTS_REQUEST_TITLE_ATTR = 'requestTitle';
export const FIREBASE_REQUESTS_TIMESTAMP_ATTR = 'timeStamp';
export const FIREBASE_REQUESTS_PLAYERS = 'players';
export const FIREBASE_REQUESTS_CURRENT_PLAYERS_NUMBER = 'currentPlayersNumber';

export const FIREBASE_REPLIES_LIKES_ATTR = '_replies_likes_';

export const FIREBASE_SAVED_REQ_ADMIN_ATTR = 'admin';
export const FFIREBASE_SAVED_REQ_ADMIN_NAME_ATTR = 'adminName';
export const FIREBASE_SAVED_REQ_DESCRIPTION_ATTR = 'description';
export const FIREBASE_SAVED_REQ_GAME_ID_ATTR = 'gameId';
export const FIREBASE_SAVED_REQ_MATCH_TYPE_ATTR = 'matchType';
export const FIREBASE_SAVED_REQ_PLATFORM_ATTR = 'platform';
export const FIREBASE_SAVED_REQ_PLAYERS_NUMBER_ATTR = 'playersNumber';
export const FIREBASE_SAVED_REQ_RANK_ATTR = 'rank';
export const FIREBASE_SAVED_REQ_REGION_ATTR = 'region';
export const FIREBASE_SAVED_REQ_REQ_PICTURE_ATTR = 'requestPicture';
export const FIREBASE_SAVED_REQ_REQ_TITLE_ATTR = 'requestTitle';
export const FIREBASE_SAVED_REQ_REQ_TIME = 'requestTime';

export const FIREBASE_FRIENDS_LIST_ATTR = '_friends_list_';
export const FIREBASE_BLOCKED_USERS = '_blocked_users_';
export const FIREBASE_DETAILS_ATTR = '_info_';
export const FIREBASE_COSMETICS_ATTR = '_cosmetics_';
export const FIREBASE_NAME_ATTR = '_name_';
export const FIREBASE_PROFILE_BORDER_ATTR = '_profile_border_';
export const FIREBASE_HOP_SHADOW_ATTR = '_hop_shadow_';
export const FIREBASE_COLOR_ATTR = '_color_';
export const FIREBASE_USER_NAMES_ATTR = '_user_names_';
export const FIREBASE_USERS_INFO_ATTR = '_users_info_';
export const FIREBASE_CONTENT_BAN = '_content_ban_';
export const FIREBASE_USERS_LIST_ATTR = '_users_';
export const FIREBASE_GAME_QUICK_ATTR = '_quick_game_';
export const FIREBASE_GAME_COMPETITVE_ATTR = '_competitive_';
export const FIREBASE_GAME_COOP_ATTR = '_coop_';
export const FIREBASE_REQUEST_REGION_ATTR = 'region';
export const FIREBASE_REQUEST_PLAYERS_NUMBER_ATTR = 'players_number';
export const FIREBASE_REQUEST_TIME_STAMP_ATTR = 'timeStamp';

export const FIREBASE_LAST_ACTIVE_TIME = '_last_active_time_';
export const FIREBASE_LAST_ACTIVE_TIMESTAMP = '_last_active_timestamp_';

// User profile passes
export const FIREBASE_USERS_PASSES = '_users_passes_';
export const FIREBASE_USERS_PASSES_COUNTER = '_counter_';
export const FIREBASE_USERS_PASSES_USERS = '_users_';
export const FIREBASE_USERS_PASSES_REMINDER = '_reminder_';

// User notifications path
export const FIREBASE_USER_NOTIFICATIONS = '_notifications_';

// User Teams
export const FIREBASE_USER_TEAMS = '_teams_';
export const FIREBASE_USER_TEAMS_REFS = '_refs_';
export const FIREBASE_USER_TEAMS_INVITATIONS = '_invitations_';
export const FIREBASE_USER_TEAMS_JOINS = '_joins_';
export const FIREBASE_USER_TEAMS_NOTIFICATIONS = '_notifications_';

// Clans Chat USer ref
export const FIREBASE_CLANS_CHATS_USER_REF = '_clans_chats_';

export const GAME_TYPE_COMPETITVE = 'Competitive';
export const GAME_TYPE_QUICK_MATCH = 'Quick Match';
export const GAME_TYPE_COOP = 'Co-op';

export const FIREBASE_SAVED_REQUESTS_REQUESTS_ATTR = 'Requests';
export const IREBASE_PROMO_CODE_POINTS_ATTR = 'Points';
export const FIREBASE_PROMO_CODE_ATTR = '_promo_code_';

export const FIREBASE_GAMES_REFERENCES = '_games_';
export const FIREBASE_GAMES_NAME_ATTR_REFERENCES = 'name';
export const FIREBASE_GAMES_PHOTO_ATTR_REFERENCES = 'photo';
export const FIREBASE_GAME_PLATFORMS_ATTR = 'platforms';
export const FIREBASE_GAME_PC_GAME_PROVIDER = 'pc_game_provider';
export const FIREBASE_USER_CHAT_REFERENCES = '_chat_refs_';
export const FIREBASE_GAME_MAX_PLAYER_ATTR = 'max_player';
export const FIREBASE_CHAT_ATTR = 'Chat';
export const FIREBASE_SAVED_REQS_ATTR = '_saved_requests_';

export const FIREBASE_CHAT_MESSAGES = '_messages_';
export const FIREBASE_PENDING_CHAT_ATTR = '_pending_chat_';

export const FIREBASE_PRIVATE_CHAT_INFO = '_info_';
export const FIREBASE_PRIVATE_CHAT_USERS = '_users_';
export const FIREBASE_PRIVATE_CHAT_LAST_MESSAGE = '_last_message_';
export const FIREBASE_PRIVATE_CHAT_PACKETS = '_packets_';

export const FIREBASE_PUBLIC_CHAT_INFO = '_info_';
export const FIREBASE_PUBLIC_CHAT_USERS = '_users_';
export const FIREBASE_PUBLIC_CHAT_LAST_MESSAGE = '_last_message_';
export const FIREBASE_PUBLIC_CHAT_PACKETS = '_packets_';

// USer Chat Ref : Group
export const FIREBASE_PUBLIC_USER_REF_LAST_MESSAGE = 'lastMessage';
export const FIREBASE_PUBLIC_USER_REF_LAST_MESSAGE_TIMESTAMP =
  'lastMessageTimestamp';
export const FIREBASE_PUBLIC_USER_REF_SENDER_ID = 'senderId';
export const FIREBASE_PUBLIC_USER_REF_OPENED = 'opened';

export const FIREBASE_USER_PRIVATE_CHAT = '_chat_refs_' + '/' + '_private_';
export const FIREBASE_USER_PUBLIC_CHAT = '_chat_refs_' + '/' + '_public_';
export const FIREBASE_CHAT_USERS_LIST_PATH = '_info_' + '/' + '_users_';
export const FIREBASE_SAVED_REQS_PATH =
  '_games_' + '/' + '_saved_requests_' + '/Requests';

export const FIREBASE_COUNTER_PATH = '_counter_';
export const FIREBASE_OPPONENT_ID_ATTR = '_opponent_ID_';

// ROOT PATH
export const FB_ROOT = 'https://ionichoplay.firebaseio.com/';
export const FB_USERS_PATH =
  'https://ionichoplay.firebaseio.com/' + '_users_info_' + '/';
export const FB_CHAT_PATH =
  'https://ionichoplay.firebaseio.com/' + 'Chat' + '/';
export const FB_PRIVATE_CHAT_PATH =
  'https://ionichoplay.firebaseio.com/' + 'Chat' + '/' + '_private_' + '/';
export const FB_PUBLIC_CHAT_PATH =
  'https://ionichoplay.firebaseio.com/' + 'Chat' + '/' + '_public_' + '/';
export const FB_PENDING_CHAT_PATH =
  'https://ionichoplay.firebaseio.com/' + 'Chat' + '/' + '_pending_chat_' + '/';

// User analytics attrs
export const FIREBASE_ANALYTICS_COUNTRY = '_country_';
export const FIREBASE_ANALYTICS_CITY = '_city_';
export const FIREBASE_ANALYTICS_GENDER = '_gender_';
export const FIREBASE_ANALYTICS_AGE = '_age_';
export const FIREBASE_ANALYTICS_MAIN_PLATFORM = '_main_platform_';
export const FIREBASE_ANALYTICS_FAV_GAME = '_fav_game_';
export const FIREBASE_ANALYTICS_AVG_PLAYTIME = '_avg_playtime_';
export const FIREBASE_ANALYTICS_AVG_DAILY_PLAYTIME = '_avg_daily_playtime_';
export const FIREBASE_ANALYTICS_PHONE = '_phone_';
export const FIREBASE_ANALYTICS_DAY_PLAYTIME = '_day_playtime_';

// Requests

export const FB_REQUESTS_REFERENCE = '_requests_';
export const FB_REQUESTS_JOIN = '_join_request_alert_';

// Regions

export const FB_REGIONS_REFERENCE = '_regions_';

// Support
export const FIREBASE_SUPPORT_REFERENCE = '_support_';
export const FIREBASE_SUPPORT_NEW_REFERENCE = '_new_';
export const FIREBASE_SUPPORT_OLD_REFERENCE = '_old_';
export const FIREBASE_SUPPORT_TIME_ATTR = 'timestamp';
export const FIREBASE_SUPPORT_TITLE_ATTR = 'title';
export const FIREBASE_SUPPORT_MESSAGE_ATTR = 'message';
export const FIREBASE_SUPPORT_EMAIL_ATTR = 'email';
export const FIREBASE_SUPPORT_USERNAME = 'username';
export const FIREBASE_SUPPORT_UID = 'UID';

// Championships
export const FIREBASE_CHAMPIONSHIPS_REFERENCE = '_championships_';
// export const FIREBASE_CHAMPIONSHIPS_OFFLINE_REFERENCE = "_offline_";
// export const FIREBASE_CHAMPIONSHIPS_ONLINE_REFERENCE = "_online_";
export const FIREBASE_CHAMPIONSHIPS_INFO = '_info_';
export const FIREBASE_CHAMPIONSHIPS_BRACKETS = '_brackets_';
export const FIREBASE_CHAMPIONSHIPS_REGISTRATION_FIELDS =
  '_registration_fields_';
export const FIREBASE_CHAMPIONSHIPS_REGISTERED_PARTICIPANTS =
  '_registered_participants_';

// Cosmetics
export const FIREBASE_COSMETICS_REFERENCE = '_cosmetics_';
export const FIREBASE_COSMETICS_USER_COSMETICS = '_user_cosmetics_';
export const FIREBASE_COSMETICS_USER_COSMETICS_PROFILE_BORDER =
  '_profile_border_';
export const FIREBASE_COSMETICS_USER_COSMETICS_HOP_SHADOW = '_hop_shadow_';
export const FIREBASE_COSMETICS_USER_COSMETICS_PROFILE_BORDER_COLORS =
  '_colors_';

// Reports
export const FIREBASE_REPORTS_REFERENCE = '_reports_';
export const FIREBASE_REPORTS_NEW_REFERENCE = '_new_';
export const FIREBASE_REPORTS_OLD_REFERENCE = '_old_';

// USER INFO
export const FIREBASE_USERNAME_ATTR = '_username_';
export const FIREBASE_BIO_ATTR = '_bio_';
export const FIREBASE_STATUS_ATTR = '_status_';
export const FIREBASE_SECURITY_ANSWER_ATTR = '_security_answer_';
export const FIREBASE_ACCOUNT_TYPE_ATTR = 'acc_type';
export const FIREBASE_PICTURE_URL_ATTR = '_picUrl_';
export const FIREBASE_NICKNAME_ATTR = '_nickname_';
export const FIREBASE_EMAIL_ATTR = '_email_';
export const FIREBASE_FAVOR_GAME_ATTR = '_favor_games_';
export const FIREBASE_RECENT_GAME_ATTR = '_recent_played_';
export const FIREBASE_USER_REQUESTS_ATTR = '_requests_refs_';
export const FIREBASAE_USER_RANK_ATTR = '_rank_';
export const FIREBASAE_USER_PROFILE_ICON = 'profile_icon';
export const FIREBASAE_USER_HOPS_ICON = 'hops_icon';

export const FIREBASE_USER_TIMELINE_REPLIES_ATTR = '_timeline_replies_';
export const FIREBASE_USER_POST_REPLIES_ATTR = '_replies_';
export const FIREBASE_USER_POST_REPLIES_FROM_OTHERS_ATTR = '_from_others_';
export const FIREBASE_USER_POST_REPLIES_TO_OTHERS_ATTR = '_to_others_';

export const FOREBASE_USER_HOPS_ATTR = '_hops_';
export const FIREBASE_HOPS_LIKES_ATTR = '_hops_likes_';
export const FIREBASE_LIKES_ATTR = '_likes_';
export const FIREBASE_DISLIKES_ATTR = '_dislikes_';

export const FIREBASE_USER_PC_GAME_PROVIDER_ATTR = 'PC_game_providers';
export const FIREBASE_USER_PS_GAME_PROVIDER_ATTR = 'PSN_account';
export const FIREBASE_USER_XBOX_GAME_PROVIDER_ATTR = 'XBOX_live_account';
export const FIREBASE_USER_NINTENDO_GAME_PROVIDER_ATTR = 'NINTENDO_account';
export const FIREBASE_USER_MOBILE_GAME_PROVIDER_ATTR = 'MOBILE_game_providers';

export const FIREBASE_USER_PC_GAME_PROVIDER =
  '_info_' + '/' + 'PC_game_providers';
export const FIREBASE_USER_PS_GAME_PROVIDER = '_info_' + '/' + 'PSN_account';
export const FIREBASE_USER_XBOX_GAME_PROVIDER =
  '_info_' + '/' + 'XBOX_live_account';
export const FIREBASE_USERNAME_PATH = '_info_' + '/' + '_username_';
export const FIREBASE_BIO_PATH = '_info_' + '/' + '_bio_';
export const FIREBASE_ACCOUNT_TYPE_PATH = '_info_' + '/' + 'acc_type';
export const FIREBASE_PICTURE_URL_PATH = '_info_' + '/' + '_picUrl_';
export const FIREBASE_NICKNAME_PATH = '_info_' + '/' + '_nickname_';
export const FIREBASE_EMAIL_PATH = '_info_' + '/' + '_email_';

export const FIREBASE_FAVOR_GAMES_PATH = '_games_' + '/' + '_favor_games_';
export const FIREBASE_RECENT_GAMES_PATH = '_games_' + '/' + '_recent_played_';
export const FIREBASESTORAGE_USERINFO_ATTR = 'UsersInfo';

// Version

export const FIREBASE_VERSION_ATTR = 'Version';
export const FIREBASE_VERSION_CODE_ATTR = 'version_code';
export const FIREBASE_VERSION_NAME_ATTR = 'version_name';

// Security
export const FIREBASE_SECURITY_ATTR = 'Security';
export const IREBASE_SECURITY_QUESTION_ATTR = '_question_';

// Requests HUB
export const FIREBASE_REQUESTS_HUB = '_requests_hub_';

// Timeline
export const FIREBASE_TIMELINE = '_timeline_';

// Timeline Replies
export const FIREBASE_TIMELINE_REPLIES = '_timeline_replies_';
export const FIREBASE_TIMELINE_REPLIES_ADMIN_UID = 'Admin';
export const FIREBASE_TIMELINE_REPLIES_REPLY = '_replies_';
export const FIREBASE_TIMELINE_REPLIES_ADMIN_USERNAME = 'Admin_Username';

// Sliders
export const FIREBASE_SLIDERS = 'sliders';

// Hops /  Timeline Reports
export const FIREBASE_HOPS_REPORTS = '_hops_reports_';
export const FIREBASE_HOPS_REPORTS_NEW = '_new_';
export const FIREBASE_HOPS_REPORTS_OLD = '_old_';

// Ban
export const FIREBASE_USER_BAN = '_ban_';
export const FIREBASE_USER_BAN_CREATE_REQUEST = 'CREATE_REQUEST';
export const FIREBASE_USER_BAN_JOIN_REQUEST = 'JOIN_REQUEST';
export const FIREBASE_USER_BAN_POST_HOP = 'POST_HOP';
export const FIREBASE_USER_BAN_REPLY_HOP = 'REPLY_HOP';
export const FIREBASE_USER_BAN_CHAT = 'CHAT';
export const FIREBASE_USER_BAN_CREATE_CLAN = 'CREATE_CLAN';
export const FIREBASE_USER_BAN_JOIN_CLAN = 'JOIN_CLAN';
export const FIREBASE_USER_BAN_ACCESS = 'ACCESS';
export const FIREBASE_USER_BAN_HOOT_CALL = 'HOOT_CALL';
export const FIREBASE_USER_BAN_HOOT_JOIN = 'HOOT_JOIN';

// Notifications Team Ref
export const FIREBASE_TEAMS_NOTIFICATIONS = '_teams_notifications_';
export const FIREBASE_TEAMS_NOTIFICATIONS_FOLLOWERS = '_followers_';
export const FIREBASE_TEAMS_NOTIFICATIONS_NEW_REQUEST = '_new_request_';

// User Team notifications Ref
export const FIREBASE_USER_TEAM_NOTIFICATION = '_team_notifications_';
export const FIREBASE_USER_TEAM_NOTIFICATION_DEACTIVATED_NOTIFICATIONS =
  'team_notifications_deactivated';

// All countries and cities path
export const FIREBASE_COUNTRIES_CITIES = '_countries_with_cities_';

// Admins List
export const FIREBASE_ADMINS_LIST = '_admins_list_';

// Ratings Attrs
export const FIREBASE_USER_RATINGS = '_ratings_';
// This attr used to keep the records of who rated this user so calculate the current user avg rating
export const FIREBASE_USER_RATING_FROM = '_ratings_from_';
// This attr used to keep the records of rated users so the user cant rate again
export const FIREBASE_USER_RATING_TO = '_ratings_to_';

// Team
export const FIREBASE_TEAMS_NAMES = '_teams_names_';
export const FIREBASE_TEAMS = '_teams_info_';
export const FIREBASE_TEAM_INFO = '_info_';
export const FIREBASE_TEAM_MEMBERS = '_members_';
export const FIREBASE_TEAM_NOTIFICATIONS = '_notifications_';
export const FIREBASE_TEAM_ACHIEVEMENTS = '_achievements_';
export const FIREBASE_TEAM_CHAMPIONSHIPS = '_championships_';
export const FIREBASE_TEAM_AWARDS = '_awards_';
export const FIREBASE_TEAM_FEEDS = '_feeds_';
export const FIREBASE_TEAM_CHAT = '_chat_';
export const FIREBASE_TEAM_VERIFIED_TEAMS = '_verified_teams_';
export const FIREBASE_TEAM_SECOND_ADMINS = '_second_admins_';

// AD SPOTS
export const FIREBASE_AD_SPOTS = '_ad_spots_';
export const FIREBASE_AD_SPOTS_HOPS = 'HOPS';
export const FIREBASE_AD_SPOTS_CLANS = 'CLANS';
export const FIREBASE_AD_SPOTS_NEWS = 'NEWS';
export const FIREBASE_AD_SPOTS_SEARCH_REQUEST_RESULTS =
  'SEARCH_REQUEST_RESULTS';
export const FIREBASE_AD_SPOTS_FOUND_MATCH = 'FOUND_MATCH';
export const FIREBASE_AD_SPOTS_PRIVATE_CHAT = 'PRIVATE_CHAT';
export const FIREBASE_AD_SPOTS_PUBLIC_CHAT = 'PUBLIC_CHAT';
export const FIREBASE_AD_SPOTS_CLAN_CHAT = 'CLAN_CHAT';
export const FIREBASE_AD_SPOTS_LOBBY = 'LOBBY';
export const FIREBASE_AD_SPOTS_CHAMPIONSHIPS = 'CHAMPIONSHIPS';

export const FIREBASE_PUBLIC_USER_REF_NEW_TITLE = 'groupNewTitle';

// News
export const FIREBASE_NEWS_REF = '_news_';

// clans achievements list
export const FIREBASE_ACHIEVEMENTS_LIST = '_achievements_';

// Leaderboard
export const FIREBASE_LEADERBOARD = '_leaderboard_';

// Features Loggers
export const FIREBASE_FEATURES_LOGGERS = '_features_loggers_';
// Hoplay Match Logs
export const FIREBASE_FEATURE_LOG_HOPLAY_MATCH = '_hoplay_match_';
export const FIREBASE_FEATURE_LOG_HOPLAY_MATCH_CHAT = 'CLICKING_CHAT_BUTTON';
export const FIREBASE_FEATURE_LOG_HOPLAY_MATCH_FOUND_MATCH = 'FOUND_MATCH';

// Hops Logs
export const FIREBASE_FEATURE_LOG_HOPS = '_hops_';
export const FIREBASE_FEATURE_LOG_HOPS_REPLY = 'HOP_REPLY';
export const FIREBASE_FEATURE_LOG_HOPS_SEND = 'HOP_SEND';
export const FIREBASE_FEATURE_LOG_HOPS_VIEWED = 'HOP_VIEWED';

// Request Logs
export const FIREBASE_FEATURE_LOG_REQUEST = '_request_';
export const FIREBASE_FEATURE_LOG_REQUEST_JOIN_GROUP = 'JOIN_GROUP';
export const FIREBASE_FEATURE_LOG_REQUEST_VIEW_GROUP = 'VIEW_GROUP';
export const FIREBASE_FEATURE_LOG_REQUEST_NEW_REQUEST = 'NEW_REQUEST';
export const FIREBASE_FEATURE_LOG_SEARCH_REQUEST_BTN = 'SEARCH_REQUEST';
export const FIREBASE_FEATURE_LOG_SEARCH_REQUEST_VIEW_RESULT =
  'VIEW_SEARCH_RESULT';
export const FIREBASE_FEATURE_LOG_REQUEST_EXIT_GROUP = 'EXIT_GROUP';
export const FIREBASE_FEATURE_LOG_REQUEST_UPDATE_REQUEST = 'UPDATE_REQUEST';
export const FIREBASE_FEATURE_LOG_REQUEST_DELETE_SAVED_REQUEST =
  'DELETE_SAVED_REQUEST';
export const FIREBASE_FEATURE_LOG_REQUEST_CHECK_REQUEST_CLICK =
  'CHECK_REQUEST_CLICK';
export const FIREBASE_FEATURE_LOG_REQUEST_VIEW_REQUEST_HUB = 'VIEW_REQUEST_HUB';

// Rate Logs
export const FIREBASE_FEATURE_LOG_RATE = '_rate_';
export const FIREBASE_FEATURE_LOG_RATE_VIEW_PAGE = 'PAGE_VIEW';
export const FIREBASE_FEATURE_LOG_RATE_RATED = 'RATED';

// Share app logs
export const FIREBASE_FEATURE_LOG_SHARE_APP = 'SHARE_APP'; // AKA profile & friend profile
export const FIREBASE_FEATURE_LOG_SHARE_HOP = 'SHARE_HOP';
export const FIREBASE_FEATURE_LOG_SHARE_REQUEST = 'SHARE_REQUEST';
export const FIREBASE_FEATURE_LOG_SHARE_CLAN = 'SHARE_CLAN';

// Profile Logs
export const FIREBASE_FEATURE_LOG_PROFILE = '_profile_';
export const FIREBASE_FEATURE_LOG_PROFILE_PROFILE_UPDATED = 'UPDATE_PROFILE';

// Friend Logs
export const FIREBASE_FEATURE_LOG_FRIEND = '_friend_';
export const FIREBASE_FEATURE_LOG_FRIEND_VIEW_PROFILE = 'VIEW_PROFILE';
export const FIREBASE_FEATURE_LOG_FRIEND_ADD = 'ADD_USER';
export const FIREBASE_FEATURE_LOG_FRIEND_DELETE = 'DELETE_USER';
export const FIREBASE_FEATURE_LOG_FRIEND_CLICK_ON_NEW_CHAT =
  'NEW_PRIVATE_CHAT_CLICK';

// Feedback Logs
export const FIREBASE_FEATURE_LOG_FEEDBACKS = '_feedbacks_';
export const FIREBASE_FEATURE_LOG_FEEDBACKS_SUPPORT = 'SUPPORT';
export const FIREBASE_FEATURE_LOG_FEEDBACKS_EMAIL = 'EMAIL';
export const FIREBASE_FEATURE_LOG_FEEDBACKS_WHATSAPP = 'WHATSAPP';

export const FIREBASE_FEATURE_LOG_FEEDBACKS_REQUEST_REPORT = 'REQUEST_REPORT';
export const FIREBASE_FEATURE_LOG_FEEDBACKS_HOP_REPORT = 'HOP_REPORT';

// Logout Log
export const FIREBASE_FEATURE_LOG_LOGOUT = 'LOGOUT';

// Notification Manager Logs
export const FIREBASE_FEATURE_LOG_NOTIFICATION = '_notifications_';
export const FIREBASE_FEATURE_LOG_NOTIFICATION_VIEW_PAGE = 'PAGE_VIEW';
export const FIREBASE_FEATURE_LOG_NOTIFICATION_ACTIVATE_NOTIFICATION =
  'ACTIVATE_NOTIFICATION';
export const FIREBASE_FEATURE_LOG_NOTIFICATION_DEACTIVATE_NOTIFICATION =
  'DEACTIVATE_NOTIFICATION';
export const FIREBASE_FEATURE_LOG_NOTIFICATION_USER_NOTIFICATIONS_PAGE =
  'USER_NOTIFICATIONS_PAGE_VIEW';
export const FIREBASE_FEATURE_LOG_NOTIFICATION_USER_NOTIFICATIONS_CLICKS =
  'USER_NOTIFICATIONS_CLICKS';

// Slide log :
export const FIREBASE_FEATURE_LOG_SLIDE_CLICK = 'SLIDE_CLICK';

// View Ranks Timeline Log
export const FIREBASE_FEATURE_LOG_RANKS_TIMELINE_VIEW = 'RANKS_TIMELINE_VIEW';

// Users activities log
export const FIREBASE_USER_RANK_ACTIVITIES = '_ranks_activities_';

// Chat log
export const FIREBASE_FEATURE_LOG_CHAT = '_chat_';
export const FIREBASE_FEATURE_LOG_CHAT_PRIVATE_OPEN = 'PRIVATE_OPENED';
export const FIREBASE_FEATURE_LOG_CHAT_GROUP_OPEN = 'GROUP_OPENED';

// Games
export const FIREBASE_FEATURE_LOG_GAMES = '_games_';
export const FIREBASE_FEATURE_LOG_GAMES_VIEW_PAGE = 'VIEW_PAGE';
export const FIREBASE_FEATURE_LOG_GAMES_ADD = 'ADD_GAME';
export const FIREBASE_FEATURE_LOG_GAMES_DELETE = 'DELETE_GAME';

// TEAM
export const FIREBASE_FEATURE_LOG_TEAMS = '_teams_';

export const FIREBASE_FEATURE_LOG_TEAMS_TEAM_PAGE_VIEW = 'TEAM_PAGE_VIEW';

export const FIREBASE_FEATURE_LOG_TEAMS_TEAM_NOTIFICATION_PAGE_VIEW =
  'TEAM_NOTIFICATION_PAGE_VIEW';

export const FIREBASE_FEATURE_LOG_TEAMS_USER_TEAMS_PAGE_VIEW =
  'USER_TEAMS_PAGE_VIEW';

export const FIREBASE_FEATURE_LOG_TEAMS_CREATE_TEAM_PAGE_VIEW =
  'CREATE_TEAM_PAGE_VIEW';

export const FIREBASE_FEATURE_LOG_TEAMS_JOIN_TEAM_PAGE_VIEW =
  'JOIN_TEAM_PAGE_VIEW';

export const FIREBASE_FEATURE_LOG_TEAMS_TEAM_JOIN_REQUESTS_PAGE_VIEW =
  'TEAM_JOIN_REQUESTS_PAGE_VIEW';

export const FIREBASE_FEATURE_LOG_TEAMS_USER_ACCOUNTS_PAGE_VIEW =
  'USER_ACCOUNTS_PAGE_VIEW';

export const FIREBASE_FEATURE_LOG_TEAMS_TEAM_CHAT_PAGE_VIEW =
  'TEAM_CHAT_PAGE_VIEW';

export const FIREBASE_FEATURE_LOG_TEAMS_TEAM_CREATED = 'TEAMS_CREATED';

export const FIREBASE_FEATURE_LOG_TEAMS_TEAMS_INVITES_SENT =
  'TEAMS_INVITES_SENT';

export const FIREBASE_FEATURE_LOG_TEAMS_TEAM_JOIN_REQUEST_SENT =
  'TEAM_JOIN_REQUEST_SENT';

export const FIREBASE_FEATURE_LOG_TEAMS_TEAM_ACCEPTS = 'TEAM_ACCEPTS';

export const FIREBASE_FEATURE_LOG_TEAMS_TEAM_REJECTS = 'TEAM_REJECTS';

export const FIREBASE_FEATURE_LOG_TEAMS_TEAM_DIRECT_JOIN = 'DIRECT_JOINS';

export const FIREBASE_FEATURE_LOG_TEAMS_TEAM_USERS_ACCEPT = 'TEAM_USERS_ACCEPT';

export const FIREBASE_FEATURE_LOG_TEAMS_TEAM_USERS_REJECTS =
  'TEAM_USERS_REJECTS';

export const FIREBASE_FEATURE_LOG_TEAMS_TEAM_UPLOAD_FEED = 'TEAM_UPLOAD_FEED';

export const FIREBASE_FEATURE_LOG_TEAMS_TEAM_EXITS = 'TEAM_EXITS';

export const FIREBASE_FEATURE_LOG_TEAMS_VERIFIED_TEAMS_SECTION_VIEW =
  'VERIFIED_TEAMS_SECTION_VIEW';

export const FIREBASE_FEATURE_LOG_TEAMS_USER_ACCOUNT_NOTIFIED =
  'USER_ACCOUNT_NOTIFIED';

export const FIREBASE_FEATURE_LOG_TEAMS_REGISTERED_TEAMS_SECTION_VIEW =
  'REGISTERED_TEAMS_SECTION_VIEW';

// Reach sources : List
export const FIREBASE_REACH_SOURCES = '_reach_sources_';
// REACH sources : USERs VALUES
export const FIREBASE_REACH_SOURCES_VALUES = '_reach_sources_values_';

// News
export const FIREBASE_FEATURE_LOG_NEWS = '_news_';
export const FIREBASE_FEATURE_LOG_NEWS_PAGE_VIEW = 'NEWS_PAGE_VIEW';
export const FIREBASE_FEATURE_LOG_NEWS_NEWS_CLICKS = 'NEWS_CLICKS';
export const FIREBASE_FEATURE_LOG_NEWS_NEWS_SHARES = 'NEWS_SHARES';

// Rank
export const FIREBASE_FEATURE_LOG_RANK = '_ranks_';
export const FIREBASE_FEATURE_LOG_RANK_LEADERBOARD_PAGE_VIEW =
  'LEADER_BOARD_PAGE_VIEW';

// Agents
export const FIREBASE_AGENTS_REF = '_agents_';
export const FIREBASE_FEATURE_LOG_AGENT = '_agents_';
export const FIREBASE_FEATURE_LOG_AGENT_PAGE_VIEW = 'AGENTS_PAGE_VIEW';

// Registration & Login
export const FIREBASE_FEATURE_LOG_USER_LOGGED = '_USER_LOGGED_IN_';

// AD page : gaming centers pages views
export const FIREBASE_FEATURE_AD_PAGE = '_ad_';
export const FIREBASE_FEATURE_AD_PAGE_VIEW = 'PAGE_VIEW';
export const FIREBASE_FEATURE_AD_LOCATION_CLICKED = 'LOCATION_CLICKED';
export const FIREBASE_FEATURE_AD_SOCIAL_MEDIA_CLICKED = 'SOCIAL_MEDIA_CLICKED';
export const FIREBASE_FEATURE_AD_WEBSITE_CLICKED = 'WEBSITE_CLICKED';
export const FIREBASE_FEATURE_AD_APP_CLICKED = 'APP_CLICKED';

// ADS
export const FIREBASE_FEATURE_LOG_ADS_SPOTS = '_ads_spots_';
export const FIREBASE_FEATURE_LOG_ADS_SPOTS_HOPS_PAGE_VIEW =
  'HOPS_AD_PAGE_VIEW';
export const FIREBASE_FEATURE_LOG_ADS_SPOTS_HOPS_CLICK = 'HOPS_AD_CLICK';
export const FIREBASE_FEATURE_LOG_ADS_SPOTS_CLANS_PAGE_VIEW =
  'CLANS_AD_PAGE_VIEW';
export const FIREBASE_FEATURE_LOG_ADS_SPOTS_CLANS_CLICK = 'CLANS_AD_CLICK';
export const FIREBASE_FEATURE_LOG_ADS_SPOTS_NEWS_PAGE_VIEW =
  'NEWS_AD_PAGE_VIEW';
export const FIREBASE_FEATURE_LOG_ADS_SPOTS_NEWS_CLICK = 'NEWS_AD_CLICK';
export const FIREBASE_FEATURE_LOG_ADS_SPOTS_SEARCH_REQUEST_RESULTS_PAGE_VIEW =
  'SEARCH_REQUEST_RESULTS_AD_PAGE_VIEW';
export const FIREBASE_FEATURE_LOG_ADS_SPOTS_SEARCH_REQUEST_RESULTS_CLICK =
  'SEARCH_REQUEST_RESULTS_AD_CLICK';
export const FIREBASE_FEATURE_LOG_ADS_SPOTS_MAIN_SLIDE_AD_CLICK =
  'MAIN_SLIDE_AD_CLICK';
export const FIREBASE_FEATURE_LOG_ADS_SPOTS_MAIN_SLIDE_AD_PAGE_VIEW =
  'MAIN_SLIDE_AD_PAGE_VIEW';

// Hopped Request Feature
export const FIREBASE_FEATURE_LOG_HOPPED_REQUEST = '_hopped_request_';
export const FIREBASE_FEATURE_LOG_HOPPED_REQUEST_USAGE = 'HOPPED_REQUEST_USAGE';
export const FIREBASE_FEATURE_LOG_HOPPED_REQUEST_VIEWED = 'HOPPED_REQUEST_VIEW';

// Sent chats photos
export const FIREBASE_FEATURE_LOG_CHATS_PHOTOS = 'CHATS_PHOTOS';

// Game Promo HP
export const FIREBASE_RANK_GAME_PROMO = '_rank_game_promo_';

// Hops like/dislikes
export const FIREBASE_FEATURE_LOG_HOP_VOTE_SYSTEM = '_hops_vote_system_';
export const FIREBASE_FEATURE_LOG_HOP_VOTE_SYSTEM_HOP_LIKE = 'HOP_LIKE';
export const FIREBASE_FEATURE_LOG_HOP_VOTE_SYSTEM_HOP_DISLIKE = 'HOP_DISLIKE';
export const FIREBASE_FEATURE_LOG_HOP_VOTE_SYSTEM_REPLY_LIKE = 'REPLY_LIKE';
export const FIREBASE_FEATURE_LOG_HOP_VOTE_SYSTEM_REPLY_DISLIKE =
  'REPLY_DISLIKE';

// Hops like/dislikes
export const FIREBASE_FEATURE_LOG_USER_COSMETICS = '_user_cosmetics_';
export const FIREBASE_FEATURE_LOG_USER_COSMETICS_PROFILE_BORDER_COLOR =
  'PROFILE_BORDER_COLOR';
export const FIREBASE_FEATURE_LOG_USER_COSMETICS_PROFILE_BORDER_COLOR_UPDATE =
  'UPDATE_PROFILE_BORDER_COLOR';
export const FIREBASE_FEATURE_LOG_USER_COSMETICS_PROFILE_BORDER_COLOR_PAGE_VIEW =
  'PAGE_VIEW';

export const FIREBASE_FEATURE_LOG_USER_COSMETICS_SHADOW_COLOR =
  'HOP_BORDER_COLOR';
export const FIREBASE_FEATURE_LOG_USER_COSMETICS_SHADOW_COLOR_UPDATE =
  'UPDATE_HOP_BORDER_COLOR';

// Championship loggers
export const FIREBASE_FEATURE_LOG_CHAMPIONSHIP = '_championship_';
export const FIREBASE_FEATURE_LOG_CHAMPIONSHIPS_PAGE_VIEW =
  'CHAMPIONSHIPS_PAGE_VIEW';
export const FIREBASE_FEATURE_LOG_CHAMPIONSHIP_PAGE_VIEW =
  'CHAMPIONSHIP_PAGE_VIEW';
export const FIREBASE_FEATURE_LOG_CHAMPIONSHIP_REGISTER_CLICKED =
  'REGISTER_CLICKED';
export const FIREBASE_FEATURE_LOG_CHAMPIONSHIP_BRACKET_IMG_VIEW =
  'BRACKET_IMG_VIEW';
export const FIREBASE_FEATURE_LOG_CHAMPIONSHIP_BRACKETS_PAGE_VIEW =
  'BRACKETS_PAGE_VIEW';
export const FIREBASE_FEATURE_LOG_CHAMPIONSHIP_COMPLETE_REGISTRATION =
  'COMPLETE_REGISTRATION';
export const FIREBASE_FEATURE_LOG_CHAMPIONSHIP_STREAMING_CLICK =
  'STREAMING_CLICK';

export const FIREBASE_FEATURE_LOG_JOIN_REQUEST_ALERT_PAGE_VIEW = 'PAGE_VIEW';
export const FIREBASE_FEATURE_LOG_JOIN_REQUEST_ALERT_URL_BTN_CLICK =
  'URL_BTN_CLICK';
export const FIREBASE_FEATURE_LOG_JOIN_REQUEST_ALERT_CHAT_REDIRECTOR_BTN =
  'CHAT_REDIRECTOR_BTN';

export const FIREBASE_FEATURE_LOG_CHAMPIONSHIPS_AD_PAGE_VIEW =
  'CHAMPIONSHIPS_AD_PAGE_VIEW';
export const FIREBASE_FEATURE_LOG_CHAMPIONSHIPS_AD_CLICK =
  'CHAMPIONSHIPS_AD_CLICK';
export const FIREBASE_FEATURE_LOG_PRIVATE_CHAT_AD_PAGE_VIEW =
  'PRIVATE_CHAT_AD_PAGE_VIEW';
export const FIREBASE_FEATURE_LOG_PRIVATE_CHAT_AD_CLICK =
  'PRIVATE_CHAT_AD_CLICK';
export const FIREBASE_FEATURE_LOG_PUBLIC_CHAT_AD_PAGE_VIEW =
  'PUBLIC_CHAT_AD_PAGE_VIEW';
export const FIREBASE_FEATURE_LOG_PUBLIC_CHAT_AD_CLICK = 'PUBLIC_CHAT_AD_CLICK';
export const FIREBASE_FEATURE_LOG_CLAN_CHAT_AD_PAGE_VIEW =
  'CLAN_CHAT_AD_PAGE_VIEW';
export const FIREBASE_FEATURE_LOG_CLAN_CHAT_AD_CLICK = 'CLAN_CHAT_AD_CLICK';
export const FIREBASE_FEATURE_LOG_LOBBY_AD_PAGE_VIEW = 'LOBBY_AD_PAGE_VIEW';
export const FIREBASE_FEATURE_LOG_LOBBY_AD_CLICK = 'LOBBY_AD_CLICK';
export const FIREBASE_FEATURE_LOG_FOUND_MATCH_AD_PAGE_VIEW =
  'FOUND_MATCH_AD_PAGE_VIEW';
export const FIREBASE_FEATURE_LOG_FOUND_MATCH_AD_CLICK = 'FOUND_MATCH_AD_CLICK';

export const FIREBASE_FEATURE_LOG_HOP_REPLY_IMG = 'HOP_REPLY_IMG';
export const FIREBASE_FEATURE_LOG_HOP_SEND_IMG = 'HOP_SEND_IMG';

export const FIREBASE_USER_PRIVACY_ATTR = '_privacy_';
export const FIREBASE_NOTIFICATIONS_SETTINGS_ATTR = '_notifications_settings_';

export const FIREBASE_USER_ACCESS_ATTR = '_access_';

// stickers references
export const FIREBASE_CHAT_STICKERS = '_chat_stickers_';
export const FIREBASE_HOP_STICKERS = '_hop_stickers_';

// stickers feature logger references
export const FIREBASE_CHAT_STICKERS_FEATURE = 'CHAT_STICKERS';
export const FIREBASE_HOP_STICKERS_FEATURE = 'HOP_STICKERS';
export const FIREBASE_STICKERS_REFERENCE = '_stickers_';
export const FIREBASE_POPOVER_OPEN = 'POPOVER_OPEN';
export const FIREBASE_STICKER_CLICK = 'STICKER_CLICK';

// launch Popups
export const FIREBASE_LAUNCH_POPUP = '_launch_popup_';
export const FIREBASE_USER_LAUNCH_POPUPS = '_launch_popups_';
export const FIREBASE_LAUNCH_POPUP_ACTIVE_ID = 'active_popup_id';
export const FIREBASE_LAUNCH_POPUP_SURVEYS = '_launch_popup_surveys_';

// launch popup feature logger
export const FIREBASE_POPUP_VIEW = 'POPUP_VIEW';
export const FIREBASE_POPUP_PRIMARY_BTN_CLICK = 'POPUP_PRIMARY_BTN_CLICK';
export const FIREBASE_POPUP_SECONDARY_BTN_CLICK = 'POPUP_SECONDARY_BTN_CLICK';
export const FIREBASE_POPUP_DISMISS = 'POPUP_DISMISS';

// Hoots

export const FIREBASE_HOOTS = '_hoots_';
export const FIREBASE_HOOTS_GENRES = '_hoots_genres_';
export const FIREBASE_HOOTS_LIVE = '_live_';
export const FIREBASE_HOOTS_RECENT = '_recent_';
export const FIREBASE_HOOTS_BANS = '_hoots_bans_';
export const FIREBASE_HOOTS_REPORTS = '_hoots_reports_';
export const FIREBASE_HOOTS_REPORTS_HOOTS = '_hoots_';
export const FIREBASE_HOOTS_REPORTS_IN_CALL = '_in_call_';
export const FIREBASE_HOOTS_REPORTS_NEW = '_new_';
export const FIREBASE_HOOTS_REPORTS_OLD = '_old_';
export const FIREBASE_HOOTS_CONF = '_hoots_conf_';
export const FIREBASE_HOOTS_MAX_LIVE_PARTICIPANTS = 'max_live_participants';
export const FIREBASE_HOOTS_MAX_HISTORY_PARTICIPANTS = 'max_history_participants';
export const FIREBASE_HOOTS_JOIN_SOUND = 'hoot_join_sound';
export const FIREBASE_HOOTS_DISCONNECT_SOUND = 'hoot_disconnect_sound';

// Hoots features loggesr
export const FIREBASE_CREATE_HOOT_BTN_CLICK = 'CREATE_HOOT_BTN_CLICK';
export const FIREBASE_CREATE_HOOT_VIEW = 'CREATE_HOOT_VIEW';
export const FIREBASE_CREATE_HOOT_GENRE_CLICK = 'CREATE_HOOT_GENRE_CLICK';
export const FIREBASE_CREATE_HOOT_GENRE_SELECT = 'CREATE_HOOT_GENRE_SELECT';
export const FIREBASE_CREATE_HOOT_COVER_CLICK = 'CREATE_HOOT_COVER_CLICK';
export const FIREBASE_CREATE_HOOT_COVER_SUCCESS = 'CREATE_HOOT_COVER_SUCCESS';
export const FIREBASE_HOOTS_TIMELINE_VIEW = 'HOOTS_TIMELINE_VIEW';
export const FIREBASE_HOOTS_TIMELINE_LIVE_CLICK = 'HOOTS_TIMELINE_LIVE_CLICK';
export const FIREBASE_HOOTS_TIMELINE_RECENT_CLICK = 'HOOTS_TIMELINE_RECENT_CLICK';
export const FIREBASE_HOOTS_TIMELINE_LIVE_HOOT_JOIN = 'HOOTS_TIMELINE_LIVE_HOOT_JOIN';
export const FIREBASE_HOOTS_TIMELINE_LIVE_HOOT_JOIN_CONFIRM = 'HOOTS_TIMELINE_LIVE_HOOT_JOIN_CONFIRM';
export const FIREBASE_HOOTS_TIMELINE_LIVE_HOOT_JOIN_DISMISS = 'HOOTS_TIMELINE_LIVE_HOOT_JOIN_DISMISS';
export const FIREBASE_HOOTS_TIMELINE_HOOT_OPTIONS_OPEN = 'HOOTS_TIMELINE_HOOT_OPTIONS_OPEN';
export const FIREBASE_HOOTS_TIMELINE_HOOT_OPTIONS_VIEW_PROFILE = 'HOOTS_TIMELINE_HOOT_OPTIONS_VIEW_PROFILE';
export const FIREBASE_HOOTS_TIMELINE_HOOT_OPTIONS_ADD_FRIEND = 'HOOTS_TIMELINE_HOOT_OPTIONS_ADD_FRIEND';
export const FIREBASE_HOOTS_TIMELINE_HOOT_OPTIONS_BLOCK_USER = 'HOOTS_TIMELINE_HOOT_OPTIONS_BLOCK_USER';
export const FIREBASE_HOOTS_TIMELINE_HOOT_OPTIONS_REPORT = 'HOOTS_TIMELINE_HOOT_OPTIONS_REPORT';
export const FIREBASE_HOOTS_TIMELINE_HOOT_OPTIONS_REPORT_CONFIRM = 'HOOTS_TIMELINE_HOOT_OPTIONS_REPORT_CONFIRM';
export const FIREBASE_HOOTS_TIMELINE_LIVE_SEARCH_TRIGGER = 'HOOTS_TIMELINE_LIVE_SEARCH_TRIGGER';
export const FIREBASE_HOOTS_TIMELINE_RECENT_HOOT_JOIN = 'HOOTS_TIMELINE_RECENT_HOOT_JOIN';
export const FIREBASE_HOOTS_TIMELINE_RECENT_HOOT_JOIN_CONFIRM = 'HOOTS_TIMELINE_RECENT_HOOT_JOIN_CONFIRM';
export const FIREBASE_HOOTS_TIMELINE_RECENT_HOOT_JOIN_DISMISS = 'HOOTS_TIMELINE_RECENT_HOOT_JOIN_DISMISS';
export const FIREBASE_HOOTS_TIMELINE_RECENT_HOOT_CALL_AGAIN = 'HOOTS_TIMELINE_RECENT_HOOT_CALL_AGAIN';
export const FIREBASE_HOOT_CALL_VIEW = 'HOOT_CALL_VIEW';
export const FIREBASE_HOOT_CALL_DISCONNECT = 'HOOT_CALL_DISCONNECT';
export const FIREBASE_HOOT_CALL_MUTE = 'HOOT_CALL_MUTE';
export const FIREBASE_HOOT_CALL_DEAFEN = 'HOOT_CALL_DEAFEN';
export const FIREBASE_HOOT_CALL_SPEAKER = 'HOOT_CALL_SPEAKER';
export const FIREBASE_HOOT_CALL_PARTICIPANT_OPTIONS = 'HOOT_CALL_PARTICIPANT_OPTIONS';
export const FIREBASE_HOOT_CALL_PARTICIPANT_OPTIONS_VIEW_PROFILE = 'HOOT_CALL_PARTICIPANT_OPTIONS_VIEW_PROFILE';
export const FIREBASE_HOOT_CALL_PARTICIPANT_OPTIONS_ADD_FRIEND = 'HOOT_CALL_PARTICIPANT_OPTIONS_ADD_FRIEND';
export const FIREBASE_HOOT_CALL_PARTICIPANT_OPTIONS_BLOCK_USER = 'HOOT_CALL_PARTICIPANT_OPTIONS_BLOCK_USER';
export const FIREBASE_HOOT_CALL_PARTICIPANT_OPTIONS_REPORT_USER = 'HOOT_CALL_PARTICIPANT_OPTIONS_REPORT_USER';
export const FIREBASE_HOOT_CALL_PARTICIPANT_OPTIONS_REPORT_USER_CONFIRM = 'HOOT_CALL_PARTICIPANT_OPTIONS_REPORT_USER_CONFIRM';
export const FIREBASE_HOOT_CALL_PARTICIPANT_OPTIONS_KICK = 'HOOT_CALL_PARTICIPANT_OPTIONS_KICK';
export const FIREBASE_HOOT_CALL_PARTICIPANT_OPTIONS_BAN = 'HOOT_CALL_PARTICIPANT_OPTIONS_BAN';
export const FIREBASE_HOOT_CALL_PARTICIPANT_OPTIONS_BAN_CONFIRM = 'HOOT_CALL_PARTICIPANT_OPTIONS_BAN_CONFIRM';