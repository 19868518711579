import React, {Component} from 'react';
import {View, SectionList} from 'react-native';
import {compose} from 'redux';
import {connect} from 'react-redux';
import firebase from 'firebase/app';
import 'firebase/database';

import I18n from '../../i18n';

// import components
import Label from '../../components/label';
import Header from '../../components/header';
import NotificationCard from '../../components/cards/notification-card';

// import styles
import AppStyles from '../../constants/styles';
import styles from './styles';
import {perfectSize} from '../../helpers/deviceHelper';

import * as FirebasePaths from '../../constants/firebasePaths';
import Teamcore from '../team/teamscore';

import {
  NOTIFICATION_LOADING_DARK_GIF,
  NOTIFICATION_LOADING_GIF,
} from '../../constants/images';

class TeamNotificationsPage extends Component {
  constructor(props) {
    super(props);
    const params = props.navigation.state.params;
    this.state = {
      teamId: params.TEAM_ID,
      notificationsArr: params.NOTIFICATIONS.reverse(),
    };
  }

  componentDidMount() {
    global.rankLogger.LOG_NEW_HP(
      global.rankLogger.VIEW_TEAMS_PAGE,
      'VIEW_TEAMS_PAGE',
    );
    // Log the page view
    global.featuresLogger.LOG_TEAMS_TEAM_TEAM_NOTIFICATION_PAGE_VIEW();

    // Call load notifications function if the arr is still empty
    if (this.state.notificationsArr.length < 1) {
      // Load team notifications
      Teamcore.loadTeamNotifications(this.state.teamId).then((snapshot) => {
        let notificationsArr = [];
        snapshot.forEach((item) => {
          notificationsArr.push(item.val());
        });
        notificationsArr.reverse();
        this.setState({notificationsArr});
      });
    }
  }

  componentWillUnmount() {
    this.changeNotificationsStatus();
  }

  // Change notifications status
  changeNotificationsStatus() {
    // now get the path and upload the arr
    let path =
      FirebasePaths.FIREBASE_TEAMS +
      '/' +
      this.state.teamId +
      '/' +
      FirebasePaths.FIREBASE_TEAM_NOTIFICATIONS;

    // First loop through the arr and change the notifications status
    this.state.notificationsArr.forEach((notification) => {
      if (notification.status === 'UNREAD') {
        notification.status = 'READ';
        firebase.database()
          .ref(path + '/' + notification.id)
          .set(notification);
      }
    });
  }

  renderCard = ({item, index}) => {
    return (
      <View style={{marginBottom: perfectSize(16)}}>
        <NotificationCard notification={item} />
      </View>
    );
  };

  // Getting Animated Gif for the right mode
  getGif() {
    if (this.props.theme.mode === 'dark') {
      return NOTIFICATION_LOADING_DARK_GIF;
    } else {
      return NOTIFICATION_LOADING_GIF;
    }
  }

  render() {
    const {notificationsArr} = this.state;
    let unreadArr = [];
    let readArr = [];
    notificationsArr.forEach((notification) => {
      if (notification.status === 'UNREAD') {
        unreadArr.push(notification);
      } else {
        readArr.push(notification);
      }
    });
    const sections = [
      {
        title: I18n.t('NOTIFICATIONS_PAGE.new'),
        data: unreadArr,
      },
      {
        title: I18n.t('NOTIFICATIONS_PAGE.earlier'),
        data: readArr,
      },
    ];
    return (
      <View style={AppStyles.rootContainer}>
        <Header text={I18n.t('TEAM_NOTIFICATIONS_PAGE.title')} />
        {/* Content */}
        <View style={AppStyles.rootContent}>
          {notificationsArr.length > 0 ? (
            <SectionList
              stickySectionHeadersEnabled={false}
              showsVerticalScrollIndicator={false}
              sections={sections}
              renderItem={(item) => this.renderCard(item)}
              renderSectionHeader={({section}) => (
                <View>
                  {section.data.length > 0 ? (
                    <Label style={styles.header}>{section.title}</Label>
                  ) : null}
                </View>
              )}
              keyExtractor={(item, index) => item + index}
            />
          ) : (
            <View style={styles.emptyContent}>
              <View style={AppStyles.emptyContent}>
                {/* <FastImage
                  source={this.getGif()}
                  style={AppStyles.emptyContentGif}
                  resizeMode="contain"
                /> */}
                <Label style={AppStyles.emptyContentLabel}>
                  {I18n.t('TEAM_NOTIFICATIONS_PAGE.no_notifications_msg')}
                </Label>
              </View>
            </View>
          )}
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
    lang: state.appReducer.currentLang,
  };
};

export default compose(connect(mapStateToProps, {}))(TeamNotificationsPage);
