import React, { memo, Component } from 'react';
import { View, Image, StyleSheet } from 'react-native';
import { connect } from 'react-redux';

import { LinearGradient } from 'expo-linear-gradient';

import ImageWithPlaceholder from '../image-with-placeholder';

import LocalImageOpacity from '../local-image-opacity';

class OverlayImage extends Component {
  render() {
    const { theme, uri, overlayImage, overlayView } = this.props;
    const _styles = styles(theme);
    return (
      <View style={this.props.style}>
        <View style={_styles.original}>
          <ImageWithPlaceholder uri={uri} style={_styles.originalImage} />
        </View>
        {overlayView != null ? (
          overlayView
        ) : overlayImage != null ? (
          <LocalImageOpacity source={overlayImage} style={_styles.overlay} />
        ) : (
          <LinearGradient
            colors={['rgba(0, 0, 0, 0)', 'rgba(0, 0, 0, 0.6)']}
            style={_styles.overlay}
          />
        )}
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
  };
};

export default memo(connect(mapStateToProps)(OverlayImage));

const styles = (theme) =>
  StyleSheet.create({
    original: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
    originalImage: {
      width: '100%',
      height: '100%',
    },
    overlay: {
      width: '100%',
      height: '100%',
      opacity: 1,
    },
  });
