import React, { Component } from 'react';
import {
  View,
  TouchableOpacity,
  FlatList,
  Linking,
  ActivityIndicator,
  RefreshControl,
} from 'react-native';
import Feather from 'react-native-vector-icons/Feather';
import { compose } from 'redux';
import { connect } from 'react-redux';
import I18n from '../../../i18n';

import HopCard from '../../../components/cards/hop-card';
import HopSkeletonCard from '../../../components/cards/hop-card/skeleton';
import PrimaryButton from '../../../components/buttons/primary-button';
import ImageWithPlaceholder from '../../../components/image-with-placeholder';

// import styles
import styles from './styles';

// import actions
import { switchTheme } from '../../../redux/modules/app/actions';
import {
  showAlert,
  showSpinner,
  hideSpinner,
} from '../../../redux/modules/alert/actions';
import { set as HubSet } from '../../../redux/modules/hub/actions';

import { perfectSize } from '../../../helpers/deviceHelper';
import NavigationService from '../../../navigation/navigationService';
import TimelineCore from '../timelinecore';
import AdService from '../../../services/adSpot.service';

import { presentToast } from '../../../helpers/toastHelper';
import { arrayFromSnapshot, dataForSkeleton } from '../../../services/utils';
import { LOADING_HOPS_DARK, LOADING_HOPS } from '../../../constants/images';
import { useIsFocused } from '@react-navigation/native';

class HopsPage extends Component {
  recentPosts$ = null;

  constructor(props) {
    super(props);
    this.state = {
      posts: [],
      displayPosts: [],
      isLoading: true,
      adminsList: new Map(),
      ad: null,
      loadAdFinished: false,
    };
  }

  componentDidMount() {
    this.loadPosts();

    this.interval = setInterval(
      () => this.setState({ time: Date.now() }),
      60 * 1000,
    );
  }

  componentWillUnmount() {
    if (this.recentPosts$) {
      this.recentPosts$.off();
      this.recentPosts$ = null;
    }

    clearInterval(this.interval);
  }

  loadPosts() {
    this.recentPosts$ = TimelineCore.loadRecentPosts();

    this.recentPosts$.on('value', (snapshot) => {
      let posts = arrayFromSnapshot(snapshot);
      posts.reverse();
      posts = posts.filter(
        (post) => !this.props.bannedUsers.includes(post.uid),
      );
      // let finalPosts = Array.from(new Set(posts.map((a) => a.id))).map((id) => {
      //   return posts.find((a) => a.id === id);
      // });

      const displayPosts = this.state.displayPosts.length == 0
        ? posts.slice(0, 50)
        : posts.slice(0, this.state.displayPosts.length);
      this.setState({
        posts: posts,
        displayPosts: displayPosts,
        // isLoading: false,
      });
      const preloadUris = [];
      displayPosts.slice(0, 5).map((post) => {
        if (post.requestImg) {
          preloadUris.push({
            uri: post.requestImg
          })
        } else if (post.postImg) {
          preloadUris.push({
            uri: post.postImg
          })
        }
        if (post.images && post.images.length > 0) {
          post.images.map((img) => {
            preloadUris.push({
              uri: img
            })
          })
        }
      })

      TimelineCore.loadADSpot((response) => {
        if (response) {
          preloadUris.push({
            uri: response.img
          })
          this.setState({
            ad: response,
            loadAdFinished: true
          });
        } else {
          this.setState({
            loadAdFinished: true
          })
        }

        setTimeout(() => {
          this.setState({
            isLoading: false
          })
        }, 1500);
      });
    });

    TimelineCore.loadAdminsList((response) => {
      let adminList = response.val();
      let adminsList = new Map();
      if (adminList) {
        Object.keys(adminList).forEach((key) => {
          adminsList.set(key, adminList[key]);
        });
      }

      this.setState({
        adminsList: adminsList,
      });
    });
  }

  goToDetails = (item) => {
    // NavigationService.moveToScreenInStack('HomeStack', 'PostPage');
    if (item.type === 'REQUEST') {
      this.toLobby(item);
    } else {
      TimelineCore.IS_REPLY_HOP_BANNED((banned) => {
        if (banned) {
          presentToast({
            message: I18n.t('TIMELINE_PAGE.ban_alert_msg2'),
          });
        } else {
          NavigationService.moveToScreenInStack('HomeStack', 'PostPage', {
            POST_ID: item.id,
          });
        }
      });
    }
  };

  // This function will load the last req from search to lobby
  toLobby = (req) => {
    NavigationService.moveToScreenInStack('HomeStack', 'LobbyPage', {
      lastReq: req,
      fromTimeline: true,
    });
  };

  sendHop = () => {
    TimelineCore.IS_POST_HOP_BANNED((response) => {
      if (response == null) {
        NavigationService.moveToScreenInStack('HomeStack', 'PostHopPage');
      } else {
        this.props.showAlert([
          I18n.t('TIMELINE_PAGE.ban_alert_title'),
          I18n.t('TIMELINE_PAGE.ban_alert_msg'),
          [
            {
              text: I18n.t('TIMELINE_PAGE.ban_alert_btn'),
              onPress: () => {
                NavigationService.moveToScreenInStack(
                  'SettingsStack',
                  'ContactUsPage',
                );
              },
            },
          ],
          false,
        ]);
      }
    });
  };

  doInfinite = () => {
    if (this.state.displayPosts.length === this.state.posts.length) {
      return;
    }

    setTimeout(() => {
      let limit = 50;
      if (this.state.posts.length - this.state.displayPosts.length < limit) {
        limit = this.state.posts.length - this.state.displayPosts.length;
      }
      let displayPost = this.state.displayPosts;
      displayPost = this.state.displayPosts.concat(
        this.state.posts.slice(displayPost.length, displayPost.length + limit),
      );
      this.setState({
        displayPosts: displayPost,
      });
    }, 500);
  };

  openHopsAD = () => {
    global.featuresLogger.HOP_AD_CLICK();
    if (this.state.ad.url) {
      AdService.checkAdUrl(this.state.ad);
    }
  };

  getGif() {
    if (this.props.theme.mode === 'dark') {
      return LOADING_HOPS_DARK;
    } else {
      return LOADING_HOPS;
    }
  }

  render() {
    const { theme, isFocused } = this.props;
    const { isLoading, ad, loadAdFinished } = this.state;
    if (isFocused) {
      const _styles = styles(theme);
      return (
        <View style={_styles.container}>
          <View style={_styles.content}>
            <PrimaryButton
              text={I18n.t('POST_PAGE.new_post')}
              size="x_small"
              type="filled"
              customStyles={_styles.postButtonStyle}
              textCustomStyles={_styles.postButtonTextStyle}
              onPress={this.sendHop}
              leftComponent={
                <Feather
                  name="plus-circle"
                  size={perfectSize(18)}
                  color={theme.primary_text}
                  style={{ marginRight: perfectSize(12) }}
                />
              }
            />
            <View style={_styles.subContent}>
              {(isLoading || !loadAdFinished) ? (
                <FlatList
                  data={dataForSkeleton(10)}
                  renderItem={({ item, index }) => {
                    return <HopSkeletonCard />;
                  }}
                  showsVerticalScrollIndicator={false}
                  style={{ width: '100%', }}
                  keyExtractor={(item, index) => {
                    return index.toString()
                  }}
                />
              ) : (
                <FlatList
                  data={this.state.displayPosts}
                  style={{ width: '100%' }}
                  showsVerticalScrollIndicator={false}
                  renderItem={({ item, index }) =>
                    !this.props.blockedList.has(item.uid) && (
                      <HopCard
                        item={item}
                        index={index}
                        onPress={() => this.goToDetails(item)}
                        isAdmin={this.state.adminsList.has(item.uid)}
                      />
                    )
                  }
                  keyExtractor={(item, index) => {
                    if (item.type === 'TEXT_ONLY') {
                      return item.id;
                    } else {
                      return index + '' + item.requestTimeStamp;
                    }
                  }}
                  onEndReached={this.doInfinite}
                  onEndReachedThreshold={0.5}
                  ListHeaderComponent={
                    <>
                      {loadAdFinished && ad !== null && ad.img !== null && (
                        <TouchableOpacity
                          onPress={this.openHopsAD}
                          style={{ marginBottom: perfectSize(16) }}>
                          <View style={_styles.adCard}>
                            <ImageWithPlaceholder
                              style={_styles.adCover}
                              uri={ad.img}
                              onLoadEnd={() => this.setState({ showDefault: false })}
                              onError={() => this.setState({ error: true })}
                              resizeMode={'cover'}
                            />

                          </View>
                        </TouchableOpacity>
                      )}
                    </>
                  }
                  refreshControl={
                    <RefreshControl
                      refreshing={this.state.refresh}
                      tintColor={'white'}
                      onRefresh={() => {
                        this.setState({
                          refresh: true,
                        });
                        setTimeout(() => {
                          this.setState({
                            refresh: false,
                          });
                        }, 2000);
                      }}
                    />
                  }
                />
              )}
            </View>
          </View>
        </View>
      );
    }
    return <View />;
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
    lang: state.appReducer.currentLang,
    blockedList: state.hubReducer.blockedList,
    bannedUsers: state.hubReducer.bannedUsers,
  };
};

function focusComponent(props) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const isFocused = useIsFocused();
  return <HopsPage {...props} isFocused={isFocused} />;
}

export default compose(
  connect(mapStateToProps, {
    switchTheme,
    HubSet,
    showSpinner,
    hideSpinner,
    showAlert,
  }),
)(focusComponent);
