import * as FirebasePaths from '../constants/firebasePaths';
import { store } from '../redux/store';
import firebase from 'firebase/app';
import 'firebase/database';

import { set as setHub } from '../redux/modules/hub/actions';
import { showAlert } from '../redux/modules/alert/actions';

import NavigationService from '../navigation/navigationService';
import MainMenuService from '../navigation/mainMenuService';
import Gamemodel from '../models/gamemodel';
import { presentToast } from '../helpers/toastHelper';
import topTabService from '../navigation/topTabService';

export default class MyApp {
  constructor() { }

  // Subscribe on notification event
  static isInProgress = false;
  static inProgressNotifications = [];

  static localNotificationInterval = null;

  static showNotification(data) {
    const state = store.getState();
    if (
      state.hubReducer.IS_LOCAL_NOTIFICATION_ACTIVATED &&
      !state.hubReducer.isKeyboardOpen
    ) {
      // Toast local notifications
      if (data.TYPE === 'TEAM_REQUEST') {
        // getting the game name
        let gameName = state.hubReducer.gamesList[data.gameId].gameName;

        presentToast({
          message: gameName + 'طلب جديد على لعبة 🤖',
          duration: 3000,
          position: 'top',
          hideOnPress: true,
          closeButtonText: 'عرض',
          onPress: () => {
            // Set the trigger value
            store.dispatch(
              setHub({
                PUSH_NOTIFICATION_TRIGGER1: data,
                USER_PUSH_NOTIFICATION_DESIRED_PAGE: 'TEAM_REQUEST',
              }),
            );
            NavigationService.moveToScreenInStack(
              'HomeStack',
              'LobbyPage',
              {
                CAME_FROM: 'TEAM_REQUEST',
                REQUEST_SHOT: data,
              },
            );
          },
          onHidden: () => {
            this.isInProgress = false;
          }
        });
      } else if (
        (data.TYPE === 'PRIVATE_CHAT' || data.TYPE === 'PUBLIC_CHAT') &&
        state.hubReducer.IS_IN_CHAT === false
      ) {
        // If the user sent a photo
        let formattedMessage = data.LOCAL_NOTIFICATION_MESSAGE;
        if (
          formattedMessage.includes(
            'https://firebasestorage.googleapis.com',
          )
        ) {
          formattedMessage = 'sent photo';
        } else {
          formattedMessage = data.LOCAL_NOTIFICATION_MESSAGE;
        }

        presentToast({
          message: formattedMessage,
          duration: 3000,
          position: 'top',
          hideOnPress: true,
          closeButtonText: 'عرض',
          onPress: () => {
            // Set the trigger value
            store.dispatch(
              setHub({
                PUSH_NOTIFICATION_TRIGGER1: data,
                USER_PUSH_NOTIFICATION_DESIRED_PAGE: 'CHAT',
              }),
            );

            if (data.CHAT_TYPE === 'PRIVATE') {
              NavigationService.moveToScreenInStack(
                'HomeStack',
                'PrivateChatPage',
                {
                  CAME_FROM: 'NOTIFICATION',
                  TRIGGER: data,
                },
              );
            } else {
              NavigationService.moveToScreenInStack(
                'HomeStack',
                'ChatPage',
                {
                  CAME_FROM: 'NOTIFICATION',
                  TRIGGER: data,
                },
              );
            }
          },
          onHidden: () => {
            this.isInProgress = false;
          }
        });
      } else if (data.TYPE === 'TEAM_JOINS') {
        presentToast({
          message: data.LOCAL_NOTIFICATION_MESSAGE,
          duration: 3000,
          position: 'top',
          hideOnPress: true,
          closeButtonText: 'عرض',
          onPress: () => {
            // Set the trigger value
            store.dispatch(
              setHub({
                PUSH_NOTIFICATION_TRIGGER1: data,
                USER_PUSH_NOTIFICATION_DESIRED_PAGE: 'TEAMS_JOINS',
              }),
            );
            NavigationService.moveToScreenInStack(
              'HomeStack',
              'TeamJoinRequestsPage',
              {
                TEAM_ID: data.TRIGGER,
                TEAM_NAME: data.TRIGGER2,
                TEAM_LOGO: data.TRIGGER3,
              },
            );
          },
          onHidden: () => {
            this.isInProgress = false;
          }
        });
      } // End of team joins
      else if (data.TYPE === 'ADD_FRIEND') {
        presentToast({
          message: data.LOCAL_NOTIFICATION_MESSAGE,
          duration: 3000,
          position: 'top',
          hideOnPress: true,
          closeButtonText: 'عرض',
          onPress: () => {
            NavigationService.navigate('ViewfriendprofilePage', {
              FRIEND_KEY: data.TRIGGER,
            });
          },
          onHidden: () => {
            this.isInProgress = false;
          }
        });
      } else if (data.TYPE === 'HOP_LIKE') {
        presentToast({
          message: data.LOCAL_NOTIFICATION_MESSAGE,
          duration: 3000,
          position: 'top',
          hideOnPress: true,
          closeButtonText: 'عرض',
          onPress: () => {
            NavigationService.navigate('PostPage', {
              CAME_FROM: 'NOTIFICATION',
              POST_ID: data.TRIGGER,
            });
          },
          onHidden: () => {
            this.isInProgress = false;
          }
        });
      } else if (data.TYPE === 'HOP_DISLIKE') {
        presentToast({
          message: data.LOCAL_NOTIFICATION_MESSAGE,
          duration: 3000,
          position: 'top',
          hideOnPress: true,
          closeButtonText: 'عرض',
          onPress: () => {
            NavigationService.navigate('PostPage', {
              CAME_FROM: 'NOTIFICATION',
              POST_ID: data.TRIGGER,
            });
          },
          onHidden: () => {
            this.isInProgress = false;
          }
        });
      } else if (
        data.TYPE === 'HOP' &&
        state.hubReducer.IS_IN_CHAT === false
      ) {
        presentToast({
          message: data.LOCAL_NOTIFICATION_MESSAGE,
          duration: 3000,
          position: 'top',
          hideOnPress: true,
          closeButtonText: 'عرض',
          onPress: () => {
            NavigationService.navigate('PostPage', {
              CAME_FROM: 'NOTIFICATION',
              POST_ID: data.TRIGGER,
            });
          },
          onHidden: () => {
            this.isInProgress = false;
          }
        });
      } else if (data.TYPE === 'HOP_MENTION') {
        presentToast({
          message: data.LOCAL_NOTIFICATION_MESSAGE,
          duration: 3000,
          position: 'top',
          hideOnPress: true,
          closeButtonText: 'عرض',
          onPress: () => {
            NavigationService.navigate('PostPage', {
              CAME_FROM: 'NOTIFICATION',
              POST_ID: data.TRIGGER,
            });
          },
          onHidden: () => {
            this.isInProgress = false;
          }
        });
      } else if (data.TYPE === 'TEAM_INVITATION') {
        presentToast({
          message: data.LOCAL_NOTIFICATION_MESSAGE,
          duration: 3000,
          position: 'top',
          hideOnPress: true,
          closeButtonText: 'عرض',
          onPress: () => {
            NavigationService.navigate('TabStack');
            setTimeout(() => {
              topTabService.navigate('Requests');
              setTimeout(() => {
                topTabService.navigate('Clans', {
                  SELECTED_SECTION: 'TEAMS_INVITATIONS',
                });
              }, 100);
            }, 100);
          },
          onHidden: () => {
            this.isInProgress = false;
          }
        });
      } else if (data.TYPE === 'TEAM_ACCEPT') {
        presentToast({
          message: data.LOCAL_NOTIFICATION_MESSAGE,
          duration: 3000,
          position: 'top',
          hideOnPress: true,
          closeButtonText: 'عرض',
          onPress: () => {
            NavigationService.navigate('TeamPage', {
              TEAM_ID: data.TRIGGER,
              CAME_FROM: 'TEAMS',
            });
          },
          onHidden: () => {
            this.isInProgress = false;
          }
        });
      } else if (data.TYPE === 'CLAN_DIRECT_JOIN') {
        presentToast({
          message: data.LOCAL_NOTIFICATION_MESSAGE,
          duration: 3000,
          position: 'top',
          hideOnPress: true,
          closeButtonText: 'عرض',
          onPress: () => {
            NavigationService.navigate('TeamPage', {
              TEAM_ID: data.TRIGGER,
              CAME_FROM: 'TEAMS',
            });
          },
          onHidden: () => {
            this.isInProgress = false;
          }
        });
      } else if (
        data.TYPE === 'CLAN_CHAT' &&
        state.hubReducer.IS_IN_CHAT === false
      ) {
        // If the user sent a photo
        let formattedMessage = data.LOCAL_NOTIFICATION_MESSAGE;
        if (
          formattedMessage.includes(
            'https://firebasestorage.googleapis.com',
          )
        ) {
          formattedMessage = 'sent photo';
        } else {
          formattedMessage = data.LOCAL_NOTIFICATION_MESSAGE;
        }
        presentToast({
          message: formattedMessage,
          duration: 3000,
          position: 'top',
          hideOnPress: true,
          closeButtonText: 'عرض',
          onPress: () => {
            // First load the admin data to pass it to the team chat page
            firebase.database()
              .ref(
                FirebasePaths.FIREBASE_TEAMS +
                '/' +
                data.TRIGGER +
                '/' +
                FirebasePaths.FIREBASE_TEAM_INFO,
              )
              .once('value')
              .then((snapshot) => {
                let obj = snapshot.val();
                if (obj.admin != null) {
                  NavigationService.moveToScreenInStack(
                    'HomeStack',
                    'TeamChatPage',
                    {
                      TEAM_ID: obj.id,
                      TEAM_LOGO: obj.logoUrl,
                      TEAM_NAME: obj.name,
                      ADMIN_ID: obj.admin,
                    },
                  );
                } else {
                  store.dispatch(
                    showAlert([
                      'شيت يا رجال',
                      'فيه مشكلة في شات الكلان تواصل معنا بنحلها ',
                      [
                        {
                          text: 'اضغط هنا !',
                          onPress: () => {
                            NavigationService.moveToScreenInStack(
                              'SettingsStack',
                              'SupportPage',
                            );
                          },
                        },
                      ],
                    ]),
                  );
                }
              });
          },
          onHidden: () => {
            this.isInProgress = false;
          }
        });
      } else if (data.TYPE === 'UPDATE') {
        presentToast({
          message: data.LOCAL_NOTIFICATION_MESSAGE,
          duration: 3000,
          position: 'top',
          hideOnPress: true,
          closeButtonText: 'عرض',
          onPress: () => {
            NavigationService.moveToScreenInStack(
              'SettingsStack',
              'EditProfilePage',
            );
          },
          onHidden: () => {
            this.isInProgress = false;
          }
        });
      } else if (data.TYPE === 'RATE') {
        presentToast({
          message: data.LOCAL_NOTIFICATION_MESSAGE,
          duration: 3000,
          position: 'top',
          hideOnPress: true,
          closeButtonText: 'عرض',
          onPress: () => {
            MainMenuService.navigate('Profile');
          },
          onHidden: () => {
            this.isInProgress = false;
          }
        });
      } else if (data.TYPE === 'JOIN_REQUEST') {
        presentToast({
          message: data.LOCAL_NOTIFICATION_MESSAGE,
          duration: 3000,
          position: 'top',
          hideOnPress: true,
          closeButtonText: 'عرض',
          onPress: () => {
            // Set the trigger value
            store.dispatch(
              setHub({
                PUSH_NOTIFICATION_TRIGGER1: data.TRIGGER,
                USER_PUSH_NOTIFICATION_DESIRED_PAGE: 'CHAT',
              }),
            );
            NavigationService.moveToScreenInStack('HomeStack', 'ChatPage', {
              CAME_FROM: 'NOTIFICATION',
              TRIGGER: data.TRIGGER,
            });
          },
          onHidden: () => {
            this.isInProgress = false;
          }
        });
      }
    }
  }

  static handleInProgressLocalNotifications() {
    if (this.inProgressNotifications.length <= 0) {
      clearInterval(this.localNotificationInterval)
      this.localNotificationInterval = null;
    }
    if (this.isInProgress) {
      return;
    } else {
      if (this.inProgressNotifications.length > 0) {
        const localNotif = this.inProgressNotifications[0];
        this.isInProgress = true;
        this.showNotification(localNotif);
        this.inProgressNotifications.shift();
        setTimeout(() => {
          this.isInProgress = false;
        }, 3000);
      } else {
        clearInterval(this.localNotificationInterval);
        this.localNotificationInterval = null;
      }
    }
  }

  static handleNotification() {
    // Foreground message
    firebase.messaging().onMessage((remoteMessage) => {
      if (remoteMessage) {
        const data = remoteMessage.data;
        if (this.isInProgress) {
          this.inProgressNotifications.push(data);
        }
        if (this.inProgressNotifications.length > 0 && !this.localNotificationInterval) {
          this.localNotificationInterval = setInterval(() => {
            this.handleInProgressLocalNotifications();
          }, 4000);
        }
        if (!this.isInProgress) {
          this.isInProgress = true;
          this.showNotification(data);
          setTimeout(() => {
            this.isInProgress = false;
          }, 3000);
        }
      }
    });

    // Opened from a quit state
    firebase.messaging()
      .getInitialNotification()
      .then((remoteMessage) => {
        this.handleBackgroundMessage(remoteMessage);
      });
    // Opened from background
    firebase.messaging().onNotificationOpenedApp((remoteMessage) => {
      if (remoteMessage) {
        this.handleBackgroundMessage(remoteMessage);
      }
    });
  }

  static handleBackgroundMessage(remoteMessage) {
    const uid = store.getState().authReducer.uid;
    if (remoteMessage) {
      const data = remoteMessage.data;
      if (uid == null || uid === '') {
        if (data.TYPE === 'TEAM_REQUEST') {
          // Set the trigger value
          store.dispatch(
            setHub({
              PUSH_NOTIFICATION_TRIGGER1: data,
              USER_PUSH_NOTIFICATION_DESIRED_PAGE: 'TEAM_REQUEST',
              USER_CLICKED_NOTIFICATION_FOREGROUND_MODE: true,
            }),
          );
        } else if (
          data.TYPE === 'PRIVATE_CHAT' ||
          data.TYPE === 'PUBLIC_CHAT'
        ) {
          // Set the trigger value
          store.dispatch(
            setHub({
              PUSH_NOTIFICATION_TRIGGER1: data,
              USER_PUSH_NOTIFICATION_DESIRED_PAGE: 'CHAT',
              USER_CLICKED_NOTIFICATION_FOREGROUND_MODE: true,
            }),
          );
        } else if (data.TYPE === 'TEAM_JOINS') {
          // Set the trigger value
          store.dispatch(
            setHub({
              PUSH_NOTIFICATION_TRIGGER1: data,
              USER_PUSH_NOTIFICATION_DESIRED_PAGE: 'TEAM_JOINS',
              USER_CLICKED_NOTIFICATION_FOREGROUND_MODE: true,
            }),
          );
        } else {
          // Other notifications ..
          this.REDIRECTOR(data.TYPE, data.TRIGGER);
        }
      } else {
        if (data.TYPE === 'TEAM_REQUEST') {
          // Set the trigger value
          store.dispatch(
            setHub({
              PUSH_NOTIFICATION_TRIGGER1: data,
              USER_PUSH_NOTIFICATION_DESIRED_PAGE: 'TEAM_REQUEST',
            }),
          );
          NavigationService.moveToScreenInStack('HomeStack', 'LobbyPage', {
            CAME_FROM: 'TEAM_REQUEST',
            REQUEST_SHOT: data,
          });
        } else if (
          data.TYPE === 'PRIVATE_CHAT' ||
          data.TYPE === 'PUBLIC_CHAT'
        ) {
          // Set the trigger value
          store.dispatch(
            setHub({
              PUSH_NOTIFICATION_TRIGGER1: data,
              USER_PUSH_NOTIFICATION_DESIRED_PAGE: 'CHAT',
            }),
          );

          if (data.CHAT_TYPE === 'PRIVATE') {
            NavigationService.moveToScreenInStack(
              'HomeStack',
              'PrivateChatPage',
              {
                CAME_FROM: 'NOTIFICATION',
                TRIGGER: data,
              },
            );
          } else {
            NavigationService.moveToScreenInStack('HomeStack', 'ChatPage', {
              CAME_FROM: 'NOTIFICATION',
              TRIGGER: data,
            });
          }
        } else if (data.TYPE === 'TEAM_JOINS') {
          // Set the trigger value
          store.dispatch(
            setHub({
              PUSH_NOTIFICATION_TRIGGER1: data,
              USER_PUSH_NOTIFICATION_DESIRED_PAGE: 'TEAMS_JOINS',
            }),
          );
          NavigationService.moveToScreenInStack(
            'HomeStack',
            'TeamJoinRequestsPage',
            {
              TEAM_ID: data.TRIGGER,
              TEAM_NAME: data.TRIGGER2,
              TEAM_LOGO: data.TRIGGER3,
            },
          );
        } else {
          // Other notifications ..
          this.REDIRECTOR2(data.TYPE, data.TRIGGER);
        }
      }
    }
  }

  // This function will handle the push notification click - redirection the user to the correct page : FOREGROUND  MODE
  static REDIRECTOR(type, trigger) {
    store.dispatch(setHub({ USER_CLICKED_NOTIFICATION_FOREGROUND_MODE: true }));
    if (type === 'ADD_FRIEND') {
      store.dispatch(
        setHub({
          USER_PUSH_NOTIFICATION_DESIRED_PAGE: 'VIEW_FRIEND_PROFILE',
          PUSH_NOTIFICATION_TRIGGER1: trigger,
        }),
      );
    } else if (type === 'HOP_LIKE') {
      store.dispatch(
        setHub({
          USER_PUSH_NOTIFICATION_DESIRED_PAGE: 'HOP',
          PUSH_NOTIFICATION_TRIGGER1: trigger,
        }),
      );
    } else if (type === 'HOP_DISLIKE') {
      store.dispatch(
        setHub({
          USER_PUSH_NOTIFICATION_DESIRED_PAGE: 'HOP',
          PUSH_NOTIFICATION_TRIGGER1: trigger,
        }),
      );
    } else if (type === 'HOP') {
      store.dispatch(
        setHub({
          USER_PUSH_NOTIFICATION_DESIRED_PAGE: 'HOP',
          PUSH_NOTIFICATION_TRIGGER1: trigger,
        }),
      );
    } else if (type === 'HOP_MENTION') {
      store.dispatch(
        setHub({
          USER_PUSH_NOTIFICATION_DESIRED_PAGE: 'HOP_MENTION',
          PUSH_NOTIFICATION_TRIGGER1: trigger,
        }),
      );
    } else if (type === 'JOIN_REQUEST') {
      // Set the trigger value
      store.dispatch(
        setHub({
          USER_PUSH_NOTIFICATION_DESIRED_PAGE: 'CHAT',
          PUSH_NOTIFICATION_TRIGGER1: trigger,
          USER_CLICKED_NOTIFICATION_FOREGROUND_MODE: true,
        }),
      );
    } else if (type === 'CLAN_CHAT') {
      // Set the trigger value
      store.dispatch(
        setHub({
          USER_PUSH_NOTIFICATION_DESIRED_PAGE: 'CLAN_CHAT',
          PUSH_NOTIFICATION_TRIGGER1: trigger,
          USER_CLICKED_NOTIFICATION_FOREGROUND_MODE: true,
        }),
      );
    } else if (type === 'TEAM_INVITATION') {
      // Set the trigger value
      store.dispatch(
        setHub({
          USER_PUSH_NOTIFICATION_DESIRED_PAGE: 'TEAMS',
          PUSH_NOTIFICATION_TRIGGER1: trigger,
          USER_CLICKED_NOTIFICATION_FOREGROUND_MODE: true,
        }),
      );
    } else if (type === 'TEAM_ACCEPT') {
      // Set the trigger value
      store.dispatch(
        setHub({
          USER_PUSH_NOTIFICATION_DESIRED_PAGE: 'TEAM_ACCEPT',
          PUSH_NOTIFICATION_TRIGGER1: trigger,
          USER_CLICKED_NOTIFICATION_FOREGROUND_MODE: true,
        }),
      );
    } else if (type === 'CLAN_DIRECT_JOIN') {
      // Set the trigger value
      store.dispatch(
        setHub({
          USER_PUSH_NOTIFICATION_DESIRED_PAGE: 'CLAN_DIRECT_JOIN',
          PUSH_NOTIFICATION_TRIGGER1: trigger,
          USER_CLICKED_NOTIFICATION_FOREGROUND_MODE: true,
        }),
      );
    } else if (type === 'UPDATE') {
      // Set the trigger value
      store.dispatch(
        setHub({
          USER_PUSH_NOTIFICATION_DESIRED_PAGE: 'EDIT_PROFILE',
          PUSH_NOTIFICATION_TRIGGER1: trigger,
          USER_CLICKED_NOTIFICATION_FOREGROUND_MODE: true,
        }),
      );
    } else if (type === 'RATE') {
      // Set the trigger value
      store.dispatch(
        setHub({
          USER_PUSH_NOTIFICATION_DESIRED_PAGE: 'PROFILE',
          PUSH_NOTIFICATION_TRIGGER1: trigger,
          USER_CLICKED_NOTIFICATION_FOREGROUND_MODE: true,
        }),
      );
    } else {
      // Something else ...
    }
  }

  // This function will handle the push notification click - redirection the user to the correct page : BACKGROUND MODE
  static REDIRECTOR2(type, trigger) {
    if (type === 'ADD_FRIEND') {
      NavigationService.navigate('ViewfriendprofilePage', {
        FRIEND_KEY: trigger,
      });
    } else if (type === 'HOP_LIKE') {
      NavigationService.navigate('PostPage', {
        CAME_FROM: 'NOTIFICATION',
        POST_ID: trigger,
      });
    } else if (type === 'HOP_DISLIKE') {
      NavigationService.navigate('PostPage', {
        CAME_FROM: 'NOTIFICATION',
        POST_ID: trigger,
      });
    } else if (type === 'HOP') {
      NavigationService.navigate('PostPage', {
        CAME_FROM: 'NOTIFICATION',
        POST_ID: trigger,
      });
    } else if (type === 'HOP_MENTION') {
      NavigationService.navigate('PostPage', {
        CAME_FROM: 'NOTIFICATION',
        POST_ID: trigger,
      });
    } else if (type === 'TEAM_INVITATION') {
      NavigationService.navigate('TabStack');
      setTimeout(() => {
        topTabService.navigate('Requests');
        setTimeout(() => {
          topTabService.navigate('Clans', {
            SELECTED_SECTION: 'TEAMS_INVITATIONS',
          });
        }, 150);
      }, 150);
    } else if (type === 'TEAM_ACCEPT') {
      // Redirect the user to the team page
      NavigationService.navigate('TeamPage', {
        TEAM_ID: trigger,
        CAME_FROM: 'TEAMS',
      });
    } else if (type === 'CLAN_DIRECT_JOIN') {
      // Redirect the user to the team page
      NavigationService.navigate('TeamPage', {
        TEAM_ID: trigger,
        CAME_FROM: 'TEAMS',
      });
    } else if (type === 'CLAN_CHAT') {
      // First load the admin data to pass it to the team chat page
      firebase.database()
        .ref(
          FirebasePaths.FIREBASE_TEAMS +
          '/' +
          trigger +
          '/' +
          FirebasePaths.FIREBASE_TEAM_INFO,
        )
        .once('value', (snapshot) => {
          let obj = snapshot.val();
          if (obj.admin != null) {
            NavigationService.moveToScreenInStack('HomeStack', 'TeamChatPage', {
              TEAM_ID: obj.id,
              TEAM_LOGO: obj.logoUrl,
              TEAM_NAME: obj.name,
              ADMIN_ID: obj.admin,
            });
          } else {
            store.dispatch(
              showAlert([
                'شيت يا رجال',
                'فيه مشكلة في شات الكلان تواصل معنا بنحلها ',
                [
                  {
                    text: 'اضغط هنا !',
                    onPress: () =>
                      NavigationService.moveToScreenInStack(
                        'SettingsStack',
                        'SupportPage',
                      ),
                    style: 'cancel',
                  },
                ],
              ]),
            );
          }
        });
    } else if (type === 'UPDATE') {
      NavigationService.moveToScreenInStack('SettingsStack', 'EditProfilePage');
    } else if (type === 'RATE') {
      // Todo
      //this.app.getRootNav().getActiveChildNav().select(5);
    } // HERE
    else if (type === 'JOIN_REQUEST') {
      // Set the trigger value
      store.dispatch(
        setHub({
          USER_PUSH_NOTIFICATION_DESIRED_PAGE: 'CHAT',
          PUSH_NOTIFICATION_TRIGGER1: trigger,
        }),
      );
      NavigationService.moveToScreenInStack('HomeStack', 'ChatPage', {
        CAME_FROM: 'NOTIFICATION',
        TRIGGER: trigger,
      });
    } else {
      // Something else ...
    }
  }

  static initialize() {
    // Load teaser attr
    this.loadTeaser();

    // // Load all  games from server
    // this.loadAllGames();

    // //   // Load all regions
    // this.loadAllRegions();

    // // Load all porhibited words
    // this.loadPorhibitedWords();

    // // Load share icons
    // this.loadShareIcons();

    // // Handle notification
    // this.handleNotification();
  }

  static async initializeOnce() {
    // Load teaser attr
    await this.loadTeaserOnce();

    // Load all  games from server
    await this.loadAllGamesOnce();

    //   // Load all regions
    await this.loadAllRegionsOnce();

    // Load all porhibited words
    await this.loadPorhibitedWordsOnce();

    // Load share icons
    await this.loadShareIconsOnce();
  }

  static loadAllGames() {
    // Call the load comp games function
    this.loadCompGames();
  }

  static async loadAllGamesOnce() {
    // Call the load comp games function
    await this.loadCompGamesOnce();
  }

  // Load all COMP games
  static loadCompGames() {
    // Getting the comp games DB ref
    let gamesRef = FirebasePaths.FIREBASE_GAMES_REFERENCES;
    let compAttr = FirebasePaths.FIREBASE_GAME_COMPETITVE_ATTR;
    let compGamesPath = '/' + gamesRef + '/' + compAttr;
    firebase.database()
      .ref(compGamesPath)
      .on('value', (compGamesShot) => {
        let gamesList = store.getState().hubReducer.gamesList;
        compGamesShot.forEach((item) => {
          // Get the game key
          let objKey = item.key;
          // Get the game obj / Payload
          let obj = item.val();

          // Create a game model and then fetch the data from the snapshot
          let gameModel = new Gamemodel();
          let game = obj;
          gameModel.gameID = objKey;
          gameModel.gameName = game.name;
          gameModel.gamePhotoUrl = game.photo;
          gameModel.gameLargePhotoUrl = game.large_photo;
          gameModel.maxPlayers = game.max_player;
          gameModel.gamePlatforms = game.platforms;
          gameModel.pcGameProvider = game.pc_game_provider;
          gameModel.tags = game.tags;

          let gameType = FirebasePaths.FIREBASE_GAME_COMPETITVE_ATTR;
          gameModel.gameType = gameType;

          let ranksList = new Map();

          let allRanks = game.ranks;
          for (var key in allRanks) {
            ranksList.set(key, allRanks[key]);
          }
          gameModel.ranksList = new Map([...ranksList.entries()].sort());

          //Finalyy add this game to the gamelist hub
          gamesList[gameModel.gameID] = gameModel;
        });
        store.dispatch(setHub({ gamesList }));
        // Call the load coop games function
        this.loadCoopGames();
      });
  }

  // Load all COMP games
  static async loadCompGamesOnce() {
    // Getting the comp games DB ref
    let gamesRef = FirebasePaths.FIREBASE_GAMES_REFERENCES;
    let compAttr = FirebasePaths.FIREBASE_GAME_COMPETITVE_ATTR;
    let compGamesPath = '/' + gamesRef + '/' + compAttr;
    const compGamesShot = await firebase.database()
      .ref(compGamesPath)
      .once('value');
    let gamesList = store.getState().hubReducer.gamesList;
    compGamesShot.forEach((item) => {
      // Get the game key
      let objKey = item.key;
      // Get the game obj / Payload
      let obj = item.val();

      // Create a game model and then fetch the data from the snapshot
      let gameModel = new Gamemodel();
      let game = obj;
      gameModel.gameID = objKey;
      gameModel.gameName = game.name;
      gameModel.gamePhotoUrl = game.photo;
      gameModel.gameLargePhotoUrl = game.large_photo;
      gameModel.maxPlayers = game.max_player;
      gameModel.gamePlatforms = game.platforms;
      gameModel.pcGameProvider = game.pc_game_provider;
      gameModel.tags = game.tags;

      let gameType = FirebasePaths.FIREBASE_GAME_COMPETITVE_ATTR;
      gameModel.gameType = gameType;

      let ranksList = new Map();

      let allRanks = game.ranks;
      for (var key in allRanks) {
        ranksList.set(key, allRanks[key]);
      }
      gameModel.ranksList = new Map([...ranksList.entries()].sort());

      //Finalyy add this game to the gamelist hub
      gamesList[gameModel.gameID] = gameModel;
    });
    store.dispatch(setHub({ gamesList }));
    // Call the load coop games function
    await this.loadCoopGamesOnce();
  }

  // Load all COOP games and insert it into hub games list
  static loadCoopGames() {
    // Getting the games DB ref
    let gamesRef = FirebasePaths.FIREBASE_GAMES_REFERENCES;
    let coopAttr = FirebasePaths.FIREBASE_GAME_COOP_ATTR;
    let coopGamesPath = '/' + gamesRef + '/' + coopAttr;

    firebase.database()
      .ref(coopGamesPath)
      .on('value', (coopGamesShot) => {
        let gamesList = store.getState().hubReducer.gamesList;
        coopGamesShot.forEach((item) => {
          // Get the game key
          let objKey = item.key;
          // Get the game object / Payload
          let obj = item.val();

          // Create a game model and then fetch the data from the snapshot
          let gameModel = new Gamemodel();
          let game = obj;
          gameModel.gameID = objKey;
          gameModel.gameName = game.name;
          gameModel.gamePhotoUrl = game.photo;
          gameModel.gameLargePhotoUrl = game.large_photo;
          gameModel.maxPlayers = game.max_player;
          gameModel.gamePlatforms = game.platforms;
          gameModel.pcGameProvider = game.pc_game_provider;
          gameModel.tags = game.tags;

          let gameType = FirebasePaths.FIREBASE_GAME_COOP_ATTR;
          gameModel.gameType = gameType;

          //Finalyy add this game to the gamelist hub
          gamesList[gameModel.gameID] = gameModel;
        });
        store.dispatch(setHub({ gamesList }));
      });
  }

  // Load all COOP games and insert it into hub games list
  static async loadCoopGamesOnce() {
    // Getting the games DB ref
    let gamesRef = FirebasePaths.FIREBASE_GAMES_REFERENCES;
    let coopAttr = FirebasePaths.FIREBASE_GAME_COOP_ATTR;
    let coopGamesPath = '/' + gamesRef + '/' + coopAttr;

    const coopGamesShot = await firebase.database()
      .ref(coopGamesPath)
      .once('value');
    let gamesList = store.getState().hubReducer.gamesList;
    coopGamesShot.forEach((item) => {
      // Get the game key
      let objKey = item.key;
      // Get the game object / Payload
      let obj = item.val();

      // Create a game model and then fetch the data from the snapshot
      let gameModel = new Gamemodel();
      let game = obj;
      gameModel.gameID = objKey;
      gameModel.gameName = game.name;
      gameModel.gamePhotoUrl = game.photo;
      gameModel.gameLargePhotoUrl = game.large_photo;
      gameModel.maxPlayers = game.max_player;
      gameModel.gamePlatforms = game.platforms;
      gameModel.pcGameProvider = game.pc_game_provider;
      gameModel.tags = game.tags;

      let gameType = FirebasePaths.FIREBASE_GAME_COOP_ATTR;
      gameModel.gameType = gameType;

      //Finalyy add this game to the gamelist hub
      gamesList[gameModel.gameID] = gameModel;
    });
    store.dispatch(setHub({ gamesList }));
  }

  static loadTeaser() {
    firebase.database()
      .ref('Teaser' + '/' + '_teaser_')
      .on('value', (data) => {
        // Assign the teaser attr
        store.dispatch(setHub({ teaser: data.val() }));
      });
  }

  static async loadTeaserOnce() {
    const data = await firebase.database()
      .ref('Teaser' + '/' + '_teaser_')
      .once('value');
    // Assign the teaser attr
    store.dispatch(setHub({ teaser: data.val() }));
  }

  static loadPorhibitedWords() {
    firebase.database()
      .ref('_prohibited_words_')
      .on('value', (words) => {
        let porhibitedWordsArr = [];
        words.forEach((item) => {
          porhibitedWordsArr.push(item.val());
        });
        store.dispatch(setHub({ porhibitedWordsArr }));
      });
  }

  static async loadPorhibitedWordsOnce() {
    const words = await firebase.database()
      .ref('_prohibited_words_')
      .once('value');
    let porhibitedWordsArr = [];
    words.forEach((item) => {
      porhibitedWordsArr.push(item.val());
    });
    store.dispatch(setHub({ porhibitedWordsArr }));
  }

  // Load all supported regions and insert it into hub regions
  static loadAllRegions() {
    // Get the regions ref
    let regionsRef = FirebasePaths.FB_REGIONS_REFERENCE;

    // getting all regions
    firebase.database()
      .ref(regionsRef)
      .on('value', (regionsShot) => {
        let regionsList = [];
        regionsShot.forEach((item) => {
          regionsList.push(item.val());
        });
        store.dispatch(setHub({ regionsList }));
      });
  }

  // Load all supported regions and insert it into hub regions
  static async loadAllRegionsOnce() {
    // Get the regions ref
    let regionsRef = FirebasePaths.FB_REGIONS_REFERENCE;

    // getting all regions
    const regionsShot = await firebase.database()
      .ref(regionsRef)
      .once('value');
    let regionsList = [];
    regionsShot.forEach((item) => {
      regionsList.push(item.val());
    });
    store.dispatch(setHub({ regionsList }));
  }

  // This function will load all the share icons
  static loadShareIcons() {
    firebase.database()
      .ref('_share_icons_')
      .on('value', (snapshot) => {
        let shareIconsMap = {};
        snapshot.forEach((item) => {
          shareIconsMap[item.key] = item.val();
        });
        store.dispatch(setHub({ shareIconsMap }));
      });
  }

  // This function will load all the share icons
  static async loadShareIconsOnce() {
    const snapshot = await firebase.database()
      .ref('_share_icons_')
      .once('value');
    let shareIconsMap = {};
    snapshot.forEach((item) => {
      shareIconsMap[item.key] = item.val();
    });
    store.dispatch(setHub({ shareIconsMap }));
  }
}
