import {StyleSheet} from 'react-native';
import {perfectSize} from '../../helpers/deviceHelper';

const styles = (theme) =>
  StyleSheet.create({
    labelWrapper: {
      height: perfectSize(36),
      alignItems: 'center',
      flexDirection: 'row',
      marginTop: perfectSize(12),
    },
    itemWrapper: {
      height: perfectSize(50),
      borderBottomColor: '#FFFFFF26',
      borderBottomWidth: 1,
      flexDirection: 'row',
      alignItems: 'center',
    },
    itemLabel: {
      width: '100%',
      color: '#FFFFFF8F',
    },
    borderContainer: {
      borderBottomColor: '#FFFFFF26',
      borderBottomWidth: 1,
      paddingTop: perfectSize(16),
      paddingBottom: perfectSize(16),
    },
    label: {
      flexDirection: 'row',
      alignItems: 'center',
      marginTop: perfectSize(8),
    },
    marginLeft: {
      marginLeft: perfectSize(8),
    },
    touchContainer: {
      flex: 1,
      alignItems: 'center',
      flexDirection: 'column',
    },
    whiteLabel: {
      color: 'white',
    },
    grayLabel: {
      color: '#FFFFFF8F',
    },
    pinkLabel: {
      color: theme.primary_btn_background,
    },
  });

export default styles;
