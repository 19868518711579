import {StyleSheet} from 'react-native';
import {perfectSize} from '../../../helpers/deviceHelper';

const styles = (theme) =>
  StyleSheet.create({
    subContainer: {
      marginTop: perfectSize(32),
    },
    centerWrapper: {
      justifyContent: 'center',
      alignItems: 'center',
    },
    logoWrapper: {
      width: '100%',
      marginTop: perfectSize(16),
    },
    logoUploadWrapper: {
      position: 'absolute',
      bottom: perfectSize(96 * 0.14645 - 32 / 2),
      right: perfectSize(96 * 0.14645 - 32 / 2),
    },
    uploadButton: {
      width: perfectSize(32),
      height: perfectSize(32),
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: theme.colors.primary,
      borderRadius: perfectSize(16),
    },
    headerWrapper: {
      height: perfectSize(116),
      marginTop: perfectSize(16),
      borderWidth: 2,
      borderRadius: perfectSize(12),
      borderColor: theme.colors.primary,
      backgroundColor: theme.colors.secondary,
    },
    header: {
      height: '100%',
      borderRadius: perfectSize(12) - 2,
    },
    headerUploadWrapper: {
      position: 'absolute',
      bottom: -16,
      right: 8,
    },
    space: {
      flex: 1,
    },
    footer: {
      paddingBottom: perfectSize(16),
    },
    submitTextStyle: {
      fontSize: perfectSize(15),
    },
  });

export default styles;
