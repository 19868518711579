import {StyleSheet} from 'react-native';
import {perfectSize} from '../../helpers/deviceHelper';
import Dimens from '../../constants/dimens';

const styles = (theme) =>
  StyleSheet.create({
    container: {
      flex: 1,
      width: '100%',

      backgroundColor: theme.primary_background,
      paddingHorizontal: Dimens.HEADER_PADDING_HORIZONTAL,
      paddingVertical: perfectSize(12),
      alignItems: 'center',
      marginTop: perfectSize(64),
    },
    iconSad: {
      width: '100%',
      height: perfectSize(120),
    },
    label: {
      marginTop: perfectSize(32),
      fontSize: perfectSize(14),
      color: '#A2A5A9',
    },
    labelShot: {
      marginTop: perfectSize(120),
      color: theme.primary_btn_background,
      fontSize: perfectSize(14),
    },
  });

export default styles;
