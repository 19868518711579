import React, { memo, Component } from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Entypo from 'react-native-vector-icons/Entypo';

import { perfectSize } from '../../../helpers/deviceHelper';

import Label from '../../label';
import OverlayImage from '../../overlay-image';
import ImageWithPlaceholder from '../../image-with-placeholder';
import { LinearGradient } from 'expo-linear-gradient';

class NewsCard extends Component {
  render() {
    const {
      theme,
      image,
      sourceLogo,
      providerName,
      onShare,
      title,
      time,
    } = this.props;
    const _styles = styles(theme);
    return (
      <View style={[_styles.container, this.props.style]}>
        <OverlayImage
          uri={image}
          overlayView={
            <LinearGradient
              colors={['rgba(0, 0, 0, 0)', 'rgba(199, 0, 100, 0.4)']}
              style={_styles.overlay}
            />
          }
          style={_styles.cover}
        />
        <View style={_styles.infoWrap}>
          <View style={_styles.flexRow}>
            <ImageWithPlaceholder uri={sourceLogo} style={_styles.sourceLogo} />
            <Label size="x_small">{providerName}</Label>
          </View>
          <Label size="x_small">{title}</Label>
          <Label size="x_small">{time}</Label>
        </View>
      </View>
    );
  }
}

NewsCard.propTypes = {
  image: PropTypes.string,
  sourceLogo: PropTypes.string,
  providerName: PropTypes.string,
  onShare: PropTypes.func,
  title: PropTypes.string,
  time: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
  };
};

export default memo(connect(mapStateToProps)(NewsCard));

const styles = (theme) =>
  StyleSheet.create({
    container: {
      borderRadius: perfectSize(12),
      width: '100%',
      height: 138,
      overflow: 'hidden',
    },
    cover: {
      width: '100%',
      height: '100%',
    },
    overlay: {
      width: '100%',
      height: '100%',
    },
    share: {
      position: 'absolute',
      top: perfectSize(16),
      right: perfectSize(16),
      padding: perfectSize(8),
    },
    infoWrap: {
      position: 'absolute',
      bottom: perfectSize(16),
      left: perfectSize(16),
      right: perfectSize(64),
    },
    flexRow: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    sourceLogo: {
      width: perfectSize(32),
      aspectRatio: 1,
      marginRight: perfectSize(4),
    },
  });
