import { takeLatest, put, select, delay } from 'redux-saga/effects';
import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import CryptoJS from "react-native-crypto-js";
import { AES_SECRET } from '@env';

import AsyncStorage from '@react-native-community/async-storage';

// import firebase actions
import {
  signupRequest,
  signupSuccess,
  signupFailed,
  resetPasswordRequest,
  resetPasswordSuccess,
  resetPasswordFailed,
  loadReachsourceRequest,
  loadReachsourceSuccess,
  updateProfile,
  updateProfileFailed,
  updateProfileSuccess,
  setUserInfo,
  updateProfileAccounts,
  updateProfileAccountsSuccess,
  updateProfileAccountsFailed,
} from './actions';

import { set as setHub } from '../hub/actions';

import { presentToast } from '../../../helpers/toastHelper';
import * as FirebasePaths from '../../../constants/firebasePaths';
import selector from './selector';

import NavigationService from '../../../navigation/navigationService';

// RESET PASSWORD
function* resetPasswordWorker({ payload }) {
  const { email } = payload;
  try {
    yield firebase.auth().sendPasswordResetEmail(email);
    presentToast({
      message:
        'Reset password link sent successfully, please check out your mail inbox !',
    });

    yield put(resetPasswordSuccess());
  } catch (err) {
    presentToast({
      message: err.message.toString(),
    });
    yield put(resetPasswordFailed(err.message));
  }
}

// LOAD REACHSOURCE
function* loadReachsourceWorker({ payload }) {
  const snapshot = yield firebase.database()
    .ref(FirebasePaths.FIREBASE_REACH_SOURCES)
    .once('value');
  yield put(
    loadReachsourceSuccess({
      en: Object.keys(snapshot.val()).map((source) => {
        return {
          value: source,
          title: source,
        };
      }),
      ar: Object.values(snapshot.val()).map((source) => {
        return {
          value: source,
          title: source,
        };
      }),
    }),
  );
}

// SIGN UP
function* signupWorker({ payload }) {
  const { email, username, password, mainPlatform, reachSource } = payload;
  try {
    const data = yield firebase.auth().createUserWithEmailAndPassword(email, password);

    const uid = data.user.uid;
    const DETAILS_PATH_WITH_UID =
      '/' +
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '//' +
      uid +
      '/' +
      FirebasePaths.FIREBASE_DETAILS_ATTR +
      '/';
    yield firebase.database()
      .ref(DETAILS_PATH_WITH_UID + FirebasePaths.FIREBASE_EMAIL_ATTR)
      .set(email);
    yield firebase.database()
      .ref(DETAILS_PATH_WITH_UID + FirebasePaths.FIREBASE_BIO_ATTR)
      .set('Bio');
    yield firebase.database()
      .ref(DETAILS_PATH_WITH_UID + FirebasePaths.FIREBASE_PICTURE_URL_ATTR)
      .set('NULL');
    yield firebase.database()
      .ref(DETAILS_PATH_WITH_UID + FirebasePaths.FIREBASE_USERNAME_ATTR)
      .set(username);
    yield firebase.database()
      .ref(DETAILS_PATH_WITH_UID + FirebasePaths.FIREBASE_ACCOUNT_TYPE_ATTR)
      .set('REGULAR');

    yield firebase.database()
      .ref(
        '/' +
        FirebasePaths.FIREBASE_USERS_INFO_ATTR +
        '/' +
        uid +
        '/' +
        FirebasePaths.FIREBASE_DETAILS_ATTR +
        '/' +
        FirebasePaths.FIREBASE_ANALYTICS_MAIN_PLATFORM,
      )
      .set(mainPlatform);
    if (
      reachSource !== '' &&
      reachSource != null &&
      reachSource !== undefined
    ) {
      yield firebase.database()
        .ref(
          '/' + FirebasePaths.FIREBASE_REACH_SOURCES_VALUES + '//' + uid + '',
        )
        .set(reachSource);
    }

    yield firebase.database()
      .ref('/' + FirebasePaths.FIREBASE_USER_NAMES_ATTR + '//' + username)
      .set(uid);

    global.rankLogger.LOG_NEW_HP(
      global.rankLogger.REGISTRATION,
      'REGISTRATION',
    );
    yield put(setHub({ isTutorial: true }));
    // yield AsyncStorage.setItem('user', JSON.stringify(data.user));
    const encryptedUser = CryptoJS.AES.encrypt(JSON.stringify(data.user), AES_SECRET).toString();
    yield AsyncStorage.setItem('user', encryptedUser);
    yield put(signupSuccess({ user: data.user, uid: uid }));
  } catch (err) {
    let msg = err.message;
    if (err.code && err.code.length > 0) {
      msg = msg.substring(err.code.length + 3);
    }
    yield put(signupFailed(msg));
  }
}

function* updateProfileWorker({ payload }) {
  const {
    bio,
    country,
    city,
    gender,
    age,
    mainPlatform,
    favoriteGame,
    avgPlaytime,
    avgDailyPlaytime,
    dayPlaytime,
    phoneNumber,
  } = payload;
  const uid = yield select(selector.selectUserUID);
  try {
    const firebaseUserDetailsPath =
      '/' +
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '//' +
      uid +
      '/' +
      FirebasePaths.FIREBASE_DETAILS_ATTR +
      '/';
    const _setParam = {};
    if (notNull(bio)) {
      firebase.database()
        .ref(firebaseUserDetailsPath + FirebasePaths.FIREBASE_BIO_ATTR)
        .set(bio);
      _setParam.bio = bio;
    }
    if (notNull(country)) {
      firebase.database()
        .ref(firebaseUserDetailsPath + FirebasePaths.FIREBASE_ANALYTICS_COUNTRY)
        .set(country);
      _setParam.country = country;
    }
    if (notNull(city)) {
      firebase.database()
        .ref(firebaseUserDetailsPath + FirebasePaths.FIREBASE_ANALYTICS_CITY)
        .set(city);
      _setParam.city = city;
    }
    if (notNull(gender)) {
      firebase.database()
        .ref(firebaseUserDetailsPath + FirebasePaths.FIREBASE_ANALYTICS_GENDER)
        .set(gender);
      _setParam.gender = gender;
    }
    if (notNull(age)) {
      firebase.database()
        .ref(firebaseUserDetailsPath + FirebasePaths.FIREBASE_ANALYTICS_AGE)
        .set(age);
      _setParam.age = age;
    }
    if (notNull(mainPlatform)) {
      firebase.database()
        .ref(
          firebaseUserDetailsPath +
          FirebasePaths.FIREBASE_ANALYTICS_MAIN_PLATFORM,
        )
        .set(mainPlatform);
      _setParam.mainPlatform = mainPlatform;
    }
    // store favorite game
    if (notNull(favoriteGame)) {
      firebase.database()
        .ref(
          firebaseUserDetailsPath + FirebasePaths.FIREBASE_ANALYTICS_FAV_GAME,
        )
        .set(favoriteGame);
      _setParam.favGame = favoriteGame;
    }
    if (notNull(avgPlaytime)) {
      firebase.database()
        .ref(
          firebaseUserDetailsPath +
          FirebasePaths.FIREBASE_ANALYTICS_AVG_PLAYTIME,
        )
        .set(avgPlaytime);
      _setParam.avgPlaytime = avgPlaytime;
    }
    if (notNull(avgDailyPlaytime)) {
      firebase.database()
        .ref(
          firebaseUserDetailsPath +
          FirebasePaths.FIREBASE_ANALYTICS_AVG_DAILY_PLAYTIME,
        )
        .set(avgDailyPlaytime);
      _setParam.avgDailyPlaytime = avgDailyPlaytime;
    }
    if (notNull(dayPlaytime)) {
      firebase.database()
        .ref(
          firebaseUserDetailsPath +
          FirebasePaths.FIREBASE_ANALYTICS_DAY_PLAYTIME,
        )
        .set(dayPlaytime);
      _setParam.dayPlaytime = dayPlaytime;
    }
    if (notNull(phoneNumber)) {
      firebase.database()
        .ref(firebaseUserDetailsPath + FirebasePaths.FIREBASE_ANALYTICS_PHONE)
        .set(phoneNumber);
      _setParam.phoneNumber = phoneNumber;
      _setParam.phone = phoneNumber;
    }
    yield put(setUserInfo(_setParam));

    yield delay(1000);
    yield put(updateProfileSuccess());
    presentToast({
      message: 'Successfully updated !',
    });
    NavigationService.goBack();
  } catch (err) {
    yield put(updateProfileFailed(err.message));
  }
}

function* updateProfileAccountsWorker({ payload }) {
  const {
    psnAccount,
    xboxAccount,
    nintendoAccount,
    epicAccount,
    steamAccount,
    originAccount,
    discordAccount,
    battlenetAccount,
  } = payload;
  try {
    const _accountsParam = {};
    const uid = yield select(selector.selectUserUID);
    const userDetailsPath =
      '/' +
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      '/' +
      uid +
      '/' +
      FirebasePaths.FIREBASE_DETAILS_ATTR +
      '/';
    _accountsParam.pcGamesAccs = yield select(selector.selectPcGamesAccs);
    if (!psnAccount.includes('حسابك') && !psnAccount.includes('Account')) {
      firebase.database()
        .ref(
          userDetailsPath + FirebasePaths.FIREBASE_USER_PS_GAME_PROVIDER_ATTR,
        )
        .set(psnAccount);
      _accountsParam.PSNAcc = psnAccount;
    }
    if (
      // xboxAccount !== '' &&
      !xboxAccount.includes('حسابك') &&
      !xboxAccount.includes('Account')
    ) {
      firebase.database()
        .ref(
          userDetailsPath + FirebasePaths.FIREBASE_USER_XBOX_GAME_PROVIDER_ATTR,
        )
        .set(xboxAccount);
      _accountsParam.XboxLiveAcc = xboxAccount;
    }
    if (
      // nintendoAccount !== '' &&
      !nintendoAccount.includes('حسابك') &&
      !nintendoAccount.includes('Account')
    ) {
      firebase.database()
        .ref(
          userDetailsPath +
          FirebasePaths.FIREBASE_USER_NINTENDO_GAME_PROVIDER_ATTR,
        )
        .set(nintendoAccount);
      _accountsParam.nintendoAcc = nintendoAccount;
    }
    if (
      // epicAccount !== '' &&
      !epicAccount.includes('حسابك') &&
      !epicAccount.includes('Account')
    ) {
      firebase.database()
        .ref(
          userDetailsPath +
          FirebasePaths.FIREBASE_USER_PC_GAME_PROVIDER_ATTR +
          '/Epic Games',
        )
        .set(epicAccount);
      _accountsParam.pcGamesAccs.set('Epic Games', epicAccount);
    }
    if (
      // steamAccount !== '' &&
      !steamAccount.includes('حسابك') &&
      !steamAccount.includes('Account')
    ) {
      firebase.database()
        .ref(
          userDetailsPath +
          FirebasePaths.FIREBASE_USER_PC_GAME_PROVIDER_ATTR +
          '/Steam',
        )
        .set(steamAccount);
      _accountsParam.pcGamesAccs.set('Steam', steamAccount);
    }
    if (
      // originAccount !== '' &&
      !originAccount.includes('حسابك') &&
      !originAccount.includes('Account')
    ) {
      firebase.database()
        .ref(
          userDetailsPath +
          FirebasePaths.FIREBASE_USER_PC_GAME_PROVIDER_ATTR +
          '/Origin',
        )
        .set(originAccount);
      _accountsParam.pcGamesAccs.set('Origin', originAccount);
    }
    if (
      // discordAccount !== '' &&
      !discordAccount.includes('حسابك') &&
      !discordAccount.includes('Account')
    ) {
      firebase.database()
        .ref(
          userDetailsPath +
          FirebasePaths.FIREBASE_USER_PC_GAME_PROVIDER_ATTR +
          '/Discord',
        )
        .set(discordAccount);
      _accountsParam.pcGamesAccs.set('Discord', discordAccount);
    }
    if (
      // battlenetAccount !== '' &&
      !battlenetAccount.includes('حسابك') &&
      !battlenetAccount.includes('Account')
    ) {
      firebase.database()
        .ref(
          userDetailsPath +
          FirebasePaths.FIREBASE_USER_PC_GAME_PROVIDER_ATTR +
          '/Battlenet',
        )
        .set(battlenetAccount);
      _accountsParam.pcGamesAccs.set('Battlenet', battlenetAccount);
    }

    yield delay(1000);
    yield put(updateProfileAccountsSuccess(_accountsParam));

    presentToast({
      message: 'Successfully updated !',
    });
    NavigationService.goBack();
  } catch (err) {
    presentToast({
      message: err.message,
    });
    yield put(updateProfileAccountsFailed(err.message));
  }
}

function notNull(val) {
  if (val !== '' && val !== undefined && val !== null) {
    return true;
  }
  return false;
}

export default function* () {
  // yield takeLatest(loginRequest, onLoginRequest);
  // yield takeLatest(loginFailed, onLoginFailed);
  yield takeLatest(resetPasswordRequest, resetPasswordWorker);
  yield takeLatest(loadReachsourceRequest, loadReachsourceWorker);
  yield takeLatest(signupRequest, signupWorker);
  yield takeLatest(updateProfile, updateProfileWorker);
  yield takeLatest(updateProfileAccounts, updateProfileAccountsWorker);
}
