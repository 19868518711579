import React, {memo, Component} from 'react';
import {Text} from 'react-native';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {getFontSize} from '../../constants/calc';
import {FONT_FAMILY, FONT_SIZE} from '../../constants/theme';

class Label extends Component {
  render() {
    const {theme, size, bold} = this.props;
    const fontSize = size ? getFontSize(size) : FONT_SIZE.medium;
    const fontFamily =
      this.props.lang === 'en' ? FONT_FAMILY.ubuntu : FONT_FAMILY.cairo;
    const fontFamilyBold =
      this.props.lang === 'en'
        ? FONT_FAMILY.ubuntu_bold
        : FONT_FAMILY.cairo_bold;
    return (
      <Text
        numberOfLines={this.props.numberOfLines || 0}
        style={[
          {
            fontSize: fontSize,
            fontFamily: fontFamily,
            color: theme.primary_text,
          },
          bold && {fontFamily: fontFamilyBold},
          this.props.style,
        ]}
        onLayout={(event) => {
          this.props.onLayout && this.props.onLayout(event);
        }}
        selectable={this.props.selectable || false}>
        {this.props.children}
      </Text>
    );
  }
}

Label.propTypes = {
  size: PropTypes.oneOf([
    'small', // 12
    'x_small', // 14
    'medium', // 16
    'x_medium', // 20
    'big', // 24
    'x_big', // 30
    'large', // 38
    'x_large', // 46
  ]),
  bold: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
    lang: state.appReducer.currentLang,
  };
};

export default memo(connect(mapStateToProps)(Label));
