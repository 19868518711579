/* eslint-disable react-native/no-inline-styles */
import {View, TouchableOpacity} from 'react-native';
import React, {Component} from 'react';
import UserAvatar from '../user-avatar';
import Label from '../label';

import {perfectSize} from '../../helpers/deviceHelper';
import styles from './styles';
import TimeStamp from '../../services/timeStamp';

const _border_ps = '#256EB8';
const _border_pc = '#E18C00';
const _border_xbox = '#107C0F';
const _border_nintendo = '#e60012';
const _border_mobile = '#9C27B0';

class UserActivity extends Component {
  render() {
    const {activity} = this.props;
    const {
      gameName,
      matchType,
      timeStamp,
      supportedPlatforms,
      gamePhotoUrl,
    } = activity;
    return (
      // eslint-disable-next-line react-native/no-inline-styles
      <View style={{width: '100%'}}>
        <TouchableOpacity
          style={styles.activityItem}
          onPress={this.props.onPress}>
          <UserAvatar
            src={gamePhotoUrl}
            size={perfectSize(48)}
            borderColor={
              supportedPlatforms === 'PC'
                ? _border_pc
                : supportedPlatforms === 'PS'
                ? _border_ps
                : supportedPlatforms === 'XBOX'
                ? _border_xbox
                : supportedPlatforms === 'MOBILE'
                ? _border_mobile
                : _border_nintendo
            }
            style={{marginVertical: perfectSize(10)}}
            borderRadius={perfectSize(12)}
          />
          <View style={[styles.itemRightView]}>
            <Label size="x_small" style={{textTransform: 'capitalize'}}>
              {gameName}
            </Label>
            <View style={styles.itemSecondRow}>
              <Label size="small" style={styles.opacity}>
                {matchType}
              </Label>
              <Label size="small" style={styles.opacity}>
                {TimeStamp.getTimestampToTimeAgo(
                  new Date().getTime(),
                  timeStamp,
                )}
              </Label>
            </View>
          </View>
        </TouchableOpacity>
        <View style={styles.activityItemSeperator} />
      </View>
    );
  }
}

export default UserActivity;
