import firebase from 'firebase/app';
import 'firebase/database';

import * as FirebasePaths from '../../constants/firebasePaths';
import {store} from '../../redux/store';

// import main menu core
import Mainmenucore from '../mainmenu/mainmenucore';

export default class FriendsListCore {
  constructor() {}

  // This function will be called when the user click on delete : in dialog , it will delete the friend ref from the user db
  static deleteFriendRef(friendRef) {
    const uid = store.getState().authReducer.uid;
    // REF : _users_info_/uid/_friends_list_
    let friendsListPath =
      '/' +
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      uid +
      '/' +
      FirebasePaths.FIREBASE_FRIENDS_LIST_ATTR;

    // Delete ref
    firebase.database()
      .ref(friendsListPath + '/' + friendRef)
      .remove();

    // Log Delete friend feaure
    global.featuresLogger.LOG_VIEW_USER_DELETE();
  }

  // This function will be called when the user type something in the search bar
  static searchForFriend(value) {}

  // Validate that user isn't in the list & is not the current user id
  static validateFriend(friendKey) {}

  // Extract user info and add him/her to the list
  static addToFriendsList(friendKey) {}

  static getProfileBorderColor(uid) {}

  // This function will be called when the user click on a friend (not necessarily)
  static addFriend(friendKey) {
    Mainmenucore.addToFriendsList(friendKey);
    const uid = store.getState().authReducer.uid;
    const userinformationDB = store.getState().authReducer.userinformationDB;
    // REF : uid/_friends_list_
    const userFrienddsPath =
      '/' +
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      uid +
      '/' +
      FirebasePaths.FIREBASE_FRIENDS_LIST_ATTR;
    firebase.database()
      .ref(userFrienddsPath + '/' + friendKey)
      .set(friendKey);

    // Send a notification for the user
    const notificationId = firebase.database().ref().push().key;
    const notificationObj = {
      id: notificationId,
      status: 'UNREAD',
      body: userinformationDB.username + ' ' + ' اضافك لقائمة الاصدقاء ',
      type: 'USER',
      TRIGGER: uid,
      timestamp: firebase.database.ServerValue.TIMESTAMP,
    };
    const friendNotificationsPath =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      friendKey +
      '/' +
      FirebasePaths.FIREBASE_USER_NOTIFICATIONS +
      '/' +
      notificationId;
    // for the admin
    firebase.database().ref(friendNotificationsPath).set(notificationObj);
    global.featuresLogger.LOG_VIEW_USER_ADD();
  }
}
