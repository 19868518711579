import React, { Component } from 'react';
import { View, TouchableOpacity, FlatList, Keyboard } from 'react-native';
import { compose } from 'redux';
import { connect } from 'react-redux';
import I18n from '../../i18n';
import Entypo from 'react-native-vector-icons/Entypo';
import Feather from 'react-native-vector-icons/Feather';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import NavigationService from '../../navigation/navigationService';
import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';

import * as ImagePicker from 'expo-image-picker';

// import components
import Header from '../../components/header';
import Reply from '../../components/reply';
import PrimaryButton from '../../components/buttons/primary-button';
import TextInput from '../../components/text-input';
import StickersModal from '../../components/stickers-modal';

// import styles
import AppStyles from '../../constants/styles';
import styles from './styles';

// import actions
import { switchTheme } from '../../redux/modules/app/actions';

import { perfectSize } from '../../helpers/deviceHelper';
import * as FirebasePaths from '../../constants/firebasePaths';
import storage from '@react-native-firebase/storage';
import Postcore from './postcore';
import { arrayFromSnapshot, requestCameraPermission } from '../../services/utils';
import { presentToast } from '../../helpers/toastHelper';
import {
  Menu,
  MenuTrigger,
  MenuOptions,
  MenuOption,
  MenuProvider,
} from 'react-native-popup-menu';
import Modal from 'react-native-modal';
import Label from '../../components/label';
import TimelineCore from '../timeline/timelinecore';
import { showSpinner, hideSpinner } from '../../redux/modules/alert/actions';
import { showAlert, showInputAlert } from '../../redux/modules/alert/actions';
import SendImageModal from '../../components/send-image-modal';
import { ScrollView } from 'react-native-gesture-handler';
import dimens from '../../constants/dimens';
import { STICKERS_ICON } from '../../constants/images';
import LocalImageOpacity from '../../components/local-image-opacity';

class PostPage extends Component {
  flatList = null;

  constructor(props) {
    super(props);
    this.mount = true;
    this.state = {
      post: null,
      FINISHED_LOADING_REPLIES: false,
      mentionedMap: null,
      participantsMap: null,
      replyMsg: '',
      adminsList: new Map(),
      sendImageModalVisible: false,
      stickersModalVisible: false,
      photoSource: null,
      photoCaption: '',
      keyboardHeight: 0,
    };
  }

  componentDidMount() {
    global.featuresLogger.LOG_HOP_VIEWED();

    this.loadData();

    Keyboard.addListener('keyboardDidShow', this.onKeyboardDidShow);
  }

  componentWillUnmount() {
    if (this.status$) {
      this.status$.off();
      this.status$ = null;
    }
    if (this.borderColor$) {
      this.borderColor$.off();
      this.borderColor$ = null;
    }
    if (this.replies$) {
      this.replies$.off();
      this.replies$ = null;
    }
    Keyboard.removeListener('keyboardDidShow', this.onKeyboardDidShow);
    this.mount = false;
  }

  onKeyboardDidShow = (e) => {
    this.setState({
      keyboardHeight: e.endCoordinates.height,
    });
  };

  loadData = () => {
    this.props.showSpinner({});
    let POST_ID = this.props.navigation.state.params.POST_ID;

    global.rankLogger.LOG_NEW_HP(global.rankLogger.VIEW_HOP, 'VIEW_HOP');

    Postcore.loadHop(POST_ID).then((response) => {
      if (response.val()) {
        if (this.mount) {
          this.setState({
            post: response.val(),
          });
        }

        let userStatusRef =
          FirebasePaths.FIREBASE_USERS_INFO_ATTR +
          '/' +
          this.props.uid +
          '/' +
          FirebasePaths.FIREBASE_DETAILS_ATTR +
          '/';
        this.status$ = firebase.database().ref(userStatusRef);
        let cosmeticsPath =
          FirebasePaths.FIREBASE_USERS_INFO_ATTR +
          '/' +
          this.props.uid +
          '/' +
          FirebasePaths.FIREBASE_COSMETICS_ATTR +
          '/' +
          FirebasePaths.FIREBASE_PROFILE_BORDER_ATTR;
        this.borderColor$ = firebase.database().ref(cosmeticsPath);
        let status = 'online';
        this.status$.on('value', (snapshot) => {
          if (snapshot.val()[FirebasePaths.FIREBASE_STATUS_ATTR]) {
            status = snapshot.val()[FirebasePaths.FIREBASE_STATUS_ATTR];
          }
        });
        let borderColor = '#880e4a';
        this.borderColor$.on('value', (snapshot) => {
          if (
            snapshot.val() != null &&
            snapshot.val()[FirebasePaths.FIREBASE_COLOR_ATTR]
          ) {
            borderColor = snapshot.val()[FirebasePaths.FIREBASE_COLOR_ATTR];
          }
        });
        if (this.mount) {
          this.setState({
            status: status,
            borderColor: borderColor,
          });
        }
        this.replies$ = Postcore.loadReplies(
          this.props.navigation.state.params.POST_ID,
        );
        this.replies$.on('value', (snapshot) => {
          this.loadStatus(snapshot);
        });
      } else {
        this.props.hideSpinner();

        this.props.showAlert([
          I18n.t('TIMELINE_PAGE.no_post_title'),
          I18n.t('TIMELINE_PAGE.no_post_msg'),
          [
            {
              text: I18n.t('TIMELINE_PAGE.no_post_btn'),
              onPress: () => {
                NavigationService.goBack();
              },
            },
          ],
          false,
        ]);
      }
    });

    TimelineCore.loadAdminsList((response) => {
      let adminList = response.val();
      const adminsList = new Map();
      if (adminList) {
        Object.keys(adminList).forEach((key) => {
          adminsList.set(key, adminList[key]);
        });
      }
      if (this.mount) {
        this.setState({
          adminsList: adminsList,
        });
      }
    });
  };

  async loadStatus(snapshot) {
    let replies = arrayFromSnapshot(snapshot);

    let post = Object.assign({}, this.state.post);
    post.status = await Postcore.getUserStatus(post.uid);
    post.hops_icon = await Postcore.getHopsIcon(post.uid);
    replies.unshift(post);

    replies = replies.filter(
      (reply) => !this.props.bannedUsers.includes(reply.uid),
    );

    this.props.hideSpinner();
    this.setState(
      {
        replies: replies,
      },
      () => {
        setTimeout(() => {
          if (this.flatList) {
            this.flatList.scrollToEnd({ animated: false });
          }
        }, 500);
      },
    );
  }

  sendReply = () => {
    if (
      this.state.replyMsg &&
      this.state.replyMsg.trim().length > 1 &&
      this.state.replyMsg.trim().length < 280
    ) {
      let prohibited = false;
      this.props.porhibitedWordsArr.forEach((badword) => {
        if (this.state.replyMsg.trim().includes(badword)) {
          prohibited = true;
        }
      });

      if (prohibited) {
        presentToast({
          message: I18n.t('TIMELINE_PAGE.porhibited_words_message'),
        });
      } else {
        global.rankLogger.LOG_NEW_HP(global.rankLogger.REPLY_HOP, 'REPLY_HOP');
        global.featuresLogger.LOG_HOP_REPLY();
        let id = firebase.database().ref().push().key;

        let reply = {
          id: id,
          title: this.state.post.title,
          description: this.state.replyMsg,
          type: 'TEXT_ONLY',
          img: this.props.userInformation.pictureURL,
          postImg: this.state.post.postImg,
          postDescription: this.state.post.postDescription,
          timeStamp: firebase.database.ServerValue.TIMESTAMP,
          uid: this.props.uid,
          username: this.props.userInformation.username,
          postId: this.state.post.postId,
          poster:
            this.state.post.poster != null
              ? this.state.post.poster
              : this.props.uid,
          mentionedMap: this.state.mentionedMap
            ? Array.from(this.state.mentionedMap.entries()).reduce(
              (main, [key, value]) => ({ ...main, [key]: value }),
              {},
            )
            : '',
        };

        Postcore.uploadReply(
          reply,
          this.state.post.postId,
          this.state.post.uid,
          this.state.post.username,
          this.state.replies.length,
        );

        if (this.state.mentionedMap) {
          this.state.mentionedMap.forEach((value, key) => {
            let notificationId = firebase.database().ref().push().key;
            let notificationObj = {
              id: notificationId,
              status: 'UNREAD',
              body:
                this.props.userInformation.username +
                ' ' +
                ' سوالك منشن على الهوب ',

              type: 'HOP_MENTION',
              TRIGGER: this.state.post.postId,
              timeStamp: firebase.database.ServerValue.TIMESTAMP,
            };
            let adminNotificationsPath =
              FirebasePaths.FIREBASE_USERS_INFO_ATTR +
              '/' +
              key +
              '/' +
              FirebasePaths.FIREBASE_USER_NOTIFICATIONS +
              '/' +
              notificationId;
            firebase.database().ref(adminNotificationsPath).set(notificationObj);
          });
        }
        presentToast({
          message: 'رسالتك وصلت !',
        });

        this.setState({
          replyMsg: '',
        });
        Keyboard.dismiss();
      }
    } else {
      presentToast({
        message: I18n.t('POST_PAGE.validation_max_min_chars_reply'),
      });
    }
  };

  onInput = (value) => {
    this.setState(
      {
        replyMsg: value,
      },
      () => {
        if (!this.state.replyMsg && this.state.mentionedMap) {
          this.setState({
            mentionedMap: new Map(),
          });
        }

        const char = this.state.replyMsg.slice(this.state.replyMsg.length - 1);
        if (char === '@') {
          if (
            this.state.replyMsg.length === 1 ||
            this.state.replyMsg.slice(
              this.state.replyMsg.length - 2,
              this.state.replyMsg.length - 1,
            ) === ' '
          ) {
            if (!this.participantsMap) {
              this.getParticipants();
            }
            this.refs.menu.open();
          }
        }
      },
    );
  };

  getParticipants = () => {
    let participantsMap = new Map();

    // Admin uid/name

    participantsMap.set(this.state.post.uid, this.state.post.username);

    // Participants uid/name

    this.state.replies.forEach((reply) => {
      if (!participantsMap.has(reply.uid)) {
        participantsMap.set(reply.uid, reply.username);
      }
    });

    // Add friends

    this.props.friendsList.forEach((value) => {
      if (!participantsMap.has(value.UID)) {
        participantsMap.set(value.UID, value.username);
      }
    });
    this.setState(
      {
        participantsMap: participantsMap,
      },
      () => { },
    );
  };
  addReport = () => {
    // Generate unique Id

    let reportId = firebase.database().ref().push().key;

    // Get the current time

    let date = new Date();
    let timestamp = date.toDateString();

    let reportObj = {
      msg: this.state.report,
      reporterEmail: this.props.userInformation.userEmail,
      reporterUsername: this.props.userInformation.username,
      reporterUid: this.props.uid,
      timestamp: timestamp,
      reportedUid: this.state.post.uid,
      reportedUsername: this.state.post.username,
      hopId: this.state.post.id,
      hopDescription: this.state.post.postDescription,
    };

    // Post as a new message

    firebase.database()
      .ref(
        FirebasePaths.FIREBASE_HOPS_REPORTS +
        '/' +
        FirebasePaths.FIREBASE_HOPS_REPORTS_NEW +
        '/' +
        reportId,
      )
      .set(reportObj); // Post as old message

    firebase.database()
      .ref(
        FirebasePaths.FIREBASE_HOPS_REPORTS +
        '/' +
        FirebasePaths.FIREBASE_HOPS_REPORTS_OLD +
        '/' +
        reportId,
      )
      .set(reportObj);

    this.setState({
      report: '',
    });
  };
  _loadMenuOptions = () => {
    let views = [];
    if (this.state.participantsMap) {
      this.state.participantsMap.forEach((item, key) => {
        views.push(
          <MenuOption
            text={'@' + item}
            onSelect={() => this.mention(item, key)}
          />,
        );
      });
    }
    return views;
  };

  mention = (item, key) => {
    let mentionedMap = this.state.mentionedMap;
    if (!mentionedMap) {
      mentionedMap = new Map();
    }

    mentionedMap.set(key, item);
    this.setState({
      mentionedMap: mentionedMap,
      replyMsg: this.state.replyMsg + item + ' ',
    });
  };

  report = () => {
    const { currentTheme } = this.props;
    const containerStyle = [
      styles.container,
      { backgroundColor: currentTheme.secondary_background },
    ];

    this.props.showInputAlert([
      I18n.t('POST_PAGE.report_title'),
      <View style={styles.dialogSectionContainer}>
        <Label style={styles.dialogDescription}>
          {I18n.t('SEARCH_REQUESTS_RESULT_PAGE.report_msg')}
        </Label>
        <TextInput
          style={styles.input}
          placeholder={I18n.t('POST_PAGE.report_placeholder')}
          // value={this.state.report}
          // numberOfLines={8}
          // multiline={true}
          onChangeText={(value) =>
            this.setState({
              report: value,
            })
          }
          placeholderTextColor={'#444444'}
        />
      </View>,
      [
        {
          text: I18n.t(
            'SEARCH_REQUESTS_RESULT_PAGE.report_cancel',
          ).toUpperCase(),
          onPress: () => { },
        },
        {
          text: I18n.t('SEARCH_REQUESTS_RESULT_PAGE.report_send').toUpperCase(),
          onPress: () => {
            if (!this.state.report || this.state.report.length === 0) {
              presentToast({
                message: I18n.t(
                  'SEARCH_REQUESTS_RESULT_PAGE.report_validation',
                ),
              });
              return;
            }
            global.rankLogger.LOG_NEW_HP(
              global.rankLogger.REPORT_HOP,
              'REPORT_HOP',
            );
            global.featuresLogger.LOG_FEEDBACKS_HOP_REPORT();
            presentToast({
              message: I18n.t('SEARCH_REQUESTS_RESULT_PAGE.report_success'),
            });
            this.addReport();
          },
        },
      ],
    ]);
  };

  sharePost = () => {
    let message = `Have a look to ${this.state.post.title} Hop:`;
    let subject = 'Hoplay';
    let link = `https://hoplay.online/post/${this.state.post.postId}`;
    // Share.open({
    //   message: message,
    //   subject: subject,
    //   url: link,
    // });

    global.rankLogger.LOG_NEW_HP(global.rankLogger.SHARE_HOP, 'SHARE_HOP');
    global.featuresLogger.LOG_SHARE_HOP();
  };

  selectImage = async () => {
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
    })

    if (!result.cancelled) {
      this.setState({
        sendImageModalVisible: true,
        photoSource: result.uri,
      });
    }
  };

  sendImage = () => {
    global.featuresLogger.CHATS_PHOTOS();

    this.uploadImageToFirebase(this.state.photoSource);
  };

  makeid = (length) => {
    let result = '';
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  uploadImageToFirebase = (image) => {
    this.props.showSpinner({ title: I18n.t('POST_PAGE.uploading_photo') });
    return this.uploadImage(image).then((photoUrl) => {
      global.rankLogger.LOG_NEW_HP(global.rankLogger.REPLY_HOP, 'REPLY_HOP');
      global.featuresLogger.LOG_HOP_REPLY();
      let id = firebase.database().ref().push().key;

      let reply = {
        id: id,
        title: this.state.post.title,
        description: photoUrl,
        type: 'IMAGE',
        img: this.props.userInformation.pictureURL,
        postImg: this.state.post.postImg,
        postDescription: this.state.post.postDescription,
        timeStamp: firebase.database.ServerValue.TIMESTAMP,
        uid: this.props.uid,
        username: this.props.userInformation.username,
        postId: this.state.post.postId,
        poster:
          this.state.post.poster != null
            ? this.state.post.poster
            : this.props.uid,
        mentionedMap: this.state.mentionedMap
          ? Array.from(this.state.mentionedMap.entries()).reduce(
            (main, [key, value]) => ({ ...main, [key]: value }),
            {},
          )
          : '',
        caption: this.state.photoCaption,
      };

      global.featuresLogger.HOP_REPLY_IMG();

      Postcore.uploadReply(
        reply,
        this.state.post.postId,
        this.state.post.uid,
        this.state.post.username,
        this.state.replies.length,
      );

      if (this.state.mentionedMap) {
        this.state.mentionedMap.forEach((value, key) => {
          let notificationId = firebase.database().ref().push().key;
          let notificationObj = {
            id: notificationId,
            status: 'UNREAD',
            body:
              this.props.userInformation.username +
              ' ' +
              ' سوالك منشن على الهوب ',

            type: 'HOP_MENTION',
            TRIGGER: this.state.post.postId,
            timeStamp: firebase.database.ServerValue.TIMESTAMP,
          };
          let adminNotificationsPath =
            FirebasePaths.FIREBASE_USERS_INFO_ATTR +
            '/' +
            key +
            '/' +
            FirebasePaths.FIREBASE_USER_NOTIFICATIONS +
            '/' +
            notificationId;
          firebase.database().ref(adminNotificationsPath).set(notificationObj);
        });
      }
      presentToast({
        message: 'رسالتك وصلت !',
      });

      this.props.hideSpinner();
    });
  };

  uploadImage(image) {
    let uid = this.props.uid;
    let storageRef = firebase.storage().ref();
    let postId = this.state.post.postId;
    let filename = this.makeid(20) + '_' + uid;
    let imageRef = storageRef.child('HopImgs').child(postId).child(filename);

    return imageRef.putString(image, 'data_url').then(() => {
      return imageRef.getDownloadURL().then((downloadURL) => {
        return downloadURL;
      });
    });
  }

  showStickersModal = () => {
    this.setState({ stickersModalVisible: true });
  };

  sendSticker = (stickerUrl) => {
    // global.rankLogger.LOG_NEW_HP(global.rankLogger.REPLY_HOP, 'REPLY_HOP');
    // global.featuresLogger.LOG_HOP_REPLY();
    global.featuresLogger.HOPS_STICKER_LOGGER('send');
    let id = firebase.database().ref().push().key;

    let reply = {
      id: id,
      title: this.state.post.title,
      description: stickerUrl,
      type: 'STICKER',
      img: this.props.userInformation.pictureURL,
      postImg: this.state.post.postImg,
      postDescription: this.state.post.postDescription,
      timeStamp: firebase.database.ServerValue.TIMESTAMP,
      uid: this.props.uid,
      username: this.props.userInformation.username,
      postId: this.state.post.postId,
      poster:
        this.state.post.poster != null
          ? this.state.post.poster
          : this.props.uid,
      mentionedMap: this.state.mentionedMap
        ? Array.from(this.state.mentionedMap.entries()).reduce(
          (main, [key, value]) => ({ ...main, [key]: value }),
          {},
        )
        : '',
      caption: this.state.photoCaption,
    };

    global.featuresLogger.HOP_REPLY_IMG();

    Postcore.uploadReply(
      reply,
      this.state.post.postId,
      this.state.post.uid,
      this.state.post.username,
      this.state.replies.length,
    );

    if (this.state.mentionedMap) {
      this.state.mentionedMap.forEach((value, key) => {
        let notificationId = firebase.database().ref().push().key;
        let notificationObj = {
          id: notificationId,
          status: 'UNREAD',
          body:
            this.props.userInformation.username +
            ' ' +
            ' سوالك منشن على الهوب ',

          type: 'HOP_MENTION',
          TRIGGER: this.state.post.postId,
          timeStamp: firebase.database.ServerValue.TIMESTAMP,
        };
        let adminNotificationsPath =
          FirebasePaths.FIREBASE_USERS_INFO_ATTR +
          '/' +
          key +
          '/' +
          FirebasePaths.FIREBASE_USER_NOTIFICATIONS +
          '/' +
          notificationId;
        firebase.database().ref(adminNotificationsPath).set(notificationObj);
      });
    }
    this.setState({ stickersModalVisible: false });
  };

  render() {
    const { currentTheme } = this.props;
    const containerStyle = [
      styles.container,
      { backgroundColor: currentTheme.secondary_background },
    ];
    const {
      sendImageModalVisible,
      stickersModalVisible,
      photoSource,
      keyboardHeight,
    } = this.state;
    return (
      <MenuProvider>
        <View style={containerStyle}>
          {/* Header */}
          <Header
            text="Hop"
            rightComponent={
              <View style={styles.headerRight}>
                <TouchableOpacity
                  style={AppStyles.rightBarButton}
                  onPress={() => this.report()}>
                  <Feather
                    name="more-vertical"
                    color="gray"
                    size={perfectSize(19)}
                  />
                </TouchableOpacity>
              </View>
            }
          />
          {/* Content */}
          {stickersModalVisible &&
            <StickersModal
              isVisible={stickersModalVisible}
              stickerType="hop"
              onHide={() => {
                this.setState({ stickersModalVisible: false });
              }}
              onSendSticker={this.sendSticker}
            />
          }
          <SendImageModal
            isVisible={sendImageModalVisible}
            photo={{
              url: photoSource,
            }}
            onCancel={() => this.setState({ sendImageModalVisible: false })}
            onSend={(caption) => {
              this.setState({
                photoCaption: caption,
                sendImageModalVisible: false,
              });
              this.sendImage();
            }}
          />

          <View
            style={[
              { backgroundColor: currentTheme.primary_background },
              styles.content,
            ]}>
            <KeyboardAwareScrollView
              scrollEnabled={false}
              extraHeight={perfectSize(150)}
              contentContainerStyle={AppStyles.fullFlex}
              keyboardShouldPersistTaps="always">
              <View
                style={[
                  AppStyles.fullFlex,
                  { paddingHorizontal: dimens.HEADER_PADDING_HORIZONTAL },
                ]}>
                <FlatList
                  data={this.state.replies}
                  renderItem={({ item, index }) => (
                    <Reply
                      item={item}
                      showLikes={index !== 0}
                      isAdmin={this.state.adminsList.has(item.uid)}
                      showDelete={index !== 0 && this.props.uid === item.uid}
                      onDelete={() => {
                        this.props.showAlert([
                          '',
                          I18n.t('POST_PAGE.delete_confirm'),
                          [
                            {
                              text: I18n.t('POST_PAGE.no'),
                              onPress: () => { },
                            },
                            {
                              text: I18n.t('POST_PAGE.yes'),
                              onPress: () => {
                                TimelineCore.deleteReply(item);
                              },
                            },
                          ],
                        ]);
                      }}
                    />
                  )}
                  keyExtractor={(item, index) => index.toString()}
                  ref={(ref) => (this.flatList = ref)}
                  removeClippedSubviews={false}
                  showsVerticalScrollIndicator={false}
                />
              </View>

              <Menu ref="menu" style={[styles.menu, { bottom: keyboardHeight }]}>
                <MenuTrigger />
                <MenuOptions
                  customStyles={{
                    optionText: styles.optionText,
                    optionWrapper: styles.menuItem,
                  }}>
                  <ScrollView style={{ height: 240, backgroundColor: '#181F29' }}>
                    {this._loadMenuOptions()}
                  </ScrollView>
                </MenuOptions>
              </Menu>

              {/* Send message */}
              <View style={styles.footer}>
                <View style={styles.inputBox}>
                  <TextInput
                    multiline
                    placeholder="Say anything..!"
                    customStyles={styles.textInput}
                    value={this.state.replyMsg}
                    onChangeText={(value) => this.onInput(value)}
                    leftComponent={
                      <TouchableOpacity
                        onPress={() => this.showStickersModal()}
                        style={AppStyles.stickersWrap}>
                        <LocalImageOpacity
                          source={STICKERS_ICON}
                          style={AppStyles.stickersIcon}
                        />
                      </TouchableOpacity>
                    }
                    rightComponent={
                      <View style={styles.photoContainer}>
                        <TouchableOpacity
                          onPress={() => this.selectImage()}
                          style={styles.attach}>
                          <Entypo
                            name="attachment"
                            size={perfectSize(20)}
                            color={currentTheme.tab_inactive_text_color}
                          />
                        </TouchableOpacity>
                      </View>
                    }
                  />
                </View>

                <PrimaryButton
                  text={I18n.t('SUPPORT_PAGE.send')}
                  type="filled"
                  customStyles={styles.sendBtn}
                  textCustomStyles={{ fontSize: perfectSize(15) }}
                  onPress={() => this.sendReply()}
                />
              </View>
            </KeyboardAwareScrollView>
          </View>
        </View>
      </MenuProvider>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentTheme: state.appReducer.currentTheme,
    lang: state.appReducer.currentLang,
    uid: state.authReducer.uid,
    porhibitedWordsArr: state.hubReducer.porhibitedWordsArr,
    userInformation: state.authReducer.userinformationDB,
    friendsList: state.hubReducer.friendsList,
    bannedUsers: state.hubReducer.bannedUsers,
  };
};

export default compose(
  connect(mapStateToProps, {
    switchTheme,
    showSpinner,
    hideSpinner,
    showAlert,
    showInputAlert,
  }),
)(PostPage);
