import React, {memo, Component} from 'react';
import {View, StyleSheet} from 'react-native';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import Label from '../../label';
import {perfectSize} from '../../../helpers/deviceHelper';
import Pipes from '../../../services/pipes';

class NotificationCard extends Component {
  render() {
    const {theme, notification} = this.props;
    const _styles = style(theme);
    return (
      <View style={_styles.container}>
        <Label size="x_small" style={_styles.message}>
          {notification.body}
        </Label>
        <Label size="small" style={_styles.timestamp}>
          {Pipes.humantime(notification.timeStamp)}
        </Label>
      </View>
    );
  }
}

const style = (theme) =>
  StyleSheet.create({
    container: {
      backgroundColor: '#3B1935',
      paddingHorizontal: perfectSize(16),
      paddingVertical: perfectSize(20),
      borderRadius: perfectSize(12),
    },
    message: {
      opacity: 0.56,
    },
    timestamp: {
      color: '#A2A5A9',
      fontWeight: '300',
      marginTop: perfectSize(4),
      alignSelf: 'flex-end',
      textAlign: 'left',
    },
  });

NotificationCard.propTypes = {
  notification: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
  };
};

export default memo(connect(mapStateToProps)(NotificationCard));
