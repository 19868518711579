/* eslint-disable react-native/no-inline-styles */
import React, {useState, useEffect} from 'react';
import {View, StyleSheet, TouchableOpacity, Animated} from 'react-native';
import {perfectSize} from '../../helpers/deviceHelper';
import TopTabService from '../../navigation/topTabService';

import {FONT_FAMILY} from '../../constants/theme';

const handleSetNavigationReference = (navigatorRef) => {
  TopTabService.setTopLevelNavigator(navigatorRef);
};

const TabBar = ({
  state,
  descriptors,
  navigation,
  badges,
  tabBarStyle,
  tabTextStyle,
  onTabChanged,
  lang,
  customAction
}) => {
  const [badgesArr, setBadgesArr] = useState(badges);

  useEffect(() => {
    setBadgesArr(badges);
  }, [badges]);

  handleSetNavigationReference(navigation);
  const {routes, index: activeRouteIndex} = state;
  const fontFamily = lang === 'en' ? FONT_FAMILY.ubuntu : FONT_FAMILY.cairo;

  return (
    <View style={styles.container}>
      <View style={customAction && styles.tabbarWrapper}>
        <View style={[styles.tabbar, tabBarStyle]}>
          {routes.map((route, routeIndex) => {
            const isRouteActive = routeIndex === activeRouteIndex;
            const { options } = descriptors[route.key];
            const label =
              options.tabBarLabel !== undefined
                ? options.tabBarLabel
                : options.title !== undefined
                  ? options.title
                  : route.name;

            const onPress = () => {
              const event = navigation.emit({
                type: 'tabPress',
                target: route.key,
                canPreventDefault: true,
              });

              if (!isRouteActive && !event.defaultPrevented) {
                navigation.navigate(route.name);

                if (onTabChanged) {
                  onTabChanged(routeIndex);
                }
              }
            };

            const onLongPress = () => {
              navigation.emit({
                type: 'tabLongPress',
                target: route.key,
              });
            };
            return (
              <TouchableOpacity
                key={routeIndex}
                style={[
                  styles.tabItem,
                  {
                    marginRight:
                      tabBarStyle &&
                        tabBarStyle.justifyContent === 'space-between'
                        ? 0
                        : perfectSize(16),
                  },
                ]}
                onPress={onPress}
                onLongPress={onLongPress}
                accessibilityLabel={options.tabBarAccessibilityLabel}>
                <Animated.View style={styles.tab}>
                  <Animated.View style={styles.flexRow}>
                    <Animated.Text
                      numberOfLines={1}
                      ellipsizeMode="clip"
                      style={[
                        styles.text,
                        isRouteActive && { color: 'white' },
                        tabTextStyle,
                        { fontFamily: fontFamily },
                      ]}>
                      {label}
                    </Animated.Text>
                  </Animated.View>
                  {isRouteActive && <Animated.View style={[styles.indicator]} />}
                  {badgesArr && badgesArr.includes(route.name) && (
                    <View style={styles.badge} />
                  )}
                </Animated.View>
              </TouchableOpacity>
            );
          })}
        </View>
        {
          customAction && customAction
        }
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    height: perfectSize(48),
  },
  tabbarWrapper: {
    justifyContent: 'space-between',
    width: '100%',
    height: '100%',
    flexDirection: 'row',
    alignItems: 'center',
  },
  tabbar: {
    height: '100%',
    flexDirection: 'row',
    alignItems: 'center',
  },
  tabItem: {
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tab: {
    alignSelf: 'flex-start',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  flexRow: {
    flexDirection: 'row',
    marginBottom: 8,
  },
  text: {
    fontSize: perfectSize(14),
    color: '#A2A5A9',
  },
  indicator: {
    width: '72%',
    height: 2,
    backgroundColor: '#C70064',
    alignSelf: 'center',
  },
  badge: {
    position: 'absolute',
    top: -perfectSize(4),
    right: -perfectSize(10),
    width: perfectSize(8),
    height: perfectSize(8),
    borderRadius: perfectSize(4),
    backgroundColor: '#C70064',
  },
});

export default TabBar;
