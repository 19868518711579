/* eslint-disable eqeqeq */
import * as FirebasePaths from '../../constants/firebasePaths';
import {store} from '../../redux/store';
// import Recentgamemodel from '../../models/recentgamemodel';
import firebase from 'firebase/app';
import 'firebase/database';

// import actions
import {set as HubSet} from '../../redux/modules/hub/actions';

export default class ViewFriendProfileCore {
  constructor() {}

  static listener = null;

  static loadFriendDataListener = null;
  static loadFriendRatesListener = null;
  static loadFriendGamesNumberListener = null;
  static loadFriendsFriendsNumberListener = null;
  static loadFriendRecentActivitiesListener = null;
  static loadFriendRecentActivityListener = null;

  static friendRecentList = null;

  static loadFriendData(friendKey) {
    // Load friend recent activities
    this.loadRecentActivities(friendKey);

    // Load friend rates
    this.loadFriendRatesFrom(friendKey);

    let friendId = friendKey;
    let bio;
    let email;
    let picUrl;
    let username;
    let HP = 0;
    let status = 'offline';
    let profile_icon = '';

    // Get friend games number
    this.getFriendGamesNumber(friendKey);

    // Get friend friends number
    this.getFriendFriendsNumber(friendKey);

    let friendPath =
      '/' +
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      friendKey +
      '/' +
      FirebasePaths.FIREBASE_DETAILS_ATTR;
    this.loadFriendDataListener = firebase.database().ref(friendPath);

    this.loadFriendDataListener.on('value', (snapshot) => {
      if (snapshot.val()) {
        snapshot.forEach((shot) => {
          if (shot.key == FirebasePaths.FIREBASE_BIO_ATTR) {
            bio = shot.val();
          }
          if (shot.key == FirebasePaths.FIREBASE_EMAIL_ATTR) {
            email = shot.val();
          }
          if (shot.key == FirebasePaths.FIREBASE_PICTURE_URL_ATTR) {
            picUrl = shot.val();
          }
          if (shot.key == FirebasePaths.FIREBASE_USERNAME_ATTR) {
            username = shot.val();
          }
          if (shot.key == FirebasePaths.FIREBASAE_USER_RANK_ATTR) {
            HP = shot.val();
          }
          if (shot.key == FirebasePaths.FIREBASE_STATUS_ATTR) {
            status = shot.val();
          }
          if (shot.key == FirebasePaths.FIREBASAE_USER_PROFILE_ICON) {
            profile_icon = shot.val();
          }
        });
        if (picUrl == 'NULL' || picUrl == 'default') {
          // SET DEFAULT IMG
          picUrl =
            'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/teams%20default%20photos%20and%20background%2Fdefault_team_logov2.png?alt=media&token=b88bf926-35b9-4568-a80e-ee24110aceb6';
        }

        // Fill this   object
        const _viewFriendModel = {};

        _viewFriendModel.UID = friendId;
        _viewFriendModel.bio = bio;
        _viewFriendModel.userEmail = email;
        _viewFriendModel.pictureURL = picUrl;
        _viewFriendModel.username = username;
        _viewFriendModel.profile_icon = profile_icon;

        store.dispatch(
          HubSet({
            viewFriendModel: _viewFriendModel,
            friendHP: HP,
            friendStatus: status,
          }),
        );
      } else {
        // Fill this   object
        const _viewFriendModel = {};

        _viewFriendModel.UID = friendId;
        _viewFriendModel.bio = '';
        _viewFriendModel.userEmail = '';
        _viewFriendModel.pictureURL = '';
        _viewFriendModel.username = '';
        _viewFriendModel.profile_icon = '';
        store.dispatch(
          HubSet({
            viewFriendModel: _viewFriendModel,
            friendHP: 0,
            friendStatus: status,
          }),
        );
      }
    });
  }

  static checkUserPasses(friendKey) {
    const ref =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      friendKey +
      '/' +
      FirebasePaths.FIREBASE_USERS_PASSES +
      '/' +
      FirebasePaths.FIREBASE_USERS_PASSES_COUNTER;

    firebase.database()
      .ref(ref)
      .once('value')
      .then((snapshot) => {
        const snap = snapshot.val();
        if (snap !== null) {
          let NEW_COUNTER = 0;
          NEW_COUNTER = snap;
          NEW_COUNTER++;
          this.logUserPass(friendKey, NEW_COUNTER);
        } else {
          const NEW_COUNTER = 1;
          this.logUserPass(friendKey, NEW_COUNTER);
        }
      });
  }

  static logUserPass(friendKey, counter) {
    // getting the paths
    const passPath =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      friendKey +
      '/' +
      FirebasePaths.FIREBASE_USERS_PASSES;

    // Update the counter number
    firebase.database()
      .ref(passPath + '/' + FirebasePaths.FIREBASE_USERS_PASSES_COUNTER)
      .set(counter);

    // Log this user as a passer
    // generate userID + ___ + Unique Id = user id and three underscores with unique id
    const _id = firebase.database().ref().push().key;
    const pass = store.getState().authReducer.uid + '___' + _id;
    firebase.database()
      .ref(
        passPath + '/' + FirebasePaths.FIREBASE_USERS_PASSES_USERS + '/' + pass,
      )
      .set(store.getState().authReducer.userinformationDB.username);
  }

  // This function will load recent Activites for friend
  static loadRecentActivities(friendKey) {
    this.friendRecentList = []; //store.getState().hubReducer.friendRecentList;
    // _users_info -> uid -> _games_ -> _recent_played_
    const recentKeysRef =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      friendKey +
      '/' +
      FirebasePaths.FIREBASE_GAMES_REFERENCES +
      '/' +
      FirebasePaths.FIREBASE_RECENT_GAME_ATTR;

    this.loadFriendRecentActivitiesListener = firebase.database().ref(recentKeysRef);
    this.loadFriendRecentActivitiesListener.on('value', (snapshot) => {
      const snap = snapshot.val();
      if (snap === null) {
        store.dispatch(HubSet({friendRecentList: []}));
        // store.dispatch(HubSet({valueChanged: generateRandom(11)}));
      } else {
        const length = Object.keys(snap).length;
        // eslint-disable-next-line no-unused-vars
        for (let key in snap) {
          this.loadRecentActivity(recentKeysRef + '/' + key, key, length);
        }
      }
    });
  }

  static loadRecentActivity(recentPath, recentKey, totalLength) {
    // First validate key isn't in the hub
    // if (
    //   !this.friendRecentList.filter((e) => e.recentKey === recentKey).length > 0
    // ) {
    // It doesn't has it then load it and add it to the hub list
    // Creating recent model
    const key = recentKey;
    let gameId = '';
    let requestId = '';
    let region = '';
    let gameType = '';
    let matchType = '';
    let requestPlatform = '';
    let timeStamp;

    let recentModel = {}; // new Recentgamemodel();
    this.loadFriendRecentActivityListener = firebase.database().ref(recentPath);
    this.loadFriendRecentActivityListener.once('value', (snapshot) => {
      snapshot.forEach((shot) => {
        if (shot.key === FirebasePaths.FIREBASE_RECENT_GAME_ID_ATTR) {
          gameId = shot.val();
        } else if (shot.key === FirebasePaths.FIREBASE_RECENT_GAME_TYPE_ATTR) {
          gameType = shot.val();
        } else if (shot.key === FirebasePaths.FIREBASE_RECENT_MATCH_TYPE_ATTR) {
          matchType = shot.val();
        } else if (
          shot.key === FirebasePaths.FIREBASE_RECENT_REQUEST_PLATFORM_ATTR
        ) {
          requestPlatform = shot.val();
        } else if (shot.key === FirebasePaths.FIREBASE_RECENT_TIME_STAMP_ATTR) {
          timeStamp = shot.val();
        } else if (shot.key === FirebasePaths.FIREBASE_RECENT_REQUEST_ID) {
          requestId = shot.val();
        } else if (shot.key === FirebasePaths.FIREBASE_RECENT_REGION) {
          region = shot.val();
        }
      });

      recentModel.recentKey = key;
      recentModel.gameId = gameId;
      recentModel.gameType = gameType;
      recentModel.matchType = matchType;
      recentModel.supportedPlatforms = requestPlatform;
      recentModel.timeStamp = timeStamp;
      if (region) {
        recentModel.region = region;
      }
      if (requestId) {
        recentModel.requestId = requestId;
      }

      this.friendRecentList.push(recentModel);
      if (this.friendRecentList.length === totalLength) {
        store.dispatch(HubSet({friendRecentList: this.friendRecentList}));
      }
    });
    // }
  }

  static loadFriendRatesFrom(friendKey) {
    let friendRatesList = new Map();
    store.dispatch(HubSet({friendRatesList}));
    const ref =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      friendKey +
      '/' +
      FirebasePaths.FIREBASE_USER_RATINGS +
      '/' +
      FirebasePaths.FIREBASE_USER_RATING_FROM;

    this.loadFriendRatesListener = firebase.database().ref(ref);
    this.loadFriendRatesListener.on('value', (snapshot) => {
      if (snapshot.val()) {
        snapshot.forEach((shot) => {
          friendRatesList.set(shot.key, shot.val());
        });
        store.dispatch(HubSet({friendRatesList}));

        // calcaulte avg user rates
        let ratesComulative = 0;
        let ratesNumber = 0;
        friendRatesList.forEach((rate) => {
          ratesComulative += rate;
          ratesNumber++;
        });

        const avgFriendRate = ratesComulative / ratesNumber;
        store.dispatch(HubSet({avgFriendRate}));
      }
    });
  }

  static getFriendGamesNumber(friendKey) {
    // REF : uid/_games_/_favor_games_
    const favorGamesRef =
      '/' +
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      friendKey +
      '/' +
      FirebasePaths.FIREBASE_GAMES_REFERENCES +
      '/' +
      FirebasePaths.FIREBASE_FAVOR_GAME_ATTR +
      '/';

    this.loadFriendGamesNumberListener = firebase.database().ref(favorGamesRef);
    this.loadFriendGamesNumberListener.on('value', (snapshot) => {
      if (snapshot.val()) {
        store.dispatch(
          HubSet({friendGamesNumber: Object.keys(snapshot.val()).length}),
        );
      } else {
        store.dispatch(HubSet({friendGamesNumber: 0}));
      }
    });
  }

  static getFriendClansNumber(friendKey) {
    // REF : uid/_games_/_favor_games_
    const favorGamesRef =
      '/' +
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      friendKey +
      '/' +
      FirebasePaths.FIREBASE_GAMES_REFERENCES +
      '/' +
      FirebasePaths.FIREBASE_FAVOR_GAME_ATTR +
      '/';

    this.loadFriendGamesNumberListener = firebase.database().ref(favorGamesRef);
    this.loadFriendGamesNumberListener.on('value', (snapshot) => {
      if (snapshot.val()) {
        store.dispatch(
          HubSet({friendGamesNumber: Object.keys(snapshot.val()).length}),
        );
      } else {
        store.dispatch(HubSet({friendGamesNumber: 0}));
      }
    });
  }

  static getUserTeamsRef(uid) {
    let path =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      uid +
      '/' +
      FirebasePaths.FIREBASE_USER_TEAMS +
      '/' +
      FirebasePaths.FIREBASE_USER_TEAMS_REFS;

    return firebase.database().ref(path);
  }

  static getFriendFriendsNumber(friendKey) {
    // REF : uid/_games_/_favor_games_
    const friendsListRef =
      '/' +
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      friendKey +
      '/' +
      FirebasePaths.FIREBASE_FRIENDS_LIST_ATTR +
      '/';

    this.loadFriendsFriendsNumberListener = firebase.database().ref(friendsListRef);
    this.loadFriendsFriendsNumberListener.on('value', (snapshot) => {
      if (snapshot.val()) {
        store.dispatch(
          HubSet({friendFriendsNumber: Object.keys(snapshot.val()).length}),
        );
      } else {
        store.dispatch(HubSet({friendFriendsNumber: 0}));
      }
    });
  }

  static getHopsLikesCount(friendKey) {
    return firebase.database().ref(
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
        '/' +
        friendKey +
        '/' +
        FirebasePaths.FIREBASE_HOPS_LIKES_ATTR,
    );
  }
}
