/* eslint-disable react/no-did-mount-set-state */
import React, { Component } from 'react';
import { View, Linking } from 'react-native';
import { compose } from 'redux';
import { connect } from 'react-redux';
import InAppBrowser from 'react-native-inappbrowser-reborn';


import { LOGO_UPDATE } from '../../constants/images';

// import components
import Label from '../../components/label';
import Button from '../../components/buttons/primary-button';

// import styles
import styles from './styles';
import { perfectSize } from '../../helpers/deviceHelper';
import NavigationService from '../../navigation/navigationService';
import { FONT_FAMILY } from '../../constants/theme';
import LocalImageOpacity from '../../components/local-image-opacity';

// Login Page
class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      textInputVal: '',
      bgImage:
        'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/updatePage%2F1.gif?alt=media&token=c7f9cb22-ddad-4920-a68c-d362a8cf0ea6',
      macUrl: 'https://hoplay.online/#intro',
      windowsUrl: 'https://hoplay.online/#intro',
      mobileUrl: 'https://appurl.io/vAH8r6wNt',
      update_text:
        'تحديثك قديم شوي تحتاج تحدث التطبيق عشان تستمتع بأطلق الخصائص الجديدة!',
      logo:
        'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/updatePage%2FwhiteLogo.png?alt=media&token=9030429e-a8e1-452e-b2ed-0cb667bf1f8f',
    };
  }

  componentDidMount = () => {
    const param = this.props.navigation.state.params;
    let shot = null;
    if (param && param.SHOT) {
      shot = param.SHOT;
    }
    if (shot.background != null) {
      this.setState({ bgImage: shot.background });
    }
    if (shot.windows_url != null) {
      this.setState({ windowsUrl: shot.windows_url });
    }
    if (shot.mac_url != null) {
      this.setState({ macUrl: shot.mac_url });
    }
    if (shot.mobile_url != null) {
      this.setState({ mobileUrl: shot.mobile_url });
    }
    if (shot.update_text != null) {
      this.setState({ update_text: shot.update_text });
    }
    if (shot.logo != null) {
      this.setState({ logo: shot.logo });
    }
  };

  toLogin = () => {
    NavigationService.navigate('HomePage');
  };

  toMac = () => { };

  toWindows = () => { };

  toUpdate = async (type) => {
    let url = '';
    if (type === 'mobile') {
      url = this.state.mobileUrl;
    }
    if (type === 'mac') {
      url = this.state.macUrl;
    }
    if (type === 'windows') {
      url = this.state.windowsUrl;
    }
    try {
      await InAppBrowser.close();
      if (await InAppBrowser.isAvailable()) {
        await InAppBrowser.open(url, {
          // iOS Properties
          dismissButtonStyle: 'cancel',
          preferredBarTintColor: '#453AA4',
          preferredControlTintColor: 'white',
          readerMode: false,
          modalPresentationStyle: 'overFullScreen',
          modalEnabled: true,
          enableBarCollapsing: false,
          // Android Properties
          showTitle: true,
          toolbarColor: '#6200EE',
          secondaryToolbarColor: 'black',
          enableUrlBarHiding: true,
          enableDefaultShare: true,
          forceCloseOnRedirection: false,
        });
      } else {
        Linking.openURL(url);
      }
    } catch (error) { }
  };

  render() {
    const { theme } = this.props;
    const fontFamilyBold =
      this.props.lang === 'en'
        ? FONT_FAMILY.ubuntu_bold
        : FONT_FAMILY.cairo_bold;
    return (
      <View style={[styles(theme).container]}>
        <View style={styles(theme).wrapper}>
          <View style={styles(theme).alignCenter}>
            <LocalImageOpacity
              source={LOGO_UPDATE}
              resizeMethod="resize"
              style={styles(theme).imgLogo}
              resizeMode="contain"
            />
          </View>
          <Label size="medium" style={styles(theme).labelStyle}>
            {this.state.update_text}
          </Label>

          <View
            style={[
              styles(theme).flexRow,
              styles(theme).alignCenter,
              styles(theme).spaceBetween,
              {
                marginVertical: perfectSize(15),
              },
            ]}>
            <Button
              text="PC update"
              // text="تحديث نسخة الويندوز"
              type="outlined"
              customStyles={styles(theme).buttonView}
              textCustomStyles={[
                styles(theme).buttonText,
                { fontFamily: fontFamilyBold },
              ]}
              onPress={() => this.toUpdate('windows')}
            />
            <View style={styles(theme).buttonSeparateView} />
            <Button
              text="Mobile update"
              // text="تحديث نسخة الجوال"
              type="outlined"
              customStyles={styles(theme).buttonView}
              textCustomStyles={[
                styles(theme).buttonText,
                { fontFamily: fontFamilyBold },
              ]}
              onPress={() => this.toUpdate('mobile')}
            />
          </View>
          <View style={[styles(theme).alignCenter]}>
            <Button
              text="Mac update"
              // text="تحديث نسخة الماك"
              type="outlined"
              customStyles={styles(theme).buttonView}
              textCustomStyles={[
                styles(theme).buttonText,
                { fontFamily: fontFamilyBold },
              ]}
              onPress={() => this.toUpdate('mac')}
            />
          </View>
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
    lang: state.appReducer.currentLang,
  };
};

export default compose(connect(mapStateToProps, {}))(LoginPage);
