import React, {memo, Component} from 'react';
import {StyleSheet, TouchableOpacity, ScrollView} from 'react-native';
import {connect} from 'react-redux';
import I18n from '../../i18n';
import NavigationService from '../../navigation/navigationService';

import ImageWithPlaceholder from '../../components/image-with-placeholder';
import PrimaryButton from '../../components/buttons/primary-button';
import {perfectSize} from '../../helpers/deviceHelper';

class Brackets extends Component {
  constructor(props) {
    super(props);
    const params = props.route.params;
    this.state = {
      championshipObj: params.championshipObj,
    };
  }

  viewBracketsImg = () => {
    const img = this.state.championshipObj.brackets_img;
    global.featuresLogger.CHAMPIONSHIP_BRACKET_IMG_VIEW();

    NavigationService.navigate('PhotoViewerPage', {
      TITLE: 'Brackets',
      IMG: img,
    });
  };

  toBracketsPage = () => {
    NavigationService.navigate('ChampionshipStatusPage', {
      CHAMPIONSHIP_OBJ: this.state.championshipObj,
    });
  };

  render() {
    const {theme} = this.props;
    const _styles = styles(theme);
    const {championshipObj} = this.state;
    return (
      <ScrollView
        style={_styles.container}
        showsVerticalScrollIndicator={false}>
        <TouchableOpacity onPress={this.viewBracketsImg}>
          <ImageWithPlaceholder
            uri={championshipObj.brackets_img}
            style={_styles.image}
          />
        </TouchableOpacity>
        <PrimaryButton
          text={I18n.t('CHAMPIONSHIP_INFO_PAGE.scores')}
          type="filled"
          customStyles={_styles.viewBtn}
          textCustomStyles={{fontSize: perfectSize(15)}}
          onPress={this.toBracketsPage}
        />
      </ScrollView>
    );
  }
}

const styles = (theme) =>
  StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: theme.primary_background,
    },
    image: {
      width: '100%',
      height: 240,
      borderColor: '#C70064',
      borderWidth: 1,
      borderRadius: 30,
      marginTop: perfectSize(16),
    },
    viewBtn: {
      marginTop: perfectSize(32),
      marginBottom: perfectSize(16),
    },
  });

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
  };
};

export default memo(connect(mapStateToProps)(Brackets));
