import {StyleSheet} from 'react-native';
import {perfectSize} from '../../helpers/deviceHelper';
import {FONT_SIZE, FONT_FAMILY} from '../../constants/theme';

const styles = (theme) =>
  StyleSheet.create({
    container: {
      flex: 1,
      width: '100%',
      backgroundColor: theme.primary_background,
    },
    wrapper: {
      flex: 1,
      paddingHorizontal: perfectSize(24),
      paddingVertical: perfectSize(24),
    },
    actionWrap: {
      flex: 1,
    },
    actionBottom: {
      height: 96,
    },
    textLight: {
      opacity: 0.56,
    },
    flexRow: {
      flexDirection: 'row',
    },
    justifyBetween: {
      justifyContent: 'space-between',
    },
    alignCenter: {
      alignItems: 'center',
    },
    imgLogo: {
      width: 96,
      height: 86.42,
      resizeMode: 'cover',
      marginTop: 64,
      marginBottom: 20,
    },
    btnLogin: {
      width: '100%',
      height: perfectSize(52),
      textAlign: 'center',
    },
    forgot: {
      fontSize: FONT_SIZE.x_small,
      fontFamily: FONT_FAMILY.ubuntu,
      lineHeight: perfectSize(19),
      color: theme.primary_btn_background,
    },
    signUpText: {
      fontFamily: FONT_FAMILY.ubuntu_bold,
      fontSize: perfectSize(15),
    },
  });

export default styles;
