import { useToast } from "react-native-toast-notifications";

export const presentToast = ({
  message,
  duration = 3000,
  position = 'top',
  hideOnPress = false,
  onPress,
  onHidden,
}) => {
  const toast = global['toast'];
  toast.show(message, {
    duration: duration,
    placement: position
  })
}