/* eslint-disable react-native/no-inline-styles */
import React, { Component } from 'react';
import { View, FlatList, TouchableOpacity, ScrollView } from 'react-native';
import Feather from 'react-native-vector-icons/Feather';
import Entypo from 'react-native-vector-icons/Entypo';
import AntDesign from 'react-native-vector-icons/AntDesign';
import { compose } from 'redux';
import { connect } from 'react-redux';
import I18n from '../../../i18n';
import NavigationService from '../../../navigation/navigationService';
import * as FirebasePaths from '../../../constants/firebasePaths';
import Newrequestcore from '../../newrequest/newrequestcore';
import firebase from 'firebase/app';
import 'firebase/database';

// import components
import Label from '../../../components/label';
import Picker from '../../../components/picker';
import Filter from '../../../components/filter';
import RequestCardSmall from '../../../components/cards/request-card-small';
import RequestCardSmallSkeleton from '../../../components/cards/request-card-small/skeleton';
import RequestCard from '../../../components/cards/request-card';
import RequestCardSkeleton from '../../../components/cards/request-card/skeleton';
import PrimaryButton from '../../../components/buttons/primary-button';
import { showAlert } from '../../../redux/modules/alert/actions';

// import styles
import { perfectSize } from '../../../helpers/deviceHelper';
import styles from './styles';
import * as Images from '../../../constants/images';
import { useIsFocused } from '@react-navigation/native';
import ImageWithPlaceholder from '../../../components/image-with-placeholder';
import LocalImageOpacity from '../../../components/local-image-opacity';

class Requests extends Component {
  platforms = ['PS', 'MOBILE', 'NINTENDO', 'PC', 'XBOX'];

  constructor(props) {
    super(props);
    this.mount = true;
    this.state = {
      platform: '',
      game: null,
      lastUserInterestingReqsArr: [],
      loadLastUserReqs: false,
      lastReqsHubArr: [],
      urgentRequestsLoading: true,
      lastRequestsLoading: true,
      urgentRequests: [],
      allGamesCnt: -1,
    };
  }

  componentDidMount() {
    this.focusListener$ = this.props.navigation.addListener(
      'focus',
      (payload) => {
        global.featuresLogger.MAIN_SLIDE_AD_PAGE_VIEW();
      },
    );
    this.loadUserInterestingReqs();
    this.loadLastRequests();
  }

  componentDidUpdate(prevProps, prevState) {
    const propsGamesCnt = this.props.allGamesIds.length;
    if (propsGamesCnt > -1 && this.state.allGamesCnt !== propsGamesCnt) {
      this.setState({
        allGamesCnt: this.props.allGamesIds.length
      })
    }

    if (prevState.loadLastUserReqs !== this.state.loadLastUserReqs && this.state.loadLastUserReqs) {
      if (this.state.lastUserInterestingReqsArr.length > 0) {
        this.filterUrgentRequests();
      } else {
        this.setState({
          urgentRequests: [],
          urgentRequestsLoading: false,
        })
      }
    }

    if (prevProps.addAllGameFinished !== this.props.addAllGameFinished && this.props.addAllGameFinished) {
      this.filterUrgentRequests();
    }


    if (prevProps.mainPageGame !== this.props.mainPageGame) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ game: this.props.mainPageGame, }, () => {
        this.filterUrgentRequests();
      })

    }
  }

  componentWillUnmount() {
    if (this.userInterestingReqsRef$) {
      this.userInterestingReqsRef$.off();
      this.userInterestingReqsRef$ = null;
    }
    if (this.lastRequestsRef$) {
      this.lastRequestsRef$.off();
      this.lastRequestsRef$ = null;
    }
    this.mount = false;
  }

  filterUrgentRequests = () => {
    // get urgent requests
    if (this.props.addAllGameFinished) {
      const urgentRequests = this.state.lastUserInterestingReqsArr.filter((request) => {
        if (this.props.allGamesIds.filter((e) => e.gameID === request.gameId).length === 0) {
          return false;
        }
        if (this.state.game && request.gameId !== this.state.game.gameID) {
          return false;
        }
        if (this.props.bannedUsers.includes(request.admin)) {
          return false;
        }
        return request.platform.includes(this.state.platform);
      });
      this.setState({
        urgentRequests,
        urgentRequestsLoading: false,
      })
    }
  }

  // This function will load the last 100 requests and filter them for the user games
  loadUserInterestingReqs() {
    this.userInterestingReqsRef$ = firebase.database()
      .ref(FirebasePaths.FIREBASE_REQUESTS_HUB)
      .limitToLast(100);
    this.userInterestingReqsRef$.on('value', (snapshot) => {
      let lastUserInterestingReqsArr = [];
      snapshot.forEach((item) => {
        lastUserInterestingReqsArr.push(item.val());
      });
      lastUserInterestingReqsArr = lastUserInterestingReqsArr
        .reverse()
        .filter((request) => {
          return (
            !this.props.blockedList.has(request.admin) &&
            new Date().getTime() - request.timeStamp < 5 * 60 * 60 * 1000
          );
        });
      if (this.mount) {
        this.setState({
          lastUserInterestingReqsArr,
        });
        setTimeout(() => {
          if (this.mount) {
            this.setState({
              loadLastUserReqs: true
            })
          }
        }, 100);
      }
    });
  }

  // This function will load last 15 recent requests from the hub
  loadLastRequests() {
    this.lastRequestsRef$ = firebase.database()
      .ref(FirebasePaths.FIREBASE_REQUESTS_HUB)
      .limitToLast(15);
    this.lastRequestsRef$.on('value', (shot) => {
      let lastReqsHubArr = [];
      shot.forEach((item) => {
        let obj = item.val();
        if (obj.adminName != null) {
          lastReqsHubArr.push(obj);
        }
      });
      if (this.mount) {
        this.setState({
          lastReqsHubArr: lastReqsHubArr.reverse().filter((request) => {
            return (
              !this.props.blockedList.has(request.admin) &&
              !this.props.bannedUsers.includes(request.admin)
            );
          }),
          lastRequestsLoading: false,
        });
      }
    });
  }

  checkRequestModal = (item) => {
    NavigationService.moveToScreenInStack('HomeStack', 'LobbyPage', {
      lastReq: item,
    });
  };

  toFindMatch = () => {
    NavigationService.moveToScreenInStack('HomeStack', 'HoplayMatchPage');
  };

  toNewRequest = () => {
    Newrequestcore.IS_CREATE_REQUEST_BANNED((isBanned) => {
      // This variable will be either true or false
      if (isBanned) {
        let banTitle = I18n.t('NEW_REQUEST_PAGE.ban_alert_title');
        let banMsg = I18n.t('NEW_REQUEST_PAGE.ban_alert_msg');
        let banBtn = I18n.t('NEW_REQUEST_PAGE.ban_alert_btn');

        // Show ban alert
        this.props.showAlert([
          banTitle,
          banMsg,
          [
            {
              text: banBtn,
              onPress: () => {
                NavigationService.moveToScreenInStack(
                  'SettingsStack',
                  'SupportPage',
                );
              },
            },
          ],
        ]);
      } else {
        // let warning = I18n.t('NEW_REQUEST_PAGE.no_game_alert_title');
        // let warningMsg = I18n.t('NEW_REQUEST_PAGE.no_game_alert_message');
        // let warningBtn = I18n.t('NEW_REQUEST_PAGE.no_game_alert_add_game');
        NavigationService.navigate('NewRequestPage');
        // NavigationService.navigate('AgoraTestPage');
      }
    });
  };

  toRequestsHub = () => {
    NavigationService.moveToScreenInStack('HomeStack', 'RequestshubPage');
  };

  goToSearchRequestPage = () => {
    NavigationService.moveToScreenInStack('HomeStack', 'SearchrequestPage');
  };

  platformTrigger = (platform) => {
    if (this.state.platform === platform) {
      this.setState({
        platform: '',
      }, () => {
        this.filterUrgentRequests();
      });
    } else {
      this.setState({
        platform: platform,
      }, () => {
        this.filterUrgentRequests();
      });
    }
  };

  renderUrgentRequests = ({ item }) => {
    return (
      <RequestCardSmall
        request={item}
        customStyles={{ marginRight: perfectSize(12), width: perfectSize(150) }}
        onPress={() => {
          this.checkRequestModal(item);
        }}
      />
    );
  };

  renderUrgentRequestSkeleton = () => {
    return (
      <RequestCardSmallSkeleton
        customStyles={{ marginRight: perfectSize(12), width: perfectSize(150) }}
        onPress={() => {
        }}
      />
    );
  }

  renderLastRequestSkeleton = () => {
    return (
      <RequestCardSkeleton
        customStyles={{ marginRight: perfectSize(12), width: '25%' }}
        onPress={() => {
        }}
      />
    );
  }

  renderRequestsForYou = ({ item, index }) => {
    return (
      <TouchableOpacity
        style={{
          width: '25%'
        }}
        onPress={() => {
          this.checkRequestModal(item);
        }}>
        <RequestCard
          request={item}
          customStyles={
            [
              { marginBottom: 12, },
              (index % 4) === 0 ? { marginRight: 6, } : (index % 4) === 3 ? { marginLeft: 6 } : { marginHorizontal: 6 }
            ]
          }
          showJoin={true}
          hideDropDown={true}
        />
      </TouchableOpacity>
    );
  };

  toSelectGame = () => {
    NavigationService.moveToScreenInStack('HomeStack', 'SelectGamePage', {
      fromHome: true,
    });
  };

  renderTags = () => {
    const btn_tags = [
      Images.BTN_TAG_PLAYSTATION,
      Images.BTN_TAG_MOBILE,
      Images.BTN_TAG_DOUBLE,
      Images.BTN_TAG_DESKTOP,
      Images.BTN_TAG_BALL,
    ];
    const btn_tags_selected = [
      Images.BTN_TAG_PLAYSTATION_SELECTED,
      Images.BTN_TAG_MOBILE_SELECTED,
      Images.BTN_TAG_DOUBLE_SELECTED,
      Images.BTN_TAG_DESKTOP_SELECTED,
      Images.BTN_TAG_BALL_SELECTED,
    ];
    let views = [];
    btn_tags.forEach((tag, index) => {
      views.push(
        <TouchableOpacity
          key={index}
          onPress={() => this.platformTrigger(this.platforms[index])}>
          {this.state.platform === this.platforms[index] && (
            <LocalImageOpacity
              source={btn_tags_selected[index]}
              style={styles.btn_tag}
              resizeMode="contain"
            />
          )}
          {this.state.platform !== this.platforms[index] && (
            <LocalImageOpacity
              source={tag}
              style={styles.btn_tag}
              resizeMode="contain"
            />
          )}
        </TouchableOpacity>,
      );
    });
    return views;
  };

  clearFilters = () => {
    if (this.state.game || this.state.platform) {
      this.setState({
        game: null,
        platform: '',
      }, () => {
        this.filterUrgentRequests();
      });
    }
  };

  renderContent = () => {
    const {
      platform,
      game,
      lastReqsHubArr,
      lastRequestsLoading,
      urgentRequestsLoading,
      urgentRequests
    } = this.state;

    return (
      <View>
        <View style={styles.flexRow}>
          {/* Buttons */}
          <PrimaryButton
            onPress={this.toNewRequest}
            text={I18n.t('TABS.request')}
            size="x_small"
            type="filled"
            customStyles={styles.button}
            leftComponent={
              <Feather
                name="plus-circle"
                size={perfectSize(18)}
                color={'white'}
                style={{ marginRight: perfectSize(4) }}
              />
            }
          />
          <PrimaryButton
            text={I18n.t('MAIN_PAGE.hoplay_match')}
            size="x_small"
            type="outlined"
            customStyles={styles.button}
            onPress={this.toFindMatch}
          />
        </View>
        {/* Search */}
        <View>
          <Label size="medium" bold style={styles.sectionLabel}>
            {I18n.t('SEARCH_REQUEST_PAGE.looking_group')}
          </Label>
          <View style={styles.flexRow}>
            <Picker
              pickerName={
                this.state.game != null && this.state.game !== undefined
                  ? this.state.game.gameName
                  : I18n.t('SEARCH_REQUEST_PAGE.game')
              }
              leftComponent={
                this.state.game != null ? (
                  <ImageWithPlaceholder
                    uri={this.state.game.gamePhotoUrl}
                    style={styles.itemImage}
                  />
                ) : (
                  <Entypo name="game-controller" size={18} color={'white'} />
                )
              }
              customStyles={{ flex: 1, marginRight: perfectSize(8) }}
              onPickerPress={this.toSelectGame}
            />
            <Filter
              customStyles={{
                opacity: 0.56,
              }}
              pickerName={I18n.t('MAIN_PAGE.filter')}
              onPickerPress={this.goToSearchRequestPage}
            />
          </View>
        </View>
        {/* Tags */}
        <View style={styles.mt8}>
          <ScrollView showsHorizontalScrollIndicator={false} horizontal={true}>
            {this.renderTags()}
          </ScrollView>
        </View>
        {(this.state.game != null || this.state.platform.length > 0) && (
          <TouchableOpacity onPress={this.clearFilters}>
            <View style={[styles.flexRow, styles.clearContainer]}>
              <AntDesign
                name="closecircleo"
                size={perfectSize(12)}
                color={'#C70064'}
                style={styles.clearIcon}
              />
              <Label style={styles.clearText}>
                {I18n.t('MAIN_PAGE.clear_filter')}
              </Label>
            </View>
          </TouchableOpacity>
        )}
        {/* Requests you may like */}
        {
          urgentRequestsLoading &&
          <View>
            <Label size="medium" bold style={styles.sectionLabel}>
              {I18n.t('MAIN_PAGE.urgent_requests')}
            </Label>
            <FlatList
              data={[0, 1, 2, 3, 4, 5]}
              renderItem={this.renderUrgentRequestSkeleton}
              horizontal={true}
              keyExtractor={(item) => item.toString()}
              showsHorizontalScrollIndicator={false}
            />
          </View>
        }

        <View>
          {urgentRequests.length > 0 && (
            <Label size="medium" bold style={styles.sectionLabel}>
              {I18n.t('MAIN_PAGE.urgent_requests')}
            </Label>
          )}
          <FlatList
            data={urgentRequests}
            renderItem={this.renderUrgentRequests}
            horizontal={true}
            keyExtractor={(item) => item.timeStamp.toString()}
            showsHorizontalScrollIndicator={true}
          />
        </View>

        {/* Recent requests */}
        <View>
          <Label size="medium" bold style={styles.sectionLabel}>
            {I18n.t('MAIN_PAGE.interesting_reqs')}
          </Label>
          {
            lastRequestsLoading &&
            <FlatList
              data={[0, 1, 2, 3]}
              renderItem={this.renderLastRequestSkeleton}
              numColumns={4}
              keyExtractor={(item) => item.toString()}
              showsVerticalScrollIndicator={false}
            />
          }
          {
            !lastRequestsLoading &&
            <FlatList
              data={lastReqsHubArr.filter((request) => {
                let show = request.platform.includes(platform);
                if (game) {
                  if (request.gameId !== game.gameID) {
                    show = false;
                  }
                }

                if (this.props.bannedUsers.includes(request.admin)) {
                  show = false;
                }

                return show;
              })}
              numColumns={4}
              contentContainerStyle={{ width: '100%' }}
              renderItem={this.renderRequestsForYou}
              style={{ width: '100%' }}
              keyExtractor={(item) => item.timeStamp + ''}
              showsVerticalScrollIndicator={false}
            />
          }

          <PrimaryButton
            text={I18n.t('MAIN_PAGE.view_all_requests')}
            size="x_small"
            type="outlined"
            customStyles={styles.viewAllBtn}
            rightComponent={
              <AntDesign
                name="doubleright"
                size={perfectSize(12)}
                color={'white'}
                style={{ marginLeft: perfectSize(4) }}
              />
            }
            onPress={this.toRequestsHub}
          />
        </View>
      </View>
    );
  };

  render() {
    const { theme, isFocused } = this.props;
    return (
      <FlatList
        data={[{}]}
        renderItem={this.renderContent}
        keyExtractor={(item) => 'key'}
        showsVerticalScrollIndicator={false}
        style={{ backgroundColor: theme.primary_background }}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
    lang: state.appReducer.currentLang,
    mainPageGame: state.hubReducer.mainPageGame,
    addAllGameFinished: state.gameManagerReducer.addAllGameFinished,
    allGamesIds: state.gameManagerReducer.allGamesIds,
    blockedList: state.hubReducer.blockedList,
    bannedUsers: state.hubReducer.bannedUsers,
  };
};

function focusComponent(props) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const isFocused = useIsFocused();
  return <Requests {...props} isFocused={isFocused} />;
}

export default compose(connect(mapStateToProps, { showAlert }))(focusComponent);
