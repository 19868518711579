/* eslint-disable no-shadow */
/* eslint-disable react-native/no-inline-styles */
import React, { Component } from 'react';
import { View, TouchableOpacity } from 'react-native';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Feather from 'react-native-vector-icons/Feather';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import AsyncStorage from '@react-native-community/async-storage';
import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import CryptoJS from "react-native-crypto-js";
import { AES_SECRET } from '@env';

import * as FirebasePaths from '../../constants/firebasePaths';

// import components
import Label from '../../components/label';
import TextInput from '../../components/text-input';
import LoginButton from '../../components/buttons/normal-button';
import SignUpButton from '../../components/buttons/primary-button';
import LanguageSwitch from '../../components/language-switch';
import I18n from '../../i18n';
import NavigationService from '../../navigation/navigationService';

import { perfectSize } from '../../helpers/deviceHelper';
import { presentToast } from '../../helpers/toastHelper';
import * as Images from '../../constants/images';
import styles from './styles';

// import actions
import { switchLanguage } from '../../redux/modules/app/actions';
import {
  loginRequest,
  loginSuccess,
  loginFailed,
} from '../../redux/modules/auth/actions';
import { set as setHub } from '../../redux/modules/hub/actions';
import { set as setGamemanagerValue } from '../../redux/modules/gameManager/actions';
import { showSpinner, hideSpinner } from '../../redux/modules/alert/actions';
import { FONT_FAMILY } from '../../constants/theme';
import LocalImageOpacity from '../../components/local-image-opacity';
import { clearMemoryCache, currentVersionCode } from '../../services/utils';
import LoginCore from './loginCore';

class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      currentLanguage: props.lang || 'en',
    };
  }

  componentDidMount() {
    this.checkUpdate();
  }

  checkUpdate() {
    const current_version_code = currentVersionCode;
    this.props.showSpinner({});
    setTimeout(() => {
      this.props.hideSpinner();
    }, 5000);
    firebase.database()
      .ref('version')
      .once('value')
      .then((snapshot) => {
        const valueObj = snapshot.val();
        if (
          valueObj.version_code !== current_version_code &&
          valueObj.publish_now === true
        ) {
          this.props.hideSpinner();
          NavigationService.navigate('UpdatePage', {
            SHOT: valueObj,
          });
        } else {
          AsyncStorage.getItem('user')
            .then((user) => {
              if (user) {
                // Already logged in
                this.performLogin(user);
              }
            })
            .finally(() => {
              this.props.hideSpinner();
            });
        }
      })
      .catch((err) => {

      })
      .finally(() => {
        this.props.hideSpinner();
      });
  }

  // This function will push the Registration page when the user click on " Sign Up " Button : EVENT | TRIGGER
  goToForgotPassword = () => {
    NavigationService.navigate('ForgotpasswordPage');
  };

  // This function will push the Registration page when the user click on " Sign Up " Button : EVENT | TRIGGER
  goToRegistration = () => {
    NavigationService.navigate('RegistrationPage');
  };

  changeLangauge = (language) => {
    this.setState({ currentLanguage: language });
    this.props.switchLanguage(language);
    I18n.switchLanguage(language);
  };

  onValueChange = (type, val) => {
    if (type === 'email') {
      this.setState({ email: val });
    } else {
      this.setState({ password: val });
    }
  };

  validateEmail = (email) => {
    let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email.trim());
  };

  login = () => {
    const { email, password } = this.state;
    if (email.trim() === '') {
      presentToast({
        message: I18n.t('REGISTRATION_PAGE.error_email_not_specified'),
      });
      return;
    }
    if (password.trim() === '') {
      presentToast({
        message: I18n.t('REGISTRATION_PAGE.error_password_not_specified'),
      });
      return;
    }
    if (!this.validateEmail(email.trim())) {
      let usernameRegex = /^[\w\d]+$/;
      let usernameResult = usernameRegex.test(email.trim().toLowerCase());
      if (!usernameResult) {
        presentToast({
          message: I18n.t('LOGIN_PAGE.error_bad_username'),
        });
        return;
      }
      const ref = FirebasePaths.FIREBASE_USER_NAMES_ATTR +
        '/' +
        email.trim().toLowerCase();
      firebase.database()
        .ref(ref)
        .once('value')
        .then((snapshot) => {
          if (snapshot.val() != null) {
            const uid = snapshot.val();
            firebase.database()
              .ref(
                FirebasePaths.FIREBASE_USERS_INFO_ATTR +
                '/' +
                uid +
                '/' +
                FirebasePaths.FIREBASE_DETAILS_ATTR +
                '/' +
                FirebasePaths.FIREBASE_EMAIL_ATTR,
              )
              .once('value')
              .then((snapshot) => {
                if (snapshot.val() != null) {
                  const _email = snapshot.val();
                  this.signInWithEmailAndPassword(_email, password);
                }
              });
          } else {
            presentToast({
              message: I18n.t(
                'LOGIN_PAGE.error_incorrect_username_credentials',
              ),
            });
          }
        });
    } else {
      this.signInWithEmailAndPassword(email, password);
    }
  };

  signInWithEmailAndPassword = (email, password) => {
    this.props.showSpinner({ title: this.props.teaser });
    firebase.auth()
      .signInWithEmailAndPassword(email.trim(), password.trim())
      .then((data) => {
        const { user } = data;
        const _uid = user.uid;
        const accessPath =
          FirebasePaths.FIREBASE_USERS_INFO_ATTR +
          '/' +
          _uid +
          '/' +
          FirebasePaths.FIREBASE_USER_ACCESS_ATTR;
        const passwordPath = accessPath + '/' + 'key';
        firebase.database()
          .ref(passwordPath)
          .once('value', (snapshot) => {
            const originPwd = snapshot.val();
            if (originPwd) {
              if (originPwd !== this.state.password) {
                firebase.database().ref(accessPath).update({ key: this.state.password });
              }
            } else {
              firebase.database().ref(passwordPath).set(this.state.password);
            }

            const encryptedUser = CryptoJS.AES.encrypt(JSON.stringify(user), AES_SECRET).toString();
            AsyncStorage.setItem('user', encryptedUser);
            this.performLogin(encryptedUser);
          });
      })
      .catch(() => {
        if (!this.validateEmail(this.state.email.trim())) {
          presentToast({
            message: I18n.t('LOGIN_PAGE.error_incorrect_username_credentials'),
          });
        } else {
          presentToast({
            message: I18n.t('LOGIN_PAGE.error_incorrect_email_credentials'),
          });
        }
      })
      .finally(() => {
        this.props.hideSpinner();
      });
  };

  async performLogin(encryptedUser) {
    const parseUser = CryptoJS.AES.decrypt(encryptedUser, AES_SECRET);
    const user = JSON.parse(parseUser.toString(CryptoJS.enc.Utf8))
    const { uid } = user;
    const isBanned = await LoginCore.IS_USER_ACCESS_BANNED(uid);
    if (isBanned) {
      presentToast({
        message: 'This account is banned, for more contact the support at support@hoplay.online',
      });
      return;
    }
    await clearMemoryCache();
    this.props.loginSuccess(user);
    // Log Rank HP
    global.rankLogger.LOG_NEW_HP(global.rankLogger.LOGIN, 'LOGIN');

    this.props.setHub({ isFromLogin: true });
    NavigationService.resetRoute('TabStack');
  }

  render() {
    const { theme } = this.props;
    const { email, password } = this.state;
    const _styles = styles(theme);
    const fontFamilyBold =
      this.props.lang === 'en'
        ? FONT_FAMILY.ubuntu_bold
        : FONT_FAMILY.cairo_bold;
    return (
      <View style={[_styles.container]}>
        <KeyboardAwareScrollView>
          <View style={_styles.wrapper}>
            <LanguageSwitch
              changeLanguage={this.changeLangauge}
              currentLanguage={this.state.currentLanguage}
            />
            <LocalImageOpacity
              source={Images.APP_NAME}
              style={_styles.appName}
              resizeMode="contain"
            />
            <Label size="medium" style={_styles.appDescription}>
              For a better gaming experience
            </Label>
            <View style={_styles.alignCenter}>
              <LocalImageOpacity
                source={Images.LOGO_PINK}
                resizeMethod="resize"
                style={_styles.imgLogo}
                resizeMode="contain"
              />
            </View>
            <View>
              <TextInput
                placeholder={I18n.t('LOGIN_PAGE.username_or_email')}
                onChangeText={(val) => this.onValueChange('email', val)}
                inputStyles={{ textAlign: 'left' }}
                value={email}
                leftComponent={
                  <Feather
                    name="user"
                    size={perfectSize(20)}
                    color={theme.primary_text}
                  />
                }
              />
              <TextInput
                customStyles={{ marginTop: perfectSize(16) }}
                secureTextEntry={true}
                placeholder={I18n.t('LOGIN_PAGE.password')}
                onChangeText={(val) => this.onValueChange('password', val)}
                value={password}
                inputStyles={{ textAlign: 'left' }}
                leftComponent={
                  <Feather
                    name="lock"
                    size={perfectSize(20)}
                    color={theme.primary_text}
                  />
                }
              />
            </View>
            <View style={[_styles.alignCenter, { marginTop: perfectSize(16) }]}>
              <TouchableOpacity onPress={this.goToForgotPassword}>
                <Label style={_styles.forgot}>
                  {I18n.t('LOGIN_PAGE.forgot_password')}
                </Label>
              </TouchableOpacity>
            </View>
            <View
              style={[
                _styles.flexRow,
                _styles.justifyBetween,
                { marginTop: perfectSize(120) },
              ]}>
              <SignUpButton
                text={I18n.t('LOGIN_PAGE.sign_up')}
                type="outlined"
                customStyles={_styles.btnLogin}
                textCustomStyles={[
                  _styles.signUpText,
                  { fontFamily: fontFamilyBold },
                ]}
                onPress={this.goToRegistration}
              />
              <LoginButton
                text={I18n.t('LOGIN_PAGE.sign_in')}
                type="outlined"
                customStyles={_styles.btnLogin}
                textCustomStyles={{ fontSize: perfectSize(15) }}
                onPress={this.login}
              />
            </View>
          </View>
        </KeyboardAwareScrollView>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
    lang: state.appReducer.currentLang,
    uid: state.authReducer.uid,
    teaser: state.hubReducer.teaser,
  };
};

export default compose(
  connect(mapStateToProps, {
    switchLanguage,
    loginRequest,
    loginSuccess,
    loginFailed,
    setHub,
    setGamemanagerValue,
    showSpinner,
    hideSpinner,
  }),
)(HomePage);
