/* eslint-disable react-native/no-inline-styles */
import React, {Component} from 'react';
import {View, TouchableOpacity, FlatList, Platform, PermissionsAndroid, ScrollView, StyleSheet} from 'react-native';
import {compose} from 'redux';
import {connect} from 'react-redux';
import I18n from '../../../i18n';
import _ from 'lodash';
import firebase from 'firebase/app';
import 'firebase/database';

// import components
import Label from '../../../components/label';
import styles from './styles';
// import actions
import {switchTheme} from '../../../redux/modules/app/actions';
import {set as setHub} from '../../../redux/modules/hub/actions';
import {
    showAlert,
    showInputAlert,
    showSpinner,
    hideSpinner,
} from '../../../redux/modules/alert/actions';

import {perfectSize} from '../../../helpers/deviceHelper';
import {presentToast} from '../../../helpers/toastHelper';
import Team from '../../../models/team';

import * as FirebasePaths from '../../../constants/firebasePaths';
import TextArea from '../../../components/text-area';
import HootReport from '../../../models/hootReport';


class HootDetailReporter extends Component {
    constructor(props) {
        super(props);
        this.mount = true;
        this.state = {
            report: null,
        };
    }

    componentDidMount() { }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.showHootDetailReport !== this.props.showHootDetailReport && this.props.showHootDetailReport) {
            this.report(this.props.userName);
        }
    }

    componentWillUnmount() {
        this.mount = false;
    }

    report = (userName, userId) => {
        const {theme} = this.props;
        const _styles = styles(theme);
        this.props.showInputAlert([
            I18n.t('HOOTS_PAGE.hoots_report_report') + ' ' + userName,
            <View style={_styles.dialogSectionContainer}>
                <Label style={_styles.dialogDescription}>
                    {I18n.t('SEARCH_REQUESTS_RESULT_PAGE.report_msg')}
                </Label>
                <TextArea
                    placeholder={I18n.t('POST_PAGE.report_placeholder')}
                    multiline={true}
                    customStyles={_styles.inputBoxCustomStyle}
                    inputStyles={_styles.inputBoxStyle}
                    onChangeText={(val) => this.setState({report: val})}
                />
            </View>,
            [
                {
                    text: I18n.t(
                        'HOOTS_PAGE.hoots_report_cancel',
                    ),
                    onPress: () => {
                        this.props.setHub({
                            showHootDetailReport: false
                        })
                    },
                },
                {
                    text: I18n.t('HOOTS_PAGE.hoots_report_report'),
                    onPress: async () => {
                        // Log participant report user confirm
                        global.featuresLogger.HOOT_CALL_PARTICIPANT_OPTIONS_REPORT_USER_CONFIRM();
                        const reportPrefix = FirebasePaths.FIREBASE_HOOTS_REPORTS +
                            '/' +
                            FirebasePaths.FIREBASE_HOOTS_REPORTS_IN_CALL + '/';
                        const reportId = firebase.database().ref().push().key;
                        const reportNewPath = reportPrefix + FirebasePaths.FIREBASE_HOOTS_REPORTS_NEW +
                            '/' +
                            reportId;
                        const reportOldPath = reportPrefix + FirebasePaths.FIREBASE_HOOTS_REPORTS_OLD +
                            '/' +
                            reportId;                        
                        const {currentHoot} = this.props;
                        const reportObject = new HootReport();
                        reportObject.id = reportId;
                        reportObject.hootId = currentHoot.id;
                        reportObject.msg = this.state.report;
                        reportObject.reporterId = this.props.uid;
                        reportObject.reporterEmail = this.props.authUserInfo.userEmail;
                        reportObject.reporterUsername = this.props.authUserInfo.username;
                        reportObject.reportedUID = currentHoot.admin;
                        reportObject.reportedUsername = currentHoot.historyParticipants[currentHoot.admin].username;
                        reportObject.timestamp = new Date().toDateString();
                        await firebase.database().ref(reportNewPath).set(reportObject);
                        await firebase.database().ref(reportOldPath).set(reportObject);
                        presentToast({
                            message: I18n.t('SUPPORT_PAGE.success_msg')
                        })
                        this.setState({report: ''})
                        this.props.setHub({
                            showHootDetailReport: false
                        })
                    },
                },
            ],
            true,
            {
                height: perfectSize(32),
                width: perfectSize(90),
                alignItems: 'center'
            }
        ]);
    };

    render() {
        const {theme} = this.props;
        return <View />;
    }
}

const mapStateToProps = (state) => {
    return {
        theme: state.appReducer.currentTheme,
        blockedList: state.hubReducer.blockedList,
        uid: state.authReducer.uid,
        authUserInfo: state.authReducer.userinformationDB,
        friendsList: state.hubReducer.friendsList,
        blockedList: state.hubReducer.blockedList,
        showHootDetailReport: state.hubReducer.showHootDetailReport
    };
};

export default compose(
    connect(mapStateToProps, {
        switchTheme,
        setHub,
        showAlert,
        showInputAlert,
        showSpinner,
        hideSpinner,
    }),
)(HootDetailReporter);

