import { StyleSheet } from 'react-native';
import Dimens from '../../constants/dimens';
import { perfectSize } from '../../helpers/deviceHelper';

export default StyleSheet.create({
  header: {
    height: Dimens.HEADER_HEIGHT,
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerLeft: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  avatar: {
    marginLeft: perfectSize(4),
  },
  wrapper: {
    flex: 1,
    marginLeft: perfectSize(8),
    marginRight: perfectSize(32),
  },
  subTitle: {
    color: '#A2A5A9',
  },
  headerRight: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  footer: {
    flexDirection: 'row',
    marginTop: perfectSize(8),
    marginBottom: perfectSize(16),
    alignItems: 'flex-end',
    paddingHorizontal: perfectSize(8),
  },
  inputBox: {
    flex: 1,
  },
  textInput: {
    maxHeight: 150,
    minHeight: 52,
    height: 'auto',
    paddingVertical: perfectSize(8),
  },
  sendBtn: {
    paddingHorizontal: perfectSize(8),
    marginLeft: perfectSize(8),
    height: '100%',
  },
  flexRow: {
    flexDirection: 'row',
  },
  rootContent: {
    flex: 1,
    backgroundColor: '#0E1319',
    paddingHorizontal: Dimens.BODY_PADDING_HORIZONTAL,
  },
  adCard: {
    borderRadius: perfectSize(12),
    marginBottom: perfectSize(16),
    width: '100%',
    aspectRatio: 327 / 100,
    height: 250,
    overflow: 'hidden',
  },
  adCover: {
    width: '100%',
    height: '100%',
  },
});
