/* eslint-disable react-native/no-inline-styles */
import React, {Component} from 'react';
import {View, StyleSheet} from 'react-native';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {perfectSize} from '../../helpers/deviceHelper';

import NormalButton from '../../components/buttons/normal-button';

class LanguageSwitch extends Component {
  render() {
    const {theme, currentLanguage} = this.props;
    const _styles = styles(theme);
    return (
      <View style={_styles.container}>
        <NormalButton
          isPrimary={currentLanguage === 'ar'}
          text="Arabic"
          size="x_small"
          textCustomStyles={[
            currentLanguage !== 'ar' && _styles.textLight,
            _styles.langText,
          ]}
          customStyles={[
            _styles.langBtn,
            currentLanguage === 'en' ? {zIndex: 1} : {zIndex: 3},
            {
              width: 90,
            },
          ]}
          onPress={() => this.props.changeLanguage('ar')}
        />
        <NormalButton
          isPrimary={currentLanguage === 'en'}
          text="English"
          size="x_small"
          textCustomStyles={[
            currentLanguage !== 'en' && _styles.textLight,
            _styles.langText,
          ]}
          customStyles={[_styles.langBtn, _styles.btnEnglish]}
          onPress={() => this.props.changeLanguage('en')}
        />
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
  };
};

export default compose(connect(mapStateToProps, {}))(LanguageSwitch);

const styles = (theme) =>
  StyleSheet.create({
    container: {
      flexDirection: 'row',
    },
    langBtn: {
      height: 36,
    },
    textLight: {
      opacity: 0.56,
    },
    btnEnglish: {
      marginLeft: -perfectSize(12),
      zIndex: 2,
    },
    langText: {
      textAlign: 'center',
      lineHeight: perfectSize(19),
    },
  });
