/* eslint-disable react/no-did-mount-set-state */
/* eslint-disable react-native/no-inline-styles */
import React, { memo, Component } from 'react';
import { View, TouchableOpacity, Linking, ScrollView, FlatList } from 'react-native';
import { connect } from 'react-redux';
import Feather from 'react-native-vector-icons/Feather';
import Entypo from 'react-native-vector-icons/Entypo';
import firebase from 'firebase/app';
import 'firebase/database';

import { MaterialIndicator } from 'react-native-indicators';
import * as FirebasePaths from '../../constants/firebasePaths';
import I18n from '../../i18n';
import { store } from '../../redux/store';

// import components
import OverlayImage from '../../components/overlay-image';
import Tag from '../../components/buttons/tag';
import Join from '../../components/buttons/normal-button';
import Label from '../../components/label';
import TextInput from '../../components/text-input';
import UserAvatar from '../../components/user-avatar';
import Spinner from '../../components/spinner';
import ImageWithPlaceholder from '../../components/image-with-placeholder';

// import styles
import AppStyles from '../../constants/styles';
import styles from './styles';

// import actions
import { set as setHub } from '../../redux/modules/hub/actions';
import {
  showAlert,
  showInputAlert,
  showSpinner,
  hideSpinner,
} from '../../redux/modules/alert/actions';

import NavigationService from '../../navigation/navigationService';
import Request from '../../firebasecontrollers/request';
import { clearFastImageCache, getHumantime } from '../../services/utils';
import { perfectSize } from '../../helpers/deviceHelper';
import { getFlag } from '../../services/utils';
import Lobbycore from './lobbycore';
import * as Images from '../../constants/images';
import Postcore from '../post/postcore';
import FriendsListCore from '../friendslist/friendslistcore';
import { presentToast } from '../../helpers/toastHelper';
import { CreateRequestCore } from '../createrequest/createrequestcore';
import Chatcore from '../chat/chatcore';
import mainMenuService from '../../navigation/mainMenuService';
import topTabService from '../../navigation/topTabService';
import GameManager from '../../services/gameManager';

// import ad service
import AdService from '../../services/adSpot.service';
import LocalImageOpacity from '../../components/local-image-opacity';
import ImageWithPlaceholderWithoutFocus from '../../components/image-with-placeholder/without-focus';
import { Placeholder, Progressive } from 'rn-placeholder';
import SkeletonUserAvatar from '../../components/skeletonWrapper/skeletonUserAvatar';
import SkeletonTextContent from '../../components/skeletonWrapper/skeletonTextContent';
import { LinearGradient } from 'expo-linear-gradient';

class LobbyPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      request: null,
      AD: null,
    };
  }

  componentDidMount() {
    global.featuresLogger.LOBBY_AD_PAGE_VIEW();

    if (this.props.navigation.state.params.fromTimeline === true) {
      global.featuresLogger.HOPPED_REQUEST_VIEWED();

      let request = this.props.navigation.state.params.lastReq;
      this.setState({
        loading: true,
        request: request,
      });
      Lobbycore.loadLobby(
        request.requestId,
        request.platform,
        request.gameId,
        request.region,
      ).then((response) => {
        if (response.val()) {
          this.setState({
            loading: false,
          });

          this.loadData(response.val());
        } else {
          this.showError();
        }
      });
    } else if (this.props.navigation.state.params.fromChat === true) {
      let reqRef = this.props.navigation.state.params.chatRef;
      let request = this.props.allGroupChatRefs.get(reqRef);
      this.setState({
        loading: true,
        request: request,
      });
      Lobbycore.loadLobby(
        request.chatRef,
        request.platform,
        request.gameId,
        request.region,
      ).then((response) => {
        if (response.val()) {
          this.setState({
            loading: false,
          });
          this.loadData(response.val());
        } else {
          this.showError();
        }
      });
    } else if (this.props.navigation.state.params.REQUEST_SHOT) {
      let request = this.props.navigation.state.params.REQUEST_SHOT;
      this.setState({
        loading: true,
        request: request,
      });
      Lobbycore.loadLobby(
        request.requestId,
        request.platform,
        request.gameId,
        request.region,
      ).then((response) => {
        if (response.val()) {
          this.setState({
            loading: false,
          });
          this.loadData(response.val());
        } else {
          this.showError();
        }
      });
    } else if (this.props.navigation.state.params.requestId) {
      let region = this.props.navigation.state.params.region;
      region = decodeURI(region);
      if (region === 'All') {
        region = 'All Countries';
      }

      this.setState({
        loading: true,
        request: {
          requestId: this.props.navigation.state.params.requestId,
          platform: this.props.navigation.state.params.platform,
          gameId: this.props.navigation.state.params.gameId,
          region: region,
        },
      });
      Lobbycore.loadLobby(
        this.props.navigation.state.params.requestId,
        this.props.navigation.state.params.platform,
        this.props.navigation.state.params.gameId,
        region,
      ).then((response) => {
        if (response.val()) {
          this.setState({
            loading: false,
          });
          this.loadData(response.val());
        } else {
          this.showError();
        }
      });
    } else {
      let request = this.props.navigation.state.params.lastReq;
      this.setState({
        loading: true,
        request: request,
      });
      Lobbycore.loadLobby(
        request.requestId,
        request.platform,
        request.gameId,
        request.region,
      ).then((response) => {
        if (response.val()) {
          this.setState({
            loading: false,
          });
          this.loadData(response.val());
        } else {
          this.showError();
        }
      });
    }

    global.featuresLogger.LOG_REQUEST_VIEW();
    global.rankLogger.LOG_NEW_HP(global.rankLogger.VIEW_GROUP, 'VIEW_GROUP');

    CreateRequestCore.CheckRankGamePromo((data) => {
      if (data && data.game && data.JOIN_REQUEST) {
        this.setState({
          PROMO_GAME_ID: data.game,
          JOIN_REQUEST_GAME_PROMO: data.JOIN_REQUEST,
        });
      }
    });

    // Load the AD
    this.ADRef$ = firebase.database().ref(
      FirebasePaths.FIREBASE_AD_SPOTS +
      '/' +
      FirebasePaths.FIREBASE_AD_SPOTS_LOBBY,
    );
    this.ADRef$.on('value', (snapshot) => {
      if (snapshot.val() != null) {
        this.setState({
          AD: snapshot.val(),
        });
      } else {
        this.setState({
          AD: 'NONE',
        });
      }
    });
  }

  componentWillUnmount() {
    if (this.ADRef$) {
      this.ADRef$.off();
      this.ADRef$ = null;
    }
  }

  showError = () => {
    this.props.showAlert([
      I18n.t('LOBBY_PAGE.no_team_found_title'),
      I18n.t('LOBBY_PAGE.no_team_found_msg_from_chat'),
      [
        {
          text: I18n.t('LOBBY_PAGE.no_team_found_btn'),
          onPress: () => {
            NavigationService.goBack();
          },
        },
      ],
      false,
    ]);
  };
  loadData = (request) => {
    this.setState({ request: request }, () => {
      let ref =
        FirebasePaths.FIREBASE_USERS_INFO_ATTR +
        '/' +
        this.state.request.admin +
        '/' +
        FirebasePaths.FIREBASE_DETAILS_ATTR;
      firebase.database()
        .ref(ref)
        .once('value', (snapshot) => {
          const shot = snapshot.val();
          let photoUrl = shot._picUrl_;
          let rank = 0;
          if (shot._rank_) {
            rank = shot._rank_;
          }
          if (photoUrl === 'NULL' || photoUrl === 'default') {
            photoUrl =
              'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/teams%20default%20photos%20and%20background%2Fdefault_team_logov2.png?alt=media&token=b88bf926-35b9-4568-a80e-ee24110aceb6';
          }
          this.setState({
            userPhoto: photoUrl,
            rank: rank,
            adminProfile: shot,
          });
        });

      if (this.state.request.platform === 'PC') {
        this.setState({
          pcProvider: this.props.gamesList[this.state.request.gameId]
            .pcGameProvider,
        });
      }

      if (this.state.request.players) {
        let players = Object.values(this.state.request.players);

        this.loadPlayers(players);
      }

      if (
        this.state.request.playersNumber ===
        this.state.request.currentPlayersNumber
      ) {
        presentToast({
          message: I18n.t('LOBBY_PAGE.full_team'),
        });
      }
    });
  };

  toFriendProfilePage = (uid) => {
    if (uid.length > 0) {
      NavigationService.navigate('ViewfriendprofilePage', {
        FRIEND_KEY: uid,
      });
    } else {
      if (
        this.props.friendsList.filter((e) => e.UID === this.state.request.admin)
          .length > 0
      ) {
        this.props.showAlert([
          I18n.t('LOBBY_PAGE.player_settings'),
          '',
          [
            {
              text: I18n.t('LOBBY_PAGE.view_profile'),
              onPress: () => {
                NavigationService.navigate('ViewfriendprofilePage', {
                  FRIEND_KEY: this.state.request.admin,
                });
              },
            },
          ],
          false,
        ]);
      } else {
        this.props.showAlert([
          I18n.t('LOBBY_PAGE.player_settings'),
          '',
          [
            {
              text: I18n.t('LOBBY_PAGE.player_settings_add'),
              onPress: () => {
                this.setState({ friendAdded: true });
                FriendsListCore.addFriend(this.state.request.admin);
                presentToast({
                  message: I18n.t('LOBBY_PAGE.success_add'),
                });
              },
            },
            {
              text: I18n.t('LOBBY_PAGE.view_profile'),
              onPress: () => {
                NavigationService.navigate('ViewfriendprofilePage', {
                  FRIEND_KEY: this.state.request.admin,
                });
              },
            },
          ],
          false,
        ]);
      }
    }
  };

  userItem = (
    theme,
    userAvatar,
    userName,
    time,
    rank = -1,
    account = '',
    borderColor,
    uid = '',
    status = '',
  ) => {
    const _styles = styles(theme);
    return (
      <TouchableOpacity
        onPress={() => this.toFriendProfilePage(uid)}
        style={[_styles.flexRow, { marginVertical: perfectSize(10) }]}>
        <UserAvatar
          src={userAvatar}
          online={status === 'online'}
          showStatus={status.length > 0}
          borderColor={borderColor}
        />
        <View style={_styles.userInfoWrap}>
          <View style={_styles.flexRow}>
            <Label size="x_small" style={_styles.userName} selectable={true}>
              {userName}
            </Label>
            {rank >= 0 && (
              <ImageWithPlaceholder
                uri={global.rankLogger.getRankImg(rank)}
                style={global.rankLogger.getRankStyle(rank)}
                resizeMethod={'resize'}
                resizeMode="contain"
              />
            )}
          </View>
          <Label size="small" style={_styles.point}>
            {time}
          </Label>
          {account.length > 0 && (
            <Label size="small" style={_styles.point} selectable={true}>
              {account}
            </Label>
          )}
        </View>
      </TouchableOpacity>
    );
  };

  getProfileBorderColor(uid) {
    let cosmeticsPath =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      uid +
      '/' +
      FirebasePaths.FIREBASE_COSMETICS_ATTR +
      '/' +
      FirebasePaths.FIREBASE_PROFILE_BORDER_ATTR;

    return firebase.database()
      .ref(cosmeticsPath)
      .once('value')
      .then((actions) => {
        let borderColor = '#880e4a';
        let profile = actions.val();

        if (profile && profile._color_ && profile._color_ !== undefined) {
          borderColor = profile._color_;
        }

        return borderColor;
      });
  }

  loadPlayers = async (players) => {
    this.setState({
      playerloading: true,
    });
    try {
      for (let i = 0; i < players.length; i++) {
        players[i].borderColor = await this.getProfileBorderColor(
          players[i].uid,
        );
        if (players[i].photoUrl === 'assets/imgs/profile_default_photo.png') {
          players[i].photoUrl =
            'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/teams%20default%20photos%20and%20background%2Fdefault_team_logov2.png?alt=media&token=b88bf926-35b9-4568-a80e-ee24110aceb6';
        }
        players[i].status = await Postcore.getUserStatus(players[i].uid);
        players[i].rank = await Postcore.getUserRank(players[i].uid);
      }
    } catch (err) { }

    this.setState({
      players: players,
      playerloading: false,
    });
  };

  renderAdmin = () => {
    const { theme } = this.props;

    let account = '';
    if (this.state.request.platform === 'PC') {
      if (this.state.adminProfile.PC_game_providers) {
        account =
          this.state.adminProfile.PC_game_providers[this.state.pcProvider] +
          ' on ' +
          this.state.pcProvider;
      }
    } else if (
      this.state.request.platform === 'PS' &&
      this.state.request.PSN_account !== 'PSN_account'
    ) {
      account = this.state.request.PSN_account + ' on PSN';
    } else if (
      this.state.request.platform === 'XBOX' &&
      this.state.request.XBOX_live_account !== 'XBOX_live_account'
    ) {
      account = this.state.request.XBOX_live_account + ' on XBox';
    } else if (this.state.request.platform === 'NINTENDO') {
      account = this.state.adminProfile.NINTENDO_account + ' on NINTENDO';
    } else if (this.state.request.platform === 'MOBILE') {
      if (this.state.adminProfile.MOBILE_game_providers) {
        account =
          this.state.adminProfile.MOBILE_game_providers[
          this.state.request.requestTitle + ' Mobile'
          ] + ' on MOBILE';
      }
    }
    return this.userItem(
      theme,
      this.state.userPhoto,
      this.state.request.adminName,
      getHumantime(this.state.request.timeStamp),
      this.state.rank,
      account,
    );
  };

  renderPlayer = (player) => {
    const { theme } = this.props;

    let account = '';

    if (
      this.state.request.platform === 'PC' &&
      player.pcProviderAccount !== 'pcProviderAccount'
    ) {
      account = player[this.state.pcProvider] + ' on ' + this.state.pcProvider;
    } else if (
      this.state.request.platform === 'PS' &&
      player.PSN_account !== 'PSN_account'
    ) {
      account = player.PSN_account + ' on PSN';
    } else if (
      this.state.request.platform === 'XBOX' &&
      player.XBOX_live_account !== 'XBOX_live_account'
    ) {
      account = player.XBOX_live_account + ' on XBox';
    } else if (
      this.state.request.platform === 'NINTENDO' &&
      player.NINTENDO_account !== 'NINTENDO_account'
    ) {
      account = player.NINTENDO_account + ' on NINTENDO';
    } else if (this.state.request.platform === 'MOBILE') {
      account =
        player[this.state.request.requestTitle + ' Mobile'] + ' on MOBILE';
    }

    return this.userItem(
      theme,
      player.photoUrl,
      player.username,
      '',
      player.rank,
      account,
      player.borderColor,
      player.uid,
      player.status,
    );
  };

  joinRequest = () => {
    Lobbycore.IS_JOIN_REQUEST_BANNED((isBanned) => {
      if (isBanned) {
        this.props.showAlert([
          I18n.t('LOBBY_PAGE.ban_alert_title'),
          I18n.t('LOBBY_PAGE.ban_alert_msg'),
          [
            {
              text: I18n.t('LOBBY_PAGE.ban_alert_btn'),
              onPress: () => {
                NavigationService.moveToScreenInStack(
                  'SettingsStack',
                  'ContactUsPage',
                );
              },
            },
          ],
          false,
        ]);
      } else {
        store.dispatch(
          setHub({
            createdReq: this.state.request,
          }),
        );
        global.featuresLogger.LOG_REQUEST_JOIN();

        this.props.showAlert([
          I18n.t('LOBBY_PAGE.alert_join') +
          this.state.request.adminName +
          I18n.t('LOBBY_PAGE.alert_team'),
          I18n.t('LOBBY_PAGE.join_msg'),
          [
            {
              text: I18n.t('LOBBY_PAGE.yes'),
              onPress: () => {
                this.processJoin();
              },
            },
            {
              text: I18n.t('LOBBY_PAGE.no'),
              onPress: () => { },
            },
          ],
        ]);
      }
    });
  };

  showGameProviderDailog = (game, platform) => {
    const { theme } = this.props;
    const _styles = styles(theme);

    let title = I18n.t('LOBBY_PAGE.provider_title_default');
    let message = I18n.t('LOBBY_PAGE.provider_msg_default');
    let pcGameProvider = '';
    if (this.state.request.platform === 'PS') {
      title = I18n.t('LOBBY_PAGE.provider_psn');
      message = I18n.t('LOBBY_PAGE.provider_psn_msg');
    } else if (this.state.request.platform === 'XBOX') {
      title = I18n.t('LOBBY_PAGE.provider_xbox');
      message = I18n.t('LOBBY_PAGE.provider_xbox_msg');
    } else if (this.state.request.platform === 'NINTENDO') {
      title = I18n.t('LOBBY_PAGE.provider_nintendo');
      message = I18n.t('LOBBY_PAGE.provider_nintendo_msg');
    } else if (this.state.request.platform === 'MOBILE') {
      title = I18n.t('LOBBY_PAGE.provider_mobile');
      message = I18n.t('LOBBY_PAGE.provider_mobile_msg');
    } else {
      title = I18n.t('LOBBY_PAGE.provider_pc');
      message = I18n.t('LOBBY_PAGE.provider_pc_msg');

      pcGameProvider = this.props.gamesList[game].pcGameProvider;

      title = pcGameProvider + title;
      message = message + title;
    }

    this.setState(
      {
        providerTitle: title,
        providerMessage: message,
        pcGameProvider: pcGameProvider,
        temp: {
          game: game,
          platform: platform,
        },
      },
      () => {
        this.props.showInputAlert([
          this.state.providerTitle,
          <View style={_styles.dialogSectionContainer}>
            <Label style={_styles.dialogDescription}>
              {this.state.providerMessage}
            </Label>
            <TextInput
              style={[_styles.input]}
              placeholder={I18n.t('LOBBY_PAGE.example')}
              value={this.state.gameProviderAcc}
              placeholderTextColor={'#444444'}
              onChangeText={(value) =>
                this.setState({
                  gameProviderAcc: value,
                })
              }
            />
          </View>,
          [
            {
              text: I18n.t('LOBBY_PAGE.cancel').toUpperCase(),
              onPress: () => { },
            },
            {
              text: I18n.t('LOBBY_PAGE.save').toUpperCase(),
              onPress: () => {
                if (
                  this.state.gameProviderAcc &&
                  this.state.gameProviderAcc.length > 0
                ) {
                  this.onEnterAccount();
                } else {
                  presentToast({
                    message: I18n.t('CREATE_REQUEST_PAGE.enter_account'),
                  });
                }
              },
            },
          ],
        ]);
      },
    );
  };

  processJoin = async () => {
    global.rankLogger.LOG_NEW_HP(
      global.rankLogger.JOIN_REQUEST,
      'JOIN_REQUEST',
    );

    // Check PROMO HP

    if (
      this.state.PROMO_GAME_ID !== '' &&
      this.state.PROMO_GAME_ID === this.state.request.gameId
    ) {
      global.rankLogger.LOG_NEW_HP(
        this.state.JOIN_REQUEST_GAME_PROMO,
        'JOIN_REQUEST_GAME_PROMO',
      );
    }

    // Send notification to the admin

    let notificationId = firebase.database().ref().push().key;
    // Create a notification Obj

    let notificationObj = {
      id: notificationId,
      status: 'UNREAD',
      body:
        this.props.userinformationDB.username +
        ' ' +
        ' دخل على تيمك اللي طلبته ! شيك على قروبات الشات ',
      type: 'JOIN_REQUEST',
      TITLE: this.props.gamesList[this.state.request.gameId].gameName,
      SUBTITLE: 'Awesome Gamers',
      CHAT_IMG: this.props.gamesList[this.state.request.gameId].gamePhotoUrl,
      FRIEND_ID: this.state.request.requestId,
      CHAT_REF: this.state.request.requestId,
      timeStamp: firebase.database.ServerValue.TIMESTAMP,
    };

    let adminNotificationPath =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      this.state.request.admin +
      '/' +
      FirebasePaths.FIREBASE_USER_NOTIFICATIONS +
      '/' +
      notificationId;

    // For the admin
    firebase.database().ref(adminNotificationPath).set(notificationObj);
    // Getting the game provider

    let gameProvider = GameManager.getGameById(this.state.request.gameId)
      .pcGameProvider;

    if (
      this.state.request.platform === 'PS' &&
      this.props.userinformationDB.PSNAcc === ''
    ) {
      this.showGameProviderDailog(
        this.state.request.gameId,
        this.state.request.platform,
      );
    } else if (
      this.state.request.platform === 'XBOX' &&
      this.props.userinformationDB.XboxLiveAcc === ''
    ) {
      this.showGameProviderDailog(
        this.state.request.gameId,
        this.state.request.platform,
      );
    } else if (
      this.state.request.platform === 'NINTENDO' &&
      this.props.userinformationDB.nintendoAcc === ''
    ) {
      this.showGameProviderDailog(
        this.state.request.gameId,
        this.state.request.platform,
      );
    } else if (
      this.state.request.platform === 'MOBILE' &&
      (!this.props.userinformationDB.mobileGamesAccs.has(
        this.props.gamesList[this.state.request.gameId].gameName +
        ' ' +
        'Mobile',
      ) ||
        this.props.userinformationDB.mobileGamesAccs.get(
          this.props.gamesList[this.state.request.gameId].gameName +
          ' ' +
          'Mobile',
        ) === '')
    ) {
      this.showGameProviderDailog(
        this.state.request.gameId,
        this.state.request.platform,
      );
    } else if (
      this.state.request.platform === 'PC' &&
      (!this.props.userinformationDB.pcGamesAccs.has(gameProvider) ||
        this.props.userinformationDB.pcGamesAccs.get(gameProvider) === '')
    ) {
      // Check if the user has the game provider acc

      this.showGameProviderDailog(
        this.state.request.gameId,
        this.state.request.platform,
      );
    } else {
      // Open connection for group chat // And create a chat ref for this user

      // Getting the pc game provider

      let pcGameProvider = this.props.gamesList[this.state.request.gameId]
        .pcGameProvider;

      // Call the join function

      // Check first which platform you are targeting  to pass correct parameters

      if (this.state.request.platform === 'PS') {
        Lobbycore.joinLobby(
          this.state.request,
          pcGameProvider,
          this.props.userinformationDB.PSNAcc,
        );
      } else if (this.state.request.platform === 'XBOX') {
        Lobbycore.joinLobby(
          this.state.request,
          pcGameProvider,
          this.props.userinformationDB.XboxLiveAcc,
        );
      } else if (this.state.request.platform === 'NINTENDO') {
        Lobbycore.joinLobby(
          this.state.request,
          pcGameProvider,
          this.props.userinformationDB.nintendoAcc,
        );
      } else if (this.state.request.platform === 'MOBILE') {
        Lobbycore.joinLobby(
          this.state.request,
          pcGameProvider,
          this.props.userinformationDB.mobileGamesAccs.get(
            this.props.gamesList[this.state.request.gameId].gameName +
            ' ' +
            'Mobile',
          ),
        );
      } else {
        Lobbycore.joinLobby(
          this.state.request,
          pcGameProvider,
          this.props.userinformationDB.pcGamesAccs.get(pcGameProvider),
        );
      }
      // Join the chat  group : This process is used to pass the right game and account provider for the join message

      if (this.state.request.platform === 'PS') {
        Lobbycore.joinGroupChatRef(
          this.state.request,
          this.state.request.platform,
          'Playstation Account:',
          this.props.userinformationDB.PSNAcc,
        );
      } else if (this.state.request.platform === 'XBOX') {
        Lobbycore.joinGroupChatRef(
          this.state.request,
          this.state.request.platform,
          'Xbox Account:',
          this.props.userinformationDB.XboxLiveAcc,
        );
      } else if (this.state.request.platform === 'NINTENDO') {
        Lobbycore.joinGroupChatRef(
          this.state.request,
          this.state.request.platform,
          'Nintendo Account:',
          this.props.userinformationDB.nintendoAcc,
        );
      } else if (this.state.request.platform === 'MOBILE') {
        Lobbycore.joinGroupChatRef(
          this.state.request,
          this.state.request.platform,
          'Mobile Account:',
          this.props.userinformationDB.mobileGamesAccs.get(
            this.props.gamesList[store.getState().hubReducer.createdReq.gameId]
              .gameName +
            ' ' +
            'Mobile',
          ),
        );
      } else {
        Lobbycore.joinGroupChatRef(
          this.state.request,
          this.state.request.platform,
          pcGameProvider + ' ' + 'Account:',
          this.props.userinformationDB.pcGamesAccs.get(pcGameProvider),
        );
      }

      // Set a recent request ref

      let request = new Request();

      request.setUserReference(
        FirebasePaths.FIREBASE_USERS_INFO_ATTR +
        '/' +
        store.getState().authReducer.uid,
        this.state.request.requestId,
        this.state.request.matchType,
        this.state.request.gameId,
        this.props.gamesList[this.state.request.gameId].gameType,
        this.state.request.platform,
        this.state.request.region,
      );

      // Show success message and pop this activity and redirect user to main menu with lobby

      presentToast({
        message: 'انت الحيـن في التيم استمتع !',
      });

      // // Set global came from variable to joined lobby

      // Show redirection to community alert

      let showRequest = await Lobbycore.checkShowJoinRequest();

      if (showRequest) {
        global.featuresLogger.JOIN_REQUEST_ALERT_PAGE_VIEW();
        this.props.showInputAlert([
          this.props.lang === 'en' ? showRequest.titleEN : showRequest.titleAR,
          <View>
            <ImageWithPlaceholderWithoutFocus
              uri={showRequest.img}
              style={AppStyles.showJoinRequestImg}
              resizeMethod={'resize'}
              resizeMode="cover"
            />

            <Label style={AppStyles.showJoinRequestTitle}>
              {this.props.lang === 'en'
                ? showRequest.descriptionEN
                : showRequest.descriptionAR}
            </Label>
          </View>,
          [
            {
              text:
                this.props.lang === 'en'
                  ? showRequest.btnENText
                  : showRequest.btnARText,
              onPress: () => {
                global.featuresLogger.JOIN_REQUEST_ALERT_URL_BTN_CLICK();
                Linking.openURL(showRequest.url);
              },
            },
            {
              text: I18n.t('LOBBY_PAGE.open_team_chat'),
              onPress: () => {
                global.featuresLogger.JOIN_REQUEST_ALERT_CHAT_REDIRECTION();
                mainMenuService.navigate('Chat');
                setTimeout(() => {
                  topTabService.navigate('ChatRequests');
                }, 50);
              },
            },
          ],
          false,
        ]);
      } else {
        this.props.showAlert([
          I18n.t('LOBBY_PAGE.bazinga'),
          I18n.t('LOBBY_PAGE.bazinga_msg'),
          [
            {
              text: I18n.t('LOBBY_PAGE.check'),
              onPress: () => {
                mainMenuService.navigate('Chat');
                setTimeout(() => {
                  topTabService.navigate('ChatRequests');
                }, 50);
              },
            },
          ],
          false,
        ]);
        // Go to the lobby menu
      }

      Chatcore.openGroupChatsConnection(
        this.state.request.requestId,
        this.props.gamesList[this.state.request.gameId].gameName,
      );

      NavigationService.resetRoute('TabStack');
      // this.navCtrl.popToRoot();
    }
  };

  onEnterAccount = async () => {
    setTimeout(() => {
      Chatcore.openGroupChatsConnection(
        this.state.request.requestId,
        this.props.gamesList[this.state.request.gameId].gameName,
      );
    }, 3000);

    if (this.state.gameProviderAcc.length > 0) {
      Lobbycore.saveGameProviderAccount(
        this.state.pcGameProvider,
        this.state.gameProviderAcc,
        this.state.temp.platform,
        this.props.gamesList[this.state.temp.game].gameName,
      );

      if (this.state.request.platform === 'PS') {
        Lobbycore.joinGroupChatRef(
          this.state.request,
          this.state.temp.platform,
          'Playstation Account:',
          this.state.gameProviderAcc,
        );
      } else if (this.state.request.platform === 'XBOX') {
        Lobbycore.joinGroupChatRef(
          this.state.request,
          this.state.temp.platform,
          'Xbox Account:',
          this.state.gameProviderAcc,
        );
      } else if (this.state.request.platform === 'NINTENDO') {
        Lobbycore.joinGroupChatRef(
          this.state.request,
          this.state.temp.platform,
          'Nintendo Account:',
          this.state.gameProviderAcc,
        );
      } else if (this.state.request.platform === 'MOBILE') {
        Lobbycore.joinGroupChatRef(
          this.state.request,
          this.state.temp.platform,
          'Mobile Account:',
          this.state.gameProviderAcc,
        );
      } else {
        Lobbycore.joinGroupChatRef(
          this.state.request,
          this.state.temp.platform,
          this.state.pcGameProvider + ' ' + 'Account:',
          this.state.gameProviderAcc,
        );
      }

      Lobbycore.joinLobby(
        this.state.request,
        this.state.pcGameProvider,
        this.state.gameProviderAcc,
      );

      // Set a recent request ref

      let request = new Request();

      request.setUserReference(
        FirebasePaths.FIREBASE_USERS_INFO_ATTR +
        '/' +
        store.getState().authReducer.uid,
        this.state.request.requestId,
        this.state.request.matchType,
        this.state.request.gameId,
        this.props.gamesList[this.state.request.gameId].gameType,
        this.state.request.platform,
        this.state.request.region,
      );

      // Show success message and pop this activity and redirect user to main menu with lobby

      presentToast({
        message: 'انت الحيـن في التيم استمتع !',
      });

      // Show redirection to community alert

      let showRequest = await Lobbycore.checkShowJoinRequest();

      if (showRequest) {
        global.featuresLogger.JOIN_REQUEST_ALERT_PAGE_VIEW();
        this.props.showInputAlert([
          this.props.lang === 'en' ? showRequest.titleEN : showRequest.titleAR,
          <View>
            <ImageWithPlaceholder
              uri={showRequest.img}
              style={AppStyles.showJoinRequestImg}
              resizeMethod={'resize'}
              resizeMode="cover"
            />

            <Label style={AppStyles.showJoinRequestTitle}>
              {this.props.lang === 'en'
                ? showRequest.descriptionEN
                : showRequest.descriptionAR}
            </Label>
          </View>,
          [
            {
              text:
                this.props.lang === 'en'
                  ? showRequest.btnENText
                  : showRequest.btnARText,
              onPress: () => {
                global.featuresLogger.JOIN_REQUEST_ALERT_URL_BTN_CLICK();
                Linking.openURL(showRequest.url);
              },
            },
            {
              text: I18n.t('LOBBY_PAGE.open_team_chat'),
              onPress: () => {
                global.featuresLogger.JOIN_REQUEST_ALERT_CHAT_REDIRECTION();
                mainMenuService.navigate('Chat');
                setTimeout(() => {
                  topTabService.navigate('ChatRequests');
                }, 50);
              },
            },
          ],
          false,
        ]);
      } else {
        this.props.showAlert([
          I18n.t('LOBBY_PAGE.bazinga'),
          I18n.t('LOBBY_PAGE.bazinga_msg'),
          [
            {
              text: I18n.t('LOBBY_PAGE.check'),
              onPress: () => {
                mainMenuService.navigate('Chat');
                setTimeout(() => {
                  topTabService.navigate('ChatRequests');
                }, 50);
              },
            },
          ],
          false, // show in row ?
        ]);
      }

      // Go to the lobby menu

      // this.navCtrl.popToRoot();
      NavigationService.resetRoute('TabStack');
    } else {
      presentToast({
        message: this.state.providerMessage,
      });
    }
  };

  _shareLobby = () => {
    let message = `Check ${this.props.gamesList[this.state.request.gameId].gameName
      } team request`;
    let subject = 'Hoplay';
    let img = this.props.shareIconsMap.SHARE_REQUEST;
    let url =
      'https://hoplay.online/requests/' +
      this.state.request.requestId +
      '/' +
      this.state.request.platform +
      '/' +
      this.state.request.gameId +
      '/' +
      encodeURI(this.state.request.region);
    // Share.open({
    //   message,
    //   subject,
    //   img,
    //   url,
    // });
    global.rankLogger.LOG_NEW_HP(
      global.rankLogger.SHARE_REQUEST,
      'SHARE_REQUEST',
    );
    global.featuresLogger.LOG_SHARE_REQUEST();
  };

  openAD = () => {
    const { AD } = this.state;
    if (AD != null && AD.url != null) {
      global.featuresLogger.LOBBY_AD_CLICK();
      AdService.checkAdUrl(AD);
    }
  };

  report = () => {
    const { theme } = this.props;
    const _styles = styles(theme);

    this.props.showInputAlert([
      I18n.t('SEARCH_REQUESTS_RESULT_PAGE.report_title'),
      <View style={_styles.dialogSectionContainer}>
        <Label style={_styles.dialogDescription}>
          {I18n.t('SEARCH_REQUESTS_RESULT_PAGE.report_msg')}
        </Label>
        <TextInput
          style={_styles.input}
          placeholder={I18n.t('SEARCH_REQUESTS_RESULT_PAGE.report_placeholder')}
          // value={this.state.report}
          // numberOfLines={8}
          // multiline={true}
          onChangeText={(value) =>
            this.setState({
              report: value,
            })
          }
          placeholderTextColor={'#444444'}
        />
      </View>,
      [
        {
          text: I18n.t(
            'SEARCH_REQUESTS_RESULT_PAGE.report_cancel',
          ).toUpperCase(),
          onPress: () => { },
        },
        {
          text: I18n.t('SEARCH_REQUESTS_RESULT_PAGE.report_send').toUpperCase(),
          onPress: () => {
            if (!this.state.report || this.state.report.length === 0) {
              presentToast({
                message: I18n.t(
                  'SEARCH_REQUESTS_RESULT_PAGE.report_validation',
                ),
              });
              return;
            }
            global.rankLogger.LOG_NEW_HP(
              global.rankLogger.REPORT_REQUEST,
              'REPORT_REQUEST',
            );
            global.featuresLogger.LOG_FEEDBACKS_REQUEST_REPORT();
            this.addReport(
              this.state.request.admin,
              this.state.request.adminName,
              this.state.report,
              this.state.request.requestId,
            );
            presentToast({
              message: I18n.t('SEARCH_REQUESTS_RESULT_PAGE.report_success'),
            });
          },
        },
      ],
    ]);
  };

  addReport = (requesterId, requesterUsername, userMsg, requestId) => {
    // Generate unique Id

    let reportId = firebase.database().ref().push().key;

    // Get the current time

    let date = new Date();
    let timestamp = date.toDateString();

    let reportObj = {
      msg: userMsg,
      reporterEmail: this.props.userinformationDB.userEmail,
      reporterUsername: this.props.userinformationDB.username,
      reporterUid: this.props.uid,
      timestamp: timestamp,
      reportedUid: requesterId,
      reportedUsername: requesterUsername,
      requestId: requestId,
    };

    // Post as a new message

    firebase.database()
      .ref(
        FirebasePaths.FIREBASE_REPORTS_REFERENCE +
        '/' +
        FirebasePaths.FIREBASE_REPORTS_NEW_REFERENCE +
        '/' +
        reportId,
      )
      .set(reportObj);

    // Post as old message

    firebase.database()
      .ref(
        FirebasePaths.FIREBASE_REPORTS_REFERENCE +
        '/' +
        FirebasePaths.FIREBASE_REPORTS_OLD_REFERENCE +
        '/' +
        reportId,
      )
      .set(reportObj);

    this.setState({
      report: '',
    });
  };

  render() {
    const { theme } = this.props;
    const _styles = styles(theme);
    const { AD } = this.state;

    let compLabel = 'All Matches';
    if (this.state.request) {
      if (this.state.request.matchType === 'Competitive') {
        compLabel = 'Comp';
      } else if (this.state.request.matchType === 'Quick Match') {
        compLabel = 'Quick';
      }
    }

    return (
      <View style={_styles.container}>
        <Spinner visible={this.state.loading} />
        {this.state.request && (
          <>
            <View style={_styles.headerWrap}>
              <OverlayImage
                uri={
                  this.props.gamesList[this.state.request.gameId] !== undefined
                    ? this.props.gamesList[this.state.request.gameId]
                      .gameLargePhotoUrl
                      ? this.props.gamesList[this.state.request.gameId]
                        .gameLargePhotoUrl
                      : this.props.gamesList[this.state.request.gameId]
                        .gamePhotoUrl
                    : ''
                }
                style={_styles.fullSize}
                noBorder
              />
              <LinearGradient
                colors={['#00000033', '#000000cc']}
                style={_styles.gradient}
              />
            </View>
            <View style={_styles.userWrap}>
              <TouchableOpacity
                style={_styles.backArrow}
                onPress={() => {
                  clearFastImageCache();
                  NavigationService.goBack()
                }}>
                <Feather
                  name="chevron-left"
                  color="#C70064"
                  size={perfectSize(22.5)}
                />
              </TouchableOpacity>
              <TouchableOpacity
                style={_styles.shareIcon}
                onPress={() => {
                  this.report();
                }}>
                <Feather
                  name="more-vertical"
                  color="#C70064"
                  size={perfectSize(18)}
                />
              </TouchableOpacity>
              {this.state.adminProfile && (
                <View style={_styles.flexRow}>{this.renderAdmin()}</View>
              )}
            </View>
            <View style={_styles.infoWrap}>
              <ScrollView>
                <View
                  style={[_styles.flexRow, { justifyContent: 'space-between' }]}>
                  <View>
                    <Label size="small" style={_styles.point}>
                      {this.state.request.requestTitle}
                    </Label>
                    <View style={[_styles.flexRow, _styles.alignCenter]}>
                      <LocalImageOpacity
                        source={getFlag(this.state.request.region)}
                        style={_styles.image}
                        resizeMethod={'resize'}
                        resizeMode="contain"
                      />
                      <Label
                        size="small"
                        style={[
                          _styles.point,
                          {
                            marginLeft: perfectSize(4),
                            fontSize: perfectSize(10),
                          },
                        ]}>
                        {this.state.request.region}
                      </Label>
                    </View>
                  </View>
                  {this.state.request.admin !== this.props.uid &&
                    this.state.request.currentPlayersNumber !==
                    this.state.request.playersNumber &&
                    (!this.state.request.players ||
                      !(
                        this.state.request.players &&
                        this.state.request.players[this.props.uid]
                      )) && (
                      <Join
                        text={I18n.t('LOBBY_PAGE.join')}
                        textCustomStyles={{ fontSize: perfectSize(12) }}
                        onPress={() => {
                          this.joinRequest();
                        }}
                      />
                    )}
                </View>
                <View style={[_styles.flexRow]}>
                  {this.state.request.platform === 'PS' && (
                    <LocalImageOpacity
                      source={Images.BTN_TAG_PLAYSTATION_SELECTED}
                      style={_styles.btn_tag}
                      resizeMode="contain"
                    />
                  )}
                  {this.state.request.platform === 'PC' && (
                    <LocalImageOpacity
                      source={Images.BTN_TAG_DESKTOP_SELECTED}
                      style={_styles.btn_tag}
                      resizeMode="contain"
                    />
                  )}
                  {this.state.request.platform === 'XBOX' && (
                    <LocalImageOpacity
                      source={Images.BTN_TAG_BALL_SELECTED}
                      style={_styles.btn_tag}
                      resizeMode="contain"
                    />
                  )}
                  {this.state.request.platform === 'MOBILE' && (
                    <LocalImageOpacity
                      source={Images.BTN_TAG_MOBILE_SELECTED}
                      style={_styles.btn_tag}
                      resizeMode="contain"
                    />
                  )}
                  {this.state.request.platform === 'NINTENDO' && (
                    <LocalImageOpacity
                      source={Images.BTN_TAG_DOUBLE_SELECTED}
                      style={_styles.btn_tag}
                      resizeMode="contain"
                    />
                  )}
                  <Tag
                    title={compLabel}
                    customStyles={{ marginRight: perfectSize(7) }}
                  />
                  {this.state.request.matchType === 'Competitive' &&
                    this.state.request.rank && (
                      <Tag
                        title={this.state.request.rank}
                        customStyles={{ marginRight: perfectSize(7) }}
                      />
                    )}
                </View>
                <Label
                  size="x_small"
                  style={{ marginVertical: perfectSize(20) }}
                  selectable={true}>
                  {this.state.request.description}
                </Label>
                <View style={_styles.footerWrap}>
                  <View
                    style={[
                      _styles.flexRow,
                      { marginVertical: perfectSize(15), alignItems: 'center' },
                    ]}>
                    <Feather name="users" color="gray" size={perfectSize(17)} />
                    <Label
                      bold
                      size="medium"
                      style={{ marginHorizontal: perfectSize(10) }}>
                      {I18n.t('NEW_REQUEST_PAGE.players')}
                    </Label>
                    <Label size="small" style={_styles.point}>
                      {this.state.request.currentPlayersNumber}/
                      {this.state.request.playersNumber}
                    </Label>
                  </View>
                </View>

                <View>
                  {
                    this.state.playerloading &&
                    <FlatList
                      data={[0, 1, 2]}
                      showsVerticalScrollIndicator={false}
                      keyExtractor={(item) => item.toString()}
                      renderItem={() => {
                        return <View style={{ marginVertical: perfectSize(10) }}>
                          <Placeholder
                            Animation={Progressive}
                          >
                            <View style={{ flexDirection: 'row' }}>
                              <SkeletonUserAvatar size={32} />
                              <View style={{ marginLeft: perfectSize(10), flex: 1 }}>
                                <SkeletonTextContent height={20} width={50} />
                                <SkeletonTextContent height={20} width={100} style={{ marginTop: 10 }} />
                              </View>
                            </View>
                          </Placeholder>

                        </View>
                      }}
                    />
                  }
                </View>

                {this.state.players && (
                  <View style={_styles.usersWrap}>
                    {this.state.players.map((player) => {
                      return this.renderPlayer(player);
                    })}
                  </View>
                )}
              </ScrollView>
              {AD != null && AD !== 'NONE' && (
                <TouchableOpacity
                  style={_styles.adCard}
                  onPress={() => this.openAD()}>
                  {AD.img != null && (
                    <ImageWithPlaceholder
                      style={_styles.adCover}
                      uri={AD.img}
                    />
                  )}
                </TouchableOpacity>
              )}
            </View>
          </>
        )}
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
    gamesList: state.hubReducer.gamesList,
    uid: state.authReducer.uid,
    friendsList: state.hubReducer.friendsList,
    userinformationDB: state.authReducer.userinformationDB,
    pcGamesWithProviders: state.gameManagerReducer.pcGamesWithProviders,
    allGroupChatRefs: state.hubReducer.allGroupChatRefs,
    shareIconsMap: state.hubReducer.shareIconsMap,
    lang: state.appReducer.currentLang,
  };
};

export default memo(
  connect(mapStateToProps, {
    showAlert,
    showInputAlert,
    showSpinner,
    hideSpinner,
  })(LobbyPage),
);
