/* eslint-disable no-unused-vars */
import React, { memo, Component } from 'react';
import { View, StyleSheet } from 'react-native';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { perfectSize } from '../../../helpers/deviceHelper';
import { Placeholder, Progressive } from 'rn-placeholder';
import SkeletonUserAvatar from '../../skeletonWrapper/skeletonUserAvatar';
import SkeletonTextContent from '../../skeletonWrapper/skeletonTextContent';


class LeaderBoardSkeletonCard extends Component {
    render() {
        const { theme } = this.props;
        const _styles = styles(theme);
        return (
            // 
            <View style={_styles.body}>
                <Placeholder
                    Animation={Progressive}
                >
                    <View style={_styles.wrapper}>
                        <View style={{ flexDirection: 'row', alignItems: 'center', width: '80%' }}>
                            <SkeletonUserAvatar style={{ marginLeft: 30 }} size={48} />
                            <View style={{ flex: 1 }}>
                                <SkeletonTextContent height={12} width={70} style={{ marginLeft: 10 }} />
                            </View>
                        </View>
                        <View style={{ alignItems: 'center', marginRight: perfectSize(25), width: '20%' }}>
                            <SkeletonUserAvatar size={20} />
                            <SkeletonTextContent height={12} width={100} style={{ marginTop: 5 }} />
                        </View>
                    </View>
                </Placeholder>
            </View>
            // 
        );
    }
}

const mapStateToProps = (state) => {
    return {
        theme: state.appReducer.currentTheme,
    };
};

export default memo(connect(mapStateToProps)(LeaderBoardSkeletonCard));

const styles = (theme) =>
    StyleSheet.create({
        container: {
            marginTop: 10,
            flexDirection: 'column',
        },
        body: {
            paddingVertical: perfectSize(16),
        },
        wrapper: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between'
        },
        divider: {
            height: 1,
            backgroundColor: '#FFFFFF',
            opacity: 0.05,
        },
    });
