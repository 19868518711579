import React, { memo, Component } from 'react';
import { View } from 'react-native';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as FirebasePaths from '../../constants/firebasePaths';

import UserAvatar from '../user-avatar';

class ChatAvatar extends Component {
  constructor(props) {
    super(props);
    this.mount = true;
    this.state = {
      status: '',
    };
  }

  componentDidMount() {
    const { uid } = this.props;
    // if (uid != null) {
    //   let userStatusRef =
    //     FirebasePaths.FIREBASE_USERS_INFO_ATTR +
    //     '/' +
    //     uid +
    //     '/' +
    //     FirebasePaths.FIREBASE_DETAILS_ATTR +
    //     '/';
    //   this.statusRef$ = firebase.database().ref(userStatusRef);
    //   this.statusRef$.on('value', (shot) => {
    //     let status = 'online';
    //     shot.forEach((data) => {
    //       if (data.key === FirebasePaths.FIREBASE_STATUS_ATTR) {
    //         status = data.val();
    //       }
    //     });
    //     if (this.mount) {
    //       this.setState({ status: status.toString() });
    //     }
    //   });
    // }
  }

  componentWillUnmount() {
    // if (this.statusRef$) {
    //   this.statusRef$.off();
    //   this.statusRef$ = null;
    // }
    this.mount = false;
  }

  render() {
    const { src, uid } = this.props;
    const { status } = this.state;
    const size = this.props.size || 48;
    const borderColor = this.props.borderColor || '#003791';
    const borderWidth = this.props.borderWidth || 0;
    const containerStyle = {
      width: size,
      height: size,
      position: 'relative',
    };
    const wrapperStyle = {
      borderRadius: size / 2,
      borderColor: borderColor,
      borderWidth: borderWidth,
      overflow: 'hidden',
      justifyContent: 'center',
      alignItems: 'center',
    };
    const avatarStyle = {
      width: size - borderWidth * 2,
      height: size - borderWidth * 2,
    };
    const badgeRadius = 12;
    const pad = size * 0.14645 - badgeRadius / 2;
    const badgeStyle = {
      position: 'absolute',
      right: pad,
      top: pad,
      width: badgeRadius,
      height: badgeRadius,
      borderRadius: badgeRadius / 2,
      backgroundColor: '#C70064',
    };
    const statusStyle = {
      position: 'absolute',
      height: badgeRadius,
      width: badgeRadius,
      right: pad,
      bottom: pad,
      borderRadius: badgeRadius / 2,
      backgroundColor: status === 'online' ? '#00FFB4' : '#BABABA',
    };
    return (
      <View style={containerStyle}>
        <View style={wrapperStyle}>
          <UserAvatar
            uid={uid}
            src={src}
            size={size}
            style={avatarStyle}
          />
        </View>
        {this.props.unread != null && this.props.unread && (
          <View style={badgeStyle} />
        )}
        {this.props.showStatus && status !== '' && <View style={statusStyle} />}
      </View>
    );
  }
}

ChatAvatar.propTypes = {
  src: PropTypes.string,
  size: PropTypes.number,
  borderColor: PropTypes.string,
  borderWidth: PropTypes.number,
  showStatus: PropTypes.bool,
  uid: PropTypes.string,
  unread: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
  };
};

export default memo(connect(mapStateToProps)(ChatAvatar));
