import React, {memo, Component} from 'react';
import {Text, TouchableOpacity, StyleSheet} from 'react-native';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import {getFontSize} from '../../../constants/calc';
import {FONT_FAMILY, FONT_SIZE} from '../../../constants/theme';
import {perfectSize} from '../../../helpers/deviceHelper';

class PrimaryButton extends Component {
  render() {
    const {
      theme,
      text,
      size,
      type,
      onPress,
      customStyles,
      textCustomStyles,
      leftComponent,
      rightComponent,
    } = this.props;
    const fontSize = size ? getFontSize(size) : FONT_SIZE.small;
    const _backgroundColor =
      type === 'filled'
        ? theme.primary_btn_background
        : theme.primary_background;
    const fontFamily =
      this.props.lang === 'en' ? FONT_FAMILY.ubuntu : FONT_FAMILY.cairo;
    const _styles = styles(theme);
    return (
      <TouchableOpacity
        onPress={onPress}
        style={[
          _styles.container,
          {
            backgroundColor: _backgroundColor,
          },
          customStyles,
        ]}>
        {leftComponent && leftComponent}
        <Text
          style={[
            _styles.text,
            {
              fontSize: fontSize,
              fontFamily: fontFamily,
            },
            textCustomStyles,
          ]}>
          {text}
        </Text>
        {rightComponent && rightComponent}
      </TouchableOpacity>
    );
  }
}

const styles = (theme) =>
  StyleSheet.create({
    container: {
      borderRadius: perfectSize(12),
      justifyContent: 'center',
      height: 52,
      borderWidth: perfectSize(1),
      borderColor: theme.primary_btn_background,
      flexDirection: 'row',
      alignItems: 'center',
    },
    text: {
      // fontFamily: FONT_FAMILY.ubuntu,
      textAlign: 'center',
      color: theme.primary_text,
    },
  });

PrimaryButton.propTypes = {
  text: PropTypes.string.isRequired,
  size: PropTypes.oneOf([
    'small',
    'x_small',
    'medium',
    'x_medium',
    'big',
    'x_big',
    'large',
    'x_large',
  ]),
  type: PropTypes.oneOf(['filled', 'outlined']),
  customStyles: PropTypes.any,
  textCustomStyles: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
    lang: state.appReducer.currentLang,
  };
};

export default memo(connect(mapStateToProps)(PrimaryButton));
