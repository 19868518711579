import React, {memo, Component} from 'react';
import {View, StyleSheet, TouchableOpacity} from 'react-native';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Feather from 'react-native-vector-icons/Feather';
import firebase from 'firebase/app';
import 'firebase/database';

import * as FirebasePaths from '../../../constants/firebasePaths';

import {perfectSize} from '../../../helpers/deviceHelper';

import Label from '../../label';
import ImageWithPlaceholder from '../../image-with-placeholder';

class ClanCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      membersCount: null,
    };
  }

  componentDidMount() {
    const {teamId} = this.props;
    if (teamId != null) {
      this.membersRef$ = firebase.database().ref(
        FirebasePaths.FIREBASE_TEAMS +
          '/' +
          teamId +
          '/' +
          FirebasePaths.FIREBASE_TEAM_MEMBERS,
      );
      this.membersRef$.on('value', (snapshot) => {
        if (snapshot.val() != null) {
          this.setState({membersCount: snapshot.numChildren() + 1});
        } else {
          this.setState({membersCount: 1});
        }
      });
    }
  }

  componentWillUnmount() {
    if (this.membersRef$) {
      this.membersRef$.off();
      this.membersRef$ = null;
    }
  }

  render() {
    const {membersCount} = this.state;
    const {theme, photo, title, isInvitation, onAccept, onIgnore} = this.props;
    const _styles = styles(theme);
    return (
      <View style={_styles.container}>
        <ImageWithPlaceholder uri={photo} style={_styles.image} />
        <View style={_styles.wrapper}>
          <Label size="x_small" style={_styles.title}>
            {title}
          </Label>
          <View style={_styles.divider} />
          <View style={_styles.flexRow}>
            <View style={_styles.participantWrap}>
              <Feather name="users" color="gray" size={perfectSize(16)} />
              <Label size="x_small" style={_styles.participantCount}>
                {membersCount}
              </Label>
            </View>
            {isInvitation && (
              <View style={_styles.participantWrap}>
                <TouchableOpacity
                  onPress={onAccept}
                  style={_styles.actionButton}>
                  <Feather
                    name="check"
                    color={theme.primary_btn_background}
                    size={perfectSize(18)}
                  />
                  <Label size="small" style={_styles.actionTxt}>
                    Accept
                  </Label>
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={onIgnore}
                  style={_styles.actionButton}>
                  <Feather
                    name="x"
                    color={theme.primary_btn_background}
                    size={perfectSize(18)}
                  />
                  <Label size="small" style={_styles.actionTxt}>
                    Ignore
                  </Label>
                </TouchableOpacity>
              </View>
            )}
          </View>
        </View>
      </View>
    );
  }
}

ClanCard.propTypes = {
  teamId: PropTypes.string,
  photo: PropTypes.string,
  title: PropTypes.string,
  isInvitation: PropTypes.bool,
  onAccept: PropTypes.func,
  onIgnore: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
  };
};

export default memo(connect(mapStateToProps)(ClanCard));

const styles = (theme) =>
  StyleSheet.create({
    container: {
      backgroundColor: theme.secondary_btn_background,
      borderRadius: perfectSize(12),
      overflow: 'hidden',
    },
    image: {
      width: '100%',
      height: perfectSize(120),
    },
    title: {textTransform: 'capitalize'},
    wrapper: {
      padding: perfectSize(20),
    },
    divider: {
      height: 1,
      backgroundColor: 'white',
      opacity: 0.05,
      marginVertical: perfectSize(8),
    },
    flexRow: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    participantWrap: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    participantCount: {
      opacity: 0.56,
      marginLeft: perfectSize(4),
    },
    actionButton: {
      flexDirection: 'row',
      marginLeft: perfectSize(16),
      alignItems: 'center',
    },
    actionIcon: {
      width: perfectSize(10),
      height: perfectSize(10),
      resizeMode: 'contain',
    },
    actionTxt: {
      color: theme.primary_btn_background,
      marginLeft: perfectSize(4),
    },
  });
