import * as FirebasePaths from '../../constants/firebasePaths';
import { store } from '../../redux/store';
import firebase from 'firebase/app';
import 'firebase/database';

export default class HootsCore {
    static async loadGenres() {
        const ref =
            FirebasePaths.FIREBASE_HOOTS_GENRES;
        const snapshot = await firebase.database().ref(ref).once('value');
        return snapshot;
    }

    static loadLiveHootsSubscription() {
        const ref = FirebasePaths.FIREBASE_HOOTS;
        return firebase.database().ref(ref).orderByChild('status').equalTo('live');
    }

    static loadRecentHootsSubscription() {
        const ref = FirebasePaths.FIREBASE_HOOTS;
        return firebase.database().ref(ref).orderByChild('status').limitToLast(1000);
    }

    static getHootDetailRef(hootId) {
        const ref = FirebasePaths.FIREBASE_HOOTS + '/' + hootId;
        return firebase.database().ref(ref);
    }
}
