/* eslint-disable react-native/no-inline-styles */
import React, { Component } from 'react';
import { View, TouchableOpacity, SectionList } from 'react-native';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Feather from 'react-native-vector-icons/Feather';

// import components
import Label from '../../components/label';
import TextInput from '../../components/text-input';
import Header from '../../components/header';

import I18n from '../../i18n';
import * as Images from '../../constants/images';
import AppStyles from '../../constants/styles';
import styles from './styles';
import { perfectSize } from '../../helpers/deviceHelper';

import gameManager from '../../services/gameManager';
import notificationManagerCore from './notification-manager-core';

import { set as setHub } from '../../redux/modules/hub/actions';
import { presentToast } from '../../helpers/toastHelper';
import PrimaryButton from '../../components/buttons/primary-button';
import navigationService from '../../navigation/navigationService';
import LocalImageOpacity from '../../components/local-image-opacity';
import ImageWithPlaceholder from '../../components/image-with-placeholder';

class NotificationManagerPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: '',
      allGamesArray: [],
      searchedAllGamesArray: [],
      addedGamesArray: [],
      USER_ACTION: false,

      extra_flag: false,
    };
  }

  componentDidMount = () => {
    global.rankLogger.LOG_NEW_HP(
      global.rankLogger.VIEW_NOTIFICATIONS_PAGE,
      'VIEW_NOTIFICATIONS_PAGE',
    );
    global.featuresLogger.LOG_NOTIFICATIONS_PAGE_VIEW();
    this._initGamesList();
  };

  _initGamesList = () => {
    let _allGamesArray = Object.values(this.props.gamesList);
    let _addedGamesArray = [];
    let _otherGamesArray = [];
    _allGamesArray.forEach((game) => {
      if (gameManager.checkIfHasGameById(game.gameID)) {
        _addedGamesArray.push(game);
      } else {
        _otherGamesArray.push(game);
      }
    });

    this.setState({
      addedGamesArray: _addedGamesArray,
      searchedAllGamesArray: _otherGamesArray,
    });
    _otherGamesArray.sort(function (a, b) {
      const nameA = a.gameName.toUpperCase();
      const nameB = b.gameName.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
    this.setState({ allGamesArray: _otherGamesArray });
  };

  searchGameTrigger = (searchTerm) => {
    let _searchedAllGamesArray = [];
    if (searchTerm !== '') {
      if (this.state.allGamesArray.length > 0) {
        let _addedGamesArray = this.state.addedGamesArray.filter(function (
          item,
        ) {
          return (
            item.gameName
              .toString()
              .toLowerCase()
              .indexOf(searchTerm.toString().toLowerCase()) > -1
          );
        });
        this.setState({ addedGamesArray: _addedGamesArray });
        _searchedAllGamesArray = this.state.allGamesArray.filter(function (
          item,
        ) {
          return (
            item.gameName
              .toString()
              .toLowerCase()
              .indexOf(searchTerm.toString().toLowerCase()) > -1 ||
            (item.tags !== undefined && typeof item.tags !== 'object'
              ? item.tags
                .toString()
                .toLowerCase()
                .indexOf(searchTerm.toString().toLowerCase()) > -1
              : false)
          );
        });
        this.setState({ searchedAllGamesArray: _searchedAllGamesArray });
      }
    } else {
      // _searchedAllGamesArray = Array.from(this.props.gamesList.values());
      this._initGamesList();
    }
  };

  searchAddedGamesList = (val) => {
    let _allGamesArray = this.state.allGamesArray;
    if (val !== '') {
      if (_allGamesArray.length > 0) {
      }
    } else {
      let _addedGamesArray = this.state.addedGamesArray;
      _addedGamesArray.splice(0, _addedGamesArray.length);
      _addedGamesArray = Array.from(this.props.allGamesIds.values());
      this.setState({ addedGamesArray: _addedGamesArray });
    }
  };

  activateGAmeNotifications = (game) => {
    if (game !== null) {
      // Log Rank HP
      global.rankLogger.LOG_NEW_HP(
        global.rankLogger.ACTIVATE_NOTIFICATION,
        'ACTIVATE_NOTIFICATION',
      );

      // First add the game to the hub
      // Remove game from deactivated notifications
      let activiatedGamesNotificationArr = this.props
        .activiatedGamesNotificationArr;
      if (!activiatedGamesNotificationArr.includes(game.gameID)) {
        activiatedGamesNotificationArr.push(game.gameID);
      }

      this.props.setHub({
        activiatedGamesNotificationArr,
      });

      let deactiviatedGamesNotificationArr = this.props.deactiviatedGamesNotificationArr.filter(
        (item) => item !== game.gameID,
      );

      this.props.setHub({
        deactiviatedGamesNotificationArr,
      });

      notificationManagerCore.activateGameNotifications(game);
      global.featuresLogger.LOG_NOTIFICATIONS_ACTIVATE_NOTIFICATION();
      presentToast({
        message:
          I18n.t('NOTIFICATION_MANAGER_PAGE.toast_activate_success') +
          ' ' +
          game.gameName,
      });
      this.setState({ extra_flag: !this.state.extra_flag });
    }
  };

  deactivateGAmeNotifications = (game) => {
    if (game !== null) {
      // First add the game to the hub
      // Remove game from deactivated notifications
      let activiatedGamesNotificationArr = this.props.activiatedGamesNotificationArr.filter(
        (item) => item !== game.gameID,
      );

      this.props.setHub({
        activiatedGamesNotificationArr,
      });

      let deactiviatedGamesNotificationArr = this.props
        .deactiviatedGamesNotificationArr;
      if (!deactiviatedGamesNotificationArr.includes(game.gameID)) {
        deactiviatedGamesNotificationArr.push(game.gameID);
      }

      this.props.setHub({
        deactiviatedGamesNotificationArr,
      });

      notificationManagerCore.deactivateGameNotifications(game);
      global.featuresLogger.LOG_NOTIFICATIONS_DEACTIVATE_NOTIFICATION();
      presentToast({
        message:
          I18n.t('NOTIFICATION_MANAGER_PAGE.toast_deactivate_success') +
          ' ' +
          game.gameName,
      });
      this.setState({ extra_flag: !this.state.extra_flag });
    }
  };

  onChangeText = (val) => {
    this.setState({ searchTerm: val });
    this.searchGameTrigger(val);
  };

  _toContactUsPage = () => {
    navigationService.moveToScreenInStack('SettingsStack', 'ContactUsPage');
  };

  render() {
    const { theme } = this.props;
    const _styles = styles(theme);
    let sections = [];
    if (gameManager.getUserGamesNumber() > 0) {
      sections.push({
        title: I18n.t('GAME_LIST_PAGE.your_games'),
        data: this.state.addedGamesArray,
      });
    }
    sections.push({
      title: I18n.t('GAME_LIST_PAGE.other_games'),
      data: this.state.searchedAllGamesArray,
    });

    return (
      <View style={AppStyles.rootContainer}>
        <Header text={I18n.t('NOTIFICATION_MANAGER_PAGE.page_title')} />
        <View style={[AppStyles.rootContent]}>
          <Label size="x_small" style={AppStyles.grayLabel}>
            {I18n.t('NOTIFICATION_MANAGER_PAGE.page_subtitle')}
          </Label>
          <TextInput
            placeholder={I18n.t('GAME_LIST_PAGE.search')}
            rightComponent={
              <Feather name="search" size={perfectSize(20)} color="gray" />
            }
            customStyles={_styles.mt16}
            value={this.state.searchTerm}
            onChangeText={(val) => this.onChangeText(val)}
          />
          <View
            style={[
              _styles.mt16,
              {
                flex: 1,
              },
            ]}>
            {(this.state.addedGamesArray.length > 0 ||
              this.state.searchedAllGamesArray.length > 0) && (
                <SectionList
                  stickySectionHeadersEnabled={false}
                  sections={sections}
                  extraData={this.state.extra_flag}
                  showsVerticalScrollIndicator={false}
                  renderItem={({ item, index }) => (
                    <View style={_styles.itemContainer}>
                      <ImageWithPlaceholder
                        uri={item.gamePhotoUrl}
                        style={_styles.avatar}
                      />
                      <Label
                        size="medium"
                        style={{
                          marginLeft: perfectSize(8),
                          flex: 1,
                        }}>
                        {item.gameName}
                      </Label>
                      <TouchableOpacity
                        onPress={() => {
                          this.props.activiatedGamesNotificationArr.includes(
                            item.gameID,
                          )
                            ? this.deactivateGAmeNotifications(item)
                            : this.activateGAmeNotifications(item);
                        }}>
                        <LocalImageOpacity
                          source={
                            this.props.activiatedGamesNotificationArr.includes(
                              item.gameID,
                            )
                              ? Images.NOTIFICATION_ON
                              : Images.NOTIFICATION_OFF
                          }
                          style={_styles.bell}
                          resizeMode="contain"
                        />
                      </TouchableOpacity>
                    </View>
                  )}
                  renderSectionHeader={({ section }) => (
                    <Label size="x_small" bold={true} style={_styles.mv16}>
                      {section.title}
                    </Label>
                  )}
                  keyExtractor={(item, index) => index}
                  ListFooterComponent={() => {
                    return <View style={AppStyles.contentFotter} />;
                  }}
                />
              )}

            {this.state.addedGamesArray.length === 0 &&
              this.state.searchedAllGamesArray.length === 0 && (
                <View style={AppStyles.noGameWrap}>
                  {/* <FastImage
                    source={
                      theme.mode === 'dark'
                        ? Images.SUPPORT_LOADING_DARK
                        : Images.SUPPORT_LOADING
                    }
                    style={AppStyles.supportLoading}
                    resizeMode="contain"
                  /> */}
                  <Label style={AppStyles.txtNoGame}>
                    {I18n.t('GAME_LIST_PAGE.no_games')}
                  </Label>
                  <PrimaryButton
                    text={I18n.t('GAME_LIST_PAGE.go_support')}
                    type="filled"
                    size="x_small"
                    customStyles={_styles.btnAction}
                    onPress={this._toContactUsPage}
                  />
                </View>
              )}
          </View>
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
    gamesList: state.hubReducer.gamesList,
    allGamesIds: state.gameManagerReducer.allGamesIds,
    activiatedGamesNotificationArr:
      state.hubReducer.activiatedGamesNotificationArr,
    deactiviatedGamesNotificationArr:
      state.hubReducer.deactiviatedGamesNotificationArr,
  };
};

export default compose(connect(mapStateToProps, { setHub }))(
  NotificationManagerPage,
);
