import React, {memo, Component} from 'react';
import {StyleSheet, TextInput, View} from 'react-native';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import {FONT_FAMILY, FONT_SIZE} from '../../constants/theme';
import {perfectSize} from '../../helpers/deviceHelper';

class TextArea extends Component {
  render() {
    const {theme, placeholder, onChangeText, autoFocus} = this.props;
    const _styles = styles(theme);
    const fontFamily =
      this.props.lang === 'en' ? FONT_FAMILY.ubuntu : FONT_FAMILY.cairo;
    return (
      <View style={[_styles.container, this.props.customStyles]}>
        <TextInput
          style={[
            _styles.input,
            this.props.inputStyles,
            {fontFamily: fontFamily},
          ]}
          value={this.props.value}
          autoFocus={autoFocus}
          autoCapitalize={'none'}
          autoCompleteType={'off'}
          returnKeyType="done"
          placeholder={placeholder}
          underlineColorAndroid={'transparent'}
          placeholderTextColor={theme.primary_text}
          placeholderStyle={_styles.placeholderStyle}
          onChangeText={(text) => onChangeText && onChangeText(text)}
          multiline={true}
          blurOnSubmit={true}
        />
      </View>
    );
  }
}

const styles = (theme) =>
  StyleSheet.create({
    container: {
      height: 200,
      backgroundColor: theme.secondary_btn_background,
      padding: perfectSize(16),
      borderRadius: perfectSize(12),
    },
    input: {
      color: theme.primary_text,
      fontSize: FONT_SIZE.medium,
      flex: 1,
      opacity: 0.56,
      textAlignVertical: 'top',
    },
    placeholderStyle: {
      opacity: 0.56,
    },
  });

TextArea.propTypes = {
  placeholder: PropTypes.string,
  autoFocus: PropTypes.bool
};

TextArea.defaultProps = {
  autoFocus: false,
};

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
    lang: state.appReducer.currentLang,
  };
};

export default memo(connect(mapStateToProps)(TextArea));
