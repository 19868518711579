export default class TimeStamp {
  static getTimestampToTimeAgo(currentTimeStamp, time) {
    if (time < 1000000000000) {
      // if timestamp given in seconds, convert to millis
      time *= 1000;
    }

    if (time <= 0) {
      return null;
    }

    const SECOND_MILLIS = 1000;
    const MINUTE_MILLIS = 60 * SECOND_MILLIS;
    const HOUR_MILLIS = 60 * MINUTE_MILLIS;
    const DAY_MILLIS = 24 * HOUR_MILLIS;

    // TODO: localize
    const diff = currentTimeStamp - time;

    if (diff < 1000 * 60) {
      return 'just now';
    } else if (diff < 2 * MINUTE_MILLIS) {
      return 'a minute ago';
    } else if (diff < 50 * MINUTE_MILLIS) {
      return (diff / MINUTE_MILLIS).toFixed() + 'm';
    } else if (diff < 90 * MINUTE_MILLIS) {
      return 'an hour ago';
    } else if (diff < 24 * HOUR_MILLIS) {
      return (diff / HOUR_MILLIS).toFixed() + 'h';
    } else if (diff < 48 * HOUR_MILLIS) {
      return 'yesterday';
    }
    return (diff / DAY_MILLIS).toFixed() + 'd';
  }
}
