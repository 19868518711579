/* eslint-disable react-native/no-inline-styles */
import React, { Component } from 'react';
import { View, FlatList, TouchableOpacity } from 'react-native';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withNavigation, withNavigationFocus } from 'react-navigation';
import I18n from '../../i18n';
import { store } from '../../redux/store';
import * as FirebasePaths from '../../constants/firebasePaths';
import NavigationService from '../../navigation/navigationService';
import firebase from 'firebase/app';
import 'firebase/database';

// import components
import Header from '../../components/header';
import RequestCardSmall from '../../components/cards/request-card-small';

import AppStyles from '../../constants/styles';
import { perfectSize } from '../../helpers/deviceHelper';
import { useIsFocused } from '@react-navigation/native';
import { clearFastImageCache, dataForSkeleton } from '../../services/utils';
import { Placeholder, Progressive } from 'rn-placeholder';
import SkeletonUserAvatar from '../../components/skeletonWrapper/skeletonUserAvatar';
import SkeletonTextContent from '../../components/skeletonWrapper/skeletonTextContent';

class RequestshubPage extends Component {
  constructor(props) {
    super(props);
    this.mount = true;
    this.state = {
      lastReqsHubMore: [],
      isLoading: true,
    };
  }

  componentDidMount() {
    // Log feature: View request hub
    global.featuresLogger.LOG_REQUEST_VIEW_REQUEST_HUB();

    this.loadLastRequestsMore();
  }

  componentWillUnmount() {
    this.mount = false;
    if (this.lastReqsMore$) {
      this.lastReqsMore$.off();
      this.lastReqsMore$ = null;
    }
  }

  // This function will load last 51 recent requests from the hub
  loadLastRequestsMore() {
    this.lastReqsMore$ = firebase.database()
      .ref(FirebasePaths.FIREBASE_REQUESTS_HUB)
      .limitToLast(50);

    this.lastReqsMore$.on('value', (snapshot) => {
      let lastReqsHubMore = [];
      let blockedList = store.getState().hubReducer.blockedList;
      snapshot.forEach((item) => {
        if (
          !blockedList.has(item.val().admin) &&
          !this.props.bannedUsers.includes(item.val().admin)
        ) {
          lastReqsHubMore.push(item.val());
        }
      });
      lastReqsHubMore = lastReqsHubMore.reverse();
      if (this.mount) {
        this.setState({ isLoading: false, lastReqsHubMore });
      }

    });
  }

  // This function will load the last req from search to lobby
  toLobby = (req) => {
    NavigationService.moveToScreenInStack('HomeStack', 'LobbyPage', {
      lastReq: req,
    });
  };

  renderCard = ({ item, index }) => {
    return (
      <TouchableOpacity
        style={[
          { width: '50%', marginBottom: perfectSize(16) },
          index % 2 === 0
            ? { paddingRight: perfectSize(8) }
            : { paddingLeft: perfectSize(8) },
        ]}>
        <RequestCardSmall
          request={item}
          onPress={() => {
            this.toLobby(item);
          }}
        />
      </TouchableOpacity>
    );
  };

  customBack = () => {
    clearFastImageCache();
    NavigationService.goBack();
  }

  render() {
    const { lastReqsHubMore, isLoading } = this.state;
    const isFocused = this.props.navigation.isFocused()
    const { theme } = this.props;
    if (isFocused) {
      return (
        <View style={AppStyles.rootContainer}>
          <Header text={I18n.t('SEARCH_REQUEST_PAGE.request_hub')} onBack={this.customBack} />
          <View style={AppStyles.rootContent}>
            {
              isLoading &&
              <FlatList
                showsVerticalScrollIndicator={false}
                data={dataForSkeleton(12)}
                renderItem={({ item, index }) => {
                  return <View style={[
                    { width: '50%', marginBottom: perfectSize(16), borderRadius: 8 },
                    index % 2 === 0
                      ? { paddingRight: perfectSize(8) }
                      : { paddingLeft: perfectSize(8) },
                  ]}>
                    <Placeholder
                      Animation={Progressive}
                    >
                      <View style={{ flexDirection: 'row', paddingLeft: perfectSize(14) }}>
                        <SkeletonUserAvatar size={32} />
                        <View style={{ marginLeft: perfectSize(10), flex: 1 }}>
                          <SkeletonTextContent height={20} width={100} />
                          <SkeletonTextContent height={20} width={100} style={{ marginTop: 5 }} />
                        </View>
                      </View>
                      <View style={{ marginLeft: perfectSize(10), marginTop: perfectSize(5) }}>
                        <SkeletonTextContent height={20} />
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                          <SkeletonTextContent height={20} width={45} style={{ marginTop: 10 }} />
                          <SkeletonTextContent height={20} width={45} style={{ marginTop: 10 }} />
                        </View>
                        <SkeletonTextContent height={20} style={{ marginTop: 10 }} />
                      </View>

                    </Placeholder>
                  </View>
                }}
                numColumns={2}
                keyExtractor={(item) => item.toString()}
              />
            }
            <FlatList
              data={lastReqsHubMore}
              renderItem={(item) => this.renderCard(item)}
              numColumns={2}
              keyExtractor={(item) => item.timeStamp}
              showsVerticalScrollIndicator={false}
            />
          </View>
        </View>
      );
    }
    return <View />;
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
    bannedUsers: state.hubReducer.bannedUsers,
  };
};

function focusComponent(props) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const isFocused = useIsFocused();
  return <RequestshubPage {...props} isFocused={isFocused} />;
}

export default compose(connect(mapStateToProps, {}))(RequestshubPage);
