/* eslint-disable react-native/no-inline-styles */
import React, {useEffect, useState} from 'react';
import {View, TouchableOpacity, FlatList, Platform, PermissionsAndroid, ScrollView, StyleSheet} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
const io = require('socket.io-client/dist/socket.io');
import _ from 'lodash';
import {createClient, createMicrophoneAudioTrack} from "agora-rtc-react";
import {Audio} from 'expo-av';
import firebase from 'firebase/app';
import 'firebase/database';

// import components
import Header from '../../../components/header';
import Label from '../../../components/label';
import UserAvatar from '../../../components/user-avatar';
import ImageWithPlaceholder from '../../../components/image-with-placeholder';
import I18n from '../../../i18n';

// import styles
import AppStyles from '../../../constants/styles';
import styles from './styles';
import {HOOTS_COVER, HOOTS_MIC_OFF, HOOTS_CALL_END, HOOTS_DEAFEN, HOOTS_DEAFEN_OFF, HOOTS_SPEAKING_OVERLAY} from '../../../constants/images';

// import actions
import {set as setHub} from '../../../redux/modules/hub/actions';
import {
    showAlert,
    showInputAlert,
    showSpinner,
    hideSpinner,
} from '../../../redux/modules/alert/actions';

import {perfectSize} from '../../../helpers/deviceHelper';
import {presentToast} from '../../../helpers/toastHelper';

import * as FirebasePaths from '../../../constants/firebasePaths';

import NavigationService from '../../../navigation/navigationService';
import LocalImageOpacity from '../../../components/local-image-opacity';
import dimens from '../../../constants/dimens';
import ImageWithPlaceholderWithoutFocus from '../../../components/image-with-placeholder/without-focus';
import HootDetailReporter from './reporter';
import TextArea from '../../../components/text-area';
import HootsCore from '../hootsCore';
import FriendsListCore from '../../friendslist/friendslistcore';
import HootReport from '../../../models/hootReport';
import {AGORA_APP_ID} from '@env';
import {AgoraSpeakingMinVolume, AgoraTokenUrl, WebSocketURL} from '../../../constants/constants';
import {AES_SECRET} from '@env';

const useClient = createClient();
const useMicrophoneTrack = createMicrophoneAudioTrack();
let hootRef$ = null;
const HootDetail = ({route, navigation}) => {
    const theme = useSelector((state) => state.appReducer.currentTheme);
    const blockedList = useSelector((state) => state.hubReducer.blockedList);
    const uid = useSelector((state) => state.authReducer.uid);
    const authUserInfo = useSelector(state => state.authReducer.userinformationDB);
    const friendsList = useSelector(state => state.hubReducer.friendsList);

    const dispatch = useDispatch();

    const client = useClient();
    const {ready, track} = useMicrophoneTrack();

    const [currentHoot, setCurrentHoot] = useState([]);
    const [activeMembers, setActiveMembers] = useState([]);
    const [historyMembers, setHistoryMembers] = useState([]);
    const [changed, setChanged] = useState(false);
    const [isDeafen, setIsDeafen] = useState(false);
    const [isMute, setIsMute] = useState(false);
    const [remoteUid, setRemoteUid] = useState([]);
    const [remoteUser, setRemoteUser] = useState([]);
    const [isRenderTextureView, setIsRenderTextureView] = useState(false);
    const [speakingUid, setSpeakingUid] = useState(null);
    const [itemUserName, setUserName ] = useState('');

    const _styles = styles(theme);

    const generateToken = async (channelName, userId) => {
        try {
            const url = AgoraTokenUrl + 'rtc/' + channelName + '/publisher/userAccount/' + userId;
            const response = await fetch(url, {
                method: 'GET'
            });
            const resObj = await response.json();
            const {rtcToken} = resObj;
            return rtcToken;
        } catch (e) {
            console.log('error happened: ', e)
            return null;
        }
    }

    useEffect(() => {
        const {state: {params}} = navigation;
        if (params) {
            const {hoot} = params;
            getHootDetails(hoot.id);
            // Log hoot call view
            global.featuresLogger.HOOT_CALL_VIEW();
            _initWebsocket(hoot.id);
        }

        return async () => {
            if (hootRef$) {
                hootRef$.off();
                hootRef$ = null;
            }
            window.appSocket.disconnect();
            // remove user from the live participatns 
            await removeUserFromLive();
            await changeStatusToDisconnected();
            _leaveChannel();
        }
    }, []) // component did mount

    useEffect(() => {
        // function to initialise the SDK
        let init = async (name) => {
            client.on("user-published", async (user, mediaType) => {
                await client.subscribe(user, mediaType);
                if (mediaType === "audio") {
                    user.audioTrack?.play();
                }
            });

            client.on("user-unpublished", (user, type) => {
                if (type === "audio") {
                    user.audioTrack?.stop();
                }
            });

            client.on("user-left", async (user) => {
                const {sound} = await Audio.Sound.createAsync(
                    require('../../../../assets/sound/HOOT_OUT.mp3')
                );
                setTimeout(async () => {
                    await sound.playAsync();
                    sound.unloadAsync();
                }, 1000);
            });

            client.on("user-joined", async (user) => {
                const {sound} = await Audio.Sound.createAsync(
                    require('../../../../assets/sound/HOOT_IN.mp3')
                );
                setTimeout(async () => {
                    await sound.playAsync();
                    sound.unloadAsync();
                }, 1000);
            });

            client.enableAudioVolumeIndicator();
            client.on('volume-indicator', (volumes) => {
                let speakerUid = null;
                volumes.map(volume => {
                    if (volume.level > AgoraSpeakingMinVolume) {
                        speakerUid = volume.uid;
                    }
                })
                setSpeakingUid(speakerUid)
            })

            const token = await generateToken(name, uid);
            if (token) {
                await client.join(AGORA_APP_ID, name, token, uid);
                if (track) {
                    await client.publish([track]);
                }
            }
        };

        if (ready && track && currentHoot && currentHoot.id) {
            init(currentHoot.id);
        }
    }, [client, ready, track]);

    const sortHistoryParticipants = (admin, originHistoryParticipants) => {
        const historyParticipants = originHistoryParticipants.sort(function (a, b) {
            const aUid = a.uid;
            const bUid = b.uid;
            if (aUid === admin) return -1;
            if (bUid === admin) return 1;
            else return 0;
        })
        return historyParticipants;
    }

    const getHootDetails = (hootId) => {
        hootRef$ = HootsCore.getHootDetailRef(hootId);
        hootRef$.on('value', snapshot => {
            const val = snapshot.val();
            if (val) {
                const {liveParticipants, historyParticipants, admin} = val;
                const activeMembers = liveParticipants ? Object.values(liveParticipants) : [];
                const originHistoryMembers = historyParticipants ? Object.values(historyParticipants) : [];
                const historyMembers = sortHistoryParticipants(admin, originHistoryMembers);
                if (!liveParticipants || (liveParticipants && !liveParticipants[uid])) {
                    if (historyParticipants && historyParticipants[uid]['isBanned']) {
                        // banned
                        presentToast({
                            message: I18n.t('HOOTS_PAGE.hoots_banned')
                        })
                    } else {
                        // kicked
                        presentToast({
                            message: I18n.t('HOOTS_PAGE.hoots_kicked')
                        })
                    }
                    NavigationService.pop();
                }
                setCurrentHoot(val);
                setActiveMembers(activeMembers);
                setHistoryMembers(historyMembers);
                setChanged(!changed);
            }
        })
    }

    const _initWebsocket = (hootId) => {
        // websocket
        window.appSocket = io.connect(`${WebSocketURL}?userId=${uid}&hootId=${hootId}`, {
            secure: true,
        });
        window.appSocket.on('connect', () => {
            // do what you line
            console.log('connected: ')
        })
    }

    const isUserActive = (userId) => {
        const existMembers = activeMembers.filter(aMember => aMember.uid === userId).length;
        return existMembers > 0;
    }

    const _renderItem = ({item, index}) => {
        const {admin} = currentHoot;
        const isAdmin = admin === item.uid;
        const isSpeaking = speakingUid === item.uid;
        const isMemberActive = isUserActive(item.uid);
        const opacity = isMemberActive ? 1 : 0.3;
        return (
            <View style={{opacity: opacity, width: '33%'}}>
                <TouchableOpacity
                    style={[_styles.userWrap]}
                    onPress={() => {
                        if (uid !== item.uid) {
                            setUserName(item.username);
                            onUserClick(item.username, item.uid, item.img, item.rank)
                        }
                    }}>
                    {isAdmin ?
                        < ImageWithPlaceholder
                            uri={'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/GeneralIcons%2Fcrown.png?alt=media&token=e1bf7662-a6de-424d-bd9b-2c1f40d47be0'}
                            style={[_styles.crown]}
                            resizeMode="contain"
                        /> : <View style={_styles.crown} />
                    }
                    <UserAvatar
                        size={perfectSize(54)}
                        uid={item.uid}
                        src={item.img}
                    />
                    {
                        isSpeaking &&
                        <View style={{position: 'absolute'}}>
                            <LocalImageOpacity
                                source={HOOTS_SPEAKING_OVERLAY}
                                style={_styles.hootsSpeakingOverlay}
                                resizeMode="contain"
                            />
                        </View>
                    }

                    {
                        isSpeaking &&
                        <View style={{position: 'absolute'}}>
                            <LocalImageOpacity
                                source={HOOTS_COVER}
                                style={_styles.hootsSpeaking}
                                tintColor={'white'}
                                resizeMode="contain"
                            />
                        </View>
                    }

                    <View style={{flexDirection: 'row', alignItems: 'center', marginTop: perfectSize(4)}}>
                        <Label size="small">{item.username}</Label>
                        <ImageWithPlaceholder
                            uri={global.rankLogger.getRankImg(item.rank)}
                            style={[global.rankLogger.getRankStyleForHootDetail(
                                item.rank,
                            ), {marginLeft: perfectSize(4)}]}
                            resizeMode="contain"
                        />
                    </View>
                </TouchableOpacity>
            </View>
        );
    };

    const addToBlockList = (uid, username) => {
        const blockListPath =
            FirebasePaths.FIREBASE_USERS_INFO_ATTR +
            '/' +
            uid +
            '/' +
            FirebasePaths.FIREBASE_BLOCKED_USERS +
            '/';
        firebase.database()
            .ref(blockListPath + '/' + uid)
            .set(username);
    };

    const onUserClick = (friendUsername, friendId, avatar, rank) => {
        // Log clicking participant options
        global.featuresLogger.HOOT_CALL_PARTICIPANT_OPTIONS();

        let buttons = [];
        const {admin} = currentHoot;
        if (uid !== friendId) {
            buttons.push({
                text: I18n.t('HOOTS_PAGE.user_action_view_profile'),
                onPress: () => {
                    // Log participant view profile
                    global.featuresLogger.HOOT_CALL_PARTICIPANT_OPTIONS_VIEW_PROFILE();
                    NavigationService.navigate('ViewfriendprofilePage', {
                        FRIEND_KEY: friendId,
                    });
                },
            });
        }

        if (friendsList === undefined ||
            (friendsList !== undefined &&
                !friendsList.filter(
                    (e) => e.UID === friendId,
                ).length > 0) && (uid !== friendId)) {
            buttons.push({
                text: I18n.t('HOOTS_PAGE.user_action_add_friend'),
                onPress: () => {
                    // Log participant add friend
                    global.featuresLogger.HOOT_CALL_PARTICIPANT_OPTIONS_ADD_FRIEND();
                    FriendsListCore.addFriend(friendId);
                    global.rankLogger.LOG_NEW_HP(
                        global.rankLogger.DELETE_FRIEND,
                        'DELETE_FRIEND',
                    );
                    presentToast({
                        message: I18n.t('FRIEND_LIST_PAGE.success_friend_add'),
                    });
                },
            });
        }


        if (!blockedList.has(friendId) && (uid !== friendId)) {
            buttons.push({
                text: I18n.t('HOOTS_PAGE.user_action_block_user'),
                onPress: () => {
                    if (friendId === uid) {
                        presentToast({
                            message: I18n.t('TIMELINE_PAGE.cant_block_msg'),
                        });
                    } else {
                        // Log participant block user
                        global.featuresLogger.HOOT_CALL_PARTICIPANT_OPTIONS_BLOCK_USER();
                        addToBlockList(friendId, friendUsername);
                        let _blockedList = _.clone(blockedList);
                        if (_blockedList === undefined) {
                            _blockedList = new Map();
                        }
                        _blockedList.set(friendId, friendUsername);
                        dispatch(setHub({
                            blockedList: _blockedList,
                        }));
                        presentToast({
                            message: I18n.t('TIMELINE_PAGE.block_alert_success_msg'),
                        });
                    }
                },
            });
        }
        if (uid !== friendId) {
            buttons.push({
                text: I18n.t('HOOTS_PAGE.user_action_report_user'),
                onPress: () => {
                    dispatch(hideSpinner());
                    setTimeout(() => {
                        // Log participant report user
                        global.featuresLogger.HOOT_CALL_PARTICIPANT_OPTIONS_REPORT_USER();
                        showReport(friendUsername, friendId);
                    }, 100);
                },
            });
        }

        if (uid === admin) {
            buttons.push({
                text: I18n.t('HOOTS_PAGE.user_action_kick'),
                onPress: () => {
                    // Log participant kick user
                    global.featuresLogger.HOOT_CALL_PARTICIPANT_OPTIONS_KICK();
                    removeUserFromLive(friendId);
                    changeStatusToDisconnected(friendId);
                },
            });

            buttons.push({
                text: I18n.t('HOOTS_PAGE.user_action_call_ban'),
                onPress: () => {
                    dispatch(hideSpinner());
                    setTimeout(() => {
                        // Log participant ban
                        global.featuresLogger.HOOT_CALL_PARTICIPANT_OPTIONS_BAN();
                        showBan(friendUsername, friendId, avatar, rank);
                    }, 100);
                },
            });
        }

        dispatch(showAlert([
            friendUsername,
            '',
            buttons,
            false,
            {
                height: perfectSize(32),
                width: perfectSize(120),
                alignItems: 'center'
            },
        ]));
    };

    const showBan = (userName, userId, avatar, rank) => {
        let buttons = [];
        buttons.push({
            text: I18n.t('HOOTS_PAGE.user_action_ban_no'),
            onPress: () => {
            },
        });
        buttons.push({
            text: I18n.t('HOOTS_PAGE.user_action_ban_yes'),
            onPress: async () => {
                // Log participant ban confirm
                global.featuresLogger.HOOT_CALL_PARTICIPANT_OPTIONS_BAN_CONFIRM();
                // remove user from live reference, 
                await addHootIdToUserBans(userId);
                await removeUserFromLive(userId);
                await changeStatusToDisconnected(userId);
            },
        });

        dispatch(showAlert([
            // 'Ban Call',
            I18n.t('HOOTS_PAGE.user_action_call_ban'),
            I18n.t('HOOTS_PAGE.user_action_ban_msg'),
            buttons,
            buttons.length > 2 ? false : true, // isRow
            {},
            <View style={{
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: perfectSize(32)
            }}>
                <UserAvatar withFocus={false} uid={userId} src={avatar} size={perfectSize(54)} />
                <View style={{flexDirection: 'row', alignItems: 'center', marginTop: perfectSize(4)}}>
                    <Label size="small">{userName}</Label>
                    <ImageWithPlaceholderWithoutFocus
                        uri={global.rankLogger.getRankImg(rank)}
                        style={[global.rankLogger.getRankStyleForHootDetail(
                            rank,
                        ), {marginLeft: perfectSize(4)}]}
                    />
                </View>
            </View>
        ]));
    }

    const showReport = (userName, userId) => {
        dispatch(setHub({
            showHootDetailReport: true
        }))
    };

    const confirmDisconnect = () => {
        dispatch(showInputAlert([
            I18n.t('HOOTS_PAGE.hoots_disconnect_title'),
            <View style={_styles.dialogSectionContainer}>
                <Label style={_styles.dialogDescription}>
                    {I18n.t('HOOTS_PAGE.hoots_disconnect')}
                </Label>
            </View>,
            [
                {
                    text: I18n.t(
                        'HOOTS_PAGE.user_action_ban_no',
                    ),
                    onPress: () => {},
                },
                {
                    text: I18n.t('HOOTS_PAGE.user_action_ban_yes'),
                    onPress: async () => {
                        // Log hoot disconnect
                        global.featuresLogger.HOOT_CALL_DISCONNECT();
                        if (hootRef$) {
                            hootRef$.off();
                            hootRef$ = null;
                        }
                        window.appSocket.disconnect();
                        // remove user from the live participatns 
                        await removeUserFromLive();
                        await changeStatusToDisconnected();
                        presentToast({
                            message: I18n.t(
                                'HOOTS_PAGE.hoots_left',
                            )
                        })
                        _leaveChannel();
                        // exit the page
                        NavigationService.goBack();
                    },
                },
            ],
            true,
        ]));
    };

    const _leaveChannel = async () => {
        await client.leave();
        client.removeAllListeners();
        track?.close();
    }

    const onMute = async () => {
        const changed = !isMute;
        await track.setMuted(changed);
        setIsMute(changed);
        // Log mute hoot
        global.featuresLogger.HOOT_CALL_MUTE();
    }

    const onDeafen = () => {
        // Log deafen hoot
        if (isDeafen) {
            setIsDeafen(false);
            // this._engine?.setEnableSpeakerphone(false);
            global.featuresLogger.HOOT_CALL_DEAFEN();
        } else {
            setIsDeafen(true);
            // this._engine?.setEnableSpeakerphone(true);
            global.featuresLogger.HOOT_CALL_SPEAKER();
        }
    }

    const removeUserFromLive = async (userId) => {
        const {id, liveParticipants: currentLiveParticipants} = currentHoot;
        const userToRemove = userId ?? uid;
        const hootPath =
            FirebasePaths.FIREBASE_HOOTS +
            '/' +
            id;
        if (currentLiveParticipants && !!currentLiveParticipants[userToRemove]) {
            await firebase.database().ref(hootPath).child('liveParticipants').child(userToRemove).remove();
        }
    }

    const changeStatusToDisconnected = async (userId) => {
        const userToRemove = userId ?? uid;
        const path =
            FirebasePaths.FIREBASE_USERS_INFO_ATTR +
            '/' +
            userToRemove +
            '/' +
            FirebasePaths.FIREBASE_HOOTS +
            '/' +
            currentHoot.id;
        const hoot = {...currentHoot};
        hoot.status = 'disconnected';

        // Upload to the user hoots ref path
        await firebase.database().ref(path).set(hoot);
    }

    const addHootIdToUserBans = async (userId) => {
        const {id, historyParticipants: currentHistoryParticipants} = currentHoot;
        const userToRemove = userId;
        const hootPath =
            FirebasePaths.FIREBASE_USERS_INFO_ATTR +
            '/' +
            userToRemove +
            '/' +
            FirebasePaths.FIREBASE_HOOTS_BANS;
        await firebase.database().ref(hootPath).child(id).set(id);
        const historyPath = FirebasePaths.FIREBASE_HOOTS +
            '/' +
            id;
        if (currentHistoryParticipants && !!currentHistoryParticipants[userToRemove]) {
            await firebase.database().ref(historyPath).child('historyParticipants').child(userToRemove).child('isBanned').set(true);
        }
    }

    if (!currentHoot) return <View style={AppStyles.rootContainer}>
        <Header
            onBack={() => {}}
            centerComponent={
                <View style={_styles.titleWrap}>
                    <Label size="medium" bold style={_styles.title}>
                    </Label>
                </View>
            }
        />
        <View style={{
            borderTopLeftRadius: dimens.BODY_BORDER_TOP_RADIUS,
            borderTopEndRadius: dimens.BODY_BORDER_TOP_RADIUS,
            overflow: 'hidden',
            flex: 1,
        }}>
            <View style={[_styles.contentWrap, !!hootCover ? {position: 'absolute', } : {backgroundColor: theme.primary_background}]}>
                <View style={_styles.space} />

            </View>
            <View style={_styles.footerWrap}>
                <View style={_styles.footer}>
                    <TouchableOpacity style={[_styles.actionWrap, _styles.actionNormal]}>
                        <LocalImageOpacity
                            source={HOOTS_MIC_OFF}
                            style={_styles.actionMicOff}
                        />
                    </TouchableOpacity>
                    <TouchableOpacity style={[_styles.actionWrap, _styles.actionFill]}>
                        <LocalImageOpacity
                            source={HOOTS_CALL_END}
                            style={_styles.actionCallEnd}
                        />
                    </TouchableOpacity>

                </View>
            </View>
        </View>
    </View>
    const {cover: hootCover, title: hootTitle} = currentHoot;
    return (
        <View style={AppStyles.rootContainer}>
            <Header
                onBack={() => {
                    confirmDisconnect();
                }}
                centerComponent={
                    <View style={_styles.titleWrap}>
                        <Label size="medium" bold style={_styles.title}>
                            {/* {I18n.t('HOOTS_PAGE.hoot_detail_page_header_title')} */}
                            {hootTitle}
                        </Label>
                        <LocalImageOpacity
                            source={HOOTS_COVER}
                            style={_styles.hootsCover}
                        />
                    </View>
                }
            />
            <View style={{
                borderTopLeftRadius: dimens.BODY_BORDER_TOP_RADIUS,
                borderTopEndRadius: dimens.BODY_BORDER_TOP_RADIUS,
                overflow: 'hidden',
                flex: 1,
            }}>
                {
                    !!hootCover &&
                    <View style={_styles.overlayWrap}>
                        <ImageWithPlaceholder
                            uri={hootCover}
                            useOpacity
                            toOpacity={0.1}
                            style={_styles.fullSize}
                        />
                    </View>
                }
                <View style={[_styles.contentWrap, !!hootCover ? {position: 'absolute', } : {backgroundColor: theme.primary_background}]}>
                    <View style={_styles.space} />
                    {
                        <FlatList
                            data={historyMembers}
                            extraData={changed}
                            renderItem={_renderItem}
                            keyExtractor={(item, index) => index.toString()}
                            numColumns={3}
                            // columnWrapperStyle={{ justifyContent: 'space-around' }}
                            contentContainerStyle={{marginBottom: perfectSize(52)}}
                            style={{marginBottom: perfectSize(92), height: '100%'}}
                        />
                    }

                    {/* {this._renderVideo()} */}
                    {/* Submit button */}
                </View>
                <HootDetailReporter userName={itemUserName} currentHoot={currentHoot} />
                <View style={_styles.footerWrap}>
                    <View style={_styles.footer}>
                        <TouchableOpacity style={[_styles.actionWrap, isMute ? _styles.actionClicked : _styles.actionNormal]} onPress={() => {
                            onMute();
                        }}>
                            <LocalImageOpacity
                                source={HOOTS_MIC_OFF}
                                style={_styles.actionMicOff}
                                tintColor={isMute ? 'black' : 'white'}
                            />
                        </TouchableOpacity>
                        <TouchableOpacity style={[_styles.actionWrap, _styles.actionFill]} onPress={() => {
                            confirmDisconnect();
                        }}>
                            <LocalImageOpacity
                                source={HOOTS_CALL_END}
                                style={_styles.actionCallEnd}
                            />
                        </TouchableOpacity>

                    </View>
                </View>
            </View>
        </View>
    );
}

export default HootDetail;

const stylesAgora = StyleSheet.create({
    container: {
        flex: 1,
    },
    float: {
        position: 'absolute',
        right: 0,
        bottom: 0,
    },
    top: {
        width: '100%',
    },
    input: {
        borderColor: 'gray',
        borderWidth: 1,
        color: 'black',
    },
    local: {
        flex: 1,
    },
    remoteContainer: {
        position: 'absolute',
        left: 0,
        top: 0,
    },
    remote: {
        width: 120,
        height: 120,
    },
});