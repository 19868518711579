/* eslint-disable react-native/no-inline-styles */
import React, { Component } from 'react';
import { View, TouchableOpacity, FlatList } from 'react-native';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Feather from 'react-native-vector-icons/Feather';
import Octicons from 'react-native-vector-icons/Octicons';
import { SwipeRow } from 'react-native-swipe-list-view';
import { withNavigation } from 'react-navigation';
import { useIsFocused } from '@react-navigation/native';

import I18n from '../../i18n';
import NavigationService from '../../navigation/navigationService';

// import components
import Label from '../../components/label';
import TextInput from '../../components/text-input';
import ChatItemHolder from '../../components/chat-item-holder';
import ChatItemHolderSkeleton from '../../components/chat-item-holder/chat-item-skeleton';
// import styles
import AppStyles from '../../constants/styles';
import styles from './styles';
import Pipes from '../../services/pipes';
import Mainmenucore from '../mainmenu/mainmenucore';
import Newrequestcore from '../newrequest/newrequestcore';
import CommunityCore from './communitycore';
import Gamemanager from '../../services/gameManager';
import Gamelistcore from '../games-list/gamelistcore';
import { getGameByNameFromGameList } from '../../services/utils';

// import actions
import { switchTheme } from '../../redux/modules/app/actions';
import { set as setHub } from '../../redux/modules/hub/actions';
import { set as setGamemanager } from '../../redux/modules/gameManager/actions';
import { showAlert, showInputAlert } from '../../redux/modules/alert/actions';

import { store } from '../../redux/store';
import { presentToast } from '../../helpers/toastHelper';
import { perfectSize } from '../../helpers/deviceHelper';

import {
  CONTROLLER_LOADING_DARK,
  CONTROLLER_LOADING,
} from '../../constants/images';


class RequestsChat extends Component {
  constructor(props) {
    super(props);
    this.swipRef = new Array(React.createRef());
    this.state = {
      chatRef: null,
      description: '',
      isLoading: true,
    };
  }

  componentDidMount() {
    const { navigation } = this.props;
    this.preloadImgs();
    this.focusListener = navigation.addListener('didFocus', () => {
      Mainmenucore.loadPublicChatsRefs();
    })
  }

  componentWillUnmount() {
    if (this.focusListener) {
      this.focusListener();
    }
    if (Mainmenucore.publicChatsRef$) {
      Mainmenucore.publicChatsRef$.off();
      Mainmenucore.publicChatsRef$ = null;
    }
  }

  componentDidUpdate(prevProps) {
    if (!this.props.navigation.isFocused()) {
      this.swipRef.forEach((swipe, _index) => {
        if (swipe && swipe.closeRow) {
          swipe.closeRow();
        }
      });
    }
  }

  preloadImgs = () => {
    const preloadUris = [];
    const preloadChats = this.props.communityGroupChatsArr.slice(0, 10);
    preloadChats.map(chat => {
      if (getGameByNameFromGameList(
        chat.opponentUsername,
      ).gamePhotoUrl) {
        return preloadUris.push({
          uri: getGameByNameFromGameList(
            chat.opponentUsername,
          ).gamePhotoUrl
        });
      }
    })
    setTimeout(() => {
      if (this.props.isFocused) {
        this.setState({
          isLoading: false,
        })
      }
    }, 1000);
  }

  // This function will open a chat when user click on chat holder : EVENT | TRIGGER
  toChatPage = (
    friendUID,
    friendUsername,
    friendBio,
    friendPhotoUrl,
    chatRef,
    CHAT_TYPE,
  ) => {
    // Disable chat local notifications when open chat
    this.props.setHub({
      IS_IN_CHAT: true,
      tempChatRoomRef: chatRef,
    });

    // Here the chat status is  always ongoing
    let CHAT_STATUS = 'ONGOING';

    // TO PREVENT THE CONNECTION FUNCTION OF CHANGING THE CHAT STATUS WAIT FOR 3S
    // setTimeout(() => {
    // Change the chat opened attr to true in front-end and back-end
    // In map and in arr
    // In map
    // let allGroupChatRefs = store.getState().hubReducer.allGroupChatRefs;
    // let tmpChatRef = allGroupChatRefs.get(chatRef);
    // tmpChatRef.opened = true;
    // allGroupChatRefs.set(chatRef, tmpChatRef);
    // // In Arr
    // let communityGroupChatsArr = store.getState().hubReducer
    //   .communityGroupChatsArr;
    // communityGroupChatsArr.forEach((chat) => {
    //   if (chat.chatRef === chatRef) {
    //     chat.opened = true;
    //   }
    // });

    // this.props.setHub({
    //   allGroupChatRefs,
    //   communityGroupChatsArr,
    // });
    // In back-end
    CommunityCore.changeOpenedAttr(chatRef, 'PUBLIC');
    // }, 3000);

    // Add the game if the user deleted the game and open a group chat with that game
    if (
      !Gamemanager.checkIfHasGameById(
        store.getState().hubReducer.allGroupChatRefs.get(chatRef).gameId,
      )
    ) {
      let gameModel = store.getState().hubReducer.gamesList[
        store.getState().hubReducer.allGroupChatRefs.get(chatRef).gameId
      ];
      // Fill the PC games Providers
      if (gameModel.gamePlatforms.includes('PC')) {
        let pcGamesWithProviders = store.getState().gameManagerReducer
          .pcGamesWithProviders;
        pcGamesWithProviders.set(gameModel.gameID, gameModel.pcGameProvider);
        this.props.setGamemanager({
          pcGamesWithProviders,
        });
      }

      // Add it to the database
      Gamelistcore.addGame(gameModel);

      // Add it to the local db
      Gamemanager.addGame(gameModel);
    }

    let createdReq = Object.assign({}, store.getState().hubReducer.createdReq);

    createdReq.gameId = store
      .getState()
      .hubReducer.allGroupChatRefs.get(chatRef).gameId;
    createdReq.requestTitle = friendUsername;
    createdReq.platform = store
      .getState()
      .hubReducer.allGroupChatRefs.get(chatRef).platform;
    createdReq.region = store
      .getState()
      .hubReducer.allGroupChatRefs.get(chatRef).region;
    this.props.setHub({ createdReq });

    if (CHAT_TYPE === 'PRIVATE') {
      NavigationService.moveToScreenInStack('HomeStack', 'PrivateChatPage', {
        TITLE: friendUsername,
        SUBTITLE: friendBio,
        CHAT_IMG: friendPhotoUrl,
        CHAT_TYPE: CHAT_TYPE,
        FRIEND_UID: friendUID,
        CHAT_STATUS: CHAT_STATUS,
      });
    } else {
      NavigationService.moveToScreenInStack('HomeStack', 'ChatPage', {
        TITLE: friendUsername,
        SUBTITLE: friendBio,
        CHAT_IMG: friendPhotoUrl,
        CHAT_TYPE: CHAT_TYPE,
        FRIEND_UID: friendUID,
        CHAT_STATUS: CHAT_STATUS,
      });
    }
  };

  // Present prompt to change group name
  presentPrompt = (chatRef) => {
    let titleTxt = I18n.t('COMMUNITY_PAGE.new_group_name');
    let quickSettings = I18n.t('COMMUNITY_PAGE.quick_settings');
    let hide = I18n.t('COMMUNITY_PAGE.hide');

    this.props.showAlert([
      quickSettings,
      '',
      [
        {
          text: titleTxt,
          onPress: () => {
            this.setState({ chatRef });
            this.props.showInputAlert([
              I18n.t('COMMUNITY_PAGE.new_group_name'),
              <TextInput
                placeholder={I18n.t('COMMUNITY_PAGE.group_name')}
                onChangeText={(text) => this.onChangeDescription(text)}
                customStyles={{
                  marginTop: perfectSize(32),
                  borderBottomColor: 'white',
                  borderBottomWidth: 1,
                  paddingHorizontal: perfectSize(0),
                  borderRadius: 0,
                }}
                inputStyles={{ paddingLeft: perfectSize(4) }}
                placeholderTextColor={'gray'}
              />,
              [
                {
                  text: I18n.t('COMMUNITY_PAGE.cancel'),
                  onPress: () => { },
                },
                {
                  text: I18n.t('COMMUNITY_PAGE.apply'),
                  onPress: () => {
                    this.handleUpdate();
                  },
                },
              ],
            ]);
          },
        },
        {
          text: hide,
          onPress: () => { },
        },
      ],
    ]);
  };

  onChangeDescription = (description) => {
    this.setState({ description });
  };

  handleUpdate = () => {
    const name = this.state.description;
    if (name.length < 3) {
      presentToast({
        message: I18n.t('COMMUNITY_PAGE.group_name_min_length_validation'),
      });
    } else {
      this.changeGroupName(this.state.chatRef, name);
    }
  };

  changeGroupName(chatRef, name) {
    CommunityCore.changeGroupName(chatRef, name);
  }

  // Process community last chat : if the message is img then do not show the url
  processLastMessage = (item) => {
    const { lastMessage } = item;
    if (lastMessage != null && lastMessage !== undefined) {
      if (item.isSticker) {
        return 'sent sticker';
      }
      if (lastMessage.includes('https://firebasestorage.googleapis.com')) {
        return 'sent photo';
      } else {
        return lastMessage;
      }
    } else {
      return 'Message';
    }
  };

  editChatTitle = (chatRef) => {
    this.presentPrompt(chatRef);
  };

  // This function will trigger the fab button base on the three section : chats sections
  fabBtnTrigger = () => {
    // Create request trigger
    // Check if this user is banned !
    Newrequestcore.IS_CREATE_REQUEST_BANNED((isBanned) => {
      // This variable will be either true or false
      if (isBanned) {
        let banTitle = I18n.t('NEW_REQUEST_PAGE.ban_alert_title');
        let banMsg = I18n.t('NEW_REQUEST_PAGE.ban_alert_msg');
        let banBtn = I18n.t('NEW_REQUEST_PAGE.ban_alert_btn');

        // Show ban alert
        this.props.showAlert([
          banTitle,
          banMsg,
          [
            {
              text: banBtn,
              onPress: () => {
                NavigationService.moveToScreenInStack(
                  'SettingsStack',
                  'SupportPage',
                );
              },
            },
          ],
        ]);
      } else {
        // let warning = I18n.t('NEW_REQUEST_PAGE.no_game_alert_title');
        // let warningMsg = I18n.t('NEW_REQUEST_PAGE.no_game_alert_message');
        // let warningBtn = I18n.t('NEW_REQUEST_PAGE.no_game_alert_add_game');
        NavigationService.moveToScreenInStack('HomeStack', 'NewRequestPage');
      }
    });
  };

  // Getting Animated Gif for the right mode
  getGif() {
    if (this.props.theme.mode === 'dark') {
      return CONTROLLER_LOADING_DARK;
    } else {
      return CONTROLLER_LOADING;
    }
  }

  render() {
    const { uid, communityGroupChatsArr, isFocused } = this.props;
    const { isLoading } = this.state;
    if (isFocused) {
      return (
        <View style={styles.subContent}>
          {
            isLoading &&
            <FlatList
              data={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9]}
              renderItem={({ item, index }) => {
                return <ChatItemHolderSkeleton />;
              }}
              showsVerticalScrollIndicator={false}
              style={{ width: '100%', }}
              keyExtractor={(item, index) => {
                return index.toString()
              }}
            />
          }
          {!isLoading && communityGroupChatsArr.length > 0 ? (
            <FlatList
              data={communityGroupChatsArr}
              showsVerticalScrollIndicator={false}
              renderItem={(data, rowMap) => {
                return (
                  !this.props.blockedList.has(data.item.uid) && (
                    <SwipeRow
                      disableRightSwipe
                      rightOpenValue={-perfectSize(75)}
                      onRowOpen={(position) => {
                        this.swipRef.forEach((swipeRef, _index) => {
                          if (data.index !== _index) {
                            if (swipeRef) {
                              swipeRef.closeRow();
                            }
                          }
                        });
                      }}
                      ref={(ref) => (this.swipRef[data.index] = ref)}>
                      <View style={styles.rowBack}>
                        <TouchableOpacity
                          style={[styles.backRightBtn, styles.backRightBtnRight]}
                          onPress={() => {
                            if (this.swipRef[data.index]) {
                              this.swipRef[data.index].closeRow();
                            }
                            this.editChatTitle(data.item.chatRef);
                          }}>
                          <Octicons name="gear" size={18} color={'white'} />
                        </TouchableOpacity>
                      </View>
                      <View style={styles.rowFront}>
                        <TouchableOpacity
                          onPress={() => {
                            this.swipRef.forEach((swipe, _index) => {
                              if (swipe) {
                                swipe.closeRow();
                              }
                            });

                            this.toChatPage(
                              data.item.opponentId,
                              data.item.opponentUsername,
                              data.item.opponentBio,
                              getGameByNameFromGameList(
                                data.item.opponentUsername,
                              ).gamePhotoUrl,
                              data.item.chatRef,
                              'PUBLIC',
                            );
                          }}
                          onLongPress={() => {
                            this.swipRef.forEach((swipe, _index) => {
                              if (swipe) {
                                swipe.closeRow();
                              }
                            });
                            this.presentPrompt(data.item.chatRef);
                          }}>
                          <ChatItemHolder
                            avatar={
                              getGameByNameFromGameList(
                                data.item.opponentUsername,
                              ).gamePhotoUrl
                            }
                            title={
                              data.item.groupNewTitle
                                ? data.item.groupNewTitle
                                : data.item.opponentUsername
                            }
                            timestamp={Pipes.humantime(
                              data.item.lastMessageTimestamp,
                            )}
                            message={this.processLastMessage(data.item)}
                            deleted={data.item.deleted}
                            showUnreadBadge={
                              data.item.senderId !== uid &&
                              data.item.opened === false
                            }
                            type={data.item.senderId === this.props.uid ? 0 : 1}
                            index={data.index}
                          />
                        </TouchableOpacity>
                      </View>
                    </SwipeRow>
                  )
                );
              }}
              keyExtractor={(item, index) => item.chatRef.toString()}
              rightOpenValue={-75}
              previewRowKey={'0'}
              previewOpenValue={-40}
              previewOpenDelay={3000}
            />
          ) : (
            !isLoading &&
            <View style={styles.emptyContent}>
              <View style={AppStyles.emptyContent}>
                {/* <FastImage
                source={this.getGif()}
                style={AppStyles.emptyContentGif}
                resizeMode="contain"
              /> */}
                <Label style={AppStyles.emptyContentLabel}>
                  {I18n.t('COMMUNITY_PAGE.no_team_req_chat')}
                </Label>
              </View>
            </View>
          )}
          <TouchableOpacity
            style={[AppStyles.fabButton, styles.fabButton]}
            onPress={() => this.fabBtnTrigger()}>
            <Feather name="plus" size={perfectSize(18)} color="white" />
          </TouchableOpacity>
        </View>
      );
    }
    return <View />;
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
    lang: state.appReducer.currentLang,
    uid: state.authReducer.uid,
    communityGroupChatsArr: state.hubReducer.communityGroupChatsArr,
    blockedList: state.hubReducer.blockedList,
  };
};

function focusComponent(props) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const isFocused = useIsFocused();
  return <RequestsChat {...props} isFocused={isFocused} />;
}

export default compose(
  connect(mapStateToProps, {
    switchTheme,
    setHub,
    setGamemanager,
    showAlert,
    showInputAlert,
  }),
)(withNavigation(focusComponent));
