import firebase from 'firebase/app';
import 'firebase/database';

import AsyncStorage from '@react-native-community/async-storage';
import * as FirebasePaths from '../../constants/firebasePaths';
import { store } from '../../redux/store';

// import actions
import {
  setUserInfo,
  setProfileBorderColor,
  setPCGamesProviders,
  setMobileGamesProviders,
} from '../../redux/modules/auth/actions';
import { set as setHub } from '../../redux/modules/hub/actions';

// import model
import Membersmodel from '../../models/membersmodel';
import UserChatRef from '../../models/userchatref';

export default class Mainmenucore {
  constructor() { }

  static userInfoRef$ = null;
  static publicChatsRef$ = null;
  static privateChatsRef$ = null;
  static clansChatsRef$ = null;
  static blockedUsersRef$ = null;
  static bannedUsersRef$ = null;

  // Load all user's info : PATH : _users_info_ > UID > info > shot of user's info
  static loadUserInfo() {
    const userMap = new Map();
    const uid = store.getState().authReducer.uid;
    const userStatusRef =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      uid +
      '/' +
      FirebasePaths.FIREBASE_DETAILS_ATTR;
    // set user status online
    firebase.database()
      .ref(userStatusRef + '/' + FirebasePaths.FIREBASE_STATUS_ATTR)
      .set('online');
    // load user info
    this.userInfoRef$ = firebase.database().ref(userStatusRef);
    this.userInfoRef$.on('value', (snapshot) => {
      const snap = snapshot.val();
      // eslint-disable-next-line no-unused-vars
      for (let key in snap) {
        const val = snap[key];
        if (key === FirebasePaths.FIREBASAE_USER_RANK_ATTR) {
          userMap.set(key, val);
        } else {
          userMap.set(key, val.toString());
        }
      }
      const _userInfo = {};
      _userInfo.username = userMap.get(FirebasePaths.FIREBASE_USERNAME_ATTR);
      _userInfo.bio = userMap.get(FirebasePaths.FIREBASE_BIO_ATTR);
      _userInfo.userEmail = userMap.get(FirebasePaths.FIREBASE_EMAIL_ATTR);
      _userInfo.pictureURL = userMap.get(
        FirebasePaths.FIREBASE_PICTURE_URL_ATTR,
      );
      _userInfo.country = userMap.get(FirebasePaths.FIREBASE_ANALYTICS_COUNTRY);
      _userInfo.city = userMap.get(FirebasePaths.FIREBASE_ANALYTICS_CITY);
      _userInfo.age = userMap.get(FirebasePaths.FIREBASE_ANALYTICS_AGE);
      _userInfo.avgPlaytime = userMap.get(
        FirebasePaths.FIREBASE_ANALYTICS_AVG_PLAYTIME,
      );
      _userInfo.avgDailyPlaytime = userMap.get(
        FirebasePaths.FIREBASE_ANALYTICS_AVG_DAILY_PLAYTIME,
      );
      _userInfo.phone = userMap.get(FirebasePaths.FIREBASE_ANALYTICS_PHONE);
      _userInfo.favGame = userMap.get(
        FirebasePaths.FIREBASE_ANALYTICS_FAV_GAME,
      );

      _userInfo.mainPlatform = userMap.get(
        FirebasePaths.FIREBASE_ANALYTICS_MAIN_PLATFORM,
      );
      _userInfo.gender = userMap.get(FirebasePaths.FIREBASE_ANALYTICS_GENDER);
      _userInfo.dayPlaytime = userMap.get(
        FirebasePaths.FIREBASE_ANALYTICS_DAY_PLAYTIME,
      );

      // hops icon and profile icon
      if (userMap.has(FirebasePaths.FIREBASAE_USER_HOPS_ICON)) {
        _userInfo.hops_icon = userMap.get(
          FirebasePaths.FIREBASAE_USER_HOPS_ICON,
        );
      }
      if (userMap.has(FirebasePaths.FIREBASAE_USER_PROFILE_ICON)) {
        _userInfo.profile_icon = userMap.get(
          FirebasePaths.FIREBASAE_USER_PROFILE_ICON,
        );
      }

      // Setting default pic if the user doens't have a profile pic
      if (
        userMap.get(FirebasePaths.FIREBASE_PICTURE_URL_ATTR) === 'NULL' ||
        userMap.get(FirebasePaths.FIREBASE_PICTURE_URL_ATTR) === 'default' ||
        userMap.get(FirebasePaths.FIREBASE_PICTURE_URL_ATTR) === undefined
      ) {
        _userInfo.pictureURL =
          'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/teams%20default%20photos%20and%20background%2Fdefault_team_logov2.png?alt=media&token=b88bf926-35b9-4568-a80e-ee24110aceb6';
      }

      // Check if user has rank

      if (notNull(userMap.get(FirebasePaths.FIREBASAE_USER_RANK_ATTR))) {
        _userInfo.rank = userMap.get(FirebasePaths.FIREBASAE_USER_RANK_ATTR);
      } else {
        _userInfo.rank = 0;
      }

      // Check if the hops shadow is activated

      AsyncStorage.getItem('hops-shadow-key').then((shadowActivated) => {
        if (shadowActivated === 'true') {
          store.dispatch(setHub({ IS_HOP_SHADOW_ACTIVATED: true }));
          _userInfo.isHopShadowActivated = true;
        } else if (shadowActivated === 'false') {
          store.dispatch(setHub({ IS_HOP_SHADOW_ACTIVATED: false }));
          _userInfo.isHopShadowActivated = false;
        }

        if (shadowActivated === null || shadowActivated === undefined) {
          if (_userInfo.rank >= 8300) {
            _userInfo.isHopShadowActivated = true;
            store.dispatch(setHub({ IS_HOP_SHADOW_ACTIVATED: true }));
          } else {
            store.dispatch(setHub({ IS_HOP_SHADOW_ACTIVATED: false }));
            _userInfo.isHopShadowActivated = false;
          }
        }
      });

      // Game Providers: PSN, XBOX And Nintendo

      if (userMap.has(FirebasePaths.FIREBASE_USER_PS_GAME_PROVIDER_ATTR)) {
        _userInfo.PSNAcc = userMap.get(
          FirebasePaths.FIREBASE_USER_PS_GAME_PROVIDER_ATTR,
        );
      }
      if (userMap.has(FirebasePaths.FIREBASE_USER_XBOX_GAME_PROVIDER_ATTR)) {
        _userInfo.XboxLiveAcc = userMap.get(
          FirebasePaths.FIREBASE_USER_XBOX_GAME_PROVIDER_ATTR,
        );
      }
      if (
        userMap.has(FirebasePaths.FIREBASE_USER_NINTENDO_GAME_PROVIDER_ATTR)
      ) {
        _userInfo.nintendoAcc = userMap.get(
          FirebasePaths.FIREBASE_USER_NINTENDO_GAME_PROVIDER_ATTR,
        );
      }
      // load Mobile games Providers
      // this.loadMobileGamesProvider(userStatusRef);
      if (userMap.has(FirebasePaths.FIREBASE_USER_MOBILE_GAME_PROVIDER_ATTR)) {
        // load mobile games provider(path)
        this.loadMobileGamesProvider(userStatusRef);
      } else {
      }

      // load PC games Providers
      if (userMap.has(FirebasePaths.FIREBASE_USER_PC_GAME_PROVIDER_ATTR)) {
        // load pc games providers(path)
        this.loadPcGamesProviders(userStatusRef);
      } else {
        _userInfo.pcGamesAccs = new Map();
      }

      const date = new Date();
      const DATE = date.toDateString();
      const TIME = date.toLocaleTimeString();

      // Log last activte time and timestamp
      firebase.database()
        .ref(
          FirebasePaths.FIREBASE_USERS_INFO_ATTR +
          '/' +
          uid +
          '/' +
          FirebasePaths.FIREBASE_LAST_ACTIVE_TIME,
        )
        .set(DATE + ' ' + TIME);

      firebase.database()
        .ref(
          FirebasePaths.FIREBASE_USERS_INFO_ATTR +
          '/' +
          uid +
          '/' +
          FirebasePaths.FIREBASE_LAST_ACTIVE_TIMESTAMP,
        )
        .set(firebase.database.ServerValue.TIMESTAMP)
        .finally(() => {
          store.dispatch(setUserInfo(_userInfo));
        });
      // store.dispatch(setUserInfo(_userInfo));  // [Nami]: this crashes. Not sure why. So I moved this line into the above finally callback
    });

    const cosmeticsPath =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      uid +
      '/' +
      FirebasePaths.FIREBASE_COSMETICS_ATTR +
      '/' +
      FirebasePaths.FIREBASE_PROFILE_BORDER_ATTR;
    firebase.database()
      .ref(cosmeticsPath)
      .once('value')
      .then((cosmeticsSnapshot) => {
        const cosmeticsVal = cosmeticsSnapshot.val();

        // eslint-disable-next-line no-unused-vars
        for (let key in cosmeticsVal) {
          const val = cosmeticsVal[key];
          userMap.set(key, val.toString());
        }
        const _profileBorderInfo = {};
        _profileBorderInfo.profileBorderColor = userMap.get(
          FirebasePaths.FIREBASE_COLOR_ATTR,
        );
        store.dispatch(setProfileBorderColor(_profileBorderInfo));
      });

    const cosmeticsShadowPath =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      uid +
      '/' +
      FirebasePaths.FIREBASE_COSMETICS_ATTR +
      '/' +
      FirebasePaths.FIREBASE_HOP_SHADOW_ATTR;
    firebase.database()
      .ref(cosmeticsShadowPath)
      .once('value')
      .then((cosmeticsSnapshot) => {
        const cosmeticsVal = cosmeticsSnapshot.val();
        // eslint-disable-next-line no-unused-vars
        for (let key in cosmeticsVal) {
          const val = cosmeticsVal[key];
          userMap.set(key, val.toString());
        }
        const _shadowColorInfo = {};
        _shadowColorInfo.shadowColor = userMap.get(
          FirebasePaths.FIREBASE_COLOR_ATTR,
        );
        store.dispatch(setUserInfo(_shadowColorInfo));
      });
  }

  // This function will update the user token in all the team notification refs
  static updateTeamNotificationsTokens() {
    const state = store.getState();
    // First check if the user has the updated token & has the uid
    if (
      state.authReducer.uid !== '' &&
      state.hubReducer.currentUserToken !== '' &&
      state.authReducer.uid != null &&
      state.hubReducer.currentUserToken != null
    ) {
      state.gameManagerReducer.allGamesIds.forEach((value, key) => {
        // Check if the game is in the activated and isn't in the deactivated
        if (
          state.hubReducer.activiatedGamesNotificationArr.includes(key) &&
          !state.hubReducer.deactiviatedGamesNotificationArr.includes(key)
        ) {
          firebase.database()
            .ref(
              FirebasePaths.FIREBASE_TEAMS_NOTIFICATIONS +
              '/' +
              key +
              '/' +
              FirebasePaths.FIREBASE_TEAMS_NOTIFICATIONS_FOLLOWERS,
            )
            .child(state.authReducer.uid)
            .set(state.hubReducer.currentUserToken);
        }
      });
    }
  }

  // This function will load the pc game providers with its values for the user
  // _users_info _/ uid / info /
  static loadMobileGamesProvider(path) {
    firebase.database()
      .ref(path + '/' + FirebasePaths.FIREBASE_USER_MOBILE_GAME_PROVIDER_ATTR)
      .once('value')
      .then((snapshot) => {
        const snap = snapshot.val();
        const _mobileGamesAccs = new Map();
        // eslint-disable-next-line no-unused-vars
        for (const key in snap) {
          _mobileGamesAccs.set(key, snap[key]);
        }
        store.dispatch(
          setMobileGamesProviders({ mobileGamesAccs: _mobileGamesAccs }),
        );
      });
  }

  // This function will load the ranks ...

  // Get the sliders imgs
  static loadSliders() { }

  // Load games notifications activated pack
  static loadActivatedNotifcationsPack() {
    let ref =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      store.getState().authReducer.uid +
      '/' +
      FirebasePaths.FIREBASE_USER_TEAM_NOTIFICATION;
    return firebase.database().ref(ref).once('value');
  }

  // Load games notifications deactivated pack
  static loaddeActivatedNotifcationsPack() {
    let ref =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      store.getState().authReducer.uid +
      '/' +
      FirebasePaths.FIREBASE_USER_TEAM_NOTIFICATION_DEACTIVATED_NOTIFICATIONS;
    return firebase.database().ref(ref).once('value');
  }

  // This function will load the pc game providers with its values for the user
  // _users_info _/ uid / info /
  static loadPcGamesProviders(path) {
    firebase.database()
      .ref(path + '/' + FirebasePaths.FIREBASE_USER_PC_GAME_PROVIDER_ATTR)
      .once('value')
      .then((snapshot) => {
        const snap = snapshot.val();
        const _pcGamesAccs = new Map();
        // eslint-disable-next-line no-unused-vars
        for (const key in snap) {
          _pcGamesAccs.set(key, snap[key]);
        }
        store.dispatch(setPCGamesProviders({ pcGamesAccs: _pcGamesAccs }));
      });
  }

  // This function will load all the clans chats
  static loadClansChats() {
    // Get the path
    let path =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      store.getState().authReducer.uid +
      '/' +
      FirebasePaths.FIREBASE_CLANS_CHATS_USER_REF;
    if (this.clansChatsRef$) {
      this.clansChatsRef$.off();
    }
    this.clansChatsRef$ = firebase.database().ref(path);
    this.clansChatsRef$.on('value', (snapshot) => {
      // Clear the map so no duplications happen , and get rid of the old ones
      let clansChatsArr = [];
      snapshot.forEach((item) => {
        if (item.key != null && item.val() != null && item.val().id != null) {
          clansChatsArr.push(item.val());
        }
      });
      clansChatsArr.sort(function (a, b) {
        return b.timeStamp - a.timeStamp;
      });
      store.dispatch(
        setHub({
          clansChatsArr,
        }),
      );

      // Call the counter for unread messages
      if (clansChatsArr.length > 0) {
        this.calculateTabsUnreads(null, clansChatsArr, null, 'CLANS');
      }
    });
  }

  // This function will load only current user's games into GameManager
  static loadUserGames() {
    let uid = store.getState().authReducer.uid;
    // let gameModel = new Gamemodel();
    let favorGamesRef =
      '/' +
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      uid +
      '/' +
      FirebasePaths.FIREBASE_GAMES_REFERENCES +
      '/' +
      FirebasePaths.FIREBASE_FAVOR_GAME_ATTR +
      '/';
    //  We will suspend the connection here for a while .. just to test loading user's  games
    // Just un comment this line and un-comment the line in the mainmenu page for closing the connection
    // You will find it in the mainmenu page

    // TEMP GAMES CONTAINER MODEL
    // let gamesContainer = new Gamescontainer();

    // this.loadUserGames$ =
    return firebase.database().ref(favorGamesRef).once('value');
  }

  // Load all user's friends to the hub lists
  static loadUserFriends() {
    const uid = store.getState().authReducer.uid;
    // REF : _users_info_/uid/_friends_list_
    let userFriendsPath =
      '/' +
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      uid +
      '/' +
      FirebasePaths.FIREBASE_FRIENDS_LIST_ATTR;
    let friendsList = [];
    // EXTRACT FRIEND KEY
    firebase.database()
      .ref(userFriendsPath)
      .once('value', (snapshot) => {
        const shot = snapshot.val();
        if (shot !== null) {
          const totalLength = Object.keys(shot).length;
          for (var friendKey in shot) {
            let friendId = friendKey;
            let bio;
            let email;
            let picUrl;
            let username;
            let status = 'offline';

            // create model
            let friendModel = {}; //new Userinformation();

            // REF : _users_info_/friendKey
            const friendPath =
              '/' +
              FirebasePaths.FIREBASE_USERS_INFO_ATTR +
              '/' +
              friendKey +
              '/' +
              FirebasePaths.FIREBASE_DETAILS_ATTR;
            firebase.database()
              .ref(friendPath)
              .once('value', (_snapshot) => {
                const snap = _snapshot.val();
                for (var key in snap) {
                  if (key === FirebasePaths.FIREBASE_BIO_ATTR) {
                    bio = snap[key];
                  }
                  if (key === FirebasePaths.FIREBASE_EMAIL_ATTR) {
                    email = snap[key];
                  }
                  if (key === FirebasePaths.FIREBASE_PICTURE_URL_ATTR) {
                    picUrl = snap[key];
                  }
                  if (key === FirebasePaths.FIREBASE_USERNAME_ATTR) {
                    username = snap[key];
                  }
                  if (key === FirebasePaths.FIREBASE_STATUS_ATTR) {
                    status = snap[key];
                  }
                }
                if (picUrl === 'NULL' || picUrl === 'default') {
                  picUrl =
                    'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/teams%20default%20photos%20and%20background%2Fdefault_team_logov2.png?alt=media&token=b88bf926-35b9-4568-a80e-ee24110aceb6';
                }

                friendModel.UID = friendId;
                friendModel.bio = bio;
                friendModel.userEmail = email;
                friendModel.pictureURL = picUrl;
                friendModel.username = username;
                friendModel.status = status;

                friendsList.push(friendModel);
                if (friendsList.length === totalLength) {
                  store.dispatch(setHub({ friendsList: friendsList }));
                }
              });
          }
        }
      });
  }

  // Create a new friend model , find the friend and load all his/her info to the model  and then add it to the hub
  static addToFriendsList(friendKey) {
    let friendId = friendKey;
    let bio;
    let email;
    let picUrl;
    let username;
    let status = 'online';

    // create model
    let friendModel = {}; //new Userinformation();

    // REF : _users_info_/friendKey
    const friendPath =
      '/' +
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      friendKey +
      '/' +
      FirebasePaths.FIREBASE_DETAILS_ATTR;
    firebase.database()
      .ref(friendPath)
      .once('value', (snapshot) => {
        const snap = snapshot.val();
        for (var key in snap) {
          if (key === FirebasePaths.FIREBASE_BIO_ATTR) {
            bio = snap[key];
          }
          if (key === FirebasePaths.FIREBASE_EMAIL_ATTR) {
            email = snap[key];
          }
          if (key === FirebasePaths.FIREBASE_PICTURE_URL_ATTR) {
            picUrl = snap[key];
          }
          if (key === FirebasePaths.FIREBASE_USERNAME_ATTR) {
            username = snap[key];
          }
          if (key === FirebasePaths.FIREBASE_STATUS_ATTR) {
            status = snap[key];
          }
        }
        if (picUrl === 'NULL' || picUrl === 'default') {
          picUrl =
            'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/teams%20default%20photos%20and%20background%2Fdefault_team_logov2.png?alt=media&token=b88bf926-35b9-4568-a80e-ee24110aceb6';
        }

        friendModel.UID = friendId;
        friendModel.bio = bio;
        friendModel.userEmail = email;
        friendModel.pictureURL = picUrl;
        friendModel.username = username;
        friendModel.status = status;

        let friendsList = store.getState().hubReducer.friendsList;
        if (friendsList.filter((e) => e.UID === friendId).length === 0) {
          friendsList.push(friendModel);
        }
        store.dispatch(setHub({ friendsList }));
      });
  }

  // This function will load recent Activites : To recentList in hub
  static loadRecentActivites() {
    const uid = store.getState().authReducer.uid;
    let recentKeysRef =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      uid +
      '/' +
      FirebasePaths.FIREBASE_GAMES_REFERENCES +
      '/' +
      FirebasePaths.FIREBASE_RECENT_GAME_ATTR;
    return firebase.database().ref(recentKeysRef).once('value');
  }

  // This function will load blocked users inside blocked users map
  static loadBlockedUsers() {
    const uid = store.getState().authReducer.uid;
    const blockedListRef =
      '/' +
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      uid +
      '/' +
      FirebasePaths.FIREBASE_BLOCKED_USERS +
      '/';
    this.blockedUsersRef$ = firebase.database().ref(blockedListRef);
    this.blockedUsersRef$.on('value', (blockedUsersShot) => {
      const blockedList = new Map();
      blockedUsersShot.forEach((shot) => {
        blockedList.set(shot.key, shot.val());
      });
      store.dispatch(
        setHub({
          blockedList,
        }),
      );
    });
  }

  // This function will load all user saved requests
  static loadSavedReqs() {
    // Getting the user path

    let uid = store.getState().authReducer.uid;

    // _users_info / uid / _games_ / _games_ / _saved_requests_

    let savedRequestPath =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      uid +
      '/' +
      FirebasePaths.FIREBASE_GAMES_REFERENCES +
      '/' +
      FirebasePaths.FIREBASE_SAVED_REQS_ATTR +
      '/' +
      FirebasePaths.FIREBASE_SAVED_REQUESTS_REQUESTS_ATTR;

    return firebase.database().ref(savedRequestPath);
  }

  // This function will load each saved req by it's Id
  static loadSavedReq(savedReqPath, savedReqKey) { }

  // This function will load  user private chats Refs
  static loadPrivateChatsRefs() {
    // payload obj
    let chatRefObj;
    // Getting the user path
    let uid = store.getState().authReducer.uid;
    // _users_info / uid /
    let userPrivateChatRef =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      uid +
      '/' +
      FirebasePaths.FIREBASE_USER_CHAT_REFERENCES +
      '/' +
      FirebasePaths.FIREBASE_PRIVATE_ATTR +
      '/';
    if (this.privateChatsRef$) {
      this.privateChatsRef$.off();
    }
    this.privateChatsRef$ = firebase.database().ref(userPrivateChatRef);
    this.privateChatsRef$.on('value', (snapshot) => {
      let allPrivateChatRefs = new Map();
      let blockedList = store.getState().hubReducer.blockedList;
      snapshot.forEach((ref) => {
        // Getting the shot
        chatRefObj = ref.val();
        console.log('chatRefObj: ', chatRefObj)
        if (chatRefObj.chatRef && !blockedList.has(chatRefObj.opponentId)) {
          // Creatung an  object  then fill it
          let userChatRef = new UserChatRef();
          userChatRef.chatRef = chatRefObj.chatRef;
          userChatRef.counter = chatRefObj.counter;
          userChatRef.opponentId = chatRefObj.opponentId;
          userChatRef.lastMessage = chatRefObj.lastMessage;
          userChatRef.lastMessageTimestamp = chatRefObj.lastMessageTimestamp;
          userChatRef.opponentPic = chatRefObj.opponentPic;
          userChatRef.opponentUsername = chatRefObj.opponentUsername;
          userChatRef.senderId = chatRefObj.senderId;
          userChatRef.opponentBio = chatRefObj.opponentBio;
          userChatRef.deleted = chatRefObj.deleted;
          userChatRef.isSticker = chatRefObj.isSticker;
          this.getProfileBorderColor(chatRefObj.opponentId).then((color) => {
            userChatRef.profileBorderColor = color;
          });
          // Load Opened chat attr  : To detect if the user opened the chat or not to show the dot
          if (chatRefObj.opened != null) {
            userChatRef.opened = chatRefObj.opened;
          }
          // Set ref to the hub
          allPrivateChatRefs[userChatRef.chatRef] = userChatRef;
        }
      });

      // Load  it to the aray
      let communityChatsArr = Object.values(allPrivateChatRefs);

      // Sort chats : un-opnned chats should be on the top : time
      communityChatsArr.sort(function (a, b) {
        return a.lastMessageTimestamp - b.lastMessageTimestamp;
      });

      communityChatsArr.reverse();

      // // // Convert obj timestamp format
      // this.hub.communityChatsArr.forEach(object => {
      //     object.lastMessageTimestamp = this.hub.getTimeStamp().getTimestampToTimeAgo(new Date().getTime() , object.lastMessageTimestamp);
      // });
      console.log('allPrivateChatRefs: ', allPrivateChatRefs, communityChatsArr)
      store.dispatch(
        setHub({
          allPrivateChatRefs,
          communityChatsArr,
        }),
      );

      // Call the counter for unread messages
      if (communityChatsArr.length > 0) {
        this.calculateTabsUnreads(communityChatsArr, null, null, 'PRIVATE');
      }
    });
  }

  static getProfileBorderColor(uid) {
    let cosmeticsPath =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      uid +
      '/' +
      FirebasePaths.FIREBASE_COSMETICS_ATTR +
      '/' +
      FirebasePaths.FIREBASE_PROFILE_BORDER_ATTR;

    return firebase.database()
      .ref(cosmeticsPath)
      .once('value')
      .then((snapshot) => {
        let borderColor = '#880e4a';
        snapshot.forEach((item) => {
          if (item.key === FirebasePaths.FIREBASE_COLOR_ATTR) {
            borderColor = item.val();
          }
        });
        return borderColor;
      });
  }
  // This function will return the user's status (offline or online)
  static getUserstatus(uid) { }

  // This function will increase the tabsUnreadMessages  : unreadPrivate + unreadPublic , input : communityType which is the chats arr type  PRIVATE or PUBLIC ro only calculate the specific arr when change occur
  static calculateTabsUnreads(
    communityChatsArr,
    clansChatsArr,
    communityGroupChatsArr,
    communtyType,
  ) {
    // Calculate private unreads
    if (communtyType === 'PRIVATE') {
      let tabsUnreadMessages = store.getState().hubReducer.tabsUnreadMessages;
      for (let i = 0; i < communityChatsArr.length; i++) {
        // If the last message sender id is not the current user then increate tabsUnread by one
        if (
          communityChatsArr[i].senderId !== store.getState().authReducer.uid
        ) {
          if (communityChatsArr[i].opened != null) {
            // If not opened then increase the number of unread messages
            if (!communityChatsArr[i].opened) {
              if (!tabsUnreadMessages.includes(communityChatsArr[i].chatRef)) {
                tabsUnreadMessages.push(communityChatsArr[i].chatRef);
              }
            } else {
              tabsUnreadMessages = tabsUnreadMessages.filter(
                (item) => item !== communityChatsArr[i].chatRef,
              );
            }
          }
        }
      }
      store.dispatch(setHub({ tabsUnreadMessages }));
    } else if (communtyType === 'CLANS') {
      let tabsUnreadMessages = store.getState().hubReducer.tabsUnreadMessages;
      for (let i = 0; i < clansChatsArr.length; i++) {
        // If the last message sender id is not the current user then increate tabsUnread by one
        if (clansChatsArr[i].uid !== store.getState().authReducer.uid) {
          if (clansChatsArr[i].opened != null) {
            // If not opened then increase the number of unread messages
            if (!clansChatsArr[i].opened) {
              if (!tabsUnreadMessages.includes(clansChatsArr[i].teamId)) {
                tabsUnreadMessages.push(clansChatsArr[i].teamId);
              }
            } else {
              tabsUnreadMessages = tabsUnreadMessages.filter(
                (item) => item !== clansChatsArr[i].teamId,
              );
            }
          }
        }
      }
      store.dispatch(setHub({ tabsUnreadMessages }));
    } else {
      let tabsUnreadMessages = store.getState().hubReducer.tabsUnreadMessages;
      for (let i = 0; i < communityGroupChatsArr.length; i++) {
        // If the last message sender id is not the current user then increate tabsUnread by one
        if (
          communityGroupChatsArr[i].senderId !==
          store.getState().authReducer.uid
        ) {
          if (communityGroupChatsArr[i].opened != null) {
            // If not opened then increase the number of unread messages
            if (!communityGroupChatsArr[i].opened) {
              if (
                !tabsUnreadMessages.includes(communityGroupChatsArr[i].chatRef)
              ) {
                tabsUnreadMessages.push(communityGroupChatsArr[i].chatRef);
              }
            } else {
              tabsUnreadMessages = tabsUnreadMessages.filter(
                (item) => item !== communityGroupChatsArr[i].chatRef,
              );
            }
          }
        }
      }
      store.dispatch(setHub({ tabsUnreadMessages }));
    }
  }

  // This function will load  user public chats Refs
  static loadPublicChatsRefs() {
    // payload obj
    let chatRefObj;
    // Getting the user path
    let uid = store.getState().authReducer.uid;
    // _users_info / uid /

    if (uid != null && uid !== undefined && uid !== '') {
      let userPublicChatRef =
        FirebasePaths.FIREBASE_USERS_INFO_ATTR +
        '/' +
        uid +
        '/' +
        FirebasePaths.FIREBASE_USER_CHAT_REFERENCES +
        '/' +
        FirebasePaths.FIREBASE_PUBLIC_ATTR +
        '/';

      if (this.publicChatsRef$) {
        this.publicChatsRef$.off();
      }
      this.publicChatsRef$ = firebase.database().ref(userPublicChatRef);
      this.publicChatsRef$.on('value', (snapshot) => {
        let allGroupChatRefs = new Map();
        snapshot.forEach((ref) => {
          // Getting the shot
          chatRefObj = ref.val();
          // Creatung an  object  then fill it
          let userChatRef = new UserChatRef();
          userChatRef.chatRef = chatRefObj.chatRef;
          userChatRef.counter = chatRefObj.counter;
          // Will pass the opponent id as the chat key
          userChatRef.opponentId = chatRefObj.chatRef;

          userChatRef.lastMessage = chatRefObj.lastMessage;
          userChatRef.lastMessageTimestamp = chatRefObj.lastMessageTimestamp;
          userChatRef.opponentPic = chatRefObj.opponentPic;
          // In this case this will be the game name so ..
          userChatRef.opponentUsername = chatRefObj.opponentUsername;
          userChatRef.groupNewTitle = chatRefObj.groupNewTitle;

          userChatRef.senderId = chatRefObj.senderId;
          userChatRef.opponentBio = chatRefObj.opponentBio;
          userChatRef.chatType = 'PUBLIC';

          // Load gameId , region , platform
          userChatRef.platform = chatRefObj.platform;
          userChatRef.gameId = chatRefObj.gameId;
          userChatRef.region = chatRefObj.region;
          userChatRef.isSticker = chatRefObj.isSticker;

          // Load Opened chat attr  : To detect if the user opened the chat or not to show the dot
          if (chatRefObj.opened != null) {
            userChatRef.opened = chatRefObj.opened;
          }
          userChatRef.deleted = chatRefObj.deleted;

          // Filter Group
          if (
            chatRefObj.senderId != null &&
            chatRefObj.opponentUsername != null &&
            chatRefObj.opponentPic != null &&
            chatRefObj.opponentId != null &&
            chatRefObj.opponentBio != null &&
            chatRefObj.lastMessageTimestamp != null &&
            chatRefObj.lastMessage != null &&
            chatRefObj.counter != null &&
            chatRefObj.chatType != null &&
            chatRefObj.chatRef != null
          ) {
            // Load members
            this.loadGroupChatMembers(userChatRef.chatRef);

            // Set ref to the hub
            allGroupChatRefs.set(userChatRef.chatRef, userChatRef);
          }
        });

        // Load  it to the array
        let communityGroupChatsArr = Array.from(allGroupChatRefs.values());

        // Sort chats : un-opnned chats should be on the top : time
        communityGroupChatsArr.sort(
          (a, b) => b.lastMessageTimestamp - a.lastMessageTimestamp,
        );

        // // Convert obj timestamp format
        // this.hub.communityGroupChatsArr.forEach(object => {
        //     object.lastMessageTimestamp = this.hub.getTimeStamp().getTimestampToTimeAgo(new Date().getTime() , object.lastMessageTimestamp);
        // });

        store.dispatch(
          setHub({
            allGroupChatRefs,
            communityGroupChatsArr,
          }),
        );

        // Call the counter for unread messages
        if (communityGroupChatsArr.length > 0) {
          this.calculateTabsUnreads(
            null,
            null,
            communityGroupChatsArr,
            'PUBLIC',
          );
        }
      });
    }
  }

  // ُ This function will load joined players in  from each group chat  in a map : hub
  static loadGroupChatMembers(groupKey) {
    if (store.getState().hubReducer.groupChatMembers[groupKey] == null) {
      // Creating the members model to save all players key
      let groupMembers = new Membersmodel();
      // Getting the ref
      let groupChatRef =
        FirebasePaths.FIREBASE_CHAT_ATTR +
        '/' +
        FirebasePaths.FIREBASE_PUBLIC_ATTR +
        '/' +
        groupKey +
        '/' +
        FirebasePaths.FIREBASE_PUBLIC_CHAT_INFO +
        '/' +
        FirebasePaths.FIREBASE_PUBLIC_CHAT_USERS +
        '/';
      firebase.database()
        .ref(groupChatRef)
        .once('value')
        .then((snapshot) => {
          snapshot.forEach((user) => {
            groupMembers.members.set(user.key, user.val());
          });
          let groupChatMembers = store.getState().hubReducer.groupChatMembers;
          groupChatMembers[groupKey] = groupMembers;
          store.dispatch(setHub({ groupChatMembers }));
        });
    }
  }

  // This function will load the group chat ref for chat but with promise : IT SHOULD BE WITH PROMISE
  static loadGroupMembers(groupKey) {
    let groupChatMembers = {};

    // Creating the members model to save all players key
    let groupMembers = new Membersmodel();
    // Getting the ref
    let groupChatRef =
      FirebasePaths.FIREBASE_CHAT_ATTR +
      '/' +
      FirebasePaths.FIREBASE_PUBLIC_ATTR +
      '/' +
      groupKey +
      '/' +
      FirebasePaths.FIREBASE_PUBLIC_CHAT_INFO +
      '/' +
      FirebasePaths.FIREBASE_PUBLIC_CHAT_USERS +
      '/';

    return firebase.database()
      .ref(groupChatRef)
      .once('value')
      .then((data) => {
        data.forEach((user) => {
          groupMembers.members.set(user.key, user.val());
        });
        groupChatMembers[groupKey] = groupMembers;
        store.dispatch(
          setHub({
            groupChatMembers,
          }),
        );
        return groupChatMembers;
      });
  }

  static getUserTeamsRef() {
    const uid = store.getState().authReducer.uid;
    let path =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      uid +
      '/' +
      FirebasePaths.FIREBASE_USER_TEAMS +
      '/' +
      FirebasePaths.FIREBASE_USER_TEAMS_REFS;

    return firebase.database().ref(path);
  }

  // This function will load the user's team
  static loadUserTeams() {
    const uid = store.getState().authReducer.uid;
    let path =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      uid +
      '/' +
      FirebasePaths.FIREBASE_USER_TEAMS +
      '/' +
      FirebasePaths.FIREBASE_USER_TEAMS_REFS;

    return firebase.database().ref(path).once('value');
  }

  // Load team join requests for admin  : just load the parents so the admin can know which one has the notification , ONLY A
  // TEAM KEY : AS A KEY , WITH THE LAST JOIN KEY .. it doesn;t matter if you load it all or not
  // load all the join requests when the admin open the join requests page
  static loadTeamJoinRequests() { }

  // This function will load the user's teams invitations
  static loadUserTeamsInvitations() {
    let path =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      store.getState().authReducer.uid +
      '/' +
      FirebasePaths.FIREBASE_USER_TEAMS +
      '/' +
      FirebasePaths.FIREBASE_USER_TEAMS_INVITATIONS;
    return firebase.database().ref(path);
  }

  static loadContentBanUsers() {
    let path = FirebasePaths.FIREBASE_CONTENT_BAN + '/';

    if (this.bannedUsersRef$) {
      this.bannedUsersRef$.off();
    }

    this.bannedUsersRef$ = firebase.database().ref(path);
    this.bannedUsersRef$.on('value', (snapshot) => {
      if (snapshot && snapshot.val()) {
        let bannedUsers = Object.keys(snapshot.val()).filter(
          (key) => key !== store.getState().authReducer.uid,
        );
        store.dispatch(
          setHub({
            bannedUsers,
          }),
        );
      }
    });
  }

  // this function will open connection for all group chats : to update the last message in community chat for the user
  static openGroupChatsConnection(groupKey, gameName) { }

  // This function will check if the user has request
  static loadUserCurrentReq() { }

  // This function will load last 100 recent requests | less than 3 h or less than 24 h && User added the games for each request from the hub
  static loadTimedReqs() { }

  // Filter 3H reqs Time and user has the games
  static filterTimedReqs(currentUnixTime) { }

  // This function will be used as a bundle for all load user info data : functions
  static autoLoginBundle() { }
}

function notNull(val) {
  if (val !== undefined && val !== null && val !== '') {
    return true;
  }
  return false;
}
