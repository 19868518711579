import {StyleSheet} from 'react-native';
import {perfectSize} from '../../../helpers/deviceHelper';
const styles = StyleSheet.create({
  flexRow: {
    flexDirection: 'row',
  },
  button: {
    height: perfectSize(32),
    paddingHorizontal: perfectSize(16),
    borderRadius: perfectSize(12),
    marginRight: perfectSize(8),
  },
  sectionLabel: {
    marginTop: perfectSize(24),
    marginBottom: perfectSize(12),
  },
  viewAllBtn: {
    height: perfectSize(32),
    paddingHorizontal: perfectSize(16),
    borderRadius: perfectSize(12),
    marginBottom: perfectSize(16),
    alignSelf: 'flex-end',
  },
  itemImage: {
    width: perfectSize(32),
    height: perfectSize(32),
    aspectRatio: 1,
    borderRadius: perfectSize(12),
  },
  btn_tag: {
    width: perfectSize(64),
    height: perfectSize(32),
    marginRight: perfectSize(7),
  },
  mt8: {
    marginTop: perfectSize(8),
  },
  clearContainer: {
    padding: perfectSize(4),
    marginTop: perfectSize(8),
  },
  clearIcon: {marginLeft: perfectSize(4), marginTop: perfectSize(1)},
  clearText: {
    color: '#C70064',
    fontSize: 12,
    marginLeft: perfectSize(4),
  },
});

export default styles;
