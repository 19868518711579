export default class HootReport {
    constructor() {
        this.id = '';
        this.msg = '';
        this.hootId = '';
        this.timestamp = '';
        this.reporterId = '';
        this.reporterEmail = '';
        this.reporterUsername = '';
        this.reportedUID = '';
        this.reportedEmail = '';
        this.reportedUsername = '';
    }
}
