/* eslint-disable react-native/no-inline-styles */
import React, { memo, Component } from 'react';
import { View, TouchableOpacity } from 'react-native';
import { connect } from 'react-redux';
import Feather from 'react-native-vector-icons/Feather';
import firebase from 'firebase/app';
import 'firebase/database';

import { store } from '../../redux/store';
import I18n from '../../i18n';
import * as FirebasePaths from '../../constants/firebasePaths';

import Label from '../../components/label';
import TextInput from '../../components/text-input';

import styles from './styles';
import Teamcore from './teamscore';
import { presentToast } from '../../helpers/toastHelper';
import { perfectSize } from '../../helpers/deviceHelper';

import { set as HubSet } from '../../redux/modules/hub/actions';
import { showInputAlert } from '../../redux/modules/alert/actions';

import {
  handleAndroidBackButton,
  removeAndroidBackButtonHandler,
} from '../../helpers/deviceHelper';
import navigationService from '../../navigation/navigationService';
import { clearFastImageCache } from '../../services/utils';

class About extends Component {
  constructor(props) {
    super(props);
    const params = props.route.params;
    this.state = {
      teamId: params.teamId,
      teamAdmin: '',
      teamDescription: '',
      secondAdminsMap: new Map(),
      description: '',
    };
  }

  componentDidMount() {
    Teamcore.loadTeamInfo(this.state.teamId).then((teamShot) => {
      teamShot.forEach((item) => {
        if (item.key === 'admin') {
          this.setState({ teamAdmin: item.val() });
        }
        if (item.key === 'description') {
          this.setState({ teamDescription: item.val() });
        }
      });
    });
    this.secondAdminsRef$ = firebase.database().ref(
      FirebasePaths.FIREBASE_TEAMS +
      '/' +
      this.state.teamId +
      '/' +
      FirebasePaths.FIREBASE_TEAM_SECOND_ADMINS +
      '/',
    );
    this.secondAdminsRef$.on('value', (shot) => {
      let secondAdminsMap = new Map();
      shot.forEach((item) => {
        secondAdminsMap.set(item.key, item.val());
      });
      this.setState({ secondAdminsMap });
    });
  }

  componentWillMount() {
    handleAndroidBackButton(() => {
      navigationService.goBack();
    });
  }

  componentWillUnmount() {
    if (this.secondAdminsRef$) {
      this.secondAdminsRef$.off();
      this.secondAdminsRef$ = null;
    }
    removeAndroidBackButtonHandler();
    clearFastImageCache();
  }

  onChangeDescription = (description) => {
    this.setState({ description });
  };

  // This function will show the edit the team descriotion alert
  editTeamDescriptionAlert = () => {
    this.props.showInputAlert([
      I18n.t('TEAM_PAGE.edit_team_alert_title'),
      <TextInput
        multiline
        placeholder={I18n.t('TEAM_PAGE.edit_team_alert_input_placeholder')}
        onChangeText={(text) => this.onChangeDescription(text)}
        customStyles={{
          marginTop: perfectSize(32),
          borderBottomColor: 'white',
          borderBottomWidth: 1,
          paddingHorizontal: perfectSize(0),
          borderRadius: 0,
          maxHeight: 120,
          minHeight: 52,
          height: 'auto',
        }}
        inputStyles={{ paddingLeft: perfectSize(4) }}
        placeholderTextColor={'gray'}
      />,
      [
        {
          text: I18n.t('TEAM_PAGE.edit_team_alert_update_cancel'),
          onPress: () => { },
        },
        {
          text: I18n.t('TEAM_PAGE.edit_team_alert_update_btn'),
          onPress: () => {
            this.handleUpdate();
          },
        },
      ],
    ]);
  };

  handleUpdate = () => {
    let success = I18n.t('TEAM_PAGE.edit_team_alert_success_msg');
    let error = I18n.t('TEAM_PAGE.edit_team_alert_error_msg');

    // Validate the text
    let text = this.state.description;
    if (text.toString().length > 3 && text.toString().length <= 600) {
      // Now call the update description back-end and locally
      this.setState({
        teamDescription: text,
      });
      Teamcore.updateTeamDescription(this.state.teamId, text);
      // Success msg
      presentToast({ message: success });
      this.setState({ description: '' });
      // Log Rank HP
      global.rankLogger.LOG_NEW_HP(
        global.rankLogger.UPDATE_TEAM_DESCRIPTION,
        'UPDATE_TEAM_DESCRIPTION',
      );
    } else {
      // error msg
      presentToast({ message: error });
    }
  };

  render() {
    const { theme } = this.props;
    const _styles = styles(theme);
    const { teamAdmin, secondAdminsMap, teamDescription } = this.state;
    const uid = store.getState().authReducer.uid;
    return (
      <View style={_styles.subContent}>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Label size="x_small" bold={true}>
            {I18n.t('TEAM_PAGE.info_description')}
          </Label>
          {(uid === teamAdmin || secondAdminsMap.has(uid)) && (
            <TouchableOpacity
              style={{ marginLeft: perfectSize(8) }}
              onPress={this.editTeamDescriptionAlert}>
              <Feather
                name="edit-2"
                size={perfectSize(15)}
                color={theme.primary_btn_background}
              />
            </TouchableOpacity>
          )}
        </View>
        <Label size="x_small" style={_styles.description}>
          {teamDescription}
        </Label>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
  };
};

export default memo(connect(mapStateToProps, { HubSet, showInputAlert })(About));
