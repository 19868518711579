/* eslint-disable react-native/no-inline-styles */
import React, {Component} from 'react';
import {View, FlatList, TouchableOpacity, Linking} from 'react-native';
import {compose} from 'redux';
import {connect} from 'react-redux';
import firebase from 'firebase/app';
import 'firebase/database';

import I18n from '../../i18n';

import * as FirebasePaths from '../../constants/firebasePaths';

// import components
import Label from '../../components/label';
import Header from '../../components/header';
import MatchCard from '../../components/cards/match-card';
import ImageWithPlaceholder from '../../components/image-with-placeholder';

import NavigationService from '../../navigation/navigationService';
import {set as setHub} from '../../redux/modules/hub/actions';

// import styles
import {perfectSize} from '../../helpers/deviceHelper';
import styles from './styles';
import FriendsListCore from '../friendslist/friendslistcore';
import {presentToast} from '../../helpers/toastHelper';
import {FindMatchCore} from '../find-match/find-match-core';
import Chatcore from '../chat/chatcore';

// import ad service
import AdService from '../../services/adSpot.service';

class FoundMatchPage extends Component {
  constructor(props) {
    super(props);
    const params = props.navigation.state.params;

    let users = Array.from(new Set(params.users.map((a) => a.name)))
      .map((name) => {
        return params.users.find((a) => a.name === name);
      })
      .filter((user) => !this.props.bannedUsers.includes(user.admin));

    this.state = {
      users: users,
      title: params.title,
      AD: null,
    };
  }

  componentDidMount() {
    global.rankLogger.LOG_NEW_HP(
      global.rankLogger.USE_HOPLAY_MATCH,
      'USE_HOPLAY_MATCH',
    );

    global.featuresLogger.LOG_FOUND_HOPLAY_MATCH();
    global.featuresLogger.FOUND_MATCH_AD_PAGE_VIEW();

    // Load the AD
    this.ADRef$ = firebase.database().ref(
      FirebasePaths.FIREBASE_AD_SPOTS +
        '/' +
        FirebasePaths.FIREBASE_AD_SPOTS_FOUND_MATCH,
    );
    this.ADRef$.on('value', (snapshot) => {
      if (snapshot.val() != null) {
        this.setState({
          AD: snapshot.val(),
        });
      } else {
        this.setState({
          AD: 'NONE',
        });
      }
    });
  }

  componentWillUnmount() {
    if (this.ADRef$) {
      this.ADRef$.off();
      this.ADRef$ = null;
    }
  }

  openChat = async (item) => {
    global.rankLogger.LOG_NEW_HP(
      global.rankLogger.CONTACT_MATCH,
      'CONTACT_MATCH',
    );

    let profile = await FindMatchCore.fetchReqAdminData(item.admin);
    if (profile._picUrl_ === 'NULL' || profile._picUrl_ === 'default') {
      profile._picUrl_ =
        'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/teams%20default%20photos%20and%20background%2Fdefault_team_logov2.png?alt=media&token=b88bf926-35b9-4568-a80e-ee24110aceb6';
    }

    let CHAT_STATUS = 'NEW';
    if (this.userHasRefWithFriend(item.admin)) {
      CHAT_STATUS = 'ONGOING';
    } else {
      CHAT_STATUS = 'NEW';
    }

    if (CHAT_STATUS === 'NEW') {
      let message =
        'أهلا 😀، هذي رسالة تلقائية من نظام هوبلاي ماتش ! ادري انك قيمر رهيب مثلي وكلنا نلعب نفس اللعبة ' +
        this.props.gamesList[item.gameId].gameName;

      Chatcore.createNewChatRefWithFirstMessage(
        this.props.uid,
        item.admin,
        message,
        item.name,
        profile._bio_,
        profile._picUrl_,
      );

      CHAT_STATUS = 'ONGOING';
    }

    let {friendsList} = this.props;
    if (friendsList === undefined) {
      friendsList = [];
    }

    global.featuresLogger.LOG_CHAT_HOPLAY_MATCH();

    if (!friendsList.filter((e) => e.UID === item.admin).length > 0) {
      FriendsListCore.addFriend(item.admin);

      presentToast({
        message:
          profile._username_ + I18n.t('FRIEND_LIST_PAGE.added_successfully'),
      });
      NavigationService.moveToScreenInStack('HomeStack', 'PrivateChatPage', {
        TITLE: profile._username_,
        SUBTITLE: profile._bio_,
        CHAT_IMG: profile._picUrl_,
        CHAT_TYPE: 'PRIVATE',
        FRIEND_UID: item.admin,
        CHAT_STATUS: CHAT_STATUS,
      });
    } else {
      NavigationService.moveToScreenInStack('HomeStack', 'PrivateChatPage', {
        TITLE: profile._username_,
        SUBTITLE: profile._bio_,
        CHAT_IMG: profile._picUrl_,
        CHAT_TYPE: 'PRIVATE',
        FRIEND_UID: item.admin,
        CHAT_STATUS: CHAT_STATUS,
      });
    }
  };

  userHasRefWithFriend = (opponentId) => {
    let FOUND_RELATED_REF = false;
    Object.values(this.props.allPrivateChatRefs).forEach((data) => {
      if (data.opponentId === opponentId) {
        FOUND_RELATED_REF = true;
        this.props.setHub({
          tempChatRoomRef: data.chatRef,
        });
      }
    });
    return FOUND_RELATED_REF;
  };

  renderMatchCards = ({item, index}) => {
    return (
      <TouchableOpacity
        onPress={() => {
          NavigationService.navigate('ViewfriendprofilePage', {
            FRIEND_KEY: item.admin,
          });
        }}
        style={[
          {width: '50%', marginBottom: perfectSize(16)},
          index % 2 === 0
            ? {paddingRight: perfectSize(8)}
            : {paddingLeft: perfectSize(8)},
        ]}>
        <MatchCard
          key={index}
          userName="Noob1998"
          rating={3}
          buttonText={I18n.t('FOUND_MATCH_PAGE.contact_btn')}
          user={item}
          onPress={() => {
            this.openChat(item);
          }}
        />
      </TouchableOpacity>
    );
  };

  openAD = () => {
    const {AD} = this.state;
    if (AD != null && AD.url != null) {
      global.featuresLogger.FOUND_MATCH_AD_CLICK();
      AdService.checkAdUrl(AD);
    }
  };

  render() {
    const {theme} = this.props;
    const {AD} = this.state;
    return (
      <View
        style={[
          styles.container,
          {backgroundColor: theme.secondary_background},
        ]}>
        <Header text={I18n.t('MAIN_PAGE.found_match')} bold />
        <View
          style={[{backgroundColor: theme.primary_background}, styles.body]}>
          {/* AD */}
          {AD != null && AD !== 'NONE' && (
            <TouchableOpacity
              style={styles.adCard}
              onPress={() => this.openAD()}>
              {AD.img != null && (
                <ImageWithPlaceholder style={styles.adCover} uri={AD.img} />
              )}
            </TouchableOpacity>
          )}
          <Label style={{opacity: 0.56}} size="x_small">
            {I18n.t('FOUND_MATCH_PAGE.found_msg')}
          </Label>
          <Label style={{marginVertical: perfectSize(16)}} bold>
            {this.state.title}
          </Label>
          <FlatList
            style={styles.content}
            data={this.state.users}
            numColumns={2}
            renderItem={(item) => this.renderMatchCards(item)}
            keyExtractor={(item, index) => String(index)}
            columnWrapperStyle={styles.flatList}
            showsVerticalScrollIndicator={false}
          />
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
    allPrivateChatRefs: state.hubReducer.allPrivateChatRefs,
    uid: state.authReducer.uid,
    gamesList: state.hubReducer.gamesList,
    bannedUsers: state.hubReducer.bannedUsers,
  };
};

export default compose(connect(mapStateToProps, {setHub}))(FoundMatchPage);
