import React, { Component } from 'react';
import { View, TouchableOpacity, FlatList, Text } from 'react-native';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Feather from 'react-native-vector-icons/Feather';
import { SwipeRow } from 'react-native-swipe-list-view';
import I18n from '../../i18n';
import NavigationService from '../../navigation/navigationService';
import { useIsFocused } from '@react-navigation/native';
import { withNavigation } from 'react-navigation';

// import components
import Label from '../../components/label';
import ChatItemHolder from '../../components/chat-item-holder';
import ChatItemHolderSkeleton from '../../components/chat-item-holder/chat-item-skeleton';

// import styles
import AppStyles from '../../constants/styles';
import styles from './styles';
import Pipes from '../../services/pipes';
import CommunityCore from './communitycore';
import Mainmenucore from '../mainmenu/mainmenucore';

// import actions
import { switchTheme } from '../../redux/modules/app/actions';
import { set as setHub } from '../../redux/modules/hub/actions';
import { showAlert } from '../../redux/modules/alert/actions';
import { perfectSize } from '../../helpers/deviceHelper';

import { CHAT_LOADING_DARK, CHAT_LOADING } from '../../constants/images';


const limit = 20;

class PrivateChat extends Component {
  constructor(props) {
    super(props);
    this.swipRef = new Array(React.createRef());
    this.state = {
      privateChats: props.communityChatsArr.slice(0, limit),
      privateChatsAll: props.communityChatsArr,
      hasMore: props.communityChatsArr.length > limit,
      isLoading: true,
    };
  }

  componentDidMount() {
    const { navigation } = this.props;
    this.preloadImgs();
    this.focusListener = navigation.addListener('didFocus', () => {
      Mainmenucore.loadPrivateChatsRefs();
    })
  }

  componentWillUnmount() {
    if (Mainmenucore.privateChatsRef$) {
      Mainmenucore.privateChatsRef$.off();
    }
    if (this.focusListener) {
      this.focusListener();
    }
  }

  componentDidUpdate(prevProps) {
    if (!this.props.navigation.isFocused()) {
      this.swipRef.forEach((swipe, _index) => {
        if (swipe && swipe.closeRow) {
          swipe.closeRow();
        }
      });
    }
    if (prevProps.communityChatsArr.length !== this.props.communityChatsArr.length) {
      if (this.props.communityChatsArr.length >= 0) {
        this.setState({
          privateChats: this.props.communityChatsArr.slice(0, limit),
          privateChatsAll: this.props.communityChatsArr,
          hasMore: this.props.communityChatsArr.length > limit
        })
      }
    }
  }

  preloadImgs = () => {
    const preloadUris = [];
    const preloadChats = this.props.communityChatsArr.slice(0, 10);
    preloadChats.map(chat => {
      if (chat.opponentPic) {
        return preloadUris.push({ uri: chat.opponentPic });
      }
    })
    setTimeout(() => {
      this.setState({
        isLoading: false,
      })
    }, 1000);
  }

  // This function will open a chat when user click on chat holder : EVENT | TRIGGER
  toChatPage = (
    friendUID,
    friendUsername,
    friendBio,
    friendPhotoUrl,
    chatRef,
    CHAT_TYPE,
  ) => {
    // Disable chat local notifications when open chat
    this.props.setHub({
      IS_IN_CHAT: true,
      tempChatRoomRef: chatRef,
    });

    // Here the chat status is  always ongoing
    let CHAT_STATUS = 'ONGOING';

    // Change the chat opened attr to true in front-end and back-end
    // In map and in arr
    // In map
    // let allPrivateChatRefs = store.getState().hubReducer.allPrivateChatRefs;
    // let tmpChatRef = allPrivateChatRefs.get(chatRef);
    // tmpChatRef.opened = true;
    // allPrivateChatRefs.set(chatRef, tmpChatRef);
    // // In Arr
    // let communityChatsArr = store.getState().hubReducer.communityChatsArr;
    // communityChatsArr.forEach((chat) => {
    //   if (chat.chatRef === chatRef) {
    //     chat.opened = true;
    //   }
    // });
    // this.props.setHub({allPrivateChatRefs, communityChatsArr});
    // In back-end
    CommunityCore.changeOpenedAttr(chatRef, 'PRIVATE');

    if (CHAT_TYPE === 'PRIVATE') {
      NavigationService.moveToScreenInStack('HomeStack', 'PrivateChatPage', {
        TITLE: friendUsername,
        SUBTITLE: friendBio,
        CHAT_IMG: friendPhotoUrl,
        CHAT_TYPE: CHAT_TYPE,
        FRIEND_UID: friendUID,
        CHAT_STATUS: CHAT_STATUS,
      });
    } else {
      NavigationService.moveToScreenInStack('HomeStack', 'ChatPage', {
        TITLE: friendUsername,
        SUBTITLE: friendBio,
        CHAT_IMG: friendPhotoUrl,
        CHAT_TYPE: CHAT_TYPE,
        FRIEND_UID: friendUID,
        CHAT_STATUS: CHAT_STATUS,
      });
    }
  };

  // this function presents an alert dialog to confrim delete chat
  deleteChatAlert = (chat) => {
    let chatSettings = I18n.t('CHAT_PAGE.chat_settings');
    let del = I18n.t('CHAT_PAGE.delete_chat');

    this.props.showAlert([
      chatSettings,
      '',
      [
        {
          text: del,
          onPress: () => {
            // Log Rank Hp
            global.rankLogger.LOG_NEW_HP(
              global.rankLogger.DELETE_PRIVATE_CHAT,
              'DELETE_PRIVATE_CHAT',
            );
            CommunityCore.deletePrivateChat(chat.chatRef);
          },
        },
      ],
    ]);
  };

  // This function will trigger the fab button base on the three section : chats sections
  fabBtnTrigger = () => {
    // Feature log : click on new private chat
    global.featuresLogger.LOG_NEW_PRIVATE_CHAT();
    // Check if this user is banned from chat feature
    CommunityCore.IS_CHAT_BANNED().then((isBanned) => {
      if (isBanned) {
        // Show ban alert
        let banTitle = I18n.t('COMMUNITY_PAGE.ban_alert_title');
        let banMsg = I18n.t('COMMUNITY_PAGE.ban_alert_msg');
        let banBtn = I18n.t('COMMUNITY_PAGE.ban_alert_btn');

        // Show ban alert
        this.props.showAlert([
          banTitle,
          banMsg,
          [
            {
              text: banBtn,
              onPress: () => {
                NavigationService.moveToScreenInStack(
                  'SettingsStack',
                  'SupportPage',
                );
              },
            },
          ],
        ]);
      } else {
        NavigationService.navigate('FriendsListPage', {
          CAME_FROM: 'COMMUNITY',
        });
      }
    });
  };

  // Getting Animated Gif for the right mode
  getGif() {
    if (this.props.theme.mode === 'dark') {
      return CHAT_LOADING_DARK;
    } else {
      return CHAT_LOADING;
    }
  }

  doInfinite = () => {
    if (this.state.privateChats.length === this.state.privateChatsAll.length) {
      this.setState({
        hasMore: false
      })
      return;
    }

    setTimeout(() => {
      let cntToLoadMore = limit;
      if (this.state.privateChatsAll.length - this.state.privateChats.length < limit) {
        cntToLoadMore = this.state.privateChatsAll.length - this.state.privateChats.length;
      }
      let displayPost = this.state.privateChats;
      displayPost = this.state.privateChats.concat(
        this.state.privateChatsAll.slice(displayPost.length, displayPost.length + cntToLoadMore),
      );
      this.setState({
        privateChats: displayPost,
        hasMore: true
      });
    }, 500);
  };

  render() {
    const { privateChats, isLoading } = this.state
    const { uid, blockedList, isFocused } = this.props;
    if (isFocused) {
      return (
        <View style={styles.subContent}>
          {
            isLoading &&
            <FlatList
              data={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9]}
              renderItem={({ item, index }) => {
                return <ChatItemHolderSkeleton />;
              }}
              showsVerticalScrollIndicator={false}
              style={{ width: '100%', }}
              keyExtractor={(item, index) => {
                return index.toString()
              }}
            />
          }
          {!isLoading && privateChats.length > 0 ? (
            <FlatList
              data={privateChats}
              showsVerticalScrollIndicator={false}
              onEndReached={this.doInfinite}
              onEndReachedThreshold={0.5}
              ListFooterComponent={
                this.state.hasMore ?
                  <ChatItemHolderSkeleton /> : <View />
              }
              renderItem={(data, rowMap) => (
                <SwipeRow
                  disableRightSwipe
                  rightOpenValue={-perfectSize(75)}
                  onRowOpen={(position) => {
                    this.swipRef.forEach((swipeRef, _index) => {
                      if (data.index !== _index) {
                        if (swipeRef) {
                          swipeRef.closeRow();
                        }
                      }
                    });
                  }}
                  ref={(ref) => (this.swipRef[data.index] = ref)}>
                  <View style={styles.rowBack}>
                    <TouchableOpacity
                      style={[styles.backRightBtn, styles.backRightBtnRight]}
                      onPress={() => {
                        if (this.swipRef[data.index]) {
                          this.swipRef[data.index].closeRow();
                        }
                        CommunityCore.deletePrivateChat(data.item.chatRef);
                      }}>
                      <Feather name="message-square" size={20} color="white" />
                      <Label>{I18n.t('GAME_LIST_PAGE.delete')}</Label>
                    </TouchableOpacity>
                  </View>
                  <View style={styles.rowFront}>
                    {!blockedList.has(data.item.opponentId) ? (
                      <TouchableOpacity
                        onPress={() => {
                          this.toChatPage(
                            data.item.opponentId,
                            data.item.opponentUsername,
                            data.item.opponentBio,
                            data.item.opponentPic,
                            data.item.chatRef,
                            'PRIVATE',
                          );
                        }}
                        onLongPress={() => {
                          this.swipRef.forEach((swipe, _index) => {
                            if (swipe) {
                              swipe.closeRow();
                            }
                          });
                          this.deleteChatAlert(data.item);
                        }}>
                        <ChatItemHolder
                          avatar={data.item.opponentPic}
                          borderColor={data.item.profileBorderColor}
                          showStatus={true}
                          uid={data.item.opponentId}
                          title={data.item.opponentUsername}
                          timestamp={Pipes.humantime(
                            data.item.lastMessageTimestamp,
                          )}
                          message={data.item.lastMessage}
                          deleted={data.item.deleted}
                          showUnreadBadge={
                            data.item.senderId !== uid &&
                            data.item.opened === false
                          }
                          type={data.item.senderId === this.props.uid ? 0 : 1}
                          index={data.index}
                        />
                      </TouchableOpacity>
                    ) : null}
                  </View>
                </SwipeRow>
              )}
              rightOpenValue={-75}
              keyExtractor={(item, index) => index.toString()}
            />
          ) : (
            !isLoading &&
            <View style={styles.emptyContent}>
              <View style={AppStyles.emptyContent}>
                {/* <FastImage
                source={this.getGif()}
                style={AppStyles.emptyContentGif}
                resizeMode="contain"
              /> */}
                <Label style={AppStyles.emptyContentLabel}>
                  {I18n.t('COMMUNITY_PAGE.no_cahts_message')}
                </Label>
              </View>
            </View>
          )}
          <TouchableOpacity
            onPress={() => this.fabBtnTrigger()}
            style={[AppStyles.fabButton, styles.fabButton]}>
            <Feather name="message-square" size={perfectSize(18)} color="white" />
          </TouchableOpacity>
        </View>
      );
    }
    return <View />;
  }

}

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
    lang: state.appReducer.currentLang,
    uid: state.authReducer.uid,
    communityChatsArr: state.hubReducer.communityChatsArr,
    blockedList: state.hubReducer.blockedList,
  };
};

function focusComponent(props) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const isFocused = useIsFocused();
  return <PrivateChat {...props} isFocused={isFocused} />;
}

export default compose(
  connect(mapStateToProps, { switchTheme, setHub, showAlert }),
)(withNavigation(focusComponent));
