

/* eslint-disable react-native/no-inline-styles */
import React, { memo, Component } from 'react';
import { View, StyleSheet } from 'react-native';
import { connect } from 'react-redux';

import AppStyles from '../../../constants/styles';
import { perfectSize } from '../../../helpers/deviceHelper';
import {
    Placeholder,
    Progressive
} from "rn-placeholder";
import SkeletonUserAvatar from '../../skeletonWrapper/skeletonUserAvatar';
import SkeletonTextContent from '../../skeletonWrapper/skeletonTextContent';


class RequestCardSkeleton extends Component {
    constructor(props) {
        super(props);
        this.mount = true;
        this.state = {};
    }

    componentDidMount() { }

    componentWillUnmount = () => {
        this.mount = false;
    }

    render() {
        const { theme } = this.props;
        const styles = style(theme);

        return (
            <View style={[styles.container, this.props.customStyles]}>
                {/*  */}
                <Placeholder
                    Animation={Progressive}
                >
                    <View
                        style={[
                            {
                                flexDirection: 'row',
                                width: '100%',
                            },
                        ]}>

                        <SkeletonUserAvatar size={32} />
                        <View style={[{ flex: 1, marginLeft: perfectSize(10) }]}>
                            <SkeletonTextContent height={22} style={{ marginTop: 2 }} />
                            <SkeletonTextContent height={22} style={{ marginTop: 8 }} />
                            <SkeletonTextContent height={22} style={{ marginTop: 8 }} />

                            <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginVertical: perfectSize(10) }}>
                                <SkeletonTextContent width={45} height={20} />
                                <SkeletonTextContent width={45} height={20} style={{ marginLeft: 5 }} />
                            </View>

                        </View>
                    </View>
                </Placeholder>

                {/*  */}
            </View>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        theme: state.appReducer.currentTheme,
        lang: state.appReducer.currentLang,
    };
};

export default memo(connect(mapStateToProps)(RequestCardSkeleton));

const style = (theme) =>
    StyleSheet.create({
        fullSize: {
            width: '100%',
            height: '100%',
        },
        container: {
            borderRadius: perfectSize(12),
            width: '100%',
            padding: 10
        },
        infoWrap: {
            paddingTop: perfectSize(8),
            paddingBottom: perfectSize(10),
        },
        userWrap: {
            flex: 1,
            flexDirection: 'row',
            width: '100%',
        },
        imgUser: {
            resizeMode: 'stretch',
            width: perfectSize(32),
            height: perfectSize(32),
        },
        userName: {
            color: theme.primary_text,
        },
        image: {
            resizeMode: 'contain',
            width: perfectSize(16),
            height: perfectSize(19),
        },
        seperator: {
            width: perfectSize(94),
            height: perfectSize(2),
            backgroundColor: theme.request_seperator,
        },
        flexRow: {
            flexDirection: 'row',
        },
        alignCenter: {
            alignItems: 'center',
        },
        point: {
            color: theme.primary_text,
            opacity: 0.56,
            marginBottom: perfectSize(4),
        },
        imgUserGroup: {
            resizeMode: 'contain',
            width: perfectSize(17.11),
            height: perfectSize(14),
        },
        btn_tag: {
            width: perfectSize(40),
            height: perfectSize(20),
            marginRight: perfectSize(4),
            marginTop: perfectSize(5),
        },
        platformContainer: {
            marginTop: perfectSize(8),
        },
    });
