import firebase from 'firebase/app';
import 'firebase/database';

import * as FirebasePaths from '../../constants/firebasePaths';
import {store} from '../../redux/store';

export default class Teamcore {
  constructor() {}

  // Set default status value
  static setDefaultStatusValue(teamId, IS_VERIFIED) {
    let path =
      FirebasePaths.FIREBASE_TEAMS +
      '/' +
      teamId +
      '/' +
      FirebasePaths.FIREBASE_TEAM_INFO;
    // If the clan is verified then it should private otherwise public
    if (IS_VERIFIED) {
      firebase.database().ref(path).child('status').set('PRIVATE');
    } else {
      firebase.database().ref(path).child('status').set('PUBLIC');
    }
  }

  static getTeamInfoRef(teamId) {
    let path =
      FirebasePaths.FIREBASE_TEAMS +
      '/' +
      teamId +
      '/' +
      FirebasePaths.FIREBASE_TEAM_INFO;
    return firebase.database().ref(path);
  }

  // This function will load all the team's info
  static loadTeamInfo(teamId) {
    let path =
      FirebasePaths.FIREBASE_TEAMS +
      '/' +
      teamId +
      '/' +
      FirebasePaths.FIREBASE_TEAM_INFO;
    return firebase.database().ref(path).once('value');
  }

  // This function will load the admin info
  static loadAdmin(adminId) {
    let path =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      adminId +
      '/' +
      FirebasePaths.FIREBASE_DETAILS_ATTR;
    return firebase.database().ref(path).once('value');
  }

  // This function will load the team members
  static loadMembers(teamId) {
    // Get the path
    let path =
      FirebasePaths.FIREBASE_TEAMS +
      '/' +
      teamId +
      '/' +
      FirebasePaths.FIREBASE_TEAM_MEMBERS;
    return firebase.database().ref(path).once('value');
  }

  // This function will load the total members number
  static loadTotalMembersNumber(teamId) {
    // Get the path
    let path =
      FirebasePaths.FIREBASE_TEAMS +
      '/' +
      teamId +
      '/' +
      FirebasePaths.FIREBASE_TEAM_MEMBERS;
    return firebase.database()
      .ref(path)
      .once('value')
      .then((snapshot) => {
        // This will return all the members shot
        let counter = 0;
        snapshot.forEach(() => {
          counter++;
        });
        return counter + 1;
      });
  }

  // This function will kick this member from the team
  static kickMember(teamId, memberId) {
    // Remove member from team members ref
    let memberTeamPath =
      FirebasePaths.FIREBASE_TEAMS +
      '/' +
      teamId +
      '/' +
      FirebasePaths.FIREBASE_TEAM_MEMBERS +
      '/' +
      memberId;
    firebase.database().ref(memberTeamPath).remove();

    // Remove team from user's teams ref
    let userTeamsPath =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      memberId +
      '/' +
      FirebasePaths.FIREBASE_USER_TEAMS +
      '/' +
      FirebasePaths.FIREBASE_USER_TEAMS_REFS +
      '/' +
      teamId;
    firebase.database().ref(userTeamsPath).remove();

    //
    // Notify user
    //   Cretea notification obj
    //       Insert a notification obj to the new admin
    //  Create a push id for the notification text
    let notificationId = firebase.database().ref().push().key;
    // Create a notification Obj
    let notificationObj = {
      id: notificationId,
      status: 'UNREAD',
      body:
        store.getState().authReducer.userinformationDB.username +
        ' طردك من الفريق  💩',
      type: 'TEAM',
      TRIGGER: teamId,
      timeStamp: firebase.database.ServerValue.TIMESTAMP,
    };

    let userNotifications =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      memberId +
      '/' +
      FirebasePaths.FIREBASE_USER_NOTIFICATIONS +
      '/' +
      notificationId;

    firebase.database().ref(userNotifications).set(notificationObj);

    // Remove the clan chat ref
    firebase.database()
      .ref(
        FirebasePaths.FIREBASE_USERS_INFO_ATTR +
          '/' +
          memberId +
          '/' +
          FirebasePaths.FIREBASE_CLANS_CHATS_USER_REF +
          '/' +
          teamId,
      )
      .remove();

    // Notify the team
    let notificationObj2 = {
      id: notificationId,
      status: 'UNREAD',
      body:
        store.getState().authReducer.userinformationDB.username +
        ' انظرد من فريقكم ',
      type: 'TEAM',
      TRIGGER: teamId,
      timeStamp: firebase.database.ServerValue.TIMESTAMP,
    };

    let teamNotificationPath =
      FirebasePaths.FIREBASE_TEAMS +
      '/' +
      teamId +
      '/' +
      FirebasePaths.FIREBASE_TEAM_NOTIFICATIONS +
      '/' +
      notificationId;
    firebase.database().ref(teamNotificationPath).set(notificationObj2);

    // Remove the user clan chat ref for the member
    let userClanChatRef =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      memberId +
      '/' +
      FirebasePaths.FIREBASE_CLANS_CHATS_USER_REF +
      '/' +
      teamId;
    // Remove the ref
    firebase.database().ref(userClanChatRef).remove();
  }

  static getTeamNotificationsRef(teamId) {
    // Get the path
    let path =
      FirebasePaths.FIREBASE_TEAMS +
      '/' +
      teamId +
      '/' +
      FirebasePaths.FIREBASE_TEAM_NOTIFICATIONS;
    return firebase.database().ref(path).limitToLast(100);
  }

  //This function will load the team notifications
  static loadTeamNotifications(teamId) {
    // Get the path
    let path =
      FirebasePaths.FIREBASE_TEAMS +
      '/' +
      teamId +
      '/' +
      FirebasePaths.FIREBASE_TEAM_NOTIFICATIONS;
    return firebase.database().ref(path).limitToLast(100).once('value');
  }

  // Send join team request
  static sendJoinRequest(teamId, adminId, teamLogo, teamName) {
    let path =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      adminId +
      '/' +
      FirebasePaths.FIREBASE_USER_TEAMS +
      '/' +
      FirebasePaths.FIREBASE_USER_TEAMS_JOINS +
      '/' +
      teamId +
      '/' +
      store.getState().authReducer.uid;

    // Create a notification for the admin and the team
    // Create a push id for the notification text
    let notificationId = firebase.database().ref().push().key;
    // Create a notification Obj
    let notificationObj = {
      id: notificationId,
      status: 'UNREAD',
      body:
        store.getState().authReducer.userinformationDB.username +
        ' ارسلك عرض انضمام للفريق',
      type: 'TEAM_JOINS',
      TRIGGER: teamId,
      timeStamp: firebase.database.ServerValue.TIMESTAMP,
      TRIGGER2: teamName,
      TRIGGER3: teamLogo,
    };

    let adminNotificationsPath =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      adminId +
      '/' +
      FirebasePaths.FIREBASE_USER_NOTIFICATIONS +
      '/' +
      notificationId;
    // For the admin
    firebase.database().ref(adminNotificationsPath).set(notificationObj);
    // Now  for the team
    let teamNotificationsPath =
      FirebasePaths.FIREBASE_TEAMS +
      '/' +
      teamId +
      '/' +
      FirebasePaths.FIREBASE_TEAM_NOTIFICATIONS +
      '/' +
      notificationId;
    firebase.database().ref(teamNotificationsPath).set(notificationObj);

    // Create join req obj
    let joinObj = {
      uid: store.getState().authReducer.uid,
      teamId: teamId,
      userPhoto: store.getState().authReducer.userinformationDB.pictureURL,
      username: store.getState().authReducer.userinformationDB.username,
      timestamp: firebase.database.ServerValue.TIMESTAMP,
    };

    // Set this obj
    firebase.database().ref(path).set(joinObj);
  }

  // This function will check if the group has members or not
  static checkHasMembers(teamId) {
    let path =
      FirebasePaths.FIREBASE_TEAMS +
      '/' +
      teamId +
      '/' +
      FirebasePaths.FIREBASE_TEAM_MEMBERS +
      '/';
    return firebase.database()
      .ref(path)
      .once('value')
      .then((snapshot) => {
        if (snapshot.val()) {
          return true;
        }
        return false;
      });
  }

  // This function will load  the team acheievements
  static loadTeamAchievements(teamId) {
    let path =
      FirebasePaths.FIREBASE_TEAMS +
      '/' +
      teamId +
      '/' +
      FirebasePaths.FIREBASE_TEAM_ACHIEVEMENTS +
      '/';
    return firebase.database().ref(path).once('value');
  }

  // This function will load the team awards
  static loadTeamAwards(teamId) {
    let path =
      FirebasePaths.FIREBASE_TEAMS +
      '/' +
      teamId +
      '/' +
      FirebasePaths.FIREBASE_TEAM_AWARDS +
      '/';
    return firebase.database().ref(path).once('value');
  }

  // This function will check if the entered user in the param is an admin or not : it will return the admin key
  // Check if the admin or not in the callback
  static checkIsAdmin(teamId) {
    let path =
      FirebasePaths.FIREBASE_TEAMS +
      '/' +
      teamId +
      '/' +
      FirebasePaths.FIREBASE_TEAM_INFO +
      '/' +
      'admin';
    return firebase.database()
      .ref(path)
      .once('value')
      .then((snapshot) => {
        return snapshot.val();
      });
  }

  // This function will load the team championships
  static loadTeamChampionships(teamId) {
    let path =
      FirebasePaths.FIREBASE_TEAMS +
      '/' +
      teamId +
      '/' +
      FirebasePaths.FIREBASE_TEAM_CHAMPIONSHIPS +
      '/';
    return firebase.database().ref(path).once('value');
  }

  // Load the team description
  static loadTeamDescription(teamId) {
    let path =
      FirebasePaths.FIREBASE_TEAMS +
      '/' +
      teamId +
      '/' +
      FirebasePaths.FIREBASE_TEAM_INFO +
      '/' +
      'description';
    return firebase.database().ref(path).once('value');
  }

  // Update the team description
  static updateTeamDescription(teamId, description) {
    let path =
      FirebasePaths.FIREBASE_TEAMS +
      '/' +
      teamId +
      '/' +
      FirebasePaths.FIREBASE_TEAM_INFO +
      '/';
    firebase.database().ref(path).child('description').set(description);
  }

  // This function will upload a feed to the team
  static uploadFeed(teamId, feedObj) {
    // Get the path
    let path =
      FirebasePaths.FIREBASE_TEAMS +
      '/' +
      teamId +
      '/' +
      FirebasePaths.FIREBASE_TEAM_FEEDS +
      '/' +
      feedObj.id;
    firebase.database().ref(path).set(feedObj);
  }

  // Share notification with all the members
  static feedNotifications(uid, teamName, teamId) {
    let notificationId = firebase.database().ref().push().key;
    // Create a notification Obj
    let notificationObj = {
      id: notificationId,
      status: 'UNREAD',
      body:
        '[' + teamName + ']' + 'رفعوا فعالية/تحديث جديد في الكلان شيك عليه !',
      type: 'CLAN_UPDATE',
      TRIGGER: teamId,
      timeStamp: firebase.database.ServerValue.TIMESTAMP,
    };

    let userNotifications =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      uid +
      '/' +
      FirebasePaths.FIREBASE_USER_NOTIFICATIONS +
      '/' +
      notificationId;

    firebase.database().ref(userNotifications).set(notificationObj);
  }

  // This function will load all the feeds
  static loadTeamFeeds(teamId) {
    let path =
      FirebasePaths.FIREBASE_TEAMS +
      '/' +
      teamId +
      '/' +
      FirebasePaths.FIREBASE_TEAM_FEEDS +
      '/';
    return firebase.database().ref(path).limitToLast(100).once('value');
  }

  // This funtion will check if this user is banned of creating clans
  static IS_CREATE_CLAN_BANNED() {
    const uid = store.getState().authReducer.uid;
    let path =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      uid +
      '/' +
      FirebasePaths.FIREBASE_USER_BAN +
      '/' +
      FirebasePaths.FIREBASE_USER_BAN_CREATE_CLAN;
    return firebase.database()
      .ref(path)
      .once('value')
      .then((snapshot) => {
        if (snapshot.val()) {
          return true;
        } else {
          return false;
        }
      });
  }

  // This funtion will check if this user is banned of joinging clans
  static IS_JOIN_CLAN_BANNED() {
    let path =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      store.getState().authReducer.uid +
      '/' +
      FirebasePaths.FIREBASE_USER_BAN +
      '/' +
      FirebasePaths.FIREBASE_USER_BAN_JOIN_CLAN;
    return firebase.database()
      .ref(path)
      .once('value')
      .then((snapshot) => {
        if (snapshot.val()) {
          return true;
        }
        return false;
      });
  }

  // This function will promote the member to admin role
  static promoteToAdminRole(teamId, teamName, member) {
    // Get the path
    let path =
      FirebasePaths.FIREBASE_TEAMS +
      '/' +
      teamId +
      '/' +
      FirebasePaths.FIREBASE_TEAM_SECOND_ADMINS;
    firebase.database().ref(path).child(member.uid).set(member.username);

    let notificationId = firebase.database().ref().push().key;
    // Create a notification Obj
    let notificationObj = {
      id: notificationId,
      status: 'UNREAD',
      body: '[' + teamName + ']' + ' ' + 'تم ترقيتك الى مرتبة مشرف',
      type: 'TEAM',
      TRIGGER: teamId,
      timeStamp: firebase.database.ServerValue.TIMESTAMP,
    };

    let userNotifications =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      member.uid +
      '/' +
      FirebasePaths.FIREBASE_USER_NOTIFICATIONS +
      '/' +
      notificationId;

    firebase.database().ref(userNotifications).set(notificationObj);
  }

  // This function will remove promote the member to admin role
  static removeAdminRole(teamId, teamName, member) {
    // Get the path
    let path =
      FirebasePaths.FIREBASE_TEAMS +
      '/' +
      teamId +
      '/' +
      FirebasePaths.FIREBASE_TEAM_SECOND_ADMINS +
      '/' +
      member.uid;
    firebase.database().ref(path).remove();

    let notificationId = firebase.database().ref().push().key;
    // Create a notification Obj
    let notificationObj = {
      id: notificationId,
      status: 'UNREAD',
      body: '[' + teamName + ']' + ' ' + 'تم ازالتك من مرتبة مشرف 💩',
      type: 'TEAM',
      TRIGGER: teamId,
      timeStamp: firebase.database.ServerValue.TIMESTAMP,
    };

    let userNotifications =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      member.uid +
      '/' +
      FirebasePaths.FIREBASE_USER_NOTIFICATIONS +
      '/' +
      notificationId;

    firebase.database().ref(userNotifications).set(notificationObj);
  }

  // This function will load all the feeds
  static loadSecondAdmins(teamId) {
    let path =
      FirebasePaths.FIREBASE_TEAMS +
      '/' +
      teamId +
      '/' +
      FirebasePaths.FIREBASE_TEAM_SECOND_ADMINS +
      '/';
    return firebase.database().ref(path).once('value');
  }
}
