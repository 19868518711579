import {handleActions} from 'redux-actions';
import _ from 'lodash';

import {
  set,
  addGameSuccess,
  addAllGameSuccess,
  deleteGameSuccess,
  clear as clearSuccess,
  logout,
} from './actions';

export const defaultState = {
  addAllGameFinished: false,
  allGamesIds: [],
  allGamesName: [],
  gamesCompList: [],
  gamesCOOPList: [],
  pcGamesWithProviders: new Map(),
};

export default handleActions(
  {
    [set]: (state, {payload}) => ({
      ...state,
      ...payload,
    }),
    [addGameSuccess]: (state, {payload}) => ({
      ...state,
      allGamesIds: _.cloneDeep(payload.allGamesIds),
      allGamesName: _.cloneDeep(payload.allGamesName),
      gamesCompList: _.cloneDeep(payload.gamesCompList),
      gamesCOOPList: _.cloneDeep(payload.gamesCOOPList),
    }),
    [addAllGameSuccess]: (state, {payload}) => ({
      ...state,
      addAllGameFinished: true,
      allGamesIds: _.cloneDeep(payload.allGamesIds),
      allGamesName: _.cloneDeep(payload.allGamesName),
      gamesCompList: _.cloneDeep(payload.gamesCompList),
      gamesCOOPList: _.cloneDeep(payload.gamesCOOPList),
    }),
    [deleteGameSuccess]: (state, {payload}) => ({
      ...state,
      ...payload,
    }),
    [clearSuccess]: (state, action) => ({
      ...state,
      gamesCOOPList: [], // state.gamesCOOPList.clear(),
      gamesCompList: [], //state.gamesCompList.clear(),
    }),
    [logout]: (state, {payload}) => ({
      ...defaultState,
    }),
  },
  defaultState,
);
