import firebase from 'firebase/app';
import 'firebase/database';

import * as FirebasePaths from '../../constants/firebasePaths';
import {store} from '../../redux/store';


export default class Postcore {
  static loadReplies(postId) {
    let ref =
      FirebasePaths.FIREBASE_TIMELINE_REPLIES +
      '/' +
      postId +
      '/' +
      FirebasePaths.FIREBASE_TIMELINE_REPLIES_REPLY;

    return firebase.database().ref(ref);
  }

  static getUserStatus(uid) {
    let userStatusRef =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      uid +
      '/' +
      FirebasePaths.FIREBASE_DETAILS_ATTR +
      '/';
    return firebase.database()
      .ref(userStatusRef)
      .once('value')
      .then((snapshot) => {
        let status = 'online';
        if (snapshot.val()[FirebasePaths.FIREBASE_STATUS_ATTR]) {
          status = snapshot.val()[FirebasePaths.FIREBASE_STATUS_ATTR];
        }
        return status;
      });
  }

  static getHopsIcon(uid) {
    let userStatusRef =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      uid +
      '/' +
      FirebasePaths.FIREBASE_DETAILS_ATTR +
      '/';
    return firebase.database()
      .ref(userStatusRef)
      .once('value')
      .then((snapshot) => {
        let hopsIcon = '';
        if (snapshot.val()[FirebasePaths.FIREBASAE_USER_HOPS_ICON]) {
          hopsIcon = snapshot.val()[FirebasePaths.FIREBASAE_USER_HOPS_ICON];
        }
        return hopsIcon;
      });
  }

  static getUserRank(uid) {
    let userStatusRef =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      uid +
      '/' +
      FirebasePaths.FIREBASE_DETAILS_ATTR +
      '/';
    return firebase.database()
      .ref(userStatusRef)
      .once('value')
      .then((snapshot) => {
        let rank = 0;
        if (snapshot.val()[FirebasePaths.FIREBASAE_USER_RANK_ATTR]) {
          rank = snapshot.val()[FirebasePaths.FIREBASAE_USER_RANK_ATTR];
        }
        return rank;
      });
  }

  static getUserBorderColor(uid) {
    let userStatusRef =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      uid +
      '/' +
      FirebasePaths.FIREBASE_COSMETICS_ATTR +
      '/' +
      FirebasePaths.FIREBASE_PROFILE_BORDER_ATTR;
    return firebase.database()
      .ref(userStatusRef)
      .once('value')
      .then((snapshot) => {
        let borderColor = '#880e4a';
        if (
          snapshot.val() != null &&
          snapshot.val()[FirebasePaths.FIREBASE_COLOR_ATTR]
        ) {
          borderColor = snapshot.val()[FirebasePaths.FIREBASE_COLOR_ATTR];
        }
        return borderColor;
      });
  }

  static getUserAvatar(uid) {
    let ref =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      uid +
      '/' +
      FirebasePaths.FIREBASE_DETAILS_ATTR;
    return firebase.database()
      .ref(ref)
      .once('value')
      .then((snapshot) => {
        let profile = snapshot.val();
        if (
          !profile._picUrl_ ||
          profile._picUrl_ == 'NULL' ||
          profile._picUrl_ == 'default'
        ) {
          profile._picUrl_ =
            'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/teams%20default%20photos%20and%20background%2Fdefault_team_logov2.png?alt=media&token=b88bf926-35b9-4568-a80e-ee24110aceb6';
        }

        return profile._picUrl_;
      });
  }

  static uploadReply(reply, postId, adminId, adminUsername, repliesNumber) {
    let ref = FirebasePaths.FIREBASE_TIMELINE_REPLIES + '/' + postId;

    firebase.database()
      .ref(
        ref +
          '/' +
          FirebasePaths.FIREBASE_TIMELINE_REPLIES_REPLY +
          '/' +
          reply.id,
      )
      .set(reply);
    firebase.database()
      .ref(ref + '/' + FirebasePaths.FIREBASE_TIMELINE_REPLIES_ADMIN_UID)
      .set(adminId);
    firebase.database()
      .ref(ref + '/' + FirebasePaths.FIREBASE_TIMELINE_REPLIES_ADMIN_USERNAME)
      .set(adminUsername);

    if (adminId != store.getState().authReducer.uid) {
      Postcore.uploadReplyToSender(reply, postId, adminId);
      Postcore.uploadReplyToCurrentUser(reply, postId, adminId);
    }

    firebase.database()
      .ref(FirebasePaths.FIREBASE_TIMELINE + '/' + postId + '/repliesNumber')
      .set(repliesNumber);
  }

  // This function will upload reply to the sender : The sender wants to know all replies

  static uploadReplyToSender(reply, postId, adminId) {
    // Upload to the sender

    let senderRef =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      adminId +
      '/' +
      FirebasePaths.FIREBASE_USER_TIMELINE_REPLIES_ATTR;

    // Set data

    // Set the admin info

    firebase.database()
      .ref(
        senderRef +
          '/' +
          FirebasePaths.FIREBASE_USER_POST_REPLIES_FROM_OTHERS_ATTR +
          '/' +
          postId +
          '/' +
          reply.id,
      )
      .set(reply);

    // Send a notification for the user

    let notificationId = firebase.database().ref().push().key;

    // Create a notification Obj

    let notificationObj = {
      id: notificationId,
      status: 'UNREAD',
      body:
        store.getState().authReducer.userinformationDB.username +
        ' ' +
        ' ارسل لك رد على هوبتك ',

      type: 'HOP',
      TRIGGER: postId,
      timeStamp: firebase.database.ServerValue.TIMESTAMP,
    };

    let adminNotificationsPath =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      adminId +
      '/' +
      FirebasePaths.FIREBASE_USER_NOTIFICATIONS +
      '/' +
      notificationId;

    // For the admin

    firebase.database().ref(adminNotificationsPath).set(notificationObj);
  }

  // This function will upload reply to the current user : this current user only wants to get a reply (access) for that specific hopo

  static uploadReplyToCurrentUser(reply, postId, adminId) {
    let currentUserRef =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      store.getState().authReducer.uid +
      '/' +
      FirebasePaths.FIREBASE_USER_TIMELINE_REPLIES_ATTR;

    // Set data

    firebase.database()
      .ref(
        currentUserRef +
          '/' +
          FirebasePaths.FIREBASE_USER_POST_REPLIES_TO_OTHERS_ATTR +
          '/' +
          postId,
      )
      .set(reply);
  }

  static thumbUpReply(reply) {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;

    firebase.database()
      .ref(
        FirebasePaths.FIREBASE_TIMELINE_REPLIES +
          '/' +
          reply.postId +
          '/' +
          FirebasePaths.FIREBASE_TIMELINE_REPLIES_REPLY +
          '/' +
          reply.id +
          '/' +
          FirebasePaths.FIREBASE_REPLIES_LIKES_ATTR +
          '/' +
          reply.id +
          '/' +
          FirebasePaths.FIREBASE_LIKES_ATTR +
          '/' +
          uid,
      )
      .set(username);
    firebase.database()
      .ref(
        FirebasePaths.FIREBASE_TIMELINE_REPLIES +
          '/' +
          reply.postId +
          '/' +
          FirebasePaths.FIREBASE_TIMELINE_REPLIES_REPLY +
          '/' +
          reply.id +
          '/' +
          FirebasePaths.FIREBASE_REPLIES_LIKES_ATTR +
          '/' +
          reply.id +
          '/' +
          FirebasePaths.FIREBASE_DISLIKES_ATTR +
          '/' +
          uid,
      )
      .remove();
    firebase.database()
      .ref(
        FirebasePaths.FIREBASE_USERS_INFO_ATTR +
          '/' +
          reply.uid +
          '/' +
          FirebasePaths.FIREBASE_HOPS_LIKES_ATTR +
          '/' +
          reply.id +
          '/' +
          FirebasePaths.FIREBASE_LIKES_ATTR +
          '/' +
          uid,
      )
      .set(username);
    firebase.database()
      .ref(
        FirebasePaths.FIREBASE_USERS_INFO_ATTR +
          '/' +
          reply.uid +
          '/' +
          FirebasePaths.FIREBASE_HOPS_LIKES_ATTR +
          '/' +
          reply.id +
          '/' +
          FirebasePaths.FIREBASE_DISLIKES_ATTR +
          '/' +
          uid,
      )
      .remove();
    global.featuresLogger.REPLY_LIKE();
  }

  static thumbDownReply(reply) {
    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;

    firebase.database()
      .ref(
        FirebasePaths.FIREBASE_TIMELINE_REPLIES +
          '/' +
          reply.postId +
          '/' +
          FirebasePaths.FIREBASE_TIMELINE_REPLIES_REPLY +
          '/' +
          reply.id +
          '/' +
          FirebasePaths.FIREBASE_REPLIES_LIKES_ATTR +
          '/' +
          reply.id +
          '/' +
          FirebasePaths.FIREBASE_DISLIKES_ATTR +
          '/' +
          uid,
      )
      .set(username);
    firebase.database()
      .ref(
        FirebasePaths.FIREBASE_TIMELINE_REPLIES +
          '/' +
          reply.postId +
          '/' +
          FirebasePaths.FIREBASE_TIMELINE_REPLIES_REPLY +
          '/' +
          reply.id +
          '/' +
          FirebasePaths.FIREBASE_REPLIES_LIKES_ATTR +
          '/' +
          reply.id +
          '/' +
          FirebasePaths.FIREBASE_LIKES_ATTR +
          '/' +
          uid,
      )
      .remove();
    firebase.database()
      .ref(
        FirebasePaths.FIREBASE_USERS_INFO_ATTR +
          '/' +
          reply.uid +
          '/' +
          FirebasePaths.FIREBASE_HOPS_LIKES_ATTR +
          '/' +
          reply.id +
          '/' +
          FirebasePaths.FIREBASE_LIKES_ATTR +
          '/' +
          uid,
      )
      .remove();

    firebase.database()
      .ref(
        FirebasePaths.FIREBASE_USERS_INFO_ATTR +
          '/' +
          reply.uid +
          '/' +
          FirebasePaths.FIREBASE_HOPS_LIKES_ATTR +
          '/' +
          reply.id +
          '/' +
          FirebasePaths.FIREBASE_DISLIKES_ATTR +
          '/' +
          uid,
      )
      .set(username);
    global.featuresLogger.REPLY_DISLIKE();
  }

  static loadHop(postId) {
    // Create the ref

    let ref = FirebasePaths.FIREBASE_TIMELINE + '/' + postId + '/';

    return firebase.database().ref(ref).once('value');
  }
}
