import React, { memo, Component } from 'react';
import { View, StyleSheet } from 'react-native';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';


import Label from '../../label';
import { perfectSize } from '../../../helpers/deviceHelper';
import ImageWithPlaceholder from '../../image-with-placeholder';
import { LinearGradient } from 'expo-linear-gradient';

class ProfileAccountCard extends Component {
  render() {
    const { theme, name, icon, bgColor, index } = this.props;
    const _styles = styles(theme);
    if (index === 4) {
      return (
        <LinearGradient
          colors={['#0B1C30', '#14659A']}
          style={[_styles.container, this.props.customStyles]}>
          <ImageWithPlaceholder
            uri={icon}
            style={_styles.icon}
            resizeMethod={'resize'}
            resizeMode="contain"
          />
          <Label size="x_small" style={_styles.name}>
            {name}
          </Label>
        </LinearGradient>
      );
    } else {
      return (
        <View
          style={[
            _styles.container,
            { backgroundColor: bgColor },
            this.props.customStyles,
          ]}>
          <ImageWithPlaceholder
            uri={icon}
            style={_styles.icon}
            resizeMethod={'resize'}
            resizeMode="contain"
          />
          <Label size="x_small" style={_styles.name} selectable={true}>
            {name}
          </Label>
        </View>
      );
    }
  }
}

ProfileAccountCard.propTypes = {
  name: PropTypes.string,
  onPress: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
  };
};

export default memo(connect(mapStateToProps)(ProfileAccountCard));

const styles = (theme) =>
  StyleSheet.create({
    container: {
      borderRadius: perfectSize(8),
      paddingVertical: perfectSize(20),
      justifyContent: 'center',
      alignItems: 'center',
      overflow: 'hidden',
    },
    icon: {
      resizeMode: 'contain',
      width: perfectSize(64),
      height: perfectSize(50),
    },
    name: {
      marginTop: perfectSize(8),
    },
  });
