/* eslint-disable react-native/no-inline-styles */
import React, { Component } from 'react';
import { View, TouchableOpacity, StyleSheet } from 'react-native';
import Modal from 'react-native-modal';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Feather from 'react-native-vector-icons/Feather';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';
import ImageViewer from 'react-native-image-zoom-viewer';

import I18n from '../../i18n';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

import TextInput from '../text-input';

import AppStyles from '../../constants/styles';
import Dimens from '../../constants/dimens';
import { perfectSize } from '../../helpers/deviceHelper';
import ImageWithPlaceholder from '../image-with-placeholder';

class SendImageModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      caption: '',
    };
  }

  render() {
    const { isVisible, photo, onCancel, onSend } = this.props;
    return (
      <Modal
        isVisible={isVisible}
        onBackdropPress={() => {
          this.setState({ caption: '' });
          onCancel();
        }}
        coverScreen={true}
        animationIn={'slideInRight'}
        animationOut={'slideOutRight'}
        style={{ margin: 0 }}
        backdropOpacity={0}>
        {/* Image */}
        <ImageViewer
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
          }}
          imageUrls={[photo]}
          renderImage={(props) => {
            return <ImageWithPlaceholder
              {...props}
              style={{ width: '100%', height: '100%' }}
              uri={props.source.uri}
            />
          }}
          renderIndicator={(currentIndex) => null}
          renderHeader={() => null}
        />
        {/* Back button */}
        <View style={styles.header}>
          <TouchableOpacity
            style={AppStyles.leftBarButton}
            onPress={() => {
              this.setState({ caption: '' });
              onCancel();
            }}>
            <Feather
              name="chevron-left"
              color="white"
              size={perfectSize(22.5)}
            />
          </TouchableOpacity>
        </View>
        <KeyboardAwareScrollView
          scrollEnabled={false}
          contentContainerStyle={AppStyles.fullFlex}
          keyboardShouldPersistTaps="always">
          {/* Input box */}
          <View style={styles.footer}>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <TextInput
                placeholder={I18n.t('CHAT_PAGE.add_caption')}
                customStyles={styles.textInput}
                inputStyles={{ opacity: 1.0 }}
                autoFocus
                placeholderTextColor="#ffffff80"
                onChangeText={(value) =>
                  this.setState({
                    caption: value,
                  })
                }
              />
              <TouchableOpacity
                style={styles.sendButton}
                onPress={() => {
                  this.setState({ caption: '' });
                  onSend(this.state.caption.trim());
                }}>
                <MaterialIcons
                  name="send"
                  color="white"
                  size={perfectSize(22)}
                  style={{
                    marginLeft: perfectSize(4),
                  }}
                />
              </TouchableOpacity>
            </View>
          </View>
        </KeyboardAwareScrollView>
      </Modal>
    );
  }
}

SendImageModal.defaultProps = {
  isVisible: false,
  onCancel: () => { },
  onSend: () => { },
};

SendImageModal.propTypes = {
  isVisible: PropTypes.bool,
  photo: PropTypes.string,
  onCancel: PropTypes.func,
  onSend: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
  };
};

export default compose(connect(mapStateToProps, {}))(SendImageModal);

const styles = StyleSheet.create({
  header: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 2,
    height: Dimens.HEADER_HEIGHT,
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  footer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    zIndex: 2,
    width: '100%',
    justifyContent: 'center',
    marginBottom: perfectSize(32),
    paddingHorizontal: perfectSize(12),
  },
  textInput: {
    flex: 1,
    backgroundColor: 'transparent',
    borderBottomColor: 'gray',
    borderBottomWidth: 2,
    paddingLeft: perfectSize(8),
  },
  sendButton: {
    width: perfectSize(48),
    height: perfectSize(48),
    borderRadius: perfectSize(24),
    marginLeft: perfectSize(8),
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#c70064',
  },
});
