import {StyleSheet, Dimensions} from 'react-native';
import {perfectSize} from '../../helpers/deviceHelper';

const styles = (theme) =>
  StyleSheet.create({
    container: {
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      height: 52,
      backgroundColor: theme.secondary_btn_background,
      paddingLeft: perfectSize(16),
      paddingRight: perfectSize(12),
      borderRadius: perfectSize(12),
    },
    flexRow: {
      flex: 1,
      flexDirection: 'row',
    },
    alignCenter: {
      alignItems: 'center',
    },
    input: {
      color: theme.primary_text,
      paddingLeft: perfectSize(8),
      flex: 1,
      opacity: 0.56,
    },
    arrowDown: {
      width: perfectSize(12),
      height: perfectSize(12),
      resizeMode: 'contain',
    },
    dialogContainer: {
      backgroundColor: theme.primary_background,
      width: '100%',
      height: '100%',
    },
    searchableDialogContainer: {
      width: '100%',
      height: '100%',
    },
    dialogBottomBorder: {
      borderWidth: 1,
      borderBottomColor: 'gray',
    },
    dialogSectionContainer: {
      padding: perfectSize(16),
      alignItems: 'flex-start',
    },
    searchableDialogSectionContainer: {
      height: perfectSize(52),
      flexDirection: 'row',
      alignItems: 'center',
      backgroundColor: theme.secondary_btn_background,
      paddingHorizontal: perfectSize(16),
    },
    searchText: {
      marginHorizontal: perfectSize(12),
      opacity: 0.56,
      color: 'white',
      fontSize: perfectSize(15),
    },
    dialogLabel: {
      color: 'white',
    },
    dialogLabelSearchable: {
      color: 'white',
      textAlign: 'center',
    },
    buttonContainer: {
      flexDirection: 'row',
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
      height: perfectSize(48),
      marginVertical: perfectSize(16),
    },
    button: {
      width: perfectSize(80),
      height: perfectSize(48),
      alignItems: 'center',
      justifyContent: 'center',
      borderColor: theme.primary_btn_background,
      borderWidth: 1,
      borderRadius: perfectSize(12),
      marginHorizontal: perfectSize(4),
    },
    buttonColor: {
      color: theme.primary_btn_background,
    },
    label: {
      marginHorizontal: perfectSize(8),
    },
    optionContainer: {
      maxHeight: Dimensions.get('window').height * 0.7,
    },
    searchContainer: {
      height: perfectSize(52),
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      paddingHorizontal: perfectSize(16),
      marginTop: perfectSize(24),
      marginBottom: perfectSize(32),
    },
    search: {
      // padding: perfectSize(8),
      height: '100%',
      alignItems: 'center',
      backgroundColor: theme.secondary_btn_background,
      width: '100%',
      flexDirection: 'row',
      borderRadius: perfectSize(16),
      justifyContent: 'space-between',
    },
    flexFull: {
      flex: 1,
    },
    shadow: {
      width: '100%',
      height: perfectSize(32),
      position: 'absolute',
    },
    noItemWrap: {
      padding: perfectSize(16),
    },
    noItem: {
      color: '#616161',
    },
  });

export default styles;
