import React from 'react';
import { createAppContainer } from 'react-navigation';
import { createStackNavigator } from 'react-navigation-stack';
import { createNativeStackNavigator } from '@react-navigation/native-stack';

import AuthStack from './stacks/authStack';
import TabStack from './stacks/drawerStack';
import HomeStack from './stacks/homeStack';
import SettingsStack from './stacks/settingsStack';
import ProfileStack from './stacks/profileStack';
import LeaderboardStack from './stacks/leaderboardStack';

import ViewfriendprofilePage from '../pages/viewfriendprofile';
import FriendsListPage from '../pages/friendslist';
import FriendsSearchPage from '../pages/friendslist/search';
import UserprofilePage from '../pages/userprofile';
import PhotoViewerPage from '../pages/photo-viewer';
import navigationService from './navigationService';

// deeplink pages
import PostPage from '../pages/post';
import LobbyPage from '../pages/lobby';
import NewsPage from '../pages/news';
import TeamPage from '../pages/team';
import { NavigationContainer } from '@react-navigation/native';

const Stack = createNativeStackNavigator();

const MainNavigator = createStackNavigator(
  {
    AuthStack,
    TabStack,
    HomeStack,
    ProfileStack,
    SettingsStack,
    LeaderboardStack,
    ViewfriendprofilePage: {
      screen: ViewfriendprofilePage,
      path: 'userprofile/:FRIEND_KEY',
    },
    PostPage: {
      screen: PostPage,
      path: 'post/:POST_ID',
    },
    LobbyPage: {
      screen: LobbyPage,
      path: 'requests/:requestId/:platform/:gameId/:region',
    },
    NewsPage: {
      screen: NewsPage,
      path: 'news',
    },
    TeamPage: {
      screen: TeamPage,
      path: 'team/:TEAM_ID',
    },
    FriendsListPage,
    UserprofilePage,
    FriendsSearchPage,
    PhotoViewerPage,
  },
  {
    initialRouteName: 'AuthStack',
    headerMode: 'none',
    navigationOptions: { header: null },
    gestureEnabled: false,
  },
);
const AppContainer = createAppContainer(MainNavigator);

export default () => {
  const prefix = ['hoplayonline://', 'https://hoplay.online'];
  const handleSetNavigationReference = (navigatorRef) => {
    if (navigatorRef !== null) {
      navigationService.setTopLevelNavigator(navigatorRef);
    }
  };
  return (
    <AppContainer
      uriPrefix={prefix}
      enableURLHandling={true}
      ref={(nav) => {
        handleSetNavigationReference(nav);
      }}
    />
  );
};
