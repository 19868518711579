import React, {Component} from 'react';
import {View, TouchableOpacity, ScrollView} from 'react-native';
import {compose} from 'redux';
import {connect} from 'react-redux';

import I18n from '../../i18n';

// import components
import Header from '../../components/header';
import CreateRequest from '../createrequest';
import Label from '../../components/label';

// import styles
import AppStyles from '../../constants/styles';
import styles from './styles';
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view';

class EditRequestPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: 0,
      savedReqs: [],
    };
  }

  componentDidMount() {}

  save = () => {
    this.createRequestCompoent.updateRequestTrigger();
  };

  render() {
    const {navigation} = this.props;
    const {
      state: {params},
    } = navigation;
    return (
      <View style={AppStyles.rootContainer}>
        <Header
          text={I18n.t('EDIT_REQUEST_PAGE.title')}
          rightComponent={
            <View style={styles.headerRight}>
              <TouchableOpacity
                style={styles.actionBtn}
                onPress={() => this.save()}>
                <Label size="x_small" style={styles.buttonTitle}>
                  {I18n.t('CREATE_REQUEST_PAGE.save_short')}
                </Label>
              </TouchableOpacity>
            </View>
          }
        />
        <View style={AppStyles.rootContent}>
          <KeyboardAwareScrollView showsVerticalScrollIndicator={false}>
            <CreateRequest
              game={params && params.game}
              request={this.props.navigation.state.params.request}
              onRef={(instance) => {
                this.createRequestCompoent = instance;
              }}
            />
          </KeyboardAwareScrollView>
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
    lang: state.appReducer.currentLang,
  };
};

export default compose(connect(mapStateToProps, {}))(EditRequestPage);
