/* eslint-disable react-native/no-inline-styles */
import React, { Component } from 'react';
import { View, TouchableOpacity, ScrollView } from 'react-native';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Entypo from 'react-native-vector-icons/Entypo';
import Feather from 'react-native-vector-icons/Feather';

import I18n from '../../i18n';
import NavigationService from '../../navigation/navigationService';
import GameManager from '../../services/gameManager';
import Gamelistcore from '../games-list/gamelistcore';
import * as FirebasePaths from '../../constants/firebasePaths';

// import components
import Header from '../../components/header';
import Label from '../../components/label';
import Picker from '../../components/picker';
import PrimaryButton from '../../components/buttons/primary-button';

import Requestmodel from '../../models/requestmodel';
import Searchrequestcore from './searchrequestcore';

// import styles
import { perfectSize } from '../../helpers/deviceHelper';
import AppStyles from '../../constants/styles';
import styles from './styles';

// import images
import * as Images from '../../constants/images';
import { presentToast } from '../../helpers/toastHelper';

import { store } from '../../redux/store';
import { set as HubSet } from '../../redux/modules/hub/actions';
import LocalImageOpacity from '../../components/local-image-opacity';
import ImageWithPlaceholder from '../../components/image-with-placeholder';

class SearchrequestPage extends Component {
  platforms = ['PS', 'MOBILE', 'NINTENDO', 'PC', 'XBOX'];
  MATCH_TYPES = [
    {
      value: 'All Matches',
      title: I18n.t('CREATE_REQUEST_PAGE.all_matches'),
    },
    {
      value: 'Competitive',
      title: 'Competitive',
    },
    {
      value: 'Quick Match',
      title: 'Quick Match',
    },
  ];

  REQUEST_TIMES = [
    {
      value: 'URGENT',
      title: I18n.t('CREATE_REQUEST_PAGE.request_time_now'),
    },
    {
      value: 'DAY',
      title: I18n.t('CREATE_REQUEST_PAGE.request_time_day'),
    },
    {
      value: 'ALL',
      title: I18n.t('CREATE_REQUEST_PAGE.request_time_all'),
    },
  ];

  constructor(props) {
    super(props);

    this.state = {
      game: null,
      platform: '',
      selectedMatchType: '',
      selectedRegion: '',
      playersNumber: '',
      selectedRank: '',
    };

    global.rankLogger.LOG_NEW_HP(
      global.rankLogger.VIEW_SEARCH_PAGE,
      'VIEW_SEARCH_PAGE',
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.navigation.state.params) {
      if (
        prevState.game !== this.props.navigation.state.params.game &&
        this.props.navigation.state.params.game != undefined
      ) {
        this.setState(
          {
            game: this.props.navigation.state.params.game,
          },
          () => {
            this.onGameSelected();
          },
        );
      }
    }
  }

  componentDidMount() {
    let allRegionsArray = this.props.regionsList;
    allRegionsArray = allRegionsArray.map((region) => {
      return {
        value: region,
        title: region,
      };
    });

    this.setState({
      allRegionsArray: allRegionsArray,
      selectedRegion: '',
    });
  }

  onGameSelected = () => {
    Gamelistcore.addGame(this.state.game);
    if (!GameManager.checkIfHasGameById(this.state.game.gameID)) {
      Gamelistcore.addGame(this.state.game);
    }

    this.setState({
      IS_COMP_GAME: this.checkIfComp(this.state.game.gameName),
    });

    this.generatePlayersNumber(this.state.game.gameName);

    if (this.state.IS_COMP_MATCH) {
      let ranksArray = [];
      // Now insert ranks

      let _game = this.props.gameNames.filter(
        (e) => e.gameName === this.state.game.gameName,
      );
      if (_game.length > 0) {
        _game = _game[0];
        ranksArray = Array.from(_game.ranksList.values());
      }

      ranksArray = ranksArray.map((rank) => {
        return { value: rank, title: rank };
      });
      ranksArray.unshift({
        value: 'All Ranks',
        title: I18n.t('CREATE_REQUEST_PAGE.all_ranks'),
      });
      this.setState({
        ranksArray: ranksArray,
      });
    }
  };

  goToSearchResultPage = () => {
    let platform = this.state.platform;
    let gameName = this.state.game ? this.state.game.gameName : '';
    let matchType = this.state.selectedMatchType;
    let region = this.state.selectedRegion;
    let playersNumber = this.state.playersNumber;
    let rank = this.state.selectedRank;

    if (this.state.game) {
    }

    if (rank.length === 0) {
      rank = 'All Ranks';
    }

    if (matchType.length === 0) {
      matchType = 'All Matches';
    }

    playersNumber =
      playersNumber === 'All Numbers' || playersNumber === ''
        ? 0
        : playersNumber;

    if (region.length === 0) {
      region = 'All Countries';
    }

    if (this.isValidSearch()) {
      let requestModel = new Requestmodel();
      requestModel.platform = platform;
      requestModel.requestTitle = gameName;
      requestModel.region = region;
      requestModel.playersNumber = playersNumber;
      requestModel.matchType = matchType;
      requestModel.rank = rank;

      Searchrequestcore.searchForRequest(requestModel);

      NavigationService.navigate('RequestSearchResultPage', {
        REQ_URL: this.state.game.gamePhotoUrl,
      });
    }
  };

  isValidSearch() {
    // Platform not specified

    if (this.state.platform.length === 0) {
      presentToast({
        message: I18n.t('SEARCH_REQUEST_PAGE.error_platform_not_specified'),
      });

      return false;
    }

    // Game not specified

    if (this.state.game == null) {
      presentToast({
        message: I18n.t('SEARCH_REQUEST_PAGE.error_game_not_specified'),
      });
      return false;
    }

    if (!this.state.game.gamePlatforms.includes(this.state.platform)) {
      // Show toast this game doesn't support this platform

      presentToast({
        message: I18n.t('CREATE_REQUEST_PAGE.not_supported_platform_error'),
      });

      return false;
    }

    if (this.state.selectedRegion.length === 0) {
      presentToast({
        message: I18n.t('FIND_MATCH_PAGE.regionـvalidation_msg'),
      });

      return false;
    }

    return true;
  }

  platformTrigger = (platform) => {
    this.setState({
      platform: platform,
    });
  };

  renderTags = () => {
    const btn_tags = [
      Images.BTN_TAG_PLAYSTATION,
      Images.BTN_TAG_MOBILE,
      Images.BTN_TAG_DOUBLE,
      Images.BTN_TAG_DESKTOP,
      Images.BTN_TAG_BALL,
    ];
    const btn_tags_selected = [
      Images.BTN_TAG_PLAYSTATION_SELECTED,
      Images.BTN_TAG_MOBILE_SELECTED,
      Images.BTN_TAG_DOUBLE_SELECTED,
      Images.BTN_TAG_DESKTOP_SELECTED,
      Images.BTN_TAG_BALL_SELECTED,
    ];
    let views = [];
    btn_tags.forEach((tag, index) => {
      views.push(
        <TouchableOpacity
          key={index}
          onPress={() => this.platformTrigger(this.platforms[index])}>
          {this.state.platform === this.platforms[index] && (
            <LocalImageOpacity
              source={btn_tags_selected[index]}
              style={styles.btn_tag}
              resizeMode="contain"
            />
          )}
          {this.state.platform !== this.platforms[index] && (
            <LocalImageOpacity
              source={tag}
              style={styles.btn_tag}
              resizeMode="contain"
            />
          )}
        </TouchableOpacity>,
      );
    });
    return views;
  };

  toSelectGame = () => {
    NavigationService.moveToScreenInStack('HomeStack', 'SelectGamePage', {
      fromSearchRequest: true,
    });
  };

  checkIfComp = (gameName) => {
    let _game = this.props.gameNames.filter((e) => e.gameName === gameName);
    if (_game.length > 0) {
      _game = _game[0];
      if (_game.gameType === FirebasePaths.FIREBASE_GAME_COMPETITVE_ATTR) {
        return true;
      }
      return false;
    }
    return false;
  };

  // Generate players number

  generatePlayersNumber = (gameName) => {
    // Empty array

    let playersNumberArray = [];

    let _game = this.props.gameNames.filter((e) => e.gameName === gameName);
    let maxPlayers = 2;
    if (_game.length > 0) {
      _game = _game[0];
      maxPlayers = _game.maxPlayers;
    }

    // We start from 2 : as a minimum players number in a request

    for (let i = 2; i <= maxPlayers; i++) {
      playersNumberArray.push(i);
    }

    this.setState({
      playersNumberArray: playersNumberArray.map((rank) => {
        return {
          title: rank + '',
          value: rank + '',
        };
      }),
    });
  };

  getSelectedMatchType = (val) => {
    this.setState({
      selectedMatchType: val,
    });

    // Show ranks div if match type is comp and load all ranks , otherwise hide it

    if (
      val.toString() === FirebasePaths.GAME_TYPE_COMPETITVE &&
      this.state.IS_COMP_GAME
    ) {
      // To show ranks view

      this.setState({
        IS_COMP_MATCH: true,
      });

      let ranksArray = [];
      // Now insert ranks

      let _game = this.props.gameNames.filter(
        (e) => e.gameName === this.state.game.gameName,
      );
      if (_game.length > 0) {
        _game = _game[0];
        ranksArray = Array.from(_game.ranksList.values());
      }
      ranksArray = ranksArray.map((rank) => {
        return { value: rank, title: rank };
      });
      ranksArray.unshift({
        value: 'All Ranks',
        title: I18n.t('CREATE_REQUEST_PAGE.all_ranks'),
      });
      this.setState({
        ranksArray: ranksArray,
      });
    } else {
      this.IS_COMP_MATCH = false;
    }
  };

  render() {
    const { theme } = this.props;
    return (
      <View style={AppStyles.rootContainer}>
        <Header text={I18n.t('SEARCH_REQUEST_PAGE.request_search')} />
        <View style={AppStyles.rootContent}>
          <ScrollView style={styles.mb8}>
            <Picker
              pickerName={
                this.state.game !== undefined && this.state.game !== null
                  ? this.state.game.gameName
                  : I18n.t('NEW_REQUEST_PAGE.choose-game')
              }
              leftComponent={
                this.state.game !== undefined && this.state.game !== null ? (
                  <ImageWithPlaceholder
                    uri={this.state.game.gamePhotoUrl}
                    style={styles.itemImage}
                  />
                ) : (
                  <Entypo
                    name="game-controller"
                    size={perfectSize(20)}
                    color={theme.primary_text}
                    style={{ opacity: 0.5 }}
                  />
                )
              }
              onPickerPress={this.toSelectGame}
            />
            <Label style={styles.label}>
              {I18n.t('CHAMPIONSHIP_INFO_PAGE.platform')}
            </Label>
            <View style={styles.mt8}>
              <ScrollView
                showsHorizontalScrollIndicator={false}
                horizontal={true}>
                {this.renderTags()}
              </ScrollView>
            </View>

            {this.state.IS_COMP_GAME && (
              <>
                <Label style={styles.label}>
                  {I18n.t('SEARCH_REQUEST_PAGE.match')}
                </Label>
                <Picker
                  customStyles={styles.mt8}
                  leftComponent={
                    <Feather
                      name="zap"
                      size={perfectSize(20)}
                      color={theme.primary_text}
                      style={{ opacity: 0.5 }}
                    />
                  }
                  value={this.state.selectedMatchType}
                  values={this.MATCH_TYPES}
                  pickerName={I18n.t('CREATE_REQUEST_PAGE.match')}
                  onPickerChanged={(val) => this.getSelectedMatchType(val)}
                />
              </>
            )}

            {this.state.IS_COMP_GAME && this.state.IS_COMP_MATCH && (
              <>
                <Label style={styles.label}>
                  {I18n.t('CREATE_REQUEST_PAGE.rank')}
                </Label>
                <Picker
                  customStyles={styles.mt8}
                  leftComponent={
                    <Feather
                      name="zap"
                      size={perfectSize(20)}
                      color={theme.primary_text}
                      style={{ opacity: 0.5 }}
                    />
                  }
                  value={this.state.selectedRank}
                  values={this.state.ranksArray}
                  pickerName={I18n.t('CREATE_REQUEST_PAGE.rank')}
                  onPickerChanged={(val) =>
                    this.setState({
                      selectedRank: val,
                    })
                  }
                />
              </>
            )}

            {/* Region */}
            <Label style={styles.label}>
              {I18n.t('SEARCH_REQUEST_PAGE.region')}
            </Label>
            <Picker
              customStyles={styles.mt8}
              value={this.state.selectedRegion}
              values={this.state.allRegionsArray}
              pickerName={I18n.t('CREATE_REQUEST_PAGE.region')}
              onPickerChanged={(val) =>
                this.setState({
                  selectedRegion: val,
                })
              }
              leftComponent={
                <Feather
                  name="map-pin"
                  size={perfectSize(20)}
                  color={theme.primary_text}
                  style={{ opacity: 0.5 }}
                />
              }
            />

            <Label style={styles.label}>
              {I18n.t('CREATE_REQUEST_PAGE.players_number')}
            </Label>
            <Picker
              customStyles={styles.mt8}
              pickerName={I18n.t('CREATE_REQUEST_PAGE.players_number')}
              value={this.state.playersNumber + ''}
              values={this.state.playersNumberArray}
              onPickerChanged={(val) =>
                this.setState({
                  playersNumber: val,
                })
              }
              leftComponent={
                <Feather
                  name="hash"
                  size={perfectSize(20)}
                  color={theme.primary_text}
                  style={{ opacity: 0.5 }}
                />
              }
            />

            <PrimaryButton
              type="filled"
              text={I18n.t('SEARCH_REQUEST_PAGE.search')}
              customStyles={{
                marginTop: perfectSize(32),
                marginBottom: perfectSize(32),
              }}
              textCustomStyles={{ fontSize: perfectSize(15) }}
              onPress={this.goToSearchResultPage}
            />
          </ScrollView>
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
    gameNames: state.gameManagerReducer.allGamesName,
    regionsList: state.hubReducer.regionsList,
    userinformationDB: state.authReducer.userinformationDB,
  };
};

export default compose(connect(mapStateToProps, {}))(SearchrequestPage);
