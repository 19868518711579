import React from 'react';
import {createAppContainer} from 'react-navigation';
import {createStackNavigator} from 'react-navigation-stack';

import UserprofilePage from '../../pages/userprofile';
import ViewfriendprofilePage from '../../pages/viewfriendprofile';
import GamesListPage from '../../pages/games-list';
import FriendsListPage from '../../pages/friendslist';
import FriendsSearchPage from '../../pages/friendslist/search';
import PhotoViewerPage from '../../pages/photo-viewer';

const ProfileStack = createStackNavigator(
  {
    UserprofilePage: {
      screen: UserprofilePage,
    },
    ViewfriendprofilePage: {
      screen: ViewfriendprofilePage,
      path: 'userprofile/:FRIEND_KEY',
    },
    GamesListPage: {
      screen: GamesListPage,
    },
    FriendsListPage: {
      screen: FriendsListPage,
    },
    FriendsSearchPage: {
      screen: FriendsSearchPage,
    },
    PhotoViewerPage: {
      screen: PhotoViewerPage,
    },
  },
  {
    initialRouteName: 'UserprofilePage',
    headerMode: 'none',
    navigationOptions: {header: null},
    gestureEnabled: false,
  },
);

const AppContainer = createAppContainer(ProfileStack);

export default () => {
  const prefix = ['hoplayonline://', 'https://hoplay.online'];
  return <AppContainer uriPrefix={prefix} />;
};
