/* eslint-disable no-shadow */
import React, { Component } from 'react';
import { Provider, connect } from 'react-redux';
import {
  BackHandler,
  SafeAreaView,
  StyleSheet,
  StatusBar,
  AppState,
  Keyboard,
  Dimensions,
  Linking,
  View,
  Alert as RNAlert,
  LogBox,
  Platform
} from 'react-native';
import { PersistGate } from 'redux-persist/integration/react';
import NetInfo from '@react-native-community/netinfo';
import { RootSiblingParent } from 'react-native-root-siblings';
import firebase from 'firebase/app';
import 'firebase/database';
import { useFonts } from 'expo-font';

import { ToastProvider } from 'react-native-toast-notifications'

import * as FirebasePaths from './src/constants/firebasePaths';
import { persistor, store } from './src/redux/store';
import NavigationService from './src/navigation/navigationService';
import RootStack from './src/navigation';

import { switchLanguage } from './src/redux/modules/app/actions';
import {
  showAlert,
  hideAlert,
  showInputAlert,
  hideInputAlert,
  showSpinner,
  hideSpinner,
} from './src/redux/modules/alert/actions';
import { set as setHub } from './src/redux/modules/hub/actions';

// import components
import Alert from './src/components/modal';
import InputAlert from './src/components/input-modal';
import Spinner from './src/components/spinner';
import NoInternetConnection from './src/pages/no-internet-connection';

// import global variables
import './src/loggers/rankLogger';
import './src/loggers/featuresLogger';

import AppStyles from './src/constants/styles';

// import splash screen
import { perfectSize } from './src/helpers/deviceHelper';

import MyApp from './src/services/app.component';
import mainMenuService from './src/navigation/mainMenuService';
import topTabService from './src/navigation/topTabService';
import { clearMemoryCache } from './src/services/utils';
import { firebaseConfig } from './firebase';
import Toast from 'react-native-toast-notifications';

// Disable debug warning
LogBox.ignoreLogs(['Warning:...', 'VirtualizedList'])
console.error = () => { };
console.warn = () => { };
console.log = () => {};
firebase.initializeApp(firebaseConfig)

class App extends Component {
  constructor(props) {
    super(props);
    this.mount = true;
    this.deepLinkInterval = null;
    this.state = {
      isLoaded: false,
      alert: false,
      inputAlert: false,
      isConnected: true,
      appState: AppState.currentState,
    };
  }

  forbidAndroidBackHandler = () =>
    BackHandler.addEventListener('hardwareBackPress', () => true);

  handleSetNavigationReference = (navigatorRef) => {
    NavigationService.setTopLevelNavigator(navigatorRef);
  };


  async componentDidMount() {
    document.body.style.maxWidth = '1028px';
    document.body.style.margin = 'auto';
    document.body.style.backgroundColor = 'rgb(14, 19, 25)'
    Linking.getInitialURL()
      .then(async (ev) => {
        if (ev) {
          if (NavigationService.isTopLevelNavgiatorSet()) {
            this.navigateFromDeeplink(ev);
          } else {
            await MyApp.initializeOnce();
            setTimeout(() => {
              this.checkNavigationPossible(ev);
            }, 3000);
          }
        }
      })
      // eslint-disable-next-line handle-callback-err
      .catch((err) => { });
    Linking.addEventListener('url', this.handleOpenURL);
    MyApp.initialize();
    setTimeout(() => {
      const { lang, switchLanguage, alert, inputAlert } = this.props;
      switchLanguage(lang || 'en');
      if (alert) {
        this.props.hideAlert();
      }
      if (inputAlert) {
        this.props.hideInputAlert();
      }
      NetInfo.addEventListener(this.watchNetworkConnection);
      AppState.addEventListener('change', this.handleAppStateChange);
      AppState.addEventListener('memoryWarning', this.handleLowMemory);
      this.keyboardDidShowListener = Keyboard.addListener(
        'keyboardDidShow',
        this._keyboardDidShow,
      );
      this.keyboardDidHideListener = Keyboard.addListener(
        'keyboardDidHide',
        this._keyboardDidHide,
      );
      setTimeout(() => {
        if (this.mount) {
          this.setState({ isLoaded: true });
        }
      }, 1000);
    }, 500);
  }

  checkNavigationPossible = (link) => {
    if (NavigationService.isTopLevelNavgiatorSet()) {
      this.navigateFromDeeplink(link);
    }
  }

  handleOpenURL = async (event) => {
    const url = event.url;
    // this.navigateFromDeeplink(url);
    if (NavigationService.isTopLevelNavgiatorSet()) {
      this.navigateFromDeeplink(url);
    } else {
      await MyApp.initializeOnce();
      setTimeout(() => {
        this.checkNavigationPossible(url);
      }, 3000);
    }
  };

  navigateFromDeeplink = (url) => {
    const route = url.replace(/.*?:\/\//g, '');
    const routeName = route.split('/')[1];
    if (routeName === 'userprofile') {
      const friendKey = route.split('/')[2];
      NavigationService.navigate('ViewfriendprofilePage', {
        FRIEND_KEY: friendKey,
      });
    }

    if (routeName === 'post') {
      const postId = route.split('/')[2];
      NavigationService.navigate('PostPage', {
        POST_ID: postId,
      });
    }

    if (routeName === 'news') {
      NavigationService.navigate('TabStack');
      setTimeout(() => {
        mainMenuService.navigate('Home');
        setTimeout(() => {
          topTabService.navigate('Feeds');
        }, 1500);
      }, 1500);
    }

    if (routeName === 'team') {
      const teamId = route.split('/')[2];
      NavigationService.navigate('TeamPage', {
        TEAM_ID: teamId,
      });
    }

    if (routeName === 'requests') {
      const requestId = route.split('/')[2];
      const platform = route.split('/')[3];
      const gameId = route.split('/')[4];
      const region = route.split('/')[5];
      NavigationService.moveToScreenInStack('HomeStack', 'LobbyPage', {
        requestId: requestId,
        platform: platform,
        gameId: gameId,
        region: region,
      });
    }
  };

  componentWillUnmount() {
    AppState.removeEventListener('change', this.handleAppStateChange);
    AppState.removeEventListener('memoryWarning', this.handleLowMemory);
    this.keyboardDidShowListener.remove();
    this.keyboardDidHideListener.remove();
    this.mount = false;
  }

  // Listen for network state
  watchNetworkConnection = (state) => {
    const isInternetReachable = state.isInternetReachable;
    const isConnected =
      state.isConnected &&
      (isInternetReachable === null || isInternetReachable);
    if (this.mount) {
      this.setState({ isConnected });
    }
  };

  handleLowMemory = (nextAppState) => {
    clearMemoryCache();
  }

  handleAppStateChange = (nextAppState) => {
    this.setState({ appState: nextAppState });

    if (this.props.uid != null && this.props.uid !== '') {
      let userStatusRef =
        FirebasePaths.FIREBASE_USERS_INFO_ATTR +
        '/' +
        this.props.uid +
        '/' +
        FirebasePaths.FIREBASE_DETAILS_ATTR +
        '/';
      if (nextAppState.match(/inactive|background/)) {
        clearMemoryCache();
        firebase.database()
          .ref(userStatusRef)
          .child(FirebasePaths.FIREBASE_STATUS_ATTR)
          .set('offline');
      }

      if (nextAppState === 'active') {
        firebase.database()
          .ref(userStatusRef)
          .child(FirebasePaths.FIREBASE_STATUS_ATTR)
          .set('online');
      }
    }
  };

  _keyboardDidShow = () => {
    this.props.setHub({ isKeyboardOpen: true });
  };

  _keyboardDidHide = () => {
    this.props.setHub({ isKeyboardOpen: false });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { alert, inputAlert } = this.props;
    if (this.mount) {
      if (alert !== nextProps.alert && !alert) {
        this.setState({ alert: true });
      }
      if (alert !== nextProps.alert && alert) {
        this.setState({ alert: false });
      }

      if (inputAlert !== nextProps.inputAlert && !inputAlert) {
        this.setState({ inputAlert: true });
      }
      if (inputAlert !== nextProps.inputAlert && inputAlert) {
        this.setState({ inputAlert: false });
      }
    }
  }

  onHideAlert = () => {
    this.props.hideAlert();
  };

  onHideInputAlert = () => {
    this.props.hideInputAlert();
  };

  renderMainView = () => {
    const { theme } = this.props;
    return <SafeAreaView
      style={[
        styles.container,
        { backgroundColor: theme.primary_background },
      ]}>
      <StatusBar
        barStyle={theme.mode === 'dark' ? 'light-content' : 'dark-content'}
        backgroundColor={theme.primary_background}
      />

      <Alert onHide={this.onHideAlert} isVisible={this.state.alert} />
      <InputAlert
        onHide={this.onHideInputAlert}
        isVisible={this.state.inputAlert}
      />
      {this.state.isConnected && (
        <>
          <Spinner
            visible={this.props.spinner}
            textContent={this.props.spinnerTitle}
            textStyle={AppStyles.spinnerTextStyle}
          />
          <RootStack />
        </>
      )}
      {!this.state.isConnected && <NoInternetConnection />}
    </SafeAreaView>
  }

  render() {
    const { theme } = this.props;
    if (!this.state.isLoaded) {
      return (
        <SafeAreaView
          style={[
            styles.container,
            { backgroundColor: theme.primary_background },
          ]}>
          <StatusBar
            barStyle={theme.mode === 'dark' ? 'light-content' : 'dark-content'}
            backgroundColor={theme.primary_background}
          />

          <Alert onHide={this.onHideAlert} isVisible={this.state.alert} />
          <InputAlert
            onHide={this.onHideInputAlert}
            isVisible={this.state.inputAlert}
          />
          {this.state.isConnected && (
            <>
              <Spinner
                visible={this.props.spinner}
                textContent={this.props.spinnerTitle}
                textStyle={AppStyles.spinnerTextStyle}
              />
            </>
          )}
          {!this.state.isConnected && <NoInternetConnection />}
        </SafeAreaView>
      );
    } else {
      return this.renderMainView();
    }
  }
}

const RootNavigator = connect(
  (state) => ({
    theme: state.appReducer.currentTheme,
    lang: state.appReducer.currentLang,
    uid: state.authReducer.uid,
    alert: state.alertReducer.alert,
    inputAlert: state.alertReducer.inputAlert,
    spinner: state.alertReducer.spinner,
    spinnerTitle: state.alertReducer.spinnerTitle,
  }),
  (dispatch) => ({
    switchLanguage: (lang) => dispatch(switchLanguage(lang)),
    showAlert: () => dispatch(showAlert()),
    showInputAlert: () => dispatch(showInputAlert()),
    hideAlert: () => dispatch(hideAlert()),
    hideInputAlert: () => dispatch(hideInputAlert()),
    showSpinner: () => dispatch(showSpinner()),
    hideSpinner: () => dispatch(hideSpinner()),
    setHub: (payload) => dispatch(setHub(payload)),
  }),
)(App);

// NOTE: ROOT COMPONENT

export default () => {
  let [fontsLoaded] = useFonts({
    'Ubuntu-B': require('./assets/fonts/Ubuntu-B.ttf'),
    'Ubuntu-R': require('./assets/fonts/Ubuntu-R.ttf'),
    'Cairo-Regular': require('./assets/fonts/Cairo-Regular.ttf'),
    'Cairo-Bold': require('./assets/fonts/Cairo-Bold.ttf'),
  })
  if (!fontsLoaded) {
    return <></>
  }
  return <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <RootNavigator />
      <Toast ref={(ref) => global['toast'] = ref} />
    </PersistGate>
  </Provider>
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
