import React, {Component} from 'react';
import {Image, Animated} from 'react-native';
const AnimatedImage = Animated.createAnimatedComponent(Image);
class LocalImageOpacity extends Component {
    constructor(props) {
        super(props);
        this.mount = true;
        this.state = {
            showDefault: true,
            error: false,
            picUrl: null,
            animatedImage: new Animated.Value(0)
        };
    }

    componentDidMount() {
        const {source} = this.props;
        if (source) {
            this.setState({
                picUrl: source,
            })
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.source !== this.props.source && this.props.source) {
            if (this.mount) {
                this.setState({
                    picUrl: this.props.source,
                })
            }
        }
    }

    componentWillUnmount() {
        this.mount = false;
    }

    onImageLoad = (e) => {
        Animated.timing(this.state.animatedImage, {
            toValue: 1,
            duration: 500,
            useNativeDriver: true,
        }).start();
    };

    render() {
        const {picUrl} = this.state;
        return <AnimatedImage
            accessibilityRole={'image'}
            onError={() => this.setState({error: true})}
            onLoad={this.onImageLoad}
            onLoadEnd={() => this.setState({showDefault: false})}
            resizeMode={this.props.resizeMode}
            source={picUrl}
            tintColor={this.props.tintColor || null}
            style={
                [
                    this.props.style,
                    {
                        // opacity: this.state.animatedImage,
                        tintColor: this.props.tintColor || null
                    }
                ]}
        />

    }
}

export default LocalImageOpacity;
