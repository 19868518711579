import {StyleSheet} from 'react-native';
import Dimens from '../../constants/dimens';
import {perfectSize} from '../../helpers/deviceHelper';

export default StyleSheet.create({
  container: {
    flex: 1,
  },
  // Header
  headerRight: {
    flex: 1,
    flexDirection: 'row-reverse',
    alignItems: 'center',
    paddingLeft: perfectSize(8),
  },
  // Content
  content: {
    flex: 1,
    borderTopStartRadius: Dimens.BODY_BORDER_TOP_RADIUS,
    borderTopEndRadius: Dimens.BODY_BORDER_TOP_RADIUS,
    // paddingHorizontal: Dimens.HEADER_PADDING_HORIZONTAL,
    paddingVertical: Dimens.HEADER_PADDING_VERTICAL,
  },
  footer: {
    flexDirection: 'row',
    paddingHorizontal: perfectSize(8),
  },
  inputBox: {
    flex: 1,
  },
  photoContainer: {
    flexDirection: 'row',
  },
  textInput: {
    maxHeight: 150,
    minHeight: 52,
    height: 'auto',
    paddingVertical: perfectSize(8),
    paddingHorizontal: perfectSize(8),
  },
  attach: {
    marginRight: perfectSize(8),
    transform: [{rotate: '270deg'}],
  },
  sendBtn: {
    // width: perfectSize(84),
    marginLeft: perfectSize(8),
    paddingHorizontal: perfectSize(12),
    height: '100%',
  },
  optionText: {
    padding: 4,
    color: '#C70064',
  },
  dialogContainer: {
    backgroundColor: '#181f29',
    width: '80%',
    padding: perfectSize(32),
    margin: 0,
    alignSelf: 'center',
  },
  dialogSectionContainer: {
    // alignItems: 'flex-end',
    marginTop: perfectSize(16),
  },
  dialogLabel: {
    color: 'white',
    fontSize: perfectSize(16),
    textAlign: 'center',
  },
  dialogDescription: {
    color: 'white',
    fontSize: 14,
    width: '100%',
  },
  button: {
    marginHorizontal: perfectSize(4),
    borderWidth: 1,
    borderColor: '#a70559',
    borderRadius: perfectSize(10),
    height: perfectSize(42),
    justifyContent: 'center',
    paddingHorizontal: perfectSize(12),
    marginVertical: perfectSize(4),
  },
  smallButton: {
    width: perfectSize(100),
    alignItems: 'center',
    paddingRight: perfectSize(16),
    marginVertical: perfectSize(8),
  },
  buttonContainer: {
    marginTop: perfectSize(32),
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonColor: {
    color: '#C70064',
    textAlign: 'right',
    width: '100%',
  },
  input: {
    textAlignVertical: 'top',
    width: '100%',
    borderBottomWidth: 1,
    borderBottomColor: '#dedede',
    color: 'white',
    height: perfectSize(30),
    marginTop: perfectSize(8),
  },
  menu: {
    backgroundColor: 'transparent',
    paddingBottom: 20,
    position: 'absolute',
    left: 16,
  },
  menuItem: {
    backgroundColor: '#181F29',
    padding: perfectSize(8),
  },
});
