import React, { memo, Component } from 'react';
import { View, TouchableOpacity, StyleSheet } from 'react-native';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import storage from '@react-native-firebase/storage';
import { withNavigation } from 'react-navigation';
import UserAvatar from '../user-avatar';
import Label from '../label';
import { perfectSize } from '../../helpers/deviceHelper';
import * as FirebasePaths from '../../constants/firebasePaths';

class FriendItem extends Component {
  render() {
    const {
      user: { item },
    } = this.props;
    return (
      <TouchableOpacity
        style={styles.container}
        onPress={this.props.onPress}
        onLongPress={this.props.onLongPress}>
        <UserAvatar
          src={item.pictureURL || ''}
          size={perfectSize(48)}
          borderColor={item.borderColor}
          showStatus={true}
          uid={item.UID}
        />
        <View style={[styles.flexRow, { marginTop: perfectSize(4) }]}>
          <Label size="x_small" numberOfLines={1}>
            {item.username}
          </Label>
        </View>
      </TouchableOpacity>
    );
  }
}

FriendItem.propTypes = {
  userName: PropTypes.string,
  userAvatar: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
  };
};

export default memo(connect(mapStateToProps)(withNavigation(FriendItem)));

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'flex-start',
    marginVertical: perfectSize(10),
    paddingVertical: perfectSize(5),
    width: '33%',
  },
  flexRow: {
    flexDirection: 'row',
  },
});
