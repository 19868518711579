import {handleActions, combineActions} from 'redux-actions';

import {
  loginRequest,
  loginSuccess,
  loginFailed,
  logoutRequest,
  signupRequest,
  signupSuccess,
  signupFailed,
  resetPasswordRequest,
  resetPasswordSuccess,
  resetPasswordFailed,
  loadReachsourceSuccess,
  updateProfile,
  updateProfileSuccess,
  updateProfileFailed,
  getUserInfo,
  getUserInfoSuccess,
  getUserInfoFailed,
  setUserInfo,
  setProfileBorderColor,
  updateProfileAccounts,
  updateProfileAccountsSuccess,
  updateProfileAccountsFailed,
  setPCGamesProviders,
  setMobileGamesProviders,
  setFriendsListPageFirstVisit
} from './actions';

const defaultState = {
  uid: null,
  authState: false,
  authUser: null,
  error: null,
  loading: false,
  reachSource: [],
  userinformationDB: {
    nickName: '',
    username: '',
    bio: '',
    pictureURL: '',
    PSNAcc: '',
    XboxLiveAcc: '',
    nintendoAcc: '',
    pcGamesAccs: new Map(),
    mobileGamesAccs: new Map(),
    country: '',
    city: '',
    gender: '',
    age: '',
    mainPlatform: '',
    favGame: '',
    avgPlaytime: '',
    avgDailyPlaytime: '',
    dayPlaytime: '',
    phoneNumber: '',
    status: 'offline',
    borderColor: '#880e4a',
    profileBorderColor: '#C70464',
    // hops and profile icons
    hops_icon: null,
    profile_icon: null,
    // these should be moved to hub
    isProfileCompleted: false,
    isHopShadowActivated: false,
  },
  firstVisitFriendsListPage: false,
};

export default handleActions(
  {
    [combineActions(
      loginRequest,
      signupRequest,
      resetPasswordRequest,
      updateProfile,
      updateProfileAccounts,
      getUserInfo,
    )]: (state) => ({
      ...state,
      loading: true,
      error: null,
    }),
    [loginSuccess]: (state, {payload}) => ({
      ...state,
      uid: payload.uid,
      authState: true,
      authUser: payload,
      error: null,
      loading: false,
    }),
    [logoutRequest]: (state) => ({
      ...defaultState,
    }),
    [resetPasswordSuccess]: (state) => ({
      ...state,
      loading: false,
    }),
    [loadReachsourceSuccess]: (state, {payload}) => ({
      ...state,
      reachSource: payload,
    }),
    [signupSuccess]: (state, {payload}) => ({
      ...state,
      userinformationDB: {
        ...state.userinformationDB,
        mainPlatform: payload.mainPlatform,
      },
      loading: false,
      authState: true,
      uid: payload.uid,
      authUser: payload.user,
      error: null,
    }),
    [getUserInfoSuccess]: (state) => ({
      ...state,
      userinformationDB: {
        ...state.userinformationDB,
        // payload,
      },
    }),
    [combineActions(
      setUserInfo,
      setPCGamesProviders,
      setMobileGamesProviders,
    )]: (state, {payload}) => ({
      ...state,
      userinformationDB: {
        ...state.userinformationDB,
        ...payload,
      },
    }),
    [setProfileBorderColor]: (state, {payload}) => ({
      ...state,
      userinformationDB: {
        ...state.userinformationDB,
        profileBorderColor: payload.profileBorderColor,
      },
    }),
    [updateProfileAccountsSuccess]: (state, {payload}) => ({
      ...state,
      loading: false,
      userinformationDB: {
        ...state.userinformationDB,
        ...payload,
      },
    }),
    [updateProfileSuccess]: (state) => ({
      ...state,
      loading: false,
    }),
    [setFriendsListPageFirstVisit]: (state) => ({
      ...state,
      firstVisitFriendsListPage: true,
    }),
    [combineActions(
      getUserInfoFailed,
      updateProfileFailed,
      updateProfileAccountsFailed,
    )]: (state, {payload}) => ({
      ...state,
      loading: false,
      error: payload,
    }),
    [combineActions(loginFailed, signupFailed, resetPasswordFailed)]: (
      state,
      {payload},
    ) => ({
      ...state,
      loading: false,
      authState: false,
      error: payload,
    }),
  },
  defaultState,
);
