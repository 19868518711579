import React, { memo, Component } from 'react';
import { connect } from 'react-redux';
import {
    Placeholder,
    PlaceholderMedia,
    PlaceholderLine,
    Fade,
    Shine,
    ShineOverlay
} from "rn-placeholder";
import { perfectSize } from "../../helpers/deviceHelper";

class SkeletonTextContent extends Component {
    constructor(props) {
        super(props);
        this.mount = true;
        this.state = {};
    }

    componentDidMount() { }

    componentWillUnmount = () => {
        this.mount = false;
    }

    render() {
        const { theme, width, height, style } = this.props;
        return (
            <PlaceholderLine color={theme.secondary_btn_background} width={width} height={height ?? perfectSize(12)} noMargin style={[{ borderRadius: 0 }, style]} />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        theme: state.appReducer.currentTheme,
        lang: state.appReducer.currentLang,
    };
};

export default memo(connect(mapStateToProps)(SkeletonTextContent));