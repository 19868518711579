import {StyleSheet} from 'react-native';
import {perfectSize} from '../../helpers/deviceHelper';

export default StyleSheet.create({
  descriptionLabel: {
    marginTop: perfectSize(8),
  },
  gamePicker: {
    marginTop: perfectSize(32),
  },
  label: {
    opacity: 0.56,
    marginTop: perfectSize(16),
  },
  mt4: {
    marginTop: perfectSize(4),
  },
  tagImage: {
    width: perfectSize(64),
    height: perfectSize(32),
    marginRight: perfectSize(8),
  },
  btn_tag: {
    width: perfectSize(64),
    height: perfectSize(32),
    marginRight: perfectSize(7),
  },
  itemImage: {
    width: perfectSize(32),
    height: perfectSize(32),
    aspectRatio: 1,
    borderRadius: perfectSize(12),
  },
});
