/* eslint-disable react-native/no-inline-styles */
import React, {Component} from 'react';
import {
  View,
  TouchableOpacity,
  StyleSheet,
  Dimensions,
  FlatList,
  Image,
} from 'react-native';
import Modal from 'react-native-modal';
import {compose} from 'redux';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Feather from 'react-native-vector-icons/Feather';
import firebase from 'firebase/app';
import 'firebase/database';

import {perfectSize} from '../../../helpers/deviceHelper';
import dimens from '../../../constants/dimens';
import * as FirebasePaths from '../../../constants/firebasePaths';
import {HOOTS_COVER} from '../../../constants/images';

import {showAlert, hideSpinner, showSpinner} from '../../../redux/modules/alert/actions';
import ImageWithPlaceholder from '../../image-with-placeholder';
import Label from '../../label';
import OverlayImage from '../../overlay-image';
import LocalImageOpacity from '../../local-image-opacity';
import PrimaryButton from '../../buttons/primary-button';
import I18n from '../../../i18n';
import UserAvatar from '../../user-avatar';
import navigationService from '../../../navigation/navigationService';
import Hoot from '../../../models/hoot';
import ImageWithPlaceholderWithoutFocus from '../../image-with-placeholder/without-focus';
import {presentToast} from '../../../helpers/toastHelper';

const {height: viewportHeight} = Dimensions.get('window');

class HootDetailModalCardContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      cover: props.cover,
      title: props.title,
      admin: props.admin,
      // hootMembers: props.historyParticipants,
      historyMembers: props.historyParticipants ? Object.values(props.historyParticipants) : [],
      activeMembers: props.liveParticipants ? Object.values(props.liveParticipants) : []
    };
  }

  componentDidMount() {
  }

  getHootDetails = (hootId) => {
    this.hootRef$ = HootsCore.getHootDetailRef(hootId);
    this.hootRef$.on('value', snapshot => {
      const val = snapshot.val();
      if (val) {
        this.setState({
          currentHoot: val
        })
      }
    })
  }

  isUserActive = (userId) => {
    const {activeMembers} = this.state;
    const existMembers = activeMembers.filter(aMember => aMember.uid === userId).length;
    return existMembers > 0;
  }

  _renderItem = (item) => {
    const isAdmin = this.state.admin === item.uid;
    return (
      <TouchableOpacity
        style={[styles.imgSticker, !this.isUserActive(item.uid) && {opacity: 0.3}]}
        onPress={() => {
        }}>
        {isAdmin ?
          < ImageWithPlaceholderWithoutFocus
            uri={'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/GeneralIcons%2Fcrown.png?alt=media&token=e1bf7662-a6de-424d-bd9b-2c1f40d47be0'}
            style={styles.crown}
            resizeMode="contain"
          /> : <View style={styles.crown} />
        }
        <UserAvatar
          size={perfectSize(32)}
          src={item.img}
          uid={item.uid}
          withFocus={false}
        // style={}
        />
        <View style={{flexDirection: 'row', alignItems: 'center', marginTop: perfectSize(4)}}>
          <Label size="small">{item.username}</Label>
          <ImageWithPlaceholderWithoutFocus
            uri={global.rankLogger.getRankImg(item.rank)}
            style={[global.rankLogger.getRankStyleForHootDetail(
              item.rank,
            ), {marginLeft: perfectSize(4)}]}
            resizeMode="contain"
          />
        </View>
      </TouchableOpacity>
    );
  };

  checkMaxParticipants = async () => {
    const {
      uid,
      liveParticipants,
      historyParticipants,
    } = this.props;
    const liveMaxParticipantsPath = FirebasePaths.FIREBASE_HOOTS_CONF + '/' + FirebasePaths.FIREBASE_HOOTS_MAX_LIVE_PARTICIPANTS;
    const liveMaxParticipantsRef = await firebase.database().ref(liveMaxParticipantsPath).once('value');
    const liveMaxParticipants = liveMaxParticipantsRef.val();

    const historyMaxParticipantsPath = FirebasePaths.FIREBASE_HOOTS_CONF + '/' + FirebasePaths.FIREBASE_HOOTS_MAX_HISTORY_PARTICIPANTS;
    const historyMaxParticipantsRef = await firebase.database().ref(historyMaxParticipantsPath).once('value');
    const historyMaxParticipants = historyMaxParticipantsRef.val();
    if (liveParticipants && Object.keys(liveParticipants).length >= liveMaxParticipants) {
      presentToast({
        message: I18n.t('HOOTS_PAGE.max_join_exceed'),
      })
      return true;
    } else {
      if (historyParticipants && !historyParticipants[uid]) {
        if (Object.keys(historyParticipants).length >= historyMaxParticipants) {
          presentToast({
            message: I18n.t('HOOTS_PAGE.max_join_exceed'),
          })
          return true;
        }
      }
    }
  }

  joinHoot = async () => {
    const {
      uid,
      authUserInfo,
      id,
      liveParticipants: currentLiveParticipants,
      historyParticipants: currentHistoryParticipants,
      isLive
    } = this.props;
    this.props.onHide(false);
    this.props.showSpinner({});
    const isExceedMax = await this.checkMaxParticipants();
    if (isExceedMax) {
      this.props.hideSpinner();
      return;
    }

    const meAsUser = {
      uid: uid,
      username: authUserInfo.username,
      rank: authUserInfo.rank,
      img: authUserInfo.pictureURL,
    }
    // add me as live user
    const hootPath =
      FirebasePaths.FIREBASE_HOOTS +
      '/' +
      id;
    let liveParticipants = currentLiveParticipants || {};
    if (!currentLiveParticipants) {
      liveParticipants[uid] = meAsUser;
    } else {
      if (!currentLiveParticipants[uid]) {
        liveParticipants[uid] = meAsUser;
      }
    }
    firebase.database().ref(hootPath).child('liveParticipants').set(liveParticipants);
    let historyParticipants = currentHistoryParticipants || {};
    if (!currentHistoryParticipants[uid]) {
      historyParticipants[uid] = meAsUser;
      firebase.database().ref(hootPath).child('historyParticipants').set(historyParticipants);
    }
    // if it's call again, then add me as reInitiator
    if (!isLive) {
      // 
      firebase.database().ref(hootPath).child('reInitiator').set(uid);
      global.rankLogger.LOG_NEW_HP(
        global.rankLogger.HOOT_RECALL,
        'HOOT_RECALL',
      );
    } else {
      global.rankLogger.LOG_NEW_HP(
        global.rankLogger.HOOT_JOIN,
        'HOOT_JOIN',
      );
    }
    if (isLive) {
      // Log live hoot join confirm
      global.featuresLogger.HOOTS_TIMELINE_LIVE_HOOT_JOIN_CONFIRM();
    } else {
      // Log recent hoot join confirm
      global.featuresLogger.HOOTS_TIMELINE_RECENT_HOOT_JOIN_CONFIRM();
    }

    this.props.hideSpinner();
    // add me to users_info ref

    const path =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      uid +
      '/' +
      FirebasePaths.FIREBASE_HOOTS +
      '/' +
      id;

    const hoot = {...this.props.selectedHoot};
    hoot.status = 'live';

    // Upload to the user hoots ref path
    firebase.database().ref(path).set(hoot);0
    const isCreate = !isLive && (!currentLiveParticipants || currentLiveParticipants.length === 0);
    navigationService.moveToScreenInStack('HomeStack', 'HootDetailPage', {
      hoot: {...this.props, isCreate: isCreate}
    })
  }

  sortHistoryParticipants = (admin, originHistoryParticipants) => {
    const historyParticipants = originHistoryParticipants.sort(function (a, b) {
      const aUid = a.uid;
      const bUid = b.uid;
      if (aUid === admin) return -1;
      if (bUid === admin) return 1;
      else return 0;
    })
    return historyParticipants;
  }

  render() {
    const {cover, title} = this.state;
    const {theme} = this.props;
    return (
          <View style={styles.box}>
            {
              !!cover &&
              <View style={styles.overlayWrap}>
                <ImageWithPlaceholderWithoutFocus
                  uri={cover}
                  style={styles.fullSize}
                  useOpacity
                  toOpacity={0.2}
                />
              </View>
            }
            <View style={[styles.headerWrap, !!cover ? {position: 'absolute', } : {backgroundColor: '#1C242E', }]}>
              <View style={[styles.header]}>
                <TouchableOpacity
                  onPress={() => {
                    // close modal
                    this.props.onHide();
                  }}
                  style={styles.downContainer}>
                  <Feather
                    name="chevron-down"
                    color="white"
                    size={perfectSize(24)}
                  />
                </TouchableOpacity>
              </View>
              <View style={{width: '100%', alignItems: 'center', justifyContent: 'center'}}>
                <LocalImageOpacity
                  source={HOOTS_COVER}
                  style={{width: perfectSize(63), height: perfectSize(63), marginBottom: perfectSize(24)}}
                  resizeMode="contain"
                />
                <Label size={'small'}>{title}</Label>
              </View>
            </View>
            <View style={styles.contentView}>
              {
                <FlatList
                  data={this.sortHistoryParticipants(this.state.admin, this.state.historyMembers)}
                  renderItem={({item}) => this._renderItem(item)}
                  keyExtractor={(item, index) => index.toString()}
                  numColumns={3}
                />
              }
              <View style={styles.fullFlex} />
              <View style={styles.footer}>
                <PrimaryButton
                  text={this.props.isLive ? I18n.t('HOOTS_PAGE.hoots_join') : I18n.t('HOOTS_PAGE.hoots_call_again')}
                  // text={I18n.t('HOOTS_PAGE.hoots_join')}
                  type="filled"
                  onPress={() => this.joinHoot()}
                  textCustomStyles={styles.submitTextStyle}
                />
              </View>
            </View>
          </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
    rank: state.authReducer.userinformationDB.rank,
    uid: state.authReducer.uid,
    authUserInfo: state.authReducer.userinformationDB,
  };
};

HootDetailModalCardContent.defaultProps = {

};

HootDetailModalCardContent.propTypes = {

};

export default compose(connect(mapStateToProps, {showAlert, showSpinner, hideSpinner}))(HootDetailModalCardContent);

const styles = StyleSheet.create({
  modalStyle: {
    marginHorizontal: 0,
    margin: 0,
    paddingTop: (viewportHeight * 2) / 7,

  },
  wrapper: {
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  box: {
    flex: 1,
    backgroundColor: '#181f29',
    // backgroundColor: '',
    width: '100%',
    borderTopLeftRadius: dimens.HEADER_PADDING_HORIZONTAL,
    borderTopRightRadius: dimens.HEADER_PADDING_HORIZONTAL,
    overflow: 'hidden'
  },
  headerWrap: {
    paddingHorizontal: perfectSize(32),
    paddingVertical: perfectSize(16),
    height: perfectSize(196),
    width: '100%',
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
  downContainer: {
    flex: 1,
    width: '100%'
  },
  divider: {
    flex: 1,
    height: 2,
    backgroundColor: 'white',
  },
  fullFlex: {
    flex: 1,
  },
  noOffset: {
    paddingTop: 0,
  },
  containerStyles: {
    marginTop: 10,
    width: '40%',
    alignSelf: 'center',
    borderRadius: 10,
    shadowOffset: {
      width: 0,
      height: 2,
    },
  },
  icon: {
    width: 20,
    height: 20,
    resizeMode: 'contain',
  },
  imgSticker: {
    width: '33%',
    marginTop: perfectSize(12),
    marginBottom: perfectSize(24),
    marginHorizontal: perfectSize(1),
    alignItems: 'center'
  },
  overlayWrap: {
    width: '100%',
    height: perfectSize(196),
    borderTopLeftRadius: dimens.HEADER_PADDING_HORIZONTAL,
    borderTopRightRadius: dimens.HEADER_PADDING_HORIZONTAL,
  },
  gradient: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    height: perfectSize(196),
  },
  fullSize: {
    width: '100%',
    height: '100%',
  },
  contentView: {
    flex: 1,
    paddingHorizontal: perfectSize(16)
  },
  footer: {
    paddingBottom: perfectSize(24),
  },
  submitTextStyle: {
    fontSize: perfectSize(15)
  },
  crown: {
    width: perfectSize(8),
    height: perfectSize(6),
    marginBottom: perfectSize(2)
  }
});
