import {StyleSheet} from 'react-native';
import {perfectSize} from '../../helpers/deviceHelper';
import {FONT_SIZE, FONT_FAMILY} from '../../constants/theme';
import Dimens from '../../constants/dimens';

const styles = (theme) =>
  StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: theme.primary_background,
    },
    wrapper: {
      paddingHorizontal: Dimens.BODY_PADDING_HORIZONTAL,
      paddingVertical: Dimens.BODY_PADDING_TOP,
    },
    appName: {
      height: 20,
      aspectRatio: 695 / 93,
      resizeMode: 'contain',
      marginTop: perfectSize(16),
    },
    appDescription: {
      opacity: 0.56,
      marginTop: perfectSize(8),
    },
    flexRow: {
      flexDirection: 'row',
    },
    justifyBetween: {
      justifyContent: 'space-between',
    },
    alignCenter: {
      alignItems: 'center',
    },
    imgLogo: {
      width: perfectSize(128),
      height: perfectSize(128),
      resizeMode: 'contain',
      marginVertical: perfectSize(16),
    },
    btnLogin: {
      width: '48%',
      height: 52,
      textAlign: 'center',
    },
    forgot: {
      fontSize: FONT_SIZE.x_small,
      // fontFamily: FONT_FAMILY.ubuntu,
      lineHeight: perfectSize(19),
      color: theme.primary_btn_background,
    },
    signUpText: {
      fontFamily: FONT_FAMILY.ubuntu_bold,
      fontSize: perfectSize(15),
    },
  });

export default styles;
