/* eslint-disable react/no-did-mount-set-state */
/* eslint-disable react-native/no-inline-styles */
import React, { Component } from 'react';
import {
  View,
  TouchableOpacity,
  FlatList,
  ScrollView,
  RefreshControl,
} from 'react-native';
import { Tooltip } from 'react-native-elements';
import { compose } from 'redux';
import { connect } from 'react-redux';
// import Share from 'react-native-share';
import StarRating from 'react-native-star-rating';
import Feather from 'react-native-vector-icons/Feather';
import deepEqual from 'fast-deep-equal';
import { MaterialIndicator } from 'react-native-indicators';
import I18n from '../../i18n';
import NavigationService from '../../navigation/navigationService';
import firebase from 'firebase/app';
import 'firebase/database';

// import components
import Header from '../../components/header';
import Label from '../../components/label';
import UserAvatar from '../../components/user-avatar';
import ActivityItem from '../../components/user-activity-item';
import SimpleTabBar from '../../components/simple-tab-bar';
import PrimaryButton from '../../components/buttons/primary-button';
import UserProfileAccountsPage from './account';
import Spinner from '../../components/spinner';

// import styles
import AppStyles from '../../constants/styles';
import styles from './styles';

import { STAR, STAR_BLANK } from '../../constants/images';
import { perfectSize } from '../../helpers/deviceHelper';
import { presentToast } from '../../helpers/toastHelper';

// import service
import ViewFriendProfileCore from './viewFriendProfileCore';
import FriendsListCore from '../friendslist/friendslistcore';
import { set as HubSet } from '../../redux/modules/hub/actions';
import { showAlert } from '../../redux/modules/alert/actions';
import * as FirebasePaths from '../../constants/firebasePaths';
import { arrayFromSnapshot, clearFastImageCache, dataForSkeleton } from '../../services/utils';
import TimelineCore from '../timeline/timelinecore';
import HopCard from '../../components/cards/hop-card';
import HopSkeletonCard from '../../components/cards/hop-card/skeleton';
import ImageWithPlaceholder from '../../components/image-with-placeholder';
import { Placeholder, Progressive } from 'rn-placeholder';
import SkeletonUserAvatar from '../../components/skeletonWrapper/skeletonUserAvatar';
import SkeletonTextContent from '../../components/skeletonWrapper/skeletonTextContent';
// 
class ViewfriendprofilePage extends Component {
  myHops$ = null;

  constructor(props) {
    super(props);
    this.mount = true;
    const params = props.navigation.state.params;
    this.tooltipRef = React.createRef();

    this.state = {
      userKey: params.FRIEND_KEY,
      currentTab: params.ACTIVE_TAB || 0,
      showSpinner: false,
      friendBorderColor: '',
      friendStatus: props.friendStatus,
      friendUsername: '',
      friendBio: '',
      friendGamesNumber: '',
      friendFriendsNumber: '',
      friendRecentActivitiesArray: [],
      friendProfileIcon: '',
      hopsLikeCount: 0,
      viewFriendModel: {}, //props.viewFriendModel,
      friendRecentList: props.friendRecentList,
      tooltipToggle: false,
      friendClans: [],
      currentSubTab: 0,
      hopsStart: null,
      hopsLimit: 10,
      hopsHasMoreData: true,
      myHops: [],
      games: {},
      refreshing: false,

      // for user privacy preferences values
      privacy_recent_requests: false,
      privacy_recent_hops: false,
      privacy_clans: false,
      privacy_games: false,
    };
  }

  async componentDidMount() {
    this.setState({ showSpinner: true });
    setTimeout(() => {
      if (this.state.showSpinner) {
        if (this.mount) {
          this.setState({ showSpinner: false });
        }
      }
    }, 1500);
    const { userKey } = this.state;
    if (userKey) {
      global.rankLogger.LOG_NEW_HP(
        global.rankLogger.VIEW_FRIEND_PROFILE,
        'VIEW_FRIEND_PROFILE',
      );
      global.featuresLogger.LOG_VIEW_USER_PROFILE();
      ViewFriendProfileCore.checkUserPasses(userKey);
      ViewFriendProfileCore.loadFriendData(userKey);

      // get user privacy preference values
      const userPrivacyPath =
        FirebasePaths.FIREBASE_USERS_INFO_ATTR +
        '/' +
        userKey +
        '/' +
        FirebasePaths.FIREBASE_USER_PRIVACY_ATTR;
      const userPrivacyRef = firebase.database().ref(userPrivacyPath);
      userPrivacyRef.once('value', (snapshot) => {
        const val = snapshot.val();
        if (val !== null) {
          if (this.mount) {
            this.setState({
              privacy_recent_requests: val.recentRequests || false,
              privacy_recent_hops: val.recentHops || false,
              privacy_clans: val.clans || false,
              privacy_games: val.games || false,
            });
          }
        }
      });

      const cosmeticsPath =
        FirebasePaths.FIREBASE_USERS_INFO_ATTR +
        '/' +
        userKey +
        '/' +
        FirebasePaths.FIREBASE_COSMETICS_ATTR +
        '/' +
        FirebasePaths.FIREBASE_PROFILE_BORDER_ATTR;
      this.cosmeticsListener = firebase.database().ref(cosmeticsPath);
      this.cosmeticsListener.on('value', (snapshot) => {
        let borderColor = '#880e4a';
        if (snapshot.val()) {
          snapshot.forEach((snap) => {
            if (snap.key === FirebasePaths.FIREBASE_COLOR_ATTR) {
              borderColor = snap.val();
            }
          });
          if (this.mount) {
            this.setState({ friendBorderColor: borderColor });
          }
        }
      });
      this.loadBlockedList();

      this.hopsLikeCountListener = ViewFriendProfileCore.getHopsLikesCount(
        userKey,
      );
      this.hopsLikeCountListener.on('value', (snapshot) => {
        let count = 0;
        if (snapshot.val()) {
          snapshot.forEach((snap) => {
            if (snap.val()._likes_) {
              count += Object.keys(snap.val()._likes_).length;
            }
          });
          if (this.mount) {
            this.setState({ hopsLikeCount: count });
          }
        }
      });

      let clansRef = ViewFriendProfileCore.getUserTeamsRef(userKey);
      const snapshot = await clansRef.once('value');
      if (this.mount) {
        this.setState({
          friendClans: arrayFromSnapshot(snapshot),
        });
      }

      this.loadUserHops(userKey);
      this.loadUserGames(userKey);
    }
  }

  componentDidUpdate(prevProps) {
    const { viewFriendModel: oldModel } = this.state;
    const { viewFriendModel: newModel } = this.props;
    if (oldModel.UID !== newModel.UID && this.mount) {
      if (this.mount) {
        this.setState({ viewFriendModel: newModel });
      }
    }
    const { friendRecentList: oldList } = prevProps;
    const { friendRecentList: newList } = this.props;
    if (!deepEqual(oldList, newList) && this.mount) {
      if (this.mount) {
        this.setState({ friendRecentList: newList });
      }
    }
    if (this.props.friendStatus !== prevProps.friendStatus && this.mount) {
      if (this.mount) {
        this.setState({ friendStatus: this.props.friendStatus });
      }
    }
  }

  componentWillUnmount() {
    this.mount = false;
    if (ViewFriendProfileCore.friendRecentList) {
      ViewFriendProfileCore.friendRecentList = [];
    }
    if (ViewFriendProfileCore.loadFriendDataListener) {
      ViewFriendProfileCore.loadFriendDataListener.off();
      ViewFriendProfileCore.loadFriendDataListener = null;
    }
    if (ViewFriendProfileCore.loadFriendRatesListener) {
      ViewFriendProfileCore.loadFriendRatesListener.off();
      ViewFriendProfileCore.loadFriendRatesListener = null;
    }
    if (ViewFriendProfileCore.loadFriendGamesNumberListener) {
      ViewFriendProfileCore.loadFriendGamesNumberListener.off();
      ViewFriendProfileCore.loadFriendGamesNumberListener = null;
    }
    if (ViewFriendProfileCore.loadFriendsFriendsNumberListener) {
      ViewFriendProfileCore.loadFriendsFriendsNumberListener.off();
      ViewFriendProfileCore.loadFriendsFriendsNumberListener = null;
    }
    if (ViewFriendProfileCore.loadFriendRecentActivitiesListener) {
      ViewFriendProfileCore.loadFriendRecentActivitiesListener.off();
      ViewFriendProfileCore.loadFriendRecentActivitiesListener = null;
    }
    if (ViewFriendProfileCore.loadFriendRecentActivityListener) {
      ViewFriendProfileCore.loadFriendRecentActivityListener.off();
      ViewFriendProfileCore.loadFriendRecentActivityListener = null;
    }
    if (this.friendStatusListener) {
      this.friendStatusListener.off();
      this.friendStatusListener = null;
    }
    if (this.cosmeticsListener) {
      this.cosmeticsListener.off();
      this.cosmeticsListener = null;
    }
    if (this.hopsLikeCountListener) {
      this.hopsLikeCountListener.off();
      this.hopsLikeCountListener = null;
    }
    if (this.blockedListlistener) {
      this.blockedListlistener.off();
      this.blockedListlistener = null;
    }

    if (this.myHops$) {
      this.myHops$.off();
      this.myHops$ = null;
    }
  }

  loadUserGames = (userKey) => {
    // REF : uid/_games_/_favor_games_
    const favorGamesRef =
      '/' +
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      userKey +
      '/' +
      FirebasePaths.FIREBASE_GAMES_REFERENCES +
      '/' +
      FirebasePaths.FIREBASE_FAVOR_GAME_ATTR +
      '/';

    firebase.database()
      .ref(favorGamesRef)
      .once('value', (snapshot) => {
        if (snapshot.val()) {
          if (this.mount) {
            this.setState({
              games: snapshot.val(),
            });
          }
        }
      });
  };

  loadUserHops = async (userKey) => {
    if (this.state.refreshing) return;
    if (this.mount) {
      this.setState({
        refreshing: true,
      });
    }
    this.myHops$ = TimelineCore.loadUserHops(
      userKey,
      this.state.hopsStart,
      this.state.hopsLimit,
    );

    const snapshot = await this.myHops$.once('value');
    let posts = arrayFromSnapshot(snapshot).filter((val) => val !== null);
    posts.reverse();
    const postsToAdd = [];
    for (const post of posts) {
      const postRes = await TimelineCore.loadHop(post.postId).once('value');
      const postResVal = postRes.val();
      if (postResVal !== null) {
        postsToAdd.push(postResVal);
      }
    }
    if (this.state.hopsStart !== null) {
      postsToAdd.shift();
    }

    const lastItem = postsToAdd[postsToAdd.length - 1];
    if (this.mount) {
      this.setState({
        myHops: [...this.state.myHops, ...postsToAdd],
        hopsStart: lastItem?.timeStamp,
        refreshing: false,
        hopsHasMoreData: posts.length > 0 ? true : false,
      });
    }
  };

  goBack = () => {
    clearFastImageCache();
    NavigationService.goBack();
  };

  onTabChanged = (tab) => {
    if (this.mount) {
      this.setState({ currentTab: tab });
    }
  };

  onSubTabChanged = (tab) => {
    if (this.mount) {
      this.setState({ currentSubTab: tab });
    }
  };

  viewUserAvatar = (url) => {
    NavigationService.navigate('PhotoViewerPage', {
      IMG: url,
    });
  };

  goToDetails = (item) => {
    // NavigationService.moveToScreenInStack('HomeStack', 'PostPage');
    TimelineCore.IS_REPLY_HOP_BANNED((banned) => {
      if (banned) {
        presentToast({
          message: I18n.t('TIMELINE_PAGE.ban_alert_msg2'),
        });
      } else {
        NavigationService.moveToScreenInStack('HomeStack', 'PostPage', {
          POST_ID: item.id,
        });
      }
    });
  };

  deleteMyHop = (item) => {
    this.props.showAlert([
      I18n.t('TIMELINE_PAGE.confirm_delete_hop_title'),
      I18n.t('TIMELINE_PAGE.confirm_delete_hop_msg'),
      [
        {
          text: I18n.t('TIMELINE_PAGE.confirm_delete_hop_yes_btn'),
          onPress: () => {
            TimelineCore.deleteMyHop(item);
          },
        },
        {
          text: I18n.t('TIMELINE_PAGE.confirm_delete_hop_no_btn'),
          onPress: () => { },
        },
      ],
      false,
    ]);
  };

  loadBlockedList = () => {
    const { uid } = this.props;
    const blockedListRef =
      '/' +
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      uid +
      '/' +
      FirebasePaths.FIREBASE_BLOCKED_USERS +
      '/';
    this.blockedListlistener = firebase.database().ref(blockedListRef);
    this.blockedListlistener.on('value', (blockedUsersShot) => {
      const blockedList = new Map();
      blockedUsersShot.forEach((shot) => {
        blockedList.set(shot.key, shot.val());
      });
      this.props.HubSet({ blockedList: blockedList });
    });
  };

  showBlockAlert = () => {
    this.props.showAlert([
      I18n.t('SEARCH_REQUESTS_RESULT_PAGE.block_title'),
      I18n.t('TIMELINE_PAGE.block_alert_message'),
      [
        {
          text: I18n.t('TIMELINE_PAGE.block_alert_yes'),
          onPress: () => {
            this.addToBlockList(
              this.state.userKey,
              this.state.viewFriendModel.username,
            );
            let _blockedList = this.props.blockedList;
            if (_blockedList === undefined) {
              _blockedList = new Map();
            }
            _blockedList.set(
              this.state.userKey,
              this.state.viewFriendModel.username,
            );
            this.props.HubSet({
              blockedList: _blockedList,
            });
            presentToast({
              message: I18n.t('TIMELINE_PAGE.block_alert_success_msg'),
            });
            setTimeout(() => {
              NavigationService.goBack();
            }, 1000);
          },
        },
        {
          text: I18n.t('TIMELINE_PAGE.block_alert_no'),
          onPress: () => { },
        },
      ],
      false,
    ]);
  };

  addToBlockList = (uid, username) => {
    const blockListPath =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      this.props.uid +
      '/' +
      FirebasePaths.FIREBASE_BLOCKED_USERS +
      '/';
    firebase.database()
      .ref(blockListPath + '/' + uid)
      .set(username);
  };

  _toggleTooltip = () => {
    presentToast({
      message: I18n.t('VIEW_FRIEND_PROFILE_PAGE.tooltip'),
    });
  };

  _openTooltip = () => {
    if (this.mount) {
      this.setState({ tooltipToggle: true });
    }
  };

  _closeTooltip = () => {
    if (this.mount) {
      this.setState({ tooltipToggle: false });
    }
  };

  toPrivateChat = () => {
    let CHAT_STATUS = 'NEW';
    if (this.userHasRefWithFriend(this.state.userKey)) {
      CHAT_STATUS = 'ONGOING';
    } else {
      CHAT_STATUS = 'NEW';
    }
    let { friendsList } = this.props;
    if (friendsList === undefined) {
      friendsList = [];
    }
    if (!friendsList.filter((e) => e.UID === this.state.userKey).length > 0) {
      global.rankLogger.LOG_NEW_HP(global.rankLogger.ADD_FRIEND, 'ADD_FRIEND');
      FriendsListCore.addFriend(this.state.userKey);

      presentToast({
        message:
          this.state.viewFriendModel.username +
          I18n.t('FRIEND_LIST_PAGE.added_successfully'),
      });

      NavigationService.moveToScreenInStack('HomeStack', 'PrivateChatPage', {
        TITLE: this.state.viewFriendModel.username,
        SUBTITLE: this.state.viewFriendModel.bio,
        CHAT_IMG: this.state.viewFriendModel.pictureURL,
        CHAT_TYPE: 'PRIVATE',
        FRIEND_UID: this.state.userKey,
        CHAT_STATUS: CHAT_STATUS,
      });
    } else {
      NavigationService.moveToScreenInStack('HomeStack', 'PrivateChatPage', {
        TITLE: this.state.viewFriendModel.username,
        SUBTITLE: this.state.viewFriendModel.bio,
        CHAT_IMG: this.state.viewFriendModel.pictureURL,
        CHAT_TYPE: 'PRIVATE',
        FRIEND_UID: this.state.userKey,
        CHAT_STATUS: CHAT_STATUS,
      });
    }
  };

  userHasRefWithFriend = (opponentId) => {
    let FOUND_RELATED_REF = false;
    Object.values(this.props.allPrivateChatRefs).forEach((data) => {
      if (data.opponentId === opponentId) {
        FOUND_RELATED_REF = true;
        this.props.HubSet({
          tempChatRoomRef: data.chatRef,
        });
      }
    });
    return FOUND_RELATED_REF;
  };

  listHeaderComponent = () => {
    const { theme } = this.props;
    const { viewFriendModel } = this.state;
    return (
      <>
        <View style={styles.userWrap}>
          <TouchableOpacity
            onPress={() => this.viewUserAvatar(viewFriendModel.pictureURL)}>
            <UserAvatar
              src={viewFriendModel.pictureURL}
              size={perfectSize(64)}
              borderColor={this.state.friendBorderColor || '#C70064'}
              showStatus={true}
              online={this.state.friendStatus === 'online' || false}
              style={{ marginVertical: perfectSize(10) }}
            // uid={this.state.userKey}
            />
          </TouchableOpacity>

          {viewFriendModel.profile_icon !== null &&
            viewFriendModel.profile_icon !== '' && (
              <ImageWithPlaceholder
                uri={viewFriendModel.profile_icon}
                style={[AppStyles.rankImg, { marginVertical: perfectSize(4) }]}
                resizeMode="contain"
              />
            )}
          <View style={[styles.flexRow, styles.alignCenter]}>
            <Label size="x_medium" style={{ textTransform: 'capitalize' }}>
              {viewFriendModel.username}
            </Label>
            <ImageWithPlaceholder
              uri={global.rankLogger.getRankImg(this.props.friendHP)}
              style={[
                global.rankLogger.getRankStyle(this.props.friendHP),
                { marginLeft: perfectSize(4) },
              ]}
              resizeMode="contain"
            />
          </View>

          <View
            style={[
              styles.flexRow,
              styles.alignCenter,
              { marginVertical: perfectSize(8) },
            ]}>
            <Label size="x_small" style={{ opacity: 0.6 }}>
              {this.props.friendHP} HP
            </Label>
          </View>
          <TouchableOpacity onPress={this._toggleTooltip}>
            <StarRating
              emptyStar={STAR_BLANK}
              fullStar={STAR}
              starSize={20}
              halfStarColor={'#C70064'}
              rating={this.props.avgFriendRate}
              starStyle={{
                marginHorizontal: perfectSize(2.5),
              }}
              disabled={true}
            />
          </TouchableOpacity>
          <Tooltip
            onOpen={this._openTooltip}
            onClose={this._closeTooltip}
            ref={this.tooltipRef}
            overlayColor={'transparent'}
            backgroundColor="black"
            containerStyle={styles.tooltip}
            popover={
              <Label>{I18n.t('VIEW_FRIEND_PROFILE_PAGE.tooltip')}</Label>
            }
          />
          <Label
            style={{
              opacity: 0.6,
              marginVertical: perfectSize(8),
              textAlign: 'center',
            }}>
            {viewFriendModel.bio}
          </Label>
          {(this.props.blockedList === undefined ||
            (this.props.blockedList !== undefined &&
              !this.props.blockedList.has(this.state.userKey))) && (
              <View style={[styles.flexRow]}>
                {this.props.friendsList === undefined ||
                  (this.props.friendsList !== undefined &&
                    !this.props.friendsList.filter(
                      (e) => e.UID === this.state.userKey,
                    ).length > 0 &&
                    this.props.uid !== this.state.userKey && (
                      <PrimaryButton
                        text={I18n.t('VIEW_FRIEND_PROFILE_PAGE.add_friend')}
                        type="outlined"
                        size="x_small"
                        onPress={() => {
                          FriendsListCore.addFriend(this.state.userKey);
                          presentToast({
                            message:
                              this.props.viewFriendModel.username +
                              I18n.t('FRIEND_LIST_PAGE.added_successfully'),
                          });
                        }}
                        customStyles={styles.btnAction}
                      />
                    ))}
                <PrimaryButton
                  text={I18n.t('VIEW_FRIEND_PROFILE_PAGE.chat')}
                  type="outlined"
                  size="x_small"
                  onPress={this.toPrivateChat}
                  customStyles={styles.btnAction}
                />
                {viewFriendModel.UID != this.props.uid && (
                  <PrimaryButton
                    onPress={this.showBlockAlert}
                    text={I18n.t('VIEW_FRIEND_PROFILE_PAGE.block_user')}
                    type="outlined"
                    size="x_small"
                    customStyles={styles.btnAction}
                  />
                )}
              </View>
            )}
        </View>
        <View
          style={[
            styles.tabView,
            { backgroundColor: theme.secondary_background },
          ]}>
          <TouchableOpacity
            onPress={() => {
              if (this.state.privacy_games) {
                presentToast({
                  message: I18n.t('USER_PROFILE_PAGE.games_protected'),
                });
                return;
              }
              if (Object.keys(this.state.games).length > 0)
                NavigationService.moveToScreenInStack(
                  'HomeStack',
                  'GameListPage',
                  {
                    name: viewFriendModel.username,
                    games: this.state.games,
                  },
                );
            }}>
            <View style={styles.tabItem}>
              <Label style={styles.tabItemName}>
                {I18n.t('USER_PROFILE_PAGE.games')}
              </Label>
              <Label
                bold
                style={[
                  styles.tabItemScore,
                  { color: theme.primary_btn_background },
                ]}>
                {this.props.friendGamesNumber}
              </Label>
            </View>
          </TouchableOpacity>

          <View style={styles.tabItem}>
            <Label style={styles.tabItemName}>
              {I18n.t('USER_PROFILE_PAGE.friends')}
            </Label>
            <Label
              bold
              style={[
                styles.tabItemScore,
                { color: theme.primary_btn_background },
              ]}>
              {this.props.friendFriendsNumber}
            </Label>
          </View>
          <TouchableOpacity
            onPress={() => {
              if (this.state.privacy_clans) {
                presentToast({
                  message: I18n.t('USER_PROFILE_PAGE.clans_protected'),
                });
                return;
              }
              if (this.state.friendClans.length > 0)
                NavigationService.moveToScreenInStack(
                  'HomeStack',
                  'UserClansPage',
                  {
                    clans: this.state.friendClans,
                    username: viewFriendModel.username,
                  },
                );
            }}>
            <View style={styles.tabItem}>
              <Label style={styles.tabItemName}>
                {I18n.t('USER_PROFILE_PAGE.teams')}
              </Label>
              <Label
                bold
                style={[
                  styles.tabItemScore,
                  { color: theme.primary_btn_background },
                ]}>
                {this.state.friendClans.length}
              </Label>
            </View>
          </TouchableOpacity>

          <View style={styles.tabItem}>
            <Label style={styles.tabItemName}>
              {I18n.t('USER_PROFILE_PAGE.hops_likes')}
            </Label>
            <Label
              bold
              style={[
                styles.tabItemScore,
                { color: theme.primary_btn_background },
              ]}>
              {this.state.hopsLikeCount}
            </Label>
          </View>

          <View style={styles.tabItem}>
            <Label style={styles.tabItemName}>
              {I18n.t('USER_PROFILE_PAGE.rating')}
            </Label>
            <Label
              bold
              style={[
                styles.tabItemScore,
                { color: theme.primary_btn_background },
              ]}>
              {this.props.friendRatesList.size}
            </Label>
          </View>
        </View>
        <View
          style={[
            styles.activityWrap,
            {
              marginBottom: perfectSize(18),
            },
          ]}>
          <Label bold>{I18n.t('USER_PROFILE_PAGE.recent_activites')}</Label>
        </View>
        <SimpleTabBar
          tabs={[I18n.t('MAIN_PAGE.Requests'), I18n.t('TABS.hops')]}
          activeTab={this.state.currentSubTab}
          onTabChanged={(tab) => this.onSubTabChanged(tab)}
          justifyContent="center"
        />
      </>
    );
  };

  _share = () => {
    //
    let message = `Check ${this.props.viewFriendModel.username} profile`;
    let subject = 'Hoplay';
    let img = this.props.shareIconsMap.SHARE_PROFILE;
    let url = 'https://hoplay.online/userprofile/' + this.state.userKey;
    // Share.open({
    //   message,
    //   subject,
    //   img,
    //   url,
    // });
    global.rankLogger.LOG_NEW_HP(global.rankLogger.SHARE_APP, 'SHARE_APP');
    global.featuresLogger.LOG_SHARE_APP();
  };

  render() {
    const { friendRecentList, showSpinner } = this.state
    const { theme } = this.props;
    let _friendRecentList = friendRecentList;
    _friendRecentList = _friendRecentList.sort((a, b) => {
      if (a.timeStamp < b.timeStamp) {
        return 1;
      }
      if (a.timeStamp > b.timeStamp) {
        return -1;
      }
      return 0;
    });
    return (
      <View style={AppStyles.rootContainer}>
        {/* Header */}
        <Header
          text="User Profile"
          rightComponent={
            <View style={styles.headerRight}>
            </View>
          }
        />
        <Spinner
          visible={this.state.showSpinner}
          textStyle={AppStyles.spinnerTextStyle}
        />
        {/* Content */}
        <View style={[AppStyles.rootContent]}>
          <SimpleTabBar
            tabs={[
              I18n.t('VIEW_FRIEND_PROFILE_PAGE.profile'),
              I18n.t('VIEW_FRIEND_PROFILE_PAGE.accounts'),
            ]}
            activeTab={this.state.currentTab}
            onTabChanged={(tab) => this.onTabChanged(tab)}
          />
          {this.state.currentTab === 0 && (
            <View style={{ flex: 1 }}>
              <View style={styles.activityWrap}>
                <ScrollView
                  showsVerticalScrollIndicator={false}
                  style={{
                    width: '100%',
                  }}>
                  {this.state.currentSubTab === 0 && this.listHeaderComponent()}

                  {this.state.currentSubTab === 0 &&
                    (!this.state.privacy_recent_requests ? (
                      _friendRecentList.length === 0 ? (
                        <View style={styles.emptyContent}>
                          <View style={AppStyles.emptyContent}>
                            <Label style={AppStyles.emptyContentLabel}>
                              {I18n.t('USER_PROFILE_PAGE.no_activity')}
                            </Label>
                          </View>
                        </View>
                      ) : (
                        showSpinner ?
                          <FlatList
                            style={{ width: '100%' }}
                            data={[0, 1, 2,]}
                            keyExtractor={(item, index) => String(index)}
                            showsVerticalScrollIndicator={false}
                            renderItem={() => {
                              return <View>
                                <Placeholder
                                  Animation={Progressive}
                                >
                                  <View >
                                    <View style={{
                                      paddingVertical: perfectSize(16),
                                      flexDirection: 'row',
                                    }}>
                                      <SkeletonUserAvatar isRound={false} size={50} style={{ borderRadius: 10 }} />
                                      <View style={{
                                        marginLeft: perfectSize(8),
                                        flex: 1,
                                        justifyContent: 'center',
                                      }}>
                                        <SkeletonTextContent height={15} width={50} style={{ marginBottom: 10 }} />
                                        <View style={{
                                          flexDirection: 'row',
                                          marginTop: perfectSize(4),
                                          justifyContent: 'space-between'
                                        }}>
                                          <SkeletonTextContent height={15} width={70} />
                                          <SkeletonTextContent height={15} width={10} />
                                        </View>
                                      </View>
                                    </View>
                                    <View style={{ height: 1, widht: '100%' }} />
                                  </View>
                                </Placeholder>

                              </View>
                            }}
                          />
                          :
                          _friendRecentList.map((item, index) => {
                            return (
                              <ActivityItem
                                key={index.toString()}
                                activity={{
                                  ...item,
                                  gameName: this.props.gamesList[item.gameId]
                                    .gameName,
                                  gamePhotoUrl: this.props.gamesList[item.gameId]
                                    .gamePhotoUrl,
                                }}
                                onPress={() => {
                                  if (item.requestId) {
                                    NavigationService.navigate('LobbyPage', {
                                      lastReq: {
                                        requestId: item.requestId,
                                        platform: item.supportedPlatforms,
                                        gameId: item.gameId,
                                        region: item.region,
                                      },
                                    });
                                  }
                                }}
                              />
                            );
                          })
                      )
                    ) : (
                      <View style={styles.emptyContent}>
                        <View style={AppStyles.emptyContent}>
                          <Label style={AppStyles.emptyContentLabel}>
                            {I18n.t('USER_PROFILE_PAGE.request_protected')}
                          </Label>
                        </View>
                      </View>
                    ))}

                  {this.state.currentSubTab === 1 &&
                    (!this.state.privacy_recent_hops ? (
                      <FlatList
                        ListHeaderComponent={this.listHeaderComponent()}
                        data={this.state.myHops}
                        renderItem={(item, index) => {
                          return (
                            <HopCard
                              item={item.item}
                              onPress={() => this.goToDetails(item.item)}
                              disableLike={true}
                              from="profile"
                            />
                          );
                        }}
                        keyExtractor={(item, index) => String(index)}
                        onEndReached={async ({ distanceFromEnd }) => {
                          await this.loadUserHops(this.state.userKey);
                        }}
                        onEndReachedThreshold={0.5}
                        ListEmptyComponent={
                          !this.state.refreshing &&
                          <View style={styles.emptyContent}>
                            <View style={AppStyles.emptyContent}>
                              <Label style={AppStyles.emptyContentLabel}>
                                {I18n.t('USER_PROFILE_PAGE.no_hops')}
                              </Label>
                            </View>
                          </View>
                        }
                        ListFooterComponent={
                          this.state.hopsHasMoreData &&
                            this.state.refreshing ? (
                            <FlatList
                              data={dataForSkeleton(3)}
                              renderItem={() => {
                                return <HopSkeletonCard />
                              }}
                              keyExtractor={(item) => item.toString()}
                            />
                          ) : (
                            <View />
                          )
                        }
                      />
                    ) : (
                      <View>
                        {this.listHeaderComponent()}
                        <View style={styles.emptyContent}>
                          <View style={AppStyles.emptyContent}>
                            <Label style={AppStyles.emptyContentLabel}>
                              {I18n.t('USER_PROFILE_PAGE.hops_protected')}
                            </Label>
                          </View>
                        </View>
                      </View>
                    ))}
                </ScrollView>
              </View>
            </View>
          )}
          {this.state.currentTab === 1 && (
            <UserProfileAccountsPage userKey={this.state.userKey} />
          )}
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
    lang: state.appReducer.currentLang,
    uid: state.authReducer.uid,
    userinformationDB: state.authReducer.userinformationDB,
    viewFriendModel: state.hubReducer.viewFriendModel,
    friendGamesNumber: state.hubReducer.friendGamesNumber,
    friendFriendsNumber: state.hubReducer.friendFriendsNumber,
    friendRatesList: state.hubReducer.friendRatesList,
    friendRecentList: state.hubReducer.friendRecentList,
    friendStatus: state.hubReducer.friendStatus,
    friendHP: state.hubReducer.friendHP,
    gamesList: state.hubReducer.gamesList,
    friendsList: state.hubReducer.friendsList,
    blockedList: state.hubReducer.blockedList,
    allPrivateChatRefs: state.hubReducer.allPrivateChatRefs,
    shareIconsMap: state.hubReducer.shareIconsMap,
    avgFriendRate: state.hubReducer.avgFriendRate,
    valueChanged: state.hubReducer.valueChanged,
  };
};

export default compose(connect(mapStateToProps, { HubSet, showAlert }))(
  ViewfriendprofilePage,
);
