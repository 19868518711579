import {metaTypes} from '../firebase/actionTypes';
import * as saga from '../firebase/saga';

import {all} from 'redux-saga/effects';

// function to handle last requests result
export function handleLastRequests(shot) {
  let item = new Map();
  // eslint-disable-next-line no-unused-vars
  for (let key in shot) {
    if (shot.hasOwnProperty(key)) {
      let obj = shot[key];
      if (obj.adminName !== null) {
        item.set(obj.requestId, obj);
      }
    }
  }
  return Array.from(item.values()).reverse();
}

export function handleUpdateLastRequests() {
  return {};
}

export function getRemovePath(param) {
  return `users/${param.uid}/contacts/${param.contactId}`;
}

export default function* () {
  yield all([
    saga.watchListener(metaTypes.lastRequests, handleLastRequests, 25),
    saga.watchUpdateRequested(handleUpdateLastRequests),
    saga.watchRemoveRequested(getRemovePath),
  ]);
}
