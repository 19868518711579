import {StyleSheet} from 'react-native';
import {perfectSize} from '../../helpers/deviceHelper';

export default StyleSheet.create({
  headerRight: {
    flexDirection: 'row-reverse',
  },
  actionBtn: {
    padding: perfectSize(4),
    marginRight: perfectSize(20),
  },
  pinkLabel: {
    color: '#C70064',
  },
  wrapper: {
    flex: 1,
    marginTop: perfectSize(8),
  },
  scroll: {
    backgroundColor: '#0E1319',
  },
  post: {
    marginBottom: perfectSize(32),
  },
});
