import {takeLatest, put, select} from 'redux-saga/effects';
import {
  addGame,
  addAllGame,
  deleteGame,
  addGameSuccess,
  addAllGameSuccess,
  deleteGameSuccess,
} from './actions';
import * as FirebasePaths from '../../../constants/firebasePaths';

const getGamesCompList = (state) => state.gameManagerReducer.gamesCompList;
const getAllGamesIds = (state) => state.gameManagerReducer.allGamesIds;
const getAllGamesNames = (state) => state.gameManagerReducer.allGamesName;
const getGamesCOOPList = (state) => state.gameManagerReducer.gamesCOOPList;

function* addGameWorker({payload}) {
  try {
    // yield put(addGameSuccess(payload));
    let gamesCompList = yield select(getGamesCompList);
    let allGamesIds = yield select(getAllGamesIds);
    let allGamesName = yield select(getAllGamesNames);
    let gamesCOOPList = yield select(getGamesCOOPList);

    if (allGamesIds.filter((e) => e.gameID === payload.gameID).length === 0) {
      allGamesIds.push(payload);
    }

    if (
      allGamesName.filter((e) => e.gameName === payload.gameName).length === 0
    ) {
      allGamesName.push(payload);
    }

    payload.gameType === FirebasePaths.FIREBASE_GAME_COMPETITVE_ATTR &&
      gamesCompList.filter((e) => e.gameID === payload.gameID).length === 0 &&
      gamesCompList.push(payload); //.set(payload.gameID, payload)

    payload.gameType !== FirebasePaths.FIREBASE_GAME_COMPETITVE_ATTR &&
      gamesCOOPList.filter((e) => e.gameID === payload.gameID).length === 0 &&
      gamesCOOPList.push(payload); //.set(payload.gameID, payload)

    yield put(
      addGameSuccess({allGamesIds, allGamesName, gamesCompList, gamesCOOPList}),
    );
  } catch (err) {}
}

function* addAllGameWorker({payload}) {
  try {
    // yield put(addGameSuccess(payload));
    let gamesCompList = yield select(getGamesCompList);
    let allGamesIds = yield select(getAllGamesIds);
    let allGamesName = yield select(getAllGamesNames);
    let gamesCOOPList = yield select(getGamesCOOPList);
    payload.forEach((game) => {
      if (allGamesIds.filter((e) => e.gameID === game.gameID).length === 0) {
        allGamesIds.push(game);
      }

      if (
        allGamesName.filter((e) => e.gameName === game.gameName).length === 0
      ) {
        allGamesName.push(game);
      }

      game.gameType === FirebasePaths.FIREBASE_GAME_COMPETITVE_ATTR &&
        gamesCompList.filter((e) => e.gameID === game.gameID).length === 0 &&
        gamesCompList.push(game); //.set(payload.gameID, payload)

      game.gameType !== FirebasePaths.FIREBASE_GAME_COMPETITVE_ATTR &&
        gamesCOOPList.filter((e) => e.gameID === game.gameID).length === 0 &&
        gamesCOOPList.push(game); //.set(payload.gameID, payload)
    });

    yield put(
      addAllGameSuccess({
        allGamesIds,
        allGamesName,
        gamesCompList,
        gamesCOOPList,
      }),
    );
  } catch (err) {}
}

function* deleteGameWorker({payload}) {
  const {gameId, gameName} = payload;
  let gamesCompList = yield select(getGamesCompList);
  let allGamesIds = yield select(getAllGamesIds);
  let allGamesName = yield select(getAllGamesNames);
  allGamesIds.splice(
    allGamesIds.findIndex((v) => v.gameID === gameId),
    1,
  );
  allGamesName.splice(
    allGamesName.findIndex((v) => v.gameName === gameName),
    1,
  );
  // if (gamesCompList.has(gameId)) {
  if (gamesCompList.filter((e) => e.gameID === gameId).length > 0) {
    // gamesCompList.delete(gameId);
    gamesCompList.splice(
      gamesCompList.findIndex((v) => v.gameID === gameId),
      1,
    );
    yield put(deleteGameSuccess({gamesCompList, allGamesIds, allGamesName}));
  } else {
    let gamesCOOPList = yield select(getGamesCOOPList);
    // gamesCOOPList.delete(gameId);
    gamesCOOPList.splice(
      gamesCOOPList.findIndex((v) => v.gameID === gameId),
      1,
    );
    yield put(deleteGameSuccess({gamesCOOPList, allGamesIds, allGamesName}));
  }
}

export default function* () {
  yield takeLatest(addGame, addGameWorker);
  yield takeLatest(addAllGame, addAllGameWorker);
  yield takeLatest(deleteGame, deleteGameWorker);
}
