import React, {memo, Component} from 'react';
import {View, StyleSheet, TouchableOpacity} from 'react-native';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import {perfectSize} from '../../helpers/deviceHelper';

import HopReplyDetail from './detail';
import Postcore from '../../pages/post/postcore';

class HopReply extends Component {
  getReplyLikesCount(reply) {
    if (reply._replies_likes_) {
      const obj = reply._replies_likes_;

      if (obj[reply.id]._likes_) {
        return Object.keys(obj[reply.id]._likes_).length;
      }
    }

    return 0;
  }

  getReplyDislikesCount(reply) {
    if (reply._replies_likes_) {
      const obj = reply._replies_likes_;

      if (obj[reply.id]._dislikes_) {
        return Object.keys(obj[reply.id]._dislikes_).length;
      }
    }

    return 0;
  }

  isLiked(reply) {
    if (reply._replies_likes_) {
      const obj = reply._replies_likes_;

      if (obj[reply.id]._likes_) {
        if (this.props.uid in obj[reply.id]._likes_) {
          return true;
        }
      }
    }

    return false;
  }

  isDisliked(reply) {
    if (reply._replies_likes_) {
      const obj = reply._replies_likes_;

      if (obj[reply.id]._dislikes_) {
        if (this.props.uid in obj[reply.id]._dislikes_) {
          return true;
        }
      }
    }

    return false;
  }

  thumbUp = () => {
    Postcore.thumbUpReply(this.props.item);
  };

  thumbDown = () => {
    Postcore.thumbDownReply(this.props.item);
  };

  render() {
    const {theme} = this.props;
    const _styles = styles(theme);
    const item = this.props.item;
    return (
      <View style={[_styles.container]}>
        <TouchableOpacity
          onPress={this.props.onPress}
          onLongPress={() =>
            this.props.onLongPress && this.props.onLongPress()
          }>
          <HopReplyDetail
            style={_styles.detailCard}
            item={item}
            onDelete={this.props.onLongPress}
          />
        </TouchableOpacity>
      </View>
    );
  }
}

HopReply.propTypes = {
  data: PropTypes.object,
  isJoined: PropTypes.bool,
  onJoin: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
    uid: state.authReducer.uid,
  };
};

export default memo(connect(mapStateToProps)(HopReply));

const styles = (theme) =>
  StyleSheet.create({
    container: {
      marginHorizontal: perfectSize(0.5),
      marginBottom: perfectSize(16),
      borderRadius: perfectSize(12),
      padding: perfectSize(16),
      backgroundColor: theme.secondary_btn_background,
    },
    detailCard: {
      marginHorizontal: 0,
      overflow: 'hidden',
    },
    footer: {
      flexDirection: 'row',
      marginTop: perfectSize(8),
    },
    flexRow: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    upvoteText: {
      color: theme.primary_btn_background,
    },
    downvoteText: {
      opacity: 0.56,
    },
    thumb: {
      marginLeft: perfectSize(4),
    },
    comment: {
      width: perfectSize(12),
      height: perfectSize(12),
      marginLeft: perfectSize(4),
    },
    imgGroup: {
      width: perfectSize(17),
      height: perfectSize(14),
      marginRight: perfectSize(4),
    },
    joinButton: {},
    divider: {
      height: 1,
      backgroundColor: 'white',
      opacity: 0.05,
      marginTop: perfectSize(16),
    },
  });
