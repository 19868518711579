import I18n from 'i18n-js';
import {store} from './redux/store';

import ar from '../assets/i18n/ar.json';
import en from '../assets/i18n/en.json';

I18n.fallbacks = true;
I18n.translations = {
  ar,
  en,
};
I18n.switchLanguage = (lang) => {
  I18n.locale = lang;
};

store.subscribe(listener);
function listener() {
  const lang = store.getState().appReducer.currentLang;
  I18n.locale = lang || 'en';
}

export default I18n;
