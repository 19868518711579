import {StyleSheet} from 'react-native';
import {perfectSize} from '../../helpers/deviceHelper';
import Dimens from '../../constants/dimens';

const styles = (theme) =>
  StyleSheet.create({
    container: {
      flex: 1,
    },
    cover: {
      width: '100%',
      height: 240,
    },
    backArrow: {
      position: 'absolute',
      width: Dimens.BAR_BUTTON_SIZE,
      height: Dimens.BAR_BUTTON_SIZE,
      justifyContent: 'center',
      alignItems: 'center',
      margin: perfectSize(12),
      zIndex: 2,
    },
    scrollContainer: {
      flex: 1,
      marginTop: -Dimens.BODY_BORDER_TOP_RADIUS,
      borderTopLeftRadius: Dimens.BODY_BORDER_TOP_RADIUS,
      borderTopRightRadius: Dimens.BODY_BORDER_TOP_RADIUS,
      paddingTop: perfectSize(24),
      paddingHorizontal: perfectSize(24),
      paddingBottom: 0,
      backgroundColor: theme.primary_background,
    },
    adDesc: {
      lineHeight: 23,
      marginTop: 24.5,
      opacity: 0.56,
    },
    itemRow: {
      marginTop: 16,
      flexDirection: 'row',
    },
    label: {
      opacity: 0.56,
    },
    titleLabel: {
      opacity: 0.56,
      width: '50%',
    },
    valueLabel: {
      opacity: 0.56,
      width: '50%',
      textAlign: 'right',
    },
    titleCol: {
      width: '50%',
    },
    valueCol: {
      width: '50%',
      alignItems: 'flex-end',
    },
    seperator: {
      height: 1,
      backgroundColor: theme.primary_text,
      opacity: 0.15,
      marginVertical: perfectSize(16),
    },
  });

export default styles;
