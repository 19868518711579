import {StyleSheet} from 'react-native';
import {perfectSize} from '../../helpers/deviceHelper';

export default StyleSheet.create({
  // Header
  headerRight: {
    flexDirection: 'row-reverse',
  },
  actionBtn: {
    padding: perfectSize(4),
    marginRight: perfectSize(20),
  },
  pinkLabel: {
    color: '#C70064',
  },
  // Content
  submitBtn: {
    marginTop: perfectSize(32),
    marginBottom: perfectSize(16),
  },
  emptyContent: {
    height: '90%',
    justifyContent: 'center',
  },
});
