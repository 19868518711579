import {StyleSheet} from 'react-native';
import {perfectSize} from '../../helpers/deviceHelper';

const styles = (theme) =>
  StyleSheet.create({
    scrollView: {
      marginTop: perfectSize(16),
    },
    textInput: {
      marginBottom: perfectSize(16),
    },
    picker: {
      marginBottom: perfectSize(16),
    },
    submitBtn: {
      marginBottom: perfectSize(16),
    },
  });

export default styles;
