/* eslint-disable react-native/no-inline-styles */
import React, {Component} from 'react';
import {View, TouchableOpacity, Linking} from 'react-native';
import {compose} from 'redux';
import {connect} from 'react-redux';

import I18n from '../../i18n';

// import components
import Label from '../../components/label';
import Header from '../../components/header';
import NavigationService from '../../navigation/navigationService';

import {SEND, WHATSAPP, EMAIL} from '../../constants/images';
import styles from './styles';
import LocalImageOpacity from '../../components/local-image-opacity';

class SupportPage extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount = () => {};

  _shareWhatsApp = () => {
    global.featuresLogger.LOG_FEEDBACKS_WHATSAPP();
    Linking.openURL('whatsapp://send?phone=+966544810485');
  };

  _shareEmail = () => {
    global.featuresLogger.LOG_FEEDBACKS_EMAIL();
    // const shareOptions = {
    //   title: 'Share via',
    //   message: '',
    //   social: Share.Social.EMAIL,
    // };
    // Share.shareSingle(shareOptions);
    Linking.openURL('mailto:support@hoplay.online');
  };

  render() {
    const {theme} = this.props;
    return (
      <View style={[styles(theme).container]}>
        <Header
          onBack={this.toLogin}
          text={I18n.t('SUPPORT_PAGE.title')}
          style={[styles(theme).header]}
        />
        <View style={styles(theme).wrapper}>
          <Label style={{color: 'white'}} size="medium" bold={true}>
            {I18n.t('SUPPORT_PAGE.title')}
          </Label>
          <Label size="x_small" style={styles(theme).description}>
            {I18n.t('CONTACT_US_PAGE.page_message')}
          </Label>

          <TouchableOpacity
            style={styles(theme).btn}
            onPress={() =>
              NavigationService.navigate('ContactUsPage', {
                CAME_FROM: 'support',
              })
            }>
            <View style={styles(theme).btnContainer}>
              <LocalImageOpacity
                source={SEND}
                resizeMethod="resize"
                style={styles(theme).imgBack}
                resizeMode="contain"
              />
              <Label size="x_small" bold={true} style={styles(theme).btnLabel}>
                {I18n.t('CONTACT_US_PAGE.message_btn')}
              </Label>
            </View>
          </TouchableOpacity>

          <TouchableOpacity
            style={styles(theme).btn}
            onPress={this._shareWhatsApp}>
            <View style={styles(theme).btnContainer}>
              <LocalImageOpacity
                source={WHATSAPP}
                resizeMethod="resize"
                style={styles(theme).imgBack}
                resizeMode="contain"
              />
              <Label size="x_small" bold={true} style={styles(theme).btnLabel}>
                {I18n.t('CONTACT_US_PAGE.call_btn')}
              </Label>
            </View>
          </TouchableOpacity>

          <TouchableOpacity
            style={styles(theme).btn}
            onPress={this._shareEmail}>
            <View style={styles(theme).btnContainer}>
              <LocalImageOpacity
                source={EMAIL}
                resizeMethod="resize"
                style={styles(theme).imgBack}
                resizeMode="contain"
              />
              <Label size="x_small" bold={true} style={styles(theme).btnLabel}>
                {I18n.t('CONTACT_US_PAGE.email_btn')}
              </Label>
            </View>
          </TouchableOpacity>
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
  };
};

export default compose(connect(mapStateToProps, {}))(SupportPage);
