import {StyleSheet} from 'react-native';
import {perfectSize} from '../../helpers/deviceHelper';
import Dimens from '../../constants/dimens';

const styles = (theme) =>
  StyleSheet.create({
    container: {
      flex: 1,
      width: '100%',

      backgroundColor: theme.secondary_background,
    },
    wrapper: {
      flex: 1,
      padding: Dimens.HEADER_PADDING_HORIZONTAL,
      backgroundColor: theme.primary_background,
      borderTopLeftRadius: Dimens.BODY_BORDER_TOP_RADIUS,
      borderTopRightRadius: Dimens.BODY_BORDER_TOP_RADIUS,
    },
    header: {
      backgroundColor: theme.secondary_background,
    },
    label: {
      marginTop: 16,
    },
    btn: {
      marginVertical: 8,
    },
    btnContainer: {
      backgroundColor: theme.btn_tag,
      padding: 16,
      alignItems: 'center',
      borderRadius: 6,
    },
    btnLabel: {
      marginTop: 4,
      color: 'white',
    },
    description: {
      color: '#95979A',
      marginTop: 20,
      marginBottom: 20,
    },
    imgBack: {
      width: perfectSize(32),
      height: perfectSize(32),
      resizeMode: 'contain',
    },
  });

export default styles;
