import {StyleSheet, Dimensions} from 'react-native';
import {perfectSize} from '../../helpers/deviceHelper';
import Dimens from '../../constants/dimens';

const styles = (theme) =>
  StyleSheet.create({
    roundButtons: {
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    roundButton: {
      width:
        (Dimensions.get('window').width -
          2 * Dimens.BODY_PADDING_HORIZONTAL -
          perfectSize(8) * 3) /
        4,
      height: perfectSize(24),
      marginBottom: perfectSize(8),
    },
    flatList: {
      marginTop: perfectSize(24),
    },
    card: {
      backgroundColor: '#181F29',
      flexDirection: 'row',
      padding: perfectSize(16),
      borderRadius: perfectSize(8),
    },
    sideArea: {
      flex: 1,
      alignItems: 'center',
    },
    avatar: {
      marginBottom: perfectSize(4),
    },
    nameLabel: {
      marginTop: perfectSize(4),
    },
    centerArea: {
      flex: 1,
      paddingHorizontal: perfectSize(36),
      justifyContent: 'center',
      alignItems: 'center',
    },
    scoresWrap: {
      flex: 1,
      flexDirection: 'row',
      marginTop: perfectSize(8),
      width: '100%',
      justifyContent: 'space-between'
    },
    emptyContent: {
      marginTop: perfectSize(16),
    },
    emptyContentLabel: {
      marginTop: 0,
    },
  });

export default styles;
