import React, {memo, Component} from 'react';
import {StyleSheet, ScrollView, View} from 'react-native';
import {connect} from 'react-redux';

import Label from '../../components/label';
import {perfectSize} from '../../helpers/deviceHelper';

class Rules extends Component {
  constructor(props) {
    super(props);
    const params = props.route.params;
    this.state = {
      championshipObj: params.championshipObj,
    };
  }

  render() {
    const {theme} = this.props;
    const _styles = styles(theme);
    const {championshipObj} = this.state;
    return (
      <ScrollView style={_styles.content}>
        {championshipObj.rule1 != null && (
          <View>
            <Label style={_styles.title}>{championshipObj.rule1Title}</Label>
            <Label style={_styles.value}>{championshipObj.rule1}</Label>
          </View>
        )}
        {championshipObj.rule2 != null && (
          <View>
            <Label style={_styles.title}>{championshipObj.rule2Title}</Label>
            <Label style={_styles.value}>{championshipObj.rule2}</Label>
          </View>
        )}
        {championshipObj.rule3 != null && (
          <View>
            <Label style={_styles.title}>{championshipObj.rule3Title}</Label>
            <Label style={_styles.value}>{championshipObj.rule3}</Label>
          </View>
        )}
        {championshipObj.rule4 != null && (
          <View>
            <Label style={_styles.title}>{championshipObj.rule4Title}</Label>
            <Label style={_styles.value}>{championshipObj.rule4}</Label>
          </View>
        )}
        {championshipObj.rule5 != null && (
          <View>
            <Label style={_styles.title}>{championshipObj.rule5Title}</Label>
            <Label style={_styles.value}>{championshipObj.rule5}</Label>
          </View>
        )}
        {championshipObj.rule6 != null && (
          <View>
            <Label style={_styles.title}>{championshipObj.rule6Title}</Label>
            <Label style={_styles.value}>{championshipObj.rule6}</Label>
          </View>
        )}
        {championshipObj.rule7 != null && (
          <View>
            <Label style={_styles.title}>{championshipObj.rule7Title}</Label>
            <Label style={_styles.value}>{championshipObj.rule7}</Label>
          </View>
        )}
        {championshipObj.rule8 != null && (
          <View>
            <Label style={_styles.title}>{championshipObj.rule8Title}</Label>
            <Label style={_styles.value}>{championshipObj.rule8}</Label>
          </View>
        )}
        {championshipObj.rule9 != null && (
          <View>
            <Label style={_styles.title}>{championshipObj.rule9Title}</Label>
            <Label style={_styles.value}>{championshipObj.rule9}</Label>
          </View>
        )}
        {championshipObj.rule10 != null && (
          <View>
            <Label style={_styles.title}>{championshipObj.rule10Title}</Label>
            <Label style={_styles.value}>{championshipObj.rule10}</Label>
          </View>
        )}
      </ScrollView>
    );
  }
}

const styles = (theme) =>
  StyleSheet.create({
    content: {
      backgroundColor: theme.primary_background,
    },
    title: {
      fontSize: perfectSize(12),
      marginBottom: perfectSize(8),
    },
    value: {
      fontSize: perfectSize(12),
      color: '#A2A5A9',
      marginBottom: perfectSize(16),
    },
  });

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
  };
};

export default memo(connect(mapStateToProps)(Rules));
