/* eslint-disable react-native/no-inline-styles */
import React, {memo, Component} from 'react';
import {Text, TouchableOpacity, StyleSheet} from 'react-native';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import {getFontSize} from '../../../constants/calc';
import {perfectSize} from '../../../helpers/deviceHelper';
import {FONT_FAMILY, FONT_SIZE} from '../../../constants/theme';

class NormalButton extends Component {
  render() {
    const {
      theme,
      text,
      size,
      onPress,
      isPrimary,
      customStyles,
      textCustomStyles,
    } = this.props;
    const fontSize = size ? getFontSize(size) : FONT_SIZE.small;
    const _backgroundColor = isPrimary
      ? theme.primary_btn_background
      : theme.secondary_btn_background;
    const fontFamily =
      this.props.lang === 'en' ? FONT_FAMILY.ubuntu : FONT_FAMILY.cairo;
    return (
      <TouchableOpacity
        style={[
          styles(theme).container,
          {
            backgroundColor: _backgroundColor,
          },
          customStyles,
        ]}
        onPress={() => onPress && onPress()}>
        <Text
          style={[
            {
              fontSize: fontSize,
              fontFamily: fontFamily,
              color: theme.primary_text,
              textAlign: 'center',
              lineHeight: perfectSize(20),
            },
            textCustomStyles,
          ]}>
          {text}
        </Text>
      </TouchableOpacity>
    );
  }
}

NormalButton.defaultProps = {
  isPrimary: true,
};

NormalButton.propTypes = {
  text: PropTypes.string,
  onPress: PropTypes.func,
  size: PropTypes.oneOf([
    'small',
    'x_small',
    'medium',
    'x_medium',
    'big',
    'x_big',
    'large',
    'x_large',
  ]),
  isPrimary: PropTypes.bool,
  customStyles: PropTypes.any,
  textCustomStyles: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
    lang: state.appReducer.currentLang,
  };
};

export default memo(connect(mapStateToProps)(NormalButton));

const styles = (theme) =>
  StyleSheet.create({
    container: {
      height: 25,
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: perfectSize(12),
      alignSelf: 'flex-start',
      paddingHorizontal: perfectSize(16),
    },
  });
