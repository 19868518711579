import {StyleSheet} from 'react-native';
import {perfectSize} from '../../helpers/deviceHelper';

export default StyleSheet.create({
  inputBoxCustomStyle: {
    height: perfectSize(200),
    padding: perfectSize(16),
    marginTop: perfectSize(24),
  },
  inputBoxStyle: {
    height: '100%',
    padding: 0,
  },
  sendBtn: {
    marginTop: perfectSize(64),
  },
  imagePanel: {
    borderStyle: 'dotted',
    borderColor: '#7D8693',
    borderWidth: perfectSize(1),
    borderRadius: perfectSize(12),
    padding: perfectSize(16),
    flexDirection: 'row',
    marginTop: perfectSize(16),
  },
  addBtn: {
    borderStyle: 'dotted',
    borderColor: '#7D8693',
    borderWidth: perfectSize(1),
    borderRadius: perfectSize(12),
    padding: perfectSize(16),
    // aspectRatio: perfectSize(1),
    width: '22%',
    aspectRatio: 1,
    height: undefined,
    justifyContent: 'center',
    overflow: 'hidden',
    marginRight: '3%',
  },
  addIcon: {
    fontSize: 28,
    textAlign: 'center',
  },
  img: {
    width: '22%',
    aspectRatio: 1,
    padding: 0,
    borderWidth: 0,
  },
  flexFull: {
    width: '100%',
    height: '100%',
  },
});
