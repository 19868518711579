import {StyleSheet} from 'react-native';
import {perfectSize} from '../../helpers/deviceHelper';

const styles = (theme) =>
  StyleSheet.create({
    mt16: {
      marginTop: perfectSize(16),
    },
    itemContainer: {
      height: perfectSize(60),
      flexDirection: 'row',
      alignItems: 'center',
      borderBottomWidth: 1,
      borderBottomColor: '#FFFFFF0C',
    },
    avatar: {
      width: perfectSize(48),
      height: perfectSize(48),
      borderRadius: perfectSize(12),
    },
    bell: {
      width: perfectSize(16),
      height: perfectSize(16),
      resizeMode: 'contain',
    },
    mv16: {
      marginVertical: perfectSize(16),
    },
    btnAction: {
      paddingHorizontal: perfectSize(16),
    },
  });

export default styles;
