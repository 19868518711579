/* eslint-disable react-native/no-inline-styles */
import React, {Component} from 'react';
import {
  View,
  FlatList,
  TouchableOpacity,
  ActivityIndicator,
} from 'react-native';
import {compose} from 'redux';
import {connect} from 'react-redux';
import firebase from 'firebase/app';
import 'firebase/database';

import _ from 'lodash';
import Feather from 'react-native-vector-icons/Feather';
import I18n from '../../i18n';
import {store} from '../../redux/store';
import * as FirebasePaths from '../../constants/firebasePaths';
import NavigationService from '../../navigation/navigationService';

// import components
import Header from '../../components/header';
import Label from '../../components/label';
import UserAvatar from '../../components/user-avatar';
import TextInput from '../../components/text-input';

// import actions
import {showAlert} from '../../redux/modules/alert/actions';
import {set as setHub} from '../../redux/modules/hub/actions';

import Teamcore from '../team/teamscore';

import AppStyles from '../../constants/styles';
import styles from './styles';
import {perfectSize} from '../../helpers/deviceHelper';
import {presentToast} from '../../helpers/toastHelper';
import {FONT_FAMILY} from '../../constants/theme';

class SelectNewAdminPage extends Component {
  constructor(props) {
    super(props);
    const params = props.navigation.state.params;
    this.state = {
      teamId: params.TEAM_ID,
      teamName: params.TEAM_NAME,
      membersMap: new Map(),
      membersArr: [],
      tmpMembersArr: [],
      isSearching: false,
    };
  }

  componentDidMount() {
    // We are going to use the team provider to load the members
    // Load members
    Teamcore.loadMembers(this.state.teamId).then((shot) => {
      let membersMap = new Map();
      shot.forEach((item) => {
        let member = item.val();
        membersMap.set(member.uid, member);
      });
      this.setState({
        membersMap,
        membersArr: Array.from(membersMap.values()),
        tmpMembersArr: Array.from(membersMap.values()),
      });
    });
  }

  // Search for player trigger
  searchForPlayerTrigger = (value) => {
    if (value !== '') {
      // Filter searching
      let membersArr = this.state.tmpMembersArr.filter(function (item) {
        return item.username
          .toString()
          .toLowerCase()
          .includes(value.toString().toLowerCase());
      });
      this.setState({membersArr});
    } else {
      let membersArr = this.state.tmpMembersArr;
      this.setState({membersArr});
    }
  };

  // This function will show a confirm message to select the new admin
  showNewAdminConfirmAlert = (member) => {
    // Alert
    let msg = I18n.t(
      'SELECT_NEW_ADMIN_PAGE.select_new_admin_confirm_alert_msg',
    );
    let yes = I18n.t(
      'SELECT_NEW_ADMIN_PAGE.select_new_admin_confirm_alert_yes',
    );
    let no = I18n.t('SELECT_NEW_ADMIN_PAGE.select_new_admin_confirm_alert_no');

    this.props.showAlert([
      member.username,
      msg,
      [
        {
          // yes
          text: yes,
          onPress: () => {
            // Set a new admin ref
            this.setNewTeamAdmin(member.uid);
            // Show a success message
            presentToast({
              message: I18n.t('SELECT_NEW_ADMIN_PAGE.exit_team_success_msg'),
            });
            // Update local teams fdor the admin
            let userTeamsArr = store.getState().hubReducer.userTeamsArr;
            userTeamsArr = userTeamsArr.filter(
              (item) => item.id !== this.state.teamId,
            );
            this.props.setHub({userTeamsArr});
            // pop this activity
            NavigationService.goBack();
          },
        },
        {
          // no
          text: no,
          onPress: () => {},
        },
      ],
    ]);
  };

  // This function will set the new admin for the team + notifiy the new admin
  setNewTeamAdmin(newAdminKey) {
    const {teamId, teamName} = this.state;
    const {uid} = this.props;
    // get the team path
    let teamPath = FirebasePaths.FIREBASE_TEAMS + '/' + teamId + '/';

    // Update admin key
    firebase.database()
      .ref(teamPath + '/' + FirebasePaths.FIREBASE_TEAM_INFO)
      .child('admin')
      .set(newAdminKey);
    // Remove the new admin from the members
    firebase.database()
      .ref(
        teamPath +
          '/' +
          FirebasePaths.FIREBASE_TEAM_MEMBERS +
          '/' +
          newAdminKey,
      )
      .remove();

    // Now notify the new admin
    // Cretea notification obj
    // Insert a notification obj to the new admin
    // Create a push id for the notification text
    let notificationId = firebase.database().ref().push().key;
    // Create a notification Obj
    let notificationObj = {
      id: notificationId,
      status: 'UNREAD',
      body: teamName + ' ' + ' رشحوك لمنصب مشرف الفريق وانت الحين المشرف ',
      type: 'TEAM',
      TRIGGER: teamId,
      timeStamp: firebase.database.ServerValue.TIMESTAMP,
    };

    let userNotifications =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      newAdminKey +
      '/' +
      FirebasePaths.FIREBASE_USER_NOTIFICATIONS +
      '/' +
      notificationId;

    firebase.database().ref(userNotifications).set(notificationObj);

    // For the team
    let teamNotificationsPath =
      FirebasePaths.FIREBASE_TEAMS +
      '/' +
      teamId +
      '/' +
      FirebasePaths.FIREBASE_TEAM_NOTIFICATIONS +
      '/' +
      notificationId;
    firebase.database().ref(teamNotificationsPath).set(notificationObj);

    // Remove team ref from the previous admin
    let currentAdminRef =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      uid +
      '/' +
      FirebasePaths.FIREBASE_USER_TEAMS +
      '/' +
      FirebasePaths.FIREBASE_USER_TEAMS_REFS +
      '/' +
      teamId;
    firebase.database().ref(currentAdminRef).remove();

    // Remove the user clan chat ref
    let userClanChatRef =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      uid +
      '/' +
      FirebasePaths.FIREBASE_CLANS_CHATS_USER_REF +
      '/' +
      teamId;
    // Remove the ref
    firebase.database().ref(userClanChatRef).remove();
  }

  renderItem = ({item, index}) => {
    const {theme} = this.props;
    const _styles = styles(theme);
    return (
      <TouchableOpacity
        style={_styles.itemContainer}
        onPress={() => this.showNewAdminConfirmAlert(item)}>
        <UserAvatar
          src={item.photo}
          size={perfectSize(48)}
          borderColor={item.borderColor}
        />
        <Label size="x_small" style={{marginTop: perfectSize(8)}}>
          {item.username}
        </Label>
      </TouchableOpacity>
    );
  };

  render() {
    const {isSearching, membersArr} = this.state;
    const fontFamily =
      this.props.lang === 'en' ? FONT_FAMILY.ubuntu : FONT_FAMILY.cairo;
    return (
      <View style={AppStyles.rootContainer}>
        <Header text={I18n.t('SELECT_NEW_ADMIN_PAGE.title')} />
        <View style={AppStyles.rootContent}>
          <View
            style={[
              AppStyles.textInputContainer,
              {paddingHorizontal: 0, marginBottom: perfectSize(16)},
            ]}>
            <TextInput
              customStyles={[AppStyles.textInput, {fontFamily: fontFamily}]}
              placeholder={I18n.t('ADD_TEAM_MEMBER_PAGE.search_placeholder')}
              onChangeText={_.debounce(
                (value) => this.searchForPlayerTrigger(value),
                300,
              )}
              rightComponent={
                <Feather name="search" size={perfectSize(20)} color="gray" />
              }
            />
          </View>
          {isSearching && (
            <ActivityIndicator
              color="#C70064"
              style={{marginBottom: perfectSize(16)}}
            />
          )}
          <FlatList
            data={membersArr}
            numColumns={3}
            renderItem={(item) => this.renderItem(item)}
            keyExtractor={(item, index) => String(index)}
          />
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
    uid: state.authReducer.uid,
    lang: state.appReducer.currentLang,
  };
};

export default compose(connect(mapStateToProps, {showAlert, setHub}))(
  SelectNewAdminPage,
);
