import {createStackNavigator} from 'react-navigation-stack';

import SettingsPage from '../../pages/settings';
import ChangepasswordPage from '../../pages/changepassword';
import NotificationManagerPage from '../../pages/notification-manager';
import ContactUsPage from '../../pages/contact-us';
import SupportPage from '../../pages/support';
import BlockedlistPage from '../../pages/blockedlist';
import EditProfilePage from '../../pages/editprofile';
import UserGamesAccountsPage from '../../pages/user-games-accounts';
import AgentsPage from '../../pages/agents';
import UserCosmeticsPage from '../../pages/user-cosmetics';
import UserCosmeticsShadowPage from '../../pages/user-cosmetics-shadow';
import PreferencesPage from '../../pages/preferences';
import ManageNotificationsPage from '../../pages/manage-notification';
import ViewfriendprofilePage from '../../pages/viewfriendprofile';

const SettingsStack = createStackNavigator(
  {
    SettingsPage,
    ChangepasswordPage,
    NotificationManagerPage,
    ContactUsPage,
    SupportPage,
    BlockedlistPage,
    EditProfilePage,
    UserGamesAccountsPage,
    AgentsPage,
    UserCosmeticsPage,
    PreferencesPage,
    ManageNotificationsPage,
    UserCosmeticsShadowPage,
    ViewfriendprofilePage,
  },
  {
    initialRouteName: 'SettingsPage',
    headerMode: 'none',
    navigationOptions: {header: null},
    gestureEnabled: false,
  },
);

export default SettingsStack;
