import React, { Component } from 'react';
import { View, TouchableOpacity, Linking } from 'react-native';
import { compose } from 'redux';
import { connect } from 'react-redux';
import firebase from 'firebase/app';
import 'firebase/database';

import I18n from '../../i18n';
import { NavigationContainer } from '@react-navigation/native';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import Feather from 'react-native-vector-icons/Feather';


// import components
import Label from '../../components/label';
import ImageWithPlaceholder from '../../components/image-with-placeholder';
import Info from './info';
import Rules from './rules';
import Brackets from './brackets';
import MyTabBar from '../../components/custom-top-tab';

// import actions
import { showAlert } from '../../redux/modules/alert/actions';

// import styles
import AppStyles from '../../constants/styles';
import styles from './styles';

import NavigationService from '../../navigation/navigationService';
import { perfectSize } from '../../helpers/deviceHelper';

// firebase
import * as FirebasePaths from '../../constants/firebasePaths';

const Tab = createMaterialTopTabNavigator();

class ChampionshipInfoPage extends Component {
  constructor(props) {
    super(props);
    const params = props.navigation.state.params;
    this.state = {
      currentTab: 0,
      championshipObj: params.championshipObj,
    };
  }

  componentDidMount() {
    const { championshipObj } = this.state;

    // Log the agents page view
    global.featuresLogger.CHAMPIONSHIP_PAGE_VIEW();
    // Log Rank HP
    global.rankLogger.LOG_NEW_HP(
      global.rankLogger.CHAMPIONSHIP_PAGE_VIEW,
      'CHAMPIONSHIP_PAGE_VIEW',
    );

    // Check if it's null give the user the option to contact us
    if (championshipObj == null || championshipObj === undefined) {
      // show back alert
      let title = I18n.t(
        'CHAMPIONSHIP_INFO_PAGE.error_loading_championship_title',
      );
      let msg = I18n.t('CHAMPIONSHIP_INFO_PAGE.error_loading_championship_msg');
      let contact = I18n.t(
        'CHAMPIONSHIP_INFO_PAGE.error_loading_championship_btn_contact',
      );
      let cancel = I18n.t(
        'CHAMPIONSHIP_INFO_PAGE.error_loading_championship_btn_back',
      );

      this.props.showAlert([
        title,
        msg,
        [
          {
            text: cancel,
            onPress: () => NavigationService.goBack(),
          },
          {
            text: contact,
            onPress: () => NavigationService.navigate('ContactUsPage'),
          },
        ],
      ]);
    }
  }

  joinChampionship = () => {
    // Check Registration type
    const { championshipObj } = this.state;
    const { uid } = this.props;
    const dbRef =
      FirebasePaths.FIREBASE_CHAMPIONSHIPS_REFERENCE +
      '/' +
      championshipObj.id +
      '/' +
      FirebasePaths.FIREBASE_CHAMPIONSHIPS_REGISTERED_PARTICIPANTS +
      '/' +
      uid;
    firebase.database()
      .ref(dbRef)
      .once('value', (snapshot) => {
        const val = snapshot.val();
        if (val !== null && val.uid === uid) {
          this.props.showAlert([
            I18n.t('CHAMPIONSHIP_INFO_PAGE.alreadyIn_title'),
            I18n.t('CHAMPIONSHIP_INFO_PAGE.alreadyIn_message'),
            [
              {
                text: 'OK',
                onPress: () => { },
              },
              {
                text: 'CANCEL',
                onPress: () => { },
              },
            ],
            true,
          ]);
          return;
        } else {
          global.featuresLogger.CHAMPIONSHIP_REGISTER_CLICKED();
          if (championshipObj.registrationType === 'URL') {
            Linking.openURL(championshipObj.registration_link);
          } else if (championshipObj.registrationType === 'SOLO') {
            NavigationService.navigate('ChampionshipRegistrationPage', {
              CHAMPIONSHIP_OBJ: championshipObj,
            });
          } else if (championshipObj.registrationType === 'BOTH') {
            const alertTitle = I18n.t(
              'CHAMPIONSHIP_INFO_PAGE.join_championship_both_alert_title',
            );
            const alertMsg = I18n.t(
              'CHAMPIONSHIP_INFO_PAGE.join_championship_both_alert_msg',
            );
            const clanBtn = I18n.t(
              'CHAMPIONSHIP_INFO_PAGE.join_championship_both_alert_with_clan_btn',
            );
            const soloBtn = I18n.t(
              'CHAMPIONSHIP_INFO_PAGE.join_championship_both_alert_as_user_btn',
            );

            this.props.showAlert([
              alertTitle,
              alertMsg,
              [
                {
                  text: clanBtn,
                  onPress: () => {
                    const pageTitle = I18n.t('USER_CLAN_SELECTOR.title');
                    const pageSubtitle = I18n.t('USER_CLAN_SELECTOR.sub-title');
                    NavigationService.navigate('UserClanSelectorPage', {
                      PAGE_TITLE: pageTitle,
                      PAGE_SUB_TITLE: pageSubtitle,
                      CHAMPIONSHIP_OBJ: championshipObj,
                    });
                  },
                },
                {
                  text: soloBtn,
                  onPress: () => {
                    NavigationService.navigate('ChampionshipRegistrationPage', {
                      CHAMPIONSHIP_OBJ: championshipObj,
                    });
                  },
                },
              ],
              false,
            ]);
          } else if (championshipObj.registrationType === 'CLAN') {
            const pageTitle = I18n.t('USER_CLAN_SELECTOR.title');
            const pageSubtitle = I18n.t('USER_CLAN_SELECTOR.sub-title');
            NavigationService.navigate('UserClanSelectorPage', {
              PAGE_TITLE: pageTitle,
              PAGE_SUB_TITLE: pageSubtitle,
              CHAMPIONSHIP_OBJ: championshipObj,
            });
          }
        }
      });
  };

  // This function will redirect the user to the streaming link
  toStreamingLink = () => {
    // Log Feature loggers
    global.featuresLogger.CHAMPIONSHIP_STREAMING_CLICK();
    // Log Rank HP
    global.rankLogger.LOG_NEW_HP(
      global.rankLogger.CHAMPIONSHIPS_STREAMING_CLICK,
      'CHAMPIONSHIPS_STREAMING_CLICK',
    );

    Linking.openURL(this.state.championshipObj.streaming_link);
  };

  // This function will get the streaming text EN | AR
  getStreamingText = () => {
    const { championshipObj } = this.state;
    if (this.props.lang === 'en') {
      return championshipObj.streaming_text_EN.trim();
    } else {
      return championshipObj.streaming_text_AR.trim();
    }
  };

  render() {
    const { theme } = this.props;
    const _styles = styles(theme);
    const { championshipObj } = this.state;
    return (
      <View style={_styles.container}>
        {/* Header */}
        <View style={_styles.header}>
          <ImageWithPlaceholder
            style={_styles.cover}
            uri={championshipObj.photo}
          />
          <View style={_styles.backArrow}>
            <TouchableOpacity
              style={AppStyles.leftBarButton}
              onPress={() => NavigationService.goBack()}>
              <Feather
                name="chevron-left"
                color="#C70064"
                size={perfectSize(22.5)}
              />
            </TouchableOpacity>
          </View>
          <View style={_styles.headerWrapper}>
            <ImageWithPlaceholder
              uri={championshipObj.provider_img}
              style={_styles.logo}
            />
            <Label size="x_small" style={_styles.appName}>
              {championshipObj.provider_name}
            </Label>
            <Label style={_styles.gameName}>{championshipObj.title}</Label>
            {(!championshipObj.is_registration_closed ||
              !championshipObj.is_closed) && (
                <TouchableOpacity
                  style={_styles.streamButton}
                  onPress={() => this.joinChampionship()}>
                  <Label style={_styles.streamLabel}>
                    {I18n.t('CHAMPIONSHIP_INFO_PAGE.register')}
                  </Label>
                </TouchableOpacity>
              )}
            {championshipObj.streaming_link != null && (
              <TouchableOpacity
                style={_styles.streamButton}
                onPress={() => this.toStreamingLink()}>
                <Label style={_styles.streamLabel}>
                  {this.getStreamingText()}
                </Label>
              </TouchableOpacity>
            )}
          </View>
        </View>
        {/* Content */}
        <View style={[AppStyles.rootContent, _styles.content]}>
          <NavigationContainer>
            <Tab.Navigator
              sceneContainerStyle={AppStyles.transparent}
              tabBar={(props) => (
                <MyTabBar
                  {...props}
                  tabBarStyle={[
                    _styles.tabbar,
                    {
                      paddingHorizontal:
                        this.props.lang === 'en'
                          ? perfectSize(64)
                          : perfectSize(32),
                    },
                  ]}
                  lang={this.props.lang}
                />
              )}>
              <Tab.Screen
                name="Info"
                component={Info}
                options={{ tabBarLabel: I18n.t('CHAMPIONSHIP_INFO_PAGE.info') }}
                initialParams={{ championshipObj }}
                listeners={({ navigation, route }) => ({
                  tabPress: (e) => {
                    this.setState({ currentTab: 0 });
                  },
                })}
              />
              <Tab.Screen
                name="Rules"
                component={Rules}
                options={{ tabBarLabel: I18n.t('CHAMPIONSHIP_INFO_PAGE.rules') }}
                initialParams={{ championshipObj }}
                listeners={({ navigation, route }) => ({
                  tabPress: (e) => {
                    this.setState({ currentTab: 1 });
                  },
                })}
              />
              <Tab.Screen
                name="Brackets"
                component={Brackets}
                options={{ tabBarLabel: I18n.t('CHAMPIONSHIP_INFO_PAGE.status') }}
                initialParams={{ championshipObj }}
                listeners={({ navigation, route }) => ({
                  tabPress: (e) => {
                    this.setState({ currentTab: 2 });
                  },
                })}
              />
            </Tab.Navigator>
          </NavigationContainer>
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
    lang: state.appReducer.currentLang,
    uid: state.authReducer.uid,
  };
};

export default compose(connect(mapStateToProps, { showAlert }))(
  ChampionshipInfoPage,
);
