import React, { Component } from 'react';
import { compose } from 'redux';
import {
  View,
  StyleSheet,
  FlatList,
  SectionList,
  TouchableOpacity,
  Linking,
} from 'react-native';
import Feather from 'react-native-vector-icons/Feather';
import { connect } from 'react-redux';
import firebase from 'firebase/app';
import 'firebase/database';

import {
  Placeholder,
  Progressive
} from "rn-placeholder";
import I18n from '../../i18n';

import Label from '../../components/label';
import PrimaryButton from '../../components/buttons/primary-button';
import ImageWithPlaceholder from '../../components/image-with-placeholder';
import ClanCard from '../../components/cards/clan-card';
import { perfectSize } from '../../helpers/deviceHelper';

// import actions
import {
  showAlert,
  showSpinner,
  hideSpinner,
} from '../../redux/modules/alert/actions';
import { set as setHub } from '../../redux/modules/hub/actions';

import * as FirebasePaths from '../../constants/firebasePaths';
import Mainmenucore from '../mainmenu/mainmenucore';
import Teamcore from '../team/teamscore';
import NavigationService from '../../navigation/navigationService';

import AppStyles from '../../constants/styles';
import { store } from '../../redux/store';
import { presentToast } from '../../helpers/toastHelper';

import {
  CLANS_LOADING_DARK_GIF,
  CLANS_LOADING_GIF,
} from '../../constants/images';
import AdSpotService from '../../services/adSpot.service';
import { useIsFocused } from '@react-navigation/native';
import { dataForSkeleton } from '../../services/utils';
import SkeletonTextContent from '../../components/skeletonWrapper/skeletonTextContent';

class YourClans extends Component {
  constructor(props) {
    super(props);
    this.state = {
      AD: null,
      userTeamsArr: [],
      userTeamsInvitationsArr: [],
      isLoadingTeams: true,
      isLoadingTeamsInvitations: true,
    };
  }

  componentDidMount() {
    this.userTeamsRef$ = Mainmenucore.getUserTeamsRef();
    this.userTeamsRef$.on('value', (teamsShot) => {
      let userTeamsArr = [];
      teamsShot.forEach((item) => {
        userTeamsArr.push(item.val());
      });
      this.props.setHub({ userTeamsArr });
      this.setState({
        userTeamsArr,
        isLoadingTeams: false,
      });
    });
    this.userTeamsInvitationsRef$ = Mainmenucore.loadUserTeamsInvitations();
    this.userTeamsInvitationsRef$.on('value', (snapshot) => {
      let userTeamsInvitationsArr = [];
      snapshot.forEach((invitation) => {
        userTeamsInvitationsArr.push(invitation.val());
      });
      this.setState({ userTeamsInvitationsArr, isLoadingTeamsInvitations: false });
    });

    // Load the AD
    this.ADRef$ = firebase.database().ref(
      FirebasePaths.FIREBASE_AD_SPOTS +
      '/' +
      FirebasePaths.FIREBASE_AD_SPOTS_CLANS,
    );
    this.ADRef$.on('value', (snapshot) => {
      if (snapshot.val() != null) {
        this.setState({
          AD: snapshot.val(),
        });
      } else {
        this.setState({
          AD: 'NONE',
        });
      }
    });
  }

  componentWillUnmount() {
    if (this.userTeamsRef$) {
      this.userTeamsRef$.off();
      this.userTeamsRef$ = null;
    }
    if (this.userTeamsInvitationsRef$) {
      this.userTeamsInvitationsRef$.off();
      this.userTeamsInvitationsRef$ = null;
    }
    if (this.ADRef$) {
      this.ADRef$.off();
      this.ADRef$ = null;
    }
  }

  // Load team page
  toTeamPage = (team) => {
    NavigationService.moveToScreenInStack('HomeStack', 'TeamPage', {
      TEAM_ID: team.id,
      CAME_FROM: 'TEAMS',
    });
  };

  // This function will show the team options alert
  showTeamOptionsAlert = (team) => {
    // Alert
    let title = I18n.t('TEAMS_PAGE.team_options_alert_title');
    let viewTeam = I18n.t('TEAMS_PAGE.team_options_alert_view_team');
    let exitTeam = I18n.t('TEAMS_PAGE.team_options_alert_leave_team');
    this.props.showAlert([
      title,
      '',
      [
        {
          // View Team
          text: viewTeam,
          onPress: () =>
            NavigationService.moveToScreenInStack('HomeStack', 'TeamPage', {
              TEAM_ID: team.id,
              CAME_FROM: 'TEAMS',
            }),
        },
        {
          // Leave Team
          text: exitTeam,
          onPress: () => {
            // First show exit team confirm alert
            this.exitTeamConfirmAlert(team);
          },
        },
      ],
      this.props.lang === 'en' ? true : false,
    ]);
  };

  // Exit team confirm alert
  exitTeamConfirmAlert(team) {
    // Alert
    let msg = I18n.t('TEAMS_PAGE.exit_team_confirm_alert_msg');
    let yes = I18n.t('TEAMS_PAGE.exit_team_confirm_alert_yes');
    let no = I18n.t('TEAMS_PAGE.exit_team_confirm_alert_no');
    this.props.showAlert([
      team.name,
      msg,
      [
        {
          // Yes
          text: yes,
          onPress: () => {
            // Log the team exit
            global.featuresLogger.LOG_TEAMS_TEAM_EXITS();
            // (1) check if this user is an admin or not
            Teamcore.checkIsAdmin(team.id).then((adminKey) => {
              // (1.1)  If the user is an admin then
              if (adminKey === this.props.uid) {
                // Admin Procedure
                // (2) check if the team has members or not
                Teamcore.checkHasMembers(team.id).then((hasMembers) => {
                  // NOW if has members then show the choose the new admin page , if not then just delete the ref .. etc
                  if (hasMembers) {
                    // (2.1) : This is an admin and has members
                    // Push to select new admin page with the team info
                    // This function
                    NavigationService.moveToScreenInStack(
                      'HomeStack',
                      'SelectNewAdminPage',
                      {
                        TEAM_ID: team.id,
                        CAME_FROM: 'TEAMS',
                        TEAM_NAME: team.name,
                      },
                    );
                  } else {
                    // (2.2)   This is an admin and has no members
                    // First Delete Ref from admin
                    this.adminExitEmptyTeam(team);
                    // update local hub and arrs
                    let userTeamsArr = store.getState().hubReducer.userTeamsArr;
                    userTeamsArr = userTeamsArr.filter(
                      (item) => item.id !== team.id,
                    );
                    this.props.setHub({
                      userTeamsArr,
                    });

                    // Show success message
                    presentToast({
                      message: I18n.t('TEAMS_PAGE.exit_team_success_msg'),
                      duration: 4000,
                    });
                  }
                });
              } else {
                // (1.2) Member Procedure
                // Backend for member
                this.memberExitTeam(team, adminKey);
                // update local hub and arrs
                let userTeamsArr = store.getState().hubReducer.userTeamsArr;
                userTeamsArr = userTeamsArr.filter(
                  (item) => item.id !== team.id,
                );
                this.props.setHub({ userTeamsArr });
                // Show success message
                presentToast({
                  message: I18n.t('TEAMS_PAGE.exit_team_success_msg'),
                  duration: 4000,
                });
              }
            });

            // Remove the clan chat ref
            firebase.database()
              .ref(
                FirebasePaths.FIREBASE_USERS_INFO_ATTR +
                '/' +
                this.props.uid +
                '/' +
                FirebasePaths.FIREBASE_CLANS_CHATS_USER_REF +
                '/' +
                team.id,
              )
              .remove();
            // Remove local data
            let clansChatsArr = store.getState().hubReducer.clansChatsArr;
            clansChatsArr = clansChatsArr.filter(
              (chat) => chat.teamId !== team.id,
            );
            this.props.setHub({ clansChatsArr });
          },
        },
        {
          // No
          text: no,
          onPress: () => { },
        },
      ],
    ]);
  }

  // Exit team for member
  memberExitTeam(team, adminKey) {
    const uid = this.props.uid;
    // First remove the ref from the member
    let memberTeamsRef =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      uid +
      '/' +
      FirebasePaths.FIREBASE_USER_TEAMS +
      '/' +
      FirebasePaths.FIREBASE_USER_TEAMS_REFS +
      '/' +
      team.id;

    // Remove the ref
    firebase.database().ref(memberTeamsRef).remove();

    // Now remove the user from the members
    let teamMembersPath =
      FirebasePaths.FIREBASE_TEAMS +
      '/' +
      team.id +
      '/' +
      FirebasePaths.FIREBASE_TEAM_MEMBERS +
      '/' +
      this.props.uid;

    // Remove the ref
    firebase.database().ref(teamMembersPath).remove();

    // Remove the user clan chat ref
    let userClanChatRef =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      uid +
      '/' +
      FirebasePaths.FIREBASE_CLANS_CHATS_USER_REF +
      '/' +
      team.id;
    // Remove the ref
    firebase.database().ref(userClanChatRef).remove();

    // Now notify the  admin
    //   Cretea notification obj
    //       Insert a notification obj to the new admin
    //  Create a push id for the notification text
    let notificationId = firebase.database().ref().push().key;
    // Create a notification Obj
    let notificationObj = {
      id: notificationId,
      status: 'UNREAD',
      body:
        store.getState().authReducer.userinformationDB.username +
        ' طلع من فريقك ',
      type: 'TEAM',
      TRIGGER: team.id,
      timeStamp: firebase.database.ServerValue.TIMESTAMP,
    };

    let userNotifications =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      adminKey +
      '/' +
      FirebasePaths.FIREBASE_USER_NOTIFICATIONS +
      '/' +
      notificationId;

    firebase.database().ref(userNotifications).set(notificationObj);

    // Notify the team
    let notificationObj2 = {
      id: notificationId,
      status: 'UNREAD',
      body:
        store.getState().authReducer.userinformationDB.username +
        ' طلع من فريقكم ',
      type: 'TEAM',
      TRIGGER: team.id,
      timeStamp: firebase.database.ServerValue.TIMESTAMP,
    };

    let teamNotificationPath =
      FirebasePaths.FIREBASE_TEAMS +
      '/' +
      team.id +
      '/' +
      FirebasePaths.FIREBASE_TEAM_NOTIFICATIONS +
      '/' +
      notificationId;
    firebase.database().ref(teamNotificationPath).set(notificationObj2);
  }

  // Exit team for admin
  adminExitEmptyTeam(team) {
    // First remove ref from admin
    let adminTeamsRef =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      this.props.uid +
      '/' +
      FirebasePaths.FIREBASE_USER_TEAMS +
      '/' +
      FirebasePaths.FIREBASE_USER_TEAMS_REFS +
      '/' +
      team.id;
    // Remove the team ref from admin
    firebase.database().ref(adminTeamsRef).remove();

    // Remove the team from teams info
    let teamsInfoRef = FirebasePaths.FIREBASE_TEAMS + '/' + team.id;
    firebase.database().ref(teamsInfoRef).remove();

    // Remove the user clan chat ref
    let userClanChatRef =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      this.props.uid +
      '/' +
      FirebasePaths.FIREBASE_CLANS_CHATS_USER_REF +
      '/' +
      team.id;
    // Remove the ref
    firebase.database().ref(userClanChatRef).remove();

    // Lower case team : LATER ...
    // let teamName : string ;
    // teamName = team.name;
    // teamName.toString().toLowerCase().trim();
    // // Remove the team from teams names
    // let teamsNamesRef = FirebasePaths.FIREBASE_TEAMS_NAMES+"/"+teamName;
    // this.rootDB.list(teamsNamesRef).remove(teamsNamesRef);
  }

  // Show accept invitation alert
  showInvitationtAlert = (invite) => {
    // Skip
  };

  // reject invitation : add this user to the : BACK END
  rejectInvitation = (invite) => {
    if (invite != null) {
      // Now remove the invitation ref
      let invitationsPath =
        FirebasePaths.FIREBASE_USERS_INFO_ATTR +
        '/' +
        this.props.uid +
        '/' +
        FirebasePaths.FIREBASE_USER_TEAMS +
        '/' +
        FirebasePaths.FIREBASE_USER_TEAMS_INVITATIONS +
        '/' +
        invite.id;
      firebase.database().ref(invitationsPath).remove();

      // Remove from local arr and map
      let { userTeamsInvitationsArr } = this.state;
      userTeamsInvitationsArr = userTeamsInvitationsArr.filter(
        (item) => item.id !== invite.id,
      );
      this.setState({
        userTeamsInvitationsArr,
      });

      // Add notification for the team admin & and admin ref

      // Log Rank HP
      global.rankLogger.LOG_NEW_HP(
        global.rankLogger.REJECT_TEAM_INVITATION,
        'REJECT_TEAM_INVITATION',
      );

      // Log the reject INVITATION
      global.featuresLogger.LOG_TEAMS_TEAM_USERS_REJECTS();

      // Create a push id for the notification text
      let notificationId = firebase.database().ref().push().key;

      // For the team
      let teamNotificationsPath =
        FirebasePaths.FIREBASE_TEAMS +
        '/' +
        invite.id +
        '/' +
        FirebasePaths.FIREBASE_TEAM_NOTIFICATIONS +
        '/' +
        notificationId;

      // Create a notification Obj
      let notificationObj = {
        id: notificationId,
        status: 'UNREAD',
        body:
          store.getState().authReducer.userinformationDB.username +
          '  رفض دعوتك لدخول الفريق، لكن ما عليك بتحصل أفضل منه ',
        type: 'TEAM',
        TRIGGER: invite.id,
        timeStamp: firebase.database.ServerValue.TIMESTAMP,
      };

      // TEAM NOTIFICATION
      firebase.database().ref(teamNotificationsPath).set(notificationObj);
      // Now set the notification for the admin
      let adminNotificationPath =
        FirebasePaths.FIREBASE_USERS_INFO_ATTR +
        '/' +
        invite.admin +
        '/' +
        FirebasePaths.FIREBASE_USER_NOTIFICATIONS +
        '/' +
        notificationId;
      firebase.database().ref(adminNotificationPath).set(notificationObj);

      // success message  ..
      this.successAlert('REJECT');
    }
  };

  // Accept invitation : add this user to the : BACK END
  acceptInvitation = (invite) => {
    // Set a ref for this user a member in the team
    // user photo , username , id  : user photo should be loaded in the team page so ..
    if (invite != null) {
      // Now remove the invitation ref
      let invitationsPath =
        FirebasePaths.FIREBASE_USERS_INFO_ATTR +
        '/' +
        this.props.uid +
        '/' +
        FirebasePaths.FIREBASE_USER_TEAMS +
        '/' +
        FirebasePaths.FIREBASE_USER_TEAMS_INVITATIONS +
        '/' +
        invite.id;
      firebase.database().ref(invitationsPath).remove();

      //// Add  team ref locally
      let userTeamsArr = store.getState().hubReducer.userTeamsArr;
      userTeamsArr.push(invite);
      // Remove from local arr and map
      let { userTeamsInvitationsArr } = this.state;
      userTeamsInvitationsArr = userTeamsInvitationsArr.filter(
        (item) => item.id !== invite.id,
      );
      this.props.setHub({
        userTeamsArr,
      });
      this.setState({
        userTeamsInvitationsArr,
      });

      let path =
        FirebasePaths.FIREBASE_TEAMS +
        '/' +
        invite.id +
        '/' +
        FirebasePaths.FIREBASE_TEAM_MEMBERS +
        '/' +
        this.props.uid;
      // Create member obj
      let memberObj = {
        uid: this.props.uid,
        username: store.getState().authReducer.userinformationDB.username,
        photo: store.getState().authReducer.userinformationDB.pictureURL,
      };

      // Add this obj
      firebase.database().ref(path).set(memberObj);

      // Create a push id for the notification text
      let notificationId = firebase.database().ref().push().key;
      // Create a notification Obj
      let notificationObj = {
        id: notificationId,
        status: 'UNREAD',
        body:
          store.getState().authReducer.userinformationDB.username +
          'قبل عرض الانضمام لفريقك ! ',
        type: 'TEAM',
        TRIGGER: invite.id,
        timeStamp: firebase.database.ServerValue.TIMESTAMP,
      };

      // Add notification for the team admin & and admin ref
      // For the team
      let teamNotificationsPath =
        FirebasePaths.FIREBASE_TEAMS +
        '/' +
        invite.id +
        '/' +
        FirebasePaths.FIREBASE_TEAM_NOTIFICATIONS +
        '/' +
        notificationId;
      firebase.database().ref(teamNotificationsPath).set(notificationObj);
      // Now set the notification for the admin
      let adminNotificationPath =
        FirebasePaths.FIREBASE_USERS_INFO_ATTR +
        '/' +
        invite.admin +
        '/' +
        FirebasePaths.FIREBASE_USER_NOTIFICATIONS +
        '/' +
        notificationId;
      firebase.database().ref(adminNotificationPath).set(notificationObj);
      // Add this team ref to this user
      let teamRefPath =
        FirebasePaths.FIREBASE_USERS_INFO_ATTR +
        '/' +
        this.props.uid +
        '/' +
        FirebasePaths.FIREBASE_USER_TEAMS +
        '/' +
        FirebasePaths.FIREBASE_USER_TEAMS_REFS +
        '/' +
        invite.id;
      // Create team ref obj
      let teamRef = {
        id: invite.id,
        name: invite.name,
        logo: invite.logo,
      };
      firebase.database().ref(teamRefPath).set(teamRef);

      // Log Rank HP
      global.rankLogger.LOG_NEW_HP(
        global.rankLogger.ACCEPT_TEAM_INVITATION,
        'ACCEPT_TEAM_INVITATION',
      );

      // Log the ACCEPT INVITATION
      global.featuresLogger.LOG_TEAMS_TEAM_USERS_ACCEPT();

      // success message  ..
      this.successAlert('ACCEPT');
    }
  };

  // show success alert : Accept invitation
  successAlert(RESPONSE) {
    let msg;
    if (RESPONSE === 'ACCEPT') {
      msg = I18n.t('TEAMS_PAGE.invitation_accept_msg');
    } else {
      msg = I18n.t('TEAMS_PAGE.invitation_reject_msg');
    }

    this.props.showAlert([
      '😀',
      msg,
      [
        {
          text: 'OK',
          onPress: () => { },
        },
      ],
    ]);
  }

  // This function will redirect user to the new team page : its the same page as join championship .. dont worry about the name : JOIN TEAM PARAM
  toJoinTeam = () => {
    // Check if this user is banned
    Teamcore.IS_JOIN_CLAN_BANNED().then((isBanned) => {
      if (isBanned) {
        let banTitle = I18n.t('TEAMS_PAGE.ban_alert_title_join_clan');
        let banMsg = I18n.t('TEAMS_PAGE.ban_alert_msg_join_clan');
        let banBtn = I18n.t('TEAMS_PAGE.ban_alert_btn_join_clan');
        // Show ban alert
        this.props.showAlert([
          banTitle,
          banMsg,
          [
            {
              text: banBtn,
              onPress: () => {
                NavigationService.moveToScreenInStack(
                  'SettingsStack',
                  'SupportPage',
                );
              },
            },
          ],
        ]);
      } else {
        // Log the page view
        global.featuresLogger.LOG_TEAMS_TEAM_JOIN_TEAM_PAGE_VIEW();

        NavigationService.moveToScreenInStack('HomeStack', 'JoinClanPage', {
          CAME_FROM: 'JOIN_TEAM',
        });
      }
    });
  };

  // This function will redirect user to the new team page : its the same page as join championship .. dont worry about the name : NEW TEAM PARAM
  toNewTeam = () => {
    // Check if this user is banned
    Teamcore.IS_CREATE_CLAN_BANNED().then((isBanned) => {
      if (isBanned) {
        let banTitle = I18n.t('TEAMS_PAGE.ban_alert_title_create_clan');
        let banMsg = I18n.t('TEAMS_PAGE.ban_alert_msg_create_clan');
        let banBtn = I18n.t('TEAMS_PAGE.ban_alert_btn_create_clan');
        // Show ban alert
        this.props.showAlert([
          banTitle,
          banMsg,
          [
            {
              text: banBtn,
              onPress: () => {
                NavigationService.moveToScreenInStack(
                  'SettingsStack',
                  'SupportPage',
                );
              },
            },
          ],
        ]);
      } else {
        // Log the page view
        global.featuresLogger.LOG_TEAMS_TEAM_CREATE_TEAM_PAGE_VIEW();

        NavigationService.moveToScreenInStack(
          'HomeStack',
          'JoinChampionshipPage',
          {
            CAME_FROM: 'NEW_TEAM',
          },
        );
      }
    });
  };

  openClansAD = () => {
    // First log the click for this ad
    global.featuresLogger.CLANS_AD_CLICK();

    const { AD } = this.state;
    if (AD != null && AD.url != null) {
      AdSpotService.checkAdUrl(AD);
    }
  };

  // Getting Animated Gif for the right mode
  getGif() {
    if (this.props.theme.mode === 'dark') {
      return CLANS_LOADING_DARK_GIF;
    } else {
      return CLANS_LOADING_GIF;
    }
  }

  renderSectionItem = ({ item, index }) => {
    const { theme } = this.props;
    const _styles = styles(theme);
    return <TouchableOpacity
      style={_styles.item}
      onPress={() => this.toTeamPage(item)}
      onLongPress={() =>
        !item.isInvitation && this.showTeamOptionsAlert(item)
      }>
      <ClanCard
        teamId={item.id}
        photo={item.logo}
        title={item.name}
        isInvitation={item.isInvitation}
        onAccept={() => this.acceptInvitation(item)}
        onIgnore={() => this.rejectInvitation(item)}
        index={index}
      />
    </TouchableOpacity>
  }

  renderContent = () => {
    const { theme } = this.props;
    const _styles = styles(theme);
    const { AD, userTeamsArr, userTeamsInvitationsArr, isLoadingTeams, isLoadingTeamsInvitations } = this.state;
    const isLoading = isLoadingTeams || isLoadingTeamsInvitations;
    let sections = [
      {
        title: I18n.t('TEAMS_PAGE.switcher_invitations'),
        data: [
          {
            list: userTeamsInvitationsArr.map((e) => {
              e.isInvitation = true;
              return e;
            })
          }
        ],
      },
      {
        title: I18n.t('TEAMS_PAGE.title'),
        data: [
          {
            list: userTeamsArr.map((e) => {
              e.isInvitation = false;
              return e;
            })
          }
        ]
        ,
      },
    ];
    return (
      <View>
        {AD != null && AD !== 'NONE' && (
          <TouchableOpacity
            onPress={() => this.openClansAD()}
            style={{ marginBottom: perfectSize(16) }}>
            <View style={_styles.adCard}>
              {AD.img != null && (
                <ImageWithPlaceholder style={_styles.adCover} uri={AD.img} />
              )}
            </View>
          </TouchableOpacity>
        )}
        {!isLoading && userTeamsInvitationsArr.length === 0 && userTeamsArr.length === 0 ? (
          <View style={_styles.emptyContent}>
            <View style={AppStyles.emptyContent}>
              {/* <FastImage
                source={this.getGif()}
                style={AppStyles.emptyContentGif}
                resizeMode="contain"
              /> */}
              <Label style={AppStyles.emptyContentLabel}>
                {I18n.t('TEAMS_PAGE.no_teams_msg')}
              </Label>
            </View>
          </View>
        ) : (
          isLoading ?
            <FlatList
              data={dataForSkeleton(15)}
              keyExtractor={(item) => item.toString()}
              numColumns={5}
              columnWrapperStyle={{ justifyContent: 'space-between' }}
              renderItem={() => {
                return <View style={{ marginVertical: perfectSize(10), width: '20%', paddingHorizontal: 10 }}>
                  <Placeholder
                    Animation={Progressive}
                  >
                    <SkeletonTextContent height={100} />
                    <SkeletonTextContent height={20} style={{ marginTop: 10 }} />
                    <SkeletonTextContent height={20} width={20} style={{ marginTop: 10 }} />
                  </Placeholder>
                </View>
              }}
            />
            :
            <SectionList
              sections={sections}
              showsVerticalScrollIndicator={false}
              showsHorizontalScrollIndicator={false}
              stickySectionHeadersEnabled={false}
              // style={{justifyContent: 'space-between', flexDirection: 'row'}}
              renderItem={({ item, index }) => (
                <FlatList
                  data={item.list}
                  numColumns={5}
                  renderItem={this.renderSectionItem}
                  keyExtractor={(item) => item.id.toString()}
                />
              )}
              renderSectionHeader={({ section }) => (
                <View>
                  {section.data[0].list.length > 0 ? (
                    <Label
                      size="x_small"
                      bold={true}
                      style={_styles.sectionHeader}>
                      {section.title}
                    </Label>
                  ) : null}
                </View>
              )}
              keyExtractor={(item, index) => index}
            />
        )}
      </View>
    );
  };

  render() {
    const { theme, isFocused } = this.props;
    const _styles = styles(theme);
    if (isFocused) {
      return (
        <View style={[_styles.container, this.props.style]}>
          <View style={_styles.buttons}>
            <PrimaryButton
              text={I18n.t('TEAMS_PAGE.create_new_team')}
              size="x_small"
              type="filled"
              customStyles={_styles.newButtonStyle}
              onPress={this.toNewTeam}
              leftComponent={
                <Feather
                  name="plus-circle"
                  size={perfectSize(18)}
                  color={theme.primary_text}
                  style={{ marginRight: perfectSize(4) }}
                />
              }
            />
            <PrimaryButton
              text={I18n.t('TEAMS_PAGE.join_new_team')}
              size="x_small"
              type="outlined"
              customStyles={_styles.newButtonStyle}
              onPress={this.toJoinTeam}
            />
          </View>
          <FlatList
            data={[{}]}
            renderItem={this.renderContent}
            keyExtractor={(item) => '_customKey'}
            showsVerticalScrollIndicator={false}
            style={{ backgroundColor: theme.primary_background }}
          />
        </View>
      );
    }
    return <View />
  }
}

const styles = (theme) =>
  StyleSheet.create({
    adCard: {
      borderRadius: perfectSize(12),
      width: 350,
      aspectRatio: 327 / 100,
      overflow: 'hidden',
      height: 150,
    },
    adCover: {
      width: '100%',
      height: '100%',
    },
    buttons: {
      flexDirection: 'row',
      marginVertical: perfectSize(8),
    },
    newButtonStyle: {
      height: perfectSize(32),
      paddingHorizontal: perfectSize(16),
      marginRight: perfectSize(8),
    },
    container: {
      flex: 1,
      backgroundColor: theme.primary_background,
    },
    item: {
      marginBottom: perfectSize(16),
      width: '20%',
      paddingHorizontal: 10
    },
    sectionHeader: {
      marginVertical: perfectSize(8),
    },
    emptyContent: {
      marginBottom: perfectSize(16),
    },
  });

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
    lang: state.appReducer.currentLang,
    uid: state.authReducer.uid,
  };
};

function focusComponent(props) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const isFocused = useIsFocused();
  return <YourClans {...props} isFocused={isFocused} />;
}

export default compose(
  connect(mapStateToProps, { setHub, showAlert, showSpinner, hideSpinner })(
    focusComponent,
  ),
);
