/* eslint-disable react-native/no-inline-styles */
import React, { Component } from 'react';
import { View, StyleSheet, FlatList, ActivityIndicator } from 'react-native';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Feather from 'react-native-vector-icons/Feather';
import firebase from 'firebase/app';
import 'firebase/database';

import _ from 'lodash';
import deepEqual from 'fast-deep-equal';

import I18n from '../../i18n';
import NavigationService from '../../navigation/navigationService';

// import components
import Header from '../../components/header';
import FriendItem from '../../components/friend-item';
import TextInput from '../../components/text-input';
import Label from '../../components/label';
import PrimaryButton from '../../components/buttons/primary-button';

// import styles
import Dimens from '../../constants/dimens';
import { perfectSize } from '../../helpers/deviceHelper';
import {
  handleAndroidBackButton,
  removeAndroidBackButtonHandler,
} from '../../helpers/deviceHelper';
import friendsListCore from './friendslistcore';
import { presentToast } from '../../helpers/toastHelper';
import { set as HubSet } from '../../redux/modules/hub/actions';
import { showAlert } from '../../redux/modules/alert/actions';

import { clearFastImageCache, dataForSkeleton, generateRandom } from '../../services/utils';

import * as FirebasePaths from '../../constants/firebasePaths';

class FriendsListPage extends Component {
  constructor(props) {
    super(props);
    this.mount = true;
    const params = props.navigation.state.params;
    this.state = {
      cameFrom: params?.CAME_FROM,
      searchTerm: '',
      allFriendsArray: props.friendsList,
      isSearching: false,
      searchedFriendsList: [],
      friendsList: props.friendsList,
    };
  }

  componentDidMount() {
    this.props.navigation.addListener('willFocus', (payload) => {
      this._init();
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { friendsList: oldList } = this.props;
    const { friendsList: newList } = nextProps;
    if (!deepEqual(oldList, newList) && this.mount) {
      this.setState({ friendsList: newList });
    }
  }

  componentWillMount() {
    handleAndroidBackButton(this.goBack);
  }

  _init = () => {
    let _allFriendsArray = this.state.friendsList;
    let tempArray = [];
    _allFriendsArray.forEach(async (item) => {
      item.borderColor = await this.getProfileBorderColor(item.UID);
      tempArray.push(item);
      if (tempArray.length === _allFriendsArray.length) {
        _allFriendsArray = tempArray;
      }
    });
    this.setState({ allFriendsArray: _allFriendsArray });
  };

  componentWillUnmount() {
    this.mount = false;

    if (this.userStatusListener) {
      this.userStatusListener.off();
    }
    removeAndroidBackButtonHandler();
  }

  toSearchPage = () => {
    NavigationService.navigate('FriendsSearchPage', { CAME_FROM: 'COMMUNITY' });
  };

  goBack = (backToProfile) => {
    clearFastImageCache();
    NavigationService.goBack();
  };

  onChangeText = (val) => {
    this.searchForFriendTrigger(val);
  };

  // Search for player trigger
  searchForFriendTrigger = (value) => {
    if (value !== '') {
      // Show the spinner
      this.setState({ isSearching: true, allFriendsArray: [] });
      this.hiddenTextInput.focus();

      // Search in server
      // Getting the users DB ref
      let usersPath = '/' + FirebasePaths.FIREBASE_USERS_INFO_ATTR + '/';

      // EXTRACT FRIEND KEY
      firebase.database()
        .ref(usersPath)
        .orderByChild('/_info_/_username_')
        .startAt(value.toLowerCase())
        .endAt(value.toLowerCase() + '\uf8ff')
        .limitToFirst(10)
        .once('value', (snapshot) => {
          let searchedFriendsList = [];
          const uid = this.props.uid;
          if (snapshot.val() === null) {
            this.setState({ isSearching: false });
            this.queryTextInput.focus();
          }
          snapshot.forEach((item) => {
            let friendKey = item.key;
            // Check if this key is not the current user key
            if (uid !== friendKey) {
              // Check if friend is in hub list
              if (!searchedFriendsList.includes(friendKey)) {
                searchedFriendsList.push(friendKey);
              }
            }
          });
          this.setState({ searchedFriendsList });
          searchedFriendsList.forEach((key) => {
            this.addToFriendsList(key);
          });
        });
    } else {
      let _allFriendsArray = this.state.friendsList;
      this.setState({ isSearching: false, allFriendsArray: _allFriendsArray });
    }
  };

  // Extract user info and add him/her to the list
  addToFriendsList = (friendKey) => {
    let friendId = friendKey;
    let bio;
    let email;
    let picUrl;
    let username;
    let status = 'online';

    let friendModel = {};

    let friendPath =
      '/' +
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      friendKey +
      '/' +
      FirebasePaths.FIREBASE_DETAILS_ATTR;
    firebase.database()
      .ref(friendPath)
      .once('value')
      .then(async (snapshot) => {
        snapshot.forEach((item) => {
          if (item.key === FirebasePaths.FIREBASE_BIO_ATTR) {
            bio = item.val();
          }
          if (item.key === FirebasePaths.FIREBASE_EMAIL_ATTR) {
            email = item.val();
          }
          if (item.key === FirebasePaths.FIREBASE_PICTURE_URL_ATTR) {
            picUrl = item.val();
            if (picUrl === 'NULL' || picUrl === 'default') {
              // SET DEFAULT IMG
              picUrl =
                'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/teams%20default%20photos%20and%20background%2Fdefault_team_logov2.png?alt=media&token=b88bf926-35b9-4568-a80e-ee24110aceb6';
            }
          }
          if (item.key === FirebasePaths.FIREBASE_USERNAME_ATTR) {
            username = item.val();
          }
          if (item.key === FirebasePaths.FIREBASE_STATUS_ATTR) {
            status = item.val();
          }

          friendModel.UID = friendId;
          friendModel.bio = bio;
          friendModel.userEmail = email;
          friendModel.pictureURL = picUrl;
          friendModel.username = username;
          friendModel.status = status;
        });
        friendModel.borderColor = await this.getProfileBorderColor(friendId);

        let { allFriendsArray } = this.state;
        allFriendsArray.push(friendModel);
        // allFriendsArray.conc
        // this.setState(allFriendsArray);
        if (this.state.searchedFriendsList.length === allFriendsArray.length) {
          allFriendsArray.reduce(
            (unique, item) =>
              unique.includes(item) ? unique : [...unique, item],
            [],
          );
          this.setState({ isSearching: false, allFriendsArray });
          this.queryTextInput.focus();
        }
      });
  };

  getProfileBorderColor(uid) {
    let cosmeticsPath =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      uid +
      '/' +
      FirebasePaths.FIREBASE_COSMETICS_ATTR +
      '/' +
      FirebasePaths.FIREBASE_PROFILE_BORDER_ATTR;

    return firebase.database()
      .ref(cosmeticsPath)
      .once('value')
      .then((snapshot) => {
        let borderColor = '#880e4a';
        snapshot.forEach((item) => {
          if (item.key === FirebasePaths.FIREBASE_COLOR_ATTR) {
            borderColor = item.val();
          }
        });
        return borderColor;
      });
  }

  showAlert = (friend) => {
    if (this.state.friendsList.filter((e) => e.UID === friend.UID).length > 0) {
      this.showFriendDialog(friend.username, friend.UID);
    } else {
      this.showNonFriendDialog(friend.username, friend.UID);
    }
  };

  showFriendDialog = (friendUsername, friendId) => {
    let buttons = [];
    buttons.push({
      text: I18n.t('FRIEND_LIST_PAGE.view_profile'),
      onPress: () => {
        // NavigationService.moveToScreenInStack(
        //   'ProfileStack',
        //   'ViewfriendprofilePage',
        //   {
        //     FRIEND_KEY: friendId,
        //   },
        // );
        NavigationService.navigate('ViewfriendprofilePage', {
          FRIEND_KEY: friendId,
        });
      },
    });
    buttons.push({
      text: I18n.t('FRIEND_LIST_PAGE.delete'),
      onPress: () => {
        friendsListCore.deleteFriendRef(friendId);
        global.rankLogger.LOG_NEW_HP(
          global.rankLogger.DELETE_FRIEND,
          'DELETE_FRIEND',
        );
        let friendsList = this.state.friendsList;
        friendsList.splice(
          friendsList.findIndex((v) => v.UID === friendId),
          1,
        );
        this.props.HubSet(friendsList);
        this.props.HubSet({ valueChanged: generateRandom(11) });
        this.setState({ allFriendsArray: friendsList });
        presentToast({
          message: I18n.t('FRIEND_LIST_PAGE.deleted_successfully'),
        });
        // NavigationService.navigate('ContactUsPage');
      },
    });
    if (!this.props.blockedList.has(friendId)) {
      buttons.push({
        text: I18n.t('CHAT_PAGE.block_user'),
        onPress: () => {
          if (friendId === this.props.uid) {
            presentToast({
              message: I18n.t('TIMELINE_PAGE.cant_block_msg'),
            });
          } else {
            this.addToBlockList(friendId, friendUsername);
            let _blockedList = this.props.blockedList;
            if (_blockedList === undefined) {
              _blockedList = new Map();
            }
            _blockedList.set(friendId, friendUsername);
            this.props.HubSet({
              blockedList: _blockedList,
            });
            presentToast({
              message: I18n.t('TIMELINE_PAGE.block_alert_success_msg'),
            });
          }
        },
      });
    }
    this.props.showAlert([
      friendUsername,
      '',
      buttons,
      buttons.length > 2 ? false : true, // isRow
    ]);
  };

  addToBlockList = (uid, username) => {
    const blockListPath =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      this.props.uid +
      '/' +
      FirebasePaths.FIREBASE_BLOCKED_USERS +
      '/';
    firebase.database()
      .ref(blockListPath + '/' + uid)
      .set(username);
  };

  showNonFriendDialog = (username, UID) => {
    this.props.showAlert([
      username,
      '',
      [
        {
          text: I18n.t('FRIEND_LIST_PAGE.view_profile'),
          onPress: () => {
            NavigationService.navigate('ViewfriendprofilePage', {
              FRIEND_KEY: UID,
            });
          },
        },
        {
          text: I18n.t('FRIEND_LIST_PAGE.alert_addd_to_friends'),
          onPress: () => {
            friendsListCore.addFriend(UID);
            global.rankLogger.LOG_NEW_HP(
              global.rankLogger.DELETE_FRIEND,
              'DELETE_FRIEND',
            );
            presentToast({
              message: I18n.t('FRIEND_LIST_PAGE.success_friend_add'),
            });
          },
        },
      ],
      true,
    ]);
  };

  toChatPage = (friendUID, friendUsername, friendBio, friendPhotoUrl) => {
    if (this.props.blockedList.has(friendUID)) {
      this.props.showAlert([
        friendUsername,
        I18n.t('FRIEND_LIST_PAGE.unblock_alert_msg'),
        [
          {
            text: I18n.t('FRIEND_LIST_PAGE.unblock_alert_yes'),
            onPress: () => {
              const uid = this.props.uid;
              // REF : uid/user_info/_blocked_users_
              let blockedListRef =
                '/' +
                FirebasePaths.FIREBASE_USERS_INFO_ATTR +
                '/' +
                uid +
                '/' +
                FirebasePaths.FIREBASE_BLOCKED_USERS +
                '/' +
                friendUID +
                '/';
              // delete user with passed key
              firebase.database().ref(blockedListRef).remove();
              let _blockedList = this.props.blockedList;
              _blockedList.delete(friendUID);

              // Remove from blocklist : Hub
              this.props.HubSet({ blockedList: _blockedList });
              presentToast({
                message: I18n.t('FRIEND_LIST_PAGE.unblock_alert_success'),
              });
            },
          },
          {
            text: I18n.t('FRIEND_LIST_PAGE.unblock_alert_no'),
            onPress: () => { },
          },
        ],
        true,
      ]);
    } else {
      let CHAT_STATUS = 'NEW';
      if (this.userHasRefWithFriend(friendUID)) {
        CHAT_STATUS = 'ONGOING';
      } else {
        CHAT_STATUS = 'NEW';
      }
      if (
        !this.state.friendsList.filter((e) => e.UID === friendUID).length > 0
      ) {
        global.rankLogger.LOG_NEW_HP(
          global.rankLogger.ADD_FRIEND,
          'ADD_FRIEND',
        );
        friendsListCore.addFriend(friendUID);

        presentToast({
          message:
            friendUsername + I18n.t('FRIEND_LIST_PAGE.added_successfully'),
        });

        NavigationService.moveToScreenInStack('HomeStack', 'PrivateChatPage', {
          TITLE: friendUsername,
          SUBTITLE: friendBio,
          CHAT_IMG: friendPhotoUrl,
          CHAT_TYPE: 'PRIVATE',
          FRIEND_UID: friendUID,
          CHAT_STATUS: CHAT_STATUS,
        });
      } else {
        NavigationService.moveToScreenInStack('HomeStack', 'PrivateChatPage', {
          TITLE: friendUsername,
          SUBTITLE: friendBio,
          CHAT_IMG: friendPhotoUrl,
          CHAT_TYPE: 'PRIVATE',
          FRIEND_UID: friendUID,
          CHAT_STATUS: CHAT_STATUS,
        });
      }
    }
  };

  userHasRefWithFriend = (opponentId) => {
    let FOUND_RELATED_REF = false;
    Object.values(this.props.allPrivateChatRefs).forEach((data) => {
      if (data.opponentId === opponentId) {
        FOUND_RELATED_REF = true;
        this.props.HubSet({
          tempChatRoomRef: data.chatRef,
        });
      }
    });
    return FOUND_RELATED_REF;
  };

  renderSkeletonItem = (item) => {
    const { theme } = this.props;
    return <View style={{
      width: '33%',
      justifyContent: 'center',
      alignItems: 'center',
      alignSelf: 'flex-start',
      marginVertical: perfectSize(10),
      paddingVertical: perfectSize(5),
    }}>

      <View style={{ alignItems: 'center' }}>
        <View style={{ width: perfectSize(48), height: perfectSize(48), borderRadius: perfectSize(24) }} />
        <View style={{ marginTop: 10, width: perfectSize(60), height: perfectSize(12) }} />
      </View>

    </View>
  }

  render() {
    const { theme, navigation } = this.props;
    const styles = style(theme);
    const containerStyle = [
      styles.container,
      { backgroundColor: theme.secondary_background },
    ];
    const { state } = navigation;

    const backToProfile = state && state.params && state.params.backToProfile;

    return (
      <View style={containerStyle}>
        <Header
          text={
            this.state.cameFrom === 'COMMUNITY'
              ? I18n.t('FRIEND_LIST_PAGE.find_friend')
              : I18n.t('FRIEND_LIST_PAGE.friend_list')
          }
          bold
          onBack={() => this.goBack(backToProfile)}
        />
        {/* Content */}
        <View
          style={[{ backgroundColor: theme.primary_background }, styles.content]}>
          {this.state.cameFrom && (
            <View>
              <TextInput
                customStyles={{ position: 'absolute', opacity: 0 }}
                onRef={(input) => {
                  this.hiddenTextInput = input;
                }}
                blurOnSubmit={false}
              />
              <TextInput
                placeholder={I18n.t('FRIEND_LIST_PAGE.search_for_friend')}
                rightComponent={
                  <Feather name="search" size={perfectSize(20)} color="gray" />
                }
                customStyles={styles.mt16}
                onChangeText={_.debounce(
                  (val) => this.searchForFriendTrigger(val),
                  1500,
                )}
                onRef={(input) => {
                  this.queryTextInput = input;
                }}
              />
            </View>
          )}
          {this.state.isSearching && (
            <View style={styles.gamesWrap}>
              <FlatList
                keyExtractor={(item, index) => String(index)}
                data={dataForSkeleton(3)}
                style={{ width: '100%' }}
                renderItem={this.renderSkeletonItem}
                numColumns={3}
                columnWrapperStyle={styles.justifyBetween}
                showsVerticalScrollIndicator={false}
              />
            </View>
          )}
          <View style={styles.gamesWrap}>
            <FlatList
              data={this.state.allFriendsArray}
              keyExtractor={(item, index) => String(index)}
              renderItem={(item) => (
                <FriendItem
                  user={item}
                  onLongPress={() => this.showAlert(item.item)}
                  onPress={() =>
                    this.toChatPage(
                      item.item.UID,
                      item.item.username,
                      item.item.bio,
                      item.item.pictureURL,
                    )
                  }
                />
              )}
              numColumns={3}
              columnWrapperStyle={styles.justifyBetween}
              showsVerticalScrollIndicator={false}
            />
          </View>

          {this.state.allFriendsArray.length < 1 &&
            this.state.cameFrom !== 'COMMUNITY' && (
              <View style={styles.noFriendsWrap}>
                {/* <FastImage
                  source={
                    theme.mode === 'dark'
                      ? Images.FRIENDS_LOADING_DARK
                      : Images.FRIENDS_LOADING
                  }
                  style={styles.supportLoading}
                  resizeMode="contain"
                /> */}
                <Label style={styles.txtNoGame}>
                  {I18n.t('FRIEND_LIST_PAGE.no_friends_msg')}
                </Label>
                <PrimaryButton
                  text={I18n.t('GAME_LIST_PAGE.go_support')}
                  type="filled"
                  size="x_small"
                  customStyles={styles.btnAction}
                  onPress={this._toContactUsPage}
                />
              </View>
            )}
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
    uid: state.authReducer.uid,
    userinformationDB: state.authReducer.userinformationDB,
    friendsList: state.hubReducer.friendsList,
    blockedList: state.hubReducer.blockedList,
    allPrivateChatRefs: state.hubReducer.allPrivateChatRefs,
  };
};

export default compose(connect(mapStateToProps, { HubSet, showAlert }))(
  FriendsListPage,
);

const style = (theme) =>
  StyleSheet.create({
    container: {
      flex: 1,
    },
    // Content
    content: {
      flex: 1,
      borderTopStartRadius: Dimens.BODY_BORDER_TOP_RADIUS,
      borderTopEndRadius: Dimens.BODY_BORDER_TOP_RADIUS,
      paddingHorizontal: Dimens.HEADER_PADDING_HORIZONTAL,
      paddingVertical: Dimens.HEADER_PADDING_VERTICAL,
    },
    btnNewFriend: {
      alignSelf: 'flex-start',
      height: perfectSize(32),
      width: perfectSize(148),
      marginTop: perfectSize(5),
    },
    gamesWrap: {
      flex: 1,
      marginTop: perfectSize(16),
      justifyContent: 'center',
      // alignItems: 'center',
    },
    flatList: {
      flex: 1,
    },
    flexRow: {
      flexDirection: 'row',
    },
    justifyBetween: {
      // justifyContent: 'space-between',
    },
    addGame: {
      fontSize: perfectSize(15),
      lineHeight: perfectSize(20),
    },
    mt16: {
      marginTop: perfectSize(16),
    },
    noFriendsWrap: {
      borderRadius: perfectSize(30),
      borderWidth: 1,
      borderStyle: 'dotted',
      borderColor: 'white',
      paddingTop: perfectSize(32),
      paddingBottom: perfectSize(24),
      paddingHorizontal: perfectSize(16),
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: perfectSize(48),
    },
    supportLoading: {
      width: perfectSize(80),
      height: perfectSize(80),
    },
    txtNoGame: {
      color: '#949494',
      textAlign: 'center',
      marginVertical: perfectSize(16),
    },
    btnAction: {
      paddingHorizontal: perfectSize(16),
    },
  });
