import React, {Component} from 'react';
import {View, FlatList, ActivityIndicator} from 'react-native';
import Feather from 'react-native-vector-icons/Feather';
import _ from 'lodash';
import firebase from 'firebase/app';
import 'firebase/database';

import {compose} from 'redux';
import {connect} from 'react-redux';
import I18n from '../../../i18n';

// import styles
import styles from './styles';

// import actions
import {switchTheme} from '../../../redux/modules/app/actions';

import {perfectSize} from '../../../helpers/deviceHelper';
import NavigationService from '../../../navigation/navigationService';
import * as FirebasePaths from '../../../constants/firebasePaths';

import {presentToast} from '../../../helpers/toastHelper';
import {set as HubSet} from '../../../redux/modules/hub/actions';

import {showAlert, showSpinner, hideSpinner, showInputAlert} from '../../../redux/modules/alert/actions';
import {useIsFocused} from '@react-navigation/native';
import HootCard from '../../../components/cards/hoot-card';
import TextInput from '../../../components/text-input';
import HootDetailModalCard from '../../../components/cards/hoot-detail-modal-card';
import {arrayFromSnapshot, dataForSkeleton} from '../../../services/utils';
import Label from '../../../components/label';
import TextArea from '../../../components/text-area';
import HootsCore from '../hootsCore';
import FriendsListCore from '../../friendslist/friendslistcore';
import HootReport from '../../../models/hootReport';

class LiveHootsPage extends Component {
    myHops$ = null;

    constructor(props) {
        super(props);
        this.mount = true;
        this.state = {
            isLoading: true,
            liveHoots: [],
            displayHoots: [],
            isHootDetailVisible: false,
            selectedHoot: null,
            searchTerm: '',
        };
    }

    componentDidMount() {
        // Log entering live hoots
        global.featuresLogger.HOOTS_TIMELINE_LIVE_CLICK();
        this.loadLiveHoots();
    }

    componentWillUnmount() {
        if (this.myHops$) {
            this.myHops$.off();
            this.myHops$ = null;
        }

        clearInterval(this.interval);
        this.mount = false;
    }

    componentDidUpdate(prevProps) {
        const {blockedList: prevBlockedList} = prevProps;
        const {blockedList: currentBlockedList} = this.props;
        if (currentBlockedList.size !== prevBlockedList.size) {
            this.filterHoots();
        }
    }

    loadLiveHoots = () => {
        const liveHootsRef = HootsCore.loadLiveHootsSubscription();
        liveHootsRef.on('value', liveHootsSnapshot => {
            const hoots = arrayFromSnapshot(liveHootsSnapshot);
            hoots.sort((a, b) => (a.timestamp < b.timestamp) ? 1 : -1);
            if (this.mount) {
                this.setState({
                    liveHoots: hoots,
                }, () => {
                    this.filterHoots();
                })
            }

        })
    };

    onSearchHoots = (name) => {
        this.setState({searchTerm: name})
        this.filterHoots(name);
    }

    filterHoots = (updateSearchTerm) => {
        const {blockedList} = this.props;
        const {liveHoots: allHoots, searchTerm} = this.state;
        const hoots = allHoots.filter(hoot => hoot.title.includes(updateSearchTerm ?? searchTerm) && !blockedList.get(hoot.admin));
        if (this.mount) {
            this.setState({displayHoots: hoots})
        }
    }

    onPressMore = (item) => {
        let buttons = [];
        const {admin, historyParticipants} = item;
        const {friendsList, blockedList} = this.props;
        const adminUserName = historyParticipants[admin].username;
        // Log hoots options open
        global.featuresLogger.HOOTS_TIMELINE_HOOT_OPTIONS_OPEN();
        buttons.push({
            text: I18n.t('HOOTS_PAGE.user_action_view_profile'),
            onPress: () => {
                // Log hoots options view profile
                global.featuresLogger.HOOTS_TIMELINE_HOOT_OPTIONS_VIEW_PROFILE();
                NavigationService.navigate('ViewfriendprofilePage', {
                    FRIEND_KEY: admin,
                });
            },
        });

        if (friendsList === undefined ||
            (friendsList !== undefined &&
                !friendsList.filter(
                    (e) => e.UID === admin,
                ).length > 0) && (admin !== this.props.uid)) {
            buttons.push({
                text: I18n.t('HOOTS_PAGE.user_action_add_friend'),
                onPress: () => {
                    FriendsListCore.addFriend(admin);
                    global.rankLogger.LOG_NEW_HP(
                        global.rankLogger.DELETE_FRIEND,
                        'DELETE_FRIEND',
                    );
                    // Log hoots options add friend
                    global.featuresLogger.HOOTS_TIMELINE_HOOT_OPTIONS_ADD_FRIEND();
                    presentToast({
                        message: I18n.t('FRIEND_LIST_PAGE.success_friend_add'),
                    });
                },
            });
        }
        if (!blockedList.has(admin) && (admin !== this.props.uid)) {
            buttons.push({
                text: I18n.t('HOOTS_PAGE.user_action_block_user'),
                onPress: () => {
                    if (admin === this.props.uid) {
                        presentToast({
                            message: I18n.t('TIMELINE_PAGE.cant_block_msg'),
                        });
                    } else {
                        this.addToBlockList(admin, adminUserName);
                        let _blockedList = _.clone(this.props.blockedList);
                        if (_blockedList === undefined) {
                            _blockedList = new Map();
                        }
                        _blockedList.set(admin, adminUserName);
                        this.props.HubSet({
                            blockedList: _blockedList,
                        });
                        // Log hoots options block user
                        global.featuresLogger.HOOTS_TIMELINE_HOOT_OPTIONS_BLOCK_USER();
                        presentToast({
                            message: I18n.t('TIMELINE_PAGE.block_alert_success_msg'),
                        });
                    }
                },
            });
        }
        if (admin !== this.props.uid) {
            buttons.push({
                text: I18n.t('HOOTS_PAGE.user_action_report_hoot'),
                onPress: () => {
                    this.props.hideSpinner();
                    setTimeout(() => {
                        // Log hoots options report
                        global.featuresLogger.HOOTS_TIMELINE_HOOT_OPTIONS_REPORT();
                        this.report(item);
                    }, 100);
                },
            });
        }

        this.props.showAlert([
            I18n.t('HOOTS_PAGE.hoots_options'),
            '',
            buttons,
            false, // isRow
            {
                height: perfectSize(32),
                width: perfectSize(120),
                alignItems: 'center'
            },
        ]);
    }

    addToBlockList = (uid, username) => {
        const blockListPath =
            FirebasePaths.FIREBASE_USERS_INFO_ATTR +
            '/' +
            this.props.uid +
            '/' +
            FirebasePaths.FIREBASE_BLOCKED_USERS +
            '/';
        firebase.database()
            .ref(blockListPath + '/' + uid)
            .set(username);
    };

    report = (item) => {
        const {theme} = this.props;
        const _styles = styles(theme);
        this.props.showInputAlert([
            I18n.t('HOOTS_PAGE.user_action_hoot_report'),
            <View style={_styles.dialogSectionContainer}>
                <Label style={_styles.dialogDescription}>
                    {I18n.t('SEARCH_REQUESTS_RESULT_PAGE.report_msg')}
                </Label>
                <TextArea
                    placeholder={I18n.t('POST_PAGE.report_placeholder')}
                    multiline={true}
                    customStyles={_styles.inputBoxCustomStyle}
                    inputStyles={_styles.inputBoxStyle}
                    onChangeText={(val) => this.setState({report: val})}
                // value={this.state.report}
                />
            </View>,
            [
                {
                    text: I18n.t(
                        'HOOTS_PAGE.hoots_report_cancel',
                    ),
                    onPress: () => {},
                },
                {
                    text: I18n.t('HOOTS_PAGE.hoots_report_report'),
                    onPress: async () => {
                        // report logic
                        // add to new
                        const reportPrefix = FirebasePaths.FIREBASE_HOOTS_REPORTS +
                            '/' +
                            FirebasePaths.FIREBASE_HOOTS_REPORTS_HOOTS + '/';
                        const reportId = firebase.database().ref().push().key;
                        const reportNewPath = reportPrefix + FirebasePaths.FIREBASE_HOOTS_REPORTS_NEW +
                            '/' +
                            reportId;
                        const reportOldPath = reportPrefix + FirebasePaths.FIREBASE_HOOTS_REPORTS_OLD +
                            '/' +
                            reportId;
                        const reportObject = new HootReport();
                        reportObject.id = reportId;
                        reportObject.hootId = item.id;
                        reportObject.msg = this.state.report;
                        reportObject.reporterId = this.props.uid;
                        reportObject.reporterEmail = this.props.authUserInfo.userEmail;
                        reportObject.reporterUsername = this.props.authUserInfo.username;
                        reportObject.reportedUID = item.admin;
                        reportObject.reportedUsername = item.historyParticipants[item.admin].username;
                        reportObject.timestamp = new Date().toDateString();
                        await firebase.database().ref(reportNewPath).set(reportObject);
                        await firebase.database().ref(reportOldPath).set(reportObject);
                        // Log hoots options open
                        global.featuresLogger.HOOTS_TIMELINE_HOOT_OPTIONS_REPORT_CONFIRM();
                        presentToast({
                            message: I18n.t('SUPPORT_PAGE.success_msg')
                        })
                        this.setState({report: ''})
                    },
                },
            ],
            true,
            {
                height: perfectSize(32),
                width: perfectSize(90),
                alignItems: 'center'
            }
        ]);
    };

    checkMaxParticipants = async (item) => {
        const liveMaxParticipantsPath = FirebasePaths.FIREBASE_HOOTS_CONF + '/' + FirebasePaths.FIREBASE_HOOTS_MAX_LIVE_PARTICIPANTS;
        const liveMaxParticipantsRef = await firebase.database().ref(liveMaxParticipantsPath).once('value');
        const liveMaxParticipants = liveMaxParticipantsRef.val();

        const historyMaxParticipantsPath = FirebasePaths.FIREBASE_HOOTS_CONF + '/' + FirebasePaths.FIREBASE_HOOTS_MAX_HISTORY_PARTICIPANTS;
        const historyMaxParticipantsRef = await firebase.database().ref(historyMaxParticipantsPath).once('value');
        const historyMaxParticipants = historyMaxParticipantsRef.val();
        const {liveParticipants, historyParticipants} = item;
        if (liveParticipants && Object.keys(liveParticipants).length >= liveMaxParticipants) {
            presentToast({
                message: I18n.t('HOOTS_PAGE.max_join_exceed'),
            })
            return true;
        } else {
            const {uid} = this.props;
            if (historyParticipants && !historyParticipants[uid]) {
                if (Object.keys(historyParticipants).length >= historyMaxParticipants) {
                    presentToast({
                        message: I18n.t('HOOTS_PAGE.max_join_exceed'),
                    })
                    return true;
                }
            }
        }
    }

    toDetailPage = async (item) => {
        this.props.showSpinner({});
        const isBannedFromSystemHootJoin = await this.checkSystemBanJoin();
        if (isBannedFromSystemHootJoin) {
            this.props.showAlert([
                'Wooah There!',
                I18n.t('HOOTS_PAGE.hoots_system_ban_join'),
                [
                    {
                        text: I18n.t('CHAMPIONSHIP_INFO_PAGE.error_loading_championship_btn_contact'),
                        onPress: () => {
                            NavigationService.moveToScreenInStack('SettingsStack', 'ContactUsPage');
                        },
                    }
                ],
                true, // isRow
            ]);
            this.props.hideSpinner();
            return;
        }
        const isBannedFromHoot = await this.checkBannedFromHoot(item);
        this.props.hideSpinner();
        if (!isBannedFromHoot) {
            // Log clicking join button
            global.featuresLogger.HOOTS_TIMELINE_LIVE_HOOT_JOIN();
            this.setState({
                selectedHoot: {...item},
                isHootDetailVisible: true
            })
        } else {
            this.props.showAlert([
                'Wooah!',
                I18n.t('HOOTS_PAGE.hoots_banned_alert'),
                [
                    {
                        text: I18n.t('HOOTS_PAGE.hoots_banned_OK'),
                        onPress: () => {
                        },
                    }
                ],
                true, // isRow
            ]);
        }
    }

    checkBannedFromHoot = async (item) => {
        const {uid} = this.props;
        const bannedHootsPath = FirebasePaths.FIREBASE_USERS_INFO_ATTR +
            '/' +
            uid +
            '/' +
            FirebasePaths.FIREBASE_HOOTS_BANS +
            '/' +
            item.id;
        const bannedSnap = await firebase.database().ref(bannedHootsPath).once('value');
        if (bannedSnap.val()) {
            return true;
        }
        return false;
    }

    checkSystemBanJoin = async () => {
        const {uid} = this.props;
        const bannedHootsPath = FirebasePaths.FIREBASE_USERS_INFO_ATTR +
            '/' +
            uid +
            '/' +
            FirebasePaths.FIREBASE_USER_BAN +
            '/' +
            FirebasePaths.FIREBASE_USER_BAN_HOOT_JOIN;
        const bannedSnap = await firebase.database().ref(bannedHootsPath).once('value');
        if (bannedSnap.val()) {
            return true;
        }
        return false;
    }

    render() {
        const {theme, isFocused} = this.props;
        const _styles = styles(theme);
        const {isLoading, isHootDetailVisible} = this.state;
        if (isFocused) {
            return (
                <View style={_styles.container}>
                    <TextInput
                        placeholder={I18n.t('HOOTS_PAGE.search_for_hoots')}
                        onChangeText={_.debounce(
                            (value) => this.onSearchHoots(value),
                            300,
                        )}
                        onFocus={() => {
                            // Log live hoots search trigger
                            global.featuresLogger.HOOTS_TIMELINE_LIVE_SEARCH_TRIGGER();
                        }}
                        inputStyles={{fontSize: perfectSize(14)}}
                        customStyles={{marginVertical: perfectSize(16), alignItems: 'center', }}
                        rightComponent={
                            <Feather name="search" size={perfectSize(18)} color="#A2A5A9" />
                        }
                    />
                    {isHootDetailVisible &&
                        <HootDetailModalCard
                            isVisible={isHootDetailVisible}
                            selectedHoot={this.state.selectedHoot}
                            {...this.state.selectedHoot}
                            onHide={(isDismiss = true) => {
                                if (isDismiss) {
                                    // Log live hoot join dismiss
                                    global.featuresLogger.HOOTS_TIMELINE_LIVE_HOOT_JOIN_DISMISS();
                                }
                                this.setState({isHootDetailVisible: false});
                            }}
                        />
                    }
                    <View style={_styles.subContent}>
                        <FlatList
                            data={this.state.displayHoots}
                            showsVerticalScrollIndicator={false}
                            style={{width: '100%'}}
                            numColumns={2}
                            columnWrapperStyle={{justifyContent: 'space-between'}}
                            renderItem={({item, index}) => {
                                return <View style={{
                                    width: '50%',
                                    paddingLeft: index % 2 === 1 ? perfectSize(8) : 0,
                                    paddingRight: index % 2 === 0 ? perfectSize(8) : 0,
                                }}>
                                    <HootCard
                                        onPressMore={this.onPressMore}
                                        onPress={this.toDetailPage}
                                        item={{
                                            isLive: true,
                                            ...item
                                        }}
                                    />
                                </View>
                            }}
                            keyExtractor={(item) => item.id.toString()}
                        />
                    </View>
                </View>
            );
        }
        return <View />;
    }
}

const mapStateToProps = (state) => {
    return {
        theme: state.appReducer.currentTheme,
        lang: state.appReducer.currentLang,
        uid: state.authReducer.uid,
        blockedList: state.hubReducer.blockedList,
        friendsList: state.hubReducer.friendsList,
        authUserInfo: state.authReducer.userinformationDB,
    };
};

function focusComponent(props) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const isFocused = useIsFocused();
    return <LiveHootsPage {...props} isFocused={isFocused} />;
}

export default compose(
    connect(mapStateToProps, {switchTheme, HubSet, showAlert, showSpinner, hideSpinner, showInputAlert}),
)(focusComponent);
