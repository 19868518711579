import React, { Component } from 'react';
import { View, SectionList, TouchableOpacity, FlatList } from 'react-native';
import { compose } from 'redux';
import { connect } from 'react-redux';
import I18n from '../../i18n';
import { store } from '../../redux/store';

// import components
import Label from '../../components/label';
import Header from '../../components/header';
import NotificationCard from '../../components/cards/notification-card';

// import styles
import AppStyles from '../../constants/styles';
import styles from './styles';
import { perfectSize } from '../../helpers/deviceHelper';

import { set as setHub } from '../../redux/modules/hub/actions';

import * as FirebasePaths from '../../constants/firebasePaths';
import firebase from 'firebase/app';
import 'firebase/database';

import Userprofilecore from '../userprofile/userprofilecore';
import NavigationService from '../../navigation/navigationService';
import MainMenuService from '../../navigation/mainMenuService';
import topTabService from '../../navigation/topTabService';

import {
  NOTIFICATION_LOADING_DARK_GIF,
  NOTIFICATION_LOADING_GIF,
} from '../../constants/images';
import mainMenuService from '../../navigation/mainMenuService';
import { Placeholder, Progressive } from 'rn-placeholder';
import SkeletonTextContent from '../../components/skeletonWrapper/skeletonTextContent';

class NotificationsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notificationsArr: [],
      isLoading: true,
    };
  }

  componentDidMount() {
    // Log Rank HP
    global.rankLogger.LOG_NEW_HP(
      global.rankLogger.VIEW_NOTIFICATION_PAGE,
      'VIEW_NOTIFICATION_PAGE',
    );
    // Log the user notifications page view
    global.featuresLogger.LOG_NOTIFICATIONS_USER_NOTIFICATIONS_PAGE_VIEW();

    // Load team notifications
    Userprofilecore.loadUserNotifications()
      .once('value')
      .then((snapshot) => {
        let notificationsArr = [];
        snapshot.forEach((item) => {
          notificationsArr.push(item.val());
        });
        notificationsArr.reverse();
        this.setState({ notificationsArr, isLoading: false, });
      });
  }

  componentWillUnmount() {
    // Before you leave change the notifications status !
    this.changeNotificationsStatus();
  }

  // Change notifications status
  changeNotificationsStatus() {
    // now get the path and upload the arr
    let path =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      store.getState().authReducer.uid +
      '/' +
      FirebasePaths.FIREBASE_USER_NOTIFICATIONS;
    // First loop through the arr and change the notifications status
    this.state.notificationsArr.forEach((notification) => {
      if (notification.status === 'UNREAD') {
        notification.status = 'READ';
        firebase.database()
          .ref(path + '/' + notification.id)
          .set(notification);
      }
    });
  }

  notificationTrigger = (notification) => {
    // Log Feature
    global.featuresLogger.LOG_NOTIFICATIONS_USER_NOTIFICATIONS_CLICKS();
    // Types :
    // TEAM : Team ID
    // USER : User Key
    // REQUEST : Request ID
    // HOP : Hop ID
    // UPDATE : with current user key, but it will redirect the user to edit profile page
    if (notification.type === 'TEAM') {
      // Redirect the user to the team page
      NavigationService.navigate('TeamPage', {
        TEAM_ID: notification.TRIGGER,
        CAME_FROM: 'TEAMS',
      });
    } else if (notification.type === 'TEAM_ACCEPT') {
      // Redirect the user to the team page
      NavigationService.navigate('TeamPage', {
        TEAM_ID: notification.TRIGGER,
        CAME_FROM: 'TEAMS',
      });
    } else if (notification.type === 'CLAN_DIRECT_JOIN') {
      // Redirect the user to the team page
      NavigationService.navigate('TeamPage', {
        TEAM_ID: notification.TRIGGER,
        CAME_FROM: 'TEAMS',
      });
    } else if (notification.type === 'CLAN_UPDATE') {
      NavigationService.navigate('TeamPage', {
        TEAM_ID: notification.TRIGGER,
        CAME_FROM: 'TEAMS',
        update: true,
      });
    } else if (notification.type === 'USER') {
      // Redirect the user to the team page
      NavigationService.navigate('ViewfriendprofilePage', {
        FRIEND_KEY: notification.TRIGGER,
      });
    } else if (notification.type === 'HOP_LIKE') {
      NavigationService.navigate('PostPage', {
        CAME_FROM: 'NOTIFICATION',
        POST_ID: notification.TRIGGER,
      });
    } else if (notification.type === 'HOP_DISLIKE') {
      NavigationService.navigate('PostPage', {
        CAME_FROM: 'NOTIFICATION',
        POST_ID: notification.TRIGGER,
      });
    } else if (notification.type === 'RATE') {
      // Redirect the user to the group chat page
      MainMenuService.navigate('Profile');
      NavigationService.navigate('TabStack');
    } else if (notification.type === 'JOIN_REQUEST') {
      // Redirect the user to the group chat page
      NavigationService.navigate('TabStack');
      MainMenuService.navigate('Chat');
    } else if (notification.type === 'HOP') {
      // Hops tab
      // MainMenuService.navigate('Home');
      // NavigationService.navigate('TabStack');
      // MainMenuService.navigate('Hops');
      NavigationService.navigate('PostPage', {
        CAME_FROM: 'NOTIFICATION',
        POST_ID: notification.TRIGGER,
      });
    } else if (notification.type === 'HOP_MENTION') {
      NavigationService.navigate('PostPage', {
        CAME_FROM: 'NOTIFICATION',
        POST_ID: notification.TRIGGER,
      });
    } else if (notification.type === 'TEAM_INVITATION') {
      NavigationService.navigate('TabStack');
      setTimeout(() => {
        mainMenuService.navigate('Home');
        topTabService.navigate('Requests');
        setTimeout(() => {
          topTabService.navigate('Clans', {
            SELECTED_SECTION: 'TEAMS_INVITATIONS',
          });
        }, 150);
      }, 150);
    } else if (notification.type === 'TEAM_JOINS') {
      if (
        notification.TRIGGER != null &&
        notification.TRIGGER2 != null &&
        notification.TRIGGER3 != null
      ) {
        NavigationService.moveToScreenInStack(
          'HomeStack',
          'TeamJoinRequestsPage',
          {
            TEAM_ID: notification.TRIGGER,
            TEAM_NAME: notification.TRIGGER2,
            TEAM_LOGO: notification.TRIGGER3,
          },
        );
      }
    } else if (notification.type === 'UPDATE') {
      // Redirect the user to the edit profile page
      NavigationService.moveToScreenInStack('SettingsStack', 'EditProfilePage');
    }
  };

  renderCard = ({ item, index }) => {
    return (
      <TouchableOpacity
        style={{ marginBottom: perfectSize(16) }}
        onPress={() => this.notificationTrigger(item)}>
        <NotificationCard notification={item} />
      </TouchableOpacity>
    );
  };

  // Getting Animated Gif for the right mode
  getGif() {
    if (this.props.theme.mode === 'dark') {
      return NOTIFICATION_LOADING_DARK_GIF;
    } else {
      return NOTIFICATION_LOADING_GIF;
    }
  }

  render() {
    const { notificationsArr, isLoading } = this.state;
    const { theme } = this.props;
    let unreadArr = [];
    let readArr = [];
    notificationsArr.forEach((notification) => {
      if (notification.status === 'UNREAD') {
        unreadArr.push(notification);
      } else {
        readArr.push(notification);
      }
    });
    const sections = [
      {
        title: I18n.t('NOTIFICATIONS_PAGE.new'),
        data: unreadArr,
      },
      {
        title: I18n.t('NOTIFICATIONS_PAGE.earlier'),
        data: readArr,
      },
    ];
    return (
      <View style={AppStyles.rootContainer}>
        <Header text={I18n.t('NOTIFICATIONS_PAGE.title')} />
        {/* Content */}
        <View style={AppStyles.rootContent}>
          {
            isLoading &&
            <FlatList
              data={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9]}
              keyExtractor={(item) => item.toString()}
              showsVerticalScrollIndicator={false}
              renderItem={() => {
                return <View style={{ marginVertical: perfectSize(6), padding: perfectSize(10) }}>
                  <Placeholder
                    Animation={Progressive}
                  >
                    <SkeletonTextContent height={30} />
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginVertical: perfectSize(15) }}>
                      <View style={{ flex: 1 }} />
                      <SkeletonTextContent height={15} width={10} />
                    </View>
                  </Placeholder>
                </View>;
              }}
            />
          }
          {!isLoading && notificationsArr.length > 0 ? (
            <SectionList
              stickySectionHeadersEnabled={false}
              sections={sections}
              renderItem={(item) => this.renderCard(item)}
              showsVerticalScrollIndicator={false}
              renderSectionHeader={({ section }) => (
                <View>
                  {section.data.length > 0 ? (
                    <Label style={styles.header}>{section.title}</Label>
                  ) : null}
                </View>
              )}
              keyExtractor={(item, index) => item + index}
            />
          ) : (
            !isLoading &&
            <View style={styles.emptyContent}>
              <View style={AppStyles.emptyContent}>
                {/* <FastImage
                  source={this.getGif()}
                  style={AppStyles.emptyContentGif}
                  resizeMode="contain"
                /> */}
                <Label style={AppStyles.emptyContentLabel}>
                  {I18n.t('NOTIFICATIONS_PAGE.no_notifications_msg')}
                </Label>
              </View>
            </View>
          )}
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
    lang: state.appReducer.currentLang,
  };
};

export default compose(
  connect(mapStateToProps, {
    setHub,
  }),
)(NotificationsPage);
