import React, { Component } from 'react';
import { View, FlatList, ActivityIndicator } from 'react-native';
import Feather from 'react-native-vector-icons/Feather';

import { compose } from 'redux';
import { connect } from 'react-redux';
import I18n from '../../../i18n';

// import components
import HopCard from '../../../components/cards/hop-card';
import HopSkeletonCard from '../../../components/cards/hop-card/skeleton';
import PrimaryButton from '../../../components/buttons/primary-button';

// import styles
import styles from './styles';

// import actions
import { switchTheme } from '../../../redux/modules/app/actions';

import { perfectSize } from '../../../helpers/deviceHelper';
import NavigationService from '../../../navigation/navigationService';
import TimelineCore from '../timelinecore';

import { presentToast } from '../../../helpers/toastHelper';
import { arrayFromSnapshot, dataForSkeleton } from '../../../services/utils';
import { set as HubSet } from '../../../redux/modules/hub/actions';

import { showAlert } from '../../../redux/modules/alert/actions';
import { useIsFocused } from '@react-navigation/native';
class MyHopsPage extends Component {
  myHops$ = null;

  constructor(props) {
    super(props);
    this.state = { isLoading: true };
  }

  componentWillUnmount() {
    if (this.myHops$) {
      this.myHops$.off();
      this.myHops$ = null;
    }

    clearInterval(this.interval);
  }

  componentDidMount() {
    this.loadMyHops();

    this.interval = setInterval(
      () => this.setState({ time: Date.now() }),
      60 * 1000,
    );
  }

  loadMyHops = () => {
    this.myHops$ = TimelineCore.loadMyHops();
    this.myHops$.on('value', (snapshot) => {
      let posts = arrayFromSnapshot(snapshot);
      posts.reverse();
      this.setState({
        myHops: posts,
        isLoading: false,
      });

      for (let i = 0; i < posts.length; i++) {
        TimelineCore.loadHop(posts[i].postId).once('value', (result) => {
          posts[i] = result.val();

          this.setState({
            myHops: posts,
          });
        });
      }
    });
  };

  goToDetails = (item) => {
    // NavigationService.moveToScreenInStack('HomeStack', 'PostPage');
    TimelineCore.IS_REPLY_HOP_BANNED((banned) => {
      if (banned) {
        presentToast({
          message: I18n.t('TIMELINE_PAGE.ban_alert_msg2'),
        });
      } else {
        NavigationService.moveToScreenInStack('HomeStack', 'PostPage', {
          POST_ID: item.id,
        });
      }
    });
  };

  deleteMyHop = (item) => {
    this.props.showAlert([
      I18n.t('TIMELINE_PAGE.confirm_delete_hop_title'),
      I18n.t('TIMELINE_PAGE.confirm_delete_hop_msg'),
      [
        {
          text: I18n.t('TIMELINE_PAGE.confirm_delete_hop_yes_btn'),
          onPress: () => {
            TimelineCore.deleteMyHop(item);
          },
        },
        {
          text: I18n.t('TIMELINE_PAGE.confirm_delete_hop_no_btn'),
          onPress: () => { },
        },
      ],
      false,
    ]);
  };
  sendHop = () => {
    TimelineCore.IS_POST_HOP_BANNED((response) => {
      if (response == null) {
        NavigationService.moveToScreenInStack('HomeStack', 'PostHopPage');
      } else {
        this.props.showAlert([
          I18n.t('TIMELINE_PAGE.ban_alert_title'),
          I18n.t('TIMELINE_PAGE.ban_alert_msg'),
          [
            {
              text: I18n.t('TIMELINE_PAGE.ban_alert_btn'),
              onPress: () => {
                NavigationService.moveToScreenInStack(
                  'SettingsStack',
                  'ContactUsPage',
                );
              },
            },
          ],
          false,
        ]);
      }
    });
  };
  render() {
    const { theme, isFocused } = this.props;
    const _styles = styles(theme);
    const { isLoading } = this.state;
    return (
      <View style={_styles.container}>
        {isLoading ? (
          <View style={_styles.subContent}>
            {/* <ActivityIndicator color="#C70064" size="large" /> */}
            <FlatList
              showsVerticalScrollIndicator={false}
              data={dataForSkeleton(5)}
              style={{ width: '100%' }}
              renderItem={() => {
                return <HopSkeletonCard />;
              }}
              keyExtractor={(item) => item.toString()}
            />
          </View>
        ) : (
          <View style={_styles.content}>
            <PrimaryButton
              text={I18n.t('POST_PAGE.new_post')}
              size="x_small"
              type="filled"
              customStyles={_styles.postButtonStyle}
              textCustomStyles={_styles.postButtonTextStyle}
              onPress={this.sendHop}
              leftComponent={
                <Feather
                  name="plus-circle"
                  size={perfectSize(18)}
                  color={theme.primary_text}
                  style={{ marginRight: perfectSize(12) }}
                />
              }
            />
            <FlatList
              data={this.state.myHops}
              showsVerticalScrollIndicator={false}
              renderItem={({ item, index }) => (
                <HopCard
                  item={item}
                  index={index}
                  onPress={() => this.goToDetails(item)}
                  onLongPress={() => this.deleteMyHop(item)}
                  onMenu={() => this.deleteMyHop(item)}
                  disableLike={true}
                />
              )}
              keyExtractor={(item, index) => {
                if (item.type === 'TEXT_ONLY') {
                  return item.id;
                } else {
                  return index + '' + item.requestTimeStamp;
                }
              }}
            />
          </View>
        )}
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
    lang: state.appReducer.currentLang,
    toOthersRepliesMap: state.hubReducer.toOthersRepliesMap,
    toOthersRepliesArr: state.hubReducer.toOthersRepliesArr,
    othersRepliesMap: state.hubReducer.othersRepliesMap,
    othersRepliesArr: state.hubReducer.othersRepliesArr,
  };
};

function focusComponent(props) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const isFocused = useIsFocused();
  return <MyHopsPage {...props} isFocused={isFocused} />;
}

export default compose(
  connect(mapStateToProps, { switchTheme, HubSet, showAlert }),
)(focusComponent);
