import React, { Component } from 'react';
import { View, TouchableOpacity, Image } from 'react-native';
import { compose } from 'redux';
import { connect } from 'react-redux';
import I18n from '../../i18n';
import AntDesign from 'react-native-vector-icons/AntDesign';
import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';

import { ART } from '../../constants/images';
// import components
import Header from '../../components/header';
import Label from '../../components/label';
import PrimaryButton from '../../components/buttons/primary-button';
import TextInput from '../../components/text-input';
import TextArea from '../../components/text-area';

// import styles
import AppStyles from '../../constants/styles';
import styles from './styles';

// import actions
import { switchTheme } from '../../redux/modules/app/actions';

import { perfectSize } from '../../helpers/deviceHelper';
import { presentToast } from '../../helpers/toastHelper';
import { arrayFromSnapshot } from '../../services/utils';
import NavigationService from '../../navigation/navigationService';
import TimelineCore from '../timeline/timelinecore';
import { showSpinner, hideSpinner } from '../../redux/modules/alert/actions';
import * as ImagePicker from 'expo-image-picker';

import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import ImageWithPlaceholder from '../../components/image-with-placeholder';
import LocalImageOpacity from '../../components/local-image-opacity';

/**
 * This is new page for post hop alert
 */
class PostHopPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      postMsg: '',
      images: ['', '', '', ''],
      imageHeight: [100, 100, 100, 100]
    };
  }

  createHop = () => {
    if (
      this.state.postMsg.trim().length > 10 &&
      this.state.postMsg.trim().length < 280
    ) {
      this.props.showSpinner({});
      firebase.database()
        .ref('_prohibited_words_')
        .once('value')
        .then(async (snapshot) => {
          const words = arrayFromSnapshot(snapshot);
          let prohibited = false;
          words.forEach((badword) => {
            if (this.state.postMsg.includes(badword)) {
              prohibited = true;
            }
          });
          if (prohibited) {
            presentToast({
              message: I18n.t('TIMELINE_PAGE.porhibited_words_message'),
            });
          } else {
            global.rankLogger.LOG_NEW_HP(
              global.rankLogger.POST_HOP,
              'POST_HOP',
            );
            global.featuresLogger.LOG_HOP_SEND();
            let id = firebase.database().ref().push().key;
            // check if the user has a border color
            let borderColor = '#C70464';
            if (
              this.props.userInformation.profileBorderColor != null &&
              this.props.userInformation.profileBorderColor != undefined &&
              this.props.userInformation.profileBorderColor != ''
            ) {
              borderColor = this.props.userInformation.profileBorderColor;
            } else {
              borderColor = '#C70464';
            }
            let postIMG =
              'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/teams%20default%20photos%20and%20background%2Fdefault_team_logov2.png?alt=media&token=b88bf926-35b9-4568-a80e-ee24110aceb6';
            if (
              this.props.userInformation.pictureURL != null &&
              this.props.userInformation.pictureURL != ' ' &&
              this.props.userInformation.pictureURL != '' &&
              this.props.userInformation.pictureURL != undefined
            ) {
              postIMG = this.props.userInformation.pictureURL;
            }

            let hops_icon = '';
            if (
              this.props.userInformation.hops_icon != '' &&
              this.props.userInformation.hops_icon != null
            )
              hops_icon = this.props.userInformation.hops_icon;

            let post = {
              id: id,
              description: this.state.postMsg,
              title: this.props.userInformation.username,
              type: 'TEXT_ONLY',
              img: this.props.userInformation.pictureURL,
              postImg: postIMG,
              postDescription: this.state.postMsg,
              timeStamp: firebase.database.ServerValue.TIMESTAMP,
              uid: this.props.uid,
              username: this.props.userInformation.username,
              postId: id,
              poster: this.props.uid,
              IS_HOP_SHADOW_ACTIVATED: this.props.IS_HOP_SHADOW_ACTIVATED,
              userBorderColor: borderColor,
              hops_icon: hops_icon,
            };

            post.rank = this.props.userInformation.rank;

            let urls = await this.uploadImages(id);
            post.images = urls;

            TimelineCore.uploadPost(post, (response) => {
              NavigationService.goBack();

              presentToast({
                message: I18n.t('TIMELINE_PAGE.success_hop_upload'),
              });

              this.props.hideSpinner({});
            });
          }
        });
    } else {
      presentToast({
        message: I18n.t('TIMELINE_PAGE.validation_msg'),
      });
    }
  };

  uploadImages = async (id) => {
    let imageExists = false;
    let urls = [];
    for (let i = 0; i < this.state.images.length; i++) {
      if (this.state.images[i] != '') {
        let url = await this.uploadImage(id, this.state.images[i]);
        urls.push(url);
        imageExists = true;
      }
    }

    if (imageExists) {
      global.featuresLogger.HOP_SEND_IMG();
    }
    return urls;
  };

  makeid = (length) => {
    let result = '';
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  uploadImage = (id, image) => {
    let uid = this.props.uid;
    let storageRef = firebase.storage().ref();
    let filename = this.makeid(20) + '_' + uid;
    let imageRef = storageRef.child('HopImgs').child(id).child(filename);

    return imageRef.putString(image, 'data_url').then(() => {
      return imageRef.getDownloadURL().then((downloadURL) => {
        return downloadURL;
      });
    });
  };

  addImage = async (index) => {
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
    })
    if (!result.cancelled) {
      let images = this.state.images;
      images[index] = result.uri;
      this.setState({
        images: images,
      });
    }
  };

  removeImage = (index) => {
    let images = this.state.images;
    images[index] = '';
    this.setState({
      images: images,
    });
  };

  render() {
    return (
      <View style={AppStyles.rootContainer}>
        {/* Header */}
        <Header text={I18n.t('TIMELINE_PAGE.post_alert_title')} />
        {/* Content */}
        <View style={AppStyles.rootContent}>
          <KeyboardAwareScrollView showsVerticalScrollIndicator={false}>
            <Label style={AppStyles.grayLabel}>
              {I18n.t('TIMELINE_PAGE.post_alert_rules')}
            </Label>
            <TextArea
              placeholder={I18n.t('TIMELINE_PAGE.post_alert_placeholder')}
              multiline={true}
              customStyles={styles.inputBoxCustomStyle}
              inputStyles={styles.inputBoxStyle}
              onChangeText={(val) => this.setState({ postMsg: val })}
              value={this.state.postMsg}
            />
            <View style={styles.imagePanel}>
              {this.state.images.map((image, index) => {
                if (image == '') {
                  return (
                    <TouchableOpacity
                      style={[styles.addBtn, { height: this.state.imageHeight[index] }]}
                      key={index.toString()}
                      onLayout={(event) => {
                        const height = event.nativeEvent.layout.width;
                        let { imageHeight: currentHeight } = this.state;
                        currentHeight[index] = height;
                        this.setState({
                          imageHeight: currentHeight
                        })

                      }}
                      onPress={() => {
                        this.addImage(index);
                      }}>
                      <LocalImageOpacity
                        source={ART}
                        style={styles.flexFull}
                        resizeMethod={'resize'}
                        resizeMode="contain"
                      />
                    </TouchableOpacity>
                  );
                } else {
                  return (
                    <TouchableOpacity
                      style={[styles.addBtn, styles.img]}
                      onPress={() => this.removeImage(index)}>
                      <ImageWithPlaceholder
                        uri={image}
                        style={styles.flexFull}
                        resizeMode="cover"
                      />
                    </TouchableOpacity>
                  );
                }
              })}
            </View>
            <PrimaryButton
              text={I18n.t('SUPPORT_PAGE.send')}
              type="filled"
              customStyles={styles.sendBtn}
              textCustomStyles={{ fontSize: perfectSize(15) }}
              onPress={() => this.createHop()}
            />
          </KeyboardAwareScrollView>
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
    lang: state.appReducer.currentLang,
    userInformation: state.authReducer.userinformationDB,
    IS_HOP_SHADOW_ACTIVATED: state.hubReducer.IS_HOP_SHADOW_ACTIVATED,
    uid: state.authReducer.uid,
  };
};

export default compose(
  connect(mapStateToProps, { switchTheme, showSpinner, hideSpinner }),
)(PostHopPage);
