import {createAction} from 'redux-actions';

import {
  SHOW_ALERT,
  SHOW_ALERT_FINISHED,
  HIDE_ALERT,
  SHOW_INPUT_ALERT,
  SHOW_INPUT_ALERT_FINISHED,
  HIDE_INPUT_ALERT,
  SHOW_SPINNER,
  SHOW_SPINNER_FINISHED,
  HIDE_SPINNER,
  SHOW_APP_EXIT,
  SHOW_APP_EXIT_FINISHED,
  HIDE_APP_EXIT,
} from './actionTypes';

export const showAlert = createAction(SHOW_ALERT);
export const showAlertFinished = createAction(SHOW_ALERT_FINISHED);
export const hideAlert = createAction(HIDE_ALERT);

export const showInputAlert = createAction(SHOW_INPUT_ALERT);
export const showInputAlertFinished = createAction(SHOW_INPUT_ALERT_FINISHED);
export const hideInputAlert = createAction(HIDE_INPUT_ALERT);

export const showSpinner = createAction(SHOW_SPINNER);
export const showSpinnerFinished = createAction(SHOW_SPINNER_FINISHED);
export const hideSpinner = createAction(HIDE_SPINNER);

export const showAppExit = createAction(SHOW_APP_EXIT);
export const showAppExitFinished = createAction(SHOW_APP_EXIT_FINISHED);
export const hideAppExit = createAction(HIDE_APP_EXIT);
