/* eslint-disable react-native/no-inline-styles */
import React, {Component} from 'react';
import {View, FlatList, TouchableOpacity} from 'react-native';
import {compose} from 'redux';
import {connect} from 'react-redux';
import I18n from '../../i18n';
import * as FirebasePaths from '../../constants/firebasePaths';
import NavigationService from '../../navigation/navigationService';
import firebase from 'firebase/app';
import 'firebase/database';

// import components
import Header from '../../components/header';
import Label from '../../components/label';
import AchievementCard from '../../components/cards/clan-achievement-card';

// import actions
import {showAlert} from '../../redux/modules/alert/actions';

import AppStyles from '../../constants/styles';
import styles from './styles';
import {perfectSize} from '../../helpers/deviceHelper';

class AchievementsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      achievementsArr: [],
    };
  }

  componentDidMount() {
    // Load achievements list
    let path = FirebasePaths.FIREBASE_ACHIEVEMENTS_LIST;
    firebase.database()
      .ref(path)
      .once('value')
      .then((shot) => {
        let achievementsArr = [];
        shot.forEach((item) => {
          achievementsArr.push(item.val());
        });
        this.setState({achievementsArr});
      });
  }

  // Show achievement alert
  showRequirementAlert = (ach) => {
    let msg =
      this.props.lang === 'ar' ? ach.requirementsAR : ach.requirementsEN;
    let ttle = I18n.t('ACHIEVEMENTS_PAGE.achievement_requirements');
    let contact = I18n.t('ACHIEVEMENTS_PAGE.contact_us_btn');

    this.props.showAlert([
      ttle,
      msg,
      [
        {
          text: 'Close',
          onPress: () => {},
        },
        {
          text: contact,
          onPress: () => {
            NavigationService.navigate('ContactUsPage');
          },
        },
      ],
      true,
    ]);
  };

  renderCard = ({item, index}) => {
    let title = this.props.lang === 'ar' ? item.titleAR : item.titleEN;
    return (
      <TouchableOpacity
        onPress={() => this.showRequirementAlert(item)}
        style={[
          {width: '50%', marginBottom: perfectSize(16)},
          index % 2 === 0
            ? {paddingRight: perfectSize(8)}
            : {paddingLeft: perfectSize(8)},
        ]}>
        <AchievementCard title={title} photo={item.photo} />
      </TouchableOpacity>
    );
  };

  render() {
    const {theme} = this.props;
    const _styles = styles(theme);
    const {achievementsArr} = this.state;
    return (
      <View style={AppStyles.rootContainer}>
        {/* Header */}
        <Header text={I18n.t('ACHIEVEMENTS_PAGE.title')} />
        {/* Content */}
        <View style={AppStyles.rootContent}>
          <Label style={AppStyles.grayLabel}>
            {I18n.t('ACHIEVEMENTS_PAGE.sub-title')}
          </Label>
          <FlatList
            data={achievementsArr}
            numColumns={2}
            renderItem={(item) => this.renderCard(item)}
            keyExtractor={(item, index) => String(index)}
            style={_styles.flatList}
            showsVerticalScrollIndicator={false}
          />
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
    lang: state.appReducer.currentLang,
  };
};

export default compose(connect(mapStateToProps, {showAlert}))(AchievementsPage);
