/* eslint-disable react-native/no-inline-styles */
import React, { Component } from 'react';
import { View, FlatList } from 'react-native';
import { compose } from 'redux';
import { connect } from 'react-redux';

// import components
import Label from '../../components/label';
import Header from '../../components/header';

import styles from './styles';
import AppStyles from '../../constants/styles';
import Dash from 'react-native-dash';
import * as Images from '../../constants/images';
// import RadialGradient from 'react-native-radial-gradient';
import { perfectSize } from '../../helpers/deviceHelper';
import I18n from '../../i18n';
import ImageWithPlaceholder from '../../components/image-with-placeholder';
import { LinearGradient } from 'expo-linear-gradient';
const portraitImages = ['Smurf'];
const biggestImages = ['Mythical', 'Immortal'];
let rank_array = [
  {
    name: 'Noob',
    point: 0,
    icon: Images._IMG_NOOB,
  },
  { name: 'AFK', point: 150, icon: Images._IMG_AFK },
  { name: 'Bot', point: 350, icon: Images._IMG_BOT },
  { name: 'Duck', point: 600, icon: Images._IMG_DUCK },
  { name: 'Duck Leader ', point: 900, icon: Images._IMG_DUCK_LEADER },
  { name: 'Bronze', point: 1300, icon: Images._IMG_BRONZE },
  { name: 'Bronze II', point: 1700, icon: Images._IMG_BRONZE_II },
  { name: 'Bronze III', point: 2100, icon: Images._IMG_BRONZE_III },
  { name: 'Silver', point: 2600, icon: Images._IMG_SILVER },
  { name: 'Silver II', point: 3100, icon: Images._IMG_SILVER_II },
  { name: 'Silver III', point: 3600, icon: Images._IMG_SILVER_III },
  { name: 'Gold', point: 4200, icon: Images._IMG_GOLD },
  { name: 'Gold II', point: 4800, icon: Images._IMG_GOLD_II },
  { name: 'Gold III', point: 5400, icon: Images._IMG_GOLD_III },
  { name: 'Platinum', point: 6100, icon: Images._IMG_PLATINUM },
  { name: 'Platinum II', point: 6800, icon: Images._IMG_PLATINUM_II },
  { name: 'Platinum III', point: 7500, icon: Images._IMG_PLATINUM_III },
  { name: 'Diamond', point: 8300, icon: Images._IMG_DIAMOND },
  { name: 'Diamond II', point: 9100, icon: Images._IMG_DIAMOND_II },
  { name: 'Diamond III', point: 9900, icon: Images._IMG_DIAMOND_III },
  { name: 'Master', point: 10800, icon: Images._IMG_MASTER },
  { name: 'Grand Master', point: 11700, icon: Images._IMG_GRAND_MASTER },
  { name: 'Challenger', point: 12600, icon: Images._IMG_CHALLENGER },
  { name: 'Hopro', point: 15000, icon: Images._IMG_HOPRO },

  { name: 'Sweaty', point: 30000, icon: Images._IMG_SWEATY },
  { name: 'Clutcher', point: 60000, icon: Images._IMG_CLUTCHER },
  { name: 'Smurf', point: 120000, icon: Images._IMG_SMURF },
  { name: 'Legend', point: 240000, icon: Images._IMG_LEGEND },
  { name: 'Mythical', point: 500000, icon: Images._IMG_MYTHICAL },
  { name: 'Immortal', point: 1000000, icon: Images._IMG_IMMORTAL },
];

class RankTimelinePage extends Component {
  ranks = [];

  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  componentDidMount = () => {
    const { rank } = this.props;
    for (let i = 0; i < rank_array.length; i++) {
      const _rank = rank_array[i];
      let _nextRank = null;
      if (i < rank_array.length - 1) {
        _nextRank = rank_array[i + 1];
      }
      if (rank >= _rank.point) {
        if (_nextRank && _nextRank.point < rank) {
          this.ranks.push({ ..._rank, type: 0, passed: true });
        }
        if (i === rank_array.length - 1) {
          this.ranks.push({ ..._rank, type: 0, passed: true, currentRank: true });
        }

        if (_nextRank && _nextRank.point > rank) {
          this.ranks.push({ ..._rank, type: 0, passed: true, currentRank: true });
          const remainingPoint = _nextRank.point - rank;
          this.ranks.push({
            type: 1,
            text:
              I18n.t('RANKS_TIMELINE_PAGE.more_point') +
              ' ' +
              remainingPoint +
              ' ' +
              I18n.t('RANKS_TIMELINE_PAGE.do_best'),
          });
        }
      } else {
        this.ranks.push({ ..._rank, type: 0, passed: false });
      }
    }

    this.ranks.filter((item) => {
      return item.passed;
    });

    this.interval = setInterval(() => {
      this.setState({
        visible: !this.state.visible,
      });
    }, 700);
  };

  componentWillUnmount = () => {
    clearInterval(this.interval);
  };

  render() {
    const { theme } = this.props;
    const _styles = styles(theme);
    return (
      <View style={AppStyles.rootContainer}>
        <Header text={I18n.t('RANKS_TIMELINE_PAGE.title')} />

        <View style={[AppStyles.rootContent]}>
          <FlatList
            data={this.ranks}
            showsVerticalScrollIndicator={false}
            renderItem={(rank) => {
              return (
                <>
                  <View style={_styles.rankContainer}>
                    <View style={_styles.lineContainer}>
                      <View style={_styles.line}>
                        <Dash
                          dashColor={
                            rank.index === 0
                              ? 'transparent'
                              : theme.primary_btn_background
                          }
                          dashLength={3}
                          dashGap={
                            rank.item.passed || rank.item.currentRank ? -1 : 2
                          }
                          style={_styles.dash}
                        />

                        {rank.item.type === 0 && (
                          <View
                            style={{
                              height: 16,
                              flexDirection: 'row',
                            }}>
                            {rank.item.currentRank && this.state.visible && (
                              <LinearGradient
                                style={_styles.shadow}
                                colors={[
                                  '#C7006499',
                                  // '#C7006400',
                                  'transparent',
                                ]}
                                center={[perfectSize(16), perfectSize(16)]}
                                radius={perfectSize(32)}
                              />
                            )}

                            <View
                              style={
                                rank.item.currentRank || rank.item.passed
                                  ? [_styles.dot, _styles.blink]
                                  : _styles.dotOutline
                              }
                            />
                            <View style={_styles.connectLine} />
                          </View>
                        )}

                        <Dash
                          dashColor={
                            rank.index === this.ranks.length - 1
                              ? 'transparent'
                              : theme.primary_btn_background
                          }
                          dashLength={3}
                          dashGap={rank.item.passed ? -1 : 2}
                          style={_styles.dash}
                        />
                      </View>
                    </View>
                    <View style={_styles.rankMainContainer}>
                      {rank.item.type === 0 && (
                        <View style={_styles.rankBody}>
                          <ImageWithPlaceholder
                            uri={rank.item.icon}
                            style={
                              portraitImages.includes(rank.item.name)
                                ? AppStyles.portraitRankImg
                                : biggestImages.includes(rank.item.name)
                                  ? AppStyles.biggestRankImg
                                  : AppStyles.rankImg
                            }
                            resizeMode="contain"
                          />
                          <Label bold={true} style={_styles.rankLabel}>
                            {rank.item.name}
                          </Label>
                          <Label
                            size="small"
                            bold={true}
                            style={_styles.rankPointer}>
                            {rank.item.point}
                          </Label>
                        </View>
                      )}
                      {rank.item.type === 1 && (
                        <View style={_styles.commentContainer}>
                          <Label size="small" style={_styles.comment}>
                            {rank.item.text}
                          </Label>
                        </View>
                      )}
                    </View>
                  </View>
                </>
              );
            }}
          />
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
    lang: state.appReducer.currentLang,
    rank: state.authReducer.userinformationDB.rank,
  };
};

export default compose(connect(mapStateToProps, {}))(RankTimelinePage);
