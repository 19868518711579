import {StyleSheet} from 'react-native';
import {perfectSize} from '../../helpers/deviceHelper';

const styles = StyleSheet.create({
  header: {
    marginBottom: perfectSize(16),
  },
  emptyContent: {
    height: '90%',
    justifyContent: 'center',
  },
});

export default styles;
