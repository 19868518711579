import React from 'react';
import {createStackNavigator} from 'react-navigation-stack';

import CheckRequestsPage from '../../pages/check-reqs-modal';
import HoplayMatchPage from '../../pages/find-match';
import HoplayFoundMatchPage from '../../pages/found-match';
import LobbyPage from '../../pages/lobby';
import NewRequestPage from '../../pages/newrequest';
import SelectGamePage from '../../pages/createrequest/choose-a-game';
import LeaderboardPage from '../../pages/leaderboard';
import NotificationsPage from '../../pages/notifications';
import AdPage from '../../pages/ad';
import UserprofilePage from '../../pages/userprofile';
import ViewfriendprofilePage from '../../pages/viewfriendprofile';
import GamesLibraryPage from '../../pages/games-list';
import FriendsListPage from '../../pages/friendslist';
import FriendsSearchPage from '../../pages/friendslist/search';
import SearchrequestPage from '../../pages/searchrequest';
import RequestSearchResultPage from '../../pages/searchrequestresults';

import ChatPage from '../../pages/chat';
import PrivateChatPage from '../../pages/private-chat';
import GroupRatingsPage from '../../pages/group-ratings';
import PostPage from '../../pages/post';
import PostHopPage from '../../pages/post-hop';
import TimelinePage from '../../pages/timeline';
import TeamsPage from '../../pages/teams';
import JoinChampionshipPage from '../../pages/join-championship';
import JoinClanPage from '../../pages/join-clan';
import TeamPage from '../../pages/team';
import TeamJoinRequestsPage from '../../pages/team-join-requests';
import AddTeamMemberPage from '../../pages/add-team-member';
import CommunityPage from '../../pages/community';
import RankTimelinePage from '../../pages/ranks-timeline';
import RequestshubPage from '../../pages/requestshub';
import AchievementsPage from '../../pages/achievements';
import ChampionshipInfoPage from '../../pages/championship-info';
import ChampionshipStatusPage from '../../pages/championship-status';
import UserClanSelectorPage from '../../pages/user-clan-selector';
import ChampionshipRegistrationPage from '../../pages/championship-registration';
import CheckRequestsModal from '../../pages/check-reqs-modal';
import PhotoViewerPage from '../../pages/photo-viewer';
import TeamNotificationsPage from '../../pages/team-notifications';
import UserGamesAccountsPage from '../../pages/user-games-accounts';
import TeamChatPage from '../../pages/team-chat';
import ClanSettingsPage from '../../pages/clan-settings';
import EditRequestPage from '../../pages/editrequest';
import SelectNewAdminPage from '../../pages/select-new-admin';
import UserClansPage from '../../pages/userclans';
import GameListPage from '../../pages/viewfriendprofile/game-list';
import { createAppContainer } from 'react-navigation';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { NavigationContainer } from '@react-navigation/native';
import AgoraTestPage from '../../pages/hoots/test';

// Hoots pages
import CreateNewHootPage from '../../pages/hoots/create-new-hoot';
import SelectGenrePage from '../../pages/hoots/create-new-hoot/select-genre';
import HootDetailPage from '../../pages/hoots/hoot-detail';

const homeStack = createStackNavigator(
  {
    CheckRequestsPage,
    HoplayMatchPage,
    UserClansPage,
    HoplayFoundMatchPage,
    LobbyPage,
    NewRequestPage,
    SelectGamePage,
    LeaderboardPage,
    NotificationsPage,
    AdPage,
    UserprofilePage,
    ViewfriendprofilePage,
    GamesLibraryPage,
    FriendsListPage,
    FriendsSearchPage,
    SearchrequestPage,
    RequestSearchResultPage,
    ChatPage,
    PrivateChatPage,
    PostPage,
    PostHopPage,
    TimelinePage,
    TeamsPage,
    JoinChampionshipPage,
    JoinClanPage,
    GroupRatingsPage,
    TeamPage,
    TeamJoinRequestsPage,
    AddTeamMemberPage,
    CommunityPage,
    RankTimelinePage,
    RequestshubPage,
    AchievementsPage,
    ChampionshipInfoPage,
    ChampionshipStatusPage,
    UserClanSelectorPage,
    ChampionshipRegistrationPage,
    CheckRequestsModal,
    PhotoViewerPage,
    TeamNotificationsPage,
    UserGamesAccountsPage,
    TeamChatPage,
    ClanSettingsPage,
    EditRequestPage,
    SelectNewAdminPage,
    GameListPage,
    AgoraTestPage,
    CreateNewHootPage,
    SelectGenrePage,
    HootDetailPage
  },
  {
    initialRouteName: 'NewRequestPage',
    headerMode: 'none',
    navigationOptions: {header: null},
    gestureEnabled: false,
  },
);

export default homeStack;