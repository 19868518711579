export default class Team {
  constructor() {
    this.id = '';
    this.admin = '';
    this.name = '';
    this.backgroundUrl = '';
    this.logoUrl = '';
    this.description = '';
    this.verified = false;
    this.status = '';
  }
}
