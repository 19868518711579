import {StyleSheet} from 'react-native';
import {perfectSize} from '../../helpers/deviceHelper';

export default StyleSheet.create({
  flexRow: {
    flexDirection: 'row',
  },
  alignCenter: {
    alignItems: 'center',
  },
  tabView: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: perfectSize(20),
    paddingVertical: perfectSize(10),
    borderRadius: perfectSize(12),
    marginTop: perfectSize(15),
  },
  tabItem: {
    alignItems: 'center',
  },
  tabItemName: {
    opacity: 0.6,
    marginVertical: perfectSize(5),
  },
  tabItemScore: {
    marginVertical: perfectSize(5),
  },
  activityWrap: {
    flex: 1,
    alignItems: 'center',
    marginTop: perfectSize(20),
  },
  activitiesView: {
    flex: 1,
  },
  activityItem: {
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    marginTop: perfectSize(10),
  },
  activityItemSeperator: {
    backgroundColor: 'white',
    height: perfectSize(1),
    opacity: 0.05,
  },
  itemRightView: {
    justifyContent: 'center',
    flex: 1,
    paddingLeft: perfectSize(7),
  },
  itemSecondRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  opacity: {
    opacity: 0.56,
  },
  pc_holder_circle_img: {
    height: 80,
    width: 80,
    borderColor: '#E18C00',
    borderRadius: 40,
    shadowColor: 'gray',
    shadowOffset: {width: 5, height: 5},
  },

  ps_holder_circle_img: {
    height: 80,
    width: 80,
    borderColor: '#256EB8',
    borderRadius: 40,
    shadowColor: 'gray',
    shadowOffset: {width: 5, height: 5},
  },

  xbox_holder_circle_img: {
    height: 80,
    width: 80,
    borderColor: '#107C0F',
    borderRadius: 40,
    shadowColor: 'gray',
    shadowOffset: {width: 5, height: 5},
  },

  nintendo_holder_circle_img: {
    height: 80,
    width: 80,
    borderColor: '#e60012',
    borderRadius: 40,
    shadowColor: 'gray',
    shadowOffset: {width: 5, height: 5},
  },

  mobile_holder_circle_img: {
    height: 80,
    width: 80,
    borderColor: '#9C27B0',
    borderRadius: 40,
    shadowColor: 'gray',
    shadowOffset: {width: 5, height: 5},
  },
});
