/* eslint-disable react-native/no-inline-styles */
import React, {Component} from 'react';
import {
  View,
  FlatList,
  TouchableOpacity,
  ActivityIndicator,
} from 'react-native';
import {compose} from 'redux';
import {connect} from 'react-redux';
import _ from 'lodash';
import Feather from 'react-native-vector-icons/Feather';
import firebase from 'firebase/app';
import 'firebase/database';
import I18n from '../../i18n';
import {store} from '../../redux/store';
import * as FirebasePaths from '../../constants/firebasePaths';
import NavigationService from '../../navigation/navigationService';

// import components
import Header from '../../components/header';
import Label from '../../components/label';
import UserAvatar from '../../components/user-avatar';
import TextInput from '../../components/text-input';

// import actions
import {showAlert} from '../../redux/modules/alert/actions';

import Userinformation from '../../models/userinformation';

import AppStyles from '../../constants/styles';
import styles from './styles';
import {perfectSize} from '../../helpers/deviceHelper';
import {FONT_FAMILY} from '../../constants/theme';

class AddTeamMemberPage extends Component {
  constructor(props) {
    super(props);
    const params = props.navigation.state.params;
    this.state = {
      teamName: params?.TEAM_NAME,
      teamLogo: params?.TEAM_LOGO,
      teamId: params?.TEAM_ID,
      teamAdmin: params?.TEAM_ADMIN,
      allFriendsArray: [],
      isSearching: false,
      searchedFriendsList: new Map(),
    };
  }

  componentDidMount() {
    // Log Rank HP
    global.rankLogger.LOG_NEW_HP(
      global.rankLogger.VIEW_ADD_MEMBER_PAGE,
      'VIEW_ADD_MEMBER_PAGE',
    );
  }

  // Search for player trigger
  searchForPlayerTrigger = (value) => {
    if (value !== '') {
      // Show the spinner
      this.setState({
        isSearching: true,
        allFriendsArray: [],
      });
      this.hiddenTextInput.focus();

      // Search in server
      // Getting the users DB ref
      let usersPath = '/' + FirebasePaths.FIREBASE_USERS_INFO_ATTR + '/';

      // EXTRACT FRIEND KEY
      firebase.database()
        .ref(usersPath)
        .orderByChild('/_info_/_username_')
        .startAt(value.toLowerCase())
        .endAt(value.toLowerCase() + '\uf8ff')
        .limitToFirst(10)
        .once('value')
        .then((snapshot) => {
          let searchedFriendsList = new Map();
          let userinformationDB = store.getState().authReducer
            .userinformationDB;
          if (!snapshot.val()) {
            this.setState({isSearching: false});
            this.queryTextInput.focus();
          }
          snapshot.forEach((item) => {
            let friendKey = item.key;
            // Check if this key is not the current user key
            if (userinformationDB.UID !== friendKey) {
              // Check if friend is in hub list
              if (!searchedFriendsList.has(friendKey)) {
                searchedFriendsList.set(friendKey);
              }
            }
          });
          this.setState({searchedFriendsList});
          for (var key of searchedFriendsList.keys()) {
            this.addToFriendsList(key);
          }
        });
    } else {
      this.setState({allFriendsArray: []});
    }
  };

  // Extract user info and add him/her to the list
  addToFriendsList(friendKey) {
    let friendId = friendKey;
    let bio;
    let email;
    let picUrl;
    let username;
    let status = 'online';

    let friendModel = new Userinformation();

    let friendPath =
      '/' +
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      friendKey +
      '/' +
      FirebasePaths.FIREBASE_DETAILS_ATTR;
    firebase.database()
      .ref(friendPath)
      .once('value')
      .then(async (snapshot) => {
        snapshot.forEach((item) => {
          if (item.key === FirebasePaths.FIREBASE_BIO_ATTR) {
            bio = item.val();
          }
          if (item.key === FirebasePaths.FIREBASE_EMAIL_ATTR) {
            email = item.val();
          }
          if (item.key === FirebasePaths.FIREBASE_PICTURE_URL_ATTR) {
            picUrl = item.val();
            if (picUrl === 'NULL' || picUrl === 'default') {
              // SET DEFAULT IMG
              picUrl =
                'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/teams%20default%20photos%20and%20background%2Fdefault_team_logov2.png?alt=media&token=b88bf926-35b9-4568-a80e-ee24110aceb6';
            }
          }
          if (item.key === FirebasePaths.FIREBASE_USERNAME_ATTR) {
            username = item.val();
          }
          if (item.key === FirebasePaths.FIREBASE_STATUS_ATTR) {
            status = item.val();
          }

          friendModel.UID = friendId;
          friendModel.bio = bio;
          friendModel.userEmail = email;
          friendModel.pictureURL = picUrl;
          friendModel.username = username;
          friendModel.status = status;
        });
        friendModel.borderColor = await this.getProfileBorderColor(friendId);

        let {allFriendsArray} = this.state;
        allFriendsArray.push(friendModel);
        this.setState(allFriendsArray);
        if (this.state.searchedFriendsList.size === allFriendsArray.length) {
          this.setState({isSearching: false});
          this.queryTextInput.focus();
        }
      });
  }

  getProfileBorderColor(uid) {
    let cosmeticsPath =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      uid +
      '/' +
      FirebasePaths.FIREBASE_COSMETICS_ATTR +
      '/' +
      FirebasePaths.FIREBASE_PROFILE_BORDER_ATTR;

    return firebase.database()
      .ref(cosmeticsPath)
      .once('value')
      .then((snapshot) => {
        let borderColor = '#880e4a';
        snapshot.forEach((item) => {
          if (item.key === FirebasePaths.FIREBASE_COLOR_ATTR) {
            borderColor = item.val();
          }
        });
        return borderColor;
      });
  }

  // Show  member dialog
  showMemberDialog = (player) => {
    let viewAccount = I18n.t('ADD_TEAM_MEMBER_PAGE.alert_view_acc');
    let addMember = I18n.t('ADD_TEAM_MEMBER_PAGE.alert_add_member');

    this.props.showAlert([
      player.username,
      '',
      [
        {
          text: viewAccount,
          onPress: () => {
            // Push the viewfriendpage and pass the friend key
            NavigationService.navigate('ViewfriendprofilePage', {
              FRIEND_KEY: player.UID,
            });
          },
        },
        {text: addMember, onPress: () => this.confirmAddMemberAlert(player)},
      ],
      false,
    ]);
  };

  confirmAddMemberAlert(player) {
    let title = I18n.t('ADD_TEAM_MEMBER_PAGE.confirm_add_member_title');
    let msg = I18n.t('ADD_TEAM_MEMBER_PAGE.confirm_add_member_msg');
    let yes = I18n.t('ADD_TEAM_MEMBER_PAGE.confirm_add_member_yes');
    let no = I18n.t('ADD_TEAM_MEMBER_PAGE.confirm_add_member_no');

    this.props.showAlert([
      title,
      msg,
      [
        {
          text: yes,
          onPress: () => {
            this.sendInvitation(player);
            this.successAlert();
          },
        },
        {
          text: no,
          onPress: () => {},
        },
      ],
    ]);
  }

  // Send an invitation to the user : Back-End
  sendInvitation(player) {
    const {teamId, teamLogo, teamName, teamAdmin} = this.state;
    if (teamId && teamLogo && teamName && teamAdmin) {
      // invitation id   = team id

      // Log Rank HP
      global.rankLogger.LOG_NEW_HP(
        global.rankLogger.SEND_TEAM_INVITATION,
        'SEND_TEAM_INVITATION',
      );
      // Log the team page view
      global.featuresLogger.LOG_TEAMS_TEAMS_INVITES_SENT();

      // Create DB path
      let path =
        FirebasePaths.FIREBASE_USERS_INFO_ATTR +
        '/' +
        player.UID +
        '/' +
        FirebasePaths.FIREBASE_USER_TEAMS +
        '/' +
        FirebasePaths.FIREBASE_USER_TEAMS_INVITATIONS +
        '/' +
        teamId;
      // Create invitation obj
      let invitationObj = {
        id: teamId,
        name: teamName,
        logo: teamLogo,
        admin: teamAdmin,
      };
      // Send invitation
      firebase.database().ref(path).set(invitationObj);

      // Cretea notification obj
      // Insert a notification obj to the invited user
      // Create a push id for the notification text
      let notificationId = firebase.database().ref().push().key;
      // Create a notification Obj
      let notificationObj = {
        id: notificationId,
        status: 'UNREAD',
        body: '[' + teamName + ']' + ' ' + 'ارسلولك عرض انضمام للفريق ',
        type: 'TEAM_INVITATION',
        TRIGGER: teamId,
        timeStamp: firebase.database.ServerValue.TIMESTAMP,
      };

      let userNotifications =
        FirebasePaths.FIREBASE_USERS_INFO_ATTR +
        '/' +
        player.UID +
        '/' +
        FirebasePaths.FIREBASE_USER_NOTIFICATIONS +
        '/' +
        notificationId;

      firebase.database().ref(userNotifications).set(notificationObj);
    }
  }

  // show success alert
  successAlert() {
    let msg = I18n.t('ADD_TEAM_MEMBER_PAGE.success_add_member_msg');
    this.props.showAlert(['😀', msg, [{text: 'OK', onPress: () => {}}]]);
  }

  renderItem = ({item, index}) => {
    const {theme} = this.props;
    const _styles = styles(theme);
    return (
      <TouchableOpacity
        style={_styles.itemContainer}
        onPress={() => this.showMemberDialog(item)}>
        <UserAvatar
          src={item.pictureURL}
          size={perfectSize(48)}
          borderColor={item.borderColor}
        />
        <Label size="x_small" style={{marginTop: perfectSize(8)}}>
          {item.username}
        </Label>
      </TouchableOpacity>
    );
  };

  render() {
    const {allFriendsArray} = this.state;
    const {isSearching} = this.state;
    const fontFamily =
      this.props.lang === 'en' ? FONT_FAMILY.ubuntu : FONT_FAMILY.cairo;
    return (
      <View style={AppStyles.rootContainer}>
        <Header text={I18n.t('ADD_TEAM_MEMBER_PAGE.title')} />
        <View style={AppStyles.rootContent}>
          <View
            style={[
              AppStyles.textInputContainer,
              {paddingHorizontal: 0, marginBottom: perfectSize(16)},
            ]}>
            <TextInput
              customStyles={{position: 'absolute', opacity: 0}}
              onRef={(input) => {
                this.hiddenTextInput = input;
              }}
              blurOnSubmit={false}
            />
            <TextInput
              customStyles={[AppStyles.textInput, {fontFamily: fontFamily}]}
              placeholder={I18n.t('ADD_TEAM_MEMBER_PAGE.search_placeholder')}
              onChangeText={_.debounce(
                (value) => this.searchForPlayerTrigger(value),
                1500,
              )}
              onRef={(input) => {
                this.queryTextInput = input;
              }}
              rightComponent={
                <Feather name="search" size={perfectSize(20)} color="gray" />
              }
            />
          </View>
          {isSearching && (
            <ActivityIndicator
              color="#C70064"
              style={{marginBottom: perfectSize(16)}}
            />
          )}
          <FlatList
            data={allFriendsArray}
            numColumns={3}
            renderItem={(item) => this.renderItem(item)}
            keyExtractor={(item, index) => String(index)}
            showsVerticalScrollIndicator={false}
          />
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
    lang: state.appReducer.currentLang,
  };
};

export default compose(connect(mapStateToProps, {showAlert}))(
  AddTeamMemberPage,
);
