/* eslint-disable react-native/no-inline-styles */
import React, {memo, Component} from 'react';
import {View, StyleSheet, TouchableOpacity} from 'react-native';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import I18n from '../../i18n';
import Feather from 'react-native-vector-icons/Feather';

import Label from '../label';
import ImageWithPlaceholder from '../image-with-placeholder';
import {perfectSize} from '../../helpers/deviceHelper';
import Pipes from '../../services/pipes';
import NavigationService from '../../navigation/navigationService';

import {showAlert} from '../../redux/modules/alert/actions';
import Sticker from '../sticker-image';
import { forceShowMessages } from '../../services/utils';

class MineMessageHolder extends Component {
  viewPhoto = (imageUrl) => {
    NavigationService.navigate('PhotoViewerPage', {
      TITLE: 'Chat Image',
      IMG: imageUrl,
    });
  };

  showDeleteAlert = () => {
    const {onDelete} = this.props;
    this.props.showAlert([
      I18n.t('CHAT_PAGE.delete_message'),
      I18n.t('CHAT_PAGE.delete_confirmation_message'),
      [
        {
          text: I18n.t('CHAT_PAGE.yes'),
          onPress: () => {
            onDelete();
          },
        },
        {
          text: I18n.t('CHAT_PAGE.no'),
          onPress: () => {},
        },
      ],
      true,
    ]);
  };

  render() {
    const {theme, item} = this.props;
    const _styles = styles(theme);
    return (
      <>
        {!item.deleted || forceShowMessages ? (
          <TouchableOpacity
            style={_styles.wrapper}
            onLongPress={() => this.showDeleteAlert()}>
            {item.type !== 'image' && item.type !== 'sticker' && (
              <View style={_styles.container}>
                <Label size="x_small" style={_styles.message} selectable={true}>
                  {item.message}
                </Label>
                <Label size="small" style={_styles.timestamp}>
                  {Pipes.humantime(item.timestamp)}
                </Label>
              </View>
            )}
            {item.type === 'image' && (
              <View style={[_styles.photoWrapper, {width: perfectSize(208)}]}>
                <TouchableOpacity onPress={() =>  {
                  this.viewPhoto(item.message)
                }}>
                  <ImageWithPlaceholder
                    uri={item.message}
                    style={_styles.photo}
                    uid={item.uid}
                  />
                </TouchableOpacity>
                {!!item.photoCaption && (
                  <Label
                    size="x_small"
                    style={[
                      _styles.message,
                      {
                        marginTop: perfectSize(8),
                        marginLeft: perfectSize(8),
                        textAlign: 'left',
                      },
                    ]}
                    selectable={true}>
                    {item.photoCaption}
                  </Label>
                )}
                <Label
                  size="small"
                  style={[
                    _styles.timestamp,
                    {marginTop: perfectSize(8), marginLeft: perfectSize(4)},
                  ]}>
                  {Pipes.humantime(item.timestamp)}
                </Label>
              </View>
            )}
            {item.type === 'sticker' && (
              <View style={[_styles.photoWrapper, {padding: perfectSize(12)}]}>
                <Sticker url={item.message} />
                <Label
                  size="small"
                  style={[
                    _styles.timestamp,
                    {marginTop: perfectSize(8), marginLeft: perfectSize(4)},
                  ]}>
                  {Pipes.humantime(item.timestamp)}
                </Label>
              </View>
            )}
          </TouchableOpacity>
        ) : (
          <View style={_styles.wrapper}>
            <View style={_styles.container}>
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                }}>
                <Feather
                  name="slash"
                  color="#ffffff80"
                  size={perfectSize(16)}
                />
                <Label
                  size="x_small"
                  style={[
                    _styles.message,
                    {
                      marginTop: 0,
                      marginLeft: perfectSize(8),
                      fontStyle: 'italic',
                      color: '#ffffff80',
                    },
                  ]}
                  selectable={false}>
                  {I18n.t('CHAT_PAGE.deleted_message')}
                </Label>
              </View>
              <Label size="small" style={_styles.timestamp}>
                {Pipes.humantime(item.timestamp)}
              </Label>
            </View>
          </View>
        )}
      </>
    );
  }
}

const styles = (theme) =>
  StyleSheet.create({
    wrapper: {
      alignSelf: 'flex-end',
      marginBottom: perfectSize(12),
    },
    container: {
      backgroundColor: '#3B1935',
      paddingHorizontal: perfectSize(12),
      paddingVertical: perfectSize(8),
      borderTopStartRadius: perfectSize(12),
      borderTopEndRadius: perfectSize(12),
      borderBottomStartRadius: perfectSize(12),
      minWidth: '30%',
    },
    message: {
      marginTop: perfectSize(4),
      textAlign: 'right',
    },
    timestamp: {
      color: '#A2A5A9',
      fontWeight: '300',
      marginTop: perfectSize(4),
      textAlign: 'left',
    },
    photoWrapper: {
      backgroundColor: '#3B1935',
      borderTopLeftRadius: perfectSize(12),
      borderTopRightRadius: perfectSize(12),
      borderBottomLeftRadius: perfectSize(12),
      padding: perfectSize(4),
      alignSelf: 'baseline',
    },
    photo: {
      width: perfectSize(200),
      height: perfectSize(200),
      borderTopLeftRadius: perfectSize(12),
      borderTopRightRadius: perfectSize(12),
      borderBottomLeftRadius: perfectSize(12),
    },
  });

MineMessageHolder.propTypes = {
  item: PropTypes.any,
  onDelete: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
  };
};

export default memo(connect(mapStateToProps, {showAlert})(MineMessageHolder));
