import React, {memo, Component} from 'react';
import {View, StyleSheet} from 'react-native';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Feather from 'react-native-vector-icons/Feather';
import firebase from 'firebase/app';
import 'firebase/database';

import * as FirebasePaths from '../../../constants/firebasePaths';

import {perfectSize} from '../../../helpers/deviceHelper';

import Label from '../../label';
import ImageWithPlaceholder from '../../image-with-placeholder';

class ClanSmallCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      membersCount: null,
    };
  }

  componentDidMount() {
    const {teamId} = this.props;
    if (teamId != null) {
      this.membersRef$ = firebase.database().ref(
        FirebasePaths.FIREBASE_TEAMS +
          '/' +
          teamId +
          '/' +
          FirebasePaths.FIREBASE_TEAM_MEMBERS,
      );
      this.membersRef$.on('value', (snapshot) => {
        if (snapshot.val() != null) {
          this.setState({membersCount: snapshot.numChildren() + 1});
        } else {
          this.setState({membersCount: 1});
        }
      });
    }
  }

  componentWillUnmount() {
    if (this.membersRef$) {
      this.membersRef$.off();
      this.membersRef$ = null;
    }
  }

  render() {
    const {membersCount} = this.state;
    const {theme, photo, title, isVerified} = this.props;
    const _styles = styles(theme);
    return (
      <View style={_styles.container}>
        <ImageWithPlaceholder uri={photo} style={_styles.image} />
        <View style={_styles.wrapper}>
          <View style={_styles.flexRow}>
            <Label size="x_small" style={_styles.title} numberOfLines={1}>
              {title}
            </Label>
            {isVerified && (
              <View style={_styles.verifiedBadge}>
                <Feather name="check" size={10} color={'white'} />
              </View>
            )}
          </View>
          <View style={[_styles.flexRow, {marginTop: perfectSize(4)}]}>
            <Feather name="users" color="gray" size={perfectSize(16)} />
            <Label size="small" style={_styles.membersCount}>
              {membersCount}
            </Label>
          </View>
        </View>
      </View>
    );
  }
}

ClanSmallCard.propTypes = {
  teamId: PropTypes.string,
  photo: PropTypes.string,
  title: PropTypes.string,
  isVerified: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
  };
};

export default memo(connect(mapStateToProps)(ClanSmallCard));

const styles = (theme) =>
  StyleSheet.create({
    container: {
      backgroundColor: theme.secondary_btn_background,
      borderRadius: perfectSize(12),
      overflow: 'hidden',
    },
    image: {
      width: '100%',
      height: 80,
    },
    wrapper: {
      justifyContent: 'center',
      alignItems: 'center',
      padding: perfectSize(16),
    },
    flexRow: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      maxWidth: '90%',
    },
    title: {
      textTransform: 'capitalize',
    },
    membersCount: {
      opacity: 0.56,
      marginLeft: perfectSize(4),
    },
    verifiedBadge: {
      width: perfectSize(16),
      height: perfectSize(16),
      backgroundColor: '#3B1935',
      marginLeft: perfectSize(4),
      borderRadius: perfectSize(8),
      justifyContent: 'center',
      alignItems: 'center',
    },
  });
