/* eslint-disable react-native/no-inline-styles */
import React, { Component } from 'react';
import { View, TouchableOpacity } from 'react-native';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Feather from 'react-native-vector-icons/Feather';
import SimpleLineIcons from 'react-native-vector-icons/SimpleLineIcons';
import Entypo from 'react-native-vector-icons/Entypo';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';
import { NavigationContainer } from '@react-navigation/native';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import I18n from '../../i18n';
import NavigationService from '../../navigation/navigationService';
import { store } from '../../redux/store';
import firebase from 'firebase/app';
import 'firebase/database';

import * as FirebasePaths from '../../constants/firebasePaths';

// import components
import MyTabBar from '../../components/custom-top-tab';
import Updates from './updates';
import Achievements from './achievements';
import Championships from './championships';
import About from './about';
import Members from './members';
import Label from '../../components/label';
import OverlayImage from '../../components/overlay-image';

// import actions
import { showAlert } from '../../redux/modules/alert/actions';
import { set as setHub } from '../../redux/modules/hub/actions';

import AppStyles from '../../constants/styles';
import style from './styles';
import * as Images from '../../constants/images';
import Teamcore from './teamscore';
import { presentToast } from '../../helpers/toastHelper';

import {
  handleAndroidBackButton,
  removeAndroidBackButtonHandler,
  perfectSize,
} from '../../helpers/deviceHelper';
import { clearFastImageCache } from '../../services/utils';
import ImageWithPlaceholder from '../../components/image-with-placeholder';

const Tab = createMaterialTopTabNavigator();

class TeamPage extends Component {
  constructor(props) {
    super(props);
    const params = props.navigation.state.params;
    this.mount = true;
    this.state = {
      teamId: params.TEAM_ID,
      teamBackground: '',
      teamLogo: '',
      teamName: '',
      teamDescription: '',
      verifiedTeam: false,
      teamAdmin: '',
      secondAdminsMap: new Map(),
      HAS_NEW_NOTIFICATION: false,
      status: 'NONE',
      membersMap: new Map(),
      membersArr: [],
      adminUsername: '',
      adminPhoto:
        'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/teams%20default%20photos%20and%20background%2Fdefault_team_logov2.png?alt=media&token=b88bf926-35b9-4568-a80e-ee24110aceb6',
      notificationsArr: [],

      initialRouteName: params.update
        ? I18n.t('TEAM_PAGE.updates_tab')
        : I18n.t('TEAM_PAGE.members_tab'),
    };
  }

  componentDidMount() {
    // Log the team page view
    global.featuresLogger.LOG_TEAMS_TEAM_PAGE_VIEW();

    // Log Rank HP
    global.rankLogger.LOG_NEW_HP(
      global.rankLogger.VIEW_TEAM_PAGE,
      'VIEW_TEAM_PAGE',
    );

    //Load the team info
    this.teamInfoRef$ = Teamcore.getTeamInfoRef(this.state.teamId);
    this.teamInfoRef$.on('value', (teamShot) => {
      let teamAdmin,
        teamBackground,
        teamDescription,
        teamId,
        teamLogo,
        teamName,
        verifiedTeam,
        status;
      teamShot.forEach((item) => {
        if (item.key === 'admin') {
          teamAdmin = item.val();
        }
        if (item.key === 'backgroundUrl') {
          teamBackground = item.val();
        }
        if (item.key === 'description') {
          teamDescription = item.val();
        }
        if (item.key === 'id') {
          teamId = item.val();
        }
        if (item.key === 'logoUrl') {
          teamLogo = item.val();
        }
        if (item.key === 'name') {
          teamName = item.val();
        }
        if (item.key === 'verified') {
          verifiedTeam = item.val();
        }
        if (item.key === 'status') {
          status = item.val();
        }
      });

      // Set status default value for the caln if the clan doesn't has a value
      if (status === 'NONE') {
        if (verifiedTeam) {
          status = 'PRIVATE';
          if (this.mount) {
            Teamcore.setDefaultStatusValue(this.state.teamId, true);
          }
        } else {
          status = 'PUBLIC';
          if (this.mount) {
            Teamcore.setDefaultStatusValue(this.state.teamId, false);
          }
        }
      }

      // Load admin info : photo , name
      Teamcore.loadAdmin(teamAdmin).then((shot) => {
        shot.forEach((item) => {
          // Username
          if (item.key === '_username_') {
            if (this.mount) {
              this.setState({ adminUsername: item.val() });
            }
          }
          // Photo
          if (item.key === '_picUrl_') {
            if (item.val() !== 'NULL' && item.val() !== 'default') {
              if (this.mount) {
                this.setState({ adminPhoto: item.val() });
              }
            }
          }
        });
      });
      if (this.mount) {
        this.setState({
          teamAdmin,
          teamBackground,
          teamDescription,
          teamId,
          teamLogo,
          teamName,
          verifiedTeam,
          status,
        });
      }
    });
    // Load second Admins
    this.secondAdminsRef$ = firebase.database().ref(
      FirebasePaths.FIREBASE_TEAMS +
      '/' +
      this.state.teamId +
      '/' +
      FirebasePaths.FIREBASE_TEAM_SECOND_ADMINS +
      '/',
    );
    this.secondAdminsRef$.on('value', (shot) => {
      let secondAdminsMap = new Map();
      shot.forEach((item) => {
        secondAdminsMap.set(item.key, item.val());
      });
      if (this.mount) {
        this.setState({ secondAdminsMap });
      }
    });
    // Load team notifications
    this.teamNotificationsRef$ = Teamcore.getTeamNotificationsRef(
      this.state.teamId,
    );
    this.teamNotificationsRef$.on('value', (shot) => {
      let notificationsArr = [];
      let HAS_NEW_NOTIFICATION = false;
      shot.forEach((item) => {
        let obj = item.val();
        notificationsArr.push(obj);
        if (obj.status === 'UNREAD') {
          HAS_NEW_NOTIFICATION = true;
        }
      });
      if (this.mount) {
        this.setState({ notificationsArr, HAS_NEW_NOTIFICATION });
      }
    });
    // Load members
    // Get the path
    this.membersRef$ = firebase.database().ref(
      FirebasePaths.FIREBASE_TEAMS +
      '/' +
      this.state.teamId +
      '/' +
      FirebasePaths.FIREBASE_TEAM_MEMBERS,
    );
    this.membersRef$.on('value', (shot) => {
      let membersMap = new Map();
      shot.forEach((item) => {
        let member = item.val();
        membersMap.set(member.uid, member);
      });
      if (this.mount) {

        this.setState({
          membersMap,
          membersArr: Array.from(membersMap.values()),
        });
      }
    });
  }

  componentWillMount() {
    handleAndroidBackButton(() => {
      NavigationService.goBack();
      // BackHandler.exitApp();
    });
  }

  componentWillUnmount() {
    if (this.membersRef$) {
      this.membersRef$.off();
      this.membersRef$ = null;
    }
    if (this.secondAdminsRef$) {
      this.secondAdminsRef$.off();
      this.secondAdminsRef$ = null;
    }
    if (this.teamInfoRef$) {
      this.teamInfoRef$.off();
      this.teamInfoRef$ = null;
    }
    if (this.teamNotificationsRef$) {
      this.teamNotificationsRef$.off();
      this.teamNotificationsRef$ = null;
    }
    removeAndroidBackButtonHandler();
    this.mount = false;
  }

  shareTeam = () => {
    let message = `Check ${this.state.teamName} clan profile:`;
    let subject = 'Hoplay';
    // let img = store.getState().hubReducer.shareIconsMap.SHARE_CLAN; // Todo
    let link = encodeURI(`https://hoplay.online/team/${this.state.teamId}`);
    // Share.open({
    //   message,
    //   subject,
    //   url: link,
    // }).then(() => {
    //   // Log Rank Hp
    //   global.rankLogger.LOG_NEW_HP(global.rankLogger.SHARE_CLAN, 'SHARE_CLAN');

    //   // Log share app feature
    //   global.featuresLogger.LOG_SHARE_CLAN();
    // });
  };

  isUserFound(params) {
    const { uid } = this.props;
    return params.uid === uid;
  }


  // To Update team graphics page
  toClanSettings = () => {
    // Pass the current team graphics
    const { teamLogo, teamBackground, teamId, teamAdmin, status } = this.state;
    NavigationService.navigate('ClanSettingsPage', {
      TEAM_LOGO: teamLogo,
      TEAM_BG: teamBackground,
      TEAM_ID: teamId,
      TEAM_ADMIN: teamAdmin,
      CLAN_STATUS: status,
    });
  };

  // This function will redirect user to the team notification page
  toTeamNotificationPage = () => {
    NavigationService.navigate('TeamNotificationsPage', {
      NOTIFICATIONS: this.state.notificationsArr,
      TEAM_ID: this.state.teamId,
    });
  };

  // This function will view team photo in another page
  viewPhoto = () => {
    NavigationService.navigate('PhotoViewerPage', {
      TITLE: this.state.teamName,
      IMG: this.state.teamLogo,
    });
  };

  // This function will show the join alert
  showJoinAlert = () => {
    // Check if this user is banned
    Teamcore.IS_JOIN_CLAN_BANNED().then((isBanned) => {
      if (isBanned) {
        let banTitle = I18n.t('TEAMS_PAGE.ban_alert_title_join_clan');
        let banMsg = I18n.t('TEAMS_PAGE.ban_alert_msg_join_clan');
        let banBtn = I18n.t('TEAMS_PAGE.ban_alert_btn_join_clan');
        // Show ban alert
        this.props.showAlert([
          banTitle,
          banMsg,
          [
            {
              text: banBtn,
              onPress: () =>
                NavigationService.moveToScreenInStack(
                  'SettingsStack',
                  'SupportPage',
                ),
            },
          ],
        ]);
      } else {
        // Check if the clan is private or public
        if (this.state.status === 'PUBLIC') {
          // Join directly and send a join notification to everyone
          let directJoinMsg = I18n.t('TEAM_PAGE.direct_join_alert_msg');
          let directJoinYes = I18n.t('TEAM_PAGE.direct_join_alert_yes');
          let directJoinNo = I18n.t('TEAM_PAGE.direct_join_alert_no');

          // Confirmation alert
          this.props.showAlert([
            '😀',
            directJoinMsg,
            [
              {
                text: directJoinYes,
                onPress: () => {
                  // Add this user as a member
                  let path =
                    FirebasePaths.FIREBASE_TEAMS +
                    '/' +
                    this.state.teamId +
                    '/' +
                    FirebasePaths.FIREBASE_TEAM_MEMBERS +
                    '/' +
                    store.getState().authReducer.uid;
                  // Create member obj
                  let memberObj = {
                    photo: store.getState().authReducer.userinformationDB
                      .pictureURL,
                    username: store.getState().authReducer.userinformationDB
                      .username,
                    uid: store.getState().authReducer.uid,
                  };

                  // Add member locally to the team
                  let membersArr = this.state.membersArr;
                  membersArr.push(memberObj);

                  // Add obj : BACKEND
                  firebase.database().ref(path).set(memberObj);

                  // Insert a team ref to the accepted user
                  let userTeamRef =
                    FirebasePaths.FIREBASE_USERS_INFO_ATTR +
                    '/' +
                    store.getState().authReducer.uid +
                    '/' +
                    FirebasePaths.FIREBASE_USER_TEAMS +
                    '/' +
                    FirebasePaths.FIREBASE_USER_TEAMS_REFS +
                    '/' +
                    this.state.teamId;
                  let teamRefObj = {
                    name: this.state.teamName,
                    id: this.state.teamId,
                    logo: this.state.teamLogo,
                  };
                  firebase.database().ref(userTeamRef).set(teamRefObj);

                  // Insert a notification obj to the accepted user
                  // Create a push id for the notification text
                  let notificationId = firebase.database().ref().push().key;
                  // Create a notification Obj
                  let notificationObj = {
                    id: notificationId,
                    status: 'UNREAD',
                    body:
                      '[' +
                      this.state.teamName +
                      ']' +
                      ' انضم لكم عضو جديد في الكلان رحبوا فيه ',
                    type: 'CLAN_DIRECT_JOIN',
                    TRIGGER: this.state.teamId,
                    timeStamp: firebase.database.ServerValue.TIMESTAMP,
                  };

                  // Send notification to the clan notifications
                  let clanNotificationsPath =
                    FirebasePaths.FIREBASE_TEAMS +
                    '/' +
                    this.state.teamId +
                    '/' +
                    FirebasePaths.FIREBASE_TEAM_NOTIFICATIONS +
                    '/' +
                    notificationId;
                  firebase.database().ref(clanNotificationsPath).set(notificationObj);

                  // Send notification to the admin
                  let adminNotificationPath =
                    FirebasePaths.FIREBASE_USERS_INFO_ATTR +
                    '/' +
                    this.state.teamAdmin +
                    '/' +
                    FirebasePaths.FIREBASE_USER_NOTIFICATIONS +
                    '/' +
                    notificationId;
                  firebase.database().ref(adminNotificationPath).set(notificationObj);

                  // Send notification to the members
                  membersArr.forEach((member) => {
                    let userNotificationsPath =
                      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
                      '/' +
                      member.uid +
                      '/' +
                      FirebasePaths.FIREBASE_USER_NOTIFICATIONS +
                      '/' +
                      notificationId;
                    firebase.database().ref(userNotificationsPath).set(notificationObj);
                  });

                  // Log Rank HP
                  global.rankLogger.LOG_NEW_HP(
                    global.rankLogger.CLAN_DIRECT_JOIN,
                    'CLAN_DIRECT_JOIN',
                  );
                  //
                  // Log the accept join request
                  global.featuresLogger.LOG_TEAMS_TEAM_DIRECT_JOIN();

                  // Show success message
                  presentToast({ message: 'كفوو ! انت الحين في الكلان' });
                },
              },
              {
                text: directJoinNo,
                onPress: () => { },
              },
            ],
          ]);
        } else {
          // Send a join request to the admin
          let msg = I18n.t('TEAM_PAGE.join_confirmation_message');
          let yes = I18n.t('TEAM_PAGE.join_confirmation_yes');
          let no = I18n.t('TEAM_PAGE.join_confirmation_no');
          let success = I18n.t('TEAM_PAGE.join_success');

          this.props.showAlert([
            '😀',
            msg,
            [
              {
                text: yes,
                onPress: () => {
                  // Send Join request to admin
                  Teamcore.sendJoinRequest(
                    this.state.teamId,
                    this.state.teamAdmin,
                    this.state.teamLogo,
                    this.state.teamName,
                  );

                  // Send join request to second admins
                  if (this.state.secondAdminsMap.size > 0) {
                    this.state.secondAdminsMap.forEach((value, key) => {
                      // Send Join request to admin
                      Teamcore.sendJoinRequest(
                        this.state.teamId,
                        key,
                        this.state.teamLogo,
                        this.state.teamName,
                      );
                    });
                  }

                  // Log Rank HP
                  global.rankLogger.LOG_NEW_HP(
                    global.rankLogger.SEND_TEAM_JOIN_REQUEST,
                    'SEND_TEAM_JOIN_REQUEST',
                  );

                  // Log the join req
                  global.featuresLogger.LOG_TEAMS_TEAM_JOIN_REQUEST_SENT();

                  // Show success message
                  presentToast({ message: success });
                },
              },
              { text: no, onPress: () => { } },
            ],
          ]);
        }
      } // else : ban block
    });
  };

  toTeamChat = () => {
    NavigationService.navigate('TeamChatPage', {
      TEAM_ID: this.state.teamId,
      TEAM_LOGO: this.state.teamLogo,
      TEAM_NAME: this.state.teamName,
      ADMIN_ID: this.state.teamAdmin,
      ADMIN_USERNAME: this.state.adminUsername,
      ADMIN_PHOTO: this.state.adminPhoto,
    });
  };

  render() {
    const { theme } = this.props;
    const _styles = style(theme);
    const {
      teamId,
      teamBackground,
      teamLogo,
      teamName,
      verifiedTeam,
      teamAdmin,
      secondAdminsMap,
      HAS_NEW_NOTIFICATION,
      membersMap,
    } = this.state;
    const uid = store.getState().authReducer.uid;
    return (
      <View style={AppStyles.rootContainer}>
        {/* Header */}
        <View style={_styles.header}>
          <OverlayImage
            uri={teamBackground}
            overlayImage={Images.CLAN_OVERLAY}
            style={_styles.cover}
          />
          <TouchableOpacity
            style={_styles.back}
            onPress={() => {
              clearFastImageCache();
              NavigationService.goBack();
            }}>
            <Feather
              name="chevron-left"
              color="#C70064"
              size={perfectSize(22.5)}
            />
          </TouchableOpacity>
          <View style={_styles.actions}>
            {(uid === teamAdmin || secondAdminsMap.has(uid)) && (
              <TouchableOpacity
                style={_styles.actionBtn}
                onPress={this.toTeamNotificationPage}>
                <Feather
                  name="bell"
                  color={'#C70064'}
                  size={perfectSize(18.5)}
                />
                {HAS_NEW_NOTIFICATION && (
                  <View style={_styles.notificationBadge} />
                )}
              </TouchableOpacity>
            )}
            {(uid === teamAdmin || secondAdminsMap.has(uid)) && (
              <TouchableOpacity
                style={_styles.actionBtn}
                onPress={this.toClanSettings}>
                <SimpleLineIcons
                  name="settings"
                  color={'#C70064'}
                  size={perfectSize(18)}
                />
              </TouchableOpacity>
            )}
          </View>
          <View style={_styles.headerContent}>
            <TouchableOpacity onPress={this.viewPhoto}>
              <ImageWithPlaceholder uri={teamLogo} style={_styles.avatar} />
            </TouchableOpacity>
            <View style={_styles.flexRow}>
              <Label size="x_small" style={{ textTransform: 'capitalize' }}>
                {teamName}
              </Label>
              {verifiedTeam && (
                <View style={_styles.verifiedBadge}>
                  <Feather name="check" size={10} color={'#C70064'} />
                </View>
              )}
            </View>
            {uid !== teamAdmin && !membersMap.has(uid) && (
              <TouchableOpacity
                style={_styles.joinBtn}
                onPress={this.showJoinAlert}>
                <Label style={_styles.joinLabel}>
                  {I18n.t('TEAM_PAGE.join_team_btn')}
                </Label>
              </TouchableOpacity>
            )}
          </View>
        </View>
        {/* Content */}
        <View style={[AppStyles.rootContent, _styles.content]}>
          <View style={{ flex: 1 }}>
            <NavigationContainer>
              <Tab.Navigator
                tabBar={(props) => (
                  <MyTabBar
                    {...props}
                    tabBarStyle={_styles.tabbar}
                    tabTextStyle={_styles.tabTextStyle}
                    lang={this.props.lang}
                  />
                )}
                sceneContainerStyle={AppStyles.transparent}
                initialRouteName={this.state.initialRouteName}>
                <Tab.Screen
                  name={I18n.t('TEAM_PAGE.updates_tab')}
                  component={Updates}
                  initialParams={{ teamId }}
                />
                <Tab.Screen
                  name={I18n.t('TEAM_PAGE.achievements_tab')}
                  component={Achievements}
                  initialParams={{ teamId }}
                />
                <Tab.Screen
                  name={I18n.t('TEAM_PAGE.championships_tab')}
                  component={Championships}
                  initialParams={{ teamId }}
                />
                <Tab.Screen
                  name={I18n.t('TEAM_PAGE.about_tab')}
                  component={About}
                  initialParams={{ teamId }}
                />
                <Tab.Screen
                  name={I18n.t('TEAM_PAGE.members_tab')}
                  component={Members}
                  initialParams={{ teamId }}
                />
              </Tab.Navigator>
            </NavigationContainer>
            {(membersMap.has(uid) ||
              uid === teamAdmin ||
              secondAdminsMap.has(uid)) && (
                <TouchableOpacity
                  onPress={this.toTeamChat}
                  style={_styles.leftFAB}>
                  <MaterialIcons
                    name="chat-bubble-outline"
                    size={20}
                    color="white"
                  />
                </TouchableOpacity>
              )}
          </View>
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
    lang: state.appReducer.currentLang,
    uid: state.authReducer.uid,
  };
};

export default compose(connect(mapStateToProps, { setHub, showAlert }))(TeamPage);
