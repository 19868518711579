import {StyleSheet} from 'react-native';
import {perfectSize} from '../../helpers/deviceHelper';
import Dimens from '../../constants/dimens';

const styles = (theme) =>
  StyleSheet.create({
    container: {
      flex: 1,
      width: '100%',

      backgroundColor: theme.secondary_background,
    },
    wrapper: {
      flex: 1,
      paddingHorizontal: Dimens.HEADER_PADDING_HORIZONTAL,
      backgroundColor: theme.primary_background,
      borderTopLeftRadius: Dimens.BODY_BORDER_TOP_RADIUS,
      borderTopRightRadius: Dimens.BODY_BORDER_TOP_RADIUS,
    },
    header: {
      backgroundColor: theme.secondary_background,
    },
    btnLogin: {
      height: perfectSize(52),
      width: '100%',
    },
    logo_wrapper: {
      padding: Dimens.dp32,
      alignItems: 'center',
    },
    iconLogo: {
      width: perfectSize(96),
      height: perfectSize(86),
      resizeMode: 'contain',
    },
    marginBottom: {
      marginBottom: 16,
    },
  });

export default styles;
