/* eslint-disable react-native/no-inline-styles */
import React, { memo, Component } from 'react';
import { View, FlatList, TouchableOpacity } from 'react-native';
import { connect } from 'react-redux';
import Feather from 'react-native-vector-icons/Feather';
import firebase from 'firebase/app';
import 'firebase/database';

import { store } from '../../redux/store';
import I18n from '../../i18n';
import _ from 'lodash';

import Label from '../../components/label';
import TextInput from '../../components/text-input';
import UserAvatar from '../../components/user-avatar';

// import actions
import {
  showAlert,
  showSpinner,
  hideSpinner,
} from '../../redux/modules/alert/actions';

import AppStyles from '../../constants/styles';
import styles from './styles';
import { perfectSize } from '../../helpers/deviceHelper';

import Teamcore from './teamscore';
import { presentToast } from '../../helpers/toastHelper';
import NavigationService from '../../navigation/navigationService';
import * as FirebasePaths from '../../constants/firebasePaths';
import {
  handleAndroidBackButton,
  removeAndroidBackButtonHandler,
} from '../../helpers/deviceHelper';
import ImageWithPlaceholder from '../../components/image-with-placeholder';

class Members extends Component {
  constructor(props) {
    super(props);
    const params = props.route.params;
    this.state = {
      teamId: params.teamId,
      teamAdmin: '',
      teamLogo: '',
      teamName: '',
      secondAdminsMap: new Map(),
      joinReqsArr: [],
      adminPhoto:
        'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/teams%20default%20photos%20and%20background%2Fdefault_team_logov2.png?alt=media&token=b88bf926-35b9-4568-a80e-ee24110aceb6',
      adminUsername: '',
      adminStatus: 'offline',
      TOTAL_MEMBERS: 0,
      membersArr: [],
      UNCHANGEABLE_MEMBERS_MAP: new Map(),
    };
  }

  componentDidMount() {
    this.props.showSpinner({});
    Teamcore.loadTeamInfo(this.state.teamId).then((teamShot) => {
      let teamAdmin, teamLogo, teamName;
      teamShot.forEach((item) => {
        if (item.key === 'admin') {
          teamAdmin = item.val();
        }
        if (item.key === 'logoUrl') {
          teamLogo = item.val();
        }
        if (item.key === 'name') {
          teamName = item.val();
        }
      });
      // Load admin info : photo , name
      this.adminRef$ = firebase.database().ref(
        FirebasePaths.FIREBASE_USERS_INFO_ATTR +
        '/' +
        teamAdmin +
        '/' +
        FirebasePaths.FIREBASE_DETAILS_ATTR,
      );
      this.adminRef$.on('value', (shot) => {
        shot.forEach((item) => {
          // Username
          if (item.key === '_username_') {
            this.setState({ adminUsername: item.val() });
          }
          // Photo
          if (item.key === '_picUrl_') {
            if (item.val() !== 'NULL' && item.val() !== 'default') {
              this.setState({ adminPhoto: item.val() });
            }
          }
          // Status
          if (item.key === '_status_') {
            this.setState({ adminStatus: item.val() });
          }
        });
      });
      // Load join requests if this user is the admin and show the notification trigger
      const uid = store.getState().authReducer.uid;
      if (uid === teamAdmin) {
        // Load Join requests
        let path =
          FirebasePaths.FIREBASE_USERS_INFO_ATTR +
          '/' +
          uid +
          '/' +
          FirebasePaths.FIREBASE_USER_TEAMS +
          '/' +
          FirebasePaths.FIREBASE_USER_TEAMS_JOINS +
          '/' +
          this.state.teamId;

        // Now load all the objects
        this.joinReqsRef$ = firebase.database().ref(path);
        this.joinReqsRef$.on('value', (data) => {
          let joinReqsArr = [];
          data.forEach((item) => {
            joinReqsArr.push(item.val());
          });
          this.setState({ joinReqsArr });
        });
      }
      this.setState({ teamAdmin, teamLogo, teamName });
      this.props.hideSpinner();
    });
    this.secondAdminsRef$ = firebase.database().ref(
      FirebasePaths.FIREBASE_TEAMS +
      '/' +
      this.state.teamId +
      '/' +
      FirebasePaths.FIREBASE_TEAM_SECOND_ADMINS +
      '/',
    );
    this.secondAdminsRef$.on('value', (shot) => {
      let secondAdminsMap = new Map();
      shot.forEach((item) => {
        secondAdminsMap.set(item.key, item.val());
      });
      this.setState({ secondAdminsMap });
    });
    // Get the members number
    Teamcore.loadTotalMembersNumber(this.state.teamId).then((TOTAL) => {
      this.setState({ TOTAL_MEMBERS: TOTAL });
    });
    // Load members
    this.membersRef$ = firebase.database().ref(
      FirebasePaths.FIREBASE_TEAMS +
      '/' +
      this.state.teamId +
      '/' +
      FirebasePaths.FIREBASE_TEAM_MEMBERS,
    );
    this.membersRef$.on('value', (shot) => {
      let membersArr = [];
      let UNCHANGEABLE_MEMBERS_MAP = new Map();
      shot.forEach((item) => {
        let member = item.val();
        membersArr.push(member);
        UNCHANGEABLE_MEMBERS_MAP.set(member.uid, member);
      });
      this.setState({ membersArr, UNCHANGEABLE_MEMBERS_MAP });
    });
  }

  componentWillMount() {
    handleAndroidBackButton(() => {
      NavigationService.goBack();
    });
  }

  componentWillUnmount() {
    if (this.adminRef$) {
      this.adminRef$.off();
      this.adminRef$ = null;
    }
    if (this.secondAdminsRef$) {
      this.secondAdminsRef$.off();
      this.secondAdminsRef$ = null;
    }
    if (this.membersRef$) {
      this.membersRef$.off();
      this.membersRef$ = null;
    }
    if (this.joinReqsRef$) {
      this.joinReqsRef$.off();
      this.joinReqsRef$ = null;
    }
    removeAndroidBackButtonHandler();
  }

  // This function will redirect user to the join team requests
  toJoinTeamRequests = () => {
    const { teamId, teamName, teamLogo, teamAdmin } = this.state;
    NavigationService.moveToScreenInStack('HomeStack', 'TeamJoinRequestsPage', {
      TEAM_ID: teamId,
      TEAM_NAME: teamName,
      TEAM_LOGO: teamLogo,
      TEAM_ADMIN: teamAdmin,
    });
  };

  // This function will show the options of clicking on member or admin : For General
  showMemberAdminAlert = (member, CLICKED) => {
    let viewAccounts = I18n.t(
      'TEAM_PAGE.member_options_for_admin_view_accounts',
    );
    let viewProfile = I18n.t('TEAM_PAGE.member_options_for_admin_view_profile');

    // PASSED PARAMS
    let userKey = '';
    let username = '';

    if (CLICKED === 'ADMIN') {
      userKey = this.state.teamAdmin;
      username = this.state.adminUsername;
    } else if (CLICKED === 'MEMBER') {
      userKey = member.uid;
      username = member.username;
    }

    this.props.showAlert([
      username,
      '',
      [
        {
          text: viewAccounts,
          onPress: () => {
            NavigationService.navigate('ViewfriendprofilePage', {
              FRIEND_KEY: userKey,
              ACTIVE_TAB: 1,
            });
          },
        },
        {
          text: viewProfile,
          onPress: () => {
            // Push the viewfriendpage and pass the friend key
            NavigationService.navigate('ViewfriendprofilePage', {
              FRIEND_KEY: userKey,
            });
          },
        },
      ],
      false,
    ]);
  };

  // Search for player trigger : HERE
  searchForPlayerTrigger = (value) => {
    if (value !== '') {
      // Show the spinner
      // this.IS_SEARCHING = true;

      // First search locally
      // Filter searching
      let membersArr = this.state.membersArr.filter(function (item) {
        return item.username
          .toString()
          .toLowerCase()
          .includes(value.toString().toLowerCase());
      });
      this.setState({ membersArr });

      // If not found locally search back end
      if (membersArr.length < 1) {
        // Getting the users DB ref
        let teamPath =
          '/' +
          FirebasePaths.FIREBASE_TEAMS +
          '/' +
          this.state.teamId +
          '/' +
          FirebasePaths.FIREBASE_TEAM_MEMBERS;

        firebase.database()
          .ref(teamPath)
          .orderByChild('/username')
          .startAt(value)
          .endAt(value + '\uf8ff')
          .limitToFirst(50)
          .once('value')
          .then((data) => {
            let arr = [];
            data.forEach((item) => {
              arr.push(item.val());
            });
            this.setState({ membersArr: arr });
          });
      }
    } else {
      this.setState({
        membersArr: Array.from(this.state.UNCHANGEABLE_MEMBERS_MAP.values()),
      });
    }
  };

  // This function will detect which alert to show for a member or an admin
  memberAlert = (member) => {
    const uid = store.getState().authReducer.uid;
    if (uid === this.state.teamAdmin || this.state.secondAdminsMap.has(uid)) {
      // Admin alerts
      // Add to second admins
      if (!this.state.secondAdminsMap.has(member.uid)) {
        this.showMemberOptionsForAdminWithAddSecondAdmin(member);
      } else {
        this.showMemberOptionsForAdminWithoutAddSecondAdmin(member);
      }
    } else {
      // Member alert ...
      this.showMemberAdminAlert(member, 'MEMBER');
    }
  };

  // This function will show the options of clicking  on a member : For Admin adding second admins
  showMemberOptionsForAdminWithAddSecondAdmin(member) {
    let viewAccounts = I18n.t(
      'TEAM_PAGE.member_options_for_admin_view_accounts',
    );
    let viewProfile = I18n.t('TEAM_PAGE.member_options_for_admin_view_profile');
    let kick = I18n.t('TEAM_PAGE.member_options_for_admin_kick');
    let promote = I18n.t('TEAM_PAGE.promote_member_to_admin');

    this.props.showAlert([
      member.username,
      '',
      [
        {
          text: viewAccounts,
          onPress: () => {
            NavigationService.navigate('ViewfriendprofilePage', {
              FRIEND_KEY: member.uid,
              ACTIVE_TAB: 1,
            });
          },
        },
        {
          text: viewProfile,
          onPress: () => {
            // Push the viewfriendpage and pass the friend key
            NavigationService.navigate('ViewfriendprofilePage', {
              FRIEND_KEY: member.uid,
            });
          },
        },
        {
          text: kick,
          onPress: () => {
            this.confirmMemberAlert(member);
          },
        },
        {
          text: promote,
          onPress: () => {
            // Show confirm alert
            this.confirmPromoteAlert(member);
          },
        },
      ],
      false,
    ]);
  }

  // This function will show the options of clicking  on a member : For Admin without adding second admins
  showMemberOptionsForAdminWithoutAddSecondAdmin(member) {
    let viewAccounts = I18n.t(
      'TEAM_PAGE.member_options_for_admin_view_accounts',
    );
    let viewProfile = I18n.t('TEAM_PAGE.member_options_for_admin_view_profile');
    let kick = I18n.t('TEAM_PAGE.member_options_for_admin_kick');
    let removeAdmin = I18n.t('TEAM_PAGE.downgrade_member');

    this.props.showAlert([
      member.username,
      '',
      [
        {
          text: viewAccounts,
          onPress: () => {
            NavigationService.navigate('ViewfriendprofilePage', {
              FRIEND_KEY: member.uid,
              ACTIVE_TAB: 1,
            });
          },
        },
        {
          text: viewProfile,
          onPress: () => {
            // Push the viewfriendpage and pass the friend key
            NavigationService.navigate('ViewfriendprofilePage', {
              FRIEND_KEY: member.uid,
            });
          },
        },
        {
          text: kick,
          onPress: () => {
            this.confirmMemberAlert(member);
          },
        },
        {
          text: removeAdmin,
          onPress: () => {
            // Downgrade member
            this.confirmRemoveAdminAlert(member);
          },
        },
      ],
      false,
    ]);
  }

  // Confirm kick user alert
  confirmMemberAlert(member) {
    let msg = I18n.t('TEAM_PAGE.confirm_kick_member_msg');
    let yes = I18n.t('TEAM_PAGE.join_confirmation_yes');
    let no = I18n.t('TEAM_PAGE.join_confirmation_no');

    this.props.showAlert([
      '😥',
      msg,
      [
        {
          text: yes,
          onPress: () => {
            //// Kick Member back end
            Teamcore.removeAdminRole(
              this.state.teamId,
              this.state.teamName,
              member,
            );
            Teamcore.kickMember(this.state.teamId, member.uid);

            // Show success message
            let success = I18n.t('TEAM_PAGE.kick_member_success_msg');
            presentToast({ message: success });

            // Log Rank HP
            global.rankLogger.LOG_NEW_HP(
              global.rankLogger.KICK_MEMBER_FROM_TEAM,
              'KICK_MEMBER_FROM_TEAM',
            );
          },
        },
        { text: no, onPress: () => { } },
      ],
    ]);
  }

  // Confirm promote member to admin alert
  confirmPromoteAlert(member) {
    let msg = I18n.t('TEAM_PAGE.promote_msg');
    let yes = I18n.t('TEAM_PAGE.join_confirmation_yes');
    let no = I18n.t('TEAM_PAGE.join_confirmation_no');

    this.props.showAlert([
      member.username,
      msg,
      [
        { text: no, onPress: () => { } },
        {
          text: yes,
          onPress: () => {
            // First call the backend function
            Teamcore.promoteToAdminRole(
              this.state.teamId,
              this.state.teamName,
              member,
            );
            // Insert to the local map
            // this.secondAdminsMap.set(member.uid, member.username);
            // Show success message
            let success = I18n.t('TEAM_PAGE.promote_success_msg');
            presentToast({ message: success });
          },
        },
      ],
    ]);
  }

  // Confirm promote member to admin alert
  confirmRemoveAdminAlert(member) {
    let msg = I18n.t('TEAM_PAGE.remove_admin_msg');
    let yes = I18n.t('TEAM_PAGE.join_confirmation_yes');
    let no = I18n.t('TEAM_PAGE.join_confirmation_no');

    this.props.showAlert([
      member.username,
      msg,
      [
        { text: no, onPress: () => { } },
        {
          text: yes,
          onPress: () => {
            //  Remove the admin
            // First remove from the local map
            // this.secondAdminsMap.delete(member.uid);
            // Remove from back end
            Teamcore.removeAdminRole(
              this.state.teamId,
              this.state.teamName,
              member,
            );

            // Show success message
            let success = I18n.t('TEAM_PAGE.promote_success_msg');
            presentToast({ message: success });
          },
        },
      ],
    ]);
  }

  // This function will show a post dialog or redirect user to add members page
  addMemberOrPost = () => {
    const { teamLogo, teamId, teamName, teamAdmin } = this.state;
    NavigationService.navigate('AddTeamMemberPage', {
      TEAM_LOGO: teamLogo,
      TEAM_ID: teamId,
      TEAM_NAME: teamName,
      TEAM_ADMIN: teamAdmin,
    });
  };

  render() {
    const { theme } = this.props;
    const _styles = styles(theme);
    const {
      teamAdmin,
      secondAdminsMap,
      joinReqsArr,
      adminPhoto,
      adminUsername,
      adminStatus,
      TOTAL_MEMBERS,
      UNCHANGEABLE_MEMBERS_MAP,
      membersArr,
    } = this.state;
    const uid = store.getState().authReducer.uid;
    return (
      <View style={_styles.subContent}>
        <View>
          <Label
            style={{
              alignSelf: 'center',
            }}
            size="x_small"
            bold={true}>
            {I18n.t('TEAM_PAGE.admin')}
          </Label>
          {(teamAdmin === uid || secondAdminsMap.has(uid)) && (
            <TouchableOpacity
              style={_styles.memberRequests}
              onPress={this.toJoinTeamRequests}>
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <Feather
                  name="mail"
                  size={perfectSize(16)}
                  color={theme.primary_btn_background}
                />
                <Label
                  size="small"
                  style={{
                    color: theme.primary_btn_background,
                    marginLeft: perfectSize(4),
                    marginBottom: perfectSize(2),
                  }}>
                  {I18n.t('TEAM_PAGE.join_requests')}
                </Label>
                {joinReqsArr.length > 0 && (
                  <View style={_styles.requestsBadge} />
                )}
              </View>
            </TouchableOpacity>
          )}
        </View>
        <TouchableOpacity
          onPress={() => this.showMemberAdminAlert(null, 'ADMIN')}
          style={{
            alignItems: 'center',
            marginTop: perfectSize(16),
          }}>
          <UserAvatar
            src={adminPhoto}
            borderColor={theme.primary_btn_background}
            showStatus={true}
            online={adminStatus === 'online' ? true : false}
          />
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: perfectSize(8),
            }}>
            <Label size="x_small">{adminUsername}</Label>
            <ImageWithPlaceholder
              uri={'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/GeneralIcons%2Fcrown.png?alt=media&token=e1bf7662-a6de-424d-bd9b-2c1f40d47be0'}
              style={_styles.crownImg}
              resizeMode="contain"
            />
          </View>
        </TouchableOpacity>
        {/* Members */}
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: perfectSize(16),
          }}>
          <Label
            style={{ marginRight: perfectSize(4) }}
            size="x_small"
            bold={true}>
            {I18n.t('TEAM_PAGE.members_tab')}
          </Label>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Label size="x_small" bold={true}>
              ({TOTAL_MEMBERS}
            </Label>
            <Feather
              name="users"
              color={theme.colors.primary}
              size={perfectSize(16)}
              style={{ marginLeft: perfectSize(2) }}
            />
            <Label size="x_small" bold={true}>
              )
            </Label>
          </View>
        </View>
        {UNCHANGEABLE_MEMBERS_MAP.size > 0 && (
          <View style={{ flex: 1 }}>
            <TextInput
              customStyles={{ marginVertical: perfectSize(16) }}
              placeholder={I18n.t('TEAM_PAGE.search_member')}
              onChangeText={_.debounce(
                (value) => this.searchForPlayerTrigger(value),
                300,
              )}
              leftComponent={
                <Feather name="search" size={perfectSize(20)} color="gray" />
              }
            />
            <FlatList
              data={membersArr}
              numColumns={3}
              renderItem={({ item, index }) => {
                return (
                  <TouchableOpacity
                    style={_styles.memberItem}
                    onPress={() => this.memberAlert(item)}>
                    <UserAvatar
                      src={item.photo}
                      size={perfectSize(32)}
                      borderColor={theme.primary_btn_background}
                      showStatus={true}
                      uid={item.uid}
                    />
                    <View
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginTop: perfectSize(8),
                      }}>
                      <Label
                        size="x_small"
                        style={{ marginLeft: perfectSize(8) }}>
                        {item.username}
                      </Label>
                      {secondAdminsMap.has(item.uid) && (
                        <ImageWithPlaceholder
                          uri={'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/GeneralIcons%2Fcrown.png?alt=media&token=e1bf7662-a6de-424d-bd9b-2c1f40d47be0'}
                          style={_styles.crownImg}
                          resizeMode="contain"
                        />
                      )}
                    </View>
                  </TouchableOpacity>
                );
              }}
            />
          </View>
        )}
        {UNCHANGEABLE_MEMBERS_MAP.size === 0 && (
          <View style={{ marginTop: perfectSize(32) }}>
            <View style={AppStyles.emptyContent}>
              <Label style={[AppStyles.emptyContentLabel, { marginTop: 0 }]}>
                {I18n.t('TEAM_PAGE.no_members_msg')}
              </Label>
            </View>
          </View>
        )}
        {(teamAdmin === uid || secondAdminsMap.has(uid)) && (
          <TouchableOpacity
            onPress={this.addMemberOrPost}
            style={_styles.rightFAB}>
            <Feather name="plus" size={20} color="white" />
          </TouchableOpacity>
        )}
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
  };
};

export default memo(
  connect(mapStateToProps, { showAlert, showSpinner, hideSpinner })(Members),
);
