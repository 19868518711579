import {StyleSheet} from 'react-native';
import {perfectSize} from '../../helpers/deviceHelper';
import Dimens from '../../constants/dimens';
import {FONT_FAMILY} from '../../constants/theme';

const styles = (theme) =>
  StyleSheet.create({
    backgroundImageWrapper: {
      position: 'absolute',
      width: '100%',
      height: '100%',
    },
    backgroundImage: {
      flex: 1,
      resizeMode: 'cover',
      alignItems: 'center',
      justifyContent: 'center',
    },
    container: {
      flex: 1,
      width: '100%',
      backgroundColor: theme.primary_background,
    },
    wrapper: {
      flex: 1,
      paddingHorizontal: Dimens.HEADER_PADDING_HORIZONTAL,
      paddingTop: Dimens.HEADER_PADDING_VERTICAL,
      // backgroundColor: theme.secondary_background,
    },
    imgLogo: {
      width: 212,
      height: 212,
      resizeMode: 'contain',
    },
    labelStyle: {
      textAlign: 'center',
      lineHeight: perfectSize(21),
      marginVertical: perfectSize(16),
    },
    flexRow: {
      flexDirection: 'row',
    },
    alignCenter: {
      alignItems: 'center',
    },
    justifyCenter: {
      justifyContent: 'center',
    },
    spaceBetween: {
      justifyContent: 'space-between',
    },
    buttonView: {
      backgroundColor: 'transparent',
      paddingHorizontal: perfectSize(8),
      width: perfectSize(158),
    },
    buttonSeparateView: {
      width: perfectSize(12),
    },
    buttonText: {
      fontFamily: FONT_FAMILY.ubuntu_bold,
      fontSize: perfectSize(15),
    },
  });

export default styles;
