/* eslint-disable react-native/no-inline-styles */
import React, {Component} from 'react';
import {View, FlatList, TouchableOpacity} from 'react-native';
import {compose} from 'redux';
import {connect} from 'react-redux';
import firebase from 'firebase/app';
import 'firebase/database';

import I18n from '../../i18n';
import * as FirebasePaths from '../../constants/firebasePaths';

// import components
import Label from '../../components/label';
import Header from '../../components/header';
import LoginButton from '../../components/buttons/normal-button';
import UserAvatar from '../../components/user-avatar';

import styles from './styles';
import {perfectSize} from '../../helpers/deviceHelper';

import NavigationService from '../../navigation/navigationService';

class AgentsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      agents: [],
    };
  }

  componentDidMount = () => {
    global.featuresLogger.LOG_AGENTS_AGENTS_PAGE_VIEW();
    this.loadAgents();
  };

  loadAgents() {
    firebase.database()
      .ref(FirebasePaths.FIREBASE_AGENTS_REF)
      .once('value', (snapshot) => {
        let agents = [];
        snapshot.forEach((item) => {
          agents.push({
            uid: item.key,
            username: item.val(),
          });
        });
        this.setState({agents: agents});
      });
  }

  toFriendProfile = (uid) => {
    NavigationService.navigate('ViewfriendprofilePage', {
      FRIEND_KEY: uid,
    });
  };

  render() {
    const {theme} = this.props;
    const _styles = styles(theme);
    return (
      <View style={_styles.container}>
        <Header text="Hoplay Agents" style={_styles.header} />
        <View style={_styles.wrapper}>
          <View style={_styles.mainContainer}>
            <Label
              style={{
                color: '#7D8693',
              }}
              size="x_small">
              {I18n.t('SETTINGS_PAGE.agents_msg')}
            </Label>
            <View style={_styles.listContainer}>
              <FlatList
                data={this.state.agents}
                numColumns={3}
                keyExtractor={(item, index) => index.toString()}
                showsVerticalScrollIndicator={false}
                renderItem={({item}) => {
                  return (
                    <TouchableOpacity
                      style={_styles.itemContainer}
                      onPress={() => this.toFriendProfile(item.uid)}>
                      <View style={_styles.avatarContainer}>
                        <UserAvatar
                          uid={item.uid}
                          size={perfectSize(48)}
                          borderColor={theme.primary_btn_background}
                        />
                      </View>
                      <View
                        style={{
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}>
                        <Label
                          size="x_small"
                          style={{
                            textAlign: 'center',
                            marginTop: 4,
                            textTransform: 'capitalize',
                          }}>
                          {item.username}
                        </Label>
                      </View>
                    </TouchableOpacity>
                  );
                }}
              />
            </View>
            <LoginButton
              text={I18n.t('AGENTS_PAGE.footer')}
              type="outlined"
              customStyles={_styles.btnLogin}
              textCustomStyles={{fontSize: 15}}
              onPress={() =>
                NavigationService.navigate('ContactUsPage', {
                  CAME_FROM: 'agents',
                })
              }
            />
          </View>
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
  };
};

export default compose(connect(mapStateToProps, {}))(AgentsPage);
