import {create, PREDEF_RES} from 'react-native-pixel-perfect';
import {Dimensions, BackHandler} from 'react-native';
import RNDeviceInfo from 'react-native-device-info';

const _width = 375;

export const perfectSize = (size) => {
  return size;
  // const rem = Dimensions.get('window').width / _width;
  // return size * rem;
};

export const handleAndroidBackButton = (callback) => {
  BackHandler.addEventListener('hardwareBackPress', () => {
    callback();
    return true;
  });
};

export const removeAndroidBackButtonHandler = () => {
  BackHandler.removeEventListener('hardwareBackPress', () => {});
};

export const isWindows = () => {
  const userAgent = RNDeviceInfo.getUserAgentSync();
  if (userAgent.includes('Windows')) {
    return true;
  }
  return false;
}