import React, {Component} from 'react';
import {View, TouchableOpacity, ScrollView} from 'react-native';
import {compose} from 'redux';
import {connect} from 'react-redux';
import firebase from 'firebase/app';
import 'firebase/database';

import I18n from '../../i18n';
import NavigationService from '../../navigation/navigationService';

// import components
import Header from '../../components/header';
import Label from '../../components/label';
import UserAvatar from '../../components/user-avatar';
import PrimaryButton from '../../components/buttons/primary-button';

import AppStyles from '../../constants/styles';
import styles from './styles';
import {presentToast} from '../../helpers/toastHelper';
import UserCosmeticsCore from './usercosmeticscore';

import {setProfileBorderColor} from '../../redux/modules/auth/actions';
import {showAlert} from '../../redux/modules/alert/actions';

import * as FirebasePaths from '../../constants/firebasePaths';

class UserCosmeticsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      borderColorsArr: [],
      previousColor: props.profileBorderColor,
    };
  }

  componentDidMount() {
    UserCosmeticsCore.loadBorderColors().then((snapshot) => {
      const shot = snapshot.val();
      let borderColorsMap = new Map();
      for (var key in shot) {
        borderColorsMap.set(key, shot[key]);
      }
      this.setState({borderColorsArr: Array.from(borderColorsMap.values())});
    });
    global.featuresLogger.USER_COSMETICS_PROFILE_BORDER_COLOR_PAGE_VIEW();
  }

  renderColors = () => {
    const {theme} = this.props;
    const {borderColorsArr} = this.state;
    let views = [];
    borderColorsArr.forEach((color, index) => {
      views.push(
        <TouchableOpacity key={index} onPress={() => this.selectColor(color)}>
          <View style={[styles(theme).colorItem, {backgroundColor: color}]} />
        </TouchableOpacity>,
      );
    });
    return views;
  };

  selectColor = (color) => {
    this.setState({previousColor: color});
  };

  saveColor = () => {
    const {previousColor} = this.state;
    const {rank} = this.props;
    const minRank = 6100; // was 4200 before
    if (rank < minRank) {
      // show alert
      this.props.showAlert([
        'Hoplay Bot 🤖',
        ' معليش رانكك ضعيف , رانكك الحالي ' +
          global.rankLogger.getUserRankName(rank) +
          '  تحتاج ' +
          (minRank - rank) +
          ' HP لاستخدام هذي الخاصية' +
          '، تقدر ترفع رانكك بالتفاعل مع القيمرز الرهيبين في التطبيق واستخدام كل خصائص التطبيق باستمرار ',
        [
          {
            text: 'OK',
            onPress: () => {},
          },
        ],
        false,
      ]);
    } else {
      global.featuresLogger.USER_COSMETICS_PROFILE_BORDER_COLOR_UPDATE_PROFILE();
      global.rankLogger.LOG_NEW_HP(
        global.rankLogger.USER_PROFILE_BORDER_COLOR_UPDATE_COLOR,
        'USER_PROFILE_BORDER_COLOR_UPDATE_COLOR',
      );
      const userColorRef =
        FirebasePaths.FIREBASE_USERS_INFO_ATTR +
        '/' +
        this.props.uid +
        '/' +
        FirebasePaths.FIREBASE_COSMETICS_ATTR +
        '/' +
        FirebasePaths.FIREBASE_PROFILE_BORDER_ATTR;

      firebase.database()
        .ref(userColorRef + '/' + FirebasePaths.FIREBASE_COLOR_ATTR)
        .set(previousColor);
      this.props.setProfileBorderColor({profileBorderColor: previousColor});
      presentToast({
        message: I18n.t('COSMETICS_PAGE.success_msg'),
      });
      NavigationService.goBack();
    }
  };

  render() {
    const {theme} = this.props;
    const _styles = styles(theme);
    return (
      <View style={AppStyles.rootContainer}>
        {/* Header */}
        <Header
          text="User Cosmetics"
          rightComponent={
            <View style={_styles.headerRight}>
              <TouchableOpacity
                style={_styles.actionBtn}
                onPress={this.saveColor}>
                <Label style={_styles.pinkLabel} size="x_small" bold={true}>
                  {I18n.t('CREATE_REQUEST_PAGE.save_short')}
                </Label>
              </TouchableOpacity>
            </View>
          }
        />
        {/* Content */}
        <View style={[AppStyles.rootContent]}>
          <ScrollView>
            <View style={_styles.wrapper}>
              <UserAvatar
                src={this.props.userAvatar}
                size={124}
                borderColor={this.state.previousColor}
                borderWidth={2}
                style={_styles.avatar}
              />
              <Label size="small" style={_styles.descLabel}>
                {I18n.t('COSMETICS_PAGE.page_message')}
              </Label>
              <View>
                <View style={_styles.colorsWrapper}>{this.renderColors()}</View>
              </View>
              <PrimaryButton
                onPress={this.saveColor}
                text={I18n.t('CREATE_REQUEST_PAGE.save')}
                size="medium"
                type="filled"
                customStyles={_styles.saveBtn}
              />
            </View>
          </ScrollView>
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
    uid: state.authReducer.uid,
    userAvatar: state.authReducer.userinformationDB.pictureURL,
    profileBorderColor: state.authReducer.userinformationDB.profileBorderColor,
    rank: state.authReducer.userinformationDB.rank,
  };
};

export default compose(
  connect(mapStateToProps, {setProfileBorderColor, showAlert}),
)(UserCosmeticsPage);
