import React, { Component } from 'react';
import { Image, Text, View, Animated } from 'react-native';
import { withNavigationFocus } from 'react-navigation';
import PropTypes from 'prop-types';

import { PLACEHOLDER } from '../../constants/images';

const AnimatedImage = Animated.createAnimatedComponent(Image);

const MAX_RELOAD_FAST_IMAGE = 100;

class ImageWithPlaceholderWithoutFocus extends Component {
    constructor(props) {
        super(props);
        this.mount = true;
        this.state = {
            showDefault: true,
            error: false,
            picUrl: null,
            ready: false,
            animatedImage: new Animated.Value(0),
            imageKey: 1,
        };
    }

    componentDidMount() {
        const { uri } = this.props;
        if (uri) {
            this.setState({
                picUrl: uri
            })
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.uri !== this.props.uri && this.props.uri) {
            if (this.mount) {
                this.setState({
                    picUrl: this.props.uri
                })
            }
        }
    }

    componentWillUnmount() {
        this.mount = false;
    }

    onImageLoad = (e) => {
        const { useOpacity, onLoad } = this.props;
        if (onLoad != null) {
            onLoad(e);
        }
        // if (useOpacity) {
        //     Animated.timing(this.state.animatedImage, {
        //         toValue: 1,
        //         duration: 500,
        //         useNativeDriver: false,
        //     }).start();
        // }
    };

    render() {
        const placeholder = this.props.placeholder || PLACEHOLDER;
        const { picUrl } = this.state;
        const { priority, useOpacity } = this.props;
        const imagePriority = priority;
        const image =
            picUrl != null &&
                picUrl !== '' &&
                picUrl.toLowerCase() !== 'default' &&
                picUrl.toLowerCase() !== 'null' &&
                picUrl.toLowerCase() !== 'undefined' &&
                (picUrl.startsWith('http') ||
                    picUrl.startsWith('file://') ||
                    picUrl.startsWith('content://') || picUrl.includes('data:image/'))
                ?
                this.state.showDefault
                    ? placeholder :
                    this.state.error
                        ? placeholder
                        : { uri: picUrl }
                : placeholder;
        return <AnimatedImage
            accessibilityRole={'image'}
            onError={() => {
                if (this.state.imageKey < MAX_RELOAD_FAST_IMAGE) {
                    this.setState({ error: true })
                    this.setState({
                        imageKey: this.state.imageKey + 1
                    })
                    setTimeout(() => {
                        if (this.mount) {
                            this.setState({ error: false })
                        }
                    }, 1000);
                }
            }}
            onLoad={this.onImageLoad}
            onLoadEnd={() => this.setState({ error: false, showDefault: false })}
            resizeMode={this.props.resizeMode}
            source={image}
            style={[this.props.style,
                // { opacity: useOpacity ? this.state.animatedImage : 1 }
            ]}
        />
    }
}

ImageWithPlaceholderWithoutFocus.defaultProps = {
    useOpacity: true
}

ImageWithPlaceholderWithoutFocus.propTypes = {
    useOpacity: PropTypes.bool,
}

export default ImageWithPlaceholderWithoutFocus;
