import {StyleSheet} from 'react-native';
import Dimens from '../../constants/dimens';
import {perfectSize} from '../../helpers/deviceHelper';

export default StyleSheet.create({
  tabBarStyle: {
    paddingHorizontal: Dimens.BODY_PADDING_HORIZONTAL,
  },
  rootContent: {
    paddingTop: perfectSize(8),
    paddingHorizontal: 0,
  },
  subContent: {
    flex: 1,
    backgroundColor: '#0E1319',
    paddingHorizontal: Dimens.BODY_PADDING_HORIZONTAL,
  },
  flex: {
    flex: 1,
  },
  fabButton: {
    position: 'absolute',
    right: perfectSize(16),
    bottom: perfectSize(16),
  },
  rowFront: {
    backgroundColor: '#0E1319',
    // flex: 1,
  },
  rowBack: {
    alignItems: 'center',
    backgroundColor: '#0E1319',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingLeft: 15,
  },
  emptyContent: {
    marginTop: perfectSize(32),
  },
  backRightBtn: {
    alignItems: 'center',
    bottom: perfectSize(0),
    justifyContent: 'center',
    position: 'absolute',
    top: perfectSize(0),
    width: 75,
    borderBottomRightRadius: perfectSize(16),
    borderTopRightRadius: perfectSize(16),
  },
  backRightBtnRight: {
    backgroundColor: '#C70464',
    right: 1,
  },
});
