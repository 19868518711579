import React, {memo, Component} from 'react';
import {View, StyleSheet, TouchableOpacity} from 'react-native';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import Label from '../label';
import {perfectSize} from '../../helpers/deviceHelper';

class SimpleTab extends Component {
  handlePress = (position) => {
    this.props.onPress(position);
  };

  render() {
    const {theme, name, position, isActive, showBadge} = this.props;
    const textColor = isActive
      ? theme.tab_active_text_color
      : theme.tab_inactive_text_color;
    const indicatorColor = isActive ? '#C70064' : 'transparent';
    return (
      <View style={[styles(theme).container, this.props.style]}>
        <TouchableOpacity onPress={() => this.handlePress(position)}>
          <Label
            size="x_small"
            color={textColor}
            style={isActive ? {opacity: 1} : {opacity: 0.6}}>
            {name}
          </Label>
        </TouchableOpacity>
        <View
          style={[
            styles(theme).indicator,
            {
              backgroundColor: indicatorColor,
            },
          ]}
        />
        {showBadge && <View style={styles(theme).badge} />}
      </View>
    );
  }
}

const styles = (theme) =>
  StyleSheet.create({
    container: {
      flexDirection: 'column',
      alignItems: 'center',
    },
    indicator: {
      width: '72%',
      height: 2,
      marginTop: perfectSize(8),
    },
    badge: {
      position: 'absolute',
      top: -perfectSize(4),
      right: -perfectSize(10),
      width: perfectSize(8),
      height: perfectSize(8),
      borderRadius: perfectSize(4),
      backgroundColor: theme.colors.primary,
    },
  });

SimpleTab.propTypes = {
  name: PropTypes.string,
  position: PropTypes.number,
  isActive: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
  };
};

export default memo(connect(mapStateToProps)(SimpleTab));
