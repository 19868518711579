import React, {Component} from 'react';
import {View, TouchableOpacity, ScrollView} from 'react-native';
import {compose} from 'redux';
import {connect} from 'react-redux';
import firebase from 'firebase/app';
import 'firebase/database';

import I18n from '../../i18n';
import NavigationService from '../../navigation/navigationService';

// import components
import Header from '../../components/header';
import Label from '../../components/label';
import PrimaryButton from '../../components/buttons/primary-button';
import HopCard from '../../components/cards/hop-card';

import AppStyles from '../../constants/styles';
import styles from './styles';
import {presentToast} from '../../helpers/toastHelper';
import UserCosmeticsCore from './usercosmeticshadowcore';

import {setUserInfo} from '../../redux/modules/auth/actions';
import {showAlert} from '../../redux/modules/alert/actions';

import * as FirebasePaths from '../../constants/firebasePaths';

class UserCosmeticsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      borderColorsArr: [],
      previousColor: props.shadowColor || '#C70064',
      // previousColor: '#C70064',
    };
  }

  componentDidMount() {
    UserCosmeticsCore.loadBorderColors().then((snapshot) => {
      const shot = snapshot.val();
      let borderColorsMap = new Map();
      for (var key in shot) {
        borderColorsMap.set(key, shot[key]);
      }
      this.setState({borderColorsArr: Array.from(borderColorsMap.values())});
    });
    let shadowColor = '#C70064';

    const val = this.props.rank;
    if (val >= 8300) {
      shadowColor = '#19E7DD';
    }
    if (val >= 10800) {
      shadowColor = '#0087FF';
    }
    if (val >= 11700) {
      shadowColor = '#FCA40B';
    }
    if (val >= 12600) {
      shadowColor = '#F8DD00';
    }
    if (val >= 15000) {
      shadowColor = '#CC2893';
    }
    shadowColor = this.props.shadowColor || shadowColor;
    this.setState({previousColor: shadowColor});
    global.featuresLogger.USER_COSMETICS_SHADOW_COLOR_PAGE_VIEW();
  }

  renderColors = () => {
    const {theme} = this.props;
    const {borderColorsArr} = this.state;
    let views = [];
    borderColorsArr.forEach((color, index) => {
      views.push(
        <TouchableOpacity key={index} onPress={() => this.selectColor(color)}>
          <View style={[styles(theme).colorItem, {backgroundColor: color}]} />
        </TouchableOpacity>,
      );
    });
    return views;
  };

  selectColor = (color) => {
    this.setState({previousColor: color});
  };

  saveColor = () => {
    const {previousColor} = this.state;
    const {rank} = this.props;
    if (rank < 4200) {
      // show alert
      this.props.showAlert([
        'Hoplay Bot 🤖',
        ' معليش رانكك ضعيف , رانكك الحالي ' +
          global.rankLogger.getUserRankName(rank) +
          '  تحتاج ' +
          (4200 - rank) +
          ' HP لاستخدام هذي الخاصية' +
          '، تقدر ترفع رانكك بالتفاعل مع القيمرز الرهيبين في التطبيق واستخدام كل خصائص التطبيق باستمرار ',
        [
          {
            text: 'OK',
            onPress: () => {},
          },
        ],
        false,
      ]);
    } else {
      global.featuresLogger.USER_COSMETICS_SHADOW_COLOR_UPDATE_PROFILE();
      global.rankLogger.LOG_NEW_HP(
        global.rankLogger.USER_HOP_BORDER_COLOR_UPDATE_COLOR,
        'USER_HOP_BORDER_COLOR_UPDATE_COLOR',
      );
      const userColorRef =
        FirebasePaths.FIREBASE_USERS_INFO_ATTR +
        '/' +
        this.props.uid +
        '/' +
        FirebasePaths.FIREBASE_COSMETICS_ATTR +
        '/' +
        FirebasePaths.FIREBASE_HOP_SHADOW_ATTR;

      firebase.database()
        .ref(userColorRef + '/' + FirebasePaths.FIREBASE_COLOR_ATTR)
        .set(previousColor);

      this.props.setUserInfo({shadowColor: previousColor});
      presentToast({
        message: I18n.t('COSMETICS_PAGE.hop_success_msg'),
      });
      NavigationService.goBack();
    }
  };

  render() {
    const {theme} = this.props;
    const _styles = styles(theme);
    return (
      <View style={AppStyles.rootContainer}>
        {/* Header */}
        <Header
          text="Hop Cosmetics"
          rightComponent={
            <View style={_styles.headerRight}>
              <TouchableOpacity
                style={_styles.actionBtn}
                onPress={this.saveColor}>
                <Label style={_styles.pinkLabel} size="x_small" bold={true}>
                  {I18n.t('CREATE_REQUEST_PAGE.save_short')}
                </Label>
              </TouchableOpacity>
            </View>
          }
        />
        {/* Content */}
        <View style={[AppStyles.rootContent]}>
          <ScrollView>
            <View style={_styles.wrapper}>
              <View style={_styles.hopWrapper}>
                <HopCard
                  item={{
                    type: 'TEXT_ONLY',
                    IS_HOP_SHADOW_ACTIVATED: true,
                    rank: this.props.rank,
                    likesCount: 12,
                    dislikesCount: 8,
                    repliesNumber: 4,
                    img: this.props.userAvatar,
                    userBorderColor: this.props.profileBorderColor,
                    username: this.props.userName,
                    timeStamp: new Date().getTime() - 60 * 60 * 60 * 0.6,
                    postDescription: '\t   Hey ! I love hoplay',
                    shadowColor: this.state.previousColor,
                  }}
                  index={1}
                  onPress={() => {}}
                  isAdmin={false}
                />
              </View>
              <Label size="small" style={_styles.descLabel}>
                {I18n.t('COSMETICS_PAGE.hop_page_message')}
              </Label>
              <View>
                <View style={_styles.colorsWrapper}>{this.renderColors()}</View>
              </View>
              <PrimaryButton
                onPress={this.saveColor}
                text={I18n.t('CREATE_REQUEST_PAGE.save')}
                size="medium"
                type="filled"
                customStyles={_styles.saveBtn}
              />
            </View>
          </ScrollView>
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
    uid: state.authReducer.uid,
    userAvatar: state.authReducer.userinformationDB.pictureURL,
    userName: state.authReducer.userinformationDB.username,
    profileBorderColor: state.authReducer.userinformationDB.profileBorderColor,
    shadowColor: state.authReducer.userinformationDB.shadowColor,
    rank: state.authReducer.userinformationDB.rank,
  };
};

export default compose(connect(mapStateToProps, {setUserInfo, showAlert}))(
  UserCosmeticsPage,
);
