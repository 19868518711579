import React, { memo, Component } from 'react';
import { View, StyleSheet } from 'react-native';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import firebase from 'firebase/app';
import 'firebase/database';

import AppStyles from '../../../constants/styles';
import { perfectSize } from '../../../helpers/deviceHelper';
import { STAR, STAR_BLANK, STAR_HALF } from '../../../constants/images';

import NormalButton from '../../buttons/normal-button';
import Label from '../../label';
import StarRating from 'react-native-star-rating';
import UserAvatar from '../../user-avatar';
import * as FirebasePaths from '../../../constants/firebasePaths';
import { FindMatchCore } from '../../../pages/find-match/find-match-core';
import ImageWithPlaceholder from '../../image-with-placeholder';

class MatchCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      profile: {},
    };
  }

  componentDidMount() {
    this.getProfile();
    this.getProfileBorderColor();
  }

  getProfile = async () => {
    let profile = await FindMatchCore.fetchReqAdminData(this.props.user.admin);
    if (profile._picUrl_ === 'NULL' || profile._picUrl_ === 'default') {
      profile._picUrl_ =
        'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/teams%20default%20photos%20and%20background%2Fdefault_team_logov2.png?alt=media&token=b88bf926-35b9-4568-a80e-ee24110aceb6';
    }
    profile.avgRate = await FindMatchCore.fetchRate(this.props.user.admin);

    this.setState({
      profile: profile,
    });
  };

  getProfileBorderColor() {
    let cosmeticsPath =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      this.props.user.admin +
      '/' +
      FirebasePaths.FIREBASE_COSMETICS_ATTR +
      '/' +
      FirebasePaths.FIREBASE_PROFILE_BORDER_ATTR;

    firebase.database()
      .ref(cosmeticsPath)
      .once('value')
      .then((snapshot) => {
        let borderColor = '#880e4a';
        snapshot.forEach((item) => {
          if (item.key === FirebasePaths.FIREBASE_COLOR_ATTR) {
            borderColor = item.val();
          }
        });
        this.setState({ borderColor: borderColor });
      });
  }

  render() {
    const { theme, buttonText, onPress, customStyles, user } = this.props;
    const _styles = styles(theme);
    return (
      <View style={[_styles.container, customStyles]}>
        <UserAvatar
          src={this.state.profile._picUrl_}
          size={perfectSize(48)}
          showStatus={true}
          uid={this.props.user.admin}
          borderColor={this.state.borderColor}
        />
        <View style={_styles.nameContainer}>
          <Label size="x_small" style={_styles.username}>
            {user.name}
          </Label>
          {this.state.profile._rank_ >= 0 && (
            <ImageWithPlaceholder
              uri={global.rankLogger.getRankImg(this.state.profile._rank_)}
              style={[
                global.rankLogger.getRankStyle(this.state.profile._rank_),
                _styles.rankIcon,
              ]}
              resizeMode="contain"
            />
          )}
        </View>

        <View style={_styles.ratingBar}>
          <StarRating
            emptyStar={STAR_BLANK}
            fullStar={STAR}
            halfStar={STAR_HALF}
            starSize={20}
            rating={this.state.profile.avgRate}
            disabled={true}
          />
        </View>
        <View style={_styles.sayButton}>
          <NormalButton text={buttonText} onPress={onPress} />
        </View>
      </View>
    );
  }
}

MatchCard.propTypes = {
  customStyles: PropTypes.any,
  userName: PropTypes.string,
  onPress: PropTypes.func,
  rating: PropTypes.number,
  buttonText: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
  };
};

export default memo(connect(mapStateToProps)(MatchCard));

const styles = (theme) =>
  StyleSheet.create({
    container: {
      backgroundColor: theme.secondary_btn_background,
      alignItems: 'center',
      borderRadius: perfectSize(8),
      padding: perfectSize(16),
      justifyContent: 'space-between',
    },
    username: {
      marginTop: perfectSize(8),
    },
    ratingBar: {
      marginTop: perfectSize(8),
    },
    sayButton: {
      marginTop: perfectSize(16),
    },
    nameContainer: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    rankIcon: { marginTop: perfectSize(10) },
  });
