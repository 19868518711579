import {StyleSheet} from 'react-native';

export default StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
    backgroundColor: '#181F29',
  },
  header: {
    zIndex: 9999,
    height: 60,
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
  },
  title: {
    position: 'absolute',
    width: '100%',
    height: 60,
    justifyContent: 'center',
    alignItems: 'center',
  },
  backButton: {
    position: 'absolute',
    width: 48,
    height: 48,
    justifyContent: 'center',
    alignItems: 'center',
    margin: 6,
  },
  image: {
    width: '100%',
    height: '100%',
  },
});
