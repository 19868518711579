import React, { memo, Component } from 'react';
import { View, StyleSheet } from 'react-native';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { perfectSize } from '../../../helpers/deviceHelper';

import Label from '../../label';
import ImageWithPlaceholder from '../../image-with-placeholder';

class AchievementCard extends Component {
  render() {
    const { theme, photo, title } = this.props;
    const _styles = styles(theme);
    return (
      <View style={[_styles.container, this.props.style]}>
        <View style={_styles.imageContainer}>
          <ImageWithPlaceholder uri={photo} style={_styles.image} />
        </View>
        {title && (
          <Label size="x_small" style={_styles.title}>
            {title}
          </Label>
        )}
      </View>
    );
  }
}

AchievementCard.propTypes = {
  photo: PropTypes.string,
  title: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
  };
};

export default memo(connect(mapStateToProps)(AchievementCard));

const styles = (theme) =>
  StyleSheet.create({
    container: {
      backgroundColor: '#3B1935',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: perfectSize(8),
      padding: perfectSize(16),
    },
    imageContainer: {
      borderRadius: perfectSize(8),
      overflow: 'hidden',
    },
    image: {
      width: perfectSize(72),
      height: perfectSize(72),
      resizeMode: 'contain',
    },
    title: {
      marginTop: perfectSize(8),
      textAlign: 'center',
    },
  });
