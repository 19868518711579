import {handleActions} from 'redux-actions';

import {
  showAlertFinished,
  hideAlert,
  showInputAlertFinished,
  hideInputAlert,
  showSpinnerFinished,
  hideSpinner,
  showAppExitFinished,
  hideAppExit,
} from './actions';

const defaultState = {
  alert: false,
  title: '',
  subtitle: '',
  buttons: [],
  isRow: true,
  customStyles: {},
  customComponent: null,

  inputAlert: false,
  inputAlertTitle: '',
  inputAlertInput: null,
  inputAlertActionButtons: [],
  inputAlertIsRow: true,
  buttonCustomStyles: {},

  spinner: false,
  spinnerTitle: '',
};

export default handleActions(
  {
    [showAlertFinished]: (state, {payload}) => ({
      ...state,
      alert: true,
      title: payload.title,
      subtitle: payload.subtitle,
      buttons: payload.buttons,
      isRow: payload.isRow,
      customStyles: payload.customStyles,
      customComponent: payload.customComponent
    }),
    [showInputAlertFinished]: (state, {payload}) => ({
      ...state,
      inputAlert: true,
      inputAlertTitle: payload.inputAlertTitle,
      inputAlertInput: payload.inputAlertInput,
      inputAlertActionButtons: payload.inputAlertActionButtons,
      inputAlertIsRow: payload.inputAlertIsRow,
      buttonCustomStyles: payload.buttonCustomStyles
    }),
    [showSpinnerFinished]: (state, {payload}) => ({
      ...state,
      spinner: true,
      spinnerTitle: payload.spinnerTitle,
    }),
    [hideAlert]: (state, {payload}) => ({
      ...state,
      alert: false,
    }),
    [hideInputAlert]: (state, {payload}) => ({
      ...state,
      inputAlert: false,
    }),
    [hideSpinner]: (state, {payload}) => ({
      ...state,
      spinner: false,
      spinnerTitle: '',
    }),
    [showAppExitFinished]: (state, {payload}) => ({
      ...state,
      alert: true,
      title: payload.title,
      subtitle: payload.subtitle,
      buttons: payload.buttons,
      isRow: payload.isRow,
    }),
    [hideAppExit]: (state, {payload}) => ({
      ...state,
      alert: false,
    }),
  },
  defaultState,
);
