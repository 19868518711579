/* eslint-disable no-unused-vars */
import React, {memo, Component} from 'react';
import {View, StyleSheet, TouchableOpacity} from 'react-native';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Feather from 'react-native-vector-icons/Feather';

import {perfectSize} from '../../../helpers/deviceHelper';
import NormalButton from '../../buttons/normal-button';
import Label from '../../label';
import I18n from '../../../i18n';
import LocalImageOpacity from '../../local-image-opacity';
import ImageWithPlaceholder from '../../image-with-placeholder';
import OverlayImage from '../../overlay-image';
import {LinearGradient} from 'expo-linear-gradient';
import AttendantUser from './attendant-user';

class HootCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    getAdminUser = () => {
        const {item} = this.props;
        const {historyParticipants, admin} = item;
        const historyUsers = historyParticipants ? Object.values(historyParticipants) : [];
        if (historyUsers) {
            return historyParticipants[admin];
        }
        return null;
    }

    renderAdmin = () => {
        const adminUser = this.getAdminUser();
        if (adminUser) {
            return <AttendantUser
                isAdmin={true}
                uid={adminUser.uid}
                userRank={adminUser.rank}
                img={adminUser.img}
                key={adminUser.uid.toString()}
            />
        }
        return <View />;
    }

    render() {
        const {theme, item, genres, lang} = this.props;
        const {genre, liveParticipants, admin, isLive} = item;
        const activeUsers = liveParticipants ? Object.values(liveParticipants) : [];
        const keyGenre = genres?.filter(g => g.key === genre);
        let genreString = genre;
        let genreImage = '';
        if (keyGenre) {
            genreString = keyGenre[0].value[lang];
            genreImage = keyGenre[0].value['icon'];
        }
        const _styles = styles(theme);
        return (
            <View
                style={[
                    {
                        paddingBottom: perfectSize(16),

                    },
                ]}>
                <View
                    style={[
                        _styles.container,
                        {
                            height: perfectSize(196)
                        },
                        {
                            backgroundColor: '#1C242E',
                        }
                    ]}>
                    {
                        !!item.cover &&
                        <View style={_styles.overlayWrap}>
                            <OverlayImage
                                uri={item.cover}
                                style={_styles.fullSize}
                                overlayView={
                                    <LinearGradient
                                        colors={['#00000033', '#1C242E']}
                                        style={_styles.fullSize}
                                    />
                                }
                            />

                        </View>
                    }

                    <TouchableOpacity
                        onLongPress={this.props.onLongPress && this.props.onLongPress}
                        style={[_styles.headerWrap, !!item.cover ? {position: 'absolute', } : {backgroundColor: theme.secondary_btn_background, }]}
                    >
                        <View style={_styles.header}>
                            <View style={_styles.statusWrap}>
                                <View style={[_styles.hootStatus, item.isLive ? _styles.statusLive : _styles.statusClosed]} />
                                <Label style={item.isLive ? _styles.labelLive : _styles.labelClosed} size={'small'} >{item.isLive ? 'Live' : 'Closed'}</Label>
                            </View>
                            <TouchableOpacity style={_styles.moreWrap} onPress={() => {
                                this.props.onPressMore(this.props.item)
                            }}>
                                <Feather name="more-horizontal" color='white' size={perfectSize(18)} />
                            </TouchableOpacity>
                        </View>
                        <View style={[_styles.flexRow, {flex: 1}]}>
                            <View style={_styles.genreImageWrapper}>
                                <ImageWithPlaceholder
                                    uri={genreImage}
                                    style={_styles.genreImage}
                                    resizeMode="contain"
                                />
                            </View>

                            <View style={{flex: 1, marginRight: perfectSize(24), paddingVertical: perfectSize(4)}}>
                                <Label numberOfLines={2} lineHeight={20} style={_styles.title}>{item.title}</Label>
                                <Label size={'small'} style={_styles.genre}>{genreString}</Label>
                            </View>
                        </View>
                    </TouchableOpacity>

                    <View style={[_styles.footer,]}>
                        <View style={_styles.flexRow}>
                            {
                                !isLive && this.renderAdmin()
                            }
                            {
                                activeUsers.slice(0, 3).map(user => (
                                    <AttendantUser
                                        isAdmin={user.uid === admin}
                                        uid={user.uid}
                                        userRank={user.rank}
                                        img={user.img}
                                        key={user.uid.toString()}
                                    />
                                ))
                            }
                            {
                                activeUsers.length > 3 && (
                                    <NormalButton
                                        text={(activeUsers.length - 3).toString()}
                                        onPress={() => {this.props.onPress(this.props.item)}}
                                        customStyles={_styles.moreMember}
                                    />
                                )
                            }

                            {/* <ImageWithPlaceholder
                                uri={global.rankLogger.getRankImg(this.props.item.userRank)}
                                style={global.rankLogger.getRankStyle(
                                    this.props.item.userRank,
                                )}
                                resizeMode="contain"
                                from={this.props.from}
                            /> */}
                        </View>
                        <NormalButton
                            text={item.isLive ? I18n.t('HOOTS_PAGE.hoots_join') : I18n.t('HOOTS_PAGE.hoots_call_again')}
                            onPress={() => {this.props.onPress(this.props.item)}}
                            customStyles={_styles.joinButton}
                            isPrimary={false}
                        />
                    </View>
                </View>
            </View>
        );
    }
}

HootCard.propTypes = {
    data: PropTypes.object,
    isJoined: PropTypes.bool,
    onJoin: PropTypes.func,
    from: PropTypes.string,
};

const mapStateToProps = (state) => {
    return {
        theme: state.appReducer.currentTheme,
        lang: state.appReducer.currentLang,
        genres: state.hubReducer.genres,
    };
};

export default memo(connect(mapStateToProps)(HootCard));

const styles = (theme) =>
    StyleSheet.create({
        container: {
            marginHorizontal: perfectSize(0.5),
            borderRadius: perfectSize(12),
            height: '100%',
            flex: 1,
            overflow: 'hidden'
        },
        detailCard: {
            marginHorizontal: 0,
            overflow: 'hidden',
        },
        footer: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: perfectSize(16),
            flex: 1,

        },
        flexRow: {
            flexDirection: 'row',
            alignItems: 'center',
        },
        joinButton: {
            borderColor: 'white',
            borderWidth: perfectSize(1),
            alignSelf: 'center'
        },
        moreMember: {
            marginTop: perfectSize(9),
            height: perfectSize(18),
            marginLeft: perfectSize(8),
            paddingHorizontal: perfectSize(8)
        },
        overlayWrap: {
            width: '100%',
            height: perfectSize(110),
            overflow: 'hidden',
        },
        gradient: {
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            height: perfectSize(110),
        },
        fullSize: {
            width: '100%',
            height: '100%',
        },
        headerWrap: {
            paddingHorizontal: perfectSize(16),
            paddingTop: perfectSize(16),
            borderTopLeftRadius: perfectSize(12),
            borderTopRightRadius: perfectSize(12),
            width: '100%',
            height: perfectSize(110)
        },
        header: {
            flexDirection: 'row',
            justifyContent: 'space-between'
        },
        statusWrap: {
            flexDirection: 'row',
            alignItems: 'center'
        },
        hootStatus: {
            width: perfectSize(6),
            height: perfectSize(6),
            borderRadius: perfectSize(3),
            marginRight: perfectSize(8)
        },
        statusLive: {
            backgroundColor: '#00FFB4'
        },
        statusClosed: {
            backgroundColor: '#D34D72'
        },
        labelLive: {
            color: '#00FFB4'
        },
        labelClosed: {
            color: '#D34D72'
        },
        genreImageWrapper: {
            overflow: 'hidden',
            marginRight: perfectSize(16),
        },
        genreImage: {
            width: perfectSize(35.6),
            height: perfectSize(34.2),
            resizeMode: 'contain',
        },
        genre: {
            color: theme.colors.primary,
            // marginTop: perfectSize(12)
        },
        title: {
            flexWrap: 'wrap',
        }
    });
