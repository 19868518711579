import {StyleSheet} from 'react-native';
import {perfectSize} from '../../helpers/deviceHelper';
import Dimens from '../../constants/dimens';

const styles = (theme) =>
  StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: theme.primary_background,
      paddingHorizontal: Dimens.HEADER_PADDING_HORIZONTAL,
      paddingVertical: perfectSize(12),
    },
    ads: {
      flex: 1,
    },
    slide: {
      borderRadius: perfectSize(12),
      overflow: 'hidden',
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
    },
    logo: {
      width: '50%',
      marginTop: '10%',
      alignSelf: 'center',
    },
    firstLabel: {
      color: theme.primary_btn_background,
      textAlign: 'center',
    },
    description: {
      marginTop: perfectSize(24),
    },
    secondLabel: {
      color: theme.tab_inactive_text_color,
      textAlign: 'center',
    },
    btnLogin: {
      width: '100%',
      height: perfectSize(52),
      textAlign: 'center',
      marginTop: perfectSize(30),
    },
  });

export default styles;
