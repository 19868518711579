import {StyleSheet} from 'react-native';
import {perfectSize} from '../../helpers/deviceHelper';
import {FONT_FAMILY} from '../../constants/theme';

const styles = (theme) =>
  StyleSheet.create({
    mt16: {
      marginTop: perfectSize(16),
    },
    itemContainer: {
      // height: perfectSize(90),
      flexDirection: 'row',
      alignItems: 'center',
      borderBottomWidth: 1,
      borderBottomColor: '#FFFFFF0C',
      justifyContent: 'center',
      flex: 1,
      paddingVertical: perfectSize(4),
    },
    itemRightContainer: {
      marginLeft: perfectSize(16),
      flex: 1,
    },
    avatar: {
      width: perfectSize(72),
      height: perfectSize(72),
      borderRadius: perfectSize(12),
    },
    bell: {
      width: perfectSize(16),
      height: perfectSize(16),
      resizeMode: 'contain',
    },
    availablePlatform: {
      width: perfectSize(16),
      height: perfectSize(16),
      resizeMode: 'contain',
      marginTop: perfectSize(8),
      marginRight: perfectSize(4),
    },
    mv16: {
      marginVertical: perfectSize(16),
    },
    btnAdd: {
      color: theme.primary_btn_background,
      alignSelf: 'flex-start',
    },
    noGameWrap: {
      borderRadius: perfectSize(36),
      borderWidth: 1,
      borderStyle: 'dotted',
      borderColor: 'white',
      padding: perfectSize(36),
      // paddingBottom: perfectSize(24),
      // paddingHorizontal: perfectSize(16),
      justifyContent: 'center',
      alignItems: 'center',
    },
    supportLoading: {
      width: perfectSize(80),
      height: perfectSize(80),
    },
    txtNoGame: {
      color: '#949494',
      textAlign: 'center',
      marginVertical: perfectSize(16),
    },
    btnAction: {
      paddingHorizontal: perfectSize(16),
    },
    backRightBtn: {
      alignItems: 'center',
      bottom: 0,
      justifyContent: 'center',
      position: 'absolute',
      top: 0,
      width: perfectSize(75),
    },
    backRightBtnRight: {
      backgroundColor: '#880e4f',
      right: 0,
    },
    backTextWhite: {
      color: 'white',
      fontSize: perfectSize(14),
    },
    doneBtn: {
      borderRadius: 0,
      marginTop: perfectSize(8),
    },
    fontUbuntu: {
      fontFamily: FONT_FAMILY.ubuntu,
    },
  });

export default styles;
