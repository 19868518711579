export default class PlayerModel {
  constructor() {
    this.username = '';
    this.uid = '';
    this.gameProvider = '';
    this.gameProviderAcc = '';
    this.photoUrl = '';

    this.pcGameProvider = '';
    this.XBOX_live_account = '';
    this.PSN_account = '';
    this.status = 'offline';
    this.borderColor = '#880e4a';

    // Used to fix a bug
    this.platform = '';

    // All players map
    this.players = new Map();
  }
}
