import authSaga from './modules/auth/saga';
import firebaseSaga from './modules/firebase/saga';
import mainMenuCoreSaga from './modules/mainMenuCore/saga';
import gameManagerSaga from './modules/gameManager/saga';
import alertSaga from './modules/alert/saga';

export default [
  authSaga,
  firebaseSaga,
  mainMenuCoreSaga,
  gameManagerSaga,
  alertSaga,
];
