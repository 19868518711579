/* eslint-disable react-native/no-inline-styles */
import React, {Component} from 'react';
import {View, StyleSheet} from 'react-native';
import {compose} from 'redux';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {perfectSize} from '../../helpers/deviceHelper';

import NormalButton from '../buttons/normal-button';
import I18n from '../../i18n';
class CustomSwitch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.initialValue == null ? true : props.initialValue,
    };
  }

  toggleValue = (value) => {
    if (this.state.value !== value) {
      const {onValueChanged} = this.props;
      onValueChanged && onValueChanged(value);
      this.setState({value});
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.value !== nextProps.value) {
      this.setState({value: nextProps.value});
    }
  }

  render() {
    const {theme} = this.props;
    const _styles = styles(theme);
    const {value} = this.state;
    const {value: propValue} = this.props;
    return (
      <View style={[_styles.container, this.props.style]}>
        <NormalButton
          isPrimary={propValue !== undefined ? !propValue : !value}
          text={this.props.offText || I18n.t('SETTINGS_PAGE.off')}
          size="x_small"
          customStyles={[
            _styles.buttonStyle,
            value ? {zIndex: 1} : {zIndex: 3},
          ]}
          textCustomStyles={[
            (propValue !== undefined ? !propValue : !value) &&
              _styles.textLight,
            _styles.textStyle,
          ]}
          onPress={() => this.toggleValue(false)}
        />
        <NormalButton
          isPrimary={propValue !== undefined ? propValue : value}
          text={this.props.onText || I18n.t('SETTINGS_PAGE.on')}
          size="x_small"
          customStyles={[_styles.buttonStyle, _styles.onButton]}
          textCustomStyles={[
            (propValue !== undefined ? propValue : value) && _styles.textLight,
            _styles.textStyle,
          ]}
          onPress={() => this.toggleValue(true)}
        />
      </View>
    );
  }
}

const styles = (theme) =>
  StyleSheet.create({
    container: {
      flexDirection: 'row',
    },
    buttonStyle: {
      height: 36,
      minWidth: 72,
    },
    textLight: {
      opacity: 0.56,
    },
    onButton: {
      marginLeft: -perfectSize(12),
      zIndex: 2,
    },
    textStyle: {
      textAlign: 'center',
      lineHeight: perfectSize(19),
    },
  });

CustomSwitch.propTypes = {
  initialValue: PropTypes.bool,
  onText: PropTypes.string,
  offText: PropTypes.string,
  onValueChanged: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
    lang: state.appReducer.currentLang,
  };
};

export default compose(connect(mapStateToProps, {}))(CustomSwitch);
