import React, { Component } from 'react';
import { Image, Text, View, Animated } from 'react-native';
import { withNavigationFocus } from 'react-navigation';

import { PLACEHOLDER, defaultProfileImage } from '../../constants/images';

const AnimatedImage = Animated.createAnimatedComponent(Image);
const MAX_RELOAD_FAST_IMAGE = 100;

class OriginImageWithPlaceholder extends Component {
    constructor(props) {
        super(props);
        this.mount = true;
        this.state = {
            showDefault: true,
            error: false,
            picUrl: null,
            ready: false,
            animatedImage: new Animated.Value(0),
            imageKey: 1,
        };
    }

    async componentDidMount() {
        const { uri } = this.props;
        if (uri) {
            await this.getOriginUri(uri);
        }
    }

    async getOriginUri(uri) {
        this.setState({
            picUrl: uri,
            ready: true
        })
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.uri !== this.props.uri && this.props.uri) {
            if (this.mount) {
                await this.getOriginUri(this.props.uri);
            }
        }
    }

    componentWillUnmount() {
        this.mount = false;
    }

    onImageLoad = (e) => {
    };

    render() {
        const placeholder = this.props.placeholder || PLACEHOLDER;
        const { picUrl } = this.state;
        const { isFocused, priority } = this.props;
        const image =
            picUrl != null &&
                picUrl !== '' &&
                picUrl.toLowerCase() !== 'default' &&
                picUrl.toLowerCase() !== 'null' &&
                picUrl.toLowerCase() !== 'undefined' &&
                (picUrl.startsWith('http') ||
                    picUrl.startsWith('file://') ||
                    picUrl.startsWith('content://') || picUrl.includes('data:image/'))
                ? this.state.showDefault
                    ? placeholder :
                    this.state.error
                        ? placeholder
                        : { uri: picUrl }
                : placeholder;
        if (isFocused) {
            return <Image
                onLoad={this.onImageLoad}
                onLoadEnd={(e) => {
                    this.setState({ error: false, showDefault: false })

                }}
                onLayout={({ nativeEvent }) => {
                }}
                resizeMode={'contain'}
                source={image}
                style={[{
                    width: '100%',
                    height: '100%',
                },
                ]}
            />
        }
        return <View />;
    }
}

export default withNavigationFocus(OriginImageWithPlaceholder);
