/* eslint-disable react-native/no-inline-styles */
import React, { memo, Component } from 'react';
import { View, FlatList } from 'react-native';
import { connect } from 'react-redux';
import I18n from '../../i18n';


import Label from '../../components/label';
import OverlayImage from '../../components/overlay-image';

import AppStyles from '../../constants/styles';
import styles from './styles';
import { perfectSize } from '../../helpers/deviceHelper';
import Teamcore from './teamscore';

import {
  handleAndroidBackButton,
  removeAndroidBackButtonHandler,
} from '../../helpers/deviceHelper';
import navigationService from '../../navigation/navigationService';
import { LinearGradient } from 'expo-linear-gradient';
class Championships extends Component {
  constructor(props) {
    super(props);
    const params = props.route.params;
    this.state = {
      teamId: params.teamId,
      championshipsArr: [],
    };
  }

  componentWillMount() {
    handleAndroidBackButton(() => {
      navigationService.goBack();
    });
  }

  componentDidMount() {
    // Load team championships
    Teamcore.loadTeamChampionships(this.state.teamId).then((shot) => {
      let championshipsArr = [];
      shot.forEach((item) => {
        championshipsArr.push(item.val());
      });
      this.setState({ championshipsArr });
    });
  }

  componentWillUnmount() {
    removeAndroidBackButtonHandler();
  }

  render() {
    const { theme } = this.props;
    const _styles = styles(theme);
    const { championshipsArr } = this.state;
    return (
      <View style={_styles.subContent}>
        <Label
          style={{
            alignSelf: 'center',
            marginBottom: perfectSize(8),
          }}
          size="x_small"
          bold={true}>
          {I18n.t('TEAM_PAGE.championships_enrolled')}
        </Label>
        {championshipsArr.length > 0 ? (
          <FlatList
            data={championshipsArr}
            renderItem={({ item, index }) => {
              return (
                <View style={_styles.championCard}>
                  <OverlayImage
                    uri={item.photo}
                    style={_styles.championCardContent}
                    overlayView={
                      <LinearGradient
                        colors={['rgba(0, 0, 0, 0)', '#181F29']}
                        style={_styles.championCardContent}
                      />
                    }
                  />
                  <Label style={_styles.championTitle}>{item.title}</Label>
                </View>
              );
            }}
          />
        ) : (
          <View style={{ marginTop: perfectSize(32) }}>
            <View style={AppStyles.emptyContent}>
              <Label style={[AppStyles.emptyContentLabel, { marginTop: 0 }]}>
                {I18n.t('TEAM_PAGE.no_championships_msg')}
              </Label>
            </View>
          </View>
        )}
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
  };
};

export default memo(connect(mapStateToProps)(Championships));
