import React, { Component } from 'react';
import { Image, Text, View, Animated } from 'react-native';
import { withNavigationFocus } from 'react-navigation';
import PropTypes from 'prop-types';

import { PLACEHOLDER, defaultProfileImage } from '../../constants/images';
const AnimatedImage = Animated.createAnimatedComponent(Image);


const MAX_RELOAD_FAST_IMAGE = 100;

class ImageWithPlaceholder extends Component {
  constructor(props) {
    super(props);
    this.mount = true;
    this.state = {
      showDefault: true,
      error: false,
      picUrl: null,
      ready: false,
      imageKey: 1,
      animatedImage: new Animated.Value(0)
    };
  }

  componentDidMount() {
    const { uri } = this.props;
    if (uri) {
      this.setState({
        picUrl: uri
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.uri !== this.props.uri && this.props.uri) {
      if (this.mount) {
        this.setState({
          picUrl: this.props.uri
        })
      }
    }
  }

  componentWillUnmount() {
    this.mount = false;
  }

  onImageLoad = (e) => {
    const { useOpacity, onLoad, toOpacity } = this.props;
    if (onLoad != null) {
      onLoad(e);
    }
    // if (useOpacity) {
    //   Animated.timing(this.state.animatedImage, {
    //     toValue: toOpacity,
    //     duration: 500,
    //     useNativeDriver: false
    //   }).start();
    // }
  };

  render() {
    const placeholder = this.props.placeholder || PLACEHOLDER;
    const { picUrl } = this.state;
    const { isFocused, priority, useOpacity } = this.props;
    const image =
      picUrl != null &&
        picUrl !== '' &&
        picUrl.toLowerCase() !== 'default' &&
        picUrl.toLowerCase() !== 'null' &&
        picUrl.toLowerCase() !== 'undefined' &&
        (picUrl.startsWith('http') ||
          picUrl.startsWith('file://') ||
          picUrl.startsWith('content://') || picUrl.includes('data:image/'))
        ?
        this.state.showDefault
          ? placeholder :
          this.state.error
            ? placeholder
            : { uri: picUrl }
        : placeholder;
    // if (isFocused) {
    return <Image
      accessibilityRole={'image'}
      // onError={() => {
      //   if (this.state.imageKey < MAX_RELOAD_FAST_IMAGE) {
      //     if (this.mount) {
      //       this.setState({ error: true })
      //       this.setState({
      //         imageKey: this.state.imageKey + 1
      //       })
      //     }
      //     setTimeout(() => {
      //       if (this.mount) {
      //         this.setState({ error: false })
      //       }
      //     }, 3000);
      //   }
      // }}
      onLoad={this.onImageLoad}
      onLoadEnd={() => this.setState({ error: false, showDefault: false })}
      resizeMode={this.props.resizeMode}
      source={image}
      style={[this.props.style,
        // useOpacity &&
        // { opacity: this.state.animatedImage }
      ]}
    />
    // }
    // return <View />;
  }
}

ImageWithPlaceholder.defaultProps = {
  useOpacity: true,
  toOpacity: 1,
}

ImageWithPlaceholder.propTypes = {
  useOpacity: PropTypes.bool,
  toOpacity: PropTypes.number
}

export default withNavigationFocus(ImageWithPlaceholder);
