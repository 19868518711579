import React, { Component } from 'react';
import {
  View,
  FlatList,
  TouchableOpacity,
  Linking,
  ScrollView,
} from 'react-native';
import { compose } from 'redux';
import { connect } from 'react-redux';
import firebase from 'firebase/app';
import 'firebase/database';

import {
  Placeholder,
  Progressive
} from "rn-placeholder";

// import components
import I18n from '../../i18n';
import Label from '../../components/label';
import ImageWithPlaceholder from '../../components/image-with-placeholder';
import OverlayImage from '../../components/overlay-image';
import NewsCard from '../../components/cards/news-card';
import Slider from './slider';

// import styles
import styles from './styles';
import { perfectSize } from '../../helpers/deviceHelper';
import Pipes from '../../services/pipes';

import * as FirebasePaths from '../../constants/firebasePaths';
import AdSpotService from '../../services/adSpot.service';
import { useIsFocused } from '@react-navigation/native';
import SkeletonTextContent from '../../components/skeletonWrapper/skeletonTextContent';
import { dataForSkeleton } from '../../services/utils';
import { LinearGradient } from 'expo-linear-gradient';

const pageSize = 10;

class NewsPage extends Component {
  constructor(props) {
    super(props);
    this.isFocused = true;
    this.state = {
      newsArr: [],
      loadedNewsArr: [],
      isLoading: true,
      AD: null,
    };
  }

  componentDidMount() {
    this.focusListener$ = this.props.navigation.addListener(
      'focus',
      (payload) => {
        this.isFocused = true;
        // Log Feature
        // Log rank
        global.rankLogger.LOG_NEW_HP(
          global.rankLogger.NEWS_PAGE_VIEW,
          'NEWS_PAGE_VIEW',
        );

        // Log the AD view
        global.featuresLogger.NEWS_AD_PAGE_VIEW();
        // LOG FEATURE PAGE VIEW
        global.featuresLogger.LOG_NEWS_PAGE_VIEW();
      },
    );

    // Load news
    this.loadNews();

    // Load the NEWS AD
    this.ADRef$ = firebase.database().ref(
      FirebasePaths.FIREBASE_AD_SPOTS +
      '/' +
      FirebasePaths.FIREBASE_AD_SPOTS_NEWS,
    );
    this.ADRef$.on('value', (snapshot) => {
      if (snapshot.val() != null) {
        this.setState({
          AD: snapshot.val(),
        });
      } else {
        this.setState({
          AD: 'NONE',
        });
      }
    });

    this.blurListener$ = this.props.navigation.addListener('blur', () => {
      this.isFocused = false;
      if (this.newsRef$) {
        this.newsRef$.off();
        this.newsRef$ = null;
      }
      if (this.ADRef$) {
        this.ADRef$.off();
        this.ADRef$ = null;
      }
    })
  }

  componentWillUnmount() {
    if (this.focusListener$) {
      this.focusListener$();
    }
    if (this.newsRef$) {
      this.newsRef$.off();
      this.newsRef$ = null;
    }
    if (this.ADRef$) {
      this.ADRef$.off();
      this.ADRef$ = null;
    }
    if (this.blurListener$) {
      this.blurListener$();
    }
  }

  renderNewsCard = ({ item, index }) => {
    const { isLoading } = this.state;
    const { theme } = this.props;
    if (isLoading) {
      return <View style={{ marginBottom: perfectSize(16), width: '25%', paddingHorizontal: 10 }}>
        <Placeholder
          Animation={Progressive}
        >
          <SkeletonTextContent height={100} />
          <SkeletonTextContent height={20} style={{ marginTop: 10 }} />
          <SkeletonTextContent height={20} style={{ marginTop: 10 }} />
          <SkeletonTextContent height={20} width={80} style={{ marginTop: 10 }} />
        </Placeholder>
      </View>
    }
    return (
      <TouchableOpacity
        onPress={() => this.toNewsPost(item)}
        style={{ marginBottom: perfectSize(16), width: '25%', paddingHorizontal: 10 }}
      >
        <NewsCard
          image={item.photo}
          sourceLogo={item.source}
          providerName={item.providerName}
          onShare={() => this.shareNews(item)}
          title={item.title}
          time={Pipes.humantime(item.timeStamp)}
        />
      </TouchableOpacity>
    );
  };

  openNewsAD = () => {
    // First log the click for this ad
    global.featuresLogger.NEWS_AD_CLICK();

    const { AD } = this.state;
    if (AD != null && AD.url != null) {
      AdSpotService.checkAdUrl(AD);
    }
  };

  // This function will load the news posts
  loadNews() {
    let path = FirebasePaths.FIREBASE_NEWS_REF + '/';
    this.newsRef$ = firebase.database().ref(path).limitToFirst(50);
    this.newsRef$.on('value', (snapshot) => {
      let newsArr = [];
      snapshot.forEach((item) => {
        newsArr.push(item.val());
      });
      if (this.isFocused) {
        this.setState({
          newsArr,
          loadedNewsArr: newsArr.slice(0, pageSize),
          // isLoading: false,
        });
        const preloadUris = [];
        newsArr.slice(0, 3).map(news => {
          if (news.photo) {
            return preloadUris.push({ uri: news.photo });
          }
        })
        setTimeout(() => {
          this.setState({
            isLoading: false,
          })
        }, 500);

      }
    });
  }

  // This function will redirect the user to the post page [WEB]
  toNewsPost = (item) => {
    // Log feature : news click
    global.featuresLogger.LOG_NEWS_CLICKS();

    // Log rank
    global.rankLogger.LOG_NEW_HP(global.rankLogger.NEWS_CLICK, 'NEWS_CLICK');

    if (item != null) {
      if (item.url != null) {
        Linking.openURL(item.url);
      }
    }
  };

  // This function will share the news
  shareNews = (item) => {
    // Log news shares
    global.featuresLogger.LOG_NEWS_SHARE();

    let message =
      item.title +
      '\n          \n' +
      'اخر الاخبار اللي تهم كل قيمر في تطبيق هوبلاي !';
    let subject = 'Hoplay';
    // let img = item.photo;
    let url = 'https://hoplay.online/news';
    // Share.open({
    //   message,
    //   subject,
    //   url,
    // });
  };

  doInfinite = () => {
    const { loadedNewsArr, newsArr } = this.state;
    if (loadedNewsArr.length === newsArr.length) {
      return;
    }
    setTimeout(() => {
      let arr = loadedNewsArr.concat(
        newsArr.slice(loadedNewsArr.length, loadedNewsArr.length + pageSize),
      );
      this.setState({ loadedNewsArr: arr });
    }, 500);
  };

  render() {
    const { theme } = this.props;
    const _styles = styles(theme);
    const { loadedNewsArr, AD, isLoading } = this.state;
    return (
      <FlatList
        data={isLoading ? dataForSkeleton(30) : loadedNewsArr}
        keyExtractor={(item, index) => String(index)}
        showsVerticalScrollIndicator={false}
        renderItem={(item) => this.renderNewsCard(item)}
        numColumns={4}
        columnWrapperStyle={{ justifyContent: 'space-between', flexWrap: 'wrap' }}
        style={{ width: '100%' }}
        ListHeaderComponent={() => {
          return <>
            {/* News */}
            <Label bold style={{ marginVertical: perfectSize(16) }}>
              {I18n.t('NEWS_PAGE.title')}
            </Label>
            {AD != null && AD !== 'NONE' && (
              <TouchableOpacity
                onPress={() => this.openNewsAD()}
                style={{ marginBottom: perfectSize(16) }}>
                <View style={_styles.container}>
                  {AD.img != null && (
                    <OverlayImage
                      style={_styles.cover}
                      uri={AD.img}
                      overlayView={
                        <LinearGradient
                          colors={['rgba(0, 0, 0, 0)', 'rgba(199, 0, 100, 0.4)']}
                          style={_styles.overlay}
                        />
                      }
                    />
                  )}
                  <View style={_styles.infoWrap}>
                    {AD.source != null && (
                      <ImageWithPlaceholder
                        style={_styles.sourceLogo}
                        uri={AD.source}
                      />
                    )}
                  </View>
                </View>
              </TouchableOpacity>
            )}
          </>
        }}
        onEndReached={this.doInfinite}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
    lang: state.appReducer.currentLang,
  };
};

function focusComponent(props) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const isFocused = useIsFocused();
  return <NewsPage {...props} isFocused={isFocused} />;
}

export default compose(connect(mapStateToProps, {}))(focusComponent);
