/* eslint-disable react-native/no-inline-styles */
import React, {Component} from 'react';
import {View} from 'react-native';
import {compose} from 'redux';
import {connect} from 'react-redux';
import Feather from 'react-native-vector-icons/Feather';
import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';

import * as FirebasePaths from '../../constants/firebasePaths';

// import components
import TextInput from '../../components/text-input';
import LoginButton from '../../components/buttons/normal-button';
import I18n from '../../i18n';
import Header from '../../components/header';

import {CHANGE_PWD_LOGO} from '../../constants/images';
import styles from './styles';
import {presentToast} from '../../helpers/toastHelper';
import {showSpinner, hideSpinner} from '../../redux/modules/alert/actions';
import LocalImageOpacity from '../../components/local-image-opacity';

class ChangepasswordPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentLanguage: 'en',
      curpwd: '',
      newpwd: '',
      confirmpwd: '',
      authenticated: false,
    };
  }

  componentDidMount = () => {};

  _onValueChange = (type, val) => {
    this.setState({[type]: val});
  };

  _onBlur = () => {
    const {curpwd} = this.state;
    const {authUser} = this.props;
    firebase.auth()
      .signInWithEmailAndPassword(authUser.email.trim(), curpwd.trim())
      .then((authenticated) => {
        this.setState({authenticated: true});
      })
      // eslint-disable-next-line handle-callback-err
      .catch((err) => {
        this.setState({authenticated: false});
        presentToast({
          message: I18n.t('CHANGE_PASSWORD_PAGE.wrong_credentials'),
        });
      });
  };

  _validateInputs = async () => {
    const {curpwd, newpwd, confirmpwd} = this.state;
    if (curpwd.toString().length === 0) {
      presentToast({
        message: I18n.t('CHANGE_PASSWORD_PAGE.error_current_not_specified'),
      });
      return false;
    }

    if (curpwd.toString().length > 1) {
      const {authUser} = this.props;
      try {
        await firebase.auth().signInWithEmailAndPassword(
          authUser.email.trim(),
          curpwd.trim(),
        );
        this.setState({authenticated: true});
      } catch (err) {
        this.setState({authenticated: false});
        presentToast({
          message: I18n.t('CHANGE_PASSWORD_PAGE.wrong_credentials'),
        });
        return false;
      }
    }
    if (newpwd.toString().length === 0) {
      presentToast({
        message: I18n.t('CHANGE_PASSWORD_PAGE.erorr_new_not_sepcified'),
      });
      return false;
    }
    if (confirmpwd.toString().length === 0) {
      presentToast({
        message: I18n.t('CHANGE_PASSWORD_PAGE.error_confirm_not_specified'),
      });
      return false;
    }
    if (confirmpwd.toString() !== newpwd.toString()) {
      presentToast({
        message: I18n.t('CHANGE_PASSWORD_PAGE.error_no_match'),
      });
      return false;
    }
    return true;
  };

  _updatePassword = async () => {
    this.props.showSpinner({});
    const isValid = await this._validateInputs();
    if (isValid && this.state.authenticated) {
      firebase.auth()
        .currentUser.updatePassword(this.state.newpwd.toString())
        .then((data) => {
          const accessPath =
            FirebasePaths.FIREBASE_USERS_INFO_ATTR +
            '/' +
            this.props.uid +
            '/' +
            FirebasePaths.FIREBASE_USER_ACCESS_ATTR;
          const passwordPath = accessPath + '/' + 'key';
          firebase.database()
            .ref(passwordPath)
            .once('value', (snapshot) => {
              const originPwd = snapshot.val();
              if (originPwd) {
                if (originPwd !== this.state.newpwd.toString()) {
                  firebase.database()
                    .ref(accessPath)
                    .update({key: this.state.newpwd.toString()});
                }
              } else {
                firebase.database().ref(passwordPath).set(this.state.newpwd.toString());
              }
              presentToast({
                message: I18n.t('CHANGE_PASSWORD_PAGE.success_update'),
              });
              this.props.hideSpinner();
              this.setState({
                curpwd: '',
                newpwd: '',
                confirmpwd: '',
              });
            });
        })
        .catch((err) => {
          this.props.hideSpinner();
          this.setState({
            curpwd: '',
            newpwd: '',
            confirmpwd: '',
          });
          presentToast({
            message: err.toString(),
          });
        });
    }
    if (isValid && !this.state.authenticated) {
      this.props.hideSpinner();
      presentToast({
        message: I18n.t('CHANGE_PASSWORD_PAGE.wrong_credentials'),
      });
    }
    this.props.hideSpinner();
  };

  render() {
    const {theme} = this.props;
    const {curpwd, newpwd, confirmpwd} = this.state;
    return (
      <View style={[styles(theme).container]}>
        <Header
          onBack={this.toLogin}
          text={I18n.t('SETTINGS_PAGE.change_password')}
          style={[styles(theme).header]}
        />
        <View style={styles(theme).wrapper}>
          <View style={styles(theme).logo_wrapper}>
            <LocalImageOpacity
              source={CHANGE_PWD_LOGO}
              style={styles(theme).iconLogo}
              resizeMode="contain"
            />
          </View>

          <View style={{flexDirection: 'column', flex: 1, marginBottom: 40}}>
            <TextInput
              placeholder={I18n.t('CHANGE_PASSWORD_PAGE.current')}
              onChangeText={(val) => this._onValueChange('curpwd', val)}
              value={curpwd}
              secureTextEntry={true}
              leftComponent={
                <Feather name="lock" size={20} color={theme.primary_text} />
              }
              customStyles={styles(theme).marginBottom}
              onBlur={this._onBlur}
            />
            <TextInput
              placeholder={I18n.t('CHANGE_PASSWORD_PAGE.new_password')}
              onChangeText={(val) => this._onValueChange('newpwd', val)}
              value={newpwd}
              secureTextEntry={true}
              leftComponent={
                <Feather name="lock" size={20} color={theme.primary_text} />
              }
              customStyles={styles(theme).marginBottom}
            />
            <TextInput
              placeholder={I18n.t('CHANGE_PASSWORD_PAGE.confirm_new_password')}
              onChangeText={(val) => this._onValueChange('confirmpwd', val)}
              value={confirmpwd}
              secureTextEntry={true}
              leftComponent={
                <Feather name="lock" size={20} color={theme.primary_text} />
              }
            />
            <View style={{flex: 1}} />
            <LoginButton
              text={I18n.t('EDIT_PROFILE_PAGE.update')}
              type="outlined"
              customStyles={styles(theme).btnLogin}
              textCustomStyles={{fontSize: 15}}
              onPress={this._updatePassword}
            />
          </View>
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
    authUser: state.authReducer.authUser,
    uid: state.authReducer.uid,
  };
};

export default compose(connect(mapStateToProps, {showSpinner, hideSpinner}))(
  ChangepasswordPage,
);
