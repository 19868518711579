/* eslint-disable react-native/no-inline-styles */
import React, { Component } from 'react';
import { View, TouchableOpacity, ScrollView } from 'react-native';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Entypo from 'react-native-vector-icons/Entypo';
import Feather from 'react-native-vector-icons/Feather';
import I18n from '../../i18n';
import NavigationService from '../../navigation/navigationService';
import GameManager from '../../services/gameManager';
import Gamelistcore from '../games-list/gamelistcore';
import { set as HubSet } from '../../redux/modules/hub/actions';
import { store } from '../../redux/store';
import { FindMatchCore } from './find-match-core';

// import components
import Label from '../../components/label';
import Header from '../../components/header';
import Picker from '../../components/picker';
import FindMatch from '../../components/buttons/normal-button';
import Spinner from '../../components/spinner';

// import styles
import AppStyles from '../../constants/styles';
import styles from './styles';
import { perfectSize } from '../../helpers/deviceHelper';

// import images
import * as Images from '../../constants/images';
import { presentToast } from '../../helpers/toastHelper';
import LocalImageOpacity from '../../components/local-image-opacity';
import ImageWithPlaceholder from '../../components/image-with-placeholder';

class FindMatchPage extends Component {
  platforms = ['PS', 'MOBILE', 'NINTENDO', 'PC', 'XBOX'];

  constructor(props) {
    super(props);
    let regions = props.regionsList;
    let game;
    let platform = '';
    if (props.recentListArr.length > 0) {
      props.recentListArr.forEach((activity) => {
        game = props.gamesList[activity.gameId];
        platform = activity.supportedPlatforms;
      });
    }
    this.state = {
      game,
      platform,
      regionList: regions.map((region) => {
        return {
          value: region,
          title: region,
        };
      }),
      selectedRegion: '',
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.navigation.state.params) {
      if (
        prevState.game !== this.props.navigation.state.params.game &&
        this.props.navigation.state.params.game != null
      ) {
        this.setState(
          {
            game: this.props.navigation.state.params.game,
          },
          () => {
            this.onGameSelected();
          },
        );
      }
    }
  }

  onGameSelected = () => {
    Gamelistcore.addGame(this.state.game);
    if (!GameManager.checkIfHasGameById(this.state.game.gameID)) {
      Gamelistcore.addGame(this.state.game);
    }
  };

  toMatchPage = () => {
    if (this.isValidInputs()) {
      this.setState({
        loading: true,
      });

      FindMatchCore.findMatch(
        this.state.platform,
        this.state.game.gameID,
        this.state.selectedRegion,
        (requests) => {
          this.setState({
            loading: false,
          });
          if (requests.length === 0) {
            presentToast({
              message: I18n.t('FIND_MATCH_PAGE.not_found'),
            });
          } else {
            NavigationService.navigate('HoplayFoundMatchPage', {
              users: requests,
              title: this.state.game.gameName,
            });
          }
        },
      );
    }
  };

  toSelectGame = () => {
    NavigationService.moveToScreenInStack('HomeStack', 'SelectGamePage', {
      fromFindMatch: true,
    });
  };

  renderTags = () => {
    const btn_tags = [
      Images.BTN_TAG_PLAYSTATION,
      Images.BTN_TAG_MOBILE,
      Images.BTN_TAG_DOUBLE,
      Images.BTN_TAG_DESKTOP,
      Images.BTN_TAG_BALL,
    ];
    const btn_tags_selected = [
      Images.BTN_TAG_PLAYSTATION_SELECTED,
      Images.BTN_TAG_MOBILE_SELECTED,
      Images.BTN_TAG_DOUBLE_SELECTED,
      Images.BTN_TAG_DESKTOP_SELECTED,
      Images.BTN_TAG_BALL_SELECTED,
    ];
    let views = [];
    btn_tags.forEach((tag, index) => {
      views.push(
        <TouchableOpacity
          key={index}
          onPress={() => this.platformTrigger(this.platforms[index])}>
          {this.state.platform === this.platforms[index] && (
            <LocalImageOpacity
              source={btn_tags_selected[index]}
              style={styles.btn_tag}
              resizeMode="contain"
            />
          )}
          {this.state.platform !== this.platforms[index] && (
            <LocalImageOpacity
              source={tag}
              style={styles.btn_tag}
              resizeMode="contain"
            />
          )}
        </TouchableOpacity>,
      );
    });
    return views;
  };

  platformTrigger = (platform) => {
    this.setState({
      platform: platform,
    });
  };

  isValidInputs = () => {
    if (this.state.platform === '') {
      presentToast({
        message: I18n.t('FIND_MATCH_PAGE.platform_validation_msg'),
      });

      return false;
    }

    if (this.state.game == null) {
      presentToast({
        message: I18n.t('FIND_MATCH_PAGE.game_validation_msg'),
      });

      return false;
    }

    if (this.state.selectedRegion === '') {
      presentToast({ message: I18n.t('FIND_MATCH_PAGE.regionـvalidation_msg') });

      return false;
    }

    return true;
  };

  render() {
    const { theme } = this.props;
    return (
      <View style={AppStyles.rootContainer}>
        <Header text={I18n.t('MAIN_PAGE.hoplay_match')} />
        <Spinner
          visible={this.state.loading}
          textContent={I18n.t('FIND_MATCH_PAGE.loading_text')}
        />
        <View style={AppStyles.rootContent}>
          <Label style={[AppStyles.grayLabel, styles.descriptionLabel]}>
            {I18n.t('FIND_MATCH_PAGE.hoplay_match_description')}
          </Label>
          <Picker
            pickerName={
              this.state.game != null
                ? this.state.game.gameName
                : I18n.t('NEW_REQUEST_PAGE.choose-game')
            }
            customStyles={styles.gamePicker}
            leftComponent={
              this.state.game != null ? (
                <ImageWithPlaceholder
                  uri={this.state.game.gamePhotoUrl}
                  style={styles.itemImage}
                />
              ) : (
                <Entypo
                  name="game-controller"
                  size={perfectSize(20)}
                  color={theme.primary_text}
                  style={{ opacity: 0.5 }}
                />
              )
            }
            onPickerPress={this.toSelectGame}
          />
          {/* Platform */}
          <Label style={styles.label}>
            {I18n.t('CHAMPIONSHIP_INFO_PAGE.platform')}
          </Label>
          <View style={styles.mt4}>
            <ScrollView
              showsHorizontalScrollIndicator={false}
              horizontal={true}>
              {this.renderTags()}
            </ScrollView>
          </View>
          {/* Region */}
          <Label style={styles.label}>
            {I18n.t('SEARCH_REQUEST_PAGE.region')}
          </Label>
          <Picker
            customStyles={styles.mt4}
            value={this.state.selectedRegion}
            values={this.state.regionList}
            pickerName={I18n.t('CREATE_REQUEST_PAGE.region')}
            onPickerChanged={(val) =>
              this.setState({
                selectedRegion: val,
              })
            }
            leftComponent={
              <Feather
                name="map-pin"
                size={perfectSize(20)}
                color={theme.primary_text}
                style={{ opacity: 0.5 }}
              />
            }
          />
          <View style={{ flex: 1 }}>
            <View style={{ flex: 0.7 }} />
            <FindMatch
              text={I18n.t('MAIN_PAGE.find_match')}
              customStyles={{
                width: '100%',
                height: perfectSize(52),
                top: -perfectSize(26),
              }}
              textCustomStyles={{ fontSize: perfectSize(15) }}
              onPress={this.toMatchPage}
            />
          </View>
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
    regionsList: state.hubReducer.regionsList,
    recentListArr: state.hubReducer.recentListArr,
    gamesList: state.hubReducer.gamesList,
  };
};

export default compose(connect(mapStateToProps, {}))(FindMatchPage);
