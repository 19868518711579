import { StyleSheet } from 'react-native';
import Dimens from '../../constants/dimens';
import { perfectSize } from '../../helpers/deviceHelper';

const styles = (theme) =>
  StyleSheet.create({
    fullSize: {
      width: '100%',
      height: '100%',
    },
    container: {
      flex: 1,
      backgroundColor: theme.secondary_btn_background,
    },
    headerWrap: {
      width: '100%',
      height: perfectSize(164),
    },
    infoWrap: {
      flex: 1,
      paddingTop: Dimens.BODY_PADDING_TOP,
      paddingHorizontal: Dimens.BODY_PADDING_HORIZONTAL,
      marginTop: -Dimens.BODY_BORDER_TOP_RADIUS,
      borderTopStartRadius: Dimens.BODY_BORDER_TOP_RADIUS,
      borderTopEndRadius: Dimens.BODY_BORDER_TOP_RADIUS,
      backgroundColor: theme.primary_background,
    },
    userWrap: {
      position: 'absolute',
      flex: 1,
      width: '100%',
      paddingHorizontal: Dimens.HEADER_PADDING_HORIZONTAL,
      top: Dimens.HEADER_PADDING_VERTICAL,
    },
    userInfoWrap: {
      marginLeft: perfectSize(4),
    },
    gradient: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      height: perfectSize(161),
    },
    backArrow: {
      width: Dimens.BAR_BUTTON_SIZE,
      height: Dimens.BAR_BUTTON_SIZE,
      justifyContent: 'center',
      alignItems: 'center',
      marginLeft: -16,
    },
    shareIcon: {
      position: 'absolute',
      right: perfectSize(16),
      justifyContent: 'center',
      alignItems: 'center',
    },
    reportIcon: {
      position: 'absolute',
      right: perfectSize(40),
      justifyContent: 'center',
      alignItems: 'center',
    },
    userName: {
      color: theme.primary_text,
    },
    image: {
      resizeMode: 'contain',
      width: perfectSize(16),
      height: perfectSize(19),
    },
    seperator: {
      width: perfectSize(94),
      height: perfectSize(2),
      backgroundColor: theme.request_seperator,
    },
    flexRow: {
      flexDirection: 'row',
    },
    alignCenter: {
      alignItems: 'center',
    },
    white_seperator: {
      width: '100%',
      height: 1,
      backgroundColor: theme.primary_text,
      opacity: 0.1,
      marginTop: 10,
    },
    footerWrap: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    point: {
      color: theme.primary_text,
      opacity: 0.56,
    },
    imgUserGroup: {
      resizeMode: 'contain',
      width: perfectSize(17.11),
      height: perfectSize(14),
    },
    usersWrap: {
      backgroundColor: theme.secondary_background,
      paddingHorizontal: perfectSize(16),
      paddingVertical: perfectSize(10),
      borderRadius: perfectSize(12),
    },
    btn_tag: {
      width: perfectSize(40),
      height: perfectSize(20),
      marginRight: perfectSize(4),
      marginTop: perfectSize(5),
    },
    modalStyle: {
      marginHorizontal: 0,
      margin: 0,
    },
    dialogWrapper: {
      justifyContent: 'center',
      alignItems: 'center',
    },
    dialogContainer: {
      backgroundColor: '#181f29',
      borderRadius: perfectSize(8),
      width: '80%',
      padding: perfectSize(32),
      alignSelf: 'center',
    },
    dialogSectionContainer: {
      padding: perfectSize(16),
      alignItems: 'flex-end',
    },
    dialogButtonContainer: {
      flexDirection: 'row',
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
      marginTop: perfectSize(16),
    },
    dialogLabel: {
      color: 'white',
      fontSize: perfectSize(16),
      textAlign: 'center',
    },
    dialogDescription: {
      color: 'white',
      fontSize: 14,
      width: '100%',
    },
    button: {
      marginHorizontal: perfectSize(4),
      borderWidth: 1,
      borderColor: '#a70559',
      borderRadius: perfectSize(10),
      height: perfectSize(42),
      justifyContent: 'center',
      paddingHorizontal: perfectSize(12),
      marginVertical: perfectSize(4),
    },
    smallButton: {
      width: perfectSize(100),
      alignItems: 'center',
      paddingRight: perfectSize(16),
      marginVertical: perfectSize(8),
    },
    buttonContainer: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    buttonColor: {
      color: theme.primary_btn_background,
      textAlign: 'right',
      width: '100%',
    },
    input: {
      textAlignVertical: 'top',
      width: '100%',
      borderBottomWidth: 1,
      borderBottomColor: '#dedede',
      color: 'white',
    },
    indicator: {
      marginTop: perfectSize(12),
    },
    error: {
      color: 'red',
      fontSize: 14,
      marginBottom: perfectSize(8),
      textAlign: 'center',
      width: '100%',
    },
    adCard: {
      borderRadius: perfectSize(12),
      marginVertical: perfectSize(16),
      width: '100%',
      aspectRatio: 327 / 100,
      height: 250,
      overflow: 'hidden',
    },
    adCover: {
      width: '100%',
      height: '100%',
    },
  });

export default styles;
