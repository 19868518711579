import {FONT_SIZE} from './theme';

export const getFontSize = (size) => {
  let fontSize = FONT_SIZE.small;
  switch (size) {
    case 'small':
      fontSize = FONT_SIZE.small;
      break;
    case 'x_small':
      fontSize = FONT_SIZE.x_small;
      break;
    case 'medium':
      fontSize = FONT_SIZE.medium;
      break;
    case 'x_medium':
      fontSize = FONT_SIZE.x_medium;
      break;
    case 'big':
      fontSize = FONT_SIZE.big;
      break;
    case 'x_big':
      fontSize = FONT_SIZE.x_big;
      break;
    case 'large':
      fontSize = FONT_SIZE.large;
      break;
    case 'x_large':
      fontSize = FONT_SIZE.x_large;
      break;

    default:
      fontSize = FONT_SIZE.small;
  }
  return fontSize;
};
