/* eslint-disable react-native/no-inline-styles */
import React, { Component } from 'react';
import { View, TouchableOpacity } from 'react-native';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { store } from '../../redux/store';
import I18n from '../../i18n';
import Feather from 'react-native-vector-icons/Feather';
import * as ImagePicker from 'expo-image-picker';
import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';

// import components
import Header from '../../components/header';
import Label from '../../components/label';
import TextInput from '../../components/text-input';
import UserAvatar from '../../components/user-avatar';
import ImageWithPlaceholder from '../../components/image-with-placeholder';
import PrimaryButton from '../../components/buttons/primary-button';

// import styles
import AppStyles from '../../constants/styles';
import styles from './styles';
import { UPLOAD } from '../../constants/images';

// import actions
import { switchTheme } from '../../redux/modules/app/actions';
import { set as setHub } from '../../redux/modules/hub/actions';
import {
  showAlert,
  showSpinner,
  hideSpinner,
} from '../../redux/modules/alert/actions';

import { perfectSize } from '../../helpers/deviceHelper';
import { presentToast } from '../../helpers/toastHelper';
import Team from '../../models/team';

import * as FirebasePaths from '../../constants/firebasePaths';
import NavigationService from '../../navigation/navigationService';
import LocalImageOpacity from '../../components/local-image-opacity';

class JoinChampionshipPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      teamName: '',
      teamLogo:
        'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/teams%20default%20photos%20and%20background%2Fdefault_team_logov2.png?alt=media&token=b88bf926-35b9-4568-a80e-ee24110aceb6',
      teamBackground:
        'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/teams%20default%20photos%20and%20background%2Fdefault_team_background.png?alt=media&token=c154021c-657b-42e5-b4dd-7216747dd06e',
      teamLogoBase64: '',
      teamBackgroundBase64: '',
      autoGeneratedTeamId: '',
      clanStatus: 'PUBLIC',
    };
  }

  componentDidMount() {
    // Where did the user come from
    this.CAME_FROM = this.props.navigation.getParam('CAME_FROM', 'NEW_TEAM');

    // Log Rank HP
    global.rankLogger.LOG_NEW_HP(
      global.rankLogger.VIEW_JOIN_CHAMPIONSHIPS_PAGE,
      'VIEW_JOIN_CHAMPIONSHIPS_PAGE',
    );
  }

  onNameChange = (val) => {
    this.setState({ teamName: val });
  };

  // Validate the team name check if the name is not taken
  validateTeam() {
    const { teamName } = this.state;
    if (teamName.toString().trim().replace(/\s\s+/g, ' ').length < 3) {
      let msg = I18n.t('JOIN_CHAMPIONSHIP_PAGE.team_name_short_validation');
      presentToast({ message: msg });
      return false;
    }

    // Check team Regx
    let teamReg = /^(\w+\s)*\w+$/;
    let usernameResult = teamReg.test(
      teamName.toString().toLocaleLowerCase().trim(),
    );

    if (!usernameResult) {
      let title = I18n.t(
        'JOIN_CHAMPIONSHIP_PAGE.team_name_regex_validation_title',
      );
      let msg1 = I18n.t('JOIN_CHAMPIONSHIP_PAGE.team_name_regex_validation1');
      let msg2 = I18n.t('JOIN_CHAMPIONSHIP_PAGE.team_name_regex_validation2');
      let msg3 = I18n.t('JOIN_CHAMPIONSHIP_PAGE.team_name_regex_validation3');
      this.props.showAlert([
        title,
        msg1 + msg2 + msg3,
        [
          {
            text: 'OK',
            onPress: () => { },
          },
        ],
      ]);
      return false;
    }

    return true;
  }

  // This function will check if the team is existed or not
  checkIfTeamTaken() {
    let usersPath = '/' + FirebasePaths.FIREBASE_TEAMS + '/';

    return firebase.database()
      .ref(usersPath)
      .orderByChild('/_info_/name')
      .startAt(
        this.state.teamName
          .toString()
          .toLowerCase()
          .replace(/\s\s+/g, ' ')
          .trim(),
      )
      .endAt(
        this.state.teamName
          .toString()
          .toLowerCase()
          .replace(/\s\s+/g, ' ')
          .trim() + '\uf8ff',
      )
      .limitToFirst(1)
      .once('value')
      .then((snapshot) => {
        if (snapshot.exists()) {
          return false;
        }
        return true;
      });
  }

  // This function will register the team in db
  registerTeam = () => {
    // First validate the team name
    if (this.validateTeam()) {
      // if (this.state.autoGeneratedTeamId.length > 1) {
      // Clan status trans
      let statusTitle = I18n.t(
        'JOIN_CHAMPIONSHIP_PAGE.clan_status_alert_title',
      );
      let statusMsg = I18n.t('JOIN_CHAMPIONSHIP_PAGE.clan_status_alert_msg');
      let statusPublic = I18n.t(
        'JOIN_CHAMPIONSHIP_PAGE.clan_status_alert_public_btn',
      );
      let statusPrivate = I18n.t(
        'JOIN_CHAMPIONSHIP_PAGE.clan_status_alert_private_btn',
      );

      // Show  loading  dialog
      this.props.showSpinner({
        title: I18n.t('JOIN_CHAMPIONSHIP_PAGE.loading_msg'),
      });

      this.checkIfTeamTaken()
        .then((isFound) => {
          this.props.hideSpinner();
          if (isFound) {
            let autoGeneratedTeamId = firebase.database().ref().push().key;
            this.props.showAlert([
              statusTitle,
              statusMsg,
              [
                {
                  text: statusPublic,
                  onPress: () => {
                    // Change status to PUBLIC
                    this.setState(
                      { autoGeneratedTeamId, clanStatus: 'PUBLIC' },
                      () => {
                        this.uploadLogos();
                      },
                    );
                  },
                },
                {
                  text: statusPrivate,
                  onPress: () => {
                    // Change status to PRIVATE
                    this.setState(
                      { autoGeneratedTeamId, clanStatus: 'PRIVATE' },
                      () => {
                        this.uploadLogos();
                      },
                    );
                  },
                },
              ],
            ]);
          } else {
            let teamTaken = I18n.t('JOIN_CHAMPIONSHIP_PAGE.team_is_taken');
            presentToast({ message: teamTaken });
          }
        })
        .catch(() => {
          presentToast({
            message:
              'يالليل .. فيه مشكلة صارت تقدر تقفل التطبيق وتشغله عشان تصلح',
            duration: 3000,
            position: 'top',
          });
          this.props.hideSpinner();
        });
      // }
    }
  };

  // This function will be used to finalize the "creating clan" process
  finalizeCreatingClan() {
    // Do not forget to trim !
    // Generate a team id and other initial info
    let teamId = this.state.autoGeneratedTeamId;
    let teamName = this.state.teamName
      .toString()
      .toLowerCase()
      .replace(/\s\s+/g, ' ')
      .trim();
    let teamDescription = 'تيم خرافي متحمس للبطولات';
    let teamAdmin = store.getState().authReducer.uid;
    let teamLogo = this.state.teamLogo;
    let teamBackground = this.state.teamBackground;
    let status = this.state.clanStatus;

    let team = new Team();
    team.admin = teamAdmin;
    team.id = teamId;
    team.name = teamName;
    team.backgroundUrl = teamBackground;
    team.description = teamDescription;
    team.logoUrl = teamLogo;
    team.status = status;

    // Locally : Hub
    let userTeamsArr = store.getState().hubReducer.userTeamsArr;
    userTeamsArr.push(team);
    this.props.setHub({ userTeamsArr });

    // ... Backend
    // Register The team name
    this.registerTeamName(teamName, teamId);
    // Register team data
    this.uploadTeamDB(team);
    // Set a ref to this user : admin , team photo , team name ,
    this.setUserTeamRef(team);

    // Log Rank HP
    global.rankLogger.LOG_NEW_HP(global.rankLogger.CREATE_TEAM, 'CREATE_TEAM');

    // Log the team created
    global.featuresLogger.LOG_TEAMS_TEAMS_CREATED();

    this.props.hideSpinner();
    let successImage = I18n.t('JOIN_CHAMPIONSHIP_PAGE.team_creation_done');
    presentToast({ message: successImage });

    // Pass the team initial info
    NavigationService.navigate('TeamPage', {
      TEAM_LOGO: teamLogo,
      TEAM_BACKGROUND: teamBackground,
      TEAM_ID: teamId,
      TEAM_ADMIN: teamAdmin,
      TEAM_NAME: teamName,
      TEAM_DESCRIPTION: teamDescription,
      CAME_FROM: 'JOIN-CHAMPIONSHIPS',
    });
  }

  // Register the team name
  registerTeamName(teamName, teamId) {
    let path = FirebasePaths.FIREBASE_TEAMS_NAMES;
    firebase.database().ref(path).child(teamName).set(teamId);
  }

  // This function will register the team info to the firebase
  uploadTeamDB(team) {
    if (team != null) {
      if (team.id != null) {
        if (team.id.length > 1) {
          let path =
            FirebasePaths.FIREBASE_TEAMS +
            '/' +
            team.id +
            '/' +
            FirebasePaths.FIREBASE_TEAM_INFO;
          firebase.database().ref(path).set(team);
        }
      }
    }
  }

  // This function will set the team ref for the user
  setUserTeamRef(team) {
    let path =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      store.getState().authReducer.uid +
      '/' +
      FirebasePaths.FIREBASE_USER_TEAMS +
      '/' +
      FirebasePaths.FIREBASE_USER_TEAMS_REFS +
      '/' +
      team.id;
    // Create a json team ref with : admin , photo , name
    let teamRef = { id: team.id, name: team.name, logo: team.logoUrl };

    // Upload to the user teams ref path
    firebase.database().ref(path).set(teamRef);
  }

  selectImage = async (imgType) => {
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      base64: true,
    })

    if (!result.cancelled) {
      if (imgType === 'LOGO') {
        this.setState({
          teamLogo: result.uri,
          teamLogoBase64: result.base64
        });
      } else if (imgType === 'BACKGROUND') {
        this.setState({
          teamBackground: result.uri,
          teamBackgroundBase64: result.base64
        });
      }
    }
  };

  uploadImageToFirebase(image, imgType) {
    //uploads img to firebase storage
    return this.uploadImage(image, imgType).then((photoURL) => {
      if (imgType === 'LOGO') {
        // Update local img and show success message
        this.setState({ teamLogo: photoURL });
        presentToast({
          message: I18n.t('JOIN_CHAMPIONSHIP_PAGE.done_upload_logo'),
        });
        return;
      } else if (imgType === 'BACKGROUND') {
        // Update local img and show success message
        this.setState({ teamBackground: photoURL });
        presentToast({
          message: I18n.t('JOIN_CHAMPIONSHIP_PAGE.done_upload_background'),
        });
        return;
      }
    });
  }

  uploadImage(imageURI, imgType) {
    // This should be the team uid
    let teamId = this.state.autoGeneratedTeamId;
    // if (teamId.length > 1) {
    let imageRef;
    let storageRef = firebase.storage().ref();
    if (imgType === 'LOGO') {
      imageRef = storageRef.child('TeamsInfo').child(teamId).child('team_logo');
    } else if (imgType === 'BACKGROUND') {
      imageRef = storageRef
        .child('TeamsInfo')
        .child(teamId)
        .child('team_background');
    }
    return imageRef.putString(imageURI, firebase.storage.StringFormat.BASE64).then(() => {
      return imageRef.getDownloadURL().then((downloadURL) => {
        return downloadURL;
      });
    });
    // }
  }

  uploadLogos() {
    this.props.showSpinner({
      title: I18n.t('JOIN_CHAMPIONSHIP_PAGE.loading_msg'),
    });
    const { teamLogo, teamLogoBase64, teamBackground, teamBackgroundBase64 } = this.state;
    if (!teamLogo.startsWith('https')) {
      this.uploadImageToFirebase(teamLogoBase64, 'LOGO').then(() => {
        if (!teamBackground.startsWith('https')) {
          this.uploadImageToFirebase(teamBackgroundBase64, 'BACKGROUND').then(() => {
            this.finalizeCreatingClan();
          });
        } else {
          this.finalizeCreatingClan();
        }
      });
    } else {
      if (!teamBackground.startsWith('https')) {
        this.uploadImageToFirebase(teamBackgroundBase64, 'BACKGROUND').then(() => {
          this.finalizeCreatingClan();
        });
      } else {
        this.finalizeCreatingClan();
      }
    }
  }

  render() {
    const { theme } = this.props;
    const _styles = styles(theme);
    const { teamLogo, teamBackground } = this.state;
    return (
      <View style={AppStyles.rootContainer}>
        <Header text={I18n.t('TEAMS_PAGE.create_new_team')} />
        <View style={AppStyles.rootContent}>
          <TextInput
            placeholder={I18n.t('JOIN_CHAMPIONSHIP_PAGE.team_name_placeholder')}
            onChangeText={(val) => this.onNameChange(val)}
            leftComponent={
              <Feather name="info" size={perfectSize(18)} color="#A2A5A9" />
            }
          />
          {/* Clan logo */}
          <View style={_styles.subContainer}>
            <Label bold={true}>
              {I18n.t('JOIN_CHAMPIONSHIP_PAGE.team_logo')}
            </Label>
            <View style={_styles.centerWrapper}>
              <View style={_styles.logoWrapper}>
                <UserAvatar
                  size={perfectSize(96)}
                  borderWidth={2}
                  src={teamLogo}
                />
                <TouchableOpacity
                  style={_styles.logoUploadWrapper}
                  onPress={() => this.selectImage('LOGO')}>
                  <View style={_styles.uploadButton}>
                    <LocalImageOpacity
                      style={{ width: '50%', height: '50%' }}
                      source={UPLOAD}
                      resizeMode="contain"
                    />
                  </View>
                </TouchableOpacity>
              </View>
            </View>
          </View>
          {/* Clan Header */}
          <View style={_styles.subContainer}>
            <Label bold={true}>
              {I18n.t('JOIN_CHAMPIONSHIP_PAGE.clan_header')}
            </Label>
            <View>
              <View style={_styles.headerWrapper}>
                <ImageWithPlaceholder
                  uri={teamBackground}
                  style={_styles.header}
                />
                <TouchableOpacity
                  style={_styles.headerUploadWrapper}
                  onPress={() => this.selectImage('BACKGROUND')}>
                  <View style={_styles.uploadButton}>
                    <LocalImageOpacity
                      style={{ width: '50%', height: '50%' }}
                      source={UPLOAD}
                      resizeMode="contain"
                    />
                  </View>
                </TouchableOpacity>
              </View>
            </View>
          </View>
          <View style={_styles.space} />
          {/* Submit button */}
          <View style={_styles.footer}>
            <PrimaryButton
              text={I18n.t('JOIN_CHAMPIONSHIP_PAGE.register_team_btn')}
              type="filled"
              onPress={() => this.registerTeam()}
              textCustomStyles={_styles.submitTextStyle}
            />
          </View>
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
  };
};

export default compose(
  connect(mapStateToProps, {
    switchTheme,
    setHub,
    showAlert,
    showSpinner,
    hideSpinner,
  }),
)(JoinChampionshipPage);
