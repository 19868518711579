/* eslint-disable react-native/no-inline-styles */
import React, { Component } from 'react';
import { View, ScrollView, TouchableOpacity, Linking } from 'react-native';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Feather from 'react-native-vector-icons/Feather';
import AsyncStorage from '@react-native-community/async-storage';

// import components
import Label from '../../components/label';
import I18n from '../../i18n';
import Header from '../../components/header';
import Switch from '../../components/switch';
import LanguageSwitch from '../../components/language-switch';

import AppStyles from '../../constants/styles';
import styles from './styles';
import { presentToast } from '../../helpers/toastHelper';

import NavigationService from '../../navigation/navigationService';

// import actions
import { switchLanguage } from '../../redux/modules/app/actions';
import { set as HubSet } from '../../redux/modules/hub/actions';
import { showAlert } from '../../redux/modules/alert/actions';

import * as Constants from '../../constants/constants';
import { perfectSize } from '../../helpers/deviceHelper';
import { currentVersionCode } from '../../services/utils';

class SettingsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentLanguage: props.lang || 'en',
      emailAddress: '',
      password: '',
      hopShadow: false,
    };
  }

  componentDidMount = () => {
    // Mainmenucore.loadUserInfo();
    this.props.navigation.addListener('willFocus', (payload) => {
      this._init();
    });
  };

  componentWillUnmount() {
    this.mount = false;
    // if (Mainmenucore.userInfoListener) {
    //   Mainmenucore.userInfoListener.off();
    // }
  }

  _init = () => {
    this.setState({
      localNotificationsToggleState: this.props.IS_LOCAL_NOTIFICATION_ACTIVATED,
    });
    if (this.props.rank < 8300) {
      this.setState({ hopShadow: false });
    } else {
      this.setState({ hopShadow: this.props.IS_HOP_SHADOW_ACTIVATED });
    }
  };

  _changeLanguage = (language) => {
    // Todo
    this.setState({ currentLanguage: language });
    this.props.switchLanguage(language);

    I18n.switchLanguage(language);
  };

  goBack = () => {
    NavigationService.navigate('TabStack');
  };

  _toTermsPage = () => {
    NavigationService.moveToScreenInStack('AuthStack', 'TermsPage');
  };

  _onLocalNotificationsChanged = (value) => {
    if (value) {
      AsyncStorage.setItem(Constants.LOCAL_NOTIFICATION_KEY, 'true');
      this.props.HubSet({ IS_LOCAL_NOTIFICATION_ACTIVATED: true });
      this.setState({ localNotificationsToggleState: true });
      presentToast({
        message: I18n.t('SETTINGS_PAGE.activate_local_notification_toast_on'),
      });
    } else {
      AsyncStorage.setItem(Constants.LOCAL_NOTIFICATION_KEY, 'false');
      this.props.HubSet({ IS_LOCAL_NOTIFICATION_ACTIVATED: false });
      this.setState({ localNotificationsToggleState: false });
      presentToast({
        message: I18n.t('SETTINGS_PAGE.activate_local_notification_toast_off'),
      });
    }
  };

  _onHopsShadowChanged = (value) => {
    const { rank } = this.props;
    this.setState({ hopShadow: value });
    if (rank < 8300 && value) {
      this.props.showAlert([
        'Hoplay Bot 🤖',
        ' معليش رانكك ضعيف , رانكك الحالي ' +
        global.rankLogger.getUserRankName(rank) +
        '  تحتاج ' +
        (8300 - rank) +
        ' HP لاستخدام هذي الخاصية' +
        '، تقدر ترفع رانكك بالتفاعل مع القيمرز الرهيبين في التطبيق واستخدام كل خصائص التطبيق باستمرار ',
        [
          {
            text: 'OK',
            onPress: () => {
              // back to off
              setTimeout(() => {
                this.setState({ hopShadow: false });
              }, 100);
            },
          },
        ],
        // false,
      ]);
    }
    if (value && rank >= 8300) {
      presentToast({
        message: I18n.t('SETTINGS_PAGE.hops_shadow_on'),
      });
      AsyncStorage.setItem('hops-shadow-key', 'true');
      this.props.HubSet({ IS_HOP_SHADOW_ACTIVATED: true });
    }
    if (!value && rank >= 8300) {
      presentToast({
        message: I18n.t('SETTINGS_PAGE.hops_shadow_off'),
      });
      AsyncStorage.setItem('hops-shadow-key', 'false');
      this.props.HubSet({ IS_HOP_SHADOW_ACTIVATED: false });
    }
  };

  _openSocial = (type) => {
    let url = '';
    if (type === 'twitter') {
      url = 'https://twitter.com/HoplayOnline';
    } else if (type === 'instagram') {
      url = 'https://instagram.com/hoplayonline';
    }
    Linking.openURL(url);
  };

  render() {
    const { theme } = this.props;
    const _styles = styles(theme);
    return (
      <View style={AppStyles.rootContainer}>
        <Header
          onBack={this.goBack}
          text={I18n.t('SETTINGS_PAGE.page_title')}
        />
        <View style={[AppStyles.rootContent]}>
          <ScrollView showsVerticalScrollIndicator={false}>
            {/* General */}
            <View style={[_styles.labelWrapper, { marginTop: 0 }]}>
              <Label size="medium" bold={true} style={_styles.whiteLabel}>
                {I18n.t('SETTINGS_PAGE.general')}
              </Label>
            </View>
            <View style={[_styles.itemWrapper]}>
              <TouchableOpacity
                style={_styles.touchContainer}
                onPress={() => NavigationService.navigate('EditProfilePage')}>
                <Label size="small" style={_styles.itemLabel}>
                  {I18n.t('SETTINGS_PAGE.edit_profile')}
                </Label>
              </TouchableOpacity>
            </View>
            <View style={[_styles.itemWrapper]}>
              <TouchableOpacity
                style={_styles.touchContainer}
                onPress={() =>
                  NavigationService.navigate('ChangepasswordPage')
                }>
                <Label size="small" style={_styles.itemLabel}>
                  {I18n.t('SETTINGS_PAGE.change_password')}
                </Label>
              </TouchableOpacity>
            </View>
            <View style={[_styles.itemWrapper]}>
              <TouchableOpacity
                style={_styles.touchContainer}
                onPress={() => NavigationService.navigate('BlockedlistPage')}>
                <Label size="small" style={_styles.itemLabel}>
                  {I18n.t('SETTINGS_PAGE.blocked_list')}
                </Label>
              </TouchableOpacity>
            </View>

            {/* Notifications */}
            <View style={[_styles.labelWrapper]}>
              <Label size="medium" bold={true} style={_styles.whiteLabel}>
                {I18n.t('SETTINGS_PAGE.notifications')}
              </Label>
            </View>
            <View style={[_styles.itemWrapper]}>
              <TouchableOpacity
                style={_styles.touchContainer}
                onPress={() =>
                  NavigationService.navigate('ManageNotificationsPage')
                }>
                <Label size="small" style={_styles.itemLabel}>
                  {I18n.t('SETTINGS_PAGE.manage_notifications')}
                </Label>
              </TouchableOpacity>
            </View>
            <View style={[_styles.itemWrapper]}>
              <TouchableOpacity
                style={_styles.touchContainer}
                onPress={() =>
                  NavigationService.navigate('NotificationManagerPage')
                }>
                <Label size="small" style={_styles.itemLabel}>
                  {I18n.t('SETTINGS_PAGE.notification_manager')}
                </Label>
              </TouchableOpacity>
            </View>
            <View style={[_styles.itemWrapper]}>
              <Label size="small" style={[_styles.itemLabel, { flex: 1 }]}>
                {I18n.t('SETTINGS_PAGE.local_notifications')}
              </Label>
              <Switch
                onValueChanged={(value) =>
                  this._onLocalNotificationsChanged(value)
                }
                value={this.state.localNotificationsToggleState}
              />
            </View>

            {/* Hoplay Cosmetics */}
            <View style={[_styles.labelWrapper]}>
              <Label size="medium" bold={true} style={_styles.whiteLabel}>
                Hoplay Cosmetics
              </Label>
            </View>
            <View style={[_styles.itemWrapper]}>
              <TouchableOpacity
                style={_styles.touchContainer}
                onPress={() => NavigationService.navigate('UserCosmeticsPage')}>
                <Label size="small" style={_styles.itemLabel}>
                  {I18n.t('SETTINGS_PAGE.cosmetics_profile_photo')}
                </Label>
              </TouchableOpacity>
            </View>
            <View style={[_styles.itemWrapper]}>
              <TouchableOpacity
                style={_styles.touchContainer}
                onPress={() =>
                  NavigationService.navigate('UserCosmeticsShadowPage')
                }>
                <Label size="small" style={_styles.itemLabel}>
                  {I18n.t('SETTINGS_PAGE.cosmetics_hops_shadow')}
                </Label>
              </TouchableOpacity>
            </View>
            {/* <View style={[_styles.itemWrapper]}>
              <Label size="small" style={[_styles.itemLabel, {flex: 1}]}>
                {I18n.t('SETTINGS_PAGE.hop_shadow_toggle')}
              </Label>
              <Switch
                onValueChanged={(value) => this._onHopsShadowChanged(value)}
                value={this.state.hopShadow}
                initialValue={this.state.hopShadow}
              />
            </View> */}

            {/* Hoplay Cosmetics */}
            <View style={[_styles.labelWrapper]}>
              <Label size="medium" bold={true} style={_styles.whiteLabel}>
                Privacy
              </Label>
            </View>
            <View style={[_styles.itemWrapper]}>
              <TouchableOpacity
                style={_styles.touchContainer}
                onPress={() => NavigationService.navigate('PreferencesPage')}>
                <Label size="small" style={_styles.itemLabel}>
                  {I18n.t('SETTINGS_PAGE.preferences')}
                </Label>
              </TouchableOpacity>
            </View>

            {/* About */}
            <View style={[_styles.labelWrapper]}>
              <Label style={_styles.whiteLabel} size="medium" bold={true}>
                {I18n.t('SETTINGS_PAGE.about')}
              </Label>
            </View>
            {/* <View style={[_styles.itemWrapper]}>
              <TouchableOpacity style={_styles.touchContainer}>
                <Label size="small" style={_styles.itemLabel}>
                  {I18n.t('SETTINGS_PAGE.how_works')}
                </Label>
              </TouchableOpacity>
            </View> */}
            <View style={[_styles.itemWrapper]}>
              <TouchableOpacity
                style={_styles.touchContainer}
                onPress={this._toTermsPage}>
                <Label size="small" style={_styles.itemLabel}>
                  {I18n.t('SETTINGS_PAGE.terms')}
                </Label>
              </TouchableOpacity>
            </View>
            <View style={_styles.borderContainer}>
              <Label size="medium" style={_styles.itemLabel}>
                Hoplay v{currentVersionCode}
              </Label>
              <Label
                size="small"
                style={[_styles.itemLabel, _styles.label, _styles.pinkLabel]}>
                Copyright info
              </Label>
              <TouchableOpacity
                style={_styles.label}
                onPress={() => this._openSocial('twitter')}>
                <Feather
                  name="twitter"
                  size={20}
                  color={theme.primary_btn_background}
                />
                <Label
                  size="medium"
                  style={[_styles.marginLeft, _styles.pinkLabel]}>
                  @HoplayOnline
                </Label>
              </TouchableOpacity>
              <TouchableOpacity
                style={_styles.label}
                onPress={() => this._openSocial('instagram')}>
                <Feather
                  name="instagram"
                  size={20}
                  color={theme.primary_btn_background}
                />
                <Label
                  size="medium"
                  style={[_styles.marginLeft, _styles.pinkLabel]}>
                  @HoplayOnline
                </Label>
              </TouchableOpacity>
            </View>

            {/* Others */}
            <View style={[_styles.labelWrapper]}>
              <Label style={_styles.whiteLabel} size="medium" bold={true}>
                {I18n.t('SETTINGS_PAGE.others')}
              </Label>
            </View>
            <View style={[_styles.borderContainer]}>
              <TouchableOpacity
                style={_styles.touchContainer}
                onPress={() => NavigationService.navigate('SupportPage')}>
                <Label size="medium" style={_styles.itemLabel}>
                  {I18n.t('SETTINGS_PAGE.contact_us')}
                </Label>
                <Label size="small" style={[_styles.itemLabel, _styles.label]}>
                  {I18n.t('SETTINGS_PAGE.report_a_problem')}
                </Label>
              </TouchableOpacity>
            </View>
            <View style={[_styles.borderContainer]}>
              <TouchableOpacity
                style={_styles.touchContainer}
                onPress={() => NavigationService.navigate('AgentsPage')}>
                <Label size="medium" style={_styles.itemLabel}>
                  Hoplay Agents
                </Label>
                <Label size="small" style={[_styles.itemLabel, _styles.label]}>
                  {I18n.t('SETTINGS_PAGE.agents_msg')}
                </Label>
              </TouchableOpacity>
            </View>

            {/* Language */}
            <View style={[_styles.labelWrapper]}>
              <Label style={_styles.whiteLabel} size="medium" bold={true}>
                {I18n.t('SETTINGS_PAGE.language')}
              </Label>
            </View>
            <View style={AppStyles.contentFotter}>
              <LanguageSwitch
                changeLanguage={this._changeLanguage}
                currentLanguage={this.state.currentLanguage}
              />
            </View>
          </ScrollView>
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
    lang: state.appReducer.currentLang,
    rank: state.authReducer.userinformationDB.rank,
    IS_HOP_SHADOW_ACTIVATED: state.hubReducer.IS_HOP_SHADOW_ACTIVATED,
    IS_LOCAL_NOTIFICATION_ACTIVATED:
      state.hubReducer.IS_LOCAL_NOTIFICATION_ACTIVATED,
  };
};

export default compose(
  connect(mapStateToProps, { switchLanguage, HubSet, showAlert }),
)(SettingsPage);
