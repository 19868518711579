import {eventChannel, buffers} from 'redux-saga';
import {put, take, call, fork, cancel} from 'redux-saga/effects';
import firebase from 'firebase/app';
import 'firebase/database';

import * as types from './actionTypes';
import * as actions from './actions';

/////////////////////////   GET(SUBSCRIBE) ////////////////////////
// watchers: listen, update, remove

export function* watchListener(metaType, listenAction, limit = 0) {
  while (true) {
    const listenRequestAction = yield take(
      types.firebase.FIREBASE_LISTEN_REQUESTED,
    );
    if (listenRequestAction.meta.type === metaType) {
      let task = yield fork(
        listenToChannel,
        listenRequestAction.payload.ref,
        metaType,
        listenAction,
        limit,
      );
      while (true) {
        const action = yield take([
          types.firebase.FIREBASE_REMOVE_LISTENER_REQUESTED,
          types.firebase.FIREBASE_LISTEN_REQUESTED,
        ]);

        if (
          action.type === types.firebase.FIREBASE_REMOVE_LISTENER_REQUESTED ||
          action.meta.type === metaType
        ) {
          yield cancel(task);
          yield put(
            actions.firebaseListenRemoved(
              !!action.payload.clearItems,
              metaType,
            ),
          );

          if (action.type === types.firebase.FIREBASE_LISTEN_REQUESTED) {
            task = yield fork(
              listenToChannel,
              action.payload.ref,
              metaType,
              listenAction,
              limit,
            );
          } else {
            break;
          }
        }
      }
    }
  }
}

// listen to the channel to get updated values

export function* listenToChannel(ref, metaType, listenAction, limit) {
  const chan = yield call(createEventChannel, ref, limit);
  try {
    while (true) {
      const data = yield take(chan);
      yield put(getUpdateAction(listenAction, data, metaType));
    }
  } finally {
    chan.close();
  }
}

// create subscribe event channel

export function createEventChannel(ref, limit) {
  let reference = ref;
  if (limit > 0) {
    reference = ref.limitToLast(limit);
  }
  const listener = eventChannel((emit) => {
    reference.on('value', (snap) => {
      emit({
        key: snap.key,
        value: snap.val(),
      });
    });
    return () => {
      ref.off();
    };
  }, buffers.expanding(1));
  return listener;
}

export function getUpdateAction(listenAction, data, metaType) {
  const value = listenAction(data.value);
  return actions.firebaseListenFulfilled(value, metaType);
}

/////////////////////////   END GET(SUBSCRIBE) ////////////////////////

/////////////////////////   UPDATE    //////////////////////////////
export function* watchUpdateRequested(getUpdates = null) {
  while (true) {
    const action = yield take(types.firebase.FIREBASE_UPDATE_REQUESTED);
    if (typeof getUpdates === 'function') {
      const updates = yield call(getUpdates, action.payload);
      yield fork(updateItems, updates, action.meta.type);
    }
  }
}

// functions to update and remove database
export function* updateItems(updates, metaType) {
  try {
    const ref = firebase.database().ref();
    yield call([ref, ref.update], updates);
    yield put(actions.firebaseUpdateFulfilled(metaType));
  } catch (error) {
    yield put(actions.firebaseUpdateRejected(error, metaType));
  }
}

/////////////////////////   END UPDATE    //////////////////////////////

/////////////////////////   REMOVE    //////////////////////////////

export function* watchRemoveRequested(getPath = null) {
  while (true) {
    const action = yield take(types.firebase.FIREBASE_REMOVE_REQUESTED);

    if (typeof getPath === 'function') {
      const path = yield call(getPath, action.payload);
      yield fork(removeItem, path, action.meta.type);
    }
  }
}

export function* removeItem(path, metaType) {
  try {
    const ref = firebase.database().ref(path);
    yield call([ref, ref.remove]);
    yield put(actions.firebaseRemoveFulfilled(metaType));
  } catch (error) {
    yield put(actions.firebaseRemoveRejected(error, metaType));
  }
}

export default function* () {}

/////////////////////////   END REMOVE    //////////////////////////////
