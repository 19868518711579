import { store } from '../redux/store';
import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/messaging';

import { set as setHub } from '../redux/modules/hub/actions';

export default class MessagingService {
  static updateToken(token) {
    const uid = store.getState().authReducer.uid;
    if (uid !== '' && token !== '' && uid != null && token != null) {
      firebase.database().ref('fcmTokens/').child(uid).set(token);
    }
  }

  static getPermission() {
    try {
      Notification.requestPermission()
        .then((authStatus) => {
          const enabled = authStatus === 'granted'
          if (enabled) {
            firebase.messaging()
              .getToken()
              .then((token) => {
                store.dispatch(setHub({ currentUserToken: token }));
                this.updateToken(token);
              })
              .catch((e) => {
              })
          }
        });
    } catch (e) {

    }
  }
}
