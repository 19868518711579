import firebase from 'firebase/app';
import 'firebase/database';

import * as FirebasePaths from '../../constants/firebasePaths';
import {store} from '../../redux/store';
import GameManager from '../../services/gameManager';
import {set as HubSet} from '../../redux/modules/hub/actions';
import {arrayFromSnapshot} from '../../services/utils';
import {generateRandom} from '../../services/utils';
import {getGameByNameFromGameList} from '../../services/utils';

export default class Searchrequestcore {
  constructor() {}

  // This function will search for requests that matches the user specifications
  static searchForRequest(requestModel) {
    store.dispatch(
      HubSet({
        lazySearchResultCounter: 0,
        searchReqResList: new Map(),
        searchReqsResults: [],
      }),
    );
    // Getting request model attrs
    let selectedGame = requestModel.requestTitle;
    let selectedRegion = requestModel.region;
    let selectedPlatform = requestModel.platform;
    let selectedMatchType = requestModel.matchType;
    let selectedPlayersNumber = requestModel.playersNumber;
    let selectedRank = requestModel.rank;

    // Get the selected game id
    let gameModel = getGameByNameFromGameList(selectedGame);
    let gameId = gameModel.gameID;

    // If the user selected all regions : Then we have to search in all regions
    if (selectedRegion === 'All Countries') {
      // Here we will load all requests in : _requests_ / selected platform  / all region / requests

      // Getting the reference

      let gameRequestsPath =
        FirebasePaths.FB_REQUESTS_REFERENCE +
        '/' +
        selectedPlatform +
        '/' +
        gameId +
        '/';
      firebase.database()
        .ref(gameRequestsPath)
        .once('value', (snapshot) => {
          if (snapshot.val()) {
            let regions = Object.keys(snapshot.val());
            regions.forEach((region) => {
              const path = gameRequestsPath + region;
              this.loadRegionReqs(path, requestModel);
            });
          }
        });
    } else {
      // Filter the search
      this.filterRequest(
        gameId,
        selectedRegion,
        selectedPlatform,
        selectedPlayersNumber,
        selectedRank,
        selectedMatchType,
      );
    }
  }

  // This function will search for requests that matches the user specifications
  static triggerSearchForRequest(requestModel) {
    store.dispatch(
      HubSet({
        lazySearchResultCounter: 0,
        searchReqResList: new Map(),
        searchReqsResults: [],
      }),
    );
    // Getting request model attrs
    let selectedGame = requestModel.requestTitle;
    let selectedRegion = requestModel.region;
    let selectedPlatform = requestModel.platform;

    // Get the selected game id
    let gameModel = getGameByNameFromGameList(selectedGame);
    let gameId = gameModel.gameID;

    this.trigggerFilterRequest(gameId, selectedRegion, selectedPlatform);
  }

  // This function will filter search request : download  data snapshots and then if statments used to filtwer the search
  static filterRequest(
    gameId,
    selectedRegion,
    selectedPlatform,
    selectedPlayersNumber,
    selectedRank,
    selectedMatchType,
  ) {
    // First clear the list from previous requests
    store.dispatch(HubSet({searchReqResList: new Map()}));

    const path =
      FirebasePaths.FB_REQUESTS_REFERENCE +
      '/' +
      selectedPlatform +
      '/' +
      gameId +
      '/' +
      selectedRegion;

    firebase.database()
      .ref(path)
      .limitToLast(20)
      .once('value', (snapshot) => {
        let reqs = arrayFromSnapshot(snapshot);

        if (reqs != null) {
          reqs.forEach((req) => {
            const requestModel = req;
            const b = this.requestValidator(
              requestModel,
              parseInt(selectedPlayersNumber),
              selectedRank,
              selectedMatchType,
            );

            if (b === true) {
              let searchReqsResults = store.getState().hubReducer
                .searchReqsResults;
              if (searchReqsResults.length < 20) {
                searchReqsResults.push(requestModel);
              }
              store.dispatch(HubSet({searchReqsResults: searchReqsResults}));

              let searchReqResList = store.getState().hubReducer
                .searchReqResList;

              searchReqResList.set(requestModel.requestId, requestModel);
              store.dispatch(HubSet({searchReqResList: searchReqResList}));
            }
          });

          store.dispatch(HubSet({valueChanged: generateRandom(11)}));
        }
      });
  }

  // This function will filter search request : download  data snapshots and then if statments used to filtwer the search
  static trigggerFilterRequest(gameId, selectedRegion, selectedPlatform) {
    // First clear the list from previous requests
    store.dispatch(HubSet({searchReqResList: new Map()}));

    const path =
      FirebasePaths.FB_REQUESTS_REFERENCE +
      '/' +
      selectedPlatform +
      '/' +
      gameId +
      '/' +
      selectedRegion;

    firebase.database()
      .ref(path)
      .limitToLast(50)
      .once('value', (snapshot) => {
        let reqs = arrayFromSnapshot(snapshot);

        if (reqs != null) {
          reqs.forEach((req) => {
            const requestModel = req;
            let searchReqsResults = store.getState().hubReducer
              .searchReqsResults;
            if (searchReqsResults.length < 20) {
              searchReqsResults.push(requestModel);
            }
            store.dispatch(HubSet({searchReqsResults: searchReqsResults}));

            let searchReqResList = store.getState().hubReducer.searchReqResList;

            searchReqResList.set(requestModel.requestId, requestModel);
            store.dispatch(HubSet({searchReqResList: searchReqResList}));
          });

          store.dispatch(HubSet({valueChanged: generateRandom(11)}));
        }
      });
  }

  // Filter is here
  static requestValidator(
    receivedRequestModel,
    playersNumberSeleceted,
    rankSelected,
    matchTypeSelected,
  ) {
    if (playersNumberSeleceted > 0) {
      if (receivedRequestModel.playersNumber !== playersNumberSeleceted) {
        return false;
      }
    }

    if (rankSelected !== 'All Ranks') {
      if (receivedRequestModel.rank !== rankSelected) {
        return false;
      }
    }

    if (matchTypeSelected !== 'All Matches') {
      if (matchTypeSelected !== receivedRequestModel.matchType) {
        return false;
      }
    }

    return true;
  }

  // Load region reqs from firebase : In case use chose all countries
  static loadRegionReqs(regionReqsPath, selectedReqModel) {
    // First clear the previous list
    firebase.database()
      .ref(regionReqsPath)
      // .limitToLast(20)
      .once('value', (snapshot) => {
        let reqs = arrayFromSnapshot(snapshot);
        if (reqs != null) {
          reqs.forEach((req) => {
            const requestModel = req;
            const b = this.requestValidator(
              requestModel,
              parseInt(selectedReqModel.playersNumber),
              selectedReqModel.rank,
              selectedReqModel.matchType,
            );

            if (b === true) {
              let searchReqsResults = store.getState().hubReducer
                .searchReqsResults;
              if (searchReqsResults.length < 20) {
                searchReqsResults.push(requestModel);
              }
              store.dispatch(HubSet({searchReqsResults: searchReqsResults}));

              let searchReqResList = store.getState().hubReducer
                .searchReqResList;

              searchReqResList.set(requestModel.requestId, requestModel);
              store.dispatch(HubSet({searchReqResList: searchReqResList}));
            }
          });

          store.dispatch(HubSet({valueChanged: generateRandom(11)}));
        }
      });
  }

  // Set the current timestamp for the user
  static setUserCurrentTimestamp() {}
}
