import firebase from 'firebase/app';
import 'firebase/database';

import * as FirebasePaths from '../../constants/firebasePaths';
import {store} from '../../redux/store';
import Request from '../../firebasecontrollers/request';
import Packet from '../../models/packet';
import UserChatRef from '../../models/userchatref';
import {setUserInfo} from '../../redux/modules/auth/actions';
import {getGameByNameFromGameList} from '../../services/utils';

export class CreateRequestCore {
  static CheckRankGamePromo(callback) {
    firebase.database()
      .ref(FirebasePaths.FIREBASE_RANK_GAME_PROMO)
      .once('value', (snapshot) => {
        callback(snapshot.val());
      });
  }

  // This function will add the request to firebase : creating a request object

  static addRequestToFirebase(
    platform,
    gameName,
    matchType,
    region,
    numberOfPlayers,
    rank,
    requestTime,
    description,
    IS_HOPPED_REQUEST,
  ) {
    // Create request Key

    let requestKey = firebase.database().ref().push().key;

    // Create request firebase controller
    let request = new Request();

    request.addRequest(
      requestKey,
      platform,
      gameName,
      matchType,
      region,
      numberOfPlayers,
      rank,
      requestTime,
      description,
      IS_HOPPED_REQUEST,
    );

    // Create Group Chat Ref

    CreateRequestCore.createGroupChatRef(
      requestKey,
      gameName,
      platform,
      region,
    );
  }

  // This function will create group chat ref and link it with the user group chat refs

  static createGroupChatRef(requestKey, gameName, platform, region) {
    // Get the game id from
    let gameModel = getGameByNameFromGameList(gameName);

    let gameId = gameModel.gameID;

    // Getting the  group chat ref

    let groupChatRef =
      FirebasePaths.FIREBASE_CHAT_ATTR +
      '/' +
      FirebasePaths.FIREBASE_PUBLIC_ATTR +
      '/' +
      requestKey +
      '/';

    // set users  : chat info

    firebase.database()
      .ref(
        groupChatRef +
          '/' +
          FirebasePaths.FIREBASE_PUBLIC_CHAT_INFO +
          '/' +
          FirebasePaths.FIREBASE_PUBLIC_CHAT_USERS +
          '/' +
          store.getState().authReducer.uid,
      )
      .set(store.getState().authReducer.userinformationDB.username);

    // Set the first start message it will be as the last message now and the first packet !

    // Generate message id and get the current timestamp

    let messageId = firebase.database().ref().push().key;
    let timestamp = firebase.database.ServerValue.TIMESTAMP;

    let packet = new Packet();
    packet.messageId = messageId;
    packet.timestamp = timestamp;
    packet.message = 'Welcome Everyone';
    packet.username = store.getState().authReducer.userinformationDB.username;
    packet.chatRef = requestKey;
    packet.uid = store.getState().authReducer.uid;

    // Now set this packet obj to the group chat ref : first as a packet aand then as a last message

    // Packet

    firebase.database()
      .ref(
        groupChatRef +
          '/' +
          FirebasePaths.FIREBASE_CHAT_MESSAGES +
          '/' +
          FirebasePaths.FIREBASE_PUBLIC_CHAT_PACKETS +
          '/' +
          messageId +
          '/',
      )
      .set(packet);

    // Last message

    firebase.database()
      .ref(
        groupChatRef +
          '/' +
          FirebasePaths.FIREBASE_CHAT_MESSAGES +
          '/' +
          FirebasePaths.FIREBASE_PUBLIC_CHAT_LAST_MESSAGE +
          '/',
      )
      .set(packet);

    // Now set the chat ref to the user

    let userChatRefObj = new UserChatRef();

    userChatRefObj.chatRef = requestKey;
    userChatRefObj.lastMessage = 'Welcome Everyone';
    userChatRefObj.lastMessageTimestamp = timestamp;

    // Opponent pic represent the game pic here

    userChatRefObj.opponentPic = gameModel.gamePhotoUrl;

    // Opponent Username represent here the game name

    userChatRefObj.opponentUsername = gameModel?.gameName;
    userChatRefObj.senderId = store.getState().authReducer.uid;

    // Opponent Bio represent the players in the room

    userChatRefObj.opponentBio =
      'Players:' +
      ' ' +
      store.getState().authReducer.userinformationDB.username;

    // Set the gameId , platform , and the region

    userChatRefObj.platform = platform;
    userChatRefObj.gameId = gameId;
    userChatRefObj.region = region;

    let userGroupChatRef =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      store.getState().authReducer.uid +
      '/' +
      FirebasePaths.FIREBASE_USER_CHAT_REFERENCES +
      '/' +
      FirebasePaths.FIREBASE_PUBLIC_ATTR +
      '/' +
      requestKey;

    firebase.database().ref(userGroupChatRef).set(userChatRefObj);
  }

  static saveGameProviderAccount(pcGameProvider, providerAcc, platform, game) {
    let uid = store.getState().authReducer.uid;

    // _users_info_ / uid / info

    let path =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      uid +
      '/' +
      FirebasePaths.FIREBASE_DETAILS_ATTR;

    // Getting the platform to insert game provider

    if (platform == 'PS') {
      // _users_info_ / uid / info / PSN_account

      firebase.database()
        .ref(path + '/' + FirebasePaths.FIREBASE_USER_PS_GAME_PROVIDER_ATTR)
        .set(providerAcc);

      // Save it to userinfoDB
      store.dispatch(
        setUserInfo({
          PSNAcc: providerAcc,
        }),
      );
    } else if (platform == 'XBOX') {
      // _users_info_ / uid / info / XBOX_LIVE_ACCOUNT

      firebase.database()
        .ref(path + '/' + FirebasePaths.FIREBASE_USER_XBOX_GAME_PROVIDER_ATTR)
        .set(providerAcc);

      // Save it to userinfoDB

      store.dispatch(
        setUserInfo({
          XboxLiveAcc: providerAcc,
        }),
      );
    } else if (platform == 'NINTENDO') {
      // _users_info_ / uid / info / XBOX_LIVE_ACCOUNT

      firebase.database()
        .ref(
          path + '/' + FirebasePaths.FIREBASE_USER_NINTENDO_GAME_PROVIDER_ATTR,
        )
        .set(providerAcc);

      // Save it to userinfoDB
      store.dispatch(
        setUserInfo({
          nintendoAcc: providerAcc,
        }),
      );
    } else if (platform == 'MOBILE') {
      // _users_info_ / uid / info / XBOX_LIVE_ACCOUNT

      firebase.database()
        .ref(
          path +
            '/' +
            FirebasePaths.FIREBASE_USER_MOBILE_GAME_PROVIDER_ATTR +
            '/' +
            game +
            ' ' +
            'Mobile',
        )
        .set(providerAcc);

      // Save it to userinfoDB

      let mobileGamesAccs = store.getState().authReducer.userinformationDB
        .mobileGamesAccs;

      mobileGamesAccs.set(game + ' ' + 'Mobile', providerAcc);
      store.dispatch(
        setUserInfo({
          mobileGamesAccs: mobileGamesAccs,
        }),
      );
    } else if (platform == 'PC') {
      // _users_info_ / uid / info / PC_providers / eg : Steam : value

      firebase.database()
        .ref(
          path +
            '/' +
            FirebasePaths.FIREBASE_USER_PC_GAME_PROVIDER_ATTR +
            '/' +
            pcGameProvider,
        )
        .set(providerAcc);

      // Save it to userinfoDB
      let pcGamesAccs = store.getState().authReducer.userinformationDB
        .pcGamesAccs;

      pcGamesAccs.set(pcGameProvider, providerAcc);
      store.dispatch(
        setUserInfo({
          pcGamesAccs: pcGamesAccs,
        }),
      );
    }
  }

  // This function will add saved request to user ref in firebase

  static addSaveRequestToFirebase(savedReqUniqueId) {
    // Getting the user path

    let uid = store.getState().authReducer.uid;

    // _users_info / uid / _games_ / _games_ / _saved_requests_

    let savedRequestPath =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      uid +
      '/' +
      FirebasePaths.FIREBASE_GAMES_REFERENCES +
      '/' +
      FirebasePaths.FIREBASE_SAVED_REQS_ATTR;

    // Set this model in firebase

    firebase.database()
      .ref(
        savedRequestPath +
          '/' +
          FirebasePaths.FIREBASE_SAVED_REQUESTS_REQUESTS_ATTR +
          '/' +
          savedReqUniqueId,
      )
      .set(store.getState().hubReducer.savedRequestsList.get(savedReqUniqueId));

    // Setting the count of saved requests

    firebase.database()
      .ref(savedRequestPath + '/count')
      .set(store.getState().hubReducer.savedRequestsList.size);
  }
}
