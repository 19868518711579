import React, { memo, Component } from 'react';
import {
  View,
  StyleSheet,
  FlatList,
  TouchableOpacity,
  Linking,
  ActivityIndicator,
} from 'react-native';
import { connect } from 'react-redux';
import firebase from 'firebase/app';
import 'firebase/database';

import {
  Placeholder,
  Progressive
} from "rn-placeholder";

import ImageWithPlaceholder from '../../components/image-with-placeholder';
import ClanSmallCard from '../../components/cards/clan-small-card';
import { perfectSize } from '../../helpers/deviceHelper';

import * as FirebasePaths from '../../constants/firebasePaths';
import NavigationService from '../../navigation/navigationService';

import { showSpinner, hideSpinner } from '../../redux/modules/alert/actions';
import AdSpotService from '../../services/adSpot.service';
import { useIsFocused } from '@react-navigation/native';
import SkeletonTextContent from '../../components/skeletonWrapper/skeletonTextContent';
import { dataForSkeleton } from '../../services/utils';

class RecentClans extends Component {
  constructor(props) {
    super(props);
    this.PAGE_SIZE = 10;
    this.state = {
      recentTeamsArrAll: [],
      recentTeamsArr: [],
      AD: null,
      isLoading: true,
    };
  }

  componentDidMount() {
    this.focusListener$ = this.props.navigation.addListener(
      'focus',
      (payload) => {
        // Log Feature
        global.featuresLogger.LOG_TEAMS_REGISTERED_TEAMS_SECTION_VIEW();
      },
    );

    // Load recent registered teams
    this.loadRecentTeams();

    // Load the AD
    this.ADRef$ = firebase.database().ref(
      FirebasePaths.FIREBASE_AD_SPOTS +
      '/' +
      FirebasePaths.FIREBASE_AD_SPOTS_CLANS,
    );
    this.ADRef$.on('value', (snapshot) => {
      if (snapshot.val() != null) {
        this.setState({
          AD: snapshot.val(),
        });
      } else {
        this.setState({
          AD: 'NONE',
        });
      }
    });
  }

  componentWillUnmount() {
    if (this.focusListener$) {
      this.focusListener$();
    }
    if (this.recentTeamsRef$) {
      this.recentTeamsRef$.off();
      this.recentTeamsRef$ = null;
    }
    if (this.ADRef$) {
      this.ADRef$.off();
      this.ADRef$ = null;
    }
  }

  // This function will load the recent registed teams
  loadRecentTeams() {
    const skipList = ['-OrHSVO_i1YCk8SeBmWM', '-NjLL8d9bTuIZVS5iWZ8'];
    let path = FirebasePaths.FIREBASE_TEAMS;
    this.recentTeamsRef$ = firebase.database().ref(path).limitToLast(100);
    this.recentTeamsRef$.on('value', (snapshot) => {
      let recentTeamsArrAll = [];
      snapshot.forEach(function (child) {
        let obj = child.val();
        if (
          obj._info_ != null &&
          obj._info_.logoUrl != null &&
          obj._info_.id !== null
        ) {
          if (!skipList.includes(obj._info_.id)) {
            recentTeamsArrAll.push(obj._info_);
          }
        }
      });
      // for (const key in shot) {
      //   if (shot.hasOwnProperty(key)) {
      //     let obj = shot[key];
      //     if (
      //       obj._info_ != null &&
      //       obj._info_.logoUrl != null &&
      //       obj._info_.id !== null
      //     ) {
      //       recentTeamsArrAll.push(obj._info_);
      //     }
      //   }
      // }
      recentTeamsArrAll = recentTeamsArrAll.reverse();
      let recentTeamsArr = recentTeamsArrAll.slice(0, this.PAGE_SIZE);
      this.setState({
        recentTeamsArrAll,
        recentTeamsArr,
        isLoading: false,
      });
    });
  }

  // This function will redirect the user to the team
  toRegisteredTeam = (team) => {
    NavigationService.moveToScreenInStack('HomeStack', 'TeamPage', {
      TEAM_ID: team.id,
      CAME_FROM: 'TEAMS',
    });
  };

  doInfinite = () => {
    const { recentTeamsArr, recentTeamsArrAll } = this.state;
    if (recentTeamsArr.length === recentTeamsArrAll.length) {
      return;
    }
    setTimeout(() => {
      let arr = recentTeamsArr.concat(
        recentTeamsArrAll.slice(
          recentTeamsArr.length,
          recentTeamsArr.length + this.PAGE_SIZE,
        ),
      );
      this.setState({ recentTeamsArr: arr });
    }, 500);
  };

  openClansAD = () => {
    // First log the click for this ad
    global.featuresLogger.CLANS_AD_CLICK();

    const { AD } = this.state;
    if (AD != null && AD.url != null) {
      AdSpotService.checkAdUrl(AD);
    }
  };

  render() {
    const { theme, isFocused } = this.props;
    const _styles = styles(theme);
    const { AD, recentTeamsArr, isLoading } = this.state;
    if (isFocused) {
      return (
        <View style={_styles.content}>
          {isLoading ? (
            <View style={[_styles.content, _styles.center]}>
              {/* <ActivityIndicator color="#C70064" size="large" /> */}
              <FlatList
                keyExtractor={(item, index) => item.toString()}
                numColumns={5}
                style={{ width: '100%' }}
                data={dataForSkeleton(20)}
                showsVerticalScrollIndicator={false}
                renderItem={({ item, index }) => {
                  return <View style={_styles.left}>
                    <Placeholder
                      Animation={Progressive}
                    >
                      <SkeletonTextContent height={100} />
                      <SkeletonTextContent height={20} style={{ marginTop: 10 }} />
                      <View style={{ alignItems: 'center' }}>
                        <SkeletonTextContent height={20} width={30} style={{ marginTop: 10 }} />
                      </View>
                    </Placeholder>
                  </View>;
                }}
              />
            </View>
          ) : (
            <>
              {AD != null && AD !== 'NONE' && (
                <TouchableOpacity
                  onPress={() => this.openClansAD()}
                  style={{ marginBottom: perfectSize(16) }}>
                  <View style={_styles.adCard}>
                    {AD.img != null && (
                      <ImageWithPlaceholder
                        style={_styles.adCover}
                        uri={AD.img}
                      />
                    )}
                  </View>
                </TouchableOpacity>
              )}
              <FlatList
                data={recentTeamsArr}
                showsVerticalScrollIndicator={false}
                renderItem={({ item, index }) => (
                  <TouchableOpacity
                    onPress={() => this.toRegisteredTeam(item)}
                    style={_styles.left}>
                    <ClanSmallCard
                      teamId={item.id}
                      photo={item.logoUrl}
                      title={item.name}
                      isVerified={item.verified}
                      index={index}
                    />
                  </TouchableOpacity>
                )}
                onEndReached={this.doInfinite}
                onEndReachedThreshold={0.5}
                keyExtractor={(item, index) => item.id}
                numColumns={5}
              />
            </>
          )}
        </View>
      );
    }
    return <View />;
  }
}

const styles = (theme) =>
  StyleSheet.create({
    content: {
      flex: 1,
    },
    center: {
      justifyContent: 'center',
      alignItems: 'center',
    },
    adCard: {
      borderRadius: perfectSize(12),
      width: 350,
      aspectRatio: 327 / 100,
      height: 150,
      overflow: 'hidden',
    },
    adCover: {
      width: '100%',
      height: '100%',
    },
    left: {
      width: '20%',
      paddingHorizontal: 10,
      marginBottom: perfectSize(16),
    },
    right: {
      width: '50%',
      paddingLeft: perfectSize(8),
      marginBottom: perfectSize(16),
    },
  });

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
    lang: state.appReducer.currentLang,
  };
};

function focusComponent(props) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const isFocused = useIsFocused();
  return <RecentClans {...props} isFocused={isFocused} />;
}

export default memo(
  connect(mapStateToProps, { showSpinner, hideSpinner })(focusComponent),
);
