import {createAction} from 'redux-actions';
import {
  LOGIN,
  LOGOUT,
  SIGNUP,
  RESET_PASSWORD,
  LOAD_REACHSOURCES,
  GET_USER_INFO,
  UPDATE_PROFILE,
  UPDATE_PROFILE_ACCOUNTS,
  SET_USER_INFO,
  SET_PROFILE_BORDER_COLOR,
  LOAD_PC_GAMES_PROVIDER,
  LOAD_MOBILE_GAMES_PROVIDER,
  LOAD_BORDER_COLORS,
  SET_FRIENDS_LIST_PAGE_FIRST_VISIT,
} from './actionTypes';

export const loginRequest = createAction(LOGIN.REQUEST);
export const loginSuccess = createAction(LOGIN.SUCCESS);
export const loginFailed = createAction(LOGIN.FAILED);

export const signupRequest = createAction(SIGNUP.REQUEST);
export const signupSuccess = createAction(SIGNUP.SUCCESS);
export const signupFailed = createAction(SIGNUP.FAILED);

export const resetPasswordRequest = createAction(RESET_PASSWORD.REQUEST);
export const resetPasswordSuccess = createAction(RESET_PASSWORD.SUCCESS);
export const resetPasswordFailed = createAction(RESET_PASSWORD.FAILED);

export const loadReachsourceRequest = createAction(LOAD_REACHSOURCES.REQUEST);
export const loadReachsourceSuccess = createAction(LOAD_REACHSOURCES.SUCCESS);

export const updateProfile = createAction(UPDATE_PROFILE.REQUEST);
export const updateProfileSuccess = createAction(UPDATE_PROFILE.SUCCESS);
export const updateProfileFailed = createAction(UPDATE_PROFILE.FAILED);

export const logoutRequest = createAction(LOGOUT);

export const updateProfileAccounts = createAction(
  UPDATE_PROFILE_ACCOUNTS.REQUEST,
);
export const updateProfileAccountsSuccess = createAction(
  UPDATE_PROFILE_ACCOUNTS.SUCCESS,
);
export const updateProfileAccountsFailed = createAction(
  UPDATE_PROFILE_ACCOUNTS.FAILED,
);

export const getUserInfo = createAction(GET_USER_INFO.REQUEST);
export const getUserInfoSuccess = createAction(GET_USER_INFO.SUCCESS);
export const getUserInfoFailed = createAction(GET_USER_INFO.FAILED);

export const setUserInfo = createAction(SET_USER_INFO);
export const setProfileBorderColor = createAction(SET_PROFILE_BORDER_COLOR);
export const setPCGamesProviders = createAction(LOAD_PC_GAMES_PROVIDER);
export const setMobileGamesProviders = createAction(LOAD_MOBILE_GAMES_PROVIDER);

export const loadBorderColors = createAction(LOAD_BORDER_COLORS);

export const setFriendsListPageFirstVisit = createAction(SET_FRIENDS_LIST_PAGE_FIRST_VISIT)
