/* eslint-disable react-native/no-inline-styles */
import React, { Component } from 'react';
import firebase from 'firebase/app';
import 'firebase/database';

import { View, ScrollView } from 'react-native';
import { compose } from 'redux';
import { connect } from 'react-redux';

// import components
import Label from '../../components/label';
import I18n from '../../i18n';
import Header from '../../components/header';
import Switch from '../../components/switch';

import AppStyles from '../../constants/styles';
import styles from './styles';

import NavigationService from '../../navigation/navigationService';

// import actions
import { set as HubSet } from '../../redux/modules/hub/actions';
import { showAlert } from '../../redux/modules/alert/actions';

// firebase
import * as FirebasePaths from '../../constants/firebasePaths';
import { clearFastImageCache } from '../../services/utils';

class ManageNotifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clanChat: true,
      requestChat: true,
      privateChat: true,
      hopsReplies: true,
    };
  }

  componentDidMount = () => {
    this.props.navigation.addListener('willFocus', (payload) => {
      this._init();
    });
  };

  componentWillUnmount() {
    this.mount = false;
  }

  _init = () => {
    const { uid } = this.props;
    global.featuresLogger.NOTIFICATION_SETTINGS_LOGGER('PAGE_VIEW');

    const userPrivacyRef = firebase.database().ref(
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      uid +
      '/' +
      FirebasePaths.FIREBASE_NOTIFICATIONS_SETTINGS_ATTR,
    );
    userPrivacyRef.once('value', (snapshot) => {
      const val = snapshot.val();
      if (val !== null) {
        this.setState({
          clanChat: this.preferenceCheck(val.clanChat),
          requestChat: this.preferenceCheck(val.requestChat),
          privateChat: this.preferenceCheck(val.privateChat),
          hopsReplies: this.preferenceCheck(val.hopsReplies),
        });
      }
    });
  };

  preferenceCheck = (val) => {
    if (val === false) {
      return false;
    }
    return true;
  };

  goBack = () => {
    clearFastImageCache();
    NavigationService.goBack();
  };

  onValueChanged = (value, type) => {
    this.setState({
      [type]: value,
    });
    switch (type) {
      case 'clanChat': {
        if (value) {
          global.featuresLogger.NOTIFICATION_SETTINGS_LOGGER('CLAN_CHAT_ON');
        } else {
          global.featuresLogger.NOTIFICATION_SETTINGS_LOGGER('CLAN_CHAT_OFF');
        }
        break;
      }
      case 'requestChat': {
        if (value) {
          global.featuresLogger.NOTIFICATION_SETTINGS_LOGGER('REQUEST_CHAT_ON');
        } else {
          global.featuresLogger.NOTIFICATION_SETTINGS_LOGGER(
            'REQUEST_CHAT_OFF',
          );
        }
        break;
      }
      case 'privateChat': {
        if (value) {
          global.featuresLogger.NOTIFICATION_SETTINGS_LOGGER('PRIVATE_CHAT_ON');
        } else {
          global.featuresLogger.NOTIFICATION_SETTINGS_LOGGER(
            'PRIVATE_CHAT_OFF',
          );
        }
        break;
      }
      case 'hopsReplies': {
        if (value) {
          global.featuresLogger.NOTIFICATION_SETTINGS_LOGGER('HOPS_REPLIES_ON');
        } else {
          global.featuresLogger.NOTIFICATION_SETTINGS_LOGGER(
            'HOPS_REPLIES_OFF',
          );
        }
        break;
      }
      default:
        global.featuresLogger.NOTIFICATION_SETTINGS_LOGGER('PAGE_VIEW');
        break;
    }
    const { uid } = this.props;
    const userPrivacyPath =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      uid +
      '/' +
      FirebasePaths.FIREBASE_NOTIFICATIONS_SETTINGS_ATTR +
      '/' +
      type;
    firebase.database().ref(userPrivacyPath).set(value);
  };

  render() {
    const { theme } = this.props;
    const _styles = styles(theme);
    return (
      <View style={AppStyles.rootContainer}>
        <Header
          onBack={this.goBack}
          text={I18n.t('SETTINGS_PAGE.manage_notifications')}
        />
        <View style={[AppStyles.rootContent]}>
          <ScrollView>
            <View style={[_styles.itemWrapper]}>
              <Label size="small" style={[_styles.itemLabel, { flex: 1 }]}>
                Clan chat
              </Label>
              <Switch
                onValueChanged={(value) =>
                  this.onValueChanged(value, 'clanChat')
                }
                value={this.state.clanChat}
                initialValue={this.state.clanChat}
              />
            </View>

            <View style={[_styles.itemWrapper]}>
              <Label size="small" style={[_styles.itemLabel, { flex: 1 }]}>
                Request chat
              </Label>
              <Switch
                onValueChanged={(value) =>
                  this.onValueChanged(value, 'requestChat')
                }
                value={this.state.requestChat}
                initialValue={this.state.requestChat}
              />
            </View>

            <View style={[_styles.itemWrapper]}>
              <Label size="small" style={[_styles.itemLabel, { flex: 1 }]}>
                Private Chat
              </Label>
              <Switch
                onValueChanged={(value) =>
                  this.onValueChanged(value, 'privateChat')
                }
                value={this.state.privateChat}
                initialValue={this.state.privateChat}
              />
            </View>

            <View style={[_styles.itemWrapper]}>
              <Label size="small" style={[_styles.itemLabel, { flex: 1 }]}>
                Hops replies
              </Label>
              <Switch
                onValueChanged={(value) =>
                  this.onValueChanged(value, 'hopsReplies')
                }
                value={this.state.hopsReplies}
                initialValue={this.state.hopsReplies}
              />
            </View>
          </ScrollView>
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
    lang: state.appReducer.currentLang,
    uid: state.authReducer.uid,
  };
};

export default compose(connect(mapStateToProps, { HubSet, showAlert }))(
  ManageNotifications,
);
