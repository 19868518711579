/* eslint-disable react-native/no-inline-styles */
import React, {Component} from 'react';
import {View, ScrollView} from 'react-native';
import {compose} from 'redux';
import {connect} from 'react-redux';
import firebase from 'firebase/app';
import 'firebase/database';

// import components
import Label from '../../components/label';
import I18n from '../../i18n';
import Header from '../../components/header';
import Switch from '../../components/switch';

import AppStyles from '../../constants/styles';
import styles from './styles';

import NavigationService from '../../navigation/navigationService';

// import actions
import {set as HubSet} from '../../redux/modules/hub/actions';
import {showAlert} from '../../redux/modules/alert/actions';

// firebase
import * as FirebasePaths from '../../constants/firebasePaths';

class PreferencesPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      recentRequests: false,
      recentHops: false,
      clans: false,
      games: false,
    };
  }

  componentDidMount = () => {
    this.props.navigation.addListener('willFocus', (payload) => {
      this._init();
    });
  };

  componentWillUnmount() {
    this.mount = false;
  }

  _init = () => {
    const {uid} = this.props;
    global.featuresLogger.PRIVACY_LOGGER('PAGE_VIEW');

    const userPrivacyRef = firebase.database().ref(
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
        '/' +
        uid +
        '/' +
        FirebasePaths.FIREBASE_USER_PRIVACY_ATTR,
    );
    userPrivacyRef.once('value', (snapshot) => {
      const val = snapshot.val();
      if (val !== null) {
        this.setState({
          recentRequests: val.recentRequests || false,
          recentHops: val.recentHops || false,
          clans: val.clans || false,
          games: val.games || false,
        });
      }
    });
  };

  goBack = () => {
    NavigationService.goBack();
  };

  onValueChanged = (value, type) => {
    this.setState({
      [type]: value,
    });
    switch (type) {
      case 'recentRequests': {
        if (value) {
          global.featuresLogger.PRIVACY_LOGGER('RECENT_REQUESTS_ON');
        } else {
          global.featuresLogger.PRIVACY_LOGGER('RECENT_REQUESTS_OFF');
        }
        break;
      }
      case 'recentHops': {
        if (value) {
          global.featuresLogger.PRIVACY_LOGGER('RECENT_HOPS_ON');
        } else {
          global.featuresLogger.PRIVACY_LOGGER('RECENT_HOPS_OFF');
        }
        break;
      }
      case 'clans': {
        if (value) {
          global.featuresLogger.PRIVACY_LOGGER('CLANS_ON');
        } else {
          global.featuresLogger.PRIVACY_LOGGER('CLANS_OFF');
        }
        break;
      }
      case 'games': {
        if (value) {
          global.featuresLogger.PRIVACY_LOGGER('GAMES_ON');
        } else {
          global.featuresLogger.PRIVACY_LOGGER('GAMES_OFF');
        }
        break;
      }
      default:
        global.featuresLogger.PRIVACY_LOGGER('PAGE_VIEW');
        break;
    }
    const {uid} = this.props;
    const userPrivacyPath =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      uid +
      '/' +
      FirebasePaths.FIREBASE_USER_PRIVACY_ATTR +
      '/' +
      type;
    firebase.database().ref(userPrivacyPath).set(value);
  };

  render() {
    const {theme} = this.props;
    const _styles = styles(theme);
    return (
      <View style={AppStyles.rootContainer}>
        <Header
          onBack={this.goBack}
          text={I18n.t('SETTINGS_PAGE.preferences')}
        />
        <View style={[AppStyles.rootContent]}>
          <ScrollView>
            <View style={[_styles.itemWrapper]}>
              <Label size="small" style={[_styles.itemLabel, {flex: 1}]}>
                Hide Recent Requests
              </Label>
              <Switch
                onValueChanged={(value) =>
                  this.onValueChanged(value, 'recentRequests')
                }
                value={this.state.recentRequests}
                initialValue={this.state.recentRequests}
              />
            </View>

            <View style={[_styles.itemWrapper]}>
              <Label size="small" style={[_styles.itemLabel, {flex: 1}]}>
                Hide Recent Hops
              </Label>
              <Switch
                onValueChanged={(value) =>
                  this.onValueChanged(value, 'recentHops')
                }
                value={this.state.recentHops}
                initialValue={this.state.recentHops}
              />
            </View>

            <View style={[_styles.itemWrapper]}>
              <Label size="small" style={[_styles.itemLabel, {flex: 1}]}>
                Hide Your Clans
              </Label>
              <Switch
                onValueChanged={(value) => this.onValueChanged(value, 'clans')}
                value={this.state.clans}
                initialValue={this.state.clans}
              />
            </View>

            <View style={[_styles.itemWrapper]}>
              <Label size="small" style={[_styles.itemLabel, {flex: 1}]}>
                Hide Your Games
              </Label>
              <Switch
                onValueChanged={(value) => this.onValueChanged(value, 'games')}
                value={this.state.games}
                initialValue={this.state.games}
              />
            </View>
          </ScrollView>
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
    lang: state.appReducer.currentLang,
    uid: state.authReducer.uid,
  };
};

export default compose(connect(mapStateToProps, {HubSet, showAlert}))(
  PreferencesPage,
);
