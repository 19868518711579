/* eslint-disable react-native/no-inline-styles */
import React, { Component } from 'react';
import { View, TouchableOpacity } from 'react-native';
import { compose } from 'redux';
import { connect } from 'react-redux';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';
import Feather from 'react-native-vector-icons/Feather';
import Entypo from 'react-native-vector-icons/Entypo';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import firebase from 'firebase/app';
import 'firebase/database';

// import components
import Header from '../../components/header';
import Label from '../../components/label';
import TextInput from '../../components/text-input';
import Picker from '../../components/picker';
import ResetPassword from '../../components/buttons/normal-button';
import I18n from '../../i18n';

import styles from './styles';
import NavigationService from '../../navigation/navigationService';
import { perfectSize } from '../../helpers/deviceHelper';
import { REGISTRATION_PLATFORM } from '../../constants/selects';
import { presentToast } from '../../helpers/toastHelper';
import { checkIsUsernameTaken } from '../../services/utils';

// import actions
import {
  loadReachsourceRequest,
  signupRequest,
} from '../../redux/modules/auth/actions';
import { showSpinner, hideSpinner } from '../../redux/modules/alert/actions';

import * as FirebasePaths from '../../constants/firebasePaths';

class RegistrationPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: '',
      email: '',
      password: '',
      confirmPwd: '',
      mainPlatform: '',
      reachSource: '',
      userJourneyArr: [],
    };
  }

  componentDidMount = () => {
    this.props.loadReachsourceRequest();
    this.loadUserJourney();
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.error !== this.props.error &&
      this.props.error != null &&
      this.props.error.length > 0
    ) {
      this.props.hideSpinner({});
      presentToast({ message: this.props.error });
    }

    if (prevProps.mainPlatform !== this.props.mainPlatform && this.props.uid) {
      // sign up finished
      // set password to _users_info_/uid/_access_/key:password
      const accessPath =
        FirebasePaths.FIREBASE_USERS_INFO_ATTR +
        '/' +
        this.props.uid +
        '/' +
        FirebasePaths.FIREBASE_USER_ACCESS_ATTR;
      const passwordPath = accessPath + '/' + 'key';
      firebase.database()
        .ref(passwordPath)
        .once('value', (snapshot) => {
          const val = snapshot.val();
          if (val) {
            firebase.database().ref(accessPath).update({ key: this.state.password });
          } else {
            firebase.database().ref(passwordPath).set(this.state.password);
          }
          this.props.hideSpinner({});
          this.onSignUpSuccess();
        });
    }
  }
  onSignUpSuccess = () => {
    // NavigationService.navigate('UserJourneyPage', {
    //   param1: this.state.userName,
    //   param2: this.state.userJourneyArr,
    // });
    NavigationService.moveToScreenInStack('HomeStack', 'GamesLibraryPage', {
      backToProfile: true,
    });
  };

  loadUserJourney = () => {
    let path = '';
    if (this.props.theme.mode === 'dark') {
      path = '_user_journey_/dark';
    } else {
      path = '_user_journey_/light';
    }
    let userJourneyMap = new Map();
    firebase.database()
      .ref(path)
      .once('value', (snapshot) => {
        const shot = snapshot.val();
        for (var key in shot) {
          if (shot[key] !== null) {
            userJourneyMap.set(key, shot[key]);
          }
        }
        const userJourneyArr = Array.from(userJourneyMap.values());
        this.setState({
          userJourneyArr: userJourneyArr,
        });
      });
  };

  _onValueChange = (val) => {
    this.setState({ emailAddress: val });
  };

  toTerms = () => {
    NavigationService.navigate('TermsPage');
  };

  signUp = () => {
    if (this.isValid()) {
      this.props.showSpinner({});
      checkIsUsernameTaken(this.state.userName, (taken) => {
        this.props.hideSpinner({});
        if (taken) {
          presentToast({
            message: I18n.t('REGISTRATION_PAGE.error_username_taken'),
          });
          return false;
        }
        this.props.showSpinner({});

        this.props.signupRequest({
          email: this.state.email.trim().toLowerCase(),
          username: this.state.userName.trim().toLowerCase(),
          password: this.state.password,
          mainPlatform: this.state.mainPlatform.trim(),
          reachSource: this.state.reachSource,
        });
      });
    }
  };

  isValid = () => {
    if (this.state.userName.trim().length < 3) {
      presentToast({
        message: I18n.t('REGISTRATION_PAGE.error_username_not_specified'),
      });
      return false;
    }

    if (this.state.userName.trim().length > 15) {
      presentToast({
        message: I18n.t('REGISTRATION_PAGE.error_username_maximum_length'),
      });
      return false;
    }

    // Check username Regx
    let usernameRe = /^[\w\d]+$/;

    let usernameResult = usernameRe.test(
      this.state.userName.toLowerCase().trim(),
    );

    if (!usernameResult) {
      presentToast({
        message: I18n.t('REGISTRATION_PAGE.error_username_format'),
      });
      return false;
    }

    if (this.state.email.trim().length === 0) {
      presentToast({
        message: I18n.t('REGISTRATION_PAGE.error_email_not_specified'),
      });
      return false;
    }

    // Email should be less than 254 chars
    if (this.state.email.trim().length > 254) {
      presentToast({
        message: I18n.t('REGISTRATION_PAGE.max_email_chars_validation'),
      });
      return false;
    }

    // Check email Regx
    let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let result = re.test(this.state.email.trim());

    if (!result) {
      presentToast({
        message: I18n.t('REGISTRATION_PAGE.error_email_format'),
      });

      return false;
    }

    if (this.state.password.length === 0) {
      presentToast({
        message: I18n.t('REGISTRATION_PAGE.error_password_not_specified'),
      });
      return false;
    }

    if (this.state.password.length < 6) {
      presentToast({
        message: I18n.t('REGISTRATION_PAGE.error_password_limit'),
      });

      return false;
    }

    if (this.state.confirmPwd.toString().length === 0) {
      presentToast({
        message: I18n.t('REGISTRATION_PAGE.error_password_not_confirmed'),
      });

      return false;
    }

    if (this.state.confirmPwd !== this.state.password) {
      presentToast({
        message: I18n.t('REGISTRATION_PAGE.error_password_do_not_match'),
      });

      return false;
    }

    if (this.state.mainPlatform.length < 1) {
      presentToast({
        message: I18n.t('REGISTRATION_PAGE.error_platform_not_specified'),
      });

      return false;
    }

    return true;
  };

  render() {
    const { theme, reachSources, lang } = this.props;
    const _styles = styles(theme);
    const { emailAddress } = this.state;
    return (
      <View style={_styles.container}>
        <Header />
        <KeyboardAwareScrollView>
          <View style={_styles.wrapper}>
            <Label
              size="medium"
              bold
              style={{
                letterSpacing: 2,
              }}>
              {I18n.t('REGISTRATION_PAGE.new_account')}
            </Label>
            <View style={_styles.actionWrap}>
              <TextInput
                placeholder={I18n.t('REGISTRATION_PAGE.username')}
                onChangeText={(val) => this.setState({ userName: val })}
                value={emailAddress}
                customStyles={{ marginVertical: perfectSize(8) }}
                leftComponent={
                  <Feather
                    name="user"
                    size={perfectSize(20)}
                    color={theme.primary_text}
                  />
                }
              />

              <TextInput
                placeholder={I18n.t('REGISTRATION_PAGE.email')}
                onChangeText={(val) => this.setState({ email: val })}
                value={emailAddress}
                customStyles={{ marginVertical: perfectSize(8) }}
                leftComponent={
                  <MaterialIcons
                    name="email"
                    size={perfectSize(20)}
                    color={theme.primary_text}
                  />
                }
              />

              <TextInput
                placeholder={I18n.t('REGISTRATION_PAGE.password')}
                onChangeText={(val) => this.setState({ password: val })}
                value={emailAddress}
                customStyles={{ marginVertical: perfectSize(8) }}
                secureTextEntry={true}
                leftComponent={
                  <Feather
                    name="lock"
                    size={perfectSize(20)}
                    color={theme.primary_text}
                  />
                }
              />

              <TextInput
                placeholder={I18n.t('REGISTRATION_PAGE.confirm_password')}
                onChangeText={(val) => this.setState({ confirmPwd: val })}
                value={emailAddress}
                customStyles={{ marginVertical: perfectSize(8) }}
                secureTextEntry={true}
                leftComponent={
                  <Feather
                    name="lock"
                    size={perfectSize(20)}
                    color={theme.primary_text}
                  />
                }
              />

              <Picker
                pickerName={I18n.t('EDIT_PROFILE_PAGE.main_platform')}
                value={this.state.mainPlatform}
                customStyles={{ marginVertical: perfectSize(8) }}
                values={REGISTRATION_PLATFORM}
                onPickerChanged={(val) => this.setState({ mainPlatform: val })}
                translate={true}
                leftComponent={
                  <Entypo
                    name="game-controller"
                    size={perfectSize(20)}
                    color={theme.primary_text}
                  />
                }
              />
              <Picker
                pickerName={I18n.t('REGISTRATION_PAGE.hoplay_source')}
                value={this.state.reachSource}
                customStyles={{ marginVertical: perfectSize(8) }}
                values={lang === 'en' ? reachSources.en : reachSources.ar}
                onPickerChanged={(val) => this.setState({ reachSource: val })}
                leftComponent={
                  <Feather
                    name="info"
                    size={perfectSize(20)}
                    color={theme.primary_text}
                  />
                }
              />

              <View style={[_styles.flexRow, { flexWrap: 'wrap' }]}>
                <Label size="small" style={_styles.txtTerms}>
                  {I18n.t('REGISTRATION_PAGE.terms_warning')}
                </Label>

                <Label>&nbsp;</Label>
                <TouchableOpacity onPress={() => this.toTerms()}>
                  <Label
                    size="small"
                    style={[
                      _styles.txtTerms,
                      { color: theme.primary_btn_background },
                    ]}>
                    {I18n.t('REGISTRATION_PAGE.terms')}
                  </Label>
                </TouchableOpacity>
              </View>
            </View>
            <View style={{ justifyContent: 'flex-end' }}>
              <ResetPassword
                text={I18n.t('REGISTRATION_PAGE.sign_up')}
                type="outlined"
                customStyles={_styles.btnLogin}
                textCustomStyles={{ fontSize: perfectSize(15) }}
                onPress={() => this.signUp()}
              />
              <View style={_styles.actionBottom} />
            </View>
          </View>
        </KeyboardAwareScrollView>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
    lang: state.appReducer.currentLang,
    reachSources: state.authReducer.reachSource,
    mainPlatform: state.authReducer.userinformationDB.mainPlatform,
    loading: state.authReducer.loading,
    error: state.authReducer.error,
    uid: state.authReducer.uid,
  };
};

export default compose(
  connect(mapStateToProps, {
    loadReachsourceRequest,
    signupRequest,
    showSpinner,
    hideSpinner,
  }),
)(RegistrationPage);
