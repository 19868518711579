import {StyleSheet} from 'react-native';
import {perfectSize} from '../../helpers/deviceHelper';
import dimens from '../../constants/dimens';

const styles = (theme) =>
  StyleSheet.create({
    headerRight: {
      flexDirection: 'row-reverse',
    },
    actionBtn: {
      padding: perfectSize(4),
      marginRight: perfectSize(20),
    },
    pinkLabel: {
      color: theme.primary_btn_background,
    },
    avatarContainer: {
      width: perfectSize(96),
      height: perfectSize(96),
    },
    iconContainer: {
      width: perfectSize(32),
      height: perfectSize(32),
      backgroundColor: theme.primary_btn_background,
      position: 'absolute',
      right: 0,
      bottom: 0,
      borderRadius: perfectSize(16),
      justifyContent: 'center',
      alignItems: 'center',
      paddingTop: perfectSize(2),
      paddingLeft: perfectSize(2),
    },
    emailContainer: {
      flexDirection: 'row',
      backgroundColor: '#2f353e',
      marginVertical: perfectSize(8),
      alignItems: 'center',
      height: 52,
      paddingHorizontal: perfectSize(16),
      borderRadius: perfectSize(12),
    },
    emailLabel: {
      opacity: 0.4,
      marginLeft: perfectSize(8),
    },
    badge: {
      width: perfectSize(16),
      height: perfectSize(16),
      marginLeft: perfectSize(8),
    },
    manageBtnContainer: {
      height: perfectSize(32),
      width: perfectSize(146),
      backgroundColor: theme.primary_btn_background,
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: perfectSize(16),
    },
    itemContainer: {
      height: 52,
      backgroundColor: theme.secondary_btn_background,
      marginTop: perfectSize(16),
      paddingHorizontal: perfectSize(16),
      borderRadius: perfectSize(12),
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: perfectSize(8),
    },
    itemIcon: {
      width: perfectSize(20),
      height: perfectSize(20),
      // marginLeft: perfectSize(10),
    },
    itemLabel: {
      color: theme.tab_inactive_text_color,
      marginLeft: 12,
      flex: 1,
    },
    accountContainer: {
      alignSelf: 'flex-start',
      flexDirection: 'row',
      alignItems: 'center',
      borderRadius: perfectSize(8),
      justifyContent: 'center',
      height: perfectSize(32),
      marginHorizontal: perfectSize(4),
      paddingHorizontal: perfectSize(12),
    },
    accountSteam: {
      alignSelf: 'flex-start',
      flexDirection: 'row',
      alignItems: 'center',
      borderRadius: perfectSize(8),
      justifyContent: 'center',
      height: perfectSize(32),
    },
    accountImage: {
      width: perfectSize(20),
      height: perfectSize(20),
      marginRight: perfectSize(10),
      resizeMode: 'contain',
    },
    submitBtn: {
      marginTop: perfectSize(32),
      marginBottom: dimens.CONTENT_PADDING_BOTTOM,
    },
  });

export default styles;
