import React, { Component } from 'react';
import { View } from 'react-native';
import { compose } from 'redux';
import { connect } from 'react-redux';
import I18n from '../../i18n';

// import components
import AuthHeader from '../../components/header-with-notification';

// import styles
import AppStyles from '../../constants/styles';
import styles from './styles';

// import actions
import { switchTheme } from '../../redux/modules/app/actions';

import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';

import MyTabBar from '../../components/custom-top-tab';
const Tab = createMaterialTopTabNavigator();

import RecentHopsTab from './hops';
import MyHopsTab from './my-hops';
import RepliesTab from './replies';
import { useIsFocused } from '@react-navigation/native';

class TimelinePage extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.focusListener$ = this.props.navigation.addListener(
      'focus',
      (payload) => {
        global.featuresLogger.HOP_AD_PAGE_VIEW();
      },
    );
  }

  componentWillUnmount() {
    if (this.focusListener$) {
      this.focusListener$();
    }
  }

  render() {
    const { theme, isFocused } = this.props;
    const _styles = styles(theme);
      return (
        <View style={_styles.container}>
          <AuthHeader title={I18n.t('TABS.home')} />
          <View style={_styles.content}>
            <Tab.Navigator
              swipeEnabled={false}
              sceneContainerStyle={AppStyles.transparent}
              lazy={true}
              tabBar={(props) => (
                <MyTabBar {...props} badges={['Feeds']} lang={this.props.lang} />
              )}>
              <Tab.Screen
                name="Timeline"
                component={RecentHopsTab}
                options={{ tabBarLabel: I18n.t('TIMELINE_PAGE.timeline_button') }}
              />
              <Tab.Screen
                name="Replies"
                component={RepliesTab}
                options={{ tabBarLabel: I18n.t('TIMELINE_PAGE.replies_button') }}
              />
              <Tab.Screen
                name="MyHops"
                component={MyHopsTab}
                options={{ tabBarLabel: I18n.t('TIMELINE_PAGE.my_hops_button') }}
              />
            </Tab.Navigator>
          </View>
        </View>
      );
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
    lang: state.appReducer.currentLang,
    toOthersRepliesMap: state.hubReducer.toOthersRepliesMap,
    toOthersRepliesArr: state.hubReducer.toOthersRepliesArr,
    othersRepliesMap: state.hubReducer.othersRepliesMap,
    othersRepliesArr: state.hubReducer.othersRepliesArr,
  };
};

function focusComponent(props) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const isFocused = useIsFocused();
  return <TimelinePage {...props} isFocused={isFocused} />;
}

export default compose(connect(mapStateToProps, { switchTheme }))(focusComponent);
