import { Linking } from 'react-native';
import firebase from 'firebase/app';
import 'firebase/database';

import * as FirebasePaths from '../constants/firebasePaths';
import NavigationService from '../navigation/navigationService';
import mainMenuService from '../navigation/mainMenuService';
import topTabService from '../navigation/topTabService';
import HootsCore from '../pages/hoots/hootsCore';
import {store} from '../redux/store';
import {showAlert} from '../redux/modules/alert/actions';

export default class AdSpotService {
  static checkAdUrl(ad) {
    const { url } = ad;
    if (url.startsWith('http')) {
      // Linking
      Linking.openURL(url);
    } else {
      // go to page
      switch (url) {
        case 'ad':
          const { adId } = ad;
          const adRef = FirebasePaths.FIREBASE_SLIDERS + '/' + adId;
          firebase.database()
            .ref(adRef)
            .once('value', (snapshot) => {
              const val = snapshot.val();
              if (val !== null) {
                NavigationService.moveToScreenInStack('HomeStack', 'AdPage', {
                  data: val,
                });
              }
            });
          break;
        case 'championship-status':
          const { championshipId: status_championshipId, championshipTitle } = ad;
          NavigationService.moveToScreenInStack(
            'HomeStack',
            'ChampionshipStatusPage',
            {
              CHAMPIONSHIP_OBJ: {
                id: status_championshipId,
                title: championshipTitle,
              },
            },
          );
          break;
        case 'championship-info':
          const { championshipId: info_championshipId } = ad;
          const dbRef =
            FirebasePaths.FIREBASE_CHAMPIONSHIPS_REFERENCE +
            '/' +
            info_championshipId +
            '/' +
            FirebasePaths.FIREBASE_CHAMPIONSHIPS_INFO;
          firebase.database()
            .ref(dbRef)
            .once('value', (snapshot) => {
              const val = snapshot.val();
              if (val !== null) {
                NavigationService.moveToScreenInStack(
                  'HomeStack',
                  'ChampionshipInfoPage',
                  {
                    championshipObj: val,
                  },
                );
              }
            });
          break;
        case 'lobby':
          const {
            requestId: lobbyRequestId,
            platform: lobbyPlatform,
            gameId: lobbyGameId,
            region: lobbyRegion,
          } = ad;
          NavigationService.moveToScreenInStack('HomeStack', 'LobbyPage', {
            requestId: lobbyRequestId,
            platform: lobbyPlatform,
            gameId: lobbyGameId,
            region: lobbyRegion,
          });
          break;
        case 'photo-viewer':
          const { title: photoTitle, IMGS: photoIMGS } = ad;
          NavigationService.moveToScreenInStack(
            'HomeStack',
            'PhotoViewerPage',
            {
              TITLE: photoTitle,
              IMGS: photoIMGS,
            },
          );
          break;
        case 'post':
          const { POST_ID: postPOSTID } = ad;
          NavigationService.moveToScreenInStack('HomeStack', 'PostPage', {
            POST_ID: postPOSTID,
          });
          break;
        case 'team':
          const { TEAM_ID: teamTEAMID } = ad;
          NavigationService.moveToScreenInStack('HomeStack', 'TeamPage', {
            TEAM_ID: teamTEAMID,
          });
          break;
        case 'user-games-accounts':
          const { FRIEND_KEY: userFriendKey, ACTIVE_TAB: userActiveTab } = ad;
          NavigationService.moveToScreenInStack(
            'HomeStack',
            'ViewfriendprofilePage',
            {
              FRIEND_KEY: userFriendKey,
              ACTIVE_TAB: userActiveTab || 1,
            },
          );
          break;
        case 'viewfriendprofile':
          const { FRIEND_KEY: friendFriendKey, ACTIVE_TAB: friendActiveTab } = ad;
          NavigationService.moveToScreenInStack(
            'HomeStack',
            'ViewfriendprofilePage',
            {
              FRIEND_KEY: friendFriendKey,
              ACTIVE_TAB: friendActiveTab || 0,
            },
          );
          break;
        // pages don't require key/id to navigate
        case 'achievements':
          NavigationService.moveToScreenInStack(
            'HomeStack',
            'AchievementsPage',
          );
          break;
        case 'agents':
          NavigationService.moveToScreenInStack('SettingsStack', 'AgentsPage');
          break;
        case 'blockedlist':
          NavigationService.moveToScreenInStack(
            'SettingsStack',
            'BlockedlistPage',
          );
          break;
        case 'championships':
          NavigationService.navigate('TabStack');
          setTimeout(() => {
            mainMenuService.navigate('Home');
            topTabService.navigate('Requests');
            setTimeout(() => {
              topTabService.navigate('Championships');
            }, 150);
          }, 150);
          break;
        case 'changepassword':
          NavigationService.moveToScreenInStack(
            'SettingsStack',
            'ChangepasswordPage',
          );
          break;
        case 'community':
          // NavigationService.moveToScreenInStack('HomeStack', 'CommunityPage');
          NavigationService.navigate('TabStack');
          mainMenuService.navigate('Chat');
          break;
        case 'contact-us':
          NavigationService.moveToScreenInStack(
            'SettingsStack',
            'ContactUsPage',
          );
          break;
        case 'createrequest':
          NavigationService.moveToScreenInStack('HomeStack', 'NewRequestPage');
          break;
        case 'editprofile':
          NavigationService.moveToScreenInStack(
            'SettingsStack',
            'EditProfilePage',
          );
          break;
        case 'find-match':
          NavigationService.moveToScreenInStack('HomeStack', 'HoplayMatchPage');
          break;
        case 'friendslist':
          NavigationService.navigate('FriendsListPage');
          break;
        case 'games-list':
          NavigationService.moveToScreenInStack(
            'HomeStack',
            'GamesLibraryPage',
          );
          break;
        case 'home':
          NavigationService.navigate('TabStack');
          mainMenuService.navigate('Home');
          break;
        case 'join-championship':
          NavigationService.moveToScreenInStack(
            'HomeStack',
            'JoinChampionshipPage',
          );
          break;
        case 'join-clan':
          NavigationService.moveToScreenInStack('HomeStack', 'JoinClanPage');
          break;
        case 'leaderboard':
          NavigationService.moveToScreenInStack('HomeStack', 'LeaderboardPage');
          break;
        case 'main':
          NavigationService.navigate('TabStack');
          setTimeout(() => {
            mainMenuService.navigate('Home');
            topTabService.navigate('Requests');
          }, 150);
          break;
        case 'mainmenu':
          break;
        case 'newrequest':
          NavigationService.moveToScreenInStack('HomeStack', 'NewRequestPage');
          break;
        case 'news':
          NavigationService.navigate('TabStack');
          setTimeout(() => {
            mainMenuService.navigate('Home');
            topTabService.navigate('Requests');
            setTimeout(() => {
              topTabService.navigate('Feeds');
            }, 150);
          }, 150);
          break;
        case 'notification-manager':
          NavigationService.moveToScreenInStack(
            'SettingsStack',
            'NotificationManagerPage',
          );
          break;
        case 'notifications':
          NavigationService.moveToScreenInStack(
            'HomeStack',
            'NotificationsPage',
          );
          break;
        case 'manage-notification':
          NavigationService.moveToScreenInStack(
            'SettingsStack',
            'ManageNotificationsPage',
          );
          break;
        case 'post-hop':
          NavigationService.moveToScreenInStack('HomeStack', 'PostHopPage');
          break;
        case 'preferences':
          NavigationService.moveToScreenInStack(
            'SettingsStack',
            'PreferencesPage',
          );
          break;
        case 'ranks-timeline':
          NavigationService.moveToScreenInStack(
            'HomeStack',
            'RankTimelinePage',
          );
          break;
        case 'requestshub':
          NavigationService.moveToScreenInStack('HomeStack', 'RequestshubPage');
          break;
        case 'searchrequest':
          NavigationService.moveToScreenInStack(
            'HomeStack',
            'SearchrequestPage',
          );
          break;
        case 'settings':
          NavigationService.navigate('SettingsStack');
          break;
        case 'support':
          NavigationService.moveToScreenInStack('SettingsStack', 'SupportPage');
          break;
        case 'teams':
          NavigationService.navigate('TabStack');
          setTimeout(() => {
            mainMenuService.navigate('Home');
            topTabService.navigate('Requests');
            setTimeout(() => {
              topTabService.navigate('Clans');
            }, 150);
          }, 150);
          break;
        case 'terms':
          NavigationService.moveToScreenInStack('AuthStack', 'TermsPage');
          break;
        case 'timeline':
          NavigationService.navigate('TabStack');
          setTimeout(() => {
            mainMenuService.navigate('Hops');
            topTabService.navigate('Timeline');
          }, 150);
          break;
        case 'userclans':
          NavigationService.navigate('TabStack');
          mainMenuService.navigate('Home');
          topTabService.navigate('Clans');
          setTimeout(() => {
            topTabService.navigate('Your');
          }, 250);
          break;
        case 'user-cosmetics':
          NavigationService.moveToScreenInStack(
            'SettingsStack',
            'UserCosmeticsPage',
          );
          break;
        case 'user-cosmetics-shadow':
          NavigationService.moveToScreenInStack(
            'SettingsStack',
            'UserCosmeticsShadowPage',
          );
          break;
        case 'userprofile':
          NavigationService.moveToScreenInStack('HomeStack', 'UserprofilePage');
          break;
        case 'hoots':
            const {HOOT_ID} = ad;
            NavigationService.navigate('TabStack');
            mainMenuService.navigate('Hoots');
            if (HOOT_ID) {
              // get hoot details from HOOT_ID
              const hootRef = HootsCore.getHootDetailRef(HOOT_ID);
              hootRef.once('value', (snapshot) => {
                  const val = snapshot.val();
                  if (val !== null) {
                    store.dispatch(
                      showAlert([
                        '',
                        '',
                        [],
                        true,
                        {},
                        {...val, isLive: true}
                      ])
                    )
                  }
                });
            }
            break;
        default:
          NavigationService.navigate(url);
          break;
      }
    }
  }
}
