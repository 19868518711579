import {StyleSheet} from 'react-native';
import {perfectSize} from '../../../helpers/deviceHelper';

const styles = (theme) =>
  StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: '#0E1319',
    },
    wrapper: {
      flex: 1,
    },
    margin: {
      marginBottom: perfectSize(8),
    },
    dialogContainer: {
      backgroundColor: 'white',
      borderRadius: perfectSize(8),
      paddingBottom: perfectSize(16),
    },
    dialogSectionContainer: {
      padding: perfectSize(16),
      alignItems: 'flex-end',
    },
    dialogLabel: {
      color: 'black',
    },
    dialogDescription: {
      color: '#00000080',
      fontSize: 14,
      width: '100%',
    },
    button: {
      width: '100%',
      alignItems: 'center',
      paddingRight: perfectSize(16),
      marginVertical: perfectSize(8),
    },
    smallButton: {
      width: perfectSize(100),
      alignItems: 'center',
      paddingRight: perfectSize(16),
      marginVertical: perfectSize(8),
    },
    buttonContainer: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    buttonColor: {
      color: theme.primary_btn_background,
      textAlign: 'right',
      width: '100%',
    },
    input: {
      textAlignVertical: 'top',
      width: '100%',
      borderBottomWidth: 1,
      borderBottomColor: '#dedede',
    },
  });

export default styles;
