import React, {memo, Component} from 'react';
import {View, StyleSheet, TouchableOpacity} from 'react-native';
import Feather from 'react-native-vector-icons/Feather';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import Label from '../label';

import {perfectSize} from '../../helpers/deviceHelper';

class Filter extends Component {
  render() {
    const {
      theme,
      pickerName,
      leftComponent,
      customStyles,
      onPickerPress,
      filterCount,
    } = this.props;
    const styles = style(theme);
    return (
      <View>
        {filterCount && (
          <View style={styles.filterBadge}>
            <Label size="small">{filterCount}1</Label>
          </View>
        )}
        <TouchableOpacity
          style={[styles.container, customStyles]}
          onPress={onPickerPress}>
          <View style={styles.flexRow}>
            {leftComponent && leftComponent}
            <Label style={styles.input}>{pickerName}</Label>
          </View>
          <Feather
            name="sliders"
            color={theme.primary_text}
            size={perfectSize(16)}
          />
        </TouchableOpacity>
      </View>
    );
  }
}

const style = (theme) =>
  StyleSheet.create({
    container: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      height: 52,
      backgroundColor: theme.secondary_btn_background,
      paddingHorizontal: perfectSize(16),
      borderRadius: perfectSize(12),
    },
    flexRow: {
      flexDirection: 'row',
    },
    input: {
      marginRight: perfectSize(8),
    },
    filterBadge: {
      backgroundColor: theme.primary_btn_background,
      width: perfectSize(16),
      height: perfectSize(16),
      borderRadius: perfectSize(8),
      alignItems: 'center',
      position: 'absolute',
      right: perfectSize(4),
      top: perfectSize(4),
      zIndex: 2,
    },
  });

Filter.propTypes = {
  pickerName: PropTypes.string,
  customStyles: PropTypes.any,
  filterCount: PropTypes.number, // how many filters applied
};

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
  };
};

export default memo(connect(mapStateToProps)(Filter));
