import React, {memo, Component} from 'react';
import {Text, TouchableOpacity, StyleSheet} from 'react-native';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import FontAwesome from 'react-native-vector-icons/FontAwesome';

import {getFontSize} from '../../../constants/calc';
import {perfectSize} from '../../../helpers/deviceHelper';
import {FONT_FAMILY, FONT_SIZE} from '../../../constants/theme';
class HoplayTag extends Component {
  render() {
    const {theme, title, size, onPress, customStyles} = this.props;
    const fontSize = size ? getFontSize(size) : FONT_SIZE.small;
    const fontFamily =
      this.props.lang === 'en' ? FONT_FAMILY.ubuntu : FONT_FAMILY.cairo;
    return (
      <TouchableOpacity
        style={[styles(theme).container, customStyles]}
        onPress={onPress}>
        {title ? (
          <Text
            style={{
              fontSize: fontSize,
              fontFamily: fontFamily,
              color: theme.primary_text,
            }}>
            {title}
          </Text>
        ) : (
          <FontAwesome name="microphone" size={perfectSize(12)} color="white" />
        )}
      </TouchableOpacity>
    );
  }
}

HoplayTag.propTypes = {
  title: PropTypes.string,
  onPress: PropTypes.func,
  size: PropTypes.oneOf([
    'small',
    'x_small',
    'medium',
    'x_medium',
    'big',
    'x_big',
    'large',
    'x_large',
  ]),
  customStyles: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
    lang: state.appReducer.currentLang,
  };
};

export default memo(connect(mapStateToProps)(HoplayTag));

const styles = (theme) =>
  StyleSheet.create({
    container: {
      flexDirection: 'row',
      height: perfectSize(21),
      marginVertical: perfectSize(5),
      backgroundColor: theme.btn_tag,
      alignItems: 'center',
      borderRadius: perfectSize(15),
      alignSelf: 'flex-start',
      paddingHorizontal: perfectSize(5),
    },
  });
