import {handleActions} from 'redux-actions';
import _ from 'lodash';

import {set, logout} from './actions';

import Userinformation from '../../../models/userinformation';
import Requestmodel from '../../../models/requestmodel';
import PlayerModel from '../../../models/playersmodel';

export const defaultState = {
  // ************************************         Nami      ************************************ //
  teaser: '',
  currentUserToken: '',
  clansChatsArr: [],
  activiatedGamesNotificationArr: [],
  deactiviatedGamesNotificationArr: [],
  tabsUnreadMessages: [],
  USER_CLICKED_NOTIFICATION_FOREGROUND_MODE: false,
  USER_PUSH_NOTIFICATION_DESIRED_PAGE: '',
  PUSH_NOTIFICATION_TRIGGER1: '',
  IS_LOCAL_NOTIFICATION_ACTIVATED: true,
  isKeyboardOpen: false,
  HAS_NEW_NOTIFICATION: false,
  shareIconsMap: {},
  mainPageGame: null,
  userTeamsArr: [],
  porhibitedWordsArr: [],
  isTutorial: false,
  regionsList: [],

  // ************************************         Wang      ************************************ //
  gamesList: {},
  allPrivateChatRefs: {},
  roomsLastMessages: {},
  groupChatMembers: {},
  bannedUsers: [],
  // ************************************         Zhang     ************************************ //
  IS_HOP_SHADOW_ACTIVATED: true,
  recentListArr: [],
  friendGamesNumber: 0,
  friendFriendsNumber: 0,
  friendStatus: 'offline',
  viewFriendModel: new Userinformation(),
  friendRecentList: [],
  friendRatesList: new Map(),
  avgFriendRate: 0,
  friendHP: 0,

  // ################## FRIENDS HUB ##########################

  // Searched Friends List : MAP DATASTRUCTRE : FOR EXCHANGING DATA BETWEEN fridnsList and it's core

  // It takes the key : user key  and the value as a userinformation object
  friendsList: [],

  // ################## GAMES HUB ##########################
  // It takes the key : gameId and the value as a gameModel : It used to store all games from the server

  // All the games in array

  // ################### SAVED REQS Hub ###################
  // Saved req as a key and request model as a value
  savedRequestsList: new Map(),

  // ################### BLOCK  HUB ######################

  // requester id as a key and requester username as a value
  blockedList: new Map(),

  // #################### REQUEST SEARCH RESULT HUB ######################
  //
  searchReqResList: new Map(),

  // ################### CREATE REQUEST HUB ##################
  createdReq: new Requestmodel(),

  // ################## Lobby Hub ##########################
  // ُThis is the map used to store players inside the request , player key as a key and  player model as a value
  joinedPlayers: new Map(),
  isJoined: false,
  joinedPlayer: new PlayerModel(),

  // This attr will hold the second admin id in case there are more than one  player in the request
  secondAdminKey: '',
  // This attr will hold the second admin name in case there are more than one  player in the request
  secondAdminName: '',

  // ################# Main Menu Hub #######################
  isFromLogin: true,

  // ################ Chat HUB #########################
  // chat ref as a key and  UserChatRef as a value
  IS_IN_CHAT: false, // This attribute will be used to detect if the user in chat (public/clan/private) to disable chat local notifications

  // chat ref as a key and  UserChatRef as a value
  allGroupChatRefs: new Map(),

  //This arr will be used to update community chats in real time : For private chats
  communityChatsArr: [],

  //This arr will be used to update community chats in real time : For Public chats
  communityGroupChatsArr: [],

  // This attr will be used to only keep the number of accessing the setChatRef for chat group : not repeating group chat notifications
  membersLimit: 0,

  // Closed Connection groups
  // This map will be used to save closed connection groups to prevent loading false data from this particular group
  // group ref as key string and true or false as value detect if the chat is closed or opened
  // True : closed  , false : opened
  //  for now only save closed chats later we will save everything
  closedGroupChats: new Map(),

  //  This map will be used to store open connection group chats  : chat ref as a key and value
  groupChatConnections: new Map(),

  // IMPORTANT NOTE : ALL CHAT PACKET WILL BE DOWNLOADED ONCE THE USER ENTER THE CHAT

  // temp map contains packets , message id as key and packet as value
  // This arr will be used to update chat in real time  because of the subscribe to the Firebase link it will load  it  to the hub and then to this arr
  chatPacketsArray: [],
  privateChatPacketsArray: [],
  // This variable will hold the chat room ref : temp variable
  tempChatRoomRef: '',

  // This model will hold the current request that the user in
  currentRequest: '',

  // ################### Search requests results hub ############
  // This array is used to link the search request list with this one
  searchReqsResults: [],
  // This attr is used to count lazy loading for our array
  lazySearchResultCounter: 0,
  hootDetailReportMsg: '',
  showHootDetailReport: false,
};

export default handleActions(
  {
    [set]: (state, {payload}) => ({
      ...state,
      ..._.cloneDeep(payload),
    }),
    [logout]: (state) => ({
      ...defaultState,
    }),
  },
  defaultState,
);
