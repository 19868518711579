import React, { memo, Component } from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';



import { perfectSize } from '../../helpers/deviceHelper';
import Feather from 'react-native-vector-icons/Feather';

import ReplyDetail from './detail';
import Label from '../label';
import Postcore from '../../pages/post/postcore';
import AppStyles from '../../constants/styles';
import NavigationService from '../../navigation/navigationService';
import ImageWithPlaceholder from '../image-with-placeholder';

class Reply extends Component {
  getReplyLikesCount(reply) {
    if (reply._replies_likes_) {
      const obj = reply._replies_likes_;

      if (obj[reply.id]._likes_) {
        return Object.keys(obj[reply.id]._likes_).length;
      }
    }

    return 0;
  }

  getReplyDislikesCount(reply) {
    if (reply._replies_likes_) {
      const obj = reply._replies_likes_;

      if (obj[reply.id]._dislikes_) {
        return Object.keys(obj[reply.id]._dislikes_).length;
      }
    }

    return 0;
  }

  isLiked(reply) {
    if (reply._replies_likes_) {
      const obj = reply._replies_likes_;

      if (obj[reply.id]._likes_) {
        if (this.props.uid in obj[reply.id]._likes_) {
          return true;
        }
      }
    }

    return false;
  }

  isDisliked(reply) {
    if (reply._replies_likes_) {
      const obj = reply._replies_likes_;

      if (obj[reply.id]._dislikes_) {
        if (this.props.uid in obj[reply.id]._dislikes_) {
          return true;
        }
      }
    }

    return false;
  }

  thumbUp = () => {
    if (!this.props.item.delete) Postcore.thumbUpReply(this.props.item);
  };

  thumbDown = () => {
    if (!this.props.item.delete) Postcore.thumbDownReply(this.props.item);
  };

  render() {
    const { theme, showLikes, onDelete, showDelete } = this.props;
    const _styles = styles(theme);
    const item = this.props.item;
    console.log('images: ', item.images)
    return (
      <View
        style={[
          _styles.container,
          this.props.isAdmin && _styles.adminContainer,
        ]}>
        <View style={[_styles.detailContainer]}>
          <ReplyDetail
            style={_styles.detailCard}
            item={item}
            isAdmin={this.props.isAdmin}
            onDelete={onDelete}
            showDelete={showDelete}
          />
          {item.images && item.images.length > 0 && (
            <View style={_styles.imgContainer}>
              {item.images.map((image, index) => (
                <TouchableOpacity
                  style={{
                    width: item.images.length > 1 ? 300 : 250,
                    height: item.images.length > 2 ? 150 : 250,
                    padding: 1,
                  }}
                  onPress={() => {
                    NavigationService.navigate('PhotoViewerPage', {
                      TITLE: 'Hop Image',
                      IMGS: item.images,
                      INDEX: index,
                    });
                  }}
                  key={index}>
                  <ImageWithPlaceholder
                    uri={image}
                    style={_styles.imgFlex}
                    resizeMode="cover"
                  />
                </TouchableOpacity>
              ))}
              {item.images.length == 3 && (
                <View
                  style={{
                    width: item.images.length > 1 ? '50%' : '100%',
                    height: item.images.length > 2 ? '50%' : '100%',
                    padding: 1,
                  }}>
                </View>
              )}
            </View>
          )}
          {/* item={this.props.item} */}
          {showLikes && (
            <View style={_styles.footer}>
              <TouchableOpacity
                style={_styles.flexRow}
                onPress={() => this.thumbUp()}>
                <Label
                  size="small"
                  style={[
                    _styles.voteText,
                    this.isLiked(item) && _styles.highlightText,
                  ]}>
                  {this.getReplyLikesCount(item)}
                </Label>
                <Feather
                  name="thumbs-up"
                  color={this.isLiked(item) ? '#C70064' : 'grey'}
                  size={perfectSize(14)}
                />
              </TouchableOpacity>
              <TouchableOpacity
                style={[_styles.flexRow, { marginLeft: perfectSize(10) }]}
                onPress={() => this.thumbDown()}>
                <Label
                  size="small"
                  style={[
                    _styles.voteText,
                    this.isDisliked(item) && _styles.highlightText,
                  ]}>
                  {this.getReplyDislikesCount(item)}
                </Label>
                <Feather
                  name="thumbs-down"
                  color={this.isDisliked(item) ? '#C70064' : 'grey'}
                  size={perfectSize(14)}
                />
              </TouchableOpacity>
            </View>
          )}
        </View>

        <View style={_styles.divider} />
      </View>
    );
  }
}

Reply.propTypes = {
  data: PropTypes.object,
  isJoined: PropTypes.bool,
  onJoin: PropTypes.func,
  showLikes: PropTypes.bool,
  onDelete: PropTypes.func,
  showDelete: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
    uid: state.authReducer.uid,
  };
};

export default memo(connect(mapStateToProps)(Reply));

const styles = (theme) =>
  StyleSheet.create({
    container: {
      marginHorizontal: perfectSize(0.5),
      // borderRadius: perfectSize(12),
      paddingTop: perfectSize(8),
    },
    adminContainer: {
      backgroundColor: '#3B1935',
    },
    detailCard: {
      marginHorizontal: 0,
      overflow: 'hidden',
    },
    footer: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
      marginTop: perfectSize(8),
    },
    flexRow: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    highlightText: {
      color: theme.primary_btn_background,
    },
    voteText: {
      opacity: 0.56,
      marginRight: perfectSize(4),
    },
    thumb: {
      marginLeft: perfectSize(4),
    },
    comment: {
      width: perfectSize(12),
      height: perfectSize(12),
      marginLeft: perfectSize(4),
    },
    imgGroup: {
      width: perfectSize(17),
      height: perfectSize(14),
      marginRight: perfectSize(4),
    },
    joinButton: {},
    divider: {
      height: 1,
      backgroundColor: 'white',
      opacity: 0.05,
      marginTop: perfectSize(16),
    },
    detailContainer: {
      paddingHorizontal: perfectSize(16),
    },
    imgContainer: {
      height: 200,
      marginTop: perfectSize(12),
      flexDirection: 'row',
      flexWrap: 'wrap',
      borderRadius: perfectSize(20),
      overflow: 'hidden',
    },
    imgFlex: {
      width: '100%',
      height: '100%',
    },
  });
