import React, { Component } from 'react';
import { Image, TouchableOpacity, View } from 'react-native';
import ImageViewer from 'react-native-image-zoom-viewer';
import Feather from 'react-native-vector-icons/Feather';
import NavigationService from '../../navigation/navigationService';

import {
  handleAndroidBackButton,
  removeAndroidBackButtonHandler,
  perfectSize,
} from '../../helpers/deviceHelper';

import Label from '../../components/label';

import styles from './styles';
import { clearFastImageCache } from '../../services/utils';
import ImageWithPlaceholder from '../../components/image-with-placeholder';
import OriginImageWithPlaceholder from '../../components/image-with-placeholder/origin-image';

export default class PhotoViewerPage extends Component {

  constructor(props) {
    super(props);
    this.mount = true;
    this.state = {
      defaultBack: true,
      title: '',
      url: '',
      urls: [],
      index: -1,
      ready: false
    }
  }

  async componentDidMount() {
    const { navigation } = this.props;
    const defaultBack = navigation.getParam('defaultBack', true);
    handleAndroidBackButton(() => this.goBack(defaultBack));
    const title = navigation.getParam('TITLE', '');
    let url = navigation.getParam('IMG', '');
    let urls = navigation.getParam('IMGS', []);
    const index = navigation.getParam('INDEX', -1);
    if (url == null) {
      url = '';
    } else if (url !== '') {
      url = await this.getOriginUri(url)
    }
    const originUrls = []
    if (urls.length > 0) {
      for (const oUrl of urls) {
        const originUrl = await this.getOriginUri(oUrl)
        originUrls.push(originUrl);
      }
    }
    if (this.mount) {
      this.setState({
        defaultBack, title, url, urls: originUrls, index, ready: true
      })
    }
  }

  getOriginUri = async (uri) => {
    const thumbUri = uri;
    const splitBy = '?alt';
    const splitted = thumbUri.split(splitBy);
    const originUri = splitted[0] + '_origin' + splitBy + splitted[1];
    try {
      await Image.prefetch(originUri);
      return originUri;
    } catch (e) {
      return uri;
    }

  }

  componentWillUnmount() {
    removeAndroidBackButtonHandler();
    this.mount = false;
  }

  goBack = (defaultBack) => {
    clearFastImageCache();
    if (defaultBack) {
      NavigationService.goBack();
    } else {
      NavigationService.navigate('TabStack');
    }
  };

  render() {
    const {
      title,
      url,
      urls,
      defaultBack,
      index,
      ready
    } = this.state;
    return (
      <View style={styles.container}>
        {
          ready &&
          <ImageViewer
            imageUrls={
              urls.length > 0
                ? urls.map((url) => {
                  return { url };
                })
                : [{ url }]
            }
            renderImage={(props) => {
              const thumbUri = props.source.uri;
              return (
                <OriginImageWithPlaceholder
                  {...props}
                  style={styles.image}
                  uri={thumbUri}
                />
              );
            }}
            renderIndicator={(currentIndex) => null}
            index={index > -1 ? index : 0}
          />
        }


        <View style={styles.header}>
          <View style={styles.title}>
            <Label>{title}</Label>
          </View>
          <TouchableOpacity
            style={styles.backButton}
            onPress={() => this.goBack(defaultBack)}>
            <Feather
              name="chevron-left"
              color="gray"
              size={perfectSize(22.5)}
            />
          </TouchableOpacity>
        </View>
      </View>
    );
  }
}
