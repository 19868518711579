import React, {Component} from 'react';
import {View} from 'react-native';
import {compose} from 'redux';
import {connect} from 'react-redux';

// import components
import styles from './styles';
import {NO_INTERNET} from '../../constants/images';
import Label from '../../components/label';
import I18n from '../../i18n';
import LocalImageOpacity from '../../components/local-image-opacity';

class NoInternetConnection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentLanguage: 'en',
    };
  }

  componentDidMount = () => {};

  render() {
    const {theme} = this.props;
    return (
      <View style={[styles(theme).container]}>
        <LocalImageOpacity
          source={NO_INTERNET}
          style={styles(theme).iconSad}
          resizeMode="contain"
        />
        <Label style={styles(theme).labelShot}>OH SHOOOOT!!</Label>
        <Label style={styles(theme).label}>
          {I18n.t('NO_INTERNET_PAGE.msg')}
        </Label>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
    lang: state.appReducer.currentLang,
  };
};

export default compose(connect(mapStateToProps, {}))(NoInternetConnection);
