/* eslint-disable react-native/no-inline-styles */
import React, {Component} from 'react';
import {View, FlatList, TouchableOpacity} from 'react-native';
import {compose} from 'redux';
import {connect} from 'react-redux';
import firebase from 'firebase/app';
import 'firebase/database';

import Fontisto from 'react-native-vector-icons/Fontisto';
import Feather from 'react-native-vector-icons/Feather';
import I18n from '../../i18n';
import * as FirebasePaths from '../../constants/firebasePaths';
import {store} from '../../redux/store';

// import components
import Label from '../../components/label';
import UserAvatar from '../../components/user-avatar';
import Header from '../../components/header';
import {perfectSize} from '../../helpers/deviceHelper';
import NavigationService from '../../navigation/navigationService';
import Pipes from '../../services/pipes';

// import actions
import {showAlert} from '../../redux/modules/alert/actions';

import AppStyles from '../../constants/styles';
import styles from './styles';

class TeamJoinRequestsPage extends Component {
  constructor(props) {
    const params = props.navigation.state.params;
    super(props);
    this.state = {
      teamId: params.TEAM_ID,
      teamName: params.TEAM_NAME,
      teamLogo: params.TEAM_LOGO,
      teamAdmin: params.TEAM_ADMIN,
      joinReqsArr: [],
    };
  }

  componentDidMount() {
    // Log Rank HP
    global.rankLogger.LOG_NEW_HP(
      global.rankLogger.VIEW_JOIN_REQUESTS_PAGE,
      'VIEW_JOIN_REQUESTS_PAGE',
    );
    // Log the page view
    global.featuresLogger.LOG_TEAMS_TEAM_JOIN_REQUESTS_PAGE_VIEW();
    // Load Join Reqs
    this.loadJoinReqs();
  }

  componentWillUnmount() {
    if (this.joinReqsRef$) {
      this.joinReqsRef$.off();
      this.joinReqsRef$ = null;
    }
  }

  // This function will be used to load all the join reqs for this team  : Back End
  loadJoinReqs() {
    let path =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      store.getState().authReducer.uid +
      '/' +
      FirebasePaths.FIREBASE_USER_TEAMS +
      '/' +
      FirebasePaths.FIREBASE_USER_TEAMS_JOINS +
      '/' +
      this.state.teamId;

    // Now load all the objects
    this.joinReqsRef$ = firebase.database().ref(path);
    this.joinReqsRef$.on('value', (snapshot) => {
      let joinReqsArr = [];
      snapshot.forEach((element) => {
        joinReqsArr.push(element.val());
      });
      this.setState({joinReqsArr});
    });
  }

  // This function will redirect the user to the add members page
  toAddMembersPage = () => {
    const {teamId, teamName, teamLogo, teamAdmin} = this.state;
    NavigationService.navigate('AddTeamMemberPage', {
      TEAM_ID: teamId,
      TEAM_NAME: teamName,
      TEAM_LOGO: teamLogo,
      TEAM_ADMIN: teamAdmin,
    });
  };

  // This function will view the user profile
  viewUserProfile = (request) => {
    // Push the viewfriendpage and pass the friend key
    NavigationService.navigate('ViewfriendprofilePage', {
      FRIEND_KEY: request.uid,
    });
  };

  // Accept team join alert
  showJoinRequestAlert = (request, type) => {
    if (type === 'ACCEPT') {
      this.acceptAlert(request, type);
    } else {
      this.rejectAlert(request, type);
    }
  };

  // Accept alert
  acceptAlert(request, type) {
    let msg = I18n.t('TEAM_JOIN_REQUESTS_PAGE.confirm_accept_msg');
    let yes = I18n.t('TEAM_JOIN_REQUESTS_PAGE.confirm_accept_yes');
    let no = I18n.t('TEAM_JOIN_REQUESTS_PAGE.confirm_accept_no');

    this.props.showAlert([
      request.username,
      msg,
      [
        {
          text: yes,
          onPress: () => {
            // Back end work : add the user as a member in the team ref and delete the invitation from the admin : current user
            // And leave the accepted user a notification
            this.acceptJoinRequest(request);
            // Success message
            this.successOperationAlert('ACCEPTED');
          },
        },
        {text: no, onPress: () => {}},
      ],
    ]);
  }

  // Reject alert
  rejectAlert(request, type) {
    let msg = I18n.t('TEAM_JOIN_REQUESTS_PAGE.confirm_reject_msg');
    let yes = I18n.t('TEAM_JOIN_REQUESTS_PAGE.confirm_accept_yes');
    let no = I18n.t('TEAM_JOIN_REQUESTS_PAGE.confirm_accept_no');

    this.props.showAlert([
      request.username,
      msg,
      [
        {
          text: yes,
          onPress: () => {
            // Back end work : add the user as a member in the team ref and delete the invitation from the admin : current user
            // And leave the accepted user a notification
            this.rejectJoinRequest(request);
            // Success message
            this.successOperationAlert('REJECTED');
          },
        },
        {text: no, onPress: () => {}},
      ],
    ]);
  }

  // This function will add this user as a member to the team in the database : back end
  acceptJoinRequest(request) {
    // Add this user as a member
    let path =
      FirebasePaths.FIREBASE_TEAMS +
      '/' +
      this.state.teamId +
      '/' +
      FirebasePaths.FIREBASE_TEAM_MEMBERS +
      '/' +
      request.uid;
    // Create member obj
    let memberObj = {
      photo: request.userPhoto,
      username: request.username,
      uid: request.uid,
    };
    // Add obj
    firebase.database().ref(path).set(memberObj);

    // NOW remove the join req from the admin
    let adminPath =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      store.getState().authReducer.uid +
      '/' +
      FirebasePaths.FIREBASE_USER_TEAMS +
      '/' +
      FirebasePaths.FIREBASE_USER_TEAMS_JOINS +
      '/' +
      this.state.teamId +
      '/' +
      request.uid;
    firebase.database().ref(adminPath).remove();

    // Insert a team ref to the accepted user
    let userTeamRef =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      request.uid +
      '/' +
      FirebasePaths.FIREBASE_USER_TEAMS +
      '/' +
      FirebasePaths.FIREBASE_USER_TEAMS_REFS +
      '/' +
      this.state.teamId;
    let teamRefObj = {
      name: this.state.teamName,
      id: this.state.teamId,
      logo: this.state.teamLogo,
    };
    firebase.database().ref(userTeamRef).set(teamRefObj);

    // Insert a notification obj to the accepted user
    // Create a push id for the notification text
    let notificationId = firebase.database().ref().push().key;
    // Create a notification Obj
    let notificationObj = {
      id: notificationId,
      status: 'UNREAD',
      body: this.state.teamName + ' ' + ' قبلوا عرض انضمامك ',
      type: 'TEAM_ACCEPT',
      TRIGGER: this.state.teamId,
      timeStamp: firebase.database.ServerValue.TIMESTAMP,
    };

    let userNotificationsPath =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      request.uid +
      '/' +
      FirebasePaths.FIREBASE_USER_NOTIFICATIONS +
      '/' +
      notificationId;
    firebase.database().ref(userNotificationsPath).set(notificationObj);

    // Log Rank HP
    global.rankLogger.LOG_NEW_HP(
      global.rankLogger.ACCEPT_JOIN_REQUEST,
      'ACCEPT_JOIN_REQUEST',
    );

    // Log the accept join request
    global.featuresLogger.LOG_TEAMS_TEAM_ACCEPTS();
  }

  // reject join request  : BACK END
  rejectJoinRequest(request) {
    // NOW remove the join req from the admin
    let adminPath =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      store.getState().authReducer.uid +
      '/' +
      FirebasePaths.FIREBASE_USER_TEAMS +
      '/' +
      FirebasePaths.FIREBASE_USER_TEAMS_JOINS +
      '/' +
      this.state.teamId +
      '/' +
      request.uid;
    firebase.database().ref(adminPath).remove();

    // Insert a notification obj to the accepted user
    // Create a push id for the notification text
    let notificationId = firebase.database().ref().push().key;
    // Create a notification Obj
    let notificationObj = {
      id: notificationId,
      status: 'UNREAD',
      body: this.state.teamName + ' ' + ' رفضوا عرض انضمامك ! ',
      type: 'TEAM',
      TRIGGER: this.state.teamId,
      timeStamp: firebase.database.ServerValue.TIMESTAMP,
    };

    let userNotificationsPath =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      request.uid +
      '/' +
      FirebasePaths.FIREBASE_USER_NOTIFICATIONS +
      '/' +
      notificationId;
    firebase.database().ref(userNotificationsPath).set(notificationObj);

    // Log the accept join request
    global.featuresLogger.LOG_TEAMS_TEAM_REJECTS();
  }

  // show success operation alert
  successOperationAlert(type) {
    let msg;
    if (type === 'ACCEPTED') {
      msg = I18n.t('TEAM_JOIN_REQUESTS_PAGE.success_accept');
    } else {
      msg = I18n.t('TEAM_JOIN_REQUESTS_PAGE.success_reject');
    }

    this.props.showAlert(['😀', msg, [{text: 'OK', onPress: () => {}}]]);
  }

  renderItem = ({item, index}) => {
    const {theme} = this.props;
    const _styles = styles(theme);
    return (
      <View
        style={[
          _styles.itemContainer,
          this.state.joinReqsArr.length - 1 !== index && _styles.itemBorder,
        ]}>
        <TouchableOpacity onPress={() => this.viewUserProfile(item)}>
          <UserAvatar
            src={item.userPhoto}
            size={perfectSize(48)}
            borderColor="white"
          />
        </TouchableOpacity>
        <View style={{flex: 1, marginLeft: perfectSize(8)}}>
          <Label size="x_small">{item.username}</Label>
          <Label size="small" style={{color: '#95979A'}}>
            {Pipes.humantime(item.timestamp)}
          </Label>
        </View>
        <TouchableOpacity
          onPress={() => this.showJoinRequestAlert(item, 'ACCEPT')}>
          <Label size="x_small" style={_styles.pinkLabel}>
            {I18n.t('TEAM_JOIN_REQUESTS_PAGE.accept_btn')}
          </Label>
        </TouchableOpacity>
        <Label style={_styles.separator}>|</Label>
        <TouchableOpacity
          onPress={() => this.showJoinRequestAlert(item, 'REJECT')}>
          <Label size="x_small" style={_styles.pinkLabel}>
            {I18n.t('TEAM_JOIN_REQUESTS_PAGE.reject_btn')}
          </Label>
        </TouchableOpacity>
      </View>
    );
  };

  render() {
    const {theme} = this.props;
    const _styles = styles(theme);
    const {joinReqsArr} = this.state;
    return (
      <View style={AppStyles.rootContainer}>
        <Header text={I18n.t('TEAM_JOIN_REQUESTS_PAGE.title')} />
        <View style={AppStyles.rootContent}>
          <TouchableOpacity onPress={this.toAddMembersPage}>
            <View style={_styles.addBtn}>
              <Feather
                name="plus-circle"
                size={perfectSize(15)}
                color="white"
              />
              <Label size="x_small" style={{marginLeft: perfectSize(4)}}>
                {I18n.t('TEAM_JOIN_REQUESTS_PAGE.no_join_requests_btn')}
              </Label>
            </View>
          </TouchableOpacity>
          {joinReqsArr.length > 0 ? (
            <FlatList
              style={_styles.flatList}
              data={joinReqsArr}
              renderItem={(item) => this.renderItem(item)}
              keyExtractor={(item, index) => String(index)}
            />
          ) : (
            <View style={_styles.emptyContent}>
              <View style={AppStyles.emptyContent}>
                <Fontisto
                  name="expressionless"
                  size={perfectSize(60)}
                  color={theme.colors.primary}
                />
                <Label style={AppStyles.emptyContentLabel}>
                  {I18n.t('TEAM_JOIN_REQUESTS_PAGE.no_join_requests_msg')}
                </Label>
              </View>
            </View>
          )}
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
  };
};

export default compose(connect(mapStateToProps, {showAlert}))(
  TeamJoinRequestsPage,
);
