/* eslint-disable react-native/no-inline-styles */
import React, { Component } from 'react';
import { View, TouchableOpacity, SectionList, FlatList } from 'react-native';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Feather from 'react-native-vector-icons/Feather';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';
import { SwipeRow } from 'react-native-swipe-list-view';
import _ from 'lodash';

// import components
import Label from '../../components/label';
import TextInput from '../../components/text-input';
import Header from '../../components/header';
import PrimaryButton from '../../components/buttons/primary-button';

import I18n from '../../i18n';
import * as Images from '../../constants/images';
import AppStyles from '../../constants/styles';
import styles from './styles';
import { perfectSize } from '../../helpers/deviceHelper';

import gameManager from '../../services/gameManager';
import gameListCore from './gamelistcore';

import { set as setHub } from '../../redux/modules/hub/actions';
import { showAlert } from '../../redux/modules/alert/actions';
import { presentToast } from '../../helpers/toastHelper';
import NavigationService from '../../navigation/navigationService';
import notificationManagerCore from '../notification-manager/notification-manager-core';
import ImageWithPlaceholder from '../../components/image-with-placeholder';
import LocalImageOpacity from '../../components/local-image-opacity';
import { Placeholder, Progressive } from 'rn-placeholder';
import SkeletonUserAvatar from '../../components/skeletonWrapper/skeletonUserAvatar';
import SkeletonTextContent from '../../components/skeletonWrapper/skeletonTextContent';

class GamesListPage extends Component {
  constructor(props) {
    super(props);
    this.swipRef = new Array(React.createRef());
    this.state = {
      searchTerm: '',
      lazyLoadingCounter: 1,
      shouldLoadMore: false,
      originalGamesArray: [],
      allGamesArray: [],
      searchedAllGamesArray: [],
      addedGamesArray: [],
      USER_ACTION: false,

      extra_flag: false,
      isLoading: true,
    };
  }

  componentDidMount = () => {
    global.rankLogger.LOG_NEW_HP(
      global.rankLogger.VIEW_GAMES_PAGE,
      'VIEW_GAMES_PAGE',
    );
    this._initGamesList();
  };

  _initGamesList = () => {
    let _allGamesArray = Object.values(this.props.gamesList);
    let _addedGamesArray = [];
    _allGamesArray.forEach((game) => {
      if (gameManager.checkIfHasGameById(game.gameID)) {
        _addedGamesArray.push(game);
      }
    });

    let _searchedAllGamesArray = [];
    if (_allGamesArray.length > 20) {
      _searchedAllGamesArray = _allGamesArray.slice(
        0,
        this.state.lazyLoadingCounter * 20,
      );
    }
    this.setState({
      addedGamesArray: _addedGamesArray,
      searchedAllGamesArray: _searchedAllGamesArray,
      originalGamesArray: _allGamesArray,
    });
    const preloadImgs = _addedGamesArray.slice(0, 7);
    const preloadUris = [];
    preloadImgs.map(img => {
      if (img.gamePhotoUrl) {
        return preloadUris.push({ uri: img.gamePhotoUrl })
      }
    });
    setTimeout(() => {
      this.setState({
        isLoading: false,
      })
    }, 1000);
    _allGamesArray.sort(function (a, b) {
      const nameA = a.gameName.toUpperCase();
      const nameB = b.gameName.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
    this.setState({ allGamesArray: _allGamesArray });
  };

  searchGameTrigger = (searchTerm) => {
    let _searchedAllGamesArray = [];
    if (searchTerm !== '') {
      if (this.state.allGamesArray.length > 0) {
        let _addedGamesArray = this.state.addedGamesArray.filter(function (
          item,
        ) {
          return (
            item.gameName
              .toString()
              .toLowerCase()
              .indexOf(searchTerm.toString().toLowerCase()) > -1 ||
            (item.tags !== undefined && typeof item.tags !== 'object'
              ? item.tags
                .toString()
                .toLowerCase()
                .indexOf(searchTerm.toString().toLowerCase()) > -1
              : false)
          );
        });
        this.setState({ addedGamesArray: _addedGamesArray });
        _searchedAllGamesArray = this.state.allGamesArray.filter(function (
          item,
        ) {
          return (
            item.gameName
              .toString()
              .toLowerCase()
              .indexOf(searchTerm.toString().toLowerCase()) > -1 ||
            (item.tags !== undefined && typeof item.tags !== 'object'
              ? item.tags
                .toString()
                .toLowerCase()
                .indexOf(searchTerm.toString().toLowerCase()) > -1
              : false)
          );
        });
        this.setState({
          searchedAllGamesArray: _searchedAllGamesArray,
        });
      }
    } else {
      this._initGamesList();
    }
  };

  onChangeText = (val) => {
    // this.setState({ searchTerm: val });
    this.searchGameTrigger(val);
  };

  addGameTrigger = (gameId) => {
    if (!gameManager.checkIfHasGameById(gameId)) {
      let gameModel = {};
      gameModel = this.props.gamesList[gameId];

      const _addedGamesArray = this.state.addedGamesArray;
      _addedGamesArray.push(gameModel);
      this.props.setHub({ addedGamesArray: _addedGamesArray });
      gameListCore.addGame(gameModel);
      this.setState({ extra_flag: !this.state.extra_flag });
      global.rankLogger.LOG_NEW_HP(global.rankLogger.ADD_GAME, 'ADD_GAME');
      global.featuresLogger.LOG_GAMES_ADD_GAME();

      let activiatedGamesNotificationArr = this.props
        .activiatedGamesNotificationArr;
      if (!activiatedGamesNotificationArr.includes(gameId)) {
        activiatedGamesNotificationArr.push(gameId);
      }

      this.props.setHub({
        activiatedGamesNotificationArr,
      });

      let deactiviatedGamesNotificationArr = this.props.deactiviatedGamesNotificationArr.filter(
        (item) => item !== gameId,
      );

      this.props.setHub({
        deactiviatedGamesNotificationArr,
      });
      notificationManagerCore.activateGameNotifications(gameModel);

      presentToast({
        message: I18n.t('GAME_LIST_PAGE.success_add'),
      });
    }
  };

  doInfinite = () => {
    if (this.state.searchTerm !== '') {
      return;
    }
    const { originalGamesArray, searchedAllGamesArray } = this.state;
    if (originalGamesArray.length === searchedAllGamesArray.length) {
      return;
    }
    setTimeout(() => {
      let arr = searchedAllGamesArray.concat(
        originalGamesArray.slice(
          searchedAllGamesArray.length,
          searchedAllGamesArray.length + 20,
        ),
      );
      this.setState({ searchedAllGamesArray: arr });
    }, 500);
  };

  _toContactUsPage = () => {
    NavigationService.moveToScreenInStack('SettingsStack', 'ContactUsPage');
  };

  _toMainMenu = () => {
    this.props.setHub({
      isTutorial: false,
      GLOBAL_GAME_FROM: 'GAME_LIST_TUTORIAL',
    });
    NavigationService.resetRoute('TabStack');
  };

  _deleteGame = (gameModel) => {
    global.rankLogger.LOG_NEW_HP(global.rankLogger.DELETE_GAME, 'DELETE_GAME');
    global.featuresLogger.LOG_GAMES_DELETE_GAME();
    gameListCore.deleteGameRef(gameModel.gameID);
    gameManager.deleteGame(gameModel.gameID, gameModel.gameName);
    let _addedGamesArray = this.state.addedGamesArray;
    _addedGamesArray = _addedGamesArray.filter((game) => {
      return game.gameID !== gameModel.gameID;
    });
    this.setState({ addedGamesArray: _addedGamesArray });
    this.props.setHub({ addedGamesArray: _addedGamesArray });

    let activiatedGamesNotificationArr = this.props.activiatedGamesNotificationArr.filter(
      (item) => item !== gameModel.gameID,
    );

    this.props.setHub({
      activiatedGamesNotificationArr,
    });

    let deactiviatedGamesNotificationArr = this.props
      .deactiviatedGamesNotificationArr;
    if (!deactiviatedGamesNotificationArr.includes(gameModel.gameID)) {
      deactiviatedGamesNotificationArr.push(gameModel.gameID);
    }

    this.props.setHub({
      deactiviatedGamesNotificationArr,
    });

    notificationManagerCore.deactivateGameNotifications(gameModel);

    presentToast({
      message:
        gameModel.gameName + ' ' + I18n.t('GAME_LIST_PAGE.success_delete'),
    });
  };

  closeRow(rowMap, rowKey) {
    if (rowMap[rowKey]) {
      rowMap[rowKey].closeRow();
    }
  }

  render() {
    const { theme } = this.props;
    const _styles = styles(theme);
    const { isLoading, addedGamesArray, searchedAllGamesArray, searchTerm } = this.state;
    let sections = [];
    if (addedGamesArray.length > 0) {
      sections.push({
        title: I18n.t('GAME_LIST_PAGE.your_games'),
        data: addedGamesArray,
        type: 1,
      });
    }

    let _otherGames = searchedAllGamesArray;
    _otherGames = _otherGames.filter((game) => {
      return (
        this.props.allGamesIds.filter((e) => e.gameID === game.gameID)
          .length === 0
      );
      // return !this.props.allGamesIds.has(game.gameID);
    });

    sections.push({
      title: I18n.t('GAME_LIST_PAGE.other_games'),
      data: _otherGames,
      type: 2,
    });

    return (
      <View style={AppStyles.rootContainer}>
        <Header
          text={
            this.props.isTutorial
              ? I18n.t('GAME_LIST_PAGE.what_question')
              : I18n.t('GAME_LIST_PAGE.games_library')
          }
        />
        <View style={[AppStyles.rootContent]}>
          <Label size="x_small" style={AppStyles.grayLabel}>
            {I18n.t('GAME_LIST_PAGE.notification_message')}
          </Label>
          <TextInput
            placeholder={I18n.t('GAME_LIST_PAGE.search')}
            rightComponent={
              <Feather name="search" size={perfectSize(20)} color="gray" />
            }
            customStyles={_styles.mt16}
            // value={searchTerm}
            onChangeText={_.debounce(
              (val) => this.onChangeText(val),
              1000,
            )}
          />
          <View
            style={[
              _styles.mt16,
              {
                flex: 1,
                justifyContent: 'center',
              },
            ]}>
            {
              isLoading &&
              <FlatList
                data={[0, 1, 2, 3, 4, 5, 6, 7]}
                keyExtractor={(item) => item.toString()}
                showsVerticalScrollIndicator={false}
                renderItem={(item) => {
                  return <View>
                    <Placeholder
                      Animation={Progressive}
                    >
                      <View >
                        <View style={{
                          paddingVertical: perfectSize(16),
                          flexDirection: 'row',
                        }}>
                          <SkeletonUserAvatar size={72} isRound={false} style={{ borderRadius: 12 }} />
                          <View style={{
                            marginLeft: perfectSize(8),
                            flex: 1,
                            justifyContent: 'center',
                          }}>
                            <View style={{
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                            }}>
                              <SkeletonTextContent height={15} width={50} />
                              <SkeletonTextContent height={15} width={30} />
                            </View>
                            <View style={{ flex: 1 }}>
                              <SkeletonTextContent height={15} width={60} style={{ marginTop: 8 }} />
                              <SkeletonTextContent height={15} width={60} style={{ marginTop: 8 }} />
                            </View>
                          </View>
                        </View>
                        <View style={{ height: 1, widht: '100%' }} />
                      </View>
                    </Placeholder>
                  </View>
                }}
              />
            }
            {!isLoading && (addedGamesArray.length > 0 ||
              searchedAllGamesArray.length > 0) && (
                <SectionList
                  sections={sections}
                  extraData={this.state.extra_flag}
                  stickySectionHeadersEnabled={false}
                  showsVerticalScrollIndicator={false}
                  renderItem={({ item, index }) => (
                    <>
                      {this.props.allGamesIds.filter(
                        (e) => e.gameID === item.gameID,
                      ).length > 0 && (
                          <SwipeRow
                            disableRightSwipe
                            rightOpenValue={-perfectSize(75)}
                            onRowOpen={(position) => {
                              this.swipRef.forEach((swipeRef, _index) => {
                                if (index !== _index) {
                                  if (swipeRef) {
                                    swipeRef.closeRow();
                                  }
                                }
                              });
                            }}
                            ref={(ref) => (this.swipRef[index] = ref)}>
                            <TouchableOpacity
                              style={[
                                _styles.backRightBtn,
                                _styles.backRightBtnRight,
                              ]}
                              onPress={() => {
                                this.props.showAlert([
                                  I18n.t('GAME_LIST_PAGE.delete_game'),
                                  I18n.t('GAME_LIST_PAGE.delete_game') +
                                  ' ' +
                                  item.gameName,
                                  [
                                    {
                                      text: I18n.t('GAME_LIST_PAGE.yes'),
                                      onPress: () => {
                                        this._deleteGame(item);
                                        if (this.swipRef[index]) {
                                          this.swipRef[index].closeRow();
                                        }
                                      },
                                    },
                                    {
                                      text: I18n.t('GAME_LIST_PAGE.no'),
                                      onPress: () => {
                                        if (this.swipRef[index]) {
                                          this.swipRef[index].closeRow();
                                        }
                                      },
                                    },
                                  ],
                                ]);
                              }}>
                              <MaterialIcons
                                name="delete"
                                color="white"
                                size={perfectSize(20)}
                              />
                              <Label
                                style={[
                                  _styles.backTextWhite,
                                  { textTransform: 'uppercase' },
                                ]}>
                                {I18n.t('GAME_LIST_PAGE.delete')}
                              </Label>
                            </TouchableOpacity>
                            <View
                              style={[
                                _styles.itemContainer,
                                { backgroundColor: theme.primary_background },
                              ]}>
                              <ImageWithPlaceholder
                                uri={item.gamePhotoUrl}
                                style={_styles.avatar}
                              />
                              <View style={{ flexDirection: 'row', flex: 1 }}>
                                <View
                                  style={[
                                    _styles.itemRightContainer,
                                    { backgroundColor: 'transparent' },
                                  ]}>
                                  <Label size="medium" style={_styles.fontUbuntu}>
                                    {item.gameName}
                                  </Label>
                                  <Label
                                    size="x_small"
                                    style={{
                                      marginTop: perfectSize(4),
                                      opacity: 0.6,
                                      textAlign: 'left',
                                    }}>
                                    {I18n.t('GAME_LIST_PAGE.available_platforms')}
                                  </Label>
                                  <View
                                    style={{
                                      flexDirection: 'row',
                                    }}>
                                    <LocalImageOpacity
                                      source={
                                        item.gamePlatforms.includes('PS')
                                          ? Images.PSICON
                                          : Images.PSICONG
                                      }
                                      style={_styles.availablePlatform}
                                      resizeMode="contain"
                                    />

                                    <LocalImageOpacity
                                      source={
                                        item.gamePlatforms.includes('PC')
                                          ? Images.PCICON
                                          : Images.PCICONG
                                      }
                                      style={_styles.availablePlatform}
                                      resizeMode="contain"
                                    />

                                    <LocalImageOpacity
                                      source={
                                        item.gamePlatforms.includes('XBOX')
                                          ? Images.XBOXICON
                                          : Images.XBOXICONG
                                      }
                                      style={_styles.availablePlatform}
                                      resizeMode="contain"
                                    />

                                    <LocalImageOpacity
                                      source={
                                        item.gamePlatforms.includes('NINTENDO')
                                          ? Images.SWITCHICON
                                          : Images.SWITCHICONG
                                      }
                                      style={_styles.availablePlatform}
                                      resizeMode="contain"
                                    />

                                    <LocalImageOpacity
                                      source={
                                        item.gamePlatforms.includes('MOBILE')
                                          ? Images.MOBILEICON
                                          : Images.MOBILEICONG
                                      }
                                      style={_styles.availablePlatform}
                                      resizeMode="contain"
                                    />
                                  </View>
                                </View>
                                <TouchableOpacity
                                  onPress={() => {
                                    this.props.showAlert([
                                      I18n.t('GAME_LIST_PAGE.delete_game'),
                                      I18n.t('GAME_LIST_PAGE.delete_game') +
                                      ' ' +
                                      item.gameName,
                                      [
                                        {
                                          text: I18n.t('GAME_LIST_PAGE.yes'),
                                          onPress: () => {
                                            this._deleteGame(item);
                                            if (this.swipRef[index]) {
                                              this.swipRef[index].closeRow();
                                            }
                                          },
                                        },
                                        {
                                          text: I18n.t('GAME_LIST_PAGE.no'),
                                          onPress: () => {
                                            if (this.swipRef[index]) {
                                              this.swipRef[index].closeRow();
                                            }
                                          },
                                        },
                                      ],
                                    ]);
                                  }}>
                                  <Label style={_styles.btnAdd}>
                                    -{I18n.t('GAME_LIST_PAGE.remove')}
                                  </Label>
                                </TouchableOpacity>
                              </View>
                            </View>
                          </SwipeRow>
                        )}
                      {!(
                        this.props.allGamesIds.filter(
                          (e) => e.gameID === item.gameID,
                        ).length > 0
                      ) && (
                          <View style={_styles.itemContainer}>
                            <ImageWithPlaceholder
                              uri={item.gamePhotoUrl}
                              style={_styles.avatar}
                            />
                            <View style={_styles.itemRightContainer}>
                              <Label size="medium" style={_styles.fontUbuntu}>
                                {item.gameName}
                              </Label>
                              <Label
                                size="x_small"
                                style={{
                                  marginTop: perfectSize(4),
                                  opacity: 0.6,
                                  textAlign: 'left',
                                }}>
                                {I18n.t('GAME_LIST_PAGE.available_platforms')}
                              </Label>
                              <View
                                style={{
                                  flexDirection: 'row',
                                }}>
                                <LocalImageOpacity
                                  source={
                                    item.gamePlatforms.includes('PS')
                                      ? Images.PSICON
                                      : Images.PSICONG
                                  }
                                  style={_styles.availablePlatform}
                                  resizeMode="contain"
                                />

                                <LocalImageOpacity
                                  source={
                                    item.gamePlatforms.includes('PC')
                                      ? Images.PCICON
                                      : Images.PCICONG
                                  }
                                  style={_styles.availablePlatform}
                                  resizeMode="contain"
                                />

                                <LocalImageOpacity
                                  source={
                                    item.gamePlatforms.includes('XBOX')
                                      ? Images.XBOXICON
                                      : Images.XBOXICONG
                                  }
                                  style={_styles.availablePlatform}
                                  resizeMode="contain"
                                />

                                <LocalImageOpacity
                                  source={
                                    item.gamePlatforms.includes('NINTENDO')
                                      ? Images.SWITCHICON
                                      : Images.SWITCHICONG
                                  }
                                  style={_styles.availablePlatform}
                                  resizeMode="contain"
                                />

                                <LocalImageOpacity
                                  source={
                                    item.gamePlatforms.includes('MOBILE')
                                      ? Images.MOBILEICON
                                      : Images.MOBILEICONG
                                  }
                                  style={_styles.availablePlatform}
                                  resizeMode="contain"
                                />
                              </View>
                            </View>
                            <TouchableOpacity
                              onPress={() => {
                                this.addGameTrigger(item.gameID);
                              }}>
                              <Label style={_styles.btnAdd}>
                                +{I18n.t('VIEW_FRIEND_PROFILE_PAGE.add_friend')}
                              </Label>
                            </TouchableOpacity>
                          </View>
                        )}
                    </>
                  )}
                  renderSectionHeader={({ section }) => (
                    <Label size="x_small" bold={true} style={_styles.mv16}>
                      {section.title}
                    </Label>
                  )}
                  keyExtractor={(item, index) => index}
                  onEndReachedThreshold={0.5}
                  onEndReached={this.doInfinite}
                  ListFooterComponent={() => {
                    return <View style={AppStyles.contentFotter} />;
                  }}
                />
              )}

            {this.state.addedGamesArray.length === 0 &&
              this.state.searchedAllGamesArray.length === 0 && (
                <View style={AppStyles.noGameWrap}>
                  {/* <FastImage
                    source={
                      theme.mode === 'dark'
                        ? Images.SUPPORT_LOADING_DARK
                        : Images.SUPPORT_LOADING
                    }
                    style={AppStyles.supportLoading}
                    resizeMode="contain"
                  /> */}
                  <Label style={AppStyles.txtNoGame}>
                    {I18n.t('GAME_LIST_PAGE.no_games')}
                  </Label>
                  <PrimaryButton
                    text={I18n.t('GAME_LIST_PAGE.go_support')}
                    type="filled"
                    size="x_small"
                    customStyles={_styles.btnAction}
                    onPress={this._toContactUsPage}
                  />
                </View>
              )}
          </View>
          {this.props.isTutorial && (
            <PrimaryButton
              text={I18n.t('GAME_LIST_PAGE.done')}
              type="filled"
              size="x_small"
              customStyles={_styles.doneBtn}
              onPress={this._toMainMenu}
            />
          )}
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
    gamesList: state.hubReducer.gamesList,
    allGamesIds: state.gameManagerReducer.allGamesIds,
    isTutorial: state.hubReducer.isTutorial,
    activiatedGamesNotificationArr:
      state.hubReducer.activiatedGamesNotificationArr,
    deactiviatedGamesNotificationArr:
      state.hubReducer.deactiviatedGamesNotificationArr,
  };
};

export default compose(connect(mapStateToProps, { setHub, showAlert }))(
  GamesListPage,
);
