import firebase from 'firebase/app';
import 'firebase/database';

import * as FirebasePaths from '../../constants/firebasePaths';
import {store} from '../../redux/store';

// import actions
import {set as HubSet} from '../../redux/modules/hub/actions';

export default class Newrequestcore {
  // This funtion will check if this user is banned of making new requests

  static IS_CREATE_REQUEST_BANNED(callback) {
    let path =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      store.getState().authReducer.uid +
      '/' +
      FirebasePaths.FIREBASE_USER_BAN +
      '/' +
      FirebasePaths.FIREBASE_USER_BAN_CREATE_REQUEST;

    firebase.database()
      .ref(path)
      .once('value', (snapshot) => {
        if (snapshot.val() != null) {
          callback(true);
        } else {
          callback(false);
        }
      });
  }

  static deleteSavedReq(savedReqKey) {
    // Getting the user path

    let uid = store.getState().authReducer.uid;

    // _users_info / uid / _games_ / _games_ / _saved_requests_

    let savedRequestsPath =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      uid +
      '/' +
      FirebasePaths.FIREBASE_GAMES_REFERENCES +
      '/' +
      FirebasePaths.FIREBASE_SAVED_REQS_ATTR;

    let savedRequestPath =
      savedRequestsPath +
      '/' +
      FirebasePaths.FIREBASE_SAVED_REQUESTS_REQUESTS_ATTR +
      '/' +
      savedReqKey;

    // // Delete it from firebase

    firebase.database().ref(savedRequestPath).remove();

    // // Delete it from hub
    let savedRequestsList = store.getState().hubReducer.savedRequestsList;
    savedRequestsList.delete(savedReqKey);
    store.dispatch(
      HubSet({
        savedRequestsList: savedRequestsList,
      }),
    );

    // Update count

    // Setting the count of saved requests

    firebase.database()
      .ref(savedRequestsPath + '/' + 'count')
      .set(savedRequestsList.size);
  }
}
