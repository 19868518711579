import Gamemodel from './gamemodel';

export default class Recentgamemodel extends Gamemodel {
  constructor() {
    super();

    this.recentKey = '';
    this.gameId = '';
    this.gameName = '';
    this.gamePhotoUrl = '';
    this.supportedPlatforms = '';
    this.activityDescription = '';
    this.timeStamp;
    this.matchType = '';

    this.gameType = '';
  }
}
