import {StyleSheet} from 'react-native';
import {perfectSize} from '../../helpers/deviceHelper';

const styles = (theme) =>
  StyleSheet.create({
    itemContainer: {
      marginBottom: perfectSize(16),
      alignItems: 'center',
      width: '33.33%',
    },
  });

export default styles;
