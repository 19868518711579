import {store} from '../redux/store';
import firebase from 'firebase/app';
import 'firebase/database';
import * as FirebasePaths from '../constants/firebasePaths';
import Requestmodel from '../models/requestmodel';
import PlayerModel from '../models/playersmodel';
import Requestmodelreference from '../models/requestmodelreference';
import Recentgamemodel from '../models/recentgamemodel';
import {set as HubSet} from '../redux/modules/hub/actions';
import {getGameByNameFromGameList} from '../services/utils';

export default class Request {
  constructor() {}

  addRequest(
    requestKey,
    platform,
    gameName,
    matchType,
    region,
    numberOfPlayers,
    rank,
    requestTime,
    description,
    IS_HOPPED_REQUEST,
  ) {
    let gameModel = getGameByNameFromGameList(gameName);

    let uid = store.getState().authReducer.uid;

    // _useres_info -> uid

    let userRef = FirebasePaths.FIREBASE_USERS_INFO_ATTR + '/' + uid;

    // _requests_ > platform > gameId > region

    let requestsRef =
      FirebasePaths.FB_REQUESTS_REFERENCE +
      '/' +
      platform.toString().toUpperCase() +
      '/' +
      gameModel.gameID +
      '/' +
      region;

    // Setting the request admin

    let requestAdmin = uid;

    // _requests_ -> platform -> GameID -> Region -> addRequestToFirebase id

    let requestRef = requestsRef + '/' + requestKey;

    // set the addRequestToFirebase info under the requests tree

    // + unary operation will convert string to number

    let playersNumber =
      numberOfPlayers == 'All Numbers'
        ? gameModel.maxPlayers
        : +numberOfPlayers;

    // Fill request model

    let requestModel = new Requestmodel();
    requestModel.platform = platform;
    requestModel.requestTitle = gameName;
    requestModel.admin = requestAdmin;
    requestModel.description = description;
    requestModel.region = region;
    requestModel.playersNumber = playersNumber;
    requestModel.matchType = matchType;
    requestModel.rank = rank;
    requestModel.currentPlayersNumber = 1;
    requestModel.requestTime = requestTime;

    // The current players number will be set it directly

    // Fill this player with uid and username and Add this player to the request

    let playerModel = new PlayerModel();
    playerModel.uid = store.getState().authReducer.userinformationDB.UID;
    playerModel.username = store.getState().authReducer.userinformationDB.username;
    requestModel.adminName = store.getState().authReducer.userinformationDB.username;
    requestModel.gameId = gameModel.gameID;
    requestModel.requestId = requestKey;
    requestModel.requestPicture = gameModel.gamePhotoUrl;

    // Fill the providers account for the admin

    requestModel.PSN_account =
      store.getState().authReducer.userinformationDB.PSNAcc.length > 1
        ? store.getState().authReducer.userinformationDB.PSNAcc
        : 'PSN_account';

    requestModel.XBOX_live_account =
      store.getState().authReducer.userinformationDB.XboxLiveAcc.length > 1
        ? store.getState().authReducer.userinformationDB.XboxLiveAcc
        : 'XBOX_live_account';

    requestModel.pcProviderAccount =
      store
        .getState()
        .authReducer.userinformationDB.pcGamesAccs.get(
          gameModel.pcGameProvider,
        ) != null
        ? store
            .getState()
            .authReducer.userinformationDB.pcGamesAccs.get(
              gameModel.pcGameProvider,
            )
        : 'pcProviderAccount';

    // Log New Request

    global.featuresLogger.LOG_NEW_REQUEST();

    // --------------------------------------------

    this.setUserReference(
      userRef,
      requestKey,
      requestModel.matchType,
      gameModel.gameID,
      gameModel.gameType,
      platform,
      region,
    );

    // ------------------------------------------

    // Set request model in firebase

    firebase.database().ref(requestRef).set(requestModel);
    // Update children used here  in native android : We will use set instead

    firebase.database()
      .ref(requestRef + '/' + FirebasePaths.FIREBASE_REQUEST_TIME_STAMP_ATTR)
      .set(firebase.database.ServerValue.TIMESTAMP);

    // Add this request to the hub to load  it in the lobby

    store.dispatch(
      HubSet({
        createdReq: requestModel,
        currentRequest: requestModel,
      }),
    );

    firebase.database()
      .ref(FirebasePaths.FIREBASE_REQUESTS_HUB + '/' + requestModel.requestId)
      .set(requestModel);

    firebase.database()
      .ref(
        FirebasePaths.FIREBASE_REQUESTS_HUB +
          '/' +
          requestModel.requestId +
          '/timeStamp',
      )
      .set(firebase.database.ServerValue.TIMESTAMP);

    // Send a notification for all the followers by publishing the request

    firebase.database()
      .ref(
        FirebasePaths.FIREBASE_TEAMS_NOTIFICATIONS +
          '/' +
          requestModel.gameId +
          '/' +
          FirebasePaths.FIREBASE_TEAMS_NOTIFICATIONS_NEW_REQUEST,
      )
      .set(requestModel);

    // Check if this is a hopped request

    if (IS_HOPPED_REQUEST) {
      this.addRequestToHops(
        requestModel.description,
        requestModel.requestId,
        requestModel.requestPicture,
        requestModel.requestTitle,
        requestModel.platform,
        requestModel.region,
        requestModel.gameId,
        requestModel.matchType,
      );

      // Call the features logger

      global.featuresLogger.HOPPED_REQUEST_USAGE();
    }

    return requestModel;
  }

  // This function will share the request with the hops timeline

  addRequestToHops(
    description,
    requestKey,
    requestImg,
    requestTitle,
    requestPlatform,
    requestRegion,
    requestGame,
    matchType,
  ) {
    let post = {
      requestId: requestKey,
      requestDescription: description,
      requestTitle: requestTitle,
      type: 'REQUEST',
      requestImg: requestImg,
      requestTimeStamp: firebase.database.ServerValue.TIMESTAMP,
      admin: store.getState().authReducer.uid,
      adminName: store.getState().authReducer.userinformationDB.username,
      platform: requestPlatform,
      region: requestRegion,
      gameId: requestGame,
      matchType: matchType,
    };

    // RANK HERE : User rank

    post.userRank = store.getState().authReducer.userinformationDB.rank;

    // Upload post

    // Getting the path

    let ref = FirebasePaths.FIREBASE_TIMELINE + '/' + post.requestId;

    // Insert the object to the timeline

    firebase.database().ref(ref).set(post);
  }

  // This function will set request in user ref

  setUserReference(
    userRef,
    requestId,
    requestType,
    gameId,
    gameType,
    platform,
    region,
  ) {
    let currentId = store.getState().authReducer.uid;

    // getting the request Ref for user

    let requestRef = userRef + '/' + FirebasePaths.FIREBASE_USER_REQUESTS_ATTR;

    // getting the Recent activity ref

    // users_info_ -> user id  -> _games_->_recent_played_

    let userRecentPlayedRef =
      userRef + '/' + FirebasePaths.FIREBASE_RECENT_GAMES_PATH;

    // Create

    let requestModelReference = new Requestmodelreference();

    // Fill

    requestModelReference.requestId = requestId;
    requestModelReference.gameId = gameId;
    requestModelReference.platform = platform;
    requestModelReference.region = region;

    // Generate recent played key

    let recentPlayedKey = firebase.database().ref().push().key;

    // Set the ref with key

    let recentGameRef = userRecentPlayedRef + '/' + recentPlayedKey;

    // Create recent activity model , fill it , and finally insert it in the hub

    let recentActivity = new Recentgamemodel();
    recentActivity.gameId = gameId;
    recentActivity.gameType = gameType;
    recentActivity.supportedPlatforms = platform;
    recentActivity.matchType = requestType;
    recentActivity.timeStamp = firebase.database.ServerValue.TIMESTAMP;
    recentActivity.recentKey = recentPlayedKey;
    recentActivity.key = recentPlayedKey;
    recentActivity.requestId = requestId;
    recentActivity.region = region;

    // We supposed to create map and push those data from map to recent ref , but here we will push it directly

    firebase.database()
      .ref(recentGameRef + '/' + FirebasePaths.FIREBASE_RECENT_GAME_ID_ATTR)
      .set(gameId);

    firebase.database()
      .ref(recentGameRef + '/' + FirebasePaths.FIREBASE_RECENT_GAME_TYPE_ATTR)
      .set(gameType);

    firebase.database()
      .ref(recentGameRef + '/' + FirebasePaths.FIREBASE_RECENT_REQUEST_ID)
      .set(requestId);

    firebase.database()
      .ref(
        recentGameRef +
          '/' +
          FirebasePaths.FIREBASE_RECENT_REQUEST_PLATFORM_ATTR,
      )
      .set(platform);

    firebase.database()
      .ref(recentGameRef + '/' + FirebasePaths.FIREBASE_RECENT_REGION)
      .set(region);

    // NOTE : request type is  == MatchType

    firebase.database()
      .ref(recentGameRef + '/' + FirebasePaths.FIREBASE_RECENT_MATCH_TYPE_ATTR)
      .set(requestType);

    // Get exactly the same timestamp of object creating

    firebase.database()
      .ref(recentGameRef + '/' + FirebasePaths.FIREBASE_REQUEST_TIME_STAMP_ATTR)
      .set(recentActivity.timeStamp);

    let recentListArr = store.getState().hubReducer.recentListArr;
    // insert it to the hub

    recentListArr.push(recentActivity);
    store.dispatch(
      HubSet({
        recentListArr,
      }),
    );

    // Set request ref  to user

    firebase.database().ref(requestRef).set(requestModelReference);
  }
}
