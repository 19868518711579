import {createAction} from 'redux-actions';
import * as types from './actionTypes';
import {SET, LOGOUT, CLEAR} from './actionTypes';

export const set = createAction(SET);
export const addGame = createAction(types.ADD_GAME.REQUEST);
export const addGameSuccess = createAction(types.ADD_GAME.SUCCESS);

export const addAllGame = createAction(types.ADD_ALL_GAME.REQUEST);
export const addAllGameSuccess = createAction(types.ADD_ALL_GAME.SUCCESS);

export const deleteGame = createAction(types.DELETE_GAME.REQUEST);
export const deleteGameSuccess = createAction(types.DELETE_GAME.SUCCESS);
export const isCompetitive = createAction(types.IS_COMPETITIVE.REQUEST);
export const isCompetitiveSuccess = createAction(types.IS_COMPETITIVE.SUCCESS);

export const clear = createAction(CLEAR)
export const logout = createAction(LOGOUT);
