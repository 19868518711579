import * as FirebasePaths from '../../constants/firebasePaths';
import firebase from 'firebase/app';
import 'firebase/database';

import {store} from '../../redux/store';

// import actions
import {setUserInfo} from '../../redux/modules/auth/actions';
import {set as setHub} from '../../redux/modules/hub/actions';

import Packet from '../../models/packet';
import Requestmodel from '../../models/recentgamemodel';
import Requestmodelreference from '../../models/requestmodelreference';

export default class Lobbycore {
  // This function will check if the user is banned from joining requests
  static IS_JOIN_REQUEST_BANNED(callback) {
    let path =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      store.getState().authReducer.uid +
      '/' +
      FirebasePaths.FIREBASE_USER_BAN +
      '/' +
      FirebasePaths.FIREBASE_USER_BAN_JOIN_REQUEST;

    firebase.database()
      .ref(path)
      .once('value')
      .then((snapshot) => {
        if (snapshot.val() != null) {
          // User is banned of joining requests

          callback(true);
        } else {
          // User is not banned of joining requests

          callback(false);
        }
      });
  }

  // Join Player Request
  static joinLobby(passedRequest, pcGameProvider, gameProviderAcc) {
    // Set the user reference
    let userRef =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      store.getState().authReducer.uid;

    // getting the request Ref for user

    let userRequestRef =
      userRef + '/' + FirebasePaths.FIREBASE_USER_REQUESTS_ATTR;

    // Create

    let requestModelReference = new Requestmodelreference();

    // Fill

    requestModelReference.requestId = passedRequest.requestId;
    requestModelReference.gameId = passedRequest.gameId;
    requestModelReference.platform = passedRequest.platform;
    requestModelReference.region = passedRequest.region;

    // Set request ref  to user

    firebase.database().ref(userRequestRef).set(requestModelReference);

    // Change Join lobby status  in hub

    // this.hub.isJoined = true;

    // Load player info in a temp player object

    // Getting the request path

    let path =
      '/' +
      FirebasePaths.FB_REQUESTS_REFERENCE +
      '/' +
      passedRequest.platform +
      '/' +
      passedRequest.gameId +
      '/' +
      passedRequest.region +
      '/' +
      passedRequest.requestId +
      '/' +
      FirebasePaths.FIREBASE_REQUESTS_PLAYERS +
      '/' +
      store.getState().authReducer.uid +
      '/';

    // Set the player info

    firebase.database()
      .ref(path + 'uid')
      .set(store.getState().authReducer.uid);

    // this.hub.joinedPlayer.uid = store.getState().authReducer.uid;

    firebase.database()
      .ref(path + 'username')
      .set(store.getState().authReducer.userinformationDB.username);

    // this.hub.joinedPlayer.username = this.userinfomationProvider.userinformationDB.username;

    firebase.database()
      .ref(path + 'photoUrl')
      .set(store.getState().authReducer.userinformationDB.pictureURL);

    // this.hub.joinedPlayer.photoUrl = store.getState().userinformationDB.pictureURL;

    // setting game provider and game provider account

    if (passedRequest.platform === 'PS') {
      firebase.database()
        .ref(path + 'PSN_account')
        .set(gameProviderAcc);

      firebase.database()
        .ref(path + 'gameProvider')
        .set('PSN_account');

      firebase.database()
        .ref(path + 'platform')
        .set('PS');

      // this.hub.joinedPlayer.gameProvider = 'PSN_account';

      // this.hub.joinedPlayer.PSN_account = gameProviderAcc;
    } else if (passedRequest.platform === 'XBOX') {
      firebase.database()
        .ref(path + 'XBOX_live_account')
        .set(gameProviderAcc);

      firebase.database()
        .ref(path + 'gameProvider')
        .set('XBOX_live_account');

      firebase.database()
        .ref(path + 'platform')
        .set('XBOX');

      // this.hub.joinedPlayer.gameProvider = 'XBOX_live_account';

      // this.hub.joinedPlayer.XBOX_live_account = gameProviderAcc;
    } else if (passedRequest.platform === 'NINTENDO') {
      firebase.database()
        .ref(path + 'NINTENDO_account')
        .set(gameProviderAcc);

      firebase.database()
        .ref(path + 'gameProvider')
        .set('NINTENDO_account');

      firebase.database()
        .ref(path + 'platform')
        .set('NINTENDO');

      // this.hub.joinedPlayer.gameProvider = 'NINTENDO_account';

      // this.hub.joinedPlayer.gameProviderAcc = gameProviderAcc;
    } else if (passedRequest.platform === 'MOBILE') {
      firebase.database()
        .ref(path + 'gameProvider')
        .set(
          store.getState().hubReducer.gamesList[passedRequest.gameId].gameName +
            ' ' +
            'Mobile',
        );

      firebase.database()
        .ref(
          path +
            store.getState().hubReducer.gamesList[passedRequest.gameId]
              .gameName +
            ' ' +
            'Mobile',
        )
        .set(gameProviderAcc);

      firebase.database()
        .ref(path + 'platform')
        .set('Mobile');

      // this.hub.joinedPlayer.gameProvider =
      //   this.hub.gamesList.get(passedRequest.gameId).gameName + ' ' + 'Mobile';

      // this.hub.joinedPlayer.gameProviderAcc = gameProviderAcc;
    } else {
      if (pcGameProvider !== undefined) {
        firebase.database()
          .ref(path + 'gameProvider')
          .set(pcGameProvider);

        firebase.database()
          .ref(path + pcGameProvider)
          .set(gameProviderAcc);
      }

      firebase.database()
        .ref(path + 'platform')
        .set('PC');

      // this.hub.joinedPlayer.gameProvider = pcGameProvider;

      // this.hub.joinedPlayer.pcGameProvider = gameProviderAcc;
    }

    // Reload the players snapshot in hub

    let playersPath =
      '/' +
      FirebasePaths.FB_REQUESTS_REFERENCE +
      '/' +
      passedRequest.platform +
      '/' +
      passedRequest.gameId +
      '/' +
      passedRequest.region +
      '/' +
      passedRequest.requestId +
      '/';

    // Increasing the current players number by one

    let currentPlayersNumberPath =
      '/' +
      FirebasePaths.FB_REQUESTS_REFERENCE +
      '/' +
      passedRequest.platform +
      '/' +
      passedRequest.gameId +
      '/' +
      passedRequest.region +
      '/' +
      passedRequest.requestId +
      '/';

    let currentPlayersNumber = passedRequest.currentPlayersNumber;

    currentPlayersNumber++;

    firebase.database()
      .ref(
        currentPlayersNumberPath +
          FirebasePaths.FIREBASE_REQUESTS_CURRENT_PLAYERS_NUMBER,
      )
      .set(currentPlayersNumber);
  }

  // This function willl executed after the user enter the provider accoiunt  in the aleret and click on save btn , it save the provider account
  static saveGameProviderAccount(
    pcGameProvider,
    providerAcc,
    platform,
    gameName,
  ) {
    let uid = store.getState().authReducer.uid;

    // _users_info_ / uid / info

    let path =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      uid +
      '/' +
      FirebasePaths.FIREBASE_DETAILS_ATTR;

    // Getting the platform to insert game provider
    let param = {};
    if (platform === 'PS') {
      // _users_info_ / uid / info / PSN_account

      firebase.database()
        .ref(path + '/' + FirebasePaths.FIREBASE_USER_PS_GAME_PROVIDER_ATTR)
        .set(providerAcc);

      // Save it to userinfoDB
      param.PSNAcc = providerAcc;
    } else if (platform === 'XBOX') {
      // _users_info_ / uid / info / XBOX_LIVE_ACCOUNT

      firebase.database()
        .ref(path + '/' + FirebasePaths.FIREBASE_USER_XBOX_GAME_PROVIDER_ATTR)
        .set(providerAcc);

      // Save it to userinfoDB

      param.XboxLiveAcc = providerAcc;
    } else if (platform === 'NINTENDO') {
      // _users_info_ / uid / info / XBOX_LIVE_ACCOUNT

      firebase.database()
        .ref(
          path + '/' + FirebasePaths.FIREBASE_USER_NINTENDO_GAME_PROVIDER_ATTR,
        )
        .set(providerAcc);

      // Save it to userinfoDB

      param.nintendoAcc = providerAcc;
    } else if (platform === 'MOBILE') {
      // _users_info_ / uid / info / XBOX_LIVE_ACCOUNT

      firebase.database()
        .ref(
          path +
            '/' +
            FirebasePaths.FIREBASE_USER_MOBILE_GAME_PROVIDER_ATTR +
            '/' +
            gameName +
            ' ' +
            'Mobile',
        )
        .set(providerAcc);

      // Save it to userinfoDB
      let mobileGamesAccs = store.getState().authReducer.userinformationDB
        .mobileGamesAccs;

      mobileGamesAccs.set(gameName + ' ' + 'Mobile', providerAcc);
      param.mobileGamesAccs = mobileGamesAccs;
    } else if (platform === 'PC') {
      // _users_info_ / uid / info / PC_providers / eg : Steam : value

      firebase.database()
        .ref(
          path +
            '/' +
            FirebasePaths.FIREBASE_USER_PC_GAME_PROVIDER_ATTR +
            '/' +
            pcGameProvider,
        )
        .set(providerAcc);

      // Save it to userinfoDB
      let pcGamesAccs = store.getState().authReducer.userinformationDB
        .pcGamesAccs;
      pcGamesAccs.set(pcGameProvider, providerAcc);

      param.pcGamesAccs = pcGamesAccs;
    }
    store.dispatch(setUserInfo(param));
  }

  // This function will create reference for useres join the room
  static joinGroupChatRef(request, platform, gameProvider, providerAccount) {
    // Create payload  obj to get the last message of the chat
    // let packet = new Packet();

    // let userChatRef = new UserChatRef();

    // Getting group chat ref

    let groupChatRef =
      FirebasePaths.FIREBASE_CHAT_ATTR +
      '/' +
      FirebasePaths.FIREBASE_PUBLIC_ATTR +
      '/' +
      request.requestId +
      '/';

    // Post join message

    // Generate message id and get the current timestamp

    let messageId = firebase.database().ref().push().key;

    let timestamp = firebase.database.ServerValue.TIMESTAMP;

    let joinedPacket = new Packet();

    joinedPacket.messageId = messageId;

    if (
      gameProvider != null &&
      gameProvider !== undefined &&
      providerAccount != null &&
      providerAccount !== undefined
    ) {
      joinedPacket.message =
        store.getState().authReducer.userinformationDB.username +
        ' joined this team 😀' +
        '\n' +
        gameProvider +
        ' ' +
        providerAccount;
    } else {
      joinedPacket.message =
        store.getState().authReducer.userinformationDB.username +
        ' joined this team 😀';
    }

    joinedPacket.timestamp = timestamp;

    joinedPacket.chatRef = request.requestId;

    joinedPacket.username = store.getState().authReducer.userinformationDB.username;

    joinedPacket.uid = 'JOINED';

    // Set a join message for this user with admin attr = "JOINED" and message : First Last  MESSAGE

    firebase.database()
      .ref(
        groupChatRef +
          '/' +
          FirebasePaths.FIREBASE_CHAT_MESSAGES +
          '/' +
          FirebasePaths.FIREBASE_PUBLIC_CHAT_LAST_MESSAGE +
          '/',
      )
      .set(joinedPacket);

    // Set the chat packet

    firebase.database()
      .ref(
        groupChatRef +
          '/' +
          FirebasePaths.FIREBASE_CHAT_MESSAGES +
          '/' +
          FirebasePaths.FIREBASE_PUBLIC_CHAT_PACKETS +
          '/' +
          messageId,
      )
      .set(joinedPacket);

    // Set the user ID to the chat Room

    firebase.database()
      .ref(
        groupChatRef +
          FirebasePaths.FIREBASE_PUBLIC_CHAT_INFO +
          '/' +
          FirebasePaths.FIREBASE_PUBLIC_CHAT_USERS +
          '/' +
          store.getState().authReducer.uid,
      )
      .set(store.getState().authReducer.userinformationDB.username);

    // Then other users
    firebase.database()
      .ref(
        groupChatRef +
          FirebasePaths.FIREBASE_PUBLIC_CHAT_INFO +
          '/' +
          FirebasePaths.FIREBASE_PUBLIC_CHAT_USERS,
      )
      .once('value', (result) => {
        let ids = Object.keys(result.val());

        for (var i = 0; i < ids.length; i++) {
          let ref =
            FirebasePaths.FIREBASE_USERS_INFO_ATTR +
            '/' +
            ids[i] +
            '/' +
            FirebasePaths.FIREBASE_USER_CHAT_REFERENCES +
            '/' +
            FirebasePaths.FIREBASE_PUBLIC_ATTR +
            '/' +
            joinedPacket.chatRef +
            '/';
          // update timestamp and last message and sender ID in the user chat ref
          // this.rootDB.list(ref).set(FirebasePaths.FIREBASE_PUBLIC_USER_REF_LAST_MESSAGE,this.hub.roomsLastMessages.get(groupKey));

          // Update once in the path  to notify once ! so we will gather the data in one shot and update !!
          let shot = {
            [FirebasePaths.FIREBASE_PUBLIC_USER_REF_LAST_MESSAGE]:
              joinedPacket.message,
            [FirebasePaths.FIREBASE_PUBLIC_USER_REF_LAST_MESSAGE_TIMESTAMP]:
              joinedPacket.timestamp,
            [FirebasePaths.FIREBASE_PUBLIC_USER_REF_SENDER_ID]: store.getState()
              .authReducer.uid,
            [FirebasePaths.FIREBASE_PUBLIC_USER_REF_OPENED]: false,
          };
          firebase.database().ref(ref).update(shot);
        }
      });
  }

  // This function will remove the user's req reference
  static removeReqRef(req, playersSize) {
    // First thing add this req ref to the closed connections ref
    let closedGroupChats = store.getState().hubReducer.closedGroupChats;
    closedGroupChats.set(req.requestId, true);
    // Clear this req locally
    let allGroupChatRefs = store.getState().hubReducer.allGroupChatRefs;
    allGroupChatRefs.delete(req.requestId);
    store.dispatch(
      setHub({
        closedGroupChats,
        createdReq: new Requestmodel(),
        currentRequest: new Requestmodel(),
        allGroupChatRefs,
        communityGroupChatsArr: Array.from(allGroupChatRefs.values()),
      }),
    );

    // Now  If the playersSize > 1 then take the second player and put it as an admin if not then just delete the request ref
    // CASE 1 : if this is the only player then  delete the request and remove the public chat room and finally remove the references in the user ref
    // Condition here is  <1 because the admin player is not in the players array he's in the top of the page
    if (playersSize < 1) {
      // Means this is the admin
      // Getting the request ref
      let reqRef =
        FirebasePaths.FB_REQUESTS_REFERENCE +
        '/' +
        req.platform +
        '/' +
        req.gameId +
        '/' +
        req.region +
        '/' +
        req.requestId;
      // Deleting the req
      firebase.database().ref(reqRef).remove();
      // Getting the group chat ref
      let groupChatRef =
        FirebasePaths.FIREBASE_CHAT_ATTR +
        '/' +
        FirebasePaths.FIREBASE_PUBLIC_ATTR +
        '/' +
        req.requestId;
      // Deleting group ref
      firebase.database().ref(groupChatRef).remove();

      // Getting the user ref
      let userRef =
        FirebasePaths.FIREBASE_USERS_INFO_ATTR +
        '/' +
        store.getState().authReducer.uid +
        '/';
      //  Deleting user req ref
      firebase.database()
        .ref(userRef + FirebasePaths.FIREBASE_USER_REQUESTS_ATTR)
        .remove();

      // Deleting user group chat ref
      firebase.database()
        .ref(
          userRef +
            FirebasePaths.FIREBASE_USER_CHAT_REFERENCES +
            '/' +
            FirebasePaths.FIREBASE_PUBLIC_ATTR +
            '/' +
            req.requestId,
        )
        .remove();

      // Delete this request from the hub
      firebase.database()
        .ref(FirebasePaths.FIREBASE_REQUESTS_HUB + '/' + req.requestId)
        .remove();
    } else {
      // CASE 2 : more than one player ! , only remove this user ref from the req info by changing the admin node to the new second admin : ONLY IF THIS USER IS THE ADMIN
      // IF THIS USER IS NOT THE ADMIN THEN JUST UPDATE OTHER THINGS
      //  and set the second user in the array as an admin and delete him from the players , and from the group chat users info , and remove user ref req and group chat ref
      // Get req ref

      let reqRef =
        FirebasePaths.FB_REQUESTS_REFERENCE +
        '/' +
        req.platform +
        '/' +
        req.gameId +
        '/' +
        req.region +
        '/' +
        req.requestId +
        '/';

      // Update  current players number
      firebase.database()
        .ref(reqRef)
        .child(FirebasePaths.FIREBASE_REQUESTS_CURRENT_PLAYERS_NUMBER)
        .set(playersSize);

      // Check if this user is the admin
      if (store.getState().authReducer.uid == req.admin) {
        // Setting the second player as an admin key
        firebase.database()
          .ref(reqRef)
          .child(FirebasePaths.FIREBASE_REQUESTS_ADMIN_ATTR)
          .set(store.getState().hubReducer.secondAdminKey);
        // Setting the second player admin name
        firebase.database()
          .ref(reqRef)
          .child(FirebasePaths.FIREBASE_REQUESTS_ADMIN_NAME_ATTR)
          .set(store.getState().hubReducer.secondAdminName);

        // Deleting this second player from the players parent nodes because he's already in the admin child node
        firebase.database()
          .ref(
            reqRef +
              FirebasePaths.FIREBASE_REQUESTS_PLAYERS +
              '/' +
              store.getState().hubReducer.secondAdminKey,
          )
          .remove();
      } else {
        // Just remove this player from the players parent

        firebase.database()
          .ref(
            reqRef +
              FirebasePaths.FIREBASE_REQUESTS_PLAYERS +
              '/' +
              store.getState().authReducer.uid,
          )
          .remove();
      }

      // Getting group chat ref
      let userInGRoupChatRef =
        FirebasePaths.FIREBASE_CHAT_ATTR +
        '/' +
        FirebasePaths.FIREBASE_PUBLIC_ATTR +
        '/' +
        req.requestId +
        '/' +
        FirebasePaths.FIREBASE_DETAILS_ATTR +
        '/' +
        FirebasePaths.FIREBASE_USERS_LIST_ATTR +
        '/' +
        store.getState().authReducer.uid;
      // Removing current user from database node
      firebase.database().ref(userInGRoupChatRef).remove();

      // Getting the user ref path
      let userRef =
        FirebasePaths.FIREBASE_USERS_INFO_ATTR +
        '/' +
        store.getState().authReducer.uid +
        '/';

      // Removing the req ref from the this current user ref
      firebase.database()
        .ref(userRef + FirebasePaths.FIREBASE_USER_REQUESTS_ATTR)
        .remove();
      // Removing the group chat ref from the user DB
      firebase.database()
        .ref(
          userRef +
            FirebasePaths.FIREBASE_USER_CHAT_REFERENCES +
            '/' +
            FirebasePaths.FIREBASE_PUBLIC_ATTR +
            '/' +
            req.requestId,
        )
        .remove();

      // Post a leave message
      this.postLeaveMessage(
        FirebasePaths.FIREBASE_CHAT_ATTR +
          '/' +
          FirebasePaths.FIREBASE_PUBLIC_ATTR +
          '/' +
          req.requestId +
          '/' +
          FirebasePaths.FIREBASE_CHAT_MESSAGES,
        req.requestId,
      );
    }
  }

  // This function will post a leave message
  static postLeaveMessage(chatPath, chatRef) {
    // Creating the packet message
    // Generate message id and get the current timestamp
    let messageId = firebase.database().ref().push().key;
    let timestamp = firebase.database.ServerValue.TIMESTAMP;

    let packet = new Packet();
    packet.messageId = messageId;
    packet.message =
      store.getState().authReducer.userinformationDB.username +
      ' left the team 😔';
    packet.timestamp = timestamp;
    packet.chatRef = chatRef;
    packet.username = store.getState().authReducer.userinformationDB.username;
    packet.uid = 'LEFT';

    // Update the last message
    firebase.database()
      .ref(chatPath + '/' + FirebasePaths.FIREBASE_PUBLIC_CHAT_LAST_MESSAGE)
      .set(packet);

    // Post new packet : leave message
    firebase.database()
      .ref(
        chatPath +
          '/' +
          FirebasePaths.FIREBASE_PUBLIC_CHAT_PACKETS +
          '/' +
          messageId,
      )
      .set(packet);

    // Set message ref for all users
    // First set it for the current user
    let shot = {
      [FirebasePaths.FIREBASE_PUBLIC_USER_REF_LAST_MESSAGE]: packet.message,
      [FirebasePaths.FIREBASE_PUBLIC_USER_REF_LAST_MESSAGE_TIMESTAMP]: timestamp,
      [FirebasePaths.FIREBASE_PUBLIC_USER_REF_SENDER_ID]: store.getState()
        .authReducer.uid,
      [FirebasePaths.FIREBASE_PUBLIC_USER_REF_OPENED]: true,
    };
    // current user chat ref
    let ref =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      store.getState().authReducer.uid +
      '/' +
      FirebasePaths.FIREBASE_USER_CHAT_REFERENCES +
      '/' +
      FirebasePaths.FIREBASE_PUBLIC_ATTR +
      '/' +
      packet.chatRef +
      '/';
    firebase.database().ref(ref).update(shot);

    // Then other users
    if (
      store.getState().hubReducer.groupChatMembers[packet.chatRef] !== undefined
    ) {
      let membersObj = store.getState().hubReducer.groupChatMembers[
        packet.chatRef
      ];
      let iterator = membersObj.members.keys();

      // Setting the members size
      store.dispatch(setHub({membersLimit: membersObj.members.size}));
      for (let i = 0; i < membersObj.members.size; i++) {
        this.setUserChatRefGroupChat(
          packet.chatRef,
          iterator.next().value,
          timestamp,
          store.getState().authReducer.uid,
          packet.message,
          i,
        );
      }
    }
  }

  static onGroupChatNameChanged(chatRef, name) {
    // Getting group chat ref
    let groupChatRef =
      FirebasePaths.FIREBASE_CHAT_ATTR +
      '/' +
      FirebasePaths.FIREBASE_PUBLIC_ATTR +
      '/' +
      chatRef +
      '/';

    // Post group name change message
    // Generate message id and sget the current timestamp
    let messageId = firebase.database().ref().push().key;
    let timestamp = firebase.database.ServerValue.TIMESTAMP;
    let joinedPacket = new Packet();
    joinedPacket.messageId = messageId;
    joinedPacket.message =
      store.getState().authReducer.userinformationDB.username +
      ' ' +
      ' has changed the group name to ' +
      '“' +
      name +
      '”';
    joinedPacket.timestamp = timestamp;
    joinedPacket.chatRef = chatRef;
    joinedPacket.username = store.getState().authReducer.userinformationDB.username;
    joinedPacket.uid = 'GROUP_NAME_CHANGE';

    // Set a group name change message for this user with admin attr = "GROUP_NAME_CHANGE" and message : First Last  MESSAGE
    firebase.database()
      .ref(
        groupChatRef +
          '/' +
          FirebasePaths.FIREBASE_CHAT_MESSAGES +
          '/' +
          FirebasePaths.FIREBASE_PUBLIC_CHAT_LAST_MESSAGE +
          '/',
      )
      .set(joinedPacket);
    // Set the chat packet
    firebase.database()
      .ref(
        groupChatRef +
          '/' +
          FirebasePaths.FIREBASE_CHAT_MESSAGES +
          '/' +
          FirebasePaths.FIREBASE_PUBLIC_CHAT_PACKETS +
          '/' +
          messageId,
      )
      .set(joinedPacket);

    // Set message ref for all users
    // First set it for the current user
    let shot = {
      [FirebasePaths.FIREBASE_PUBLIC_USER_REF_LAST_MESSAGE]:
        joinedPacket.message,
      [FirebasePaths.FIREBASE_PUBLIC_USER_REF_LAST_MESSAGE_TIMESTAMP]: timestamp,
      [FirebasePaths.FIREBASE_PUBLIC_USER_REF_SENDER_ID]: joinedPacket.uid,
      [FirebasePaths.FIREBASE_PUBLIC_USER_REF_OPENED]: true,
    };
    // current user chat ref
    let ref =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      store.getState().authReducer.uid +
      '/' +
      FirebasePaths.FIREBASE_USER_CHAT_REFERENCES +
      '/' +
      FirebasePaths.FIREBASE_PUBLIC_ATTR +
      '/' +
      joinedPacket.chatRef +
      '/';
    firebase.database().ref(ref).update(shot);

    // Then other users
    if (
      store.getState().hubReducer.groupChatMembers[joinedPacket.chatRef] !==
      undefined
    ) {
      let membersObj = store.getState().hubReducer.groupChatMembers[
        joinedPacket.chatRef
      ];
      let iterator = membersObj.members.keys();

      // Setting the members size
      store.dispatch(setHub({membersLimit: membersObj.members.size}));
      for (let i = 0; i < membersObj.members.size; i++) {
        this.setUserChatRefGroupChat(
          joinedPacket.chatRef,
          iterator.next().value,
          timestamp,
          store.getState().authReducer.uid,
          joinedPacket.message,
          i,
        );
      }
    }
  }

  // This function will set the last userchat ref fdor publioc chat for each user
  static setUserChatRefGroupChat(
    groupKey,
    userId,
    messageTimestamp,
    senderId,
    lastMessage,
    counter,
  ) {
    if (counter < store.getState().hubReducer.membersLimit) {
      if (senderId !== '') {
        // Getting user ref
        let ref =
          FirebasePaths.FIREBASE_USERS_INFO_ATTR +
          '/' +
          userId +
          '/' +
          FirebasePaths.FIREBASE_USER_CHAT_REFERENCES +
          '/' +
          FirebasePaths.FIREBASE_PUBLIC_ATTR +
          '/' +
          groupKey +
          '/';
        // update timestamp and last message and sender ID in the user chat ref
        // this.rootDB.list(ref).set(FirebasePaths.FIREBASE_PUBLIC_USER_REF_LAST_MESSAGE,this.hub.roomsLastMessages.get(groupKey));

        // Update once in the path  to notify once ! so we will gather the data in one shot and update !!
        let shot = {
          [FirebasePaths.FIREBASE_PUBLIC_USER_REF_LAST_MESSAGE]: lastMessage,
          [FirebasePaths.FIREBASE_PUBLIC_USER_REF_LAST_MESSAGE_TIMESTAMP]: messageTimestamp,
          [FirebasePaths.FIREBASE_PUBLIC_USER_REF_SENDER_ID]: senderId,
          [FirebasePaths.FIREBASE_PUBLIC_USER_REF_OPENED]: false,
        };
        firebase.database().ref(ref).update(shot);
      }
    } else {
      // Clear value
      store.dispatch(setHub({membersLimit: 0}));
    }
  }

  static loadLobby(reqRef, platform, gameId, region) {
    // Get the request path
    let requestPath =
      FirebasePaths.FB_REQUESTS_REFERENCE +
      '/' +
      platform +
      '/' +
      gameId +
      '/' +
      region +
      '/' +
      reqRef;

    return firebase.database().ref(requestPath).once('value');
  }

  static checkShowJoinRequest() {
    let requestPath = FirebasePaths.FB_REQUESTS_JOIN + '/';
    return firebase.database()
      .ref(requestPath)
      .once('value')
      .then((snapshot) => {
        let userProfile = store.getState().authReducer.userinformationDB;
        let join_request = snapshot.val();

        return (
          join_request.isActivated &&
          ((join_request.type === 'REGION' &&
            (join_request.cityKey === userProfile.city ||
              join_request.countryKey === userProfile.country)) ||
            (join_request.type === 'COUNTRY' &&
              join_request.countryKey === userProfile.country) ||
            (join_request.type === 'CITY' &&
              join_request.cityKey === userProfile.city) ||
            join_request.type === 'UNIVERSAL') &&
          join_request
        );
      });
  }
}
