/* eslint-disable react-native/no-inline-styles */
import React, { Component } from 'react';
import { View, TouchableOpacity, FlatList, Linking, Keyboard } from 'react-native';
import { compose } from 'redux';
import { connect } from 'react-redux';
import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/storage';

import I18n from '../../i18n';
import Entypo from 'react-native-vector-icons/Entypo';
import * as ImagePicker from 'expo-image-picker';
import Feather from 'react-native-vector-icons/Feather';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';

import * as FirebasePaths from '../../constants/firebasePaths';
import { store } from '../../redux/store';

// import components
import UserAvatar from '../../components/user-avatar';
import Label from '../../components/label';
import PrimaryButton from '../../components/buttons/primary-button';
import TextInput from '../../components/text-input';
import ChatMessageHolder from '../../components/chat-message-holder';
import ImageWithPlaceholder from '../../components/image-with-placeholder';
import SendImageModal from '../../components/send-image-modal';
import StickersModal from '../../components/stickers-modal';

// import styles
import styles from './styles';
import AppStyles from '../../constants/styles';
import Dimens from '../../constants/dimens';
import { perfectSize } from '../../helpers/deviceHelper';

// import actions
import { switchTheme } from '../../redux/modules/app/actions';
import { set as setHub } from '../../redux/modules/hub/actions';
import {
  showAlert,
  showInputAlert,
  showSpinner,
  hideSpinner,
} from '../../redux/modules/alert/actions';

import NavigationService from '../../navigation/navigationService';
import Chatcore from './chatcore';
import Mainmenucore from '../mainmenu/mainmenucore';
import Lobbycore from '../lobby/lobbycore';
import FriendsListCore from '../friendslist/friendslistcore';
import CommunityCore from '../community/communitycore';
import Packet from '../../models/packet';
import PlayerModel from '../../models/playersmodel';
import { presentToast } from '../../helpers/toastHelper';

// android back button handle
import {
  handleAndroidBackButton,
  removeAndroidBackButtonHandler,
} from '../../helpers/deviceHelper';
import dimens from '../../constants/dimens';

// import ad service
import AdService from '../../services/adSpot.service';

import { STICKERS_ICON } from '../../constants/images';
import { clearFastImageCache, requestCameraPermission } from '../../services/utils';
import LocalImageOpacity from '../../components/local-image-opacity';

class ChatPage extends Component {
  constructor(props) {
    super(props);
    const params = props.navigation.state.params;
    let CAME_FROM = params.CAME_FROM;
    if (CAME_FROM != null) {
      if (CAME_FROM === 'NOTIFICATION') {
        // Get the :
        // this.CHAT_TYPE  : FIXED VALUE FROM NOTIFICATION : PUBLIC or PRIVATE
        // this.TITLE    : opponentUsername
        // this.SUBTITLE : opponentBio
        // this.CHAT_IMG  :  opponentPic
        // this.friendUID    : in public chat it will be the chat ref aka opponentId
        //  this.CHAT_STATUS : FIXED VALUE FROM THE NOTIFICATION PAYLOAD : ONGOING
        //  this.hub.tempChatRoomRef : chatRef
        // Now lets get the chat shot from the passed parameter
        let shot = params.TRIGGER;
        // Setting the values
        var CHAT_TYPE = shot.CHAT_TYPE;
        var TITLE = shot.TITLE;
        var SUBTITLE = shot.SUBTITLE;
        var CHAT_IMG = shot.CHAT_IMG;
        var friendUID = shot.FRIEND_ID;
        var CHAT_STATUS = shot.CHAT_STATUS;
        this.props.setHub({ tempChatRoomRef: shot.CHAT_REF });
      } else {
        // Getting the General Attrs
        CHAT_TYPE = params.CHAT_TYPE;
        TITLE = params.TITLE;
        SUBTITLE = params.SUBTITLE;
        CHAT_IMG = params.CHAT_IMG;
        friendUID = params.FRIEND_UID;
        CHAT_STATUS = params.CHAT_STATUS;

        // Clear chat array if it has messages before the load
        this.props.setHub({ chatPacketsArray: [] });
      }
    } else {
      // Getting the General Attrs
      CHAT_TYPE = params.CHAT_TYPE;
      TITLE = params.TITLE;
      SUBTITLE = params.SUBTITLE;
      CHAT_IMG = params.CHAT_IMG;
      friendUID = params.FRIEND_UID;
      CHAT_STATUS = params.CHAT_STATUS;

      // Clear chat array if it has messages before the load
      this.props.setHub({ chatPacketsArray: [] });
    }
    this.state = {
      userMessage: '',
      CHAT_TYPE: CHAT_TYPE || '',
      TITLE: TITLE || '',
      SUBTITLE: SUBTITLE || '',
      CHAT_IMG: CHAT_IMG || '',
      friendUID: friendUID || '',
      CHAT_STATUS: CHAT_STATUS || '',
      CHAT_BORDERCOLOR: '#ffffff',
      CAME_FROM: CAME_FROM,
      inputText: '',
      AD: null,
      sendImageModalVisible: false,
      stickersModalVisible: false,
      photoSource: null,
      photoCaption: '',
    };
  }

  componentDidMount() {
    // Disable chat local notifications when open chat
    this.props.setHub({ IS_IN_CHAT: true });
    // Getting the private attrs if it private
    if (this.state.CHAT_TYPE === 'PRIVATE') {
      // Log feature : private chat opened
      global.featuresLogger.LOG_CHAT_PRIVATE_OPENED();
      global.featuresLogger.PRIVATE_CHAT_AD_PAGE_VIEW();

      if (this.state.CAME_FROM !== 'NOTIFICATION') {
        this.getProfileBorderColor(this.state.friendUID).then((color) => {
          this.setState({ CHAT_BORDERCOLOR: color });
        });
      }

      // NOW  if the chat is NEW status then create new ref when the user send a new message , else load the chat : ً When the user send the first message

      // Load the chat if it's not new
      if (this.state.CHAT_STATUS === 'ONGOING') {
        // Load the chat : just to update the latest chat
        Chatcore.loadChat('PRIVATE', 'NONE');
      }
      // Load bio
      firebase.database()
        .ref(
          FirebasePaths.FIREBASE_USERS_INFO_ATTR +
          '/' +
          this.state.friendUID +
          '/' +
          FirebasePaths.FIREBASE_DETAILS_ATTR +
          '/' +
          FirebasePaths.FIREBASE_BIO_ATTR,
        )
        .once('value')
        .then((snapshot) => {
          this.setState({ SUBTITLE: snapshot.val() });
        });
    } else if (this.state.CHAT_TYPE === 'PUBLIC') {
      // Log feature : group opened
      global.featuresLogger.LOG_CHAT_GROUP_OPENED();
      global.featuresLogger.PUBLIC_CHAT_AD_PAGE_VIEW();
      // Get the group chat members
      Mainmenucore.loadGroupMembers(this.props.tempChatRoomRef).then(
        (groupChatMembers) => {
          // SET JOINED MEMBERS
          let SUBTITLE = 'Players: ';
          let count = 0; // USed to avoid the first comma in adding players
          let membersMap = groupChatMembers[this.props.tempChatRoomRef].members;
          membersMap.forEach((member) => {
            if (count < 1) {
              SUBTITLE = SUBTITLE + member;
            } else {
              SUBTITLE = SUBTITLE + ', ' + member;
            }
            count++;
          });
          this.setState({ SUBTITLE });
        },
      );

      // NOW  if the chat is NEW status then create new ref when the user send a new message , else load the chat : ً When the user send the first message

      // Load the chat if it's not new
      if (this.state.CHAT_STATUS === 'ONGOING') {
        // Load the chat : just to update the latest chat
        Chatcore.loadChat('PUBLIC', this.state.TITLE);
      }

      const ref =
        FirebasePaths.FIREBASE_USERS_INFO_ATTR +
        '/' +
        this.props.uid +
        '/' +
        FirebasePaths.FIREBASE_USER_CHAT_REFERENCES +
        '/' +
        FirebasePaths.FIREBASE_PUBLIC_ATTR +
        '/' +
        this.props.tempChatRoomRef;
      firebase.database()
        .ref(ref)
        .once('value')
        .then((snapshot) => {
          let val = snapshot.val();
          let TITLE = val.groupNewTitle
            ? val.groupNewTitle
            : val.opponentUsername;
          this.setState({ TITLE });
        });
    }

    // Load the AD
    this.ADRef$ = firebase.database().ref(
      FirebasePaths.FIREBASE_AD_SPOTS +
      '/' +
      FirebasePaths.FIREBASE_AD_SPOTS_PUBLIC_CHAT,
    );
    this.ADRef$.on('value', (snapshot) => {
      if (snapshot.val() != null) {
        this.setState({
          AD: snapshot.val(),
        });
      } else {
        this.setState({
          AD: 'NONE',
        });
      }
    });

    CommunityCore.IS_CHAT_BANNED().then((isBanned) => {
      if (isBanned) {
        // Show ban alert
        let banTitle = I18n.t('COMMUNITY_PAGE.ban_alert_title');
        let banMsg = I18n.t('COMMUNITY_PAGE.ban_alert_msg');
        let banBtn = I18n.t('COMMUNITY_PAGE.ban_alert_btn');

        // Show ban alert
        this.props.showAlert([
          banTitle,
          banMsg,
          [
            {
              text: banBtn,
              onPress: () => {
                NavigationService.moveToScreenInStack(
                  'SettingsStack',
                  'SupportPage',
                );
              },
            },
          ],
        ]);
        clearFastImageCache();
        NavigationService.goBack();
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.chatPacketsArray.length !== this.props.chatPacketsArray.length
    ) {
      if (this.flatList && this.props.chatPacketsArray.length > 0) {
        // this.flatList.scrollToIndex({ animated: true, index: 0 });
      }
    }
  }

  componentWillMount() {
    handleAndroidBackButton(() => {
      clearFastImageCache();
      NavigationService.goBack();
    });
  }

  componentWillUnmount() {
    if (Chatcore.chatRef$) {
      Chatcore.chatRef$.off();
      Chatcore.chatRef$ = null;
    }
    if (this.ADRef$) {
      this.ADRef$.off();
      this.ADRef$ = null;
    }
    // Before clearing data structures store the last message
    if (
      this.state.CHAT_TYPE === 'PUBLIC' &&
      this.props.chatPacketsArray[this.props.chatPacketsArray.length - 1] !=
      null
    ) {
      let roomsLastMessages = store.getState().hubReducer.roomsLastMessages;
      roomsLastMessages[this.props.tempChatRoomRef] =
        this.props.chatPacketsArray[this.props.chatPacketsArray.length - 1]
          .type === 'image'
          ? 'Image'
          : this.props.chatPacketsArray[this.props.chatPacketsArray.length - 1]
            .message;
      this.props.setHub({ roomsLastMessages });
    }
    // Clear the map
    this.props.setHub({
      chatPacketsArray: [],
      IS_IN_CHAT: false,
    });
    removeAndroidBackButtonHandler();
  }

  getProfileBorderColor(uid) {
    let cosmeticsPath =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      uid +
      '/' +
      FirebasePaths.FIREBASE_COSMETICS_ATTR +
      '/' +
      FirebasePaths.FIREBASE_PROFILE_BORDER_ATTR;
    return firebase.database()
      .ref(cosmeticsPath)
      .once('value')
      .then((actions) => {
        let borderColor = '#ffffff';
        actions.forEach((data) => {
          if (data.key === FirebasePaths.FIREBASE_COLOR_ATTR) {
            borderColor = data.val();
          }
        });
        return borderColor;
      });
  }

  // This function will fire when the user  click on the send icon on the chat bottom  bar
  sendMessage = () => {
    // First make sure this user typed anything before you send
    if (this.state.userMessage.trim().length < 1) {
      return;
    }

    // For Private Chat
    if (this.state.CHAT_TYPE === 'PRIVATE') {
      // If the chat status is new then post a message and create a chat ref for both users
      if (this.state.CHAT_STATUS === 'NEW') {
        // Sending the packet locally is in the method : createNewChatRefWithFirstMessage

        let packet = new Packet();
        packet.message = this.state.userMessage;
        packet.uid = this.props.uid;

        // Creating Chat ref for both// NOTE TITLE HERE IS THE FRIEND USERNAME !
        Chatcore.createNewChatRefWithFirstMessage(
          this.props.uid,
          this.state.friendUID,
          this.state.userMessage,
          this.state.TITLE,
          this.state.SUBTITLE,
          this.state.CHAT_IMG,
        );

        // Load the chat : just to update the latest chat
        Chatcore.loadChat('PRIVATE', 'NONE');

        // Change the chat status to ongoing
        this.setState({ CHAT_STATUS: 'ONGOING' });
      } // Else just send the message and update the database and hub
      else {
        // post message and update the hub
        Chatcore.postMessage(
          this.props.uid,
          this.state.friendUID,
          this.state.userMessage,
          this.state.TITLE,
          this.state.SUBTITLE,
          this.state.CHAT_IMG,
          '',
        );
      }
    } else {
      // For Public chats we will use different method
      Chatcore.postGroupMessage(
        this.state.friendUID,
        this.props.uid,
        this.state.userMessage,
        '',
        '',
      );
    }

    // Clear the chat bar
    this.setState({ userMessage: '' });
  };

  // This function will show the chat settings alert
  showChatSettingsAlert = () => {
    // Based on the chat type we will show different alerts settings
    if (this.state.CHAT_TYPE === 'PUBLIC') {
      let chatRefObj = store
        .getState()
        .hubReducer.allGroupChatRefs.get(this.props.tempChatRoomRef);

      let chatSettings = I18n.t('CHAT_PAGE.chat_settings');
      let view_group = I18n.t('CHAT_PAGE.view_group');
      let leaveGroup = I18n.t('CHAT_PAGE.leave_group');
      let leaveGroupSuccess = I18n.t('CHAT_PAGE.leave_group_success');
      let rate = I18n.t('CHAT_PAGE.rate_users');
      let changeGroupNameTxt = I18n.t('CHAT_PAGE.change_group_name');

      this.props.showAlert([
        chatSettings,
        '',
        [
          {
            text: changeGroupNameTxt,
            onPress: () => {
              this.presentPrompt();
            },
          },
          {
            text: view_group,
            onPress: () => {
              NavigationService.navigate('LobbyPage', {
                chatRef: this.props.tempChatRoomRef,
                fromChat: true,
              });
            },
          },
          {
            text: rate,
            onPress: () => {
              // Go to rate page
              NavigationService.navigate('GroupRatingsPage', {
                chatRef: this.props.tempChatRoomRef,
              });
            },
          },
          {
            text: leaveGroup,
            onPress: () => {
              // Log Rank Hp
              global.rankLogger.LOG_NEW_HP(
                global.rankLogger.EXIT_GROUP,
                'EXIT_GROUP',
              );

              // Log feature : EXIT GROUP
              global.featuresLogger.LOG_REQUEST_EXIT();

              Lobbycore.loadLobby(
                chatRefObj.chatRef,
                chatRefObj.platform,
                chatRefObj.gameId,
                chatRefObj.region,
              ).then((shot) => {
                // We want to take the admin Id and the  current players
                let loadedReq = shot.val();

                if (loadedReq != null) {
                  // First clear the joined players hub before loading the new objs
                  let joinedPlayers = new Map();
                  // And empty the second player admin and key : NOTE we need sercond player name and key to replace the first one (this one) in the request ref
                  let secondAdminKey = '';
                  let secondAdminName = '';

                  // Extract players
                  // Check if this request has players ! and fill the joinedPlayers list
                  if (loadedReq.players != null) {
                    let playersJson = loadedReq.players;

                    // filling the hub
                    for (var key in playersJson) {
                      if (playersJson.hasOwnProperty(key)) {
                        let player = new PlayerModel();
                        player.username = playersJson[key].username;
                        player.uid = playersJson[key].uid;
                        player.photoUrl = playersJson[key].photoUrl;
                        player.gameProvider = playersJson[key].gameProvider;

                        if (loadedReq.platform === 'PS') {
                          player.PSN_account = playersJson[key].PSN_account;
                        } else if (loadedReq.platform === 'XBOX') {
                          player.XBOX_live_account =
                            playersJson[key].XBOX_live_account;
                        } else {
                          // We used those square brackets because we don't know which variable is the pc provider
                          player.pcGameProvider =
                            playersJson[key][player.gameProvider];
                        }
                        joinedPlayers.set(key, player);
                        // Set this player as an admin if this player is not this user
                        if (this.props.uid !== player.uid) {
                          secondAdminName = player.username;
                          secondAdminKey = player.uid;
                        }
                      }
                    }
                    this.props.setHub({
                      joinedPlayers,
                      secondAdminName,
                      secondAdminKey,
                    });
                  }

                  // Now invoke the remove reqRef function
                  Lobbycore.removeReqRef(loadedReq, joinedPlayers.size);

                  presentToast({ message: leaveGroupSuccess });
                  clearFastImageCache();
                  NavigationService.goBack();
                } else {
                  // The result here is a string with "SERVER REJECTED" : that means the request ref is not found

                  // Now just remove the user from the group ref and from the user chat ref
                  // STEP 1 : leave the  group ref : public chat

                  // Getting group chat ref
                  let userInGRoupChatRef =
                    FirebasePaths.FIREBASE_CHAT_ATTR +
                    '/' +
                    FirebasePaths.FIREBASE_PUBLIC_ATTR +
                    '/' +
                    chatRefObj.chatRef +
                    '/' +
                    FirebasePaths.FIREBASE_DETAILS_ATTR +
                    '/' +
                    FirebasePaths.FIREBASE_USERS_LIST_ATTR +
                    '/' +
                    this.props.uid;
                  // Removing current user from database node
                  firebase.database().ref(userInGRoupChatRef).remove();

                  // Getting the user ref
                  let userRef =
                    FirebasePaths.FIREBASE_USERS_INFO_ATTR +
                    '/' +
                    this.props.uid +
                    '/';
                  //  Deleting user req ref
                  firebase.database()
                    .ref(userRef + FirebasePaths.FIREBASE_USER_REQUESTS_ATTR)
                    .remove();

                  // Deleting user group chat ref
                  firebase.database()
                    .ref(
                      userRef +
                      FirebasePaths.FIREBASE_USER_CHAT_REFERENCES +
                      '/' +
                      FirebasePaths.FIREBASE_PUBLIC_ATTR +
                      '/' +
                      chatRefObj.chatRef,
                    )
                    .remove();

                  // Delete this request from the hub
                  firebase.database()
                    .ref(
                      FirebasePaths.FIREBASE_REQUESTS_HUB +
                      '/' +
                      chatRefObj.chatRef,
                    )
                    .remove();

                  // Now remove the group chat ref from the map and from the arr
                  let allGroupChatRefs = store.getState().allGroupChatRefs;
                  allGroupChatRefs.delete(chatRefObj.chatRef);
                  this.props.setHub({
                    allGroupChatRefs,
                    communityGroupChatsArr: Array.from(
                      allGroupChatRefs.values(),
                    ),
                  });

                  presentToast({ message: leaveGroupSuccess });
                  clearFastImageCache();
                  NavigationService.goBack();
                }
              });
            },
          },
        ],
        false,
      ]);
    } else {
      // Private Chat
      // Its two cases : this guy is a friend then just view profile , this guy is not a friend
      if (
        store
          .getState()
          .hubReducer.friendsList.filter((e) => e.UID === this.state.friendUID)
          .length > 0
      ) {
        let chatSettings = I18n.t('CHAT_PAGE.chat_settings');
        let viewProfile = I18n.t('CHAT_PAGE.view_profile');
        let block = I18n.t('CHAT_PAGE.block');
        let del = I18n.t('CHAT_PAGE.delete_chat');
        this.props.showAlert([
          chatSettings,
          '',
          [
            {
              text: viewProfile,
              onPress: () => {
                // Push the viewfriendpage and pass the friend key
                NavigationService.navigate('ViewfriendprofilePage', {
                  FRIEND_KEY: this.state.friendUID,
                });
              },
            },
            {
              text: block,
              onPress: () => {
                // Show block confirmation alert
                this.blockConfirmationAlert(
                  this.state.friendUID,
                  this.state.TITLE,
                );
              },
            },
            {
              text: del,
              onPress: () => {
                Chatcore.deletePrivateChat();
              },
            },
          ],
          false,
        ]);
      } else {
        let chatSettings = I18n.t('CHAT_PAGE.chat_settings');
        let viewProfile = I18n.t('CHAT_PAGE.view_profile');
        let addFriend = I18n.t('CHAT_PAGE.add_friend');
        let successAdd = I18n.t('CHAT_PAGE.success_add');
        let block = I18n.t('CHAT_PAGE.block');
        let del = I18n.t('CHAT_PAGE.delete_chat');

        // Show add friend option
        this.props.showAlert([
          chatSettings,
          '',
          [
            {
              text: viewProfile,
              onPress: () => {
                // Push the viewfriendpage and pass the friend key
                NavigationService.navigate('ViewfriendprofilePage', {
                  FRIEND_KEY: this.state.friendUID,
                });
              },
            },
            {
              text: addFriend,
              onPress: () => {
                // Add this friend
                FriendsListCore.addFriend(this.state.friendUID);
                // Show Toast
                // show a  success message
                presentToast({ message: this.state.TITLE + successAdd });
              },
            },
            {
              text: del,
              onPress: () => {
                Chatcore.deletePrivateChat();
              },
            },
            {
              text: block,
              onPress: () => {
                // Show block confirmation alert
                this.blockConfirmationAlert(
                  this.state.friendUID,
                  this.state.TITLE,
                );
              },
            },
          ],
          false,
        ]);
      }
    }
  };

  // This alert will show when the user request to block user
  blockConfirmationAlert(requesterId, requesterUsername) {
    let blockUser = I18n.t('CHAT_PAGE.block_user');
    let blockUserMsg1 = I18n.t('CHAT_PAGE.block_user_msg1');
    let blockThisUser = I18n.t('CHAT_PAGE.block_this_user');
    let successBlock = I18n.t('CHAT_PAGE.success_block');
    let no = I18n.t('CHAT_PAGE.no');
    this.props.showAlert([
      blockUser,
      blockUserMsg1,
      [
        {
          text: no,
          onPress: () => { },
        },
        {
          text: blockThisUser,
          onPress: () => {
            // get the requester id
            // add it to the this user blocked users : back end
            Chatcore.addToBlockList(requesterId, requesterUsername);
            // add it to the blocked list map : local
            let blockedList = store.getState().hubReducer.blockedList;
            blockedList.set(requesterId, requesterUsername);
            this.props.setHub({ blockedList });
            // Show toast
            presentToast({ message: requesterUsername + successBlock });

            // Now just pop this chat page
            clearFastImageCache();
            NavigationService.goBack();
          },
        },
      ],
    ]);
  }

  selectImage = async () => {
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
    })
    if (!result.cancelled) {
      this.setState({
        sendImageModalVisible: true,
        photoSource: result.uri,
      });
    }
  };

  sendImage = () => {
    global.featuresLogger.CHATS_PHOTOS();

    this.uploadImageToFirebase(this.state.photoSource);
  };

  uploadImageToFirebase(image) {
    //uploads img to firebase storage
    this.props.showSpinner({ title: I18n.t('POST_PAGE.uploading_photo') });
    this.uploadImage(image).then((photoURL) => {
      if (this.state.CHAT_TYPE === 'PRIVATE') {
        if (this.state.CHAT_STATUS === 'NEW') {
          let packet = new Packet();
          packet.type = 'image';
          packet.message = photoURL;
          packet.uid = this.props.uid;
          Chatcore.createNewChatRefWithFirstMessage(
            this.props.uid,
            this.state.friendUID,
            this.state.userMessage,
            this.state.TITLE,
            this.state.SUBTITLE,
            this.state.CHAT_IMG,
          );
          Chatcore.loadChat('PRIVATE', 'NONE');

          // Change the chat status to ongoing
          this.setState({ CHAT_STATUS: 'ONGOING' });
        } else {
          Chatcore.postMessage(
            this.props.uid,
            this.state.friendUID,
            this.state.userMessage,
            this.state.TITLE,
            this.state.SUBTITLE,
            this.state.CHAT_IMG,
            photoURL,
          );
        }
      } else {
        // For Public chats we will use different method
        Chatcore.postGroupMessage(
          this.state.friendUID,
          this.props.uid,
          photoURL,
          this.state.photoCaption,
          'image',
        );
      }
      this.setState({ userMessage: '' });
      this.props.hideSpinner();
      presentToast({ message: 'Photo sent successfully !' });
    });
  }

  uploadImage(imageURI) {
    let storageRef = firebase.storage().ref();
    if (this.state.CHAT_TYPE !== 'PRIVATE') {
      let filename = this.makeid(20) + '_' + this.props.uid;
      let imageRef = storageRef
        .child('ChatImgs')
        .child('Public')
        .child(this.props.tempChatRoomRef)
        .child(filename);
      return imageRef.putString(imageURI, 'data_url').then(() => {
        return imageRef.getDownloadURL().then((downloadURL) => {
          return downloadURL;
        });
      });
    } else {
      let filename = this.makeid(20) + '_' + this.props.uid;
      let imageRef = storageRef
        .child('ChatImgs')
        .child('Private')
        .child(this.props.tempChatRoomRef)
        .child(filename);
      return imageRef.putString(imageURI, 'data_url').then(() => {
        return imageRef.getDownloadURL().then((downloadURL) => {
          return downloadURL;
        });
      });
    }
  }

  makeid(length) {
    let result = '';
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  onChangeDescription = (text) => {
    this.setState({ inputText: text });
  };

  // Present prompt to change group name
  presentPrompt() {
    let titleTxt = I18n.t('COMMUNITY_PAGE.new_group_name');
    let placeholderTxt = I18n.t('COMMUNITY_PAGE.group_name');
    let applyTxt = I18n.t('COMMUNITY_PAGE.apply');
    let cancelTxt = I18n.t('COMMUNITY_PAGE.cancel');

    this.props.showInputAlert([
      titleTxt,
      <TextInput
        placeholder={placeholderTxt}
        onChangeText={(text) => this.onChangeDescription(text)}
        customStyles={{
          marginTop: perfectSize(32),
          borderBottomColor: 'white',
          borderBottomWidth: 1,
          paddingHorizontal: perfectSize(0),
          borderRadius: 0,
        }}
        inputStyles={{ paddingLeft: perfectSize(4) }}
        placeholderTextColor={'gray'}
      />,
      [
        {
          text: applyTxt,
          onPress: () => {
            let name = this.state.inputText;
            if (name.length < 3) {
              presentToast({
                message: I18n.t(
                  'COMMUNITY_PAGE.group_name_min_length_validation',
                ),
              });
              return false;
            } else {
              this.changeGroupName(name);
              return true;
            }
          },
        },
        {
          text: cancelTxt,
          onPress: () => { },
        },
      ],
    ]);
  }

  changeGroupName(name) {
    CommunityCore.changeGroupName(this.props.tempChatRoomRef, name);
    this.setState({
      TITLE: name,
    });
  }

  // This function will either view the user profile or the lobby if the chat is public
  viewInfo = () => {
    if (this.state.CHAT_TYPE === 'PUBLIC') {
      NavigationService.navigate('LobbyPage', {
        chatRef: this.props.tempChatRoomRef,
        fromChat: true,
      });
    } else if (this.state.CHAT_TYPE === 'PRIVATE') {
      NavigationService.navigate('ViewfriendprofilePage', {
        FRIEND_KEY: this.state.friendUID,
      });
    }
  };

  getHolderType(packet) {
    let { uid } = this.props;
    if (packet.uid === uid) {
      return 0;
    } else if (
      packet.uid !== uid &&
      packet.uid !== 'JOINED' &&
      packet.uid !== 'LEFT' &&
      packet.uid !== 'GROUP_NAME_CHANGE'
    ) {
      return 1;
    } else if (packet.uid === 'JOINED') {
      return 2;
    } else if (packet.uid === 'LEFT') {
      return 2;
    } else if (packet.uid === 'GROUP_NAME_CHANGE') {
      return 2;
    }
  }

  openAD = () => {
    // First log the click for this ad
    const { AD } = this.state;
    if (AD != null && AD.url != null) {
      global.featuresLogger.PUBLIC_CHAT_AD_CLICK();
      AdService.checkAdUrl(AD);
    }
  };

  deleteMessage = (item, index) => {
    Chatcore.deleteGroupMessage(
      item.uid,
      item.chatRef,
      item.messageId,
      index === 0,
    );
  };

  showStickersModal = () => {
    this.setState({ stickersModalVisible: true });
  };

  sendSticker = (photoURL) => {
    global.featuresLogger.CHAT_STICKER_LOGGER('PUBLIC', 'send');
    if (this.state.CHAT_TYPE === 'PRIVATE') {
      if (this.state.CHAT_STATUS === 'NEW') {
        let packet = new Packet();
        packet.type = 'sticker';
        packet.message = photoURL;
        packet.uid = this.props.uid;
        Chatcore.createNewChatRefWithFirstMessage(
          this.props.uid,
          this.state.friendUID,
          this.state.userMessage,
          this.state.TITLE,
          this.state.SUBTITLE,
          this.state.CHAT_IMG,
          true,
        );
        Chatcore.loadChat('PRIVATE', 'NONE');

        // Change the chat status to ongoing
        this.setState({ CHAT_STATUS: 'ONGOING' });
      } else {
        Chatcore.postMessage(
          this.props.uid,
          this.state.friendUID,
          this.state.userMessage,
          this.state.TITLE,
          this.state.SUBTITLE,
          this.state.CHAT_IMG,
          photoURL,
          true,
        );
      }
    } else {
      // For Public chats we will use different method
      Chatcore.postGroupMessage(
        this.state.friendUID,
        this.props.uid,
        photoURL,
        this.state.photoCaption,
        'sticker',
      );
    }
    this.setState({ userMessage: '' });
    this.setState({ stickersModalVisible: false });
    presentToast({ message: 'Sticker sent successfully !' });
    Keyboard.dismiss();
  };

  render() {
    const {
      CHAT_IMG,
      CHAT_TYPE,
      CHAT_BORDERCOLOR,
      TITLE,
      SUBTITLE,
      userMessage,
      AD,
      sendImageModalVisible,
      stickersModalVisible,
      photoSource,
    } = this.state;
    const chatPacketsArray = Array.from(this.props.chatPacketsArray)
      .reverse()
      .filter((packet) => !this.props.bannedUsers.includes(packet.uid));

    return (
      <View style={AppStyles.rootContainer}>
        <SendImageModal
          isVisible={sendImageModalVisible}
          photo={{
            url: photoSource,
          }}
          onCancel={() => this.setState({ sendImageModalVisible: false })}
          onSend={(caption) => {
            this.setState({
              photoCaption: caption,
              sendImageModalVisible: false,
            });
            this.sendImage();
          }}
        />
        {stickersModalVisible &&
          <StickersModal
            isVisible={stickersModalVisible}
            onHide={() => {
              this.setState({ stickersModalVisible: false });
            }}
            chatType="PUBLIC"
            onSendSticker={this.sendSticker}
          />
        }
        {/* Header */}
        <View style={styles.header}>
          <View style={styles.headerLeft}>
            <TouchableOpacity
              style={AppStyles.leftBarButton}
              onPress={() => {
                clearFastImageCache();
                NavigationService.goBack();
              }}>
              <Feather
                name="chevron-left"
                color="gray"
                size={perfectSize(22.5)}
              />
            </TouchableOpacity>
            <TouchableOpacity onPress={() => this.viewInfo()}>
              <UserAvatar
                style={styles.avatar}
                src={CHAT_IMG}
                size={Dimens.NAV_BAR_AVATAR_SIZE}
                borderColor={
                  CHAT_TYPE === 'PRIVATE' ? CHAT_BORDERCOLOR : 'transparent'
                }
              />
            </TouchableOpacity>
            <View style={styles.wrapper}>
              <Label size="medium" bold={true}>
                {TITLE}
              </Label>
              <Label size="small" style={styles.subTitle} numberOfLines={1}>
                {SUBTITLE}
              </Label>
            </View>
          </View>
          <View style={styles.headerRight}>
            <TouchableOpacity
              style={AppStyles.rightBarButton}
              onPress={() => this.showChatSettingsAlert()}>
              <Feather
                name="more-vertical"
                color="gray"
                size={perfectSize(20)}
              />
            </TouchableOpacity>
          </View>
        </View>
        {/* Content */}
        <View style={[AppStyles.rootContent, { paddingHorizontal: 0 }]}>
          <KeyboardAwareScrollView
            scrollEnabled={false}
            contentContainerStyle={AppStyles.fullFlex}
            extraHeight={perfectSize(150)}
            keyboardShouldPersistTaps="always">
            {/* Messages */}
            <View
              style={[
                AppStyles.fullFlex,
                { paddingHorizontal: dimens.BODY_PADDING_HORIZONTAL },
              ]}>
              {/* AD */}
              {AD != null && AD !== 'NONE' && (
                <TouchableOpacity
                  style={styles.adCard}
                  onPress={() => this.openAD()}>
                  {AD.img != null && (
                    <ImageWithPlaceholder style={styles.adCover} uri={AD.img} />
                  )}
                </TouchableOpacity>
              )}
              <FlatList
                inverted
                ref={(ref) => (this.flatList = ref)}
                data={chatPacketsArray}
                showsVerticalScrollIndicator={false}
                removeClippedSubviews={false}
                renderItem={({ item, index }) => (
                  <ChatMessageHolder
                    type={this.getHolderType(item)}
                    item={{
                      type: item.type,
                      message: item.message,
                      timestamp: item.timestamp,
                      photo: item.photo,
                      uid: item.uid,
                      username: CHAT_TYPE === 'PUBLIC' ? item.username : null,
                      deleted: item.deleted,
                      photoCaption: item.photoCaption,
                    }}
                    index={index}
                    onDelete={() => this.deleteMessage(item, index)}
                  />
                )}
                // onContentSizeChange={() =>
                //   this.flatList.scrollToEnd({animated: false})
                // }
                // onLayout={() => this.flatList.scrollToEnd({animated: false})}
                keyExtractor={(item, index) => index.toString()}
              />
            </View>
            {/* Send message */}
            <View style={styles.footer}>
              <View style={styles.inputBox}>
                <TextInput
                  multiline
                  customStyles={styles.textInput}
                  placeholder={I18n.t('CHAT_PAGE.type_message')}
                  value={userMessage}
                  onChangeText={(value) =>
                    this.setState({
                      userMessage: value,
                    })
                  }
                  leftComponent={
                    <TouchableOpacity
                      onPress={() => this.showStickersModal()}
                      style={AppStyles.stickersWrap}>
                      <LocalImageOpacity
                        source={STICKERS_ICON}
                        style={AppStyles.stickersIcon}
                      />
                    </TouchableOpacity>
                  }
                  rightComponent={
                    <View style={styles.flexRow}>
                      <TouchableOpacity onPress={() => this.selectImage()}>
                        <Entypo
                          name="attachment"
                          size={20}
                          color="gray"
                          style={{
                            marginLeft: perfectSize(8),
                            transform: [{ rotate: '90deg' }],
                          }}
                        />
                      </TouchableOpacity>
                    </View>
                  }
                />
              </View>
              <PrimaryButton
                text={I18n.t('SUPPORT_PAGE.send')}
                type="filled"
                onPress={() => this.sendMessage()}
                customStyles={styles.sendBtn}
                textCustomStyles={{ fontSize: perfectSize(15) }}
              />
            </View>
          </KeyboardAwareScrollView>
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
    uid: state.authReducer.uid,
    chatPacketsArray: state.hubReducer.chatPacketsArray,
    tempChatRoomRef: state.hubReducer.tempChatRoomRef,
    bannedUsers: state.hubReducer.bannedUsers,
  };
};

export default compose(
  connect(mapStateToProps, {
    switchTheme,
    setHub,
    showAlert,
    showInputAlert,
    showSpinner,
    hideSpinner,
  }),
)(ChatPage);
