export default class RatingPlayer {
  constructor() {
    // This model will used in group rating page
    // For adding the rating feature

    this.username = '';
    this.uid = '';
    this.gameProvider = '';
    this.gameProviderAcc = '';
    this.photoUrl = '';

    this.pcGameProvider = '';
    this.XBOX_live_account = '';
    this.PSN_account = '';

    // Used to fix a bug
    this.platform = '';

    // All players map
    this.players = new Map();

    // Rating Stars
    this.rating = 'ZERO';
    // Rating Detector Attr
    this.rated = false;
  }
}
