import React, {memo, Component} from 'react';
import {View, StyleSheet} from 'react-native';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import I18n from '../../../i18n';

import StarRating from 'react-native-star-rating';
import UserAvatar from '../../user-avatar';
import Label from '../../label';

import {perfectSize} from '../../../helpers/deviceHelper';
import {STAR, STAR_BLANK} from '../../../constants/images';

class RateCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      starCount: props.starCount,
    };
  }

  onStarRatingPress(rating) {
    this.setState({
      starCount: rating,
    });
    this.props.onRate(rating);
  }

  render() {
    const {theme, item, disabled} = this.props;
    const _styles = styles(theme);
    const {starCount} = this.state;
    return (
      <View style={_styles.container}>
        <UserAvatar src={item.photoUrl} />
        <Label size="x_small" style={_styles.username}>
          {item.username}
        </Label>
        {disabled && (
          <Label>{I18n.t('GROUP_RATING_PAGE.done_rating_div_msg')}</Label>
        )}
        <StarRating
          style={_styles.ratebar}
          emptyStar={STAR_BLANK}
          fullStar={STAR}
          starSize={20}
          rating={starCount}
          selectedStar={(rating) => this.onStarRatingPress(rating)}
          disabled={disabled}
        />
      </View>
    );
  }
}

const styles = (theme) =>
  StyleSheet.create({
    container: {
      padding: perfectSize(12),
      justifyContent: 'center',
      alignItems: 'center',
    },
    username: {
      color: '#FFFFFF',
      marginTop: perfectSize(4),
      marginBottom: perfectSize(2),
    },
  });

RateCard.propTypes = {
  item: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
  };
};

export default memo(connect(mapStateToProps)(RateCard));
