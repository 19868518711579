import {store} from '../../redux/store';
import firebase from 'firebase/app';
import 'firebase/database';

import * as FirebasePaths from '../../constants/firebasePaths';
import Request from '../../firebasecontrollers/request';
import Packet from '../../models/packet';
import UserChatRef from '../../models/userchatref';
import {setUserInfo} from '../../redux/modules/auth/actions';

export default class Editrequestcore {
  static saveGameProviderAccount(pcGameProvider, providerAcc, platform, game) {
    let uid = store.getState().authReducer.uid;

    // _users_info_ / uid / info

    let path =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      uid +
      '/' +
      FirebasePaths.FIREBASE_DETAILS_ATTR;

    // Getting the platform to insert game provider

    if (platform == 'PS') {
      // _users_info_ / uid / info / PSN_account

      firebase.database()
        .ref(path + '/' + FirebasePaths.FIREBASE_USER_PS_GAME_PROVIDER_ATTR)
        .set(providerAcc);

      // Save it to userinfoDB
      store.dispatch(
        setUserInfo({
          PSNAcc: providerAcc,
        }),
      );
    } else if (platform == 'XBOX') {
      // _users_info_ / uid / info / XBOX_LIVE_ACCOUNT

      firebase.database()
        .ref(path + '/' + FirebasePaths.FIREBASE_USER_XBOX_GAME_PROVIDER_ATTR)
        .set(providerAcc);

      // Save it to userinfoDB

      store.dispatch(
        setUserInfo({
          XboxLiveAcc: providerAcc,
        }),
      );
    } else if (platform == 'NINTENDO') {
      // _users_info_ / uid / info / XBOX_LIVE_ACCOUNT

      firebase.database()
        .ref(
          path + '/' + FirebasePaths.FIREBASE_USER_NINTENDO_GAME_PROVIDER_ATTR,
        )
        .set(providerAcc);

      // Save it to userinfoDB
      store.dispatch(
        setUserInfo({
          nintendoAcc: providerAcc,
        }),
      );
    } else if (platform == 'MOBILE') {
      // _users_info_ / uid / info / XBOX_LIVE_ACCOUNT

      firebase.database()
        .ref(
          path +
            '/' +
            FirebasePaths.FIREBASE_USER_MOBILE_GAME_PROVIDER_ATTR +
            '/' +
            game +
            ' ' +
            'Mobile',
        )
        .set(providerAcc);

      // Save it to userinfoDB

      let mobileGamesAccs = store.getState().authReducer.userinformationDB
        .mobileGamesAccs;

      mobileGamesAccs.set(game + ' ' + 'Mobile', providerAcc);
      store.dispatch(
        setUserInfo({
          mobileGamesAccs: mobileGamesAccs,
        }),
      );
    } else if (platform == 'PC') {
      // _users_info_ / uid / info / PC_providers / eg : Steam : value

      firebase.database()
        .ref(
          path +
            '/' +
            FirebasePaths.FIREBASE_USER_PC_GAME_PROVIDER_ATTR +
            '/' +
            pcGameProvider,
        )
        .set(providerAcc);

      // Save it to userinfoDB
      let pcGamesAccs = store.getState().authReducer.userinformationDB
        .pcGamesAccs;

      pcGamesAccs.set(pcGameProvider, providerAcc);
      store.dispatch(
        setUserInfo({
          pcGamesAccs: pcGamesAccs,
        }),
      );
    }
  }

  // This function will update the request model in the database

  static updateReqFirebase(requestModel) {
    // Getting the user path

    let uid = store.getState().authReducer.uid;

    // _users_info / uid / _games_ / _games_ / _saved_requests_

    let savedRequestPath =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      uid +
      '/' +
      FirebasePaths.FIREBASE_GAMES_REFERENCES +
      '/' +
      FirebasePaths.FIREBASE_SAVED_REQS_ATTR;

    // Set this model in firebase

    firebase.database()
      .ref(
        savedRequestPath +
          '/' +
          FirebasePaths.FIREBASE_SAVED_REQUESTS_REQUESTS_ATTR +
          '/' +
          requestModel.savedRequestUniqueId,
      )
      .set(requestModel);

    // Setting the count of saved requests

    firebase.database()
      .ref(savedRequestPath + '/' + 'count')
      .set(store.getState().hubReducer.savedRequestsList.size);
  }
}
