/* eslint-disable react-native/no-inline-styles */
import React, { memo, Component } from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import I18n from '../../i18n';
import Feather from 'react-native-vector-icons/Feather';

import Label from '../label';
import ImageWithPlaceholder from '../image-with-placeholder';
import UserAvatar from '../user-avatar';
import { perfectSize } from '../../helpers/deviceHelper';
import Pipes from '../../services/pipes';
import NavigationService from '../../navigation/navigationService';
import Postcore from '../../pages/post/postcore';
import Sticker from '../sticker-image';
import { defaultProfileImage } from '../../constants/images';
import { forceShowMessages } from '../../services/utils';

class OpponentMessageHolder extends Component {
  constructor(props) {
    super(props);
    this.mount = true;
    this.state = {
      borderColor: '',
      userAvatar: '',
    };
  }

  componentDidMount() {
    this.loadData();
  }

  componentWillUnmount() {
    this.mount = false;
  }

  loadData() {
    const { item } = this.props;
    Postcore.getUserBorderColor(item.uid).then((borderColor) => {
      if (this.mount) {
        this.setState({ borderColor });
      }
    });

    Postcore.getUserAvatar(item.uid).then((userAvatar) => {
      if (this.mount) {
        this.setState({
          userAvatar: userAvatar,
        });
      }
    });
  }

  // This function will redirect the user to the sender profile page
  toUserprofilepage = (packet) => {
    // Push the viewfriendpage and pass the friend key
    NavigationService.navigate('ViewfriendprofilePage', {
      FRIEND_KEY: packet.uid,
    });
  };

  viewPhoto = (imageUrl) => {
    NavigationService.navigate('PhotoViewerPage', {
      TITLE: 'Chat Image',
      IMG: imageUrl,
    });
  };

  render() {
    const { theme, item } = this.props;
    const _styles = styles(theme);
    const { borderColor, userAvatar } = this.state;
    return (
      <View style={_styles.container}>
        <View style={_styles.flexRow}>
          <TouchableOpacity onPress={() => this.toUserprofilepage(item)}>
            <UserAvatar
              src={userAvatar}
              style={_styles.avatar}
              borderColor={borderColor}
            />
          </TouchableOpacity>
          <View style={_styles.content}>
            {!item.deleted || forceShowMessages ? (
              <>
                {item.type !== 'image' && item.type !== 'sticker' && (
                  <View style={_styles.wrapper}>
                    {item.username != null && (
                      <Label size="small" style={_styles.name}>
                        {item.username}
                      </Label>
                    )}
                    <Label
                      size="x_small"
                      style={_styles.message}
                      selectable={true}>
                      {item.message}
                    </Label>
                    <Label size="small" style={_styles.timestamp}>
                      {Pipes.humantime(item.timestamp)}
                    </Label>
                  </View>
                )}
                {item.type === 'image' && (
                  <View
                    style={[_styles.photoWrapper, { width: perfectSize(208) }]}>
                    {item.username != null && (
                      <Label
                        size="small"
                        style={[_styles.name, { marginLeft: perfectSize(8) }]}>
                        {item.username}
                      </Label>
                    )}
                    <TouchableOpacity
                      onPress={() => this.viewPhoto(item.message)}>
                      <ImageWithPlaceholder
                        uri={item.message}
                        style={_styles.photo}
                      />
                    </TouchableOpacity>
                    {!!item.photoCaption && (
                      <Label
                        size="x_small"
                        style={[
                          _styles.message,
                          {
                            marginTop: perfectSize(8),
                            marginLeft: perfectSize(8),
                            textAlign: 'left',
                          },
                        ]}
                        selectable={true}>
                        {item.photoCaption}
                      </Label>
                    )}
                    <Label
                      size="small"
                      style={[
                        _styles.timestamp,
                        {
                          marginTop: perfectSize(8),
                          marginRight: perfectSize(4),
                        },
                      ]}>
                      {Pipes.humantime(item.timestamp)}
                    </Label>
                  </View>
                )}
                {item.type === 'sticker' && (
                  <View
                    style={[_styles.photoWrapper, { padding: perfectSize(12) }]}>
                    <Sticker url={item.message} />
                    <Label
                      size="small"
                      style={[
                        _styles.timestamp,
                        { marginTop: perfectSize(8), marginLeft: perfectSize(4) },
                      ]}>
                      {Pipes.humantime(item.timestamp)}
                    </Label>
                  </View>
                )}
              </>
            ) : (
              <View style={_styles.wrapper}>
                {item.username != null && (
                  <Label size="small" style={_styles.name}>
                    {item.username}
                  </Label>
                )}
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}>
                  <Feather
                    name="slash"
                    color="#ffffff80"
                    size={perfectSize(16)}
                  />
                  <Label
                    size="x_small"
                    style={[
                      _styles.message,
                      {
                        marginTop: 0,
                        marginLeft: perfectSize(8),
                        fontStyle: 'italic',
                        color: '#ffffff80',
                      },
                    ]}
                    selectable={false}>
                    {I18n.t('CHAT_PAGE.deleted_message_other')}
                  </Label>
                </View>
                <Label size="small" style={_styles.timestamp}>
                  {Pipes.humantime(item.timestamp)}
                </Label>
              </View>
            )}
          </View>
        </View>
      </View>
    );
  }
}

const styles = (theme) =>
  StyleSheet.create({
    container: {
      marginBottom: perfectSize(12),
    },
    flexRow: {
      flexDirection: 'row',
    },
    avatar: {
      marginRight: perfectSize(8),
    },
    content: {
      flex: 1,
    },
    wrapper: {
      backgroundColor: '#181F29',
      paddingHorizontal: perfectSize(12),
      paddingVertical: perfectSize(8),
      borderTopStartRadius: perfectSize(12),
      borderTopEndRadius: perfectSize(12),
      borderBottomEndRadius: perfectSize(12),
      alignSelf: 'baseline',
      minWidth: '30%',
    },
    name: {
      color: '#A2A5A9',
      marginBottom: perfectSize(4),
    },
    message: {
      marginTop: perfectSize(4),
    },
    timestamp: {
      color: '#A2A5A9',
      fontWeight: '300',
      marginTop: perfectSize(4),
      textAlign: 'right',
    },
    photoWrapper: {
      backgroundColor: '#181F29',
      borderTopLeftRadius: perfectSize(12),
      borderTopRightRadius: perfectSize(12),
      borderBottomRightRadius: perfectSize(12),
      padding: perfectSize(4),
      alignSelf: 'baseline',
    },
    photo: {
      width: perfectSize(200),
      height: perfectSize(200),
      borderTopLeftRadius: perfectSize(12),
      borderTopRightRadius: perfectSize(12),
      borderBottomRightRadius: perfectSize(12),
    },
  });

OpponentMessageHolder.propTypes = {
  item: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
  };
};

export default memo(connect(mapStateToProps)(OpponentMessageHolder));
