import { StyleSheet } from 'react-native';
import dimens from '../../../constants/dimens';
import { perfectSize } from '../../../helpers/deviceHelper';

const styles = (theme) =>
    StyleSheet.create({
        titleWrap: {
            flexDirection: 'row',
            alignItems: 'center'
        },
        title: {
            textAlign: 'center',
            color: theme.primary_text,
        },
        hootsCover: {
            width: perfectSize(23),
            height: perfectSize(23),
            marginLeft: perfectSize(8)
        },
        hootsSpeaking: {
            width: perfectSize(18),
            height: perfectSize(18),
            top: perfectSize(30)
        },
        hootsSpeakingOverlay: {
            width: perfectSize(54),
            height: perfectSize(54),
            top: perfectSize(13),
            borderWidth: 1,
            borderRadius: perfectSize(27),
            borderColor: '#C70064'
        },
        overlayWrap: {
            width: '100%',
            height: '100%',
            borderTopLeftRadius: dimens.HEADER_PADDING_HORIZONTAL,
            borderTopRightRadius: dimens.HEADER_PADDING_HORIZONTAL,
            overflow: 'hidden',
        },
        gradient: {
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            height: '100%',
        },
        fullSize: {
            width: '100%',
            height: '100%',
            opacity: 0.1,
            // resizeMode: 'contain',
        },
        contentWrap: {
            paddingHorizontal: perfectSize(16),
            paddingTop: perfectSize(36),
            height: '100%',
            width: '100%',
            borderTopLeftRadius: dimens.HEADER_PADDING_HORIZONTAL,
            borderTopRightRadius: dimens.HEADER_PADDING_HORIZONTAL,

        },
        subContainer: {
            marginTop: perfectSize(32),
        },
        centerWrapper: {
            justifyContent: 'center',
            alignItems: 'center',
        },
        space: {
            flex: 1,
        },
        footerWrap: {
            position: 'absolute',
            bottom: 0,
            width: '100%',
        },
        footer: {
            flexDirection: 'row',
            paddingVertical: perfectSize(16),
            alignItems: 'center',
            justifyContent: 'center',
        },
        submitTextStyle: {
            fontSize: perfectSize(15),
        },
        userWrap: {
            marginBottom: perfectSize(32),
            alignItems: 'center',
            opacity: 1,
        },
        actionWrap: {
            width: perfectSize(56),
            height: perfectSize(56),
            borderRadius: perfectSize(28),
            alignItems: 'center',
            justifyContent: 'center',
            marginHorizontal: perfectSize(16)
        },
        actionNormal: {
            borderColor: 'white',
            borderWidth: perfectSize(1)
        },
        actionClicked: {
            backgroundColor: 'white'
        },
        actionFill: {
            backgroundColor: '#D34D72'
        },
        actionMute: {
            width: perfectSize(25),
            height: perfectSize(21.78),
            resizeMode: 'contain'
        },
        actionMicOff: {
            width: perfectSize(25),
            height: perfectSize(29.49),
            resizeMode: 'contain',
            // tintColor: 'black'
        },
        actionCallEnd: {
            width: perfectSize(25),
            height: perfectSize(25.32),
            resizeMode: 'contain'
        },
        dialogSectionContainer: {
            marginTop: perfectSize(16),
        },
        dialogDescription: {
            color: 'white',
            fontSize: 14,
            width: '100%',
        },
        inputBoxCustomStyle: {
            height: perfectSize(150),
            marginTop: perfectSize(24),
            backgroundColor: '#1E2836'
        },
        inputBoxStyle: {
            height: '100%',
            padding: 0,
        },
        crown: {
            width: perfectSize(16),
            height: perfectSize(11),
            marginBottom: perfectSize(2)
        },
    });

export default styles;
