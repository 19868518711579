import {StyleSheet} from 'react-native';
import {perfectSize} from '../../helpers/deviceHelper';

const styles = (theme) =>
  StyleSheet.create({
    flatList: {
      marginTop: perfectSize(24),
    },
    subTitle: {
      textAlign: 'center',
      marginVertical: perfectSize(16),
    },
  });

export default styles;
