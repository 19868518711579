import {perfectSize} from '../helpers/deviceHelper';

export default {
  // Global dimensions
  HEADER_PADDING_HORIZONTAL: perfectSize(24), // Todo: should remove
  HEADER_PADDING_VERTICAL: perfectSize(16), // Todo: should remove
  BODY_BORDER_TOP_RADIUS: perfectSize(24),
  NAV_BAR_AVATAR_SIZE: perfectSize(32),
  BODY_PADDING_TOP: perfectSize(24),
  BODY_PADDING_HORIZONTAL: perfectSize(24),
  HEADER_HEIGHT: 64,
  BAR_BUTTON_SIZE: perfectSize(32),
  CONTENT_PADDING_BOTTOM: perfectSize(16),

  // Number dimensions
  dp4: perfectSize(4),
  dp8: perfectSize(8),
  dp10: perfectSize(10),
  dp12: perfectSize(12),
  dp16: perfectSize(16),
  dp20: perfectSize(20),
  dp24: perfectSize(24),
  dp32: perfectSize(32),
  dp36: perfectSize(36),
  dp40: perfectSize(40),
  dp48: perfectSize(48),
  dp60: perfectSize(60),
};
