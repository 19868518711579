import {StyleSheet} from 'react-native';
import {perfectSize} from '../../helpers/deviceHelper';

const styles = (theme) =>
  StyleSheet.create({
    dash: {
      flex: 1,
      flexDirection: 'column',
      width: 1,
      marginLeft: perfectSize(15),
    },
    dot: {
      width: perfectSize(16),
      height: perfectSize(16),
      borderRadius: perfectSize(8),
      backgroundColor: theme.primary_btn_background,
      marginLeft: perfectSize(8),
    },
    shadow: {
      width: perfectSize(32),
      height: perfectSize(32),
      borderRadius: perfectSize(16),
      position: 'absolute',
      top: perfectSize(-8),
    },
    dotOutline: {
      width: perfectSize(16),
      height: perfectSize(16),
      borderRadius: perfectSize(8),
      borderWidth: 2,
      borderColor: theme.primary_btn_background,
      marginLeft: perfectSize(8),
    },
    connectLine: {
      flex: 1,
      height: 1,
      backgroundColor: '#3B1935',
      marginTop: perfectSize(7),
    },
    rankContainer: {
      height: perfectSize(86),
      flexDirection: 'row',
    },
    lineContainer: {
      flexDirection: 'row',
      width: perfectSize(36),
    },
    line: {
      width: perfectSize(36),
    },
    rankMainContainer: {
      flex: 1,
      paddingBottom: perfectSize(16),
    },
    commentContainer: {
      flex: 1,
      backgroundColor: theme.secondary_background,
      borderRadius: perfectSize(8),
      alignItems: 'center',
      justifyContent: 'center',
      padding: perfectSize(16),
    },
    comment: {
      textAlign: 'center',
      color: theme.tab_inactive_text_color,
    },
    rankBody: {
      flex: 1,
      backgroundColor: theme.btn_tag,
      borderRadius: 8,
      alignItems: 'center',
      justifyContent: 'center',
    },
    rankLabel: {
      fontSize: perfectSize(12),
      marginVertical: perfectSize(2),
    },
    rankPointer: {
      color: theme.tab_inactive_text_color,
    },
  });

export default styles;
