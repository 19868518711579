import firebase from 'firebase/app';
import 'firebase/database';

import * as FirebasePaths from '../../constants/firebasePaths';
import { store } from '../../redux/store';

export default class LaunchPopupCore {
    constructor() { }
    static async loadUserLaunchPopups() {
        const uid = store.getState().authReducer.uid
        const ref =
            FirebasePaths.FIREBASE_USERS_INFO_ATTR +
            '/' +
            uid +
            '/' +
            FirebasePaths.FIREBASE_USER_LAUNCH_POPUPS;
        return await firebase.database()
            .ref(ref)
            .once('value');
    }

    static async saveUserLaunchPopup(popupId) {
        const uid = store.getState().authReducer.uid
        const ref =
            FirebasePaths.FIREBASE_USERS_INFO_ATTR +
            '/' +
            uid +
            '/' +
            FirebasePaths.FIREBASE_USER_LAUNCH_POPUPS +
            '/' +
            popupId;
        await firebase.database().ref(ref).set(firebase.database.ServerValue.TIMESTAMP);
    }

    static async loadActivePopup(popupId) {
        const ref = FirebasePaths.FIREBASE_LAUNCH_POPUP +
            '/' + popupId;
        return await firebase.database().ref(ref).once('value');
    }

    static async getActivePopupId() {
        const ref = FirebasePaths.FIREBASE_LAUNCH_POPUP +
            '/' + FirebasePaths.FIREBASE_LAUNCH_POPUP_ACTIVE_ID;
        const snapshot = await firebase.database().ref(ref).once('value');
        return snapshot.val();
    }

    static async userAlreadySeenPopup(popupId) {
        const uid = store.getState().authReducer.uid
        const ref =
            FirebasePaths.FIREBASE_USERS_INFO_ATTR +
            '/' +
            uid +
            '/' +
            FirebasePaths.FIREBASE_USER_LAUNCH_POPUPS +
            '/' +
            popupId;
        const snapshot = await firebase.database().ref(ref).once('value');
        const val = snapshot.val();
        if (val) {
            return true;
        } else {
            return false;
        }
    }

    static async saveUserSurveyCollection(popupId, surveyKey) {
        const uid = store.getState().authReducer.uid
        const ref = FirebasePaths.FIREBASE_LAUNCH_POPUP_SURVEYS +
            '/' +
            popupId + 
            '/' +
            uid;
        await firebase.database().ref(ref).set(surveyKey);
    }
}
