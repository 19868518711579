import createActionConstants from '../../../helpers/actionCreatorHelper';

// auth types
export const LOGIN = createActionConstants('LOGIN');
export const SIGNUP = createActionConstants('SIGN_UP');
export const RESET_PASSWORD = createActionConstants('RESET_PASSWORD');
export const LOGOUT = 'LOGOUT';
export const LOAD_REACHSOURCES = createActionConstants('LOAD_REACHSOURCES');

// user info
// get users information : country, city, ...
export const GET_USER_INFO = createActionConstants('GET_USER_INFO');
export const UPDATE_PROFILE = createActionConstants('UPDATE_PROFILE');
// game providers (psn, xbox and nintendo)
export const UPDATE_PROFILE_ACCOUNTS = createActionConstants(
  'UPDATE_PROFILE_ACCOUNTS',
);

// set user hops icon
// set if user profile is completed or not
// set user default profile pic if he doesn't have a profile pic
// set user rank
// check if the hops shadow is activated
export const SET_USER_INFO = 'SET_USER_INFO';
export const SET_PROFILE_BORDER_COLOR = 'SET_PROFILE_BORDER_COLOR';

export const LOAD_MOBILE_GAMES_PROVIDER = 'LOAD_MOBILE_GAMES_PROVIDER';
export const LOAD_PC_GAMES_PROVIDER = 'LOAD_PC_GAMES_PROVIDER';

export const LOAD_BORDER_COLORS = 'LOAD_BORDER_COLORS';
export const SET_FRIENDS_LIST_PAGE_FIRST_VISIT = 'SET_FRIENDS_LIST_PAGE_FIRST_VISIT';
