import firebase from 'firebase/app';
import 'firebase/database';

import * as FirebasePaths from '../../constants/firebasePaths';
import { store } from '../../redux/store';
import UserChatRef from '../../models/userchatref';
import Packet from '../../models/packet';
import { set as setHub } from '../../redux/modules/hub/actions';
import { getGameByNameFromGameList } from '../../services/utils';

export default class Chatcore {
  static chatRef$ = null;

  // This function will create new chat ref for both users and post the first message
  static createNewChatRefWithFirstMessage(
    currentUserId,
    opponentId,
    userMessage,
    opponentUsername,
    opponentBio,
    opponentPic,
    imgUrl = '',
    photoCaption = '',
    isSticker = false,
  ) {
    // Generate message id and get the current timestamp
    let messageId = firebase.database().ref().push().key;
    let timestamp = firebase.database.ServerValue.TIMESTAMP;

    // Creating Chat new Ref  with unique Id
    let chatUniqueRef = firebase.database().ref().push().key;
    // Assign it to the temp variable hub
    store.dispatch(setHub({ tempChatRoomRef: chatUniqueRef }));

    // First post the data locally
    let packet = new Packet();
    packet.messageId = messageId;
    packet.timestamp = timestamp;
    packet.chatRef = chatUniqueRef;
    packet.username = store.getState().authReducer.userinformationDB.username;
    packet.uid = currentUserId;
    if (imgUrl != null && imgUrl !== '') {
      packet.type = isSticker ? 'sticker' : 'image';
      packet.message = imgUrl;
      userMessage = isSticker ? 'sent sticker' : 'sent photo';
    } else {
      packet.message = userMessage;
    }
    packet.photoCaption = photoCaption;
    packet.isSticker = isSticker;

    // Post the data locally first : hub , for the first message
    let chatPacketsArray = store.getState().hubReducer.chatPacketsArray;
    chatPacketsArray.push(packet);
    store.dispatch(
      setHub({
        chatPacketsArray,
      }),
    );

    // Getting the private chat ref
    let privateChatRef =
      FirebasePaths.FIREBASE_CHAT_ATTR +
      '/' +
      FirebasePaths.FIREBASE_PRIVATE_ATTR +
      '/' +
      chatUniqueRef +
      '/';

    // Now set the chat ref info users
    firebase.database()
      .ref(
        privateChatRef +
        '/' +
        FirebasePaths.FIREBASE_PRIVATE_CHAT_INFO +
        '/' +
        FirebasePaths.FIREBASE_PRIVATE_CHAT_USERS,
      )
      .child(currentUserId)
      .set(store.getState().authReducer.userinformationDB.username);
    firebase.database()
      .ref(
        privateChatRef +
        '/' +
        FirebasePaths.FIREBASE_PRIVATE_CHAT_INFO +
        '/' +
        FirebasePaths.FIREBASE_PRIVATE_CHAT_USERS,
      )
      .child(opponentId)
      .set(opponentUsername);

    // Post the first message and set it as the last message
    // Generate a message unique key
    // Get the current Timestamp

    // Post it as an object as last message
    firebase.database()
      .ref(
        privateChatRef +
        '/' +
        FirebasePaths.FIREBASE_CHAT_MESSAGES +
        '/' +
        FirebasePaths.FIREBASE_PRIVATE_CHAT_LAST_MESSAGE +
        '/',
      )
      .set(packet);
    // Post it as an object on messages as packet
    firebase.database()
      .ref(
        privateChatRef +
        '/' +
        FirebasePaths.FIREBASE_CHAT_MESSAGES +
        '/' +
        FirebasePaths.FIREBASE_PRIVATE_CHAT_PACKETS +
        '/' +
        messageId,
      )
      .set(packet);

    // Create  chat ref to set it for both users
    let currentUserChatRef = new UserChatRef();
    currentUserChatRef.opponentId = opponentId;
    currentUserChatRef.chatRef = chatUniqueRef;
    currentUserChatRef.lastMessage = userMessage;
    currentUserChatRef.lastMessageTimestamp = timestamp;
    currentUserChatRef.opponentPic = opponentPic;
    currentUserChatRef.senderId = store.getState().authReducer.uid;
    currentUserChatRef.opponentUsername = opponentUsername;
    currentUserChatRef.opponentBio = opponentBio;
    currentUserChatRef.opened = true;
    currentUserChatRef.isSticker = isSticker;
    let opponentUserChatRef = new UserChatRef();
    opponentUserChatRef.opponentId = currentUserId;
    opponentUserChatRef.chatRef = chatUniqueRef;
    opponentUserChatRef.lastMessage = userMessage;
    opponentUserChatRef.lastMessageTimestamp = timestamp;
    opponentUserChatRef.senderId = store.getState().authReducer.uid;
    opponentUserChatRef.opened = false;
    opponentUserChatRef.opponentBio = store.getState().authReducer.userinformationDB.bio;
    opponentUserChatRef.opponentUsername = store.getState().authReducer.userinformationDB.username;
    opponentUserChatRef.opponentPic = store.getState().authReducer.userinformationDB.pictureURL;
    opponentUserChatRef.isSticker = isSticker;

    // Finally set a ref for both user

    // First current user
    let currentUserPrivateChatRef =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      currentUserId +
      '/' +
      FirebasePaths.FIREBASE_USER_CHAT_REFERENCES +
      '/' +
      FirebasePaths.FIREBASE_PRIVATE_ATTR +
      '/' +
      chatUniqueRef +
      '/';
    firebase.database().ref(currentUserPrivateChatRef).set(currentUserChatRef);
    // Second the opponent
    let opponentPrivateChatRef =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      opponentId +
      '/' +
      FirebasePaths.FIREBASE_USER_CHAT_REFERENCES +
      '/' +
      FirebasePaths.FIREBASE_PRIVATE_ATTR +
      '/' +
      chatUniqueRef +
      '/';
    firebase.database().ref(opponentPrivateChatRef).set(opponentUserChatRef);
  }

  // This function will post  message to a reference and update the last message
  static postMessage(
    currentUserId,
    opponentId,
    userMessage,
    opponentUsername,
    opponentBio,
    opponentPic,
    imgUrl,
    photoCaption,
    isSticker = false,
  ) {
    // Generate message id and get the current timestamp
    let messageId = firebase.database().ref().push().key;
    let timestamp = firebase.database.ServerValue.TIMESTAMP;

    // First post the data locally
    let packet = new Packet();
    packet.messageId = messageId;
    packet.timestamp = timestamp;
    packet.chatRef = store.getState().hubReducer.tempChatRoomRef;
    packet.username = store.getState().authReducer.userinformationDB.username;
    packet.uid = currentUserId;
    if (imgUrl != null && imgUrl !== '') {
      packet.type = isSticker ? 'sticker' : 'image';
      packet.message = imgUrl;
      userMessage = isSticker ? 'sent sticker' : 'sent photo';
    } else {
      packet.message = userMessage;
    }
    packet.photoCaption = photoCaption;
    packet.isSticker = isSticker;

    // Post the data locally first : hub
    let chatPacketsArray = store.getState().hubReducer.chatPacketsArray;
    chatPacketsArray.push(packet);
    store.dispatch(
      setHub({
        chatPacketsArray,
      }),
    );

    // Getting the private chat ref
    let privateChatRef =
      FirebasePaths.FIREBASE_CHAT_ATTR +
      '/' +
      FirebasePaths.FIREBASE_PRIVATE_ATTR +
      '/' +
      store.getState().hubReducer.tempChatRoomRef +
      '/';

    // Now set the chat ref info users
    firebase.database()
      .ref(
        privateChatRef +
        '/' +
        FirebasePaths.FIREBASE_PRIVATE_CHAT_INFO +
        '/' +
        FirebasePaths.FIREBASE_PRIVATE_CHAT_USERS,
      )
      .child(currentUserId)
      .set(store.getState().authReducer.userinformationDB.username);
    firebase.database()
      .ref(
        privateChatRef +
        '/' +
        FirebasePaths.FIREBASE_PRIVATE_CHAT_INFO +
        '/' +
        FirebasePaths.FIREBASE_PRIVATE_CHAT_USERS,
      )
      .child(opponentId)
      .set(opponentUsername);

    // Post the first message and set it as the last message
    // Generate a message unique key
    // Get the current Timestamp

    // Post it as an object as last message : in chat ref
    firebase.database()
      .ref(
        privateChatRef +
        '/' +
        FirebasePaths.FIREBASE_CHAT_MESSAGES +
        '/' +
        FirebasePaths.FIREBASE_PRIVATE_CHAT_LAST_MESSAGE +
        '/',
      )
      .set(packet);
    // Post it as an object on messages as packet : in chat ref
    firebase.database()
      .ref(
        privateChatRef +
        '/' +
        FirebasePaths.FIREBASE_CHAT_MESSAGES +
        '/' +
        FirebasePaths.FIREBASE_PRIVATE_CHAT_PACKETS +
        '/' +
        messageId,
      )
      .set(packet);

    // Create  chat ref to set it for both users
    let currentUserChatRef = new UserChatRef();
    currentUserChatRef.opponentId = opponentId;
    currentUserChatRef.chatRef = store.getState().hubReducer.tempChatRoomRef;
    currentUserChatRef.lastMessage = userMessage;
    currentUserChatRef.lastMessageTimestamp = new Date().getTime();
    currentUserChatRef.opponentPic = opponentPic;
    currentUserChatRef.opponentUsername = opponentUsername;
    currentUserChatRef.senderId = store.getState().authReducer.uid;
    currentUserChatRef.opponentBio = opponentBio;
    currentUserChatRef.opened = true;
    currentUserChatRef.isSticker = isSticker;
    let opponentUserChatRef = new UserChatRef();
    opponentUserChatRef.opponentId = currentUserId;
    opponentUserChatRef.chatRef = store.getState().hubReducer.tempChatRoomRef;
    opponentUserChatRef.lastMessage = userMessage;
    opponentUserChatRef.senderId = store.getState().authReducer.uid;
    opponentUserChatRef.lastMessageTimestamp = new Date().getTime();
    opponentUserChatRef.opened = false;
    opponentUserChatRef.opponentBio = store.getState().authReducer.userinformationDB.bio;
    opponentUserChatRef.opponentUsername = store.getState().authReducer.userinformationDB.username;
    opponentUserChatRef.opponentPic = store.getState().authReducer.userinformationDB.pictureURL;
    opponentUserChatRef.isSticker = isSticker;

    // Finally set a ref for both user

    // First current user
    let currentUserPrivateChatRef =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      currentUserId +
      '/' +
      FirebasePaths.FIREBASE_USER_CHAT_REFERENCES +
      '/' +
      FirebasePaths.FIREBASE_PRIVATE_ATTR +
      '/' +
      store.getState().hubReducer.tempChatRoomRef +
      '/';
    firebase.database().ref(currentUserPrivateChatRef).set(currentUserChatRef);
    // Second the opponent
    let opponentPrivateChatRef =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      opponentId +
      '/' +
      FirebasePaths.FIREBASE_USER_CHAT_REFERENCES +
      '/' +
      FirebasePaths.FIREBASE_PRIVATE_ATTR +
      '/' +
      store.getState().hubReducer.tempChatRoomRef +
      '/';
    firebase.database().ref(opponentPrivateChatRef).set(opponentUserChatRef);
  }

  static deletePrivateChatMessage(
    uid,
    opponentId,
    chatRef,
    messageId,
    isLastMsg,
  ) {
    // Post the data locally first : hub
    let chatPacketsArray = store.getState().hubReducer.chatPacketsArray;
    chatPacketsArray.forEach((obj) => {
      if (obj.messageId === messageId) {
        obj.deleted = true;
      }
    });
    store.dispatch(
      setHub({
        chatPacketsArray,
      }),
    );

    // Getting the private chat ref
    let privateChatRef =
      FirebasePaths.FIREBASE_CHAT_ATTR +
      '/' +
      FirebasePaths.FIREBASE_PRIVATE_ATTR +
      '/' +
      chatRef;
    // Post it as an object as last message : in chat ref
    firebase.database()
      .ref(
        privateChatRef +
        '/' +
        FirebasePaths.FIREBASE_CHAT_MESSAGES +
        '/' +
        FirebasePaths.FIREBASE_PRIVATE_CHAT_LAST_MESSAGE,
      )
      .update({ deleted: true });
    // Post it as an object on messages as packet : in chat ref
    firebase.database()
      .ref(
        privateChatRef +
        '/' +
        FirebasePaths.FIREBASE_CHAT_MESSAGES +
        '/' +
        FirebasePaths.FIREBASE_PRIVATE_CHAT_PACKETS +
        '/' +
        messageId,
      )
      .update({ deleted: true });

    if (isLastMsg) {
      // First current user
      let currentUserPrivateChatRef =
        FirebasePaths.FIREBASE_USERS_INFO_ATTR +
        '/' +
        uid +
        '/' +
        FirebasePaths.FIREBASE_USER_CHAT_REFERENCES +
        '/' +
        FirebasePaths.FIREBASE_PRIVATE_ATTR +
        '/' +
        chatRef +
        '/';
      firebase.database().ref(currentUserPrivateChatRef).update({ deleted: true });
      // Second the opponent
      let opponentPrivateChatRef =
        FirebasePaths.FIREBASE_USERS_INFO_ATTR +
        '/' +
        opponentId +
        '/' +
        FirebasePaths.FIREBASE_USER_CHAT_REFERENCES +
        '/' +
        FirebasePaths.FIREBASE_PRIVATE_ATTR +
        '/' +
        chatRef +
        '/';
      firebase.database().ref(opponentPrivateChatRef).update({ deleted: true });
    }
  }

  // This function will be used to post message in group chat
  static postGroupMessage(
    groupKey,
    currentUserId,
    userMessage,
    photoCaption,
    type,
  ) {
    // First :  post the packet in group chat ref

    // Generate message id and get the current timestamp
    let messageId = firebase.database().ref().push().key;
    let timestamp = firebase.database.ServerValue.TIMESTAMP;

    // Create packet object
    let packet = new Packet();
    packet.messageId = messageId;
    packet.message = userMessage;
    packet.photoCaption = photoCaption;
    packet.timestamp = timestamp;
    packet.chatRef = store.getState().hubReducer.tempChatRoomRef;
    packet.username = store.getState().authReducer.userinformationDB.username;
    packet.uid = currentUserId;
    packet.type = type;
    const isSticker = type === 'sticker' ? true : false;
    packet.isSticker = isSticker;

    // Post the data locally first : hub : THIS
    // Gertting group chat ref and post the packet and the last message
    let groupChatRef =
      FirebasePaths.FIREBASE_CHAT_ATTR +
      '/' +
      FirebasePaths.FIREBASE_PUBLIC_ATTR +
      '/' +
      packet.chatRef +
      '/' +
      FirebasePaths.FIREBASE_CHAT_MESSAGES;

    // Packets
    firebase.database()
      .ref(
        groupChatRef +
        '/' +
        FirebasePaths.FIREBASE_PUBLIC_CHAT_PACKETS +
        '/' +
        messageId +
        '/',
      )
      .set(packet);
    // Last message
    firebase.database()
      .ref(
        groupChatRef +
        '/' +
        FirebasePaths.FIREBASE_PUBLIC_CHAT_LAST_MESSAGE +
        '/',
      )
      .set(packet);

    // Set message ref for all users
    // First set it for the current user
    let shot = {
      [FirebasePaths.FIREBASE_PUBLIC_USER_REF_LAST_MESSAGE]: userMessage,
      [FirebasePaths.FIREBASE_PUBLIC_USER_REF_LAST_MESSAGE_TIMESTAMP]: timestamp,
      [FirebasePaths.FIREBASE_PUBLIC_USER_REF_SENDER_ID]: packet.uid,
      [FirebasePaths.FIREBASE_PUBLIC_USER_REF_OPENED]: true,
      ['isSticker']: isSticker,
      ['deleted']: false,
    };
    // current user chat ref
    let ref =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      packet.uid +
      '/' +
      FirebasePaths.FIREBASE_USER_CHAT_REFERENCES +
      '/' +
      FirebasePaths.FIREBASE_PUBLIC_ATTR +
      '/' +
      packet.chatRef +
      '/';
    firebase.database().ref(ref).update(shot);

    // Then other users
    if (
      store.getState().hubReducer.groupChatMembers[packet.chatRef] !== undefined
    ) {
      let membersObj = store.getState().hubReducer.groupChatMembers[
        packet.chatRef
      ];
      let iterator = membersObj.members.keys();

      // Setting the members size
      store.dispatch(setHub({ membersLimit: membersObj.members.size }));
      for (let i = 0; i < membersObj.members.size; i++) {
        this.setUserChatRefGroupChat(
          packet.chatRef,
          iterator.next().value,
          timestamp,
          currentUserId,
          userMessage,
          i,
        );
      }
    }
  }

  static deleteGroupMessage(uid, chatRef, messageId, isLastMsg) {
    let groupChatRef =
      FirebasePaths.FIREBASE_CHAT_ATTR +
      '/' +
      FirebasePaths.FIREBASE_PUBLIC_ATTR +
      '/' +
      chatRef +
      '/' +
      FirebasePaths.FIREBASE_CHAT_MESSAGES;
    firebase.database()
      .ref(
        groupChatRef +
        '/' +
        FirebasePaths.FIREBASE_PUBLIC_CHAT_PACKETS +
        '/' +
        messageId,
      )
      .update({ deleted: true });

    // Last message
    firebase.database()
      .ref(groupChatRef + '/' + FirebasePaths.FIREBASE_PUBLIC_CHAT_LAST_MESSAGE)
      .update({ deleted: true });

    // Set message ref for all users
    if (isLastMsg) {
      // First set it for the current user
      // current user chat ref
      let ref =
        FirebasePaths.FIREBASE_USERS_INFO_ATTR +
        '/' +
        uid +
        '/' +
        FirebasePaths.FIREBASE_USER_CHAT_REFERENCES +
        '/' +
        FirebasePaths.FIREBASE_PUBLIC_ATTR +
        '/' +
        chatRef +
        '/';
      firebase.database().ref(ref).update({ deleted: true });

      // Then other users
      if (store.getState().hubReducer.groupChatMembers[chatRef] !== undefined) {
        let membersObj = store.getState().hubReducer.groupChatMembers[chatRef];
        let iterator = membersObj.members.keys();

        for (let i = 0; i < membersObj.members.size; i++) {
          // eslint-disable-next-line no-shadow
          let ref =
            FirebasePaths.FIREBASE_USERS_INFO_ATTR +
            '/' +
            iterator.next().value +
            '/' +
            FirebasePaths.FIREBASE_USER_CHAT_REFERENCES +
            '/' +
            FirebasePaths.FIREBASE_PUBLIC_ATTR +
            '/' +
            chatRef +
            '/';
          firebase.database().ref(ref).update({ deleted: true });
        }
      }
    }
  }

  // This function will set the last userchat ref fdor publioc chat for each user
  static setUserChatRefGroupChat(
    groupKey,
    userId,
    messageTimestamp,
    senderId,
    lastMessage,
    counter,
  ) {
    if (counter < store.getState().hubReducer.membersLimit) {
      if (senderId !== '') {
        // Getting user ref
        let ref =
          FirebasePaths.FIREBASE_USERS_INFO_ATTR +
          '/' +
          userId +
          '/' +
          FirebasePaths.FIREBASE_USER_CHAT_REFERENCES +
          '/' +
          FirebasePaths.FIREBASE_PUBLIC_ATTR +
          '/' +
          groupKey +
          '/';
        // update timestamp and last message and sender ID in the user chat ref
        // this.rootDB.list(ref).set(FirebasePaths.FIREBASE_PUBLIC_USER_REF_LAST_MESSAGE,this.hub.roomsLastMessages.get(groupKey));

        // Update once in the path  to notify once ! so we will gather the data in one shot and update !!
        let shot = {
          [FirebasePaths.FIREBASE_PUBLIC_USER_REF_LAST_MESSAGE]: lastMessage,
          [FirebasePaths.FIREBASE_PUBLIC_USER_REF_LAST_MESSAGE_TIMESTAMP]: messageTimestamp,
          [FirebasePaths.FIREBASE_PUBLIC_USER_REF_SENDER_ID]: senderId,
          [FirebasePaths.FIREBASE_PUBLIC_USER_REF_OPENED]: false,
          ['deleted']: false,
        };
        firebase.database().ref(ref).update(shot);
      }
    } else {
      // Clear value
      store.dispatch(setHub({ membersLimit: 0 }));
    }
  }

  static openGroupChatsConnection(groupKey, gameName) {
    // Check if this group chat is in connections or not

    // Creatin user chat ref
    let userChatRef = new UserChatRef();

    // Get the ref for the group chat last message ref
    let groupChatRef =
      FirebasePaths.FIREBASE_CHAT_ATTR +
      '/' +
      FirebasePaths.FIREBASE_PUBLIC_ATTR +
      '/' +
      groupKey +
      '/' +
      FirebasePaths.FIREBASE_CHAT_MESSAGES +
      '/' +
      FirebasePaths.FIREBASE_PUBLIC_CHAT_LAST_MESSAGE +
      '/';
    firebase.database()
      .ref(groupChatRef)
      .once('value', (data) => {
        // USed to store the payload
        let packetObj;
        packetObj = data.val();

        // Check if this
        // if (!store.getState().hubReducer.closedGroupChats.has(groupKey)) {
        // Check if this package is not null
        if (packetObj !== null) {
          firebase.database()
            .ref(
              FirebasePaths.FIREBASE_CHAT_ATTR +
              '/' +
              FirebasePaths.FIREBASE_PUBLIC_ATTR +
              '/' +
              groupKey +
              '/' +
              FirebasePaths.FIREBASE_PRIVATE_CHAT_INFO +
              '/' +
              FirebasePaths.FIREBASE_PUBLIC_USER_REF_NEW_TITLE,
            )
            .once('value', (snapshot) => {
              let groupTitle = snapshot.val();

              userChatRef.opponentUsername = gameName;
              userChatRef.lastMessage = packetObj.message;
              userChatRef.lastMessageTimestamp = packetObj.timestamp;
              userChatRef.chatRef = groupKey;
              userChatRef.senderId = packetObj.uid;
              userChatRef.groupNewTitle = groupTitle;
              userChatRef.counter = 0;
              userChatRef.opponentId = groupKey;
              userChatRef.opponentBio = 'Awesome Gamers';
              userChatRef.chatType = 'PUBLIC';
              userChatRef.gameId = getGameByNameFromGameList(gameName).gameID;

              // If group is already created then don't take from created request object
              if (store.getState().hubReducer.allGroupChatRefs.has(groupKey)) {
                userChatRef.opponentPic = store.getState().hubReducer.gamesList[
                  userChatRef.gameId
                ].gamePhotoUrl;
                userChatRef.opponentUsername = gameName;
                userChatRef.platform = store
                  .getState()
                  .hubReducer.allGroupChatRefs.get(groupKey).platform;
                userChatRef.region = store
                  .getState()
                  .hubReducer.allGroupChatRefs.get(groupKey).region;

                // Get the value of the chat and set it to this userchat ref
                let chat = store
                  .getState()
                  .hubReducer.allGroupChatRefs.get(groupKey);
                if (chat.opened != null) {
                  if (
                    userChatRef.senderId !== store.getState().authReducer.uid
                  ) {
                    userChatRef.opened = false;
                  } else {
                    userChatRef.opened = true;
                  }
                }
              } else {
                // Its a new connection so u can use hub.createdReq
                userChatRef.opponentPic = store.getState().hubReducer.gamesList[
                  store.getState().hubReducer.createdReq.gameId
                ].gamePhotoUrl;
                userChatRef.opponentUsername = store.getState().hubReducer.createdReq.requestTitle;
                userChatRef.platform = store.getState().hubReducer.createdReq.platform;
                userChatRef.region = store.getState().hubReducer.createdReq.region;
              }

              // Set this ref for the user : public ref
              Chatcore.createChatRefAfterJoin(userChatRef);

              let groupChatConnections = store.getState().hubReducer
                .groupChatConnections;
              groupChatConnections.set(groupKey, groupKey);
              let allGroupChatRefs = store.getState().hubReducer
                .allGroupChatRefs;
              allGroupChatRefs.set(groupKey, userChatRef);
              store.dispatch(
                setHub({
                  groupChatConnections,
                  allGroupChatRefs,
                  communityGroupChatsArr: Array.from(allGroupChatRefs.values()),
                }),
              );
            });
        }
        // }
      });
  }

  // This function will create a chat ref for the user after he join the request
  static createChatRefAfterJoin(userChatRef) {
    // user public chat ref path
    let publicChatRef =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      store.getState().authReducer.uid +
      '/' +
      FirebasePaths.FIREBASE_USER_CHAT_REFERENCES +
      '/' +
      FirebasePaths.FIREBASE_PUBLIC_ATTR +
      '/' +
      userChatRef.opponentId;

    // Set for the user this public ref
    firebase.database().ref(publicChatRef).set(userChatRef);
  }

  // This function will load the chat
  static loadChat(CHAT_TYPE, gameNAme) {
    let chatRef;
    let tempChatRoomRef = store.getState().hubReducer.tempChatRoomRef;

    // Get the ref either private or public
    if (CHAT_TYPE === 'PRIVATE') {
      chatRef =
        FirebasePaths.FIREBASE_CHAT_ATTR +
        '/' +
        FirebasePaths.FIREBASE_PRIVATE_ATTR +
        '/' +
        tempChatRoomRef +
        '/' +
        FirebasePaths.FIREBASE_CHAT_MESSAGES +
        '/' +
        FirebasePaths.FIREBASE_PRIVATE_CHAT_PACKETS;
    } else if (CHAT_TYPE === 'PUBLIC') {
      chatRef =
        FirebasePaths.FIREBASE_CHAT_ATTR +
        '/' +
        FirebasePaths.FIREBASE_PUBLIC_ATTR +
        '/' +
        tempChatRoomRef +
        '/' +
        FirebasePaths.FIREBASE_CHAT_MESSAGES +
        '/' +
        FirebasePaths.FIREBASE_PUBLIC_CHAT_PACKETS;

      // Open connections to last messages
      // this.openGroupChatsConnection(tempChatRoomRef, gameNAme);  // [Nami] :this causes that unread badge not decreased
    }
    // Load  all packets  inside the  packets map

    // Clear packets
    if (this.chatRef$) {
      this.chatRef$.off();
      this.chatRef$ = null;
    }
    this.chatRef$ = firebase.database().ref(chatRef);
    this.chatRef$.on('value', (packets) => {
      let chatPacketsArray = [];
      packets.forEach((packet) => {
        // Get the packet shot
        let packetObj = packet.val();

        //  create a packet object to store it
        let storedPacket = new Packet();
        storedPacket.messageId = packetObj.messageId;
        storedPacket.uid = packetObj.uid;
        storedPacket.message = packetObj.message;
        storedPacket.chatRef = packetObj.chatRef;
        storedPacket.timestamp = packetObj.timestamp;
        storedPacket.username = packetObj.username;
        storedPacket.type = packetObj.type;
        storedPacket.deleted = packetObj.deleted;
        storedPacket.photoCaption = packetObj.photoCaption;
        storedPacket.isSticker = packetObj.isSticker;
        // if(CHAT_TYPE == "PRIVATE")
        // storedPacket.borderColor = await this.getProfileBorderColor(packetObj.uid);
        // Store it  in map
        chatPacketsArray.push(storedPacket);
      });
      // Load it in array

      if (CHAT_TYPE === 'PRIVATE') {
        store.dispatch(
          setHub({
            privateChatPacketsArray: chatPacketsArray,
          }),
        );
      } else {
        store.dispatch(
          setHub({
            chatPacketsArray,
          }),
        );
      }

      // Set the last message for this room in room last message in hub
      if (CHAT_TYPE === 'PUBLIC') {
        if (chatPacketsArray[chatPacketsArray.length - 1] != null) {
          let roomsLastMessages = store.getState().hubReducer.roomsLastMessages;
          roomsLastMessages[tempChatRoomRef] =
            chatPacketsArray[chatPacketsArray.length - 1].message;
          store.dispatch(
            setHub({
              roomsLastMessages,
            }),
          );
          // Set message ref for all users
          if (
            store.getState().hubReducer.groupChatMembers[tempChatRoomRef] !==
            undefined
          ) {
            let groupChatMembers = store.getState().hubReducer.groupChatMembers;
            let membersObj = groupChatMembers[tempChatRoomRef];
            // let iterator = membersObj.members.keys();
            let outUserId = '';
            // Remove the scender if he left
            if (chatPacketsArray[chatPacketsArray.length - 1].uid === 'LEFT') {
              // Remove this user from the members
              let outUsername = '';
              membersObj.members.forEach((username) => {
                if (
                  username ===
                  chatPacketsArray[chatPacketsArray.length - 1].username
                ) {
                  outUsername = username;
                }
              });
              membersObj.members.forEach((value, key) => {
                if (value === outUsername) {
                  outUserId = key;
                }
              });

              // Finally remove him
              membersObj.members.delete(outUserId);
            }
            groupChatMembers[tempChatRoomRef] = membersObj;
            store.dispatch(
              setHub({
                groupChatMembers,
              }),
            );

            // TO Notify USERS WHEN ENTER THE CHAT AND SET NEW CHAT MESSAGE  ENABLE THIS CODE BLOCK : Then do the iterator
            // for(let i = 0 ; i < membersObj.members.size ; i++)
            // {
            //     this.setUserChatRefGroupChat(this.hub.tempChatRoomRef,iterator.next().value,new Date().getTime(),this.hub.chatPacketsArray[this.hub.chatPacketsArray.length - 1].uid, this.hub.chatPacketsArray[this.hub.chatPacketsArray.length - 1].message,i);
            // }
          }
        }
      }
    });
  }

  static getProfileBorderColor(uid) { }

  // this function will add users to current user's block list
  static addToBlockList(uid, username) {
    // Getting the blacklist path for the user
    let blockListPath =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      store.getState().authReducer.uid +
      '/' +
      FirebasePaths.FIREBASE_BLOCKED_USERS +
      '/';
    firebase.database().ref(blockListPath).child(uid).set(username);
  }

  // this function deletes the entire chat from the user's side
  static deletePrivateChat() {
    let uid = store.getState().authReducer.uid;
    let userRef =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      uid +
      '/' +
      FirebasePaths.FIREBASE_USER_CHAT_REFERENCES +
      '/' +
      FirebasePaths.FIREBASE_PRIVATE_ATTR +
      '/' +
      store.getState().hubReducer.tempChatRoomRef;
    firebase.database().ref(userRef).remove();
    let allPrivateChatRefs = store.getState().hubReducer.allPrivateChatRefs;

    delete allPrivateChatRefs[
      store.getState().hubReducer.tempChatRoomRef.chatRef
    ];

    store.dispatch(
      setHub({
        chatPacketsArray: [],
        allPrivateChatRefs,
      }),
    );
  }

  // deletes a single msg (needs testing)
  static deletePrivateMsg(msgid) { }
}
