import { PermissionsAndroid, Platform } from 'react-native';
import firebase from 'firebase/app';
import 'firebase/database';
import * as FirebasePaths from '../constants/firebasePaths';
// import images
import * as Images from '../constants/images';
import Gamemodel from '../models/gamemodel';
import { store } from '../redux/store';

export const clearFastImageCache = () => {
  // FastImage.clearMemoryCache();
  // FastImage.clearDiskCache();
}

export const clearMemoryCache = async () => {

}

export const currentVersionCode = '1.7.4';

export const forceShowMessages = false;

export const requestCameraPermission = async () => {
  if (Platform.OS === 'ios') {
    return true;
  }
  try {
    const granted = await PermissionsAndroid.request(
      PermissionsAndroid.PERMISSIONS.CAMERA,
      {
        title: "App Camera Permission",
        message: "App needs access to your camera ",
        buttonNeutral: "Ask Me Later",
        buttonNegative: "Cancel",
        buttonPositive: "OK"
      }
    );
    if (granted === PermissionsAndroid.RESULTS.GRANTED) {
      return true;
    } else {
      return false;
    }
  } catch (err) {
    console.warn(err);
    return false;
  }
};

export const checkIsUsernameTaken = (username, onResult) => {
  let usersPath = '/' + FirebasePaths.FIREBASE_USERS_INFO_ATTR + '/';
  firebase.database()
    .ref(usersPath)
    .orderByChild('/_info_/_username_')
    .startAt(username.trim().toLowerCase())
    .endAt(username.trim().toLowerCase())
    .limitToFirst(1)
    .once('value')
    .then((snapshot) => {
      if (snapshot.val()) {
        onResult(true);
        return;
      }

      onResult(false);
    });
};

export const getHumantime = (timestamp) => {
  let currentUnixTime = new Date().getTime();

  if (timestamp < 1000000000000) {
    // if timestamp given in seconds, convert to millis
    timestamp *= 1000;
  }

  if (timestamp <= 0 || timestamp == null) {
    return '';
  }

  const SECOND_MILLIS = 1000;
  const MINUTE_MILLIS = 60 * SECOND_MILLIS;
  const HOUR_MILLIS = 60 * MINUTE_MILLIS;
  const DAY_MILLIS = 24 * HOUR_MILLIS;

  // TODO: localize
  const diff = currentUnixTime - timestamp;

  if (Number.isNaN(diff)) return 'Now';

  if (diff < 1000 * 60) {
    return 'now';
  } else if (diff < 2 * MINUTE_MILLIS) {
    return '1 minute ago';
  } else if (diff < 50 * MINUTE_MILLIS) {
    return (diff / MINUTE_MILLIS).toFixed() + 'm';
  } else if (diff < 90 * MINUTE_MILLIS) {
    return '1 hour ago';
  } else if (diff < 24 * HOUR_MILLIS) {
    return (diff / HOUR_MILLIS).toFixed() + 'h';
  } else if (diff < 48 * HOUR_MILLIS) {
    return 'yesterday';
  }
  return (diff / DAY_MILLIS).toFixed() + 'd';
};

export const getColorTimestamp = (timestamp) => {
  let currentUnixTime = new Date().getTime();

  if (timestamp < 1000000000000) {
    // if timestamp given in seconds, convert to millis
    timestamp *= 1000;
  }

  if (timestamp <= 0 || timestamp == null) {
    return '';
  }

  const SECOND_MILLIS = 1000;
  const MINUTE_MILLIS = 60 * SECOND_MILLIS;
  const HOUR_MILLIS = 60 * MINUTE_MILLIS;
  const DAY_MILLIS = 24 * HOUR_MILLIS;

  // TODO: localize
  const diff = currentUnixTime - timestamp;

  if (diff < HOUR_MILLIS) {
    return '#38f339';
  } else if (diff >= HOUR_MILLIS && diff < 2 * HOUR_MILLIS) {
    return '#ffbf00';
  } else if (diff >= HOUR_MILLIS * 2 && diff < 3 * HOUR_MILLIS) {
    return '#ff0000';
  }

  return '#00000000';
};
export const getWidthTimestamp = (timestamp) => {
  let currentUnixTime = new Date().getTime();

  if (timestamp < 1000000000000) {
    // if timestamp given in seconds, convert to millis
    timestamp *= 1000;
  }

  if (timestamp <= 0 || timestamp == null) {
    return 60;
  }

  const SECOND_MILLIS = 1000;
  const MINUTE_MILLIS = 60 * SECOND_MILLIS;
  const HOUR_MILLIS = 60 * MINUTE_MILLIS;
  const DAY_MILLIS = 24 * HOUR_MILLIS;

  // TODO: localize
  const diff = currentUnixTime - timestamp;

  if (diff < HOUR_MILLIS) {
    return 100;
  } else if (diff >= HOUR_MILLIS && diff < 2 * HOUR_MILLIS) {
    return 60;
  } else if (diff >= HOUR_MILLIS * 2 && diff < 3 * HOUR_MILLIS) {
    return 25;
  }

  return 60;
};

export const arrayFromSnapshot = (snapshot) => {
  if (snapshot == null || snapshot == undefined) {
    return [];
  }
  const result = [];

  snapshot.forEach(function (item) {
    const key = item.key;
    if (key && key !== 'undefined') {
      const itemVal = item.val();
      result.push(itemVal);
    }
  });

  return result;
};

export const arrayFromSnapshotWithKey = (snapshot) => {
  if (snapshot == null || snapshot == undefined) {
    return [];
  }
  const result = [];

  snapshot.forEach(function (item) {
    const key = item.key;
    if (key && key !== 'undefined') {
      const itemVal = item.val();
      result.push({ key: key, value: itemVal });
    }
  });

  return result;
};

export const getFlag = (country) => {
  switch (country) {
    case 'Bahrain':
      return Images.FLAG_BAHRAIN;
    case 'Egypt':
      return Images.FLAG_EGYPT;
    case 'Iraq':
      return Images.FLAG_IRAQ;
    case 'Jordan':
      return Images.FLAG_JORDAN;
    case 'Kuwait':
      return Images.FLAG_KUWAIT;
    case 'Lebanon':
      return Images.FLAG_LEBANON;
    case 'Libya':
      return Images.FLAG_LIBYA;
    case 'Oman':
      return Images.FLAG_OMAN;
    case 'Qatar':
      return Images.FLAG_QATAR;
    case 'Saudi Arabia':
      return Images.FLAG_SAUDI_ARABIA;
    case 'Sudan':
      return Images.FLAG_SUDAN;
    case 'Syria':
      return Images.FLAG_SYRIA;
    case 'Turkey':
      return Images.FLAG_TURKEY;
    case 'United Arab Emirates':
      return Images.FLAG_UAE;
    case 'Yemen':
      return Images.FLAG_YEMEN;
    default:
      return Images.FLAG_EARTH;
  }
};

// Get game by gamename
export const getGameByNameFromGameList = (gameName) => {
  let gModel = new Gamemodel();

  Object.values(store.getState().hubReducer.gamesList).forEach((gamemodel) => {
    if (gamemodel.gameName === gameName) {
      gModel = gamemodel;
    }
  });

  return gModel;
};

export const generateRandom = (length) => {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const dataForSkeleton = (length) => {
  const res = [];
  for (let i = 0; i < length; i++) {
    res.push(i);
  }
  return res;
}