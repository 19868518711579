import createActionConstants from '../../../helpers/actionCreatorHelper';

export const SET = 'SET';
export const ADD_GAME = createActionConstants('ADD_GAME');
export const ADD_ALL_GAME = createActionConstants('ADD_ALL_GAME');
export const DELETE_GAME = createActionConstants('DELETE_GAME');
export const IS_COMPETITIVE = createActionConstants('IS_COMPETITIVE');
export const GET_GAME_BY_NAME = createActionConstants('GET_GAME_BY_NAME');
export const GET_GAME_BY_ID = createActionConstants('GET_GAME_BY_ID');
export const CLEAR = 'CLEAR';

export const LOGOUT = 'LOGOUT';
