/* eslint-disable react-native/no-inline-styles */
import React, { Component } from 'react';
import { View, TouchableOpacity, StyleSheet, FlatList } from 'react-native';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Feather from 'react-native-vector-icons/Feather';
import AppStyles from '../../constants/styles';
import * as Images from '../../constants/images';
import I18n from '../../i18n';

import NavigationService from '../../navigation/navigationService';
// import components
import Header from '../../components/header';
import TextInput from '../../components/text-input';
import Label from '../../components/label';
import PrimaryButton from '../../components/buttons/primary-button';

// import styles
import Dimens from '../../constants/dimens';
import { perfectSize } from '../../helpers/deviceHelper';
import ImageWithPlaceholder from '../../components/image-with-placeholder';
import { Placeholder, Progressive } from 'rn-placeholder';
import SkeletonTextContent from '../../components/skeletonWrapper/skeletonTextContent';
import SkeletonUserAvatar from '../../components/skeletonWrapper/skeletonUserAvatar';

class GameListPage extends Component {
  constructor(props) {
    super(props);

    let games = [];

    Object.keys(this.props.navigation.state.params.games).forEach((gameId) => {
      games.push(props.games[gameId]);
    });
    this.state = {
      games: games,
      name: this.props.navigation.state.params.name,
      isLoading: true,
    };
  }

  componentDidMount() {
    const { games } = this.state;
    const sliceCount = games.length > 8 ? 8 : games.length;
    const preloadImgs = games.slice(0, sliceCount);
    const preloadUris = [];
    preloadImgs.map(img => {
      if (img.gamePhotoUrl) {
        return preloadUris.push({ uri: img.gamePhotoUrl })
      }
    });
    setTimeout(() => {
      this.setState({
        isLoading: false,
      })
    }, 1000);
  }

  render() {
    const { theme } = this.props;
    const containerStyle = [
      styles.container,
      { backgroundColor: theme.secondary_background },
    ];
    const { isLoading } = this.state;
    return (
      <View style={containerStyle}>
        <Header
          text={this.state.name + ' ' + I18n.t('USER_PROFILE_PAGE.games')}
          bold
        />
        {/* Content */}
        <View
          style={[{ backgroundColor: theme.primary_background }, styles.content]}>
          <View style={styles.gamesWrap}>
            {/* <Label size="medium" bold>
              My games
            </Label> */}
            <View style={styles.label}>
              {
                isLoading &&
                <FlatList
                  keyExtractor={(item) => item.toString()}
                  data={[0, 1, 2, 3, 4]}
                  renderItem={(item) => {
                    return <View>
                      <Placeholder
                        Animation={Progressive}
                      >
                        <View >
                          <View style={{
                            paddingVertical: perfectSize(8),
                            flexDirection: 'row',
                          }}>
                            <SkeletonUserAvatar size={48} isRound={false} style={{ borderRadius: 12 }} />
                            <View style={{
                              marginLeft: perfectSize(8),
                              flex: 1,
                              justifyContent: 'center',
                            }}>
                              <SkeletonTextContent height={20} />
                            </View>
                          </View>
                          <View style={{ height: 1, widht: '100%' }} />
                        </View>
                      </Placeholder>
                    </View>
                  }}
                />
              }
              {
                !isLoading &&
                <FlatList
                  style={{}}
                  data={this.state.games}
                  keyExtractor={(item, index) => index.toString()}
                  showsVerticalScrollIndicator={false}
                  ListEmptyComponent={() => {
                    return (
                      <View style={AppStyles.noGameWrap}>
                        {/* <FastImage
                        source={
                          theme.mode === 'dark'
                            ? Images.SUPPORT_LOADING_DARK
                            : Images.SUPPORT_LOADING
                        }
                        style={AppStyles.supportLoading}
                        resizeMode="contain"
                      /> */}
                        <Label style={AppStyles.txtNoGame}>
                          {I18n.t('GAME_LIST_PAGE.no_games')}
                        </Label>
                        <PrimaryButton
                          text={I18n.t('GAME_LIST_PAGE.go_support')}
                          type="filled"
                          size="x_small"
                          customStyles={styles.btnAction}
                          onPress={this._toContactUsPage}
                        />
                      </View>
                    );
                  }}
                  renderItem={(item) => {
                    return (
                      <TouchableOpacity
                        key={item.index}
                        style={[
                          styles.itemContainer,
                          this.state.games.length - 1 !== item.index &&
                          styles.borderBottom,
                        ]}>
                        <ImageWithPlaceholder
                          uri={item.item.gamePhotoUrl}
                          style={styles.itemImage}
                        />
                        <Label size="medium" style={{ marginLeft: perfectSize(8) }}>
                          {item.item?.gameName}
                        </Label>
                      </TouchableOpacity>
                    );
                  }}
                />
              }
            </View>
          </View>
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
    games: state.hubReducer.gamesList,
  };
};

export default compose(connect(mapStateToProps, {}))(GameListPage);

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  // Header
  header: {
    flexDirection: 'row',
    paddingHorizontal: Dimens.HEADER_PADDING_HORIZONTAL,
    paddingVertical: Dimens.HEADER_PADDING_VERTICAL,
  },
  headerLeft: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerCenter: {
    flex: 2,
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerRight: {
    flex: 1,
    flexDirection: 'row-reverse',
    alignItems: 'center',
  },
  actionBtn: {
    color: '#C70064',
  },
  // Content
  content: {
    flex: 1,
    borderTopStartRadius: Dimens.BODY_BORDER_TOP_RADIUS,
    borderTopEndRadius: Dimens.BODY_BORDER_TOP_RADIUS,
    paddingHorizontal: Dimens.HEADER_PADDING_HORIZONTAL,
    paddingVertical: Dimens.HEADER_PADDING_VERTICAL,
  },
  gamesWrap: {
    marginTop: perfectSize(16),
    flex: 1
  },
  label: {
    marginTop: perfectSize(16),
    flex: 1,
  },
  itemContainer: {
    height: perfectSize(60),
    flexDirection: 'row',
    alignItems: 'center',
  },
  borderBottom: {
    borderBottomWidth: 1,
    borderBottomColor: '#FFFFFF0C',
  },
  itemImage: {
    width: perfectSize(48),
    height: perfectSize(48),
    borderRadius: perfectSize(12),
  },
  btnAction: {
    paddingHorizontal: perfectSize(16),
  },
});
