import {createSelector} from 'reselect';

const selectUser = (state) => state.authReducer.authUser;
const selectUserUID = (state) => state.authReducer.uid;
const selectProfile = (state) => state.authReducer.userinformationDB;
const selectUserProfilePic = createSelector(
  selectProfile,
  (userinformationDB) => userinformationDB && userinformationDB.pictureURL,
);
const selectPcGamesAccs = createSelector(
  selectProfile,
  (userinformationDB) => userinformationDB && userinformationDB.pcGamesAccs,
);

export default {
  selectUser,
  selectUserUID,
  selectUserProfilePic,
  selectPcGamesAccs,
};
