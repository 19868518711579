import React, {Component} from 'react';
import {View, FlatList, SafeAreaView, TouchableOpacity} from 'react-native';
import {compose} from 'redux';
import {connect} from 'react-redux';
import Modal from 'react-native-modal';
import I18n from '../../../i18n';
import NavigationService from '../../../navigation/navigationService';
import Label from '../../../components/label';
import Newrequestcore from '../newrequestcore';
import {store} from '../../../redux/store';
import {set as HubSet} from '../../../redux/modules/hub/actions';
import {showAlert} from '../../../redux/modules/alert/actions';

// import components
import RequestCard from '../../../components/cards/request-card';
import GameManager from '../../../services/gameManager';

// import styles
import style from './styles';
import AppStyles from '../../../constants/styles';

import {presentToast} from '../../../helpers/toastHelper';
import {CreateRequestCore} from '../../createrequest/createrequestcore';
import Chatcore from '../../chat/chatcore';
import mainMenuService from '../../../navigation/mainMenuService';
import topTabService from '../../../navigation/topTabService';

import {
  CONTROLLER_LOADING_DARK,
  CONTROLLER_LOADING,
} from '../../../constants/images';

class SavedRequests extends Component {
  constructor(props) {
    super(props);
    this.state = {dialogOpen: false};
  }

  componentDidMount() {}

  showPost = (item) => {
    this.request(item);
  };

  selectItem = (item) => {
    Newrequestcore.IS_CREATE_REQUEST_BANNED((result) => {
      if (result) {
        this.props.showAlert([
          I18n.t('NEW_REQUEST_PAGE.ban_alert_title'),
          I18n.t('NEW_REQUEST_PAGE.ban_alert_msg'),
          [
            {
              text: I18n.t('NEW_REQUEST_PAGE.ban_alert_btn'),
              onPress: () => {
                NavigationService.moveToScreenInStack(
                  'SettingsStack',
                  'ContactUsPage',
                );
              },
            },
          ],
          false,
        ]);
      } else {
        if (!GameManager.checkIfHasGameById(item.gameId)) {
          let game = store.getState().hubReducer.gamesList[item.gameId];
          GameManager.addGame(game);
        }

        this.setState({
          currentItem: item,
        });
        this.props.showAlert([
          I18n.t('NEW_REQUEST_PAGE.alert_title'),
          '',
          [
            {
              text: I18n.t('NEW_REQUEST_PAGE.alert_request'),
              onPress: () => {
                this.request(this.state.currentItem);
              },
            },

            {
              text: I18n.t('NEW_REQUEST_PAGE.alert_edit'),
              onPress: () => {
                NavigationService.moveToScreenInStack(
                  'HomeStack',
                  'EditRequestPage',
                  {
                    request: this.state.currentItem,
                  },
                );
              },
            },

            {
              text: I18n.t('NEW_REQUEST_PAGE.alert_delete'),
              onPress: () => {
                this.deleteSavedRequest();
              },
            },
          ],
          false,
        ]);
      }
    });
  };
  deleteSavedRequest = () => {
    Newrequestcore.deleteSavedReq(this.state.currentItem.savedRequestUniqueId);
    global.rankLogger.LOG_NEW_HP(
      global.rankLogger.DELETE_SAVED_REQUEST,
      'DELETE_SAVED_REQUEST',
    );
    global.featuresLogger.LOG_REQUEST_DELETE_SAVED_REQUEST();
    presentToast({
      message:
        this.state.currentItem.requestTitle +
        I18n.t('NEW_REQUEST_PAGE.alert_success_delete'),
    });
  };

  request = (request) => {
    let platform = request.platform;
    let matchType = request.matchType;
    let region = request.region;
    let numberOfPlayers = request.playersNumber;
    let rank = request.rank;
    let description = request.description;
    let requestTime = request.requestTime;
    let game = store.getState().hubReducer.gamesList[request.gameId];

    if (!description) {
      description = 'Hey, who want to play ' + game.gameName + ' ?';
    }
    // This function will create new request

    CreateRequestCore.addRequestToFirebase(
      platform,
      game.gameName,
      matchType,
      region,
      numberOfPlayers,
      rank,
      requestTime,
      description,
      false,
    );

    // Log Rank HP

    global.rankLogger.LOG_NEW_HP(global.rankLogger.REQUEST, 'REQUEST');

    // Open connection for group chat

    setTimeout(() => {
      Chatcore.openGroupChatsConnection(
        store.getState().hubReducer.currentRequest.requestId,
        game.gameName,
      );
    }, 5000);

    // Load new mneu with lobby

    // this.navCtrl.setRoot(MainmenuwithlobbyPage);

    presentToast({
      message: I18n.t('NEW_REQUEST_PAGE.alert_success_request'),
    });

    // Show alert if come after : creating request , or joining request : after 3s
    this.props.showAlert([
      I18n.t('LOBBY_PAGE.bazinga'),
      I18n.t('LOBBY_PAGE.bazinga_msg'),
      [
        {
          text: I18n.t('LOBBY_PAGE.check'),
          onPress: () => {
            NavigationService.navigate('TabStack', {});
            setTimeout(() => {
              mainMenuService.navigate('Chat');
              setTimeout(() => {
                topTabService.navigate('ChatRequests');
              }, 50);
            }, 50);
          },
        },
      ],
      false,
    ]);
  };

  getGif() {
    if (this.props.theme.mode === 'dark') {
      return CONTROLLER_LOADING_DARK;
    } else {
      return CONTROLLER_LOADING;
    }
  }

  render() {
    const {theme} = this.props;
    const styles = style(theme);
    return (
      <View style={[styles.container]}>
        {(!this.props.reqs || this.props.reqs.length === 0) && (
          <View style={AppStyles.emptyContent}>
            {/* <FastImage
              source={this.getGif()}
              style={AppStyles.emptyContentGif}
              resizeMode="contain"
            /> */}
            <Label style={AppStyles.emptyContentLabel}>
              {I18n.t('NEW_REQUEST_PAGE.no_saved_req_msg')}
            </Label>
          </View>
        )}

        {this.props.reqs && this.props.reqs.length > 0 && (
          <FlatList
            data={this.props.reqs}
            renderItem={({item, index}) => (
              <RequestCard
                key={index}
                request={item}
                customStyles={styles.margin}
                showPost={true}
                onPost={(item) => this.showPost(item)}
                onMenu={() => {
                  this.selectItem(item);
                }}
              />
            )}
            keyExtractor={(item, index) => index.toString()}
            showsVerticalScrollIndicator={false}
          />
        )}
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
    lang: state.appReducer.currentLang,
    savedRequestsList: state.hubReducer.savedRequestsList,
  };
};

export default compose(connect(mapStateToProps, {showAlert}))(SavedRequests);
