/* eslint-disable react-native/no-inline-styles */
import React, {Component} from 'react';
import {View, FlatList, TouchableOpacity} from 'react-native';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {store} from '../../redux/store';
import Teamcore from '../team/teamscore';
import NavigationService from '../../navigation/navigationService';
import I18n from '../../i18n';

// import components
import Header from '../../components/header';
import Label from '../../components/label';
import ClanSmallCard from '../../components/cards/clan-small-card';

// import actions
import {showAlert} from '../../redux/modules/alert/actions';

import AppStyles from '../../constants/styles';
import styles from './styles';
import {perfectSize} from '../../helpers/deviceHelper';

import {
  CLANS_LOADING_DARK_GIF,
  CLANS_LOADING_GIF,
} from '../../constants/images';

class UserClanSelectorPage extends Component {
  constructor(props) {
    super(props);
    const params = props.navigation.state.params;
    this.state = {
      pageTitle: params.PAGE_TITLE || 'التسجيل بالكلان',
      pageSubTitle:
        params.PAGE_SUB_TITLE ||
        'اختار احد الكلانات اللي انت موجود فيها, وتبي تسجل فيها',
      championshipObj: params.CHAMPIONSHIP_OBJ,
    };
  }

  renderCard = ({item, index}) => {
    return (
      <TouchableOpacity
        onPress={() => this.selectTeam(item)}
        style={[
          {width: '50%', marginBottom: perfectSize(16)},
          index % 2 === 0
            ? {paddingRight: perfectSize(8)}
            : {paddingLeft: perfectSize(8)},
        ]}>
        <ClanSmallCard
          teamId={item.id}
          photo={item.logo}
          title={item.name}
          index={index}
        />
      </TouchableOpacity>
    );
  };

  selectTeam = (team) => {
    // check if this user is the admin for this clan if not then show an alert
    Teamcore.checkIsAdmin(team.id).then((adminKey) => {
      if (adminKey === store.getState().authReducer.uid) {
        // redirect the user to championship-registration page
        NavigationService.navigate('ChampionshipRegistrationPage', {
          IS_CLAN_SELECTED: true,
          CLAN_OBJ: team,
          CHAMPIONSHIP_OBJ: this.state.championshipObj,
        });
      } else {
        // show alert : only admin can register with this clan
        const title = I18n.t('USER_CLAN_SELECTOR.only_admin_alert_title');
        const msg = I18n.t('USER_CLAN_SELECTOR.only_admin_alert_msg');
        const toClans = I18n.t('USER_CLAN_SELECTOR.only_admin_alert_to_clans');
        const selectAnotherClan = I18n.t(
          'USER_CLAN_SELECTOR.only_admin_alert_select_another_clan',
        );

        this.props.showAlert([
          title,
          msg,
          [
            {
              text: selectAnotherClan,
              onPress: () => {},
              style: 'cancel',
            },
            {
              text: toClans,
              onPress: () => {
                NavigationService.navigate('JoinChampionshipPage', {
                  CAME_FROM: 'NEW_TEAM',
                });
              },
            },
          ],
          false,
        ]);
      }
    });
  };

  // Getting Animated Gif for the right mode
  getGif() {
    if (this.props.theme.mode === 'dark') {
      return CLANS_LOADING_DARK_GIF;
    } else {
      return CLANS_LOADING_GIF;
    }
  }

  render() {
    const {theme, userTeamsArr} = this.props;
    const _styles = styles(theme);
    const {pageTitle, pageSubTitle} = this.state;
    return (
      <View style={AppStyles.rootContainer}>
        {/* Header */}
        <Header text={pageTitle} />
        {/* Content */}
        <View style={AppStyles.rootContent}>
          <Label style={_styles.subTitle}>{pageSubTitle}</Label>
          {userTeamsArr.length > 0 && (
            <FlatList
              data={userTeamsArr}
              numColumns={2}
              renderItem={(item) => this.renderCard(item)}
              keyExtractor={(item, index) => String(index)}
              style={_styles.flatList}
            />
          )}
          {userTeamsArr.length === 0 && (
            <View style={_styles.emptyContent}>
              <View style={[AppStyles.emptyContent, {borderWidth: 0}]}>
                {/* <FastImage
                  source={this.getGif()}
                  style={AppStyles.emptyContentGif}
                  resizeMode="contain"
                /> */}
                <Label style={AppStyles.emptyContentLabel}>
                  {I18n.t('USER_CLAN_SELECTOR.no_clan_msg')}
                </Label>
              </View>
            </View>
          )}
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
    userTeamsArr: state.hubReducer.userTeamsArr,
  };
};

export default compose(connect(mapStateToProps, {showAlert}))(
  UserClanSelectorPage,
);
