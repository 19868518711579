/* eslint-disable react-native/no-inline-styles */
import React, {Component} from 'react';
import {
  View,
  FlatList,
  TouchableOpacity,
  ActivityIndicator,
} from 'react-native';
import {compose} from 'redux';
import {connect} from 'react-redux';
import Feather from 'react-native-vector-icons/Feather';
import firebase from 'firebase/app';
import 'firebase/database';

import I18n from '../../i18n';
import NavigationService from '../../navigation/navigationService';
import _ from 'lodash';

// import components
import Header from '../../components/header';
import TextInput from '../../components/text-input';
import ClanSmallCard from '../../components/cards/clan-small-card';

import AppStyles from '../../constants/styles';
import styles from './styles';
import {perfectSize} from '../../helpers/deviceHelper';

import * as FirebasePaths from '../../constants/firebasePaths';
import {FONT_FAMILY} from '../../constants/theme';

/**
 * This page is part of pages/join-championship in Ionic
 */
class JoinClanPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: '',
      loading: false,
      searchedTeamsArr: [],
    };
  }

  componentWillUnmount() {
    if (this.ref$) {
      this.ref$.off();
      this.ref$ = null;
    }
  }

  toTeamPage = (teamId) => {
    NavigationService.navigate('TeamPage', {
      TEAM_ID: teamId,
      CAME_FROM: 'TEAMS',
    });
  };

  searchTeamTrigger = (value) => {
    if (value !== '') {
      // Show the spinner
      this.setState({
        loading: true,
      });

      // Search in server
      const teamName = value.toLowerCase();
      // Getting the users DB ref
      let teamPath = '/' + FirebasePaths.FIREBASE_TEAMS + '/';

      this.ref$ = firebase.database()
        .ref(teamPath)
        .orderByChild('/_info_/name')
        .startAt(teamName)
        .endAt(teamName + '\uf8ff')
        .limitToFirst(10);
      this.ref$.on('value', (snapshot) => {
        let searchedTeamsArr = [];
        snapshot.forEach((item) => {
          let obj = item.val()._info_;
          // Filter searching
          if (
            obj.name
              .toString()
              .toLowerCase()
              .includes(value.toString().toLowerCase())
          ) {
            searchedTeamsArr.push(obj);
          }
        });
        this.setState({
          loading: false,
          searchedTeamsArr,
        });
      });
    } else {
      this.setState({
        searchedTeamsArr: [],
      });
    }
  };

  renderCard = ({item, index}) => {
    return (
      <TouchableOpacity
        onPress={() => this.toTeamPage(item.id)}
        style={[
          {width: '50%', marginBottom: perfectSize(16)},
          index % 2 === 0
            ? {paddingRight: perfectSize(8)}
            : {paddingLeft: perfectSize(8)},
        ]}>
        <ClanSmallCard
          teamId={item.id}
          photo={item.logoUrl}
          title={item.name}
          isVerified={item.verified}
          index={index}
        />
      </TouchableOpacity>
    );
  };

  render() {
    const {theme} = this.props;
    const _styles = styles(theme);
    const {loading, searchedTeamsArr} = this.state;
    const fontFamily =
      this.props.lang === 'en' ? FONT_FAMILY.ubuntu : FONT_FAMILY.cairo;
    return (
      <View style={AppStyles.rootContainer}>
        {/* Header */}
        <Header text={I18n.t('JOIN_CHAMPIONSHIP_PAGE.join_team_title')} />
        {/* Content */}
        <View style={AppStyles.rootContent}>
          <View
            style={[
              AppStyles.textInputContainer,
              {paddingHorizontal: 0, marginBottom: perfectSize(16)},
            ]}>
            <TextInput
              placeholder={I18n.t('CHAMPIONSHIP_INFO_PAGE.search_placeholder')}
              onChangeText={_.debounce(
                (value) => this.searchTeamTrigger(value),
                300,
              )}
              customStyles={[AppStyles.textInput, {fontFamily: fontFamily}]}
              rightComponent={
                <Feather name="search" size={perfectSize(20)} color="gray" />
              }
            />
          </View>
          {loading && (
            <ActivityIndicator
              color="#C70064"
              style={{marginBottom: perfectSize(16)}}
            />
          )}
          <FlatList
            data={searchedTeamsArr}
            numColumns={2}
            renderItem={(item) => this.renderCard(item)}
            keyExtractor={(item, index) => item.id}
            style={_styles.flatList}
            showsVerticalScrollIndicator={false}
          />
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
    lang: state.appReducer.currentLang,
  };
};

export default compose(connect(mapStateToProps, {}))(JoinClanPage);
