export default class Gamemodel {
  constructor() {
    this.gameName = null;
    this.gameID = null;
    this.gamePhotoUrl = null;
    this.gameLargePhotoUrl = null;
    this.gameType = null;
    this.gamePlatforms = null;
    this.pcGameProvider = null;
    this.tags = null;
    this.maxPlayers = 0;
    // map of ranks : rank key and it's value
    this.ranksList = new Map();
  }
}
