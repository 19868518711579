import {StyleSheet} from 'react-native';
import {perfectSize} from '../../helpers/deviceHelper';
import dimens from '../../constants/dimens';

const styles = (theme) =>
  StyleSheet.create({
    // Header
    headerRight: {
      flexDirection: 'row-reverse',
    },
    actionBtn: {
      padding: perfectSize(4),
      marginRight: perfectSize(20),
    },
    pinkLabel: {
      color: '#C70064',
    },
    // Content
    avatarContainer: {
      width: perfectSize(96),
      height: perfectSize(96),
    },
    iconContainer: {
      width: perfectSize(32),
      height: perfectSize(32),
      backgroundColor: theme.primary_btn_background,
      position: 'absolute',
      right: 0,
      bottom: 0,
      borderRadius: perfectSize(16),
      justifyContent: 'center',
    },
    editIcon: {
      width: '60%',
      height: '60%',
      alignSelf: 'center',
    },
    badge: {
      width: perfectSize(16),
      height: perfectSize(16),
      marginLeft: perfectSize(8),
    },
    manageBtnContainer: {
      height: perfectSize(32),
      width: perfectSize(146),
      backgroundColor: theme.primary_btn_background,
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: perfectSize(16),
    },
    mt16: {
      marginTop: perfectSize(16),
    },
    itemContainer: {
      height: 52,
      backgroundColor: theme.secondary_btn_background,
      paddingHorizontal: perfectSize(16),
      borderRadius: perfectSize(12),
      flexDirection: 'row',
      alignItems: 'center',
    },
    itemIcon: {
      width: 20,
      height: 20,
    },
    itemLabel: {
      color: theme.tab_inactive_text_color,
      marginLeft: 12,
    },
    saveBtn: {
      marginTop: perfectSize(32),
      marginBottom: dimens.CONTENT_PADDING_BOTTOM,
    },
    flexRow: {
      flexDirection: 'row',
      alignSelf: 'flex-start',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    },
  });

export default styles;
