export const REGISTRATION_PLATFORM = [
  {
    value: 'PS',
    title: 'EDIT_PROFILE_PAGE.main_platform_playstation',
  },
  {
    value: 'XBOX',
    title: 'EDIT_PROFILE_PAGE.main_platform_xbox',
  },
  {
    value: 'PC',
    title: 'EDIT_PROFILE_PAGE.main_platform_pc',
  },
  {
    value: 'NINTENDO',
    title: 'EDIT_PROFILE_PAGE.main_platform_nintendo',
  },
  {
    value: 'MOBILE',
    title: 'EDIT_PROFILE_PAGE.main_platform_mobile',
  },
];

export const EDIT_PROFILE_GENDER = [
  {
    value: 'MALE',
    title: 'EDIT_PROFILE_PAGE.male',
  },
  {
    value: 'FEMALE',
    title: 'EDIT_PROFILE_PAGE.female',
  },
];

export const EDIT_PROFILE_AGE = [
  {
    value: '17_YOUNGER',
    title: 'EDIT_PROFILE_PAGE.age_option_17_younger',
  },
  {
    value: '18_TO_25',
    title: 'EDIT_PROFILE_PAGE.age_option_18_to_25',
  },
  {
    value: '26_TO_35',
    title: 'EDIT_PROFILE_PAGE.age_option_26_to_35',
  },
  {
    value: '36_OLDER',
    title: 'EDIT_PROFILE_PAGE.age_option_36_or_older',
  },
];

export const EDIT_PROFILE_AVG_PLAYTIME = [
  {
    value: 'DAILY',
    title: 'EDIT_PROFILE_PAGE.avg_playtime_daily_option',
  },
  {
    value: 'FEW_TIMES_WEEK',
    title: 'EDIT_PROFILE_PAGE.avg_playtime_few_times_week_option',
  },
  {
    value: 'ONCE_WEEK',
    title: 'EDIT_PROFILE_PAGE.avg_playtime_once_week_option',
  },
  {
    value: 'FEW_TIMES_MONTH',
    title: 'EDIT_PROFILE_PAGE.avg_playtime_few_times_month_option',
  },
];

export const EDIT_PROFILE_AVG_PLAYTIME_DAILY = [
  {
    value: '1H_LESS',
    title: 'EDIT_PROFILE_PAGE.avg_playtime_daily_1h_less_option',
  },
  {
    value: '2H_LESS',
    title: 'EDIT_PROFILE_PAGE.avg_playtime_daily_2h_less_option',
  },
  {
    value: '4H_LESS',
    title: 'EDIT_PROFILE_PAGE.avg_playtime_daily_4h_less_option',
  },
  {
    value: '6H_LESS',
    title: 'EDIT_PROFILE_PAGE.avg_playtime_daily_6h_less_option',
  },
  {
    value: '6H_MORE',
    title: 'EDIT_PROFILE_PAGE.avg_playtime_daily_6h_more_option',
  },
];
export const EDIT_PROFILE_DAY_PLAYTIME = [
  {
    value: '6_12_MORNING',
    title: 'EDIT_PROFILE_PAGE.6_12_morning',
  },
  {
    value: '1_3_AFTERNOON',
    title: 'EDIT_PROFILE_PAGE.1_3_afternoon',
  },
  {
    value: '4_7_EVENING',
    title: 'EDIT_PROFILE_PAGE.4_7_evening',
  },
  {
    value: '8_12_EVENING',
    title: 'EDIT_PROFILE_PAGE.8_12_evening',
  },
  {
    value: '1_5_MIDNIGHT',
    title: 'EDIT_PROFILE_PAGE.1_5_midnight',
  },
];
