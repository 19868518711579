import React, { Component } from 'react';
import { View, FlatList, TouchableOpacity } from 'react-native';
import { compose } from 'redux';
import { connect } from 'react-redux';
import I18n from '../../i18n';

// import components
import Header from '../../components/header';
import ClanSmallCard from '../../components/cards/clan-small-card';

// import styles
import AppStyles from '../../constants/styles';
import styles from './styles';

import NavigationService from '../../navigation/navigationService';
import { perfectSize } from '../../helpers/deviceHelper';
import { dataForSkeleton } from '../../services/utils';

class UserClansPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clans: [],
      isLoading: true,
      loadingCount: 2
    };
  }

  componentDidMount() {
    const clans = this.props.navigation.state.params.clans;
    const username = this.props.navigation.state.params.username;
    const sliceLength = clans.length > 10 ? 10 : clans.length;
    this.setState({
      clans: clans,
      username: username,
      loadingCount: sliceLength
    });
    const preloadClans = clans.slice(0, sliceLength);
    const preloadUri = [];
    preloadClans.map(clan => {
      if (clan.logo) {
        preloadUri.push({ uri: clan.logo })
      }

    });
    setTimeout(() => {
      this.setState({
        isLoading: false
      })
    }, 1000);
  }

  // This function will redirect the user to the team
  toRegisteredTeam = (team) => {
    NavigationService.moveToScreenInStack('HomeStack', 'TeamPage', {
      TEAM_ID: team.id,
      CAME_FROM: 'TEAMS',
    });
  };

  render() {
    const { isLoading, clans, username, loadingCount } = this.state;
    const { theme } = this.props;
    return (
      <View style={AppStyles.rootContainer}>
        <Header
          text={username + ' ' + I18n.t('USER_PROFILE_PAGE.clans')}
        />
        <View style={AppStyles.rootContent}>
          {
            isLoading &&
            <FlatList
              data={dataForSkeleton(loadingCount)}
              keyExtractor={(item) => item.toString()}
              numColumns={2}
              style={{ width: '100%' }}
              renderItem={({ item, index }) => {
                return <View style={index % 2 === 0 ? {
                  width: '50%',
                  paddingRight: perfectSize(8),
                  marginBottom: perfectSize(16),
                } : {
                  width: '50%',
                  paddingLeft: perfectSize(8),
                  marginBottom: perfectSize(16),
                }}>
                  
                    <View style={{ height: perfectSize(100) }} />
                    <View style={{ marginTop: perfectSize(10), height: perfectSize(20) }} />
                    <View style={{ alignItems: 'center' }}>
                      <View style={{ marginTop: perfectSize(10), width: perfectSize(50), height: perfectSize(20) }} />
                    </View>
                  
                </View>
              }}
            />
          }
          {
            !isLoading &&
            <FlatList
              data={clans}
              renderItem={({ item, index }) => (
                <TouchableOpacity
                  onPress={() => this.toRegisteredTeam(item)}
                  style={index % 2 === 0 ? styles.left : styles.right}>
                  <ClanSmallCard
                    teamId={item.id}
                    photo={item.logo}
                    title={item.name}
                    index={index}
                  />
                </TouchableOpacity>
              )}
              keyExtractor={(item, index) => item.id}
              numColumns={2}
            />
          }
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
  };
};

export default compose(connect(mapStateToProps, {}))(UserClansPage);
