/* eslint-disable no-unused-vars */
import React, { memo, Component } from 'react';
import { View, StyleSheet } from 'react-native';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
    Placeholder,
    Progressive
} from "rn-placeholder";

import { perfectSize } from '../../helpers/deviceHelper';
import SkeletonUserAvatar from '../skeletonWrapper/skeletonUserAvatar';
import SkeletonTextContent from '../skeletonWrapper/skeletonTextContent';

class ChatItemHolderSkeleton extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() { }

    componentWillUnmount() { }

    render() {
        const { theme } = this.props;
        const _styles = styles(theme);

        return (

            <View style={_styles.container}>
                <Placeholder
                    Animation={Progressive}
                >
                    <View style={_styles.body}>
                        <SkeletonUserAvatar size={50} />
                        <View style={_styles.wrapper}>
                            <SkeletonTextContent height={15} width={30} style={{ marginBottom: 10 }} />
                            <View style={_styles.designation}>
                                <SkeletonTextContent height={15} width={60} />
                                <SkeletonTextContent height={15} width={10} />
                            </View>
                        </View>
                    </View>
                    <View style={{ height: 1, widht: '100%' }} />
                </Placeholder>
            </View>

        );
    }
}

const mapStateToProps = (state) => {
    return {
        theme: state.appReducer.currentTheme,
    };
};

export default memo(connect(mapStateToProps)(ChatItemHolderSkeleton));

const styles = (theme) =>
    StyleSheet.create({
        container: {
            flexDirection: 'column',
        },
        body: {
            paddingVertical: 9,
            flexDirection: 'row',
        },
        wrapper: {
            marginLeft: 8,
            flex: 1,
            justifyContent: 'center',
        },
        designation: {
            flexDirection: 'row',
            marginTop: 4,
            justifyContent: 'space-between'
        },
        message: {
            opacity: 0.56,
            flex: 1,
        },
        timeline: {
            width: 80,
            color: '#95979A',
            fontWeight: '300',
            textAlign: 'right',
        },
        divider: {
            height: 1,
            backgroundColor: '#FFFFFF',
            opacity: 0.05,
        },
    });
