import { Platform } from 'react-native';
import { perfectSize, isWindows } from '../helpers/deviceHelper';

const dark = {
  mode: 'dark',
  colors: {
    primary: '#C70064',
    secondary: '#181F29',
  },
  primary_background: '#0E1319', // similar to black
  secondary_background: '#181F29',
  primary_btn_background: '#C70064', // pink
  secondary_btn_background: '#181F29', // dark gray
  primary_text: 'white',
  btn_playstation: '#003791', // blue
  btn_tag: '#3B1935', // purple
  request_seperator: '#C74500', // fire
  tab_active_text_color: '#FFFFFF',
  tab_inactive_text_color: '#A2A5A9',
};

// Todo: We will add light theme later
const light = {
  mode: 'light',
  primary_background: 'white',
  primary_btn_background: 'white',
  secondary_btn_background: 'red',
  primary_text: 'black',
  btn_playstation: '#003791', // blue
  btn_tag: '#3B1935', // purple
  request_seperator: '#C74500', // fire
};

export const theme = {
  dark,
  light,
};

export const COLOR = {
  white: '#FFFFFF',
  whiteSmoke: '#FAFAFA',
  black: '#000000',
  curiousBlue: '#3B8BC7',
  lochmara: '#2C6FB4',
  gunPowder: '#4D4D4E',
  eucalypt: '#27ae60',
  red: '#CA3433',
  layout: 'rgba(0,0,0,0.3)',
  darkLayout: 'rgba(0,0,0,0.5)',
};

export const FONT_FAMILY = {
  ubuntu: !isWindows() ? 'Ubuntu' : 'Ubuntu-R',
  ubuntu_bold: !isWindows() ? 'Ubuntu-Bold' : 'Ubuntu-B',
  cairo: !isWindows() ? 'Cairo' : 'Cairo-Regular',
  cairo_bold: !isWindows() ? 'Cairo-Bold' : 'Cairo-Bold',
};

export const FONT_SIZE = {
  x_large: perfectSize(46),
  large: perfectSize(38),
  x_big: perfectSize(30),
  big: perfectSize(24),
  x_medium: perfectSize(20),
  medium: perfectSize(16),
  x_small: perfectSize(14),
  small: perfectSize(12),
};

export const FONT_WEIGHT = {
  regular: 'normal',
  bold: 'bold',
};
