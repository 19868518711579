export default class Userinformation {
  constructor() {
    this.username = '';
    this.pictureURL = '';
    this.UID = '';
    this.nickName = '';
    this.userEmail = '';
    this.bio = '';
    this.isPremium = false;
    this.PSNAcc = '';

    // Analytics Attrs
    this.age = '';
    this.avgDailyPlaytime = '';
    this.avgPlaytime = '';
    this.city = '';
    this.country = '';
    this.favGame = '';
    this.gender = '';
    this.mainPlatform = '';
    this.phone = '';
    this.dayPlayTime = '';
    this.status = 'offline';
    this.borderColor = '#880e4a';

    // take the game provide accout as a key and it's value : ex : steam : key , kdesu : value
    this.pcGamesAccs = new Map();

    // take the game provide accout as a key and it's value : ex : pubg mobile : key , kdesu : value  : the provider is always the game name here
    // Key  Pubg(space)Mobile , khaled
    this.mobileGamesAccs = new Map();

    // takes games as keys  , and pc providers as value : DONT THINK THIS ONE HAVE DATA USE THE ONE IN GAME MANAGER
    this.pcGamesWithProviders = new Map();

    this.XboxLiveAcc = '';
    this.hopyPoints = '';

    // This attr for user nintendo account
    this.nintendoAcc = '';

    this.gamesNumber = 0;
    this.friendsNumber = 0;

    // Rank
    this.rank = 0;

    // Cosmetics
    this.profileBoderColor = '#C70464';

    // Icons
    this.profile_icon = '';
    this.hops_icon = '';
  }

  IsPremium() {
    return this.IsPremium;
  }
}
