import firebase from 'firebase/app';
import 'firebase/database';

import * as FirebasePaths from '../../constants/firebasePaths';
import {store} from '../../redux/store';

export default class Userprofilecore {
  // This function will load the user notifications
  static loadUserNotifications() {
    const uid = store.getState().authReducer.uid;
    // get the path
    let path =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      uid +
      '/' +
      FirebasePaths.FIREBASE_USER_NOTIFICATIONS;

    return firebase.database().ref(path);
  }

  // Get user hops likes count
  static getHopsLikesCount() {
    const uid = store.getState().authReducer.uid;
    return firebase.database().ref(
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
        '/' +
        uid +
        '/' +
        FirebasePaths.FIREBASE_HOPS_LIKES_ATTR,
    );
  }
}
