import firebase from 'firebase/app';
import 'firebase/database';

import * as FirebasePaths from '../../constants/firebasePaths';
import {store} from '../../redux/store';
import {set as HubSet} from '../../redux/modules/hub/actions';

export default class TimelineCore {
  static loadRecentPosts() {
    return firebase.database().ref(FirebasePaths.FIREBASE_TIMELINE).limitToLast(100);
  }

  static loadMyHops() {
    return firebase.database()
      .ref(
        FirebasePaths.FIREBASE_USERS_INFO_ATTR +
          '/' +
          store.getState().authReducer.uid +
          '/' +
          FirebasePaths.FOREBASE_USER_HOPS_ATTR,
      )
      .limitToLast(50);
  }

  static loadUserHops(uid, endAt = null, limit = 10) {
    // return firebase.database()
    //   .ref(FirebasePaths.FIREBASE_TIMELINE)
    //   .orderByChild('/poster')
    //   .equalTo(uid);
    const ref =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      uid +
      '/' +
      FirebasePaths.FOREBASE_USER_HOPS_ATTR;
    if (endAt) {
      return firebase.database()
        .ref(ref)
        .orderByChild('timeStamp')
        .endAt(endAt)
        .limitToLast(limit);
    } else {
      return firebase.database().ref(ref).orderByChild('timeStamp').limitToLast(limit);
    }
  }

  static loadRepliesFromOthers(endAt) {
    let refFromOthers =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      store.getState().authReducer.uid +
      '/' +
      FirebasePaths.FIREBASE_USER_TIMELINE_REPLIES_ATTR +
      '/' +
      FirebasePaths.FIREBASE_USER_POST_REPLIES_FROM_OTHERS_ATTR;

    if (endAt) {
      return firebase.database()
        .ref(refFromOthers)
        .orderByKey()
        .endAt(endAt)
        .limitToLast(10);
    } else {
      return firebase.database().ref(refFromOthers).orderByKey().limitToLast(10);
    }
  }

  static loadRepliesToOthers() {
    let refToOthers =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      store.getState().authReducer.uid +
      '/' +
      FirebasePaths.FIREBASE_USER_TIMELINE_REPLIES_ATTR +
      '/' +
      FirebasePaths.FIREBASE_USER_POST_REPLIES_TO_OTHERS_ATTR;

    return firebase.database().ref(refToOthers);
  }

  static loadAdminsList(callback) {
    return firebase.database()
      .ref(FirebasePaths.FIREBASE_ADMINS_LIST)
      .on('value', callback);
  }

  static getHopLikesCount(post) {
    if (post._hops_likes_) {
      const obj = post._hops_likes_;

      if (obj[post.id]._likes_) {
        return Object.keys(obj[post.id]._likes_).length;
      }
    }

    return 0;
  }

  static getLiked(post) {
    if (post._hops_likes_) {
      const obj = post._hops_likes_;

      if (obj[post.id]._likes_) {
        return Object.keys(obj[post.id]._likes_).includes(
          store.getState().authReducer.uid,
        );
      }
    }

    return false;
  }

  static getHopDislikesCount(post) {
    if (post._hops_likes_) {
      const obj = post._hops_likes_;

      if (obj[post.id]._dislikes_) {
        return Object.keys(obj[post.id]._dislikes_).length;
      }
    }

    return 0;
  }

  static getDisLiked(post) {
    if (post._hops_likes_) {
      const obj = post._hops_likes_;

      if (obj[post.id]._dislikes_) {
        return Object.keys(obj[post.id]._dislikes_).includes(
          store.getState().authReducer.uid,
        );
      }
    }

    return false;
  }

  static increaseHP(post) {
    let adminHPPath =
      '/' +
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      post.poster +
      '/' +
      FirebasePaths.FIREBASE_DETAILS_ATTR +
      '/' +
      FirebasePaths.FIREBASAE_USER_RANK_ATTR;

    let HP = 0;

    firebase.database()
      .ref(adminHPPath)
      .once('value')
      .then((snapshot) => {
        if (snapshot.val()) {
          HP = snapshot.val();
        }
        firebase.database()
          .ref(adminHPPath)
          .set(HP + 5);
      });
  }

  static uploadPost(post, callback) {
    firebase.database()
      .ref(FirebasePaths.FIREBASE_TIMELINE + '/' + post.id)
      .set(post)
      .then((response) => {
        TimelineCore.uploadToMyHops(post, callback);
      });
  }

  static uploadToMyHops(hop, callback) {
    firebase.database()
      .ref(
        FirebasePaths.FIREBASE_USERS_INFO_ATTR +
          '/' +
          store.getState().authReducer.uid +
          '/' +
          FirebasePaths.FOREBASE_USER_HOPS_ATTR +
          '/' +
          hop.postId,
      )
      .set(hop)
      .then((response) => {
        callback(true);
      });
  }

  static IS_REPLY_HOP_BANNED(callback) {
    let path =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      store.getState().authReducer.uid +
      '/' +
      FirebasePaths.FIREBASE_USER_BAN +
      '/' +
      FirebasePaths.FIREBASE_USER_BAN_REPLY_HOP;
    firebase.database()
      .ref(path)
      .once('value', (snapshot) => {
        if (snapshot.val() != null) {
          callback(true);
        } else {
          callback(false);
        }
      });
  }

  static thumbUpHop(post) {
    if (post.id === undefined) {
      return;
    }

    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;
    firebase.database()
      .ref(
        FirebasePaths.FIREBASE_TIMELINE +
          '/' +
          post.id +
          '/' +
          FirebasePaths.FIREBASE_HOPS_LIKES_ATTR +
          '/' +
          post.id +
          '/' +
          FirebasePaths.FIREBASE_LIKES_ATTR +
          '/' +
          uid,
      )
      .set(username);

    firebase.database()
      .ref(
        FirebasePaths.FIREBASE_TIMELINE +
          '/' +
          post.id +
          '/' +
          FirebasePaths.FIREBASE_HOPS_LIKES_ATTR +
          '/' +
          post.id +
          '/' +
          FirebasePaths.FIREBASE_DISLIKES_ATTR +
          '/' +
          uid,
      )
      .remove();

    firebase.database()
      .ref(
        FirebasePaths.FIREBASE_USERS_INFO_ATTR +
          '/' +
          post.uid +
          '/' +
          FirebasePaths.FIREBASE_HOPS_LIKES_ATTR +
          '/' +
          post.id +
          '/' +
          FirebasePaths.FIREBASE_LIKES_ATTR +
          '/' +
          uid,
      )
      .set(username);

    firebase.database()
      .ref(
        FirebasePaths.FIREBASE_USERS_INFO_ATTR +
          '/' +
          post.uid +
          '/' +
          FirebasePaths.FIREBASE_HOPS_LIKES_ATTR +
          '/' +
          post.id +
          '/' +
          FirebasePaths.FIREBASE_DISLIKES_ATTR +
          '/' +
          uid,
      )
      .remove();

    if (post.uid !== uid) {
      let notificationId = firebase.database().ref().push().key;
      let notificationObj = {
        id: notificationId,
        status: 'UNREAD',
        body: username + ' ' + ' اعطاك لايك عالهوب ',

        type: 'HOP_LIKE',
        TRIGGER: post.postId,
        timeStamp: firebase.database.ServerValue.TIMESTAMP,
      };

      let friendNotificationsPath =
        FirebasePaths.FIREBASE_USERS_INFO_ATTR +
        '/' +
        post.uid +
        '/' +
        FirebasePaths.FIREBASE_USER_NOTIFICATIONS +
        '/' +
        notificationId;

      // For the admin

      firebase.database().ref(friendNotificationsPath).set(notificationObj);
    }
  }
  static thumbDownHop(post) {
    if (post.id === undefined) {
      return;
    }

    const uid = store.getState().authReducer.uid;
    const username = store.getState().authReducer.userinformationDB.username;

    firebase.database()
      .ref(
        FirebasePaths.FIREBASE_TIMELINE +
          '/' +
          post.id +
          '/' +
          FirebasePaths.FIREBASE_HOPS_LIKES_ATTR +
          '/' +
          post.id +
          '/' +
          FirebasePaths.FIREBASE_LIKES_ATTR +
          '/' +
          uid,
      )
      .remove();

    firebase.database()
      .ref(
        FirebasePaths.FIREBASE_TIMELINE +
          '/' +
          post.id +
          '/' +
          FirebasePaths.FIREBASE_HOPS_LIKES_ATTR +
          '/' +
          post.id +
          '/' +
          FirebasePaths.FIREBASE_DISLIKES_ATTR +
          '/' +
          uid,
      )
      .set(username);
    firebase.database()
      .ref(
        FirebasePaths.FIREBASE_USERS_INFO_ATTR +
          '/' +
          post.uid +
          '/' +
          FirebasePaths.FIREBASE_HOPS_LIKES_ATTR +
          '/' +
          post.id +
          '/' +
          FirebasePaths.FIREBASE_LIKES_ATTR +
          '/' +
          uid,
      )
      .remove();

    firebase.database()
      .ref(
        FirebasePaths.FIREBASE_USERS_INFO_ATTR +
          '/' +
          post.uid +
          '/' +
          FirebasePaths.FIREBASE_HOPS_LIKES_ATTR +
          '/' +
          post.id +
          '/' +
          FirebasePaths.FIREBASE_DISLIKES_ATTR +
          '/' +
          uid,
      )
      .set(username);
  }

  static removeOthersReplies() {
    let ref =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      store.getState().authReducer.uid +
      '/' +
      FirebasePaths.FIREBASE_USER_TIMELINE_REPLIES_ATTR +
      '/' +
      FirebasePaths.FIREBASE_USER_POST_REPLIES_FROM_OTHERS_ATTR;

    firebase.database().ref(ref).remove();
  }

  static removeUserReplies() {
    let ref =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      store.getState().authReducer.uid +
      '/' +
      FirebasePaths.FIREBASE_USER_TIMELINE_REPLIES_ATTR +
      '/' +
      FirebasePaths.FIREBASE_USER_POST_REPLIES_TO_OTHERS_ATTR;
    firebase.database().ref(ref).remove();
  }

  static IS_POST_HOP_BANNED(callback) {
    let path =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      store.getState().authReducer.uid +
      '/' +
      FirebasePaths.FIREBASE_USER_BAN +
      '/' +
      FirebasePaths.FIREBASE_USER_BAN_POST_HOP;

    firebase.database()
      .ref(path)
      .once('value', (snapshot) => {
        callback(snapshot.val());
      });
  }

  static removeUserReply(reply) {
    let ref =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      store.getState().authReducer.uid +
      '/' +
      FirebasePaths.FIREBASE_USER_TIMELINE_REPLIES_ATTR +
      '/' +
      FirebasePaths.FIREBASE_USER_POST_REPLIES_TO_OTHERS_ATTR +
      '/' +
      reply.postId;

    firebase.database().ref(ref).remove();

    ref =
      FirebasePaths.FIREBASE_TIMELINE_REPLIES +
      '/' +
      reply.postId +
      '/' +
      FirebasePaths.FIREBASE_TIMELINE_REPLIES_REPLY +
      '/' +
      reply.id +
      '/delete';
    firebase.database().ref(ref).set(true);

    if (store.getState().authReducer.uid !== reply.poster) {
      ref =
        FirebasePaths.FIREBASE_USERS_INFO_ATTR +
        '/' +
        reply.poster +
        '/' +
        FirebasePaths.FIREBASE_USER_TIMELINE_REPLIES_ATTR +
        '/' +
        FirebasePaths.FIREBASE_USER_POST_REPLIES_FROM_OTHERS_ATTR +
        '/' +
        reply.postId +
        '/' +
        reply.id +
        '/delete';
      firebase.database().ref(ref).set(true);
    }
  }

  // This function will remove the other (single) reply

  static removeOtherReply(reply) {
    let ref =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      store.getState().authReducer.uid +
      '/' +
      FirebasePaths.FIREBASE_USER_TIMELINE_REPLIES_ATTR +
      '/' +
      FirebasePaths.FIREBASE_USER_POST_REPLIES_FROM_OTHERS_ATTR +
      '/' +
      reply.postId +
      '/' +
      reply.id;
    firebase.database().ref(ref).remove();
  }

  static deleteMyHop(hop) {
    let ref =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      store.getState().authReducer.uid +
      '/' +
      FirebasePaths.FOREBASE_USER_HOPS_ATTR +
      '/' +
      hop.id;

    // Remove from My Hops

    firebase.database()
      .ref(ref)
      .remove()
      .then(() => {
        let myHopsArr = store.getState().hubReducer.myHopsArr;
        if (myHopsArr !== undefined) {
          myHopsArr.forEach((item, index) => {
            if (item.id === hop.id) {
              myHopsArr.splice(index, 1);
            }
          });

          store.dispatch(HubSet({myHopsArr: myHopsArr}));
        }

        // Remove from Timeline

        firebase.database()
          .ref(FirebasePaths.FIREBASE_TIMELINE + '/' + hop.id)
          .remove()
          .then(() => {
            let timelinePosts = store.getState().hubReducer.timelinePosts;
            let timelinePostsArr = store.getState().hubReducer.timelinePostsArr;
            if (timelinePosts !== undefined) {
              timelinePosts.forEach((item, index) => {
                if (item.id === hop.id) {
                  timelinePosts.splice(index, 1);
                  timelinePostsArr.splice(index, 1);
                }
              });

              store.dispatch(
                HubSet({
                  timelinePosts: timelinePosts,
                  timelinePostsArr: timelinePostsArr,
                }),
              );
            }
          });
      });
  }

  static loadADSpot(callback) {
    let path =
      FirebasePaths.FIREBASE_AD_SPOTS +
      '/' +
      FirebasePaths.FIREBASE_AD_SPOTS_HOPS;

    firebase.database()
      .ref(path)
      .once('value', (snapshot) => {
        callback(snapshot.val());
      });
  }

  static deleteReply(item) {
    let ref =
      FirebasePaths.FIREBASE_TIMELINE_REPLIES +
      '/' +
      item.postId +
      '/' +
      FirebasePaths.FIREBASE_TIMELINE_REPLIES_REPLY +
      '/' +
      item.id +
      '/delete';
    firebase.database().ref(ref).set(true);

    if (store.getState().authReducer.uid !== item.poster) {
      ref =
        FirebasePaths.FIREBASE_USERS_INFO_ATTR +
        '/' +
        item.poster +
        '/' +
        FirebasePaths.FIREBASE_USER_TIMELINE_REPLIES_ATTR +
        '/' +
        FirebasePaths.FIREBASE_USER_POST_REPLIES_FROM_OTHERS_ATTR +
        '/' +
        item.postId +
        '/' +
        item.id +
        '/delete';
      firebase.database().ref(ref).set(true);

      ref =
        FirebasePaths.FIREBASE_USERS_INFO_ATTR +
        '/' +
        store.getState().authReducer.uid +
        '/' +
        FirebasePaths.FIREBASE_USER_TIMELINE_REPLIES_ATTR +
        '/' +
        FirebasePaths.FIREBASE_USER_POST_REPLIES_TO_OTHERS_ATTR +
        '/' +
        item.postId +
        '/delete';
      firebase.database().ref(ref).set(true);
    }
  }

  static loadHop(id) {
    let ref = FirebasePaths.FIREBASE_TIMELINE + '/' + id;
    return firebase.database().ref(ref);
  }
}
