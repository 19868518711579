import React, {memo, Component} from 'react';
import {View, StyleSheet} from 'react-native';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import SimpleTab from './simpleTab';
import {perfectSize} from '../../helpers/deviceHelper';

class SimpleTabBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: props.activeTab || 0,
    };
  }

  render() {
    const {theme, tabs, justifyContent} = this.props;
    const tapPad =
      justifyContent && justifyContent === 'space-between'
        ? 0
        : perfectSize(24);
    return (
      <View
        style={[
          {justifyContent: justifyContent || 'flex-start'},
          styles(theme).container,
        ]}>
        {tabs.map((tab, key) => {
          return (
            <SimpleTab
              style={{
                marginRight: tapPad,
              }}
              name={tab.text || tab}
              position={key}
              isActive={this.state.activeTab === key}
              showBadge={tab.showBadge}
              onPress={this.tabSelected}
              key={key}
            />
          );
        })}
      </View>
    );
  }

  tabSelected = (position) => {
    if (this.state.activeTab !== position) {
      const {onTabChanged} = this.props;
      onTabChanged && onTabChanged(position);
    }
    this.setState({activeTab: position});
  };
}

const styles = (theme) =>
  StyleSheet.create({
    container: {
      flexDirection: 'row',
      paddingBottom: perfectSize(8),
    },
  });

SimpleTabBar.propTypes = {
  tabs: PropTypes.array,
  activeTab: PropTypes.number,
  justifyContent: PropTypes.string,
  tabIndicatorColor: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
  };
};

export default memo(connect(mapStateToProps)(SimpleTabBar));
