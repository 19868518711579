/* eslint-disable react-native/no-inline-styles */
import React, {memo, Component} from 'react';
import {View, FlatList, TouchableOpacity} from 'react-native';
import {connect} from 'react-redux';
import Feather from 'react-native-vector-icons/Feather';
import firebase from 'firebase/app';
import 'firebase/database';

import {store} from '../../redux/store';
import I18n from '../../i18n';

import * as FirebasePaths from '../../constants/firebasePaths';

import Label from '../../components/label';
import TextInput from '../../components/text-input';

import AppStyles from '../../constants/styles';
import styles from './styles';

import Pipes from '../../services/pipes';
import Teamcore from './teamscore';
import {presentToast} from '../../helpers/toastHelper';
import {perfectSize} from '../../helpers/deviceHelper';
import {showInputAlert} from '../../redux/modules/alert/actions';
import {
  handleAndroidBackButton,
  removeAndroidBackButtonHandler,
} from '../../helpers/deviceHelper';
import NavigationService from '../../navigation/navigationService';

class Updates extends Component {
  constructor(props) {
    super(props);
    const params = props.route.params;
    this.state = {
      teamId: params.teamId,
      feedsArr: [],
      teamAdmin: '',
      secondAdminsMap: new Map(),
      description: '',
      membersMap: new Map(),
      teamName: '',
    };
  }

  componentDidMount() {
    Teamcore.loadTeamInfo(this.state.teamId).then((teamShot) => {
      teamShot.forEach((item) => {
        if (item.key === 'admin') {
          this.setState({teamAdmin: item.val()});
        }
        if (item.key === 'name') {
          this.setState({teamName: item.val()});
        }
      });
    });

    this.secondAdminsRef$ = firebase.database().ref(
      FirebasePaths.FIREBASE_TEAMS +
        '/' +
        this.state.teamId +
        '/' +
        FirebasePaths.FIREBASE_TEAM_SECOND_ADMINS +
        '/',
    );
    this.secondAdminsRef$.on('value', (shot) => {
      let secondAdminsMap = new Map();
      shot.forEach((item) => {
        secondAdminsMap.set(item.key, item.val());
      });
      this.setState({secondAdminsMap});
    });
    // Load team feeds
    this.feedsRef$ = firebase.database()
      .ref(
        FirebasePaths.FIREBASE_TEAMS +
          '/' +
          this.state.teamId +
          '/' +
          FirebasePaths.FIREBASE_TEAM_FEEDS +
          '/',
      )
      .limitToLast(100);
    this.feedsRef$.on('value', (shots) => {
      let feedsArr = [];
      shots.forEach((item) => {
        feedsArr.push(item.val());
      });
      feedsArr.reverse();
      this.setState({feedsArr});
    });
    // Load members
    this.membersRef$ = firebase.database().ref(
      FirebasePaths.FIREBASE_TEAMS +
        '/' +
        this.state.teamId +
        '/' +
        FirebasePaths.FIREBASE_TEAM_MEMBERS,
    );
    this.membersRef$.on('value', (shot) => {
      let membersMap = new Map();
      shot.forEach((item) => {
        let member = item.val();
        membersMap.set(member.uid, member);
      });
      this.setState({
        membersMap,
      });
    });
  }

  componentWillMount() {
    handleAndroidBackButton(() => {
      NavigationService.goBack();
    });
  }

  componentWillUnmount() {
    if (this.feedsRef$) {
      this.feedsRef$.off();
      this.feedsRef$ = null;
    }
    if (this.secondAdminsRef$) {
      this.secondAdminsRef$.off();
      this.secondAdminsRef$ = null;
    }
    if (this.membersRef$) {
      this.membersRef$.off();
      this.membersRef$ = null;
    }
    removeAndroidBackButtonHandler();
  }

  // This function will show a post dialog or redirect user to add members page
  addMemberOrPost = () => {
    // show post or feed dialog
    this.props.showInputAlert([
      I18n.t('TEAM_PAGE.feed_alert_title'),
      <TextInput
        placeholder={I18n.t('TEAM_PAGE.feed_alert_input_placeholder')}
        onChangeText={(text) => this.onChangeDescription(text)}
        customStyles={{
          marginTop: perfectSize(32),
          borderBottomColor: 'white',
          borderBottomWidth: 1,
          paddingHorizontal: perfectSize(0),
          borderRadius: 0,
        }}
        inputStyles={{paddingLeft: perfectSize(4)}}
        placeholderTextColor={'gray'}
      />,
      [
        {
          text: I18n.t('TEAM_PAGE.feed_alert_cancel'),
          onPress: () => {},
        },
        {
          text: I18n.t('TEAM_PAGE.feed_alert_upload'),
          onPress: () => {
            this.handleUpload();
          },
        },
      ],
    ]);
  };

  onChangeDescription = (description) => {
    this.setState({description});
  };

  handleUpload = () => {
    let success = I18n.t('TEAM_PAGE.feed_alert_success_msg');
    let error = I18n.t('TEAM_PAGE.feed_alert_error_msg');

    let text = this.state.description;
    if (text.toString().length > 10 && text.toString().length <= 280) {
      // Now call the upload feed back-end and locally
      // Create a feed obj
      let feedId = firebase.database().ref().push().key;
      let feed = {
        id: feedId,
        timeStamp: firebase.database.ServerValue.TIMESTAMP,
        feed: text,
        admin: store.getState().authReducer.uid,
      };
      Teamcore.uploadFeed(this.state.teamId, feed);

      // Notify all the members with the feed
      this.state.membersMap.forEach((value, key) => {
        if (value.uid != null) {
          Teamcore.feedNotifications(
            value.uid,
            this.state.teamName,
            this.state.teamId,
          );
        }
      });

      // Log Rank HP
      global.rankLogger.LOG_NEW_HP(
        global.rankLogger.SEND_TEAM_FEED,
        'SEND_TEAM_FEED',
      );

      // Log the upload feed
      global.featuresLogger.LOG_TEAMS_TEAM_UPLOAD_FEED();

      // Load team feeds : UPDATE LOCAL ARRAY
      Teamcore.loadTeamFeeds(this.state.teamId).then((shots) => {
        let feedsArr = [];
        shots.forEach((item) => {
          feedsArr.push(item.val());
        });
        feedsArr.reverse();
        this.setState({feedsArr});
      });

      presentToast({
        message: success,
      });
      this.setState({description: ''});
    } else {
      // error msg
      presentToast({
        message: error,
      });
    }
  };

  render() {
    const {theme} = this.props;
    const _styles = styles(theme);
    const {feedsArr, teamAdmin, secondAdminsMap} = this.state;
    const uid = store.getState().authReducer.uid;
    return (
      <View style={_styles.subContent}>
        {(teamAdmin === uid || secondAdminsMap.has(uid)) && (
          <TouchableOpacity onPress={this.addMemberOrPost}>
            <View style={_styles.newUpdateBtn}>
              <Feather
                name="plus-circle"
                size={perfectSize(15)}
                color={'white'}
                style={{marginRight: perfectSize(4)}}
              />
              <Label size="x_small">{I18n.t('TEAM_PAGE.no_feeds_btn')}</Label>
            </View>
          </TouchableOpacity>
        )}
        {feedsArr.length > 0 ? (
          <FlatList
            data={feedsArr}
            renderItem={({item, index}) => {
              return (
                <View style={_styles.updateCard}>
                  <Label size="x_small">{item.feed}</Label>
                  <Label
                    size="small"
                    style={{
                      color: theme.tab_inactive_text_color,
                      marginTop: 8,
                      textAlign: 'right',
                    }}>
                    {Pipes.humantime(item.timeStamp)}
                  </Label>
                </View>
              );
            }}
          />
        ) : (
          <View style={{marginTop: perfectSize(32)}}>
            <View style={AppStyles.emptyContent}>
              <Label style={[AppStyles.emptyContentLabel, {marginTop: 0}]}>
                {I18n.t('TEAM_PAGE.no_feeds_msg')}
              </Label>
            </View>
          </View>
        )}
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
  };
};

export default memo(connect(mapStateToProps, {showInputAlert})(Updates));
