/* eslint-disable react-native/no-inline-styles */
import React, {Component} from 'react';
import {View, TouchableOpacity} from 'react-native';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {store} from '../../../redux/store';
import I18n from '../../../i18n';
import Feather from 'react-native-vector-icons/Feather';
import Entypo from 'react-native-vector-icons/Entypo';
import FontAwesome from 'react-native-vector-icons/FontAwesome';
import {launchImageLibrary} from 'react-native-image-picker';
import * as ImagePicker from 'expo-image-picker';

// import components
import Header from '../../../components/header';
import Label from '../../../components/label';
import TextInput from '../../../components/text-input';
import UserAvatar from '../../../components/user-avatar';
import ImageWithPlaceholder from '../../../components/image-with-placeholder';
import PrimaryButton from '../../../components/buttons/primary-button';

// import styles
import AppStyles from '../../../constants/styles';
import styles from './styles';
import {UPLOAD, HOOTS_COVER} from '../../../constants/images';

// import actions
import {switchTheme} from '../../../redux/modules/app/actions';
import {set as setHub} from '../../../redux/modules/hub/actions';
import {
    showAlert,
    showSpinner,
    hideSpinner,
} from '../../../redux/modules/alert/actions';

import {perfectSize} from '../../../helpers/deviceHelper';
import {presentToast} from '../../../helpers/toastHelper';
import Team from '../../../models/team';

import * as FirebasePaths from '../../../constants/firebasePaths';
import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/storage';
import NavigationService from '../../../navigation/navigationService';
import LocalImageOpacity from '../../../components/local-image-opacity';
import {FONT_SIZE} from '../../../constants/theme';
import Picker from '../../../components/picker';
import Hoot from '../../../models/hoot';

class CreateNewHootPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hootTitle: '',
            teamLogo:
                'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/teams%20default%20photos%20and%20background%2Fdefault_team_logov2.png?alt=media&token=b88bf926-35b9-4568-a80e-ee24110aceb6',
            hootCover: '',
            hootGenreKey: 'GENERAL',
            hootGenre: {
                ar: 'عام',
                en: 'General',
                icon: 'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/HootsGenres%2FGeneral.png?alt=media&token=b8d3d064-0c9b-4d39-b1a4-d7c0596945df'
            },
            autoGeneratedTeamId: '',
        };
    }

    componentDidMount() {
        this.focusListener = this.props.navigation.addListener(
            'didFocus',
            (payload) => {
                const {params} = this.props.navigation.state;
                if (params) {
                    const {genre} = params;
                    // Log genre selected
                    global.featuresLogger.CREATE_HOOT_GENRE_SELECT();
                    this.setState({
                        hootGenreKey: genre.key,
                        hootGenre: genre.value
                    })
                } else {
                    // Log entering create hoot view
                    global.featuresLogger.CREATE_HOOT_VIEW();
                }
            },
        );
    }

    componentWillUnmount() {
        if (this.focusListener) {
            this.focusListener.remove()
        }
    }

    onNameChange = (val) => {
        this.setState({hootTitle: val});
    };

    // Validate the hoot name check if the name is not taken
    validateHoot() {
        const {hootTitle} = this.state;
        const title = I18n.t(
            'HOOTS_PAGE.hoots_title_validation_rule',
        );
        if (hootTitle.toString().trim().replace(/\s\s+/g, ' ').length < 1) {
            let msg = I18n.t('HOOTS_PAGE.hoots_title_at_least_character');
            this.props.showAlert([
                title,
                msg,
                [
                    {
                        text: 'OK',
                        onPress: () => {},
                    },
                ],
            ]);
            return false;
        }

        if (hootTitle.toString().trim().replace(/\s\s+/g, ' ').length > 100) {
            let msg = I18n.t('HOOTS_PAGE.hoots_title_exceed_100_character');
            this.props.showAlert([
                title,
                msg,
                [
                    {
                        text: 'OK',
                        onPress: () => {},
                    },
                ],
            ]);
            return false;
        }

        // Check team Regx
        const onlySpecialCharactersRegex = /^[^a-zA-Z\u0600-\u06FF]+$/;
        if (onlySpecialCharactersRegex.test(hootTitle.toString().toLocaleLowerCase().trim())) {
            const msg = I18n.t('HOOTS_PAGE.hoots_title_only_symbols_or_numbers');
            this.props.showAlert([
                title,
                msg,
                [
                    {
                        text: 'OK',
                        onPress: () => {},
                    },
                ],
            ]);
            return false;
        }


        return true;
    }

    // This function will register the hoot in db
    registerHoot = () => {
        // First validate the team name
        if (this.validateHoot()) {
            const autoGeneratedTeamId = firebase.database().ref().push().key;
            this.setState(
                {autoGeneratedTeamId},
                () => {
                    this.uploadCover();
                },
            );
        }
    };

    // This function will be used to finalize the "creating hoot" process
    finalizeCreatingHoot = async () => {
        // Do not forget to trim !
        // Generate a team id and other initial info
        const {uid} = this.props;
        const hootId = this.state.autoGeneratedTeamId;
        const hootTitle = this.state.hootTitle
            .toString()
            .toLowerCase()
            .replace(/\s+/g, ' ')
            .trim();
        const admin = uid;
        const cover = this.state.hootCover;
        const status = 'live';
        let date = new Date();
        const timestamp = date.toDateString();
        if (!this.state.hootGenreKey) {
            presentToast({message: 'Genre is missing'});
            this.props.hideSpinner();
            return;
        }
        const genre = this.state.hootGenreKey;

        const hoot = new Hoot();
        hoot.id = hootId;
        hoot.title = hootTitle;
        hoot.genre = genre;
        hoot.cover = cover;
        hoot.timestamp = timestamp;
        hoot.status = status;
        hoot.admin = admin;
        // add me as a liveParticipants and historyParticipants
        const meAsUser = {
            uid: uid,
            username: this.props.authUserInfo.username,
            rank: this.props.authUserInfo.rank,
            img: this.props.authUserInfo.pictureURL,
        }
        const liveParticipants = {};
        liveParticipants[uid] = meAsUser;
        hoot.liveParticipants = liveParticipants;
        const historyParticipants = {};
        historyParticipants[uid] = meAsUser;
        hoot.historyParticipants = historyParticipants;

        // ... Backend

        // Register hoot data to _hoots_

        const hootPath =
            FirebasePaths.FIREBASE_HOOTS +
            '/' +
            hoot.id
        await firebase.database().ref(hootPath).set(hoot);

        await this.setUserHootRef(hoot);

        global.rankLogger.LOG_NEW_HP(
            global.rankLogger.HOOT_CALL,
            'HOOT_CALL',
        );

        // Log the Hoot created
        global.featuresLogger.CREATE_HOOT_BTN_CLICK();

        this.props.hideSpinner();
        let successImage = I18n.t('HOOTS_PAGE.create_hoot_finished');
        presentToast({message: successImage});

        NavigationService.pop();
        setTimeout(() => {
            NavigationService.moveToScreenInStack('HomeStack', 'HootDetailPage', {
                hoot: {...hoot, isCreate: true}
            })
        }, 150);

    }

    // This function will set the team ref for the user
    setUserHootRef = async (hoot) => {
        const path =
            FirebasePaths.FIREBASE_USERS_INFO_ATTR +
            '/' +
            store.getState().authReducer.uid +
            '/' +
            FirebasePaths.FIREBASE_HOOTS +
            '/' +
            hoot.id;

        // Upload to the user hoots ref path
        await firebase.database().ref(path).set(hoot);
    }

    selectImage = async () => {
        // Log cover click
        global.featuresLogger.CREATE_HOOT_COVER_CLICK();
        const {authUserInfo: {rank}} = this.props;
        if (rank < 8300) {
            presentToast({
                message: I18n.t('HOOTS_PAGE.hoots_cover_hp_more_than') + ' ' + parseInt(8300 - rank) + I18n.t('HOOTS_PAGE.hoots_cover_hp_required')
            })
            // return;
        }
        const result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.All,
            allowsEditing: true,
            base64: true,
        })

        if (!result.cancelled) {
            global.featuresLogger.CREATE_HOOT_COVER_SUCCESS();
            this.setState({
                hootCover: result.uri,
            });
        }
    };

    uploadImageToFirebase(image) {
        //uploads img to firebase storage
        return this.uploadImage(image).then((photoURL) => {
            // Update local img and show success message
            this.setState({hootCover: photoURL});
            presentToast({
                message: I18n.t('JOIN_CHAMPIONSHIP_PAGE.done_upload_background'),
            });
            this.props.hideSpinner();
            return;
        });
    }

    uploadImage(imageURI) {
        // This should be the hoot uid
        let hootId = this.state.autoGeneratedTeamId;
        let imageRef;
        let storageRef = firebase.storage().ref();
        imageRef = storageRef
            .child('_hoots_')
            .child(hootId)
            .child('cover');
        return imageRef.putString(imageURI, 'data_url').then(() => {
            return imageRef.getDownloadURL().then((downloadURL) => {
                return downloadURL;
            });
        }).catch(e => {
        });
    }

    uploadCover() {
        this.props.showSpinner({
            title: I18n.t('JOIN_CHAMPIONSHIP_PAGE.loading_msg'),
        });
        const {hootCover} = this.state;
        if (!hootCover.startsWith('https') && hootCover) {
            this.uploadImageToFirebase(hootCover).then(() => {
                this.finalizeCreatingHoot();
            });
        } else {
            this.finalizeCreatingHoot();
        }
    }

    toSelectGenre = () => {
        // Log genre picker selected
        global.featuresLogger.CREATE_HOOT_GENRE_CLICK();
        NavigationService.moveToScreenInStack('HomeStack', 'SelectGenrePage');
    }

    render() {
        const {theme} = this.props;
        const _styles = styles(theme);
        const {hootCover} = this.state;
        return (
            <View style={AppStyles.rootContainer}>
                <Header text={I18n.t('HOOTS_PAGE.new_hoots_title')} />
                <View style={AppStyles.rootContent}>
                    {/* Title */}
                    <View style={_styles.subContainer}>
                        <Label bold={true}>
                            {I18n.t('HOOTS_PAGE.new_hoots_input_title')}
                        </Label>
                        <TextInput
                            placeholder={I18n.t('HOOTS_PAGE.new_hoots_input_title_placeholder')}
                            onChangeText={(val) => this.onNameChange(val)}
                            inputStyles={{fontSize: perfectSize(14)}}
                            customStyles={{marginTop: perfectSize(16), alignItems: 'center', }}
                            leftComponent={
                                <Feather name="info" size={perfectSize(18)} color="#A2A5A9" />
                            }
                        />
                    </View>
                    {/* Genre */}
                    <View style={_styles.subContainer}>
                        <Label bold={true}>
                            {I18n.t('HOOTS_PAGE.new_hoots_genre')}
                        </Label>
                        <View style={_styles.centerWrapper}>
                            <View style={_styles.logoWrapper}>
                                <Picker
                                    pickerName={
                                        this.state.hootGenre != null && this.state.hootGenre !== undefined
                                            ? this.state.hootGenre[this.props.lang]
                                            : I18n.t('HOOTS_PAGE.new_hoots_genre')
                                    }
                                    leftComponent={
                                        this.state.hootGenre !== undefined && this.state.hootGenre !== null ? (
                                            <ImageWithPlaceholder
                                                uri={this.state.hootGenre.icon}
                                                style={{
                                                    width: perfectSize(20),
                                                    height: perfectSize(20)
                                                }}
                                                resizeMode="contain"
                                            />
                                        ) : (
                                            <FontAwesome
                                                name="th-large"
                                                size={perfectSize(20)}
                                                color={theme.primary_text}
                                                style={{opacity: 0.5}}
                                            />
                                        )
                                    }
                                    onPickerPress={this.toSelectGenre}
                                />
                            </View>
                        </View>
                    </View>
                    {/* Clan Header */}
                    <View style={_styles.subContainer}>
                        <Label bold={true}>
                            {I18n.t('HOOTS_PAGE.new_hoots_cover')}
                        </Label>
                        <View>
                            <View style={_styles.headerWrapper}>
                                {
                                    hootCover ? <ImageWithPlaceholder
                                        uri={hootCover}
                                        style={_styles.header}
                                        resizeMode="cover"
                                    /> : <View style={{alignItems: 'center', justifyContent: 'center', alignContent: 'center', flex: 1}}>
                                        <LocalImageOpacity
                                            style={{width: perfectSize(46), height: perfectSize(46)}}
                                            source={HOOTS_COVER}
                                            resizeMode="contain"
                                        />
                                    </View>
                                }

                                <TouchableOpacity
                                    style={_styles.headerUploadWrapper}
                                    onPress={() => this.selectImage()}>
                                    <View style={_styles.uploadButton}>
                                        <LocalImageOpacity
                                            style={{width: '50%', height: '50%'}}
                                            source={UPLOAD}
                                            resizeMode="contain"
                                        />
                                    </View>
                                </TouchableOpacity>
                            </View>
                        </View>
                    </View>
                    <View style={_styles.space} />
                    {/* Submit button */}
                    <View style={_styles.footer}>
                        <PrimaryButton
                            text={I18n.t('HOOTS_PAGE.new_hoots_create_btn')}
                            type="filled"
                            onPress={() => this.registerHoot()}
                            textCustomStyles={_styles.submitTextStyle}
                        />
                    </View>
                </View>
            </View>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        theme: state.appReducer.currentTheme,
        lang: state.appReducer.currentLang,
        uid: state.authReducer.uid,
        authUserInfo: state.authReducer.userinformationDB,
    };
};

export default compose(
    connect(mapStateToProps, {
        switchTheme,
        setHub,
        showAlert,
        showSpinner,
        hideSpinner,
    }),
)(CreateNewHootPage);
