export default class UserChatRef {
  constructor() {
    this.chatRef = '';
    this.counter = 0;
    this.opponentId = '';
    this.lastMessage = '';
    this.lastMessageTimestamp = null;
    this.opponentPic = '';
    this.opponentUsername = '';
    this.senderId = '';
    this.opponentBio = '';
    this.chatType = '';
    this.opened = false;
    this.status = 'offline';
    this.profileBorderColor = '#880e4a';
    this.isSticker = false;

    this.groupNewTitle = '';

    // For public refs : To access request path , for the request id is the same as the chatref
    this.platform = '';
    this.gameId = '';
    this.region = '';
  }
}
