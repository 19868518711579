/* eslint-disable react-native/no-inline-styles */
import React, {Component} from 'react';
import {View, Image, TouchableOpacity, FlatList, Linking} from 'react-native';
import Entypo from 'react-native-vector-icons/Entypo';
import SimpleLineIcons from 'react-native-vector-icons/SimpleLineIcons';
import {compose} from 'redux';
import {connect} from 'react-redux';
import firebase from 'firebase/app';
import 'firebase/database';

// import components
import I18n from '../../i18n';
import Header from '../../components/header';
import Label from '../../components/label';
import GamePicker from '../../components/picker';
import Filter from '../../components/filter';
import RequestCard from '../../components/cards/request-card';

// import styles
import {perfectSize} from '../../helpers/deviceHelper';
import styles from './styles';

import NavigationService from '../../navigation/navigationService';
import RequestCardSmall from '../../components/cards/request-card-small';
import {
  showAlert,
  showSpinner,
  hideSpinner,
} from '../../redux/modules/alert/actions';
import * as FirebasePaths from '../../constants/firebasePaths';
import ImageWithPlaceholder from '../../components/image-with-placeholder';
import AdSpotService from '../../services/adSpot.service';

// Requests Page
class SearchRequestsResultPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedGameTitle: 'Fortnite',
      selectedGameAvatar:
        'https://gamersmaze.com/wp-content/uploads/2019/07/Fortnite-feature-img.png',
      requests: [],
      displayList: [],
    };

    global.rankLogger.LOG_NEW_HP(
      global.rankLogger.SEARCH_REQUEST,
      'SEARCH_REQUEST',
    );
  }

  componentDidMount() {
    this.props.showSpinner({});
    setTimeout(() => {
      this.props.hideSpinner();
      if (this.state.requests.length == 0) {
        this.props.showAlert([
          I18n.t('SEARCH_REQUESTS_RESULT_PAGE.search_results'),
          I18n.t('SEARCH_REQUESTS_RESULT_PAGE.search_results_msg'),
          [
            {
              text: I18n.t('SEARCH_REQUESTS_RESULT_PAGE.create_request'),
              onPress: () => {
                NavigationService.navigate('NewRequestPage');
              },
            },
            {
              text: I18n.t('SEARCH_REQUESTS_RESULT_PAGE.go_back'),
              onPress: () => {
                NavigationService.navigate('SearchrequestPage');
              },
            },
          ],
        ]);
      }
    }, 8000);

    let path =
      FirebasePaths.FIREBASE_AD_SPOTS +
      '/' +
      FirebasePaths.FIREBASE_AD_SPOTS_SEARCH_REQUEST_RESULTS;

    firebase.database()
      .ref(path)
      .once('value', (snapshot) => {
        if (snapshot.val() != null) {
          this.setState({ad: snapshot.val()});
        }
      });
  }

  componentWillUpdate(prevProps) {
    if (this.props.valueChanged != prevProps.valueChanged) {
      this.props.hideSpinner();
      let list = Array.from(this.props.searchReqResList.values()).filter(
        (request) => {
          return (
            !this.props.blockedList.has(request.admin) &&
            !this.props.bannedUsers.includes(request.admin)
          );
        },
      );

      list.sort((request1, request2) => {
        return request2.timeStamp - request1.timeStamp;
      });

      this.setState(
        {
          requests: list,
          displayList: list.slice(0, 10),
        },
        () => {},
      );
    }
  }

  goToSearchRequestPage = () => {
    NavigationService.navigate('SearchrequestPage');
  };

  toLobby = (req) => {
    NavigationService.navigate('LobbyPage', {
      lastReq: req,
    });
  };

  doInfinite = (infiniteScroll) => {
    if (this.state.displayList.length == this.state.requests.length) {
      return;
    }

    setTimeout(() => {
      let limit = 10;
      if (this.state.requests.length - this.state.displayList.length < limit) {
        limit = this.state.requests.length - this.state.displayList.length;
      }
      let displayPost = this.state.displayList;
      displayPost = this.state.displayList.concat(
        this.state.requests.slice(
          displayPost.length,
          displayPost.length + limit,
        ),
      );
      this.setState({
        displayList: displayPost,
      });
    }, 500);
  };

  renderRequestsForYou = ({item, index}) => {
    return (
      <View
        style={[
          {width: '50%', marginBottom: perfectSize(16)},
          index % 2 === 0
            ? {paddingRight: perfectSize(8)}
            : {paddingLeft: perfectSize(8)},
        ]}>
        <RequestCardSmall
          request={item}
          onPress={() => {
            this.toLobby(item);
          }}
        />
      </View>
    );
  };
  openHopsAD = () => {
    global.featuresLogger.HOP_AD_CLICK();
    if (this.state.ad.url) {
      AdSpotService.checkAdUrl(this.state.ad);
    }
  };
  render() {
    const {theme} = this.props;
    return (
      <View
        style={[
          styles.container,
          {backgroundColor: theme.secondary_background},
        ]}>
        <Header text={I18n.t('SEARCH_REQUEST_PAGE.request_search')} />
        <View
          style={[styles.wrapper, {backgroundColor: theme.primary_background}]}>
          <FlatList
            data={this.state.displayList}
            renderItem={this.renderRequestsForYou}
            keyExtractor={(item) => item.timeStamp}
            numColumns={2}
            onEndReached={this.doInfinite}
            onEndReachedThreshold={0.5}
            ListHeaderComponent={
              this.state.ad != null && (
                <>
                  <TouchableOpacity
                    onPress={this.openHopsAD}
                    style={{marginBottom: perfectSize(16)}}>
                    <View style={styles.adCard}>
                      {this.state.ad.img != null && (
                        <ImageWithPlaceholder
                          style={styles.adCover}
                          uri={this.state.ad.img}
                        />
                      )}
                    </View>
                  </TouchableOpacity>
                </>
              )
            }
          />
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
    searchReqResList: state.hubReducer.searchReqResList,
    blockedList: state.hubReducer.blockedList,
    valueChanged: state.hubReducer.valueChanged,
    bannedUsers: state.hubReducer.bannedUsers,
  };
};

export default compose(
  connect(mapStateToProps, {showAlert, showSpinner, hideSpinner}),
)(SearchRequestsResultPage);
