import * as FirebasePaths from '../../constants/firebasePaths';
import {store} from '../../redux/store';
import firebase from 'firebase/app';
import 'firebase/database';

// import actions
import {set as setHub} from '../../redux/modules/hub/actions';

export default class NotificationManagerCore {
  constructor() {}

  static listener = null;

  static activateGameNotifications(game) {
    const uid = store.getState().authReducer.uid;
    const currentUserToken = store.getState().hubReducer.currentUserToken;
    if (uid !== null && uid !== '' && currentUserToken !== '') {
      let activiatedGamesNotificationArr = store.getState().hubReducer
        .activiatedGamesNotificationArr;
      if (!activiatedGamesNotificationArr.includes(game.gameID)) {
        activiatedGamesNotificationArr.push(game.gameID);
      }
      store.dispatch(setHub({activiatedGamesNotificationArr}));
      let deactiviatedGamesNotificationArr = store
        .getState()
        .hubReducer.deactiviatedGamesNotificationArr.filter(
          (item) => item !== game.gameID,
        );
      store.dispatch(setHub({deactiviatedGamesNotificationArr}));
      firebase.database()
        .ref(
          FirebasePaths.FIREBASE_TEAMS_NOTIFICATIONS +
            '/' +
            game.gameID +
            '/' +
            FirebasePaths.FIREBASE_TEAMS_NOTIFICATIONS_FOLLOWERS +
            '/' +
            uid,
        )
        .set(currentUserToken);
      firebase.database()
        .ref(
          FirebasePaths.FIREBASE_USERS_INFO_ATTR +
            '/' +
            uid +
            '/' +
            FirebasePaths.FIREBASE_USER_TEAM_NOTIFICATION +
            '/' +
            game.gameID,
        )
        .set(game.gameName);
      firebase.database()
        .ref(
          FirebasePaths.FIREBASE_USERS_INFO_ATTR +
            '/' +
            uid +
            FirebasePaths.FIREBASE_USER_TEAM_NOTIFICATION_DEACTIVATED_NOTIFICATIONS +
            '/' +
            game.gameID,
        )
        .remove();
    }
  }

  static deactivateGameNotifications(game) {
    const uid = store.getState().authReducer.uid;
    const currentUserToken = store.getState().hubReducer.currentUserToken;
    if (uid !== null && uid !== '' && currentUserToken !== '') {
      let activiatedGamesNotificationArr = store
        .getState()
        .hubReducer.activiatedGamesNotificationArr.filter(
          (item) => item !== game.gameID,
        );
      store.dispatch(setHub({activiatedGamesNotificationArr}));
      let deactiviatedGamesNotificationArr = store.getState().hubReducer
        .deactiviatedGamesNotificationArr;
      if (!deactiviatedGamesNotificationArr.includes(game.gameID)) {
        deactiviatedGamesNotificationArr.push(game.gameID);
      }
      store.dispatch(setHub({deactiviatedGamesNotificationArr}));
      firebase.database()
        .ref(
          FirebasePaths.FIREBASE_TEAMS_NOTIFICATIONS +
            '/' +
            game.gameID +
            '/' +
            FirebasePaths.FIREBASE_TEAMS_NOTIFICATIONS_FOLLOWERS +
            '/' +
            uid,
        )
        .remove();
      firebase.database()
        .ref(
          FirebasePaths.FIREBASE_USERS_INFO_ATTR +
            '/' +
            uid +
            '/' +
            FirebasePaths.FIREBASE_USER_TEAM_NOTIFICATION +
            '/' +
            game.gameID,
        )
        .remove();
      firebase.database()
        .ref(
          FirebasePaths.FIREBASE_USERS_INFO_ATTR +
            '/' +
            uid +
            FirebasePaths.FIREBASE_USER_TEAM_NOTIFICATION_DEACTIVATED_NOTIFICATIONS +
            '/' +
            game.gameID,
        )
        .set(game.gameName);
    }
  }
}
