/* eslint-disable react-native/no-inline-styles */
import React, { memo, Component } from 'react';
import { View, TouchableOpacity, ScrollView, SafeAreaView } from 'react-native';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from 'react-native-modal';
import { CheckBox } from 'react-native-elements';
import Feather from 'react-native-vector-icons/Feather';
import { SearchableFlatList } from 'react-native-searchable-list';

import Label from '../label';
import TextInput from '../text-input';
import { perfectSize } from '../../helpers/deviceHelper';
import { FONT_FAMILY } from '../../constants/theme';
import * as Images from '../../constants/images';

import styles from './styles';
import I18n from '../../i18n';

import LocalImageOpacity from '../local-image-opacity';

class CustomPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogOpen: false,
      tmpValue: -1,
      value: -1,
      searchTerm: '',
      searchAttribute: '',
      ignoreCase: true,
      searchValue: '',
    };
  }

  renderSelectable = ({ item }) => {
    const { theme, onPickerChanged } = this.props;
    const _styles = styles(theme);

    const fontFamily =
      this.props.lang === 'en' ? FONT_FAMILY.ubuntu : FONT_FAMILY.cairo;
    return (
      <CheckBox
        containerStyle={{
          backgroundColor: 'transparent',
          borderWidth: 0,
          alignSelf: 'flex-end',
        }}
        iconRight
        textStyle={{
          color: 'white',
          opacity: 0.5,
          fontSize: perfectSize(15),
          fontFamily: fontFamily,
        }}
        fontFamily={fontFamily}
        checkedIcon="dot-circle-o"
        uncheckedIcon="circle-o"
        checkedColor={this.props.theme.primary_btn_background}
        title={<Label style={_styles.searchText}>{item}</Label>}
        checked={this.state.tmpValue === item || this.props.value === item}
        onPress={() => {
          this.setState(
            {
              dialogOpen: false,
              value: item,
              tmpValue: -1,
              searchValue: item,
            },
            () => {
              if (onPickerChanged) {
                onPickerChanged(this.state.value);
              }
            },
          );
        }}
      />
    );
  };

  capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  capitalizeFirstLetter = (str) => {
    const splitted = str.split('_');
    if (splitted.length > 1) {
      return this.capitalize(splitted[0]) + ' ' + this.capitalize(splitted[1])
    } else {
      return this.capitalize(splitted[0]);
    }

  }

  render() {
    const {
      theme,
      pickerName,
      leftComponent,
      customStyles,
      values,
      onPickerChanged,
      translate,
      fieldName,
      onPickerPress,
      isSearchable,
    } = this.props;
    const _styles = styles(theme);
    let _value = '';
    let _values = [];
    if (values && values.length > 0) {
      let obj = values.find((value) => value.value === this.props.value);
      if (obj) {
        _value = obj.title;
      }
      if (fieldName) {
        values.forEach((val) => {
          _values.push(val[fieldName]);
        });
      }
    }
    const fontFamily =
      this.props.lang === 'en' ? FONT_FAMILY.ubuntu : FONT_FAMILY.cairo;
    const textAlign = this.props.lang === 'en' ? 'left' : 'right';

    return (
      <>
        {/* Control */}
        <TouchableOpacity
          style={[_styles.container, customStyles]}
          onPress={() => {
            if (onPickerPress) {
              onPickerPress();
            } else {
              this.setState({ dialogOpen: true, tempValue: this.state.value });
            }
          }}>
          <View style={[_styles.flexRow, _styles.alignCenter]}>
            {leftComponent && leftComponent}
            <Label
              size="medium"
              style={[_styles.input, { textAlign: textAlign }]}
              numberOfLines={1}>
              {pickerName}
            </Label>
            {isSearchable && (
              <Label style={_styles.label}>{this.props.value}</Label>
            )}
            {!isSearchable &&
              this.props.value !== '' &&
              this.props.value !== undefined && (
                <Label style={_styles.label}>
                  {translate ? I18n.t(_value) : _value}
                </Label>
              )}
            <TouchableOpacity>
              <Feather
                name="chevron-down"
                color="gray"
                size={perfectSize(20)}
              />
            </TouchableOpacity>
          </View>
        </TouchableOpacity>

        {/* Modal */}
        {(values || (fieldName && _values)) && (
          <Modal
            isVisible={this.state.dialogOpen}
            onBackdropPress={() => {
              this.setState({ dialogOpen: false, tmpValue: -1 });
            }}
            // style={
            //   this.props.isSearchable && {
            //     margin: 0,
            //   }
            // }
            coverScreen={true}
            animationIn={'pulse'}
            style={isSearchable ? { margin: 'auto', width: '100%', maxWidth: 812 } : { margin: 'auto' }}
            animationOut={'fadeOut'}>
            <View style={[{ backgroundColor: theme.primary_background, minWidth: 350 }, isSearchable && { flex: 1 }]}>
              <View
                style={[
                  isSearchable
                    ? _styles.searchableDialogContainer
                    : _styles.dialogContainer,
                  {
                    borderBottomColor: this.props.theme.primary_btn_background,
                    borderBottomWidth: 3,
                    padding: this.props.isSearchable ? 0 : 16,
                    justifyContent: 'center',
                  }
                ]}>
                {/* Modal header */}
                {isSearchable ? (
                  <View style={_styles.searchableDialogSectionContainer}>
                    <TouchableOpacity
                      style={[
                        _styles.flexFull,
                        { alignItems: 'flex-start', marginRight: perfectSize(8) },
                      ]}
                      onPress={() =>
                        this.setState({ dialogOpen: false, tmpValue: -1 })
                      }>
                      <Feather
                        name="chevron-left"
                        color="gray"
                        size={perfectSize(22.5)}
                      />
                    </TouchableOpacity>

                    <View style={[{ flex: 5 }]}>
                      <Label style={_styles.dialogLabelSearchable}>
                        {pickerName}
                      </Label>
                    </View>
                    <View style={_styles.flexFull} />
                  </View>
                ) : (
                  <View style={_styles.dialogSectionContainer}>
                    <Label style={_styles.dialogLabel}>{pickerName}</Label>
                  </View>
                )}

                <View
                  style={[
                    isSearchable
                      ? {
                        flex: 1,
                        backgroundColor: theme.secondary_btn_background,
                      }
                      : _styles.optionContainer,
                  ]}>
                  {/* Search input box */}
                  {isSearchable ? (
                    <View
                      style={[
                        _styles.flexFull,
                        {
                          borderTopLeftRadius: perfectSize(24),
                          borderTopRightRadius: perfectSize(24),
                          backgroundColor: theme.primary_background,
                        },
                      ]}>
                      <View style={_styles.searchContainer}>
                        <View style={_styles.search}>
                          <TextInput
                            placeholder={this.props.searchPlaceholder}
                            placeholderTextColor={'#7D8693'}
                            customStyles={{
                              // flex: 1,
                              width: '90%',
                              color: '#7D8693',
                              fontSize: perfectSize(15),
                              // backgroundColor: 'red',
                            }}
                            onChangeText={(searchTerm) =>
                              this.setState({ searchTerm })
                            }
                          />
                          <LocalImageOpacity
                            source={
                              fieldName === 'gameName'
                                ? Images.JOYSTICK
                                : Images.MAP_PIN
                            }
                            style={{
                              width: perfectSize(20),
                              height: perfectSize(20),
                              opacity: 0.6,
                              marginRight: perfectSize(16),
                            }}
                            resizeMode="contain"
                          />
                        </View>
                      </View>
                      <SearchableFlatList
                        data={fieldName ? _values : values}
                        ListEmptyComponent={() => {
                          return (
                            <View style={_styles.noItemWrap}>
                              <Label style={_styles.noItem}>
                                No Items found.
                              </Label>
                            </View>
                          );
                        }}
                        searchTerm={this.state.searchTerm || ''}
                        renderItem={this.renderSelectable}
                        keyExtractor={(item) => item}
                        showsVerticalScrollIndicator={false}
                      />
                    </View>
                  ) : (
                    <ScrollView showsVerticalScrollIndicator={false}>
                      {values.map((value, index) => (
                        <CheckBox
                          containerStyle={{
                            backgroundColor: 'transparent',
                            borderWidth: 0,
                          }}
                          textStyle={{
                            color: 'white',
                            opacity: 0.5,
                            fontSize: perfectSize(15),
                          }}
                          checkedIcon="dot-circle-o"
                          uncheckedIcon="circle-o"
                          checkedColor={this.props.theme.primary_btn_background}
                          title={
                            <Label style={_styles.searchText}>
                              {translate ? I18n.t(value.title) : this.capitalizeFirstLetter(value.value.toLowerCase())}
                            </Label>
                          }
                          checked={
                            this.state.tmpValue > -1
                              ? parseInt(this.state.tmpValue, 10) === index
                              : _value === value.title
                          }
                          onPress={() => {
                            this.setState({ tmpValue: index });
                          }}
                          fontFamily={fontFamily}
                        />
                      ))}
                    </ScrollView>
                  )}
                </View>
                {!this.props.isSearchable && (
                  <View style={_styles.buttonContainer}>
                    <TouchableOpacity
                      style={_styles.button}
                      onPress={() =>
                        this.setState({ dialogOpen: false, tmpValue: -1 })
                      }>
                      <Label style={_styles.buttonColor}>CANCEL</Label>
                    </TouchableOpacity>
                    <TouchableOpacity
                      style={_styles.button}
                      onPress={() => {
                        if (this.state.tmpValue > -1) {
                          this.setState(
                            {
                              dialogOpen: false,
                              value: this.state.tmpValue,
                              tmpValue: -1,
                            },
                            () => {
                              if (onPickerChanged) {
                                onPickerChanged(values[this.state.value].value);
                              }
                            },
                          );
                        } else {
                          this.setState({ dialogOpen: false });
                        }
                      }}>
                      <Label style={_styles.buttonColor}>OK</Label>
                    </TouchableOpacity>
                  </View>
                )}
              </View>
            </View>
          </Modal>
        )}
      </>
    );
  }
}

CustomPicker.defaultProps = {
  isSearchable: false,
};

CustomPicker.propTypes = {
  pickerName: PropTypes.string,
  customStyles: PropTypes.any,
  leftComponent: PropTypes.any,
  isSearchable: PropTypes.bool,
  onPickerPress: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
    lang: state.appReducer.currentLang,
  };
};

export default memo(connect(mapStateToProps)(CustomPicker));
