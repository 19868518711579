import React, {memo, Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import MineMessageHolder from './mine';
import OpponentMessageHolder from './opponent';
import GroupMessageHolder from './group';

class ChatMessageHolder extends Component {
  render() {
    const {type, item, onDelete} = this.props;
    if (type === 0) {
      return <MineMessageHolder item={item} onDelete={onDelete} />;
    } else if (type === 1) {
      return <OpponentMessageHolder item={item} />;
    } else if (type === 2) {
      return <GroupMessageHolder item={item} />;
    } else {
      return null;
    }
  }
}

ChatMessageHolder.propTypes = {
  item: PropTypes.any,
  onDelete: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
  };
};

export default memo(connect(mapStateToProps)(ChatMessageHolder));
