/* eslint-disable react-native/no-inline-styles */
import React, {memo, Component} from 'react';
import {View, StyleSheet} from 'react-native';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import I18n from '../../i18n';
import Feather from 'react-native-vector-icons/Feather';

import ChatAvatar from '../chat-avatar';
import Label from '../label';
import {perfectSize} from '../../helpers/deviceHelper';

class ChatItemHolder extends Component {
  render() {
    const {
      theme,
      avatar,
      borderColor,
      showStatus,
      uid,
      title,
      timestamp,
      message,
      deleted,
      showUnreadBadge,
      type,
    } = this.props;
    const _styles = styles(theme);
    return (
      <View style={_styles.container}>
        <View style={_styles.body}>
          <ChatAvatar
            src={avatar}
            borderColor={borderColor}
            showStatus={showStatus}
            uid={uid}
            unread={showUnreadBadge}
          />
          <View style={_styles.wrapper}>
            <Label size="x_small">{title}</Label>
            <View style={_styles.designation}>
              {!deleted ? (
                <Label
                  size="small"
                  style={_styles.message}
                  numberOfLines={1}>
                  {message}
                </Label>
              ) : (
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    flex: 1,
                  }}>
                  <Feather
                    name="slash"
                    color="#ffffff80"
                    size={perfectSize(12)}
                  />
                  <Label
                    size="small"
                    style={{
                      textAlign: 'right',
                      marginLeft: perfectSize(2),
                      fontStyle: 'italic',
                      color: '#ffffff80',
                    }}
                    selectable={false}>
                    {type === 0
                      ? I18n.t('CHAT_PAGE.deleted_message')
                      : I18n.t('CHAT_PAGE.deleted_message_other')}
                  </Label>
                </View>
              )}
              <Label size="small" style={_styles.timeline}>
                {timestamp}
              </Label>
            </View>
          </View>
        </View>
        <View style={_styles.divider} />
      </View>
    );
  }
}

const styles = (theme) =>
  StyleSheet.create({
    container: {
      flexDirection: 'column',
    },
    body: {
      paddingVertical: 9,
      flexDirection: 'row',
    },
    wrapper: {
      marginLeft: 8,
      flex: 1,
      justifyContent: 'center',
    },
    designation: {
      flexDirection: 'row',
      marginTop: 4,
    },
    message: {
      opacity: 0.56,
      flex: 1,
    },
    timeline: {
      width: 80,
      color: '#95979A',
      fontWeight: '300',
      textAlign: 'right',
    },
    divider: {
      height: 1,
      backgroundColor: '#FFFFFF',
      opacity: 0.05,
    },
  });

ChatItemHolder.defaultProps = {
  showStatus: false,
};

ChatItemHolder.propTypes = {
  avatar: PropTypes.string,
  borderColor: PropTypes.string,
  showStatus: PropTypes.bool,
  uid: PropTypes.string,
  title: PropTypes.string,
  timestamp: PropTypes.string,
  message: PropTypes.string,
  deleted: PropTypes.bool,
  showUnreadBadge: PropTypes.bool,
  index: PropTypes.number,
};

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
  };
};

export default memo(connect(mapStateToProps)(ChatItemHolder));
