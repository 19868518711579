import React, { memo, Component } from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import NavigationService from '../../navigation/navigationService';
import Feather from 'react-native-vector-icons/Feather';

import UserAvatar from '../user-avatar';
import Label from '../label';

import Dimens from '../../constants/dimens';
import AppStyles from '../../constants/styles';
import { perfectSize } from '../../helpers/deviceHelper';

class HeaderWithNotification extends Component {
  constructor(props) {
    super(props);
    this.mount = true;
    this.state = {
      uid: props.uid,
      user: props.user,
    }
  }

  componentDidMount = () => {

  }

  componentDidUpdate(prevProps) {
    if (prevProps.user.pictureURL !== this.props.user.pictureURL || prevProps.user.profileBorderColor !== this.props.user.profileBorderColor) {
      if (this.mount) {
        this.setState({
          user: this.props.user
        });
      }
    }
  }

  componentWillUnmount() {
    this.mount = false
  }

  toNotification = () => {
    NavigationService.moveToScreenInStack('HomeStack', 'NotificationsPage');
  };

  toMyProfile = () => {
    NavigationService.toggleDrawer();
  };

  render() {
    const { theme, } = this.props;
    const _styles = styles(theme);
    const { uid, user } = this.state;
    return (
      <View style={_styles.container}>
        <View style={_styles.left}>
          <TouchableOpacity onPress={this.toMyProfile}>
            <UserAvatar
              src={user.pictureURL}
              borderColor={user.profileBorderColor}
              uid={uid}
            />
          </TouchableOpacity>
          <Label size="medium" bold={true} style={_styles.title}>
            {this.props.title}
          </Label>
        </View>
        <View style={_styles.right}>
          <TouchableOpacity
            style={AppStyles.rightBarButton}
            onPress={this.toNotification}>
            <Feather name="bell" color="gray" size={perfectSize(19)} />
          </TouchableOpacity>
          {this.props.HAS_NEW_NOTIFICATION && <View style={_styles.badge} />}
        </View>
      </View>
    );
  }
}

const styles = (theme) =>
  StyleSheet.create({
    container: {
      height: Dimens.HEADER_HEIGHT,
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingLeft: perfectSize(20),
      paddingRight: perfectSize(8),
    },
    left: {
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center',
    },
    title: {
      marginLeft: perfectSize(8),
    },
    right: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    badge: {
      position: 'absolute',
      top: perfectSize(16),
      right: perfectSize(2),
      width: perfectSize(8),
      height: perfectSize(8),
      borderRadius: perfectSize(4),
      backgroundColor: '#C70064',
    },
    iconBell: {
      width: perfectSize(16),
      height: perfectSize(16),
      resizeMode: 'contain',
    },
  });

HeaderWithNotification.propTypes = {
  title: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
    user: state.authReducer.userinformationDB,
    HAS_NEW_NOTIFICATION: state.hubReducer.HAS_NEW_NOTIFICATION,
    uid: state.authReducer.uid,
  };
};

export default memo(connect(mapStateToProps)(HeaderWithNotification));
