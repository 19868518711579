import React, {memo, Component} from 'react';
import {View, StyleSheet, TouchableOpacity, SafeAreaView} from 'react-native';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Feather from 'react-native-vector-icons/Feather';
import firebase from 'firebase/app';
import 'firebase/database';

import {perfectSize} from '../../helpers/deviceHelper';

import UserAvatar from '../user-avatar';
import Label from '../label';
import {getHumantime} from '../../services/utils';
import Modal from 'react-native-modal';
import I18n from '../../i18n';
import NavigationService from '../../navigation/navigationService';

import {presentToast} from '../../helpers/toastHelper';
import * as FirebasePaths from '../../constants/firebasePaths';
import {set as HubSet} from '../../redux/modules/hub/actions';
import {showAlert} from '../../redux/modules/alert/actions';
import Entypo from 'react-native-vector-icons/Entypo';

class HopReplyDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  showBlockAlert = () => {
    this.props.showAlert([
      I18n.t('SEARCH_REQUESTS_RESULT_PAGE.block_title'),
      I18n.t('TIMELINE_PAGE.block_alert_message'),
      [
        {
          text: I18n.t('TIMELINE_PAGE.block_alert_yes'),
          onPress: () => {
            this.addToBlockList(this.props.item.uid, this.props.item.username);
            let _blockedList = this.props.blockedList;
            if (_blockedList === undefined) {
              _blockedList = new Map();
            }
            _blockedList.set(this.props.item.uid, this.props.item.username);
            this.props.HubSet({
              blockedList: _blockedList,
            });
            presentToast({
              message: I18n.t('TIMELINE_PAGE.block_alert_success_msg'),
            });
          },
        },
        {
          text: I18n.t('TIMELINE_PAGE.block_alert_no'),
          onPress: () => {},
        },
      ],
      false,
    ]);
  };

  addToBlockList = (uid, username) => {
    const blockListPath =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      this.props.uid +
      '/' +
      FirebasePaths.FIREBASE_BLOCKED_USERS +
      '/';
    firebase.database()
      .ref(blockListPath + '/' + uid)
      .set(username);
  };

  openDialog = () => {
    const item = this.props.item;

    if (this.props.item.uid !== this.props.uid) {
      this.props.showAlert([
        I18n.t('TIMELINE_PAGE.hop_dialog_settings_title'),
        '',
        [
          {
            text: I18n.t('TIMELINE_PAGE.view_hop'),
            onPress: () => {
              NavigationService.moveToScreenInStack('HomeStack', 'PostPage', {
                POST_ID: item.postId,
              });
            },
          },
          {
            text: I18n.t('TIMELINE_PAGE.delete_reply_title'),
            onPress: () => {
              this.props.onDelete && this.props.onDelete();
            },
          },
          {
            text: I18n.t('TIMELINE_PAGE.hop_dialog_settings_block_user'),
            onPress: () => {
              this.showBlockAlert();
            },
          },
        ],
        false,
      ]);
    } else {
      this.props.showAlert([
        I18n.t('TIMELINE_PAGE.hop_dialog_settings_title'),
        '',
        [
          {
            text: I18n.t('TIMELINE_PAGE.view_hop'),
            onPress: () => {
              NavigationService.moveToScreenInStack('HomeStack', 'PostPage', {
                POST_ID: item.postId,
              });
            },
          },
          {
            text: I18n.t('TIMELINE_PAGE.delete_reply_title'),
            onPress: () => {
              this.props.onDelete && this.props.onDelete();
            },
          },
        ],
        false,
      ]);
    }
  };

  render() {
    const {theme} = this.props;
    const _styles = styles(theme);
    const item = this.props.item;

    return (
      <View style={_styles.container}>
        <View style={[_styles.wrapper, this.props.style]}>
          <View style={_styles.userInfo}>
            <UserAvatar
              src={item.img}
              showStatus={false}
              borderColor={
                item.userBorderColor ? item.userBorderColor : '#880e4a'
              }
            />
            <View style={_styles.nametimestamp}>
              <View style={_styles.flexRow}>
                <Label size="x_small">{item.username}</Label>
              </View>
              <Label size="small" style={_styles.timestamp}>
                {getHumantime(item.timeStamp)}
              </Label>
            </View>

            <TouchableOpacity
              style={_styles.actionMenu}
              onPress={() => {
                this.props.onMenu ? this.props.onMenu() : this.openDialog();
              }}>
              <View style={_styles.downContainer}>
                <Feather
                  name="chevron-down"
                  color="gray"
                  size={perfectSize(20)}
                />
              </View>
            </TouchableOpacity>
          </View>
          {item.delete === true ? (
            <View
              style={{
                flexDirection: 'row',
              }}>
              <Entypo
                name="block"
                color="gray"
                size={perfectSize(16)}
                style={{
                  marginTop: perfectSize(7),
                  marginRight: perfectSize(4),
                }}
              />
              <Label size="x_small" style={_styles.deleted}>
                {I18n.t('POST_PAGE.message_deleted')}
              </Label>
            </View>
          ) : (
            <>
              {item.description.includes(
                'https://firebasestorage.googleapis.com',
              ) && (
                <Label size="x_small" style={_styles.message} numberOfLines={1}>
                  sent photo
                </Label>
              )}
              {!item.description.includes(
                'https://firebasestorage.googleapis.com',
              ) && (
                <Label size="x_small" style={_styles.message} numberOfLines={1}>
                  {item.description}
                </Label>
              )}
            </>
          )}
        </View>
      </View>
    );
  }
}

HopReplyDetail.propTypes = {
  data: PropTypes.object,
  isJoined: PropTypes.bool,
  showLike: PropTypes.bool,
  onJoin: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
    uid: state.authReducer.uid,
  };
};

export default memo(
  connect(mapStateToProps, {HubSet, showAlert})(HopReplyDetail),
);

const styles = (theme) =>
  StyleSheet.create({
    container: {},
    wrapper: {
      marginHorizontal: perfectSize(24),
    },
    userInfo: {
      flexDirection: 'row',
      alignItems: 'center',
      elevation: 10,
    },
    nametimestamp: {
      marginLeft: perfectSize(4),
    },
    timestamp: {
      opacity: 0.56,
      marginTop: perfectSize(2),
    },
    message: {
      opacity: 0.64,
      marginTop: perfectSize(8),
    },
    deleted: {
      opacity: 0.5,
      marginTop: perfectSize(8),
      fontWeight: '400',
      fontStyle: 'italic',
    },
    actionMenu: {
      position: 'absolute',
      top: 0,
      right: 0,
    },
    arrowDown: {
      width: perfectSize(12.05),
      height: perfectSize(6),
      resizeMode: 'contain',
    },
    playStation: {
      width: perfectSize(14.64),
      height: perfectSize(12),
      resizeMode: 'contain',
    },
    ecllipse: {
      width: perfectSize(24),
      height: perfectSize(24),
      borderColor: '#A2A5A9',
      borderWidth: 1,
      borderRadius: perfectSize(12),
      alignItems: 'center',
      justifyContent: 'center',
      top: -perfectSize(5),
    },
    flexRow: {
      flexDirection: 'row',
    },
    likeWrapper: {
      justifyContent: 'flex-end',
      marginTop: perfectSize(16),
    },
    thumb: {
      marginLeft: perfectSize(4),
    },
    upvoteText: {
      color: theme.primary_btn_background,
    },
    downvoteText: {
      opacity: 0.56,
    },
    rankBadge: {
      width: perfectSize(18),
      height: perfectSize(18),
      marginLeft: perfectSize(8),
    },

    dialogContainer: {
      backgroundColor: '#181f29',
      width: '80%',
      padding: perfectSize(32),
      margin: 0,
      alignSelf: 'center',
    },
    dialogSectionContainer: {
      padding: perfectSize(16),
      alignItems: 'flex-end',
    },
    dialogLabel: {
      color: 'white',
      fontSize: perfectSize(16),
      textAlign: 'center',
    },
    buttonContainer: {
      // width: '100%', // 100% of parent width
      marginTop: perfectSize(32),
      justifyContent: 'center',
      alignItems: 'center',
    },
    dialogDescription: {
      color: '#00000080',
      fontSize: 14,
      width: '100%',
    },
    button: {
      marginHorizontal: perfectSize(4),
      borderWidth: 1,
      borderColor: '#a70559',
      borderRadius: perfectSize(10),
      height: perfectSize(42),
      justifyContent: 'center',
      paddingHorizontal: perfectSize(12),
      marginVertical: perfectSize(4),
    },
    smallButton: {
      width: perfectSize(100),
      alignItems: 'center',
      paddingRight: perfectSize(16),
      marginVertical: perfectSize(8),
    },

    buttonColor: {
      color: theme.primary_btn_background,
      textAlign: 'right',
      width: '100%',
    },
  });
