export const STAR = require('../../assets/images/filled-star.png');
export const STAR_BLANK = require('../../assets/images/star.png');
export const STAR_HALF = require('../../assets/images/star_half.png');
export const SPORTS_CUP = require('../../assets/images/sports-and-competition.png');
export const THUMBS_UP = require('../../assets/images/thumbs-up.png');
export const THUMBS_DOWN = require('../../assets/images/thumbs-down.png');
export const CROWN = require('../../assets/images/crown.png');
export const MESSAGE = require('../../assets/images/message-circle.png');
export const LOGO_WHITE = require('../../assets/images/logo_white.png');
export const LOGO_PINK = require('../../assets/images/logo_dark.png');
export const LOGO_RESET_PASSWORD = require('../../assets/images/reset-password.png');
export const PLACEHOLDER = require('../../assets/images/placeholder.png');
export const PLACEHOLDER_AVATAR = require('../../assets/images/avatar.png');
export const CLAN_OVERLAY = require('../../assets/images/clan-overlay.png');
export const CHANGE_PWD_LOGO = require('../../assets/images/change_pwd_logo.png');
export const NOTIFICATION_ON = require('../../assets/images/notifications-on.png');
export const NOTIFICATION_OFF = require('../../assets/images/notifications-off.png');
export const SEND = require('../../assets/images/send.png');
export const WHATSAPP = require('../../assets/images/whatsapp.png');
export const EMAIL = require('../../assets/images/email.png');
export const UPLOAD = require('../../assets/images/upload.png');
export const XBOX = require('../../assets/images/xbox.png');
export const MAP_PIN = require('../../assets/images/map-pin.png');
export const INFO = require('../../assets/images/info.png');
export const GENDER = require('../../assets/images/gender.png');
export const AGE = require('../../assets/images/age.png');
export const JOYSTICK = require('../../assets/images/joystick.png');
export const TIME = require('../../assets/images/time.png');
export const PHONE = require('../../assets/images/phone.png');
export const PLAYSTATION_GRAY = require('../../assets/images/playstation_gray.png');
export const XBOX_GRAY = require('../../assets/images/xbox_gray.png');
export const NINRENDO = require('../../assets/images/ninrendo.png');
export const MOBILE = require('../../assets/images/mobile.png');
export const PC = require('../../assets/images/pc.png');
export const BATTLENET = require('../../assets/images/battlenet.png');
export const CIRCLECLOSE = require('../../assets/images/circleclose.png');
export const ICON_LEADERBOARD_PINK = require('../../assets/images/icon-leaderboard-pink.png');
export const WORLD_CUP = require('../../assets/images/sports-and-competition.png');
export const TARGET = require('../../assets/images/target.png');
export const AWARD = require('../../assets/images/award.png');
export const CALENDAR = require('../../assets/images/calendar.png');
export const GROUP = require('../../assets/images/group.png');
export const JOYSTICK2 = require('../../assets/images/joystick2.png');
export const APP_NAME = require('../../assets/images/app-name.png');
export const PSICON = require('../../assets/images/PSIcon.png');
export const PSICONG = require('../../assets/images/PSIconG.png');
export const PCICON = require('../../assets/images/PCIcon.png');
export const PCICONG = require('../../assets/images/PCIconG.png');
export const XBOXICON = require('../../assets/images/XboxIcon.png');
export const XBOXICONG = require('../../assets/images/XboxIconG.png');
export const SWITCHICON = require('../../assets/images/SwitchIcon.png');
export const SWITCHICONG = require('../../assets/images/SwitchIconG.png');
export const MOBILEICON = require('../../assets/images/MobileIcon.png');
export const MOBILEICONG = require('../../assets/images/MobileIconG.png');
export const BTN_TAG_BALL = require('../../assets/images/btn-tag-ball.png');
export const BTN_TAG_DESKTOP = require('../../assets/images/btn-tag-desktop.png');
export const BTN_TAG_DOUBLE = require('../../assets/images/btn-tag-double.png');
export const BTN_TAG_MOBILE = require('../../assets/images/btn-tag-mobile.png');
export const BTN_TAG_PLAYSTATION = require('../../assets/images/btn-tag-playstation.png');
export const BTN_TAG_BALL_SELECTED = require('../../assets/images/btn-tag-ball_selected.png');
export const BTN_TAG_DESKTOP_SELECTED = require('../../assets/images/btn-tag-desktop_selected.png');
export const BTN_TAG_DOUBLE_SELECTED = require('../../assets/images/btn-tag-double_selected.png');
export const BTN_TAG_MOBILE_SELECTED = require('../../assets/images/btn-tag-mobile_selected.png');
export const BTN_TAG_PLAYSTATION_SELECTED = require('../../assets/images/btn-tag-playstation_selected.png');
export const NO_INTERNET = require('../../assets/images/no-internet.png');
export const ART = require('../../assets/images/art.png');
export const ACCOUNT_GROUP = require('../../assets/images/account-group.png');

// Bottom tab
export const BOTTOM_TAB_HOME = require('../../assets/images/bottom-tab/Home.png');
export const BOTTOM_TAB_HOPS = require('../../assets/images/bottom-tab/Hops.png');
export const BOTTOM_TAB_CHATS = require('../../assets/images/bottom-tab/Chats.png');
export const BOTTOM_TAB_PROFILE = require('../../assets/images/bottom-tab/Profile.png');
export const BOTTOM_TAB_HOOTS = require('../../assets/images/bottom-tab/Hoots.png');

// Flags
export const FLAG_BAHRAIN = require('../../assets/images/flags/bahrain.png');
export const FLAG_EGYPT = require('../../assets/images/flags/egypt.png');
export const FLAG_IRAQ = require('../../assets/images/flags/iraq.png');
export const FLAG_JORDAN = require('../../assets/images/flags/jordan.png');
export const FLAG_KUWAIT = require('../../assets/images/flags/kuwait.png');
export const FLAG_LEBANON = require('../../assets/images/flags/lebanon.png');
export const FLAG_LIBYA = require('../../assets/images/flags/libya.png');
export const FLAG_OMAN = require('../../assets/images/flags/oman.png');
export const FLAG_QATAR = require('../../assets/images/flags/qatar.png');
export const FLAG_SAUDI_ARABIA = require('../../assets/images/flags/saudi_arabia.png');
export const FLAG_SUDAN = require('../../assets/images/flags/sudan.png');
export const FLAG_SYRIA = require('../../assets/images/flags/syria.png');
export const FLAG_TURKEY = require('../../assets/images/flags/turkey.png');
export const FLAG_UAE = require('../../assets/images/flags/uae.png');
export const FLAG_YEMEN = require('../../assets/images/flags/yemen.png');
export const FLAG_EARTH = require('../../assets/images/flags/earth.png');

// Gifs
export const SAD_FACE = require('../../assets/gifs/sad-face.gif');
export const STAR_DARK_GIF = require('../../assets/gifs/star_dark.gif');
export const STAR_GIF = require('../../assets/gifs/star-gif.gif');
export const CLANS_LOADING_DARK_GIF = require('../../assets/gifs/clans_loading_dark.gif');
export const CLANS_LOADING_GIF = require('../../assets/gifs/clans_loading.gif');
export const NOTIFICATION_LOADING_DARK_GIF = require('../../assets/gifs/notification_loading_dark.gif');
export const NOTIFICATION_LOADING_GIF = require('../../assets/gifs/notification_loading.gif');
export const LOADING_HOPS_DARK = require('../../assets/gifs/loading-hops-dark.gif');
export const LOADING_HOPS = require('../../assets/gifs/loading-hops.gif');
export const SUPPORT_LOADING_DARK = require('../../assets/gifs/support_loading_dark.gif');
export const SUPPORT_LOADING = require('../../assets/gifs/support_loading.gif');
export const FRIENDS_LOADING_DARK = require('../../assets/gifs/friends_loading_dark.gif');
export const FRIENDS_LOADING = require('../../assets/gifs/friends_loading.gif');
export const NO_ACTIVITY_DARK = require('../../assets/gifs/face_loading_dark.gif');
export const NO_ACTIVITY_LIGHT = require('../../assets/gifs/lock_loading.gif');
export const CHAT_LOADING_DARK = require('../../assets/gifs/chat_loading_dark.gif');
export const CHAT_LOADING = require('../../assets/gifs/chat_loading.gif');
export const CONTROLLER_LOADING_DARK = require('../../assets/gifs/controller-loading-dark.gif');
export const CONTROLLER_LOADING = require('../../assets/gifs/controller-loading.gif');
export const LOGO_UPDATE = require('../../assets/images/logo_update.png');

export const HOOTS_COVER = require('../../assets/images/hoot_cover.png');
export const GENRE_STREAM = require('../../assets/images/genre_stream.png');
export const HOOTS_MUTE = require('../../assets/images/mute.png');
export const HOOTS_DEAFEN = require('../../assets/images/deafen_on.png');
export const HOOTS_DEAFEN_OFF = require('../../assets/images/deafen_off.png');
export const HOOTS_MIC_OFF = require('../../assets/images/mic_off.png');
export const HOOTS_CALL_END = require('../../assets/images/call_end.png');
export const HOOTS_SPEAKING_OVERLAY = require('../../assets/images/hoot_speaking_overlay.png');

// Tags
export const _img_ps =
  'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/Games%20Accounts%20Icons%2FPS-WHITE.png?alt=media&token=3d4bc220-7fca-4f4d-bdf2-a2e79c9b4a7f';
export const _img_xbox =
  'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/Games%20Accounts%20Icons%2FXBOX-WHITE.png?alt=media&token=e4fad577-2e0c-4839-8bf1-e63c005f324c';
export const _img_epic =
  'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/Games%20Accounts%20Icons%2FEPIC-WHITE.png?alt=media&token=e68390d9-0577-4160-99c5-285464dc4d74';
export const _img_steam =
  'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/Games%20Accounts%20Icons%2FSTEAM-WHITE.png?alt=media&token=d8933d08-35f7-4373-921f-59a270f4983a';
export const _img_origin =
  'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/Games%20Accounts%20Icons%2FORIGIN-WHITE.png?alt=media&token=7eb17488-835d-4ef7-96c0-34732fd46333';
export const _img_battlenet =
  'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/Games%20Accounts%20Icons%2FBATTLENET-WHITE.png?alt=media&token=08d6a727-78bc-45b3-acf1-5ed4d3077620';
export const _img_nintendo =
  'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/Games%20Accounts%20Icons%2FNINTENDO-WHITE.png?alt=media&token=62f32425-a42a-4dcc-b936-bd78da4afbd9';
export const _img_discord =
  'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/Games%20Accounts%20Icons%2FDISCORD-WHITE.png?alt=media&token=691c107c-cf6e-40a5-893e-d5d091b56ccd';

// Rank
export const _IMG_NOOB =
  'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/ranks%2FNoob.png?alt=media&token=4027ea73-0663-4368-ab2c-a9dd35ad7a49';
export const _IMG_AFK =
  'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/ranks%2FAFK.png?alt=media&token=7ec253f6-4217-417d-8cc1-dda9be248b82';
export const _IMG_BOT =
  'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/ranks%2FBot.png?alt=media&token=d002b58a-36fd-4827-a368-7f1aa08c6335';
export const _IMG_DUCK =
  'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/ranks%2FDuck.png?alt=media&token=9b0baf1b-6d59-497b-9e90-63213eb4113e';
export const _IMG_DUCK_LEADER =
  'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/ranks%2FDuck%20Leader.png?alt=media&token=22ce939a-5f5d-4576-a195-b987028b60b3';
export const _IMG_BRONZE =
  'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/ranks%2FBronze%20I.png?alt=media&token=59ec90b5-9448-4601-ab0a-8a63026c0d60';
export const _IMG_BRONZE_II =
  'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/ranks%2FBronze%20II.png?alt=media&token=c90e564d-f263-4a55-bdd9-ae40e2d505cb';
export const _IMG_BRONZE_III =
  'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/ranks%2FBronze%20III.png?alt=media&token=01f4e9bc-2b8b-4df2-a5ba-bea1465b5a5b';
export const _IMG_SILVER =
  'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/ranks%2FSilver%20I.png?alt=media&token=0b21290b-40a5-422f-9b61-6246f96a4d19';
export const _IMG_SILVER_II =
  'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/ranks%2FSilver%20II.png?alt=media&token=50b9d1b6-da5c-4c7e-a99a-0b02e57c4e49';
export const _IMG_SILVER_III =
  'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/ranks%2FSilver%20III.png?alt=media&token=3bb47619-c33b-4fd2-b75f-54cb1e3f5d64';
export const _IMG_GOLD =
  'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/ranks%2FGold%20I.png?alt=media&token=682266b1-9b7f-4af2-a457-c03cceea262a';
export const _IMG_GOLD_II =
  'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/ranks%2FGold%20II.png?alt=media&token=9d83856a-425c-4989-914d-a5b242097a98';
export const _IMG_GOLD_III =
  'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/ranks%2FGold%20III.png?alt=media&token=b838aac2-2efc-4d3e-ac40-8c516f06752c';
export const _IMG_PLATINUM =
  'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/ranks%2FPlatinum%20I.png?alt=media&token=ed44837f-7c77-4d50-b107-91769fce9ead';
export const _IMG_PLATINUM_II =
  'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/ranks%2FPlatinum%20II.png?alt=media&token=57d121f7-009e-41ba-8928-210ad60e7680';
export const _IMG_PLATINUM_III =
  'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/ranks%2FPlatinum%20III.png?alt=media&token=65375e3f-8d24-4c23-9188-4381b2ce35a9';
export const _IMG_DIAMOND =
  'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/ranks%2FDiamond%20I.png?alt=media&token=1bff74bb-4279-4544-a55b-64d4536c4f82';
export const _IMG_DIAMOND_II =
  'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/ranks%2FDiamond%20II.png?alt=media&token=d3c75e8c-f79d-4781-92e5-f75db6cac7bb';
export const _IMG_DIAMOND_III =
  'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/ranks%2FDiamond%20III.png?alt=media&token=be84d09c-0272-4eed-94e5-d9a3edf88f49';
export const _IMG_MASTER =
  'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/ranks%2FMaster.png?alt=media&token=e93a5560-eba3-4b28-85a3-ada4810b8093';
export const _IMG_GRAND_MASTER =
  'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/ranks%2FGrand%20Master.png?alt=media&token=5941fa84-eb5a-45ca-8d84-95091a11b90e';
export const _IMG_CHALLENGER =
  'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/ranks%2FChallenger.png?alt=media&token=395fe1b5-1bf9-4cfa-88f6-b337d84b4cb1';
export const _IMG_HOPRO =
  'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/ranks%2FHoPro.png?alt=media&token=7a614c6e-657d-4d38-bea2-aab491cd535d';

export const _IMG_SWEATY =
  'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/ranks%2FSweaty.png?alt=media&token=a86fee0a-a26e-4161-88ac-a82b9b17b0ad';
export const _IMG_CLUTCHER =
  'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/ranks%2FClutcher.png?alt=media&token=3606d2aa-ec9f-4da5-89a0-809aa6fc366a';
export const _IMG_SMURF =
  'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/ranks%2FSmurf.png?alt=media&token=2b718b4d-a95e-43ff-8fbf-83df3d89c48a';
export const _IMG_LEGEND =
  'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/ranks%2FLegend.png?alt=media&token=2de88d20-145a-4e27-af37-185e8982d682';
// 'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/ranks%2FLegend.png?alt=media&token=192755b6-a817-487e-a54b-fad4f6ef68a7';
export const _IMG_MYTHICAL =
  'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/ranks%2FMythical.png?alt=media&token=42435782-f15b-4a84-983c-e544c20c75f4';
export const _IMG_IMMORTAL =
  'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/ranks%2FImmortal.png?alt=media&token=cd578602-5ef2-4e04-88d7-aad60b0a2396';

export const STICKERS_ICON = require('../../assets/images/stickers_icon.png');


export const defaultProfileImage = 'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/teams%20default%20photos%20and%20background%2Fdefault_team_logov2.png?alt=media&token=b88bf926-35b9-4568-a80e-ee24110aceb6';