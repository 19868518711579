/* eslint-disable react-native/no-inline-styles */
import React, { Component } from 'react';
import { View, TouchableOpacity, ScrollView } from 'react-native';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Entypo from 'react-native-vector-icons/Entypo';
import Feather from 'react-native-vector-icons/Feather';
import firebase from 'firebase/app';
import 'firebase/database';


import I18n from '../../i18n';
import NavigationService from '../../navigation/navigationService';
import GameManager from '../../services/gameManager';
import Gamelistcore from '../games-list/gamelistcore';
import * as FirebasePaths from '../../constants/firebasePaths';
import Gamemodel from '../../models/gamemodel';

// import components
import Label from '../../components/label';
import Picker from '../../components/picker';
import TextInput from '../../components/text-input';
import TextArea from '../../components/text-area';
import Switch from '../../components/switch';

// import styles
import { perfectSize } from '../../helpers/deviceHelper';
import styles from './styles';

// import images
import * as Images from '../../constants/images';

// import models
import Requestmodel from '../../models/requestmodel';
import { presentToast } from '../../helpers/toastHelper';
import { CreateRequestCore } from './createrequestcore';
import Chatcore from '../chat/chatcore';
import { store } from '../../redux/store';
import { set as HubSet } from '../../redux/modules/hub/actions';
import { showAlert, showInputAlert } from '../../redux/modules/alert/actions';
import Searchrequestcore from '../searchrequest/searchrequestcore';
import * as Constants from '../../constants/constants';
import Editrequestcore from '../editrequest/editrequest';
import mainMenuService from '../../navigation/mainMenuService';
import topTabService from '../../navigation/topTabService';
import { getGameByNameFromGameList } from '../../services/utils';
import { showSpinner, hideSpinner } from '../../redux/modules/alert/actions';
import LocalImageOpacity from '../../components/local-image-opacity';
import ImageWithPlaceholder from '../../components/image-with-placeholder';

class CreaterequestPage extends Component {
  platforms = ['PS', 'MOBILE', 'NINTENDO', 'PC', 'XBOX'];
  MATCH_TYPES = [
    {
      value: 'All Matches',
      title: I18n.t('CREATE_REQUEST_PAGE.all_matches'),
    },
    {
      value: 'Competitive',
      title: 'Competitive',
    },
    {
      value: 'Quick Match',
      title: 'Quick Match',
    },
  ];

  REQUEST_TIMES = [
    {
      value: 'URGENT',
      title: I18n.t('CREATE_REQUEST_PAGE.request_time_now'),
    },
    {
      value: 'DAY',
      title: I18n.t('CREATE_REQUEST_PAGE.request_time_day'),
    },
    {
      value: 'ALL',
      title: I18n.t('CREATE_REQUEST_PAGE.request_time_all'),
    },
  ];
  constructor(props) {
    super(props);
    this.state = {
      hoppedRequestToggleState: false,
      CREATE_REQUEST_GAME_PROMO: 0,
      isEditing: props.request ? true : false,
    };
  }

  componentDidMount() {
    let allRegionsArray = this.props.regionsList;
    allRegionsArray = allRegionsArray.map((region) => {
      return {
        value: region,
        title: region,
      };
    });
    this.setState({
      allRegionsArray: allRegionsArray,
      selectedRegion: '',
    });

    this.props.onRef(this);

    CreateRequestCore.CheckRankGamePromo((data) => {
      if (data && data.game && data.CREATE_REQUEST) {
        this.setState({
          PROMO_GAME_ID: data.game,
          CREATE_REQUEST_GAME_PROMO: data.CREATE_REQUEST,
        });
      }
    });

    if (this.props.request) {
      this.setState(
        {
          platform: this.props.request.platform,
          game: GameManager.getGameById(this.props.request.gameId),
          selectedMatchType: this.props.request.matchType,
          selectedRegion: this.props.request.region,
          playersNumber: this.props.request.playersNumber,
          selectedRank: this.props.request.rank,
          description: this.props.request.description,
          requestTime: this.props.request.requestTime,
        },
        () => {
          this.onGameSelected();
        },
      );
    }

    global.rankLogger.LOG_NEW_HP(
      global.rankLogger.VIEW_REQUEST_PAGE,
      'VIEW_REQUEST_PAGE',
    );
    if (this.props.userinformationDB.rank >= 1300) {
      this.hoppedRequestTrigger(true);
    } else {
      this.hoppedRequestTrigger(false);
    }
  }

  toSelectGame = () => {
    NavigationService.moveToScreenInStack('HomeStack', 'SelectGamePage', {
      fromSavedRequest: this.props.request != null,
    });
  };

  platformTrigger = (platform) => {
    this.setState(
      {
        platform: platform,
      },
      () => {
        this.triggerCheckModel();
      },
    );
  };

  renderTags = () => {
    const btn_tags = [
      Images.BTN_TAG_PLAYSTATION,
      Images.BTN_TAG_MOBILE,
      Images.BTN_TAG_DOUBLE,
      Images.BTN_TAG_DESKTOP,
      Images.BTN_TAG_BALL,
    ];
    const btn_tags_selected = [
      Images.BTN_TAG_PLAYSTATION_SELECTED,
      Images.BTN_TAG_MOBILE_SELECTED,
      Images.BTN_TAG_DOUBLE_SELECTED,
      Images.BTN_TAG_DESKTOP_SELECTED,
      Images.BTN_TAG_BALL_SELECTED,
    ];
    let views = [];
    btn_tags.forEach((tag, index) => {
      views.push(
        <TouchableOpacity
          key={index}
          onPress={() => this.platformTrigger(this.platforms[index])}>
          {this.state.platform === this.platforms[index] && (
            <LocalImageOpacity
              source={btn_tags_selected[index]}
              style={styles.btn_tag}
              resizeMode="contain"
            />
          )}
          {this.state.platform !== this.platforms[index] && (
            <LocalImageOpacity
              source={tag}
              style={styles.btn_tag}
              resizeMode="contain"
            />
          )}
        </TouchableOpacity>,
      );
    });
    return views;
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.game !== this.props.game && this.props.game != null) {
      this.setState(
        {
          game: this.props.game,
        },
        () => {
          this.onGameSelected();
        },
      );
    }
  }

  onGameSelected = () => {
    Gamelistcore.addGame(this.state.game);
    if (!GameManager.checkIfHasGameById(this.state.game.gameID)) {
      Gamelistcore.addGame(this.state.game);
    }

    this.setState({
      IS_COMP_GAME: this.checkIfComp(this.state.game.gameName),
    });

    this.generatePlayersNumber(this.state.game.gameName);

    if (this.state.IS_COMP_MATCH) {
      let ranksArray = [];
      // Now insert ranks
      let _game = this.props.gameNames.filter(
        (e) => e.gameName === this.state.game.gameName,
      );
      if (_game.length > 0) {
        _game = _game[0];
        ranksArray = Array.from(_game.ranksList.values());
      }

      ranksArray = ranksArray.map((rank) => {
        return { value: rank, title: rank };
      });
      ranksArray.unshift({
        value: 'All Ranks',
        title: I18n.t('CREATE_REQUEST_PAGE.all_ranks'),
      });
      this.setState({
        ranksArray: ranksArray,
      });
    }

    this.triggerCheckModel();
  };

  triggerCheckModel = () => {
    if (
      this.state.platform !== '' &&
      this.state.game != null &&
      this.state.selectedRegion != ''
    ) {
      if (!this.props.request) {
        let requestModel = new Requestmodel();

        // Fill a req model

        // All ranks all regions all matches ..etc except for platforms and the game name

        requestModel.platform = this.state.platform;
        requestModel.requestTitle = this.state.game.gameName;
        requestModel.region = this.state.selectedRegion;
        requestModel.playersNumber = 'All Numbers';
        requestModel.matchType = 'All Matches';
        requestModel.rank = 'All Ranks';

        // Now call the search request core and search for reqs

        // Check if found results and then show the page

        Searchrequestcore.triggerSearchForRequest(requestModel);
        setTimeout(() => {
          if (this.props.searchReqResList.size > 0) {
            let gamePhoto = this.state.game.gamePhotoUrl;

            NavigationService.moveToScreenInStack(
              'HomeStack',
              'CheckRequestsModal',
              {
                gamePhotoUrl: gamePhoto,
              },
            );
          }
        }, 5000);
      }
    }
  };

  checkIfComp = (gameName) => {
    let _game = this.props.gameNames.filter((e) => e.gameName === gameName);
    if (_game.length > 0) {
      _game = _game[0];
      if (_game.gameType === FirebasePaths.FIREBASE_GAME_COMPETITVE_ATTR) {
        return true;
      }
      return false;
    }
    return false;
  };

  // Generate players number

  generatePlayersNumber = (gameName) => {
    // Empty array

    let playersNumberArray = [];
    let _game = this.props.gameNames.filter((e) => e.gameName === gameName);
    let maxPlayers = 2;
    if (_game.length > 0) {
      _game = _game[0];
      maxPlayers = _game.maxPlayers;
    }

    // We start from 2 : as a minimum players number in a request

    for (let i = 2; i <= maxPlayers; i++) {
      playersNumberArray.push({
        title: i + '',
        value: i + '',
      });
    }

    this.setState({
      playersNumberArray: playersNumberArray,
      // playersNumber: maxPlayers,
    });
  };

  getSelectedMatchType = (val) => {
    this.setState({
      selectedMatchType: val,
    });

    // Show ranks div if match type is comp and load all ranks , otherwise hide it

    if (
      val.toString() === FirebasePaths.GAME_TYPE_COMPETITVE &&
      this.state.IS_COMP_GAME
    ) {
      // To show ranks view

      this.setState({
        IS_COMP_MATCH: true,
      });

      let ranksArray = [];
      // Now insert ranks
      let _game = this.props.gameNames.filter(
        (e) => e.gameName === this.state.game.gameName,
      );
      if (_game.length > 0) {
        _game = _game[0];
        ranksArray = Array.from(_game.ranksList.values());
      }
      ranksArray = ranksArray.map((rank) => {
        return { value: rank, title: rank };
      });
      ranksArray.unshift({
        value: 'All Ranks',
        title: I18n.t('CREATE_REQUEST_PAGE.all_ranks'),
      });
      this.setState({
        ranksArray: ranksArray,
      });
    } else {
      this.IS_COMP_MATCH = false;
    }
  };

  updateRequestTrigger = () => {
    let platform = this.state.platform;
    let gameName = this.state.game ? this.state.game.gameName : '';
    let matchType = this.state.selectedMatchType;
    let region = this.state.selectedRegion;
    let playersNumber = this.state.playersNumber;
    let rank = this.state.selectedRank;
    let description = this.state.description;

    let pcGameProvider = '';

    if (getGameByNameFromGameList(gameName) != null) {
      pcGameProvider = getGameByNameFromGameList(gameName).pcGameProvider;
    }

    if (!rank) {
      rank = 'All Ranks';
    }
    if (!description) {
      description = 'Hey, who want to play ' + gameName + ' ?';
    }
    if (!matchType) {
      matchType = 'All Matches';
    }
    if (!playersNumber) {
      playersNumber = 'All Numbers';
    }

    if (this.isValidRequest()) {
      let supportedPlatforms = this.state.game.gamePlatforms;

      if (this.validatePlatforms(supportedPlatforms)) {
        global.rankLogger.LOG_NEW_HP(
          global.rankLogger.EDIT_REQUEST,
          'EDIT_REQUEST',
        );

        global.featuresLogger.LOG_REQUEST_UPDATE_SAVED_REQUEST();

        if (
          (this.state.platform === 'PS' &&
            this.props.userinformationDB.PSNAcc !== '') ||
          (this.state.platform === 'XBOX' &&
            this.props.userinformationDB.XboxLiveAcc !== '') ||
          (this.state.platform === 'NINTENDO' &&
            this.props.userinformationDB.nintendoAcc !== '') ||
          (this.state.platform === 'MOBILE' &&
            this.props.userinformationDB.mobileGamesAccs.has(
              this.state.game.gameName + ' ' + 'Mobile',
            ) &&
            this.props.userinformationDB.mobileGamesAccs.get(
              this.state.game.gameName + ' ' + 'Mobile',
            ) !== '') ||
          (this.state.platform === 'PC' &&
            this.props.userinformationDB.pcGamesAccs.has(pcGameProvider) &&
            this.props.userinformationDB.pcGamesAccs.get(pcGameProvider) !== '')
        ) {
          this.updateRequest(
            platform,
            this.state.game.gameName,
            matchType,
            region,
            playersNumber,
            rank,
            this.state.requestTime,
            this.state.description,
          );
        } else {
          this.showGameProviderDialog(
            platform,
            this.state.game,
            matchType,
            region,
            playersNumber,
            rank,
            description,
          );
        }
      }
    }
  };

  updateRequest(
    platform,
    gameName,
    match,
    region,
    playersNumber,
    rank,
    requestTime,
    description,
  ) {
    let updatedRequestModel = Object.assign({}, this.props.request);

    updatedRequestModel.gameId = this.state.game.gameID;
    updatedRequestModel.platform = platform;
    updatedRequestModel.matchType = match;
    updatedRequestModel.region = region;
    updatedRequestModel.requestTitle = gameName;
    updatedRequestModel.requestPicture = this.state.game.gamePhotoUrl;
    updatedRequestModel.requestTime = requestTime;

    let gameModel = new Gamemodel();

    gameModel = this.state.game;

    let numberOfPlayers =
      playersNumber === 'All Numbers' ? gameModel.maxPlayers : +playersNumber;
    updatedRequestModel.playersNumber = numberOfPlayers;
    updatedRequestModel.rank = rank;
    updatedRequestModel.description = description;

    // We fill the updated request model now we updated in hub and  in firebase

    // Updated  in Hub
    let savedRequestsList = this.props.savedRequestsList;
    savedRequestsList.set(
      updatedRequestModel.savedRequestUniqueId,
      updatedRequestModel,
    );
    store.dispatch(
      HubSet({
        savedRequestsList: savedRequestsList,
      }),
    );

    Editrequestcore.updateReqFirebase(updatedRequestModel);

    // Log Feature : Update Request

    global.featuresLogger.LOG_REQUEST_UPDATE_SAVED_REQUEST();

    presentToast({
      message: I18n.t('EDIT_REQUEST_PAGE.success_update'),
    });

    // Finish this page : pop it

    NavigationService.navigate('NewRequestPage');
  }

  requestTrigger = (requestType) => {
    this.setState({
      requestType: requestType,
    });

    let platform = this.state.platform;
    let gameName = this.state.game ? this.state.game.gameName : '';
    let matchType = this.state.selectedMatchType;
    let region = this.state.selectedRegion;
    let playersNumber = this.state.playersNumber;
    let rank = this.state.selectedRank;
    let description = this.state.description;

    let pcGameProvider = '';

    if (getGameByNameFromGameList(gameName) != null) {
      pcGameProvider = getGameByNameFromGameList(gameName).pcGameProvider;
    }

    if (!rank) {
      rank = 'All Ranks';
    }
    if (!description || description.length === 0) {
      description = 'Hey, who want to play ' + gameName + ' ?';
    }
    if (!matchType) {
      matchType = 'All Matches';
    }
    if (!playersNumber) {
      playersNumber = 'All Numbers';
    }

    if (this.isValidRequest()) {
      let supportedPlatforms = this.state.game.gamePlatforms;

      if (this.validatePlatforms(supportedPlatforms)) {
        if (
          (this.state.platform === 'PS' &&
            this.props.userinformationDB.PSNAcc !== '') ||
          (this.state.platform === 'XBOX' &&
            this.props.userinformationDB.XboxLiveAcc !== '') ||
          (this.state.platform === 'NINTENDO' &&
            this.props.userinformationDB.nintendoAcc !== '') ||
          (this.state.platform === 'MOBILE' &&
            this.props.userinformationDB.mobileGamesAccs.has(
              this.state.game.gameName + ' ' + 'Mobile',
            ) &&
            this.props.userinformationDB.mobileGamesAccs.get(
              this.state.game.gameName + ' ' + 'Mobile',
            ) !== '') ||
          (this.state.platform === 'PC' &&
            this.props.userinformationDB.pcGamesAccs.has(pcGameProvider) &&
            this.props.userinformationDB.pcGamesAccs.get(pcGameProvider) !== '')
        ) {
          if (requestType === 'NEW_REQUEST') {
            global.rankLogger.LOG_NEW_HP(global.rankLogger.REQUEST, 'REQUEST');
            let gameId = this.state.game.gameID;
            if (
              this.state.PROMO_GAME_ID &&
              this.state.PROMO_GAME_ID === gameId
            ) {
              global.rankLogger.LOG_NEW_HP(
                this.state.CREATE_REQUEST_GAME_PROMO,
                'CREATE_REQUEST_GAME_PROMO',
              );
            }
            CreateRequestCore.addRequestToFirebase(
              platform,
              this.state.game.gameName,
              matchType,
              region,
              playersNumber,
              rank,
              this.state.requestTime,
              description,
              this.state.hoppedRequestToggleState,
            );
            this.finishRequest();
          } else {
            this.prepareSaveRequest(
              this.state.game.gameName,
              matchType,
              region,
              playersNumber,
              rank,
              description,
            );
          }
        } else {
          this.showGameProviderDialog(
            platform,
            this.state.game,
            matchType,
            region,
            playersNumber,
            rank,
            description,
          );
        }
      }
    }
  };

  prepareSaveRequest = (
    game,
    match,
    region,
    playersNumber,
    rank,
    description,
  ) => {
    let maxReqs = Constants.MAX_SAVED_REQUEST_REGULAR;
    // Check if user exceeds the max limit of saved reqs
    if (this.props.savedRequestsList.size >= maxReqs) {
      presentToast({
        message: I18n.t('CREATE_REQUEST_PAGE.max_requests'),
      });
    }
    // Get the game model  by name
    let gameModel = this.state.game;
    // Get the max players as a number
    let numberOfPlayers =
      playersNumber === 'All Numbers' ? gameModel.maxPlayers : +playersNumber;
    // Create request model and fill the model
    let requestModel = new Requestmodel();
    requestModel.platform = this.state.platform;
    requestModel.requestTitle = game;
    requestModel.adminName = this.props.userinformationDB.username;
    requestModel.description = description;
    requestModel.region = region;
    requestModel.playersNumber = numberOfPlayers;
    requestModel.matchType = match;
    requestModel.rank = rank;
    requestModel.admin = this.props.uid;
    requestModel.gameId = gameModel.gameID;
    requestModel.requestPicture = gameModel.gamePhotoUrl;

    // Generate random unique id for saved request
    let uniqueID = firebase.database().ref().push().key;
    // Set unique id for this saved request
    requestModel.savedRequestUniqueId = uniqueID;
    // Add this saved request to the hub
    let savedRequestsList = this.props.savedRequestsList;
    savedRequestsList.set(uniqueID, requestModel);
    store.dispatch(
      HubSet({
        savedRequestsList: savedRequestsList,
      }),
    );
    // Add this saved request to the firebase
    CreateRequestCore.addSaveRequestToFirebase(uniqueID);
    // pop this page
    NavigationService.resetRoute('TabStack');
    presentToast({
      message: I18n.t('CREATE_REQUEST_PAGE.success_save'),
    });
  };

  showGameProviderDialog = (
    platform,
    game,
    match,
    region,
    playersNumber,
    rank,
    description,
  ) => {
    let title = I18n.t('CREATE_REQUEST_PAGE.game_provider');
    let message = I18n.t('CREATE_REQUEST_PAGE.game_provider_message_default');

    if (platform === 'PS') {
      title = I18n.t('CREATE_REQUEST_PAGE.ps_game_provider');
      message = I18n.t('CREATE_REQUEST_PAGE.ps_game_provider_message');
    } else if (platform === 'XBOX') {
      title = I18n.t('CREATE_REQUEST_PAGE.xbox_game_provider');
      message = I18n.t('CREATE_REQUEST_PAGE.xbox_game_provider_message');
    } else if (platform === 'NINTENDO') {
      title = I18n.t('CREATE_REQUEST_PAGE.nintendo_game_provider');
      message = I18n.t('CREATE_REQUEST_PAGE.nintendo_game_provider_message');
    } else if (platform === 'MOBILE') {
      title = I18n.t('CREATE_REQUEST_PAGE.mobile_game_provider');
      message = I18n.t('CREATE_REQUEST_PAGE.mobile_game_provider_message');
    } else {
      title =
        this.state.game.pcGameProvider +
        ' ' +
        I18n.t('CREATE_REQUEST_PAGE.account');
      message = I18n.t('CREATE_REQUEST_PAGE.pc_game_provider_message') + title;
    }

    this.setState(
      {
        dialogTitle: title,
        dialogMessage: message,
      },
      () => {
        this.props.showInputAlert([
          this.state.dialogTitle,
          <View style={styles.dialogSectionContainer}>
            <Label style={styles.dialogDescription}>
              {this.state.dialogMessage}
            </Label>
            <TextInput
              customStyles={[
                styles.input,
                this.state.error && {
                  borderBottomColor: 'red',
                },
              ]}
              placeholder={I18n.t('CREATE_REQUEST_PAGE.provider_acc_example')}
              value={this.state.gameProviderAcc}
              onChangeText={(value) =>
                this.setState({
                  gameProviderAcc: value,
                  error: false,
                })
              }
              placeholderTextColor={'#444444'}
            />
          </View>,
          [
            {
              text: I18n.t('CREATE_REQUEST_PAGE.cancel').toUpperCase(),
              onPress: () => { },
            },
            {
              text: I18n.t('CREATE_REQUEST_PAGE.save').toUpperCase(),
              onPress: () => {
                if (
                  this.state.gameProviderAcc &&
                  this.state.gameProviderAcc.length > 0
                ) {
                  this.onEnterAccount();
                } else {
                  presentToast({
                    message: I18n.t('CREATE_REQUEST_PAGE.enter_account'),
                  });
                }
              },
            },
          ],
        ]);
      },
    );
  };

  finishRequest = () => {
    store.dispatch(HubSet({ isFromLogin: false }));

    presentToast({
      message: I18n.t('NEW_REQUEST_PAGE.alert_success_request'),
    });

    this.props.showAlert([
      I18n.t('LOBBY_PAGE.bazinga'),
      I18n.t('LOBBY_PAGE.bazinga_msg'),
      [
        {
          text: I18n.t('LOBBY_PAGE.check'),
          onPress: () => {
            mainMenuService.navigate('Chat');
            setTimeout(() => {
              topTabService.navigate('ChatRequests');
            }, 50);
          },
        },
      ],
      false,
    ]);

    Chatcore.openGroupChatsConnection(
      store.getState().hubReducer.currentRequest.requestId,
      this.state.game.gameName,
    );

    // Go to the lobby menu
    NavigationService.resetRoute('TabStack');
  };

  validatePlatforms(supportedPlatforms) {
    if (!supportedPlatforms.includes(this.state.platform)) {
      // Show toast this game doesn't support this platform

      presentToast({
        message: I18n.t('CREATE_REQUEST_PAGE.not_supported_platform_error'),
      });

      return false;
    }

    return true;
  }

  isValidRequest = () => {
    if (!this.state.platform) {
      presentToast({
        message: I18n.t('CREATE_REQUEST_PAGE.error_platform'),
      });
      return false;
    }
    if (!this.state.game) {
      presentToast({
        message: I18n.t('CREATE_REQUEST_PAGE.error_game'),
      });
      return false;
    }
    if (!this.state.selectedRegion) {
      presentToast({
        message: I18n.t('CREATE_REQUEST_PAGE.error_region'),
      });
      return false;
    }

    return true;
  };

  onEnterAccount = () => {
    if (this.props.request) {
      if (this.state.gameProviderAcc && this.state.gameProviderAcc.length > 0) {
        Editrequestcore.saveGameProviderAccount(
          this.state.game.pcGameProvider,
          this.state.gameProviderAcc,
          this.state.platform,
          this.state.game.gameName,
        );
        this.updateRequest(
          this.state.platform,
          this.state.game.gameName,
          this.state.selectedMatchType,
          this.state.selectedRegion,
          this.state.playersNumber,
          this.state.selectedRank,
          this.state.requestTime,
          this.state.description,
        );
      }
    } else {
      let platform = this.state.platform;
      let gameName = this.state.game ? this.state.game.gameName : '';
      let matchType = this.state.selectedMatchType;
      let region = this.state.selectedRegion;
      let playersNumber = this.state.playersNumber;
      let rank = this.state.selectedRank;
      let description = this.state.description;
      if (
        this.state.requestType === 'NEW_REQUEST' &&
        this.state.gameProviderAcc &&
        this.state.gameProviderAcc.length > 0
      ) {
        if (!rank) {
          rank = 'All Ranks';
        }
        if (!description || description.length === 0) {
          description = 'Hey, who want to play ' + gameName + ' ?';
        }
        if (!matchType) {
          matchType = 'All Matches';
        }
        if (!playersNumber) {
          playersNumber = 'All Numbers';
        }

        global.rankLogger.LOG_NEW_HP(global.rankLogger.REQUEST, 'REQUEST');

        CreateRequestCore.saveGameProviderAccount(
          this.state.game.pcGameProvider,
          this.state.gameProviderAcc,
          this.state.platform,
          this.state.game.gameName,
        );
        CreateRequestCore.addRequestToFirebase(
          platform,
          gameName,
          matchType,
          region,
          playersNumber,
          rank,
          this.state.requestTime,
          description,
          this.state.hoppedRequestToggleState,
        );
        this.finishRequest();
      } else {
        global.rankLogger.LOG_NEW_HP(
          global.rankLogger.SAVE_REQUEST,
          'SAVE_REQUEST',
        );

        CreateRequestCore.saveGameProviderAccount(
          this.state.game.pcGameProvider,
          this.state.gameProviderAcc,
          this.state.platform,
          this.state.game.gameName,
        );

        this.prepareSaveRequest(
          this.state.game.gameName,
          matchType,
          region,
          playersNumber,
          rank,
          description,
        );
      }
    }
  };

  hoppedRequestTrigger = (triggerValue) => {
    this.setState({
      hoppedRequestToggleState: triggerValue,
    });
    if (this.props.userinformationDB.rank < 1300 && triggerValue) {
      presentToast({
        message:
          ' معليش رانكك ضعيف , رانكك الحالي ' +
          global.rankLogger.getUserRankName(this.props.userinformationDB.rank) +
          '  تحتاج ' +
          (1300 - this.props.userinformationDB.rank) +
          ' HP لاستخدام هذي الخاصية' +
          '، تقدر ترفع رانكك بالتفاعل مع القيمرز الرهيبين في التطبيق واستخدام كل خصائص التطبيق باستمرار ',
      });
      setTimeout(() => {
        this.setState({
          hoppedRequestToggleState: false,
        });
      }, 2);
    } else if (triggerValue && this.props.userinformationDB.rank >= 1300) {
      this.setState({
        hoppedRequestToggleState: true,
      });
    }
  };

  render() {
    const { theme } = this.props;
    return (
      <View style={styles.container}>
        <Picker
          pickerName={
            this.state.game !== undefined
              ? this.state.game.gameName
              : I18n.t('NEW_REQUEST_PAGE.choose-game')
          }
          leftComponent={
            this.state.game !== undefined ? (
              <ImageWithPlaceholder
                uri={this.state.game.gamePhotoUrl}
                style={styles.itemImage}
              />
            ) : (
              <Entypo
                name="game-controller"
                size={perfectSize(20)}
                color={theme.primary_text}
                style={{ opacity: 0.5 }}
              />
            )
          }
          onPickerPress={this.toSelectGame}
        // value={this.state.game}
        // customStyles={{marginVertical: perfectSize(8)}}
        // values={this.state.searchableGamesModel}
        // onPickerChanged={(val) => this.setState({game: val})}
        />
        {/* Platform */}
        <Label style={styles.label}>
          {I18n.t('CHAMPIONSHIP_INFO_PAGE.platform')}
        </Label>
        <View style={styles.mt4}>
          <ScrollView showsHorizontalScrollIndicator={false} horizontal={true}>
            {this.renderTags()}
          </ScrollView>
        </View>
        {/* Match type */}
        {this.state.IS_COMP_GAME && (
          <>
            <Label style={styles.label}>
              {I18n.t('SEARCH_REQUEST_PAGE.match')}
            </Label>
            <Picker
              customStyles={styles.mt4}
              leftComponent={
                <Feather
                  name="zap"
                  size={perfectSize(20)}
                  color={theme.primary_text}
                  style={{ opacity: 0.5 }}
                />
              }
              value={this.state.selectedMatchType}
              values={this.MATCH_TYPES}
              pickerName={I18n.t('CREATE_REQUEST_PAGE.match')}
              onPickerChanged={(val) => this.getSelectedMatchType(val)}
            />
          </>
        )}

        {/* Rank */}
        {this.state.IS_COMP_GAME && this.state.IS_COMP_MATCH && (
          <>
            <Label style={styles.label}>
              {I18n.t('CREATE_REQUEST_PAGE.rank')}
            </Label>
            <Picker
              customStyles={styles.mt4}
              leftComponent={
                <Feather
                  name="zap"
                  size={perfectSize(20)}
                  color={theme.primary_text}
                  style={{ opacity: 0.5 }}
                />
              }
              value={this.state.selectedRank}
              values={this.state.ranksArray}
              pickerName={I18n.t('CREATE_REQUEST_PAGE.rank')}
              onPickerChanged={(val) =>
                this.setState({
                  selectedRank: val,
                })
              }
            />
          </>
        )}

        {/* Region */}
        <Label style={styles.label}>
          {I18n.t('SEARCH_REQUEST_PAGE.region')}
        </Label>
        <Picker
          customStyles={styles.mt4}
          value={this.state.selectedRegion}
          values={this.state.allRegionsArray}
          pickerName={I18n.t('SEARCH_REQUEST_PAGE.region')}
          onPickerChanged={(val) =>
            this.setState(
              {
                selectedRegion: val,
              },
              () => {
                this.triggerCheckModel();
              },
            )
          }
          leftComponent={
            <Feather
              name="map-pin"
              size={perfectSize(20)}
              color={theme.primary_text}
              style={{ opacity: 0.5 }}
            />
          }
        />
        {/* Number of Player */}
        <Label style={styles.label}>
          {I18n.t('CREATE_REQUEST_PAGE.players_number')}
        </Label>
        <Picker
          customStyles={styles.mt4}
          pickerName={I18n.t('CREATE_REQUEST_PAGE.players_number')}
          value={this.state.playersNumber + ''}
          values={this.state.playersNumberArray}
          onPickerChanged={(val) =>
            this.setState({
              playersNumber: val,
            })
          }
          leftComponent={
            <Feather
              name="hash"
              size={perfectSize(20)}
              color={theme.primary_text}
              style={{ opacity: 0.5 }}
            />
          }
        />
        {/* Request Time */}
        <Label style={styles.label}>
          {I18n.t('CREATE_REQUEST_PAGE.request_time')}
        </Label>
        <Picker
          customStyles={styles.mt4}
          pickerName={I18n.t('CREATE_REQUEST_PAGE.request_time')}
          leftComponent={
            <Feather
              name="clock"
              size={perfectSize(20)}
              color={theme.primary_text}
              style={{ opacity: 0.5 }}
            />
          }
          value={this.state.requestTime}
          values={this.REQUEST_TIMES}
          onPickerChanged={(val) => {
            this.setState({
              requestTime: val,
            });
          }}
        />
        <View style={styles.flexRow}>
          <Label style={[styles.label, styles.flex]}>
            {I18n.t('CREATE_REQUEST_PAGE.hopped_request_label')}
          </Label>
          <Switch
            initialValue={this.state.hoppedRequestToggleState}
            value={this.state.hoppedRequestToggleState}
            onValueChanged={(val) => this.hoppedRequestTrigger(val)}
            style={styles.marginTop}
          />
        </View>
        {/* Description */}
        <Label style={styles.label}>
          {I18n.t('CREATE_REQUEST_PAGE.description')}
        </Label>
        <TextArea
          customStyles={styles.description}
          placeholder={I18n.t('CREATE_REQUEST_PAGE.more_standards')}
          value={this.state.description}
          onChangeText={(value) =>
            this.setState({
              description: value,
            })
          }
        />
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
    gameNames: state.gameManagerReducer.allGamesName,
    regionsList: state.hubReducer.regionsList,
    userinformationDB: state.authReducer.userinformationDB,
    searchReqResList: state.hubReducer.searchReqResList,
    savedRequestsList: state.hubReducer.savedRequestsList,
    uid: state.authReducer.uid,
  };
};

export default compose(
  connect(mapStateToProps, {
    showAlert,
    showInputAlert,
    showSpinner,
    hideSpinner,
  }),
)(CreaterequestPage);
