export default class Packet {
  constructor() {
    this.messageId = '';
    this.counter = 0;
    this.chatRef = '';
    this.username = '';
    this.uid = '';
    this.message = '';
    this.timestamp = null;
    this.type = '';
    this.borderColor = '#880e4a';
    this.isSticker = false;
    this.extension = null;
  }
}
