/* eslint-disable no-unused-vars */
import React, { memo, Component } from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Feather from 'react-native-vector-icons/Feather';

import { perfectSize } from '../../../helpers/deviceHelper';
import Label from '../../label';
import I18n from '../../../i18n';
import LocalImageOpacity from '../../local-image-opacity';
import ImageWithPlaceholder from '../../image-with-placeholder';
import OverlayImage from '../../overlay-image';

import { CROWN, _IMG_GOLD_II } from '../../../constants/images';
import UserAvatar from '../../user-avatar';

class AttendantUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    render() {
        const { theme, item, uid, userRank, isAdmin, img } = this.props;
        const _styles = styles(theme);
        return (
            <View style={_styles.container}>
                {isAdmin ?
                    < ImageWithPlaceholder
                        uri={'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/GeneralIcons%2Fcrown.png?alt=media&token=e1bf7662-a6de-424d-bd9b-2c1f40d47be0'}
                        style={_styles.crown}
                    /> : <View style={_styles.crown} />
                }

                <View style={{ alignItems: 'center' }}>
                    <UserAvatar
                        uid={uid}
                        src={img}
                        size={perfectSize(18)}
                    />
                    <ImageWithPlaceholder
                        uri={global.rankLogger.getRankImg(userRank)}
                        style={[global.rankLogger.getRankStyleForHoot(
                            userRank,
                        ), { position: 'absolute', bottom: -perfectSize(2) }]}
                    />
                </View>
            </View>
        );
    }
}

AttendantUser.propTypes = {
    data: PropTypes.object,
    isJoined: PropTypes.bool,
    onJoin: PropTypes.func,
    from: PropTypes.string,
};

const mapStateToProps = (state) => {
    return {
        theme: state.appReducer.currentTheme,
    };
};

export default memo(connect(mapStateToProps)(AttendantUser));

const styles = (theme) =>
    StyleSheet.create({
        container: {
            alignItems: 'center'
        },
        crown: {
            width: perfectSize(7),
            height: perfectSize(5),
            marginBottom: perfectSize(2)
        },
        userRank: {
            position: 'absolute',
        }
    });
