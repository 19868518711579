// custom alert
export const SHOW_ALERT = 'SHOW_ALERT';
export const SHOW_ALERT_FINISHED = 'SHOW_ALERT_FINISHED';
export const HIDE_ALERT = 'HIDE_ALERT';

export const SHOW_INPUT_ALERT = 'SHOW_INPUT_ALERT';
export const SHOW_INPUT_ALERT_FINISHED = 'SHOW_INPUT_ALERT_FINISHED';
export const HIDE_INPUT_ALERT = 'HIDE_INPUT_ALERT';

export const SHOW_SPINNER = 'SHOW_SPINNER';
export const SHOW_SPINNER_FINISHED = 'SHOW_SPINNER_FINISHED';
export const HIDE_SPINNER = 'HIDE_SPINNER';

export const SHOW_APP_EXIT = 'SHOW_APP_EXIT';
export const SHOW_APP_EXIT_FINISHED = 'SHOW_APP_EXIT_FINISHED';
export const HIDE_APP_EXIT = 'HIDE_APP_EXIT';
