import React, { useEffect, useState } from 'react';
import { Image, Platform } from 'react-native';

import ImageWithPlaceholder from '../image-with-placeholder';

const Sticker = (props) => {
  const { url, customStyles } = props;
  const [width, setWidth] = useState();
  const [height, setHeight] = useState();
  useEffect(() => {
    Image.getSize(url, (width, height) => {
      setWidth(width);
      setHeight(height);
    })
  }, [])
  return (
    <ImageWithPlaceholder
      uri={url}
      style={[{ width: width, height: height }, customStyles]}
    />
  );
};

export default Sticker;
