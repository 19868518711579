import {StyleSheet} from 'react-native';

const styles = (theme) =>
  StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: theme.secondary_background,
    },
    content: {
      flex: 1,
      backgroundColor: theme.primary_background,
    },
    tabContent: {
      flex: 1,
    },
  });

export default styles;
