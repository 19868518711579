import { StyleSheet } from 'react-native';
import { perfectSize } from '../../helpers/deviceHelper';
import Dimens from '../../constants/dimens';

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  wrapper: {
    flex: 1,
    // marginTop: perfectSize(21),
    borderTopLeftRadius: Dimens.BODY_BORDER_TOP_RADIUS,
    borderTopRightRadius: Dimens.BODY_BORDER_TOP_RADIUS,
    paddingHorizontal: Dimens.HEADER_PADDING_HORIZONTAL,
    paddingVertical: Dimens.HEADER_PADDING_VERTICAL,
  },
  flexRow: {
    flexDirection: 'row',
  },
  alingCenter: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  justifyBetween: {
    justifyContent: 'space-between',
  },
  btnNewRequest: {
    height: perfectSize(32),
    paddingHorizontal: perfectSize(20),
    borderRadius: perfectSize(10),
    marginRight: perfectSize(10),
  },
  itemWrapper: {
    marginTop: perfectSize(21),
  },
  gameIcon: {
    width: perfectSize(32),
    height: perfectSize(32),
    aspectRatio: 1,
    borderRadius: perfectSize(12),
  },
  adCard: {
    borderRadius: perfectSize(12),
    width: '100%',
    aspectRatio: 327 / 100,
    height: 250,
    overflow: 'hidden',
  },
  adCover: {
    width: '100%',
    height: '100%',
  },
});

export default styles;
