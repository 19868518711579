import React, { memo, Component } from 'react';
import { View } from 'react-native';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as FirebasePaths from '../../constants/firebasePaths';
import firebase from 'firebase/app';
import 'firebase/database';

import ImageWithPlaceholder from '../image-with-placeholder';
import { PLACEHOLDER_AVATAR, defaultProfileImage } from '../../constants/images';
import { perfectSize } from '../../helpers/deviceHelper';
import ImageWithPlaceholderWithoutFocus from '../image-with-placeholder/without-focus';

class UserAvatar extends Component {
  constructor(props) {
    super(props);
    this.mount = true;
    this.state = {
      status: 'offline',
      picUrl: props.src,
    };
  }

  componentDidMount() {
    const { uid } = this.props;
    if (uid !== null) {
      this.checkUserStatus(uid);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.uid !== this.props.uid && this.props.uid !== null) {
      this.checkUserStatus(this.props.uid);
    }
  }

  checkUserStatus = (uid) => {
    let path =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      uid +
      '/' +
      FirebasePaths.FIREBASE_DETAILS_ATTR;
    this.userRef$ = firebase.database().ref(path);
    this.userRef$.on('value', (shot) => {
      shot.forEach((item) => {
        // Status
        if (item.key === '_status_') {
          if (this.mount) {
            this.setState({ status: item.val() });
          }

        }
        if (item.key === FirebasePaths.FIREBASE_PICTURE_URL_ATTR) {
          if (item.val() && item.val() !== 'NULL') {
            if (this.mount) {
              this.setState({ picUrl: item.val() });
            }
          }
        }
      });
    });
  }

  componentWillUnmount() {
    if (this.userRef$) {
      this.userRef$.off();
      this.userRef$ = null;
    }
    this.mount = false;
  }

  render() {
    const { status, picUrl } = this.state;
    const { src, online, showStatus, priority, from, withFocus } = this.props;
    const size = this.props.size || perfectSize(32);

    const borderColor = this.props.borderColor || '#C70064';
    const borderWidth =
      this.props.borderWidth != null ? this.props.borderWidth : 1;
    const containerStyle = {
      width: size,
      height: size,
    };
    const imgContainerStyle = {
      width: size,
      height: size,
      borderRadius: this.props.borderRadius || size / 2,
      borderColor: borderColor,
      borderWidth: borderWidth,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'gray',
      overflow: 'hidden',
    };
    const avatarStyle = {
      width: size - borderWidth * 2,
      height: size - borderWidth * 2,
    };
    const badgeRadius = size / 4;
    const pad = size * 0.14645 - badgeRadius / 2;
    const statusStyle = {
      position: 'absolute',
      height: badgeRadius,
      width: badgeRadius,
      right: pad,
      bottom: pad,
      borderRadius: badgeRadius / 2,
      backgroundColor:
        online != null
          ? online
            ? '#00FFB4'
            : '#BABABA'
          : status === 'online'
            ? '#00FFB4'
            : '#BABABA',
    };
    return (
      <View style={[containerStyle, this.props.style]}>
        <View style={[imgContainerStyle, this.props.imgStyle]}>
        {
            withFocus ? <ImageWithPlaceholder
              style={avatarStyle}
              uri={src || picUrl}
              placeholder={defaultProfileImage}
              size={size}
              priority={priority}
              from={from}
            /> : <ImageWithPlaceholderWithoutFocus
              style={avatarStyle}
              uri={src || picUrl}
              placeholder={defaultProfileImage}
              size={size}
              priority={priority}
              from={from}
            />
          }
        </View>
        {showStatus && <View style={statusStyle} />}
      </View>
    );
  }
}

UserAvatar.propTypes = {
  uid: PropTypes.string,
  src: PropTypes.string,
  borderColor: PropTypes.string,
  withFocus: PropTypes.bool,
};

UserAvatar.defaultProps = {
  withFocus: true
}

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
  };
};

export default memo(connect(mapStateToProps)(UserAvatar));
