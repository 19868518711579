import {StyleSheet} from 'react-native';
import {perfectSize} from '../../helpers/deviceHelper';
import Dimens from '../../constants/dimens';

const styles = (theme) =>
  StyleSheet.create({
    // Header
    header: {
      height: 200,
      backgroundColor: '#181F29',
      justifyContent: 'center',
    },
    back: {
      position: 'absolute',
      top: 0,
      left: 0,
      padding: perfectSize(16),
      zIndex: 999,
    },
    actions: {
      position: 'absolute',
      top: 0,
      right: perfectSize(16),
      flexDirection: 'row',
      zIndex: 999,
    },
    actionBtn: {
      paddingVertical: perfectSize(16),
      paddingHorizontal: perfectSize(4),
    },
    notificationBadge: {
      position: 'absolute',
      top: perfectSize(12),
      right: perfectSize(4),
      width: perfectSize(8),
      height: perfectSize(8),
      borderRadius: perfectSize(4),
      backgroundColor: '#C70064',
    },
    headerContent: {
      alignItems: 'center',
      zIndex: 1,
    },
    flexRow: {
      flexDirection: 'row',
    },
    cover: {
      position: 'absolute',
      width: '100%',
      height: '100%',
    },
    avatar: {
      width: perfectSize(48),
      height: perfectSize(48),
      borderRadius: perfectSize(24),
      borderWidth: 1,
      borderColor: 'white',
      marginBottom: perfectSize(8),
    },
    verifiedBadge: {
      width: perfectSize(16),
      height: perfectSize(16),
      borderWidth: 1,
      borderColor: theme.colors.primary,
      marginLeft: perfectSize(4),
      borderRadius: perfectSize(8),
      justifyContent: 'center',
      alignItems: 'center',
    },
    joinBtn: {
      minWidth: perfectSize(90),
      marginTop: perfectSize(8),
      paddingVertical: perfectSize(4),
      paddingHorizontal: perfectSize(12),
      borderWidth: 1,
      borderColor: 'white',
      borderRadius: 30,
      zIndex: 999,
    },
    joinLabel: {
      fontSize: perfectSize(10),
      alignSelf: 'center',
    },
    // Content
    content: {
      marginTop: -Dimens.BODY_BORDER_TOP_RADIUS,
      paddingHorizontal: 0,
      paddingTop: perfectSize(8),
    },
    tabbar: {
      justifyContent: 'space-between',
      paddingHorizontal: Dimens.BODY_PADDING_HORIZONTAL,
    },
    tabTextStyle: {
      fontSize: perfectSize(10),
    },
    subContent: {
      flex: 1,
      backgroundColor: theme.primary_background,
      paddingHorizontal: Dimens.BODY_PADDING_HORIZONTAL,
    },
    // Updates
    newUpdateBtn: {
      flexDirection: 'row',
      height: 32,
      width: 125,
      backgroundColor: theme.primary_btn_background,
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: perfectSize(8),
      marginBottom: perfectSize(8),
    },
    updateCard: {
      backgroundColor: '#181F29',
      borderRadius: 12,
      borderBottomLeftRadius: 0,
      marginBottom: perfectSize(8),
      padding: perfectSize(8),
    },
    // Achievements
    lockedBadge: {
      position: 'absolute',
      top: 0,
      left: 0,
      backgroundColor: '#C70064',
      paddingVertical: 8,
      paddingLeft: 4,
      paddingRight: 16,
      marginTop: 4,
      borderTopRightRadius: 12,
      borderBottomRightRadius: 12,
      zIndex: 1,
    },
    sectionLabel: {
      alignSelf: 'center',
      marginTop: perfectSize(8),
      marginBottom: perfectSize(16),
    },
    // Championships
    championCard: {
      height: 200,
      borderRadius: 8,
      backgroundColor: 'gray',
      overflow: 'hidden',
      marginBottom: 16,
    },
    championCardContent: {
      width: '100%',
      height: '100%',
    },
    championTitle: {
      position: 'absolute',
      left: 16,
      bottom: 16,
      right: 16,
    },
    // About
    description: {
      color: '#7D8693',
      marginTop: 8,
    },
    // Members
    memberRequests: {
      position: 'absolute',
      top: 0,
      right: perfectSize(8),
      zIndex: 1,
    },
    requestsBadge: {
      width: perfectSize(8),
      height: perfectSize(8),
      borderRadius: perfectSize(4),
      position: 'absolute',
      top: 0,
      right: -8,
      backgroundColor: theme.primary_btn_background,
    },
    crownImg: {
      width: perfectSize(16),
      height: perfectSize(16),
      marginLeft: perfectSize(4),
    },
    memberItem: {
      alignItems: 'center',
      width: '33.33%',
      padding: perfectSize(8),
    },
    leftFAB: {
      position: 'absolute',
      bottom: perfectSize(16),
      left: Dimens.BODY_PADDING_HORIZONTAL,
      width: perfectSize(48),
      height: perfectSize(48),
      borderRadius: perfectSize(24),
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: theme.colors.primary,
    },
    rightFAB: {
      position: 'absolute',
      bottom: perfectSize(16),
      right: Dimens.BODY_PADDING_HORIZONTAL,
      width: perfectSize(48),
      height: perfectSize(48),
      borderRadius: perfectSize(24),
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: theme.colors.primary,
    },
  });

export default styles;
