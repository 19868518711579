import { GENRE_STREAM } from '../../../constants/images';

export const genres = [
    {
        name: 'General',
        icon: GENRE_STREAM
    },
    {
        name: 'Streaming',
        icon: GENRE_STREAM
    },
    {
        name: 'Games',
        icon: GENRE_STREAM
    },
    {
        name: 'Valorant',
        icon: GENRE_STREAM
    },
    {
        name: 'Rocket league',
        icon: GENRE_STREAM
    },
    {
        name: 'Tomato',
        icon: GENRE_STREAM
    },
    {
        name: 'Potato',
        icon: GENRE_STREAM
    },
    {
        name: 'Garlic',
        icon: GENRE_STREAM
    },
    {
        name: 'Pepper',
        icon: GENRE_STREAM
    },
    {
        name: 'Juice',
        icon: GENRE_STREAM
    },
    {
        name: 'Henre',
        icon: GENRE_STREAM
    },
    {
        name: 'Other',
        icon: GENRE_STREAM
    },
]