/* eslint-disable react-native/no-inline-styles */
import React, {Component} from 'react';
import {View} from 'react-native';
import {compose} from 'redux';
import {connect} from 'react-redux';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';

// import components
import Header from '../../components/header';
import Label from '../../components/label';
import TextInput from '../../components/text-input';
import ResetPassword from '../../components/buttons/normal-button';
import I18n from '../../i18n';

// import image
import {LOGO_RESET_PASSWORD} from '../../constants/images';

// import style
import styles from './styles';
import {perfectSize} from '../../helpers/deviceHelper';

// toast
import {presentToast} from '../../helpers/toastHelper';

// import actions
import {resetPasswordRequest} from '../../redux/modules/auth/actions';
import {showSpinner, hideSpinner} from '../../redux/modules/alert/actions';
import LocalImageOpacity from '../../components/local-image-opacity';

const email_regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

class ForgotpasswordPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailAddress: '',
      error: '',
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.loading !== nextProps.loading && this.props.loading) {
      this.setState({emailAddress: ''});
      this.props.hideSpinner({});
    }
  }

  _onValueChange = (val) => {
    this.setState({emailAddress: val});
  };

  isValid = () => {
    const {emailAddress} = this.state;
    if (emailAddress.length === 0) {
      presentToast({
        message: I18n.t('REGISTRATION_PAGE.error_email_not_specified'),
      });
      return false;
    }
    const email_check = email_regex.test(emailAddress);
    if (!email_check) {
      presentToast({message: I18n.t('REGISTRATION_PAGE.error_email_format')});
      return false;
    }
    return true;
  };

  _sendResetPasswordLink = () => {
    if (this.isValid()) {
      // send reset link
      const {emailAddress} = this.state;
      this.props.showSpinner({});
      this.props.showSpinner({});
      this.props.resetPasswordRequest({
        email: emailAddress,
      });
    }
  };

  render() {
    const {theme} = this.props;
    const _styles = styles(theme);
    const {emailAddress} = this.state;
    return (
      <View style={_styles.container}>
        <Header />
        <View style={_styles.wrapper}>
          <View style={{flex: 1}}>
            <View style={{flex: 1}}>
              <Label
                size="medium"
                bold
                style={{
                  letterSpacing: 2,
                }}>
                {I18n.t('FORGOT_PASSWORD_PAGE.title')}
              </Label>

              <View style={_styles.alignCenter}>
                <LocalImageOpacity
                  source={LOGO_RESET_PASSWORD}
                  resizeMethod="resize"
                  style={_styles.imgLogo}
                  resizeMode="contain"
                />
              </View>
            </View>
            <TextInput
              placeholder={I18n.t('LOGIN_PAGE.email')}
              onChangeText={(val) => this._onValueChange(val)}
              value={emailAddress}
              leftComponent={
                <MaterialIcons
                  name="email"
                  size={perfectSize(20)}
                  color={theme.primary_text}
                />
              }
              customStyles={{top: -perfectSize(26)}}
            />
          </View>
          <View style={{flex: 1, justifyContent: 'flex-end'}}>
            <ResetPassword
              text={I18n.t('FORGOT_PASSWORD_PAGE.update')}
              type="outlined"
              customStyles={_styles.btnLogin}
              textCustomStyles={{fontSize: perfectSize(15)}}
              onPress={this._sendResetPasswordLink}
            />
            <View style={_styles.actionBottom} />
          </View>
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
    loading: state.authReducer.loading,
  };
};

export default compose(
  connect(mapStateToProps, {resetPasswordRequest, showSpinner, hideSpinner}),
)(ForgotpasswordPage);
