import {createDrawerNavigator} from 'react-navigation-drawer';
import TabStack from './tabStack';
import DrawerScreen from '../../components/side-bar';
import {perfectSize} from '../../helpers/deviceHelper';
const DrawerNavigator = createDrawerNavigator(
  {
    Home: {
      screen: TabStack,
    },
  },
  {
    initialRouteName: 'Home',
    contentComponent: DrawerScreen,
    drawerPosition: 'left',
    drawerWidth: perfectSize(248),
    headerMode: 'none',
    drawerType: 'front',
    overlayColor: 'transparent',
    backBehavior: 'none',
  },
);

export default DrawerNavigator;
