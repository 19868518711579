import React, { Component } from 'react';
import { TouchableOpacity, View } from 'react-native';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Feather from 'react-native-vector-icons/Feather';
import I18n from '../../i18n';
import firebase from 'firebase/app';
import 'firebase/database';

// import components
import AuthHeader from '../../components/header-with-notification';

// import styles
import AppStyles from '../../constants/styles';
import styles from './styles';

// import actions
import { switchTheme } from '../../redux/modules/app/actions';
import { set as setHub } from '../../redux/modules/hub/actions';
import { showAlert } from '../../redux/modules/alert/actions';
import * as FirebasePaths from '../../constants/firebasePaths';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';

import MyTabBar from '../../components/custom-top-tab';
const Tab = createMaterialTopTabNavigator();

import RecentHoots from './recent';
import LiveHoots from './live';
import { useIsFocused } from '@react-navigation/native';
import PrimaryButton from '../../components/buttons/primary-button';
import { perfectSize } from '../../helpers/deviceHelper';
import navigationService from '../../navigation/navigationService';
import HootsCore from './hootsCore';
import { arrayFromSnapshot, arrayFromSnapshotWithKey } from '../../services/utils';

class HootsPage extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.focusListener$ = this.props.navigation.addListener(
            'focus',
            (payload) => {
                // Log timeline hoots view
                global.featuresLogger.HOOTS_TIMELINE_VIEW();
                this.loadGenres();
            },
        );
    }

    async loadGenres() {
        const genres = await HootsCore.loadGenres();
        const { setHub } = this.props;
        setHub({ genres: arrayFromSnapshotWithKey(genres) });
    }

    componentWillUnmount() {
        if (this.focusListener$) {
            this.focusListener$();
        }
    }

    checkSystemBanCall = async () => {
        const { uid } = this.props;
        const bannedHootsPath = FirebasePaths.FIREBASE_USERS_INFO_ATTR +
            '/' +
            uid +
            '/' +
            FirebasePaths.FIREBASE_USER_BAN +
            '/' +
            FirebasePaths.FIREBASE_USER_BAN_HOOT_CALL;
        const bannedSnap = await firebase.database().ref(bannedHootsPath).once('value');
        if (bannedSnap.val()) {
            return true;
        }
        return false;
    }

    toCreateNewHootsPage = async () => {
        const isHootCallBanned = await this.checkSystemBanCall();
        if (isHootCallBanned) {
            this.props.showAlert([
                'Wooah There!',
                I18n.t('HOOTS_PAGE.hoots_system_ban_join_create'),
                [
                    {
                        text: I18n.t('CHAMPIONSHIP_INFO_PAGE.error_loading_championship_btn_contact'),
                        onPress: () => {
                            navigationService.moveToScreenInStack('SettingsStack', 'ContactUsPage');
                        },
                    }
                ],
                true, // isRow
            ]);
        } else {
            navigationService.moveToScreenInStack('HomeStack', 'CreateNewHootPage');
        }
    }

    render() {
        const { theme, isFocused } = this.props;
        const _styles = styles(theme);
        return (
            <View style={_styles.container}>
                <AuthHeader title={I18n.t('TABS.home')} />
                <View style={_styles.content}>
                    <Tab.Navigator
                        swipeEnabled={false}
                        sceneContainerStyle={AppStyles.transparent}
                        lazy={true}
                        tabBar={(props) => (
                            <MyTabBar {...props} lang={this.props.lang}
                                onTabChanged={(index) => {
                                    if (index === 0) {
                                        // Log entering live hoots
                                        global.featuresLogger.HOOTS_TIMELINE_LIVE_CLICK();
                                    } else if (index === 1) {
                                        // Log entering recent hoots
                                        global.featuresLogger.HOOTS_TIMELINE_RECENT_CLICK();
                                    }
                                }}
                                customAction={
                                    <PrimaryButton
                                        text={I18n.t('HOOTS_PAGE.new_hoots')}
                                        size="x_small"
                                        type="filled"
                                        customStyles={_styles.postButtonStyle}
                                        textCustomStyles={_styles.postButtonTextStyle}
                                        onPress={this.toCreateNewHootsPage}
                                        leftComponent={
                                            <Feather
                                                name="plus-circle"
                                                size={perfectSize(18)}
                                                color={theme.primary_text}
                                                style={{ marginRight: perfectSize(12) }}
                                            />
                                        }
                                    />
                                } />
                        )}>
                        <Tab.Screen
                            name="Live"
                            component={LiveHoots}
                            options={{ tabBarLabel: I18n.t('HOOTS_PAGE.hoots_live') }}

                        />
                        <Tab.Screen
                            name="Recent"
                            component={RecentHoots}
                            options={{ tabBarLabel: I18n.t('HOOTS_PAGE.hoots_recent') }}
                        />
                    </Tab.Navigator>
                </View>
            </View>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        theme: state.appReducer.currentTheme,
        lang: state.appReducer.currentLang,
        toOthersRepliesMap: state.hubReducer.toOthersRepliesMap,
        toOthersRepliesArr: state.hubReducer.toOthersRepliesArr,
        othersRepliesMap: state.hubReducer.othersRepliesMap,
        othersRepliesArr: state.hubReducer.othersRepliesArr,
        uid: state.authReducer.uid,
        authUserInfo: state.authReducer.userinformationDB,
    };
};

function focusComponent(props) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const isFocused = useIsFocused();
    return <HootsPage {...props} isFocused={isFocused} />;
}

export default compose(connect(mapStateToProps, { switchTheme, setHub, showAlert }))(focusComponent);
