/* eslint-disable react-native/no-inline-styles */
import React, { Component } from 'react';
import { View, TouchableOpacity, StyleSheet, FlatList } from 'react-native';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Feather from 'react-native-vector-icons/Feather';
import _ from 'lodash';
import AppStyles from '../../constants/styles';
import * as Images from '../../constants/images';
import I18n from '../../i18n';


import NavigationService from '../../navigation/navigationService';
// import components
import Header from '../../components/header';
import TextInput from '../../components/text-input';
import Label from '../../components/label';
import PrimaryButton from '../../components/buttons/primary-button';

// import styles
import Dimens from '../../constants/dimens';
import { perfectSize } from '../../helpers/deviceHelper';

import { store } from '../../redux/store';
import { set as HubSet } from '../../redux/modules/hub/actions';
import ImageWithPlaceholder from '../../components/image-with-placeholder';

class ChooseGamePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      games: Object.values(props.games),
      displayGames: Object.values(props.games),
    };
  }

  selectGame = (item) => {
    const param = {};
    param.game = item;

    if (this.props.navigation.state.params.fromSavedRequest) {
      NavigationService.navigate('EditRequestPage', param);
    } else if (this.props.navigation.state.params.fromHome) {
      store.dispatch(
        HubSet({
          mainPageGame: item,
        }),
      );
      NavigationService.navigate('TabStack', param);
    } else if (this.props.navigation.state.params.fromSearchRequest) {
      NavigationService.navigate('SearchrequestPage', param);
    } else if (this.props.navigation.state.params.fromFindMatch) {
      NavigationService.navigate('HoplayMatchPage', param);
    } else {
      NavigationService.navigate('NewRequestPage', param);
    }
  };

  filterGames = (val) => {
    this.setState({
      displayGames: this.state.games.filter(
        (game) =>
          game?.gameName.includes(val.toLowerCase()) ||
          (game.tags !== undefined && typeof game.tags !== 'object'
            ? game.tags
              .toString()
              .toLowerCase()
              .indexOf(val.toLowerCase()) > -1
            : false),
      ),
    });
  };

  _toContactUsPage = () => {
    NavigationService.moveToScreenInStack('SettingsStack', 'ContactUsPage');
  };

  render() {
    const { theme } = this.props;
    const containerStyle = [
      styles.container,
      { backgroundColor: theme.secondary_background },
    ];
    return (
      <View style={containerStyle}>
        <Header text={I18n.t('NEW_REQUEST_PAGE.choose-game')} bold />
        {/* Content */}
        <View
          style={[{ backgroundColor: theme.primary_background }, styles.content]}>
          <TextInput
            placeholder={I18n.t('NEW_REQUEST_PAGE.search_games')}
            rightComponent={
              <Feather
                name="search"
                size={perfectSize(20)}
                color={theme.primary_text}
                style={{ opacity: 0.5 }}
              />
            }
            onChangeText={_.debounce(
              (val) => this.filterGames(val),
              1000,
            )}
          />
          <View style={styles.gamesWrap}>
            {/* <Label size="medium" bold>
              My games
            </Label> */}
            <View style={styles.label}>
              <FlatList
                data={this.state.displayGames}
                keyExtractor={(item, index) => index.toString()}
                showsVerticalScrollIndicator={false}
                ListEmptyComponent={() => {
                  return (
                    <View style={AppStyles.noGameWrap}>
                      <Label style={AppStyles.txtNoGame}>
                        {I18n.t('GAME_LIST_PAGE.no_games')}
                      </Label>
                      <PrimaryButton
                        text={I18n.t('GAME_LIST_PAGE.go_support')}
                        type="filled"
                        size="x_small"
                        customStyles={styles.btnAction}
                        onPress={this._toContactUsPage}
                      />
                    </View>
                  );
                }}
                renderItem={(item) => {
                  return (
                    <TouchableOpacity
                      key={item.index}
                      style={[
                        styles.itemContainer,
                        this.state.games.length - 1 !== item.index &&
                        styles.borderBottom,
                      ]}
                      onPress={() => this.selectGame(item.item)}>
                      <ImageWithPlaceholder
                        uri={item.item.gamePhotoUrl}
                        style={styles.itemImage}
                      />
                      <Label size="medium" style={{ marginLeft: perfectSize(8) }}>
                        {item.item?.gameName}
                      </Label>
                    </TouchableOpacity>
                  );
                }}
              />
            </View>
          </View>
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
    games: state.hubReducer.gamesList,
  };
};

export default compose(connect(mapStateToProps, {}))(ChooseGamePage);

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  // Header
  header: {
    flexDirection: 'row',
    paddingHorizontal: Dimens.HEADER_PADDING_HORIZONTAL,
    paddingVertical: Dimens.HEADER_PADDING_VERTICAL,
  },
  headerLeft: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerCenter: {
    flex: 2,
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerRight: {
    flex: 1,
    flexDirection: 'row-reverse',
    alignItems: 'center',
  },
  actionBtn: {
    color: '#C70064',
  },
  // Content
  content: {
    flex: 1,
    borderTopStartRadius: Dimens.BODY_BORDER_TOP_RADIUS,
    borderTopEndRadius: Dimens.BODY_BORDER_TOP_RADIUS,
    paddingHorizontal: Dimens.HEADER_PADDING_HORIZONTAL,
    paddingVertical: Dimens.HEADER_PADDING_VERTICAL,
  },
  gamesWrap: {
    marginTop: perfectSize(16),
    flex: 1,
  },
  label: {
    marginTop: perfectSize(16),
    flex: 1,

  },
  itemContainer: {
    height: perfectSize(60),
    flexDirection: 'row',
    alignItems: 'center',
  },
  borderBottom: {
    borderBottomWidth: 1,
    borderBottomColor: '#FFFFFF0C',
  },
  itemImage: {
    width: perfectSize(48),
    height: perfectSize(48),
    borderRadius: perfectSize(12),
  },
  btnAction: {
    paddingHorizontal: perfectSize(16),
  },
});
