import {StyleSheet} from 'react-native';
import {perfectSize} from '../../helpers/deviceHelper';

export default StyleSheet.create({
  headerRight: {
    flexDirection: 'row-reverse',
  },
  actionBtn: {
    padding: perfectSize(4),
    marginRight: perfectSize(20),
  },
  buttonTitle: {
    color: '#C70064',
  },
  wrapper: {
    flex: 1,
    marginTop: perfectSize(8),
  },
  container: {
    flex: 1,
    backgroundColor: '#0E1319',
  },
  label: {
    opacity: 0.56,
    marginTop: perfectSize(16),
  },
  mt4: {
    marginTop: perfectSize(4),
  },
  btn_tag: {
    width: perfectSize(64),
    height: perfectSize(32),
    marginRight: perfectSize(8),
  },
  description: {
    marginTop: perfectSize(4),
    marginBottom: perfectSize(16),
  },
  itemImage: {
    width: perfectSize(32),
    height: perfectSize(32),
    aspectRatio: 1,
    borderRadius: perfectSize(12),
  },
  submitBtn: {
    marginTop: perfectSize(32),
  },
  dialogContainer: {
    backgroundColor: 'white',
    borderRadius: perfectSize(8),
  },
  dialogSectionContainer: {
    borderWidth: 1,
    borderBottomColor: 'gray',
    padding: perfectSize(16),
    // alignItems: 'flex-end',
  },
  dialogLabel: {
    color: 'black',
  },
  dialogDescription: {
    fontSize: 14,
    color: '#00000080',
    marginBottom: perfectSize(16),
  },
  buttonContainer: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    padding: 16,
    height: 50,
  },
  buttonColor: {
    color: '#C70064',
  },
  button: {
    width: 80,
    alignItems: 'center',
  },
  input: {
    width: '100%',
  },
});
