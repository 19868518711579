/* eslint-disable react-native/no-inline-styles */
import React, { Component } from 'react';
import { View, TouchableOpacity, FlatList, Linking, Keyboard } from 'react-native';
import { compose } from 'redux';
import { connect } from 'react-redux';
import I18n from '../../i18n';
import Entypo from 'react-native-vector-icons/Entypo';
import Feather from 'react-native-vector-icons/Feather';
import * as ImagePicker from 'expo-image-picker';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/storage';

import * as FirebasePaths from '../../constants/firebasePaths';

// import components
import UserAvatar from '../../components/user-avatar';
import Label from '../../components/label';
import PrimaryButton from '../../components/buttons/primary-button';
import TextInput from '../../components/text-input';
import ChatMessageHolder from '../../components/chat-message-holder';
import ImageWithPlaceholder from '../../components/image-with-placeholder';
import SendImageModal from '../../components/send-image-modal';
import StickersModal from '../../components/stickers-modal';

// import styles
import styles from './styles';
import AppStyles from '../../constants/styles';
import { perfectSize } from '../../helpers/deviceHelper';

// import actions
import { switchTheme } from '../../redux/modules/app/actions';
import { set as setHub } from '../../redux/modules/hub/actions';
import {
  showAlert,
  showSpinner,
  hideSpinner,
} from '../../redux/modules/alert/actions';
import NavigationService from '../../navigation/navigationService';
import Teamcore from '../team/teamscore';
import { presentToast } from '../../helpers/toastHelper';
import CommunityCore from '../community/communitycore';
import dimens from '../../constants/dimens';

// import ad service
import AdService from '../../services/adSpot.service';
import { STICKERS_ICON } from '../../constants/images';
import { clearFastImageCache, requestCameraPermission } from '../../services/utils';
import LocalImageOpacity from '../../components/local-image-opacity';

class TeamChatPage extends Component {
  constructor(props) {
    super(props);
    const params = props.navigation.state.params;
    this.state = {
      teamId: params.TEAM_ID,
      teamLogo: params.TEAM_LOGO,
      teamName: params.TEAM_NAME,
      teamDescription: '',
      adminId: params.ADMIN_ID,
      messagesArr: [],
      messageModel: '',
      membersMap: new Map(),
      AD: null,
      sendImageModalVisible: false,
      stickersModalVisible: false,
      photoSource: null,
      photoCaption: '',
    };
  }

  componentDidMount() {
    // Log Rank HP
    global.rankLogger.LOG_NEW_HP(
      global.rankLogger.VIEW_TEAM_CHAT_PAGE,
      'VIEW_TEAM_CHAT_PAGE',
    );
    // Log the team chat page view
    global.featuresLogger.LOG_TEAMS_TEAM_CHAT_PAGE_VIEW();
    global.featuresLogger.CLAN_CHAT_AD_PAGE_VIEW();

    Teamcore.loadTeamDescription(this.state.teamId).then((snapshot) => {
      this.setState({ teamDescription: snapshot.val() });
    });

    Teamcore.loadMembers(this.state.teamId).then((shot) => {
      let membersMap = new Map();
      shot.forEach((item) => {
        let member = item.val();
        membersMap.set(member.uid, member);
      });
      // Now mapo to array
      this.setState({
        membersMap,
        membersArr: membersMap.values(),
      });
    });

    // Load the team messages
    this.loadTeamMessages();

    // Call the change status function
    this.changeChatStatus();

    // Load the AD
    this.ADRef$ = firebase.database().ref(
      FirebasePaths.FIREBASE_AD_SPOTS +
      '/' +
      FirebasePaths.FIREBASE_AD_SPOTS_CLAN_CHAT,
    );
    this.ADRef$.on('value', (snapshot) => {
      if (snapshot.val() != null) {
        this.setState({
          AD: snapshot.val(),
        });
      } else {
        this.setState({
          AD: 'NONE',
        });
      }
    });

    CommunityCore.IS_CHAT_BANNED().then((isBanned) => {
      if (isBanned) {
        // Show ban alert
        let banTitle = I18n.t('COMMUNITY_PAGE.ban_alert_title');
        let banMsg = I18n.t('COMMUNITY_PAGE.ban_alert_msg');
        let banBtn = I18n.t('COMMUNITY_PAGE.ban_alert_btn');

        // Show ban alert
        this.props.showAlert([
          banTitle,
          banMsg,
          [
            {
              text: banBtn,
              onPress: () => {
                NavigationService.moveToScreenInStack(
                  'SettingsStack',
                  'SupportPage',
                );
              },
            },
          ],
        ]);

        NavigationService.goBack();
      }
    });
  }

  componentWillUnmount() {
    if (this.teamMessagesRef$) {
      this.teamMessagesRef$.off();
      this.teamMessagesRef$ = null;
    }
    if (this.ADRef$) {
      this.ADRef$.off();
      this.ADRef$ = null;
    }
    this.props.setHub({ IS_IN_CHAT: false });
  }

  toClanPage = () => {
    NavigationService.navigate('TeamPage', {
      TEAM_ID: this.state.teamId,
    });
  };

  // This function will be used to trigger the send message btn
  messageTrigger = () => {
    if (this.state.messageModel.trim().length > 0) {
      this.sendMessage('');
    }
  };

  // This function will change the chat status to opened = true
  changeChatStatus() {
    firebase.database()
      .ref(
        FirebasePaths.FIREBASE_USERS_INFO_ATTR +
        '/' +
        this.props.uid +
        '/' +
        FirebasePaths.FIREBASE_CLANS_CHATS_USER_REF +
        '/' +
        this.state.teamId,
      )
      .child('opened')
      .set(true);
  }

  // This function will load all the messages
  loadTeamMessages() {
    let path =
      FirebasePaths.FIREBASE_TEAMS +
      '/' +
      this.state.teamId +
      '/' +
      FirebasePaths.FIREBASE_TEAM_CHAT;
    this.teamMessagesRef$ = firebase.database().ref(path).limitToLast(100);
    this.teamMessagesRef$.on('value', (packets) => {
      let messagesMap = new Map();
      packets.forEach((packet) => {
        messagesMap.set(packet.key, packet.val());
      });
      this.setState(
        {
          messagesMap,
          messagesArr: Array.from(messagesMap.values())
            .reverse()
            .filter((message) => !this.props.bannedUsers.includes(message.uid)),
        },
        () => {
          if (messagesMap.size > 0) {
            // this.flatList.scrollToIndex({ animated: true, index: 0 });
          }
        },
      );
    });
  }

  // This function will upload a message to the firebase
  sendMessage(imageURL) {
    // Create chat packet ref
    let packetId = firebase.database().ref().push().key;
    // Get the team path
    let path =
      FirebasePaths.FIREBASE_TEAMS +
      '/' +
      this.state.teamId +
      '/' +
      FirebasePaths.FIREBASE_TEAM_CHAT +
      '/' +
      packetId;
    // Create packet obj
    let packetObj = {
      id: packetId,
      message: this.state.messageModel,
      timeStamp: firebase.database.ServerValue.TIMESTAMP,
      photo: this.props.userinformationDB.pictureURL,
      uid: this.props.uid,
      username: this.props.userinformationDB.username,
      opened: false,
      admin: this.state.adminId,
      type: '',
    };
    if (imageURL !== '') {
      packetObj.type = 'image';
      packetObj.message = imageURL;
      packetObj.photoCaption = this.state.photoCaption;
    } else {
      packetObj.type = '';
    }

    // Append to ui first
    let messagesArr = this.state.messagesArr.reverse();
    messagesArr.push(packetObj);
    this.setState({ messagesArr: messagesArr.reverse() });

    // Insert the obj
    firebase.database().ref(path).set(packetObj);

    // And set the user ref
    this.sendNotifications(packetObj);

    // now clear the message model
    this.setState({ messageModel: '' });
  }

  // This function will send all the notifications to the users : will set a chat ref to the user for the clan
  // uid > _clans_chats_ > clanId > message obj
  sendNotifications(payload) {
    let packetObj = Object.assign({}, payload);
    // Add the team name & team id to the ref
    packetObj.teamId = this.state.teamId;
    packetObj.teamName = this.state.teamName;
    packetObj.teamLogo = this.state.teamLogo;

    let path = FirebasePaths.FIREBASE_USERS_INFO_ATTR;

    // Send ref to the admin
    firebase.database()
      .ref(
        path +
        '/' +
        this.state.adminId +
        '/' +
        FirebasePaths.FIREBASE_CLANS_CHATS_USER_REF +
        '/' +
        this.state.teamId,
      )
      .set(packetObj);

    this.state.membersMap.forEach((value, key) => {
      firebase.database()
        .ref(
          path +
          '/' +
          key +
          '/' +
          FirebasePaths.FIREBASE_CLANS_CHATS_USER_REF +
          '/' +
          this.state.teamId,
        )
        .set(packetObj);
    });
  }

  selectImage = async () => {
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
    })
    if (!result.cancelled) {
      this.setState({
        sendImageModalVisible: true,
        photoSource: result.uri,
      });
    }
  };

  sendImage = () => {
    global.featuresLogger.CHATS_PHOTOS();

    this.uploadImageToFirebase(this.state.photoSource);
  };

  uploadImageToFirebase(image) {
    //uploads img to firebase storage
    this.props.showSpinner({ title: I18n.t('POST_PAGE.uploading_photo') });
    this.uploadImage(image).then((photoURL) => {
      this.sendMessage(photoURL);
      this.props.hideSpinner();
      presentToast({ message: 'Image uploaded successfully' });
    });
  }

  uploadImage(imageURI) {
    let storageRef = firebase.storage().ref();
    let teamId = this.state.teamId;
    let filename = this.makeid(20) + '_' + this.props.uid;
    let imageRef = storageRef
      .child('ChatImgs')
      .child('Clans')
      .child(teamId)
      .child(filename);
    return imageRef.putString(imageURI, 'data_url').then(() => {
      return imageRef.getDownloadURL().then((downloadURL) => {
        return downloadURL;
      });
    });
  }

  makeid(length) {
    let result = '';
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  checkAdUrl = (ad) => {
    const { url } = ad;
    if (url.startsWith('http')) {
      Linking.openURL(url);
      // Linking
    } else {
      // go to page
      switch (url) {
        case 'ad':
          const { adId } = ad;
          const adRef = FirebasePaths.FIREBASE_SLIDERS + '/' + adId;
          firebase.database()
            .ref(adRef)
            .once('value', (snapshot) => {
              const val = snapshot.val();
              if (val !== null) {
                NavigationService.moveToScreenInStack('HomeStack', 'AdPage', {
                  data: val,
                });
              }
            });
          break;
        case 'championship-status':
          const { championshipId: status_championshipId, championshipTitle } = ad;
          NavigationService.moveToScreenInStack(
            'HomeStack',
            'ChampionshipStatusPage',
            {
              CHAMPIONSHIP_OBJ: {
                id: status_championshipId,
                title: championshipTitle,
              },
            },
          );
          break;
        case 'championship-info':
          const { championshipId: info_championshipId } = ad;
          const dbRef =
            FirebasePaths.FIREBASE_CHAMPIONSHIPS_REFERENCE +
            '/' +
            info_championshipId +
            '/' +
            FirebasePaths.FIREBASE_CHAMPIONSHIPS_INFO;
          firebase.database()
            .ref(dbRef)
            .once('value', (snapshot) => {
              const val = snapshot.val();
              if (val !== null) {
                NavigationService.moveToScreenInStack(
                  'HomeStack',
                  'ChampionshipInfoPage',
                  {
                    championshipObj: val,
                  },
                );
              }
            });
          break;
        case 'lobby':
          const {
            requestId: lobbyRequestId,
            platform: lobbyPlatform,
            gameId: lobbyGameId,
            region: lobbyRegion,
          } = ad;
          NavigationService.moveToScreenInStack('HomeStack', 'LobbyPage', {
            requestId: lobbyRequestId,
            platform: lobbyPlatform,
            gameId: lobbyGameId,
            region: lobbyRegion,
          });
          break;
        case 'photo-viewer':
          const { title: photoTitle, IMGS: photoIMGS } = ad;
          NavigationService.moveToScreenInStack(
            'HomeStack',
            'PhotoViewerPage',
            {
              TITLE: photoTitle,
              IMGS: photoIMGS,
            },
          );
          break;
        case 'post':
          const { POST_ID: postPOSTID } = ad;
          NavigationService.moveToScreenInStack('HomeStack', 'PostPage', {
            POST_ID: postPOSTID,
          });
          break;
        case 'team':
          const { TEAM_ID: teamTEAMID } = ad;
          NavigationService.moveToScreenInStack('HomeStack', 'TeamPage', {
            TEAM_ID: teamTEAMID,
          });
          break;
        case 'user-games-accounts':
          const { FRIEND_KEY: userFriendKey, ACTIVE_TAB: userActiveTab } = ad;
          NavigationService.moveToScreenInStack(
            'HomeStack',
            'ViewfriendprofilePage',
            {
              FRIEND_KEY: userFriendKey,
              ACTIVE_TAB: userActiveTab || 1,
            },
          );
          break;
        case 'viewfriendprofile':
          const { FRIEND_KEY: friendFriendKey, ACTIVE_TAB: friendActiveTab } = ad;
          NavigationService.moveToScreenInStack(
            'HomeStack',
            'ViewfriendprofilePage',
            {
              FRIEND_KEY: friendFriendKey,
              ACTIVE_TAB: friendActiveTab || 0,
            },
          );
          break;
        default:
          break;
      }
    }
  };

  openAD = () => {
    const { AD } = this.state;
    if (AD !== null && AD.url !== null) {
      global.featuresLogger.CLAN_CHAT_AD_CLICK();
      AdService.checkAdUrl(this.state.AD);
    }
  };

  deleteMessage = (item) => {
    let path =
      FirebasePaths.FIREBASE_TEAMS +
      '/' +
      this.state.teamId +
      '/' +
      FirebasePaths.FIREBASE_TEAM_CHAT +
      '/' +
      item.id;

    // Update ui first
    let messagesArr = this.state.messagesArr;
    messagesArr.forEach((obj) => {
      if (obj.id === item.id) {
        obj.deleted = true;
      }
    });
    this.setState({ messagesArr });

    firebase.database().ref(path).update({ deleted: true });

    if (messagesArr[0].id === item.id) {
      // The last message
      // Team admin
      firebase.database()
        .ref(
          FirebasePaths.FIREBASE_TEAMS +
          '/' +
          this.state.teamId +
          '/' +
          FirebasePaths.FIREBASE_TEAM_INFO +
          '/' +
          'admin',
        )
        .once('value')
        .then((snapshot) => {
          let teamAdmin = snapshot.val();
          let ref =
            FirebasePaths.FIREBASE_USERS_INFO_ATTR +
            '/' +
            teamAdmin +
            '/' +
            FirebasePaths.FIREBASE_CLANS_CHATS_USER_REF +
            '/' +
            this.state.teamId;
          firebase.database().ref(ref).update({ deleted: true });
        });
      // Team members
      firebase.database()
        .ref(
          FirebasePaths.FIREBASE_TEAMS +
          '/' +
          this.state.teamId +
          '/' +
          FirebasePaths.FIREBASE_TEAM_MEMBERS,
        )
        .once('value', (snapshot) => {
          snapshot.forEach((shot) => {
            let member = shot.val();
            let ref =
              FirebasePaths.FIREBASE_USERS_INFO_ATTR +
              '/' +
              member.uid +
              '/' +
              FirebasePaths.FIREBASE_CLANS_CHATS_USER_REF +
              '/' +
              this.state.teamId;
            firebase.database().ref(ref).update({ deleted: true });
          });
        });
    }
  };

  showStickersModal = () => {
    this.setState({ stickersModalVisible: true });
  };

  sendSticker = (imageURL) => {
    global.featuresLogger.CHAT_STICKER_LOGGER('CLAN', 'send');

    // Create chat packet ref
    let packetId = firebase.database().ref().push().key;
    // Get the team path
    let path =
      FirebasePaths.FIREBASE_TEAMS +
      '/' +
      this.state.teamId +
      '/' +
      FirebasePaths.FIREBASE_TEAM_CHAT +
      '/' +
      packetId;
    // Create packet obj
    let packetObj = {
      id: packetId,
      message: this.state.messageModel,
      timeStamp: firebase.database.ServerValue.TIMESTAMP,
      photo: this.props.userinformationDB.pictureURL,
      uid: this.props.uid,
      username: this.props.userinformationDB.username,
      opened: false,
      admin: this.state.adminId,
      type: 'sticker',
    };
    if (imageURL !== '') {
      packetObj.type = 'sticker';
      packetObj.message = imageURL;
      packetObj.photoCaption = this.state.photoCaption;
    } else {
      packetObj.type = '';
    }

    // Append to ui first
    let messagesArr = this.state.messagesArr.reverse();
    messagesArr.push(packetObj);
    this.setState({ messagesArr: messagesArr.reverse() });

    // Insert the obj
    firebase.database().ref(path).set(packetObj);

    // And set the user ref
    this.sendNotifications(packetObj);

    // now clear the message model
    this.setState({ messageModel: '' });
    this.setState({ stickersModalVisible: false });
    presentToast({ message: 'Sticker sent successfully !' });
    Keyboard.dismiss();
  };

  render() {
    const {
      teamLogo,
      teamName,
      teamDescription,
      messagesArr,
      messageModel,
      AD,
      sendImageModalVisible,
      stickersModalVisible,
      photoSource,
    } = this.state;
    return (
      <View style={AppStyles.rootContainer}>
        <SendImageModal
          isVisible={sendImageModalVisible}
          photo={{
            url: photoSource,
          }}
          onCancel={() => this.setState({ sendImageModalVisible: false })}
          onSend={(caption) => {
            this.setState({
              photoCaption: caption,
              sendImageModalVisible: false,
            });
            this.sendImage();
          }}
        />
        {stickersModalVisible &&
          <StickersModal
            isVisible={stickersModalVisible}
            onHide={() => {
              this.setState({ stickersModalVisible: false });
            }}
            chatType="CLAN"
            onSendSticker={this.sendSticker}
          />
        }
        {/* Header */}
        <View style={styles.header}>
          <View style={styles.headerLeft}>
            <TouchableOpacity
              style={AppStyles.leftBarButton}
              onPress={() => {
                clearFastImageCache();
                NavigationService.goBack()
              }}>
              <Feather
                name="chevron-left"
                color="gray"
                size={perfectSize(22.5)}
              />
            </TouchableOpacity>
            <TouchableOpacity onPress={() => this.toClanPage()}>
              <UserAvatar style={styles.avatar} src={teamLogo} />
            </TouchableOpacity>
            <View style={styles.wrapper}>
              <Label size="medium" bold={true}>
                {teamName}
              </Label>
              <Label size="small" style={styles.subTitle} numberOfLines={1}>
                {teamDescription}
              </Label>
            </View>
          </View>
        </View>
        {/* Content */}
        <View style={[AppStyles.rootContent, { paddingHorizontal: 0 }]}>
          <KeyboardAwareScrollView
            scrollEnabled={false}
            extraHeight={perfectSize(150)}
            contentContainerStyle={AppStyles.fullFlex}
            keyboardShouldPersistTaps="always">
            <View
              style={[
                AppStyles.fullFlex,
                { paddingHorizontal: dimens.BODY_PADDING_HORIZONTAL },
              ]}>
              {/* AD */}
              {AD != null && AD !== 'NONE' && (
                <TouchableOpacity
                  style={styles.adCard}
                  onPress={() => this.openAD()}>
                  {AD.img != null && (
                    <ImageWithPlaceholder style={styles.adCover} uri={AD.img} />
                  )}
                </TouchableOpacity>
              )}
              {/* Messages */}
              <FlatList
                inverted
                ref={(ref) => (this.flatList = ref)}
                data={messagesArr}
                renderItem={({ item, index }) => (
                  <ChatMessageHolder
                    type={item.uid === this.props.uid ? 0 : 1}
                    item={{
                      uid: item.uid,
                      type: item.type,
                      message: item.message,
                      timestamp: item.timeStamp,
                      photo: item.photo,
                      username: item.username,
                      deleted: item.deleted,
                      photoCaption: item.photoCaption,
                    }}
                    index={index}
                    onDelete={() => this.deleteMessage(item)}
                  />
                )}
                removeClippedSubviews={false}
                keyExtractor={(item, index) => index.toString()}
              />
            </View>
            {/* Send message */}
            <View style={styles.footer}>
              <View style={styles.inputBox}>
                <TextInput
                  multiline
                  customStyles={styles.textInput}
                  placeholder={I18n.t('CHAT_PAGE.type_message')}
                  value={messageModel}
                  onChangeText={(value) =>
                    this.setState({
                      messageModel: value,
                    })
                  }
                  leftComponent={
                    <TouchableOpacity
                      onPress={() => this.showStickersModal()}
                      style={AppStyles.stickersWrap}>
                      <LocalImageOpacity
                        source={STICKERS_ICON}
                        style={AppStyles.stickersIcon}
                      />
                    </TouchableOpacity>
                  }
                  rightComponent={
                    <View style={styles.flexRow}>
                      <TouchableOpacity onPress={() => this.selectImage()}>
                        <Entypo
                          name="attachment"
                          size={20}
                          color="gray"
                          style={{
                            marginLeft: perfectSize(8),
                            transform: [{ rotate: '90deg' }],
                          }}
                        />
                      </TouchableOpacity>
                    </View>
                  }
                />
              </View>
              <PrimaryButton
                text={I18n.t('SUPPORT_PAGE.send')}
                type="filled"
                onPress={() => this.messageTrigger()}
                customStyles={styles.sendBtn}
                textCustomStyles={{ fontSize: perfectSize(15) }}
              />
            </View>
          </KeyboardAwareScrollView>
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
    uid: state.authReducer.uid,
    userinformationDB: state.authReducer.userinformationDB,
    bannedUsers: state.hubReducer.bannedUsers,
  };
};

export default compose(
  connect(mapStateToProps, {
    switchTheme,
    setHub,
    showSpinner,
    hideSpinner,
    showAlert,
  }),
)(TeamChatPage);
