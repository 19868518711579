/* eslint-disable react-native/no-inline-styles */
import React, {Component} from 'react';
import {View} from 'react-native';
import {compose} from 'redux';
import {connect} from 'react-redux';
import Feather from 'react-native-vector-icons/Feather';
import firebase from 'firebase/app';
import 'firebase/database';

import I18n from '../../i18n';

// import components
import TextInput from '../../components/text-input';
import LoginButton from '../../components/buttons/normal-button';
import Header from '../../components/header';

import styles from './styles';
import {ScrollView} from 'react-native-gesture-handler';
import {perfectSize} from '../../helpers/deviceHelper';
import {presentToast} from '../../helpers/toastHelper';

import * as FirebasePaths from '../../constants/firebasePaths';
import navigationService from '../../navigation/navigationService';

class ContactUsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentLanguage: 'en',
      title: '',
      message: '',
      phonenumber: '',
    };
  }

  componentDidMount = () => {};

  _onValueChange = (type, val) => {
    this.setState({[type]: val});
  };

  _sendSupportMessage = () => {
    const {title, message, phonenumber} = this.state;
    if (title.length < 1) {
      presentToast({
        message: I18n.t('SUPPORT_PAGE.error_title'),
      });
      return false;
    }
    if (message.length < 1) {
      presentToast({
        message: I18n.t('SUPPORT_PAGE.error_message'),
      });
      return false;
    }
    const messageId = firebase.database().ref().push().key;
    let phone = phonenumber;
    if (phonenumber.length < 1) {
      phone = 'NOT_PROVIDED';
    }

    const date = new Date();
    const timestamp = date.toDateString();

    const supportObj = {
      title: title,
      message: message,
      email: this.props.userEmail,
      phone: phone,
      username: this.props.username,
      UID: this.props.uid,
      timestamp: timestamp,
    };

    // Post as a new message
    firebase.database()
      .ref(
        FirebasePaths.FIREBASE_SUPPORT_REFERENCE +
          '/' +
          FirebasePaths.FIREBASE_SUPPORT_NEW_REFERENCE +
          '/' +
          messageId,
      )
      .set(supportObj);

    // Post as old message
    firebase.database()
      .ref(
        FirebasePaths.FIREBASE_SUPPORT_REFERENCE +
          '/' +
          FirebasePaths.FIREBASE_SUPPORT_OLD_REFERENCE +
          '/' +
          messageId,
      )
      .set(supportObj);

    // Log Rank HP
    global.rankLogger.LOG_NEW_HP(
      global.rankLogger.SEND_SUPPORT,
      'SEND_SUPPORT',
    );

    // Log Feature : Support
    global.featuresLogger.LOG_FEEDBACKS_SUPPORT();

    presentToast({
      message: I18n.t('SUPPORT_PAGE.success_msg'),
    });

    setTimeout(() => {
      navigationService.goBack();
    }, 500);
  };

  render() {
    const {theme} = this.props;
    const {title, phonenumber, message} = this.state;
    const _state = this.props.navigation.state;
    return (
      <View style={[styles(theme).container]}>
        <Header
          onBack={this.toLogin}
          text="Send Message"
          // text={I18n.t('SUPPORT_PAGE.title')}
          style={[styles(theme).header]}
        />
        <View style={styles(theme).wrapper}>
          <View style={{flexDirection: 'column', flex: 1, marginVertical: 24}}>
            <ScrollView>
              <TextInput
                placeholder={I18n.t('SUPPORT_PAGE.title_field')}
                onChangeText={(val) => this._onValueChange('title', val)}
                value={title}
                leftComponent={
                  <Feather name="info" size={20} color={theme.primary_text} />
                }
              />
              <TextInput
                placeholder={I18n.t('SUPPORT_PAGE.phone')}
                onChangeText={(val) => this._onValueChange('phonenumber', val)}
                value={phonenumber}
                leftComponent={
                  <Feather name="phone" size={20} color={theme.primary_text} />
                }
                customStyles={{
                  marginVertical: perfectSize(8),
                }}
              />
              <TextInput
                placeholder={
                  _state &&
                  _state.params &&
                  _state.params.CAME_FROM === 'GAMESLIST'
                    ? I18n.t('SUPPORT_PAGE.game_placeholder')
                    : I18n.t('SUPPORT_PAGE.message')
                }
                onChangeText={(val) => this._onValueChange('message', val)}
                value={message}
                multiline={true}
                customStyles={{
                  height: perfectSize(200),
                  paddingLeft: 0,
                }}
                inputStyles={{
                  height: '100%',
                  textAlignVertical: 'top',
                }}
              />

              <LoginButton
                text={I18n.t('SUPPORT_PAGE.send')}
                type="outlined"
                customStyles={styles(theme).btnLogin}
                textCustomStyles={{fontSize: 15}}
                onPress={this._sendSupportMessage}
              />
            </ScrollView>
          </View>
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
    userEmail: state.authReducer.userinformationDB.userEmail,
    username: state.authReducer.userinformationDB.username,
    uid: state.authReducer.uid,
  };
};

export default compose(connect(mapStateToProps, {}))(ContactUsPage);
