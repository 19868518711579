import {StyleSheet} from 'react-native';
import Dimens from '../../constants/dimens';

const styles = (theme) =>
  StyleSheet.create({
    container: {
      height: Dimens.HEADER_HEIGHT,
      justifyContent: 'center',
      backgroundColor: 'transparent',
    },
    wrapper: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    sides: {
      flex: 1,
    },
    center: {
      flex: 2,
      justifyContent: 'center',
      alignItems: 'center',
    },
    title: {
      textAlign: 'center',
      color: theme.primary_text,
    },
  });

export default styles;
