import {StyleSheet} from 'react-native';
import Dimens from '../../constants/dimens';
import {perfectSize} from '../../helpers/deviceHelper';

const style = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
  },
  body: {
    flex: 1,
    borderTopStartRadius: Dimens.BODY_BORDER_TOP_RADIUS,
    borderTopEndRadius: Dimens.BODY_BORDER_TOP_RADIUS,
    paddingHorizontal: Dimens.HEADER_PADDING_HORIZONTAL,
    paddingTop: Dimens.HEADER_PADDING_VERTICAL,
  },
  wrapper: {
    flex: 1,
  },
  content: {
    flex: 1,
  },
  flatList: {
    justifyContent: 'space-between',
  },
  separator: {
    height: perfectSize(16),
  },
});

export default style;
