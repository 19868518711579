import {takeLatest, put} from 'redux-saga/effects';
// import firebase actions
import {
  showAlert,
  showAlertFinished,
  showInputAlert,
  showInputAlertFinished,
  showSpinner,
  showSpinnerFinished,
  showAppExit,
  showAppExitFinished,
  hideAppExit,
} from './actions';
import {BackHandler} from 'react-native';

// SHOW NORMAL ALERT
function* showAlertWorker({payload}) {
  const title = payload[0];
  const subtitle = payload[1];
  const buttons = payload[2];
  let isRow = payload[3];
  if (isRow === undefined) {
    isRow = true;
  }
  const customStyles = payload[4] || {};
  const customComponent = payload[5] || null;
  //   const {} = payload;
  try {
    yield put(
      showAlertFinished({
        title,
        subtitle,
        buttons,
        isRow,
        customStyles,
        customComponent
      }),
    );
  } catch (err) {}
}

// SHOW APP EXIT MODAL
function* showAppExitWorker({payload}) {
  const title = 'Do you want to exit the app ?';
  const subtitle = '';
  const buttons = [];
  buttons.push({
    text: 'Yes',
    onPress: () => {
      BackHandler.exitApp();
    },
  });

  const btnNo = {
    text: 'No',
    onPress: () => {
      // HideAppExitModal();
    },
  };
  buttons.push(btnNo);
  const isRow = true;

  try {
    yield put(
      showAppExitFinished({
        title,
        subtitle,
        buttons,
        isRow,
      }),
    );
  } catch (err) {}
}

function* HideAppExitModal() {
  yield put(hideAppExit);
}

// SHOW INPUT ALERT
function* showInputAlertWorker({payload}) {
  const inputAlertTitle = payload[0];
  const inputAlertInput = payload[1];
  const inputAlertActionButtons = payload[2];
  //   const {} = payload;
  let inputAlertIsRow = payload[3];
  if (inputAlertIsRow === undefined) {
    inputAlertIsRow = true;
  }

  const buttonCustomStyles = payload[4];

  try {
    yield put(
      showInputAlertFinished({
        inputAlertTitle,
        inputAlertInput,
        inputAlertActionButtons,
        inputAlertIsRow,
        buttonCustomStyles
      }),
    );
  } catch (err) {}
}

// SHOW NORMAL SPINNER
function* showSpinnerWorker({payload}) {
  const spinnerTitle = payload.title;
  try {
    yield put(
      showSpinnerFinished({
        spinnerTitle,
      }),
    );
  } catch (err) {}
}

export default function* () {
  yield takeLatest(showAlert, showAlertWorker);
  yield takeLatest(showInputAlert, showInputAlertWorker);
  yield takeLatest(showSpinner, showSpinnerWorker);
  yield takeLatest(showAppExit, showAppExitWorker);
}
