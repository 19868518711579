import React, { Component } from 'react';
import { View, TouchableOpacity } from 'react-native';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

import I18n from '../../i18n';

// import components
import Header from '../../components/header';
import Label from '../../components/label';
import PrimaryButton from '../../components/buttons/primary-button';
import TextInput from '../../components/text-input';

import AppStyles from '../../constants/styles';
import styles from './styles';
import * as Images from '../../constants/images';
import { perfectSize } from '../../helpers/deviceHelper';

// import actions
import { updateProfileAccounts } from '../../redux/modules/auth/actions';
import ImageWithPlaceholder from '../../components/image-with-placeholder';
import LocalImageOpacity from '../../components/local-image-opacity';
class UserGamesAccountsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedGameProvider: 'Game Provider',
      pcGameProvidersArray: [],
      pcGameProvidersAccsArray: [],

      gameProviderAcc: 'Game Provider Account',
      psnProviderAccField: I18n.t('EDIT_PROFILE_PAGE.psn_account'),
      xboxProviderAccField: I18n.t('EDIT_PROFILE_PAGE.xbox_account'),
      nintendoProviderAccField: I18n.t('EDIT_PROFILE_PAGE.nintendo_account'),
      battlenetProviderAccField: I18n.t('EDIT_PROFILE_PAGE.battlenet_account'),
      epicProviderAccField: I18n.t('EDIT_PROFILE_PAGE.epic_account'),
      originProviderAccField: I18n.t('EDIT_PROFILE_PAGE.origin_account'),
      steamProviderAccField: I18n.t('EDIT_PROFILE_PAGE.steam_account'),
      discordProviderAccField: I18n.t('EDIT_PROFILE_PAGE.discord_account'),

      psnProvider: props.userinformationDB.PSNAcc || '',
      xboxProvider: props.userinformationDB.XboxLiveAcc || '',
      nintendoProvider: props.userinformationDB.nintendoAcc || '',
      batttlenetProvider:
        props.userinformationDB.pcGamesAccs.get('Battlenet') || '',
      epicProvider: props.userinformationDB.pcGamesAccs.get('Epic Games') || '',
      originProvider: props.userinformationDB.pcGamesAccs.get('Origin') || '',
      steamProvider: props.userinformationDB.pcGamesAccs.get('Steam') || '',
      discordProvider: props.userinformationDB.pcGamesAccs.get('Discord') || '',
    };
  }

  componentDidMount = () => { };

  _onValueChange = (type, val) => {
    this.setState({ [type]: val });
  };

  _clearValue = (type) => {
    this.setState({ [type]: '' });
  };

  _saveGameProviders = () => {
    const param = {};
    param.psnAccount = this.state.psnProvider;
    param.xboxAccount = this.state.xboxProvider;
    param.nintendoAccount = this.state.nintendoProvider;
    param.epicAccount = this.state.epicProvider;
    param.steamAccount = this.state.steamProvider;
    param.originAccount = this.state.originProvider;
    param.discordAccount = this.state.discordProvider;
    param.battlenetAccount = this.state.batttlenetProvider;
    this.props.updateProfileAccounts(param);
  };

  render() {
    const { theme } = this.props;
    const _styles = styles(theme);
    return (
      <View style={AppStyles.rootContainer}>
        {/* Header */}
        <Header
          text={I18n.t('USER_GAMES_ACCOUNTS_PAGE.accounts')}
          rightComponent={
            <View style={_styles.headerRight}>
              <TouchableOpacity
                style={_styles.actionBtn}
                onPress={this._saveGameProviders}>
                <Label style={_styles.pinkLabel} size="x_small" bold={true}>
                  {I18n.t('LOBBY_PAGE.save')}
                </Label>
              </TouchableOpacity>
            </View>
          }
        />
        {/* Content */}
        <View style={[AppStyles.rootContent]}>
          <KeyboardAwareScrollView>
            <TextInput
              placeholder={this.state.psnProvider || 'none'}
              onChangeText={(val) => this._onValueChange('psnProvider', val)}
              value={this.state.psnProvider}
              leftComponent={
                <View style={_styles.flexRow}>
                  <ImageWithPlaceholder
                    uri={Images._img_ps}
                    style={_styles.itemIcon}
                    resizeMode="contain"
                  />
                  <Label style={_styles.itemLabel}>Psn: </Label>
                </View>
              }
              rightComponent={
                this.state.psnProvider ? (
                  <TouchableOpacity
                    onPress={() => this._clearValue('psnProvider')}>
                    <LocalImageOpacity
                      source={Images.CIRCLECLOSE}
                      style={[_styles.itemIcon, { margin: perfectSize(4) }]}
                      resizeMode="contain"
                    />
                  </TouchableOpacity>
                ) : (
                  <View />
                )
              }
              customStyles={{
                marginVertical: perfectSize(8),
              }}
            />

            <TextInput
              placeholder={this.state.xboxProvider || 'none'}
              onChangeText={(val) => this._onValueChange('xboxProvider', val)}
              value={this.state.xboxProvider}
              leftComponent={
                <View style={_styles.flexRow}>
                  <ImageWithPlaceholder
                    uri={Images._img_xbox}
                    style={_styles.itemIcon}
                    resizeMode="contain"
                  />
                  <Label style={_styles.itemLabel}>Xbox live: </Label>
                </View>
              }
              rightComponent={
                this.state.xboxProvider ? (
                  <TouchableOpacity
                    onPress={() => this._clearValue('xboxProvider')}>
                    <LocalImageOpacity
                      source={Images.CIRCLECLOSE}
                      style={[_styles.itemIcon, { margin: perfectSize(4) }]}
                      resizeMode="contain"
                    />
                  </TouchableOpacity>
                ) : (
                  <View />
                )
              }
              customStyles={{
                marginVertical: perfectSize(8),
              }}
            />
            <TextInput
              placeholder={this.state.nintendoProvider || 'none'}
              onChangeText={(val) =>
                this._onValueChange('nintendoProvider', val)
              }
              value={this.state.nintendoProvider}
              leftComponent={
                <View style={_styles.flexRow}>
                  <ImageWithPlaceholder
                    uri={Images._img_nintendo}
                    style={_styles.itemIcon}
                    resizeMode="contain"
                  />
                  <Label style={_styles.itemLabel}>Nintendo: </Label>
                </View>
              }
              rightComponent={
                this.state.nintendoProvider ? (
                  <TouchableOpacity
                    onPress={() => this._clearValue('nintendoProvider')}>
                    <LocalImageOpacity
                      source={Images.CIRCLECLOSE}
                      style={[_styles.itemIcon, { margin: perfectSize(4) }]}
                      resizeMode="contain"
                    />
                  </TouchableOpacity>
                ) : (
                  <View />
                )
              }
              customStyles={{
                marginVertical: perfectSize(8),
              }}
            />
            <TextInput
              placeholder={this.state.steamProvider || 'none'}
              onChangeText={(val) => this._onValueChange('steamProvider', val)}
              value={this.state.steamProvider}
              leftComponent={
                <View style={_styles.flexRow}>
                  <ImageWithPlaceholder
                    uri={Images._img_steam}
                    style={_styles.itemIcon}
                    resizeMode="contain"
                  />
                  <Label style={_styles.itemLabel}>Steam: </Label>
                </View>
              }
              rightComponent={
                this.state.steamProvider ? (
                  <TouchableOpacity
                    onPress={() => this._clearValue('steamProvider')}>
                    <LocalImageOpacity
                      source={Images.CIRCLECLOSE}
                      style={[_styles.itemIcon, { margin: perfectSize(4) }]}
                      resizeMode="contain"
                    />
                  </TouchableOpacity>
                ) : (
                  <View />
                )
              }
              customStyles={{
                marginVertical: perfectSize(8),
              }}
            />
            <TextInput
              placeholder={this.state.epicProvider || 'none'}
              onChangeText={(val) => this._onValueChange('epicProvider', val)}
              value={this.state.epicProvider}
              leftComponent={
                <View style={_styles.flexRow}>
                  <ImageWithPlaceholder
                    uri={Images._img_epic}
                    style={_styles.itemIcon}
                    resizeMode="contain"
                  />
                  <Label style={_styles.itemLabel}>Epic games: </Label>
                </View>
              }
              rightComponent={
                this.state.epicProvider ? (
                  <TouchableOpacity
                    onPress={() => this._clearValue('epicProvider')}>
                    <LocalImageOpacity
                      source={Images.CIRCLECLOSE}
                      style={[_styles.itemIcon, { margin: perfectSize(4) }]}
                      resizeMode="contain"
                    />
                  </TouchableOpacity>
                ) : (
                  <View />
                )
              }
              customStyles={{
                marginVertical: perfectSize(8),
              }}
            />
            <TextInput
              placeholder={this.state.batttlenetProvider || 'none'}
              onChangeText={(val) =>
                this._onValueChange('batttlenetProvider', val)
              }
              value={this.state.batttlenetProvider}
              leftComponent={
                <View style={_styles.flexRow}>
                  <ImageWithPlaceholder
                    uri={Images._img_battlenet}
                    style={_styles.itemIcon}
                    resizeMode="contain"
                  />
                  <Label style={_styles.itemLabel}>Battlenet: </Label>
                </View>
              }
              rightComponent={
                this.state.batttlenetProvider ? (
                  <TouchableOpacity
                    onPress={() => this._clearValue('batttlenetProvider')}>
                    <LocalImageOpacity
                      source={Images.CIRCLECLOSE}
                      style={[_styles.itemIcon, { margin: perfectSize(4) }]}
                      resizeMode="contain"
                    />
                  </TouchableOpacity>
                ) : (
                  <View />
                )
              }
              customStyles={{
                marginVertical: perfectSize(8),
              }}
            />

            <TextInput
              placeholder={this.state.originProvider || 'none'}
              onChangeText={(val) => this._onValueChange('originProvider', val)}
              value={this.state.originProvider}
              leftComponent={
                <View style={_styles.flexRow}>
                  <ImageWithPlaceholder
                    uri={Images._img_origin}
                    style={_styles.itemIcon}
                    resizeMode="contain"
                  />
                  <Label style={_styles.itemLabel}>Origin: </Label>
                </View>
              }
              rightComponent={
                this.state.originProvider ? (
                  <TouchableOpacity
                    onPress={() => this._clearValue('originProvider')}>
                    <LocalImageOpacity
                      source={Images.CIRCLECLOSE}
                      style={[_styles.itemIcon, { margin: perfectSize(4) }]}
                      resizeMode="contain"
                    />
                  </TouchableOpacity>
                ) : (
                  <View />
                )
              }
              customStyles={{
                marginVertical: perfectSize(8),
              }}
            />

            <TextInput
              placeholder={this.state.discordProvider || 'none'}
              onChangeText={(val) =>
                this._onValueChange('discordProvider', val)
              }
              value={this.state.discordProvider}
              leftComponent={
                <View style={_styles.flexRow}>
                  <ImageWithPlaceholder
                    uri={Images._img_discord}
                    style={_styles.itemIcon}
                    resizeMode="contain"
                  />
                  <Label style={_styles.itemLabel}>Discord: </Label>
                </View>
              }
              rightComponent={
                this.state.discordProvider ? (
                  <TouchableOpacity
                    onPress={() => this._clearValue('discordProvider')}>
                    <LocalImageOpacity
                      source={Images.CIRCLECLOSE}
                      style={[_styles.itemIcon, { margin: perfectSize(4) }]}
                      resizeMode="contain"
                    />
                  </TouchableOpacity>
                ) : (
                  <View />
                )
              }
              customStyles={{
                marginVertical: perfectSize(8),
              }}
            />
            <PrimaryButton
              text={I18n.t('LOBBY_PAGE.save')}
              size="medium"
              type="filled"
              customStyles={_styles.saveBtn}
              onPress={this._saveGameProviders}
            />
          </KeyboardAwareScrollView>
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
    userinformationDB: state.authReducer.userinformationDB,
  };
};

export default compose(connect(mapStateToProps, { updateProfileAccounts }))(
  UserGamesAccountsPage,
);
