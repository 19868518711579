import React, { Component } from 'react';
import { View, TouchableOpacity, FlatList, Alert, Text } from 'react-native';
import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';

import { compose } from 'redux';
import { connect } from 'react-redux';

// import styles
import styles from './styles';

// import actions
import { switchTheme } from '../../../redux/modules/app/actions';

import Spinner from '../../../components/spinner';
import { set as HubSet } from '../../../redux/modules/hub/actions';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';

import MyTabBar from '../../../components/custom-top-tab';
const Tab = createMaterialTopTabNavigator();

import MyRepliesTab from './my-replies';
import OtherRepliesTab from './other-replies';
import AppStyles from '../../../constants/styles';
import I18n from '../../../i18n';
import { useIsFocused } from '@react-navigation/native';

class RepliesPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      replyTab: 0,
    };
  }

  render() {
    const { theme } = this.props;
    const _styles = styles(theme);
      return (
        <View style={_styles.container}>
          <View style={_styles.content}>
            <View style={_styles.flexView}>
              <Tab.Navigator
                swipeEnabled={false}
                lazy={true}
                tabBar={(props) => (
                  <MyTabBar
                    {...props}
                    badges={['Feeds']}
                    lang={this.props.lang}
                  />
                )}
                sceneContainerStyle={AppStyles.transparent}>
                <Tab.Screen
                  name="OtherReplies"
                  component={OtherRepliesTab}
                  options={{ tabBarLabel: I18n.t('TIMELINE_PAGE.other_replies') }}
                />
                <Tab.Screen
                  name="MyReplies"
                  component={MyRepliesTab}
                  options={{ tabBarLabel: I18n.t('TIMELINE_PAGE.my_replies') }}
                />
              </Tab.Navigator>
            </View>
          </View>
        </View>
      );
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
    lang: state.appReducer.currentLang,
  };
};

function focusComponent(props) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const isFocused = useIsFocused();
  return <RepliesPage {...props} isFocused={isFocused} />;
}

export default compose(connect(mapStateToProps, { switchTheme, HubSet }))(
  focusComponent,
);
