import React, {memo, Component} from 'react';
import {View, TextInput} from 'react-native';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import AppStyles from '../../constants/styles';
import {perfectSize} from '../../helpers/deviceHelper';
import {FONT_FAMILY} from '../../constants/theme';

class CustomTextInput extends Component {
  componentDidMount() {
    if (this.props.onRef != null) {
      this.props.onRef(this);
    }
  }

  focus() {
    this.textInput.focus();
  }

  render() {
    const {
      theme,
      placeholder,
      onChangeText,
      leftComponent,
      rightComponent,
      customStyles,
      multiline,
      inputStyles,
      secureTextEntry,
      onEndEditing,
      autoFocus,
    } = this.props;
    const fontFamily =
      this.props.lang === 'en' ? FONT_FAMILY.ubuntu : FONT_FAMILY.cairo;
    const textAlign = this.props.lang === 'en' ? 'left' : 'right';
    return (
      <View style={[AppStyles.textInputContainer, customStyles]}>
        {leftComponent && leftComponent}
        <TextInput
          style={[
            AppStyles.textInput,
            {
              fontFamily: fontFamily,
              textAlign: textAlign,
            },
            inputStyles,
          ]}
          autoCapitalize={'none'}
          autoCompleteType={'off'}
          returnKeyType="done"
          placeholder={placeholder}
          underlineColorAndroid={'transparent'}
          placeholderTextColor={theme.primary_text}
          placeholderStyle={[
            AppStyles.textInputPlaceholder,
            // {textAlign: textAlign},
          ]}
          autoFocus={autoFocus || false}
          onChangeText={(text) => onChangeText && onChangeText(text)}
          inlineImageLeft="username"
          inlineImagePadding={perfectSize(5)}
          multiline={multiline}
          secureTextEntry={secureTextEntry}
          onBlur={this.props.onBlur}
          onEndEditing={(element) => onEndEditing && onEndEditing(element)}
          ref={(input) => (this.textInput = input)}
          {...this.props}
        />
        {rightComponent && rightComponent}
      </View>
    );
  }
}

CustomTextInput.propTypes = {
  placeholder: PropTypes.string,
  size: PropTypes.oneOf([
    'small',
    'x_small',
    'medium',
    'x_medium',
    'big',
    'x_big',
    'large',
    'x_large',
  ]),
  customStyles: PropTypes.any,
  inputStyles: PropTypes.any,
  leftComponent: PropTypes.any,
  rightComponent: PropTypes.any,
  multiline: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
    lang: state.appReducer.currentLang,
  };
};

export default memo(connect(mapStateToProps)(CustomTextInput));
