import * as FirebasePaths from '../../constants/firebasePaths';
import firebase from 'firebase/app';
import 'firebase/database';

export default class UserCosmeticsCore {
  constructor() {}

  // This function will load the available  profile  border  colors
  static loadBorderColors() {
    const path =
      FirebasePaths.FIREBASE_COSMETICS_REFERENCE +
      '/' +
      FirebasePaths.FIREBASE_COSMETICS_USER_COSMETICS +
      '/' +
      FirebasePaths.FIREBASE_COSMETICS_USER_COSMETICS_HOP_SHADOW +
      '/' +
      FirebasePaths.FIREBASE_COSMETICS_USER_COSMETICS_PROFILE_BORDER_COLORS;
    return firebase.database().ref(path).once('value');
  }
}
