/* eslint-disable react-native/no-inline-styles */
import React, { Component } from 'react';
import { View, Dimensions } from 'react-native';
import { compose } from 'redux';
import { connect } from 'react-redux';
import firebase from 'firebase/app';
import 'firebase/database';

// import Carousel, { Pagination } from 'react-native-snap-carousel';

// import components
import styles from './styles';
import dimens from '../../constants/dimens';
import { perfectSize } from '../../helpers/deviceHelper';
import Skip from '../../components/buttons/normal-button';
import Label from '../../components/label';
import I18n from '../../i18n';
import NavigationService from '../../navigation/navigationService';
import ImageWithPlaceholder from '../../components/image-with-placeholder';

const { width: viewportWidth } = Dimensions.get('window');

class UserJourneyPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      photos: [],
      descriptions: {},
      activeSlide: 0,
    };
  }

  componentDidMount() {
    let path = '';
    if (this.props.theme.mode === 'dark') {
      path = '_user_journey_/dark';
    } else {
      path = '_user_journey_/light';
    }
    let descriptionPath = '_user_journey_/descriptions';
    firebase.database()
      .ref(descriptionPath)
      .once('value', (snapshot) => {
        if (snapshot.val() != null) {
          this.setState({ descriptions: snapshot.val() });
        }
      });
    firebase.database()
      .ref(path)
      .once('value', (snapshot) => {
        if (snapshot.val() != null) {
          let photos = snapshot.val().filter((item) => item);
          this.setState({ photos });
        }
      });
  }

  skip = () => {
    // NavigationService.resetRoute('TabStack');
    NavigationService.moveToScreenInStack('HomeStack', 'GamesLibraryPage', {
      backToProfile: true,
    });
  };

  renderAdsCard = ({ item, index }) => {
    const { theme, lang } = this.props;
    const _styles = styles(theme);
    const { descriptions } = this.state;
    let description =
      lang === 'en'
        ? descriptions[index + 1 + '_EN']
        : descriptions[index + 1 + '_AR'];
    return (
      <View style={_styles.slide}>
        <ImageWithPlaceholder
          uri={item}
          style={{ width: '100%', flex: 1 }}
          resizeMode="contain"
        />
        <Label style={_styles.description}>{description}</Label>
      </View>
    );
  };

  _slideIndexForSlide(index) {
    this.setState({ activeSlide: index });
  }

  render() {
    const { theme } = this.props;
    const _styles = styles(theme);
    const { photos } = this.state;
    return (
      <View style={[_styles.container]}>
        <View style={_styles.ads}>
          {/* <Carousel
            ref={(c) => {
              this._carousel = c;
            }}
            data={photos}
            renderItem={this.renderAdsCard}
            sliderWidth={parseFloat(
              viewportWidth - dimens.HEADER_PADDING_HORIZONTAL * 2,
            )}
            itemWidth={viewportWidth - dimens.HEADER_PADDING_HORIZONTAL * 2}
            onSnapToItem={(index) => this._slideIndexForSlide(index)}
            activeSlideAlignment={'start'}
            inactiveSlideOpacity={1}
            inactiveSlideScale={1}
          />
          <Pagination
            dotsLength={photos.length}
            activeDotIndex={this.state.activeSlide}
            containerStyle={_styles.pagination}
            dotStyle={
              ([_styles.dot], { backgroundColor: theme.primary_btn_background })
            }
            inactiveDotStyle={[
              _styles.inactiveDot,
              { backgroundColor: theme.secondary_background },
            ]}
            inactiveDotScale={1}
          /> */}
        </View>
        <Skip
          text={I18n.t('NEWS_PAGE.skip')}
          type="outlined"
          customStyles={_styles.btnLogin}
          textCustomStyles={{ fontSize: perfectSize(15) }}
          onPress={() => this.skip()}
        />
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
    lang: state.appReducer.currentLang,
  };
};

export default compose(connect(mapStateToProps, {}))(UserJourneyPage);
