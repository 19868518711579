import React, { Component } from 'react';
import { View } from 'react-native';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import I18n from '../../i18n';
import AsyncStorage from '@react-native-community/async-storage';
import firebase from 'firebase/app';
import 'firebase/database';

import * as FirebasePaths from '../../constants/firebasePaths';
import { store } from '../../redux/store';
import NavigationService from '../../navigation/navigationService';
import MainMenuService from '../../navigation/mainMenuService';

// import components
import HeaderWithNotification from '../../components/header-with-notification';
import MyTabBar from '../../components/custom-top-tab';
import Requests from './requests';
import NewsPage from '../news';
import TeamsPage from '../teams';
import ChampionshipsPage from '../championships';


// import actions
import { set as setHub } from '../../redux/modules/hub/actions';
import { set as setGamemanager } from '../../redux/modules/gameManager/actions';
import { showAlert, showAppExit } from '../../redux/modules/alert/actions';

// import styles
import AppStyles from '../../constants/styles';
import styles from './styles';
import { presentToast } from '../../helpers/toastHelper';
import * as Constants from '../../constants/constants';

import Mainmenucore from '../mainmenu/mainmenucore';
import LaunchPopupCore from '../mainmenu/launchPopupCore';
import Gamemodel from '../../models/gamemodel';
import Gamemanager from '../../services/gameManager';
import MessagingService from '../../services/messaging.service';

import { removeAndroidBackButtonHandler } from '../../helpers/deviceHelper';
import Userprofilecore from '../userprofile/userprofilecore';
import topTabService from '../../navigation/topTabService';
import { clearFastImageCache } from '../../services/utils';

const Tab = createMaterialTopTabNavigator();

class MainPage extends Component {
  constructor(props) {
    super(props);
    this.mount = true;
    this.state = {
      HAS_NEW_NOTIFICATION: false,
      notificationsArr: [],
      needRefresh: false,
    };
  }

  componentDidMount() {
    const { navigation } = this.props;

    // Log : USER LOGGED IN
    global.featuresLogger.LOG_USER_LOGGED_IN();

    MessagingService.getPermission();

    // Check if the local notification is activated
    AsyncStorage.getItem(Constants.LOCAL_NOTIFICATION_KEY).then((result) => {
      if (result === 'true') {
        this.props.setHub({ IS_LOCAL_NOTIFICATION_ACTIVATED: true });
      } else if (result === 'false') {
        this.props.setHub({ IS_LOCAL_NOTIFICATION_ACTIVATED: false });
      }
    });

    // This welcome message should  only be shown
    if (this.props.isFromLogin) {
      setTimeout(() => {
        presentToast({
          message: 'Welcome ' + this.props.userinformationDB.username + ' ..❤',
          duration: 3000,
          position: 'bottom',
        });
      }, 2000);
    }

    // Load sliders imgs
    // Mainmenucore.loadSliders(); // -> news/index.js

    Mainmenucore.loadUserInfo();
    Mainmenucore.loadUserFriends();

    this.userNotificationsRef$ = Userprofilecore.loadUserNotifications();
    this.userNotificationsRef$.on('value', (snapshot) => {
      let _notificationsMap = new Map();
      let HAS_NEW_NOTIFICATION = false;
      snapshot.forEach((snap) => {
        const obj = snap.val();
        _notificationsMap.set(obj.id, obj);
        if (obj.status === 'UNREAD') {
          HAS_NEW_NOTIFICATION = true;
        }
      });
      this.props.setHub({ HAS_NEW_NOTIFICATION });
      this.setState({
        notificationsArr: Array.from(_notificationsMap.values()),
      });
    });

    // Mainmenucore.loadUserTeams().then();  // -> teams/yourClans.js
    // Mainmenucore.loadUserTeamsInvitations();  // -> teams/yourClans.js

    // Log Rank
    global.rankLogger.LOG_NEW_HP(
      global.rankLogger.VIEW_MAIN_PAGE,
      'VIEW_MAIN_PAGE',
    );

    // Load user's games
    Mainmenucore.loadUserGames().then((shot) => {
      // Access the payload : key attr inside it and get it's value
      let pcGamesWithProviders = new Map();
      let allGames = [];
      shot.forEach((item) => {
        let gameModel = new Gamemodel();

        // Parse the json and loop through it
        // Will be converted to  map to fetch the data
        // Get the gameModel of all games hub
        gameModel = store.getState().hubReducer.gamesList[item.key];
        // Fill the PC games Providers
        if (gameModel != null) {
          if (gameModel.gamePlatforms.includes('PC')) {
            pcGamesWithProviders.set(
              gameModel.gameID,
              gameModel.pcGameProvider,
            );
          }
        }
        // Then add this game to the game manager
        if (gameModel != null) {
          allGames.push(gameModel);
        }
      });
      Gamemanager.addAllGame(allGames);
      this.props.setGamemanager({
        pcGamesWithProviders,
      });
    });

    // Mainmenucore.loadTimedReqs(); // Not used

    Mainmenucore.loadRecentActivites().then((snapshot) => {
      const shot = snapshot.val();
      let recent = JSON.stringify(shot);
      let parsedJson = JSON.parse(recent);
      let recentListArr = [];
      for (var key in parsedJson) {
        if (parsedJson.hasOwnProperty(key)) {
          let recentModel = {};
          let obj = parsedJson[key];
          recentModel.gameId = obj.game_id;
          recentModel.gameType = obj.game_type;
          recentModel.matchType = obj.match_type;
          recentModel.supportedPlatforms = obj.request_platform;
          recentModel.timeStamp = obj.timeStamp;
          recentModel.key = key;
          if (recentListArr.filter((e) => e.key === key).length === 0) {
            recentListArr.push(recentModel);
          }
        }
      }
      this.props.setHub({ recentListArr });
    });

    Mainmenucore.loadBlockedUsers();
    // Mainmenucore.loadSavedReqs(); // -> newrequest/index.js
    Mainmenucore.loadPublicChatsRefs(); // -> community/requests.js
    Mainmenucore.loadPrivateChatsRefs(); // -> community/private.js
    Mainmenucore.loadClansChats(); // -> community/clans.js
    Mainmenucore.loadContentBanUsers();

    setTimeout(() => {
      this.handleNotification();
    }, 3000);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.allGamesIds.length !== nextProps.allGamesIds.length &&
      this.mount
    ) {
      this.setState({ needRefresh: !this.state.needRefresh });
    }
  }

  componentWillUnmount() {
    this.mount = false;
    if (this.userNotificationsRef$) {
      this.userNotificationsRef$.off();
      this.userNotificationsRef$ = null;
    }
    if (Mainmenucore.userInfoRef$) {
      Mainmenucore.userInfoRef$.off();
      Mainmenucore.userInfoRef$ = null;
    }
    if (Mainmenucore.ratesFromRef$) {
      Mainmenucore.ratesFromRef$.off();
      Mainmenucore.ratesFromRef$ = null;
    }
    if (Mainmenucore.ratesToRef$) {
      Mainmenucore.ratesToRef$.off();
      Mainmenucore.ratesToRef$ = null;
    }
    if (Mainmenucore.blockedUsersRef$) {
      Mainmenucore.blockedUsersRef$.off();
      Mainmenucore.blockedUsersRef$ = null;
    }
    removeAndroidBackButtonHandler();
  }

  

  handleNotification() {
    const {
      USER_CLICKED_NOTIFICATION_FOREGROUND_MODE,
      USER_PUSH_NOTIFICATION_DESIRED_PAGE,
    } = this.props;
    // Now Open the notification if it in the background mode
    if (USER_CLICKED_NOTIFICATION_FOREGROUND_MODE) {
      if (USER_PUSH_NOTIFICATION_DESIRED_PAGE === 'VIEW_FRIEND_PROFILE') {
        this.props.setHub({ USER_CLICKED_NOTIFICATION_FOREGROUND_MODE: false });
        NavigationService.navigate('ViewfriendprofilePage', {
          FRIEND_KEY: store.getState().hubReducer.PUSH_NOTIFICATION_TRIGGER1,
        });
      } else if (USER_PUSH_NOTIFICATION_DESIRED_PAGE === 'TEAMS') {
        this.props.setHub({ USER_CLICKED_NOTIFICATION_FOREGROUND_MODE: false });
        NavigationService.navigate('TabStack');
        setTimeout(() => {
          topTabService.navigate('Requests');
          setTimeout(() => {
            topTabService.navigate('Clans', {
              SELECTED_SECTION: 'TEAMS_INVITATIONS',
            });
          }, 150);
        }, 150);
      } else if (USER_PUSH_NOTIFICATION_DESIRED_PAGE === 'TEAM_REQUEST') {
        this.props.setHub({ USER_CLICKED_NOTIFICATION_FOREGROUND_MODE: false });
        NavigationService.navigate('LobbyPage', {
          CAME_FROM: 'TEAM_REQUEST',
          REQUEST_SHOT: store.getState().hubReducer.PUSH_NOTIFICATION_TRIGGER1,
        });
      } else if (USER_PUSH_NOTIFICATION_DESIRED_PAGE === 'HOP') {
        this.props.setHub({ USER_CLICKED_NOTIFICATION_FOREGROUND_MODE: false });
        NavigationService.navigate('PostPage', {
          CAME_FROM: 'NOTIFICATION',
          POST_ID: store.getState().hubReducer.PUSH_NOTIFICATION_TRIGGER1,
        });
      } else if (USER_PUSH_NOTIFICATION_DESIRED_PAGE === 'HOP_MENTION') {
        this.props.setHub({ USER_CLICKED_NOTIFICATION_FOREGROUND_MODE: false });
        NavigationService.navigate('PostPage', {
          CAME_FROM: 'NOTIFICATION',
          POST_ID: store.getState().hubReducer.PUSH_NOTIFICATION_TRIGGER1,
        });
      } else if (USER_PUSH_NOTIFICATION_DESIRED_PAGE === 'TEAM_JOINS') {
        this.props.setHub({ USER_CLICKED_NOTIFICATION_FOREGROUND_MODE: false });
        NavigationService.moveToScreenInStack(
          'HomeStack',
          'TeamJoinRequestsPage',
          {
            TEAM_ID: store.getState().hubReducer.PUSH_NOTIFICATION_TRIGGER1
              .TRIGGER,
            TEAM_NAME: store.getState().hubReducer.PUSH_NOTIFICATION_TRIGGER1
              .TRIGGER2,
            TEAM_LOGO: store.getState().hubReducer.PUSH_NOTIFICATION_TRIGGER1
              .TRIGGER3,
          },
        );
      } else if (USER_PUSH_NOTIFICATION_DESIRED_PAGE === 'CHAT') {
        this.props.setHub({ USER_CLICKED_NOTIFICATION_FOREGROUND_MODE: false });
        if (
          store.getState().hubReducer.PUSH_NOTIFICATION_TRIGGER1.CHAT_TYPE ===
          'PRIVATE'
        ) {
          NavigationService.moveToScreenInStack(
            'HomeStack',
            'PrivateChatPage',
            {
              CAME_FROM: 'NOTIFICATION',
              TRIGGER: store.getState().hubReducer.PUSH_NOTIFICATION_TRIGGER1,
            },
          );
        } else {
          NavigationService.moveToScreenInStack('HomeStack', 'ChatPage', {
            CAME_FROM: 'NOTIFICATION',
            TRIGGER: store.getState().hubReducer.PUSH_NOTIFICATION_TRIGGER1,
          });
        }
      } else if (USER_PUSH_NOTIFICATION_DESIRED_PAGE === 'EDIT_PROFILE') {
        this.props.setHub({ USER_CLICKED_NOTIFICATION_FOREGROUND_MODE: false });
        NavigationService.moveToScreenInStack(
          'SettingsStack',
          'EditProfilePage',
        );
      } else if (USER_PUSH_NOTIFICATION_DESIRED_PAGE === 'PROFILE') {
        this.props.setHub({ USER_CLICKED_NOTIFICATION_FOREGROUND_MODE: false });
        MainMenuService.navigate('Profile');
      } else if (USER_PUSH_NOTIFICATION_DESIRED_PAGE === 'TEAM_ACCEPT') {
        // Redirect the user to the team page
        NavigationService.navigate('TeamPage', {
          TEAM_ID: store.getState().hubReducer.PUSH_NOTIFICATION_TRIGGER1,
          CAME_FROM: 'TEAMS',
        });
      } else if (USER_PUSH_NOTIFICATION_DESIRED_PAGE === 'CLAN_DIRECT_JOIN') {
        // Redirect the user to the team page
        NavigationService.navigate('TeamPage', {
          TEAM_ID: store.getState().hubReducer.PUSH_NOTIFICATION_TRIGGER1,
          CAME_FROM: 'TEAMS',
        });
      } else if (USER_PUSH_NOTIFICATION_DESIRED_PAGE === 'CLAN_CHAT') {
        // First load the admin data to pass it to the team chat page
        firebase.database()
          .ref(
            FirebasePaths.FIREBASE_TEAMS +
            '/' +
            store.getState().hubReducer.PUSH_NOTIFICATION_TRIGGER1 +
            '/' +
            FirebasePaths.FIREBASE_TEAM_INFO,
          )
          .once('value')
          .then((data) => {
            let obj = data.val();
            if (obj.admin != null) {
              NavigationService.moveToScreenInStack(
                'HomeStack',
                'TeamChatPage',
                {
                  TEAM_ID: obj.id,
                  TEAM_LOGO: obj.logoUrl,
                  TEAM_NAME: obj.name,
                  ADMIN_ID: obj.admin,
                },
              );
            } else {
              this.props.showAlert([
                'شيت يا رجال',
                'فيه مشكلة في شات الكلان تواصل معنا بنحلها ',
                [
                  {
                    text: 'اضغط هنا !',
                    onPress: () => {
                      NavigationService.moveToScreenInStack(
                        'SettingsStack',
                        'SupportPage',
                      );
                    },
                  },
                ],
              ]);
            }
          });
      }
    }

    // Notifications pack
    Mainmenucore.loadActivatedNotifcationsPack().then((eshot) => {
      let activiatedGamesNotificationArr = store.getState().hubReducer
        .activiatedGamesNotificationArr;
      eshot.forEach((item) => {
        if (!activiatedGamesNotificationArr.includes(item.key)) {
          activiatedGamesNotificationArr.push(item.key);
        }
      });
      this.props.setHub({ activiatedGamesNotificationArr });
      Mainmenucore.loaddeActivatedNotifcationsPack().then((dShot) => {
        let deactiviatedGamesNotificationArr = store.getState().hubReducer
          .deactiviatedGamesNotificationArr;
        dShot.forEach((item) => {
          if (!deactiviatedGamesNotificationArr.includes(item.key)) {
            deactiviatedGamesNotificationArr.push(item.key);
          }
        });
        this.props.setHub({ deactiviatedGamesNotificationArr });
        Mainmenucore.updateTeamNotificationsTokens();
      });
    });
  }

  

  render() {
    return (
      <View style={AppStyles.rootContainer}>
        <HeaderWithNotification title={I18n.t('TABS.home')} />
        <View style={[AppStyles.rootContent, styles.content]}>

          <Tab.Navigator
            tabBar={(props) => (
              <MyTabBar
                {...props}
                badges={[]}
                lang={this.props.lang}
                onTabChanged={(index) => {
                  clearFastImageCache();
                }}
              />
            )}
            lazy={true}
            swipeEnabled={false}
            sceneContainerStyle={AppStyles.transparent}>
            <Tab.Screen
              name="Requests"
              component={Requests}
              options={{ tabBarLabel: I18n.t('MAIN_PAGE.Requests') }}
              listeners={({ navigation, route }) => ({
                tabPress: (e) => {
                },
              })}
            />
            <Tab.Screen
              name="Feeds"
              component={NewsPage}
              options={{ tabBarLabel: I18n.t('MAIN_PAGE.feeds') }}
              listeners={({ navigation, route }) => ({
                tabPress: (e) => {
                },
              })}
            />
            <Tab.Screen
              name="Clans"
              component={TeamsPage}
              options={{ tabBarLabel: I18n.t('MAIN_PAGE.clans') }}
              listeners={({ navigation, route }) => ({
                tabPress: (e) => {
                },
              })}
            />
            <Tab.Screen
              name="Championships"
              component={ChampionshipsPage}
              options={{ tabBarLabel: I18n.t('MAIN_PAGE.championships') }}
              listeners={({ navigation, route }) => ({
                tabPress: (e) => {
                  global.featuresLogger.CHAMPIONSHIPS_AD_PAGE_VIEW();
                },
              })}
            />
          </Tab.Navigator>
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
    lang: state.appReducer.currentLang,
    isFromLogin: state.hubReducer.isFromLogin,
    userinformationDB: state.authReducer.userinformationDB,
    USER_CLICKED_NOTIFICATION_FOREGROUND_MODE:
      state.hubReducer.USER_CLICKED_NOTIFICATION_FOREGROUND_MODE,
    USER_PUSH_NOTIFICATION_DESIRED_PAGE:
      state.hubReducer.USER_PUSH_NOTIFICATION_DESIRED_PAGE,
    allGamesIds: state.gameManagerReducer.allGamesIds,
  };
};

export default compose(
  connect(mapStateToProps, {
    setHub,
    setGamemanager,
    showAlert,
    showAppExit,
  }),
)(MainPage);
