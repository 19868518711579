/* eslint-disable react-native/no-inline-styles */
import React, { memo, Component } from 'react';
import { View, TouchableOpacity, StyleSheet, Text } from 'react-native';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { perfectSize } from '../../../helpers/deviceHelper';

import {
    Placeholder,
    Progressive
} from "rn-placeholder";
import SkeletonUserAvatar from '../../skeletonWrapper/skeletonUserAvatar';
import SkeletonTextContent from '../../skeletonWrapper/skeletonTextContent';

class HoplayRequestCardSmallSkeleton extends Component {
    constructor(props) {
        super(props);
        this.mount = true;
        this.state = {};
    }

    componentDidMount() { }

    componentWillUnmount = () => {
        this.mount = false;
    }

    render() {
        const { theme } = this.props;
        const styles = style(theme);

        return (
            <View style={[styles.container, this.props.customStyles]}>
                <TouchableOpacity
                    style={{}}>
                    <Placeholder
                        Animation={Progressive}
                    >
                        <View style={styles.headerWrap}>
                            <View
                                style={{ marginTop: perfectSize(79) }}
                            />
                        </View>
                        <View style={styles.userWrap}>
                            <View
                                style={[
                                    styles.flexRow,
                                    {
                                        flex: 1,
                                        marginHorizontal: perfectSize(10),
                                    },
                                ]}>
                                <SkeletonUserAvatar size={perfectSize(32)} />
                                <View style={[styles.userInfoWrap, { flex: 1, marginLeft: perfectSize(10) }]}>
                                    <SkeletonTextContent width={perfectSize(100)} height={perfectSize(20)} />
                                    <SkeletonTextContent width={perfectSize(100)} height={perfectSize(20)} style={{ marginTop: perfectSize(5) }} />
                                </View>
                            </View>
                        </View>
                        <View style={[styles.infoWrap, {
                            marginHorizontal: perfectSize(10),
                        }]}>
                            <SkeletonTextContent height={perfectSize(20)} />
                            <View style={[styles.flexRow, { marginTop: perfectSize(10), justifyContent: 'space-between' }]}>
                                <SkeletonTextContent width={45} height={20} />
                                <SkeletonTextContent width={45} height={20} style={{ marginLeft: 5 }} />
                            </View>
                            <SkeletonTextContent height={perfectSize(20)} style={{ marginTop: perfectSize(15) }} />
                        </View>
                    </Placeholder>
                </TouchableOpacity>
            </View>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        theme: state.appReducer.currentTheme,
        lang: state.appReducer.currentLang,
    };
};

export default memo(connect(mapStateToProps)(HoplayRequestCardSmallSkeleton));

const style = (theme) =>
    StyleSheet.create({
        fullSize: {
            width: '100%',
            height: '100%',
        },
        container: {
            borderRadius: perfectSize(12),
            // backgroundColor: theme.secondary_btn_background,
            overflow: 'hidden',
        },
        headerWrap: {
            width: '100%',
            height: perfectSize(79),
        },
        gradient: {
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            height: perfectSize(79),
        },
        infoWrap: {
            paddingTop: perfectSize(8),
            paddingBottom: perfectSize(10),
        },
        userWrap: {
            position: 'absolute',
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%',
            top: perfectSize(24),
        },
        imgUser: {
            resizeMode: 'stretch',
            width: perfectSize(32),
            height: perfectSize(32),
        },
        userName: {
            color: theme.primary_text,
        },
        image: {
            resizeMode: 'contain',
            width: perfectSize(16),
            height: perfectSize(19),
        },
        seperator: {
            width: perfectSize(94),
            height: perfectSize(2),
            backgroundColor: theme.request_seperator,
        },
        flexRow: {
            flexDirection: 'row',
        },
        alignCenter: {
            alignItems: 'center',
        },
        point: {
            color: theme.primary_text,
            opacity: 0.56,
            marginBottom: perfectSize(4),
        },
        imgUserGroup: {
            resizeMode: 'contain',
            width: perfectSize(17.11),
            height: perfectSize(14),
        },
        btn_tag: {
            width: perfectSize(40),
            height: perfectSize(20),
            marginRight: perfectSize(4),
            marginTop: perfectSize(5),
        },
        platformContainer: {
            marginTop: perfectSize(8),
        },
    });
