import React, {Component} from 'react';
import {View, TouchableOpacity} from 'react-native';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {createMaterialTopTabNavigator} from '@react-navigation/material-top-tabs';
import {NavigationContainer} from '@react-navigation/native';

import I18n from '../../i18n';

// import components
import Header from '../../components/header';
import CreateRequest from '../createrequest';
import SavedRequests from './saved-requests';
import Label from '../../components/label';
import MyTabBar from '../../components/custom-top-tab';
import Mainmenucore from '../mainmenu/mainmenucore';
import PrimaryButton from '../../components/buttons/primary-button';

// import styles
import {perfectSize} from '../../helpers/deviceHelper';
import AppStyles from '../../constants/styles';
import styles from './styles';

import {store} from '../../redux/store';
import {set as HubSet} from '../../redux/modules/hub/actions';
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view';

const Tab = createMaterialTopTabNavigator();

class NewRequestPage extends Component {
  savedReqs$ = null;
  createRequestCompoent = null;

  constructor(props) {
    super(props);
    this.state = {
      currentTab: 0,
      savedReqs: [],
    };
  }

  componentDidMount() {
    this.$savedReqs = Mainmenucore.loadSavedReqs();
    this.$savedReqs.on('value', (snapshot) => {
      let list = snapshot.val();
      let savedRequestsList = new Map();
      if (list) {
        Object.keys(list).forEach((key) => {
          savedRequestsList.set(key, list[key]);
        });
        store.dispatch(
          HubSet({
            savedRequestsList: savedRequestsList,
          }),
        );
        this.setState(
          {
            savedReqs: Object.values(list),
          },
          () => {},
        );
      } else {
        store.dispatch(
          HubSet({
            savedRequestsList: new Map(),
          }),
        );
        this.setState({
          savedReqs: [],
        });
      }
    });
  }

  componentWillUnmount() {
    if (this.savedReqs$) {
      this.savedReqs$.off();
      this.savedReqs$ = null;
    }
  }

  onTabChanged = (tab) => {
    this.setState({currentTab: tab});
  };

  post = () => {
    this.createRequestCompoent.requestTrigger('NEW_REQUEST');
  };

  save = () => {
    this.createRequestCompoent.requestTrigger('SAVED_REQUEST');
  };

  render() {
    const {navigation} = this.props;
    const {currentTab} = this.state;
    const {
      state: {params},
    } = navigation;

    return (
      <View style={AppStyles.rootContainer}>
        <Header
          text={I18n.t('TABS.request')}
          rightComponent={
            currentTab === 0 && (
              <View style={styles.headerRight}>
                <TouchableOpacity
                  style={styles.actionBtn}
                  onPress={() => this.post()}>
                  <Label size="x_small" bold={true} style={styles.pinkLabel}>
                    {I18n.t('NEW_REQUEST_PAGE.post_short')}
                  </Label>
                </TouchableOpacity>
                <TouchableOpacity
                  style={[styles.actionBtn, {marginRight: perfectSize(4)}]}
                  onPress={() => this.save()}>
                  <Label size="x_small">
                    {I18n.t('CREATE_REQUEST_PAGE.save_short')}
                  </Label>
                </TouchableOpacity>
              </View>
            )
          }
        />
        <View style={AppStyles.rootContent}>
          <NavigationContainer>
            <Tab.Navigator
              sceneContainerStyle={AppStyles.transparent}
              tabBar={(props) => (
                <MyTabBar
                  {...props}
                  lang={this.props.lang}
                  tabBarStyle={styles.tabBarStyle}
                  onTabChanged={(index) => {
                    this.setState({
                      currentTab: index,
                    });
                  }}
                />
              )}>
              <Tab.Screen
                name={I18n.t('NEW_REQUEST_PAGE.new_request')}
                component={() => {
                  return (
                    <KeyboardAwareScrollView
                      showsVerticalScrollIndicator={false}
                      style={styles.scroll}>
                      <CreateRequest
                        game={params && params.game}
                        onRef={(instance) => {
                          this.createRequestCompoent = instance;
                        }}
                      />
                      <PrimaryButton
                        type="filled"
                        text={I18n.t('NEW_REQUEST_PAGE.post')}
                        customStyles={styles.post}
                        textCustomStyles={{fontSize: perfectSize(15)}}
                        onPress={this.post}
                      />
                    </KeyboardAwareScrollView>
                  );
                }}
              />
              <Tab.Screen
                name={I18n.t('NEW_REQUEST_PAGE.saved_request')}
                component={() => {
                  return <SavedRequests reqs={this.state.savedReqs} />;
                }}
              />
            </Tab.Navigator>
          </NavigationContainer>
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
    lang: state.appReducer.currentLang,
  };
};

export default compose(connect(mapStateToProps, {}))(NewRequestPage);
