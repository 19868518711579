export default class Hoot {
    constructor() {
      this.id = '';
      this.title = '';
      this.genre = '';
      this.cover = '';
      this.timestamp = '';
      this.status = '';
      this.admin = '';
      this.liveParticipants = [];
      this.historyParticipants = [];
    }
  }
  