import {StyleSheet} from 'react-native';
import {perfectSize} from '../../helpers/deviceHelper';

const styles = (theme) =>
  StyleSheet.create({
    flatList: {
      marginTop: perfectSize(32),
    },
    separator: {
      height: perfectSize(16),
    },
  });

export default styles;
