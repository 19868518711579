import {StyleSheet} from 'react-native';
import {perfectSize} from '../../helpers/deviceHelper';

export default StyleSheet.create({
  // Header
  headerRight: {
    flex: 1,
    flexDirection: 'row-reverse',
    alignItems: 'center',
    paddingLeft: perfectSize(8),
  },
  // Content
  userWrap: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  flexRow: {
    flexDirection: 'row',
  },
  alignCenter: {
    alignItems: 'center',
  },
  tabView: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: perfectSize(20),
    paddingVertical: perfectSize(10),
    borderRadius: perfectSize(12),
    marginTop: perfectSize(15),
  },
  tabItem: {
    alignItems: 'center',
  },
  tabItemName: {
    opacity: 0.6,
    marginVertical: perfectSize(5),
    fontSize: perfectSize(14),
  },
  tabItemScore: {
    marginVertical: perfectSize(5),
    fontSize: perfectSize(12),
  },
  activityWrap: {
    flex: 1,
    alignItems: 'center',
    marginTop: perfectSize(8),
  },
  emptyContent: {
    marginTop: perfectSize(16),
  },
  activitiesView: {
    flex: 1,
  },
  btnAction: {
    width: perfectSize(68),
    height: perfectSize(32),
    marginHorizontal: perfectSize(5),
  },
  tooltip: {
    height: 100,
    width: 500,
    left: 0,
    // position: 'absolute',
    top: 300
  },
});
