/* eslint-disable react-native/no-inline-styles */
import React, { Component } from 'react';
import { View, TouchableOpacity, StyleSheet, Keyboard } from 'react-native';
import Modal from 'react-native-modal';
import { compose } from 'redux';
import { connect } from 'react-redux';

import Label from '../label';

import { perfectSize } from '../../helpers/deviceHelper';

class InputModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  _renderButtons = () => {
    let buttonViews = [];
    if (this.props.buttons) {
      this.props.buttons.forEach((button, index) => {
        buttonViews.push(
          <TouchableOpacity
            style={[styles.buttonStyle, this.props.buttonCustomStyles]}
            onPress={() => {
              this.props.onHide();
              button.onPress();
            }}>
            <Label style={styles.buttonText}>{button.text}</Label>
          </TouchableOpacity>,
        );
      });
    }

    return buttonViews;
  };

  render() {
    return (
      <Modal
        isVisible={this.props.isVisible}
        onBackdropPress={() => {
          if (this.props.isKeyboardOpen) {
            Keyboard.dismiss();
          } else {
            this.props.onHide();
          }
        }}
        coverScreen={true}
        animationIn={'pulse'}
        animationOut={'fadeOut'}
        style={styles.modalStyle}
        backdropOpacity={0.7}>
        <View style={styles.wrapper}>
          <View style={[styles.box, {
            borderBottomColor: this.props.theme.primary_btn_background,
            borderBottomWidth: 3
          }]}>
            <Label bold={true} style={styles.title}>
              {this.props.title}
            </Label>
            {this.props.input}
            <View
              style={[
                styles.buttonContainer,
                this.props.isRow && {
                  flexDirection: 'row',
                  alignItems: 'flex-end',
                  justifyContent: 'flex-end',
                },
              ]}>
              {this._renderButtons()}
            </View>
          </View>
        </View>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
    title: state.alertReducer.inputAlertTitle,
    input: state.alertReducer.inputAlertInput,
    buttons: state.alertReducer.inputAlertActionButtons,
    isRow: state.alertReducer.inputAlertIsRow,
    buttonCustomStyles: state.alertReducer.buttonCustomStyles,
    isKeyboardOpen: state.hubReducer.isKeyboardOpen,
  };
};

export default compose(connect(mapStateToProps, {}))(InputModal);

const styles = StyleSheet.create({
  modalStyle: {
    // marginHorizontal: 0,
    // margin: 0,
    margin: 'auto',
  },
  wrapper: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  box: {
    backgroundColor: '#181f29',
    padding: perfectSize(32),
  },
  title: {
    color: 'white',
    fontSize: perfectSize(16),
    textAlign: 'center',
    marginTop: perfectSize(16),
  },
  buttonContainer: {
    marginTop: perfectSize(32),
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonStyle: {
    marginLeft: perfectSize(8),
    borderWidth: 1,
    borderColor: '#a70559',
    borderRadius: perfectSize(10),
    height: perfectSize(42),
    justifyContent: 'center',
    paddingHorizontal: perfectSize(12),
    marginVertical: perfectSize(4),
  },
  buttonText: {
    color: '#a70559',
    fontSize: perfectSize(14),
  },
});
