import * as FirebasePaths from '../constants/firebasePaths';
import {store} from '../redux/store';
import * as Images from '../constants/images';
import AppStyles from '../constants/styles';
import firebase from 'firebase/app';
import 'firebase/database';

export default class RankLogger {
  // HP Logs Values
  JOIN_REQUEST = 2;
  REQUEST = 2;
  SAVE_REQUEST = 2;
  EDIT_REQUEST = 2;
  CHAT_MESSAGE = 1;
  UPDATE_PROFILE = 2;
  POST_HOP = 1;
  REPLY_HOP = 1;
  RATE_USERS = 1;
  ADD_FRIEND = 1;
  REPORT_REQUEST = 2;
  REPORT_HOP = 2;
  SEARCH_REQUEST = 2;
  SHARE_APP = 1;
  REGISTRATION = 50;
  LOGIN = 1;
  ACTIVATE_NOTIFICATION = 2;
  SEND_SUPPORT = 2;
  ADD_GAME = 1;
  DELETE_FRIEND = 1;
  DELETE_SAVED_REQUEST = 1;
  DELETE_REPLY = 1;
  DELETE_PRIVATE_CHAT = 1;
  EXIT_GROUP = 2;
  DELETE_GAME = 1;
  SLIDE_CLICK = 1;
  USE_HOPLAY_MATCH = 1;
  CONTACT_MATCH = 1;
  VIEW_GROUP = 1;
  VIEW_FRIEND_PROFILE = 1;
  VIEW_HOP = 1;
  VIEW_GAMES_PAGE = 1;
  VIEW_FRIENDS_PAGE = 1;
  VIEW_NOTIFICATIONS_PAGE = 1;
  VIEW_BLOCK_PAGE = 1;
  VIEW_SEARCH_PAGE = 1;
  VIEW_MAIN_PAGE = 1;
  VIEW_REQUEST_PAGE = 1;
  VIEW_EDIT_REQUEST_PAGE = 1;
  VIEW_NOTIFICATION_PAGE = 1;
  VIEW_TEAMS_PAGE = 1;
  VIEW_TEAM_NOTIFICATION_PAGE = 1;
  VIEW_TEAM_PAGE = 1;
  VIEW_ADD_MEMBER_PAGE = 1;
  VIEW_JOIN_REQUESTS_PAGE = 1;
  VIEW_JOIN_CHAMPIONSHIPS_PAGE = 1;
  VIEW_USER_ACCOUNTS_PAGE = 1;
  VIEW_TEAM_CHAT_PAGE = 1;
  SEND_TEAM_INVITATION = 1;
  ACCEPT_TEAM_INVITATION = 1;
  REJECT_TEAM_INVITATION = 1;
  UPDATE_TEAM_DESCRIPTION = 1;
  KICK_MEMBER_FROM_TEAM = 2;
  SEND_TEAM_JOIN_REQUEST = 1;
  CLAN_DIRECT_JOIN = 1;
  SEND_TEAM_FEED = 1;
  CREATE_TEAM = 2;
  ACCEPT_JOIN_REQUEST = 1;
  // News
  NEWS_PAGE_VIEW = 1;
  NEWS_CLICK = 1;

  SHARE_REQUEST = 1;
  SHARE_HOP = 1;
  SHARE_CLAN = 1;

  USER_PROFILE_BORDER_COLOR_PAGE_VIEW = 1;
  USER_PROFILE_BORDER_COLOR_UPDATE_COLOR = 1;

  USER_HOP_BORDER_COLOR_PAGE_VIEW = 1;
  USER_HOP_BORDER_COLOR_UPDATE_COLOR = 1;

  CHAMPIONSHIPS_PAGE_VIEW = 1; // DONE
  CHAMPIONSHIP_PAGE_VIEW = 1; // DONE
  CHAMPIONSHIPS_BRACKETS_PAGE_VIEW = 1; // DONE
  CHAMPIONSHIPS_COMPLETE_REGISTRATION = 2; // DONE
  CHAMPIONSHIPS_STREAMING_CLICK = 1;

  // CAP Map : activity as a key , usage as a value
  RANKS_CAP_MAP = new Map();
  RANKS_CAP_COUNTER = new Map();

  constructor() {
    // this.CHECK_BONUS_HP();
  }

  CHECK_BONUS_HP() {
    const path = '_ranks_bonus_';
    firebase.database()
      .ref(path)
      .once('value')
      .then((snapshot) => {
        if (snapshot.val()) {
          const shot = snapshot.val();
          // eslint-disable-next-line no-unused-vars
          for (let key in shot) {
            if (shot.hasOwnProperty(key)) {
              if (key === 'JOIN_REQUEST') {
                this.JOIN_REQUEST = shot[key];
              } else if (key === 'REQUEST') {
                this.REQUEST = shot[key];
              } else if (key === 'SAVE_REQUEST') {
                this.SAVE_REQUEST = shot[key];
              } else if (key === 'EDIT_REQUEST') {
                this.EDIT_REQUEST = shot[key];
              } else if (key === 'CHAT_MESSAGE') {
                this.CHAT_MESSAGE = shot[key];
              } else if (key === 'UPDATE_PROFILE') {
                this.UPDATE_PROFILE = shot[key];
              } else if (key === 'POST_HOP') {
                this.POST_HOP = shot[key];
              } else if (key === 'REPLY_HOP') {
                this.REPLY_HOP = shot[key];
              } else if (key === 'RATE_USERS') {
                this.RATE_USERS = shot[key];
              } else if (key === 'ADD_FRIEND') {
                this.ADD_FRIEND = shot[key];
              } else if (key === 'REPORT_REQUEST') {
                this.REPORT_REQUEST = shot[key];
              } else if (key === 'SEARCH_REQUEST') {
                this.SEARCH_REQUEST = shot[key];
              } else if (key === 'SHARE_APP') {
                this.SHARE_APP = shot[key];
              } else if (key === 'REGISTRATION') {
                this.REGISTRATION = shot[key];
              } else if (key === 'LOGIN') {
                this.LOGIN = shot[key];
              } else if (key === 'ACTIVATE_NOTIFICATION') {
                this.ACTIVATE_NOTIFICATION = shot[key];
              } else if (key === 'ADD_GAME') {
                this.ADD_GAME = shot[key];
              } else if (key === 'DELETE_FRIEND') {
                this.DELETE_FRIEND = shot[key];
              } else if (key === 'DELETE_SAVED_REQUEST') {
                this.DELETE_SAVED_REQUEST = shot[key];
              } else if (key === 'DELETE_REPLY') {
                this.DELETE_REPLY = shot[key];
              } else if (key === 'DELETE_PRIVATE_CHAT') {
                this.DELETE_PRIVATE_CHAT = shot[key];
              } else if (key === 'EXIT_GROUP') {
                this.EXIT_GROUP = shot[key];
              } else if (key === 'DELETE_GAME') {
                this.DELETE_GAME = shot[key];
              } else if (key === 'SLIDE_CLICK') {
                this.SLIDE_CLICK = shot[key];
              } else if (key === 'USE_HOPLAY_MATCH') {
                this.USE_HOPLAY_MATCH = shot[key];
              } else if (key === 'CONTACT_MATCH') {
                this.CONTACT_MATCH = shot[key];
              } else if (key === 'VIEW_GROUP') {
                this.VIEW_GROUP = shot[key];
              } else if (key === 'VIEW_FRIEND_PROFILE') {
                this.VIEW_FRIEND_PROFILE = shot[key];
              } else if (key === 'VIEW_HOP') {
                this.VIEW_HOP = shot[key];
              } else if (key === 'VIEW_GAMES_PAGE') {
                this.VIEW_GAMES_PAGE = shot[key];
              } else if (key === 'VIEW_FRIENDS_PAGE') {
                this.VIEW_FRIENDS_PAGE = shot[key];
              } else if (key === 'VIEW_NOTIFICATIONS_PAGE') {
                this.VIEW_NOTIFICATIONS_PAGE = shot[key];
              } else if (key === 'VIEW_BLOCK_PAGE') {
                this.VIEW_BLOCK_PAGE = shot[key];
              } else if (key === 'VIEW_SEARCH_PAGE') {
                this.VIEW_SEARCH_PAGE = shot[key];
              } else if (key === 'VIEW_MAIN_PAGE') {
                this.VIEW_MAIN_PAGE = shot[key];
              } else if (key === 'VIEW_REQUEST_PAGE') {
                this.VIEW_REQUEST_PAGE = shot[key];
              } else if (key === 'VIEW_EDIT_REQUEST_PAGE') {
                this.VIEW_EDIT_REQUEST_PAGE = shot[key];
              } else if (key === 'VIEW_NOTIFICATION_PAGE') {
                this.VIEW_NOTIFICATION_PAGE = shot[key];
              } else if (key === 'VIEW_TEAMS_PAGE') {
                this.VIEW_TEAMS_PAGE = shot[key];
              } else if (key === 'VIEW_TEAM_NOTIFICATION_PAGE') {
                this.VIEW_TEAM_NOTIFICATION_PAGE = shot[key];
              } else if (key === 'VIEW_TEAM_PAGE') {
                this.VIEW_TEAM_PAGE = shot[key];
              } else if (key === 'VIEW_ADD_MEMBER_PAGE') {
                this.VIEW_ADD_MEMBER_PAGE = shot[key];
              } else if (key === 'VIEW_JOIN_REQUESTS_PAGE') {
                this.VIEW_JOIN_REQUESTS_PAGE = shot[key];
              } else if (key === 'VIEW_JOIN_CHAMPIONSHIPS_PAGE') {
                this.VIEW_JOIN_CHAMPIONSHIPS_PAGE = shot[key];
              } else if (key === 'VIEW_USER_ACCOUNTS_PAGE') {
                this.VIEW_USER_ACCOUNTS_PAGE = shot[key];
              } else if (key === 'VIEW_TEAM_CHAT_PAGE') {
                this.VIEW_TEAM_CHAT_PAGE = shot[key];
              } else if (key === 'SEND_TEAM_INVITATION') {
                this.SEND_TEAM_INVITATION = shot[key];
              } else if (key === 'ACCEPT_TEAM_INVITATION') {
                this.ACCEPT_TEAM_INVITATION = shot[key];
              } else if (key === 'REJECT_TEAM_INVITATION') {
                this.REJECT_TEAM_INVITATION = shot[key];
              } else if (key === 'UPDATE_TEAM_DESCRIPTION') {
                this.UPDATE_TEAM_DESCRIPTION = shot[key];
              } else if (key === 'KICK_MEMBER_FROM_TEAM') {
                this.KICK_MEMBER_FROM_TEAM = shot[key];
              } else if (key === 'SEND_TEAM_JOIN_REQUEST') {
                this.SEND_TEAM_JOIN_REQUEST = shot[key];
              } else if (key === 'SEND_TEAM_FEED') {
                this.SEND_TEAM_FEED = shot[key];
              } else if (key === 'CREATE_TEAM') {
                this.CREATE_TEAM = shot[key];
              } else if (key === 'ACCEPT_JOIN_REQUEST') {
                this.ACCEPT_JOIN_REQUEST = shot[key];
              } else if (key === 'NEWS_PAGE_VIEW') {
                this.NEWS_PAGE_VIEW = shot[key];
              } else if (key === 'NEWS_CLICK') {
                this.NEWS_CLICK = shot[key];
              } else if (key === 'SHARE_CLAN') {
                this.SHARE_CLAN = shot[key];
              } else if (key === 'SHARE_HOP') {
                this.SHARE_HOP = shot[key];
              } else if (key === 'SHARE_REQUEST') {
                this.SHARE_REQUEST = shot[key];
              } else if (key === 'USER_PROFILE_BORDER_COLOR_PAGE_VIEW') {
                this.USER_PROFILE_BORDER_COLOR_PAGE_VIEW = shot[key];
              } else if (key === 'USER_PROFILE_BORDER_COLOR_UPDATE_COLOR') {
                this.USER_PROFILE_BORDER_COLOR_UPDATE_COLOR = shot[key];
              } else if (key === 'USER_HOP_BORDER_COLOR_PAGE_VIEW') {
                this.USER_HOP_BORDER_COLOR_PAGE_VIEW = shot[key];
              } else if (key === 'USER_HOP_BORDER_COLOR_UPDATE_COLOR') {
                this.USER_HOP_BORDER_COLOR_UPDATE_COLOR = shot[key];
              } else if (key === 'CLAN_DIRECT_JOIN') {
                this.CLAN_DIRECT_JOIN = shot[key];
              } else if (key === 'CHAMPIONSHIPS_PAGE_VIEW') {
                this.CHAMPIONSHIPS_PAGE_VIEW = shot[key];
              } else if (key === 'CHAMPIONSHIP_PAGE_VIEW') {
                this.CHAMPIONSHIP_PAGE_VIEW = shot[key];
              } else if (key === 'CHAMPIONSHIPS_BRACKETS_PAGE_VIEW') {
                this.CHAMPIONSHIPS_BRACKETS_PAGE_VIEW = shot[key];
              } else if (key === 'CHAMPIONSHIPS_COMPLETE_REGISTRATION') {
                this.CHAMPIONSHIPS_COMPLETE_REGISTRATION = shot[key];
              } else if (key === 'CHAMPIONSHIPS_STREAMING_CLICK') {
                this.CHAMPIONSHIPS_STREAMING_CLICK = shot[key];
              }
            }
          }
        }
      });

    // Check the bonus HP Attrs
    this.loadActionsCaps();
  }

  // This function will load actions caps
  loadActionsCaps() {
    let path = '_ranks_caps_';
    firebase.database()
      .ref(path)
      .once('value')
      .then((snapshot) => {
        if (snapshot.val()) {
          const shot = snapshot.val();
          // eslint-disable-next-line no-unused-vars
          for (let key in shot) {
            if (shot.hasOwnProperty(key)) {
              this.RANKS_CAP_MAP.set(key, shot[key]);
            }
          }
        }
      });
  }

  LOG_NEW_HP(hp, ACTION_NAME) {
    const uid = store.getState().authReducer.uid;
    if (hp != null) {
      //    Log action counter : but first check if the number of this action name exceeds the cap
      if (this.RANKS_CAP_COUNTER.has(ACTION_NAME)) {
        if (
          this.RANKS_CAP_COUNTER.get(ACTION_NAME) <
          this.RANKS_CAP_MAP.get(ACTION_NAME)
        ) {
          // Increase rank and log the cap
          let newRank = 0;
          // Get the current rank first
          let ref =
            FirebasePaths.FIREBASE_USERS_INFO_ATTR +
            '/' +
            uid +
            '/' +
            FirebasePaths.FIREBASE_DETAILS_ATTR +
            '/' +
            FirebasePaths.FIREBASAE_USER_RANK_ATTR;
          firebase.database()
            .ref(ref)
            .once('value')
            .then((currentRank) => {
              if (currentRank.val() != null) {
                newRank = currentRank.val() + hp;
              }
              firebase.database().ref(ref).set(newRank);

              // Log the user activity
              let date = new Date();
              let DATE = date.toDateString();
              let TIME = date.toLocaleTimeString();
              firebase.database()
                .ref(
                  FirebasePaths.FIREBASE_USERS_INFO_ATTR +
                    '/' +
                    uid +
                    '/' +
                    FirebasePaths.FIREBASE_USER_RANK_ACTIVITIES +
                    '/' +
                    DATE +
                    '/' +
                    TIME,
                )
                .set(ACTION_NAME + ':' + hp);
            });

          // Log action name to the cap counter
          let counter = this.RANKS_CAP_COUNTER.get(ACTION_NAME) + 1;
          this.RANKS_CAP_COUNTER.set(ACTION_NAME, counter);
        } else {
          return;
        }
      } else {
        // Increase rank and log the cap
        let newRank = 0;
        // Get the current rank first
        let ref =
          FirebasePaths.FIREBASE_USERS_INFO_ATTR +
          '/' +
          uid +
          '/' +
          FirebasePaths.FIREBASE_DETAILS_ATTR +
          '/' +
          FirebasePaths.FIREBASAE_USER_RANK_ATTR;

        firebase.database()
          .ref(ref)
          .once('value')
          .then((currentRank) => {
            if (currentRank.val() != null) {
              newRank = currentRank.val() + hp;
            }
            firebase.database().ref(ref).set(newRank);

            // Log the user activity
            let date = new Date();
            let DATE = date.toDateString();
            let TIME = date.toLocaleTimeString();
            firebase.database()
              .ref(
                FirebasePaths.FIREBASE_USERS_INFO_ATTR +
                  '/' +
                  uid +
                  '/' +
                  FirebasePaths.FIREBASE_USER_RANK_ACTIVITIES +
                  '/' +
                  DATE,
              )
              .child(TIME)
              .set(ACTION_NAME + ':' + hp);
          });

        // Log action name to the cap counter
        this.RANKS_CAP_COUNTER.set(ACTION_NAME, 1);
      }
    }
  }

  // This function will get the hp and return the rank img
  getRankImg(hp) {
    if (hp == null) {
      return;
    }

    if (hp < 150) {
      // Noob
      return 'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/ranks%2FNoob.png?alt=media&token=4027ea73-0663-4368-ab2c-a9dd35ad7a49';
    } else if (hp >= 150 && hp < 350) {
      // AFK
      return 'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/ranks%2FAFK.png?alt=media&token=7ec253f6-4217-417d-8cc1-dda9be248b82';
    } else if (hp >= 350 && hp < 600) {
      // BOT
      return 'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/ranks%2FBot.png?alt=media&token=d002b58a-36fd-4827-a368-7f1aa08c6335';
    } else if (hp >= 600 && hp < 900) {
      // DUCK
      return 'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/ranks%2FDuck.png?alt=media&token=9b0baf1b-6d59-497b-9e90-63213eb4113e';
    } else if (hp >= 900 && hp < 1300) {
      // DUCK LEADER
      return 'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/ranks%2FDuck%20Leader.png?alt=media&token=22ce939a-5f5d-4576-a195-b987028b60b3';
    } else if (hp >= 1300 && hp < 1700) {
      // BRONZE I
      return 'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/ranks%2FBronze%20I.png?alt=media&token=59ec90b5-9448-4601-ab0a-8a63026c0d60';
    } else if (hp >= 1700 && hp < 2100) {
      // BRONZE II
      return 'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/ranks%2FBronze%20II.png?alt=media&token=c90e564d-f263-4a55-bdd9-ae40e2d505cb';
    } else if (hp >= 2100 && hp < 2600) {
      // BRONZE III
      return 'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/ranks%2FBronze%20III.png?alt=media&token=01f4e9bc-2b8b-4df2-a5ba-bea1465b5a5b';
    } else if (hp >= 2600 && hp < 3100) {
      // SILVER I
      return 'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/ranks%2FSilver%20I.png?alt=media&token=0b21290b-40a5-422f-9b61-6246f96a4d19';
    } else if (hp >= 3100 && hp < 3600) {
      // SILVER II
      return 'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/ranks%2FSilver%20II.png?alt=media&token=50b9d1b6-da5c-4c7e-a99a-0b02e57c4e49';
    } else if (hp >= 3600 && hp < 4200) {
      // SILVER III
      return 'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/ranks%2FSilver%20III.png?alt=media&token=3bb47619-c33b-4fd2-b75f-54cb1e3f5d64';
    } else if (hp >= 4200 && hp < 4800) {
      // GOLD I
      return 'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/ranks%2FGold%20I.png?alt=media&token=682266b1-9b7f-4af2-a457-c03cceea262a';
    } else if (hp >= 4800 && hp < 5400) {
      // GOLD II
      return 'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/ranks%2FGold%20II.png?alt=media&token=9d83856a-425c-4989-914d-a5b242097a98';
    } else if (hp >= 5400 && hp < 6100) {
      // GOLD III
      return 'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/ranks%2FGold%20III.png?alt=media&token=b838aac2-2efc-4d3e-ac40-8c516f06752c';
    } else if (hp >= 6100 && hp < 6800) {
      // PLATINUM I
      return 'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/ranks%2FPlatinum%20I.png?alt=media&token=ed44837f-7c77-4d50-b107-91769fce9ead';
    } else if (hp >= 6800 && hp < 7500) {
      // PLATINUM II
      return 'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/ranks%2FPlatinum%20II.png?alt=media&token=57d121f7-009e-41ba-8928-210ad60e7680';
    } else if (hp >= 7500 && hp < 8300) {
      // PLATINUM III
      return 'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/ranks%2FPlatinum%20III.png?alt=media&token=65375e3f-8d24-4c23-9188-4381b2ce35a9';
    } else if (hp >= 8300 && hp < 9100) {
      // DIAMONMD I
      return 'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/ranks%2FDiamond%20I.png?alt=media&token=1bff74bb-4279-4544-a55b-64d4536c4f82';
    } else if (hp >= 9100 && hp < 9900) {
      // DIAMOND II
      return 'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/ranks%2FDiamond%20II.png?alt=media&token=d3c75e8c-f79d-4781-92e5-f75db6cac7bb';
    } else if (hp >= 9900 && hp < 10800) {
      // DIAMOND III
      return 'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/ranks%2FDiamond%20III.png?alt=media&token=be84d09c-0272-4eed-94e5-d9a3edf88f49';
    } else if (hp >= 10800 && hp < 11700) {
      // MASTER
      return 'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/ranks%2FMaster.png?alt=media&token=e93a5560-eba3-4b28-85a3-ada4810b8093';
    } else if (hp >= 11700 && hp < 12600) {
      // GRAND MASTER
      return 'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/ranks%2FGrand%20Master.png?alt=media&token=5941fa84-eb5a-45ca-8d84-95091a11b90e';
    } else if (hp >= 12600 && hp < 15000) {
      // CHALLENGER
      return 'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/ranks%2FChallenger.png?alt=media&token=395fe1b5-1bf9-4cfa-88f6-b337d84b4cb1';
    } else if (hp >= 15000 && hp < 30000) {
      // HOPRO
      return 'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/ranks%2FHoPro.png?alt=media&token=7a614c6e-657d-4d38-bea2-aab491cd535d';
    } else if (hp >= 30000 && hp < 60000) {
      return Images._IMG_SWEATY;
    } else if (hp >= 60000 && hp < 120000) {
      return Images._IMG_CLUTCHER;
    } else if (hp >= 120000 && hp < 240000) {
      return Images._IMG_SMURF;
    } else if (hp >= 240000 && hp < 500000) {
      return Images._IMG_LEGEND;
    } else if (hp >= 500000 && hp < 1000000) {
      return Images._IMG_MYTHICAL;
    } else if (hp >= 1000000) {
      return Images._IMG_IMMORTAL;
    }
  }

  getRankStyle(hp) {
    if (hp < 120000) {
      return AppStyles.rankImg; // normal style
    } else if (hp >= 120000 && hp < 240000) {
      return AppStyles.portraitRankImg;
    } else if (hp >= 240000 && hp < 500000) {
      return AppStyles.rankImg; // normal style
    } else if (hp > 500000) {
      return AppStyles.biggestRankImg;
    }
  }

  getRankStyleForHoot(hp) {
    if (hp < 120000) {
      return AppStyles.rankImgHoot; // normal style
    } else if (hp >= 120000 && hp < 240000) {
      return AppStyles.portraitRankImgHoot;
    } else if (hp >= 240000 && hp < 500000) {
      return AppStyles.rankImgHoot; // normal style
    } else if (hp > 500000) {
      return AppStyles.biggestRankImgHoot;
    }
  }

  getRankStyleForHootDetail(hp) {
    if (hp < 120000) {
      return AppStyles.rankImgHootDetail; // normal style
    } else if (hp >= 120000 && hp < 240000) {
      return AppStyles.portraitRankImgHootDetail;
    } else if (hp >= 240000 && hp < 500000) {
      return AppStyles.rankImgHootDetail; // normal style
    } else if (hp > 500000) {
      return AppStyles.biggestRankImgHootDetail;
    }
  }

  // Get the rank name
  getUserRankName(hp) {
    if (hp < 150) {
      // Noob
      return 'نووب';
    } else if (hp >= 150 && hp < 350) {
      // AFK
      return 'AFK';
    } else if (hp >= 350 && hp < 600) {
      // BOT
      return 'بوت';
    } else if (hp >= 600 && hp < 900) {
      // DUCK
      return 'بطه';
    } else if (hp >= 900 && hp < 1300) {
      // DUCK LEADER
      return 'قائد البطات';
    } else if (hp >= 1300 && hp < 1700) {
      // BRONZE I
      return 'برونز';
    } else if (hp >= 1700 && hp < 2100) {
      // BRONZE II
      return 'برونز II';
    } else if (hp >= 2100 && hp < 2600) {
      // BRONZE III
      return 'برونز III';
    } else if (hp >= 2600 && hp < 3100) {
      // SILVER I
      return 'سلڤر';
    } else if (hp >= 3100 && hp < 3600) {
      // SILVER II
      return 'II سلڤر';
    } else if (hp >= 3600 && hp < 4200) {
      // SILVER III
      return 'III سلڤر';
    } else if (hp >= 4200 && hp < 4800) {
      // GOLD I
      return 'قولد';
    } else if (hp >= 4800 && hp < 5400) {
      // GOLD II
      return 'II قولد';
    } else if (hp >= 5400 && hp < 6100) {
      // GOLD III
      return 'III قولد';
    } else if (hp >= 6100 && hp < 6800) {
      // PLATINUM I
      return 'بلاتينيوم';
    } else if (hp >= 6800 && hp < 7500) {
      // PLATINUM II
      return 'II بلاتينيوم';
    } else if (hp >= 7500 && hp < 8300) {
      // PLATINUM III
      return 'III بلاتينيوم';
    } else if (hp >= 8300 && hp < 9100) {
      // DIAMONMD I
      return 'دايموند';
    } else if (hp >= 9100 && hp < 9900) {
      // DIAMOND II
      return ' II دايموند';
    } else if (hp >= 9900 && hp < 10800) {
      // DIAMOND III
      return ' III دايموند';
    } else if (hp >= 10800 && hp < 11700) {
      // MASTER
      return 'ماستر';
    } else if (hp >= 11700 && hp < 12600) {
      // GRAND MASTER
      return 'قراند ماستر';
    } else if (hp >= 12600 && hp < 15000) {
      // CHALLENGER
      return 'تشالنجر';
    } else if (hp >= 15000) {
      // HOPRO
      return 'هوبرو';
    }
  }
}

if (!global.rankLogger) {
  global.rankLogger = new RankLogger();
}
