/* eslint-disable react-native/no-inline-styles */
import React, { Component } from 'react';
import { View, StyleSheet } from 'react-native';
import Modal from 'react-native-modal';
import { MaterialIndicator } from 'react-native-indicators';
import { compose } from 'redux';
import { connect } from 'react-redux';

import Label from '../label';
import { perfectSize } from '../../helpers/deviceHelper';

class CustomSpinner extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { visible, textContent } = this.props;
    return (
      <Modal
        isVisible={visible}
        onBackdropPress={() => { }}
        coverScreen={true}
        animationIn={'pulse'}
        animationOut={'fadeOut'}
        style={styles.modalStyle}
        backdropOpacity={0.7}>
        <View
          style={[
            styles.box,
            {
              // backgroundColor: textContent ? '#181f29' : 'transparent',
              backgroundColor: '#181f29',
              minWidth: textContent ? '80%' : '60%',
              borderBottomColor: this.props.theme.primary_btn_background,
              borderBottomWidth: 3
            },
          ]}>
          <View style={{ width: perfectSize(35) }}>
            <MaterialIndicator color="#C70064" size={perfectSize(35)} />
          </View>
          {textContent !== '' && textContent != null && (
            <Label style={styles.title}>{textContent}</Label>
          )}
        </View>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
    title: state.alertReducer.title,
  };
};

export default compose(connect(mapStateToProps, {}))(CustomSpinner);

const styles = StyleSheet.create({
  modalStyle: {
    // margin: 0,
    margin: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: 624,
    minWidth: 218,
    width: 'auto'
  },
  box: {
    padding: perfectSize(32),
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  title: {
    color: 'white',
    fontSize: perfectSize(16),
    textAlign: 'left',
    marginLeft: perfectSize(16),
  },
});
