import React from 'react';
import {
  View,
  StyleSheet,
  TouchableOpacity,
  Dimensions,
  Image,
  Animated,
} from 'react-native';
import {compose} from 'redux';
import {connect} from 'react-redux';
import I18n from '../../i18n';

import Label from '../label';
import {perfectSize} from '../../helpers/deviceHelper';
import MainMenuService from '../../navigation/mainMenuService';

import {
  BOTTOM_TAB_HOME,
  BOTTOM_TAB_HOPS,
  BOTTOM_TAB_HOOTS,
  BOTTOM_TAB_CHATS,
  BOTTOM_TAB_PROFILE,
} from '../../constants/images';

import {FONT_FAMILY} from '../../constants/theme';

const {width: screenWidth} = Dimensions.get('window');
const AnimatedIcon = Animated.createAnimatedComponent(Image);

const showHoots = true;

const items = [
  { icon: BOTTOM_TAB_HOME, label: I18n.t('TABS.home'), color: '#553DB0' },
  { icon: BOTTOM_TAB_HOPS, label: I18n.t('TABS.hops'), color: '#BA4699' },
  { icon: BOTTOM_TAB_HOOTS, label: I18n.t('TABS.hoots'), color: '#DDAA40' },
  { icon: BOTTOM_TAB_CHATS, label: I18n.t('TABS.chats'), color: '#DDAA40' },
  { icon: BOTTOM_TAB_PROFILE, label: I18n.t('TABS.profile'), color: '#4392A5' },
];

const styles = StyleSheet.create({
  wrapperContainer: {
    backgroundColor: '#0E1319',
  },
  mainContainer: {
    width: '100%',
    height: 64,
    paddingHorizontal: perfectSize(40),
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#181F29',
    borderTopLeftRadius: 32,
    borderTopRightRadius: 32,
  },
  tabButton: {
    minWidth: perfectSize(18),
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    alignSelf: 'center',
  },
  wrapper: {
    flexDirection: 'row',
  },
  icon: {
    width: perfectSize(20),
    height: perfectSize(20),
    resizeMode: 'contain',
  },
  text: {
    fontSize: perfectSize(16),
    color: '#A2A5A9',
    marginLeft: perfectSize(4),
    lineHeight: 25,
  },
  indicator: {
    height: 2,
    backgroundColor: '#C70064',
    marginTop: 4,
  },
  badge: {
    position: 'absolute',
    top: -perfectSize(12),
    right: -perfectSize(18),
    borderRadius: perfectSize(4),
    overflow: 'hidden',
    paddingHorizontal: perfectSize(4),
    paddingVertical: perfectSize(2),
    backgroundColor: '#C70064',
  },
  badgeText: {
    fontSize: perfectSize(10),
    color: 'white',
  },
});

const handleSetNavigationReference = (navigatorRef) => {
  MainMenuService.setTopLevelNavigator(navigatorRef);
};

const TabBar = ({state, descriptors, navigation, lang, onTabChanged}) => {
  const {routes, index: activeRouteIndex} = state;
  const padding = perfectSize(showHoots ? 20 : 40); //20
  const iconSize = perfectSize(20);
  const fontFamily = lang === 'en' ? FONT_FAMILY.ubuntu : FONT_FAMILY.cairo;
  const maxWidth =
    screenWidth -
    (iconSize + perfectSize(50)) * (items.length - 1) -
    padding * 2;
  handleSetNavigationReference(navigation);
  return (
    <View style={styles.wrapperContainer}>
      <View style={styles.mainContainer}>
        {routes.map((route, routeIndex) => {
          const isRouteActive = routeIndex === activeRouteIndex;
          const {options} = descriptors[route.key];
          const label = options.tabBarLabel || items[routeIndex].label;
          const badgeCount = options.tabBarBadge;
          const onPress = () => {
            const event = navigation.emit({
              type: 'tabPress',
              target: route.key,
              canPreventDefault: true,
            });

            if (!isRouteActive && !event.defaultPrevented) {
              navigation.navigate(route.name);
              if (onTabChanged) {
                onTabChanged(routeIndex);
              }
            }
          };

          const onLongPress = () => {
            navigation.emit({
              type: 'tabLongPress',
              target: route.key,
            });
          };
          const icon = items[routeIndex].icon;
          return (
            <TouchableOpacity
              key={routeIndex}
              style={[
                styles.tabButton,
                {
                  width: showHoots ? '20%' : '25%',
                },
              ]}
              onPress={onPress}
              onLongPress={onLongPress}
              accessibilityLabel={options.tabBarAccessibilityLabel}>
              <Animated.View style={styles.container}>
                <Animated.View style={styles.wrapper}>
                  <AnimatedIcon source={icon} style={styles.icon} />
                  {isRouteActive && (
                    <Animated.Text
                      numberOfLines={1}
                      ellipsizeMode="clip"
                      style={[styles.text, {fontFamily: fontFamily}]}>
                      {label}
                    </Animated.Text>
                  )}
                </Animated.View>
                {isRouteActive && <Animated.View style={[styles.indicator]} />}
                {badgeCount != null && badgeCount > 0 && (
                  <View style={styles.badge}>
                    <Animated.Text style={styles.badgeText}>
                      {badgeCount}
                    </Animated.Text>
                  </View>
                )}
              </Animated.View>
            </TouchableOpacity>
          );
        })}
      </View>
    </View>
  );
};

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
    lang: state.appReducer.currentLang,
  };
};

export default compose(connect(mapStateToProps, {}))(TabBar);
