/* eslint-disable no-unused-vars */
import React, { memo, Component } from 'react';
import { View, StyleSheet, Dimensions } from 'react-native';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { perfectSize } from '../../../helpers/deviceHelper';
import { Placeholder, Progressive } from 'rn-placeholder';
import SkeletonUserAvatar from '../../skeletonWrapper/skeletonUserAvatar';
import SkeletonTextContent from '../../skeletonWrapper/skeletonTextContent';


class HopSkeletonCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            textHeight: 20,
            shadowColor: '#C70064',
        };
    }

    componentDidMount() { }

    componentWillUnmount() { }

    render() {
        const { theme } = this.props;
        const _styles = styles(theme);

        return (
            <Placeholder
                Animation={Progressive}
            >
                <View
                    style={[
                        _styles.flexRow,
                        {
                            paddingBottom: perfectSize(16),
                        },
                    ]}>
                    <View
                        style={[
                            _styles.container,
                        ]}>
                        <View >
                            <View style={_styles.flexRow}>
                                <SkeletonUserAvatar size={32} />
                                <SkeletonTextContent height={26} width={70} style={{ marginLeft: 10 }} />
                            </View>
                            <SkeletonTextContent height={60} width={100} style={{ marginTop: 20, marginBottom: 20 }} />
                            <View style={[{ flexDirection: 'row', width: '100%', justifyContent: 'space-between' }]}>
                                <SkeletonTextContent height={26} width={45} />
                                <SkeletonTextContent height={26} width={45} />
                            </View>
                        </View>

                    </View>
                </View>
            </Placeholder>
        );
    }
}

HopSkeletonCard.propTypes = {
    data: PropTypes.object,
    isJoined: PropTypes.bool,
    onJoin: PropTypes.func,
};

const mapStateToProps = (state) => {
    return {
        theme: state.appReducer.currentTheme,
    };
};

export default memo(connect(mapStateToProps)(HopSkeletonCard));

const styles = (theme) =>
    StyleSheet.create({
        container: {
            marginHorizontal: perfectSize(0.5),
            borderRadius: perfectSize(12),
            height: '100%',
            flex: 1,
        },
        detailCard: {
            marginHorizontal: 0,
            overflow: 'hidden',
        },
        footer: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: perfectSize(8),
        },
        flexRow: {
            flexDirection: 'row',
            alignItems: 'center',
        },
        hightlightText: {
            color: theme.primary_btn_background,
        },
        voteText: {
            opacity: 0.56,
            marginRight: perfectSize(4),
        },
        thumb: {
            marginLeft: perfectSize(4),
        },
        comment: {
            width: perfectSize(12),
            height: perfectSize(12),
            marginLeft: perfectSize(4),
        },
        imgGroup: {
            width: perfectSize(17),
            height: perfectSize(14),
            marginRight: perfectSize(4),
        },
        joinButton: {},
        leftShadow: {
            width: perfectSize(1),
            height: '80%',
        },
    });
