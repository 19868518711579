/* eslint-disable react-native/no-inline-styles */
import React, { Component } from 'react';
import {
  View,
  TouchableOpacity,
  StyleSheet,
  Dimensions,
  FlatList,
  Image,
} from 'react-native';
import Modal from 'react-native-modal';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Feather from 'react-native-vector-icons/Feather';
import firebase from 'firebase/app';
import 'firebase/database';

import Sticker from '../sticker-image';
import { perfectSize } from '../../helpers/deviceHelper';
import dimens from '../../constants/dimens';
import * as FirebasePaths from '../../constants/firebasePaths';

import { showAlert } from '../../redux/modules/alert/actions';
import ImageWithPlaceholder from '../image-with-placeholder';

const { height: viewportHeight } = Dimensions.get('window');

class StickersModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stickers: [],
      isLoading: true,
    };
  }

  componentDidMount() {
    // load stickers according to the sticker type

    const { stickerType, chatType } = this.props;
    const stickersPath =
      stickerType === 'chat'
        ? FirebasePaths.FIREBASE_CHAT_STICKERS
        : FirebasePaths.FIREBASE_HOP_STICKERS;
    if (stickerType === 'hop') {
      global.featuresLogger.HOPS_STICKER_LOGGER('open');
    } else {
      global.featuresLogger.CHAT_STICKER_LOGGER(chatType, 'open');
    }
    firebase.database()
      .ref(stickersPath)
      .once('value', (snapshot) => {
        const stickers = snapshot.val();
        if (stickers) {
          const realStickers = stickers.filter((e) => !!e);
          this.setState({ stickers: realStickers, isLoading: false });
        } else {
          this.setState({ stickers: [], isLoading: false });
        }
      });
  }

  _renderItem = (item) => {
    const { rank } = this.props;
    const stickerRank = item.rank;
    const isSendable = rank > stickerRank;
    return (
      <TouchableOpacity
        style={styles.imgSticker}
        onPress={() => {
          if (isSendable) {
            this.props.onSendSticker(item.url);
          } else {
            const remaining = stickerRank - rank;
            this.props.onHide();
            setTimeout(() => {
              this.props.showAlert([
                'Missing points',
                'You need ' + remaining + ' more points to use this sticker!',
                [{ text: 'OK', onPress: () => { } }],
              ]);
            }, 500);
          }
        }}>
        {/* <ImageWithPlaceholder
          uri={item.url}
          style={{
            width: '100%',
            height: '100%',
            opacity: isSendable ? 1 : 0.2,
          }}
          toOpacity={isSendable ? 1 : 0.2}
        /> */}
        <Sticker url={item.url} customStyles={{ opacity: isSendable ? 1 : 0.2, }} />
      </TouchableOpacity>
    );
  };

  render() {
    return (
      <Modal
        isVisible={this.props.isVisible}
        onBackdropPress={() => {
          this.props.onHide();
        }}
        coverScreen={true}
        animationIn={'pulse'}
        animationOut={'fadeOut'}
        style={styles.modalStyle}
        backdropOpacity={0.7}>
        <View style={styles.wrapper}>
          <View style={styles.box}>
            <View style={styles.header}>
              <TouchableOpacity
                onPress={() => {
                  // close modal
                  this.props.onHide();
                }}
                style={styles.downContainer}>
                <Feather
                  name="chevron-down"
                  color="white"
                  size={perfectSize(24)}
                />
              </TouchableOpacity>
              <View style={styles.divider} />
              <View style={styles.fullFlex} />
            </View>
            {
              !this.state.isLoading &&
              <FlatList
                data={this.state.stickers}
                renderItem={({ item }) => this._renderItem(item)}
                keyExtractor={(item, index) => index.toString()}
                numColumns={4}
                columnWrapperStyle={{ justifyContent: 'space-between' }}
                showsVerticalScrollIndicator={false}
              />
            }

          </View>
        </View>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
    rank: state.authReducer.userinformationDB.rank,
  };
};

StickersModal.defaultProps = {
  stickerType: 'chat',
};

StickersModal.propTypes = {
  stickerType: PropTypes.string, // could be 'chat' or 'hop'
  chatType: PropTypes.string, // could be 'PRIVATE', 'PUBLIC' AND 'CLAN' only for chats
  onSendSticker: PropTypes.func,
};

export default compose(connect(mapStateToProps, { showAlert }))(StickersModal);

const styles = StyleSheet.create({
  modalStyle: {
    // margin: 'auto',
    margin: 0,
    paddingTop: (viewportHeight * 2) / 5,
  },
  wrapper: {
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    height: '50%',
    // maxWidth: 812,
    // minWidth: 400,
    // width: '100%'
  },
  box: {
    flex: 1,
    backgroundColor: '#181f29',
    width: '100%',
    paddingHorizontal: perfectSize(32),
    paddingVertical: perfectSize(16),
    borderTopLeftRadius: dimens.HEADER_PADDING_HORIZONTAL,
    borderTopRightRadius: dimens.HEADER_PADDING_HORIZONTAL,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  downContainer: {
    flex: 1,
  },
  divider: {
    flex: 1,
    height: 2,
    backgroundColor: 'white',
  },
  fullFlex: {
    flex: 1,
  },
  noOffset: {
    paddingTop: 0,
  },
  containerStyles: {
    marginTop: 10,
    width: '40%',
    alignSelf: 'center',
    borderRadius: 10,
    shadowOffset: {
      width: 0,
      height: 2,
    },
  },
  icon: {
    width: 20,
    height: 20,
    resizeMode: 'contain',
  },
  imgSticker: {
    width: '25%',
    aspectRatio: 1,
    alignItems: 'center',
    // backgroundColor: 'red',
    marginVertical: perfectSize(12),
    marginHorizontal: perfectSize(1),
  },
});
