import React, {Component} from 'react';
import {View, FlatList, StyleSheet} from 'react-native';
import {compose} from 'redux';
import {connect} from 'react-redux';
import firebase from 'firebase/app';
import 'firebase/database';

import I18n from '../../i18n';

// import styles
import ProfileAccountCard from '../../components/cards/profile-account-card';
import {
  _img_ps,
  _img_xbox,
  _img_epic,
  _img_steam,
  _img_origin,
  _img_battlenet,
  _img_nintendo,
  _img_discord,
} from '../../constants/images';
import {perfectSize} from '../../helpers/deviceHelper';
import PrimaryButton from '../../components/buttons/primary-button';

import * as FirebasePaths from '../../constants/firebasePaths';
import {presentToast} from '../../helpers/toastHelper';

import {showAlert} from '../../redux/modules/alert/actions';
import dimens from '../../constants/dimens';

// Acounts Page
class UserProfileAccountsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ps: '',
      xbox: '',
      nintendo: '',
      epic: '',
      steam: '',
      origin: '',
      battlenet: '',
      discord: '',
      dialogVisible: false,
    };
  }

  componentDidMount() {
    global.rankLogger.LOG_NEW_HP(
      global.rankLogger.VIEW_USER_ACCOUNTS_PAGE,
      'VIEW_USER_ACCOUNTS_PAGE',
    );
    global.featuresLogger.LOG_TEAMS_USER_ACCOUNTS_PAGE_VIEW();
    this.loadUserAccountsConsoles().then((snapshot) => {
      const snap = snapshot.val();
      // eslint-disable-next-line no-unused-vars
      for (let key in snap) {
        if (snap.hasOwnProperty(key)) {
          if (key === 'PSN_account') {
            this.setState({ps: snap[key]});
          }
          if (key === 'XBOX_live_account') {
            this.setState({xbox: snap[key]});
          }
          if (key === 'NINTENDO_account') {
            this.setState({nintendo: snap[key]});
          }
        }
      }
    });

    this.loadUserAccountsPC().then((snapshot) => {
      const snap = snapshot.val();
      // eslint-disable-next-line no-unused-vars
      for (let key in snap) {
        if (snap.hasOwnProperty(key)) {
          if (key === 'Epic Games') {
            this.setState({epic: snap[key]});
          }
          if (key === 'Steam') {
            this.setState({steam: snap[key]});
          }
          if (key === 'Origin') {
            this.setState({origin: snap[key]});
          }
          if (key === 'Battlenet') {
            this.setState({battlenet: snap[key]});
          }
          if (key === 'Discord') {
            this.setState({discord: snap[key]});
          }
        }
      }
    });
  }

  loadUserAccountsConsoles() {
    const path =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      this.props.userKey +
      '/' +
      FirebasePaths.FIREBASE_DETAILS_ATTR;
    return firebase.database().ref(path).once('value');
  }

  loadUserAccountsPC() {
    const path =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      this.props.userKey +
      '/' +
      FirebasePaths.FIREBASE_DETAILS_ATTR +
      '/' +
      FirebasePaths.FIREBASE_USER_PC_GAME_PROVIDER_ATTR;
    return firebase.database().ref(path).once('value');
  }

  renderAccounts = ({item, index}) => {
    return (
      <ProfileAccountCard
        name={item.name}
        icon={item.icon}
        index={item.index}
        bgColor={item._backgroundColor}
        customStyles={[
          styles.cardItem,
          index % 2 === 0
            ? {marginRight: perfectSize(8)}
            : {marginLeft: perfectSize(8)},
        ]}
      />
    );
  };

  renderSeparator = () => <View style={styles.separator} />;

  notify = (type) => {
    global.featuresLogger.LOG_TEAMS_USER_ACCOUNT_NOTIFIED();
    let message = '';
    switch (type) {
      case 1:
        message = 'يبيك تحدث بياناتك وتحط حساب البلايستيشن 🙃';
        break;
      case 2:
        message = 'يبيك تحدث بياناتك وتحط حساب الايبيك 🙃';
        break;
      case 3:
        message = 'يبيك تحدث بياناتك وتحط حسابك في ستيم 🙃';
        break;
      case 4:
        message = 'يبيك تحدث بياناتك وتحط حسابك في اوريجن 🙃';
        break;
      case 5:
        message = 'يبيك تحدث بياناتك وتحط حسابك في باتلنت 🙃';
        break;
      case 6:
        message = 'يبيك تحدث بياناتك وتحط حسابك في نيتيندو 🙃';
        break;
      case 7:
        message = 'يبيك تحدث بياناتك وتحط حسابك في اكس بوكس 🙃';
        break;
      default:
        message = 'يبيك تحدث بياناتك وتحط حساب البلايستيشن 🙃';
        break;
    }
    this.notifyUser(message);
    this.setState({dialogVisible: false});
    presentToast({
      message: I18n.t('USER_GAMES_ACCOUNTS_PAGE.success_notification'),
    });
  };

  notifyUser = (message) => {
    let notificationId = firebase.database().ref().push().key;
    let notificationObj = {
      id: notificationId,
      status: 'UNREAD',
      body: this.props.userinformationDB.username + ' ' + message,
      type: 'UPDATE',
      TRIGGER: this.props.userKey,
      timeStamp: firebase.database.ServerValue.TIMESTAMP,
    };

    let userNotifications =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      this.props.userKey +
      '/' +
      FirebasePaths.FIREBASE_USER_NOTIFICATIONS +
      '/' +
      notificationId;
    firebase.database().ref(userNotifications).set(notificationObj);
  };

  showNotifyAlert = () => {
    let buttons = [];
    buttons.push({
      text: 'PLAYSTATION',
      onPress: () => {
        this.notify(1);
      },
    });
    buttons.push({
      text: 'EPIC',
      onPress: () => {
        this.notify(2);
      },
    });
    buttons.push({
      text: 'STEAM',
      onPress: () => {
        this.notify(3);
      },
    });
    buttons.push({
      text: 'ORIGIN',
      onPress: () => {
        this.notify(4);
      },
    });
    buttons.push({
      text: 'BATTLENET',
      onPress: () => {
        this.notify(5);
      },
    });
    buttons.push({
      text: 'NINTENDO',
      onPress: () => {
        this.notify(6);
      },
    });
    buttons.push({
      text: 'XBOX',
      onPress: () => {
        this.notify(7);
      },
    });

    this.props.showAlert([
      I18n.t('USER_GAMES_ACCOUNTS_PAGE.alert_title'),
      I18n.t('USER_GAMES_ACCOUNTS_PAGE.alert_msg'),
      buttons,
      false, // isRow
    ]);
  };

  render() {
    const _noAccount = this.props.lang === 'en' ? 'Not Available' : 'لا يوجد';
    return (
      <View style={[styles.container]}>
        <FlatList
          data={[
            {
              name: this.state.ps || _noAccount,
              icon: _img_ps,
              _backgroundColor: '#003791',
              index: 1,
            },
            {
              name: this.state.xbox || _noAccount,
              icon: _img_xbox,
              _backgroundColor: '#107C10',
              index: 2,
            },
            {
              name: this.state.epic || _noAccount,
              icon: _img_epic,
              _backgroundColor: '#2f2d2e',
              index: 3,
            },
            {
              name: this.state.steam || _noAccount,
              icon: _img_steam,
              _backgroundColor: '#14659A',
              index: 4,
            },
            {
              name: this.state.origin || _noAccount,
              icon: _img_origin,
              _backgroundColor: '#ef5b2f',
              index: 5,
            },
            {
              name: this.state.battlenet || _noAccount,
              icon: _img_battlenet,
              _backgroundColor: '#1eaaf1',
              index: 6,
            },
            {
              name: this.state.nintendo || _noAccount,
              icon: _img_nintendo,
              _backgroundColor: '#e30b20',
              index: 7,
            },
            {
              name: this.state.discord || _noAccount,
              icon: _img_discord,
              _backgroundColor: '#7289da',
              index: 8,
            },
          ]}
          style={{marginBottom: perfectSize(16)}}
          numColumns={2}
          ItemSeparatorComponent={this.renderSeparator}
          renderItem={this.renderAccounts}
          keyExtractor={(item) => item.index.toString()}
          columnWrapperStyle={styles.flatList}
          removeClippedSubviews={false}
        />

        {(this.state.ps === '' ||
          this.state.xbox === '' ||
          this.state.nintendo === '' ||
          this.state.origin === '' ||
          this.state.epic === '' ||
          this.state.steam === '' ||
          this.state.battlenet === '' ||
          this.state.discord === '') && (
          <PrimaryButton
            text={I18n.t('USER_GAMES_ACCOUNTS_PAGE.notify_user_btn')}
            type="filled"
            size="x_small"
            onPress={this.showNotifyAlert}
          />
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: perfectSize(16),
    paddingBottom: dimens.CONTENT_PADDING_BOTTOM,
  },
  flatList: {
    justifyContent: 'space-between',
  },
  cardItem: {
    flex: 1,
  },
  separator: {
    height: perfectSize(16),
  },
  dialogContainer: {
    backgroundColor: 'white',
    borderRadius: perfectSize(8),
    marginHorizontal: perfectSize(16),
    paddingTop: perfectSize(32),
    // height: '100%',
  },
  dialogSectionContainer: {
    padding: perfectSize(16),
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  dialogLabel: {
    color: 'black',
  },
  dialogSubLabel: {
    fontSize: perfectSize(14),
    color: 'rgba(0, 0, 0, 0.5)',
    textAlign: 'left',
  },
  dialogDescription: {
    color: '#00000080',
    fontSize: 14,
    width: '100%',
  },
  accountWrap: {
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    paddingHorizontal: perfectSize(16),
    marginVertical: perfectSize(32),
  },
  accountBtn: {
    color: '#880e4f',
    marginVertical: perfectSize(8),
    textTransform: 'uppercase',
    fontSize: perfectSize(14),
  },
});

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
    lang: state.appReducer.currentLang,
    userinformationDB: state.authReducer.userinformationDB,
  };
};

export default compose(connect(mapStateToProps, {showAlert}))(
  UserProfileAccountsPage,
);
