/* eslint-disable react-native/no-inline-styles */
import React, { Component } from 'react';
import { View, TouchableOpacity, StyleSheet, Dimensions } from 'react-native';
import Modal from 'react-native-modal';
import { compose } from 'redux';
import { connect } from 'react-redux';

import Label from '../label';

import { perfectSize } from '../../helpers/deviceHelper';
import HootDetailModalCardContent from '../cards/hoot-detail-modal-card/content';
import dimens from '../../constants/dimens';
const {height: viewportHeight} = Dimensions.get('window');

class LanguageSwitch extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  _renderButtons = () => {
    let buttonViews = [];
    this.props.buttons.forEach((button, index) => {
      buttonViews.push(
        <TouchableOpacity
          key={index}
          style={[styles.buttonStyle, this.props.customStyles]}
          onPress={() => {
            this.props.onHide();
            setTimeout(() => {
              button.onPress && button.onPress();
            }, 300);
          }}>
          <Label style={styles.buttonText}>{button.text}</Label>
        </TouchableOpacity>,
      );
    });
    return buttonViews;
  };

  render() {
    const {title, subtitle, customComponent} = this.props;
    const isHoot = customComponent && customComponent.isLive;
    return (
      <Modal
        isVisible={this.props.isVisible}
        onBackdropPress={() => {
          this.props.onHide();
        }}
        coverScreen={true}
        animationIn={'pulse'}
        animationOut={'fadeOut'}
        style={[styles.modalStyle, isHoot && {
          paddingTop: (viewportHeight * 2) / 7,
        }]}
        backdropOpacity={0.7}>
        <View style={[styles.wrapper, isHoot && {flex: 1}]}>
          <View style={[styles.box, {
            borderBottomColor: this.props.theme.primary_btn_background,
            borderBottomWidth: isHoot ? 0 : 3
          }, isHoot ? {
            flex: 1, width: '100%',
            borderTopLeftRadius: dimens.HEADER_PADDING_HORIZONTAL,
            borderTopRightRadius: dimens.HEADER_PADDING_HORIZONTAL,
            overflow: 'hidden'
          } : {
            width: '80%',
            padding: perfectSize(32),
          }]}>
            {
              title !== '' && <Label bold={true} style={styles.title}>
                {title}
              </Label>
            }
            {customComponent && (customComponent.isLive ?
              <HootDetailModalCardContent
                isVisible={this.props.isVisible}
                {...customComponent}
                onHide={() => {
                  this.props.onHide();
                }}
              /> : customComponent)
            }

            {subtitle !== '' && (
              <Label style={styles.subtitle}>{subtitle}</Label>
            )}
            <View
              style={[
                styles.buttonContainer,
                this.props.isRow && {
                  flexDirection: 'row',
                  alignItems: 'flex-end',
                  justifyContent: 'flex-end',
                },
              ]}>
              {this._renderButtons()}
            </View>
          </View>
        </View>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
    title: state.alertReducer.title,
    subtitle: state.alertReducer.subtitle,
    buttons: state.alertReducer.buttons,
    isRow: state.alertReducer.isRow,
    customStyles: state.alertReducer.customStyles,
    customComponent: state.alertReducer.customComponent
  };
};

export default compose(connect(mapStateToProps, {}))(LanguageSwitch);

const styles = StyleSheet.create({
  modalStyle: {
    // marginHorizontal: 0,
    // margin: 0,
    // maxWidth: 812,
    margin: 'auto'
  },
  wrapper: {
    justifyContent: 'center',
    alignItems: 'center',

  },
  box: {
    backgroundColor: '#181f29',
    padding: perfectSize(32),
  },
  noOffset: {
    paddingTop: 0,
  },
  title: {
    color: 'white',
    fontSize: perfectSize(16),
    textAlign: 'center',
  },
  subtitle: {
    color: 'white',
    fontSize: perfectSize(15),
    opacity: 0.6,
    marginTop: perfectSize(32),
    marginBottom: perfectSize(16),
    textAlign: 'center',
  },
  buttonContainer: {
    marginTop: perfectSize(32),
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonStyle: {
    marginHorizontal: perfectSize(4),
    borderWidth: 1,
    borderColor: '#a70559',
    borderRadius: perfectSize(10),
    height: perfectSize(42),
    justifyContent: 'center',
    paddingHorizontal: perfectSize(12),
    marginVertical: perfectSize(4),
    width: perfectSize(120),
    alignItems: 'center'
  },
  buttonText: {
    color: '#a70559',
    fontSize: perfectSize(14),
  },
  containerStyles: {
    marginTop: 10,
    width: '40%',
    alignSelf: 'center',
    borderRadius: 10,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    // shadowOpacity: calculatedHeight(0.25),
    // shadowRadius: calculatedHeight(3.84),
    // elevation: calculatedHeight(5),
  },
  icon: {
    width: 20,
    height: 20,
    resizeMode: 'contain',
  },
});
