import { StyleSheet } from 'react-native';
import { perfectSize } from '../../helpers/deviceHelper';

const styles = (theme) =>
  StyleSheet.create({
    content: {
      backgroundColor: theme.primary_background,
    },
    ads: {
      width: '100%',
      aspectRatio: 327 / 200,
      height: 250,
    },
    dot: {
      width: perfectSize(8),
      height: perfectSize(8),
      borderRadius: perfectSize(4),
    },
    pagination: {
      width: '100%',
      justifyContent: 'center',
      position: 'absolute',
      bottom: -perfectSize(16),
      backgroundColor: 'transparent',
    },
    slide: {
      borderRadius: perfectSize(12),
      overflow: 'hidden',
    },
    container: {
      borderRadius: perfectSize(12),
      width: '100%',
      aspectRatio: 327 / 200,
      height: 250,
      overflow: 'hidden',
    },
    cover: {
      width: '100%',
      height: '100%',
    },
    overlay: {
      width: '100%',
      height: '100%',
    },
    infoWrap: {
      position: 'absolute',
      bottom: perfectSize(32),
      left: perfectSize(32),
      right: perfectSize(32),
    },
    sourceLogo: {
      width: perfectSize(16),
      aspectRatio: 1,
      marginRight: perfectSize(4),
    },
  });

export default styles;
