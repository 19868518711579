/* eslint-disable react-native/no-inline-styles */
import React, { Component } from 'react';
import { View, TouchableOpacity } from 'react-native';
import { compose } from 'redux';
import { connect } from 'react-redux';
import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/storage';

import * as ImagePicker from 'expo-image-picker';
import I18n from '../../i18n';


// import components
import Header from '../../components/header';
import Label from '../../components/label';
import UserAvatar from '../../components/user-avatar';
import ImageWithPlaceholder from '../../components/image-with-placeholder';
import PrimaryButton from '../../components/buttons/primary-button';
import Switch from '../../components/switch';

// import styles
import AppStyles from '../../constants/styles';
import styles from './styles';
import { UPLOAD } from '../../constants/images';

// import actions
import { switchTheme } from '../../redux/modules/app/actions';
import { set as setHub } from '../../redux/modules/hub/actions';
import { showSpinner, hideSpinner } from '../../redux/modules/alert/actions';

import { perfectSize } from '../../helpers/deviceHelper';
import { presentToast } from '../../helpers/toastHelper';
import Teamcore from '../team/teamscore';

import * as FirebasePaths from '../../constants/firebasePaths';
import storage from '@react-native-firebase/storage';
import NavigationService from '../../navigation/navigationService';
import { clearFastImageCache } from '../../services/utils';
import LocalImageOpacity from '../../components/local-image-opacity';

/**
 * This page is merge of
 * `ClanSettingsPage` and `EditTeamGraphicsPage` in Ionic
 */
class ClanSettingsPage extends Component {
  constructor(props) {
    super(props);
    const params = props.navigation.state.params;
    this.state = {
      teamId: params.TEAM_ID,
      teamAdmin: params.TEAM_ADMIN,
      teamLogo: params.TEAM_LOGO,
      teamLogoBase64: '',
      teamBackground: params.TEAM_BG,
      teamBackgroundBase64: '',
      clanStatus: params.CLAN_STATUS,
      clanStatusToggleState: params.CLAN_STATUS === 'PUBLIC' ? true : false,
      membersArr: [],
    };
  }

  componentDidMount() {
    Teamcore.loadMembers(this.state.teamId).then((shot) => {
      let membersArr = [];
      shot.forEach((item) => {
        membersArr.push(item.val());
      });
      this.setState({ membersArr });
    });
  }

  // Change the clan status toggle trigger
  clanStatusTrigger = (triggerValue) => {
    if (triggerValue) {
      // Change the team status to public
      firebase.database()
        .ref(
          FirebasePaths.FIREBASE_TEAMS +
          '/' +
          this.state.teamId +
          '/' +
          FirebasePaths.FIREBASE_TEAM_INFO,
        )
        .child('status')
        .set('PUBLIC');
    } else {
      // Change the team status to private
      firebase.database()
        .ref(
          FirebasePaths.FIREBASE_TEAMS +
          '/' +
          this.state.teamId +
          '/' +
          FirebasePaths.FIREBASE_TEAM_INFO,
        )
        .child('status')
        .set('PRIVATE');
    }
  };

  // this function will update the team graphics
  updateTeamGraphics() {
    this.props.hideSpinner();

    presentToast({
      message: I18n.t('EDIT_TEAM_GRAPHICS_PAGE.success_update_msg'),
    });

    // Update members ref
    this.updateTeamsGraphicsForUsers();
    clearFastImageCache();
    NavigationService.goBack();
  }

  // This function will update team graphics info in all members ref
  updateTeamsGraphicsForUsers() {
    const { teamId, teamAdmin, teamLogo, membersArr } = this.state;
    // Update for the admin
    if (teamAdmin != null) {
      firebase.database()
        .ref(
          FirebasePaths.FIREBASE_USERS_INFO_ATTR +
          '/' +
          teamAdmin +
          '/' +
          FirebasePaths.FIREBASE_USER_TEAMS +
          '/' +
          FirebasePaths.FIREBASE_USER_TEAMS_REFS +
          '/' +
          teamId,
        )
        .child('logo')
        .set(teamLogo);

      let adminClanChatRef =
        FirebasePaths.FIREBASE_USERS_INFO_ATTR +
        '/' +
        teamAdmin +
        '/' +
        FirebasePaths.FIREBASE_CLANS_CHATS_USER_REF +
        '/' +
        teamId;

      firebase.database().ref(adminClanChatRef).child('teamLogo').set(teamLogo);
    }

    // Update for the members
    membersArr.forEach((member) => {
      if (member.uid != null) {
        // Update the clan logo ref
        firebase.database()
          .ref(
            FirebasePaths.FIREBASE_USERS_INFO_ATTR +
            '/' +
            member.uid +
            '/' +
            FirebasePaths.FIREBASE_USER_TEAMS +
            '/' +
            FirebasePaths.FIREBASE_USER_TEAMS_REFS +
            '/' +
            teamId,
          )
          .child('logo')
          .set(teamLogo);

        // Update the clan logo chat ref
        let userClanRef =
          FirebasePaths.FIREBASE_USERS_INFO_ATTR +
          '/' +
          member.uid +
          '/' +
          FirebasePaths.FIREBASE_CLANS_CHATS_USER_REF +
          '/' +
          teamId;

        firebase.database().ref(userClanRef).child('teamLogo').set(teamLogo);
      }
    });
  }

  selectImage = async (imgType) => {
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      base64: true,
    })

    if (!result.cancelled) {
      const { uri, base64 } = result;

      if (imgType === 'LOGO') {
        this.setState({
          teamLogo: uri,
          teamLogoBase64: base64,
        });
      } else if (imgType === 'BACKGROUND') {
        this.setState({
          teamBackground: uri,
          teamBackgroundBase64: base64,
        });
      }
    }
  };

  uploadImageToFirebase(image, imgType) {
    //uploads img to firebase storage
    return this.uploadImage(image, imgType).then((photoURL) => {
      if (imgType === 'LOGO') {
        // Update local img and show success message
        this.setState({ teamLogo: photoURL });
        presentToast({
          message: I18n.t('JOIN_CHAMPIONSHIP_PAGE.done_upload_logo'),
        });
        // Upload photo url
        firebase.database()
          .ref(
            FirebasePaths.FIREBASE_TEAMS +
            '/' +
            this.state.teamId +
            '/' +
            FirebasePaths.FIREBASE_TEAM_INFO,
          )
          .child('logoUrl')
          .set(photoURL);
        return;
      } else if (imgType === 'BACKGROUND') {
        // Update local img and show success message
        this.setState({ teamBackground: photoURL });
        presentToast({
          message: I18n.t('JOIN_CHAMPIONSHIP_PAGE.done_upload_background'),
        });
        // Upload photo url
        firebase.database()
          .ref(
            FirebasePaths.FIREBASE_TEAMS +
            '/' +
            this.state.teamId +
            '/' +
            FirebasePaths.FIREBASE_TEAM_INFO,
          )
          .child('backgroundUrl')
          .set(photoURL);
        return;
      }
    });
  }

  uploadImage(imageURI, imgType) {
    let teamId = this.state.teamId;
    // if (teamId.length > 1) {
    let imageRef;
    let date = new Date();
    let timestamp = date.toDateString();
    let storageRef = firebase.storage().ref();
    if (imgType === 'LOGO') {
      imageRef = storageRef
        .child('TeamsInfo')
        .child(teamId)
        .child('team_logo' + timestamp);
    } else if (imgType === 'BACKGROUND') {
      imageRef = storageRef
        .child('TeamsInfo')
        .child(teamId)
        .child('team_background' + timestamp);
    }
    return imageRef.putString(imageURI, firebase.storage.StringFormat.BASE64).then(() => {
      return imageRef.getDownloadURL().then((downloadURL) => {
        return downloadURL;
      });
    });
    // }
  }

  uploadLogos = () => {
    this.props.showSpinner({
      title: I18n.t('JOIN_CHAMPIONSHIP_PAGE.loading_msg'),
    });
    const {teamLogo, teamLogoBase64, teamBackground, teamBackgroundBase64 } = this.state;
    if (!teamLogo.startsWith('https')) {
      this.uploadImageToFirebase(teamLogoBase64, 'LOGO').then(() => {
        if (!teamBackground.startsWith('https')) {
          this.uploadImageToFirebase(teamBackgroundBase64, 'BACKGROUND').then(() => {
            this.updateTeamGraphics();
          });
        } else {
          this.updateTeamGraphics();
        }
      });
    } else {
      if (!teamBackground.startsWith('https')) {
        this.uploadImageToFirebase(teamBackgroundBase64, 'BACKGROUND').then(() => {
          this.updateTeamGraphics();
        });
      } else {
        this.updateTeamGraphics();
      }
    }
  };

  render() {
    const { theme } = this.props;
    const _styles = styles(theme);
    const { teamLogo, teamBackground, clanStatusToggleState } = this.state;
    return (
      <View style={AppStyles.rootContainer}>
        <Header text={I18n.t('CLANS_SETTINGS.title')} />
        <View style={AppStyles.rootContent}>
          {/* Clan logo */}
          <View>
            <Label bold={true}>
              {I18n.t('JOIN_CHAMPIONSHIP_PAGE.team_logo')}
            </Label>
            <View style={_styles.centerWrapper}>
              <View style={_styles.logoWrapper}>
                <UserAvatar
                  size={perfectSize(96)}
                  borderWidth={2}
                  src={teamLogo}
                />
                <TouchableOpacity
                  style={_styles.logoUploadWrapper}
                  onPress={() => this.selectImage('LOGO')}>
                  <View style={_styles.uploadButton}>
                    <LocalImageOpacity
                      style={{ width: '50%', height: '50%' }}
                      source={UPLOAD}
                      resizeMode="contain"
                    />
                  </View>
                </TouchableOpacity>
              </View>
            </View>
          </View>
          {/* Clan Header */}
          <View style={_styles.subContainer}>
            <Label bold={true}>
              {I18n.t('JOIN_CHAMPIONSHIP_PAGE.clan_header')}
            </Label>
            <View>
              <View style={_styles.headerWrapper}>
                <ImageWithPlaceholder
                  uri={teamBackground}
                  style={_styles.header}
                />
                <TouchableOpacity
                  style={_styles.headerUploadWrapper}
                  onPress={() => this.selectImage('BACKGROUND')}>
                  <View style={_styles.uploadButton}>
                    <LocalImageOpacity
                      style={{ width: '50%', height: '50%' }}
                      source={UPLOAD}
                      resizeMode="contain"
                    />
                  </View>
                </TouchableOpacity>
              </View>
            </View>
          </View>
          {/* Clan status */}
          <View style={_styles.subContainer}>
            <Label bold={true}>
              {I18n.t('JOIN_CHAMPIONSHIP_PAGE.clan_status')}
            </Label>
            <Switch
              initialValue={clanStatusToggleState}
              style={_styles.switch}
              onText={I18n.t(
                'JOIN_CHAMPIONSHIP_PAGE.clan_status_alert_public_btn',
              )}
              offText={I18n.t(
                'JOIN_CHAMPIONSHIP_PAGE.clan_status_alert_private_btn',
              )}
              onValueChanged={(val) => this.clanStatusTrigger(val)}
            />
            <Label size="small" style={_styles.statusDesc}>
              {I18n.t('CLANS_SETTINGS.status_msg')}
            </Label>
          </View>
          <View style={_styles.space} />
          {/* Submit button */}
          <View style={_styles.footer}>
            <PrimaryButton
              text={I18n.t('EDIT_TEAM_GRAPHICS_PAGE.update_btn')}
              type="filled"
              onPress={() => this.uploadLogos()}
              textCustomStyles={_styles.submitTextStyle}
            />
          </View>
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
  };
};

export default compose(
  connect(mapStateToProps, { switchTheme, setHub, showSpinner, hideSpinner }),
)(ClanSettingsPage);
