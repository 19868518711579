/* eslint-disable react-native/no-inline-styles */
import React, {Component} from 'react';
import {View, FlatList} from 'react-native';
import {compose} from 'redux';
import firebase from 'firebase/app';
import 'firebase/database';

import {connect} from 'react-redux';
import I18n from '../../i18n';

// import components
import Header from '../../components/header';
import Label from '../../components/label';
import UserAvatar from '../../components/user-avatar';
import PrimaryButton from '../../components/buttons/primary-button';

import AppStyles from '../../constants/styles';
import styles from './styles';
import {perfectSize} from '../../helpers/deviceHelper';

import * as FirebasePaths from '../../constants/firebasePaths';

class ChampionshipStatusPage extends Component {
  constructor(props) {
    super(props);
    const params = props.navigation.state.params;
    this.state = {
      passedChampionshipObj: params.CHAMPIONSHIP_OBJ,
      roundsArr: [],
      participantsArr: [],
    };
  }

  componentDidMount() {
    // Log the agents page view
    global.featuresLogger.CHAMPIONSHIP_BRACKETS_PAGE_VIEW();
    // Log Rank HP
    global.rankLogger.LOG_NEW_HP(
      global.rankLogger.CHAMPIONSHIPS_BRACKETS_PAGE_VIEW,
      'CHAMPIONSHIPS_BRACKETS_PAGE_VIEW',
    );

    // Load brackets
    this.loadBrackets().then((shot) => {
      let roundsArr = [];
      let participantsArr = [];
      shot.forEach((item) => {
        let bracket = item.val();
        if (item.key === 'round_1') {
          bracket.selected = true;
          let firstParticipants = bracket.participants;
          Object.keys(firstParticipants)
            .sort()
            .forEach((key) => {
              participantsArr.push(firstParticipants[key]);
            });
        } else {
          bracket.selected = false;
        }
        roundsArr.push(bracket);
      });

      this.setState({roundsArr, participantsArr});
    });
  }

  renderCard = ({item, index}) => {
    const _styles = styles(this.props.theme);
    return (
      <View style={{marginBottom: perfectSize(16)}}>
        <View style={_styles.card}>
          <View style={_styles.sideArea}>
            <UserAvatar
              src={item.participant_1.logo}
              size={perfectSize(48)}
              borderWidth={0}
              style={_styles.avatar}
            />
            <Label size="small" numberOfLines={1} style={_styles.nameLabel}>
              {item.participant_1.name}
            </Label>
          </View>
          <View style={_styles.centerArea}>
            <View>
              <Label size="x_medium">VS</Label>
            </View>
            <View style={_styles.scoresWrap}>
              <Label
                style={{
                  flex: 1,
                  color:
                    item.participant_1.score === 'W' ? '#00FF89' : '#FF0000',
                }}>
                {item.participant_1.score}
              </Label>
              <Label
                style={{
                  flex: 1,
                  color:
                    item.participant_2.score === 'W' ? '#00FF89' : '#FF0000',
                  textAlign: 'right',
                }}>
                {item.participant_2.score}
              </Label>
            </View>
          </View>
          <View style={_styles.sideArea}>
            <UserAvatar
              src={item.participant_2.logo}
              size={perfectSize(48)}
              borderWidth={0}
              style={_styles.avatar}
            />
            <Label size="small" numberOfLines={1} style={_styles.nameLabel}>
              {item.participant_2.name}
            </Label>
          </View>
        </View>
      </View>
    );
  };

  renderRoundButtons = () => {
    const _styles = styles(this.props.theme);
    const {roundsArr} = this.state;
    return (
      <View style={_styles.roundButtons}>
        {roundsArr.map((round, index) => {
          return (
            <PrimaryButton
              text={round.title}
              type={round.selected ? 'filled' : 'outlined'}
              onPress={() => this.switchRound(round)}
              customStyles={[
                _styles.roundButton,
                {marginRight: (index + 1) % 4 === 0 ? 0 : perfectSize(8)},
              ]}
              textCustomStyles={{fontSize: perfectSize(10)}}
            />
          );
        })}
      </View>
    );
  };

  switchRound = (selectedRound) => {
    // First mark this round as selected : for the design and unselect all other rounds
    const {roundsArr} = this.state;
    let participantsArr = [];
    roundsArr.forEach((round) => {
      if (round.id === selectedRound.id) {
        // Update the arr
        round.selected = true;
        let participantsOBJ = selectedRound.participants;
        Object.keys(participantsOBJ)
          .sort()
          .forEach((key) => {
            participantsArr.push(participantsOBJ[key]);
          });
      } else {
        round.selected = false;
      }
    });
    this.setState({roundsArr, participantsArr});
  };

  // This function will load all the brackets rounds
  loadBrackets() {
    let path =
      FirebasePaths.FIREBASE_CHAMPIONSHIPS_REFERENCE +
      '/' +
      this.state.passedChampionshipObj.id +
      '/' +
      FirebasePaths.FIREBASE_CHAMPIONSHIPS_BRACKETS;
    return firebase.database().ref(path).once('value');
  }

  render() {
    const {theme} = this.props;
    const _styles = styles(theme);
    const {passedChampionshipObj, roundsArr, participantsArr} = this.state;
    return (
      <View style={AppStyles.rootContainer}>
        {/* Header */}
        <Header text={passedChampionshipObj.title} />
        {/* Content */}
        <View style={AppStyles.rootContent}>
          {roundsArr.length > 0 ? (
            <>
              {this.renderRoundButtons()}
              <FlatList
                data={participantsArr}
                renderItem={(item) => this.renderCard(item)}
                keyExtractor={(item, index) => String(index)}
                style={_styles.flatList}
                showsVerticalScrollIndicator={false}
              />
            </>
          ) : (
            <View style={[AppStyles.emptyContent, _styles.emptyContent]}>
              <Label
                style={[
                  AppStyles.emptyContentLabel,
                  _styles.emptyContentLabel,
                ]}>
                {I18n.t('CHAMPIONSHIP_INFO_PAGE.no_brackets_message')}
              </Label>
            </View>
          )}
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
  };
};

export default compose(connect(mapStateToProps, {}))(ChampionshipStatusPage);
