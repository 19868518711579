/* eslint-disable react-native/no-inline-styles */
import React, { Component } from 'react';
import { View, TouchableOpacity, FlatList, Platform, PermissionsAndroid, ScrollView, StyleSheet } from 'react-native';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { AgoraVideoPlayer, createClient, createMicrophoneAndCameraTracks } from "agora-rtc-react";


// import components
import Header from '../../../components/header';
import Label from '../../../components/label';

// import styles
import AppStyles from '../../../constants/styles';
import styles from './styles';

// import actions
import { switchTheme } from '../../../redux/modules/app/actions';
import { set as setHub } from '../../../redux/modules/hub/actions';
import {
    showAlert,
    showInputAlert,
    showSpinner,
    hideSpinner,
} from '../../../redux/modules/alert/actions';

import { perfectSize } from '../../../helpers/deviceHelper';
import { presentToast } from '../../../helpers/toastHelper';
import Team from '../../../models/team';

import * as FirebasePaths from '../../../constants/firebasePaths';
import firebase from 'firebase/app';
import 'firebase/database';
import NavigationService from '../../../navigation/navigationService';
import LocalImageOpacity from '../../../components/local-image-opacity';
import dimens from '../../../constants/dimens';
import { dataForSkeleton } from '../../../services/utils';
import ImageWithPlaceholderWithoutFocus from '../../../components/image-with-placeholder/without-focus';
import { AGORA_APP_ID } from '@env';
import { AgoraNotSpeakingDelay, AgoraSpeakingMinVolume, AgoraTokenUrl } from '../../../constants/constants';

// class AgoraTestPage extends Component {
//     constructor(props) {
//         super(props);
//         this.mount = true;
//         this.state = {
//         };
//     }

//     componentDidMount() {
//     }

//     generateToken = async (channelName, userId) => {
//         try {
//             const url = AgoraTokenUrl + 'rtc/' + channelName + '/publisher/userAccount/' + userId;
//             const response = await fetch(url, { method: 'GET' });
//             const resObj = await response.json();
//             const { rtcToken } = resObj;
//             return rtcToken;
//         } catch (e) {
//             return null;
//         }
//     }

//     componentWillUnmount() {
//         this.mount = false;
//         if (this.focusListener) {
//             this.focusListener.remove()
//         }
//         if (this.hootRef$) {
//             this.hootRef$.off();
//             this.hootRef$ = null;
//         }
//         this.removeUserFromLive();
//         this.changeStatusToDisconnected();
//     }

//     onMute = () => {
//         const { isMute } = this.state;
//         const changed = !isMute;
//         console.log('changed: ', changed)
//         this.setState({
//             isMute: changed
//         })
//         // Log mute hoot
//         global.featuresLogger.HOOT_CALL_MUTE();
//     }

//     onDeafen = () => {
//         // Log deafen hoot
//         if (this.state.isDeafen) {
//             this.setState({
//                 isDeafen: false
//             })
//             global.featuresLogger.HOOT_CALL_DEAFEN();
//         } else {
//             this.setState({
//                 isDeafen: true
//             })
//             global.featuresLogger.HOOT_CALL_SPEAKER();
//         }
//     }

//     removeUserFromLive = async (userId) => {
//         const { currentHoot: { id, liveParticipants: currentLiveParticipants } } = this.state;
//         const { uid } = this.props;
//         const userToRemove = userId ?? uid;
//         const hootPath =
//             FirebasePaths.FIREBASE_HOOTS +
//             '/' +
//             id;
//         if (currentLiveParticipants && !!currentLiveParticipants[userToRemove]) {
//             await firebase.database().ref(hootPath).child('liveParticipants').child(userToRemove).remove();
//         }
//     }

//     changeStatusToDisconnected = async (userId) => {
//         const { currentHoot } = this.state;
//         const { uid } = this.props;
//         const userToRemove = userId ?? uid;
//         const path =
//             FirebasePaths.FIREBASE_USERS_INFO_ATTR +
//             '/' +
//             userToRemove +
//             '/' +
//             FirebasePaths.FIREBASE_HOOTS +
//             '/' +
//             currentHoot.id;
//         const hoot = { ...currentHoot };
//         hoot.status = 'disconnected';

//         // Upload to the user hoots ref path
//         await database().ref(path).set(hoot);
//     }

//     addHootIdToUserBans = async (userId) => {
//         const { currentHoot: { id, historyParticipants: currentHistoryParticipants } } = this.state;
//         const userToRemove = userId;
//         const hootPath =
//             FirebasePaths.FIREBASE_USERS_INFO_ATTR +
//             '/' +
//             userToRemove +
//             '/' +
//             FirebasePaths.FIREBASE_HOOTS_BANS;
//         await database().ref(hootPath).child(id).set(id);
//         const historyPath = FirebasePaths.FIREBASE_HOOTS +
//             '/' +
//             id;
//         if (currentHistoryParticipants && !!currentHistoryParticipants[userToRemove]) {
//             await database().ref(historyPath).child('historyParticipants').child(userToRemove).child('isBanned').set(true);
//         }
//     }

//     _renderVideo = () => {
//         const { isRenderTextureView, remoteUid } = this.state;
//         return (
//             <View style={stylesAgora.container}>
//                 {isRenderTextureView ? (
//                     <RtcLocalView.TextureView style={stylesAgora.local} />
//                 ) : (
//                     <RtcLocalView.SurfaceView style={stylesAgora.local} />
//                 )}
//                 {remoteUid !== undefined && (
//                     <ScrollView horizontal={true} style={stylesAgora.remoteContainer}>
//                         {remoteUid.map((value, index) => (
//                             <TouchableOpacity
//                                 key={index}
//                                 style={stylesAgora.remote}
//                                 onPress={this._switchRender}
//                             >
//                                 {isRenderTextureView ? (
//                                     <RtcRemoteView.TextureView
//                                         style={stylesAgora.container}
//                                         uid={value}
//                                     />
//                                 ) : (
//                                     <RtcRemoteView.SurfaceView
//                                         style={stylesAgora.container}
//                                         uid={value}
//                                         zOrderMediaOverlay={true}
//                                     />
//                                 )}
//                             </TouchableOpacity>
//                         ))}
//                     </ScrollView>
//                 )}
//             </View>
//         );
//     };

//     render() {
//         const { theme } = this.props;
//         const _styles = styles(theme);
//         const { currentHoot, historyMembers, changed, remoteUser } = this.state;
//         return (
//             <View style={AppStyles.rootContainer}>
//                 <Header
//                     onBack={() => {
//                         // this.confirmDisconnect();
//                     }}
//                     centerComponent={
//                         <View style={_styles.titleWrap}>
//                             <Label size="medium" bold style={_styles.title}>
//                                 test
//                             </Label>
//                         </View>
//                     }
//                 />
//                 <View style={{
//                     borderTopLeftRadius: dimens.BODY_BORDER_TOP_RADIUS,
//                     borderTopEndRadius: dimens.BODY_BORDER_TOP_RADIUS,
//                     overflow: 'hidden',
//                     flex: 1,
//                 }}>
//                     <View style={[_styles.contentWrap, { backgroundColor: theme.primary_background }]}>
//                         <View style={_styles.space} />
//                         {/* {this._renderVideo()} */}
//                         {/* Submit button */}
//                     </View>
//                     <View style={_styles.footerWrap}>
//                         <View style={_styles.footer}>
//                         </View>
//                     </View>
//                 </View>
//             </View>
//         );
//     }
// }

// const mapStateToProps = (state) => {
//     return {
//         theme: state.appReducer.currentTheme,
//         blockedList: state.hubReducer.blockedList,
//         uid: state.authReducer.uid,
//         authUserInfo: state.authReducer.userinformationDB,
//         friendsList: state.hubReducer.friendsList,
//         blockedList: state.hubReducer.blockedList,
//     };
// };

// export default compose(
//     connect(mapStateToProps, {
//         switchTheme,
//         setHub,
//         showAlert,
//         showInputAlert,
//         showSpinner,
//         hideSpinner,
//     }),
// )(AgoraTestPage);

const config = {mode: "rtc", codec: "vp8"}

const useClient = createClient(config);
const useMicrophoneAndCameraTracks = createMicrophoneAndCameraTracks();

const App = () => {
  const client = useClient();
  const { ready, tracks } = useMicrophoneAndCameraTracks();

  return (
    <div style={{backgroundColor: 'red'}}>
    {ready && <AgoraVideoPlayer videoTrack={tracks[1]} style={{height: '100%', width: '100%'}} />}
    </div>
  )
}

export default App;


const stylesAgora = StyleSheet.create({
    container: {
        flex: 1,
    },
    float: {
        position: 'absolute',
        right: 0,
        bottom: 0,
    },
    top: {
        width: '100%',
    },
    input: {
        borderColor: 'gray',
        borderWidth: 1,
        color: 'black',
    },
    local: {
        flex: 1,
    },
    remoteContainer: {
        position: 'absolute',
        left: 0,
        top: 0,
    },
    remote: {
        width: 120,
        height: 120,
    },
});