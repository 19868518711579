/* eslint-disable react-native/no-inline-styles */
import React, { memo, Component } from 'react';
import { View, FlatList, ScrollView, TouchableOpacity } from 'react-native';
import { connect } from 'react-redux';
import { store } from '../../redux/store';
import I18n from '../../i18n';
import NavigationService from '../../navigation/navigationService';
import firebase from 'firebase/app';
import 'firebase/database';
import * as FirebasePaths from '../../constants/firebasePaths';

import Label from '../../components/label';
import AchievementCard from '../../components/cards/clan-achievement-card';

// import actions
import { showAlert } from '../../redux/modules/alert/actions';

import AppStyles from '../../constants/styles';
import styles from './styles';
import { perfectSize } from '../../helpers/deviceHelper';
import Teamcore from './teamscore';

import {
  handleAndroidBackButton,
  removeAndroidBackButtonHandler,
} from '../../helpers/deviceHelper';
import { clearFastImageCache } from '../../services/utils';

class Achievements extends Component {
  constructor(props) {
    super(props);
    const params = props.route.params;
    this.state = {
      teamId: params.teamId,
      achievementsArr: [],
      rewardsArr: [],
      teamAdmin: '',
      secondAdminsMap: new Map(),
    };
  }

  componentDidMount() {
    Teamcore.loadTeamInfo(this.state.teamId).then((teamShot) => {
      teamShot.forEach((item) => {
        if (item.key === 'admin') {
          this.setState({ teamAdmin: item.val() });
        }
      });
    });
    this.secondAdminsRef$ = firebase.database().ref(
      FirebasePaths.FIREBASE_TEAMS +
      '/' +
      this.state.teamId +
      '/' +
      FirebasePaths.FIREBASE_TEAM_SECOND_ADMINS +
      '/',
    );
    this.secondAdminsRef$.on('value', (shot) => {
      let secondAdminsMap = new Map();
      shot.forEach((item) => {
        secondAdminsMap.set(item.key, item.val());
      });
      this.setState({ secondAdminsMap });
    });
    // Load team achievements ..
    Teamcore.loadTeamAchievements(this.state.teamId).then((shot) => {
      let achievementsArr = [];
      shot.forEach((item) => {
        achievementsArr.push(item.val());
      });
      this.setState({ achievementsArr });
    });
    // Load team awards
    Teamcore.loadTeamAwards(this.state.teamId).then((shot) => {
      let rewardsArr = [];
      shot.forEach((item) => {
        rewardsArr.push(item.val());
      });
      this.setState({ rewardsArr });
    });
  }

  componentWillMount() {
    handleAndroidBackButton(() => {
      NavigationService.goBack();
    });
  }

  componentWillUnmount() {
    if (this.secondAdminsRef$) {
      this.secondAdminsRef$.off();
      this.secondAdminsRef$ = null;
    }
    removeAndroidBackButtonHandler();
    clearFastImageCache();
  }

  // To achievements page
  toAchievementsPage = () => {
    NavigationService.navigate('AchievementsPage');
  };

  // This function will redirect the user to the user profile who got the acheievement
  showAchievementAlert = (achObj) => {
    let msg = 'تقدر تصير خرافي وتسوي زيهم؟';
    let ttile = 'انجاز خرافي';
    let more = I18n.t('TEAM_PAGE.achievements_for_more');

    if (this.props.lang === 'ar') {
      if (achObj.descriptionAR != null && achObj.descriptionAR !== undefined) {
        msg = achObj.descriptionAR;
      }
      if (achObj.titleAR != null && achObj.titleAR !== undefined) {
        ttile = achObj.titleAR;
      }
    } else {
      if (achObj.descriptionEN != null && achObj.descriptionEN !== undefined) {
        msg = achObj.descriptionEN;
      }
      if (achObj.titleEN != null && achObj.titleEN !== undefined) {
        ttile = achObj.titleEN;
      }
    }

    this.props.showAlert([
      ttile,
      msg,
      [
        {
          text: 'Close',
          onPress: () => { },
        },
        {
          text: more,
          onPress: () => {
            NavigationService.navigate('AchievementsPage');
          },
        },
      ],
    ]);
  };

  renderAchievementCard = ({ item, index }) => {
    return (
      <TouchableOpacity
        onPress={() => this.showAchievementAlert(item)}
        style={[
          { width: '50%', marginBottom: perfectSize(16) },
          index % 2 === 0
            ? { paddingRight: perfectSize(8) }
            : { paddingLeft: perfectSize(8) },
        ]}>
        <AchievementCard photo={item.photo} />
      </TouchableOpacity>
    );
  };

  renderAwardCard = ({ item, index }) => {
    return (
      <View
        style={[
          { width: '50%', marginBottom: perfectSize(16) },
          index % 2 === 0
            ? { paddingRight: perfectSize(8) }
            : { paddingLeft: perfectSize(8) },
        ]}>
        <AchievementCard photo={item.photo} />
      </View>
    );
  };

  render() {
    const { theme } = this.props;
    const _styles = styles(theme);
    const {
      teamAdmin,
      secondAdminsMap,
      achievementsArr,
      rewardsArr,
    } = this.state;
    const uid = store.getState().authReducer.uid;
    return (
      <View style={_styles.subContent}>
        {(uid === teamAdmin || secondAdminsMap.has(uid)) && (
          <TouchableOpacity
            style={_styles.lockedBadge}
            onPress={this.toAchievementsPage}>
            <Label style={{ fontSize: perfectSize(8) }} bold={true}>
              {I18n.t('TEAM_PAGE.to_achievements_page')}
            </Label>
          </TouchableOpacity>
        )}
        {/* Content */}
        <ScrollView>
          {/* Achievements */}
          <View>
            <Label style={_styles.sectionLabel} size="x_small" bold={true}>
              {I18n.t('TEAM_PAGE.achievements_tab')}
            </Label>
            {achievementsArr.length > 0 ? (
              <FlatList
                data={achievementsArr}
                renderItem={(item) => this.renderAchievementCard(item)}
                numColumns={2}
                keyExtractor={(item, index) => String(index)}
              />
            ) : (
              <View style={{ marginBottom: perfectSize(16) }}>
                <View style={AppStyles.emptyContent}>
                  <Label style={[AppStyles.emptyContentLabel, { marginTop: 0 }]}>
                    {I18n.t('TEAM_PAGE.no_achievements_msg')}
                  </Label>
                </View>
              </View>
            )}
          </View>
          {/* Awards */}
          <View>
            <Label style={_styles.sectionLabel} size="x_small" bold={true}>
              {I18n.t('TEAM_PAGE.awards')}
            </Label>
            {rewardsArr.length > 0 ? (
              <FlatList
                data={rewardsArr}
                renderItem={(item) => this.renderAwardCard(item)}
                numColumns={2}
                keyExtractor={(item, index) => String(index)}
              />
            ) : (
              <View>
                <View style={AppStyles.emptyContent}>
                  <Label style={[AppStyles.emptyContentLabel, { marginTop: 0 }]}>
                    {I18n.t('TEAM_PAGE.no_rewards_msg')}
                  </Label>
                </View>
              </View>
            )}
          </View>
        </ScrollView>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
    lang: state.appReducer.currentLang,
  };
};

export default memo(connect(mapStateToProps, { showAlert })(Achievements));
