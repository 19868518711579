/* eslint-disable react-native/no-inline-styles */
import React, {Component} from 'react';
import {View, Image, FlatList, TouchableOpacity, Alert} from 'react-native';
import {compose} from 'redux';
import {connect} from 'react-redux';
import firebase from 'firebase/app';
import 'firebase/database';

import I18n from '../../i18n';

// import components
import Label from '../../components/label';
import UserAvatar from '../../components/user-avatar';
import Header from '../../components/header';
import {perfectSize} from '../../helpers/deviceHelper';

import styles from './styles';
import AppStyles from '../../constants/styles';
// import consts
import * as FirebasePaths from '../../constants/firebasePaths';

import {set as HubSet} from '../../redux/modules/hub/actions';
import {showAlert} from '../../redux/modules/alert/actions';
class BlockedlistPage extends Component {
  constructor(props) {
    super(props);
    this.mount = true;
    this.listener = null;
    this.state = {
      blockedList: [],
    };
  }

  componentDidMount = async () => {
    await this.loadBlockedList();
    global.rankLogger.LOG_NEW_HP(
      global.rankLogger.VIEW_BLOCK_PAGE,
      'VIEW_BLOCK_PAGE',
    );
  };

  loadBlockedList = async () => {
    const {uid} = this.props;
    const blockedListRef =
      '/' +
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      uid +
      '/' +
      FirebasePaths.FIREBASE_BLOCKED_USERS +
      '/';
    this.listener = firebase.database().ref(blockedListRef);
    this.listener.on('value', async (blockedUsersShot) => {
      const blockedList = [];

      const snap = blockedUsersShot.val();
      for (let key in snap) {
        const item = {};
        item.key = key;
        item.value = snap[key];
        const userPhoto = await this._getUserPhoto(key);
        item.userPhoto = userPhoto;
        blockedList.push(item);
      }

      this.setState({blockedList});
    });
  };

  componentWillUnmount() {
    this.mount = false;
    if (this.listener) {
      this.listener.off();
      this.listener = null;
    }
  }

  _getUserPhoto = async (key) => {
    let userPhoto =
      'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/teams%20default%20photos%20and%20background%2Fdefault_team_logov2.png?alt=media&token=b88bf926-35b9-4568-a80e-ee24110aceb6';
    // Pass the user id and load his/her img

    let ref =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      key +
      '/' +
      FirebasePaths.FIREBASE_DETAILS_ATTR;

    const snapshot = await firebase.database().ref(ref).once('value');

    const shot = snapshot.val();
    let photoUrl = shot._picUrl_;
    if (photoUrl === 'NULL' || photoUrl === 'default') {
      photoUrl =
        'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/teams%20default%20photos%20and%20background%2Fdefault_team_logov2.png?alt=media&token=b88bf926-35b9-4568-a80e-ee24110aceb6';
    }

    userPhoto = photoUrl;
    return userPhoto;
  };

  _renderItem = (item, theme) => {
    return (
      <>
        <View
          style={[
            styles(theme).itemContainer,
            this.state.blockedList.length - 1 !== item.index &&
              styles(theme).borderBottom,
          ]}>
          <UserAvatar
            src={item.item.userPhoto}
            size={perfectSize(48)}
            borderColor="white"
          />
          <Label size="x_small" style={{marginLeft: perfectSize(8)}}>
            {item.item.value}
          </Label>
          <View style={{flex: 1}} />
          <TouchableOpacity onPress={() => this._unblock(item.item)}>
            <Label size="x_small" style={styles(theme).pinkLabel}>
              Unblock
            </Label>
          </TouchableOpacity>
        </View>
      </>
    );
  };

  _unblock = (user) => {
    this.props.showAlert([
      I18n.t('BLOCKED_LIST_PAGE.confirm'),
      '',
      [
        {
          text: I18n.t('BLOCKED_LIST_PAGE.unblock'),
          onPress: () => {
            this._unblcokRequest(user.key);
          },
        },
        {
          text: I18n.t('BLOCKED_LIST_PAGE.cancel'),
          onPress: () => {},
        },
      ],
    ]);
  };

  _unblcokRequest = (key) => {
    const {uid} = this.props;
    const blockedListRef =
      '/' +
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      uid +
      '/' +
      FirebasePaths.FIREBASE_BLOCKED_USERS +
      '/' +
      key;
    firebase.database().ref(blockedListRef).remove();
    // remove from hub.blockedlist
    let _updatedList = this.props.blockedList;
    _updatedList.delete(key);
    this.props.HubSet({blockedList: _updatedList});
  };

  render() {
    const {theme} = this.props;
    return (
      <View style={[styles(theme).container]}>
        <Header
          onBack={this.toLogin}
          text={I18n.t('SETTINGS_PAGE.blocked_list')}
          style={[styles(theme).header]}
        />
        <View style={styles(theme).wrapper}>
          <View
            style={[
              styles(theme).label,
              {
                flex: 1,
              },
            ]}>
            <FlatList
              style={{}}
              data={this.state.blockedList}
              renderItem={(item) => this._renderItem(item, theme)}
              keyExtractor={(item) => item.key.toString()}
              ListFooterComponent={() => {
                return <View style={AppStyles.contentFotter} />;
              }}
              showsVerticalScrollIndicator={false}
            />
          </View>
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
    uid: state.authReducer.uid,
    blockedList: state.hubReducer.blockedList,
  };
};

export default compose(connect(mapStateToProps, {HubSet, showAlert}))(
  BlockedlistPage,
);
