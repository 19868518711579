import {StyleSheet} from 'react-native';
import {perfectSize} from '../../helpers/deviceHelper';

const styles = (theme) =>
  StyleSheet.create({
    label: {
      opacity: 0.56,
      marginBottom: perfectSize(16),
    },
    card: {
      marginBottom: perfectSize(16),
    },
  });

export default styles;
