import firebase from 'firebase/app';
import 'firebase/database';

import * as FirebasePaths from '../../constants/firebasePaths';
import {store} from '../../redux/store';
import {set as setHub} from '../../redux/modules/hub/actions';

import Lobbycore from '../lobby/lobbycore';

export default class CommunityCore {
  constructor() {}

  static deletePrivateChat(chatref) {
    let uid = store.getState().authReducer.uid;
    let userRef =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      uid +
      '/' +
      FirebasePaths.FIREBASE_USER_CHAT_REFERENCES +
      '/' +
      FirebasePaths.FIREBASE_PRIVATE_ATTR +
      '/' +
      chatref;
    firebase.database().ref(userRef).remove();
    let allPrivateChatRefs = store.getState().hubReducer.allPrivateChatRefs;

    delete allPrivateChatRefs[chatref];

    store.dispatch(
      setHub({
        chatPacketsArray: [],
        allPrivateChatRefs,
        communityChatsArr: Array.from(allPrivateChatRefs.values()),
      }),
    );
  }

  // This function will check if the user is banned from chat
  static IS_CHAT_BANNED() {
    let path =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      store.getState().authReducer.uid +
      '/' +
      FirebasePaths.FIREBASE_USER_BAN +
      '/' +
      FirebasePaths.FIREBASE_USER_BAN_CHAT;

    return firebase.database()
      .ref(path)
      .once('value')
      .then((snapshot) => {
        if (snapshot.val() != null) {
          return true;
        } else {
          return false;
        }
      });
  }

  // Change the chat ref opened attr : receive the chat ref , and the chat type
  // Change in the user chat references
  static changeOpenedAttr(chatRef, chatType) {
    let ref;
    // Detect the chat ref by the chat type
    if (chatType === 'PUBLIC') {
      ref =
        FirebasePaths.FIREBASE_USERS_INFO_ATTR +
        '/' +
        store.getState().authReducer.uid +
        '/' +
        FirebasePaths.FIREBASE_USER_CHAT_REFERENCES +
        '/' +
        FirebasePaths.FIREBASE_PUBLIC_ATTR +
        '/' +
        chatRef;
      firebase.database()
        .ref(ref)
        .child(FirebasePaths.FIREBASE_PUBLIC_USER_REF_OPENED)
        .set(true);
    } else {
      // Private
      ref =
        FirebasePaths.FIREBASE_USERS_INFO_ATTR +
        '/' +
        store.getState().authReducer.uid +
        '/' +
        FirebasePaths.FIREBASE_USER_CHAT_REFERENCES +
        '/' +
        FirebasePaths.FIREBASE_PRIVATE_ATTR +
        '/' +
        chatRef;
      firebase.database()
        .ref(ref)
        .child(FirebasePaths.FIREBASE_PUBLIC_USER_REF_OPENED)
        .set(true);
    }
  }

  static changeGroupName(chatRef, name) {
    // Update on user's node
    const ref =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      store.getState().authReducer.uid +
      '/' +
      FirebasePaths.FIREBASE_USER_CHAT_REFERENCES +
      '/' +
      FirebasePaths.FIREBASE_PUBLIC_ATTR +
      '/' +
      chatRef;
    firebase.database()
      .ref(ref)
      .child(FirebasePaths.FIREBASE_PUBLIC_USER_REF_NEW_TITLE)
      .set(name);
    // Update on the other's node
    if (store.getState().hubReducer.groupChatMembers[chatRef] !== undefined) {
      store
        .getState()
        .hubReducer.groupChatMembers[chatRef].members.forEach(
          async (value, key) => {
            if (key !== store.getState().authReducer.uid) {
              // eslint-disable-next-line no-shadow
              const ref =
                FirebasePaths.FIREBASE_USERS_INFO_ATTR +
                '/' +
                key +
                '/' +
                FirebasePaths.FIREBASE_USER_CHAT_REFERENCES +
                '/' +
                FirebasePaths.FIREBASE_PUBLIC_ATTR +
                '/' +
                chatRef;
              await firebase.database()
                .ref(ref)
                .child(FirebasePaths.FIREBASE_PUBLIC_USER_REF_NEW_TITLE)
                .set(name);
            }
          },
        );
    }
    // Update on Chat node
    firebase.database()
      .ref(
        FirebasePaths.FIREBASE_CHAT_ATTR +
          '/' +
          FirebasePaths.FIREBASE_PUBLIC_ATTR +
          '/' +
          chatRef +
          '/' +
          FirebasePaths.FIREBASE_PRIVATE_CHAT_INFO,
      )
      .child(FirebasePaths.FIREBASE_PUBLIC_USER_REF_NEW_TITLE)
      .set(name);

    // Post group name change message
    Lobbycore.onGroupChatNameChanged(chatRef, name);
  }
}
