import firebase from 'firebase/app';
import 'firebase/database';

import * as FirebasePaths from '../../constants/firebasePaths';
import {store} from '../../redux/store';

import notificationManager from '../notification-manager/notification-manager-core';
import gameManager from '../../services/gameManager';

export default class Gamelistcore {
  constructor() {}

  // This function will be used when the user click on the "Yes" button on the delete game dialog
  static deleteGameRef(gameId) {
    const uid = store.getState().authReducer.uid;
    // REF : uid/_games_/_favor_games_
    let favorGamesRef =
      '/' +
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      uid +
      '/' +
      FirebasePaths.FIREBASE_GAMES_REFERENCES +
      '/' +
      FirebasePaths.FIREBASE_FAVOR_GAME_ATTR +
      '/';

    firebase.database()
      .ref(favorGamesRef + '/' + gameId)
      .remove();

    // Check if this game activated notification : to deactivate
    const activiatedGamesNotificationArr = store.getState().hubReducer
      .activiatedGamesNotificationArr;
    const deactiviatedGamesNotificationArr = store.getState().hubReducer
      .deactiviatedGamesNotificationArr;
    if (
      activiatedGamesNotificationArr.includes(gameId) ||
      !deactiviatedGamesNotificationArr.includes(gameId)
    ) {
      notificationManager.deactivateGameNotifications(
        store.getState().hubReducer.gamesList[gameId],
      );
    }
  }

  // This function will used in baclend to add game to user Ref >  uid / games / _favor_game and to the game manager
  static addGame(gameModel) {
    const uid = store.getState().authReducer.uid;
    let favorGamesRef =
      '/' +
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      uid +
      '/' +
      FirebasePaths.FIREBASE_GAMES_REFERENCES +
      '/' +
      FirebasePaths.FIREBASE_FAVOR_GAME_ATTR +
      '/';

    // Add game to user ref : uid/_games_/_favor_games_
    firebase.database()
      .ref(favorGamesRef + '/' + gameModel.gameID)
      .set(gameModel.gameType);

    // Add game to user's game manager
    gameManager.addGame(gameModel);

    // Activiate notification for this game
    // First check if the user intentionally disable notifications for this game
    const deactiviatedGamesNotificationArr = store.getState().hubReducer
      .deactiviatedGamesNotificationArr;
    if (!deactiviatedGamesNotificationArr.includes(gameModel.gameID)) {
      notificationManager.activateGameNotifications(gameModel);
    }
  }

  // ******************  OLD WORK : SEARCH REAL TIME FOR EACH GAME AND WAIT FOR THE RESPONSE ************************

  static searchForGame(value) {}

  // NOTE : THIS FUNCTION SHOULD BE EDITED : IT SHOULD CHECK BY GAMEID NOT GAMENAME
  static validateGame(gamePath, gameType, gameKey) {}

  static addGameToList(gamePath, gameType, gameKey) {}
}
