import {createStackNavigator} from 'react-navigation-stack';
import LeaderboardPage from '../../pages/leaderboard';

export default createStackNavigator(
  {
    LeaderboardPage,
  },
  {
    initialRouteName: 'LeaderboardPage',
    headerMode: 'none',
    navigationOptions: {header: null},
    gestureEnabled: false,
  },
);
