/* eslint-disable react-native/no-inline-styles */
import React, { Component } from 'react';
import { View, TouchableOpacity, Dimensions, Linking } from 'react-native';
import { compose } from 'redux';
import { connect } from 'react-redux';
// import Carousel, { Pagination } from 'react-native-snap-carousel';
import firebase from 'firebase/app';
import 'firebase/database';

// import components
import ImageWithPlaceholder from '../../components/image-with-placeholder';

// import styles
import styles from './styles';
import dimens from '../../constants/dimens';

import * as FirebasePaths from '../../constants/firebasePaths';
import NavigationService from '../../navigation/navigationService';
import topTabService from '../../navigation/topTabService';
import mainMenuService from '../../navigation/mainMenuService';
import AdSpotService from '../../services/adSpot.service';

const { width: viewportWidth } = Dimensions.get('window');

class Slider extends Component {
  constructor(props) {
    super(props);
    this.mount = true;
    this.state = {
      activeSlide: 0,
      slidersArr: [],
      isLoading: true,
    };
  }

  componentDidMount() {
    // Get the sliders imgs
    let path = FirebasePaths.FIREBASE_SLIDERS;
    this.slidersRef$ = firebase.database().ref(path);
    this.slidersRef$.on('value', (slidersShot) => {
      let slidersArr = [];
      slidersShot.forEach((item) => {
        slidersArr.push(item.val());
      });
      if (this.mount) {
        this.setState({ slidersArr });
      }

      const preloadUri = [];
      slidersArr.map(slider => {
        if (slider.img) {
          preloadUri.push({
            uri: slider.img
          })
        }
      })
      setTimeout(() => {
        if (this.mount) {
          this.setState({ isLoading: false });
        }
      }, 1000);
    });
  }

  componentWillUnmount() {
    if (this.slidersRef$) {
      this.slidersRef$.off();
      this.slidersRef$ = null;
    }
    this.mount = false;
  }

  // This function will open the ad depend on the ad type : PAGE or WEB
  openAd = (slide) => {
    // Log Rank Hp
    global.rankLogger.LOG_NEW_HP(global.rankLogger.SLIDE_CLICK, 'SLIDE_CLICK');

    // Log feature: Slide click
    global.featuresLogger.LOG_SLIDE_CLICK();
    // Type PAGE : open the ad page  inside the app
    if (slide.adType === 'PAGE') {
      NavigationService.moveToScreenInStack('HomeStack', 'AdPage', {
        data: slide,
      });
    } else if (slide.adType === 'WEB') {
      // Open the website using  inAppBrowser
      Linking.openURL(slide.webTarget);
    } else if (slide.adType === 'AD_SPOT_PAGE') {
      // Log the feature
      global.featuresLogger.MAIN_SLIDE_AD_CLICK();
      NavigationService.moveToScreenInStack('HomeStack', 'AdPage', {
        data: slide,
      });
    } else if (slide.adType === 'AD_SPOT_URL') {
      // Linking.openURL(slide.webTarget);
      AdSpotService.checkAdUrl({ url: slide.webTarget });
      // Log the feature
      global.featuresLogger.MAIN_SLIDE_AD_CLICK();
    } else if (slide.adType.toString().includes('Activity')) {
      // Detect the directed page
      if (slide.adType.toString().includes('BLOCKEDLIST')) {
        NavigationService.moveToScreenInStack(
          'SettingsStack',
          'BlockedlistPage',
        );
      } else if (slide.adType.toString().includes('CHANGEPASSWORD')) {
        NavigationService.moveToScreenInStack(
          'SettingsStack',
          'ChangepasswordPage',
        );
      } else if (slide.adType.toString().includes('CREATEREQUEST')) {
        NavigationService.navigate('NewRequestPage');
      } else if (slide.adType.toString().includes('EDITPROFILE')) {
        NavigationService.moveToScreenInStack(
          'SettingsStack',
          'EditProfilePage',
        );
      } else if (slide.adType.toString().includes('CLANS')) {
        NavigationService.navigate('TabStack');
        setTimeout(() => {
          mainMenuService.navigate('Home');
          topTabService.navigate('Requests');
          setTimeout(() => {
            topTabService.navigate('Clans');
          }, 150);
        }, 150);
      } else if (slide.adType.toString().includes('CREATECLAN')) {
        NavigationService.moveToScreenInStack(
          'HomeStack',
          'JoinChampionshipPage',
          {
            CAME_FROM: 'NEW_TEAM',
          },
        );
      } else if (slide.adType.toString().includes('JOINCLAN')) {
        NavigationService.moveToScreenInStack(
          'HomeStack',
          'JoinChampionshipPage',
          {
            CAME_FROM: 'JOIN_TEAM',
          },
        );
      } else if (slide.adType.toString().includes('TEAM')) {
        NavigationService.navigate('TeamPage', {
          TEAM_ID: slide.teamId,
          CAME_FROM: 'TEAMS',
        });
      } else if (slide.adType.toString().includes('NEWS')) {
        NavigationService.navigate('NewsPage');
      } else if (slide.adType.toString().includes('LEADERBOARD')) {
        NavigationService.moveToScreenInStack('HomeStack', 'LeaderboardPage');
      } else if (slide.adType.toString().includes('AGENTS')) {
        NavigationService.moveToScreenInStack('SettingsStack', 'AgentsPage');
      } else if (slide.adType.toString().includes('FORGOTPASSWORD')) {
        NavigationService.moveToScreenInStack(
          'AuthStack',
          'ForgotpasswordPage',
        );
      } else if (slide.adType.toString().includes('FRIENDSLIST')) {
        NavigationService.navigate('FriendsListPage');
      } else if (slide.adType.toString().includes('GAMESLIST')) {
        NavigationService.moveToScreenInStack('ProfileStack', 'GamesListPage');
      } else if (slide.adType.toString().includes('NOTIFICATIONMANAGER')) {
        NavigationService.moveToScreenInStack(
          'SettingsStack',
          'NotificationManagerPage',
        );
      } else if (slide.adType.toString().includes('REQUESTSHUB')) {
        NavigationService.moveToScreenInStack('HomeStack', 'RequestshubPage');
      } else if (slide.adType.toString().includes('SEARCHREQUEST')) {
        NavigationService.moveToScreenInStack('HomeStack', 'SearchrequestPage');
      } else if (slide.adType.toString().includes('SETTINGS')) {
        NavigationService.navigate('SettingsPage');
      } else if (slide.adType.toString().includes('SUPPORT')) {
        NavigationService.moveToScreenInStack('SettingsStack', 'SupportPage');
      } else if (slide.adType.toString().includes('TERMS')) {
        NavigationService.moveToScreenInStack('AuthStack', 'TermsPage');
      } else if (slide.adType.toString().includes('CHAMPIONSHIPS')) {
        NavigationService.navigate('TabStack');
        setTimeout(() => {
          mainMenuService.navigate('Home');
          topTabService.navigate('Requests');
          setTimeout(() => {
            topTabService.navigate('Championships');
          }, 150);
        }, 150);
      }
    }
  };

  renderAdsCard = ({ item, index }) => {
    const { theme } = this.props;
    const _styles = styles(theme);
    return (
      <TouchableOpacity style={_styles.slide} onPress={() => this.openAd(item)}>
        <ImageWithPlaceholder
          uri={item.img}
          style={{ width: '100%', height: '100%' }}
        />
      </TouchableOpacity>
    );
  };

  render() {
    const { theme } = this.props;
    const _styles = styles(theme);
    const { slidersArr, isLoading } = this.state;
    if (isLoading) {
      return (
        <View style={_styles.ads}>
          
            <View style={{ height: '100%' }} />
          
        </View>
      )
    }
    return (
      <>
        {slidersArr.length > 0 ? (
          <View style={_styles.ads}>
            {/* <Carousel
              ref={(c) => {
                this._carousel = c;
              }}
              data={slidersArr}
              renderItem={this.renderAdsCard}
              sliderWidth={parseFloat(
                viewportWidth - dimens.HEADER_PADDING_HORIZONTAL * 2,
              )}
              itemWidth={viewportWidth - dimens.HEADER_PADDING_HORIZONTAL * 2}
              inactiveSlideOpacity={1}
              inactiveSlideScale={1}
              loop={true}
              loopClonesPerSide={slidersArr.length}
              autoplay={true}
              autoplayDelay={3000}
              autoplayInterval={3000}
              onSnapToItem={(index) => {
                if (this.mount) {
                  this.setState({ activeSlide: index })
                }
              }}
            />
            <Pagination
              dotsLength={slidersArr.length}
              activeDotIndex={this.state.activeSlide}
              containerStyle={_styles.pagination}
              dotStyle={
                ([_styles.dot], { backgroundColor: theme.primary_btn_background })
              }
              inactiveDotStyle={[
                _styles.inactiveDot,
                { backgroundColor: theme.secondary_background },
              ]}
              inactiveDotScale={1}
              carouselRef={this._carousel}
              tappableDots={!!this._carousel}
            /> */}
          </View>
        ) : null}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
    lang: state.appReducer.currentLang,
  };
};

export default compose(connect(mapStateToProps, {}))(Slider);
