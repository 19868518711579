import * as React from 'react';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { View } from 'react-native';

import MainPage from '../../pages/main';
import TimelinePage from '../../pages/timeline';
import CommunityPage from '../../pages/community';
import UserprofilePage from '../../pages/userprofile';
import HootsPage from '../../pages/hoots';
import AuthHeader from '../../components/header-with-notification';
import AppStyles from '../../constants/styles';
import LaunchPopupModal from '../../components/launchPopup'

// tab bar
import { NavigationContainer } from '@react-navigation/native';
import MyTabBar from '../../components/custom-bottom-tab';
const Tab = createBottomTabNavigator();
import I18n from '../../i18n';

import { clearFastImageCache } from '../../services/utils';
import LaunchPopupCore from '../../pages/mainmenu/launchPopupCore';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showLaunchPopup: false,
      launchPopup: {},
      launchPopupId: null,
    }
  }

  async componentDidMount() {
    await this.checkLaunchPopup();
  }

  async checkLaunchPopup() {
    const activePopupId = await LaunchPopupCore.getActivePopupId();
    if (activePopupId) {
      const userSeenActivePopup = await LaunchPopupCore.userAlreadySeenPopup(activePopupId);
      if (!userSeenActivePopup) {
        const activePopup = await LaunchPopupCore.loadActivePopup(activePopupId);
        if (activePopup.val()) {
          await this.showPopup(activePopupId, activePopup.val())
        }
      }
    }
  }

  async showPopup(popupId, popup) {
    global.featuresLogger.POPUP_VIEW();
    this.setState({
      showLaunchPopup: true,
      launchPopup: popup,
      launchPopupId: popupId
    })
  }

  onHideLaunchPopup = async () => {
    await LaunchPopupCore.saveUserLaunchPopup(this.state.launchPopupId);
    this.setState({
      showLaunchPopup: false
    })
  }

  render() {
    const { showLaunchPopup, launchPopup, launchPopupId } = this.state;

    return (
      <NavigationContainer>
        <View style={AppStyles.rootContainer}>
          {
            showLaunchPopup &&
            <LaunchPopupModal
              popup={launchPopup}
              popupId={launchPopupId}
              isVisible={showLaunchPopup}
              onHide={this.onHideLaunchPopup}
            />
          }
          <Tab.Navigator
            tabBar={(props) => <MyTabBar {...props} lang={this.props.lang} onTabChanged={async (index) => {
              if (index === 0) {
                await this.checkLaunchPopup();
              }
            }} />}
          >
            <Tab.Screen
              name="Home"
              component={MainPage}
              options={{ tabBarLabel: I18n.t('TABS.home') }}
            />
            <Tab.Screen
              name="Hops"
              component={TimelinePage}
              options={{ tabBarLabel: I18n.t('TABS.hops') }}
            />
            <Tab.Screen
              name="Hoots"
              component={HootsPage}
              options={{ tabBarLabel: I18n.t('TABS.hoots') }}
            />
            <Tab.Screen
              name="Chat"
              component={CommunityPage}
              options={{
                tabBarLabel: I18n.t('TABS.chats'),
                tabBarBadge: this.props.tabsUnreadMessages.length,
              }}
            />
            <Tab.Screen
              name="Profile"
              component={UserprofilePage}
              options={{ tabBarLabel: I18n.t('TABS.profile') }}
            />
          </Tab.Navigator>
        </View>
      </NavigationContainer>

    );
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
    lang: state.appReducer.currentLang,
    tabsUnreadMessages: state.hubReducer.tabsUnreadMessages,
  };
};

export default compose(connect(mapStateToProps, {}))(App);
