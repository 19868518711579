/* eslint-disable react-native/no-inline-styles */
import React, { Component } from 'react';
import { View, TouchableOpacity, StyleSheet, Keyboard, Linking } from 'react-native';
import Modal from 'react-native-modal';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { CheckBox } from 'react-native-elements';

import { showAlert } from '../../redux/modules/alert/actions';

import Label from '../label';

import { perfectSize } from '../../helpers/deviceHelper';
import ImageWithPlaceholder from '../image-with-placeholder';
import LaunchPopupCore from '../../pages/mainmenu/launchPopupCore';
import AdSpotService from '../../services/adSpot.service';

class LaunchPopupModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            surveySelectedItem: null,
        };
    }

    renderCheckBox = (item) => {
        const { popup: { surveySelectionColor } } = this.props;
        return (
            <CheckBox
                key={item.key.toString()}
                containerStyle={{
                    backgroundColor: 'transparent',
                    borderWidth: 0,
                    alignSelf: 'flex-start',
                    padding: 0
                }}
                textStyle={{
                    color: 'white',
                    opacity: 0.5,
                    fontSize: perfectSize(12),
                }}
                checkedIcon="dot-circle-o"
                uncheckedIcon="circle-o"
                checkedColor={surveySelectionColor || this.props.theme.primary_btn_background}
                title={<Label style={styles.surveyText}>{item.value}</Label>}
                checked={this.state.surveySelectedItem === item.key}
                onPress={() => {
                    this.setState({
                        surveySelectedItem: item.key
                    })
                }}
            />
        );
    }

    renderSurveyOptions = (surveyOptions) => {
        const objLen = Object.keys(surveyOptions).length;
        const keys = Object.keys(surveyOptions);
        const values = Object.values(surveyOptions);
        const items = [];
        for (let i = 0; i < objLen; i++) {
            items.push({
                key: keys[i],
                value: values[i]
            })
        }
        return (
            <View style={{}}>
                {
                    items.map(item => (
                        this.renderCheckBox(item)
                    ))
                }
            </View>
        )
    }

    renderButton = (showButton, buttonStyleType, buttonBackgroundColor, buttonText, buttonClick, popupType, customButtonAction) => {
        if (showButton) {
            return <TouchableOpacity
                style={[styles.buttonStyle,
                buttonStyleType === 'SOLID' ? {
                    backgroundColor: buttonBackgroundColor,
                    borderColor: buttonBackgroundColor,
                } : {
                    borderColor: buttonBackgroundColor,
                }
                ]}
                onPress={() => {
                    this.buttonAction(popupType, buttonClick, customButtonAction);
                }}>
                <Label style={[styles.buttonText,
                buttonStyleType === 'SOLID' ? {
                    color: 'white'
                } : {
                    color: buttonBackgroundColor,
                }
                ]}>{buttonText}</Label>
            </TouchableOpacity>;
        }
        return <View />
    }

    buttonAction = async (popupType, buttonClick, customButtonAction) => {
        if (popupType === 'REDIRECTOR') {
            if (buttonClick === 'SKIP') {
                // just hide
            } else {
                const ad = this.props.popup;
                ad.url = buttonClick;
                AdSpotService.checkAdUrl(ad);
            }
        } else if (popupType === 'SURVEY') {
            if (this.state.surveySelectedItem) {
                await LaunchPopupCore.saveUserSurveyCollection(this.props.popupId, this.state.surveySelectedItem);
            } else {
                this.props.showAlert([
                    'Survey Options',
                    'Please select survey option to SUBMIT',
                    [

                        {
                            text: 'Ok',
                            onPress: () => { },
                        },
                    ],
                    true
                ])
                return;
            }
        }
        customButtonAction();
        this.props.onHide();
    }

    primaryBtnClick = () => {
        global.featuresLogger.POPUP_PRIMARY_BTN_CLICK();
    }

    secondaryBtnClick = () => {
        global.featuresLogger.POPUP_SECONDARY_BTN_CLICK();
    }

    render() {
        const { popup } = this.props;
        const {
            isPopupDismissible,
            title,
            titleColor,
            popupBottomlineColor,
            img,
            imgSize,
            showImg,
            popupType,
            primaryButtonBackgroundColor,
            primaryButtonClick,
            primaryButtonStyleType,
            primaryButtonText,
            secondaryButtonBackgroundColor,
            secondaryButtonClick,
            secondaryButtonStyleType,
            secondaryButtonText,
            showPrimaryButton,
            showSecondaryButton,
            description,
            descriptionColor,
            popupBackground,
            surveyOptions
        } = popup;
        const imgStyle = imgSize === 'LANDSCAPE' ? styles.imgLandscape : imgSize === 'SQUARE' ? styles.imgSquare : styles.imgLogo;
        return (
            <Modal
                isVisible={this.props.isVisible}
                onBackdropPress={() => {
                    if (isPopupDismissible) {
                        global.featuresLogger.POPUP_DISMISS();
                        this.props.onHide();
                    } else {
                        // do nothing
                    }
                }}
                coverScreen={true}
                animationIn={'pulse'}
                animationOut={'fadeOut'}
                style={[styles.modalStyle,]}
                backdropOpacity={0.7}>
                <View style={[styles.wrapper]}>
                    <View style={[styles.box, { backgroundColor: popupBackground, borderBottomColor: popupBottomlineColor, borderBottomWidth: 5 }]}>
                        <Label bold={true} style={[styles.title, { color: titleColor }]}>
                            {title}
                        </Label>
                        {
                            showImg &&
                            <View style={[styles.img, imgStyle]}>
                                <ImageWithPlaceholder
                                    uri={img}
                                    style={{ width: '100%', height: '100%', borderRadius: perfectSize(10) }}
                                    resizeMode={'cover'}
                                />
                            </View>
                        }

                        <Label style={[styles.description, { color: descriptionColor }]}>
                            {description}
                        </Label>

                        {
                            popupType === 'SURVEY' &&
                            this.renderSurveyOptions(surveyOptions)
                        }

                        {this.renderButton(showPrimaryButton, primaryButtonStyleType, primaryButtonBackgroundColor, primaryButtonText, primaryButtonClick, popupType, this.primaryBtnClick)}
                        {this.renderButton(showSecondaryButton, secondaryButtonStyleType, secondaryButtonBackgroundColor, secondaryButtonText, secondaryButtonClick, popupType, this.secondaryBtnClick)}

                    </View>
                </View>
            </Modal>
        );
    }
}

LaunchPopupModal.propTypes = {
    popup: PropTypes.object,
    popupId: PropTypes.string,
}

const mapStateToProps = (state) => {
    return {
        theme: state.appReducer.currentTheme,
    };
};

export default compose(connect(mapStateToProps, { showAlert }))(LaunchPopupModal);

const styles = StyleSheet.create({
    modalStyle: {
        // marginHorizontal: 0,
        // margin: 0,
        margin: 'auto',
    },
    wrapper: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    box: {
        minWidth: 400,
        maxWidth: 600,
        backgroundColor: '#181f29',
        // width: '90%',
        paddingHorizontal: perfectSize(32),
        paddingVertical: perfectSize(12)
    },
    title: {
        color: 'white',
        fontSize: perfectSize(16),
        textAlign: 'center',
        marginTop: perfectSize(16),
    },
    description: {
        fontSize: perfectSize(12),
        textAlign: 'center',
        marginVertical: perfectSize(10)
    },
    buttonContainer: {
        marginTop: perfectSize(32),
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    buttonStyle: {
        marginLeft: perfectSize(8),
        borderWidth: 1,
        borderRadius: perfectSize(10),
        height: perfectSize(42),
        justifyContent: 'center',
        paddingHorizontal: perfectSize(12),
        marginVertical: perfectSize(12),
    },
    buttonText: {
        color: 'white',
        fontSize: perfectSize(14),
        textAlign: 'center'
    },
    img: {
        marginVertical: perfectSize(15),
        borderRadius: perfectSize(10),
        alignSelf: 'center'
    },
    imgLandscape: {
        width: '100%',
        height: perfectSize(150),
    },
    imgSquare: {
        width: perfectSize(200),
        height: perfectSize(200),
    },
    imgLogo: {
        width: perfectSize(80),
        height: perfectSize(80),
    },
    surveyText: {
        marginLeft: perfectSize(12),
        fontSize: perfectSize(12)
    }
});
