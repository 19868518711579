import {StyleSheet} from 'react-native';
import {perfectSize} from '../../helpers/deviceHelper';
import Dimens from '../../constants/dimens';

const styles = (theme) =>
  StyleSheet.create({
    container: {
      flex: 1,
      width: '100%',

      backgroundColor: theme.secondary_background,
    },
    wrapper: {
      flex: 1,
      paddingHorizontal: Dimens.HEADER_PADDING_HORIZONTAL,
      backgroundColor: theme.primary_background,
      borderTopLeftRadius: Dimens.BODY_BORDER_TOP_RADIUS,
      borderTopRightRadius: Dimens.BODY_BORDER_TOP_RADIUS,
      paddingBottom: Dimens.CONTENT_PADDING_BOTTOM,
    },
    header: {
      backgroundColor: theme.secondary_background,
    },
    label: {
      marginTop: perfectSize(16),
    },
    itemContainer: {
      height: perfectSize(60),
      flexDirection: 'row',
      alignItems: 'center',
    },
    borderBottom: {
      borderBottomWidth: 1,
      borderBottomColor: '#FFFFFF0C',
    },
    itemImage: {
      width: perfectSize(48),
      height: perfectSize(48),
      borderRadius: perfectSize(12),
    },
    btn_on: {
      height: perfectSize(24),
      borderRadius: 5,
    },
    badge: {
      width: perfectSize(16),
      height: perfectSize(16),
      marginLeft: perfectSize(8),
    },
    pinkLabel: {
      color: theme.primary_btn_background,
    },
  });

export default styles;
