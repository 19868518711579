import {StyleSheet} from 'react-native';
import {perfectSize} from '../../helpers/deviceHelper';
import Dimens from '../../constants/dimens';

const styles = (theme) =>
  StyleSheet.create({
    container: {
      flex: 1,
      width: '100%',

      backgroundColor: theme.secondary_background,
    },
    wrapper: {
      flex: 1,
      paddingHorizontal: Dimens.HEADER_PADDING_HORIZONTAL,
      backgroundColor: theme.primary_background,
      borderTopLeftRadius: Dimens.BODY_BORDER_TOP_RADIUS,
      borderTopRightRadius: Dimens.BODY_BORDER_TOP_RADIUS,
    },
    header: {
      backgroundColor: theme.secondary_background,
    },

    logo_wrapper: {
      padding: Dimens.dp32,
      alignItems: 'center',
    },
    avatarContainer: {
      width: perfectSize(48),
      height: perfectSize(48),
    },
    badge: {
      width: perfectSize(16),
      height: perfectSize(16),
      marginLeft: perfectSize(8),
    },
    btnLogin: {
      height: perfectSize(52),
      width: '100%',
    },
    itemContainer: {
      alignItems: 'center',
      justifyContent: 'center',
      padding: perfectSize(8),
      alignSelf: 'flex-start',
      width: '33.33%',
    },
    mainContainer: {
      flexDirection: 'column',
      flex: 1,
      marginVertical: 24,
    },
    listContainer: {
      flex: 1,
      marginVertical: perfectSize(16),
    },
  });

export default styles;
