import React, {memo, Component} from 'react';
import {View, StyleSheet} from 'react-native';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import Label from '../label';
import {perfectSize} from '../../helpers/deviceHelper';

class GroupMessageHolder extends Component {
  render() {
    const {theme, item} = this.props;
    const _styles = styles(theme);
    return (
      <View style={_styles.wrapper}>
        <View style={_styles.container}>
          <Label size="x_small" style={_styles.message} selectable={true}>
            {item.message}
          </Label>
        </View>
      </View>
    );
  }
}

const styles = (theme) =>
  StyleSheet.create({
    wrapper: {
      alignSelf: 'center',
      marginBottom: perfectSize(12),
    },
    container: {
      backgroundColor: '#181F29',
      paddingHorizontal: perfectSize(32),
      paddingVertical: perfectSize(12),
      borderRadius: 12,
      alignSelf: 'baseline',
    },
    message: {
      color: '#A2A5A9',
    },
  });

GroupMessageHolder.propTypes = {
  item: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
  };
};

export default memo(connect(mapStateToProps)(GroupMessageHolder));
