/* eslint-disable react-native/no-inline-styles */
import React, { memo, Component } from 'react';
import { View, StyleSheet, TouchableOpacity, Dimensions, Image } from 'react-native';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import I18n from '../../i18n';

import AppStyles from '../../constants/styles';
import { perfectSize } from '../../helpers/deviceHelper';
import { CROWN } from '../../constants/images';

import UserAvatar from '../user-avatar';
import Label from '../label';
import { getHumantime } from '../../services/utils';
import NavigationService from '../../navigation/navigationService';
// import AutoHeightImage from 'react-native-auto-height-image';
import Postcore from '../../pages/post/postcore';
import Feather from 'react-native-vector-icons/Feather';
import Entypo from 'react-native-vector-icons/Entypo';
import Sticker from '../sticker-image';
import LocalImageOpacity from '../local-image-opacity';
import ImageWithPlaceholder from '../image-with-placeholder';

class ReplyDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      status: 'offline',
      rank: -1,
      userBorderColor: '#880e4a',
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    let status = await Postcore.getUserStatus(this.props.item.uid);
    let rank = await Postcore.getUserRank(this.props.item.uid);
    let userBorderColor = await Postcore.getUserBorderColor(
      this.props.item.uid,
    );

    this.setState({
      status: status,
      rank: rank,
      userBorderColor: userBorderColor,
    });
  };

  _toFriendProfile = (uid) => {
    NavigationService.navigate('ViewfriendprofilePage', {
      FRIEND_KEY: uid,
    });
  };

  _buildDescriptionHtml = (reply) => {
    const { theme } = this.props;
    const _styles = styles(theme);
    let _views = [];
    if (reply.type === 'TEXT_ONLY') {
      if (!reply.description.includes('@') || !reply.mentionedMap) {
        _views.push(
          <Label
            size="x_small"
            style={_styles.message}
            selectable={true}
            key={_views.length}>
            {reply.description}
          </Label>,
        );
      } else {
        const words = reply.description.split(' ');
        let temp = '';

        for (var word of words) {
          if (word.charAt(0) === '@') {
            let uid;

            for (var key in reply.mentionedMap) {
              let value = reply.mentionedMap[key];

              if (word === '@' + value) {
                uid = key;
              }
            }

            if (uid) {
              if (temp.length > 0) {
                _views.push(
                  <Label
                    size="x_small"
                    style={_styles.message}
                    selectable={true}>
                    {temp}{' '}
                  </Label>,
                );

                temp = '';
              }

              _views.push(
                <TouchableOpacity onPress={() => this._toFriendProfile(uid)}>
                  <Label
                    size="x_small"
                    style={[_styles.message, _styles.hashLink]}>
                    {word}{' '}
                  </Label>
                </TouchableOpacity>,
              );
            } else {
              temp = temp + word + ' ';
            }
          } else {
            temp = temp + word + ' ';
          }
        }

        if (temp.length > 0) {
          _views.push(
            <Label size="x_small" style={_styles.message} selectable={true}>
              {temp}
            </Label>,
          );

          temp = '';
        }
      }
    }
    return (
      <View
        style={{
          flexDirection: 'row',
          flexWrap: 'wrap',
        }}>
        {_views}
      </View>
    );
  };

  render() {
    const { theme, onDelete, showDelete } = this.props;
    const _styles = styles(theme);
    const item = this.props.item;
    return (
      <View style={_styles.container}>
        <View style={[_styles.wrapper, this.props.style]}>
          {this.props.isAdmin && (
            <LocalImageOpacity
              source={CROWN}
              style={_styles.crown}
              resizeMode="contain"
            />
          )}

          <View style={_styles.userInfo}>
            <TouchableOpacity
              onPress={() => {
                NavigationService.navigate('ViewfriendprofilePage', {
                  FRIEND_KEY: this.props.item.uid,
                });
              }}>
              <UserAvatar
                src={item.img}
                showStatus={true}
                online={this.state.status === 'online' ? true : false}
                borderColor={this.state.userBorderColor}
              />
            </TouchableOpacity>
            <View style={_styles.nametimestamp}>
              <View style={_styles.flexRow}>
                <Label size="x_small" selectable={true}>
                  {item.username}
                </Label>
                {this.state.rank > 0 && (
                  <ImageWithPlaceholder
                    uri={global.rankLogger.getRankImg(this.props.item.rank)}
                    style={global.rankLogger.getRankStyle(this.props.item.rank)}
                    resizeMode="contain"
                  />
                )}
                { !!item.hops_icon && (
                  <ImageWithPlaceholder
                    uri={item.hops_icon}
                    style={AppStyles.rankImg}
                    resizeMode="contain"
                  />
                )}
              </View>
              <Label size="small" style={_styles.timestamp}>
                {getHumantime(item.timeStamp)}
              </Label>
            </View>
            {!item.delete && showDelete && (
              <TouchableOpacity
                style={_styles.actionMenu}
                onPress={() => {
                  if (onDelete) {
                    onDelete();
                  }
                }}>
                <View style={_styles.downContainer}>
                  <Feather
                    name="chevron-down"
                    color="gray"
                    size={perfectSize(20)}
                  />
                </View>
              </TouchableOpacity>
            )}
          </View>

          {item.delete === true ? (
            <View
              style={{
                flexDirection: 'row',
              }}>
              <Entypo
                name="block"
                color="gray"
                size={perfectSize(16)}
                style={{
                  marginTop: perfectSize(7),
                  marginRight: perfectSize(4),
                }}
              />
              <Label size="x_small" style={_styles.deleted}>
                {I18n.t('POST_PAGE.message_deleted')}
              </Label>
            </View>
          ) : (
            <>
              {item.type === 'TEXT_ONLY' && this._buildDescriptionHtml(item)}
              {item.type === 'IMAGE' && (
                <TouchableOpacity
                  onPress={() => {
                    NavigationService.navigate('PhotoViewerPage', {
                      TITLE: 'Hop Image',
                      IMG: item.description,
                      defaultBack: true,
                    });
                  }}>
                  <View style={_styles.imageContainer}>
                    <Image
                      width={Dimensions.get('window').width * 0.65}
                      source={{ uri: item.description }}
                      style={_styles.image}
                    />
                  </View>
                  <Label size="x_small" style={_styles.caption}>
                    {item.caption}
                  </Label>
                </TouchableOpacity>
              )}
              {item.type === 'STICKER' && (
                <View
                  style={{
                    marginTop: perfectSize(12),
                    marginLeft: perfectSize(16),
                  }}>
                  <Sticker url={item.description} />
                </View>
              )}
            </>
          )}
        </View>
      </View>
    );
  }
}

ReplyDetail.propTypes = {
  data: PropTypes.object,
  isJoined: PropTypes.bool,
  showLike: PropTypes.bool,
  onJoin: PropTypes.func,
  onDelete: PropTypes.func,
  showDelete: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
  };
};

export default memo(connect(mapStateToProps)(ReplyDetail));

const styles = (theme) =>
  StyleSheet.create({
    container: {},
    wrapper: {
      marginHorizontal: perfectSize(24),
    },
    userInfo: {
      flexDirection: 'row',
      alignItems: 'center',
      elevation: 10,
    },
    nametimestamp: {
      marginLeft: perfectSize(4),
    },
    timestamp: {
      opacity: 0.56,
      marginTop: perfectSize(2),
    },
    message: {
      opacity: 0.64,
      marginTop: perfectSize(8),
    },
    caption: {
      opacity: 0.64,
      marginTop: perfectSize(8),
      marginLeft: perfectSize(24),
    },
    deleted: {
      opacity: 0.5,
      marginTop: perfectSize(8),
      fontWeight: '400',
      fontStyle: 'italic',
    },
    actionMenu: {
      position: 'absolute',
      top: 0,
      right: 0,
    },
    arrowDown: {
      width: perfectSize(12.05),
      height: perfectSize(6),
      resizeMode: 'contain',
    },
    playStation: {
      width: perfectSize(14.64),
      height: perfectSize(12),
      resizeMode: 'contain',
    },
    ecllipse: {
      width: perfectSize(24),
      height: perfectSize(24),
      borderColor: '#A2A5A9',
      borderWidth: 1,
      borderRadius: perfectSize(12),
      alignItems: 'center',
      justifyContent: 'center',
      top: -perfectSize(5),
    },
    flexRow: {
      flexDirection: 'row',
    },
    likeWrapper: {
      justifyContent: 'flex-end',
      marginTop: perfectSize(16),
    },
    thumb: {
      marginLeft: perfectSize(4),
    },
    upvoteText: {
      color: theme.primary_btn_background,
    },
    downvoteText: {
      opacity: 0.56,
    },

    hashLink: {
      marginHorizontal: perfectSize(4),
      opacity: 1,
      color: '#4495DA',
    },
    imageContainer: {
      width: Dimensions.get('window').width * 0.65 + perfectSize(16),
      height: 250,
      backgroundColor: '#181F29',
      padding: perfectSize(8),
      borderBottomRightRadius: perfectSize(16),
      borderBottomLeftRadius: perfectSize(16),
      borderTopRightRadius: perfectSize(16),
      alignSelf: 'flex-end',
      marginTop: perfectSize(8),
      marginRight: perfectSize(16),
    },
    image: {
      borderBottomRightRadius: perfectSize(8),
      borderBottomLeftRadius: perfectSize(8),
      borderTopRightRadius: perfectSize(8),
      borderColor: '#181F29',
      height: 230,
    },
    crown: {
      width: perfectSize(32),
      height: perfectSize(20),
    },
  });
