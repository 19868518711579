import React, {Component} from 'react';
import {View, ScrollView} from 'react-native';
import {compose} from 'redux';
import {connect} from 'react-redux';
import firebase from 'firebase/app';
import 'firebase/database';

import I18n from '../../i18n';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import Feather from 'react-native-vector-icons/Feather';
import Entypo from 'react-native-vector-icons/Entypo';
import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';
import * as FirebasePaths from '../../constants/firebasePaths';
import NavigationService from '../../navigation/navigationService';

// import components
import Header from '../../components/header';
import Label from '../../components/label';
import Spinner from '../../components/spinner';
import TextInput from '../../components/text-input';
import Picker from '../../components/picker';
import PrimaryButton from '../../components/buttons/primary-button';

// import actions
import {showAlert} from '../../redux/modules/alert/actions';

import AppStyles from '../../constants/styles';
import styles from './styles';
import {perfectSize} from '../../helpers/deviceHelper';
import {presentToast} from '../../helpers/toastHelper';

import {EDIT_PROFILE_GENDER} from '../../constants/selects';

class ChampionshipRegistrationPage extends Component {
  constructor(props) {
    super(props);
    const params = props.navigation.state.params;
    this.state = {
      championshipObj: params.CHAMPIONSHIP_OBJ,
      showName: false,
      namePlacholder: 'الإسم',
      nameModel: '',
      showPhone: false,
      phonePlaceholder: 'رقم الجوال',
      phoneNumberModel: '',
      showGameAccount: false,
      gameAccountPlaceholder: 'حسابك في اللعبة',
      gameAccountModel: '',
      gameProviderImg: '',
      showGameRank: false,
      gameRankPlaceholder: 'رانكك في اللعبة',
      gameRankModel: '',
      gameRanksArr: [],
      showAge: false,
      agePlaceholder: 'العمر',
      ageKey: '',
      ageValue: '',
      ageOptionsMap: new Map(),
      ageOptionsArr: [],
      showGender: false,
      genderPlaceholder: 'الجنس',
      genderModel: '',
      showReachSource: false,
      reachSourcePlaceholder: 'كيف عرفت عن البطولة؟',
      reachKey: '',
      reachValue: '',
      reachSourceOptionsMap: new Map(),
      reachSourceOptionsArr: [],
      IS_CLAN_SELECTED: params.IS_CLAN_SELECTED,
      clanObj: params.CLAN_OBJ,
      loading: true,
    };
  }

  componentDidMount() {
    // Load registration fields
    this.loadRegistrationFields().then((snapshot) => {
      snapshot.forEach((item) => {
        this.fieldsServerValidator(item.key, item.val());
      });
      this.setState({loading: false});
    });
  }

  // This function to show and hide fileds / load placeholders / load fields options
  fieldsServerValidator(fieldKey, payload) {
    let isEn = false;
    this.props.lang === 'en' ? (isEn = true) : (isEn = false);

    if (fieldKey === 'age') {
      // Show/hide
      let showAge,
        agePlaceholder,
        ageOptionsMap = new Map(),
        ageOptionsArr = [];
      payload.show === true ? (showAge = true) : (showAge = false);
      // Load Placeholder
      if (isEn) {
        payload.placeholderEN != null
          ? (agePlaceholder = payload.placeholderEN)
          : (agePlaceholder = 'Age');
      } else {
        payload.placeholderAR != null
          ? (agePlaceholder = payload.placeholderAR)
          : (agePlaceholder = 'العمر');
      }

      // Load options
      if (payload.options != null) {
        let optionsShot = payload.options;
        for (var key in optionsShot) {
          ageOptionsMap.set(key, optionsShot[key]);
          ageOptionsArr.push({
            value: optionsShot[key],
            key: key,
          });
        }
      }
      this.setState({showAge, agePlaceholder, ageOptionsMap, ageOptionsArr});
    } else if (fieldKey === 'gameAccount') {
      let showGameAccount, gameAccountPlaceholder, gameProviderImg;
      // Show/hide
      payload.show === true
        ? (showGameAccount = true)
        : (showGameAccount = false);
      // Load Placeholder
      if (isEn) {
        payload.placeholderEN != null
          ? (gameAccountPlaceholder = payload.placeholderEN)
          : (gameAccountPlaceholder = 'Game Account');
      } else {
        payload.placeholderAR != null
          ? (gameAccountPlaceholder = payload.placeholderAR)
          : (gameAccountPlaceholder = 'حسابك في اللعبة');
      }

      // Load game account img
      payload.gameProviderImg != null
        ? (gameProviderImg = payload.gameProviderImg)
        : (gameProviderImg = '');
      this.setState({showGameAccount, gameAccountPlaceholder, gameProviderImg});
    } else if (fieldKey === 'gameRank') {
      let showGameRank,
        gameRankPlaceholder,
        gameRanksArr = [];
      // Show/hide
      payload.show === true ? (showGameRank = true) : (showGameRank = false);
      // Load Placeholder
      if (isEn) {
        payload.placeholderEN != null
          ? (gameRankPlaceholder = payload.placeholderEN)
          : (gameRankPlaceholder = 'Game Rank');
      } else {
        payload.placeholderAR != null
          ? (gameRankPlaceholder = payload.placeholderAR)
          : (gameRankPlaceholder = 'رانكك في اللعبة');
      }

      // Load game ranks
      let gamemodel = this.props.gamesList[this.state.championshipObj.game];
      // eslint-disable-next-line no-shadow
      if (gamemodel) {
        gamemodel.ranksList.forEach((value, key) => {
          let rankObj = {
            key: key,
            value: value,
          };
          gameRanksArr.push(rankObj);
        });
      }
      this.setState({showGameRank, gameRankPlaceholder, gameRanksArr});
    } else if (fieldKey === 'gender') {
      let showGender, genderPlaceholder;
      // Show/hide
      payload.show === true ? (showGender = true) : (showGender = false);
      // Load Placeholder
      if (isEn) {
        payload.placeholderEN != null
          ? (genderPlaceholder = payload.placeholderEN)
          : (genderPlaceholder = 'Gender');
      } else {
        payload.placeholderAR != null
          ? (genderPlaceholder = payload.placeholderAR)
          : (genderPlaceholder = 'الجنس');
      }
      this.setState({showGender, genderPlaceholder});
    } else if (fieldKey === 'name') {
      let showName, namePlacholder;
      // Show/hide
      payload.show === true ? (showName = true) : (showName = false);
      // Load Placeholder
      if (isEn) {
        payload.placeholderEN != null
          ? (namePlacholder = payload.placeholderEN)
          : (namePlacholder = 'Name');
      } else {
        payload.placeholderAR != null
          ? (namePlacholder = payload.placeholderAR)
          : (namePlacholder = 'الإسم');
      }
      this.setState({showName, namePlacholder});
    } else if (fieldKey === 'phone') {
      let showPhone, phonePlaceholder;
      // Show/hide
      payload.show === true ? (showPhone = true) : (showPhone = false);
      // Load Placeholder
      if (isEn) {
        payload.placeholderEN != null
          ? (phonePlaceholder = payload.placeholderEN)
          : (phonePlaceholder = 'Phone');
      } else {
        payload.placeholderAR != null
          ? (phonePlaceholder = payload.placeholderAR)
          : (phonePlaceholder = 'رقم الجوال');
      }
      this.setState({showPhone, phonePlaceholder});
    } else if (fieldKey === 'reachSource') {
      let showReachSource,
        reachSourcePlaceholder,
        reachSourceOptionsMap = new Map(),
        reachSourceOptionsArr = [];
      // Show/hide
      payload.show === true
        ? (showReachSource = true)
        : (showReachSource = false);
      // Load Placeholder
      if (isEn) {
        payload.placeholderEN != null
          ? (reachSourcePlaceholder = payload.placeholderEN)
          : (reachSourcePlaceholder =
              'How did you know about this tournament?');
      } else {
        payload.placeholderAR != null
          ? (reachSourcePlaceholder = payload.placeholderAR)
          : (reachSourcePlaceholder = 'كيف عرفت البطولة؟');
      }

      // Load options
      if (payload.options != null) {
        let optionsShot = payload.options;
        for (var key in optionsShot) {
          reachSourceOptionsMap.set(key, optionsShot[key]);
          reachSourceOptionsArr.push({
            value: optionsShot[key],
            key: key,
          });
        }
      }
      this.setState({
        showReachSource,
        reachSourcePlaceholder,
        reachSourceOptionsMap,
        reachSourceOptionsArr,
      });
    }
  }

  // This function will load the championship's registration fields
  loadRegistrationFields() {
    let path =
      FirebasePaths.FIREBASE_CHAMPIONSHIPS_REFERENCE +
      '/' +
      this.state.championshipObj.id +
      '/' +
      FirebasePaths.FIREBASE_CHAMPIONSHIPS_REGISTRATION_FIELDS;
    return firebase.database().ref(path).once('value');
  }

  onValueChange = (type, val) => {
    this.setState({[type]: val});
  };

  // Registration button trigger
  registrationTrigger = () => {
    if (this.validateFields()) {
      const {
        championshipObj,
        IS_CLAN_SELECTED,
        clanObj,
        showName,
        nameModel,
        showGender,
        genderModel,
        showPhone,
        phoneNumberModel,
        showAge,
        ageKey,
        ageValue,
        showReachSource,
        reachKey,
        reachValue,
        showGameAccount,
        gameAccountModel,
        showGameRank,
        gameRankModel,
      } = this.state;
      const {uid, userinformationDB, lang} = this.props;
      // Get the path
      let path =
        FirebasePaths.FIREBASE_CHAMPIONSHIPS_REFERENCE +
        '/' +
        championshipObj.id +
        '/' +
        FirebasePaths.FIREBASE_CHAMPIONSHIPS_REGISTERED_PARTICIPANTS +
        '/' +
        uid;

      // Create participant Obj
      let participantOBJ = {};

      // Inserting Data starting with background data
      participantOBJ.email = userinformationDB.userEmail;
      participantOBJ.username = userinformationDB.username;
      participantOBJ.uid = uid;

      if (IS_CLAN_SELECTED) {
        participantOBJ.photo = clanObj.logo;
        participantOBJ.clanId = clanObj.id;
        participantOBJ.clanName = clanObj.name;
      } else {
        if (
          userinformationDB.pictureURL.toString() === 'NULL' ||
          userinformationDB.pictureURL.toString() ===
            'assets/imgs/profile_default_photo.png'
        ) {
          participantOBJ.photo =
            'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/teams%20default%20photos%20and%20background%2Fdefault_team_logov2.png?alt=media&token=b88bf926-35b9-4568-a80e-ee24110aceb6';
        }
      }

      // Insert other data : some of them with the user ref : like gender , name , phone
      let userRef =
        FirebasePaths.FIREBASE_USERS_INFO_ATTR +
        '/' +
        uid +
        '/' +
        FirebasePaths.FIREBASE_DETAILS_ATTR +
        '/';
      if (showName) {
        participantOBJ.name = nameModel;
        firebase.database()
          .ref(userRef)
          .child(FirebasePaths.FIREBASE_NAME_ATTR)
          .set(nameModel);
      }
      if (showGender) {
        participantOBJ.gender = genderModel;
        firebase.database()
          .ref(userRef)
          .child(FirebasePaths.FIREBASE_ANALYTICS_GENDER)
          .set(genderModel);
      }
      if (showPhone) {
        participantOBJ.phone = phoneNumberModel;
        firebase.database()
          .ref(userRef)
          .child(FirebasePaths.FIREBASE_ANALYTICS_PHONE)
          .set(phoneNumberModel);
      }
      if (showAge) {
        participantOBJ.ageKey = ageKey;
        participantOBJ.ageValue = ageValue;
      }
      if (showReachSource) {
        participantOBJ.reachKey = reachKey;
        participantOBJ.reachValue = reachValue;
      }
      showGameAccount ? (participantOBJ.gameAccount = gameAccountModel) : null;
      showGameRank ? (participantOBJ.gameRank = gameRankModel) : null;

      // Set registration  time
      participantOBJ.registrationTime = firebase.database.ServerValue.TIMESTAMP;

      // Insert the registered participant object to the DB and show a success alert with loaded msg from the server
      firebase.database().ref(path).set(participantOBJ);

      let titl = 'تم تسجيلك بنجاح !';
      let msg =
        'بنتواصل معك بأقرب وقت ونزودك بمعلومات التواصل اول ما يتم قبولك في البطوله !';

      // Log complete registration log
      global.featuresLogger.CHAMPIONSHIP_COMPLETE_REGISTRATION();

      // Log Rank HP
      global.rankLogger.LOG_NEW_HP(
        global.rankLogger.CHAMPIONSHIPS_COMPLETE_REGISTRATION,
        'CHAMPIONSHIPS_COMPLETE_REGISTRATION',
      );

      // Get the msg from the server
      let isEn = false;
      lang === 'en' ? (isEn = true) : (isEn = false);

      if (isEn) {
        championshipObj.registration_submit_msg_EN != null
          ? (msg = championshipObj.registration_submit_msg_EN)
          : (msg =
              'We will provide you all the information you need for the next step once we confirm your registration');

        championshipObj.registration_submit_title_EN != null
          ? (titl = championshipObj.registration_submit_title_EN)
          : (titl = 'Thank you !');
      } else {
        championshipObj.registration_submit_msg_AR != null
          ? (msg = championshipObj.registration_submit_msg_AR)
          : (msg =
              'بنتواصل معك بأقرب وقت ونزودك بمعلومات التواصل اول ما يتم قبولك في البطوله !');

        championshipObj.registration_submit_title_AR != null
          ? (titl = championshipObj.registration_submit_title_AR)
          : (titl = 'تم تسجيلك بنجاح !');
      }

      this.props.showAlert([
        titl,
        msg,
        [
          {
            text: 'OK !',
            onPress: () => {
              // Pop to root
              NavigationService.resetRoute('AuthStack');
            },
          },
        ],
      ]);
    }
  };

  // This function will validate user's fields
  validateFields() {
    const {
      nameModel,
      showName,
      phoneNumberModel,
      showPhone,
      gameAccountModel,
      showGameAccount,
      gameRankModel,
      showGameRank,
      ageKey,
      showAge,
      genderModel,
      showGender,
    } = this.state;
    if (nameModel.toString().length < 3 && showName === true) {
      presentToast({message: I18n.t('CHAMPIONSHIP_REGISTRATION.name_error')});
      return false;
    }
    if (phoneNumberModel.toString().length < 6 && showPhone === true) {
      presentToast({message: I18n.t('CHAMPIONSHIP_REGISTRATION.phone_error')});
      return false;
    }
    if (gameAccountModel.toString().length < 2 && showGameAccount === true) {
      presentToast({
        message: I18n.t('CHAMPIONSHIP_REGISTRATION.game_account_error'),
      });
      return false;
    }
    if (gameRankModel.toString().length < 1 && showGameRank === true) {
      presentToast({
        message: I18n.t('CHAMPIONSHIP_REGISTRATION.game_rank_error'),
      });
      return false;
    }
    if (ageKey.toString().length < 1 && showAge === true) {
      presentToast({message: I18n.t('CHAMPIONSHIP_REGISTRATION.age_error')});
      return false;
    }
    if (genderModel.toString().length < 1 && showGender === true) {
      presentToast({message: I18n.t('CHAMPIONSHIP_REGISTRATION.gender_error')});
      return false;
    }

    return true;
  }

  render() {
    const {theme} = this.props;
    const _styles = styles(theme);
    const {
      showName,
      namePlacholder,
      nameModel,
      showPhone,
      phonePlaceholder,
      phoneNumberModel,
      showGameAccount,
      gameAccountPlaceholder,
      gameAccountModel,
      showGameRank,
      gameRankPlaceholder,
      gameRankModel,
      gameRanksArr,
      showAge,
      agePlaceholder,
      ageKey,
      ageOptionsMap,
      ageOptionsArr,
      showGender,
      genderPlaceholder,
      genderModel,
      showReachSource,
      reachSourcePlaceholder,
      reachKey,
      reachSourceOptionsMap,
      reachSourceOptionsArr,
      loading,
    } = this.state;
    return (
      <View style={AppStyles.rootContainer}>
        <Spinner visible={loading} />
        {/* Header */}
        <Header text={I18n.t('CHAMPIONSHIP_REGISTRATION.title')} />
        {/* Content */}
        <View style={AppStyles.rootContent}>
          <Label style={AppStyles.grayLabel}>
            {I18n.t('CHAMPIONSHIP_REGISTRATION.sub-title')}
          </Label>
          <ScrollView style={_styles.scrollView}>
            {/* Name */}
            {showName && (
              <TextInput
                placeholder={namePlacholder}
                onChangeText={(val) => this.onValueChange('nameModel', val)}
                value={nameModel}
                customStyles={_styles.textInput}
                leftComponent={
                  <Feather
                    name="user"
                    size={perfectSize(20)}
                    color={theme.primary_text}
                  />
                }
              />
            )}
            {/* Phone */}
            {showPhone && (
              <TextInput
                placeholder={phonePlaceholder}
                onChangeText={(val) =>
                  this.onValueChange('phoneNumberModel', val)
                }
                value={phoneNumberModel}
                customStyles={_styles.textInput}
                leftComponent={
                  <Feather
                    name="phone"
                    size={perfectSize(20)}
                    color={theme.primary_text}
                  />
                }
              />
            )}
            {/* Game Account */}
            {showGameAccount && (
              <TextInput
                placeholder={gameAccountPlaceholder}
                onChangeText={(val) =>
                  this.onValueChange('gameAccountModel', val)
                }
                value={gameAccountModel}
                customStyles={_styles.textInput}
                leftComponent={
                  <Entypo
                    name="game-controller"
                    size={perfectSize(20)}
                    color={theme.primary_text}
                  />
                }
              />
            )}
            {/* Game Rank */}
            {showGameRank && (
              <Picker
                pickerName={gameRankPlaceholder}
                value={gameRankModel}
                values={gameRanksArr.map((e) => {
                  return {value: e.value, title: e.value};
                })}
                customStyles={_styles.picker}
                onPickerChanged={(val) => this.setState({gameRankModel: val})}
                translate={false}
                leftComponent={
                  <FontAwesome5
                    name="hackerrank"
                    size={perfectSize(20)}
                    color={theme.primary_text}
                  />
                }
              />
            )}
            {/* Age */}
            {showAge && (
              <Picker
                pickerName={agePlaceholder}
                value={this.state.ageValue}
                values={ageOptionsArr.map((e) => {
                  return {value: e.key, title: e.value};
                })}
                customStyles={_styles.picker}
                onPickerChanged={(val) => {
                  const ageOption = ageOptionsArr.find((option) => option.value === val);
                  this.setState({ageKey: ageOption.key, ageValue: val})
                }}
                translate={false}
                leftComponent={
                  <MaterialCommunityIcons
                    name="account-clock-outline"
                    size={perfectSize(20)}
                    color={theme.primary_text}
                  />
                }
              />
            )}
            {/* Gender */}
            {showGender && (
              <Picker
                pickerName={genderPlaceholder}
                value={genderModel}
                values={EDIT_PROFILE_GENDER}
                customStyles={[_styles.picker, {paddingLeft: perfectSize(12)}]}
                onPickerChanged={(val) => this.setState({genderModel: val})}
                translate={true}
                leftComponent={
                  <MaterialCommunityIcons
                    name="gender-male-female"
                    size={perfectSize(20)}
                    color={theme.primary_text}
                  />
                }
              />
            )}
            {/* Reach source */}
            {showReachSource && (
              <Picker
                pickerName={reachSourcePlaceholder}
                value={reachKey}
                values={reachSourceOptionsArr.map((e) => {
                  return {value: e.key, title: e.value};
                })}
                customStyles={_styles.picker}
                onPickerChanged={(val) =>
                  this.setState({
                    reachKey: val,
                    reachValue: reachSourceOptionsMap.get(val),
                  })
                }
                translate={false}
                leftComponent={
                  <Feather
                    name="info"
                    size={perfectSize(20)}
                    color={theme.primary_text}
                  />
                }
              />
            )}
            {/* Join button */}
            <PrimaryButton
              text={I18n.t('CHAMPIONSHIP_REGISTRATION.registerBtn')}
              type="filled"
              size="medium"
              customStyles={_styles.submitBtn}
              onPress={() => this.registrationTrigger()}
            />
          </ScrollView>
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
    lang: state.appReducer.currentLang,
    gamesList: state.hubReducer.gamesList,
    uid: state.authReducer.uid,
    userinformationDB: state.authReducer.userinformationDB,
  };
};

export default compose(connect(mapStateToProps, {showAlert}))(
  ChampionshipRegistrationPage,
);
