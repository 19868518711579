import {StyleSheet} from 'react-native';
import {FONT_SIZE, FONT_FAMILY} from '../../constants/theme';
import {perfectSize} from '../../helpers/deviceHelper';

const styles = (theme) =>
  StyleSheet.create({
    container: {
      flex: 1,
      width: '100%',
      backgroundColor: theme.primary_background,
    },
    wrapper: {
      flex: 1,
      paddingHorizontal: perfectSize(24),
      paddingVertical: perfectSize(24),
    },
    actionWrap: {
      flex: 1,
      justifyContent: 'space-between',
      marginVertical: perfectSize(15),
    },
    actionBottom: {
      height: perfectSize(41),
    },
    textLight: {
      opacity: 0.56,
    },
    flexRow: {
      flexDirection: 'row',
    },
    justifyBetween: {
      justifyContent: 'space-between',
    },
    alignCenter: {
      alignItems: 'center',
    },
    imgLogo: {
      width: perfectSize(96),
      height: perfectSize(86.42),
      resizeMode: 'cover',
      marginTop: perfectSize(64),
      marginBottom: perfectSize(20),
    },
    btnLogin: {
      width: '100%',
      height: perfectSize(52),
      textAlign: 'center',
    },
    forgot: {
      fontSize: FONT_SIZE.x_small,
      fontFamily: FONT_FAMILY.ubuntu,
      lineHeight: 19,
      color: theme.primary_btn_background,
    },
    signUpText: {
      fontFamily: FONT_FAMILY.ubuntu_bold,
      fontSize: perfectSize(15),
    },
    txtTerms: {
      letterSpacing: 1.1,
      marginTop: perfectSize(8),
    },
  });

export default styles;
