import {createStackNavigator} from 'react-navigation-stack';

import HomePage from '../../pages/home';
import CommunityPage from '../../pages/community';
import UpdatePage from '../../pages/app-update';
import ForgotpasswordPage from '../../pages/forgotpassword';
import RegistrationPage from '../../pages/registration';
import TermsPage from '../../pages/terms';
import UserJourneyPage from '../../pages/user-journey';

const AuthStack = createStackNavigator(
  {
    HomePage,
    CommunityPage,
    UpdatePage,
    ForgotpasswordPage,
    RegistrationPage,
    TermsPage,
    UserJourneyPage,
  },
  {
    initialRouteName: 'HomePage',
    headerMode: 'none',
    navigationOptions: {header: null},
    gestureEnabled: false,
  },
);

export default AuthStack;
