import {store} from '../redux/store';
import {
  deleteGame,
  clear,
  addGame,
  addAllGame,
} from '../redux/modules/gameManager/actions';
import * as FirebasePaths from '../constants/firebasePaths';

export default class Gamemanager {
  static addGame(gameModel) {
    store.dispatch(addGame(gameModel));
  }

  static addAllGame(allGames) {
    store.dispatch(addAllGame(allGames));
  }

  static deleteGame(gameId, gameName) {
    const isCompetitive = this.isCompetitive(gameId);
    store.dispatch(
      deleteGame({
        isCompetitive,
        gameId,
        gameName,
      }),
    );
  }

  static isCompetitive(gameId) {
    const gamesCompList = store.getState().gameManagerReducer.gamesCompList;
    if (gamesCompList.filter((e) => e.gameID === gameId).length > 0) {
      return true;
    }
    return false;
  }

  static getGameByName(gameName) {
    const allGamesName = store.getState().gameManagerReducer.allGamesName;
    let _item = allGamesName.filter((e) => e.gameName === gameName);
    if (_item.length > 0) {
      return _item[0];
    }
    return null;
  }

  static getGameById(gameId) {
    if (!store.getState().hubReducer.gamesList) {
      return null;
    }
    const gameModel = store.getState().hubReducer.gamesList[gameId];
    return gameModel;
  }

  static clear() {
    store.dispatch(clear);
  }

  static getUserGamesNumber() {
    return store.getState().gameManagerReducer.allGamesIds.length;
  }

  static checkIfHasGameByName(gameName) {
    // gameModel = store.getState().gameManagerReducer.allGamesName.get(gameName);
    const allGamesName = store.getState().gameManagerReducer.allGamesName;
    let _item = allGamesName.filter((e) => e.gameName === gameName);
    if (_item.length > 0) {
      return true;
    }
    return false;
  }

  static checkIfHasGameById(gameId) {
    let _item = store
      .getState()
      .gameManagerReducer.allGamesIds.filter((e) => e.gameID === gameId);
    if (_item.length > 0) {
      return true;
    }
    return false;
  }

  static getGameType(gameType) {
    switch (gameType) {
      case FirebasePaths.FIREBASE_GAME_COMPETITVE_ATTR:
        return FirebasePaths.GAME_TYPE_COMPETITVE;
      case FirebasePaths.FIREBASE_GAME_QUICK_ATTR:
        return FirebasePaths.GAME_TYPE_QUICK_MATCH;
      case FirebasePaths.FIREBASE_GAME_COOP_ATTR:
        return FirebasePaths.GAME_TYPE_COOP;
    }
  }
}
