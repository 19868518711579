/* eslint-disable react-native/no-inline-styles */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import AsyncStorage from '@react-native-community/async-storage';
import Feather from 'react-native-vector-icons/Feather';
import I18n from '../../i18n';
import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';

import Label from '../label';
import UserAvatar from '../user-avatar';
import dimens from '../../constants/dimens';
import { perfectSize } from '../../helpers/deviceHelper';
import NavigationService from '../../navigation/navigationService';

import { logoutRequest, setUserInfo } from '../../redux/modules/auth/actions';
import { set as setHub } from '../../redux/modules/hub/actions';
import { set as setGameManager } from '../../redux/modules/gameManager/actions';
import { showSpinner, hideSpinner } from '../../redux/modules/alert/actions';
import { defaultState as hubDefaultState } from '../../redux/modules/hub/index';
import { defaultState as gameManagerDefaultState } from '../../redux/modules/gameManager/index';

import { ICON_LEADERBOARD_PINK } from '../../constants/images';
import AppStyles from '../../constants/styles';

import * as FirebasePaths from '../../constants/firebasePaths';
import { presentToast } from '../../helpers/toastHelper';

// core files
import UserProfileCore from '../../pages/userprofile';
import ImageWithPlaceholder from '../image-with-placeholder';
import LocalImageOpacity from '../local-image-opacity';

class DrawerScreen extends Component {
  constructor(props) {
    super(props);
    this.mount = true;
    this.state = {
      showSpinner: false,
    };
  }

  componentWillUnmount() {
    this.mount = false;
  }

  navigate = (routeName, param) => {
    NavigationService.toggleDrawer();
    NavigationService.navigate(routeName, param);
  };

  toSettingsStack = () => {
    NavigationService.toggleDrawer();
    NavigationService.navigate('SettingsStack');
  };

  logout = () => {
    // this.setState({showSpinner: true});
    this.props.showSpinner({});
    let userStatusRef =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      this.props.uid +
      '/' +
      FirebasePaths.FIREBASE_DETAILS_ATTR;
    firebase.database()
      .ref(userStatusRef + '/' + FirebasePaths.FIREBASE_STATUS_ATTR)
      .set('offline');
    global.featuresLogger.LOG_LOGOUT();
    // Logging user out
    firebase.auth()
      .signOut()
      .finally(() => {
        this.setState({ showSpinner: false });
        const userNotificationListener = UserProfileCore.loadUserNotifications;
        if (userNotificationListener) {
          userNotificationListener.off();
        }
        this.props.logoutRequest({});
        this.props.setUserInfo({
          pcGamesAccs: new Map(),
          mobileGamesAccs: new Map(),
          rank: 0,
        });
        this.props.setHub({
          ...hubDefaultState,
          teaser: this.props.teaser,
          regionsList: this.props.regionsList,
          gamesList: this.props.gamesList,
          porhibitedWordsArr: this.props.porhibitedWordsArr,
          shareIconsMap: this.props.shareIconsMap,
        });
        this.props.setGameManager(gameManagerDefaultState);
        AsyncStorage.clear();
        presentToast({
          message: 'Come back soon',
        });
        this.props.hideSpinner();
        NavigationService.resetRoute('AuthStack', {
          CAME_FROM: 'SETTINGS_PAGE',
        });
      });
  };

  render() {
    const { theme } = this.props;
    return (
      <View
        style={[styles.container, { backgroundColor: theme.primary_background }]}>
        <View style={styles.wrapper}>
          <UserAvatar
            src={this.props.user.pictureURL}
            size={perfectSize(64)}
            borderColor={this.props.user.profileBorderColor}
          />
          <View
            style={[
              styles.flexRow,
              { marginTop: perfectSize(16), alignItems: 'center' },
            ]}>
            <Label size="x_medium" style={{ textTransform: 'capitalize' }}>
              {this.props.user.username}
            </Label>
            <ImageWithPlaceholder
              uri={global.rankLogger.getRankImg(this.props.user.rank)}
              resizeMethod="resize"
              style={global.rankLogger.getRankStyle(this.props.user.rank)}
              resizeMode="contain"
            />
          </View>
          <Label
            style={{
              color: theme.primary_btn_background,
            }}
            size="x_small">
            {this.props.user.rank} HP
          </Label>
          <View style={styles.actionWrap}>
            <TouchableOpacity
              style={styles.actionItem}
              onPress={() =>
                this.navigate('UserprofilePage', { showBack: true })
              }>
              <Feather name="user" color="#C70064" size={perfectSize(20)} />
              <Label style={styles.txtAction}>
                {I18n.t('SIDE_BAR.profile')}
              </Label>
            </TouchableOpacity>

            <TouchableOpacity
              style={styles.actionItem}
              onPress={() => this.navigate('LeaderboardStack')}>
              <LocalImageOpacity
                source={ICON_LEADERBOARD_PINK}
                resizeMethod="resize"
                style={styles.imgAction}
                resizeMode="contain"
              />
              <Label style={styles.txtAction}>
                {I18n.t('SIDE_BAR.leaderboard')}
              </Label>
            </TouchableOpacity>

            <TouchableOpacity
              style={styles.actionItem}
              onPress={this.toSettingsStack}>
              <Feather name="settings" color="#C70064" size={perfectSize(18)} />
              <Label style={styles.txtAction}>
                {I18n.t('SIDE_BAR.settings')}
              </Label>
            </TouchableOpacity>
          </View>
          <View style={styles.logoutWrap}>
            <TouchableOpacity style={styles.actionItem} onPress={this.logout}>
              <Feather name="log-out" color="gray" size={perfectSize(20)} />
              <Label style={styles.txtAction}>
                {I18n.t('SIDE_BAR.logout')}
              </Label>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    );
  }
}

DrawerScreen.propTypes = {
  navigation: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
    user: state.authReducer.userinformationDB,
    uid: state.authReducer.uid,
    teaser: state.hubReducer.teaser,
    regionsList: state.hubReducer.regionsList,
    gamesList: state.hubReducer.gamesList,
    porhibitedWordsArr: state.hubReducer.porhibitedWordsArr,
    shareIconsMap: state.hubReducer.shareIconsMap,
  };
};

export default connect(mapStateToProps, {
  logoutRequest,
  setGameManager,
  setHub,
  showSpinner,
  hideSpinner,
  setUserInfo,
})(DrawerScreen);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: dimens.HEADER_PADDING_HORIZONTAL,
    paddingTop: perfectSize(61),
    paddingBottom: perfectSize(51),
  },
  wrapper: {
    flex: 1,
    paddingHorizontal: dimens.HEADER_PADDING_HORIZONTAL / 2,
  },
  actionWrap: {
    flex: 1,
    paddingTop: perfectSize(32),
  },
  actionItem: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: perfectSize(48),
  },
  logoutWrap: {},
  flexRow: {
    flexDirection: 'row',
  },
  imgAction: {
    width: perfectSize(16),
    height: perfectSize(18),
    resizeMode: 'contain',
  },
  txtAction: {
    fontSize: perfectSize(16),
    color: '#A2A5A9',
    marginLeft: perfectSize(16),
  },
});
