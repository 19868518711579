import {StyleSheet} from 'react-native';
import {perfectSize} from '../../helpers/deviceHelper';
import dimens from '../../constants/dimens';

const styles = (theme) =>
  StyleSheet.create({
    container: {
      flex: 1,
      width: '100%',
      backgroundColor: theme.primary_background,
    },
    wrapper: {
      flex: 1,
      paddingHorizontal: perfectSize(24),
      paddingTop: perfectSize(24),
    },
    txtTerms: {
      lineHeight: 23,
      marginTop: 24.5,
      opacity: 0.56,
    },
  });

export default styles;
