import firebase from 'firebase/app';
import 'firebase/database';
import * as FirebasePaths from '../../constants/firebasePaths';
import { store } from '../../redux/store';

export default class LoginCore {
    // This function will check if the user is banned from chat
    static async IS_USER_ACCESS_BANNED(uid) {
        let path =
            FirebasePaths.FIREBASE_USERS_INFO_ATTR +
            '/' +
            uid +
            '/' +
            FirebasePaths.FIREBASE_USER_BAN +
            '/' +
            FirebasePaths.FIREBASE_USER_BAN_ACCESS;

        const snapshot = await firebase.database()
            .ref(path)
            .once('value');
        if (snapshot.val() != null) {
            return true;
        } else {
            return false;
        }

    }
}
