import {StyleSheet} from 'react-native';
import {perfectSize} from '../../helpers/deviceHelper';

const styles = (theme) =>
  StyleSheet.create({
    content: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
    },
    flatList: {
      width: '100%',
      backgroundColor: theme.primary_background,
    },
    card: {
      marginBottom: perfectSize(16),
      height: 240,
      
    },
    cover: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
    cardContent: {
      justifyContent: 'center',
      alignItems: 'center',
      paddingBottom: 30,
    },
    logo: {
      width: 50,
      height: 50,
      borderRadius: 25,
      borderWidth: 1,
      borderColor: 'white',
      resizeMode: 'cover',
    },
    appName: {
      marginTop: perfectSize(4),
    },
    gameName: {
      marginTop: perfectSize(16),
    },
    footer: {
      width: perfectSize(160),
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: perfectSize(16),
    },
    item: {
      alignItems: 'center',
    },
    icon: {
      width: perfectSize(17),
      height: perfectSize(13),
      resizeMode: 'contain',
    },
    value: {
      marginTop: 2,
      fontSize: perfectSize(10),
    },
    status: {
      position: 'absolute',
      top: perfectSize(16),
      right: perfectSize(8),
      alignItems: 'center',
      minWidth: perfectSize(80),
      paddingHorizontal: perfectSize(12),
      paddingVertical: perfectSize(4),
      shadowOffset: {
        width: 0,
        height: 6,
      },
      shadowOpacity: 0.37,
      shadowRadius: 7.49,
      elevation: 12,
      borderRadius: perfectSize(30)
    },
    overlay: {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      borderRadius: perfectSize(30),
      opacity: 0.3,
    },
    statusText: {
      fontSize: perfectSize(10),
    },
    adCard: {
      borderRadius: perfectSize(12),
      marginBottom: perfectSize(16),
      width: '100%',
      height: 240,
      overflow: 'hidden',
    },
    adCover: {
      width: '100%',
      height: '100%',
    },
  });

export default styles;
