/* eslint-disable react-native/no-inline-styles */
import React, { Component } from 'react';
import { View, TouchableOpacity, FlatList } from 'react-native';
import { compose } from 'redux';
import { connect } from 'react-redux';
import StarRating from 'react-native-star-rating';
import Feather from 'react-native-vector-icons/Feather';
import deepEqual from 'fast-deep-equal';
import I18n from '../../i18n';
import * as FirebasePaths from '../../constants/firebasePaths';
import firebase from 'firebase/app';
import 'firebase/database';

import {
  Placeholder,
  Progressive
} from "rn-placeholder";

// import components
import Header from '../../components/header';
import Label from '../../components/label';
import UserAvatar from '../../components/user-avatar';
import ActivityItem from '../../components/user-activity-item';
import PrimaryButton from '../../components/buttons/primary-button';

// import styles
import AppStyles from '../../constants/styles';
import styles from './styles';

import { STAR, STAR_BLANK } from '../../constants/images';
import { perfectSize } from '../../helpers/deviceHelper';
import NavigationService from '../../navigation/navigationService';
import TopTabService from '../../navigation/topTabService';

import Userprofilecore from './userprofilecore';
import mainMenuService from '../../navigation/mainMenuService';
import Mainmenucore from '../mainmenu/mainmenucore';
import { set as setHub } from '../../redux/modules/hub/actions';
import { clearFastImageCache, dataForSkeleton } from '../../services/utils';
import ImageWithPlaceholder from '../../components/image-with-placeholder';
import SkeletonUserAvatar from '../../components/skeletonWrapper/skeletonUserAvatar';
import SkeletonTextContent from '../../components/skeletonWrapper/skeletonTextContent';

class UserprofilePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hopsLikeCount: 0,
      notificationsArr: [],
      allGamesIds: props.allGamesIds,
      ratesFromUsers: [],
      avgUserRates: 0,
      recentListArr: props.recentListArr,
      friendsList: props.friendsList,
      isLoading: true,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        isLoading: false,
      })
    }, 500);
    global.rankLogger.CHECK_BONUS_HP();
    this.hopsLikesCountRef$ = Userprofilecore.getHopsLikesCount();
    this.hopsLikesCountRef$.on('value', (snapshot) => {
      let count = 0;
      snapshot.forEach((snap) => {
        if (snap.val()._likes_) {
          count += Object.keys(snap.val()._likes_).length;
        }
      });
      this.setState({ hopsLikeCount: count });
    });

    this.loadRatesFrom();

    this.userTeamsRef$ = Mainmenucore.getUserTeamsRef();
    this.userTeamsRef$.on('value', (teamsShot) => {
      let userTeamsArr = [];
      teamsShot.forEach((item) => {
        userTeamsArr.push(item.val());
      });
      this.props.setHub({ userTeamsArr });
      this.setState({
        userTeamsArr,
      });
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { recentListArr: oldList, friendsList: oldFriends } = this.props;
    const { recentListArr: newList, friendsList: newFriends } = nextProps;
    if (!deepEqual(oldList, newList)) {
      this.setState({ recentListArr: newList });
    }
    if (!deepEqual(oldFriends, newFriends)) {
      this.setState({ friendsList: newFriends });
    }
  }

  // This function will load all rates from other users  : FROM
  loadRatesFrom() {
    let ref =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      this.props.uid +
      '/' +
      FirebasePaths.FIREBASE_USER_RATINGS +
      '/' +
      FirebasePaths.FIREBASE_USER_RATING_FROM;
    this.ratesFromRef$ = firebase.database().ref(ref);
    this.ratesFromRef$.on('value', (rates) => {
      let ratesFromUsers = [];
      let ratesComulative = 0;
      rates.forEach((rate) => {
        if (!ratesFromUsers.includes(rate.key)) {
          ratesFromUsers.push(rate.key);
          ratesComulative += rate.val();
        }
      });
      this.setState({
        ratesFromUsers,
        avgUserRates: ratesComulative / ratesFromUsers.length,
      });
    });
  }

  componentWillUnmount() {
    if (this.hopsLikesCountRef$) {
      this.hopsLikesCountRef$.off();
      this.hopsLikesCountRef$ = null;
    }
    if (this.ratesFromRef$) {
      this.ratesFromRef$.off();
      this.ratesFromRef$ = null;
    }
    if (this.focusListener$) {
      this.focusListener$();
    }
    if (this.userTeamsRef$) {
      this.userTeamsRef$.off();
      this.userTeamsRef$ = null;
    }
  }

  goBack = () => {
    clearFastImageCache();
    NavigationService.goBack();
  };

  toFriendsListPage = (showBack) => {
    NavigationService.navigate('FriendsListPage', {
      backToProfile: showBack,
    });
  };

  toGamesLibraryPage = (showBack) => {
    NavigationService.moveToScreenInStack('HomeStack', 'GamesLibraryPage', {
      backToProfile: showBack,
    });
  };

  toNotificationsPage = () => {
    NavigationService.moveToScreenInStack('HomeStack', 'NotificationsPage');
  };

  toSettingsPage = () => {
    NavigationService.navigate('SettingsStack');
  };

  _toLeaderBoardPage = () => {
    NavigationService.navigate('LeaderboardStack');
  };

  _toCreateNewRequest = () => {
    NavigationService.moveToScreenInStack('HomeStack', 'NewRequestPage');
  };

  toTeamsPage = () => {
    NavigationService.navigate('TabStack');
    mainMenuService.navigate('Home');
    setTimeout(() => {
      TopTabService.navigate('Clans');
    }, 100);
  };

  viewUserAvatar = (showBack, url) => {
    NavigationService.navigate('PhotoViewerPage', {
      IMG: url,
      defaultBack: showBack,
    });
  };

  _share = () => {
    //
    let message = `Check ${this.props.userinformationDB.username}'s profile`;
    let subject = 'Hoplay';
    let img = this.props.shareIconsMap.SHARE_PROFILE;
    // let url = 'https://hoplay.online://userprofile/' + this.props.uid;
    let url = 'https://hoplay.online/userprofile/' + this.props.uid;
    // Share.open({
    //   message,
    //   subject,
    //   img,
    //   url,
    // });
    global.rankLogger.LOG_NEW_HP(global.rankLogger.SHARE_APP, 'SHARE_APP');
    global.featuresLogger.LOG_SHARE_APP();
  };

  listHeaderComponent = (showBack) => {
    const { theme, userinformationDB } = this.props;
    const userProfile =
      userinformationDB.pictureURL ||
      'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/teams%20default%20photos%20and%20background%2Fdefault_team_logov2.png?alt=media&token=b88bf926-35b9-4568-a80e-ee24110aceb6';
    return (
      <>
        <View style={styles.userWrap}>
          <TouchableOpacity
            onPress={() => this.viewUserAvatar(showBack, userProfile)}>
            <UserAvatar
              src={userProfile}
              size={perfectSize(64)}
              borderColor={userinformationDB.profileBorderColor || '#C70064'}
              style={{ marginBottom: perfectSize(8) }}
              uid={this.props.uid}
            />
          </TouchableOpacity>
          {userinformationDB.profile_icon !== null &&
            userinformationDB.profile_icon !== '' && (
              <ImageWithPlaceholder
                uri={userinformationDB.profile_icon}
                style={[AppStyles.rankImg, { marginVertical: perfectSize(4) }]}
                resizeMode="contain"
              />
            )}
          <View style={[styles.flexRow, styles.alignCenter]}>
            <Label size="x_medium" style={{ textTransform: 'capitalize' }}>
              {userinformationDB.username}
            </Label>
            <TouchableOpacity onPress={this._toLeaderBoardPage}>
              <ImageWithPlaceholder
                uri={global.rankLogger.getRankImg(userinformationDB.rank)}
                style={[
                  global.rankLogger.getRankStyle(userinformationDB.rank),
                  { marginLeft: perfectSize(4) },
                ]}
                resizeMode="contain"
              />
            </TouchableOpacity>
          </View>
          <TouchableOpacity
            onPress={() =>
              NavigationService.moveToScreenInStack(
                'HomeStack',
                'RankTimelinePage',
              )
            }>
            <View
              style={[
                styles.flexRow,
                styles.alignCenter,
                { marginVertical: perfectSize(8) },
              ]}>
              <Label
                size="x_small"
                style={{
                  color: theme.primary_btn_background,
                  marginRight: perfectSize(5),
                }}>
                {userinformationDB.rank} HP
              </Label>
              <Feather
                name="chevron-right"
                color="#C70064"
                size={perfectSize(22)}
              />
            </View>
          </TouchableOpacity>
          <StarRating
            emptyStar={STAR_BLANK}
            fullStar={STAR}
            starSize={20}
            halfStarColor={'#C70064'}
            rating={this.state.avgUserRates}
            starStyle={{ marginHorizontal: perfectSize(2.5) }}
            disabled={true}
          />
          <Label style={{ opacity: 0.6, marginVertical: perfectSize(8) }}>
            {userinformationDB.bio}
          </Label>
        </View>
        <View
          style={[
            styles.tabView,
            { backgroundColor: theme.secondary_background },
          ]}>
          <TouchableOpacity
            style={styles.tabItem}
            onPress={() => this.toGamesLibraryPage(showBack)}>
            <Label style={styles.tabItemName}>
              {I18n.t('USER_PROFILE_PAGE.games')}
            </Label>
            <Label
              bold
              style={[
                styles.tabItemScore,
                { color: theme.primary_btn_background },
              ]}>
              {/* {gameManager.getUserGamesNumber()} */}
              {this.props.allGamesIds.length}
            </Label>
          </TouchableOpacity>

          <TouchableOpacity
            style={styles.tabItem}
            onPress={() => this.toFriendsListPage(showBack)}>
            <Label style={styles.tabItemName}>
              {I18n.t('USER_PROFILE_PAGE.friends')}
            </Label>
            <Label
              bold
              style={[
                styles.tabItemScore,
                { color: theme.primary_btn_background },
              ]}>
              {this.state.friendsList ? this.state.friendsList.length : 0}
            </Label>
          </TouchableOpacity>

          <TouchableOpacity style={styles.tabItem} onPress={this.toTeamsPage}>
            <Label style={styles.tabItemName}>
              {I18n.t('USER_PROFILE_PAGE.teams')}
            </Label>
            <Label
              bold
              style={[
                styles.tabItemScore,
                { color: theme.primary_btn_background },
              ]}>
              {this.props.userTeamsArr ? this.props.userTeamsArr.length : 0}
            </Label>
          </TouchableOpacity>

          <View style={styles.tabItem}>
            <Label style={styles.tabItemName}>
              {I18n.t('USER_PROFILE_PAGE.rating')}
            </Label>
            <Label
              bold
              style={[
                styles.tabItemScore,
                { color: theme.primary_btn_background },
              ]}>
              {this.state.ratesFromUsers ? this.state.ratesFromUsers.length : 0}
            </Label>
          </View>
          <View style={styles.tabItem}>
            <Label style={styles.tabItemName}>
              {I18n.t('USER_PROFILE_PAGE.hops_likes')}
            </Label>
            <Label
              bold
              style={[
                styles.tabItemScore,
                { color: theme.primary_btn_background },
              ]}>
              {this.state.hopsLikeCount}
            </Label>
          </View>
        </View>
        <View style={styles.activityWrap}>
          <Label bold>{I18n.t('USER_PROFILE_PAGE.recent_activites')}</Label>
        </View>
      </>
    );
  };

  listEmptyComponent = () => {
    return (
      <View style={{ alignItems: 'center' }}>
        <View style={[AppStyles.emptyContent, { marginTop: perfectSize(12) }]}>
          {/* <FastImage
            source={NO_ACTIVITY_DARK}
            style={styles.noActivityImage}
            resizeMode="contain"
          /> */}
          <Label style={styles.noActivityText}>
            {I18n.t('USER_PROFILE_PAGE.no_activites_message')}
          </Label>
          <PrimaryButton
            text={I18n.t('USER_PROFILE_PAGE.no_activities_action')}
            type="filled"
            size="x_small"
            customStyles={styles.btnAction}
            onPress={this._toCreateNewRequest}
          />
        </View>
      </View>
    );
  };

  _goBack = () => {
    NavigationService.navigate('TabStack');
  };

  render() {
    const { theme, navigation, isFocused } = this.props;
    const containerStyle = [
      styles.container,
      { backgroundColor: theme.secondary_background },
    ];
    const { state, } = navigation;
    const { isLoading, recentListArr } = this.state;
    const showBack = state && state.params && state.params.showBack;
    let _recentList = recentListArr;
    _recentList = _recentList.sort((a, b) => {
      if (a.timeStamp < b.timeStamp) {
        return 1;
      }
      if (a.timeStamp > b.timeStamp) {
        return -1;
      }
      return 0;
    })

    return (
      <View style={containerStyle}>
        {/* Header */}
        <Header
          text={I18n.t('USER_PROFILE_PAGE.my_profile')}
          back={!!showBack}
          onBack={this._goBack}
          rightComponent={
            <View style={styles.headerRight}>
              <TouchableOpacity
                style={AppStyles.rightBarButton}
                onPress={this.toNotificationsPage}>
                <Feather name="bell" color="gray" size={perfectSize(19)} />
              </TouchableOpacity>
              <TouchableOpacity
                style={AppStyles.rightBarButton}
                onPress={this.toSettingsPage}>
                <Feather name="settings" color="gray" size={perfectSize(19)} />
              </TouchableOpacity>
              {this.props.HAS_NEW_NOTIFICATION && <View style={styles.badge} />}
            </View>
          }
        />
        {/* Content */}
        <View
          style={[{ backgroundColor: theme.primary_background }, styles.content]}>
          <View style={styles.activityWrap}>
            <FlatList
              style={{ width: '100%' }}
              ListHeaderComponent={this.listHeaderComponent(showBack)}
              data={isLoading ? dataForSkeleton(10) : _recentList}
              showsVerticalScrollIndicator={false}
              renderItem={(item) => (
                isLoading ? <View>
                  <Placeholder
                    Animation={Progressive}
                  >
                    <View >
                      <View style={{
                        paddingVertical: perfectSize(16),
                        flexDirection: 'row',
                      }}>
                        <SkeletonUserAvatar isRound={false} size={50} style={{ borderRadius: 10 }} />
                        <View style={{
                          marginLeft: perfectSize(8),
                          flex: 1,
                          justifyContent: 'center',
                        }}>
                          <SkeletonTextContent height={15} width={50} style={{ marginBottom: 10 }} />
                          <View style={{
                            flexDirection: 'row',
                            marginTop: perfectSize(4),
                            justifyContent: 'space-between'
                          }}>
                            <SkeletonTextContent height={15} width={70} />
                            <SkeletonTextContent height={15} width={10} />
                          </View>
                        </View>
                      </View>
                      <View style={{ height: 1, widht: '100%' }} />
                    </View>
                  </Placeholder>
                </View> :
                  <ActivityItem
                    activity={{
                      ...item.item,
                      gameName: this.props.gamesList[item.item.gameId]?.gameName,
                      gamePhotoUrl: this.props.gamesList[item.item.gameId]
                        ?.gamePhotoUrl,
                    }}
                  />
              )}
              ListEmptyComponent={this.listEmptyComponent}
              keyExtractor={(item, index) => String(index)}
              ListFooterComponent={() => {
                return <View style={AppStyles.contentFotter} />;
              }}
            />
          </View>
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
    lang: state.appReducer.currentLang,
    uid: state.authReducer.uid,
    userinformationDB: state.authReducer.userinformationDB,
    recentListArr: state.hubReducer.recentListArr,
    friendsList: state.hubReducer.friendsList,
    userTeamsArr: state.hubReducer.userTeamsArr,
    gamesList: state.hubReducer.gamesList,
    addedGamesArray: state.hubReducer.addedGamesArray,
    allGamesIds: state.gameManagerReducer.allGamesIds,
    shareIconsMap: state.hubReducer.shareIconsMap,
    valueChanged: state.hubReducer.valueChanged,
    HAS_NEW_NOTIFICATION: state.hubReducer.HAS_NEW_NOTIFICATION,
  };
};

export default compose(connect(mapStateToProps, { setHub }))(UserprofilePage);


