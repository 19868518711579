/* eslint-disable react-native/no-inline-styles */
import React, {Component} from 'react';
import {View, ScrollView} from 'react-native';
import {compose} from 'redux';
import {connect} from 'react-redux';
// import components
import Header from '../../components/header';
import Label from '../../components/label';
import I18n from '../../i18n';

import style from './styles';
import {perfectSize} from '../../helpers/deviceHelper';
// Terms Page
class TermsPage extends Component {
  render() {
    const {theme} = this.props;
    const styles = style(theme);
    return (
      <View style={[styles.container]}>
        <Header />
        <View style={styles.wrapper}>
          <ScrollView contentContainerStyle={{paddingBottom: perfectSize(16)}}>
            <Label
              size="medium"
              bold
              style={{
                // lineHeight: 18,
                letterSpacing: 2,
              }}>
              {I18n.t('REGISTRATION_PAGE.terms')}
              {/* Terms and Conditions */}
            </Label>
            <Label style={styles.txtTerms} size="medium">
              Welcome to the Hoplay App, an Application aims to make the
              ultimate gaming experience, and form a better gaming community.
            </Label>
            <Label style={[styles.txtTerms, {opacity: 1}]} size="medium">
              We are updating our Terms of Use: Our updated terms of use will be
              effective on February 5th , 2020.
            </Label>
            <Label style={[styles.txtTerms, {opacity: 1}]} size="medium">
              By using the Hoplay.online website and Hoplay service you are
              agreeing to be bound by the following terms and conditions ("Terms
              of Use").
            </Label>
            <Label style={styles.txtTerms} size="medium">
              Basic Terms
            </Label>
            <Label style={styles.txtTerms} size="x_small">
              1. You must be 14 years or older to use this site.
            </Label>
            <Label style={styles.txtTerms} size="x_small">
              2. You are responsible for any activity that occurs under your
              screen name.
            </Label>
            <Label style={styles.txtTerms} size="x_small">
              3. You are responsible for keeping your password secure.
            </Label>
            <Label style={styles.txtTerms} size="x_small">
              4. You must not abuse, harass, threaten, impersonate or intimidate
              other Hoplay users.
            </Label>
            <Label style={styles.txtTerms} size="x_small">
              5. You may not use the Hoplay service for any illegal or
              unauthorized purpose. International users agree to comply with all
              local laws regarding online conduct and acceptable content.
            </Label>
            <Label style={styles.txtTerms} size="x_small">
              6. You are solely responsible for your conduct and any data, text,
              information, screen names, graphics, photos, profiles, audio and
              video clips, links ("Content") that you submit, post, and display
              on the Hoplay service.
            </Label>
            <Label style={styles.txtTerms} size="x_small">
              7. You must not modify, adapt or hack Hoplay or modify another
              website so as to falsely imply that it is associated with Hoplay.
            </Label>
            <Label style={styles.txtTerms} size="x_small">
              8. You must not access Hoplay’s private API by any other means
              other than the Hoplay application itself.
            </Label>
            <Label style={styles.txtTerms} size="x_small">
              9. You must not crawl, scrape, or otherwise cache any content from
              Hoplay including but not limited to user profiles and photos.
            </Label>
            <Label style={styles.txtTerms} size="x_small">
              10. You must not create or submit unwanted email or comments to
              any Hoplay members ("Spam").
            </Label>
            <Label style={styles.txtTerms} size="x_small">
              11. You must not use web URLs in your name without prior written
              consent from Hoplay.
            </Label>
            <Label style={styles.txtTerms} size="x_small">
              12. You must not transmit any worms or viruses or any code of a
              destructive nature.
            </Label>
            <Label style={styles.txtTerms} size="x_small">
              13. You must not, in the use of Hoplay, violate any laws in your
              jurisdiction (including but not limited to copyright laws).
            </Label>
            <Label style={styles.txtTerms} size="x_small">
              14. Violation of any of these agreements will result in the
              termination of your Hoplay account. While Hoplay prohibits such
              conduct and content on its site, you understand and agree that
              Hoplay cannot be responsible for the Content posted on its web
              site and you nonetheless may be exposed to such materials and that
              you use the Hoplay service at your own risk.
            </Label>
            <Label style={styles.txtTerms} size="medium">
              General Conditions
            </Label>
            <Label style={styles.txtTerms} size="x_small">
              1. We reserve the right to modify or terminate the Hoplay service
              for any reason, without notice at any time.
            </Label>
            <Label style={styles.txtTerms} size="x_small">
              2. We reserve the right to alter these Terms of Use at any time.
              If the alterations constitute a material change to the Terms of
              Use, we will notify you via internet mail according to the
              preference expressed on your account. What constitutes a "material
              change" will be determined at our sole discretion, in good faith
              and using common sense and reasonable judgement.{' '}
            </Label>
            <Label style={styles.txtTerms} size="x_small">
              3. We reserve the right to refuse service to anyone for any reason
              at any time.{' '}
            </Label>
            <Label style={styles.txtTerms} size="x_small">
              4. We reserve the right to force forfeiture of any username that
              becomes inactive, violates trademark, or may mislead other users.{' '}
            </Label>
            <Label style={styles.txtTerms} size="x_small">
              5. We may, but have no obligation to, remove Content and accounts
              containing Content that we determine in our sole discretion are
              unlawful, offensive, threatening, libellous, defamatory, obscene
              or otherwise objectionable or violates any party's intellectual
              property or these Terms of Use.{' '}
            </Label>
            <Label style={styles.txtTerms} size="x_small">
              6. We reserve the right to reclaim usernames on behalf of
              businesses or individuals that hold legal claim or trademark on
              those usernames.{' '}
            </Label>
            <Label style={styles.txtTerms} size="x_small">
              7. We have the right to use the data we have on all users
              including requests , chats, hops feeds (all Hoplay content) in a
              way to improve Hoplay's overall services in order to optimize and
              improve the gaming community to serve them and other community as
              well.
            </Label>
            <Label style={styles.txtTerms} size="medium">
              Proprietary Rights in Content on Hoplay
            </Label>
            <Label style={styles.txtTerms} size="x_small">
              1. Hoplay does claim an ownership rights in the text, files,
              images, photos, video, sounds, musical works, works of authorship,
              applications, or any other materials (collectively, "Content")
              that you post on or through the Hoplay Services. By displaying or
              publishing ("posting") any Content on or through the Hoplay
              Services, you hereby grant to Hoplay a non-exclusive, fully paid
              and royalty-free, worldwide, limited license to use, modify,
              delete from, add to, publicly perform, publicly display, reproduce
              and translate such Content, including without limitation
              distributing part or all of the Site in any media formats through
              any media channels, except Content not shared publicly ("private")
              will not be distributed outside the Hoplay Services.
            </Label>
            <Label style={styles.txtTerms} size="x_small">
              2. Some of the Hoplay Services are supported by advertising
              revenue and may display advertisements and promotions, and you
              hereby agree that Hoplay may place such advertising and promotions
              on the Hoplay Services or on, about, or in conjunction with your
              Content. The manner, mode and extent of such advertising and
              promotions are subject to change without specific notice to you.
            </Label>
            <Label style={styles.txtTerms} size="x_small">
              3. You represent and warrant that: (i) you do not own the Content
              posted by you on or through the Hoplay Services or otherwise have
              the right to grant the license set forth in this section, (ii) the
              posting and use of your Content on or through the Hoplay Services
              does not violate the privacy rights, publicity rights, copyrights,
              contract rights, intellectual property rights or any other rights
              of any person, and (iii) the posting of your Content on the Site
              does not result in a breach of contract between you and a third
              party. You agree to pay for all royalties, fees, and any other
              monies owing any person by reason of Content you post on or
              through the Hoplay Services.
            </Label>
            <Label style={styles.txtTerms} size="x_small">
              4. The Hoplay Services contain Content of Hoplay ("Hoplay
              Content"). Hoplay Content is protected by copyright, trademark,
              patent, trade secret and other laws, and Hoplay owns and retains
              all rights in the Hoplay Content and the Hoplay Services. Hoplay
              hereby grants you a limited, revocable, no sublicensable license
              to reproduce and display the Hoplay Content (excluding any
              software code) solely for your personal use in connection with
              viewing the Site and using the Hoplay Services.
            </Label>
            <Label style={styles.txtTerms} size="x_small">
              5. The Hoplay Services contain Content of Users and other Hoplay
              licensors. Except as provided within this Agreement, you may not
              copy, modify, translate, publish, broadcast, transmit, distribute,
              perform, display, or sell any Content appearing on or through the
              Hoplay Services.
            </Label>
            <Label style={styles.txtTerms} size="x_small">
              6. Hoplay performs technical functions necessary to offer the
              Hoplay Services, including but not limited to transcoding and/or
              reformatting Content to allow its use throughout the Hoplay
              Services.
            </Label>
            <Label style={styles.txtTerms} size="x_small">
              7. Although the Site and other Hoplay Services are normally
              available, there will be occasions when the Site or other Hoplay
              Services will be interrupted for scheduled maintenance or
              upgrades, for emergency repairs, or due to failure of
              telecommunications links and equipment that are beyond the control
              of Hoplay. Also, although Hoplay will normally only delete Content
              that violates this Agreement, Hoplay reserves the right to delete
              any Content for any reason, without prior notice. Deleted content
              may be stored by Hoplay in order to comply with certain legal
              obligations and is not retrievable without a valid court order.
              Consequently, Hoplay encourages you to maintain your own backup of
              your Content. In other words, Hoplay is not a backup service.
              Hoplay will not be liable to you for any modification, suspension,
              or discontinuation of the Hoplay Services, or the loss of any
              Content.
            </Label>
          </ScrollView>
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
  };
};

export default compose(connect(mapStateToProps, {}))(TermsPage);
