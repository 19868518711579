import React, { memo, Component } from 'react';
import {
  View,
  StyleSheet,
  FlatList,
  TouchableOpacity,
  Linking,
  ActivityIndicator,
} from 'react-native';
import { connect } from 'react-redux';
import firebase from 'firebase/app';
import 'firebase/database';

import {
  Placeholder,
  Progressive
} from "rn-placeholder";

import ImageWithPlaceholder from '../../components/image-with-placeholder';
import ClanSmallCard from '../../components/cards/clan-small-card';
import { perfectSize } from '../../helpers/deviceHelper';

import * as FirebasePaths from '../../constants/firebasePaths';

import NavigationService from '../../navigation/navigationService';
import AdSpotService from '../../services/adSpot.service';
import { useIsFocused } from '@react-navigation/native';
import { dataForSkeleton } from '../../services/utils';
import SkeletonTextContent from '../../components/skeletonWrapper/skeletonTextContent';

class VerifiedClans extends Component {
  constructor(props) {
    super(props);
    this.state = {
      verifiedTeamsArr: [],
      AD: null,
      isLoading: true,
    };
  }

  componentDidMount() {
    this.focusListener$ = this.props.navigation.addListener(
      'focus',
      (payload) => {
        // Log Feature
        global.featuresLogger.LOG_TEAMS_VERIFIED_TEAMS_SECTION_VIEW();
      },
    );

    // Load all the verified teams
    this.loadVerifiedTeams();

    // Load the AD
    this.ADRef$ = firebase.database().ref(
      FirebasePaths.FIREBASE_AD_SPOTS +
      '/' +
      FirebasePaths.FIREBASE_AD_SPOTS_CLANS,
    );
    this.ADRef$.on('value', (snapshot) => {
      if (snapshot.val() != null) {
        this.setState({
          AD: snapshot.val(),
        });
      } else {
        this.setState({
          AD: 'NONE',
        });
      }
    });
  }

  componentWillUnmount() {
    if (this.focusListener$) {
      this.focusListener$();
    }
    if (this.verifiedTeamsRef) {
      this.verifiedTeamsRef.off();
      this.verifiedTeamsRef = null;
    }
    if (this.ADRef$) {
      this.ADRef$.off();
      this.ADRef$ = null;
    }
  }

  // This function will load all the verified teams
  loadVerifiedTeams() {
    let path = FirebasePaths.FIREBASE_TEAM_VERIFIED_TEAMS;

    this.verifiedTeamsRef = firebase.database().ref(path);
    this.verifiedTeamsRef.on('value', (snapshot) => {
      const val = snapshot.val();
      if (val != null) {
        // the `val` type is either object or array because the verified teams list key is number
        if (Array.isArray(val)) {
          this.setState({
            verifiedTeamsArr: snapshot.val().filter((obj) => obj),
          });
        } else {
          let verifiedTeamsArr = [];
          for (var key in val) {
            if (val.hasOwnProperty(key)) {
              let obj = val[key];
              verifiedTeamsArr.push(obj);
            }
          }
          this.setState({ verifiedTeamsArr });
        }
      }
      this.setState({ isLoading: false });
    });
  }

  // This function will redirect the user to the team
  toVerifiedTeam = (team) => {
    NavigationService.moveToScreenInStack('HomeStack', 'TeamPage', {
      TEAM_ID: team.id,
      CAME_FROM: 'TEAMS',
    });
  };

  openClansAD = () => {
    // First log the click for this ad
    global.featuresLogger.CLANS_AD_CLICK();

    const { AD } = this.state;
    if (AD != null && AD.url != null) {
      AdSpotService.checkAdUrl(AD);
    }
  };

  render() {
    const { theme, isFocused } = this.props;
    const _styles = styles(theme);
    const { AD, verifiedTeamsArr, isLoading } = this.state;
    if (isFocused) {
      return (
        <View style={_styles.content}>
          {isLoading ? (
            <View style={[_styles.content, _styles.center]}>
              <FlatList
                keyExtractor={(item, index) => item.toString()}
                numColumns={5}
                style={{ width: '100%' }}
                data={dataForSkeleton(20)}
                showsVerticalScrollIndicator={false}
                renderItem={({ item, index }) => {
                  return <View style={_styles.left}>
                    <Placeholder
                      Animation={Progressive}
                    >
                      <SkeletonTextContent height={100} />
                      <SkeletonTextContent height={20} style={{ marginTop: 10 }} />
                      <View style={{ alignItems: 'center' }}>
                        <SkeletonTextContent height={20} width={30} style={{ marginTop: 10 }} />
                      </View>
                    </Placeholder>
                  </View>;
                }}
              />
            </View>
          ) : (
            <>
              {AD != null && AD !== 'NONE' && (
                <TouchableOpacity
                  onPress={() => this.openClansAD()}
                  style={{ marginBottom: perfectSize(16) }}>
                  <View style={_styles.adCard}>
                    {AD.img != null && (
                      <ImageWithPlaceholder
                        style={_styles.adCover}
                        uri={AD.img}
                      />
                    )}
                  </View>
                </TouchableOpacity>
              )}
              <FlatList
                data={verifiedTeamsArr}
                showsVerticalScrollIndicator={false}
                renderItem={({ item, index }) => (
                  <TouchableOpacity
                    onPress={() => this.toVerifiedTeam(item)}
                    style={_styles.left}>
                    <ClanSmallCard
                      teamId={item.id}
                      photo={item.photo}
                      title={item.name}
                      index={index}
                      isVerified={true}
                    />
                  </TouchableOpacity>
                )}
                keyExtractor={(item, index) => index.toString()}
                numColumns={5}
              />
            </>
          )}
        </View>
      );
    }
    return <View />;
  }
}

const styles = (theme) =>
  StyleSheet.create({
    content: {
      flex: 1,
    },
    center: {
      justifyContent: 'center',
      alignItems: 'center',
    },
    adCard: {
      borderRadius: perfectSize(12),
      width: 350,
      aspectRatio: 327 / 100,
      height: 150,
      overflow: 'hidden',
    },
    adCover: {
      width: '100%',
      height: '100%',
    },
    left: {
      width: '20%',
      paddingHorizontal: 10,
      marginBottom: perfectSize(16),
    },
    right: {
      width: '50%',
      paddingLeft: perfectSize(8),
      marginBottom: perfectSize(16),
    },
  });

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
  };
};

function focusComponent(props) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const isFocused = useIsFocused();
  return <VerifiedClans {...props} isFocused={isFocused} />;
}

export default memo(connect(mapStateToProps)(focusComponent));
