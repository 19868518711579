let _navigator;

function setTopLevelNavigator(navigatorRef) {
  _navigator = navigatorRef;
}

function navigate(routeName, params) {
  _navigator.navigate(routeName, {
    params,
  });
}

function getNavigator() {
  return _navigator;
}
// add other navigation functions that you need and export them

export default {
  getNavigator,
  navigate,
  setTopLevelNavigator,
};
