/* eslint-disable react-native/no-inline-styles */
import React, { memo, Component } from 'react';
import { View, TouchableOpacity, StyleSheet } from 'react-native';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// import LinearGradient from 'react-native-linear-gradient';

import AppStyles from '../../../constants/styles';
import { perfectSize } from '../../../helpers/deviceHelper';

import GameManager from '../../../services/gameManager';
import Label from '../../label';
import OverlayImage from '../../overlay-image';
import Tag from '../../buttons/tag';
import Join from '../../buttons/normal-button';
import UserAvatar from '../../user-avatar';
import {
  getHumantime,
  getColorTimestamp,
  getWidthTimestamp,
} from '../../../services/utils';
import firebase from 'firebase/app';
import 'firebase/database';
import * as FirebasePaths from '../../../constants/firebasePaths';
import { getFlag } from '../../../services/utils';
import Feather from 'react-native-vector-icons/Feather';
import * as Images from '../../../constants/images';
import I18n from '../../../i18n';
import Lobbycore from '../../../pages/lobby/lobbycore';
import ImageWithPlaceholder from '../../image-with-placeholder';
import LocalImageOpacity from '../../local-image-opacity';
import { LinearGradient } from 'expo-linear-gradient';


class HoplayRequestCardSmall extends Component {
  constructor(props) {
    super(props);
    this.mount = true;
    this.state = {
      userPhoto: null,
      rank: -1,
    };
  }

  componentDidMount() {
    if (this.props.request !== undefined) {
      this.setState({
        playersNumber: this.props.request.playersNumber,
        currentPlayersNumber: this.props.request.currentPlayersNumber,
      });
      let ref =
        FirebasePaths.FIREBASE_USERS_INFO_ATTR +
        '/' +
        this.props.request.admin +
        '/' +
        FirebasePaths.FIREBASE_DETAILS_ATTR;
      firebase.database()
        .ref(ref)
        .once('value')
        .then((snapshot) => {
          const shot = snapshot.val();
          let photoUrl = shot._picUrl_;
          let rank = 0;
          if (shot._rank_) {
            rank = shot._rank_;
          }

          if (!photoUrl || photoUrl === 'NULL' || photoUrl === 'default') {
            photoUrl =
              'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/teams%20default%20photos%20and%20background%2Fdefault_team_logov2.png?alt=media&token=b88bf926-35b9-4568-a80e-ee24110aceb6';
          }
          if (this.mount) {
            this.setState({
              userPhoto: photoUrl,
              rank: rank,
            });
          }
        });

      this.getProfileBorderColor();

      Lobbycore.loadLobby(
        this.props.request.requestId,
        this.props.request.platform,
        this.props.request.gameId,
        this.props.request.region,
      ).then((response) => {
        let request = response.val();
        if (this.mount) {
          this.setState({
            playersNumber: request.playersNumber,
            currentPlayersNumber: request.currentPlayersNumber,
          });
        }
      });
    }
  }

  componentWillUnmount = () => {
    this.mount = false;
  }

  getProfileBorderColor() {
    let cosmeticsPath =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      this.props.request.admin +
      '/' +
      FirebasePaths.FIREBASE_COSMETICS_ATTR +
      '/' +
      FirebasePaths.FIREBASE_PROFILE_BORDER_ATTR;

    firebase.database()
      .ref(cosmeticsPath)
      .once('value')
      .then((snapshot) => {
        let borderColor = '#880e4a';
        snapshot.forEach((item) => {
          if (item.key === FirebasePaths.FIREBASE_COLOR_ATTR) {
            borderColor = item.val();
          }
        });
        if (this.mount) {
          this.setState({ borderColor: borderColor });
        }

      });
  }

  render() {
    const { theme, request, originalImage } = this.props;
    const styles = style(theme);

    let compLabel = '';
    if (request !== undefined) {
      compLabel = 'All Matches';
      if (request.matchType === 'Competitive') {
        compLabel = 'Comp';
      } else if (request.matchType === 'Quick Match') {
        compLabel = 'Quick';
      }
    }
    const url = this.props.gamesList[this.props.request.gameId] !== undefined
      ? this.props.gamesList[this.props.request.gameId]
        .gameLargePhotoUrl
        ? this.props.gamesList[this.props.request.gameId]
          .gameLargePhotoUrl
        : this.props.gamesList[this.props.request.gameId]
          .gamePhotoUrl
      : '';
    return (
      <>
        {request !== undefined && (
          <TouchableOpacity
            onPress={this.props.onPress}
            style={[styles.container, this.props.customStyles]}>
            <View style={styles.headerWrap}>
              <OverlayImage
                uri={url}
                style={styles.fullSize}
              />
              <View
                style={[
                  styles.seperator,
                  {
                    backgroundColor: getColorTimestamp(request.timeStamp),
                    width: getWidthTimestamp(request.timeStamp) + '%',
                  },
                ]}
              />

              <LinearGradient
                colors={['#00000033', '#000000cc']}
                style={styles.gradient}
              />
            </View>
            <View style={styles.userWrap}>
              <View
                style={[
                  styles.flexRow,
                  {
                    flex: 1,
                    marginHorizontal: perfectSize(16),
                    alignItems: 'center',
                  },
                ]}>
                <UserAvatar
                  src={this.state.userPhoto}
                  size={perfectSize(32)}
                  borderColor={this.state.borderColor}
                  style={{ marginRight: perfectSize(10) }}
                />
                <View style={[styles.userInfoWrap, { flex: 1 }]}>
                  <View style={styles.flexRow}>
                    <Label
                      size="x_small"
                      style={styles.userName}
                      numberOfLines={1}>
                      {request.adminName}
                    </Label>
                    {this.state.rank >= 0 && (
                      <ImageWithPlaceholder
                        uri={global.rankLogger.getRankImg(this.state.rank)}
                        style={global.rankLogger.getRankStyle(this.state.rank)}
                        resizeMode="contain"
                        placeholder={Images.PLACEHOLDER_AVATAR}
                      />
                    )}
                  </View>
                  <Label size="small" style={styles.point}>
                    {getHumantime(request.timeStamp)}
                  </Label>
                </View>
              </View>
            </View>
            <View style={styles.infoWrap}>
              <Label style={styles.point} numberOfLines={1}>
                {GameManager.getGameById(request.gameId)?.gameName}
              </Label>
              <View style={[styles.flexRow, styles.alignCenter]}>
                {request.region && (
                  <LocalImageOpacity
                    source={getFlag(request.region)}
                    style={styles.image}
                    resizeMode="contain"
                  />
                )}

                <Label
                  style={[
                    styles.point,
                    {
                      marginLeft: perfectSize(4),
                      fontSize: perfectSize(10),
                    },
                  ]}>
                  {request.region}
                </Label>
              </View>
              <View style={[styles.flexRow, styles.platformContainer]}>
                {request.platform === 'PS' && (
                  <LocalImageOpacity
                    source={Images.BTN_TAG_PLAYSTATION_SELECTED}
                    style={styles.btn_tag}
                    resizeMode="contain"
                  />
                )}
                {request.platform === 'PC' && (
                  <LocalImageOpacity
                    source={Images.BTN_TAG_DESKTOP_SELECTED}
                    style={styles.btn_tag}
                    resizeMode="contain"
                  />
                )}
                {request.platform === 'XBOX' && (
                  <LocalImageOpacity
                    source={Images.BTN_TAG_BALL_SELECTED}
                    style={styles.btn_tag}
                    resizeMode="contain"
                  />
                )}
                {request.platform === 'MOBILE' && (
                  <LocalImageOpacity
                    source={Images.BTN_TAG_MOBILE_SELECTED}
                    style={styles.btn_tag}
                    resizeMode="contain"
                  />
                )}
                {request.platform === 'NINTENDO' && (
                  <LocalImageOpacity
                    source={Images.BTN_TAG_DOUBLE_SELECTED}
                    style={styles.btn_tag}
                    resizeMode="contain"
                  />
                )}
                <Tag
                  title={compLabel}
                  customStyles={{ marginRight: perfectSize(5) }}
                />
              </View>
              <View style={[styles.alignCenter]}>
                <View
                  style={[styles.flexRow, { marginVertical: perfectSize(10) }]}>
                  <Feather name="users" color="gray" size={perfectSize(17)} />

                  <Label style={[styles.point, { marginLeft: perfectSize(4) }]}>
                    {`${this.state.currentPlayersNumber}/${this.state.playersNumber}`}
                  </Label>
                </View>

                <View style={styles.alignCenter}>
                  <Join
                    text={I18n.t('LOBBY_PAGE.join')}
                    onPress={() => {
                      this.props.onPress && this.props.onPress();
                    }}
                  />
                </View>
              </View>
            </View>
          </TouchableOpacity>
        )}
      </>
    );
  }
}

HoplayRequestCardSmall.propTypes = {
  title: PropTypes.string,
  country: PropTypes.string,
  onPress: PropTypes.func,
  userName: PropTypes.string,
  timeAgo: PropTypes.number,
  originalImage: PropTypes.any,
  timestamp: PropTypes.number,
};

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
    lang: state.appReducer.currentLang,
    gamesList: state.hubReducer.gamesList,
  };
};

export default memo(connect(mapStateToProps)(HoplayRequestCardSmall));

const style = (theme) =>
  StyleSheet.create({
    fullSize: {
      width: '100%',
      height: '100%',
    },
    container: {
      borderRadius: perfectSize(12),
      backgroundColor: theme.secondary_btn_background,
      overflow: 'hidden',
    },

    headerWrap: {
      width: '100%',
      height: perfectSize(79),
    },
    gradient: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      height: perfectSize(79),
    },
    infoWrap: {
      flex: 1,
      width: '100%',
      paddingTop: perfectSize(8),
      paddingLeft: perfectSize(10),
      paddingRight: perfectSize(10),
      paddingBottom: perfectSize(10),
    },
    userWrap: {
      position: 'absolute',
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '90%',
      top: perfectSize(24),
    },
    imgUser: {
      resizeMode: 'stretch',
      width: perfectSize(32),
      height: perfectSize(32),
    },
    userName: {
      color: theme.primary_text,
    },
    image: {
      resizeMode: 'contain',
      width: perfectSize(16),
      height: perfectSize(19),
    },
    seperator: {
      width: perfectSize(94),
      height: perfectSize(2),
      backgroundColor: theme.request_seperator,
    },
    flexRow: {
      flexDirection: 'row',
    },
    alignCenter: {
      alignItems: 'center',
    },
    point: {
      color: theme.primary_text,
      opacity: 0.56,
      marginBottom: perfectSize(4),
    },
    imgUserGroup: {
      resizeMode: 'contain',
      width: perfectSize(17.11),
      height: perfectSize(14),
    },
    btn_tag: {
      width: perfectSize(40),
      height: perfectSize(20),
      marginRight: perfectSize(4),
      marginTop: perfectSize(5),
    },
    platformContainer: {
      marginTop: perfectSize(8),
    },
  });
