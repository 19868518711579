import {handleActions} from 'redux-actions';

import {switchTheme, switchLanguage} from './actions';

import {theme} from '../../../constants/theme';

const defaultState = {
  currentTheme: theme.dark,
  currentLang: '',
  alert: false,
};

export default handleActions(
  {
    [switchTheme]: (state, {payload}) => ({
      ...state,
      currentTheme: payload,
    }),
    [switchLanguage]: (state, {payload}) => ({
      ...state,
      currentLang: payload,
    }),
  },
  defaultState,
);
