import {StyleSheet} from 'react-native';
import Dimens from '../../constants/dimens';
import {perfectSize} from '../../helpers/deviceHelper';

const styles = (theme) =>
  StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: theme.secondary_background,
    },
    content: {
      flex: 1,
      backgroundColor: theme.primary_background,
      borderTopStartRadius: Dimens.BODY_BORDER_TOP_RADIUS,
      borderTopEndRadius: Dimens.BODY_BORDER_TOP_RADIUS,
      paddingHorizontal: Dimens.HEADER_PADDING_HORIZONTAL,
      paddingTop: Dimens.HEADER_PADDING_VERTICAL,
    },
    postButtonStyle: {
      alignSelf: 'baseline',
      height: 36,
      paddingHorizontal: 16,
      marginVertical: perfectSize(8),
    },
    clearButtonStyle: {
      alignSelf: 'baseline',
      height: 36,
      paddingHorizontal: 16,
      marginVertical: perfectSize(8),
      width: 120,
    },
    postButtonTextStyle: {},
    flexView: {
      flex: 1,
    },
    backTextWhite: {
      color: '#FFF',
    },
    rowBack: {
      alignItems: 'center',
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingLeft: 15,
    },
    backRightBtn: {
      alignItems: 'center',
      bottom: perfectSize(8),
      justifyContent: 'center',
      position: 'absolute',
      top: perfectSize(16),
      width: 75,
      borderBottomRightRadius: perfectSize(16),
    },
    backRightBtnRight: {
      backgroundColor: '#C70464',
      right: 1,
    },
  });

export default styles;
