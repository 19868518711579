import {StyleSheet} from 'react-native';
import {perfectSize} from '../../helpers/deviceHelper';

export default StyleSheet.create({
  left: {
    width: '50%',
    paddingRight: perfectSize(8),
    marginBottom: perfectSize(16),
  },
  right: {
    width: '50%',
    paddingLeft: perfectSize(8),
    marginBottom: perfectSize(16),
  },
});
