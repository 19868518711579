export default class Pipes {
  /**
   * HumantimePipe
   */
  static humantime(value) {
    let currentUnixTime = new Date().getTime();

    if (value < 1000000000000) {
      // if timestamp given in seconds, convert to millis
      value *= 1000;
    }

    if (value <= 0 || value == null) {
      return '';
    }

    const SECOND_MILLIS = 1000;
    const MINUTE_MILLIS = 60 * SECOND_MILLIS;
    const HOUR_MILLIS = 60 * MINUTE_MILLIS;
    const DAY_MILLIS = 24 * HOUR_MILLIS;

    // TODO: localize
    const diff = currentUnixTime - value;

    if (Number.isNaN(diff)) {
      return 'Now';
    }

    if (diff < 1000 * 60) {
      return 'now';
    } else if (diff < 2 * MINUTE_MILLIS) {
      return '1m';
    } else if (diff < 50 * MINUTE_MILLIS) {
      return (diff / MINUTE_MILLIS).toFixed() + 'm';
    } else if (diff < 90 * MINUTE_MILLIS) {
      return '1h';
    } else if (diff < 24 * HOUR_MILLIS) {
      return (diff / HOUR_MILLIS).toFixed() + 'h';
    } else if (diff < 48 * HOUR_MILLIS) {
      return 'yesterday';
    }
    return (diff / DAY_MILLIS).toFixed() + 'd';
  }

  /**
   * LoadUserPhotoPipe
   * This pipe is not used so I didn't implement this.
   */

  /**
   * ParseUrlPipe
   */
  static urls = /(\b(https?|http|ftp|ftps|Https|rtsp|Rtsp):\/\/[A-Z0-9+&@#\/%?=~_|!:,.;-]*[-A-Z0-9+&@#\/%=~_|])/gim; // Find/Replace URL's in text
  static hashtags = /(^|\s)(#[a-z\d][\w-]*)/gi; // Find/Replace #hashtags in text
  static mentions = /(^|\s)(@[a-z\d][\w-]*)/gi; // Find/Replace @Handle/Mentions in text
  static emails = /(\S+@\S+\.\S+)/gim; // Find/Replace email addresses in text

  static parseUrl(text) {
    // Find/Replace URL's in text
    if (text.match(this.urls)) {
      text = text.replace(this.urls, function replacer($1, $2, $3) {
        let url = $1;
        let urlClean = url.replace('' + $3 + '://', '');

        return '<a href="' + url + '" target="_blank">' + urlClean + '</a>';
      });
    }

    // Find/Replace @Handle/Mentions in text
    if (text.match(this.hashtags)) {
      text = text.replace(
        this.hashtags,
        '<a href="/search/hashtag/$2" class="hashtag-link">$1$2</a>',
      );
    }

    // Find/Replace #hashtags in text
    if (text.match(this.mentions)) {
      text = text.replace(
        this.mentions,
        '<a href="/search/handle/$2" class="handle-link">$1$2</a>',
      );
    }

    // Find/Replace email addresses in text
    if (text.match(this.emails)) {
      text = text.replace(this.emails, '<a href="mailto:$1">$1</a>');
    }

    return text;
  }
}
