import {StyleSheet} from 'react-native';
import Dimens from '../../constants/dimens';
import {perfectSize} from '../../helpers/deviceHelper';

export default StyleSheet.create({
  container: {
    flex: 1,
  },
  // Header
  headerRight: {
    flex: 1,
    flexDirection: 'row-reverse',
    alignItems: 'center',
    paddingLeft: perfectSize(8),
  },
  // Content
  content: {
    flex: 1,
    borderTopStartRadius: Dimens.BODY_BORDER_TOP_RADIUS,
    borderTopEndRadius: Dimens.BODY_BORDER_TOP_RADIUS,
    paddingHorizontal: Dimens.BODY_PADDING_HORIZONTAL,
    paddingTop: Dimens.BODY_PADDING_TOP,
    // paddingBottom: Dimens.CONTENT_PADDING_BOTTOM,
  },
  userWrap: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  flexRow: {
    flexDirection: 'row',
  },
  alignCenter: {
    alignItems: 'center',
  },
  tabView: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: perfectSize(20),
    paddingVertical: perfectSize(10),
    borderRadius: perfectSize(12),
    marginTop: perfectSize(15),
  },
  tabItem: {
    alignItems: 'center',
  },
  tabItemName: {
    opacity: 0.6,
    marginVertical: perfectSize(5),
    fontSize: perfectSize(14),
  },
  tabItemScore: {
    marginVertical: perfectSize(5),
    fontSize: perfectSize(12),
  },
  activityWrap: {
    flex: 1,
    alignItems: 'center',
    marginTop: perfectSize(8),
    // justifyContent: 'center',
  },
  activitiesView: {
    flex: 1,
  },
  noActivityWrap: {
    width: '80%',
    borderWidth: 1,
    borderColor: 'white',
    borderRadius: 50,
    borderStyle: 'dotted',
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: perfectSize(8),
    marginTop: perfectSize(32),
  },
  noActivityImage: {
    width: perfectSize(50),
    height: perfectSize(50),
    resizeMode: 'contain',
  },
  noActivityText: {
    marginVertical: perfectSize(16),
    color: '#949494',
  },
  btnAction: {
    paddingHorizontal: perfectSize(16),
  },
  badge: {
    position: 'absolute',
    top: -perfectSize(4),
    right: 4,
    width: perfectSize(8),
    height: perfectSize(8),
    borderRadius: perfectSize(4),
    backgroundColor: '#C70064',
  },
});
