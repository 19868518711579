export const MAX_SAVED_REQUEST_PREMIUM = 10;
export const MAX_SAVED_REQUEST_REGULAR = 5;
export const LOCAL_NOTIFICATION_KEY = 'local-notification-key';
export const AgoraTokenUrl = 'https://app.hoplay.online/api/'
export const AgoraNotSpeakingDelay = 10;
export const AgoraSpeakingMinVolume = 5;
// export const WebSocketURL = 'wss://104.236.39.127:8082'
export const WebSocketURL = 'wss://hoplay.online:8082'
export const isBase64 = (str) => {
    return str.includes('data:image/');
}
