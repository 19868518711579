export default class Requestmodel {
  constructor() {
    this.platform = '';
    this.requestId = '';
    this.requestTitle = '';

    // ADMIN UID
    this.admin = '';

    this.description = '';
    this.region = '';
    this.playersNumber = 0;
    this.matchType = '';
    this.rank = '';
    this.timeStamp;
    this.gameVersion = 0.0;
    this.requestTime = 'ALL';

    // ADMIN USERNAME
    this.adminName = '';
    this.requestPicture = '';
    this.gameId = '';

    // For saved requests
    this.savedRequestUniqueId = '';

    // REPRESENT THE CURRENT PLAYERS NUMBER : by default it will be 1 (the admin)
    this.currentPlayersNumber = 1;

    // Admin gamer provider acc
    // PC is only the accoun not the provider we will get the provider from the request
    this.pcProviderAccount = 'pcProviderAccount';
    this.PSN_account = 'PSN_account';
    this.XBOX_live_account = 'XBOX_live_account';

    // Players map : player id as a key , player model as a value
    this.players = null;
  }

  // This function will add players to the request
  // addPlayer( playerModel : PlayerModel)
  // {
  //     // Check if player is already in the map
  //     if(!this.players.has(playerModel.UID)){
  //         this.players.set(playerModel.UID,playerModel);
  //     }
  // }

  // This function will add new request
}
