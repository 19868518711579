import {StyleSheet} from 'react-native';
import {perfectSize} from '../../helpers/deviceHelper';

export default StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#0E1319',
  },
  label: {
    opacity: 0.56,
    marginTop: perfectSize(16),
  },
  mt4: {
    marginTop: perfectSize(4),
  },
  btn_tag: {
    width: perfectSize(64),
    height: perfectSize(32),
    marginRight: perfectSize(8),
  },
  description: {
    marginTop: perfectSize(4),
    marginBottom: perfectSize(16),
  },
  itemImage: {
    width: perfectSize(32),
    height: perfectSize(32),
    aspectRatio: 1,
    borderRadius: perfectSize(12),
  },
  submitBtn: {
    marginTop: perfectSize(32),
  },
  dialogContainer: {
    backgroundColor: '#181f29',
    width: '80%',
    padding: perfectSize(32),
    margin: 0,
    alignSelf: 'center',
  },
  dialogSectionContainer: {
    marginTop: perfectSize(16),
  },
  dialogLabel: {
    color: 'white',
    fontSize: perfectSize(16),
    textAlign: 'center',
  },
  dialogDescription: {
    color: 'white',
    fontSize: perfectSize(15),
    width: '100%',
  },
  error: {
    color: 'red',
    fontSize: 14,
    marginTop: perfectSize(8),
    textAlign: 'center',
    width: '100%',
  },
  buttonContainer: {
    marginTop: perfectSize(32),
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    flexDirection: 'row',
  },
  buttonColor: {
    color: '#C70064',
    textAlign: 'right',
    width: '100%',
    fontSize: perfectSize(14),
  },
  button: {
    marginLeft: perfectSize(8),
    borderWidth: 1,
    borderColor: '#a70559',
    borderRadius: perfectSize(12),
    height: perfectSize(42),
    justifyContent: 'center',
    textAlignVertical: 'center',
    paddingHorizontal: perfectSize(12),
  },
  input: {
    textAlignVertical: 'top',
    width: '100%',
    borderBottomWidth: 1,
    borderBottomColor: '#dedede',
    color: 'white',
    marginTop: perfectSize(8),
    paddingHorizontal: 0,
    borderRadius: 0,
  },
  flex: {
    flex: 1,
  },
  flexRow: {
    flexDirection: 'row',
  },
  marginTop: {
    marginTop: 16,
  },
});
