/* eslint-disable react-native/no-inline-styles */
import React, { memo, Component } from 'react';
import {
  View,
  StyleSheet,
  TouchableOpacity,
  SafeAreaView,
  Image,
} from 'react-native';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Feather from 'react-native-vector-icons/Feather';
import firebase from 'firebase/app';
import 'firebase/database';

import { perfectSize } from '../../../helpers/deviceHelper';
import {
  PLAYSTATION_GRAY,
  THUMBS_UP,
  THUMBS_DOWN,
  NINRENDO,
  MOBILE,
  XBOX,
  PC,
  CROWN,
} from '../../../constants/images';

import UserAvatar from '../../user-avatar';
import Label from '../../label';
import TextInput from '../../text-input';
import { clearFastImageCache, getHumantime } from '../../../services/utils';
import TimelineCore from '../../../pages/timeline/timelinecore';
import NavigationService from '../../../navigation/navigationService';
import I18n from '../../../i18n';
import FriendsListCore from '../../../pages/friendslist/friendslistcore';
import { presentToast } from '../../../helpers/toastHelper';
import * as FirebasePaths from '../../../constants/firebasePaths';
import { set as HubSet } from '../../../redux/modules/hub/actions';
import { showAlert, showInputAlert } from '../../../redux/modules/alert/actions';


import AppStyles from '../../../constants/styles';
import LocalImageOpacity from '../../local-image-opacity';
import ImageWithPlaceholder from '../../image-with-placeholder';

class HopDetailCard extends Component {
  constructor(props) {
    super(props);
    this.mount = true;
    this.state = {
      textHeight: 20,
      hopsIcon: '',
    };
  }

  componentDidMount() {
    if (this.props.item.type === 'TEXT_ONLY') {
      this.getHopsIcon();
    }
  }

  componentWillUnmount() {
    this.mount = false;
  }

  showBlockAlert = () => {
    this.props.showAlert([
      I18n.t('SEARCH_REQUESTS_RESULT_PAGE.block_title'),
      I18n.t('TIMELINE_PAGE.block_alert_message'),
      [
        {
          text: I18n.t('TIMELINE_PAGE.block_alert_yes'),
          onPress: () => {
            this.addToBlockList(this.props.item.uid, this.props.item.username);
            let _blockedList = this.props.blockedList;
            if (_blockedList === undefined) {
              _blockedList = new Map();
            }
            _blockedList.set(this.props.item.uid, this.props.item.username);
            this.props.HubSet({
              blockedList: _blockedList,
            });
            presentToast({
              message: I18n.t('TIMELINE_PAGE.block_alert_success_msg'),
            });
            setTimeout(() => {
              clearFastImageCache();
              NavigationService.goBack();
            }, 1000);
          },
        },
        {
          text: I18n.t('TIMELINE_PAGE.block_alert_no'),
          onPress: () => { },
        },
      ],
      false,
    ]);
  };

  addToBlockList = (uid, username) => {
    const blockListPath =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      this.props.uid +
      '/' +
      FirebasePaths.FIREBASE_BLOCKED_USERS +
      '/';
    firebase.database()
      .ref(blockListPath + '/' + uid)
      .set(username);
  };

  addReport = () => {
    // Generate unique Id

    let reportId = firebase.database().ref().push().key;

    // Get the current time

    let date = new Date();
    let timestamp = date.toDateString();

    let reportObj = {
      msg: this.state.report,
      reporterEmail: this.props.userinformationDB.userEmail,
      reporterUsername: this.props.userinformationDB.username,
      reporterUid: this.props.uid,
      timestamp: timestamp,
      reportedUid: this.props.item.uid,
      reportedUsername: this.props.item.username,
      hopId: this.props.item.id,
      hopDescription: this.props.item.postDescription,
    };

    // Post as a new message

    firebase.database()
      .ref(
        FirebasePaths.FIREBASE_HOPS_REPORTS +
        '/' +
        FirebasePaths.FIREBASE_HOPS_REPORTS_NEW +
        '/' +
        reportId,
      )
      .set(reportObj);

    // Post as old message

    firebase.database()
      .ref(
        FirebasePaths.FIREBASE_HOPS_REPORTS +
        '/' +
        FirebasePaths.FIREBASE_HOPS_REPORTS_OLD +
        '/' +
        reportId,
      )
      .set(reportObj);

    this.setState({
      report: '',
    });
  };

  getHopsIcon() {
    let infoPath =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      this.props.item.uid +
      '/' +
      FirebasePaths.FIREBASE_DETAILS_ATTR +
      '/';

      firebase.database()
      .ref(infoPath)
      .once('value')
      .then((snapshot) => {
        let hopsIcon = '';
        snapshot.forEach((item) => {
          if (item.key === FirebasePaths.FIREBASAE_USER_HOPS_ICON) {
            hopsIcon = item.val();
          }
        });
        if (this.mount) {
          this.setState({ hopsIcon: hopsIcon });
        }
      });
  }

  openDialog = () => {
    const { theme } = this.props;
    const _styles = styles(theme);

    if (this.props.item.uid !== this.props.uid) {
      this.props.showAlert([
        I18n.t('TIMELINE_PAGE.hop_dialog_settings_title'),
        '',
        [
          {
            text: I18n.t('TIMELINE_PAGE.hop_dialog_settings_view_profile'),
            onPress: () => {
              NavigationService.moveToScreenInStack(
                'HomeStack',
                'ViewfriendprofilePage',
                {
                  FRIEND_KEY: this.props.item.uid,
                },
              );
            },
          },
          {
            text: I18n.t('TIMELINE_PAGE.hop_dialog_settings_add_friend'),
            onPress: () => {
              FriendsListCore.addFriend(this.props.item.uid);
              global.rankLogger.LOG_NEW_HP(
                global.rankLogger.ADD_FRIEND,
                'ADD_FRIEND',
              );
              presentToast({
                message: I18n.t('FRIEND_LIST_PAGE.added_successfully'),
              });
            },
          },
          {
            text: I18n.t('TIMELINE_PAGE.hop_dialog_settings_block_user'),
            onPress: () => {
              if (this.props.item.uid === this.props.uid) {
                presentToast({
                  message: I18n.t('TIMELINE_PAGE.cant_block_msg'),
                });
              } else {
                this.showBlockAlert();
              }
            },
          },
          {
            text: I18n.t('TIMELINE_PAGE.hop_dialog_settings_report_hop'),
            onPress: () => {
              this.props.showInputAlert([
                I18n.t('POST_PAGE.report_title'),
                <View style={_styles.dialogSectionContainer}>
                  <Label style={_styles.dialogDescription}>
                    {I18n.t('SEARCH_REQUESTS_RESULT_PAGE.report_msg')}
                  </Label>
                  <TextInput
                    style={_styles.input}
                    placeholder={I18n.t('POST_PAGE.report_placeholder')}
                    // numberOfLines={8}
                    // multiline={true}
                    onChangeText={(value) => {
                      this.setState({
                        report: value,
                      })
                    }}
                    placeholderTextColor={'#444444'}
                  />
                </View>,
                [
                  {
                    text: I18n.t(
                      'SEARCH_REQUESTS_RESULT_PAGE.report_cancel',
                    ).toUpperCase(),
                    onPress: () => { },
                  },
                  {
                    text: I18n.t(
                      'SEARCH_REQUESTS_RESULT_PAGE.report_send',
                    ).toUpperCase(),
                    onPress: () => {
                      if (
                        !this.state.report ||
                        this.state.report.length === 0
                      ) {
                        presentToast({
                          message: I18n.t(
                            'SEARCH_REQUESTS_RESULT_PAGE.report_validation',
                          ),
                        });
                        return;
                      }
                      global.rankLogger.LOG_NEW_HP(
                        global.rankLogger.REPORT_HOP,
                        'REPORT_HOP',
                      );
                      global.featuresLogger.LOG_FEEDBACKS_HOP_REPORT();
                      presentToast({
                        message: I18n.t(
                          'SEARCH_REQUESTS_RESULT_PAGE.report_success',
                        ),
                      });
                      this.addReport();
                    },
                  },
                ],
              ]);
            },
          },
        ],
        false,
      ]);
    } else {
      this.props.showAlert([
        I18n.t('TIMELINE_PAGE.hop_dialog_settings_title'),
        '',
        [
          {
            text: I18n.t('TIMELINE_PAGE.hop_dialog_settings_view_profile'),
            onPress: () => {
              NavigationService.moveToScreenInStack(
                'HomeStack',
                'ViewfriendprofilePage',
                {
                  FRIEND_KEY: this.props.item.uid,
                },
              );
            },
          },
          {
            text: I18n.t('TIMELINE_PAGE.hop_dialog_settings_report_hop'),
            onPress: () => {
              this.props.showInputAlert([
                I18n.t('POST_PAGE.report_title'),
                <View style={_styles.dialogSectionContainer}>
                  <Label style={_styles.dialogDescription}>
                    {I18n.t('SEARCH_REQUESTS_RESULT_PAGE.report_msg')}
                  </Label>
                  <TextInput
                    style={_styles.input}
                    placeholder={I18n.t('POST_PAGE.report_placeholder')}
                    // numberOfLines={8}
                    // multiline={true}
                    onChangeText={(value) =>
                      this.setState({
                        report: value,
                      })
                    }
                    placeholderTextColor={'#444444'}
                  />
                </View>,
                [
                  {
                    text: I18n.t(
                      'SEARCH_REQUESTS_RESULT_PAGE.report_cancel',
                    ).toUpperCase(),
                    onPress: () => { },
                  },
                  {
                    text: I18n.t(
                      'SEARCH_REQUESTS_RESULT_PAGE.report_send',
                    ).toUpperCase(),
                    onPress: () => {
                      if (
                        !this.state.report ||
                        this.state.report.length === 0
                      ) {
                        presentToast({
                          message: I18n.t(
                            'SEARCH_REQUESTS_RESULT_PAGE.report_validation',
                          ),
                        });
                        return;
                      }
                      global.rankLogger.LOG_NEW_HP(
                        global.rankLogger.REPORT_HOP,
                        'REPORT_HOP',
                      );
                      global.featuresLogger.LOG_FEEDBACKS_HOP_REPORT();
                      presentToast({
                        message: I18n.t(
                          'SEARCH_REQUESTS_RESULT_PAGE.report_success',
                        ),
                      });
                      this.addReport();
                    },
                  },
                ],
              ]);
            },
          },
        ],
        false,
      ]);
    }
  };

  render() {
    const { theme, showLike, from } = this.props;
    const isJoined = this.props.item.type === 'TEXT_ONLY';
    const _styles = styles(theme);
    const useOpacity = from !== 'profile';
    return (
      <View
        style={[
          _styles.container,
          this.props.isAdmin && {
            paddingTop: perfectSize(4),
          },
          {
            height:
              90 +
              this.state.textHeight +
              (this.props.item.images && this.props.item.images.length > 0
                ? 200
                : 0),
          },
        ]}>
        <View style={[_styles.wrapper, this.props.style]}>
          {this.props.isAdmin && (
            <LocalImageOpacity
              source={CROWN}
              style={_styles.crown}
              resizeMode="contain"
            />
          )}
          <View style={_styles.userInfo}>
            <TouchableOpacity
              onPress={() => {
                if (isJoined) {
                  NavigationService.navigate('ViewfriendprofilePage', {
                    FRIEND_KEY: this.props.item.uid,
                  });
                }
              }}>
              <UserAvatar
                from={this.props.from}
                src={
                  isJoined ? this.props.item.img : this.props.item.requestImg
                }
                uid={!isJoined ? this.props.item.uid : this.props.item.admin}
                showStatus={false}
                borderColor={this.props.item.userBorderColor}
              />
            </TouchableOpacity>
            <View style={_styles.nametimestamp}>
              <View style={[_styles.flexRow, { alignItems: 'center' }]}>
                <Label size="x_small">
                  {isJoined
                    ? this.props.item.username
                    : this.props.item.requestTitle}
                </Label>
                {isJoined && (
                  <ImageWithPlaceholder
                    uri={global.rankLogger.getRankImg(this.props.item.rank)}
                    style={global.rankLogger.getRankStyle(this.props.item.rank)}
                    resizeMode="contain"
                    useOpacity={useOpacity}
                  />
                )}
                {this.state.hopsIcon.length > 0 && (
                  <ImageWithPlaceholder
                    uri={this.state.hopsIcon}
                    style={AppStyles.rankImg}
                    resizeMode="contain"
                    useOpacity={useOpacity}
                  />
                )}
              </View>
              <Label size="small" style={_styles.timestamp}>
                {isJoined
                  ? getHumantime(this.props.item.timeStamp)
                  : getHumantime(this.props.item.requestTimeStamp)}
              </Label>
            </View>
          </View>
          <Label
            size="x_small"
            style={[
              _styles.message,
              !isJoined && {
                marginTop: perfectSize(16),
              },
            ]}
            onLayout={(event) => {
              this.setState({
                textHeight: event.nativeEvent.layout.height,
              });
              this.props.onLayout &&
                this.props.onLayout(event.nativeEvent.layout.height);
            }}>
            {isJoined
              ? this.props.item.postDescription
              : this.props.item.requestDescription}
          </Label>

          {this.props.item.images && this.props.item.images.length > 0 && (
            <View style={_styles.imgContainer}>
              {this.props.item.images.map((image, index) => (
                <TouchableOpacity
                  style={{
                    width: this.props.item.images.length > 1 ? '50%' : '100%',
                    height: this.props.item.images.length > 2 ? '50%' : '100%',
                    padding: 1,
                  }}
                  onPress={() => {
                    NavigationService.navigate('PhotoViewerPage', {
                      TITLE: 'Hop Image',
                      IMGS: this.props.item.images,
                      INDEX: index,
                    });
                  }}
                  key={index}>
                  <ImageWithPlaceholder
                    uri={image}
                    style={_styles.imgFlex}
                    resizeMode="cover"
                    useOpacity={useOpacity}
                  />
                </TouchableOpacity>
              ))}
              {this.props.item.images.length === 3 && (
                <View
                  style={{
                    width: this.props.item.images.length > 1 ? '50%' : '100%',
                    height: this.props.item.images.length > 2 ? '50%' : '100%',
                    padding: 1,
                  }}>
                </View>
              )}
            </View>
          )}

          {showLike && (
            <View style={[_styles.flexRow, _styles.likeWrapper]}>
              <View style={_styles.flexRow}>
                <Label size="small" style={_styles.upvoteText}>
                  {TimelineCore.getHopLikesCount(this.props.item)}
                </Label>
                <TouchableOpacity>
                  <LocalImageOpacity
                    source={THUMBS_UP}
                    style={[_styles.thumb, AppStyles.iconArrow]}
                    resizeMode="contain"
                  />
                </TouchableOpacity>
              </View>
              <View style={[_styles.flexRow, { marginLeft: perfectSize(10) }]}>
                <Label size="small" style={_styles.downvoteText}>
                  {TimelineCore.getHopLikesCount(this.props.item)}
                </Label>
                <TouchableOpacity>
                  <LocalImageOpacity
                    source={THUMBS_DOWN}
                    style={[_styles.thumb, AppStyles.iconArrow]}
                    resizeMode="contain"
                  />
                </TouchableOpacity>
              </View>
            </View>
          )}

          {isJoined ? (
            <TouchableOpacity
              style={[
                _styles.actionMenu,
                this.props.isAdmin && {
                  top: 16,
                },
              ]}
              onPress={() => {
                this.props.onMenu ? this.props.onMenu() : this.openDialog();
              }}>
              <View style={_styles.downContainer}>
                <Feather
                  name="chevron-down"
                  color="gray"
                  size={perfectSize(20)}
                />
              </View>
            </TouchableOpacity>
          ) : (
            <TouchableOpacity style={[_styles.actionMenu, _styles.ecllipse]}>
              {this.props.item.platform === 'XBOX' && (
                <LocalImageOpacity
                  source={XBOX}
                  resizeMethod="resize"
                  style={_styles.playStation}
                  resizeMode="contain"
                />
              )}
              {this.props.item.platform === 'PC' && (
                <LocalImageOpacity
                  source={PC}
                  resizeMethod="resize"
                  style={_styles.playStation}
                  resizeMode="contain"
                />
              )}
              {this.props.item.platform === 'PS' && (
                <LocalImageOpacity
                  source={PLAYSTATION_GRAY}
                  resizeMethod="resize"
                  style={_styles.playStation}
                  resizeMode="contain"
                />
              )}
              {this.props.item.platform === 'MOBILE' && (
                <LocalImageOpacity
                  source={MOBILE}
                  resizeMethod="resize"
                  style={_styles.playStation}
                  resizeMode="contain"
                />
              )}
              {this.props.item.platform === 'NINTENDO' && (
                <LocalImageOpacity
                  source={NINRENDO}
                  resizeMethod="resize"
                  style={_styles.playStation}
                  resizeMode="contain"
                />
              )}
            </TouchableOpacity>
          )}
        </View>
        <View style={_styles.divider} />
      </View>
    );
  }
}

HopDetailCard.propTypes = {
  data: PropTypes.object,
  isJoined: PropTypes.bool,
  showLike: PropTypes.bool,
  onJoin: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
    uid: state.authReducer.uid,
    blockedList: state.hubReducer.blockedList,
    userinformationDB: state.authReducer.userinformationDB,
  };
};

export default memo(
  connect(mapStateToProps, { HubSet, showAlert, showInputAlert })(HopDetailCard),
);

const styles = (theme) =>
  StyleSheet.create({
    container: {
      paddingTop: perfectSize(16),
      flexDirection: 'column',
    },
    wrapper: {
      marginHorizontal: perfectSize(24),
      flex: 1,
    },
    userInfo: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    nametimestamp: {
      marginLeft: perfectSize(4),
    },
    timestamp: {
      opacity: 0.56,
      marginTop: perfectSize(2),
    },
    message: {
      opacity: 0.64,
      marginTop: perfectSize(8),
      // fontSize: 24,
    },
    divider: {
      height: 1,
      backgroundColor: 'white',
      opacity: 0.05,
      marginTop: perfectSize(0),
    },
    actionMenu: {
      position: 'absolute',
      top: 0,
      right: 0,
    },
    arrowDown: {
      width: perfectSize(12.05),
      height: perfectSize(6),
      resizeMode: 'contain',
    },
    playStation: {
      width: perfectSize(14.64),
      height: perfectSize(12),
      resizeMode: 'contain',
    },
    ecllipse: {
      width: perfectSize(24),
      height: perfectSize(24),
      borderColor: '#A2A5A9',
      borderWidth: 1,
      borderRadius: perfectSize(12),
      alignItems: 'center',
      justifyContent: 'center',
      top: perfectSize(5),
      marginRight: perfectSize(1),
    },
    flexRow: {
      flexDirection: 'row',
    },
    likeWrapper: {
      justifyContent: 'flex-end',
      marginTop: perfectSize(16),
    },
    thumb: {
      marginLeft: perfectSize(4),
    },
    upvoteText: {
      color: theme.primary_btn_background,
    },
    downvoteText: {
      opacity: 0.56,
    },
    downContainer: {
      width: 40,
      height: 40,
      alignItems: 'flex-end',
      // backgroundColor: 'blue',
    },
    dialogContainer: {
      backgroundColor: '#181f29',
      width: '80%',
      padding: perfectSize(32),
      margin: 0,
      alignSelf: 'center',
    },
    buttonContainer: {
      // width: '100%', // 100% of parent width
      marginTop: perfectSize(32),
      justifyContent: 'center',
      alignItems: 'center',
    },
    dialogSectionContainer: {
      // padding: perfectSize(16),
      alignItems: 'flex-end',
      marginTop: perfectSize(16),
    },
    dialogLabel: {
      color: 'white',
      fontSize: perfectSize(16),
      textAlign: 'center',
    },
    dialogDescription: {
      color: 'white',
      fontSize: 14,
      width: '100%',
    },
    button: {
      marginHorizontal: perfectSize(4),
      borderWidth: 1,
      borderColor: '#a70559',
      borderRadius: perfectSize(10),
      height: perfectSize(42),
      justifyContent: 'center',
      paddingHorizontal: perfectSize(12),
      marginVertical: perfectSize(4),
    },
    smallButton: {
      width: perfectSize(100),
      alignItems: 'center',
      paddingRight: perfectSize(16),
      marginVertical: perfectSize(8),
    },
    buttonColor: {
      color: '#a70559',
      fontSize: perfectSize(14),
    },
    input: {
      textAlignVertical: 'top',
      width: '100%',
      borderBottomWidth: 1,
      borderBottomColor: '#dedede',
      color: 'white',
      height: perfectSize(30),
    },
    crown: {
      width: perfectSize(32),
      height: perfectSize(20),
      // marginBottom: perfectSize(8),
    },
    imgContainer: {
      height: 200,
      width: 300,
      marginTop: perfectSize(12),
      flexDirection: 'row',
      flexWrap: 'wrap',
      borderRadius: perfectSize(20),
      overflow: 'hidden',
    },
    imgFlex: {
      width: '100%',
      height: '100%',
    },
  });
