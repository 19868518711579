import React, { Component } from 'react';
import { View, TouchableOpacity, ActivityIndicator, FlatList } from 'react-native';
import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { SwipeListView } from 'react-native-swipe-list-view';
import { MaterialIndicator } from 'react-native-indicators';

import { compose } from 'redux';
import { connect } from 'react-redux';
import I18n from '../../../../i18n';

// import components
import Label from '../../../../components/label';
import HopReply from '../../../../components/hop-reply';
import HopReplySkeleton from '../../../../components/hop-reply/skeleton';
import PrimaryButton from '../../../../components/buttons/primary-button';

// import styles
import styles from './styles';

// import actions
import { switchTheme } from '../../../../redux/modules/app/actions';

import { perfectSize } from '../../../../helpers/deviceHelper';
import NavigationService from '../../../../navigation/navigationService';
import TimelineCore from '../../timelinecore';

import { presentToast } from '../../../../helpers/toastHelper';
import { set as setHub } from '../../../../redux/modules/hub/actions';
import { showAlert } from '../../../../redux/modules/alert/actions';

import Postcore from '../../../post/postcore';
import { dataForSkeleton } from '../../../../services/utils';

class OtherRepliesPage extends Component {
  replyFromOthers$ = null;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      isLoading: true,
      repliesEndAt: null,
      otherReplies: [],
      repliesHasMoreData: true,
      refreshing: false,
    };
  }

  componentWillUnmount() {
    if (this.replyFromOthers$) {
      this.replyFromOthers$.off();
      this.replyFromOthers$ = null;
    }

    clearInterval(this.interval);
  }

  componentDidMount() {
    this.loadRepliesFromOthers();

    this.interval = setInterval(
      () => this.setState({ time: Date.now() }),
      60 * 1000,
    );
  }

  loadRepliesFromOthers = () => {
    if (this.state.refreshing) return;
    this.setState({
      refreshing: true,
    });
    this.replyFromOthers$ = TimelineCore.loadRepliesFromOthers(
      this.state.repliesEndAt,
    );
    this.replyFromOthers$.on('value', (snapshot) => {
      if (snapshot.val() != null) {
        let replies = [];
        const childKeys = Object.keys(snapshot.val());
        snapshot.forEach((item) => {
          if (this.state.repliesEndAt !== null) {
            const lastKey = childKeys[childKeys.length - 1];
            if (item.key !== lastKey) {
              item.forEach((reply) => {
                replies.push(reply.val());
              });
            }
          } else {
            item.forEach((reply) => {
              replies.push(reply.val());
            });
          }
        });
        replies = replies.sort((reply1, reply2) => {
          return reply2.timeStamp - reply1.timeStamp;
        });
        this.loadStatus(replies, childKeys);
      } else {
        this.setState({
          otherReplies: [],
          isLoading: false,
          refreshing: false,
        });
      }
    });
  };

  loadStatus = async (data, keys) => {
    let replies = data;
    let childKeys = keys;
    for (let i = 0; i < replies.length; i++) {
      replies[i].userBorderColor = await Postcore.getUserBorderColor(
        replies[i].uid,
      );
    }

    replies = replies.filter(
      (reply) => !this.props.bannedUsers.includes(reply.uid),
    );
    if (this.state.repliesEndAt !== null) {
      childKeys.pop();
    }
    this.setState({
      otherReplies: [...this.state.otherReplies, ...replies],
      isLoading: false,
      repliesHasMoreData: keys.length === 10 ? true : false,
      repliesEndAt: childKeys[0],
      refreshing: false,
    });
  };

  goToDetails = (item) => {
    // NavigationService.moveToScreenInStack('HomeStack', 'PostPage');
    TimelineCore.IS_REPLY_HOP_BANNED((banned) => {
      if (banned) {
        presentToast({
          message: I18n.t('TIMELINE_PAGE.ban_alert_msg2'),
        });
      } else {
        NavigationService.moveToScreenInStack('HomeStack', 'PostPage', {
          POST_ID: item.postId,
        });
      }
    });
  };

  delete = () => {
    this.props.showAlert([
      I18n.t('TIMELINE_PAGE.delete_all_other_replies_title'),
      I18n.t('TIMELINE_PAGE.delete_all_other_replies_msg'),
      [
        {
          text: I18n.t('TIMELINE_PAGE.delete_reply_yes_btn'),
          onPress: () => {
            global.rankLogger.LOG_NEW_HP(
              global.rankLogger.DELETE_REPLY,
              'DELETE_REPLY',
            );

            this.setState({
              otherReplies: [],
            });

            TimelineCore.removeOthersReplies();
          },
        },
        {
          text: I18n.t('TIMELINE_PAGE.delete_reply_no_btn'),
          onPress: () => { },
        },
      ],
    ]);
  };

  deleteReply = (data, rowMap, rowKey) => {
    if (rowMap) {
      if (rowMap[rowKey]) {
        rowMap[rowKey].closeRow();
      }
    }

    global.rankLogger.LOG_NEW_HP(
      global.rankLogger.DELETE_REPLY,
      'DELETE_REPLY',
    );

    TimelineCore.removeOtherReply(data);
  };

  render() {
    const { theme, blockedList } = this.props;
    const _styles = styles(theme);
    const { isLoading } = this.state;

    return (
      <View style={_styles.container}>
        <View style={_styles.content}>
          {isLoading ? (
            <View style={_styles.subContent}>
              <FlatList
                showsVerticalScrollIndicator={false}
                data={dataForSkeleton(8)}
                style={{ width: '100%' }}
                renderItem={() => {
                  return <HopReplySkeleton />;
                }}
              />
            </View>
          ) : (
            <View style={_styles.flexView}>
              <PrimaryButton
                text={I18n.t('TIMELINE_PAGE.clear')}
                size="x_small"
                type="filled"
                customStyles={_styles.clearButtonStyle}
                textCustomStyles={_styles.postButtonTextStyle}
                onPress={this.delete}
                leftComponent={
                  <FontAwesome5
                    name="trash"
                    size={perfectSize(18)}
                    color={theme.primary_text}
                    style={{ marginRight: perfectSize(12) }}
                  />
                }
              />

              <SwipeListView
                useFlatList={true}
                showsVerticalScrollIndicator={false}
                data={this.state.otherReplies.filter((reply) => {
                  return blockedList.size == 0 || !blockedList.has(reply.uid);
                })}
                renderItem={({ item, index }) => {
                  return (
                    <HopReply
                      item={item}
                      index={index}
                      onPress={() => this.goToDetails(item)}
                      onLongPress={() => this.deleteReply(item)}
                    />
                  );
                }}
                onEndReached={({ distanceFromEnd }) => {
                  this.loadRepliesFromOthers();
                }}
                ListFooterComponent={
                  this.state.repliesHasMoreData &&
                    this.state.refreshing ? (
                    <MaterialIndicator
                      color="#C70064"
                      size={perfectSize(35)}
                    />
                  ) : (
                    <View />
                  )
                }
                onEndReachedThreshold={0.5}
                keyExtractor={(item, index) => index.toString()}
                renderHiddenItem={(data, rowMap) => {
                  return (
                    <View style={_styles.rowBack}>
                      <TouchableOpacity
                        style={[
                          _styles.backRightBtn,
                          _styles.backRightBtnRight,
                        ]}
                        onPress={() =>
                          this.deleteReply(data.item, rowMap, data.index)
                        }>
                        <MaterialCommunityIcons
                          name="delete"
                          size={perfectSize(18)}
                          color={'white'}
                        />
                        <Label style={_styles.backTextWhite}>
                          {I18n.t('GAME_LIST_PAGE.delete')}
                        </Label>
                      </TouchableOpacity>
                    </View>
                  );
                }}
                rightOpenValue={-75}
                previewRowKey={'0'}
                previewOpenValue={-40}
                previewOpenDelay={3000}
              />
            </View>
          )}
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
    lang: state.appReducer.currentLang,
    blockedList: state.hubReducer.blockedList,
    bannedUsers: state.hubReducer.bannedUsers,
  };
};

export default compose(
  connect(mapStateToProps, { switchTheme, setHub, showAlert }),
)(OtherRepliesPage);
