import React, {memo, Component} from 'react';
import {StyleSheet, ScrollView, View} from 'react-native';
import {connect} from 'react-redux';
import I18n from '../../i18n';
import {store} from '../../redux/store';

import Label from '../../components/label';
import {perfectSize} from '../../helpers/deviceHelper';

class Info extends Component {
  constructor(props) {
    super(props);
    const params = props.route.params;
    this.state = {
      championshipObj: params.championshipObj,
    };
  }

  getGameName() {
    const {championshipObj} = this.state;
    const gamesList = store.getState().hubReducer.gamesList;
    return gamesList[championshipObj.game]?.gameName;
  }

  render() {
    const {theme} = this.props;
    const _styles = styles(theme);
    const {championshipObj} = this.state;
    return (
      <ScrollView style={_styles.content}>
        {championshipObj.game != null && (
          <View>
            <Label style={_styles.title}>
              {I18n.t('CHAMPIONSHIP_INFO_PAGE.game')}
            </Label>
            <Label style={_styles.value}>{this.getGameName()}</Label>
          </View>
        )}
        {championshipObj.platform != null && (
          <View>
            <Label style={_styles.title}>
              {I18n.t('CHAMPIONSHIP_INFO_PAGE.platform')}
            </Label>
            <Label style={_styles.value}>{championshipObj.platform}</Label>
          </View>
        )}
        {championshipObj.prize != null && (
          <View>
            <Label style={_styles.title}>
              {I18n.t('CHAMPIONSHIP_INFO_PAGE.prize')}
            </Label>
            <Label style={_styles.value}>{championshipObj.prize}</Label>
          </View>
        )}
        {championshipObj.server != null && (
          <View>
            <Label style={_styles.title}>
              {I18n.t('CHAMPIONSHIP_INFO_PAGE.server')}
            </Label>
            <Label style={_styles.value}>{championshipObj.server.trim()}</Label>
          </View>
        )}
        {championshipObj.max_participants != null && (
          <View>
            <Label style={_styles.title}>
              {I18n.t('CHAMPIONSHIP_INFO_PAGE.number_of_participants')}
            </Label>
            <Label style={_styles.value}>
              {championshipObj.max_participants}
            </Label>
          </View>
        )}
        {championshipObj.number_of_players != null && (
          <View>
            <Label style={_styles.title}>
              {I18n.t('CHAMPIONSHIP_INFO_PAGE.number_of_players')}
            </Label>
            <Label style={_styles.value}>
              {championshipObj.number_of_players}
            </Label>
          </View>
        )}
        {championshipObj.players_ranks != null && (
          <View>
            <Label style={_styles.title}>
              {I18n.t('CHAMPIONSHIP_INFO_PAGE.players_rank')}
            </Label>
            <Label style={_styles.value}>{championshipObj.players_ranks}</Label>
          </View>
        )}
        {championshipObj.registration_starts != null && (
          <View>
            <Label style={_styles.title}>
              {I18n.t('CHAMPIONSHIP_INFO_PAGE.registration_starts')}
            </Label>
            <Label style={_styles.value}>
              {championshipObj.registration_starts}
            </Label>
          </View>
        )}
        {championshipObj.registration_ends != null && (
          <View>
            <Label style={_styles.title}>
              {I18n.t('CHAMPIONSHIP_INFO_PAGE.registration_ends')}
            </Label>
            <Label style={_styles.value}>
              {championshipObj.registration_ends}
            </Label>
          </View>
        )}
        {championshipObj.tournament_starts != null && (
          <View>
            <Label style={_styles.title}>
              {I18n.t('CHAMPIONSHIP_INFO_PAGE.tournament_starts')}
            </Label>
            <Label style={_styles.value}>
              {championshipObj.tournament_starts}
            </Label>
          </View>
        )}
        {championshipObj.tournament_ends != null && (
          <View>
            <Label style={_styles.title}>
              {I18n.t('CHAMPIONSHIP_INFO_PAGE.tournament_ends')}
            </Label>
            <Label style={_styles.value}>
              {championshipObj.tournament_ends}
            </Label>
          </View>
        )}
        {championshipObj.description != null && (
          <View>
            <Label style={_styles.title}>
              {I18n.t('CHAMPIONSHIP_INFO_PAGE.description')}
            </Label>
            <Label style={_styles.value}>{championshipObj.description}</Label>
          </View>
        )}
        <View style={_styles.footer} />
      </ScrollView>
    );
  }
}

const styles = (theme) =>
  StyleSheet.create({
    content: {
      backgroundColor: theme.primary_background,
    },
    title: {
      fontSize: perfectSize(12),
      marginBottom: perfectSize(4),
    },
    value: {
      fontSize: perfectSize(12),
      color: '#A2A5A9',
      marginBottom: perfectSize(12),
    },
    footer: {
      height: perfectSize(4),
    },
  });

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
  };
};

export default memo(connect(mapStateToProps)(Info));
