import React, { Component } from 'react';
import { View, TouchableOpacity, FlatList } from 'react-native';
import { compose } from 'redux';
import { connect } from 'react-redux';
import firebase from 'firebase/app';
import 'firebase/database';

import NavigationService from '../../navigation/navigationService';
import I18n from '../../i18n';

import * as FirebasePaths from '../../constants/firebasePaths';

// import components
import Label from '../../components/label';
import ChatItemHolder from '../../components/chat-item-holder';

// import styles
import AppStyles from '../../constants/styles';
import styles from './styles';
import Pipes from '../../services/pipes';
import Mainmenucore from '../mainmenu/mainmenucore';
import { perfectSize } from '../../helpers/deviceHelper';

// import actions
import { switchTheme } from '../../redux/modules/app/actions';
import { set as setHub } from '../../redux/modules/hub/actions';
import { showAlert } from '../../redux/modules/alert/actions';
import mainMenuService from '../../navigation/mainMenuService';
import topTabService from '../../navigation/topTabService';

import {
  CLANS_LOADING_DARK_GIF,
  CLANS_LOADING_GIF,
  ACCOUNT_GROUP,
} from '../../constants/images';
import { useIsFocused } from '@react-navigation/native';
import LocalImageOpacity from '../../components/local-image-opacity';

class ClansChat extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    Mainmenucore.loadClansChats();
  }

  componentWillUnmount() {
    if (Mainmenucore.clansChatsRef$) {
      Mainmenucore.clansChatsRef$.off();
    }
  }

  // This function will redirect the user to the clan chat
  toClanChat = (chat) => {
    // First load the admin data to pass it to the team chat page
    firebase.database()
      .ref(
        FirebasePaths.FIREBASE_TEAMS +
        '/' +
        chat.teamId +
        '/' +
        FirebasePaths.FIREBASE_TEAM_INFO,
      )
      .once('value')
      .then((snapshot) => {
        let obj = snapshot.val();
        if (obj.admin != null) {
          // Disable chat local notifications when open chat
          this.props.setHub({ IS_IN_CHAT: true });
          NavigationService.moveToScreenInStack('HomeStack', 'TeamChatPage', {
            // NavigationService.navigate('TeamChatPage', {
            TEAM_ID: chat.teamId,
            TEAM_LOGO: chat.teamLogo,
            TEAM_NAME: chat.teamName,
            ADMIN_ID: obj.admin,
          });
        } else {
          this.props.showAlert([
            'شيت يا رجال',
            'فيه مشكلة في شات الكلان تواصل معنا بنحلها ',
            [
              {
                text: 'اضغط هنا !',
                onPress: () =>
                  NavigationService.moveToScreenInStack(
                    'SettingsStack',
                    'SupportPage',
                  ),
              },
            ],
          ]);
        }
      });
  };

  // Process community last chat : if the message is img then do not show the url
  processLastMessage = (chat) => {
    let message = chat.uid !== this.props.uid ? chat.username + ': ' : '';
    if (chat.message != null && chat.message !== undefined) {
      if (chat.message.includes('https://firebasestorage.googleapis.com')) {
        if (chat.type === 'sticker') {
          message += 'sent sticker';
        } else {
          message += 'sent photo';
        }
      } else {
        message += chat.message;
      }
    } else {
      message = 'Message';
    }
    return message;
  };

  // This function will trigger the fab button base on the three section : chats sections
  fabBtnTrigger = () => {
    mainMenuService.navigate('Home');
    topTabService.navigate('Clans');
  };

  // Getting Animated Gif for the right mode
  getGif() {
    if (this.props.theme.mode === 'dark') {
      return CLANS_LOADING_DARK_GIF;
    } else {
      return CLANS_LOADING_GIF;
    }
  }

  render() {
    const { uid, clansChatsArr, isFocused } = this.props;
    if (isFocused) {
      return (
        <View style={styles.subContent}>
          {clansChatsArr.length > 0 ? (
            <FlatList
              data={clansChatsArr}
              showsVerticalScrollIndicator={false}
              renderItem={({ item, index }) => (
                <View>
                  {item.id != null ? (
                    <TouchableOpacity onPress={() => this.toClanChat(item)}>
                      <ChatItemHolder
                        avatar={item.teamLogo}
                        title={item.teamName}
                        timestamp={Pipes.humantime(item.timeStamp)}
                        message={this.processLastMessage(item)}
                        deleted={item.deleted}
                        showUnreadBadge={
                          item.uid !== uid && item.opened === false
                        }
                        type={item.uid === this.props.uid ? 0 : 1}
                        index={index}
                      />
                    </TouchableOpacity>
                  ) : null}
                </View>
              )}
              keyExtractor={(item, index) => index.toString()}
            />
          ) : (
            <View style={styles.emptyContent}>
              <View style={AppStyles.emptyContent}>
                {/* <FastImage
                source={this.getGif()}
                style={AppStyles.emptyContentGif}
                resizeMode="contain"
              /> */}
                <Label style={AppStyles.emptyContentLabel}>
                  {I18n.t('TEAMS_PAGE.no_teams_msg')}
                </Label>
              </View>
            </View>
          )}
          <TouchableOpacity
            onPress={() => this.fabBtnTrigger()}
            style={[AppStyles.fabButton, styles.fabButton]}>
            <LocalImageOpacity
              source={ACCOUNT_GROUP}
              style={{ width: perfectSize(20), height: perfectSize(20) }}
              resizeMode="contain"
            />
          </TouchableOpacity>
        </View>
      );
    }
    return <View />;
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
    lang: state.appReducer.currentLang,
    uid: state.authReducer.uid,
    clansChatsArr: state.hubReducer.clansChatsArr,
  };
};

function focusComponent(props) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const isFocused = useIsFocused();
  return <ClansChat {...props} isFocused={isFocused} />;
}

export default compose(
  connect(mapStateToProps, { switchTheme, setHub, showAlert }),
)(focusComponent);
