import {combineReducers} from 'redux';

import appReducer from './app/index';
import authReducer from './auth/index';
import firebaseReducer from './firebase/index';
import gameManagerReducer from './gameManager/index';
import hubReducer from './hub/index';
import alertReducer from './alert/index';

export default combineReducers({
  appReducer,
  authReducer,
  firebaseReducer,
  gameManagerReducer,
  hubReducer,
  alertReducer,
});
