import {StyleSheet} from 'react-native';
import Dimens from '../../constants/dimens';
import {perfectSize} from '../../helpers/deviceHelper';

export default StyleSheet.create({
  container: {
    flex: 1,
  },
  body: {
    flex: 1,
    borderTopStartRadius: Dimens.BODY_BORDER_TOP_RADIUS,
    borderTopEndRadius: Dimens.BODY_BORDER_TOP_RADIUS,
    paddingHorizontal: Dimens.HEADER_PADDING_HORIZONTAL,
    paddingTop: Dimens.BODY_PADDING_TOP,
  },
  content: {
    flex: 1,
  },
  flatList: {
    justifyContent: 'space-between',
  },
  separator: {
    height: perfectSize(16),
  },
  adCard: {
    borderRadius: perfectSize(12),
    marginBottom: perfectSize(16),
    width: '100%',
    aspectRatio: 327 / 100,
    height: 250,
    overflow: 'hidden',
  },
  adCover: {
    width: '100%',
    height: '100%',
  },
});
