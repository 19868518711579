import {StyleSheet} from 'react-native';
import {perfectSize} from '../helpers/deviceHelper';
import Dimens from './dimens';
import {FONT_SIZE} from '../constants/theme';

export default StyleSheet.create({
  // Header
  leftBarButton: {
    width: Dimens.BAR_BUTTON_SIZE,
    height: Dimens.BAR_BUTTON_SIZE,
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: perfectSize(8),
  },
  rightBarButton: {
    width: Dimens.BAR_BUTTON_SIZE,
    height: Dimens.BAR_BUTTON_SIZE,
    justifyContent: 'center',
    alignItems: 'center',
  },

  // Content
  rootContainer: {
    flex: 1,
    backgroundColor: '#181F29',
  },
  rootContent: {
    flex: 1,
    backgroundColor: '#0E1319',
    borderTopStartRadius: Dimens.BODY_BORDER_TOP_RADIUS,
    borderTopEndRadius: Dimens.BODY_BORDER_TOP_RADIUS,
    paddingHorizontal: Dimens.BODY_PADDING_HORIZONTAL,
    paddingTop: Dimens.BODY_PADDING_TOP,
  },
  contentFotter: {
    paddingBottom: Dimens.CONTENT_PADDING_BOTTOM,
  },

  // Spinner
  spinnerTextStyle: {
    color: 'white',
  },

  // Label
  grayLabel: {
    color: '#7D8693',
  },
  opacityLabel: {
    opacity: 0.56,
  },

  // Custom TextInput
  textInputContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: 52,
    backgroundColor: '#181F29',
    paddingHorizontal: perfectSize(16),
    borderRadius: perfectSize(12),
  },
  textInput: {
    color: 'white',
    fontSize: FONT_SIZE.medium,
    paddingLeft: perfectSize(8),
    flex: 1,
    opacity: 0.56,
  },
  textInputPlaceholder: {
    opacity: 0.56,
  },

  // Floating Action Button
  fabButton: {
    width: perfectSize(48),
    height: perfectSize(48),
    borderRadius: perfectSize(24),
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#C70064',
  },

  transparent: {
    backgroundColor: 'transparent',
  },

  // Empty content
  emptyContent: {
    borderWidth: 1,
    borderColor: '#616161',
    borderRadius: perfectSize(36),
    padding: perfectSize(36),
    alignItems: 'center',
  },
  emptyContentGif: {
    width: perfectSize(80),
    height: perfectSize(80),
  },
  emptyContentLabel: {
    opacity: 0.56,
    textAlign: 'center',
    marginTop: perfectSize(16),
  },

  noGameWrap: {
    borderRadius: perfectSize(12),
    borderWidth: 1,
    borderStyle: 'dotted',
    borderColor: 'white',
    padding: perfectSize(36),
    justifyContent: 'center',
    alignItems: 'center',
  },
  supportLoading: {
    width: perfectSize(80),
    height: perfectSize(80),
  },
  txtNoGame: {
    color: '#949494',
    textAlign: 'center',
    marginVertical: perfectSize(16),
  },
  // Image icons
  iconWrap: {
    width: perfectSize(16),
    height: perfectSize(16),
    resizeMode: 'contain',
  },
  iconArrow: {
    width: perfectSize(12),
    height: perfectSize(12),
  },
  // Rank image
  rankImg: {
    // width: perfectSize(27),
    // height: perfectSize(20),
    width: perfectSize(30),
    height: perfectSize(23),
    resizeMode: 'contain',
  },
  biggestRankImg: {
    width: perfectSize(40),
    height: perfectSize(30),
    resizeMode: 'contain',
  },
  portraitRankImg: {
    width: perfectSize(20),
    height: perfectSize(27),
    // width: perfectSize(23),
    // height: perfectSize(30),
    resizeMode: 'contain',
  },
  fullFlex: {
    flex: 1,
  },
  showJoinRequestImg: {
    height: perfectSize(116),
    width: perfectSize(116),
    alignSelf: 'center',
    marginVertical: perfectSize(24),
    borderRadius: perfectSize(12),
  },
  showJoinRequestTitle: {
    fontSize: perfectSize(15),
    textAlign: 'center',
    color: 'grey',
  },
  stickersWrap: {
    alignItems: 'center',
  },
  stickersIcon: {
    width: perfectSize(20),
    height: perfectSize(20),
    resizeMode: 'contain',
  },
});
