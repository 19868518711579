import React, { Component } from 'react';
import { View, FlatList, TouchableOpacity } from 'react-native';
import { compose } from 'redux';
import { connect } from 'react-redux';
import firebase from 'firebase/app';
import 'firebase/database';

// import components
import Header from '../../components/header';
import Label from '../../components/label';
import LeaderboardCard from '../../components/cards/leardboard-card';
import LeaderBoardSkeletonCard from '../../components/cards/leardboard-card/skeleton';

// import styles
import AppStyles from '../../constants/styles';
import styles from './styles';

import * as FirebasePaths from '../../constants/firebasePaths';
import NavigationService from '../../navigation/navigationService';
import { dataForSkeleton } from '../../services/utils';

class LeaderboardPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      isLoading: true,
      leadersArr: [],
    };
    this.leadersMap = new Map();
  }

  componentDidMount() {
    // Log the updated profile feature
    global.featuresLogger.LOG_RANKS_LEADER_BOARD_PAGE_VIEW();

    // Load the title
    this.loadTitle();
    // Load the leaders
    this.loadLeaders().then((snapshot) => {
      snapshot.forEach((item) => {
        if (item.val() != null) {
          // In the future it will only fetch the key : ex: 10 ( the number is not the rank cuz it will sort it autmatically so ..) , and the value : uid .. so be prepared
          // Now fetch each leader info : because we want his info updated in real time.
          let userID = item.val().uid;
          this.fetchLeaderInfo(userID);
        }
      });
    });
  }

  // This function will load the title for the leaderboard
  loadTitle() {
    let path = FirebasePaths.FIREBASE_LEADERBOARD + '/' + 'title';
    firebase.database()
      .ref(path)
      .once('value')
      .then((snapshot) => {
        this.setState({
          title: snapshot.val(),
        });
      });
  }

  // This function will load the leaders
  loadLeaders() {
    let path = FirebasePaths.FIREBASE_LEADERBOARD + '/';
    return firebase.database().ref(path).once('value');
  }

  // This function will fetch each leader's rank and info
  fetchLeaderInfo(uid) {
    let path =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      uid +
      '/' +
      FirebasePaths.FIREBASE_DETAILS_ATTR;
    firebase.database()
      .ref(path)
      .once('value')
      .then((snapshot) => {
        let userInfo = snapshot.val();
        if (userInfo != null && userInfo._username_) {
          // Pass the uid into the userInfo
          userInfo._uid_ = uid;

          // Change the user photo url if it NULL or default
          if (userInfo._picUrl_ === 'NULL' || userInfo._picUrl_ === 'default') {
            userInfo._picUrl_ =
              'https://firebasestorage.googleapis.com/v0/b/ionichoplay.appspot.com/o/teams%20default%20photos%20and%20background%2Fdefault_team_logov2.png?alt=media&token=b88bf926-35b9-4568-a80e-ee24110aceb6';
          }

          this.leadersMap.set(userInfo._username_, userInfo);
          let leadersArr = Array.from(this.leadersMap.values());
          leadersArr = leadersArr.sort(function (a, b) {
            return b._rank_ - a._rank_;
          });
          this.setState({ leadersArr, isLoading: false });
        }
      });
  }

  // This function will view the user profile
  viewUserProfile = (leader) => {
    NavigationService.navigate('ViewfriendprofilePage', {
      FRIEND_KEY: leader._uid_,
    });
  };

  render() {
    const { theme } = this.props;
    const _styles = styles(theme);
    const { title, isLoading, leadersArr } = this.state;
    return (
      <View style={AppStyles.rootContainer}>
        <Header text={'Leaderboard'} />
        <View style={AppStyles.rootContent}>
          <Label size="x_small" style={_styles.label}>
            {title}
          </Label>
          {
            isLoading &&
            <FlatList
              data={dataForSkeleton(15)}
              renderItem={() => {
                return <LeaderBoardSkeletonCard />
              }}
              showsVerticalScrollIndicator={false}
            />
          }
          {
            !isLoading && <FlatList
              data={leadersArr}
              showsVerticalScrollIndicator={false}
              renderItem={({ item, index }) => (
                <TouchableOpacity
                  style={_styles.card}
                  onPress={() => this.viewUserProfile(item)}>
                  <LeaderboardCard
                    index={index + 1}
                    photo={item._picUrl_}
                    username={item._username_}
                    rank={item._rank_}
                    key={index}
                  />
                </TouchableOpacity>
              )}
              keyExtractor={(item, index) => String(index)}
            />
          }

        </View>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
  };
};

export default compose(connect(mapStateToProps, {}))(LeaderboardPage);
