import {store} from '../../redux/store';
import firebase from 'firebase/app';
import 'firebase/database';

import * as FirebasePaths from '../../constants/firebasePaths';
import {arrayFromSnapshot} from '../../services/utils';
import {set as HubSet} from '../../redux/modules/hub/actions';

export class FindMatchCore {
  static findMatch(platform, gameId, region, callback) {
    if (region == 'All Countries') {
      this.allRegionSearch(platform, gameId, async (result) => {
        let tmpFilteredArr = [];
        let response = result.reverse();
        // let response = result;

        for (let request of response) {
          if (request.admin != store.getState().authReducer.uid) {
            if (request.platform == platform)
              tmpFilteredArr.push({
                admin: request.admin,
                timeStamp: request.timeStamp,
                name: request.adminName,
                gameId: request.gameId,
              });
          }

          if (tmpFilteredArr.length == 20) {
            break;
          }
        }

        tmpFilteredArr.sort((admin1, admin2) => {
          return admin2.timeStamp - admin1.timeStamp;
        });

        callback(tmpFilteredArr);
      });
    } else {
      this.specificRegionSearch(platform, gameId, region, async (response) => {
        let tmpFilteredArr = [];

        response.forEach((request) => {
          if (request.admin != store.getState().authReducer.uid) {
            tmpFilteredArr.push({
              admin: request.admin,
              timeStamp: request.timeStamp,
              name: request.adminName,
              gameId: request.gameId,
            });
          }
        });
        tmpFilteredArr.sort((admin1, admin2) => {
          return admin2.timeStamp - admin1.timeStamp;
        });

        callback(tmpFilteredArr);
      });
    }
  }

  static allRegionSearch(platform, gameId, callback) {
    let ALL_REGIONS_REF = FirebasePaths.FIREBASE_REQUESTS_HUB + '/';

    firebase.database()
      .ref(ALL_REGIONS_REF)
      .orderByChild('gameId')
      .equalTo(gameId)
      .limitToLast(200)
      .once('value', (snapshot) => {
        let reqs = arrayFromSnapshot(snapshot);
        callback(reqs);
      });
  }

  static specificRegionSearch(platform, gameId, region, callback) {
    let SPECIFIC_REGION_REF =
      FirebasePaths.FB_REQUESTS_REFERENCE +
      '/' +
      platform +
      '/' +
      gameId +
      '/' +
      region;

    firebase.database()
      .ref(SPECIFIC_REGION_REF)
      .limitToLast(20)
      .once('value', (snapshot) => {
        let reqs = arrayFromSnapshot(snapshot);
        callback(reqs);
      });
  }

  static fetchReqAdminData(adminKey) {
    // Set the founded user key
    let ref =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      adminKey +
      '/' +
      FirebasePaths.FIREBASE_DETAILS_ATTR;
    return firebase.database()
      .ref(ref)
      .once('value')
      .then((snapshot) => {
        return snapshot.val();
      });
  }

  static fetchRate(adminKey) {
    let friendRatesList = new Map();
    const ref =
      FirebasePaths.FIREBASE_USERS_INFO_ATTR +
      '/' +
      adminKey +
      '/' +
      FirebasePaths.FIREBASE_USER_RATINGS +
      '/' +
      FirebasePaths.FIREBASE_USER_RATING_FROM;

    this.loadFriendRatesListener = firebase.database().ref(ref);
    return this.loadFriendRatesListener.once('value').then((snapshot) => {
      snapshot.forEach((shot) => {
        friendRatesList.set(shot.key, shot.val());
      });

      // calcaulte avg user rates
      let ratesComulative = 0;
      let ratesNumber = 0;
      friendRatesList.forEach((rate) => {
        ratesComulative += rate;
        ratesNumber++;
      });

      const avgFriendRate = ratesComulative / ratesNumber;
      return avgFriendRate;
    });
  }
}
