/* eslint-disable react-native/no-inline-styles */
import React, { Component } from 'react';
import { View, TouchableOpacity, StyleSheet, FlatList } from 'react-native';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Feather from 'react-native-vector-icons/Feather';
import FontAwesome from 'react-native-vector-icons/FontAwesome';
import _ from 'lodash';
import AppStyles from '../../../constants/styles';
import * as Images from '../../../constants/images';
import I18n from '../../../i18n';

import NavigationService from '../../../navigation/navigationService';
// import components
import Header from '../../../components/header';
import TextInput from '../../../components/text-input';
import Label from '../../../components/label';
import PrimaryButton from '../../../components/buttons/primary-button';

// import styles
import Dimens from '../../../constants/dimens';
import { perfectSize } from '../../../helpers/deviceHelper';

import { store } from '../../../redux/store';
import { set as HubSet } from '../../../redux/modules/hub/actions';
import ImageWithPlaceholder from '../../../components/image-with-placeholder';
import { genres } from './genres';
import LocalImageOpacity from '../../../components/local-image-opacity';
import HootsCore from '../hootsCore';
import { arrayFromSnapshot } from '../../../services/utils';



class SelectGenrePage extends Component {
    constructor(props) {
        super(props);
        this.mount = true;
        this.state = {
            loading: true,
            genres: props.genres,
            displayGenres: props.genres,
        };
    }

    async componentDidMount() {
    }

    componentWillUnmount() {
        this.mount = false;
    }

    selectGenre = (item) => {
        const param = {};
        param.genre = item;
        NavigationService.navigate('CreateNewHootPage', param);
    };

    filterGenres = (val) => {
        this.setState({
            displayGenres: this.state.genres.filter(
                (genre) =>
                    genre.value['en'].toLowerCase().includes(val.toLowerCase()) || genre.value['ar'].toLowerCase().includes(val.toLowerCase()),
            ),
        });
    };

    _toContactUsPage = () => {
        NavigationService.moveToScreenInStack('SettingsStack', 'ContactUsPage');
    };

    render() {
        const { theme } = this.props;
        const styles = _styles(theme);
        const containerStyle = [
            styles.container,
            { backgroundColor: theme.secondary_background },
        ];
        return (
            <View style={containerStyle}>
                <Header text={I18n.t('HOOTS_PAGE.select_hoots_genre_header')} bold />
                {/* Content */}
                <View
                    style={[{ backgroundColor: theme.primary_background }, styles.content]}>
                    <TextInput
                        placeholder={I18n.t('HOOTS_PAGE.select_hoots_search_genre')}
                        rightComponent={
                            <FontAwesome
                                name="th-large"
                                size={perfectSize(20)}
                                color={theme.primary_text}
                                style={{ opacity: 0.5 }}
                            />
                        }
                        onChangeText={_.debounce(
                            (val) => this.filterGenres(val),
                            1000,
                        )}
                    />
                    <View style={styles.gamesWrap}>
                        <View style={styles.label}>
                            <FlatList
                                style={{}}
                                data={this.state.displayGenres}
                                keyExtractor={(item, index) => index.toString()}
                                showsVerticalScrollIndicator={false}
                                ListEmptyComponent={() => {
                                    return (
                                        <View style={AppStyles.noGameWrap}>
                                            <Label style={AppStyles.txtNoGame}>
                                                {I18n.t('HOOTS_PAGE.no_genres')}
                                            </Label>
                                            <PrimaryButton
                                                text={I18n.t('GAME_LIST_PAGE.go_support')}
                                                type="filled"
                                                size="x_small"
                                                customStyles={styles.btnAction}
                                                onPress={this._toContactUsPage}
                                            />
                                        </View>
                                    );
                                }}
                                numColumns={3}
                                columnWrapperStyle={{ justifyContent: 'space-between' }}
                                renderItem={(item) => {
                                    return (
                                        <TouchableOpacity
                                            key={item.index}
                                            style={[
                                                styles.itemContainer
                                            ]}
                                            onPress={() => this.selectGenre(item.item)}>
                                            <View style={styles.itemImageWrapper}>
                                                <ImageWithPlaceholder
                                                    uri={item.item.value.icon}
                                                    style={styles.itemImage}
                                                    resizeMode="contain"
                                                />
                                            </View>
                                            <Label size="small" style={{ marginTop: perfectSize(8), textAlign: 'center' }}>
                                                {/* {item.item?.name} */}
                                                {item.item.value[this.props.lang]}
                                            </Label>
                                        </TouchableOpacity>
                                    );
                                }}
                            />
                        </View>
                    </View>
                </View>
            </View>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        theme: state.appReducer.currentTheme,
        lang: state.appReducer.currentLang,
        genres: state.hubReducer.genres,
    };
};

export default compose(connect(mapStateToProps, {}))(SelectGenrePage);

const _styles = (theme) => StyleSheet.create({
    container: {
        flex: 1,
    },
    // Header
    header: {
        flexDirection: 'row',
        paddingHorizontal: Dimens.HEADER_PADDING_HORIZONTAL,
        paddingVertical: Dimens.HEADER_PADDING_VERTICAL,
    },
    headerLeft: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
    },
    headerCenter: {
        flex: 2,
        alignItems: 'center',
        justifyContent: 'center',
    },
    headerRight: {
        flex: 1,
        flexDirection: 'row-reverse',
        alignItems: 'center',
    },
    actionBtn: {
        color: '#C70064',
    },
    // Content
    content: {
        flex: 1,
        borderTopStartRadius: Dimens.BODY_BORDER_TOP_RADIUS,
        borderTopEndRadius: Dimens.BODY_BORDER_TOP_RADIUS,
        paddingHorizontal: Dimens.HEADER_PADDING_HORIZONTAL,
        paddingVertical: Dimens.HEADER_PADDING_VERTICAL,
    },
    gamesWrap: {
        marginTop: perfectSize(16),
        flex: 1,
    },
    label: {
        marginTop: perfectSize(16),
        flex: 1,
    },
    itemContainer: {
        width: '33%',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: perfectSize(32),
    },
    borderBottom: {
        borderBottomWidth: 1,
        borderBottomColor: '#FFFFFF0C',
    },
    itemImageWrapper: {
        width: perfectSize(60),
        height: perfectSize(60),
        borderRadius: perfectSize(30),
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.colors.secondary
    },
    itemImage: {
        width: perfectSize(30.6),
        height: perfectSize(34.2),
        resizeMode: 'contain'
    },
    btnAction: {
        paddingHorizontal: perfectSize(16),
    },
});
