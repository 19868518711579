/* eslint-disable react-native/no-inline-styles */
import React, {Component} from 'react';
import {View, FlatList} from 'react-native';
import {compose} from 'redux';
import {connect} from 'react-redux';
import I18n from '../../i18n';

// import components
import Header from '../../components/header';
import Label from '../../components/label';
import RequestCard from '../../components/cards/request-card-small';

// import styles
import styles from './styles';
import {perfectSize} from '../../helpers/deviceHelper';
import NavigationService from '../../navigation/navigationService';
import {set as HubSet} from '../../redux/modules/hub/actions';
import {getHumantime} from '../../services/utils';
import GameManager from '../../services/gameManager';
import * as FirebasePaths from '../../constants/firebasePaths';
import {store} from '../../redux/store';

class CheckRequestsModal extends Component {
  resultsHelperArr = [];

  constructor(props) {
    super(props);
    this.state = {
      requests: [],
      gamePhoto: '',
    };
  }

  componentDidMount() {
    this.resultsHelperArr = Array.from(this.props.searchReqResList.values());
    this.resultsHelperArr = this.resultsHelperArr.sort((a, b) => {
      return b.timestamp - a.timestamp;
    });

    this.resultsHelperArr = this.resultsHelperArr.reverse();
    this.resultsHelperArr = this.resultsHelperArr.filter(
      (request) => !this.props.bannedUsers.includes(request.admin),
    );
    this.setState({
      gamePhoto: this.props.navigation.state.params.gamePhotoUrl,
      displayList: this.resultsHelperArr.slice(0, 10),
    });
  }

  toLobbyPage = (item) => {
    global.featuresLogger.LOG_REQUEST_CHECK_REQUEST_CLICK();

    NavigationService.navigate('LobbyPage', {
      lastReq: item,
    });
  };

  renderRequests = ({item, index}) => {
    return (
      <RequestCard
        originalImage={{uri: this.state.gamePhoto}}
        request={item}
        customStyles={[
          {flex: 1 / 2, marginBottom: perfectSize(16)},
          index % 2 === 0
            ? {marginRight: perfectSize(8)}
            : {marginLeft: perfectSize(8)},
        ]}
        onPress={() => this.toLobbyPage(item)}
      />
    );
  };

  // renderSeparator = () => <View style={styles.separator} />;

  doInfinite = (infiniteScroll) => {
    if (this.state.displayList.length == this.resultsHelperArr.length) {
      return;
    }

    setTimeout(() => {
      let limit = 10;
      if (
        this.resultsHelperArr.length - this.state.displayList.length <
        limit
      ) {
        limit = this.resultsHelperArr.length - this.state.displayList.length;
      }
      let displayPost = this.state.displayList;
      displayPost = this.state.displayList.concat(
        this.resultsHelperArr.slice(
          displayPost.length,
          displayPost.length + limit,
        ),
      );
      this.setState({
        displayList: displayPost,
      });
    }, 500);
  };

  render() {
    const {theme} = this.props;
    const containerStyle = [
      styles.container,
      {backgroundColor: theme.secondary_background},
    ];
    return (
      <View style={containerStyle}>
        <Header text={I18n.t('SEARCH_REQUESTS_RESULT_PAGE.check_request')} />
        <View
          style={[{backgroundColor: theme.primary_background}, styles.body]}>
          <View style={styles.wrapper}>
            <Label
              size="x_small"
              style={{color: '#7D8693', marginBottom: perfectSize(16)}}>
              {I18n.t('CHECK_REQS_MODAL.spinner_text')}
            </Label>
            <FlatList
              style={styles.content}
              data={this.state.displayList}
              numColumns={2}
              ItemSeparatorComponent={this.renderSeparator}
              renderItem={(item, index) => this.renderRequests(item)}
              keyExtractor={(item, index) => String(index)}
              columnWrapperStyle={styles.flatList}
              onEndReached={this.doInfinite}
              onEndReachedThreshold={0.5}
              showsVerticalScrollIndicator={false}
            />
          </View>
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
    searchReqResList: state.hubReducer.searchReqResList,
    searchReqsResults: state.hubReducer.searchReqsResults,
    lazySearchResultCounter: state.hubReducer.lazySearchResultCounter,
    bannedUsers: state.hubReducer.bannedUsers,
  };
};

export default compose(connect(mapStateToProps, {}))(CheckRequestsModal);
