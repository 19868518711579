import React, {Component} from 'react';
import {View} from 'react-native';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {createMaterialTopTabNavigator} from '@react-navigation/material-top-tabs';

import I18n from '../../i18n';

// import components
import HeaderWithNotification from '../../components/header-with-notification';
import MyTabBar from '../../components/custom-top-tab';

// import styles
import AppStyles from '../../constants/styles';
import styles from './styles';

// import actions
import {switchTheme} from '../../redux/modules/app/actions';
import {set as setHub} from '../../redux/modules/hub/actions';

const Tab = createMaterialTopTabNavigator();

// import tab views
import Requests from './requests';
import Private from './private';
import Clans from './clans';

import { clearFastImageCache } from '../../services/utils';

class CommunityPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      TEAMS_NOTIFICATION_INDICATOR: false,
      PRIVATE_NOTIFICATION_INDICATOR: false,
      CLANS_NOTIFICATION_INDICATOR: false,
    };
  }

  componentDidMount() {
    global.rankLogger.CHECK_BONUS_HP();
  }

  render() {
    const {
      uid,
      communityChatsArr,
      clansChatsArr,
      communityGroupChatsArr,
    } = this.props;
    // For private chats
    let PRIVATE_NOTIFICATION_INDICATOR = false;
    communityChatsArr.forEach((chat) => {
      if (chat.senderId !== uid) {
        if (chat.opened != null) {
          if (!chat.opened) {
            PRIVATE_NOTIFICATION_INDICATOR = true;
          }
        }
      }
    });
    // For Clans
    let CLANS_NOTIFICATION_INDICATOR = false;
    clansChatsArr.forEach((chat) => {
      if (chat.uid !== uid) {
        if (chat.opened != null) {
          if (!chat.opened) {
            CLANS_NOTIFICATION_INDICATOR = true;
          }
        }
      }
    });
    // For groups | Requests
    let TEAMS_NOTIFICATION_INDICATOR = false;
    communityGroupChatsArr.forEach((chat) => {
      if (chat.senderId !== uid) {
        if (chat.opened != null) {
          if (!chat.opened) {
            TEAMS_NOTIFICATION_INDICATOR = true;
          }
        }
      }
    });
    let badges = [];
    if (TEAMS_NOTIFICATION_INDICATOR) {
      badges.push('ChatRequests');
    }
    if (PRIVATE_NOTIFICATION_INDICATOR) {
      badges.push('ChatPrivate');
    }
    if (CLANS_NOTIFICATION_INDICATOR) {
      badges.push('ChatClans');
    }
    return (
      <View style={AppStyles.rootContainer}>
        <HeaderWithNotification title={I18n.t('TABS.home')} />
        <View style={[AppStyles.rootContent, styles.rootContent]}>
          <Tab.Navigator
            sceneContainerStyle={AppStyles.transparent}
            swipeEnabled={false}
            unmountOnBlur={true}
            tabBar={(props) => (
              <MyTabBar
                {...props}
                badges={badges}
                tabBarStyle={styles.tabBarStyle}
                lang={this.props.lang}
                onTabChanged={(index) => {
                  clearFastImageCache();
                }}
              />
            )}>
            <Tab.Screen
              name="ChatRequests"
              component={Requests}
              options={{tabBarLabel: I18n.t('COMMUNITY_PAGE.groups_switch')}}
            />
            <Tab.Screen
              name="ChatPrivate"
              component={Private}
              options={{tabBarLabel: I18n.t('COMMUNITY_PAGE.private_switch')}}
            />
            <Tab.Screen
              name="ChatClans"
              component={Clans}
              options={{tabBarLabel: I18n.t('COMMUNITY_PAGE.clans_switch')}}
            />
          </Tab.Navigator>
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
    lang: state.appReducer.currentLang,
    uid: state.authReducer.uid,
    communityGroupChatsArr: state.hubReducer.communityGroupChatsArr,
    communityChatsArr: state.hubReducer.communityChatsArr,
    clansChatsArr: state.hubReducer.clansChatsArr,
  };
};

export default compose(connect(mapStateToProps, {switchTheme, setHub}))(
  CommunityPage,
);
