import {StyleSheet} from 'react-native';
import {perfectSize} from '../../helpers/deviceHelper';

const styles = (theme) =>
  StyleSheet.create({
    addBtn: {
      flexDirection: 'row',
      height: 32,
      paddingHorizontal: perfectSize(8),
      alignSelf: 'baseline',
      backgroundColor: theme.primary_btn_background,
      alignItems: 'center',
      borderRadius: 8,
    },
    flatList: {
      marginTop: perfectSize(16),
    },
    itemContainer: {
      height: perfectSize(60),
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: perfectSize(8),
      paddingBottom: perfectSize(8),
    },
    itemBorder: {
      borderBottomWidth: 1,
      borderBottomColor: '#FFFFFF0C',
    },
    pinkLabel: {
      color: theme.primary_btn_background,
    },
    separator: {
      color: theme.primary_btn_background,
      marginHorizontal: perfectSize(8),
    },
    emptyContent: {
      marginTop: perfectSize(32),
    },
  });

export default styles;
