import React, { Component } from 'react';
import { View, TouchableOpacity, ScrollView, Linking } from 'react-native';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Feather from 'react-native-vector-icons/Feather';
import I18n from '../../i18n';
import NavigationService from '../../navigation/navigationService';

// import components
import Label from '../../components/label';
import ImageWithPlaceholder from '../../components/image-with-placeholder';

import styles from './styles';

import {
  handleAndroidBackButton,
  removeAndroidBackButtonHandler,
  perfectSize,
} from '../../helpers/deviceHelper';

import { clearFastImageCache } from '../../services/utils';
class AdPage extends Component {
  constructor(props) {
    super(props);
    const params = props.navigation.state.params;
    this.state = {
      adObj: params.data,
    };
  }

  componentDidMount() {
    global.featuresLogger.AD_PAGE_VIEW();
    handleAndroidBackButton(this.goBack);
  }

  componentWillUnmount() {
    removeAndroidBackButtonHandler();
  }

  // Open link function
  openLink = (link, logger) => {
    if (logger === 'WEBSITE') {
      global.featuresLogger.AD_WEBSITE_CLICKED();
      if (!link.startsWith('http')) {
        link = 'http://' + link;
      }
    } else if (logger === 'APP') {
      global.featuresLogger.AD_APP_CLICKED();
    } else if (logger === 'LOCATION') {
      global.featuresLogger.AD_LOCATION_CLICKED();
    } else if (logger === 'SOCIAL') {
      global.featuresLogger.AD_SOCIAL_MEDIA_CLICKED();
    }

    if (link.toString().length > 7) {
      Linking.openURL(link);
    }
  };

  goBack = () => {
    clearFastImageCache();
    NavigationService.goBack();
  };

  render() {
    const { theme } = this.props;
    const _styles = styles(theme);
    const { adObj } = this.state;
    return (
      <View style={_styles.container}>
        {/* Header */}
        <View>
          <ImageWithPlaceholder style={_styles.cover} uri={adObj.img} />
          <TouchableOpacity
            style={_styles.backArrow}
            onPress={() => {
              clearFastImageCache();
              NavigationService.goBack()
            }}>
            <Feather
              name="chevron-left"
              color="#C70064"
              size={perfectSize(22.5)}
            />
          </TouchableOpacity>
        </View>
        {/* Content */}
        <View style={_styles.scrollContainer}>
          <ScrollView>
            <Label size="x_medium" bold>
              {adObj.offerTitle}
            </Label>
            <Label style={_styles.adDesc} size="x_small">
              {adObj.offerMessage}
            </Label>
            <View style={_styles.seperator} />
            {/* Prices */}
            {adObj.price1 != null && (
              <View>
                <Label bold size="medium">
                  {I18n.t('AD_PAGE.prices')}
                </Label>
                {adObj.price1 != null && (
                  <View style={_styles.itemRow}>
                    <Label style={_styles.titleLabel} size="x_small">
                      {adObj.price1Title}
                    </Label>
                    <Label style={_styles.valueLabel} size="x_small">
                      {adObj.price1}
                    </Label>
                  </View>
                )}
                {adObj.price2 != null && (
                  <View style={_styles.itemRow}>
                    <Label style={_styles.titleLabel} size="x_small">
                      {adObj.price2Title}
                    </Label>
                    <Label style={_styles.valueLabel} size="x_small">
                      {adObj.price2}
                    </Label>
                  </View>
                )}
                {adObj.price3 != null && (
                  <View style={_styles.itemRow}>
                    <Label style={_styles.titleLabel} size="x_small">
                      {adObj.price3Title}
                    </Label>
                    <Label style={_styles.valueLabel} size="x_small">
                      {adObj.price3}
                    </Label>
                  </View>
                )}
                {adObj.price4 != null && (
                  <View style={_styles.itemRow}>
                    <Label style={_styles.titleLabel} size="x_small">
                      {adObj.price4Title}
                    </Label>
                    <Label style={_styles.valueLabel} size="x_small">
                      {adObj.price4}
                    </Label>
                  </View>
                )}
                {adObj.price5 != null && (
                  <View style={_styles.itemRow}>
                    <Label style={_styles.titleLabel} size="x_small">
                      {adObj.price5Title}
                    </Label>
                    <Label style={_styles.valueLabel} size="x_small">
                      {adObj.price5}
                    </Label>
                  </View>
                )}
                {adObj.price6 != null && (
                  <View style={_styles.itemRow}>
                    <Label style={_styles.titleLabel} size="x_small">
                      {adObj.price6Title}
                    </Label>
                    <Label style={_styles.valueLabel} size="x_small">
                      {adObj.price6}
                    </Label>
                  </View>
                )}
                {adObj.price7 != null && (
                  <View style={_styles.itemRow}>
                    <Label style={_styles.titleLabel} size="x_small">
                      {adObj.price7Title}
                    </Label>
                    <Label style={_styles.valueLabel} size="x_small">
                      {adObj.price7}
                    </Label>
                  </View>
                )}
                {adObj.price8 != null && (
                  <View style={_styles.itemRow}>
                    <Label style={_styles.titleLabel} size="x_small">
                      {adObj.price8Title}
                    </Label>
                    <Label style={_styles.valueLabel} size="x_small">
                      {adObj.price8}
                    </Label>
                  </View>
                )}
                {adObj.price9 != null && (
                  <View style={_styles.itemRow}>
                    <Label style={_styles.titleLabel} size="x_small">
                      {adObj.price9Title}
                    </Label>
                    <Label style={_styles.valueLabel} size="x_small">
                      {adObj.price9}
                    </Label>
                  </View>
                )}
                {adObj.price10 != null && (
                  <View style={_styles.itemRow}>
                    <Label style={_styles.titleLabel} size="x_small">
                      {adObj.price10Title}
                    </Label>
                    <Label style={_styles.valueLabel} size="x_small">
                      {adObj.price10}
                    </Label>
                  </View>
                )}
                {adObj.price11 != null && (
                  <View style={_styles.itemRow}>
                    <Label style={_styles.titleLabel} size="x_small">
                      {adObj.price11Title}
                    </Label>
                    <Label style={_styles.valueLabel} size="x_small">
                      {adObj.price11}
                    </Label>
                  </View>
                )}
                {adObj.price12 != null && (
                  <View style={_styles.itemRow}>
                    <Label style={_styles.titleLabel} size="x_small">
                      {adObj.price12Title}
                    </Label>
                    <Label style={_styles.valueLabel} size="x_small">
                      {adObj.price12}
                    </Label>
                  </View>
                )}
                {adObj.price13 != null && (
                  <View style={_styles.itemRow}>
                    <Label style={_styles.titleLabel} size="x_small">
                      {adObj.price13Title}
                    </Label>
                    <Label style={_styles.valueLabel} size="x_small">
                      {adObj.price13}
                    </Label>
                  </View>
                )}
                {adObj.price14 != null && (
                  <View style={_styles.itemRow}>
                    <Label style={_styles.titleLabel} size="x_small">
                      {adObj.price14Title}
                    </Label>
                    <Label style={_styles.valueLabel} size="x_small">
                      {adObj.price14}
                    </Label>
                  </View>
                )}
                {adObj.price15 != null && (
                  <View style={_styles.itemRow}>
                    <Label style={_styles.titleLabel} size="x_small">
                      {adObj.price15Title}
                    </Label>
                    <Label style={_styles.valueLabel} size="x_small">
                      {adObj.price15}
                    </Label>
                  </View>
                )}
                {adObj.price16 != null && (
                  <View style={_styles.itemRow}>
                    <Label style={_styles.titleLabel} size="x_small">
                      {adObj.price16Title}
                    </Label>
                    <Label style={_styles.valueLabel} size="x_small">
                      {adObj.price16}
                    </Label>
                  </View>
                )}
                {adObj.price17 != null && (
                  <View style={_styles.itemRow}>
                    <Label style={_styles.titleLabel} size="x_small">
                      {adObj.price17Title}
                    </Label>
                    <Label style={_styles.valueLabel} size="x_small">
                      {adObj.price17}
                    </Label>
                  </View>
                )}
                {adObj.price18 != null && (
                  <View style={_styles.itemRow}>
                    <Label style={_styles.titleLabel} size="x_small">
                      {adObj.price18Title}
                    </Label>
                    <Label style={_styles.valueLabel} size="x_small">
                      {adObj.price18}
                    </Label>
                  </View>
                )}
                {adObj.price19 != null && (
                  <View style={_styles.itemRow}>
                    <Label style={_styles.titleLabel} size="x_small">
                      {adObj.price19Title}
                    </Label>
                    <Label style={_styles.valueLabel} size="x_small">
                      {adObj.price19}
                    </Label>
                  </View>
                )}
                {adObj.price20 != null && (
                  <View style={_styles.itemRow}>
                    <Label style={_styles.titleLabel} size="x_small">
                      {adObj.price20Title}
                    </Label>
                    <Label style={_styles.valueLabel} size="x_small">
                      {adObj.price20}
                    </Label>
                  </View>
                )}
                <View style={_styles.seperator} />
              </View>
            )}
            {/* Website */}
            {adObj.webLink != null && (
              <View>
                <View style={_styles.itemRow}>
                  <View style={_styles.titleCol}>
                    <Label style={_styles.label} size="x_small">
                      {I18n.t('AD_PAGE.website_title')}
                    </Label>
                    {adObj.webLink != null && (
                      <Label style={_styles.label} size="x_small">
                        {adObj.webLink}
                      </Label>
                    )}
                  </View>
                  <View style={_styles.valueCol}>
                    <TouchableOpacity
                      onPress={() => this.openLink(adObj.webLink, 'WEBSITE')}>
                      <Feather
                        name="link"
                        size={perfectSize(20)}
                        color={theme.colors.primary}
                      />
                    </TouchableOpacity>
                  </View>
                </View>
                <View style={_styles.seperator} />
              </View>
            )}
            {/* App link */}
            {adObj.appLink != null && (
              <View>
                <View style={_styles.itemRow}>
                  <View style={_styles.titleCol}>
                    <Label style={_styles.label} size="x_small">
                      {I18n.t('AD_PAGE.universal_link')}
                    </Label>
                  </View>
                  <View style={_styles.valueCol}>
                    <TouchableOpacity
                      onPress={() => this.openLink(adObj.appLink, 'APP')}>
                      <Feather
                        name="link"
                        size={perfectSize(20)}
                        color={theme.colors.primary}
                      />
                    </TouchableOpacity>
                  </View>
                </View>
                <View style={_styles.seperator} />
              </View>
            )}
            {/* Android link */}
            {adObj.androidLink != null && (
              <View>
                <View style={_styles.itemRow}>
                  <View style={_styles.titleCol}>
                    <Label style={_styles.label} size="x_small">
                      {I18n.t('AD_PAGE.android_link')}
                    </Label>
                  </View>
                  <View style={_styles.valueCol}>
                    <TouchableOpacity
                      onPress={() => this.openLink(adObj.androidLink, 'APP')}>
                      <Feather
                        name="link"
                        size={perfectSize(20)}
                        color={theme.colors.primary}
                      />
                    </TouchableOpacity>
                  </View>
                </View>
                <View style={_styles.seperator} />
              </View>
            )}
            {/* iPhone link */}
            {adObj.iphoneLink != null && (
              <View>
                <View style={_styles.itemRow}>
                  <View style={_styles.titleCol}>
                    <Label style={_styles.label} size="x_small">
                      {I18n.t('AD_PAGE.iphone_link')}
                    </Label>
                  </View>
                  <View style={_styles.valueCol}>
                    <TouchableOpacity
                      onPress={() => this.openLink(adObj.iphoneLink, 'APP')}>
                      <Feather
                        name="link"
                        size={perfectSize(20)}
                        color={theme.colors.primary}
                      />
                    </TouchableOpacity>
                  </View>
                </View>
                <View style={_styles.seperator} />
              </View>
            )}
            {/* Branches */}
            {adObj.branch1 != null && (
              <View>
                <Label bold size="medium">
                  {I18n.t('AD_PAGE.branches')}
                </Label>
                <View style={_styles.itemRow}>
                  <View style={_styles.titleCol}>
                    <Label style={_styles.label} size="x_small">
                      {adObj.branch1_description}
                    </Label>
                  </View>
                  <View style={_styles.valueCol}>
                    <TouchableOpacity
                      onPress={() => this.openLink(adObj.branch1, 'LOCATION')}>
                      <Feather
                        name="link"
                        size={perfectSize(20)}
                        color={theme.colors.primary}
                      />
                    </TouchableOpacity>
                  </View>
                </View>
                {adObj.branch2 != null && (
                  <View style={_styles.itemRow}>
                    <View style={_styles.titleCol}>
                      <Label style={_styles.label} size="x_small">
                        {adObj.branch2_description}
                      </Label>
                    </View>
                    <View style={_styles.valueCol}>
                      <TouchableOpacity
                        onPress={() =>
                          this.openLink(adObj.branch2, 'LOCATION')
                        }>
                        <Feather
                          name="link"
                          size={perfectSize(20)}
                          color={theme.colors.primary}
                        />
                      </TouchableOpacity>
                    </View>
                  </View>
                )}
                {adObj.branch3 != null && (
                  <View style={_styles.itemRow}>
                    <View style={_styles.titleCol}>
                      <Label style={_styles.label} size="x_small">
                        {adObj.branch3_description}
                      </Label>
                    </View>
                    <View style={_styles.valueCol}>
                      <TouchableOpacity
                        onPress={() =>
                          this.openLink(adObj.branch3, 'LOCATION')
                        }>
                        <Feather
                          name="link"
                          size={perfectSize(20)}
                          color={theme.colors.primary}
                        />
                      </TouchableOpacity>
                    </View>
                  </View>
                )}
                <View style={_styles.seperator} />
              </View>
            )}
            {/* Social Media */}
            {adObj.socialMedia != null && (
              <View>
                <Label bold size="medium">
                  {I18n.t('AD_PAGE.social_media')}
                </Label>
                {adObj.twitter != null && (
                  <View style={_styles.itemRow}>
                    <View style={_styles.titleCol}>
                      <Label style={_styles.label} size="x_small">
                        {I18n.t('AD_PAGE.twitter')}
                      </Label>
                      <Label style={_styles.label} size="x_small">
                        {adObj.twitter}
                      </Label>
                    </View>
                    <View style={_styles.valueCol}>
                      <TouchableOpacity
                        onPress={() =>
                          this.openLink(adObj.twitterUrl, 'SOCIAL')
                        }>
                        <Feather
                          name="link"
                          size={perfectSize(20)}
                          color={theme.colors.primary}
                        />
                      </TouchableOpacity>
                    </View>
                  </View>
                )}
                {adObj.instagram != null && (
                  <View style={_styles.itemRow}>
                    <View style={_styles.titleCol}>
                      <Label style={_styles.label} size="x_small">
                        {I18n.t('AD_PAGE.instagram')}
                      </Label>
                      <Label style={_styles.label} size="x_small">
                        {adObj.instagram}
                      </Label>
                    </View>
                    <View style={_styles.valueCol}>
                      <TouchableOpacity
                        onPress={() =>
                          this.openLink(adObj.instagramUrl, 'SOCIAL')
                        }>
                        <Feather
                          name="link"
                          size={perfectSize(20)}
                          color={theme.colors.primary}
                        />
                      </TouchableOpacity>
                    </View>
                  </View>
                )}
                {adObj.snapchat != null && (
                  <View style={_styles.itemRow}>
                    <View style={_styles.titleCol}>
                      <Label style={_styles.label} size="x_small">
                        {I18n.t('AD_PAGE.snapchat')}
                      </Label>
                      <Label style={_styles.label} size="x_small">
                        {adObj.snapchat}
                      </Label>
                    </View>
                    <View style={_styles.valueCol}>
                      <TouchableOpacity
                        onPress={() =>
                          this.openLink(adObj.snapchatUrl, 'SOCIAL')
                        }>
                        <Feather
                          name="link"
                          size={perfectSize(20)}
                          color={theme.colors.primary}
                        />
                      </TouchableOpacity>
                    </View>
                  </View>
                )}
                {adObj.youtube != null && (
                  <View style={_styles.itemRow}>
                    <View style={_styles.titleCol}>
                      <Label style={_styles.label} size="x_small">
                        {I18n.t('AD_PAGE.youtube')}
                      </Label>
                      <Label style={_styles.label} size="x_small">
                        {adObj.youtube}
                      </Label>
                    </View>
                    <View style={_styles.valueCol}>
                      <TouchableOpacity
                        onPress={() =>
                          this.openLink(adObj.youtubeUrl, 'SOCIAL')
                        }>
                        <Feather
                          name="link"
                          size={perfectSize(20)}
                          color={theme.colors.primary}
                        />
                      </TouchableOpacity>
                    </View>
                  </View>
                )}
                <View style={_styles.seperator} />
              </View>
            )}
          </ScrollView>
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
  };
};

export default compose(connect(mapStateToProps, {}))(AdPage);
