/* eslint-disable react-native/no-inline-styles */
import React, { Component } from 'react';
import { View, TouchableOpacity, FlatList } from 'react-native';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Tooltip } from 'react-native-elements';
import { LinearGradient } from 'expo-linear-gradient';
import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/storage';

import * as ImagePicker from 'expo-image-picker';
import Feather from 'react-native-vector-icons/Feather';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

// import components
import Header from '../../components/header';
import Label from '../../components/label';
import TextInput from '../../components/text-input';
import PrimaryButton from '../../components/buttons/primary-button';
import Picker from '../../components/picker';
import NavigationService from '../../navigation/navigationService';
import I18n from '../../i18n';

import AppStyles from '../../constants/styles';
import styles from './styles';
import * as Images from '../../constants/images';
import UserAvatar from '../../components/user-avatar';
import { perfectSize } from '../../helpers/deviceHelper';
import { presentToast } from '../../helpers/toastHelper';

// import firebase path
import * as FirebasePaths from '../../constants/firebasePaths';

// import actions
import { updateProfile, setUserInfo } from '../../redux/modules/auth/actions';
import { showSpinner, hideSpinner } from '../../redux/modules/alert/actions';
import {
  EDIT_PROFILE_GENDER,
  EDIT_PROFILE_AGE,
  REGISTRATION_PLATFORM,
  EDIT_PROFILE_AVG_PLAYTIME,
  EDIT_PROFILE_AVG_PLAYTIME_DAILY,
  EDIT_PROFILE_DAY_PLAYTIME,
} from '../../constants/selects';
import ImageWithPlaceholder from '../../components/image-with-placeholder';
import LocalImageOpacity from '../../components/local-image-opacity';

class EditProfilePage extends Component {
  constructor(props) {
    super(props);
    this.mount = true;
    this.tooltipRef = React.createRef();
    this.state = {
      // tooltip toggle
      tooltipToggle: false,

      // All inputs
      emailEditProfile: '',
      bioEditProfile: '',

      // Countries and cities maps and games and arrays: Selectable
      countriesMap: new Map(),
      countriesArray: [],
      citiesMap: new Map(),
      citiesArray: [],
      allGames: Object.values(props.gamesList),

      // Searchable lists models
      searchableCountriesModel: '',

      //
      selectedGameProvider: 'Game Provider',
      pcGameProvidersArray: [],
      pcGameProvidersAccsArray: [],

      gameProviderAcc: 'Game Provider  Account',
      psnProviderAccField: I18n.t('EDIT_PROFILE_PAGE.psn_account'),
      xboxProviderAccField: I18n.t('EDIT_PROFILE_PAGE.xbox_account'),
      nintendoProviderAccField: I18n.t('EDIT_PROFILE_PAGE.nintendo_account'),
      battlenetProviderAccField: I18n.t('EDIT_PROFILE_PAGE.battlenet_account'),
      epicProviderAccField: I18n.t('EDIT_PROFILE_PAGE.epic_account'),
      originProviderAccField: I18n.t('EDIT_PROFILE_PAGE.origin_account'),
      steamProviderAccField: I18n.t('EDIT_PROFILE_PAGE.steam_account'),
      discordProviderAccField: I18n.t('EDIT_PROFILE_PAGE.discord_account'),

      // More analytics attrs
      selectedCountry: props.userinformationDB.country,
      selectedCity: props.userinformationDB.city,
      selectedGender: props.userinformationDB.gender,
      selectedAge: props.userinformationDB.age,
      selectedPlatform: props.userinformationDB.mainPlatform,
      favoriteOnlineGame: props.userinformationDB.favGame,
      selectedAvgPlaytime: props.userinformationDB.avgPlaytime,
      selectedAvgDailyPlaytime: props.userinformationDB.avgDailyPlaytime,
      phoneNumberModel: props.userinformationDB.phoneNumber,
      selectedDayPlaytime: props.userinformationDB.dayPlaytime,

      email: props.userinformationDB.userEmail,
      phone: props.userinformationDB.phone,
      bio: props.userinformationDB.bio,

      pictureURL: props.userinformationDB.pictureURL,

      showSpinner: true,

      PSNAcc: {
        name: props.userinformationDB.PSNAcc || 'none',
        icon: Images._img_ps,
        _backgroundColor: '#003791',
      },
      XboxLiveAcc: {
        name: props.userinformationDB.XboxLiveAcc || 'none',
        icon: Images._img_xbox,
        _backgroundColor: '#107C10',
      },
      nintendoAcc: {
        name: props.userinformationDB.nintendoAcc || 'none',
        icon: Images._img_nintendo,
        _backgroundColor: '#e30b20',
      },
      epicGames: {
        name: props.userinformationDB.pcGamesAccs.get('Epic Games') || 'none',
        icon: Images._img_epic,
        _backgroundColor: '#2f2d2e',
      },
      steam: {
        name: props.userinformationDB.pcGamesAccs.get('Steam') || 'none',
        icon: Images._img_steam,
        _backgroundColor: '#14659A',
      },
      origin: {
        name: props.userinformationDB.pcGamesAccs.get('Origin') || 'none',
        icon: Images._img_origin,
        _backgroundColor: '#ef5b2f',
      },
      discord: {
        name: props.userinformationDB.pcGamesAccs.get('Discord') || 'none',
        icon: Images._img_discord,
        _backgroundColor: '#7289da',
      },
      battlenet: {
        name: props.userinformationDB.pcGamesAccs.get('Battlenet') || 'none',
        icon: Images._img_battlenet,
        _backgroundColor: '#1eaaf1',
      },
    };
  }

  componentDidMount = () => {
    this.loadAllCountries();
    if (this.state.selectedCountry) {
      this.loadCountryCities(this.state.selectedCountry);
    }
  };

  componentWillUnmount() {
    this.mount = false;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.mount) {
      return;
    }
    if (this.mount) {
      if (this.props.loading !== nextProps.loading) {
        this.props.hideSpinner();
      }
      const { userinformationDB: _new } = nextProps;
      const { userinformationDB: _old } = this.props;
      if (_old.pictureURL !== _new.pictureURL) {
        this.setState({ pictureURL: _new.pictureURL });
      }
      if (_old.country !== _new.country) {
        this.setState({ selectedCountry: _new.country });
      }
      if (_old.city !== _new.city) {
        this.setState({ selectedCity: _new.city });
      }
      if (_old.gender !== _new.gender) {
        this.setState({ selectedGender: _new.gender });
      }
      if (_old.mainPlatform !== _new.mainPlatform) {
        this.setState({ selectedPlatform: _new.mainPlatform });
      }

      if (_old.age !== _new.age) {
        this.setState({ selectedAge: _new.age });
      }
      if (_old.favGame !== _new.favGame) {
        this.setState({ favoriteOnlineGame: _new.favGame });
      }
      if (_old.avgPlaytime !== _new.avgPlaytime) {
        this.setState({ selectedAvgPlaytime: _new.avgPlaytime });
      }
      if (_old.avgDailyPlaytime !== _new.avgDailyPlaytime) {
        this.setState({ selectedAvgDailyPlaytime: _new.avgDailyPlaytime });
      }
      if (_old.phoneNumber !== _new.phoneNumber) {
        this.setState({ phoneNumberModel: _new.phoneNumber });
      }
      if (_old.dayPlaytime !== _new.dayPlaytime) {
        this.setState({ selectedDayPlaytime: _new.dayPlaytime });
      }
      if (_old.userEmail !== _new.userEmail) {
        this.setState({ email: _new.userEmail });
      }
      if (_old.phone !== _new.phone) {
        this.setState({ phone: _new.phone });
      }
      if (_old.bio !== _new.bio) {
        this.setState({ bio: _new.bio });
      }
      if (_old.PSNAcc !== _new.PSNAcc) {
        this.setState({
          PSNAcc: {
            ...this.state.PSNAcc,
            name: _new.PSNAcc || 'none',
          },
        });
      }

      if (_old.nintendoAcc !== _new.nintendoAcc) {
        this.setState({
          nintendoAcc: {
            ...this.state.nintendoAcc,
            name: _new.nintendoAcc || 'none',
          },
        });
      }

      if (_old.XboxLiveAcc !== _new.XboxLiveAcc) {
        this.setState({
          XboxLiveAcc: {
            ...this.state.XboxLiveAcc,
            name: _new.XboxLiveAcc || 'none',
          },
        });
      }

      if (_old.pcGamesAccs !== _new.pcGamesAccs) {
        this.setState({
          epicGames: {
            ...this.state.epicGames,
            name: _new.pcGamesAccs.get('Epic Games') || 'none',
          },
          steam: {
            ...this.state.steam,
            name: _new.pcGamesAccs.get('Steam') || 'none',
          },
          origin: {
            ...this.state.origin,
            name: _new.pcGamesAccs.get('Origin') || 'none',
          },
          discord: {
            ...this.state.discord,
            name: _new.pcGamesAccs.get('Discord') || 'none',
          },
          battlenet: {
            ...this.state.battlenet,
            name: _new.pcGamesAccs.get('Battlenet') || 'none',
          },
        });
      }
    }
    // }
  }

  loadAllCountries = () => {
    // load all countries in the searchable list: called when this page opened
    firebase.database()
      .ref(FirebasePaths.FIREBASE_COUNTRIES_CITIES)
      .once('value')
      .then((snapshot) => {
        const snap = snapshot.val();
        let countriesArray = [];
        // eslint-disable-next-line no-unused-vars
        for (let key in snap) {
          if (snap.hasOwnProperty(key)) {
            countriesArray.push(key);
          }
        }
        this.setState({
          countriesArray,
        });
      });
  };

  loadCountryCities = (country) => {
    firebase.database()
      .ref(FirebasePaths.FIREBASE_COUNTRIES_CITIES + '/' + country)
      .once('value')
      .then((snapshot) => {
        const snap = snapshot.val();
        let citiesArray = [];
        // eslint-disable-next-line no-unused-vars
        for (let key in snap) {
          if (snap.hasOwnProperty(key)) {
            citiesArray.push(snap[key]);
          }
        }
        this.setState({
          citiesArray,
        });
      });
  };

  _onValueChange = (type, val) => {
    this.setState({ [type]: val });
  };

  updateUserInfo = () => {
    if (this.isValidInput()) {
      // Log Rank HP
      global.rankLogger.LOG_NEW_HP(
        global.rankLogger.UPDATE_PROFILE,
        'UPDATE_PROFILE',
      );
      // update account
      const _profileParams = {};
      _profileParams.bio = this.state.bio;
      _profileParams.country = this.state.selectedCountry;
      _profileParams.city = this.state.selectedCity;
      _profileParams.gender = this.state.selectedGender;
      _profileParams.age = this.state.selectedAge;
      _profileParams.favoriteGame = this.state.favoriteOnlineGame;
      _profileParams.mainPlatform = this.state.selectedPlatform;
      _profileParams.avgDailyPlaytime = this.state.selectedAvgDailyPlaytime;
      _profileParams.avgPlaytime = this.state.selectedAvgPlaytime;
      _profileParams.dayPlaytime = this.state.selectedDayPlaytime;
      _profileParams.phoneNumber = this.state.phone;
      this.props.showSpinner({});
      this.props.updateProfile(_profileParams);
    }
  };

  isValidInput = () => {
    const { bio } = this.state;
    if (!bio || bio === '') {
      presentToast({
        message: I18n.t('EDIT_PROFILE_PAGE.error_bio'),
      });
      return false;
    }
    return true;
  };

  selectImage = async () => {
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      base64: true,
    })

    if (!result.cancelled) {
      this.uploadImageToFirebaseBase64(result.base64);
    }
  };

  uploadImageToFirebaseBase64(image) {
    this.uploadImageBase64(image)
      .then((photoURL) => {
        firebase.database()
          .ref(
            FirebasePaths.FIREBASE_USERS_INFO_ATTR +
            '/' +
            this.props.uid +
            '/' +
            FirebasePaths.FIREBASE_DETAILS_ATTR +
            '/' +
            FirebasePaths.FIREBASE_PICTURE_URL_ATTR,
          )
          .set(photoURL);
        try {
          this.props.setUserInfo({ pictureURL: photoURL });
          this.setState({ pictureURL: photoURL });
        } catch (err) { }
        presentToast({
          message: I18n.t('EDIT_PROFILE_PAGE.success_image_update'),
        });
        // this.setState({showSpinner: false});
        this.props.hideSpinner();
      })
      .catch((err) => {
        presentToast({
          message: JSON.stringify(err),
        });
        // this.setState({showSpinner: false});
        this.props.hideSpinner();
      });
  }

  uploadImageBase64(imageURI) {
    const storageRef = firebase.storage().ref();
    const { uid } = this.props;
    const imageRef = storageRef
      .child('UsersInfo')
      .child(uid)
      .child('profile_picture');
    return imageRef.putString(imageURI, firebase.storage.StringFormat.BASE64).then(() => {
      return imageRef.getDownloadURL().then((downloadURL) => {
        return downloadURL;
      });
    });
  }

  renderAccounts = ({ item }) => {
    const { theme } = this.props;
    if (item.icon === Images._img_steam) {
      return (
        <LinearGradient colors={['#0B1C30', '#14659A']}
          style={[styles(theme).accountContainer]}>
          <TouchableOpacity
            onPress={() => NavigationService.navigate('UserGamesAccountsPage')}
            style={[styles(theme).accountSteam]}>
            <ImageWithPlaceholder
              uri={item.icon}
              style={styles(theme).accountImage}
              resizeMode="contain"
            />
            <Label size="x_small">{item.name}</Label>
          </TouchableOpacity>
        </LinearGradient>
      );
    } else {
      return (
        <TouchableOpacity
          onPress={() => NavigationService.navigate('UserGamesAccountsPage')}
          style={[
            styles(theme).accountContainer,
            { backgroundColor: item._backgroundColor },
          ]}>
          <ImageWithPlaceholder
            uri={item.icon}
            style={styles(theme).accountImage}
            resizeMode="contain"
          />
          <Label size="x_small">{item.name}</Label>
        </TouchableOpacity>
      );
    }
  };

  _toggleTooltip = () => {
    if (this.tooltipRef.current) {
      if (!this.state.tooltipToggle) {
        this.tooltipRef.current.toggleTooltip();
        setTimeout(() => {
          if (this.state.tooltipToggle) {
            this.tooltipRef.current.toggleTooltip();
          }
        }, 3000);
      }
    }
  };

  _openTooltip = () => {
    this.setState({ tooltipToggle: true });
  };

  _closeTooltip = () => {
    this.setState({ tooltipToggle: false });
  };

  render() {
    const { theme } = this.props;
    const { email, bio, phone } = this.state;
    const _styles = styles(theme);
    return (
      <View style={AppStyles.rootContainer}>
        {/* Header */}
        <Header
          text={I18n.t('EDIT_PROFILE_PAGE.profile')}
          rightComponent={
            <View style={_styles.headerRight}>
              <TouchableOpacity
                style={_styles.actionBtn}
                onPress={() => this.updateUserInfo()}>
                <Label style={_styles.pinkLabel} size="x_small" bold={true}>
                  {I18n.t('LOBBY_PAGE.save')}
                </Label>
              </TouchableOpacity>
            </View>
          }
        />
        {/* Content */}
        <View style={[AppStyles.rootContent]}>
          <KeyboardAwareScrollView showsVerticalScrollIndicator={false}>
            <View
              style={{
                alignItems: 'center',
              }}>
              <View style={_styles.avatarContainer}>
                <UserAvatar
                  src={this.state.pictureURL}
                  size={perfectSize(96)}
                  borderColor={
                    this.props.userinformationDB.profileBorderColor ||
                    theme.primary_btn_background
                  }
                />

                <TouchableOpacity
                  style={_styles.iconContainer}
                  onPress={this.selectImage}>
                  <Feather name="edit-2" size={perfectSize(16)} color="white" />
                </TouchableOpacity>
              </View>
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                }}>
                <Label
                  size="big"
                  style={{
                    textTransform: 'capitalize',
                  }}>
                  {this.props.userinformationDB.username}
                </Label>
              </View>
            </View>

            <Label style={{ marginTop: 24 }} size="medium" bold={true}>
              {I18n.t('EDIT_PROFILE_PAGE.your_accounts')}
            </Label>

            <TouchableOpacity
              style={{
                marginTop: perfectSize(16),
              }}
              onPress={() =>
                NavigationService.navigate('UserGamesAccountsPage')
              }>
              <View style={_styles.manageBtnContainer}>
                <Label size="x_small">
                  {I18n.t('EDIT_PROFILE_PAGE.manage_accounts')}
                </Label>
              </View>
            </TouchableOpacity>

            <View
              style={{
                flex: 1,
                marginTop: perfectSize(16),
              }}>
              <FlatList
                data={[
                  this.state.PSNAcc,
                  this.state.XboxLiveAcc,
                  this.state.epicGames,
                  this.state.steam,
                  this.state.origin,
                  this.state.battlenet,
                  this.state.nintendoAcc,
                  this.state.discord,
                ]}
                keyExtractor={(item, index) => String(index)}
                horizontal={true}
                renderItem={this.renderAccounts}
                showsVerticalScrollIndicator={false}
              />
            </View>

            <Label style={{ marginTop: 24 }} size="medium" bold={true}>
              {I18n.t('EDIT_PROFILE_PAGE.your_information')}
            </Label>

            <TouchableOpacity
              style={[_styles.emailContainer]}
              onPress={this._toggleTooltip}>
              <LocalImageOpacity
                source={Images.EMAIL}
                style={_styles.itemIcon}
                resizeMode="contain"
              />
              <Label style={_styles.emailLabel}>
                {email || 'You@hotplay.com'}
              </Label>
            </TouchableOpacity>
            <Tooltip
              onOpen={this._openTooltip}
              onClose={this._closeTooltip}
              ref={this.tooltipRef}
              overlayColor={'transparent'}
              backgroundColor="black"
              containerStyle={{
                height: 100,
                width: '100%',
                left: 0,
                position: 'absolute',
              }}
              popover={
                <Label>{I18n.t('EDIT_PROFILE_PAGE.email_tooltip')}</Label>
              }
            />
            <TextInput
              placeholder={I18n.t('EDIT_PROFILE_PAGE.bio')}
              onChangeText={(val) => this._onValueChange('bio', val)}
              value={bio}
              leftComponent={
                <LocalImageOpacity
                  source={Images.INFO}
                  style={_styles.itemIcon}
                  resizeMode="contain"
                />
              }
              customStyles={{
                backgroundColor: theme.secondary_background,
                marginVertical: perfectSize(8),
              }}
            />
            <Picker
              pickerName={I18n.t('EDIT_PROFILE_PAGE.country')}
              value={this.state.selectedCountry}
              searchPlaceholder={'Search for a country...'}
              isSearchable={true}
              searchTerm={''}
              customStyles={{ marginVertical: perfectSize(8) }}
              values={this.state.countriesArray}
              onPickerChanged={(val) => {
                this.setState({
                  selectedCountry: val,
                  citiesMap: new Map(),
                  selectedCity: '',
                });
                this.loadCountryCities(val);
              }}
              translate={false}
              leftComponent={
                <LocalImageOpacity
                  source={Images.MAP_PIN}
                  style={_styles.itemIcon}
                  resizeMode="contain"
                />
              }
            />

            <Picker
              pickerName={I18n.t('EDIT_PROFILE_PAGE.city')}
              value={this.state.selectedCity}
              searchPlaceholder={'Search for a city...'}
              isSearchable={true}
              searchTerm={''}
              customStyles={{ marginVertical: perfectSize(8) }}
              values={this.state.citiesArray}
              onPickerChanged={(val) => {
                this.setState({ selectedCity: val });
              }}
              translate={false}
              leftComponent={
                <LocalImageOpacity
                  source={Images.MAP_PIN}
                  style={_styles.itemIcon}
                  resizeMode="contain"
                />
              }
            />
            <Picker
              pickerName={I18n.t('EDIT_PROFILE_PAGE.gender')}
              value={this.state.selectedGender}
              customStyles={{ marginVertical: perfectSize(8) }}
              values={EDIT_PROFILE_GENDER}
              translate={true}
              onPickerChanged={(val) => this.setState({ selectedGender: val })}
              leftComponent={
                <LocalImageOpacity
                  source={Images.GENDER}
                  style={_styles.itemIcon}
                  resizeMode="contain"
                />
              }
            />

            <Picker
              pickerName={I18n.t('EDIT_PROFILE_PAGE.age')}
              value={this.state.selectedAge}
              customStyles={{ marginVertical: perfectSize(8) }}
              values={EDIT_PROFILE_AGE}
              translate={true}
              onPickerChanged={(val) => this.setState({ selectedAge: val })}
              leftComponent={
                <LocalImageOpacity
                  source={Images.AGE}
                  style={_styles.itemIcon}
                  resizeMode="contain"
                />
              }
            />

            <Picker
              pickerName={I18n.t('EDIT_PROFILE_PAGE.main_platform')}
              value={this.state.selectedPlatform}
              customStyles={{ marginVertical: perfectSize(8) }}
              values={REGISTRATION_PLATFORM}
              onPickerChanged={(val) => this.setState({ selectedPlatform: val })}
              translate={true}
              leftComponent={
                <LocalImageOpacity
                  source={Images.JOYSTICK}
                  style={_styles.itemIcon}
                  resizeMode="contain"
                />
              }
            />

            <Picker
              pickerName={I18n.t('EDIT_PROFILE_PAGE.favorite_game')}
              value={this.state.favoriteOnlineGame}
              searchPlaceholder={I18n.t('GAME_LIST_PAGE.search')}
              isSearchable={true}
              fieldName={'gameName'}
              searchTerm={''}
              customStyles={{ marginVertical: perfectSize(8) }}
              values={this.state.allGames}
              onPickerChanged={(val) => {
                this.setState({ favoriteOnlineGame: val });
                this.loadCountryCities(val);
              }}
              translate={false}
              leftComponent={
                <LocalImageOpacity
                  source={Images.JOYSTICK}
                  style={_styles.itemIcon}
                  resizeMode="contain"
                />
              }
            />

            <Picker
              pickerName={I18n.t('EDIT_PROFILE_PAGE.avg_playtime')}
              value={this.state.selectedAvgPlaytime}
              customStyles={{ marginVertical: perfectSize(8) }}
              values={EDIT_PROFILE_AVG_PLAYTIME}
              onPickerChanged={(val) =>
                this.setState({ selectedAvgPlaytime: val })
              }
              translate={true}
              leftComponent={
                <LocalImageOpacity
                  source={Images.TIME}
                  style={_styles.itemIcon}
                  resizeMode="contain"
                />
              }
            />

            <Picker
              pickerName={I18n.t('EDIT_PROFILE_PAGE.avg_playtime_daily')}
              value={this.state.selectedAvgDailyPlaytime}
              customStyles={{ marginVertical: perfectSize(8) }}
              values={EDIT_PROFILE_AVG_PLAYTIME_DAILY}
              onPickerChanged={(val) =>
                this.setState({ selectedAvgDailyPlaytime: val })
              }
              translate={true}
              leftComponent={
                <LocalImageOpacity
                  source={Images.TIME}
                  style={_styles.itemIcon}
                  resizeMode="contain"
                />
              }
            />

            <Picker
              pickerName={I18n.t('EDIT_PROFILE_PAGE.day_playtime')}
              value={this.state.selectedDayPlaytime}
              customStyles={{ marginVertical: perfectSize(8) }}
              values={EDIT_PROFILE_DAY_PLAYTIME}
              onPickerChanged={(val) =>
                this.setState({ selectedDayPlaytime: val })
              }
              translate={true}
              leftComponent={
                <LocalImageOpacity
                  source={Images.TIME}
                  style={_styles.itemIcon}
                  resizeMode="contain"
                />
              }
            />
            <TextInput
              placeholder={I18n.t('EDIT_PROFILE_PAGE.phone')}
              onChangeText={(val) => this._onValueChange('phone', val)}
              value={phone}
              leftComponent={
                <LocalImageOpacity
                  source={Images.PHONE}
                  style={_styles.itemIcon}
                  resizeMode="contain"
                />
              }
              customStyles={{
                backgroundColor: theme.secondary_background,
                marginVertical: perfectSize(8),
              }}
            />
            <PrimaryButton
              onPress={() => this.updateUserInfo()}
              text={I18n.t('EDIT_PROFILE_PAGE.update')}
              size="medium"
              type="filled"
              customStyles={_styles.submitBtn}
            />
          </KeyboardAwareScrollView>
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
    uid: state.authReducer.uid,
    userinformationDB: state.authReducer.userinformationDB,
    loading: state.authReducer.loading,
    gamesList: state.hubReducer.gamesList,
  };
};

export default compose(
  connect(mapStateToProps, {
    updateProfile,
    setUserInfo,
    showSpinner,
    hideSpinner,
  }),
)(EditProfilePage);
