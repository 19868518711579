import {StyleSheet} from 'react-native';
import {perfectSize} from '../../helpers/deviceHelper';
import dimens from '../../constants/dimens';

const styles = (theme) =>
  StyleSheet.create({
    // Header
    headerRight: {
      flexDirection: 'row-reverse',
    },
    actionBtn: {
      padding: perfectSize(4),
      marginRight: perfectSize(20),
    },
    pinkLabel: {
      color: '#C70064',
    },
    // Content
    wrapper: {
      alignItems: 'center',
    },
    hopWrapper: {
      width: '70%',
      marginTop: perfectSize(50),
    },
    avatar: {
      marginTop: perfectSize(45),
    },
    descLabel: {
      marginTop: perfectSize(24),
      opacity: 0.7,
    },
    colorsWrapper: {
      marginTop: perfectSize(32),
      flexDirection: 'row',
      width: '100%',
      flexWrap: 'wrap',
    },
    colorItem: {
      width: 30,
      height: 40,
      margin: 12,
      borderRadius: 15,
      backgroundColor: '#dd5500',
    },
    saveBtn: {
      width: '100%',
      marginTop: perfectSize(32),
      marginBottom: dimens.CONTENT_PADDING_BOTTOM,
    },
  });

export default styles;
