import React, { memo, Component } from 'react';
import { connect } from 'react-redux';
import {
    Placeholder,
    PlaceholderMedia,
    PlaceholderLine,
    Fade,
    Shine,
    ShineOverlay
} from "rn-placeholder";

class SkeletonUserAvatar extends Component {
    constructor(props) {
        super(props);
        this.mount = true;
        this.state = {};
    }

    componentDidMount() { }

    componentWillUnmount = () => {
        this.mount = false;
    }

    render() {
        const { theme, isRound, style, size } = this.props;

        return (
            <PlaceholderMedia color={theme.secondary_btn_background} isRound={isRound || true} style={style} size={size} />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        theme: state.appReducer.currentTheme,
        lang: state.appReducer.currentLang,
    };
};

export default memo(connect(mapStateToProps)(SkeletonUserAvatar));