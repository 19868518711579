import React, { Component } from 'react';
import {
  View,
  FlatList,
  TouchableOpacity,
  Image,
  Linking,
} from 'react-native';
import { compose } from 'redux';
import { connect } from 'react-redux';
import firebase from 'firebase/app';
import 'firebase/database';

import {
  Placeholder,
  Progressive
} from "rn-placeholder";

// import components
import Label from '../../components/label';
import ImageWithPlaceholder from '../../components/image-with-placeholder';

import styles from './styles';
import * as Images from '../../constants/images';
import NavigationService from '../../navigation/navigationService';

import * as FirebasePaths from '../../constants/firebasePaths';

import { showSpinner, hideSpinner } from '../../redux/modules/alert/actions';

// import ad service
import AdService from '../../services/adSpot.service';
import { useIsFocused } from '@react-navigation/native';
import LocalImageOpacity from '../../components/local-image-opacity';
import { perfectSize } from '../../helpers/deviceHelper';
import { dataForSkeleton } from '../../services/utils';
import SkeletonTextContent from '../../components/skeletonWrapper/skeletonTextContent';

class ChampionshipsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      championshipsArr: [],
      isLoading: true,
      AD: null,
    };
  }

  componentDidMount() {
    this.focusListener$ = this.props.navigation.addListener(
      'focus',
      (payload) => {
        // Log the agents page view
        global.featuresLogger.CHAMPIONSHIPS_PAGE_VIEW();
        // Log Rank HP
        global.rankLogger.LOG_NEW_HP(
          global.rankLogger.CHAMPIONSHIPS_PAGE_VIEW,
          'CHAMPIONSHIPS_PAGE_VIEW',
        );
      },
    );

    // Call the load championships functiuon here
    this.loadChampionships();

    // Load the AD
    this.ADRef$ = firebase.database().ref(
      FirebasePaths.FIREBASE_AD_SPOTS +
      '/' +
      FirebasePaths.FIREBASE_AD_SPOTS_CHAMPIONSHIPS,
    );
    this.ADRef$.on('value', (snapshot) => {
      if (snapshot.val() != null) {
        this.setState({
          AD: snapshot.val(),
        });
      } else {
        this.setState({
          AD: 'NONE',
        });
      }
    });
  }

  componentWillUnmount() {
    if (this.championshipsRef$) {
      this.championshipsRef$.off();
      this.championshipsRef$ = null;
    }
    if (this.ADRef$) {
      this.ADRef$.off();
      this.ADRef$ = null;
    }
    if (this.focusListener$) {
      this.focusListener$();
    }
  }

  // This function will load the offline championships
  loadChampionships() {
    let path = FirebasePaths.FIREBASE_CHAMPIONSHIPS_REFERENCE + '/';
    this.championshipsRef$ = firebase.database().ref(path).limitToLast(100);
    this.championshipsRef$.on('value', (shot) => {
      let championshipsArr = [];
      shot.forEach((item) => {
        if (item.val()._info_ != null) {
          championshipsArr.push(item.val()._info_);
        }
      });
      const sliceCount = championshipsArr.length > 3 ? 3 : championshipsArr.length;
      const preloadUris = []
      championshipsArr.slice(0, sliceCount).map(champ => {
        if (champ.photo) {
          return preloadUris.push({
            uri: champ.photo
          })
        }
      })
      this.setState({ championshipsArr });
      setTimeout(() => {
        this.setState({ isLoading: false });
      }, 1000);
    });
  }

  toChampionshipInfo = (champ) => {
    NavigationService.moveToScreenInStack('HomeStack', 'ChampionshipInfoPage', {
      championshipObj: champ,
    });
  };

  openAD = () => {
    const { AD } = this.state;
    if (AD != null && AD.url != null) {
      global.featuresLogger.CHAMPIONSHIPS_AD_CLICK();
      AdService.checkAdUrl(AD);
    }
  };

  renderCards = ({ item, index }) => {
    const _styles = styles(this.props.theme);
    return (
      <TouchableOpacity
        style={[_styles.card, { width: '25%', paddingHorizontal: 10 }]}
        onPress={() => this.toChampionshipInfo(item)}>
        <View style={{
          flex: 1,
          borderRadius: perfectSize(12),
          overflow: 'hidden',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#181F29',
        }}>
          <ImageWithPlaceholder style={_styles.cover} uri={item.photo} />
          <View style={_styles.cardContent}>
            <ImageWithPlaceholder uri={item.provider_img} style={_styles.logo} />
            <Label size="x_small" style={_styles.appName}>
              {item.provider_name}
            </Label>
            <Label style={_styles.gameName}>{item.title}</Label>
            <View style={_styles.footer}>
              <View style={_styles.item}>
                <LocalImageOpacity
                  source={Images.AWARD}
                  style={_styles.icon}
                  resizeMode="contain"
                />
                <Label style={_styles.value}>{item.prize}</Label>
              </View>
              <View style={_styles.item}>
                <LocalImageOpacity
                  source={Images.GROUP}
                  style={_styles.icon}
                  resizeMode="contain"
                />
                <Label style={_styles.value}>{item.max_participants}</Label>
              </View>
              <View style={_styles.item}>
                <LocalImageOpacity
                  source={Images.JOYSTICK2}
                  style={_styles.icon}
                  resizeMode="contain"
                />
                <Label style={_styles.value}>{item.platform_text}</Label>
              </View>
              <View style={_styles.item}>
                <LocalImageOpacity
                  source={Images.CALENDAR}
                  style={_styles.icon}
                  resizeMode="contain"
                />
                <Label style={_styles.value}>{item.start_date}</Label>
              </View>
            </View>
          </View>
          <View style={[_styles.status, ]}>
            <View
              style={[_styles.overlay, { backgroundColor: item.status_color }]}
            />
            <Label style={_styles.statusText}>{item.status_text}</Label>
          </View>
        </View>
      </TouchableOpacity>
    );
  };

  render() {
    const { theme, isFocused } = this.props;
    const _styles = styles(theme);
    const { championshipsArr, isLoading, AD } = this.state;
    if (isFocused) {
      return (
        <View style={_styles.content}>
          {isLoading ? (
            <FlatList
              data={dataForSkeleton(10)}
              style={{ width: '100%' }}
              showsVerticalScrollIndicator={false}
              numColumns={4}
              renderItem={() => {
                return <View style={{ marginVertical: perfectSize(10), width: '25%', paddingHorizontal: 10 }}>
                  <Placeholder
                    Animation={Progressive}
                  >
                    <SkeletonTextContent height={100} />
                    <SkeletonTextContent height={20} style={{ marginTop: 10 }} />
                    <SkeletonTextContent height={20} width={20} style={{ marginTop: 10 }} />
                  </Placeholder>
                </View>
              }}
            />
          ) : (
            <FlatList
              data={championshipsArr}
              showsVerticalScrollIndicator={false}
              renderItem={this.renderCards}
              keyExtractor={(item, index) => String(index)}
              style={_styles.flatList}
              numColumns={4}
              columnWrapperStyle={{ justifyContent: 'space-between' }}
              ListHeaderComponent={
                <View>
                  {AD != null && AD !== 'NONE' && (
                    <TouchableOpacity
                      style={_styles.adCard}
                      onPress={() => this.openAD()}>
                      {AD.img != null && (
                        AD.img.includes('.gif') ?
                          // <GifImage
                          //   style={_styles.adCover}
                          //   source={{ uri: AD.img }}
                          // />
                          <></>
                          :
                          <ImageWithPlaceholder
                            style={_styles.adCover}
                            uri={AD.img}
                          />
                      )}
                    </TouchableOpacity>
                  )}
                </View>
              }
            />
          )}
        </View>
      );
    }
    return <View />;

  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
  };
};

function focusComponent(props) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const isFocused = useIsFocused();
  return <ChampionshipsPage {...props} isFocused={isFocused} />;
}

export default compose(connect(mapStateToProps, { showSpinner, hideSpinner }))(
  focusComponent,
);
