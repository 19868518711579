import React, { memo, Component } from 'react';
import { View, StyleSheet } from 'react-native';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import UserAvatar from '../../user-avatar';
import Label from '../../label';

import AppStyles from '../../../constants/styles';
import { perfectSize } from '../../../helpers/deviceHelper';
import ImageWithPlaceholder from '../../image-with-placeholder';

class LeaderboardCard extends Component {
  render() {
    const { theme, index, photo, username, rank, borderColor } = this.props;
    const _styles = styles(theme);
    const rankImage = global.rankLogger.getRankImg(rank);
    return (
      <View style={[_styles.container, this.props.style]}>
        <View style={_styles.leftContent}>
          <Label>{index}</Label>
          <UserAvatar
            src={photo}
            size={perfectSize(48)}
            borderColor={borderColor}
            style={_styles.avatar}
          />
          <Label size="x_small">{username}</Label>
        </View>
        <View style={_styles.rightContent}>
          <ImageWithPlaceholder
            uri={rankImage}
            style={global.rankLogger.getRankStyle(rank)}
            resizeMethod={'resize'}
            resizeMode="contain"
          />
          <View style={_styles.rankWrapper}>
            <Label size="small" style={_styles.rank}>
              {rank}
            </Label>
            <Label size="small" style={_styles.rankUnit}>
              HP
            </Label>
          </View>
        </View>
      </View>
    );
  }
}

LeaderboardCard.defaultProps = {
  borderColor: '#C70064',
};

LeaderboardCard.propTypes = {
  index: PropTypes.number,
  photo: PropTypes.string,
  username: PropTypes.string,
  rank: PropTypes.number,
  borderColor: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    theme: state.appReducer.currentTheme,
  };
};

export default memo(connect(mapStateToProps)(LeaderboardCard));

const styles = (theme) =>
  StyleSheet.create({
    container: {
      backgroundColor: theme.secondary_btn_background,
      flexDirection: 'row',
      alignItems: 'center',
      borderRadius: perfectSize(8),
      padding: perfectSize(16),
      justifyContent: 'space-between',
    },
    leftContent: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    avatar: {
      marginHorizontal: perfectSize(8),
    },
    rightContent: {
      alignItems: 'center',
    },
    rankWrapper: {
      flexDirection: 'row',
      marginTop: perfectSize(4),
    },
    rank: {
      opacity: 0.56,
    },
    rankUnit: {
      color: theme.primary_btn_background,
      marginLeft: perfectSize(4),
    },
  });
