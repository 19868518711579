import React, { PureComponent } from 'react';
import { View, TouchableOpacity } from 'react-native';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Feather from 'react-native-vector-icons/Feather';

import NavigationService from '../../navigation/navigationService';

import Label from '../label';
import styles from './styles';
import AppStyles from '../../constants/styles';

import {
  handleAndroidBackButton,
  removeAndroidBackButtonHandler,
  perfectSize,
} from '../../helpers/deviceHelper';
import { clearFastImageCache } from '../../services/utils';

class Header extends PureComponent {
  goBack = () => {
    clearFastImageCache();
    NavigationService.goBack();
  };

  componentDidMount() {
    handleAndroidBackButton(this.goBack);
  }

  componentWillUnmount() {
    removeAndroidBackButtonHandler();
  }

  render() {
    const { theme, text, back, bold, onBack, rightComponent } = this.props;
    const _styles = styles(theme);
    return (
      <View style={[_styles.container, this.props.style]}>
        <View style={_styles.wrapper}>
          {/* Back button */}
          <View style={_styles.sides}>
            {back && (
              <TouchableOpacity
                style={AppStyles.leftBarButton}
                onPress={() => (onBack ? onBack() : this.goBack())}>
                <Feather
                  name="chevron-left"
                  color="gray"
                  size={perfectSize(22.5)}
                />
              </TouchableOpacity>
            )}
          </View>
          {/* Title */}
          <View style={_styles.center}>
            {Boolean(text) && (
              <Label size="medium" bold={bold} style={_styles.title}>
                {text}
              </Label>
            )}
          </View>
          {/* Right item */}
          <View style={_styles.sides}>{rightComponent && rightComponent}</View>
        </View>
      </View>
    );
  }
}

Header.defaultProps = {
  text: '',
  back: true,
  bold: true,
};

Header.propTypes = {
  text: PropTypes.string,
  back: PropTypes.bool,
  bold: PropTypes.bool,
  rightComponent: PropTypes.any,
};

export default connect(
  (state) => ({
    theme: state.appReducer.currentTheme,
    lang: state.appReducer.currentLang,
  }),
  (dispatch) => ({}),
)(Header);
